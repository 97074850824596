import PropTypes from "prop-types";
import { FormProvider } from "react-hook-form";
import Typography from "@mui/material/Typography";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useTheme } from "@mui/styles";
import charts from "theme/charts";
import useMediaQuery from "@mui/material/useMediaQuery";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import TitleIcon from "components/TitleIcon";
import SimpleAlert from "components/SimpleAlert";
import { ROLES, ICON_TYPES, INFO } from "common/constants";
import { isAdmin, isValidRole } from "common/utils";
import palette from "theme/palette";
import ProcessForm from "./components/ProcessForm";
import CalendarProcess from "./components/CalendarProcess";
import { StyledContainer, StyledContent, StyledCalendarSection } from "./styles";

const PlanningView = (props) => {
  const {
    t, user, methods,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const processForm = (
    <StyledContent>
      <FormProvider { ...methods }>
        <ProcessForm { ...props } />
      </FormProvider>
    </StyledContent>
  );

  return (
    <StyledContainer data-testid={ "planning-view-component" }>
      <div>
        <ViewHeaderTitle
          title={ t("planning:title") }
        />
        <Typography>
          {t("planning:subtitle")}
          <strong>{t("planning:subtitle2") }</strong>
        </Typography>
      </div>
      {isMobile ? (
        <SimpleAlert
          type={ INFO }
          message={ t("planning:alert.mobile") }
        />
      ) : (isAdmin(user?.userCookies)
        || isValidRole(user?.userCookies, ROLES.TALENT_MANAGER))
        && processForm}
      <StyledCalendarSection>
        <TitleIcon
          icon={ {
            src: CalendarTodayIcon,
            color: palette.primaryApp,
          } }
          title={ t("planning:scheduledEvents") }
          subtitle={ t("planning:subtitle3") }
          iconType={ ICON_TYPES.icon }
        />
        <CalendarProcess { ...props } isMobile={ isMobile } />
      </StyledCalendarSection>
    </StyledContainer>
  );
};

PlanningView.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  methods: PropTypes.object.isRequired,
};

export default PlanningView;
