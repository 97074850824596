import ImageUploading from "react-images-uploading";
import PropTypes from "prop-types";
import { isUndefined } from "common/helpers";
import Badge from "@mui/material/Badge";
import {
  ALIGN_ITEMS, DATA_URL_KEY, INDEX, VARIANT,
} from "common/constants";
import { ReactComponent as CameraIcon } from "assets/images/icons/FlipCamera.svg";
import defaultImg from "assets/images/icons/account.svg";
import useComplexState from "hooks/utils/useComplexState";
import {
  StyledProfileImage,
  StyledEditIcon,
  StyledEditLayer,
  StyledImageLoaderContainer,
} from "./styles";

const UploadAvatar = (props) => {
  const {
    id, value, onChange, isReadOnly, altValue,
  } = props;

  const [states, setStates] = useComplexState({
    avatar: [],
    hoverClass: "",
  });

  const handleChange = (image) => {
    setStates({
      avatar: image,
    });
    onChange(id, image[0]);
  };

  const handleHoverEditAvatar = (className) => {
    setStates({
      hoverClass: className,
    });
  };

  return (
    <StyledImageLoaderContainer
      canEdit={ !isReadOnly }
      onMouseEnter={ () => handleHoverEditAvatar("editLayer") }
      onMouseLeave={ () => handleHoverEditAvatar("") }
    >
      {isReadOnly ? (
        <StyledProfileImage
          alt={ altValue }
          src={ value || defaultImg }
        />
      ) : (
        <ImageUploading
          value={ states.avatar }
          onChange={ handleChange }
          dataURLKey={ DATA_URL_KEY }
          acceptType={ ["jpg", "png", "jpeg"] }
        >
          {({ imageList, onImageUpload }) => (
            <div
              onClick={ onImageUpload }
              onKeyPress={ onImageUpload }
              role={ "button" }
              tabIndex={ "0" }
            >
              <Badge
                overlap={ VARIANT.circle }
                anchorOrigin={ {
                  vertical: ALIGN_ITEMS.bottom,
                  horizontal: ALIGN_ITEMS.right,
                } }
              >
                {states.hoverClass && (
                  <StyledEditLayer>
                    <StyledEditIcon>
                      <CameraIcon />
                    </StyledEditIcon>
                  </StyledEditLayer>
                )}
                <StyledProfileImage
                  alt={ altValue }
                  src={ isUndefined(imageList[INDEX.zero])
                    ? value
                    : imageList[INDEX.zero][DATA_URL_KEY] }
                />
              </Badge>
            </div>
          )}
        </ImageUploading>
      )}
    </StyledImageLoaderContainer>
  );
};

UploadAvatar.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  altValue: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

UploadAvatar.defaultProps = {
  defaultValue: null,
  label: null,
  isReadOnly: false,
};

export default UploadAvatar;
