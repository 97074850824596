import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import InputTextController from "components/InputTextController";
import CollaboratorProfileSummary from "components/CollaboratorProfileSummary";
import SelectController from "components/SelectController";
import { orderByDesc } from "common/helpers";
import { formatDate, findDataInScale } from "common/utils";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { useCalibrationReasons } from "hooks/useCalibrationReasons";
import { useCreateCalibration, useUpdateCalibration } from "hooks/calibration";
import CurrentScore from "./components/CurrentScore";
import CalibratedScore from "./components/CalibratedScore";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
  StyledForm,
  StyledGridArrow,
  StyledGridContainer,
  StyledContainer,
  StyledGridDetail,
  StyledLabel,
  StyledInputContainer,
  StyledCalibrationInfo,
} from "./styles";

const CalibrationModal = ({
  isOpen, onClose, selectResult, scale, type, isValidToUpdate,
}) => {
  const { t } = useTranslation("common");

  // Calibration Reason
  const { calibrationReasons, isLoading } = useCalibrationReasons();

  // Calibration
  const { mutate: createCalibration } = useCreateCalibration(t);

  const { mutate: updateCalibration } = useUpdateCalibration(t);

  const {
    control, handleSubmit, reset, setValue,
  } = useForm({
    defaultValues: {
      calibration_reason_id: "",
      comments: "",
    },
  });

  const [selectedValue, setSelectedValue] = useState(null);
  const [resultIndex, setResultIndex] = useState(null);
  const [dataByEmployee, setDataByEmployee] = useState(null);
  const [result, setResult] = useState(null);

  const resultScaleIndexDesc = orderByDesc(scale, "top");

  const setCalibrationData = useCallback(() => {
    const { calibration } = dataByEmployee;
    setValue("calibration_reason_id", calibration.calibration_reason.id);
    setValue("comments", calibration.comments);

    const scaleIndexResult = findDataInScale(scale, calibration.calibrated_score, false);
    const scaleValue = scaleIndexResult?.id;

    const scaleIndex = findDataInScale(scale, calibration.previous_score, false);
    setSelectedValue(scaleValue);
    setResultIndex(scaleIndex);
    setResult(scaleIndexResult);
    // eslint-disable-next-line
  }, [dataByEmployee, resultScaleIndexDesc, setValue]);

  const setDefaultData = useCallback(() => {
    reset({
      calibration_reason_id: calibrationReasons[0]?.value,
      comments: "",
    });

    const scaleIndex = findDataInScale(scale, dataByEmployee.result);

    setSelectedValue(resultScaleIndexDesc[0].id);
    setResultIndex(scaleIndex);
    setResult(resultScaleIndexDesc[0]);
    // eslint-disable-next-line
  }, [calibrationReasons, dataByEmployee, reset, resultScaleIndexDesc]);

  useEffect(() => {
    if (selectResult) {
      setDataByEmployee(selectResult);
    }
    // eslint-disable-next-line
  }, [selectResult]);

  useEffect(() => {
    if (dataByEmployee !== null) {
      if (selectResult?.calibration) {
        setCalibrationData();
      } else {
        setDefaultData();
      }
    }
    // eslint-disable-next-line
  }, [dataByEmployee]);

  const onSubmit = (data) => {
    data.survey_result_id = dataByEmployee.id;

    const selectedScale = resultScaleIndexDesc.find((item) => item.id === selectedValue);

    let calibratedResult = null;

    if (selectedScale) {
      const { top, bottom, result: resultName } = selectedScale;
      data.calibrated_score = (top + bottom) / 2;
      calibratedResult = resultName;
    }

    const calibrationId = dataByEmployee?.calibration?.id;
    if (calibrationId) {
      updateCalibration({ id: calibrationId, data }, {
        onSuccess: (updatedCalibration) => {
          selectResult.calibration = updatedCalibration;
          const managerEvaluation = selectResult.results_by_evaluation_type.find(
            (evalType) => evalType.evaluation_type === EVALUATION_TYPE.MANAGER,
          );
          if (managerEvaluation) {
            selectResult.calibrated = true;
            selectResult.calibratedResult = calibratedResult;
          }
          onClose();
        },
      });
    } else {
      createCalibration(data, {
        onSuccess: (createdCalibration) => {
          selectResult.calibration = createdCalibration;
          const managerEvaluation = selectResult.results_by_evaluation_type.find(
            (evalType) => evalType.evaluation_type === EVALUATION_TYPE.MANAGER,
          );
          if (managerEvaluation) {
            selectResult.calibrated = true;
            selectResult.calibratedResult = calibratedResult;
          }
          onClose();
        },
      });
    }
  };

  const handleRatingChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Dialog open={ isOpen } maxWidth={ "sm" } fullWidth>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <StyledDialogTitle>
          {isValidToUpdate ? `${t("calibration.modal.title")}:` : `${t("calibration.title")}:`}
          <IconButton onClick={ onClose }>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        {dataByEmployee && (
          <StyledDialogContent isView={ !isValidToUpdate }>
            <StyledForm>
              {dataByEmployee?.employee && (
                <CollaboratorProfileSummary collaborator={ dataByEmployee.employee } />
              )}
              <StyledGridContainer container spacing={ 2 }>
                <Grid item xs={ isValidToUpdate ? 5 : 6 } align={ !isValidToUpdate && "right" } className={ "result " }>
                  {resultIndex && (
                    <CurrentScore
                      scaleMax={ scale.length }
                      resultIndex={ resultIndex }
                      selectResult={ dataByEmployee }
                      t={ t }
                      type={ type }
                      isValidToUpdate={ isValidToUpdate }
                    />
                  )}
                </Grid>
                {isValidToUpdate
                && (
                  <StyledGridArrow item xs={ 2 }>
                    <ArrowForwardIcon />
                  </StyledGridArrow>
                )}
                <Grid item xs={ isValidToUpdate ? 5 : 6 }>
                  {selectedValue && (
                    <CalibratedScore
                      scale={ resultScaleIndexDesc }
                      selectedValue={ selectedValue }
                      handleRatingChange={ handleRatingChange }
                      t={ t }
                      type={ type }
                      isValidToUpdate={ isValidToUpdate }
                      result={ result }
                    />
                  )}
                </Grid>
              </StyledGridContainer>
            </StyledForm>
            <StyledGridDetail container>
              <Grid item xs={ 12 }>
                <StyledLabel>
                  {`${t("calibration.modal.reason")}: `}
                  {!isValidToUpdate && dataByEmployee.calibration?.calibration_reason?.name}
                </StyledLabel>
                {isValidToUpdate
                && (
                  <SelectController
                    id={ "calibration_reason_id" }
                    name={ "calibration_reason_id" }
                    control={ control }
                    menuItems={ calibrationReasons }
                    sx={ { width: "100%" } }
                    disabled={ calibrationReasons.length === 0 && isLoading }
                    size={ "small" }
                  />
                )}
              </Grid>
              <StyledContainer item xs={ 12 }>
                <StyledInputContainer>
                  <InputTextController
                    type={ "text" }
                    control={ control }
                    multiline={ 3 }
                    name={ "comments" }
                    size={ "small" }
                    placeholder={ t("calibration.modal.comments") }
                    disabled={ !isValidToUpdate }
                  />
                  {dataByEmployee?.calibration?.last_updated_by && (
                    <StyledCalibrationInfo>
                      {t("calibration.last_update", {
                        name: dataByEmployee.calibration.last_updated_by,
                        date: formatDate(dataByEmployee.calibration.last_updated_at),
                      })}
                    </StyledCalibrationInfo>
                  )}
                </StyledInputContainer>
              </StyledContainer>
            </StyledGridDetail>
          </StyledDialogContent>
        )}
        <StyledDialogActions>
          <Button typeStyle={ "cancel" } onClick={ onClose }>
            {t("common.cancel")}
          </Button>
          <Button typeStyle={ "submit" } type={ "submit" } isDisabled={ !isValidToUpdate }>
            {t("common.save")}
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

CalibrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectResult: PropTypes.shape({
    id: PropTypes.number.isRequired,
    result: PropTypes.string.isRequired,
    results_by_evaluation_type: PropTypes.arrayOf(
      PropTypes.shape({
        evaluation_type: PropTypes.string.isRequired,
      }),
    ).isRequired,
    calibration: PropTypes.shape({
      calibration_reason: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      comments: PropTypes.string,
      calibrated_score: PropTypes.number,
      previous_score: PropTypes.number,
    }),
    employee: PropTypes.shape({
      full_name: PropTypes.string,
    }),
    calibrated: PropTypes.bool,
    calibratedResult: PropTypes.string,
  }).isRequired,
  scale: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    result: PropTypes.string.isRequired,
    top: PropTypes.number.isRequired,
  })).isRequired,
  type: PropTypes.string,
  isValidToUpdate: PropTypes.bool,
};

CalibrationModal.defaultProps = {
  type: "performance",
  isValidToUpdate: false,
};

export default CalibrationModal;
