import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import DateInputController from "components/DateInputController";
import Button from "components/Button";
import {
  AUTOCOMPLETE,
  BUTTON_STYLE_TYPES,
  BUTTON_TYPE,
  DATE_PARTS,
  OBJECT_KEYS,
  VARIANT,
} from "common/constants";
import { formatDateToSubmit } from "common/utils";
import { getHistoricalMovementsToDownload } from "redux/actions/historicalMovementActions";
import { StyledContainer, StyledActions, StyledTypography } from "./styles";

const MovementDownloadModal = (props) => {
  const {
    modalHandler, query,
  } = props;
  const { t } = useTranslation("timeOff");

  const {
    isLoadingDownloadList,
  } = useSelector(({ historicalMovementsReducer }) => historicalMovementsReducer);

  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    query.q.date_gteq = formatDateToSubmit(data.first_date);
    query.q.date_lteq = formatDateToSubmit(data.second_date);
    dispatch(getHistoricalMovementsToDownload(query));
    modalHandler(false);
  };

  return (
    <StyledContainer>
      <form
        autoComplete={ AUTOCOMPLETE.off }
        onSubmit={ handleSubmit(onSubmit) }
      >
        <StyledTypography>{ t("common:common.dateRange") }</StyledTypography>
        <DateInputController
          id={ OBJECT_KEYS.firstDate }
          label={ t("from") }
          control={ control }
          name={ OBJECT_KEYS.firstDate }
          openTo={ DATE_PARTS.date }
          value={ new Date() }
          maxDate={ new Date() }
          required
          views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
        />
        <DateInputController
          id={ OBJECT_KEYS.secondDate }
          label={ t("to") }
          control={ control }
          name={ OBJECT_KEYS.secondDate }
          openTo={ DATE_PARTS.date }
          value={ new Date() }
          maxDate={ new Date() }
          required
          views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
        />
        <StyledActions>
          <Button
            variant={ VARIANT.contained }
            type={ BUTTON_TYPE.submit }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            isLoading={ isLoadingDownloadList }
            autoFocus
          >
            { t("common:common.downloadAction") }
          </Button>
        </StyledActions>
      </form>
    </StyledContainer>
  );
};

MovementDownloadModal.propTypes = {
  modalHandler: PropTypes.func.isRequired,
  query: PropTypes.object,
};

MovementDownloadModal.defaultValues = {
  query: {},
};

export default MovementDownloadModal;
