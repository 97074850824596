import { useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PropTypes from "prop-types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputNumberController from "components/InputNumberController";
import InputText from "components/InputTextController";
import { DIRECTION, VARIANT } from "common/constants";
import { getScaleIndicesOrder, generateRandomId } from "common/utils";
import OutBox from "assets/images/planning/outbox.svg";
import LanguageSelector from "../LanguageSelector";
import StakeholdersSection from "./StakeholdersSection";
import Question from "./Question";
import SectionActions from "./SectionActions";
import { PERFORMANCE_EVALUATION_TYPES } from "../../functions";
import { AddSectionButton } from "../SectionList/styles";
import {
  StyledDragItem,
  StyledItemContainer,
  StyledDragButton,
  StyledAccordion,
  StyledExpandMoreIcon,
  StyledWeighingTitle,
  StyledNameContainer,
  StyledStack,
  StyledNewQuestion,
  StyledImage,
  StyledBox,
} from "./styles";

const Section = (props) => {
  const {
    id,
    data,
    setShowItem,
    showItemId,
    onLanguageChange,
    i18n,
    control,
    watch,
    formLanguage,
    handleAccordionChange,
    expandedSection,
    evaluationScales,
    t,
    setTemplateStates,
    formValues,
    setFormValues,
    handleAddSection,
    handleDelete,
    handleDuplicate,
    templateData,
    setWatchedFields,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id });

  const [selectedEvaluationType, setSelectedEvaluationType] = useState(
    PERFORMANCE_EVALUATION_TYPES[0],
  );

  const [showNewQuestionMessage, setShowNewQuestionMessage] = useState(
    !(data?.isNew && data?.questions?.length === 0),
  );

  // Set the evaluation type when change stakeholder
  const handleTypeChange = (newType) => {
    setSelectedEvaluationType(newType);
  };

  // Stop propagation of the click event to avoid the section to be expanded
  const handleInputClick = (event) => {
    event.stopPropagation();
  };

  // Set the active section an type when the section is expanded
  useEffect(() => {
    if (showItemId === id || expandedSection === id) {
      setTemplateStates({
        active: {
          id,
          type: selectedEvaluationType,
        },
      });
    }
    // eslint-disable-next-line
  }, [showItemId, expandedSection, selectedEvaluationType]);

  const handleAddNewQuestion = () => {
    const newQuestion = {
      id: generateRandomId(14),
      name_en: "",
      name_es: "",
      name_pt: "",
      evaluation_scale_id: null,
      isNew: true,
    };

    const updatedQuestions = [...data.questions, newQuestion];
    const updatedSections = formValues.sections.map((section) => {
      if (section.id === id) {
        return { ...section, questions: updatedQuestions };
      }
      return section;
    });

    // Add the new question to the form values
    setFormValues({
      ...formValues,
      sections: updatedSections,
      [`question_${formLanguage}_${newQuestion.id}`]: newQuestion[`name_${formLanguage}`],
      [`scale_${newQuestion.id}`]: newQuestion.evaluation_scale_id,
    });

    // Add the new question to the watched fields
    setWatchedFields((prevState) => ({
      ...prevState,
      questions: [
        ...prevState.questions,
          `question_${formLanguage}_${newQuestion.id}`,
      ],
      scales: [
        ...prevState.scales,
          `scale_${newQuestion.id}`,
      ],
    }));
    setShowNewQuestionMessage(updatedQuestions.length > 0);
  };

  const handleDuplicateQuestion = (questionId) => {
    const { sections } = formValues;
    const sectionIndex = sections.findIndex((section) => section.id === id);
    const section = sections[sectionIndex];
    const questionIndex = section.questions.findIndex((question) => question.id === questionId);
    const questionToDuplicate = section.questions[questionIndex];

    const newQuestion = {
      ...questionToDuplicate,
      id: generateRandomId(10),
      isNew: true,
    };

    const updatedQuestions = [
      ...section.questions.slice(0, questionIndex + 1),
      newQuestion,
      ...section.questions.slice(questionIndex + 1),
    ];

    const updatedSection = {
      ...section,
      questions: updatedQuestions,
    };

    const updatedSections = [
      ...sections.slice(0, sectionIndex),
      updatedSection,
      ...sections.slice(sectionIndex + 1),
    ];

    // Add the new question to the form values
    setFormValues({
      ...formValues,
      sections: updatedSections,
      [`question_${formLanguage}_${newQuestion.id}`]: newQuestion[`name_${formLanguage}`],
      [`scale_${newQuestion.id}`]: newQuestion.evaluation_scale_id,
    });

    // Add the new question to the watched fields
    setWatchedFields((prevState) => ({
      ...prevState,
      questions: [
        ...prevState.questions,
          `question_${formLanguage}_${newQuestion.id}`,
      ],
      scales: [
        ...prevState.scales,
          `scale_${newQuestion.id}`,
      ],
    }));
  };

  const handleDeleteQuestion = (questionId) => {
    const isNalaDefault = templateData?.nala_default;
    let updatedQuestions = [];
    let updatedSections = [];
    const questionToDelete = data.questions.find((question) => question.id === questionId);

    // If the question is not new and is not the default Nala template, mark it as deleted
    if (!questionToDelete.isNew && !isNalaDefault) {
      updatedQuestions = data.questions.map((question) => {
        if (question.id === questionId) {
          return { ...question, isDeleted: true };
        }
        return question;
      });
      updatedSections = formValues.sections.map((section) => {
        if (section.id === id) {
          return { ...section, questions: updatedQuestions };
        }
        return section;
      });
    } else {
      // If the question is new or is the default Nala template, remove it from the form values
      updatedQuestions = data.questions.filter((question) => question.id !== questionId);
      updatedSections = formValues.sections.map((section) => {
        if (section.id === id) {
          return { ...section, questions: updatedQuestions };
        }
        return section;
      });
    }

    // Remove the question from the form values
    setFormValues({ ...formValues, sections: updatedSections });

    // Remove the question from the watched fields
    setWatchedFields((prevState) => ({
      ...prevState,
      questions: prevState.questions.filter((field) => field !== `question_${formLanguage}_${questionId}`),
      scales: prevState.scales.filter((field) => field !== `scale_${questionId}`),
    }));

    const noShowedQuestions = updatedQuestions.filter((question) => !question.isDeleted);
    if (noShowedQuestions.length === 0) {
      setShowNewQuestionMessage(false);
      setTemplateStates({ isValid: false });
    }
  };

  return (
    <StyledItemContainer
      ref={ setNodeRef }
      transform={ CSS.Transform.toString(transform) }
      transition={ transition }
      isDragging={ isDragging }
      isSelectedItem={ showItemId === id }
    >
      {expandedSection === id
        && (
          <>
            <LanguageSelector
              onLanguageChange={ onLanguageChange }
              i18n={ i18n }
              value={ formLanguage }
            />
            <SectionActions
              onAdd={ handleAddSection }
              onDuplicate={ () => handleDuplicate(id) }
              onDelete={ () => handleDelete(id) }
              t={ t }
            />
          </>
        )}
      <StyledAccordion
        expanded={ expandedSection === id }
        onChange={ () => handleAccordionChange(id) }
        elevation={ 0 }
      >
        <AccordionSummary expandIcon={ <StyledExpandMoreIcon expanded={ expandedSection === id } /> } aria-controls={ `panel${id}bh-content` } id={ id }>
          <div spacing={ 1 }>
            <StyledDragItem item xs={ 12 } onClick={ () => setShowItem(id) }>
              <StyledDragButton
                ref={ setActivatorNodeRef }
                { ...listeners }
                { ...attributes }
              >
                <DragIndicatorIcon />
              </StyledDragButton>
              <StyledNameContainer onClick={ handleInputClick }>
                <InputText
                  name={ `section_${formLanguage}_${id}` }
                  defaultValue={ data[`name_${formLanguage}`] }
                  control={ control }
                  variant={ VARIANT.outlined }
                  rules={ {
                    required: true,
                  } }
                  placeholder={ t("planning:templates.sections.section") }
                />
                <EditIcon />
              </StyledNameContainer>
            </StyledDragItem>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <StyledStack>
            {/* Stakeholders */}
            <StakeholdersSection
              { ...props }
              onEvaluationTypeChange={ handleTypeChange }
              defaultValue={ selectedEvaluationType }
              evaluationTypes={ PERFORMANCE_EVALUATION_TYPES }
              section={ data }
            />
            <StyledWeighingTitle open={ data.open }>
              {data.open
                ? t("planning:templates.sections.open")
                : (
                  <>
                    <p>{`${t("planning:templates.sections.assigned_weight")}:`}</p>
                    <InputNumberController
                      name={ `weighing_${selectedEvaluationType}_${id}` }
                      control={ control }
                      variant={ VARIANT.outlined }
                      isInteger
                      maxLength={ 3 }
                      isDisabled={ !formValues[`checkbox_${selectedEvaluationType}_${id}`] }
                    />
                    <span>{"%"}</span>
                  </>
                )}
            </StyledWeighingTitle>
          </StyledStack>
          {/* Questions */}
          {data?.questions && data.questions.map((question) => {
            if (question?.isDeleted) {
              return null;
            }
            const scaleIndices = data.open ? null : evaluationScales.find(
              (scale) => scale.id === question.evaluation_scale_id,
            );
            if (scaleIndices) {
              scaleIndices.indices = getScaleIndicesOrder(scaleIndices.indices);
            }

            return (
              <Question
                key={ question.id }
                questionId={ question.id }
                question={ question[`name_${formLanguage}`] }
                scale={ scaleIndices }
                scales={ evaluationScales }
                formLanguage={ formLanguage }
                control={ control }
                watch={ watch }
                open={ data.open }
                t={ t }
                handleAddNewQuestion={ handleAddNewQuestion }
                handleDuplicateQuestion={ () => handleDuplicateQuestion(question.id) }
                onDelete={ () => handleDeleteQuestion(question.id) }
                { ...props }
              />
            );
          })}
          <StyledNewQuestion>
            {showNewQuestionMessage ? (
              <AddSectionButton
                variant={ "outlined" }
                color={ "primary" }
                onClick={ handleAddNewQuestion }
              >
                <StyledBox direction={ DIRECTION.column }>
                  <StyledBox>
                    <AddCircleIcon />
                    {t("planning:templates.sections.new_question")}
                  </StyledBox>
                </StyledBox>
              </AddSectionButton>
            ) : (
              <AddSectionButton
                variant={ "outlined" }
                color={ "primary" }
                onClick={ handleAddNewQuestion }
                empty
              >
                <StyledBox direction={ DIRECTION.column }>
                  <StyledImage src={ OutBox } alt={ "new section" } />
                  {t("planning:templates.sections.empty_section")}
                  <StyledBox>
                    <AddCircleIcon />
                    {t("planning:templates.sections.new_question")}
                  </StyledBox>
                </StyledBox>
              </AddSectionButton>
            )}
          </StyledNewQuestion>
        </AccordionDetails>
      </StyledAccordion>
    </StyledItemContainer>
  );
};

Section.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name_en: PropTypes.string,
    name_es: PropTypes.string,
    name_pt: PropTypes.string,
    evaluations: PropTypes.any,
    questions: PropTypes.any,
    open: PropTypes.bool,
    isNew: PropTypes.bool,
  }).isRequired,
  setShowItem: PropTypes.func.isRequired,
  showItemId: PropTypes.string,
  onLanguageChange: PropTypes.func,
  i18n: PropTypes.any,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func,
  formLanguage: PropTypes.string,
  handleAccordionChange: PropTypes.func.isRequired,
  expandedSection: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  evaluationScales: PropTypes.any,
  setTemplateStates: PropTypes.func,
  formValues: PropTypes.any,
  setFormValues: PropTypes.func,
  handleAddSection: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDuplicate: PropTypes.func,
  templateData: PropTypes.object,
  setWatchedFields: PropTypes.func,
};

Section.defaultProps = {
  showItemId: null,
  onLanguageChange: () => {},
  evaluationScales: null,
  i18n: null,
  watch: null,
  formLanguage: null,
  expandedSection: null,
  setTemplateStates: () => {},
  formValues: null,
  setFormValues: () => {},
  handleAddSection: () => {},
  handleDelete: () => {},
  handleDuplicate: () => {},
  templateData: null,
  setWatchedFields: () => {},
};

export default Section;
