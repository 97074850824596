import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { isEqual } from "common/helpers";
import { INDEX } from "common/constants";
import { StyledTableHead, StyledSkeleton } from "../../styles";

const TableSkeletonLoader = (props) => {
  const { cols, rows } = props;

  return (
    <TableRow>
      <TableCell colSpan={ cols }>
        {[...Array(rows).keys()].map((row) => (
          <StyledTableHead key={ row } >
            {[...Array(cols).keys()].map((col, index) => (
              <StyledSkeleton key={ col } height={ 24 } width={ isEqual(index, INDEX.zero) ? "200%" : "100%" } />
            ))}
          </StyledTableHead>
        ))}
      </TableCell>
    </TableRow>
  );
};

TableSkeletonLoader.propTypes = {
  cols: PropTypes.number.isRequired,
  rows: PropTypes.number,
};

TableSkeletonLoader.defaultProps = {
  rows: 10,
};

export default TableSkeletonLoader;
