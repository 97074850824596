import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "components/Breadcrumbs";
import UploadDocument from "components/UploadDocument";
import AlertModal from "components/AlertModal";
import { BULK_UPLOAD, ONBOARDING_KEYS, ROLES } from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import {
  uploadUsers,
  getUploadStatus,
  resetState as resetUpload,
} from "redux/actions/bulkUploadActions";
import { resetState } from "redux/actions/candidateActions";
import Templates from "views/Templates";
import CardSection from "views/Onboarding/components/CardSection";
import { getModalData, getTimeout } from "views/BulkUpload/functions";
import { getDocumentFormat, getSectionItemFormat } from "views/Onboarding/functions";
import templateIcon from "assets/images/onboarding/template.svg";
import { excelHeaderByDefault, getDataBreadcrumbs } from "./functions";
import { useStyles, StyledTitle, StyledContent } from "./styles";

const OnboardingBulkUpload = () => {
  const classes = useStyles();
  const { t } = useTranslation(["bulkUpload", "Onboarding"]);
  const [allData, setAllData] = useState({});
  const [docs, setDocs] = useState([]);
  const [info, setInfo] = useState([]);
  const [sectionItems, setSectionItems] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);

  const openCloseModal = () => {
    setModal(!modal);
  };

  const dispatch = useDispatch();

  const {
    successProcess: uploadStatus,
    isLoadingProcess,
  } = useSelector(({ bulkUploadReducer }) => bulkUploadReducer);

  const sendData = () => {
    const data = {
      users: allData,
      type: ROLES.CANDIDATE,
      requested_documents_attributes: getDocumentFormat(docs, ONBOARDING_KEYS.documentTypeId),
      requested_infos_attributes: getDocumentFormat(info, ONBOARDING_KEYS.infoTypeId),
      requested_section_items_attributes: getSectionItemFormat(sectionItems, ONBOARDING_KEYS.sectionItemId),
    };
    dispatch(uploadUsers(data));
  };

  const getProcessStatus = useCallback(() => {
    if (!isLoadingProcess && uploadStatus?.aasm_state) {
      setModalData(
        getModalData(
          uploadStatus?.aasm_state,
          t,
          classes,
          uploadStatus?.response,
        ),
      );
      setModal(true);
      if (isEqual(uploadStatus?.aasm_state, BULK_UPLOAD?.state.pending)) {
        const timeout = getTimeout(allData.length);
        setTimeout(() => {
          dispatch(getUploadStatus(uploadStatus?.id));
        }, timeout);
      } else {
        dispatch(resetUpload());
      }
    }
  }, [dispatch, t, classes, allData, isLoadingProcess, uploadStatus]);

  useEffect(() => {
    if (!isEmpty(uploadStatus)) {
      getProcessStatus();
      dispatch(resetState());
    }
  }, [uploadStatus, getProcessStatus, dispatch]);

  return (
    <StyledContent data-testid={ "bulk-upload-onboarding" }>
      <Breadcrumbs data={ getDataBreadcrumbs(t) } />
      <StyledTitle>{ t("Onboarding:massiveTitle") }</StyledTitle>
      <CardSection
        title={ t("templates:select_template") }
        icon={ templateIcon }
      >
        <Templates
          setDocs={ setDocs }
          setInfo={ setInfo }
          setSectionItems={ setSectionItems }
        />
      </CardSection>
      <UploadDocument
        filename={ t("importTemplateOnboarding") }
        excelHeader={ excelHeaderByDefault(t) }
        sendData={ sendData }
        setAllData={ setAllData }
      />
      {modalData && (
        <AlertModal
          disableBackdropClick
          disableEscapeKeyDown
          title={ modalData.title }
          open={ modal }
          handleClose={ openCloseModal }
          isDisabledIcon={ modalData.isDisabledIcon }
        >
          {modalData.children}
        </AlertModal>
      )}
    </StyledContent>
  );
};

export default OnboardingBulkUpload;
