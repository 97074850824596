import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Button from "@mui/material/Button";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 32px;
`;

export const StyledContent = styled.div`
  margin-top: 16px;
`;

export const StyledCalendarContainer = styled(Card)`
  margin-top: 16px;
  padding: 24px;
`;

export const StyledCalendarGrid = styled(Grid)`
  padding: 12px;
`;

export const StyledTitle = styled(Typography)`
  padding-bottom: 25px;
  font-weight: 700;
  padding: 10px;
`;

export const StyledEvents = styled(Grid)`
  padding: 12px;
  border-left: ${palette.border.simpleLight};
  & img {
    width: 30px;
    height: 30px;
  }
`;

export const StyledFormContainer = styled(Card)`
  padding: 24px;
  & input, .MuiOutlinedInput-root, select {
    background: ${palette.white};
  }
`;

export const StyledProcessList = styled(Grid)`
  width: 100%;
  padding: 1px;
  & .swiper-button-next, .swiper-button-prev {
    top: 30% !important;
  }
  & > div {
    width: 100%;
  }
`;

export const StyledCardHorizontalContainer = styled.div`
  & .MuiPaper-root {
    margin: 10px;
  }
`;

export const StyledInputContainer = styled(Grid)`
  margin-bottom: 16px;
`;

export const StyledSubtitleForm = styled(Typography)`
  margin-bottom: 32px;
  & strong {
    padding-left: 4px;
  }
`;

export const StyledLabel = styled(Typography)`
  margin-bottom: 8px;
`;

export const StyledInputNumberContainer = styled.div`
  width: 100px;
`;

export const StyledActions = styled(Grid)`
  text-align: right;
`;

export const StyledButton = styled(Button)`
  text-transform: capitalize;
`;

export const StyledHeaderCalendar = styled(Grid)`
  padding: 0px 5px 0px 5px;
`;

export const StyledEventsTitle = styled(Typography)`
  padding: 0 10px 10px 10px;
`;

export const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  color: ${palette.background.empty};
  margin: 20px;
`;

export const StyledDivider = styled.hr`
  margin: 16px 0;
  border: ${palette.border.grayLight};
`;

export const StyledCalendarSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
`;

export const StyledTemplateTitle = styled(Typography)`
  color: ${palette.primaryApp};
  font-weight: 700;
`;
