import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import theme from "theme";
import Charts from "theme/charts";
import { hexToRgbA } from "common/utils";

export const StyledCardContainer = styled.div`
  margin-top: ${(props) => props.marginTop}px;
  @media (max-width: 600px) {
    padding: 10px 0;
  }
`;

export const StyledCard = styled(Card)`
  &.MuiPaper-root {
    background: ${(props) => hexToRgbA(props.color, Charts.opacity.fillOpacity)};
  }
  width: ${(props) => (props.isFullWidth ? "100%" : "95%")};
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 24px 16px 16px 16px !important;
`;

export const StyledIcon = styled.img`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  margin-right: 15px;
`;

export const StyledText = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${(props) => props.color || theme.palette.text.secondaryTitle};
    font-weight: ${(props) => (props.isBold ? "bold" : "normal") };
  }
`;

export const StyledContent = styled.div`
  .MuiTypography-h5 {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 5px;
  }
`;
