import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import QueryString from "query-string";
import Button from "components/Button";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES,
  BUTTON_TYPE,
  MODE_ON_CHANGE_FORMS,
  LOCATION_PROPS,
  INDEX,
} from "common/constants";
import paths from "common/paths";
import { getPropertyByLocation } from "common/utils";
import Box from "@mui/material/Box";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import useCreatePasswordService from "hooks/auth/useCreatePasswordService";
import useRecoverPasswordService from "hooks/auth/useRecoverPasswordService";
import EmailRequestForm from "./components/EmailRequestForm";
import CreatePassword from "../SignIn/components/CreatePassword";

const RecoverPassword = (props) => {
  const { t } = useTranslation(["common", "authentication", "formsValidation"]);
  const {
    control, handleSubmit, formState, errors, watch,
  } = useForm({ mode: MODE_ON_CHANGE_FORMS });
  const { location } = props;
  const {
    reset_password_token: resetPasswordToken, "access-token": acessToken, client, expiry, uid,
  } = QueryString.parse(location?.search);

  const locationParam = useLocation();
  const locationSearch = getPropertyByLocation(locationParam, LOCATION_PROPS.search);

  const invalidToken = locationSearch?.split(paths.invalidToken)[INDEX.one];
  const expiredToken = locationSearch?.split(paths.expiredToken)[INDEX.one];
  let toastMessage = {};
  if (invalidToken) {
    toastMessage = {
      title: t("common:common.api_responses.error.title"),
      message: t("authentication:invalid_token_password_created"),
    };
  } else if (expiredToken) {
    toastMessage = {
      title: t("common:common.api_responses.error.title"),
      message: t("authentication:token_expired"),
    };
  }

  const {
    validateToken,
    isLoadingTokenValidation,
    createPasswordPost,
    isLoadingPasswordCreation,
  } = useCreatePasswordService();

  const {
    sendEmailRecoverPasswordPost,
    isLoadingPasswordRecovery,
  } = useRecoverPasswordService();

  const onSubmit = (formData) => {
    if (acessToken) {
      formData.reset_password_token = resetPasswordToken;
      formData.expiry = expiry;
      formData.accessToken = acessToken;
      formData.client = client;
      formData.uid = uid;
      createPasswordPost(formData);
    } else {
      sendEmailRecoverPasswordPost(formData);
    }
  };

  useEffect(() => {
    if (resetPasswordToken) {
      validateToken({ reset_password_token: resetPasswordToken });
    }
  }, [resetPasswordToken, validateToken]);

  const typeStyleButton = formState.isValid
    ? BUTTON_STYLE_TYPES.SUBMIT
    : BUTTON_STYLE_TYPES.DISABLED;

  const [hasError, setHasError] = useState(false);

  const setErrors = useCallback(() => {
    if (invalidToken || expiredToken) {
      toast(MESSAGE_TYPES.error, toastMessage);
    }
    setHasError(true);
  }, [setHasError, invalidToken, expiredToken, toastMessage]);

  useEffect(() => {
    if (!hasError) {
      setErrors();
    }
  }, [hasError, setErrors]);

  return (
    <div
      data-testid={ "recover-password-view" }
    >
      <ViewHeaderTitle
        title={ t("authentication:recover_password.recover_password") }
        subtitle={ t("authentication:welcome") }
      />

      <form onSubmit={ handleSubmit(onSubmit) }>
        <Box mb={ 4 }>
          {acessToken ? (
            <CreatePassword
              control={ control }
              errors={ errors }
              watch={ watch }
            />
          ) : (
            <EmailRequestForm control={ control } errors={ errors } />
          )}
        </Box>

        <Button
          type={ BUTTON_TYPE.submit }
          typeStyle={ typeStyleButton }
          isDisabled={ !formState.isValid }
          isFullWidth
          isLoading={ isLoadingTokenValidation
                      || isLoadingPasswordCreation
                      || isLoadingPasswordRecovery }
        >
          {t(`authentication:recover_password.${acessToken ? "save_changes" : "send_instructions"}`)}
        </Button>
      </form>
    </div>
  );
};

export default RecoverPassword;
