import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import clsx from "clsx";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import MenuItem from "@mui/material/MenuItem";
import Button from "components/Button";
import { LANGUAGES, LANGUAGES_NAMES } from "common/constants";
import { isEqual } from "common/helpers";
import {
  useStyles, StyledContainer, StyledFormControl, StyledSelect,
} from "./styles";

const ChangeLanguageSelect = (props) => {
  const { isCustomIcon } = props;
  const classes = useStyles();
  const [openSelect, setOpenSelect] = useState(false);
  const [cookies, setCookie] = useCookies(["language"]);
  const [language, setLanguage] = useState(null);
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    setCookie("language", event.target.value);
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleOpenClose = () => {
    setOpenSelect(!openSelect);
  };

  const isBlueTheme = isCustomIcon && classes.blueTheme;

  const setValidPtLanguage = useCallback(() => {
    setLanguage(LANGUAGES.pt);
    setCookie("language", LANGUAGES.pt);
  }, [setCookie]);

  useEffect(() => {
    if (cookies.language) {
      if (isEqual("pr", cookies.language)) {
        setValidPtLanguage();
      } else {
        setLanguage(cookies.language);
      }
    } else {
      setLanguage(LANGUAGES.es);
    }
    // eslint-disable-next-line
  }, [cookies]);

  return (
    <StyledContainer data-testid={ "change-language-select" }>
      <Button onClick={ handleOpenClose }>
        <GTranslateIcon className={ clsx(classes.icon, isBlueTheme) } />
      </Button>
      <StyledFormControl>
        <StyledSelect
          custom={ isCustomIcon }
          open={ openSelect }
          onClose={ handleOpenClose }
          onOpen={ handleOpenClose }
          value={ language }
          onChange={ handleChange }
          inputProps={ {
            classes: {
              icon: clsx(classes.icon, isBlueTheme),
            },
          } }
        >
          <MenuItem value={ LANGUAGES.es }>{LANGUAGES_NAMES.spanish}</MenuItem>
          <MenuItem value={ LANGUAGES.en }>{LANGUAGES_NAMES.english}</MenuItem>
          <MenuItem value={ LANGUAGES.pt }>{LANGUAGES_NAMES.portuguese}</MenuItem>
        </StyledSelect>
      </StyledFormControl>
    </StyledContainer>
  );
};

ChangeLanguageSelect.propTypes = {
  isCustomIcon: PropTypes.bool,
};

ChangeLanguageSelect.defaultProps = {
  isCustomIcon: false,
};

export default ChangeLanguageSelect;
