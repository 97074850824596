export const FILTER_ACTIONS_TYPES = {
  collaborators: "collaborators",
  ninebox: "ninebox",
  talentDrain: "talentDrain",
  potentialCollaborators: "potentialCollaborators",
  attritionCollaborator: "attritionCollaborator",
  orgChart: "orgChart",
  timeOff: "timeOff",
  personalTimeOff: "personalTimeOff",
  goodLeader: "goodLeader",
  engagement: "engagement",
  nalaAnalytics: "nalaAnalytics",
  noActions: "noActions",
  performance: "performance",
  goals: "goals",
  positions: "positions",
};

export const ENABLE_STATES_COLLABORATOR_FILTER = [
  FILTER_ACTIONS_TYPES.collaborators,
  FILTER_ACTIONS_TYPES.potentialCollaborators,
  FILTER_ACTIONS_TYPES.performance,
  FILTER_ACTIONS_TYPES.goodLeader,
  FILTER_ACTIONS_TYPES.talentDrain,
  FILTER_ACTIONS_TYPES.noActions,
  FILTER_ACTIONS_TYPES.goals,
];
