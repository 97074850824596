import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { VARIANT } from "common/constants";
import { ReactComponent as AppliedFiltersIcon } from "assets/images/talent-drain/applied_filters.svg";
import FilterOption from "../FilterOption";
import { StyledAppliedFilter } from "../../styles";

const AppliedFilters = (props) => {
  const { filterOptions, handleDelete, filterName } = props;
  const { t } = useTranslation("talentDrain");
  return (
    <Box data-testid={ "applied-filters-component-view" }>
      <Hidden smDown>
        <StyledAppliedFilter>
          <AppliedFiltersIcon />
          <Typography variant={ VARIANT.h5 }>{t("filters.appliedFilters")}</Typography>
        </StyledAppliedFilter>
      </Hidden>
      <Grid container data-testid={ "filter-list" }>
        {filterOptions.map((filter) => (
          <Grid key={ filter.title } item xs={ 12 } sm={ 3 } md={ 12 }>
            <FilterOption
              { ...filter }
              handleDelete={ handleDelete }
              filterName={ filterName }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

AppliedFilters.propTypes = {
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    options: PropTypes.array,
  })).isRequired,
  handleDelete: PropTypes.func.isRequired,
  filterName: PropTypes.array.isRequired,
};

export default AppliedFilters;
