import { historyPush } from "common/utils";
import localRoutes from "../../views_refactor/Profile/functions/routes";

/**
 * Generates the path for retrieving a survey process based on the employee ID and process ID.
 * @param {string} employeeId - The ID of the employee.
 * @param {string} processId - The ID of the process.
 * @returns {string} - The generated path.
 */
export const getGoodLeaderPath = (employeeId, processId) => {
  const process = processId ? `&goodLeader=${processId}` : "";
  return `?collaborator=${employeeId}${process}`;
};

export const getPerformancePath = (employeeId, processId) => {
  const process = processId ? `&process=${processId}` : "";
  return `?collaborator=${employeeId}${process}`;
};

export const getPotentialPath = (employeeId, processId) => {
  const process = processId ? `&process=${processId}` : "";
  return `?collaborator=${employeeId}${process}`;
};

/**
 * Redirects to the process profile (simple profile or modal result) view
 * with the appropriate path.
 * @param {object} history - The history object from react-router.
 * @param {string} employeeId - The ID of the employee.
 * @param {string} processId - The ID of the process.
 */
export const viewLeaderProfile = (history, employeeId, processId) => {
  historyPush(
    history,
    localRoutes.goodLeader,
    getGoodLeaderPath(employeeId, processId),
  );
};

export const viewPerformanceProfile = (history, employeeId, processId) => {
  historyPush(
    history,
    localRoutes.performance,
    getPerformancePath(employeeId, processId),
  );
};

export const viewPotentialProfile = (history, employeeId, processId) => {
  historyPush(
    history,
    localRoutes.skills,
    getPotentialPath(employeeId, processId),
  );
};
