import PropTypes from "prop-types";
import { HtmlTooltip } from "components/CustomChartTooltip/styles";
import { formatDate } from "common/utils";
import { VARIANT, DATE } from "common/constants";
import { isEqual, isNotValid } from "common/helpers";
import { getStepIcon } from "views/Account/functions/salary";
import { stepContentTitle } from "views_refactor/Profile/functions/movements";
import {
  StyledContainer, StyledIconContainer, StyledIcon, StyledDate, StyledDetail,
} from "./styles";

const LabelTimeline = (props) => {
  const { t, data, notIncludeSalary } = props;
  return (
    <StyledContainer>
      <HtmlTooltip
        title={ stepContentTitle(t, notIncludeSalary).map((content) => !isNotValid(data[content.element])
          && (
            <StyledDetail variant={ VARIANT.bodyOne }>
              <span>{`${content.title}: `}</span>
              {isEqual(content.element, DATE) ? formatDate(data[content.element]) : data[content.element]}
            </StyledDetail>
          )) }
        arrow
      >
        <div>
          <StyledIconContainer>
            <StyledIcon>{getStepIcon(data.type)}</StyledIcon>
            <p>{t(`profile:${data.type}`)}</p>
          </StyledIconContainer>
          <StyledDate>{ formatDate(data.date)}</StyledDate>
        </div>
      </HtmlTooltip>
    </StyledContainer>
  );
};

LabelTimeline.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  notIncludeSalary: PropTypes.bool.isRequired,
};

export default LabelTimeline;
