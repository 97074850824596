import styled from "styled-components";
import palette from "theme/palette";

export const StyledEvaluationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const StyledImage = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 12px;
`;

export const StyledDashboardContainer = styled.div`
  padding: 32px;
  @media (max-width: 960px) {
    padding: 16px 12px;
    font-family: "Lato";
    background: ${ palette.white };
    min-height: 100%;
  }
`;

export const StyledMember = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-body1 {
    font-size: 16px;
    margin-left: 12px;
  }
  .MuiAvatar-root, .MuiSvgIcon-root.MuiAvatar-fallback {
    width: 40px;
    height: 40px;
  }
`;

export const StyledEvaluated = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
`;
