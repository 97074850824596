import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import InputEditor from "components/InputEditor";
import Button from "components/Button";
import { BUTTON_STYLE_TYPES, BUTTON_TYPE } from "common/constants";
import { Actions, Container, Content } from "./styles";

const FeedbackStartStopContinue = (props) => {
  const {
    t,
    feedbackStates,
    hookFeedbackForm,
    feedbackProcess,
    validateInputSSCEdit,
  } = props;
  const { isDisabledFeedbackSSC } = feedbackStates;
  const { control, isValid } = hookFeedbackForm;
  const { isLoadingProcess } = feedbackProcess;

  return (
    <Container container data-testid={ "feedback-start-stop-continue-view" }>
      <Content item xs={ 12 }>
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputSSCEdit } /> }
          name={ "feedback_start_stop_continue.should_stop_do" }
          control={ control }
          label={ t("feedback:should_stop_do") }
        />
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputSSCEdit } /> }
          name={ "feedback_start_stop_continue.should_start_do" }
          control={ control }
          label={ t("feedback:should_start_do") }
        />
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputSSCEdit } /> }
          name={ "feedback_start_stop_continue.should_continue_do" }
          control={ control }
          label={ t("feedback:should_continue_do") }
        />
      </Content>
      <Actions item xs={ 12 } >
        <Button
          type={ BUTTON_TYPE.submit }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          isDisabled={ !isValid || isDisabledFeedbackSSC }
          isLoading={ isLoadingProcess }
        >
          {t("feedback:send_feedback")}
        </Button>
      </Actions>
    </Container>
  );
};

FeedbackStartStopContinue.propTypes = {
  t: PropTypes.func.isRequired,
  feedbackStates: PropTypes.object.isRequired,
  hookFeedbackForm: PropTypes.object.isRequired,
  feedbackProcess: PropTypes.object.isRequired,
  validateInputSSCEdit: PropTypes.func.isRequired,
};

export default FeedbackStartStopContinue;
