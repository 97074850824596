import parse from "html-react-parser";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SimpleAccordion from "components/SimpleAccordion";
import NoDataMessage from "components/NoDataMessage";
import Button from "components/Button";
import CircleLoader from "components_refactor/CircleLoader";
import {
  BUTTON_STYLE_TYPES, INDEX, TYPES, VARIANT,
} from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import {
  formatDate, getAcknowledgementIcon, getCurrentLanguage, getParseValue, replacePath,
} from "common/utils";
import { getFullDateFormatStandard } from "views/Account/components/Feedback/functions";
import {
  EMPLOYEE_TYPE, FEEDBACKABLES, FEEDBACK_TYPE, LIMIT_PAGINATE_INDEX,
} from "views_refactor/Profile/functions";
import NalaBot from "assets/images/general/nala_bot_normal.svg";
import CardMessage from "./components/CardMessage";
import { SSCMessageType } from "./components/CardMessage/styledComponents";
import localRoutes from "../../../../functions/routes";
import {
  StyledFeedbackListContainer,
  StyledAccordionContainer,
  StyledFixedContainer,
  StyledAcknowledgementElement,
  StyledAcknowledgementContainer,
  StyledLink,
  StyledMessage,
} from "../../styles";

const FeedbackList = (props) => {
  const {
    t,
    history,
    isOwnProfile,
    data,
    acknowledgementTypesList,
    viewType,
    isLoading,
    feedbackStates,
    showAsCards,
    getIconFeedback,
    handleOnClickViewLessOrMore,
  } = props;

  const { isShowAllData } = feedbackStates;

  const feedbackMessage = (feedback) => (isEqual(feedback.feedbackable_type, FEEDBACKABLES.feedbackStartStopContinue)
    ? (
      <>
        <Grid item xs={ 12 }>
          <SSCMessageType>
            <Typography variant={ VARIANT.h6 }>{t("feedback:should_stop_do")}</Typography>
            <Typography variant={ VARIANT.bodyOne }>{getParseValue(feedback.feedbackable.should_stop_do)}</Typography>
          </SSCMessageType>
        </Grid>
        <Grid item xs={ 12 }>
          <SSCMessageType>
            <Typography variant={ VARIANT.h6 }>{t("feedback:should_start_do")}</Typography>
            <Typography variant={ VARIANT.bodyOne }>{getParseValue(feedback.feedbackable.should_start_do)}</Typography>
          </SSCMessageType>
        </Grid>
        <Grid item xs={ 12 }>
          <SSCMessageType>
            <Typography variant={ VARIANT.h6 }>{t("feedback:should_continue_do")}</Typography>
            <Typography variant={ VARIANT.bodyOne }>{getParseValue(feedback.feedbackable.should_continue_do)}</Typography>
          </SSCMessageType>
        </Grid>
      </>
    )
    : getParseValue(feedback.feedbackable.message));

  const collapseView = data && (
    <>
      {data?.map((item, index) => {
        const acknowledgementElement = getAcknowledgementIcon(
          item.feedbackable.acknowledgement_type_id,
          acknowledgementTypesList,
        );
        if (index <= INDEX.two) {
          return (
            <SimpleAccordion
              key={ item.id }
              title={ (
                <StyledAccordionContainer>
                  <span>{`${item[`employee_${viewType}_full_name`]}`}</span>
                  <p>
                    {formatDate(item.feedbackable.created_at)}
                  </p>
                </StyledAccordionContainer>
              ) }
              avatar={ (
                <Avatar
                  alt={ `${item[`employee_${viewType}_full_name`]}` }
                  src={ `${item[`employee_${viewType}_profile_img_url`]}` }
                />
              ) }
            >
              <Grid item xs={ 12 }>
                <StyledAcknowledgementContainer>
                  {acknowledgementElement && (
                    <StyledAcknowledgementElement>
                      <img
                        src={ acknowledgementElement.external_img }
                        alt={ acknowledgementElement.title }
                      />
                      <span>{acknowledgementElement[`title_${getCurrentLanguage()}`]}</span>
                    </StyledAcknowledgementElement>
                  )}
                  <StyledMessage>{ feedbackMessage(item) }</StyledMessage>
                </StyledAcknowledgementContainer>
              </Grid>
            </SimpleAccordion>
          );
        }
        return null;
      })}
      {isEmpty(data) ? (
        <NoDataMessage
          iconImage={ NalaBot }
          message={ (
            <>
              { isEqual(viewType, FEEDBACK_TYPE.from) && t(`feedback:no_data_from${isOwnProfile ? "" : "_for_manager"}`)}
              <StyledLink onClick={ () => replacePath(history, localRoutes.feedback) } isCenter>
                { t("feedback:send_feedback_or_acknowledgement") }
              </StyledLink>
            </>
          ) }
        />
      ) : (
        <StyledFixedContainer>
          <StyledLink onClick={ () => replacePath(history, localRoutes.feedback) }>
            { t("common:common.viewMore") }
          </StyledLink>
        </StyledFixedContainer>
      )}
    </>
  );

  const ListCards = ({ dataList }) => dataList.map((feedback, index) => {
    const type = feedback.feedbackable_type;
    const employeeToShow = isEqual(viewType, FEEDBACK_TYPE.to) ? EMPLOYEE_TYPE.sender : EMPLOYEE_TYPE.receiver;
    const createdAtFormatted = getFullDateFormatStandard(feedback.feedbackable.created_at);
    return (
      <CardMessage
        t={ t }
        name={ feedback[`${employeeToShow}_full_name`] }
        imageUrl={ feedback[`${employeeToShow}_profile_img_url`] }
        imageThumbnailUrl={ feedback[`${employeeToShow}_profile_img_url_thumb`] }
        message={ feedback.feedbackable.message ? parse(feedback.feedbackable.message) : "" }
        date={ createdAtFormatted }
        type={ type }
        sscData={ feedback.feedbackable }
        acknowledgementElement={ getAcknowledgementIcon(feedback.feedbackable.acknowledgement_type_id, acknowledgementTypesList) }
        // eslint-disable-next-line react/no-unstable-nested-components
        TypeImage={ () => (
          <img src={ getIconFeedback(type) } alt={ type } />
        ) }
        key={ `${feedback.employee_from_full_name}-${index}-${type}-${feedback.feedbackable.created_at}` }
      />
    );
  });

  const cardsView = isEmpty(data) ? (
    <NoDataMessage />
  ) : (
    <>
      <ListCards dataList={ isShowAllData
        ? data
        : data?.slice(LIMIT_PAGINATE_INDEX.start, LIMIT_PAGINATE_INDEX.end) }
      />
      {data?.length > 5 && (
        <Button
          variant={ BUTTON_STYLE_TYPES.OUTLINED }
          type={ TYPES.text }
          onClick={ handleOnClickViewLessOrMore }
          isFullWidth
        >
          {t(isShowAllData ? "feedback:view_less" : "feedback:view_more")}
        </Button>
      )}
    </>
  );

  const showCardsOrCollapse = showAsCards ? cardsView : collapseView;

  return (
    <StyledFeedbackListContainer isCollapse={ !showAsCards }>
      { isLoading ? (
        <CircleLoader />
      ) : (data && showCardsOrCollapse) }
    </StyledFeedbackListContainer>
  );
};

FeedbackList.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  viewType: PropTypes.string.isRequired,
  feedbackStates: PropTypes.object.isRequired,
  getIconFeedback: PropTypes.func.isRequired,
  handleOnClickViewLessOrMore: PropTypes.func.isRequired,
  acknowledgementTypesList: PropTypes.array.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  showAsCards: PropTypes.bool,
};

FeedbackList.defaultProps = {
  isLoading: false,
  showAsCards: false,
};

export default FeedbackList;
