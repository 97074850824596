import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import InputText from "components/InputText";
import FormHelperText from "components/FormHelperText";
import LoaderInput from "components/LoaderInput";
import { AUTOCOMPLETE, KEY_PRESS, SIZE } from "common/constants";
import { useStyles, StyledIconRemove } from "./styles";

const filter = createFilterOptions();

const AutoCompleteCreable = (props) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const {
    id,
    label,
    disabled,
    onChange,
    value,
    register,
    name,
    options,
    loading,
    nameOfAttr,
    isMarginNormal,
    isRequired,
    isFreeSolo = false,
    customStyles,
    isWithHelperText = true,
    addNew,
    hasError,
    checkIsValid,
    renderOption,
    size,
  } = props;

  const [inputValue, setInputValue] = useState("");

  const handleChange = (prop) => (event, newValue) => {
    onChange && onChange(prop, event, newValue);
    checkIsValid && checkIsValid(id, newValue ? true : false);
  };

  const getOption = (option) => {
    return typeof option === "object" ? option[nameOfAttr] : option;
  };

  const filterOptions = (filterOpt, params) => filter(filterOpt, params);

  const getInputEnterValidation = (e) => {
    return e.key === KEY_PRESS.enter && !isEmpty(inputValue) && addNew
      ? addNew.handleNew(inputValue, addNew.prop)
      : {};
  };

  const renderInput = (params) => (
    <>
      <InputText
        params={ { ...params } }
        label={ label }
        name={ name }
        inputRef={ register }
        inputProps={ {
          ...params.inputProps,
          form: {
            autoComplete: AUTOCOMPLETE.off,
          },
        } }
        InputProps={ {
          ...params.InputProps,
          endAdornment: (
            <StyledIconRemove>
              {loading ? (
                <LoaderInput />
              ) : null}
              {params.InputProps.endAdornment}
            </StyledIconRemove>
          ),
        } }
        isMarginNormal={ isMarginNormal }
        customStyles={ customStyles }
        onKeyUp={ (e) => getInputEnterValidation(e) }
        size={ size }
      />
      {!disabled && !hasError && isRequired && isWithHelperText && <FormHelperText />}
    </>
  );

  const getOptionSelected = (option, optValue) => {
    if (isString(option) && isString(optValue)) {
      return optValue === option;
    }
    if (isString(option) && !isString(optValue)) {
      return optValue[nameOfAttr] === option;
    }
    if (!isString(option) && isString(optValue)) {
      return option[nameOfAttr] === optValue;
    }
    return optValue?.[nameOfAttr] === option?.[nameOfAttr];
  };

  const addButton = ({ children, ...other }) => (
    <Paper { ...other }>
      {children}
      {!isEmpty(inputValue) && addNew && (
        <p className={ classes.addNew } onMouseDown={ () => addNew.handleNew(inputValue, addNew?.prop) }>
          {addNew.text}
        </p>
      )}
    </Paper>
  );

  const handleInputTextChange = (text) => {
    setInputValue(text);
    // if (!isEmpty(inputValue)) {
    //   if (addNew) {
    //     addNew.onInputTextChange(text);
    //   }
    // }
  };

  return (
    <Autocomplete
      data-testid={ "autocompleteCreable" }
      value={ value }
      id={ id }
      options={ options }
      disabled={ disabled || loading }
      loading={ loading }
      loadingText={ `${t("common.loading")}...` }
      onChange={ handleChange(id) }
      inputValue={ inputValue }
      onInputChange={ (event, newInputValue) => {
        handleInputTextChange(newInputValue);
      } }
      filterOptions={ filterOptions }
      getOptionLabel={ getOption }
      renderInput={ renderInput }
      renderOption={ renderOption }
      isOptionEqualToValue={ getOptionSelected }
      selectOnFocus
      handleHomeEndKeys
      freeSolo={ isFreeSolo }
      PaperComponent={ addButton }
      size={ size || SIZE.medium }
    />
  );
};

AutoCompleteCreable.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  register: PropTypes.func,
  name: PropTypes.string,
  nameOfAttr: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  isMarginNormal: PropTypes.bool,
  isFreeSolo: PropTypes.bool,
  isRequired: PropTypes.bool,
  customStyles: PropTypes.string,
  isWithHelperText: PropTypes.bool,
  addNew: PropTypes.object,
  hasError: PropTypes.bool,
  checkIsValid: PropTypes.func,
  renderOption: PropTypes.func,
  size: PropTypes.string,
};

export default AutoCompleteCreable;
