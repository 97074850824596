import styled from "styled-components";

export const StyledContainer = styled.div`
  .MuiSelect-selectMenu {
    width: 100px;
  }
  .MuiInputBase-input {
    padding: 10px;
  }
`;
