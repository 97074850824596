import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { getCollaboratorLightInfoPaginated } from "redux/actions/collaboratorActions";

const useCollaboratorList = (employeeInfo, shouldRefetch, handleCollaboratorError) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchCollaborators = (
    { pageParam = 1 },
  ) => getCollaboratorLightInfoPaginated(employeeInfo?.audience_id, pageParam);

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch: refetchCollaborators,
    error,
  } = useInfiniteQuery(
    ["collaboratorList", employeeInfo?.audience_id || "all"],
    fetchCollaborators,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      retry: false,
      onError: handleCollaboratorError,
      enabled: !shouldRefetch,
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.reduce((acc, page) => acc + page.collaborators.length, 0);
        return totalFetched < lastPage.total ? lastPage.page + 1 : undefined;
      },
    },
  );

  useEffect(() => {
    if (isInitialLoad && !isLoading) {
      setIsInitialLoad(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isInitialLoad && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, isInitialLoad, isFetchingNextPage]);

  // Flatten the paginated data
  const collaboratorList = data?.pages.flatMap((page) => page.collaborators) || [];

  const isLoadingCollaborators = isLoading && isInitialLoad;
  const isFetching = isFetchingNextPage && !isInitialLoad;

  return {
    collaboratorList,
    isLoadingCollaborators,
    isFetching,
    refetchCollaborators,
    collaboratorError: error,
  };
};

export default useCollaboratorList;
