import { NINEBOX, TYPES_RESULTS, CATEGORY_PROCESS } from "common/constants";
import { getKeyByValue } from "common/utils";
import Talented from "assets/images/potential/skills/talented.svg";
import Developed from "assets/images/potential/skills/developed.svg";
import ToDevelop from "assets/images/potential/skills/to_develop.svg";

export const INDEX_TABS_SKILLS = {
  skills: 0,
  personality: 1,
};

const sectionIcons = {
  [TYPES_RESULTS.skillProp.talented]: Talented,
  [TYPES_RESULTS.skillProp.develop]: Developed,
  [TYPES_RESULTS.skillProp.toDevelop]: ToDevelop,
};

export const getTabs = (t, hasPersonalitySection) => {
  const skillTabs = [{ label: t("profile:modals.skills.skills.title") }];
  if (hasPersonalitySection) {
    skillTabs.push({ label: t("profile:modals.skills.personality.title") });
  }
  return skillTabs;
};

export const locationPath = "skills";

const getFullInformationByDefault = (dataToUse) => ({
  archetype: dataToUse.archetype || dataToUse.archetype_es,
  description: dataToUse.general_description || dataToUse.general_description_es,
  info: {
    energizes: dataToUse.what_energizes || dataToUse.what_energizes_es,
    drains: dataToUse.what_drains || dataToUse.what_drains_es,
  },
});

export const getFullInformation = (data) => {
  let fullData = {
    archetype: "",
    description: "",
    info: {
      energizes: "",
      drains: "",
    },
  };

  if (data?.length > 0) {
    const dataToUse = data[0];
    fullData = getFullInformationByDefault(dataToUse);
  }
  return fullData;
};

export const isActiveDataResult = (personality) => {
  const { personalityData, isLoadingListPersonality } = personality;
  return personalityData !== null && !isLoadingListPersonality;
};

export const getPersonalityData = (data) => ({
  decision: data[0].decision,
  interaction: data[0].interaction,
  compliance: data[0].compliance,
  serenity: data[0].serenity,
});

export const getDefinitionResult = (data) => {
  let dataToUse = {};
  if (data !== undefined && data?.length > 0) {
    dataToUse = getPersonalityData(data);
  }
  return dataToUse;
};

export const skillLevels = [
  {
    key: TYPES_RESULTS.large,
    name: CATEGORY_PROCESS.high,
  },
  {
    key: TYPES_RESULTS.balanced,
    name: CATEGORY_PROCESS.medium,
  },
  {
    key: TYPES_RESULTS.deep,
    name: CATEGORY_PROCESS.low,
  },
];

export const skillTypeKeys = [
  {
    id: TYPES_RESULTS.skillKeys.mental,
    value: TYPES_RESULTS.skills.mental,
  },
  {
    id: TYPES_RESULTS.skillKeys.interpersonal,
    value: TYPES_RESULTS.skills.interpersonal,
  },
  {
    id: TYPES_RESULTS.skillKeys.change,
    value: TYPES_RESULTS.skills.change,
  },
  {
    id: TYPES_RESULTS.skillKeys.results,
    value: TYPES_RESULTS.skills.results,
  },
  {
    id: TYPES_RESULTS.skillKeys.awareness,
    value: TYPES_RESULTS.skills.awareness,
  }];

export const skillType = [
  TYPES_RESULTS.skills.mental,
  TYPES_RESULTS.skills.interpersonal,
  TYPES_RESULTS.skills.change,
  TYPES_RESULTS.skills.results,
  TYPES_RESULTS.skills.awareness,
];

export const getLastResult = (data) => data.filter((item) => item.evaluations?.length > 0);

export const isLoadingOrEmpty = (isLoading, data) => isLoading || data?.length === 0;

export const hasDataNineBox = (values) => values.nineBoxResult?.length > 0
&& values.nineBoxResult
&& values.nineBoxResult[0]?.results[0];

const getCategoryType = (category) => {
  if (category === CATEGORY_PROCESS.high
  ) {
    return TYPES_RESULTS.skillProp.talented;
  }
  if (category === CATEGORY_PROCESS.medium) {
    return TYPES_RESULTS.skillProp.develop;
  }
  return TYPES_RESULTS.skillProp.toDevelop;
};

export const setSkillCategoryAndTip = (category, item) => {
  item.category = getCategoryType(category);
  item.enableTip = category === CATEGORY_PROCESS.low;
};

export const getKeyByQuadrant = (quadrantIndex) => getKeyByValue(NINEBOX.quadrantIndex, quadrantIndex);

/**
 * @param {Object} t - i18n function
 * @param {Array} completeResultsBySection - Array of results by section
 * @param {Boolean} isOwnProfile - Flag to know if is own profile
 * @returns {Array} - Array of results by section
 * @description - This function returns an array of results by section
 * filtered by section that contain results
 * to be used in the SkillCard component
 */
export const getResultsBySection = (t, completeResultsBySection, isOwnProfile) => {
  const resultsBySection = Object.values(TYPES_RESULTS.skillProp).map((item) => {
    const resultsByCategory = completeResultsBySection?.filter(
      (result) => result.category === item,
    );
    return {
      title: item !== TYPES_RESULTS.skillProp.develop
        ? t(`profile:modals.skills.skills.categories.${item}${ isOwnProfile ? "" : "ForManager" }`)
        : t(`profile:modals.skills.skills.categories.${item}`),
      icon: sectionIcons[item],
      skills: resultsByCategory,
    };
  }).filter((item) => item.skills.length > 0);

  return resultsBySection;
};
