import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import {
  AWS_ACCESS_KEY_ID, AWS_BUCKET, AWS_REGION, AWS_SECRET_ACCESS_KEY,
} from "common/constants";

const clientParams = {
  region: AWS_REGION,
  credentials: {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  },
};

const getObjectParams = (key) => ({
  Bucket: AWS_BUCKET,
  Key: key,
});

const client = new S3Client(clientParams);

export const getImageURL = async (key) => {
  const command = new GetObjectCommand(getObjectParams(key));
  const url = await getSignedUrl(client, command, { expiresIn: 3600 });
  return url;
};
