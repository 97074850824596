import styled from "styled-components";
import palette from "theme/palette";

export const StyledAttritionProbability = styled.div`
  padding: ${(props) => (props.hasBorder ? "18px" : "18px 0")};
  margin-bottom: 20px;
  box-shadow: ${(props) => (props.hasBorder ? palette.boxShadow.radioIcon : "none")};
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPercent = styled.div`
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 450px) {
    font-size: 30px;
  }
`;

export const StyledRisk = styled.div`
  margin-left: 15px;
  .MuiTypography-h4 {
    color: ${palette.text.grayDark};
    font-weight: 700;
    margin-bottom: 4px;
  }
  .MuiLinearProgress-root {
    height: 15px;
    width: auto;
    @media (max-width: 450px) {
      height: 10px;
    }
  }
  .MuiLinearProgress-colorPrimary {
    background-color: transparent;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.color};
    border-radius: 0 8px 8px 0;
  }
`;
