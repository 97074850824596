import styled from "styled-components";
import Typography from "@mui/material/Typography";
import theme from "theme/palette";

export const StyledEditor = styled.div`
  .ace_editor {
    border: ${theme.border.grayLight};
    border-radius: 4px;
  }
  .ace-tm .ace_gutter, .ace_gutter-active-line {
    background: ${theme.background.editor};
    color: ${theme.white};
  }
  .ace_comment {
    color: ${theme.background.mediumGrey};
  }
  .ace_content {
    color: ${theme.input.borderControl};
  }
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 5px;
`;
