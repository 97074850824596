export const RESET_STATE = "RESET_STATE_HISTORICAL_MOVEMENT";
export const GET_LIST = "GET_LIST_HISTORICAL_MOVEMENT";
export const GET_LIST_LOADING = "GET_LIST_LOADING_HISTORICAL_MOVEMENT";
export const GET_LIST_ERROR = "GET_LIST_ERROR_HISTORICAL_MOVEMENT";
export const RESET_STATE_PROCESS = "RESET_STATE_PROCESS_MOVEMENT";
export const PROCCESS_HISTORICAL_MOVEMENT = "PROCCESS_HISTORICAL_MOVEMENT";
export const PROCCESS_HISTORICAL_MOVEMENT_LOADING = "PROCCESS_HISTORICAL_MOVEMENT_LOADING";
export const PROCCESS_HISTORICAL_MOVEMENT_ERROR = "PROCCESS_HISTORICAL_MOVEMENT_ERROR";

export const RESET_STATE_TO_DOWNLOAD = "RESET_STATE_HISTORICAL_MOVEMENT_TO_DOWNLOAD";
export const GET_LIST_TO_DOWNLOAD = "GET_LIST_HISTORICAL_MOVEMENT_TO_DOWNLOAD";
export const GET_LIST_TO_DOWNLOAD_LOADING = "GET_LIST_HISTORICAL_MOVEMENT_TO_DOWNLOAD_LOADING";
export const GET_LIST_TO_DOWNLOAD_ERROR = "GET_LIST_HISTORICAL_MOVEMENT_TO_DOWNLOAD_ERROR";
