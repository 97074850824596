import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledContainer = styled(Grid)`
  padding: 16px;
  margin-bottom: 8px;
  border-bottom: ${palette.border.simple},
  background: ${palette.background.light},
`;
