import palette from "theme/palette";
import { RobotoFont } from "common/constants";

export const optionsPie = () => ({
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    position: "right",
    labels: {
      boxWidth: 30,
      fontColor: palette.black,
      fontFamily: RobotoFont,
      fontSize: 12,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const value = data.datasets[0].data[tooltipItem.index];
        return ` ${value}%`;
      },
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
    },
    bodyFontSize: 13,
  },
  plugins: {
    datalabels: {
      display: true,
      formatter: (label) => `${label}%`,
      color: palette.black,
      fontSize: 14,
      fontFamily: RobotoFont,
    },
  },
});

export const optionsBar = {
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 25,
          max: 100,
        },
      },
    ],
  },
  tooltips: {
    bodyFontSize: 13,
  },
  plugins: {
    datalabels: {
      color: palette.white,
      anchor: "center",
      align: "center",
      formatter: Math.round,
      font: {
        weight: "bold",
      },
    },
  },
  legend: {
    display: false,
  },
};

export const optionsSatistactionBar = () => ({
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 25,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      mode: "x",
    },
    bodyFontSize: 13,
  },
  plugins: {
    padding: 10,
    datalabels: {
      display: false,
    },
  },
  legend: {
    display: false,
  },
});

export const optionsThemeBar = () => ({
  responsive: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          stepSize: 25,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const value = data.datasets[0].data[tooltipItem.index];
        return ` ${value}%`;
      },
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
    },
    bodyFontSize: 13,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    display: false,
  },
});

export const optionsComparativeSatistactionBar = () => ({
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: "top",
    align: "center",
    labels: {
      fontColor: palette.black,
      fontFamily: RobotoFont,
      fontSize: 12,
    },
  },
  scales: {
    xAxes: [
      {
        stacked: false,
      },
    ],
    yAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
          stepSize: 25,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      mode: "x",
    },
    bodyFontSize: 13,
  },
  plugins: {
    padding: 10,
    datalabels: {
      display: false,
    },
  },
});

export const optionsSatistactionBarByDivision = {
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          max: 100,
          stepSize: 25,
          callback: (value) => `${((value / 100) * 100).toFixed(0) }%`,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem) => {
        const { value } = tooltipItem;
        return ` ${value}%`;
      },
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
    },
    bodyFontSize: 13,
  },
  plugins: {
    padding: 10,
    datalabels: {
      display: false,
    },
  },
  legend: {
    display: false,
  },
};
