import includes from "lodash/includes";
import lodashGet from "lodash/get";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import { DESC, SORT_COMPARATOR } from "common/constants";

export const getSearchResult = (list, searchBy, value) => {
  const searchResult = [];
  list.forEach((item) => {
    item?.person
      && includes(lodashGet(item, searchBy).toLowerCase(), value)
      && searchResult.push(item);
    item?.name
      && includes(lodashGet(item, searchBy).toLowerCase(), value)
      && searchResult.push(item);
    item?.employee
      && includes(lodashGet(item, searchBy).toLowerCase(), value)
      && searchResult.push(item);
    item?.full_name
      && includes(lodashGet(item, searchBy).toLowerCase(), value)
      && searchResult.push(item);
    item?.employee_full_name
      && includes(lodashGet(item, searchBy).toLowerCase(), value)
      && searchResult.push(item);
  });

  return searchResult;
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return SORT_COMPARATOR.minusOne;
  }
  if (b[orderBy] > a[orderBy]) {
    return SORT_COMPARATOR.one;
  }
  return SORT_COMPARATOR.zero;
};

export const getComparator = (order, orderBy) => (isEqual(order, DESC)
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

export const sortTable = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis
    && stabilizedThis.sort((a, b) => {
      const order = comparator(
        a[SORT_COMPARATOR.zero],
        b[SORT_COMPARATOR.zero],
      );
      if (!isEqual(order, SORT_COMPARATOR.zero)) return order;
      return a[SORT_COMPARATOR.one] - b[SORT_COMPARATOR.one];
    });
  return stabilizedThis && stabilizedThis.map((el) => el[SORT_COMPARATOR.zero]);
};

export const getRowClass = (item, classes, open, isNotHeader, isDragable) => {
  const openDragable = open && !isNotHeader && isDragable;
  const openHeaderLess = open && isNotHeader;
  return item?.isActive
    ? classes.active
    : openDragable
      ? classes.open
      : openHeaderLess
        ? classes.openNested
        : "";
};

export const isNewElementSelected = (item, actions) => isEmpty(item.children) && isEqual(item.id, actions.newIdElement);

export const isNewInputRow = (actions, parentId) => (
  (!isNull(actions?.newIdElement) && actions[`isNewRow_${parentId}`])
    || (isNull(actions?.newIdElement) && isNull(parentId) && actions?.isDisabled)
);

export const isMainEmptyList = (isLoading, actions) => (
  !isLoading
    && actions
    && isNull(actions?.newIdElement)
    && actions?.isMainTable
);
