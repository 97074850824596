import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { VARIANT, TARGET, REL } from "common/constants";
import { Typography } from "@mui/material";
import {
  StyledDescription,
  StyledContent,
  StyledSubtitle,
  StyledList,
} from "./styles";

const ModalDetails = (props) => {
  const { data } = props;
  const { t } = useTranslation(["developmentPlan", "common"]);

  return (
    <div>
      <StyledContent>
        <StyledDescription variant={ VARIANT.bodyOne }>
          {data.description}
        </StyledDescription>
        <StyledSubtitle>{ t("labels.supportLinks") }</StyledSubtitle>
        <StyledList>
          {data?.urls?.map((item) => (
            <li key={ item.id }>
              <Typography variant={ VARIANT.h5 }>
                {item.title}
              </Typography>
              <Typography variant={ VARIANT.bodyOne }>
                <a href={ item.url } target={ TARGET.blank } rel={ REL }>
                  {item.url}
                </a>
              </Typography>
            </li>
          ))}
        </StyledList>
      </StyledContent>
    </div>
  );
};
ModalDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ModalDetails;
