import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import { isEqual } from "common/helpers";
import { StyledTableHead } from "../../styles";

const TableSkeletonLoader = (props) => {
  const { cols, rows } = props;

  return [...Array(rows).keys()].map((row) => (
    <StyledTableHead key={ row } >
      {[...Array(cols).keys()].map((col, index) => (
        <Skeleton key={ col } height={ 24 } width={ isEqual(index, 0) ? "200%" : "100%" } className={ "skeleton" } />
      ))}
    </StyledTableHead>
  ));
};

TableSkeletonLoader.propTypes = {
  cols: PropTypes.number.isRequired,
  rows: PropTypes.number,
};

TableSkeletonLoader.defaultProps = {
  rows: 10,
};

export default TableSkeletonLoader;
