import makeStyles from "@mui/styles/makeStyles";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import { default as AvatarMUI } from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

// TODO: refactor
export const useStyles = makeStyles((theme) => ({
  root: ({ maxWidth }) => ({
    width: maxWidth || "unset",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1.5),
    outline: 0,
    fontFamily: "Roboto",
    fontSize: 14,
    top: "50%",
    left: "50%",
    borderRadius: 4,
    transform: "translate(-50%, -50%)",
    maxHeight: "700px",
    overflow: "hidden",
    "@media (max-width: 1024px)": {
      maxHeight: "calc(100% - 70px)",
    },
    "@media (max-width: 943px)": {
      overflowY: "scroll",
    },
    "@media (max-width: 600px)": {
      boxSizing: "border-box",
      width: "calc(100% - 10px)",
      maxHeight: "calc(100% - 10px)",
      overflow: "hidden",
      padding: theme.spacing(1),
    },
  }),
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleContainer: {
    justifyContent: "space-between",
    maxWidth: "100%",
  },
  content: {
    marginTop: "10px",
    padding: 0,
  },
  titleStyle: {
    color: theme.palette.title?.subtitleColor,
    fontSize: "18px",
  },
  buttonActionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "25px",
  },
  titleContainerFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: 10,
  },
  iconContainer: {
    flexShrink: 0,
    marginRight: 10,
  },
  titleFlexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
  },
}));

export function modalStyle() {
  const top = 50 + Math.round(Math.random() * 20) - 10;
  const left = 50 + Math.round(Math.random() * 20) - 10;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const ModalView = styled(Modal)`
  border-radius: 5px;
`;

export const Avatar = styled(AvatarMUI)`
  margin-top: 10px;
  width: 45px;
  height: 45px;
  padding: 6px;
`;

export const StyledContent = styled.div`
  margin-top: 10px;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const StyledHeaderIcon = styled.div`
  .MuiAvatar-root {
    border: ${(props) => (props.borderIconColor ? `2px solid${props.borderIconColor}` : "")};
  }
`;
