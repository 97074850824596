import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import constants from "styledComponents/constants";
import palette from "theme/palette";

export const StyledTitle = styled(Typography)`
  font-size: ${(prop) => `${prop.size}px` || constants.fontSize.medium};
  font-weight: ${constants.fontWeight.medium};
  margin-bottom: 16px;
  text-align: ${(prop) => prop.textAlign || "left"};
`;

export const StyledContainer = styled.div`
  padding: ${(props) => props.padding};
  height: ${(props) => props.height || "100%"};
  border-left: ${palette.border.lightGray};
`;

export const StyledTabContent = styled.div`
  margin: 16px;
`;

export const StyledChartContainer = styled.div`
  padding: 25px 0;
`;

export const StyledGridAlert = styled(Grid)`
  .MuiAlert-root {
    width: 97%;
  }
`;
