import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Skeleton from "@mui/material/Skeleton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import palette from "theme/palette";
import Button from "components/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export const StyledTable = styled.div`
  width: 100%;
  color: ${palette.text.secondaryTitle};
  font-size: 14px;
  font-weight: 500;
`;

export const StyledTableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
  font-size: 14px;
  th:first-child: {
    borderRadius: 8px 0 0 8px;
  },
  th:last-child: {
    borderRadius: 0 8px 8px 0;
  }
`;

export const StyledTableBody = styled(TableBody)`
  width: 100%;
  box-shadow: ${(props) => (props.hasBorder ? `inset 0 0 0 1px ${palette.table.disabled}` : "none")};
  background-color: ${palette.table.white};
  &.content{
    .noData{
      min-height: 200px;
    }
    .rowContent{
      height: 70px;
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      &.odd{
        background-color: ${palette.table.oddRow} ;
      }
      > div {
        width: 100%;
        padding: 10px 25px;
        &:first-child{
          width: 200%;
        }
        &:last-child{
          width: 15%;
        }
      }
    }
  }
`;

export const StyledTitleWithSubtitle = styled.div`
  display: inline;
  text-align: ${(props) => (props.isMobile ? "center" : "left") };
  padding-left: 5px;
  .subtitle{
    font-weight: 400;
    font-size: 12px;
    color: ${palette.text.content};
    display: flex;
    align-items: center;
  }
  svg{
    margin-right: 5px;
    width: 14px;
  }
  .title{
    font-size: 14px;
    font-weight: 500;
  }
`;

export const StyledActionItem = styled.div`
  &.error{
    color: ${palette.table.red}
  }
`;

export const StyledActionCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.onlyOne ? "flex-end" : "space-between")};
  background-color: ${palette.white};
  border-radius: 0 0 8px 8px;
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.table.paginationIcon};
  font-size: 12px;
`;

export const StyledAddButton = styled.div`
  padding: 5px 8px 12px;
`;

export const StyledButtonPaginator = styled(Button)`
  background-color: ${palette.white};
  margin: 5px 2px;
  svg{
    color: ${palette.table.paginationIcon};
  }
  &.Mui-disabled{
    svg{
      color: ${palette.table.disabled};
    }
  }
`;

export const StyledSearchContainer = styled.div`
  display: ${(props) => (props.isMobile ? "inline-block" : "flex")};
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  .searchInput{
    input{
      width: 250px;
      @media (max-width: 600px) {
        width: 100%
      };
    }
  }
`;

export const StyledDownLoad = styled.div`
  margin-left: 20px;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    margin-left: 0;
  };
`;

export const StyledTableCard = styled(Paper)`
  padding:25px;
  margin-top: 15px;
  box-shadow: ${palette.boxShadow.card};
`;

export const StyledButtonActionsContainer = styled.div`
  margin-top: 25px;
  text-align: center;
`;
export const StyledLink = styled.div`
  color: ${palette.text.link};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledTableRow = styled(TableRow)`
  justify-content: space-evenly;
  .MuiTableCell-root {
    border-bottom: ${palette.border.simpleThin};
  }
  .MuiTableCell-head {
    background-color: ${ palette.table.header };
    padding: 15px 24px 15px 16px !important;
    color: ${ palette.white };
    .MuiIconButton-root, .MuiSvgIcon-root {
      color: ${ palette.white };
    }
  }
  .MuiTableCell-root:first-child {
    width: 300px;
    text-align: left;
  }
  &:nth-of-type(even) {
    background-color: ${ palette.table.oddRow };
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 0;
    display: ${(props) => (props.disabled ? "none" : "") };
    line-height: 16px;
    font-size: 14px;
    border-bottom: ${palette.border.simpleThin};
  }
`;

export const StyledCollapseContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 0 15px;
`;

export const StyledTableAddCell = styled(TableCell)`
  background-color: ${palette.background.default};
  font-size: 14px;
`;

export const StyledOrder = styled.div`
  .MuiIconButton-root {
    color: ${palette.white};
    padding: 5px;
    svg {
      font-size: 1rem;
    }
  }
`;

export const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)`
  opacity: ${(props) => props.isDisabled && 0.4};
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  color: ${ palette.addIcon };
`;

export const StyledRemoveCircleIcon = styled(RemoveCircleIcon)`
  color: ${ palette.addIcon };
`;

export const StyledRowTableCell = styled(TableCell)`
  display: ${(props) => (props.disabled ? "none" : "") };
  font-size: 14px;
  border-bottom: ${palette.border.simpleThin};
`;

export const StyledTableScroll = styled(Table)`
  ${({ hasScroll }) => hasScroll
    && `
    position: relative;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: ${palette.white};
    }
  `}
`;

export const StyledTableContainer = styled(TableContainer)`
  ${({ hasScroll }) => hasScroll
  && `
    max-height: 550px;
    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${palette.background.mediumGrey};
      border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: ${palette.scroll.thumbActive};
    }
    ::-webkit-scrollbar-thumb:hover: {
      background: ${palette.scroll.thumbHover};
      box-shadow: ${palette.scroll.boxShadow};
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
    }
    ::-webkit-scrollbar-track:hover,::-webkit-scrollbar-track:active {}
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: ${palette.scroll.firefoxColor};
  `}
`;
