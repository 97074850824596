import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import InputTextSearch from "components/InputTextSearch";
import TableSkeletonLoader from "../TableSkeletonLoader";
import {
  StyledTableHead,
  StyledTableBody,
  StyledSearchContainer,
  StyledDownLoad,
} from "../../styles";

const TableDesktop = (props) => {
  const {
    header,
    isLoading,
    handleSearch,
    rightOptions,
    withoutActions,
    contentTable,
    isDense,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <StyledSearchContainer>
        { handleSearch && <InputTextSearch handleInput={ handleSearch } />}
        { rightOptions && <StyledDownLoad>{ rightOptions }</StyledDownLoad>}
      </StyledSearchContainer>
      {header && (
        <StyledTableHead className={ "content header" } withoutActions={ withoutActions }>
          {header.map((headCell, index) => (
            <div key={ headCell || `headCell-${index}` }>
              {headCell ? t(`tables:headers.${headCell}`) : ""}
            </div>
          ))}
        </StyledTableHead>
      )}

      {isLoading ? (
        <StyledTableBody>
          <TableSkeletonLoader cols={ header.length } />
        </StyledTableBody>
      ) : (
        <StyledTableBody className={ "content" } withoutActions={ withoutActions } isDense={ isDense }>
          {contentTable()}
        </StyledTableBody>
      )}
    </>
  );
};

TableDesktop.propTypes = {
  header: PropTypes.array.isRequired,
  contentTable: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleSearch: PropTypes.func,
  rightOptions: PropTypes.any,
  withoutActions: PropTypes.bool,
  isDense: PropTypes.bool,
};

TableDesktop.defaultProps = {
  isLoading: false,
  handleSearch: null,
  rightOptions: null,
  withoutActions: false,
  isDense: false,
};

export default TableDesktop;
