import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import InputTextSearch from "components/InputTextSearch";
import {
  isEmpty,
  isEqual,
  isEmptyObject,
  isUndefined,
} from "common/helpers";
import { PAGINATION, ALIGN_ITEMS} from "common/constants";
import TableSkeletonLoader from "../TableSkeletonLoader";
import {
  StyledTableRow,
  StyledTableBody,
  StyledSearchContainer,
  StyledDownLoad,
  StyledLegend,
  StyledTableContainer,
  StyledOrder,
  StyledArrowUpwardIcon,
  StyledTableCell,
} from "../../styles";

const TableDesktop = (props) => {
  const {
    header,
    isLoading,
    handleSearch,
    rightOptions,
    contentTable,
    noBorder,
    isMobile,
    tableRef,
    list,
    total,
    handleSort,
    order,
  } = props;
  const { t } = useTranslation();

  const [orderData, setOrderData] = useState({});

  const handleSortTable = (columnName, direction) => {
    setOrderData({ [columnName]: direction });
    if (handleSort) handleSort(columnName, direction);
  };

  const getOrderIcon = (headCell) => {
    let orderIcon = <StyledArrowUpwardIcon isDisabled />;
    if (!isUndefined(orderData[order[headCell]])) {
      orderIcon = orderData[order[headCell]] ? <StyledArrowUpwardIcon /> : <ArrowDownwardIcon />;
    }
    return orderIcon;
  };

  const content = (
    <StyledTableBody className={ "content" } hasBorder={ !noBorder } >
      {contentTable()}
      {!isLoading && !isEmpty(list) && list.length >= PAGINATION.maxPerPage && (
        <TableRow>
          <StyledTableCell colSpan={ header.length }>
            <StyledLegend>
              {isEqual(list.length, total) ? t("common:common.no_more_information") : `(${list.length}/${total})`}
              {list.length < total && ` ${t("common:common.loading")}...`}
            </StyledLegend>
          </StyledTableCell>
        </TableRow>
      )}
      { isLoading && <TableSkeletonLoader cols={ header.length } />}
    </StyledTableBody>
  );

  const headerContent = header && (
    <TableHead>
      <StyledTableRow className={ "content header" }>
        {header.map((headCell, index) => (
          <StyledTableCell key={ headCell || `headCell-${index}` }>
            <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
              {!isEmptyObject(order) && headCell && order[headCell] && (
                <StyledOrder>
                  <IconButton
                    onClick={ (e) => (
                      handleSortTable(order[headCell], !orderData[order[headCell]], e)
                    ) }
                  >
                    { getOrderIcon(headCell) }
                  </IconButton>
                </StyledOrder>
              )}
              {headCell ? t(`tables:headers.${headCell}`) : ""}
            </Box>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );

  return (
    <>
      <StyledSearchContainer isMobile={ isMobile }>
        { handleSearch && <InputTextSearch handleInput={ handleSearch } />}
        { rightOptions && <StyledDownLoad>{ rightOptions }</StyledDownLoad>}
      </StyledSearchContainer>
      <StyledTableContainer ref={ tableRef }>
        <Table stickyHeader >
          { headerContent }
          { content }
        </Table>
      </StyledTableContainer>
    </>
  );
};

TableDesktop.propTypes = {
  header: PropTypes.array.isRequired,
  contentTable: PropTypes.func.isRequired,
  tableRef: PropTypes.element.isRequired,
  list: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  handleSearch: PropTypes.func,
  rightOptions: PropTypes.any,
  noBorder: PropTypes.bool,
  isMobile: PropTypes.bool,
  total: PropTypes.number,
  handleSort: PropTypes.func,
  order: PropTypes.object,
};

TableDesktop.defaultProps = {
  isLoading: false,
  handleSearch: null,
  rightOptions: null,
  noBorder: false,
  isMobile: false,
  total: 0,
  handleSort: null,
  order: {},
};

export default TableDesktop;
