import { StyledSectionContainer } from "./styles";

const SectionContainer = (props) => {
  const { children, height, isFlex} = props;
  return (
    <StyledSectionContainer height={ height } isFlex={ isFlex }>
      {children}
    </StyledSectionContainer>
  );
};

export default SectionContainer;
