import styled from "styled-components";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledAccordionContainer = styled.div`
  & .MuiAccordionSummary-root {
    border-bottom: ${palette.border.light};
  }
  & .MuiCollapse-wrapperInner {
    background: ${palette.background.default};
  }
  & .MuiPaper-rounded .MuiButtonBase-root {
    border-radius: 0;
  }
  & .MuiAccordion-root {
    box-shadow: none;
  }
  & .MuiAccordionSummary-root .Mui-expanded {
    min-height: 80px;
  }
`;

export const StyledTypography = styled(Typography)`
  padding: 16px;
`;
