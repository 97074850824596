import qs from "qs";
import { OBJECT_KEYS, PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import { OVERVIEW_TYPES } from "common/constants/nalaAnalytics";
import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";

import {
  RESET_STATE,
  GET_OVERVIEW,
  GET_OVERVIEW_LOADING,
  GET_OVERVIEW_ERROR,
} from "../../actionTypes/nalaAnalytics";

const URL = {
  main: "/nala_analytics",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getOverviewCards = (type, query) => async (dispatch) => {
  dispatch({ type: GET_OVERVIEW_LOADING, label: OVERVIEW_TYPES[type] });
  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, [OBJECT_KEYS.type]: type },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_OVERVIEW,
      payload: response.data,
      label: OVERVIEW_TYPES[type],
    });
  } catch (error) {
    errorHandler(
      error,
      dispatch,
      GET_OVERVIEW_ERROR,
      { label: OVERVIEW_TYPES[type] },
    );
  }
};
