import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import TimeIcon from "assets/images/icons/time.svg";
import { VARIANT, SIZE, BUTTON_STYLE_TYPES } from "common/constants";
import {
  StyledHeader,
  StyledTitle,
  StyledDialogContent,
} from "./styles";

const ModalInfo = (props) => {
  const {
    title, text, buttonText, icon, isOpen, onClose,
  } = props;

  return (
    <Dialog open={ isOpen }>
      {/* Header of the modal */}
      <StyledHeader>
        <StyledTitle>
          <img src={ icon } alt={ "Modal Icon" } />
          <h4>{title}</h4>
        </StyledTitle>

        {/* Button to close the modal */}
        <Button
          onClick={ onClose }
          variant={ VARIANT.text }
          typeStyle={ BUTTON_STYLE_TYPES.DEFAULT }
          size={ SIZE.small }
        >
          <CloseIcon />
        </Button>
      </StyledHeader>

      {/* Content of the modal */}
      <StyledDialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </StyledDialogContent>

      {/* Action buttons at the bottom */}
      <DialogActions>
        {/* Button to close the modal */}
        <Button
          onClick={ onClose }
          variant={ VARIANT.contained }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          size={ SIZE.small }
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalInfo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

ModalInfo.defaultProps = {
  icon: TimeIcon,
};

export default ModalInfo;
