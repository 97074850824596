import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledAccordionContainer = styled.div`
  & .MuiAccordionSummary-root {
    border-bottom: ${palette.border.light};
  }
  & .MuiCollapse-wrapperInner {
    background: ${palette.background.default};
  }
  & .MuiPaper-rounded .MuiButtonBase-root {
    border-radius: 0 !important;
  }
  & .MuiAccordion-root {
    box-shadow: none !important;
  }
  & .MuiAccordionSummary-root .Mui-expanded {
    min-height: 80px !important;
    .MuiSvgIcon-root {
      align-self: center;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0px;
  }
`;

export const StyledAnswers = styled.div`
  padding: 16px;
  width: 100%;
`;

export const StyledAccordionHeader = styled(Grid)`
  padding-left: ${ (props) => (props.isWide ? "16px" : "0px") };
  padding-right: 16px;
  align-items: center;
`;

export const StyledQuestion = styled(Grid)`
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
`;

export const StyledScaleIcon = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  & img {
    position: absolute;
    width: 24px;
  }
  & img:nth-child(1) {
    left: 0;
  }
  & img:nth-child(2) {
    left: 45%;
  }
  & img:nth-child(3) {
    right: 0;
  }
`;

export const StyledGridContainer = styled(Grid)`
  justify-content: flex-end;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
`;

export const StyledSubtitle = styled(Typography)`
  padding-bottom: 16px;
  font-weight: 700;
`;

export const StyledNpsTitle = styled(Typography)`
  padding: 2px 0 16px;
  min-height: 52px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  :hover {
    text-decoration-line: underline;
  }
`;
