import PropTypes from "prop-types";
import { StyledInfoDetail, StyledInfoTitle, StyledIcon } from "../../styles";

const InfoDetail = (props) => {
  const { title, subtitle, icon } = props;
  return (
    <StyledInfoDetail>
      <StyledInfoTitle>{title}</StyledInfoTitle>
      <p>
        <StyledIcon>{icon}</StyledIcon>
        {subtitle}
      </p>
    </StyledInfoDetail>
  );
};

InfoDetail.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

export default InfoDetail;
