import styled from "styled-components";
import palette from "theme/palette";

export const StyledAgreementTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 20px;;
  svg {
    color: ${(props) => (props.isFinished ? palette.background.valid : palette.background.gray)};
    font-size: 30px;
    margin-right: 10px;
  }
`;

export const StyledAgreementSubTitle = styled.div`
  font-size: 16px;
  color: ${palette.text.secondaryTitle};
  font-weight: 700;
  margin: 16px 0;
`;

export const StyledSectionContainer = styled.div`
  padding: 16px;
`;
