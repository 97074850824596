import { inRange } from "lodash";
import Typography from "@mui/material/Typography";
import TodayIcon from "@mui/icons-material/Today";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import MenuPopup from "components/MenuPopup";
import StarRating from "components/StarRating";
import TooltipIcon from "components/TooltipIcon";
import SkeletonLoader from "components/SkeletonLoader";
import StaticProgressBar from "components/StaticProgressBar";
import ProfileImageDetail from "components/ProfileImageDetail";
import { orderByDesc, isEmpty, orderByAsc } from "common/helpers";
import {
  getDaysFromToday,
  getPercent,
  historyPush,
  navigateToPath,
} from "common/utils";
import {
  MIN_VALUE,
  PATHS,
  RATING,
  SIZE,
  VARIANT,
  OBJECT_KEYS,
  SURVEY_PROCESS_TYPE,
} from "common/constants";
import { SATISFACTION_RANGE } from "common/constants/engagement";
import { CARD_RESULT, ENGAGEMENT } from "theme/palette";
import SatisfactionIcon from "assets/images/engagement/satisfied_legend.svg";
import NeutralIcon from "assets/images/engagement/neutral.svg";
import UnsatisfiedIcon from "assets/images/engagement/unsatisfied.svg";
import NoInfoFaceIcon from "assets/images/tables/noInfoFace.svg";
import NoInfoFaceBlack from "assets/images/general/noInfoFaceBlack.svg";
import TeamIcon from "assets/images/newHireExperience/team.svg";
import NpsIcon from "assets/images/newHireExperience/nps.svg";
import SatisfactionHandIcon from "assets/images/newHireExperience/satisfaction.svg";
import PerformanceIcon from "assets/images/newHireExperience/performance.svg";
import {
  StyledTeamList,
  StyledDays,
  StyledSatisfaction,
  StyledNoResult,
  StyledAnswer,
  StyledTeamContent,
} from "../styles";

const getSortedHeader = (headerList, key, t, followUpList) => {
  const headerItems = headerList.map((element) => {
    const days = followUpList?.find(
      (followUpProcess) => followUpProcess.id === element.follow_up_process_id,
    )?.days_to_display;
    return {
      id: element.id,
      label: `${days} ${t("common:common.date_parts.day.plural")}`,
      days,
      color: ENGAGEMENT.bgLight,
      key,
    };
  });

  const sortedHeader = orderByAsc(headerItems, "days");
  return sortedHeader;
};

export const getHeader = (performanceHeaderList, satisfactionHeaderList, t, followUpList) => {
  const header = [
    {
      id: "full_name",
      label: "",
    },
    {
      id: "manager_name",
      label: t("detailsTable.manager"),
    },
    {
      id: "position_name",
      label: t("detailsTable.position"),
    },
    {
      id: "days",
      label: t("detailsTable.days"),
      tooltip: (
        <TooltipIcon
          children={ t("description.days") }
          isHelpIcon
        />
      ),
    },
  ];

  header.push({
    id: "satisfaction",
    label: t("detailsTable.satisfaction"),
    isParent: true,
    hasSorting: true,
    sortingColumn: "follow_up_engagement_results_score",
  });
  // Satisfaction results
  const sortedHeaderSatisfaction = getSortedHeader(satisfactionHeaderList, "satisfaction", t, followUpList);
  header.push(...sortedHeaderSatisfaction);

  header.push({
    id: "performance",
    label: t("detailsTable.performance"),
    isParent: true,
    hasSorting: true,
    sortingColumn: "follow_up_performance_results_score",
  });
  // Performance results
  const sortedHeaderPerformance = getSortedHeader(performanceHeaderList, "performance", t, followUpList);
  header.push(...sortedHeaderPerformance);

  header.push(
    {
      id: "task",
      label: t("tasks.title"),
    },
    {
      id: "actions",
      label: "",
    },
  );

  return header;
};

export const getTotalCompleteProcessByCollaborator = (followUpProcess) => {
  const tasks = followUpProcess?.map((result) => result.tasks).reduce((prev, curr) => prev + curr, MIN_VALUE);
  const completedPerformanceTasks = followUpProcess?.filter((result) => result?.performance?.score !== null)?.length;
  const completedEngagementTasks = followUpProcess?.filter((result) => result?.engagement?.score !== null)?.length;
  return {
    total: tasks,
    completedTasks: completedPerformanceTasks + completedEngagementTasks,
  };
};

const getOptions = (collaborator, history, handleSatisfaction, t) => [
  {
    title: t("actions.viewFollowUp"),
    icon: <PermContactCalendarIcon fontSize={ SIZE.small } />,
    onClick: () => {
      historyPush(
        history,
        `${PATHS.profile}${PATHS.onboardingFollowUp}`,
        `${PATHS.search.collaborators}${collaborator?.id}`,
      );
    },
  },
  {
    title: t("actions.viewSatisfaction"),
    icon: <SentimentVerySatisfiedIcon fontSize={ SIZE.small } />,
    onClick: () => handleSatisfaction(collaborator),
  },
  {
    title: t("actions.viewProfile"),
    icon: <VisibilityIcon fontSize={ SIZE.small } />,
    onClick: () => {
      historyPush(
        history,
        PATHS.profile,
        `${PATHS.search.collaborators}${collaborator?.id}`,
      );
    },
  },
];

const satisfactionIcons = (t) => [
  {
    minValue: SATISFACTION_RANGE.satisfied.max,
    maxValue: SATISFACTION_RANGE.totallySatisfied.max,
    icon: SatisfactionIcon,
    label: t("engagement:satisfied"),
  },
  {
    minValue: SATISFACTION_RANGE.dissatisfied.max,
    maxValue: SATISFACTION_RANGE.satisfied.max,
    icon: NeutralIcon,
    label: t("engagement:neutral"),
  },
  {
    minValue: SATISFACTION_RANGE.totallyDissatisfied.min,
    maxValue: SATISFACTION_RANGE.dissatisfied.max,
    icon: UnsatisfiedIcon,
    label: t("engagement:unsatisfied"),
  },
];

export const getSatisfaction = (value, t) => satisfactionIcons(t).find((e) => inRange(value, e.minValue, e.maxValue));

export const getSatisfactionByLabelOrScore = (score, label, t) => satisfactionIcons(t).find((e) => e.label === label)
  || getSatisfaction(getPercent(score), t);

export const getSatisfationDetail = (score, label, t) => (
  <StyledSatisfaction>
    { <img
      src={ label ? getSatisfactionByLabelOrScore(score, label, t)?.icon : NoInfoFaceIcon }
      alt={ label || t("noResult") }
    /> }
    { label || <StyledNoResult>{t("noResult")}</StyledNoResult> }
  </StyledSatisfaction>
);

export const getLastFollowUpResults = (collaborator) => {
  const followUpResult = orderByDesc(collaborator.follow_up_processes_results?.results, OBJECT_KEYS.days);
  const lastFollowUpResultPerformance = followUpResult?.filter((followUp) => followUp.performance?.score !== null)[0]?.performance;
  const lastFollowUpResultEngagement = followUpResult?.filter((followUp) => followUp.engagement?.score !== null)[0]?.engagement;
  return { followUpResult, lastFollowUpResultPerformance, lastFollowUpResultEngagement };
};

export const getRows = (data, header, history, handleSatisfaction, t) => {
  const satisfactionColumns = header.filter((item) => item.key === OBJECT_KEYS.satisfaction);
  const performanceColumns = header.filter((item) => item.key === OBJECT_KEYS.performance);
  const rows = data?.map((collaborator) => {
    const {
      followUpResult,
      lastFollowUpResultPerformance,
      lastFollowUpResultEngagement,
    } = getLastFollowUpResults(collaborator);

    const cell = [
      {
        content: (
          <StyledTeamList key={ collaborator.id }>
            <ProfileImageDetail collaborator={ collaborator } hasStatus />
          </StyledTeamList>
        ),
      },
      {
        content: <Typography variant={ VARIANT.bodyOne }>{collaborator?.manager?.full_name}</Typography>,
      },
      {
        content: <Typography variant={ VARIANT.bodyOne }>{collaborator.position_name}</Typography>,
      },
      {
        content: (
          <StyledDays>
            <TodayIcon />
            {` ${getDaysFromToday(collaborator.starting_date)} ${t("detailsTable.days")}` }
          </StyledDays>
        ),
      },
      {
        content: getSatisfationDetail(
          lastFollowUpResultEngagement?.score,
          lastFollowUpResultEngagement?.result,
          t,
        ),
      },
    ];

    satisfactionColumns.forEach((col) => {
      const engagementProcess = followUpResult && followUpResult.find((followUpProcess) => followUpProcess?.engagement && followUpProcess.engagement.id === col.id)?.engagement;
      cell.push({
        content: engagementProcess ? (
          getSatisfationDetail(engagementProcess?.score, engagementProcess?.result, t)
        ) : "",
      });
    });
    cell.push(
      {
        content: <StarRating
          name={ `performance-total-result-${collaborator.id}` }
          value={ lastFollowUpResultPerformance?.position || 0 }
          isReadOnly
          maxRating={ lastFollowUpResultPerformance?.scale_index_count }
          max={ lastFollowUpResultPerformance?.scale_index_count }
          precision={ 1 }
          hasNoFormattedValue
          label={ lastFollowUpResultPerformance?.result }
        />,
      },
    );
    performanceColumns.forEach((col) => {
      const performanceProcess = followUpResult && followUpResult.find((followUpProcess) => followUpProcess?.performance && followUpProcess.performance.id === col.id)?.performance;
      cell.push({
        content: performanceProcess ? (
          <StarRating
            name={ `star-rating-${collaborator.id}` }
            value={ performanceProcess?.position || 0 }
            isReadOnly
            maxRating={ performanceProcess?.scale_index_count }
            max={ performanceProcess?.scale_index_count }
            precision={ 1 }
            hasNoFormattedValue
            label={ performanceProcess?.result }
          />
        ) : "",
      });
    });
    const tasks = getTotalCompleteProcessByCollaborator(followUpResult);

    cell.push(
      {
        content: tasks ? (
          <StaticProgressBar
            value={ tasks.completedTasks }
            maxValue={ tasks.total }
            percentage={ getPercent(tasks.completedTasks / tasks.total) }
          />
        ) : <SkeletonLoader />,
      },
      {
        content: <MenuPopup items={ getOptions(collaborator, history, handleSatisfaction, t) } />,
      },
    );
    return cell;
  });
  return rows;
};

export const getExcelData = (excelList, t, surveyProcessList, followUpList) => {
  const generalData = [];

  // Filtering survey processes by their types
  const performanceProcesses = surveyProcessList.filter(
    (process) => process.type === SURVEY_PROCESS_TYPE.performance.key,
  );
  const engagementProcesses = surveyProcessList.filter(
    (process) => process.type === SURVEY_PROCESS_TYPE.engagement.key,
  );

  excelList.forEach((item, index) => {
    // Total completed tasks for a collaborator
    const tasks = getTotalCompleteProcessByCollaborator(item.follow_up_processes_results?.results);
    const {
      followUpResult,
      lastFollowUpResultPerformance,
      lastFollowUpResultEngagement,
    } = getLastFollowUpResults(item);

    generalData.push({
      [t("tables:headers.name")]: item.full_name,
      [t("common:common.email")]: item.email,
      [t("common:common.manager")]: item?.manager?.full_name,
      [`${t("common:common.manager")} ${t("common:common.email")}`]: item?.manager?.email,
      [t("tables:headers.state")]: t(`tables:state.${ item.is_active ? "active" : "inactive"}`),
      [t("detailsTable.position")]: item.position_name,
      [t("detailsTable.days")]: getDaysFromToday(item.starting_date),
      // Adding last follow-up result for engagement
      [t("detailsTable.satisfaction")]: lastFollowUpResultEngagement?.result || "",
    });

    // Processing engagement processes if they exist
    if (!isEmpty(engagementProcesses)) {
      followUpList.forEach((followUpProcess) => {
        const followUpEngagementProcess = engagementProcesses.find(
          (process) => process.follow_up_process_id === followUpProcess.id,
        );
        // Adding engagement results for each follow-up process
        if (followUpEngagementProcess) {
          const followUpEngagementName = `${t("detailsTable.satisfaction")} ${followUpProcess.days_to_display} ${t("planning:calendar.days")}`;
          const resultByFollowUpProcess = followUpResult.find(
            (results) => results.id === followUpProcess.id,
          );
          generalData[index][followUpEngagementName] = resultByFollowUpProcess?.engagement?.result || "";
        }
      });
    }

    // Adding last follow-up result for performance
    generalData[index][t("detailsTable.performance")] = lastFollowUpResultPerformance?.result || "";

    // Processing performance processes if they exist
    if (!isEmpty(performanceProcesses)) {
      followUpList.forEach((followUpProcess) => {
        const followUpPerformanceProcess = performanceProcesses.find(
          (process) => process.follow_up_process_id === followUpProcess.id,
        );
        if (followUpPerformanceProcess) {
          const followUpPerformanceName = `${t("detailsTable.performance")} ${followUpProcess.days_to_display} ${t("planning:calendar.days")}`;
          const resultByFollowUpProcess = followUpResult.find(
            (results) => results.id === followUpProcess.id,
          );
          generalData[index][followUpPerformanceName] = resultByFollowUpProcess?.performance?.result || "";
        }
      });
    }
    generalData[index][t("tasks.title")] = `${tasks.completedTasks}/${tasks.total}`;
  });

  const allData = [
    {
      name: t("common:common.results"),
      data: generalData,
    },
  ];
  // Create a Set to store unique follow-up names
  const uniqueFollowUps = new Set();

  // Iterate over the excelList to identify all unique follow-up processes.
  excelList.forEach((item) => {
    item.follow_up_processes_results?.results.forEach((followUp) => {
      uniqueFollowUps.add(followUp.name);
    });
  });

  // Process each unique follow-up and structure its data
  uniqueFollowUps.forEach((followUpName) => {
    const followUpData = excelList.map((item) => {
      const followUpResult = item.follow_up_processes_results?.results.find(
        (f) => f.name === followUpName,
      );

      const followUpDataStructure = {
        [t("tables:headers.name")]: item.full_name,
        [t("common:common.email")]: item.email,
        [t("common:common.manager")]: item?.manager?.full_name,
        [`${t("common:common.manager")} ${t("common:common.email")}`]: item?.manager?.email,
        [t("tables:headers.state")]: t(`tables:state.${ item.is_active ? "active" : "inactive"}`),
        [t("detailsTable.position")]: item.position_name,
        [t("detailsTable.performance")]: followUpResult?.performance?.result || "",
        [t("detailsTable.satisfaction")]: followUpResult?.engagement?.result || "",
      };

      const addQuestionsAndComments = (category, categoryName) => {
        if (followUpResult && followUpResult[category]) {
          // Add comments for the category if available
          if (followUpResult[category]?.comments) {
            followUpDataStructure[`${categoryName} ${t("surveys:form.fields.general_data.comments")}`] = followUpResult[category].comments.join(", ");
          }
          // Add answers for each open question in the category
          followUpResult[category]?.open_questions?.forEach((question) => {
            followUpDataStructure[`${categoryName}: ${question.name}`] = question.answer || "";
          });
        }
      };

      // Add question and comments
      addQuestionsAndComments("performance", t("detailsTable.performance"));
      addQuestionsAndComments("engagement", t("detailsTable.satisfaction"));

      return followUpDataStructure;
    });

    allData.push({
      name: followUpName,
      data: followUpData,
    });
  });

  return allData;
};

export const getOpenQuestions = (questions) => {
  const questionWithAnswers = [];
  questions.forEach((item) => {
    const answers = item.evaluation_results[MIN_VALUE]?.evaluation_answers[MIN_VALUE]?.answers;
    if (answers) {
      questionWithAnswers.push({
        title: item.name,
        customRender: answers.map((answer) => (
          <StyledAnswer key={ `item-${answer}` }>
            {answer.answer}
          </StyledAnswer>
        )),
      });
    }
  });

  return questionWithAnswers;
};

export const getFormatCards = (meta, t, history) => [
  {
    id: "collaborator-total",
    icon: TeamIcon,
    size: 42,
    customRender: (
      <StyledTeamContent>
        <Typography variant={ VARIANT.h4 }>{ meta?.total_collaborators }</Typography>
        { t("collaboratorsOnboarding") }
      </StyledTeamContent>
    ),
  },
  {
    id: "nps",
    title: t("engagement:nps"),
    subtitle: `(${t("currentStage")})`,
    icon: NpsIcon,
    color: CARD_RESULT.cyanCard,
    dataList: meta?.total_nps && meta?.total_nps.map((e) => ({
      value: e.days,
      label: `${e.days} ${t("planning:calendar.days")}`,
      handleOnClick: () => navigateToPath(history, PATHS.engagement, [{
        paramName: OBJECT_KEYS.id,
        value: e.process_id,
      }]),
      participation: { total: e.nps, max: 1 },
      hasResult: e.nps !== null,
    })),
    hasScore: true,
  },
  {
    id: "satisfaction",
    title: t("engagement:satisfaction"),
    subtitle: `(${t("currentStage")})`,
    icon: SatisfactionHandIcon,
    color: CARD_RESULT.yellowCard,
    dataList: meta?.total_engagement && meta?.total_engagement.map((engagementData) => ({
      value: engagementData.days,
      label: `${engagementData.days} ${t("planning:calendar.days")}`,
      handleOnClick: () => navigateToPath(history, PATHS.engagement, [{
        paramName: OBJECT_KEYS.id,
        value: engagementData.process_id,
      }]),
      result: (
        <img
          src={ engagementData?.result
            ? getSatisfactionByLabelOrScore(engagementData?.score, engagementData?.result, t)?.icon
            : NoInfoFaceBlack }
          alt={ engagementData?.result || t("noResult") }
        />
      ),
      hasResult: engagementData?.result !== null,
    })),
  },
  {
    id: "performance",
    title: t("common:common.performance"),
    subtitle: `(${t("currentStage")})`,
    icon: PerformanceIcon,
    dataList: meta?.total_performance && meta?.total_performance.map((performanceData) => ({
      value: performanceData.days,
      label: `${performanceData.days} ${t("planning:calendar.days")}`,
      handleOnClick: () => navigateToPath(history, PATHS.performance, [{
        paramName: OBJECT_KEYS.id,
        value: performanceData.process_id,
      }]),
      participation: { total: performanceData.score, max: 1 },
      hasResult: performanceData?.score !== null,
    })),
    hasScore: true,
    hasSymbol: true,
  },
];
