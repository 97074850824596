import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isEqual as isEqualObject } from "lodash";
import Grid from "@mui/material/Grid";
import Switch from "components/Switch";
import { ALIGN_ITEMS, VARIANT } from "common/constants";
import { isEqual } from "common/helpers";
import { StyledTitle, StyledHeader } from "./styles";

const SwitchSelectorContent = (props) => {
  const {
    options, hasTitle, onChangeOption, isFull, customStyles,
  } = props;
  const [generalOptions, setGeneralOptions] = useState();
  const [optionContent, setOptionContent] = useState();

  useEffect(() => {
    if (options && !isEqualObject(options, generalOptions)) {
      setGeneralOptions(options);
    }
    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (generalOptions) {
      setOptionContent(generalOptions[0]);
    }
    // eslint-disable-next-line
  }, [generalOptions]);

  const onChange = (optionSelected) => {
    const dataByOption = options.find((item) => isEqual(item.value, optionSelected));
    setOptionContent(dataByOption);
    if (onChangeOption) { onChangeOption(optionSelected); }
  };

  const switchRender = (
    <Switch
      options={ options }
      onChange={ onChange }
      selected={ optionContent?.value }
      customStyles={ customStyles?.switch }
    />
  );

  return (
    <div data-testid={ "switch-selector-content-component" }>
      { optionContent
      && (
        <>
          <Grid container spacing={ 1 } justifyContent={ ALIGN_ITEMS.flexEnd }>
            {optionContent.isTitleBelow && (
              <Grid item xs={ 12 } md={ 6 }>
                { switchRender }
              </Grid>
            )}
            {hasTitle && (
              <StyledHeader item xs={ 12 } md={ optionContent.isTitleBelow ? 12 : 8 }>
                { optionContent?.customTitle
                  || (
                    <StyledTitle variant={ VARIANT.h4 }>
                      {/* if you want to use a different title than the switch - title  */}
                      { optionContent?.title || optionContent.label }
                    </StyledTitle>
                  )}
              </StyledHeader>
            )}
            {!optionContent.isTitleBelow && (
              <Grid item xs={ 12 } md={ isFull ? 12 : 4 }>
                { switchRender }
              </Grid>
            )}
          </Grid>
          { optionContent?.content }
        </>
      )}
    </div>
  );
};

SwitchSelectorContent.propTypes = {
  options: PropTypes.array.isRequired,
  hasTitle: PropTypes.bool,
  onChangeOption: PropTypes.func,
  isFull: PropTypes.bool,
  customStyles: PropTypes.object,
};

SwitchSelectorContent.defaultProps = {
  hasTitle: true,
  onChangeOption: null,
  isFull: false,
  customStyles: null,
};

export default SwitchSelectorContent;
