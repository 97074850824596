import toNumber from "lodash/toNumber";
import isString from "lodash/isString";
import moment from "moment";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import SimpleIframe from "components/SimpleIframe";
import PerformanceIcon from "assets/images/planning/performance.svg";
import PotentialIcon from "assets/images/planning/potential.svg";
import GoodLeaderIcon from "assets/images/planning/good_leader.svg";
import EngagementIcon from "assets/images/planning/engagement.svg";
import {
  SURVEY_PROCESS_TYPE,
  OBJECT_KEYS,
  WIDTH,
  FULLDATE_FORMATS,
  VARIANT,
  DATE_PARTS,
  STATE,
} from "common/constants";
import palette from "theme/palette";
import { EVALUATION_TYPE, SURVEY_PROCESS_IFRAMES, GOOGLE_FORMS } from "common/constants/surveyProcess";
import { formatDate, getCurrentLanguage } from "common/utils";
import { getEvaluationType } from "common/validators";
import { singleTabStyles, StyledSubtitle, StyledChip } from "../components/ProcessForm/styles";

const getEmailTemplates = (processType) => {
  const lang = getCurrentLanguage();
  return {
    closure_type_manager: `closure-${processType}-process-manager-${lang}`,
    closure_type_employee: `closure-${processType}-process-employee-${lang}`,
    send_process: `lanzamiento-${processType}-${lang}`,
    send_process_custom_content: `lanzamiento-${processType}-customizado-${lang}`,
    send_process_reminder: `recordatorio-${processType}-${lang}`,
    send_process_custom_content_basic: `lanzamiento-${processType}-customizado`,
  };
};

const getMonthYearLabel = (es, en, pt) => {
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  const date = new Date(year, month - 1);

  const formatMonth = (monthString) => monthString.charAt(0).toUpperCase() + monthString.slice(1).replace(".", "");

  const monthES = formatMonth(date.toLocaleString("es", { month: "short" }));
  const monthEN = formatMonth(date.toLocaleString("en", { month: "short" }));
  const monthPT = formatMonth(date.toLocaleString("pt", { month: "short" }));

  const formattedLabel = {
    es: `${es} ${monthES} / ${year}`,
    en: `${en} ${monthEN} / ${year}`,
    pt: `${pt} ${monthPT} / ${year}`,
  };

  return formattedLabel;
};

export const getProcessTypes = (t) => [
  {
    key: SURVEY_PROCESS_TYPE.performance.key,
    value: SURVEY_PROCESS_TYPE.performance.value,
    itemData: SURVEY_PROCESS_TYPE.performance.itemData,
    label: t("surveys:processes_types.performance"),
    description: "",
    iframes: SURVEY_PROCESS_IFRAMES.performance,
    icon: PerformanceIcon,
    template: SURVEY_PROCESS_TYPE.performance.template,
    mandrillLabel: SURVEY_PROCESS_TYPE.performance.mandrillLabel,
    templates: getEmailTemplates(SURVEY_PROCESS_TYPE.performance.mandrillLabel),
    onlyClosure: false,
    isValidToCustomProcess: true,
    isValidToStakeholders: true,
    processType: SURVEY_PROCESS_TYPE.performance.processType,
    labelValue: getMonthYearLabel("Performance", "Performance", "Desempenho"),
    dashboard: t("common:sidebar.performance"),
  },
  {
    key: SURVEY_PROCESS_TYPE.potential.key,
    value: SURVEY_PROCESS_TYPE.potential.value,
    itemData: SURVEY_PROCESS_TYPE.potential.itemData,
    label: t("surveys:processes_types.potential"),
    iframes: SURVEY_PROCESS_IFRAMES.potential,
    icon: PotentialIcon,
    template: SURVEY_PROCESS_TYPE.potential.template,
    mandrillLabel: SURVEY_PROCESS_TYPE.potential.mandrillLabel,
    templates: getEmailTemplates(SURVEY_PROCESS_TYPE.potential.mandrillLabel),
    onlyClosure: false,
    isValidToCustomProcess: false,
    isValidToStakeholders: true,
    processType: SURVEY_PROCESS_TYPE.potential.processType,
    labelValue: getMonthYearLabel("Potencial", "Skills", "Potencial"),
    dashboard: t("common:sidebar.potential"),
  },
  {
    key: SURVEY_PROCESS_TYPE.goodLeader.key,
    value: SURVEY_PROCESS_TYPE.goodLeader.value,
    itemData: SURVEY_PROCESS_TYPE.goodLeader.itemData,
    label: t("surveys:processes_types.good_leader"),
    iframes: SURVEY_PROCESS_IFRAMES.goodLeader,
    icon: GoodLeaderIcon,
    template: SURVEY_PROCESS_TYPE.goodLeader.template,
    mandrillLabel: SURVEY_PROCESS_TYPE.goodLeader.mandrillLabel,
    templates: getEmailTemplates(SURVEY_PROCESS_TYPE.goodLeader.mandrillLabel),
    onlyClosure: false,
    isValidToCustomProcess: false,
    isValidToStakeholders: false,
    processType: SURVEY_PROCESS_TYPE.goodLeader.processType,
    labelValue: getMonthYearLabel("Liderazgo", "Leadership", "Liderança"),
    dashboard: t("common:sidebar.leadership"),
  },
  {
    key: SURVEY_PROCESS_TYPE.engagement.key,
    value: SURVEY_PROCESS_TYPE.engagement.value,
    itemData: SURVEY_PROCESS_TYPE.engagement.itemData,
    label: t("surveys:processes_types.engagement"),
    iframes: SURVEY_PROCESS_IFRAMES.engagement,
    icon: EngagementIcon,
    template: SURVEY_PROCESS_TYPE.engagement.template,
    mandrillLabel: SURVEY_PROCESS_TYPE.engagement.mandrillLabel,
    templates: getEmailTemplates(SURVEY_PROCESS_TYPE.engagement.mandrillLabel),
    onlyClosure: false,
    isValidToCustomProcess: false,
    isValidToStakeholders: false,
    processType: SURVEY_PROCESS_TYPE.engagement.processType,
    labelValue: getMonthYearLabel("Clima", "Engagement", "Clima"),
    dashboard: t("common:sidebar.engagement"),
  },
];

export const getEvaluationsArray = (t) => [
  {
    id: EVALUATION_TYPE.AUTOEVALUATION,
    name: getEvaluationType(EVALUATION_TYPE.AUTOEVALUATION, t),
  },
  {
    id: EVALUATION_TYPE.MANAGER,
    name: getEvaluationType(EVALUATION_TYPE.MANAGER, t),
  },
  {
    id: EVALUATION_TYPE.PAIRS,
    name: getEvaluationType(EVALUATION_TYPE.PAIRS, t),
  },
  {
    id: EVALUATION_TYPE.COLLABORATORS,
    name: getEvaluationType(EVALUATION_TYPE.COLLABORATORS, t),
  },
  {
    id: EVALUATION_TYPE.OVERALL,
    name: getEvaluationType(EVALUATION_TYPE.OVERALL, t),
  },
];

const updateEmailContent = (surveyProcess, key, currentContent, lastContent) => {
  if (currentContent !== lastContent) {
    surveyProcess[key] = currentContent;
  } else {
    delete surveyProcess[key];
  }
};

export const clearEmailContentFromLocalStorage = () => {
  const keys = [
    "email_content_es",
    "email_content_en",
    "email_content_pt",
    "email_content_es_last",
    "email_content_en_last",
    "email_content_pt_last",
  ];
  keys.forEach((key) => localStorage.removeItem(key));
};

export const buildSurveyProcess = (
  surveyProcess,
  audienceSelected,
  processId,
  processState,
  toProgram,
) => {
  if (isString(surveyProcess.min_worked_days)) {
    surveyProcess.min_worked_days = surveyProcess.min_worked_days.replaceAll(
      ",",
      "",
    );
  }

  if (isString(surveyProcess.reminder_days)) {
    surveyProcess.reminder_days = surveyProcess.reminder_days.replaceAll(
      ",",
      "",
    );
  }
  surveyProcess.end_date = moment.utc(surveyProcess.end_date).endOf("day");

  surveyProcess.min_worked_days = toNumber(surveyProcess.min_worked_days);
  surveyProcess.reminder_days = toNumber(surveyProcess.reminder_days);
  surveyProcess.audience_id = audienceSelected && audienceSelected.id;

  if (processState === STATE.finished) {
    delete surveyProcess.state_transition;
  } else if (processState === STATE.programmed || processState === STATE.running) {
    delete surveyProcess.survey_process_template_id;
    delete surveyProcess.state_transition;
  } else if (processId && !toProgram) {
    delete surveyProcess.state_transition;
  } else {
    surveyProcess.state_transition = toProgram ? STATE.program : STATE.draft;
  }

  const emailContentEs = localStorage.getItem("email_content_es");
  const emailContentEn = localStorage.getItem("email_content_en");
  const emailContentPt = localStorage.getItem("email_content_pt");
  const emailContentEsLast = localStorage.getItem("email_content_es_last");
  const emailContentEnLast = localStorage.getItem("email_content_en_last");
  const emailContentPtLast = localStorage.getItem("email_content_pt_last");

  updateEmailContent(surveyProcess, "email_content_es", emailContentEs, emailContentEsLast);
  updateEmailContent(surveyProcess, "email_content_en", emailContentEn, emailContentEnLast);
  updateEmailContent(surveyProcess, "email_content_pt", emailContentPt, emailContentPtLast);

  delete surveyProcess.state;
  delete surveyProcess.result_scale_indices_attributes;
  delete surveyProcess.participation_schema;

  return surveyProcess;
};

export const setLocalStorageValues = (processData) => {
  const keys = ["email_content_es", "email_content_en", "email_content_pt"];
  keys.forEach((key) => {
    const value = processData ? (processData[key] ?? "") : "";
    localStorage.setItem(`${key}_last`, value);
    localStorage.setItem(key, value);
  });
};

export const processDefaults = {
  id: "",
  type: "",
  name: "",
  name_es: "",
  name_en: "",
  name_pt: "",
  start_date: "",
  end_date: "",
  min_worked_days: 0,
  reminder_days: 0,
  survey_process_template_id: "",
  audience_id: null,
  state_transition: null,
  email_content_es: "",
  email_content_en: "",
  email_content_pt: "",
  result_scale_id: "",
  closure_email: true,
  participation_schema: null,
  calibration_activated: false,
};

const getProcessData = (processData, processType) => ({
  id: processData.id,
  type: processType.value,
  name: processData.name,
  name_es: processData.name_es,
  name_en: processData.name_en,
  name_pt: processData.name_pt,
  start_date: processData.start_date,
  end_date: processData.end_date,
  min_worked_days: processData.min_worked_days || 0,
  reminder_days: processData.reminder_days || 0,
  audience_id: processData.audience_id,
  state: processData.state,
  survey_process_template_id: processData?.survey_process_template_id,
  email_content_es: processData.email_content_es,
  email_content_en: processData.email_content_en,
  email_content_pt: processData.email_content_pt,
  result_scale_id: processData.result_scale_id,
  closure_email: processData.closure_email,
  participation_schema: processData?.participation_schema,
  calibration_activated: processData?.calibration_activated,
});

export const processEditData = (processData, processType) => {
  setLocalStorageValues(processData);
  return getProcessData(processData, processType);
};

export const resetFormData = (processType, data, t) => {
  const processTypes = getProcessTypes(t);
  const evaluationTypes = getEvaluationsArray(t);
  const processTypeValues = processTypes.find((process) => processType === process.value);
  const formData = data[processTypeValues.itemData];
  return formData
    ? processEditData(formData, processTypeValues, evaluationTypes)
    : [];
};

// FIXME:  Remove when calendar is finished
export const getSurveyHeader = (t) => [
  {
    id: OBJECT_KEYS.name,
    label: t("surveys:title"),
  },
  {
    id: OBJECT_KEYS.date,
    label: t("surveys:form.fields.end_date"),
    width: WIDTH.date,
    customRender: (rowData) => formatDate(rowData.date),
  },
  {
    id: OBJECT_KEYS.type,
    label: t("surveys:form.fields.process_type"),
    width: WIDTH.text,
    customRender: (rowData) => {
      const processType = getProcessTypes(t).find((process) => rowData.type === process.key);
      return processType?.label;
    },
  },
];

export const getAllTabs = (t, processId) => {
  const process = getProcessTypes(t);
  const allTabs = process.map((item) => ({
    label: item.label,
    icon: <Avatar
      src={ item.icon }
      variant={ VARIANT.square }
      sx={ { width: 24, height: 24 } }
    />,
    sx: singleTabStyles,
    isDisabled: processId,
  }));
  return allTabs;
};

export const getIframeEvaluation = (process) => process?.iframes?.map((item) => ({
  title: item.id,
  container: () => (
    <SimpleIframe
      title={ item.id }
      src={ `${GOOGLE_FORMS.url}/${item.key}/${GOOGLE_FORMS.view}` }
    />
  ),
}));

export const getCalendarData = (processes, t) => {
  const calendarResults = [];
  const lang = getCurrentLanguage();

  if (processes) {
    const filteredData = processes.filter((process) => !(process?.process_detail?.status === "complete" && process?.follow_up_process_id));
    filteredData.forEach((process) => {
      if (process?.start_date && process?.date) {
        const processStartDate = process?.process_detail?.follow_up_data?.date || process?.start_date;
        const processEndDate = process?.process_detail?.follow_up_data?.finishes_at || process?.date;
        const processFinishesAt = process?.process_detail?.follow_up_data?.finishes_at || process?.finishes_at;
        const startDate = new Date(formatDate(processStartDate, FULLDATE_FORMATS.slash_calendar));
        const endDate = new Date(formatDate(processEndDate, FULLDATE_FORMATS.slash_calendar));
        const endDateWithTime = formatDate(processFinishesAt, FULLDATE_FORMATS.slashTime);

        const processType = getProcessTypes(t).find((item) => process.type === item.key);
        calendarResults.push({
          id: process?.id,
          startDate,
          endDate,
          endDateWithTime,
          name: process[`name_${lang}`],
          processType,
          reminderTime: process.reminder_days || 0,
          minTime: process.min_worked_days || 0,
          processDetail: process?.process_detail,
          hasNalaForm: process?.has_nala_form,
          defaultStartDate: formatDate(process?.start_date),
          defaultEndDate: formatDate(process?.date),
          process,
        });
      }
    });
  }
  return calendarResults;
};

export const getEventsFromDate = (processes, date) => {
  const events = processes.filter((process) => process.startDate <= date
    && new Date(process.endDate.toDateString()) >= new Date(date.toDateString()));
  return events.sort((a, b) => b.start_date - a.start_date);
};

export const getCustomSubtitle = (event, t) => (
  <StyledSubtitle>
    <Typography variant={ VARIANT.h6 }>
      { `${t("surveys:form.fields.end_date")}: ${event.defaultEndDate}` }
    </Typography>
    <StyledChip
      backgroundColor={ event.process.state === STATE.finished ? "default" : "primary" }
      label={ t(`surveys:survey_state.${event.process.state}`) }
      size={ "small" }
    />
  </StyledSubtitle>
);

export const getTextByDays = (days, t) => {
  const textByDay = days === 1 ? DATE_PARTS.day : DATE_PARTS.days;
  return `${days} ${t(`planning:calendar.${textByDay}`)}`;
};

export const getFormattedAudiencesTags = (options) => {
  const formattedElements = options?.map((item) => {
    const formattedItem = {
      id: item.id,
      label: `${item.name} (${item.employees_count})`,
      value: `${item.name} (${item.employees_count})`,
      employees_count: item.employees_count,
    };
    return formattedItem;
  });
  return formattedElements;
};

export const iconStyles = {
  color: palette.primaryApp,
  width: "36px",
  height: "36px",
};

export const titleLanguages = [
  {
    value: "es",
    name: "nameEs",
  },
  {
    value: "en",
    name: "nameEn",
  },
  {
    value: "pt",
    name: "namePt",
  },
];

export const getFormattedTemplatesList = (templatesList, process) => {
  const lang = getCurrentLanguage();
  const formattedTemplatesList = templatesList?.filter(
    (item) => item.type === process.template,
  ).map((item) => ({
    value: item.id,
    label: item[`name_${lang}`],
  }));
  return formattedTemplatesList;
};

export const processStates = {
  drafted: "drafted",
  finished: "finished",
  running: "running",
};

export const resetFormDataAndEvaluations = (processType, data, t) => {
  const processTypes = getProcessTypes(t);
  const evaluationTypes = getEvaluationsArray(t);
  const processTypeValues = processTypes.find((process) => processType === process.value);
  const formData = data[processTypeValues.itemData];
  const isDrafted = formData?.state === processStates.drafted;
  return {
    editData: formData ? processEditData(formData, processTypeValues, evaluationTypes) : [],
    evaluations: isDrafted ? null : formData?.evaluations,
  };
};

export const getValidData = (process, t) => {
  const lang = getCurrentLanguage();
  const startDate = new Date(formatDate(process?.start_date, FULLDATE_FORMATS.slash_calendar));
  const endDate = new Date(formatDate(process?.date, FULLDATE_FORMATS.slash_calendar));
  const endDateWithTime = formatDate(process?.finishes_at, FULLDATE_FORMATS.slashTime);

  const processType = getProcessTypes(t).find((item) => process.type === item.key);
  return {
    id: process?.id,
    startDate,
    endDate,
    endDateWithTime,
    name: process[`name_${lang}`],
    processType,
    reminderTime: process.reminder_days || 0,
    minTime: process.min_worked_days || 0,
    processDetail: process?.process_detail,
    hasNalaForm: process?.has_nala_form,
    defaultStartDate: formatDate(process?.start_date),
    defaultEndDate: formatDate(process?.date),
  };
};

export const getFormattedResultScaleList = (resultScaleList, process) => {
  const lang = getCurrentLanguage();
  const formattedResultScaleList = resultScaleList?.filter(
    (item) => item.process_type === process.processType,
  ).map((item) => ({
    value: item.id,
    label: item[`name_${lang}`],
  }));
  return formattedResultScaleList;
};

export const formatResultScaleData = (resultScaleData) => {
  const formattedData = {
    result_scale: {
      result_scale_indices_attributes: [],
    },
  };

  Object.entries(resultScaleData).forEach(([key, value]) => {
    const [id, lang] = key.split("_result_");
    const existingEntry = formattedData.result_scale.result_scale_indices_attributes.find(
      (item) => item.id === parseInt(id, 10),
    );

    if (existingEntry) {
      existingEntry[`result_${lang}`] = value;
    } else {
      const newEntry = { id: parseInt(id, 10) };
      newEntry[`result_${lang}`] = value;
      formattedData.result_scale.result_scale_indices_attributes.push(newEntry);
    }
  });

  return formattedData;
};

export const getDefaultResultScale = (resultScaleList, process) => {
  const formattedResultScaleList = resultScaleList?.filter(
    (item) => item.process_type === process.processType,
  );

  if (formattedResultScaleList?.length === 1) {
    return formattedResultScaleList[0];
  }

  return formattedResultScaleList?.reduce((prev, current) => (
    (prev.result_scale_indices.length > current.result_scale_indices.length) ? prev : current));
};

export const getAudienceDataToDownload = (rows, t) => rows.map((employee) => ({
  [t("tables:headers.state")]: t(`tables:state.${employee.is_active ? "active" : "inactive"}`),
  [t("collaborators:table.table-head.personal_id")]: employee.personal_id,
  [t("collaborators:table.table-head.name")]: employee.full_name,
  [t("collaborators:table.table-head.email")]: employee?.email,
  [t("collaborators:table.table-head.manager")]: employee?.manager_info?.full_name || "",
  [t("collaborators:table.table-head.position")]: employee?.job_position?.position?.name || "",
  [t("collaborators:table.table-head.country")]: employee?.job_position?.country?.name || "",
  [t("collaborators:table.table-head.city")]: employee?.job_position?.city?.name || "",
  [t("administrator:modules.positions.header.unit")]: employee?.job_position?.units_complete_path || "",
}));
