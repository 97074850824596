import { Link } from "react-router-dom";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import split from "lodash/split";
import {
  OBJECT_KEYS,
  UNDEFINED,
  WARNING,
  STATE,
  DATE,
  INDEX,
} from "common/constants";
import { getAmountFormat, formatDate, isAdmin, formatDateToSubmit } from "common/utils";
import SimpleAlert from "components/SimpleAlert";
import { ReactComponent as Hiring } from "assets/images/salary/rehiring.svg";
import { ReactComponent as FinishContract } from "assets/images/salary/finish_contract.svg";
import { ReactComponent as Promotion } from "assets/images/salary/promotion.svg";
import { ReactComponent as LateralMovement } from "assets/images/salary/lateral_movement.svg";
import { ReactComponent as SalaryIncrease } from "assets/images/salary/salary_increase.svg";
import {
  getList as getMovementHistory,
  create as createMovementHistory,
} from "redux/actions/historicalMovementActions";
import { getOne as getCollaborator } from "redux/actions/collaboratorActions";

export const resetDefaultValues = (employee) => ({
  [`${employee}.starting_date`]: "",
  [`${employee}.job_position_attributes.type_of_contract_id`]: "",
  [`${employee}.salary_attributes.gross_salary`]: "",
});

export const resetBenefitsValues = (employee) => ({
  [`${employee}.benefits`]: "",
});

export const getBenefitName = (allBenefits, id) => allBenefits.find((item) => item.id === id)?.name;

export const getBenefitsCost = (data, hasSymbol, allBenefits) => {
  let benefits = [];

  benefits = data?.map((item) => {
    const labelName = getBenefitName(allBenefits, item.payroll_item_id);
    const valueSymbolValidation = hasSymbol
      ? `$${getAmountFormat(item.value)}`
      : `${item.value}`;
    const symbolValidation = hasSymbol ? "$0" : 0; // FIXME: no validaton for CERO value YET (isNumber validation will deleted eventually)
    const value = `${
      item.value // This validation SHOULD NOT be necessary
        ? valueSymbolValidation
        : symbolValidation
    }`;
    return {
      id: item.id,
      payroll_item_id: item.payroll_item_id,
      name: labelName,
      label: labelName,
      value,
      frequency: item.frequency,
    };
  });

  return benefits;
};

export const exchangeIsNotNaN = (values) => !isNaN(values.projectedCtc) && !isNaN(values.exchange);

export const getUnusedBenefits = (allBenefits, data) => {
  const unusedBenefits = allBenefits.filter(
    (mainObject) => !data.some((item) => mainObject.payroll_item_id === item.payroll_item_id),
  );

  return unusedBenefits;
};

export const getBenefitsInitFormat = (data, t) => {
  let benefits = [];
  benefits = data.map((item) => ({
    id: UNDEFINED,
    payroll_item_id: item.id,
    name: item.name,
    label: item.name,
    value: 0,
  }));
  return benefits;
};

export const deleteUnusedBenefits = (temporalList, itemsSeleted) => {
  const temp = temporalList.filter(
    (mainObject) => !itemsSeleted.some((item) => mainObject.payroll_item_id === item),
  );
  return temp;
};

export const isValiBenefit = (text, tags) => tags.filter((tag) => isEqual(tag.name, text));

export const stepContentTitle = (t) => [
  { title: t("movementsHistory.date"), element: "date" },
  { title: t("movementsHistory.position"), element: "position" },
  { title: t("movementsHistory.salary"), element: "salary" },
  { title: t("movementsHistory.type"), element: "finishContractType" },
  { title: t("movementsHistory.reason"), element: "reason" },
  { title: t("movementsHistory.comments"), element: "comments" },
];

export const extraStep = (icon, title) => ({
  icon,
  title,
});

export const translation = (t, mainTranslation) => ({
  t,
  main: mainTranslation,
});

export const getMappedMovementList = (movements, t, employee) => {
  const mappedMovements = movements?.map((movement) => {
    const movementObject = {
      type: movement?.type,
      date: formatDate(movement?.date),
      position: movement?.job_position?.position_name,
    };
    if (isEqual(movement?.type, STATE.turnover)) {
      movementObject.reason = movement?.reason;
      movementObject.comments = movement?.comments;
      movementObject.finishContractType = movement?.voluntary ? t("account:movementsHistory.voluntary") : t("account:movementsHistory.involuntary");
    } else {
      movementObject.salary = `$${getAmountFormat(movement?.salary?.gross_salary)} ${movement?.salary?.currency || ""}`;
    }

    return movementObject;
  });

  if (mappedMovements) {
    mappedMovements.push(
      {
        type: STATE.start,
        date: formatDate(employee?.starting_date),
        position: employee?.starting_job_position?.position_name,
        salary: `$${getAmountFormat(employee?.starting_salary?.gross_salary)} ${employee?.starting_salary?.currency || ""}`,
      },
    );
  }

  return mappedMovements;
};

export const validateAllFields = (data) => {
  const { collaborator, candidate } = data;
  const employeeData = collaborator || candidate;
  const jobAttributes = employeeData.job_position_attributes;
  const salaryAttributes = employeeData.salary_attributes;

  const dataToValidate = [
    { id: OBJECT_KEYS.date, value: employeeData.date },
    {
      id: OBJECT_KEYS.typeOfContractId,
      value: jobAttributes?.type_of_contract_id,
    },
    { id: OBJECT_KEYS.grossSalary, value: salaryAttributes?.gross_salary },
    { id: OBJECT_KEYS.currency, value: salaryAttributes?.currency },
  ];

  const fieldsToValidate = dataToValidate.filter((item) => {
    if (item.id === DATE) {
      return isNull(item.value)
        ? isEmpty(item.value)
        : isEmpty(formatDate(item.value));
    }
    if (isEqual(item.id, "type_of_contract_id")) {
      return !isNumber(item.value);
    }
    return isEmpty(item.value);
  });

  return fieldsToValidate.map((item) => item.id);
};

export const handleMovement = (constToHandle) => {
  const {
    data, dispatch, employeeId,
  } = constToHandle;
  data.collaborator.date = formatDateToSubmit(data.collaborator.date);
  const movementData = {
    date: data.collaborator.date,
    type: OBJECT_KEYS.salaryRaise,
  };

  const salary = data.collaborator.salary_attributes.gross_salary;
  data.collaborator.salary_attributes.gross_salary = salary.replaceAll(",", "");

  movementData.salary_attributes = data.collaborator.salary_attributes;
  movementData.job_position_attributes = data.collaborator.job_position_attributes;

  return dispatch(createMovementHistory(movementData, employeeId));
};

export const isInvalidAllFields = (data, isSalaryIncrease) => {
  let isInvalid = false;

  const date = isSalaryIncrease
    ? data.collaborator.date
    : data.collaborator.starting_date;

  const isEmptyDate = isNull(date) ? isEmpty(date) : isEmpty(formatDate(date));

  const hasEmptyJobElement = includes(
    data.collaborator.job_position_attributes,
    "",
  );

  const hasEmptySalaryElement = includes(data.collaborator.salary_attributes, UNDEFINED)
    || isEmpty(data.collaborator.salary_attributes?.gross_salary);

  isInvalid = isEmptyDate || hasEmptyJobElement || hasEmptySalaryElement;

  return isInvalid;
};

export const getStepIcon = (iconType) => {
  let icon;
  switch (iconType) {
  case OBJECT_KEYS.promotion:
    icon = <Promotion />;
    break;
  case OBJECT_KEYS.lateralMovement:
    icon = <LateralMovement />;
    break;
  case OBJECT_KEYS.salaryRaise:
    icon = <SalaryIncrease />;
    break;
  case OBJECT_KEYS.finishContract:
    icon = <FinishContract />;
    break;
  default:
    icon = <Hiring />;
    break;
  }
  return icon;
};

export const getDeletedItemsObjects = (initialData, deletedItems) => {
  let benefits = [];
  const initialDataAux = initialData;
  benefits = initialDataAux.filter((benefit) => deletedItems.find((id) => isEqual(benefit.payroll_item_id, id)));
  return benefits;
};

export const getDeletedFormat = (deletedItemsObjects) => deletedItemsObjects.map((item) => ({
  payroll_item_id: item.payroll_item_id,
  id: item.id,
  _destroy: true,
}));

export const getAssembledObject = (benefits) => {
  const benefitsArrObject = [];
  for (const item in benefits) {
    const type = split(item, "_")[INDEX.zero];
    if (type === OBJECT_KEYS.benefit) {
      const benefitPayrollId = split(item, "_")[INDEX.one];
      const benefitId = split(item, "_")[INDEX.two];
      const benefitIdNumber = Number(benefitPayrollId);
      const frequency = benefits[`${OBJECT_KEYS.frequency}_${benefitPayrollId}_${benefitId}`];
      const benefitObject = {
        payroll_item_id: isNaN(benefitIdNumber) ? "" : benefitIdNumber,
        value: isNumber(benefits[item])
          ? benefits[item]
          : parseFloat(benefits[item].replaceAll(",", "")), // FIXME: no validaton for CERO value YET (isNumber validation will deleted eventually)
        frequency,
      };
      if (!isEqual(benefitId, OBJECT_KEYS.undefined)) {
        benefitObject.id = Number(benefitId);
      }
      if (isNaN(benefitIdNumber)) {
        benefitObject.name = benefitPayrollId;
      }
      benefitsArrObject.push(benefitObject);
    }
  }
  return benefitsArrObject;
};

export const dispatchEmployeeAndMovements = (
  dispatch,
  employeeId,
) => {
  dispatch(getCollaborator(employeeId));
  dispatch(getMovementHistory(employeeId));
};

export const getAlertContractRelationship = (t, userCookies) => {
  const message = t("account:accountDetails.contract_relationship_message");

  return (
    <SimpleAlert
      type={ WARNING }
      message={ isAdmin(userCookies) ? (
        <Link
          to={ "/administrator/contract-types" }
        >
          {message}
        </Link>
      ) : message }
    />
  );
};

export const getSalaryAttributesData = (collaboratorId, benefits) => ({
  salary_attributes: {
    id: collaboratorId,
    payroll_items_salaries_attributes: benefits.map((item) => {
      return {
        payroll_item_id: item.payroll_item_id,
        value: item.value,
      };
    }),
  },
});
