import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { hasPreviewImage } from "common/utils";
import LoaderImageProfile from "../LoaderImageProfile";
import {
  StyledTeamList,
  StyledEmailIcon,
} from "../../styles";

const TeamDetail = (props) => {
  const { team } = props;
  const { t } = useTranslation(["onboardingFollowUp"]);

  const getProfileImage = (collaborator) => (hasPreviewImage(collaborator)
    ? <LoaderImageProfile collaborator={ collaborator } component={ Avatar } />
    : <Avatar src={ collaborator.profile_img_url } />);

  const getCollaboratorSubtitle = (collaborator) => (collaborator.isManager ? t("team.manager") : collaborator.position_name);

  return (
    team.map((collaborator) => (
      <StyledTeamList key={ collaborator.id }>
        <Box pb={ 4 }>
          {getProfileImage(collaborator)}
        </Box>
        <Box pl={ 2 }>
          <h4>{getCollaboratorSubtitle(collaborator)}</h4>
          <h3>{collaborator.full_name}</h3>
          <StyledEmailIcon />
          <a href={ `mailto:${collaborator.email}` } >{collaborator.email}</a>
        </Box>
      </StyledTeamList>
    ))
  );
};

TeamDetail.propTypes = {
  team: PropTypes.array.isRequired,
};

export default TeamDetail;
