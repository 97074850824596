import styled from "styled-components";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import palette from "theme/palette";

const getBgColor = ({ year, active }) => {
  if (year) return palette.text.transparent;
  return active ? palette.background.darkGrey : palette.background.iconBtn;
};

const getColor = ({ year, active }) => {
  if (year) return palette.text.link;
  return active ? palette.white : palette.black;
};

export const StyledTableRow = styled(TableRow)`
    &.MuiTableRow-root {
        display: flex;
        justify-content: space-between;
        border-bottom: ${palette.border.strong};
        padding: ${(props) => (props.year ? "4px 0 4px 4px" : "2px 0 2px 35px")};
    }
`;

export const StyledTableCell = styled(TableCell)`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: ${(props) => (props.goals ? "35px" : "10px")};
    padding-top: 0;
    padding-bottom: 0;
    border: none;
`;

export const StyledChip = styled(Chip)`
  background-color: ${getBgColor} !important;
  color: ${getColor} !important;
  font-size: ${(props) => (props.year ? "16px" : "14px")};
  font-weight: ${(props) => (props.year ? "700" : "400")};
  height: auto;
  min-width: ${(props) => (props.year ? "auto" : "85px")};
  border-radius: 20px;
  transition: color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  .MuiChip-label {
    padding: 0;
  }
`;

export const StyledIconButton = styled(IconButton)`
    &.MuiIconButton-root {
        color: ${(props) => (props.year ? palette.text.link : palette.text.iconDisabled)} !important;
    }
`;

export const StyledLabel = styled.span`
    font-size: ${(props) => (props.small ? "12px" : "14px")};
    font-weight: ${(props) => (props.bold === 1 ? "700" : "400")};
    min-width: 80px;
    text-align: ${(props) => (props.small ? "right" : "left")};
    cursor: pointer;
`;

export const StyledProgressContainer = styled.div`
    width: 100%;
    min-width: 150px;
`;
