import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "components/Select";
import { OBJECT_KEYS, VARIANT } from "common/constants";
import goodLeaderImage from "assets/images/performance/good_leader.svg";
import { useStyles } from "./styles";

const Header = (props) => {
  const {
    evaluation, evaluations, handleChange, goodLeaderProcess,
  } = props;
  const { t } = useTranslation("performance");
  const classes = useStyles();

  return (
    <Grid container className={ classes.header } data-testid={ "good-leader-header" }>
      <Grid item xs={ 12 } md={ 9 }>
        <Box className={ classes.classification }>
          <Box pr={ 1 }>
            <img src={ goodLeaderImage } alt={ t("leaderEvaluation") } />
          </Box>
          <Box>
            <Typography variant={ VARIANT.h5 }>{t("leaderEvaluation")}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={ 12 } md={ 3 }>
        <Select
          id={ OBJECT_KEYS.evaluation }
          label={ t("dashboard.filter_evaluation") }
          menuItems={ evaluations }
          value={ evaluation }
          onChange={ handleChange }
          disabled={ isEmpty(goodLeaderProcess) }
        />
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  evaluation: PropTypes.number.isRequired,
  evaluations: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  goodLeaderProcess: PropTypes.array.isRequired,
};

export default Header;
