import PropTypes from "prop-types";
import ProfileInfo from "components/ProfileInfo";
import DownloadExcelButton from "components/DownloadExcelButton";
import TableGrid from "components/TableGrid";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { ROWS_RANGE_PER_PAGE } from "common/constants";
import { mainDownloadExcel } from "common/utils";
import useAudienceList from "./hooks/useAudienceList";
import { StyledModal } from "./styles";
import { getAudienceDataToDownload } from "../../../../functions";

const ModalAudience = (props) => {
  const {
    t,
    isOpen,
    handleCloseModal,
    audienceSelected,
  } = props;

  const {
    audienceList,
    isLoadingAudienceList,
    isFetchingAudienceList,
  } = useAudienceList(audienceSelected.id, isOpen);

  const getProfile = (employee) => (
    <ProfileInfo
      key={ employee.id }
      collaborator={ employee }
      isShortVersion
      hasStatus
    />
  );

  const header = [
    {
      id: "full_name",
      label: t("tables:headers.name"),
      customRender: (rowData) => getProfile(rowData),
    },
  ];

  // Handle download Excel
  const handleDownloadExcel = () => {
    const dataToDownload = getAudienceDataToDownload(audienceList, t);
    mainDownloadExcel(dataToDownload, t("planning:modal.audience"));
  };

  return (
    <StyledModal
      isOpen={ isOpen }
      onClose={ handleCloseModal }
      title={ `${t("planning:modal.audience")} (${audienceSelected?.employees_count})` }
    >
      <StyledScrollBar maxHeight={ "650px" } minWidth={ "650px" }>
        <TableGrid
          rows={ audienceList }
          id={ "collaborator-table-grid" }
          header={ header }
          paginationOptions={ {
            maxPerPage: ROWS_RANGE_PER_PAGE[0],
            rowsRange: ROWS_RANGE_PER_PAGE,
          } }
          topContent={ (
            <DownloadExcelButton
              onClick={ handleDownloadExcel }
              isDisabled={ audienceList.length === 0 || isFetchingAudienceList }
            />
          ) }
          isLoading={ audienceList.length === 0 || isLoadingAudienceList }
          isDisabledSearch={ audienceList.length === 0 }
          searchBy={ "full_name" }
        />
      </StyledScrollBar>
    </StyledModal>
  );
};

ModalAudience.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  audienceSelected: PropTypes.object,
};

ModalAudience.defaultProps = {
  audienceSelected: undefined,
};

export default ModalAudience;
