import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import palette from "theme/palette";

export const StyledHeaderCalendar = styled(Grid)`
  padding: 0px 5px 0px 5px;
  margin-top: 20px;
`;

export const StyledActions = styled(Grid)`
  text-align: right;
`;

export const StyledButton = styled(Button)`
  text-transform: capitalize;
  color: ${palette.input.borderControl};
  border: ${palette.border.darkGray};

  &:hover {
    border: ${palette.border.darkGray};
  }
`;

export const StyledBox = styled(Box)`
  margin: 0px 10%;
  @media (max-width: 1420px) {
    margin: 0px;
  }
`;

export const StyledEventsTitle = styled(Typography)`
  padding-bottom: 10px;
`;

export const StyledTitleEvents = styled(Typography)`
  padding: 10px;
  font-weight: 700;
`;

export const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  color: ${palette.background.empty};
  margin: 20px;
`;

export const StyledIcon = styled.div`
  background: white;
  border-radius: 30px;
  padding: 10px;
  height: 60px;
`;

export const StyledSubtitleEvent = styled.div`
  color: ${palette.white};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const StyledDetail = styled(Box)`
  margin: 18px 28px 25px;
  .MuiGrid-spacing-xs-2 {
    margin: 0px;
  }
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 0px;
  }
`;

export const StyledEvents = styled.div`
  padding: 1px 0.4px 10px 5px;
  overflow: auto;
  overflow-x: clip;
  max-height: calc(100vh - 20vh);
  min-height: 50vh;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${palette.background.mediumGrey};
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: ${palette.scroll.thumbActive};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${palette.scroll.thumbHover};
    box-shadow: ${palette.scroll.boxShadow};
  }
  ::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track:hover, ::-webkit-scrollbar-track:active {}
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: ${palette.scroll.firefoxColor};
  .MuiAccordionDetails-root {
    display: block;
  }
`;

export const StyledHeader = styled.div`
  margin-left: 16px;
  line-height: 36px;
`;
