import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SimpleAlert from "components/SimpleAlert";
import Button from "components/Button";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import {
  LOCAL_STORAGE_NAMES,
  WARNING,
  VARIANT,
  BUTTON_STYLE_TYPES,
  TIMEOUT_REDIRECT,
  ALIGN_ITEMS,
} from "common/constants";
import { StyledNotification } from "./styles";

const UpdateNotification = () => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  const resetSession = () => {
    setIsLoading(true);
    localStorage.clear();
    const toastMessage = {
      title: t("notification.updateProcess.title"),
      message: t("notification.updateProcess.description"),
    };
    toast(MESSAGE_TYPES.info, toastMessage);
    setTimeout(() => {
      window.location.reload(true);
    }, TIMEOUT_REDIRECT);
  };

  return localStorage.getItem(LOCAL_STORAGE_NAMES.newUpdate)
    && (
      <StyledNotification>
        <SimpleAlert
          type={ WARNING }
        >
          <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
            <Box pr={ 2 }>
              <Typography variant={ VARIANT.h6 }>
                { t("notification.updateProcess.message") }
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={ () => resetSession() }
                variant={ VARIANT.contained }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                isDisabled={ isLoading }
              >
                { t("common.update") }
              </Button>
            </Box>
          </Box>
        </SimpleAlert>
      </StyledNotification>
    );
};

export default UpdateNotification;
