import { useQuery, useQueryClient } from "react-query";
import { getPositionList } from "redux/actions/position/positionActions";

const fetchPositions = async () => {
  const response = await getPositionList();
  return response;
};

export const usePosition = () => {
  const queryClient = useQueryClient();

  const { data: positions, isLoading, isError } = useQuery("positions", fetchPositions, {
    staleTime: Infinity,
  });

  return {
    positions,
    isLoading,
    isError,
    queryClient,
  };
};
