import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "components/Button";
import palette, { STATE_COLORS } from "theme/palette";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  downloadButton: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.text.link,
    textTransform: "none",
    padding: 0,
    "&:hover": {
      background: "none",
      color: theme.palette.text.secondary,
    },
  },
}));

export const StyledCard = styled(Card)`
  margin-top: 32px;
  .MuiCardContent-root {
    padding: 24px;
  }
`;

export const StyledCardTitle = styled(Typography)`
  font-weight: 700;
  margin-bottom: 15px;
`;

export const StyledAlert = styled(Box)`
  .MuiAlert-root{
    width: 95%;
  }
  @media (max-width: 540px) {
    .MuiAlert-root{
      width: 100%;
    }
  }
`;

export const StyledBoxFile = styled(Box)`
  .MuiButton-root {
    width: 50%;
  }
  @media (max-width: 540px) {
    display: block;
    .MuiButton-root {
      width: 100%;
    }
  }
`;

export const StyledFileName = styled(Box)`
  @media (max-width: 540px) {
    margin-top: 8px;
    padding-left: 0;
  }
`;

export const StyledName = styled(Typography)`
  font-weight: 500;
`;

export const StyledUploadTitle = styled(Typography)`
  font-weight: 700;
  margin-bottom: 15px;
`;

export const StyledBoxProgress = styled(Box)`
  width: 60%;
`;

export const StyledIconButton = styled(IconButton)`
  color: ${palette.btnIcon.light};
  padding: 0;
  :hover {
    color: ${STATE_COLORS.ERROR}
  }
`;

export const StyledInfoGrid = styled(Grid)`
  .MuiAlert-root{
    padding: 16px;
    margin-top: 16px;
  }
`;

export const StyledSaveAltIcon = styled(SaveAltIcon)`
  font-size: 0.8rem;
  margin-left: 4px;
`;

export const StyledErrorItem = styled.ul`
  padding: 4px;
  font-size: 12px;
  margin-left: 8px;
`;

export const StyledPreviewGrid = styled(Grid)`
  margin-bottom: 30px;
  .MuiSvgIcon-root{
    color: ${STATE_COLORS.ERROR};
    width: 14px;
    cursor: pointer;
    height: 14px;
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 rgb(255 18 8);
    border-radius: 50%;
  }
  .MuiTableCell-head{
    font-size: 13px;
    min-width: 180px;
    background: ${palette.background.light};
  }
  .MuiTableCell-root {
    font-size: 13px;
  }
  .MuiTableContainer-root {
    padding: 0;
    overflow: auto;
    overflow-x: scroll;
    scrollbar-color: ${palette.scroll.firefoxColor};
    scrollbar-height: thin;
    td:first-child {
      padding-left: 8px;
    }
    th:first-child {
      min-width: 100px;
    }
    td {
      padding: 4px;
      padding-left: 8px;
    }
    ::-webkit-scrollbar {
      height: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: #75768F;
      border-radius: 7px;
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: #9a9a9a;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #4c4c4c;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }
    ::-webkit-scrollbar-track {
      border-radius: 7px;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 2%;
`;
