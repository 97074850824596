import styled from "styled-components";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import palette from "theme/palette";

export const StyledButton = styled(Button)`
  &.submit {
    background: ${palette.text.link};
    color: ${palette.white};
    .MuiSvgIcon-root {
      color: ${palette.white};
    }
    &:hover {
      background: ${palette.text.linkHover};
    }
    &:disabled {
      background: ${palette.background.lightGrey};
      color: ${palette.background.gray};
    }
  }
  &.cancel {
    background: ${palette.background.lightGrey};
    color: ${palette.background.gray};
    margin-right: 12px;
  }
  &.back {
    background-color: ${palette.background.lightGrey};
    color: ${palette.background.darkGrey};
  }
  &.outlined {
    border: 1px solid ${palette.text.link};
    color: ${palette.text.link};
    text-transform: none;
    & input {
      display: none;
    }
    .MuiSvgIcon-root {
      color: ${palette.text.link};
    }
    &.Mui-disabled{
      color: ${palette.background.gray};
      border: 1px solid ${palette.background.gray};
      svg {
        color: ${palette.background.gray};
      }
    }
  }
  &.disabled{
    background-color: rgba(0, 0, 0, 0.12);
  }
  &.general {
    text-transform: none;
  }
  &.dialogActions{
    padding: 6px 16px;
    font-size: 11px;
  }
  .buttonIconContainer {
    display: flex;
    align-items: center;
    .left {
    margin-right: 10px;
    margin-top: 5px;
    }
    .right {
      margin-left: 10px;
      margin-top: 5px;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .MuiButton-root.Mui-disabled{
    min-width: 200px !important;
    z-index: 9999;
  }
`;
