import { groupBy } from "lodash";
import {
  EVENT_STATE_KEYS, FULLDATE_FORMATS, SURVEY_PROCESS_TYPE,
} from "common/constants";
import {
  formatDate, getCurrentLanguage, getPercent,
} from "common/utils";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { getPeersEvaluationText } from "common/validators";
import { getProcessTypes } from "views/Planning/functions";

export const MIN_WIDTH = 20;

export const MAX_EMPLOYEES = 5;

export const EVALUATION_STATES = {
  pending: "pending",
  answered: "answered",
  drafted: "drafted",
};

const EMPLOYEES_VISIBLES = {
  [MAX_EMPLOYEES]: 1,
  [MAX_EMPLOYEES - 1]: 2,
  [MAX_EMPLOYEES - 2]: 3,
  [MAX_EMPLOYEES - 3]: 4,
  [MAX_EMPLOYEES - 4]: 5,
};

export const getLabelByType = (t) => ({
  [EVALUATION_TYPE.AUTOEVALUATION]: t("surveys:evaluation_types.self_evaluation"),
  [EVALUATION_TYPE.COLLABORATORS]: t("surveys:evaluation_types.manager"),
  [EVALUATION_TYPE.MANAGER]: t("surveys:evaluation_types.collaborators"),
  [EVALUATION_TYPE.PAIRS]: getPeersEvaluationText(t),
  [EVALUATION_TYPE.OVERALL]: t("surveys:evaluation_types.overall"),
});

export const getDescriptionBySurvey = (t) => ({
  [SURVEY_PROCESS_TYPE.performance.key]: t("surveys:processes_types_description.performance"),
  [SURVEY_PROCESS_TYPE.potential.key]: t("surveys:processes_types_description.potential"),
  [SURVEY_PROCESS_TYPE.goodLeader.key]: t("surveys:processes_types_description.good_leader"),
  [SURVEY_PROCESS_TYPE.engagement.key]: t("surveys:processes_types_description.engagement"),
  [SURVEY_PROCESS_TYPE.training.key]: t("surveys:processes_types_description.training"),
});

export const hasMoreEmployees = (data) => {
  if ((data.type === EVALUATION_TYPE.MANAGER || data.type === EVALUATION_TYPE.COLLABORATORS)
    && data.show < data.employees.length) {
    return true;
  } if (data.type === EVALUATION_TYPE.PAIRS
    && data.employees.length > 0) {
    return true;
  }
  return false;
};

export const getSurveyDetail = (process, evaluationResults, t) => {
  const lang = getCurrentLanguage();
  const startDate = new Date(formatDate(process?.start_date, FULLDATE_FORMATS.slash_calendar));
  const endDate = new Date(formatDate(process?.date, FULLDATE_FORMATS.slash_calendar));
  const endDateWithTime = formatDate(process?.finishes_at, FULLDATE_FORMATS.slashTime);
  const processType = getProcessTypes(t).find((item) => process.type === item.key);
  const isComplete = process?.process_detail?.status === EVENT_STATE_KEYS.complete
    && evaluationResults.find((result) => result.state === EVALUATION_STATES.answered);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const isFinished = today.valueOf() > new Date(endDate).valueOf();

  return {
    id: process?.id,
    type: process?.type,
    startDate,
    endDate,
    endDateWithTime,
    name: process[`name_${lang}`],
    processType,
    processDetail: process?.process_detail,
    hasNalaForm: process?.has_nala_form,
    isComplete,
    isFinished,
  };
};

const generateLink = (evaluationId, evaluationResultId = null) => `/evaluation-form/${evaluationId}${evaluationResultId
  ? `?evaluation_result=${evaluationResultId}` : ""}`;

const formatEmployee = (evaluationResult) => ({
  id: evaluationResult?.evaluated_id,
  name: evaluationResult?.employee?.full_name,
  profile_img_url: evaluationResult?.employee?.profile_img_url,
  link: generateLink(evaluationResult.evaluation_id, evaluationResult.id),
  isPending: evaluationResult?.state !== EVALUATION_STATES.answered,
  evaluationResultId: evaluationResult?.id,
});

const getEmployees = (type, evaluationResults) => {
  const employees = [];
  if (type === EVALUATION_TYPE.AUTOEVALUATION
    || type === EVALUATION_TYPE.OVERALL
  ) {
    employees.push(formatEmployee(evaluationResults[0]));
  } else if (type === EVALUATION_TYPE.MANAGER
    || type === EVALUATION_TYPE.PAIRS
    || type === EVALUATION_TYPE.COLLABORATORS) {
    evaluationResults.forEach((result) => {
      employees.push(formatEmployee(result));
    });
  }
  return employees.sort((first, second) => second.isPending - first.isPending);
};

export const getProcessList = (t, surveyProcess, allProcess, isMobile) => {
  const list = [];
  const pendingProcess = surveyProcess?.process_detail?.evaluations;
  const labels = getLabelByType(t);
  const processByType = groupBy(allProcess, (item) => item.evaluation_id);
  const evaluationIds = Object.keys(processByType);
  const evaluatedList = allProcess.map((process) => process.employee?.id);
  evaluationIds.forEach((evaluationId) => {
    const type = processByType[evaluationId][0]?.evaluation_type;
    const employees = getEmployees(type, processByType[evaluationId]);
    const linkForEvaluateAll = type === EVALUATION_TYPE.MANAGER && employees?.length > 0
      ? `/evaluation-form/${evaluationId}?process_id=${surveyProcess.id}` : null;
    list.push({
      id: evaluationId,
      evaluatedList,
      type,
      label: labels[type],
      employees,
      link: type === EVALUATION_TYPE.MANAGER ? linkForEvaluateAll : generateLink(
        evaluationId,
        type === EVALUATION_TYPE.PAIRS ? null : processByType[evaluationId][0]?.id,
      ),
      isCompleted: !employees.find((employee) => employee.isPending),
      show: type === EVALUATION_TYPE.MANAGER && !isMobile
        ? EMPLOYEES_VISIBLES[evaluationIds.length] : 1,
    });
  });
  const hasPeerEvaluation = list.find((item) => item.type === EVALUATION_TYPE.PAIRS);
  if (!hasPeerEvaluation) {
    const peerEvaluation = pendingProcess.find(
      (item) => item.evaluation_type === EVALUATION_TYPE.PAIRS
    );
    if (peerEvaluation) {
      list.push({
        id: peerEvaluation.evaluation_id,
        evaluatedList,
        type: peerEvaluation.evaluation_type,
        label: labels[peerEvaluation.evaluation_type],
        employees: [],
        link: generateLink(peerEvaluation.evaluation_id),
        isCompleted: peerEvaluation?.is_completed || null,
        show: 1,
      });
    }
  }
  return list;
};

export const getPercentCompleted = (evaluationResults) => {
  const total = evaluationResults.length;
  const completed = evaluationResults.filter(
    (result) => result.state === EVALUATION_STATES.answered,
  ).length;
  return total > 0 ? getPercent(completed / total) : 0;
};

/**
 * @param {Array} selected - List of selected employees
 * @param {Number} totalTeamEmployees - Total of employees in team
 * @param {Array} allEmployees - List of all employees
 * @param {Object} data - process data
 * @param {Number} processId - Survey process id
 * @returns {String} - Link to redirect
 */
export const getLinkFromTeamEvaluation = (
  selected, totalTeamEmployees, allEmployees, data, processId,
) => {
  const arrayToString = selected.join(",");
  let link = selected.length > 0
    ? `/evaluation-form/${data.id}?process_id=${processId}&selected=${arrayToString}`
    : null;

  if (selected.length === totalTeamEmployees) {
    link = data.link;
  }

  if (selected.length === 1) {
    link = allEmployees.filter(
      (employee) => employee.evaluationResultId === selected[0]
    )[0].link;
  }

  return link;
};

/**
 * @param {Boolean} isTeamEvaluation - If the evaluation is team evaluation
 * @param {Array} employees - List of employees
 * @returns {Array} - List of all team employees
 */
export const getAllTeamEmployees = (isTeamEvaluation, employees) => (isTeamEvaluation
  ? employees?.filter(
    (employee) => employee.isPending,
  ).map((employee) => employee.evaluationResultId)
  : []);
