import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoCompleteCreable from "components/AutocompleteCreable";
import AlertDialog from "components/AlertDialog";
import TablePagination from "components/TablePagination";
import CircleLoader from "components_refactor/CircleLoader";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import { isNullOrUndefined } from "common/helpers";
import {
  INPUT_TYPE,
  ALIGN_ITEMS,
  INDEX,
  OBJECT_KEYS,
  VARIANT,
  TYPES,
  ACCEPTED_FILE_FORMATS,
  COMPONENT,
  TARGET,
  BUTTON_STYLE_TYPES,
  SIZE,
} from "common/constants";
import { isAdmin, isCandidate } from "common/utils";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import {
  getValidDocuments,
  getHeader,
  getRowsMobile,
  getRowsDesktop,
} from "views_refactor/Profile/functions/documents";
import {
  StyledCardContent,
  StyledTableContainer,
  StyledUploadFileContainer,
  StyledButtonFile,
  StyledIconButton,
  StyledUpload,
  StyledDialogItemActions,
} from "./styles";

const DocumentsComponent = (props) => {
  const { t, documentsHookInfo } = props;
  const {
    documentsStates,
    defaultValue,
    collaboratorDocument,
    documentsType,
    candidate,
    candidateValidation,
    documentsFunctions,
    user,
    hookForm,
  } = documentsHookInfo;

  const {
    currentCollaboratorId,
    selectDocument,
    dialog,
    file,
  } = documentsStates;

  const {
    listCollaboratorDocument,
    isLoadingListCollaboratorDocument,
    isLoadingProcessCollaboratorDocument,
  } = collaboratorDocument;

  const { listDocumentTypes, isLoadingListDocumentTypes } = documentsType;

  const {
    handleSelect,
    handleDialog,
    handleSubmitDocumentType,
    handleNew,
    handleDeleteDocument,
    handleUpdateDocument,
    handleUpload,
    handleRemoveFile,
    onSubmit,
  } = documentsFunctions;

  const { control, watch } = hookForm;

  const fileName = file?.name && (
    <>
      <Box>
        <Typography
          variant={ VARIANT.bodyTwo }
          component={ COMPONENT.span }
        >
          <Link href={ file?.url } target={ TARGET.blank }>
            {file?.name}
          </Link>
        </Typography>
      </Box>
      <Box>
        <StyledIconButton
          onClick={ handleRemoveFile }
        >
          <DeleteIcon />
        </StyledIconButton>
      </Box>
    </>
  );

  return (
    <>
      <StyledCardContent>
        <Grid container alignItems={ ALIGN_ITEMS.center } spacing={ 2 }>
          {isAdmin(user?.userCookies) && (
            <>
              <Grid item xs={ 12 }>
                <StyledUpload>
                  <Box pr={ INDEX.one }>
                    <img alt={ t("account:accountDocuments.general_title") } src={ uploadImg } />
                  </Box>
                  <Box flexGrow={ INDEX.one }>
                    <Typography variant={ VARIANT.h6 }>
                      {t("account:accountDocuments.general_title")}
                    </Typography>
                    <Typography variant={ VARIANT.bodyTwo }>
                      {t("account:accountDocuments.upload_file_description")}
                    </Typography>
                  </Box>
                </StyledUpload>
              </Grid>
              <Grid item xs={ 12 } md={ 5 }>
                <AutoCompleteCreable
                  id={ OBJECT_KEYS.documentType }
                  options={ listDocumentTypes || [] }
                  label={ t("account:accountDocuments.select_document_type") }
                  onChange={ handleSelect }
                  value={ defaultValue.name }
                  isDisabled={ isLoadingListDocumentTypes }
                  nameOfAttr={ OBJECT_KEYS.name }
                  size={ SIZE.small }
                  addNew={ {
                    handleNew: handleSubmitDocumentType,
                    text: t("account:accountDocuments.add_new_document_type"),
                    prop: OBJECT_KEYS.documentType,
                  } }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 7 }>
                <StyledUploadFileContainer>
                  <StyledButtonFile
                    variant={ VARIANT.outlined }
                    typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                    component={ COMPONENT.label }
                    isDisabled={ watch(OBJECT_KEYS.url) }
                  >
                    {t("templates:sections.form.upload_file")}
                    <input
                      accept={ ACCEPTED_FILE_FORMATS }
                      type={ INPUT_TYPE.file }
                      onChange={ handleUpload }
                    />
                  </StyledButtonFile>
                  { fileName }
                </StyledUploadFileContainer>
              </Grid>
              <Grid item xs={ 12 } md={ 9 }>
                <InputTextController
                  type={ TYPES.text }
                  label={ t("templates:sections.form.url") }
                  control={ control }
                  name={ OBJECT_KEYS.url }
                  disabled={ file?.name }
                  size={ SIZE.small }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 3 }>
                <StyledDialogItemActions>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    onClick={ onSubmit }
                    isDisabled={ isNullOrUndefined(selectDocument) }
                  >
                    { t("common:common.save") }
                  </Button>
                </StyledDialogItemActions>
              </Grid>
            </>
          )}
          <Grid item xs={ 12 }>
            {isLoadingListCollaboratorDocument ? (
              <CircleLoader />
            ) : (
              <StyledTableContainer>
                <TablePagination
                  header={ getHeader(isAdmin(user?.userCookies)) }
                  list={ isCandidate(user?.userCookies) || candidateValidation
                    ? getValidDocuments(candidate.requested_documents)
                    : getValidDocuments(listCollaboratorDocument) }
                  isLoading={ isLoadingProcessCollaboratorDocument }
                  getRows={ {
                    mobile: getRowsMobile,
                    desktop: getRowsDesktop,
                  } }
                  extraParam={ user }
                  modalOptions={ [
                    handleDeleteDocument,
                    handleUpdateDocument,
                    currentCollaboratorId,
                  ] }
                />
              </StyledTableContainer>
            )}
          </Grid>
        </Grid>
      </StyledCardContent>
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => handleDialog(false) }
        title={ t("account:accountDocuments.add_new_document_type") }
        message={ t("common:common.modal_messages.sure_text") }
        onSubmit={ handleNew }
        buttons={ {
          isLoading: false,
        } }
      />
    </>
  );
};

DocumentsComponent.propTypes = {
  t: PropTypes.func.isRequired,
  documentsHookInfo: PropTypes.object.isRequired,
};

export default DocumentsComponent;
