import isEmpty from "lodash/isEmpty";
import { ReactComponent as Great } from "assets/images/talent-drain/great.svg";
import { ReactComponent as TakeAction } from "assets/images/talent-drain/take_an_action.svg";
import { ReactComponent as Warning } from "assets/images/talent-drain/warning.svg";
import { ReactComponent as Critic } from "assets/images/talent-drain/critic.svg";
import {
  INFO, WARNING, ERROR, SUCCESS, CRITIC, INDEX,
} from "common/constants";


const isTakeAction = (value) => {
  return value >= 60 && value < 80;
};

const isAlarming = (value) => {
  return value >= 40 && value < 60;
};

const isCritic = (value) => {
  return value >= 0 && value < 40;
};

export const getTooltipIcon = (value) => {
  if (isTakeAction(value)) {
    return <TakeAction className={ INFO } />;
  }

  if (isAlarming(value)) {
    return <Warning className={ WARNING } />;
  }

  if (isCritic(value)) {
    return <Critic className={ ERROR } />;
  }

  return <Great className={ SUCCESS } />;
};

export const getTooltipTitle = (value, t) => {
  const titles = [
    t("talentDrain:chart.tooltipsContent.titles.critic"),
    t("talentDrain:chart.tooltipsContent.titles.warning"),
    t("talentDrain:chart.tooltipsContent.titles.info"),
    t("talentDrain:chart.tooltipsContent.titles.success"),
  ];

  if (isTakeAction(value)) {
    return titles[INDEX.two];
  }

  if (isAlarming(value)) {
    return titles[INDEX.one];
  }

  if (isCritic(value)) {
    return titles[INDEX.zero];
  }

  return titles[INDEX.three];
};

export const getTooltipRecommendation = (value, label, t) => {
  const recommendation = "";
  let type = "";
  const vertices = {
    label,
    messages: {
      critic: t(`talentDrain:chart.tooltipsContent.${label}.critic`),
      warning: t(`talentDrain:chart.tooltipsContent.${label}.warning`),
      info: t(`talentDrain:chart.tooltipsContent.${label}.info`),
    },
  };

  if (isTakeAction(value)) {
    type = INFO;
  } else if (isAlarming(value)) {
    type = WARNING;
  } else if (isCritic(value)) {
    type = CRITIC;
  }

  if (!isEmpty(type)) {
    return vertices.messages[type];
  }

  return recommendation;
};
