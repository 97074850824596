import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { TARGET } from "common/constants";
import { getFilteredFAQsList } from "./functions";
import { StyledMenuItem, StyledContainer } from "./styles";

const FAQs = () => {
  const { t, i18n } = useTranslation(["common"]);
  const { language } = i18n;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredFAQsList = getFilteredFAQsList(t, language);

  return (
    <StyledContainer>
      <Button aria-controls={ "support-menu" } aria-haspopup={ "true" } onClick={ handleClick }>
        <ContactSupportIcon />
      </Button>
      <Menu
        id={ "support-menu" }
        anchorEl={ anchorEl }
        keepMounted
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
      >
        { filteredFAQsList.map((item) => (
          <a href={ item.href } target={ TARGET.blank } onClick={ handleClose } key={ item.name }>
            <StyledMenuItem>
              {item.name}
            </StyledMenuItem>
          </a>
        ))}
      </Menu>
    </StyledContainer>
  );
};

export default FAQs;
