import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import palette from "theme/palette";

export const StyledText = styled(Typography)`
  text-align: left;
`;

export const StyledButton = styled(Button)`
  color: ${palette.background.grayBox};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  text-decoration: underline;
  :hover {
    background: none;
    text-decoration: underline;
  }
`;

export const StyledModalButton = styled(Button)`
  color: ${palette.primaryApp};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  text-decoration: underline;
  :hover {
    background: none;
    text-decoration: underline;
  }
`;

export const StyledCancelButton = styled(Button)`
  color: ${palette.background.grayBox};
  font-size: 14px;
  cursor: pointer;
  min-width: 20px;
  border-radius: 50px;
  padding: 5px;
  svg {
    font-size: 14px;
  }
`;

export const CriticalInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .cancel {
    margin-right: 0;
  }
  .critical-details {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    p {
      font-size: 12px;
      text-align:left;
    }
  }
`;
