import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { VARIANT } from "common/constants";
import MatrixIcon from "assets/images/ninebox/bill.svg";
import { getCurrentLanguage } from "common/utils";
import LeadershipTypeEmployee from "../LeadershipTypeEmployee";
import {
  StyledMatrixTitle,
  StyledDescription,
  StyledContainer,
  StyledDataList,
} from "./styles";

const LeadershipResult = (props) => {
  const {
    t,
    leadershipStates,
  } = props;

  const {
    leadershipResult,
    quadrantResult,
  } = leadershipStates;

  const language = getCurrentLanguage();

  const title = quadrantResult
    ? (
      <Typography variant={ VARIANT.h4 }>
        {t("leadership:leadership_type")}
      </Typography>
    )
    : (
      <StyledMatrixTitle>
        <img src={ MatrixIcon } alt={ "Leadership" } />
        <Typography variant={ VARIANT.h4 }>
          {t("leadership:quadrant.all.title")}
        </Typography>
      </StyledMatrixTitle>
    );

  const subtitle = quadrantResult ? quadrantResult[`name_${language}`] : t("leadership:quadrant.all.subtitle");
  const description = quadrantResult ? quadrantResult[`description_${language}`] : t("leadership:quadrant.all.description");

  return (
    <StyledContainer>
      {title}
      <StyledDescription variant={ quadrantResult ? VARIANT.h5 : VARIANT.body1 }>
        {subtitle}
      </StyledDescription>
      <StyledDescription variant={ VARIANT.body1 }>
        {description}
      </StyledDescription>
      {/* Displaying results based on selected quadrant */}
      {quadrantResult && leadershipResult
        && (
          <>
            <StyledDataList>
              <Typography variant={ VARIANT.h4 }>
                {`${t("leadership:quadrant.inThisBox")}:`}
              </Typography>
            </StyledDataList>
            <LeadershipTypeEmployee { ...props } />
          </>
        )}
    </StyledContainer>
  );
};

LeadershipResult.propTypes = {
  t: PropTypes.func.isRequired,
  leadershipStates: PropTypes.object.isRequired,
};

export default LeadershipResult;
