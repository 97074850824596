import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Switch from "components/Switch";
import { switchOptions } from "common/utils";

const SwitchView = (props) => {
  const { handleChangeSelection } = props;
  const { t } = useTranslation("performance");
  const options = [
    switchOptions(t("viewOptions.categories"), 0),
    switchOptions(t("viewOptions.questions"), 1),
  ];

  return (
    <Grid item xs={ 12 } md={ 4 } data-testid={ "good-leader-switch-view" } >
      <Switch
        options={ options }
        onChange={ handleChangeSelection }
      />
    </Grid>
  );
};

SwitchView.propTypes = {
  handleChangeSelection: PropTypes.func.isRequired,
};

export default SwitchView;
