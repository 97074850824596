import useComplexState from "hooks/utils/useComplexState";
import { TIMEOUT_TRANSITION } from "common/constants";

export const useLeadershipHook = () => {
  const [leadershipStates, setLeadershipStates] = useComplexState({
    leadershipResult: null,
    quadrantResult: null,
    dataBox: null,
    circleSelected: null,
    accordionSelected: undefined,
    selectedQuadrant: null, // all
    selectedPanel: null,
    anchorEl: null,
    isLoading: false,
    employeeGroup: null,
  });

  const resetQuadrants = () => {
    setLeadershipStates({
      circleSelected: null,
      accordionSelected: null,
      selectedPanel: null,
      selectedQuadrant: null,
    });
  };

  // When a filter is applied, the query is received for use in the Matrix component.
  const handleExternalQuery = (newQuery) => {
    setLeadershipStates({ query: newQuery });
  };

  // Quadrant data
  const getQuadrantDetail = (result) => {
    setLeadershipStates({
      quadrantResult: [],
    });
    setTimeout(() => {
      setLeadershipStates({
        setLeadershipStates: null,
        accordionSelected: null,
        selectedPanel: null,
        quadrantResult: result,
      });
    });
  };

  const handleCircleSelected = (result) => {
    resetQuadrants();
    setTimeout(() => {
      setLeadershipStates({
        circleSelected: result.index,
        accordionSelected: result.index,
        selectedPanel: result,
        quadrantResult: result.quadrantData,
        selectedQuadrant: result.quadrantData.index,
      });
    }, TIMEOUT_TRANSITION);
  };

  const handleEmployeeList = (event, result) => {
    if (result) setLeadershipStates({ employeeGroup: result });
    setLeadershipStates({ anchorEl: leadershipStates.anchorEl ? null : event.currentTarget });
  };

  const handleCircleAndReset = (e, result) => {
    handleEmployeeList(e);
    handleCircleSelected(result);
  };

  return {
    leadershipStates,
    setLeadershipStates,
    handleExternalQuery,
    getQuadrantDetail,
    resetQuadrants,
    handleCircleSelected,
    handleEmployeeList,
    handleCircleAndReset,
  };
};
