import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
// import Button from "components/Button";
import { VARIANT /* ALIGN_ITEMS, SIZE, TYPES */ } from "common/constants";
import EvaluationDetail from "../EvaluationDetail";
import DefinitionResults from "../DefinitionResults";
import { getPersonalityData } from "../../../../functions/potential";
import PersonalitySpiderChart from "./components/PersonalitySpiderChart";
import { getFormattedObject } from "./components/PersonalitySpiderChart/functions";
import { StyledTitle } from "./styles";

const PersonalityTestChart = (props) => {
  const { data } = props;
  const { t } = useTranslation(["potential", "common"]);

  const getDefinitionResult = () => {
    let dataToUse = {};
    if (!_.isUndefined(data) && !_.isEmpty(data)) {
      dataToUse = getPersonalityData(data);
    }
    return dataToUse;
  };

  return (
    <Grid container spacing={ 3 } data-testid={ "personality-test-chart" }>
      <Grid item xs={ 12 }>
        <Typography variant={ VARIANT.h4 }>
          {t("potential:chart.personality_test_title")}
        </Typography>
        <StyledTitle variant={ VARIANT.bodyOne }>
          {t("potential:chart.personality_test_description")}
        </StyledTitle>
      </Grid>
      <Grid item xs={ 12 } md={ 7 }>
        <PersonalitySpiderChart dataFormatted={ getFormattedObject(data) } />
      </Grid>
      <Grid item xs={ 12 } md={ 5 }>
        <EvaluationDetail data={ data } />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <DefinitionResults data={ getDefinitionResult() } />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        {/* TODO: implement when endpoint exists for this
        <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.flexEnd } justifyContent={ ALIGN_ITEMS.flexEnd }>
          <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
            <Box pr={ 1 }>
              <Typography variant={ VARIANT.h6 }>{t("potential:tabPotential.form.description")}</Typography>
            </Box>
            <Box pr={ 1 }>
              <Button
                variant={ VARIANT.outlined }
                type={ TYPES.text }
                size={ SIZE.small }
                isDisabled
              >
                {t("common:common.yes")}
              </Button>
            </Box>
            <Box>
              <Button
                variant={ "outlined" }
                type={ TYPES.text }
                size={ SIZE.small }
                isDisabled
              >
                {t("common:common.no")}
              </Button>
            </Box>
          </Box>
        </Box> */}
      </Grid>
    </Grid>
  );
};

PersonalityTestChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PersonalityTestChart;
