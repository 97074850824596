import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import {
  StyledCardContainer, StyledCard,
  StyledCardContent,
  StyledText, StyledButton,
} from "./styles";

const ActionCard = ({
  title, onClick, actionName,
  text, color, secondaryAction,
  extraInfo,
}) => (
  <StyledCardContainer>
    <StyledCard color={ color }>
      <StyledCardContent>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Box display={ "flex" } alignItems={ "center" }>
              <LightbulbIcon alt={ title } size={ 20 } />
              <StyledText fontSize={ 18 } isBold isTitle>
                {title}
              </StyledText>
            </Box>
            {text && (
              <StyledText fontSize={ 14 }>
                {text}
              </StyledText>
            )}
            {extraInfo && (
              <StyledText fontSize={ 14 } isBold>
                {extraInfo}
              </StyledText>
            )}
            {actionName && onClick
            && (
              <StyledButton
                onClick={ onClick }
                variant={ "contained" }
                type={ "button" }
              >
                {actionName}
              </StyledButton>
            )}
            {secondaryAction && (
              <StyledButton
                onClick={ secondaryAction.handleOnClick }
                variant={ "contained" }
                type={ "button" }
                isSecondary
              >
                {secondaryAction.title}
              </StyledButton>
            )}
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  </StyledCardContainer>
);

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  actionName: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  secondaryAction: PropTypes.shape({
    title: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func.isRequired,
  }),
  extraInfo: PropTypes.string,
};

ActionCard.defaultProps = {
  onClick: null,
  actionName: null,
  text: "",
  color: null,
  secondaryAction: null,
  extraInfo: null,
};

export default ActionCard;
