import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledMessage = styled(Typography)`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

export const StyledContainer = styled.div`
  padding: 10px;
  width: 100%;
  h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const StyledContent = styled.div`
  padding: 10px;
  & img {
    margin-bottom: 10px;
    width: 80px;
  }
`;
