import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { SIZE } from "common/constants";
import { useStyles } from "./styles";

const CommentList = (props) => {
  const { title, onClick, data } = props;
  const classes = useStyles();
  return (
    <List className={classes.root} data-testid="comment-list">
      <Box display="flex" className={classes.container}>
        <Box flexGrow={1}>
          <Typography className={classes.typography} variant="subtitle2">
            {title}
          </Typography>
        </Box>
        <Box>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClick}
          >
            <CloseIcon fontSize={SIZE.small} className={classes.closeIcon} />
          </IconButton>
        </Box>
      </Box>
      <div className={classes.list}>
        {data &&
          data.map((item, index) => {
            return (
              <ListItem
                key={index}
                alignItems="flex-start"
                className={classes.listItem}
              >
                <ListItemAvatar className={classes.itemAvatar}>
                  <Avatar
                    alt={item.name}
                    src={item.img}
                    className={classes.smallAvatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.title}
                      color="textPrimary"
                    >
                      {item.name}
                    </Typography>
                  }
                  className={classes.itemText}
                  secondary={item.text}
                />
              </ListItem>
            );
          })}
      </div>
    </List>
  );
};
CommentList.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.array,
};

export default CommentList;
