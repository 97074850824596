import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import useUserAbility from "hooks/useUserAbility";
import Select from "components/Select";
import Modal from "components/Modal";
import {
  MESSAGE_TYPES,
  toast,
} from "components/Toast/functions";
import {
  COLLABORATORS_PATH,
  COUNTRIES,
  FEEDBACK_PATH,
  LOCAL_STORAGE_NAMES,
  MOVEMENTS_PATH,
  ROLES,
  GENDER_STATUS as GS,
  MODULE_ACTIONS,
  MODULES,
} from "common/constants";
import {
  mainDownloadExcel,
  isColombianAdmin,
  getEmployeeCompanyId,
  getItemFromLocalStorage,
  isValidRole,
  isValidAdmin,
} from "common/utils";
import {
  getCollaboratorsToDownload,
  resetCollaboratorsToDownload,
  downloadTalentFramework,
} from "redux/actions/collaboratorActions";
import { getList as getDynamicAttribute } from "redux/actions/common/dynamicAttributeActions";
import { getList as getGenderList } from "redux/actions/common/genderActions";
import { resetStateToDownload as resetStateMovementsToDownload } from "redux/actions/historicalMovementActions";
import useFeedbacks from "../../hooks/useFeedbacks";
import {
  getDataExcelCollaborators,
  getDataExcelFeedbacks,
  getDataExcelMovements,
} from "../../functions/collaborators";
import MovementDownloadModal from "../MovementDownloadModal";
import { StyledSelect } from "./styles";

const DownloadExcel = (props) => {
  const { user, query } = props;
  let hasAccessAcknowledgments = false;
  const ability = useUserAbility(user);
  if (ability && ability?.acknowledgments) {
    const acknowledgementAbility = ability?.acknowledgments;
    hasAccessAcknowledgments = acknowledgementAbility?.can(
      MODULE_ACTIONS.view, MODULES.acknowledgments,
    );
  }
  const { t } = useTranslation(["collaborators", "common", "feedback"]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    list: dynamicAttributesList,
  } = useSelector(({ dynamicAttributeReducer }) => dynamicAttributeReducer);

  const {
    downloadList,
    isLoadingDownloadList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    list: allGenderList,
  } = useSelector(({ genderReducer }) => genderReducer);

  const {
    downloadlist: downloadMovementsList,
    isLoadingDownloadList: isLoadingDownloadMovementsList,
  } = useSelector(({ historicalMovementsReducer }) => historicalMovementsReducer);

  const isTalentManager = isValidRole(user?.userCookies, ROLES.TALENT_MANAGER);
  const isAdmin = isValidAdmin(user?.userCookies);
  const isManager = isValidRole(user?.userCookies, ROLES.MANAGER);
  const isOnlyManager = isManager && !(isAdmin || isTalentManager);
  const colombianAdmin = isColombianAdmin(user?.userCookies);
  const companyId = getEmployeeCompanyId(user);
  const genderList = allGenderList?.map((item) => (
    { ...item, value: t(GS[item.id]), label: t(GS[item.id]) }
  ));

  useEffect(() => {
    dispatch(getGenderList());
  }, [dispatch]);

  useEffect(() => {
    const country = colombianAdmin ? COUNTRIES.colombia : "";
    if (downloadList) {
      const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);
      const dataToExcel = getDataExcelCollaborators(
        downloadList,
        dynamicAttributesList,
        companyId,
        currencies,
        t,
        country,
        isTalentManager,
        genderList,
      );

      mainDownloadExcel(dataToExcel, "collaborators");
      dispatch(resetCollaboratorsToDownload());
    }
  }, [
    downloadList,
    dynamicAttributesList,
    companyId,
    t,
    dispatch,
    colombianAdmin,
    isTalentManager,
    genderList,
  ]);

  useEffect(() => {
    if (!isLoadingDownloadMovementsList && downloadMovementsList) {
      const dataToExcel = getDataExcelMovements(downloadMovementsList, t);
      const { date_gteq, date_lteq } = query.q;
      mainDownloadExcel(dataToExcel, `${t("common:fileNames.movements")}_${date_gteq}_${date_lteq}`);
      dispatch(resetStateMovementsToDownload());
    }
  }, [
    downloadMovementsList,
    isLoadingDownloadMovementsList,
    query.q,
    t,
    dispatch,
  ]);

  const getDownloadTypes = () => {
    const types = [];
    if (!isOnlyManager) {
      types.push({ value: COLLABORATORS_PATH, label: t("common:common.download.collaborators") });
    }
    if (isAdmin) {
      types.push({ value: MOVEMENTS_PATH, label: t("common:common.download.movements") });
    }
    if (hasAccessAcknowledgments && !isOnlyManager) {
      types.push({ value: FEEDBACK_PATH, label: t("common:common.download.feedback") });
    }
    if (isAdmin || isTalentManager || isManager) {
      types.push({ value: "talentFramework", label: "Talent Framework" });
    }
    return types;
  };

  const queryFeedbackKey = ["feedbacks"];
  const [isDownloadFeedbacks, setIsDownloadFeedbacks] = useState(false);

  const {
    feedbacks,
    isLoadingFeedbacks,
    isFetchingFeedbacks,
    refetchFeedbacks,
  } = useFeedbacks(query, queryFeedbackKey);

  useEffect(() => {
    if (isDownloadFeedbacks && feedbacks.length > 0 && !isFetchingFeedbacks) {
      const dataToExcel = getDataExcelFeedbacks(feedbacks, t);
      mainDownloadExcel(dataToExcel, t("common:fileNames.feedbacks"));
      setIsDownloadFeedbacks(false);
    }
  }, [
    isDownloadFeedbacks,
    feedbacks,
    isFetchingFeedbacks,
    t,
  ]);

  const handleSendExcel = async () => {
    const asyncTask = await dispatch(downloadTalentFramework(query, isOnlyManager));
    if (asyncTask?.aasm_state === "pending") {
      toast(MESSAGE_TYPES.info, {
        title: t("administrator:modules.positions.download.title"),
        message: t("administrator:modules.positions.download.message"),
      });
    }
  };

  const handleDownload = (prop, event) => {
    const { value } = event.target;
    if (value === COLLABORATORS_PATH) {
      dispatch(getDynamicAttribute());
      dispatch(getCollaboratorsToDownload(query));
    } else if (value === FEEDBACK_PATH) {
      setIsDownloadFeedbacks(true);
      refetchFeedbacks();
    } else if (value === "talentFramework") {
      handleSendExcel();
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <StyledSelect>
      <Select
        label={ t("common:common.downloadAction") }
        menuItems={ getDownloadTypes() }
        onChange={ handleDownload }
        className={ "custom-select" }
        disabled={ isLoadingDownloadList || isLoadingFeedbacks || isLoadingDownloadMovementsList }
      />
      <Modal
        isOpen={ isOpen }
        onClose={ () => setIsOpen(false) }
        title={ t("common:common.download.movements") }
      >
        <MovementDownloadModal modalHandler={ setIsOpen } query={ query } />
      </Modal>
    </StyledSelect>
  );
};

DownloadExcel.propTypes = {
  user: PropTypes.object.isRequired,
  query: PropTypes.object,
};

DownloadExcel.defaultProps = {
  query: {},
};

export default DownloadExcel;
