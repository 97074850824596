import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import { VARIANT, BUTTON_STYLE_TYPES } from "common/constants";
import { StyledButtonActionsContainer } from "components/TablePagination/styles";

const DeleteDocumentModal = (props) => {
  const { itemSelected, modal, handleDelete } = props;
  const { t } = useTranslation(["common", "account"]);
  return (
    <>
      <div>
        {`${t("common:common.modal_messages.delete_text")} ${itemSelected?.filename || itemSelected?.url}?`}
      </div>
      <Grid item xs={ 12 }>
        <StyledButtonActionsContainer>
          <Button
            onClick={ () => modal.set(false) }
            variant={ VARIANT.contained }
            typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
          >
            {t("collaborators:buttons.cancel")}
          </Button>
          <Button
            variant={ BUTTON_STYLE_TYPES.CONTAINED }
            autoFocus
            type={ BUTTON_STYLE_TYPES.SUBMIT }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            onClick={ () => handleDelete(itemSelected.id) }
          >
            {t("common:common.modal_messages.yes_confirm")}
          </Button>
        </StyledButtonActionsContainer>
      </Grid>
    </>
  );
};

DeleteDocumentModal.propTypes = {
  itemSelected: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
export default DeleteDocumentModal;
