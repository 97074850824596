import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { clarity } from "react-microsoft-clarity";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import useComplexState from "hooks/utils/useComplexState";
import { getSearchParams } from "common/utils";
import { TRACKING_TAGS, MODULES } from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import {
  getEvaluationResultBySurveyProcessId,
  getListByEmployee as getSurveyProcessesByEmployee,
  resetStateByEmployee,
  deleteEvaluationResultById,
} from "redux/actions/surveyProcessesActions";
import {
  getCollaboratorChildrens,
  resetState,
} from "redux/actions/collaboratorActions";
import { getPercentCompleted, getProcessList, getSurveyDetail } from "../functions";

export const useCoverSummaryHook = (externalHooks) => {
  const {
    t, dispatch, history, user,
  } = externalHooks;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const params = history.location.search;

  const [summaryStates, setSummaryStates] = useComplexState({
    evaluation: null,
    evaluationResults: null,
    processList: null,
    showAll: false,
    showType: null,
    justOne: false,
    percentage: null,
  });

  const {
    listByEmployee,
    isLoadingListByEmployee,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const addEmployeeInResults = useCallback(async (results) => {
    const queryCollabs = {
      q: {
        id_in: results.map((item) => item.evaluated_id),
      },
    };
    const childrens = await getCollaboratorChildrens(queryCollabs);
    setSummaryStates({
      evaluationResults: results.map((item) => (
        { ...item, employee: childrens.find((employee) => isEqual(employee.id, item.evaluated_id)) }
      )),
    });
    // eslint-disable-next-line
  }, []);

  const getEvaluationResults = useCallback(async (id, refetch = false) => {
    if (refetch) {
      dispatch(resetState());
      dispatch(resetStateByEmployee());
      setSummaryStates({
        evaluation: null,
        evaluationResults: null,
        processList: null,
        showAll: false,
        showType: null,
        justOne: false,
        percentage: null,
      });
    }
    const query = {
      q: {
        employee_id_eq: user?.employee?.id,
      },
    };
    const results = await dispatch(getEvaluationResultBySurveyProcessId(id, query));
    if (results?.error || results?.length === 0) {
      setSummaryStates({
        evaluationResults: [],
      });
    } else if (!isEmpty(results)) {
      addEmployeeInResults(results);
    }

    const queryByProcess = {
      q: {
        id_in: [id],
      },
    };
    dispatch(getSurveyProcessesByEmployee(queryByProcess));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const allParams = getSearchParams(params);
    if (allParams?.process_id) {
      getEvaluationResults(allParams.process_id);
    }
  }, [dispatch, params, getEvaluationResults]);

  useEffect(() => {
    if (summaryStates.evaluationResults !== null && listByEmployee && !isLoadingListByEmployee) {
      if (!isEmpty(listByEmployee)) {
        // TODO: review the function names and the naming of results
        const processes = getProcessList(
          t,
          listByEmployee[0],
          summaryStates.evaluationResults,
          isMobile,
        );
        setSummaryStates({
          evaluation: getSurveyDetail(listByEmployee[0], summaryStates?.evaluationResults, t),
          processList: processes,
          justOne: isEqual(processes.length, 1)
            && isEqual(processes[0]?.employees.length, 1)
            && processes[0],
          percentage: getPercentCompleted(summaryStates.evaluationResults),
        });
      } else {
        setSummaryStates({
          evaluation: [],
          processList: [],
        });
      }
    }
    // eslint-disable-next-line
  }, [
    t,
    isMobile,
    summaryStates.evaluationResults,
    listByEmployee,
    isLoadingListByEmployee,
  ]);

  const setShowAll = (processType) => {
    setSummaryStates({
      showAll: !summaryStates.showAll,
      showType: processType,
    });
  };

  const deleteEvaluationResult = (evaluationResultId) => {
    // Track delete peers
    if (typeof clarity !== "undefined") {
      clarity.setTag(MODULES.cover, TRACKING_TAGS.deletePeer);
    }
    dispatch(deleteEvaluationResultById(evaluationResultId));
    const newEvaluationResults = summaryStates.evaluationResults.filter(
      (item) => item.id !== evaluationResultId,
    );
    setSummaryStates({
      evaluationResults: newEvaluationResults,
    });
  };

  return {
    summaryStates,
    evaluationStates: {
      isLoadingListByEmployee,
    },
    setShowAll,
    getEvaluationResults,
    deleteEvaluationResult,
  };
};
