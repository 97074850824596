import { getDivisionAndAreaType, getItemFromLocalStorage } from "common/utils";
import { LOCAL_STORAGE_NAMES } from "common/constants";

export const TABS = (t, receivedCount = 0, sentCount = 0) => [
  { label: `${t("feedback:received")} (${receivedCount || 0})` },
  { label: `${t("feedback:sent")} (${sentCount || 0})` },
];

export const INDEX_TABS = {
  received: 0,
  sent: 1,
};

export const FEEDBACK_TYPE = {
  received: "feedbacksReceived",
  sent: "feedbacksSent",
  from: "from",
  to: "to",
};

export const FEEDBACKABLES = {
  feedback: "Feedback",
  feedbackSimple: "FeedbackSimple",
  feedbackStartStopContinue: "FeedbackStartStopContinue",
  feedbackAcknowledgement: "FeedbackAcknowledgement",
};

export const EMPLOYEE_TYPE = {
  receiver: "employee_from",
  sender: "employee_to",
};

export const LIMIT_PAGINATE_INDEX = {
  start: 0,
  end: 5,
};

export const INDEX_TABS_SEND = {
  simple: 0,
  stop_start_continue: 1,
};

export const TABS_SEND_FORM = (t) => [
  { label: t("feedback:simple") },
  { label: t("feedback:stop_start_continue") },
];

export const VIEW_MORE_OPTIONS = {
  received: "feedbacksReceived",
  sent: "feedbacksSent",
  goals: "goals",
  benefits: "benefits",
  movementsHistory: "movementsHistory",
  agreements: "agreements",
};

export const getDivisions = (orgUnits) => {
  const organizationUnitsTypes = getDivisionAndAreaType();
  const orgUnitTypeDivisionId = organizationUnitsTypes?.division?.id;

  if (orgUnits.length === 0) {
    return [];
  }

  return orgUnits
    .filter((orgUnit) => orgUnit.organization_unit_type_id === orgUnitTypeDivisionId)
    .map((division) => ({
      label: division.name,
      value: division.id,
    }));
};

export const getAreas = (organizationUnits, divisionSelected) => {
  const organizationUnitsTypes = getDivisionAndAreaType();
  const orgUnitTypeAreaId = organizationUnitsTypes?.area?.id;
  const allOrgUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);

  const areasSelected = allOrgUnits.filter(
    (orgUnit) => orgUnit.parent_id === divisionSelected
      && orgUnit.organization_unit_type_id === orgUnitTypeAreaId,
  );

  return areasSelected.map((area) => ({
    label: area.name,
    value: area.id,
  }));
};
