import styled from "styled-components";
import TextField from "@mui/material/TextField";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: ${palette.white};
`;
