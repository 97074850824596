import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledCardContainer = styled(Card)`
  padding: 20px 40px;
  background: ${palette.text.link};
  border-radius: 0;
  @media (max-width: 450px) {
    padding: 10px;
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${palette.white};
  line-height: 50px;
  font-size: 40px;
  font-weight: bold;
  @media (max-width: 450px) {
    line-height: 20px;
    font-size: 16px;
  }
`;

export const StyledImageContainer = styled(Box)`
  background: ${palette.white};
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  img {
    width: 100px;
    height: 100px;
    padding: 20px;
  }
  @media (max-width: 450px) {
    img {
      width: 50px;
      height: 50px;
      padding: 5px;
    }
  }
`;
