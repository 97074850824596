import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CollaboratorProfileSummaryCard from "components/CollaboratorProfileSummaryCard";
import SimpleCustomAccordion from "components/SimpleCustomAccordion";
import { useForm, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StaticCustomProgressBar from "components/StaticCustomProgressBar";
import InputForm from "components/InputForm";
import Button from "components/Button";
import TooltipIcon from "components/TooltipIcon";
import SkeletonLoader from "components/SkeletonLoader";
import AlertDialog from "components/AlertDialog";
import {
  getSuccessionPlansByEmployeeId,
  updateSuccessionPlanByCollaborator,
  deleteSuccessionPlanByCollaborator,
} from "redux/actions/collaboratorActions";
import {
  INPUT_RULES, INPUT_TYPE,
  BUTTON_STYLE_TYPES, SIZE,
  SKELETONS_NUMBER,
} from "common/constants";
import palette from "theme/palette";
import { calculateTimePassedPercentageAndRemainingTime, getEstimatedTime } from "views/Successions/functions";
import {
  StyledButtons, StyledSuccessionPlan, StyledSuccessionItem,
  StyledSuccessionContainer,
} from "./styles";

const CollaboratorInfo = React.memo((props) => {
  const {
    collaborator,
    t,
    estimatedTime,
  } = props;

  const {
    control, setValue, getValues,
  } = useForm();
  const dispatch = useDispatch();

  const employeeId = collaborator.id;
  const [successionPlans, setSuccessionPlans] = useState([]);
  const [editingSuccessionId, setEditingSuccessionId] = useState(null);
  const [dialog, setDialog] = useState({ isOpen: false, selectedId: null });

  const {
    data: successionPlansByEmployee,
    isLoading: isLoadingSuccessionPlans,
    refetch,
    // error,
  } = useQuery(["successionPlansByEmployee", employeeId], () => getSuccessionPlansByEmployeeId(employeeId), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (successionPlansByEmployee) {
      setSuccessionPlans(successionPlansByEmployee);
      successionPlansByEmployee.forEach((successionData) => {
        const matchingOption = getEstimatedTime(successionData, estimatedTime);

        setValue(`estimated_time_${successionData.id}`, matchingOption);
      });
    }
    // eslint-disable-next-line
  }, [successionPlansByEmployee]);

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateSuccession = (successionId) => {
    setEditingSuccessionId(successionId);
  };

  const handleUpdateSuccessionPlan = async (successionId) => {
    const fieldName = `estimated_time_${successionId}`;
    const newEstimatedTime = getValues(fieldName);

    if (typeof newEstimatedTime === "string") {
      const dataToSend = {
        succession_plan: {
          estimated_time: newEstimatedTime,
        },
      };
      const succession = await dispatch(
        updateSuccessionPlanByCollaborator(successionId, dataToSend),
      );
      setSuccessionPlans((currentPlans) => currentPlans.map(
        (plan) => (plan.id === successionId ? succession : plan),
      ));
    }
    setEditingSuccessionId(null);
  };

  const handleCloseEditForm = () => {
    setEditingSuccessionId(null);
  };

  const handleOpenDeleteDialog = (id) => {
    setDialog({ isOpen: true, selectedId: id });
  };

  const handleCloseDialog = () => {
    setDialog({ isOpen: false, selectedId: null });
  };

  const handleDeleteSuccession = async (successionId) => {
    await dispatch(deleteSuccessionPlanByCollaborator(successionId));
    setSuccessionPlans((currentPlans) => currentPlans.filter((plan) => plan.id !== successionId));
    setDialog({ isOpen: false, selectedId: null });
  };

  const getSuccessionContent = (successionPlan) => {
    let defaultValue = null;
    if (editingSuccessionId) {
      defaultValue = getEstimatedTime(successionPlan, estimatedTime) || null;
    }

    const {
      percentage,
      remainingTimeKey,
      remainingTimeValue,
    } = calculateTimePassedPercentageAndRemainingTime(successionPlan);
    const description = t(`successions:plan.${remainingTimeKey}`, { months: remainingTimeValue, years: remainingTimeValue });

    return (
      <Grid item xs={ 12 } md={ 5 }>
        {editingSuccessionId === successionPlan.id ? (
          <Controller
            control={ control }
            name={ `estimated_time_${successionPlan.id}` }
            rules={ INPUT_RULES.required }
            render={ () => (
              <InputForm
                id={ `estimated_time_${successionPlan.id}` }
                options={ estimatedTime }
                type={ INPUT_TYPE.autocomplete }
                label={ t("successions:estimated_time") }
                control={ control }
                name={ `estimated_time_${successionPlan.id}` }
                nameOfAttr={ "name" }
                fieldValue={ "value" }
                size={ SIZE.small }
                defaultValue={ defaultValue }
              />
            ) }
          />
        ) : (
          <StaticCustomProgressBar
            title={ t("successions:plan.progress") }
            percentage={ percentage }
            color={ palette.background.purpleBox }
            description={ description }
            hasOpacity={ successionPlan?.total_agreements === 0 }
            t={ t }
            name={ collaborator.full_name }
          />
        )}
      </Grid>
    );
  };

  const successionInfo = (
    <>
      {isLoadingSuccessionPlans
  && <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.SIX } />}
      {!isLoadingSuccessionPlans && successionPlans?.length > 0
  && (
    <StyledSuccessionPlan>
      <h4>{`${t("successions:plan.title")}:`}</h4>
      {successionPlans.map((successionPlan) => (
        <StyledSuccessionItem key={ successionPlan.id }>
          <StyledSuccessionContainer container spacing={ 3 } alignItems={ "center" }>
            <Grid item xs={ 12 } md={ 5 }>
              <h2>{successionPlan.position_name}</h2>
              <p>{successionPlan.organization_unit_complete_path}</p>
            </Grid>
            {getSuccessionContent(successionPlan)}
            {editingSuccessionId === successionPlan.id
              ? (
                <StyledButtons item xs={ 12 } md={ 2 }>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    size={ SIZE.small }
                    onClick={ () => handleUpdateSuccessionPlan(successionPlan.id) }
                  >
                    <TooltipIcon title={ t("common:common.save") } element={ <SaveIcon /> } />
                  </Button>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                    onClick={ handleCloseEditForm }
                    size={ SIZE.small }
                  >
                    <TooltipIcon title={ t("common:common.cancel") } element={ <CloseIcon /> } />
                  </Button>
                </StyledButtons>
              ) : (
                <StyledButtons item xs={ 12 } md={ 2 }>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    size={ SIZE.small }
                    onClick={ () => handleUpdateSuccession(successionPlan.id) }
                    isDisabled={ editingSuccessionId !== null }
                  >
                    <TooltipIcon title={ t("common:common.edit") } element={ <EditIcon /> } />
                  </Button>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                    size={ SIZE.small }
                    isDisabled={ editingSuccessionId !== null }
                    onClick={ () => handleOpenDeleteDialog(successionPlan.id) }
                  >
                    <TooltipIcon title={ t("common:common.delete") } element={ <DeleteIcon /> } />
                  </Button>
                </StyledButtons>
              )}
          </StyledSuccessionContainer>
        </StyledSuccessionItem>
      ))}
    </StyledSuccessionPlan>
  )}
    </>
  );

  return (
    <>
      <SimpleCustomAccordion
        header={ <CollaboratorProfileSummaryCard collaborator={ collaborator } /> }
        content={ successionInfo }
        isDisabled={ successionPlans.length === 0 }
      />
      <AlertDialog
        isOpen={ dialog.isOpen }
        onClose={ handleCloseDialog }
        title={ t("successions:actions.delete.title") }
        message={ t("successions:actions.delete.description") }
        onSubmit={ () => handleDeleteSuccession(dialog.selectedId) }
      />
    </>
  );
});

CollaboratorInfo.propTypes = {
  collaborator: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  estimatedTime: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

export default CollaboratorInfo;
