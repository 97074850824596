import palette, { STATE_STEPPER_COLOR } from "theme/palette";

export const STATES = {
  pending: "pending",
  inProgress: "inProgress",
  completed: "completed",
  sent: "sent",
};

export const getAllStates = (t) => ({
  pending: {
    name: t("preboarding:states.pending"),
    color: STATE_STEPPER_COLOR.pending,
    background: palette.white,
  },
  inProgress: {
    name: t("preboarding:states.inProgress"),
    color: STATE_STEPPER_COLOR.inProgress.color,
    background: STATE_STEPPER_COLOR.inProgress.background,
  },
  completed: {
    name: t("preboarding:states.completed"),
    color: STATE_STEPPER_COLOR.completed.color,
    background: STATE_STEPPER_COLOR.completed.background,
  },
  sent: {
    name: t("preboarding:states.sent"),
    color: STATE_STEPPER_COLOR.sent.color,
    background: STATE_STEPPER_COLOR.sent.background,
  },
});
