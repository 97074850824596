import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import Calendar from "components/Calendar";
import SimpleAccordion from "components/SimpleAccordion";
import Years from "components/Years";
import {
  SKELETONS_NUMBER, VARIANT, ALIGN_ITEMS,
} from "common/constants";
import { getUtilsFromDate } from "common/utils";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { getCustomSubtitle } from "../../functions";
import Events from "../Events";
import ProcessDetail from "../ProcessDetail";
import {
  StyledCalendarContainer,
  StyledTitle, StyledEvents,
  StyledActions, StyledButton,
  StyledHeaderCalendar,
  StyledEventsTitle,
  StyledCalendarTodayIcon,
  StyledDivider,
  StyledCalendarGrid,
} from "../../styles";

const CalendarProcess = (props) => {
  const {
    t,
    surveysReducerStates,
    calendarStates,
    setEventsByCurrentDate,
    handleControls,
    handleEvent,
    handleItemSelected,
    dispatch,
    isMobile,
    handleOpenModal,
  } = props;

  const { loadingList } = surveysReducerStates;
  const { data, values, currentYear } = calendarStates;

  const [events, setEvents] = useState(values.events);

  const handleChangeState = (eventToUpdate) => {
    const updatedEvent = values.events.find((item) => item.id === eventToUpdate.id);
    if (updatedEvent) {
      const updatedEvents = events.map(
        (event) => (event.id === eventToUpdate.id ? { ...event, ...updatedEvent } : event),
      );
      setEvents(updatedEvents);
    }
  };

  useEffect(() => {
    setEvents(values.events);
  }, [values.events]);

  return (
    <StyledCalendarContainer data-testid={ "calendar-process-view-component" }>
      {!loadingList && data && values ? (
        <Grid container spacing={ 3 }>
          <StyledCalendarGrid item xs={ 12 } md={ 8 }>
            <StyledHeaderCalendar container spacing={ 3 }>
              <Grid item xs={ 12 } md={ 6 }>
                <StyledTitle variant={ VARIANT.h5 }>
                  {t("planning:calendar.title")}
                </StyledTitle>
              </Grid>
              {/* general actions */}
              <StyledActions item xs={ 12 } md={ 6 }>
                <Box
                  display={ ALIGN_ITEMS.flex }
                  justifyContent={ ALIGN_ITEMS.flexEnd }
                  alignItems={ ALIGN_ITEMS.center }
                >
                  <Box pr={ 2 }>
                    <StyledButton
                      variant={ VARIANT.outlined }
                      onClick={ () => setEventsByCurrentDate(data) }
                    >
                      {t("planning:calendar.today")}
                    </StyledButton>
                  </Box>
                  <Box>
                    <Years onClick={ handleControls } currentYear={ currentYear } />
                  </Box>
                </Box>
              </StyledActions>
            </StyledHeaderCalendar>
            {/* calendar */}
            <Calendar
              currentYear={ currentYear }
              data={ data }
              onSelectedEvent={ handleEvent }
            />
          </StyledCalendarGrid>
          {/* list of events */}
          <StyledEvents item xs={ 12 } md={ 4 }>
            <StyledTitle variant={ VARIANT.h5 }>
              {t("planning:calendar.events")}
            </StyledTitle>
            {values?.date && (
              <StyledEventsTitle variant={ VARIANT.h6 }>
                {`${getUtilsFromDate(values.date, t)} ${
                  values.endDate
                    ? ` - ${getUtilsFromDate(values.endDate, t)}`
                    : ""
                }`}
              </StyledEventsTitle>
            )}
            <StyledScrollBar maxHeight={ "600px" } padding={ "10px" }>
              {isEmpty(events) ? (
                <NoDataMessage
                  message={ t("planning:calendar.empty_events") }
                  icon={ <StyledCalendarTodayIcon /> }
                />
              ) : (
                events.map((event) => (
                  <Box key={ event.id } mt={ 2 }>
                    <SimpleAccordion
                      title={ event.name }
                      customSubtitle={ getCustomSubtitle(event, t) }
                      src={ event.processType.icon }
                    >
                      <Events
                        data={ event }
                        handleItemSelected={ handleItemSelected }
                        t={ t }
                        dispatch={ dispatch }
                        isMobile={ isMobile }
                        handleOpenModal={ handleOpenModal }
                        handleChangeState={ handleChangeState }
                      />
                      {event?.processDetail && (
                        <>
                          <StyledDivider />
                          <ProcessDetail
                            data={ event.processDetail }
                            t={ t }
                            hasNalaForm={ event.hasNalaForm }
                            type={ event.processType.key }
                          />
                        </>
                      )}
                    </SimpleAccordion>
                  </Box>
                ))
              )}
            </StyledScrollBar>
          </StyledEvents>
        </Grid>
      ) : (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
      )}
    </StyledCalendarContainer>
  );
};

CalendarProcess.propTypes = {
  t: PropTypes.func.isRequired,
  surveysReducerStates: PropTypes.shape({
    loadingList: PropTypes.bool.isRequired,
  }).isRequired,
  calendarStates: PropTypes.shape({
    data: PropTypes.array.isRequired,
    values: PropTypes.shape({
      date: PropTypes.string,
      endDate: PropTypes.string,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          processType: PropTypes.shape({
            icon: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
          }).isRequired,
          processDetail: PropTypes.object,
          hasNalaForm: PropTypes.bool,
          defaultEndDate: PropTypes.string.isRequired,
          process: PropTypes.shape({
            state: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    currentYear: PropTypes.number.isRequired,
  }).isRequired,
  setEventsByCurrentDate: PropTypes.func.isRequired,
  handleControls: PropTypes.func.isRequired,
  handleEvent: PropTypes.func.isRequired,
  handleItemSelected: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default CalendarProcess;
