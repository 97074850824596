import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import {
  StyledCard,
  StyledCardContent,
  StyledCollapseArrow,
  StyledCollapseContainer,
  StyledTitle,
  StyledRight,
} from "./styles";

const ParticipationCardCollapse = ({
  title, total, icon, content,
}) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const { t } = useTranslation();

  const handleToggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse);
  };

  return (
    <>
      <StyledCard onClick={ handleToggleCollapse }>
        <StyledCardContent>
          <Grid container alignItems={ "center" }>
            <Grid item xs={ 10 } sm={ 7 }>
              <StyledCollapseContainer>
                {icon}
                <StyledTitle variant={ "h3" }>
                  <span>{`${total} `}</span>
                  {t(title)}
                </StyledTitle>
              </StyledCollapseContainer>
            </Grid>
            <Grid item xs={ 2 } sm={ 5 }>
              <StyledRight>
                <StyledCollapseArrow>
                  {isOpenCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </StyledCollapseArrow>
              </StyledRight>
            </Grid>
          </Grid>
        </StyledCardContent>
      </StyledCard>
      <Collapse in={ isOpenCollapse } unmountOnExit>
        {content}
      </Collapse>
    </>
  );
};

ParticipationCardCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  icon: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
};

export default ParticipationCardCollapse;
