export const RESET_STATE = "RESET_STATE_ENGAGEMENT";
export const GET_LIST = "GET_LIST_ENGAGEMENT";
export const GET_LIST_LOADING = "GET_LIST_LOADING_ENGAGEMENT";
export const GET_LIST_ERROR = "GET_LIST_ERROR_ENGAGEMENT";

export const RESET_STATE_ENGAGEMENT_EVOLUTION = "RESET_STATE_ENGAGEMENT_EVOLUTION";
export const GET_LIST_ENGAGEMENT_EVOLUTION = "GET_LIST_ENGAGEMENT_EVOLUTION";
export const GET_LIST_ENGAGEMENT_EVOLUTION_LOADING = "GET_LIST_ENGAGEMENT_EVOLUTION_LOADING";
export const GET_LIST_ENGAGEMENT_EVOLUTION_ERROR = "GET_LIST_ENGAGEMENT_EVOLUTION_ERROR";

export const RESET_STATE_ENGAGEMENT_COMMENTS = "RESET_STATE_ENGAGEMENT_COMMENTS";
export const GET_LIST_ENGAGEMENT_COMMENTS = "GET_LIST_ENGAGEMENT_COMMENTS";
export const GET_LIST_ENGAGEMENT_COMMENTS_LOADING = "GET_LIST_ENGAGEMENT_COMMENTS_LOADING";
export const GET_LIST_ENGAGEMENT_COMMENTS_ERROR = "GET_LIST_ENGAGEMENT_COMMENTS_ERROR";

export const RESET_STATE_ENGAGEMENT_NPS = "RESET_STATE_ENGAGEMENT_NPS";
export const GET_LIST_ENGAGEMENT_NPS = "GET_LIST_ENGAGEMENT_NPS";
export const GET_LIST_ENGAGEMENT_NPS_LOADING = "GET_LIST_ENGAGEMENT_NPS_LOADING";
export const GET_LIST_ENGAGEMENT_NPS_ERROR = "GET_LIST_ENGAGEMENT_NPS_ERROR";

export const RESET_STATE_ENGAGEMENT_SATISFACTION = "RESET_STATE_ENGAGEMENT_SATISFACTION";
export const GET_LIST_ENGAGEMENT_SATISFACTION = "GET_LIST_ENGAGEMENT_SATISFACTION";
export const GET_LIST_ENGAGEMENT_SATISFACTION_LOADING = "GET_LIST_ENGAGEMENT_SATISFACTION_LOADING";
export const GET_LIST_ENGAGEMENT_SATISFACTION_ERROR = "GET_LIST_ENGAGEMENT_SATISFACTION_ERROR";

export const RESET_STATE_ENGAGEMENT_OVERALL_STATISTICS = "RESET_STATE_ENGAGEMENT_OVERALL_STATISTICS";
export const GET_LIST_ENGAGEMENT_OVERALL_STATISTICS = "GET_LIST_ENGAGEMENT_OVERALL_STATISTICS";
export const GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_LOADING = "GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_LOADING";
export const GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_ERROR = "GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_ERROR";

export const RESET_STATE_ENGAGEMENT_PARTICIPATION = "RESET_STATE_ENGAGEMENT_PARTICIPATION";
export const GET_LIST_ENGAGEMENT_PARTICIPATION = "GET_LIST_ENGAGEMENT_PARTICIPATION";
export const GET_LIST_ENGAGEMENT_PARTICIPATION_LOADING = "GET_LIST_ENGAGEMENT_PARTICIPATION_LOADING";
export const GET_LIST_ENGAGEMENT_PARTICIPATION_ERROR = "GET_LIST_ENGAGEMENT_PARTICIPATION_ERROR";

export const RESET_STATE_ENGAGEMENT_TOPIC = "RESET_STATE_ENGAGEMENT_TOPIC";
export const GET_LIST_ENGAGEMENT_TOPIC = "GET_LIST_ENGAGEMENT_TOPIC";
export const GET_LIST_ENGAGEMENT_TOPIC_LOADING = "GET_LIST_ENGAGEMENT_TOPIC_LOADING";
export const GET_LIST_ENGAGEMENT_TOPIC_ERROR = "GET_LIST_ENGAGEMENT_TOPIC_ERROR";

export const RESET_STATE_COLLABORATORS_BY_PROCESS = "RESET_STATE_COLLABORATORS_BY_PROCESS";
export const GET_LIST_COLLABORATORS_BY_PROCESS = "GET_LIST_COLLABORATORS_BY_PROCESS";
export const GET_LIST_COLLABORATORS_BY_PROCESS_LOADING = "GET_LIST_COLLABORATORS_BY_PROCESS_LOADING";
export const GET_LIST_COLLABORATORS_BY_PROCESS_ERROR = "GET_LIST_COLLABORATORS_BY_PROCESS_ERROR";
