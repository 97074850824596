import { useContext } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { SessionContext } from "modules/session/context";
import useUserAbility from "hooks/useUserAbility";
import { useHomeCalendarHook } from "./hooks/homeCalendar.hook";
import { useHomeAcknowledgmentHook } from "./hooks/homeAcknowledgment.hook";
import HomeView from "./Home.view";

const HomeController = (props) => {
  const { t, dispatch } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  const ability = useUserAbility(user);
  const theme = useTheme();

  const homeCalendarStates = useHomeCalendarHook({ dispatch, t });
  const homeAcknowledgmentStates = useHomeAcknowledgmentHook({
    dispatch,
    user,
    theme,
    t,
  });

  if (!ability?.acknowledgments) {
    return "";
  }
  return (
    <HomeView
      user={ user }
      ability={ ability?.acknowledgments }
      { ...homeCalendarStates }
      { ...homeAcknowledgmentStates }
      { ...props }
    />
  );
};

HomeController.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default HomeController;
