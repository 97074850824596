import round from "lodash/round";
import includes from "lodash/includes";
import isInteger from "lodash/isInteger";
import getStar from "lodash/get";
import reduce from "lodash/reduce";
import startCase from "lodash/startCase";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import StarRating from "components/StarRating";
import Comments from "components/Comments";
import SimpleTable from "components/SimpleTable";
import StaticProgressBar from "components/StaticProgressBar";
import {
  LEADER_CLASSIFICATION_RANK,
  VISIBILITY,
  INDEX,
  PERFORMANCE_MAX_PERCENTAGE,
  DEFAULT_MIN_DECIMAL,
  OBJECT_KEYS,
  VARIANT,
  SIZE,
  RATING,
  MIN_VALUE,
} from "common/constants";
import {
  isEmpty, isEqual, isNull, isUndefined,
} from "common/helpers";
import { RESULT_SECTIONS } from "common/constants/goodLeader";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { getPercent, getScaleByScore } from "common/utils";
import { getEvaluationType, isNullOrUndefined } from "common/validators";
import { StyledComment } from "views/Account/components/Performance/styles";
import {
  StyledAccordionHeader, StyledAnswers, StyledQuestion, StyledLink,
} from "views/Engagement/styledComponents";

// TODO: remove
export const createClassificationLeader = (top, bottom, result) => ({ top, bottom, result });

export const setEvaluationData = (
  evaluation_type,
  weighing,
  score,
  result,
  comment,
) => ({
  evaluation_type, weighing, score, result, comment,
});

export const createEvaluatorsHeader = (
  id,
  label,
  customRender,
  newCustomRender,
) => ({
  id, label, customRender, newCustomRender,
});

// FIXME: remove when receiving scale
export const leaderClassification = (t) => [
  createClassificationLeader(
    LEADER_CLASSIFICATION_RANK.EXEMPLARY_LEADER[INDEX.zero],
    LEADER_CLASSIFICATION_RANK.EXEMPLARY_LEADER[INDEX.one],
    t("leaderClassification.exemplaryLeader"),
  ),
  createClassificationLeader(
    LEADER_CLASSIFICATION_RANK.GOOD_LEADER[INDEX.zero],
    LEADER_CLASSIFICATION_RANK.GOOD_LEADER[INDEX.one],
    t("leaderClassification.goodLeader"),
  ),
  createClassificationLeader(
    LEADER_CLASSIFICATION_RANK.LEADER_IN_DEVELOPMENT[INDEX.zero],
    LEADER_CLASSIFICATION_RANK.LEADER_IN_DEVELOPMENT[INDEX.one],
    t("leaderClassification.leaderInDevelopment"),
  ),
  createClassificationLeader(
    LEADER_CLASSIFICATION_RANK.BOX[INDEX.zero],
    LEADER_CLASSIFICATION_RANK.BOX[INDEX.one],
    t("leaderClassification.box"),
  ),
];

export const evaluationScale = (value, scales) => (
    scales.find(
      (scale) => value <= getPercent(scale.top) && value >= getPercent(scale.bottom),
    )?.result || ""
);

export const getResults = (results, t) => {
  const data = results.map((result) => setEvaluationData(
    getEvaluationType(result.evaluation_type, t),
    getPercent(result.weighing),
    getPercent(result?.score),
    result.result,
    result.comment || "",
  ));
  return data;
};

export const setEvaluatorsData = (name, title, percentage, comments) => ({
  name, title, percentage, comments,
});

const isNotConfidentialComment = (evaluationType) => !includes(
  [EVALUATION_TYPE.PAIRS, EVALUATION_TYPE.COLLABORATORS],
  evaluationType,
);

export const setComments = (comments, evaluationType) => {
  const commentList = comments.map((comment) => ({
    name: isNotConfidentialComment(evaluationType) ? comment.name : "",
    img:
        !isNullOrUndefined(comment.image)
        && isNotConfidentialComment(evaluationType)
          ? comment.image
          : "",
    text: comment.comment,
  }));
  return commentList;
};

const getResultByQuestion = (results, question) => {
  const resultByQuestion = [];
  results.map((result) => result.evaluation_answers
    .filter((filterAnswer) => isEqual(question.question_id, filterAnswer.question_id))
    .map((answer) => resultByQuestion.push(answer)));
  return resultByQuestion;
};

const getResultByOpenQuestion = (results, question) => {
  const resultByQuestion = [];
  results.map((result) => result.evaluation_answers
    .filter((filterAnswer) => isEqual(question.question_id, filterAnswer.question_id))
    .map((answer) => resultByQuestion.push({ type: result.evaluation_type, answers: answer?.answers })));
  return resultByQuestion;
};

const getOpenComments = (answers) => answers.map((answer) => <StyledComment key={ answer }>{answer}</StyledComment>);

const getTotalScoreAndAnswers = (results, resultByQuestion, isOpen = false) => {
  const answersToSave = {};
  let scoreCounter = MIN_VALUE;

  const scores = results.map((evaluator, index) => {
    const comments = resultByQuestion[index]?.answers ? getOpenComments(resultByQuestion[index]?.answers) : [];
    const answer = isOpen ? comments : resultByQuestion[index]?.result;
    const score = getPercent(resultByQuestion[index]?.score);
    if (!isEmpty(answer)) {
      answersToSave[evaluator.evaluation_type] = {
        result: score,
        label: answer,
      };
    }
    return score;
  });

  scores.map((score) => (scoreCounter += score));

  return {
    total: Math.round(scoreCounter / scores.length),
    answers: answersToSave,
  };
};

const getCommentsByAnswers = (results, resultByQuestion) => {
  const answersToSave = {};

  const resultsByEvaluator = results;
  resultsByEvaluator.forEach((evaluator) => {
    const answersByQuestion = resultByQuestion.find((question) => isEqual(question.type, evaluator.evaluation_type));
    const answer = !isUndefined(answersByQuestion) && answersByQuestion.answers ? getOpenComments(answersByQuestion.answers) : [];
    if (!isEmpty(answer)) {
      answersToSave[evaluator.evaluation_type] = {
        result: null,
        label: answer,
      };
    }
  });

  return {
    total: null,
    answers: answersToSave,
  };
};

export const getQuestions = (results, scales, isOpen = false) => {
  const questions = [];

  const resultByEvaluator = isOpen ? results.filter((result) => !isEmpty(result.evaluation_answers)) : results;

  const questionsAndAnswers = [];

  if (isOpen) {
    resultByEvaluator.forEach((result) => {
      result.evaluation_answers.forEach((evaluationAnswer) => {
        const hasQuestion = questions.find((question) => isEqual(question.question_id, evaluationAnswer.question_id));
        if (isEmpty(questions) || !hasQuestion) {
          questions.push(evaluationAnswer);
        }
      });
    });

    questions.forEach((questionItem) => {
      const resultByQuestion = getResultByOpenQuestion(resultByEvaluator, questionItem);
      const totalScoreAndAnswers = getCommentsByAnswers(
        resultByEvaluator,
        resultByQuestion,
      );
      const questionResult = Object(totalScoreAndAnswers.answers);
      questionsAndAnswers.push({
        title: questionItem.question,
        questionResult,
        total: null,
        label: "",
        answers: [],
      });
    });
  } else {
    resultByEvaluator[INDEX.zero].evaluation_answers.forEach(
      (question) => {
        const resultByQuestion = getResultByQuestion(resultByEvaluator, question);
        const totalScoreAndAnswers = getTotalScoreAndAnswers(
          resultByEvaluator,
          resultByQuestion,
          isOpen,
        );
        const questionResult = Object(totalScoreAndAnswers.answers);
        questionsAndAnswers.push({
          title: question.question,
          questionResult,
          total: totalScoreAndAnswers.total,
          label: evaluationScale(totalScoreAndAnswers.total, scales),
          answers: question.answers,
        });
      },
    );
  }
  return questionsAndAnswers;
};

export const evaluatorHeader = (results) => {
  const data = results.map((result) => setEvaluatorsData(
    result.evaluation_type,
    result.evaluation_type,
    result.weighing,
    !isEmpty(result.comments)

        && setComments(result.comments, result.evaluation_type),
  ));
  return data;
};

export const getFinalWeighing = (weighing, total) => {
  const value = round(
    (weighing / total) * PERFORMANCE_MAX_PERCENTAGE,
    DEFAULT_MIN_DECIMAL,
  );
  return isInteger(value) ? value : value.toFixed(DEFAULT_MIN_DECIMAL);
};

export const getAlert = (evaluations, t) => {
  const total = getPercent(
    reduce(
      evaluations,
      (result, value) => result + value.weighing,
      INDEX.zero,
    ),
  );

  let evaluationsDetail = "";
  evaluations.map((evaluation) => {
    const weighing = getPercent(evaluation.weighing);
    return (evaluationsDetail += ` ${getEvaluationType(
      evaluation.evaluation_type,
      t,
    )} (${getFinalWeighing(weighing, total)}%).`);
  });
  return `${t("alert")} : ${evaluationsDetail}`;
};

export const starRating = (rowData, isSmall) => (
  <StarRating
    name={ rowData.name }
    value={ rowData.value }
    isReadOnly
    size={ isSmall ? SIZE.small : SIZE.medium }
    label={ rowData.label }
    maxRating={ RATING.total_max }
  />
);

export const getEmployeeId = (dataUser) => dataUser.employee?.id || dataUser.id;

export const getStarRating = (rowData, isSmall, id, label) => {
  const starRatingData = {
    value: isSmall ? getStar(rowData, id) : getPercent(rowData?.score),
    name: isSmall ? rowData.title : rowData.evaluation_type,
    label: isSmall ? getStar(rowData, label) : rowData.result,
  };
  return starRating(starRatingData, isSmall);
};

export const getReducerToUse = (searchBy, reducerToUse) => reducerToUse.one;

export const isValidToSearch = (
  dataUser,
  performanceData,
  surveyResults,
  surveyProcess,
) => (
  !isEmpty(dataUser)
    && isEmpty(performanceData)
    && isNull(surveyResults)
    && isNull(surveyProcess)
);

export const isValidToSearchSurveys = (isLoadingPerformanceProcess, listPerformanceProcess, dataUser) => (
  !isLoadingPerformanceProcess
    && !isEmpty(listPerformanceProcess)
    && !isEmpty(dataUser)
);

export const isActiveDataResult = (
  activeResult,
  performanceData,
  isLoading,
  isLoadingProfile,
) => (
  activeResult && !isNull(performanceData) && !isLoading && !isLoadingProfile
);

export const getEvaluationHeader = (t) => [
  { id: OBJECT_KEYS.evaluationType, label: "" },
  {
    id: OBJECT_KEYS.score,
    label: t("grades"),
    customRender: (rowData) => getStarRating(rowData, false),
  },
  { id: OBJECT_KEYS.comment, label: t("comments") },
];

export const getTotalHeader = (t) => [
  {
    id: OBJECT_KEYS.evaluationType,
    label: "",
    customRender: (rowData) => getEvaluationType(rowData.evaluation_type, t),
  },
  {
    id: OBJECT_KEYS.score,
    label: "",
    customRender: (rowData) => getStarRating(rowData),
  },
];

const getCommentDetails = (comments, t) => (
  <Comments
    textLink={ t("seeComments") }
    title={ t("comments") }
    data={ comments }
  />
);

const getEvaluatorsHeader = (evaluators, t, classes, isOpen = false) => {
  const evaluatorsHeader = [
    { id: OBJECT_KEYS.title, label: "" },
  ];
  evaluators.map((header) => {
    if (!isOpen && header.comments) {
      evaluatorsHeader[INDEX.zero].newCustomRender = t("profile:modals.performance.comments");
    }
    const head = createEvaluatorsHeader(
      `questionResult.${header.name}.result`,
      getEvaluationType(header.title, t),
      (rowData) => {
        const openAnswerText = isOpen ? getStar(rowData, `questionResult.${header.name}.label`) : "";
        return !isOpen ? getStarRating(
          rowData,
          true,
        `questionResult.${header.name}.result`,
        `questionResult.${header.name}.label`,
        )
          : (
            <Typography
              key={ openAnswerText }
              variant={ VARIANT.bodyOne }
            >
              { openAnswerText }
            </Typography>
          );
      },
      !isOpen && header.comments && getCommentDetails(header.comments, t),
    );
    return evaluatorsHeader.push(head);
  });
  if (!isOpen) {
    evaluatorsHeader.push({
      id: OBJECT_KEYS.total,
      label: startCase(OBJECT_KEYS.total),
      customRender: (rowData) => getStarRating(rowData, true, OBJECT_KEYS.total, OBJECT_KEYS.label),
    });
  }
  return evaluatorsHeader;
};

export const getTotalEvaluationTable = (
  evaluation,
  results,
  user,
  performanceData,
  t,
  classes,
  isOpen,
) => (isEqual(evaluation.visibility, VISIBILITY.COMPACT) ? (
  <SimpleTable
    header={ getEvaluationHeader(t) }
    data={ getResults(results, t) }
  />
) : (
  <SimpleTable
    header={ getEvaluatorsHeader(
      evaluatorHeader(results),
      t,
      classes,
      isOpen,
    ) }
    data={ getQuestions(results, performanceData.result_scale_indices, isOpen) }
    size={ SIZE.small }
    newRow
  />
));

export const accordionData = (t, results, scale, handleModal) => results?.map((item, index) => {
  const isWide = isEqual(item.visibility, VISIBILITY.WIDE);
  const resultSection = RESULT_SECTIONS(t).filter(
    (section) => !isEmpty(section.translations.filter((section) => item?.name.includes(section))),
  );

  const data = {
    title: item?.name,
    customHeader: (
      <StyledAccordionHeader container spacing={ 2 } isWide={ isWide }>
        <Grid item xs={ 12 } md={ 6 }>
          <Typography variant={ VARIANT.h6 }>{ isWide ? item?.name : `${index + 1}. ${item?.name}` }</Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <StaticProgressBar
            title={ item?.result || " " }
            percentage={ getPercent(item?.score || MIN_VALUE) }
            color={ getScaleByScore(item?.score, scale)?.color }
          />
        </Grid>
        { !isEmpty(resultSection)
          && item.score < LEADER_CLASSIFICATION_RANK.EXEMPLARY_LEADER[INDEX.zero]
          && (
            <Grid item xs={ 12 } md={ 2 }>
              <StyledLink onClick={ () => handleModal({
                name: item?.name,
                key: resultSection[INDEX.zero].key,
                urls: resultSection[INDEX.zero].urls,
              }) }
              >
                { t("goodLeader:wantToImproveIt") }
              </StyledLink>
            </Grid>
          )}
      </StyledAccordionHeader>
    ),
  };
  if (isWide && !isEmpty(item.evaluationResults)) {
    data.customRender = (
      <StyledAnswers>
        {item?.evaluationResults[INDEX.zero].evaluation_answers.map((answer, indexAnswer) => (
          <StyledQuestion container spacing={ 3 } key={ `answer-${answer.question_id}` }>
            <Grid item xs={ 12 } md={ 6 }>
              <Typography variant={ VARIANT.bodyOne }>{ `${indexAnswer + 1}. ${answer?.question}` }</Typography>
            </Grid>
            <Grid item xs={ 12 } md={ 5 }>
              <StaticProgressBar
                title={ answer?.result || " " }
                percentage={ getPercent(answer?.score || MIN_VALUE) }
                color={ getScaleByScore(answer?.score, scale)?.color }
              />
            </Grid>
          </StyledQuestion>
        ))}
      </StyledAnswers>
    );
  }

  return data;
});

export const renderComments = (comments, classes) => comments.map((item, index) => (
  <Grid
    item
    xs={ 12 }
    md={ 6 }
    key={ `result-${index}` }
    className={ classes.commentGrid }
  >
    <div className={ classes.commentItem }>
      <Typography variant={ VARIANT.bodyOne }>{item}</Typography>
    </div>
  </Grid>
));

const evaluationResults = (data) => (
    data?.results_by_evaluation_type
    && data?.results_by_evaluation_type[INDEX.zero]
);

export const getAnswers = (data) => {
  const answers = data?.complete_results_by_section.map((answer) => ({
    name: answer.name,
    result: answer.evaluation_results[INDEX.zero]?.result,
    score: answer.evaluation_results[INDEX.zero]?.score,
    visibility: answer.visibility,
    evaluationResults: answer.evaluation_results,
  }));

  return answers;
};

export const getComments = (data) => evaluationResults(data)?.comments;

export const getRange = (scale) => `${getPercent(scale.bottom, true)} - ${getPercent(scale.top, true)}`;

export const locationPath = "performance";
