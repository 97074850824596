import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {
  VARIANT, SIZE, INDEX, OBJECT_KEYS, SORT_COMPARATOR,
} from "common/constants";
import { isEqual, isEmpty, isEmptyObject } from "common/helpers";
import companyInfoIcon from "assets/images/onboarding/company.svg";
import itemIcon from "assets/images/onboarding/item.svg";
import CardSection from "views/Onboarding/components/CardSection";
import { StyledTypography } from "views/Templates/styles";
import NoDataMessage from "components/NoDataMessage";
import InputTag from "components/InputTag";
import {
  toast, handleMessages, MESSAGE_TYPES, HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import SortableItem from "../SortableItem";
import FormItem from "../FormItem";
import { getNewFormat } from "../functions";
import {
  StyledItemDetailContainer,
  StyledTypographyItem,
  StyledSelectedContainer,
} from "../styles";

const SectionItems = (props) => {
  const {
    data, setValue, defaultValues,
  } = props;
  const { t } = useTranslation(["templates", "Onboarding", "candidates", "common"]);

  const [items, setItems] = useState([]);
  const [showItemId, setShowItemId] = useState("");
  const [inputTextValue, setInputTextValue] = useState("");
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [newItem, setNewItem] = useState(null);
  const [newSectionItem, setNewSectionItem] = useState({});

  useEffect(() => {
    setInputTagsSelected(defaultValues);
    const itemList = defaultValues?.map((item) => item.id);
    setItems(itemList);
  }, [defaultValues]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!isEqual(active.id, over.id)) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const setShowItem = (id) => {
    setShowItemId(id);
  };

  useEffect(() => {
    if (data?.section_items && !isEmptyObject(newSectionItem)) {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );

      const inputTagsNewItem = getNewFormat([newSectionItem])[INDEX.zero];
      const oldItem = data.section_items.findIndex((sectionItem) => isEqual(sectionItem.id, newSectionItem.id));
      if (!isEqual(oldItem, SORT_COMPARATOR.minusOne)) {
        data.section_items.splice(oldItem, INDEX.one);
        const oldInputTag = inputTagsSelected.findIndex((inputTag) => isEqual(inputTag.id, inputTagsNewItem.id));
        inputTagsNewItem.required = inputTagsSelected[oldInputTag].required;
        inputTagsSelected[oldInputTag] = inputTagsNewItem;
      } else {
        inputTagsSelected.push(inputTagsNewItem);
        items.push(newSectionItem.id);
      }
      data.section_items.push(newSectionItem);
      setNewSectionItem({});
      setShowItem(null);
    }
    setValue(
      inputTagsSelected.map((item) => ({
        id: item.id,
        idParent: item.idParent,
        required: item.required,
        order: items.indexOf(item.id),
      })),
    );
  }, [t, data, newSectionItem, inputTagsSelected, items, setValue]);

  const showItemInfo = (itemsBySection) => {
    const itemDetail = isEqual(showItemId, OBJECT_KEYS.new) ? newItem
      : itemsBySection.find((item) => isEqual(item.id, showItemId));
    return (
      <FormItem
        data={ itemDetail }
        onClose={ setShowItem }
        setNewSectionItem={ setNewSectionItem }
      />
    );
  };

  const handleTags = (addedValues) => {
    setInputTagsSelected(addedValues);
    const itemList = addedValues?.map((item) => item.id);
    setItems(itemList);
  };

  const handleAddDocument = async () => {
    if (!isEmpty(inputTextValue)) {
      setShowItemId(OBJECT_KEYS.new);
      setNewItem({ section_id: data?.id, name: inputTextValue });
    }
  };

  const handleInputText = (text) => setInputTextValue(text);

  const handleInputData = () => getNewFormat(data?.section_items);

  const removeItem = (itemId) => {
    const newList = inputTagsSelected.filter((item) => !isEqual(item.id, itemId));
    setInputTagsSelected(newList);
    const itemList = items.filter((item) => !isEqual(item, itemId));
    setItems(itemList);
  };

  const handleChange = (itemData) => {
    const newState = [...inputTagsSelected];
    newState.find((item) => isEqual(item.id, itemData.id)).required = !itemData.required;
    setInputTagsSelected(newState);
  };

  const selectedItem = showItemId ? showItemInfo(data?.section_items)
    : (
      <NoDataMessage
        message={ t("sections.form.select_item") }
        iconImage={ itemIcon }
      />
    );

  return (
    <CardSection
      data-testid={ "section-items-view-component" }
      title={ t("sections.title") } // TODO: manage multiple sections
      icon={ companyInfoIcon }
      noPadding
    >
      <StyledTypography>{ t("sections.description") }</StyledTypography>
      <Grid item xs={ 12 } sm={ 6 }>
        <InputTag
          id={ "section_item" }
          label={ t("sections.select") }
          size={ SIZE.small }
          itemsSelected={ inputTagsSelected }
          onChange={ handleTags }
          data={ handleInputData() }
          hasCheckbox
          textAddDinamicTag={ t("sections.add") }
          onAddDinamicTag={ handleAddDocument }
          onInputTextChange={ handleInputText }
        />
      </Grid>
      <StyledSelectedContainer>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } md={ 6 }>
                <StyledTypographyItem variant={ VARIANT.h5 } >{ t("sections.form.added_items") }</StyledTypographyItem>
                <DndContext
                  sensors={ sensors }
                  collisionDetection={ closestCenter }
                  onDragEnd={ handleDragEnd }
                >
                  <SortableContext
                    items={ items }
                    strategy={ verticalListSortingStrategy }
                  >
                    {items.map((item) => {
                      const itemInfo = data?.section_items.find((sectionItem) => isEqual(sectionItem.id, item));
                      const inputTagsSelectedRequired = inputTagsSelected.find((requiredInfo) => isEqual(requiredInfo.id, item))?.required;
                      itemInfo.required = inputTagsSelectedRequired;
                      return (
                        <SortableItem
                          key={ item }
                          id={ item }
                          data={ itemInfo }
                          setShowItem={ setShowItem }
                          showItemId={ showItemId }
                          removeItem={ removeItem }
                          handleChange={ handleChange }
                          handle
                        />
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </Grid>
              <StyledItemDetailContainer item xs={ 12 } md={ 6 }>
                { selectedItem }
              </StyledItemDetailContainer>
            </Grid>
          </Grid>
        </Grid>
      </StyledSelectedContainer>
    </CardSection>

  );
};

SectionItems.propTypes = {
  data: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  defaultValues: PropTypes.array,
};

SectionItems.defaultProps = {
  defaultValues: [],
};

export default SectionItems;
