import { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  INPUT_TYPE,
  TIMEOUT_DISPATCH,
  VARIANT,
  OBJECT_KEYS,
} from "common/constants";
import { isEmpty, isEqual, isNull } from "common/helpers";
import InputTextController from "components/InputTextController";

const InputTextSearch = (props) => {
  const {
    handleInput, defaultValue, label, isDisabled,
  } = props;
  const { t } = useTranslation("common");
  const { control, watch } = useForm();
  const searchWatch = watch(OBJECT_KEYS.search);
  const title = isEmpty(label) ? t("common.search") : label;
  useEffect(() => {
    if (isNull(defaultValue) || !isEqual(defaultValue, searchWatch)) {
      const delayDebounceFn = setTimeout(() => {
        handleInput(searchWatch);
      }, TIMEOUT_DISPATCH);

      return () => clearTimeout(delayDebounceFn);
    }
  // eslint-disable-next-line
  }, [searchWatch]);

  return (
    <InputTextController
      type={ INPUT_TYPE.text }
      placeholder={ title }
      control={ control }
      name={ OBJECT_KEYS.search }
      customStyles={ "searchInput" }
      id={ "standard-basic" }
      variant={ VARIANT.standard }
      disabled={ isDisabled }
      defaultValue={ defaultValue }
    />
  );
};

InputTextSearch.propTypes = {
  handleInput: PropTypes.func.isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.string,
};

InputTextSearch.defaultProps = {
  label: "",
  isDisabled: false,
  defaultValue: null,
};

export default InputTextSearch;
