import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const Content = styled(Grid)`
  margin-top: 20px;
`;

export const Container = styled(Grid)`
  border-top: 3px solid ${palette.text.iconDisabled};
`;

export const Actions = styled(Grid)`
  text-align: right;
  margin-top: 20px;
`;
