import styled from "styled-components";
import palette from "theme/palette";

export const StyledLink = styled.div`
  font-size: 14px;
  color: ${palette.text.link};
  text-align: ${(props) => props.align };
  text-decoration: underline;
  :hover{
    cursor: pointer;
    color: ${palette.text.purple};
  }
`;
