import styled from "styled-components";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  .MuiAutocomplete-tag {
    font-size: 10px !important;
    color: ${palette.background.valid} !important;
    border: 1px solid ${palette.background.valid} !important;
    background: none;
    &.MuiChip-root {
      margin: 1px;
      height: 18px;
      .MuiChip-deleteIcon {
        color: ${palette.background.valid};
        font-size: 16px;
      }
    }
  }
  .submit {
    background: ${palette.background.valid};
    font-size: 10px;
    :hover {
      background: ${palette.primary};
    }
  }
  .cancel {
    background: none;
    color: ${palette.background.valid};
    border-color: ${palette.background.valid};
    font-size: 10px;
    :hover {
      background: ${palette.background.lightGray};
    }
  }
`;

export const StyledOptionContainer = styled.li`
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  line-height: 14px;
  .MuiCheckbox-root {
    padding: 0;
    margin-right: 4px;
  }
  .MuiAvatar-root {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
`;
