import styled from "styled-components";
import palette from "theme/palette";

export const StyledDashboardContainer = styled.div`
  padding: 32px;
  margin-top: 16px;
  margin-bottom: 25px;
  .custom-select {
    background-color: ${palette.white};
    padding: 10px 0 10px 0;
    label + & {
      margin-top: 8;
    }
  }
`;
