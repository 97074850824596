import {
  useEffect, useState, useCallback,
} from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import VerticalStepperList from "components/VerticalStepperList";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleAlert from "components/SimpleAlert";
import { VARIANT, SKELETONS_NUMBER } from "common/constants";
import { getCurrentLanguage, formatDateMonthYear } from "common/utils";
import { isNotValid } from "common/helpers";
import { getLeadershipTypeResultsByEmployee } from "redux/actions/leadershipTypeResults/leadershipTypeResultsActions";
import {
  Container, Title, StyledGrid, StyledCurrentQuadrant,
} from "./styles";

const EmployeeHistory = (props) => {
  const {
    data,
    t,
  } = props;

  const employeeId = data?.employee?.id;
  const currentLeadershipTypeId = data?.id;
  const language = getCurrentLanguage();

  const [employeeHistory, setEmployeeHistory] = useState([]);

  // React Query
  // When obtaining the history of an employee,
  // it is avoided to consult again since it caches the data
  const {
    data: leadershipTypeHistoryByEmployee,
    isLoading,
    error,
  } = useQuery(["leadershipTypeHistoryByEmployee", employeeId, currentLeadershipTypeId],
    () => getLeadershipTypeResultsByEmployee(employeeId, currentLeadershipTypeId), {
    // considered time in which the state has no changes to avoid querying API
    // use Infinity when considered to be unchanged
      staleTime: Infinity,
    });
  // End React Query

  const getLeadershipTypeDetail = useCallback((calculatedAt, leadershipType) => {
    const date = formatDateMonthYear(new Date(calculatedAt));
    return `${t("leadership:quadrant.title")}: ${leadershipType} (${date})`;
  }, [t]);

  const getEmployeeHistory = useCallback(async () => {
    const historyByEmployee = leadershipTypeHistoryByEmployee.map((result) => {
      const leadershipType = result.leadership_type[`name_${language}`];
      const leadershipTypeDetail = getLeadershipTypeDetail(result.calculated_at, leadershipType);
      return {
        title: <Typography variant={ VARIANT.bodyOne }>{ leadershipTypeDetail }</Typography>,
      };
    });
    setEmployeeHistory(historyByEmployee);
  }, [
    leadershipTypeHistoryByEmployee,
    getLeadershipTypeDetail,
    language,
  ]);

  useEffect(() => {
    if (!isLoading && leadershipTypeHistoryByEmployee) {
      getEmployeeHistory();
    }
    // eslint-disable-next-line
  }, [leadershipTypeHistoryByEmployee, isLoading]);

  const historicalData = () => {
    if (error) {
      return (
        <SimpleAlert
          type={ "error" }
          title={ t("leadership:errors.history") }
        />
      );
    }
    return (
      <StyledGrid container>
        <Grid item xs={ 12 }>
          <StyledCurrentQuadrant>
            <Typography variant={ VARIANT.h6 }>
              {getLeadershipTypeDetail(data.calculated_at, data.quadrantData[`name_${language}`])}
            </Typography>
          </StyledCurrentQuadrant>
        </Grid>
        {employeeHistory.length > 0
        && (
          <>
            <Title variant={ VARIANT.h6 }>
              {`${t("leadership:historical")}:`}
            </Title>
            <VerticalStepperList data={ employeeHistory } />

          </>
        )}
      </StyledGrid>
    );
  };

  return (
    <Container>
      {isLoading && isNotValid(leadershipTypeHistoryByEmployee) && employeeHistory
        ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FOUR } />
        : historicalData() }
    </Container>
  );
};

EmployeeHistory.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default EmployeeHistory;
