import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  svg {
    width: ${(props) => props.width || "40px"};
    height: ${(props) => props.height || "40px"};
    color: ${(props) => props.color || "inherit"};
    fill: ${(props) => props.color || "inherit"};
  }
`;

export const StyledSubtitle = styled(Typography)`
  margin-left: 4px;
`;
