import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledTitle = styled.div`
  font-size: 20px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyledManagerInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledProfileImageDetail = styled(Grid)`
  div {
    text-decoration: none;
  }
  h2 {
    font-size: 20px;
    color: ${palette.text.secondaryTitle};
  }
  svg {
    font-size: 16px;
  }
  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }
`;

export const StyledInfo = styled.p`
  color: ${palette.error.main};
  font-weight: 500;
  font-size: 14px;
`;
