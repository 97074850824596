import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import SkeletonLoader from "components/SkeletonLoader";
import Pagination from "components/Pagination";
import SearchInput from "components/SearchInput";
import {
  ASC,
  ORDER_BY_NAME,
  DESC,
  SKELETONS_NUMBER,
  SIZE,
  PAGINATION,
  MIN_VALUE,
} from "common/constants";
import { getSearchResult } from "./functions";
import TableHeader from "./components/TableHeader";
import TBody from "./components/TableBody";
import { StyledContainer, StyledSearchContainer } from "./styles";

const TableComponent = (props) => {
  const [order, setOrder] = useState(ASC);
  const [orderBy, setOrderBy] = useState(ORDER_BY_NAME);
  const [search, setSearch] = useState("");
  const [totalRows, setTotalRows] = useState(MIN_VALUE);
  const [lastPage, setLastPage] = useState(PAGINATION.next);
  const [currentPage, setCurrentPage] = useState(PAGINATION.next);
  const [maxPerPage, setMaxPerPage] = useState(
    props.paginationOptions
      ? props.paginationOptions.maxPerPage
      : PAGINATION.maxPerPage
  );
  const [isSearch, setIsSearch] = useState(false);
  const [listAux, setListAux] = useState([]);

  const {
    id,
    header,
    list,
    searchPlaceHolder,
    searchBy,
    paginationOptions,
    disabledInputSearch,
    isLoading,
    isSimpleTable,
    getCollapseData,
    customStyles,
    isNotPagination,
    isNotHeader,
    isDragable,
    actions,
  } = props;

  const getLastPage = useCallback(
    (list) => {
      let lastPage = list && list.length / maxPerPage;
      return Number.isInteger(lastPage)
        ? lastPage
        : Math.floor(lastPage) + PAGINATION.next;
    },
    [maxPerPage]
  );

  useEffect(() => {
    setTotalRows(list ? list.length : MIN_VALUE);
    setLastPage(getLastPage(list));
  }, [getLastPage, list]);

  const handleSearch = (value) => {
    isEqual(value, "") ? setIsSearch(false) : setIsSearch(true);
    setSearch(value);
    const searchResult = list ? getSearchResult(list, searchBy, value) : [];
    setCurrentPage(PAGINATION.next);
    setLastPage(getLastPage(searchResult));
    setTotalRows(searchResult.length);
    setListAux(searchResult);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderBy(property);
  };

  const handleChangePagination = (event) => {
    setMaxPerPage(event.target.value);
    setLastPage(getLastPage(list));
    setCurrentPage(PAGINATION.next);
  };

  const handleControls = (page) => {
    setCurrentPage(page);
  };

  return (
    <StyledContainer data-testid={ "table-container" }>
      <Paper>
        {!isSimpleTable && (
          <StyledSearchContainer>
            <SearchInput
              placeholder={ searchPlaceHolder }
              value={ search }
              onChange={ handleSearch }
              disabled={ disabledInputSearch }
            />
          </StyledSearchContainer>
        )}

        <TableContainer className={ customStyles || "" }>
          <Table size={ SIZE.small }>
            {!isNotHeader && (
              <TableHeader
                order={ order }
                orderBy={ orderBy }
                onRequestSort={ handleRequestSort }
                header={ header }
              />
            )}

            <TBody
              list={ isSearch ? listAux : list }
              keys={ header }
              order={ order }
              orderBy={ orderBy }
              currentPage={ currentPage }
              maxPerPage={ maxPerPage }
              colspan={ header?.length }
              isLoading={ isLoading }
              getCollapseData={ getCollapseData }
              isNotPagination={ isNotPagination }
              isNotHeader={ isNotHeader }
              isDragable={ isDragable }
              actions={ actions }
            />
          </Table>
        </TableContainer>
        {isLoading ? (
          <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
        ) : (
          !isSimpleTable
          && !isNotPagination
          && list?.length > MIN_VALUE
          && !isLoading && (
            <Pagination
              id={ id }
              rowText={ paginationOptions?.rowText }
              maxPerPage={ maxPerPage }
              rowsRange={ paginationOptions?.rowsRange }
              currentPage={ currentPage }
              totalRows={ totalRows }
              lastPage={ lastPage }
              onClick={ handleControls }
              onChange={ handleChangePagination }
            />
          )
        )}
      </Paper>
    </StyledContainer>
  );
};

TableComponent.propTypes = {
  id: PropTypes.string,
  header: PropTypes.array,
  list: PropTypes.array,
  searchPlaceHolder: PropTypes.string,
  searchBy: PropTypes.string,
  paginationOptions: PropTypes.object,
  disabledInputSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSimpleTable: PropTypes.bool,
  getCollapseData: PropTypes.func,
  customStyles: PropTypes.string,
  isNotPagination: PropTypes.bool,
  isNotHeader: PropTypes.bool,
  isDragable: PropTypes.bool,
  actions: PropTypes.object,
};

export default TableComponent;
