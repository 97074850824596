import { /* useQuery, */useMutation, useQueryClient } from "react-query";
import {
  // getCalibrations,
  // getCalibrationById,
  createCalibration,
  updateCalibration,
  // deleteCalibration,
  // getCalibrationBySurveyResult,
} from "redux/actions/surveyProcessesActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

const handleQueryError = (error, t) => {
  let errorMessage = t("common:calibration.alert.default_error");

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  } else {
    errorMessage = error.toString();
  }

  toast(MESSAGE_TYPES.error, { message: errorMessage });
};
// export const useCalibrations = () => {
//   const {
//     data: calibrations, isLoading, isError, error,
//   } = useQuery("calibrations", getCalibrations, {
//     staleTime: Infinity,
//     onError: (queryError) => handleQueryError(queryError, t),
//   });

//   return {
//     calibrations,
//     isLoading,
//     isError,
//     error,
//   };
// };

// export const useCalibrationById = (calibrationId) => {
//   const {
//     data: calibration, isLoading, isError, error,
//   } = useQuery(
//     ["calibration", calibrationId],
//     () => getCalibrationById(calibrationId),
//     {
//       staleTime: Infinity,
//       onError: (queryError) => handleQueryError(queryError, t),
//     },
//   );

//   return {
//     calibration,
//     isLoading,
//     isError,
//     error,
//   };
// };

export const useCreateCalibration = (t) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createCalibration, {
    onSuccess: () => {
      queryClient.invalidateQueries("calibrations");
      toast(MESSAGE_TYPES.success, { message: t("common:calibration.alert.create") });
    },
    onError: (mutationError) => handleQueryError(mutationError, t),
  });

  return mutation;
};

export const useUpdateCalibration = (t) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(({ id, data }) => updateCalibration(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("calibrations");
      toast(MESSAGE_TYPES.success, { message: t("common:calibration.alert.update") });
    },
    onError: (mutationError) => handleQueryError(mutationError, t),
  });

  return mutation;
};

// export const useDeleteCalibration = (t) => {
//   const queryClient = useQueryClient();

//   const mutation = useMutation(deleteCalibration, {
//     onSuccess: () => {
//       queryClient.invalidateQueries("calibrations");
//       toast(MESSAGE_TYPES.success, { message: t("common:calibration.alert.delete") });
//     },
//     onError: (mutationError) => handleQueryError(mutationError),
//   });

//   return mutation;
// };

// export const useCalibrationBySurveyResultId = (surveyResultId) => {
//   const {
//     data: calibrationBySurveyResult, isLoading, isError, error,
//   } = useQuery(
//     ["calibrationBySurveyResult", surveyResultId],
//     () => getCalibrationBySurveyResult(surveyResultId),
//     {
//       staleTime: Infinity,
//       onError: (queryError) => handleQueryError(queryError, t),
//     },
//   );

//   return {
//     calibrationBySurveyResult,
//     isLoading,
//     isError,
//     error,
//   };
// };
