import {
  useState, useContext, useEffect, useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { SessionContext } from "modules/session/context";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import Filter from "components/Filter";
import Alert from "components/Alert";
import TalentSpiderChart from "components/TalentSpiderChart";
import PerformanceChart from "components/PerformanceChart";
import { getFormattedObject, getFilterNames } from "components/TalentSpiderChart/functions";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import {
  ALIGN_ITEMS, INDEX, VARIANT, ATTRITION_AXES_MAX_FOR_RECOMMENDATION,
} from "common/constants";
import {
  getParamEmployeeId, getAverageResult, getPercent, getProcessNameById,
} from "common/utils";
import { isNullOrUndefinedOrEmpty } from "common/validators";
import { isEmpty, isEqual } from "common/helpers";
import { getOne as getCollaborator } from "redux/actions/collaboratorActions";
import { getCollaboratorPerformanceEvolution } from "redux/actions/surveyProcessesActions";
import { getList as getPerformanceProcess } from "redux/actions/performance/performanceActions";
import { StyledContainer, StyledTitle, StyledGridFlex } from "styledComponents/View";
import { StyledChartContainer } from "styledComponents/Charts";
import { StyledTabContent } from "styledComponents/Tabs";
import {
  StyledCollapseContainer,
  StyledPercent,
  StyledRisk,
} from "views/TalentDrain/components/RiskCardCollapse/styles";
import { RISK_CATEGORY } from "views/TalentDrain/functions";
import Recommendations from "./components/Recommendations";
import {
  TABS, INDEX_TABS, getRecommendations,
} from "../../functions/lossRisk";
import { StyledAttritionProbability, StyledGridAlert } from "./styles";

const LossRisk = (props) => {
  const { params } = props;
  const { t, i18n } = useTranslation(["talentDrain", "performance"]);
  const { language } = i18n;
  const {
    state: { user },
  } = useContext(SessionContext);

  const [tabHandler, setTabHandler] = useState(INDEX.zero);

  const dispatch = useDispatch();

  const collaboratorId = getParamEmployeeId(user, params);

  const {
    list: collaboratorAttrition,
    loadingList: loadingCollaboratorAttrition,
  } = useSelector(({ collaboratorAttritionReducer }) => collaboratorAttritionReducer);

  const {
    one: collaboratorSelected,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    collaboratorPerformanceEvolution: performanceEvolution,
    loadingCollaboratorPerformanceEvolution: loadingPerformanceEvolution,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const {
    list: performanceProcessesList,
    loadingList: isLoadingPerformanceProcessesList,
  } = useSelector(({ performanceReducer }) => performanceReducer);

  useEffect(() => {
    if (!collaboratorSelected) {
      dispatch(getCollaborator(collaboratorId));
    } else {
      dispatch(getCollaboratorPerformanceEvolution(collaboratorId));
    }
  }, [dispatch, collaboratorId, collaboratorSelected]);

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  useEffect(() => {
    dispatch(getPerformanceProcess());
  }, [dispatch]);

  const spiderChart = (
    <TalentSpiderChart
      dataFormatted={ getFormattedObject(collaboratorAttrition?.averages) }
      filterNames={ getFilterNames(collaboratorAttrition?.averages) }
      isPersonal
    />
  );

  const getEvaluations = useCallback(() => {
    let evaluations = [];
    if (!isLoadingPerformanceProcessesList && !loadingPerformanceEvolution && performanceEvolution && performanceProcessesList) {
      evaluations = performanceEvolution.filter((evolution) => evolution?.id).map((item) => ({
        value: item.id,
        label: getProcessNameById(performanceProcessesList, item.id, language),
      }));
    }
    return evaluations.map((evaluation) => evaluation.label);
  }, [
    loadingPerformanceEvolution,
    isLoadingPerformanceProcessesList,
    performanceEvolution,
    performanceProcessesList,
    language,
  ]);

  const recommendations = <Recommendations data={ getRecommendations(collaboratorAttrition?.responseList, t) } isLoading={ loadingCollaboratorAttrition } />;

  const attritionProbability = () => {
    if (!isNullOrUndefinedOrEmpty(collaboratorAttrition?.responseList)) {
      const probability = collaboratorAttrition.responseList[INDEX.zero]?.attrition_probability;
      const riskCategory = collaboratorAttrition.responseList[INDEX.zero]?.risk_category;

      if (probability && riskCategory) {
        return (
          <StyledAttritionProbability>
            <StyledCollapseContainer>
              <StyledPercent>{ getPercent(probability, true) }</StyledPercent>
              <StyledRisk
                color={ RISK_CATEGORY[riskCategory].color }
              >
                <Typography variant={ VARIANT.h4 }>
                  {t(`talentDrain:${riskCategory}Risk`)}
                </Typography>
                <LinearProgress
                  variant={ VARIANT.determinate }
                  value={ getPercent(probability) }
                />
              </StyledRisk>
            </StyledCollapseContainer>
          </StyledAttritionProbability>
        );
      }
    }
    return "";
  };

  const getRecommendationByExceptionalCase = () => {
    let isValidForTheRecommendation = false;
    const riskCategory = collaboratorAttrition?.responseList[INDEX.zero]?.risk_category;
    const averageResults = collaboratorAttrition?.averages[INDEX.zero]?.values;
    if (riskCategory && isEqual(riskCategory, RISK_CATEGORY.high.key) && !isEmpty(averageResults)) {
      const averageResultsArray = Object.values(averageResults);
      isValidForTheRecommendation = averageResultsArray.every(
        (item) => item > ATTRITION_AXES_MAX_FOR_RECOMMENDATION,
      );
    }
    return isValidForTheRecommendation ? <Alert text={ t("talentDrain:chart.recommendation") } /> : "";
  };

  return (
    <StyledContainer data-testid={ "loss-risk-tab-view-component" } padding={ "24px" }>
      <Grid container>
        <Grid item xs={ 12 } sm={ 7 } md={ 8 }>
          <Grid container>
            <Grid item xs={ 12 } md={ 6 }>
              <StyledTitle>{t("satisfactionMap")}</StyledTitle>
            </Grid>
            <Hidden smDown>
              <Grid item xs={ 12 } md={ 3 } />
              <StyledGridFlex justifyContent={ ALIGN_ITEMS.flexEnd } item xs={ 12 } md={ 3 } >
                <Filter type={ FILTER_ACTIONS_TYPES.attritionCollaborator } externalParams={ [collaboratorId] } />
              </StyledGridFlex>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={ 12 }>
                {spiderChart}
              </Grid>
              <StyledGridAlert item xs={ 12 }>
                {getRecommendationByExceptionalCase()}
              </StyledGridAlert>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={ 12 } sm={ 5 } md={ 4 }>
            <StyledContainer padding={ "16px" } height={ "580px" }>
              {attritionProbability()}
              {recommendations}
            </StyledContainer>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={ 12 }>
            <Tab
              tabs={ TABS(t) }
              onChange={ handleValueChanged }
              tabValue={ tabHandler }
            />
            <TabPanel
              value={ tabHandler }
              index={ INDEX_TABS.map }
            >
              <StyledTabContent>
                {spiderChart}
                {getRecommendationByExceptionalCase()}
              </StyledTabContent>
            </TabPanel>
            <TabPanel
              value={ tabHandler }
              index={ INDEX_TABS.recommendations }
            >
              <StyledTabContent>
                {attritionProbability()}
                {recommendations}
              </StyledTabContent>
            </TabPanel>
          </Grid>
        </Hidden>
        <Grid item xs={ 12 } md={ 10 }>
          <StyledTitle>{t("performance:evolution")}</StyledTitle>
          <StyledChartContainer>
            <PerformanceChart
              title={ t("performance:dashboard.graphic_title") }
              labels={ getEvaluations() }
              data={ performanceEvolution ? getAverageResult(performanceEvolution) : [] }
              isLoading={ isLoadingPerformanceProcessesList || loadingPerformanceEvolution }
            />
          </StyledChartContainer>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

LossRisk.propTypes = {
  params: PropTypes.object.isRequired,
};

export default LossRisk;
