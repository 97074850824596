import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import gt from "lodash/gt";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { StyledToolbar, StyledTitle } from "./styles";

const TableToolbar = (props) => {
  const {
    tooltipTitle,
    tooltipIcon: TooltipIcon,
    numSelected,
    itemsSelected,
    onClick,
    isMainTable,
  } = props;
  const { t } = useTranslation("common");

  const handleOnClick = () => {
    onClick(itemsSelected);
  };

  return (
    <StyledToolbar
      data-testid={ "simple-table-checkbox-toolbar" }
      highlight={ numSelected > 0 }
    >
      {gt(numSelected, 0) && (
        <>
          <StyledTitle>
            {`${numSelected} ${
              gt(numSelected, 1) ? t("selected_plural") : t("selected")
            }`}
          </StyledTitle>
          {!isMainTable && (
            <Tooltip title={ tooltipTitle }>
              <IconButton aria-label={ tooltipTitle } onClick={ handleOnClick }>
                {<TooltipIcon />}
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </StyledToolbar>
  );
};

TableToolbar.propTypes = {
  tooltipTitle: PropTypes.string,
  tooltipIcon: PropTypes.any,
  numSelected: PropTypes.number.isRequired,
  itemsSelected: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  isMainTable: PropTypes.bool,
};

export default TableToolbar;
