import { ROLES } from "common/constants";

const roleAccess = {
  candidate: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.ADMIN_COLOMBIA,
    ROLES.ONBOARDING_ADMIN,
  ],
};

export default roleAccess;
