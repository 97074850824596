import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import palette, { TOP_BAR } from "theme/palette";

export const StyledContainer = styled.div`
  button:hover {
    background: none;
  }
  .MuiButton-root {
    min-width: 20px !important;
  }
  svg {
    opacity: 0.8;
    color: ${ TOP_BAR.background };
    :hover {
      opacity: 1;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  a {
    width: 100%;
    color: ${ palette.black };
  }
`;
