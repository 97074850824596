import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import Switch from "@mui/material/Switch";
import palette from "theme/palette";

export const StyledGrid = styled(Grid)`
  border: ${palette.borderTab};
  margin-top: 32px;
  border-radius: 4px;
`;

export const StyledBox = styled(Box)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const StyledBoldText = styled.div`
  font-weight: 500;
`;

export const StyledChartIcon = styled(InsertChartOutlinedIcon)`
  display: flex;
  width: 28px;
  height: 28px;
  color: ${palette.text.secondaryTitle};
  :hover {
    cursor: pointer;
    color: ${palette.text.link};
  }
`;

export const StyledBoxContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledImageContainer = styled.div`
  position: relative;
`;

export const StyledContainerManagerData = styled.div`
  margin-bottom: 20px;
  .row-0 {
    background-color: ${palette.background.purpleLight};
  }
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-colorPrimary.Mui-checked {
      color: ${palette.text.link};
    }
`;
