import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ThumbsUpDown from "@mui/icons-material/ThumbsUpDown";
import StarsIcon from "@mui/icons-material/Stars";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { SessionContext } from "modules/session/context";
import { getEmployeeCompanyId, getEmployeeId } from "common/utils";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import FeedbackContent from "./components/FeedbackContent";
import FeedbackFormModal from "./components/FeedbackFormModal";
import AcknowledgmentFormModal from "./components/AcknowledgmentFormModal";
import { useStyles } from "./styles";

const Feedback = (props) => {
  const { profileViewEmployeeId } = props;
  const classes = useStyles();
  const { t } = useTranslation(["feedback", "common"]);
  const [isOpenModalForm, setIsOpenModalForm] = useState({
    feedback: false,
    acknowledgment: false,
  });
  const {
    state: { user },
  } = useContext(SessionContext);

  const companyId = getEmployeeCompanyId(user) || null;
  const employeeId = profileViewEmployeeId || getEmployeeId(user) || null;

  const handleClickOnSend = (type) => {
    setIsOpenModalForm({
      ...isOpenModalForm,
      [type]: true,
    });
  };

  const handleCloseModalForm = () => {
    setIsOpenModalForm({
      feedback: false,
      acknowledgment: false,
    });
  };

  const ACTIONS_MENU = [
    {
      title: t("buttons.send_feedback"),
      icon: <ThumbsUpDown />,
      onClick: () => handleClickOnSend("feedback"),
    },
    {
      title: t("buttons.send_acknowledgment"),
      icon: <StarsIcon />,
      onClick: () => handleClickOnSend("acknowledgment"),
    },
  ];

  const showAlertErrorOrSuccess = (isError) => {
    const message = {
      title: t(`common:common.api_responses.${isError ? "error.title" : "success.title"}`),
      message: t(`feedback:${isError ? "error_feedback" : "send_feedback_successfully"}`),
    };

    toast(isError ? MESSAGE_TYPES.error : MESSAGE_TYPES.success, message);
  };

  return (
    <Card data-testid={ "feedback-view" }>
      <CardContent className={ classes.root }>
        <ViewHeaderTitle
          title={ t("feedback:title") }
          isWithMenuButton={ !profileViewEmployeeId }
          menuItems={ ACTIONS_MENU }
          menuButtonLabel={ t("feedback:buttons.actions") }
        />
        <>
          <FeedbackContent
            companyId={ companyId }
            employeeId={ employeeId }
          />
          <FeedbackFormModal
            employeeId={ employeeId }
            companyId={ companyId }
            isOpen={ isOpenModalForm.feedback }
            onClose={ handleCloseModalForm }
            onSend={ showAlertErrorOrSuccess }
          />
          <AcknowledgmentFormModal
            employeeId={ employeeId }
            companyId={ companyId }
            isOpen={ isOpenModalForm.acknowledgment }
            onClose={ handleCloseModalForm }
            onSend={ showAlertErrorOrSuccess }
          />
        </>
      </CardContent>
    </Card>
  );
};
Feedback.propTypes = {
  profileViewEmployeeId: PropTypes.number,
};

Feedback.defaultProps = {
  profileViewEmployeeId: null,
};
export default Feedback;
