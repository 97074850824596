import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  comments: {
    marginTop: theme.spacing(2),
  },
  commentItem: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.lightGrey,
    borderRadius: 5,
  },
  commentGrid: {
    display: "grid",
  },
}));
