import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getPropertyByLocation } from "common/utils";
import { ACTIONS, INDEX, LOCATION_PROPS } from "common/constants";
import TemplateInformation from "../components/TemplateInformation";
import { getDataBreadcrumbs } from "../functions";

const Forms = () => {
  const { t } = useTranslation(["templates"]);
  const history = useHistory();
  const location = useLocation();
  const thisModule = history?.location.pathname.split("/")[2];
  const isNew = thisModule === ACTIONS.new;
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const templateId = locationSearch?.split("=")[INDEX.one];

  return (
    <div data-testid={ "form-templates" }>
      <TemplateInformation
        header={{
          title: isNew ? t("actions.create") : t("actions.edit"),
          breadcrumb: getDataBreadcrumbs( t, thisModule ),
          alertMessage: isNew ? t("alertMessage.create_template") : t("alertMessage.update_template"),
        }}
        templateId={ templateId }
      />
    </div>
  );
};

export default Forms;
