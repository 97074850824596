import React from "react";
import PropTypes from "prop-types";
import TooltipIcon from "components/TooltipIcon";
import { ROLES_NAMES } from "common/constants";
import { isNotValid, isEmpty } from "common/helpers";
import { StyledNoData } from "views_refactor/Profile/styles";
import { StyleRolesContainer } from "../../styles";

const RolesWithTooltips = ({ collaborator, t }) => {
  if (isNotValid(collaborator?.roles_with_scope) || isEmpty(collaborator?.roles_with_scope)) {
    return <StyledNoData>{t("profile:noData")}</StyledNoData>;
  }

  return (
    <StyleRolesContainer>
      {collaborator.roles_with_scope.map((rol) => {
        const roleName = t(ROLES_NAMES[rol.name]);

        let accessDetails = "";
        if (!isEmpty(rol.managed_countries)) {
          accessDetails += `${t("talentDrain:filters.countries")}: ${rol.managed_countries.map((country) => country.name).join(", ")}`;
        }
        if (!isEmpty(rol.managed_organization_units)) {
          if (accessDetails) accessDetails += " | ";
          accessDetails += `${t("talentDrain:filters.orgUnits")}: ${rol.managed_organization_units.map((unit) => unit.complete_path || unit.name).join(", ")}`;
        }

        return (
          <div key={ rol.name } style={ { display: "inline" } }>
            {roleName}
            {accessDetails && <TooltipIcon title={ accessDetails } isHelpIcon />}
          </div>
        );
      }).reduce((prev, curr) => [prev, " | ", curr])}
    </StyleRolesContainer>
  );
};

RolesWithTooltips.propTypes = {
  collaborator: PropTypes.shape({
    roles_with_scope: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        managed_countries: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ),
        managed_organization_units: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            complete_path: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default RolesWithTooltips;
