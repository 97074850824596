import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import toNumber from "lodash/toNumber";
import includes from "lodash/includes";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SimpleTable from "components/SimpleTable";
import SimpleAlert from "components/SimpleAlert";
import Select from "components/Select";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import starImg from "assets/images/performance/star.svg";
import {
  SKELETONS_NUMBER,
  INDEX,
  INFO,
  OBJECT_KEYS,
  LOCATION_PROPS,
  ROLES,
  COMPANIES_WITHOUT_PERCENTAGE_PROFILE_PERFORMANCE,
  VARIANT,
} from "common/constants";
import {
  getEmployeeCompanyId,
  getEmployeeCompanySlug,
  getPropertyByLocation,
  enableComponent,
  getUserRoles,
} from "common/utils";
import {
  isEqual,
  isEmpty,
  isNull,
  isUndefined,
} from "common/helpers";
import { getList as getIndividualSurveyResult } from "redux/actions/performance/surveyResultsActions";
import { getList as getPerformanceProcess } from "redux/actions/performance/performanceActions";
import { getSurveyResults } from "redux/actions/surveyProcessesActions";
import paths from "common/paths";
import {
  getAlert,
  getReducerToUse,
  isActiveDataResult,
  getTotalHeader,
  getTotalEvaluationTable,
} from "../../functions/performance";
import AccordionRate from "./components/AccordionRate";
import { useStyles } from "./styles";

// TODO: Remove
const Performance = (props) => {
  const {
    user, from, reducerToUse, searchBy,
  } = props;
  const { t } = useTranslation(["performance", "surveys"]);
  const classes = useStyles();
  const {
    list: listPerformanceProcess,
    loadingList: isLoadingPerformanceProcess,
  } = useSelector(({ performanceReducer }) => performanceReducer);

  const {
    list: surveyResults,
    isLoadingList: isLoadingSurveyResults,
  } = useSelector(({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer);
  const dispatch = useDispatch();

  // search process id by url
  const location = useLocation();
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processParam = locationSearch?.split(paths.process)[INDEX.one];
  const processId = processParam ? locationSearch?.split("=")[INDEX.two] : null;

  const [values, setValues] = useState({
    evaluation: "",
  });
  const [activeResult, setActiveResult] = useState(false);
  const [dataUser, setUser] = useState(null);
  const [performanceData, setData] = useState(null);

  const [profile, setProfile] = useState(false);
  const [employeeProfile, setEmployeeProfile] = useState(false);

  const companySlug = getEmployeeCompanySlug(user);

  const setDataEmpty = useCallback(() => {
    setData([]);
    setActiveResult(true);
  }, [setData, setActiveResult]);

  const getDataByProfile = useCallback(() => {
    let result = [];
    if (!isEmpty(surveyResults) && !isNull(surveyResults)) {
      result = surveyResults.find((item) => (
        item.survey_process
          && isEqual(item.survey_process.id, values.evaluation)
      ));
    }
    if (!isUndefined(result)) {
      setData(result);
      setActiveResult(true);
    } else {
      setDataEmpty();
    }
    setProfile(false);
  }, [surveyResults, setDataEmpty, values.evaluation]);

  const getGeneralData = useCallback(() => {
    const userProfile = from
      ? getReducerToUse(searchBy, reducerToUse, user, from)
      : user;
    setUser(userProfile);
    if (!isEmpty(userProfile) && !includes(getUserRoles(user?.userCookies), ROLES.CANDIDATE)
    && !includes(searchBy, ROLES.CANDIDATE)) {
      dispatch(
        getPerformanceProcess(
          getEmployeeCompanyId(userProfile),
        ),
      );
      const employeeId = from ? userProfile?.id : userProfile?.employee?.id;
      if (employeeId && !includes(searchBy, ROLES.CANDIDATE)) {
        dispatch(getIndividualSurveyResult(employeeId));
        setProfile(true);
        setEmployeeProfile(false);
      } else {
        setDataEmpty();
      }
    }
  }, [searchBy, reducerToUse, user, from, dispatch, setDataEmpty]);

  useEffect(() => {
    if (!isLoadingSurveyResults && surveyResults && profile) {
      getDataByProfile();
    }
  }, [isLoadingSurveyResults, getDataByProfile, profile, surveyResults]);

  useEffect(() => {
    if (user && isNull(dataUser)) {
      getGeneralData();
    }
  }, [user, dataUser, getGeneralData]);

  // Get evaluations with results for the collaborator visualized
  const getEvaluations = useCallback(() => {
    const evaluations = [];
    if (!isLoadingSurveyResults && !isEmpty(surveyResults) && !isNull(surveyResults)) {
      surveyResults.forEach((item) => {
        const isPerformanceProcess = listPerformanceProcess?.find(
          (process) => process.id === item.survey_process?.id,
        );
        if (isPerformanceProcess) {
          evaluations.push({
            value: item.survey_process?.id,
            label: item.survey_process?.name,
          });
        }
      });
    }
    return evaluations;
  },
  [
    isLoadingSurveyResults,
    surveyResults,
    listPerformanceProcess,
  ]);

  useEffect(() => {
    if (employeeProfile && !isLoadingPerformanceProcess && !isNull(surveyResults)) {
      setData(surveyResults);
    }
  }, [
    surveyResults,
    isLoadingPerformanceProcess,
    employeeProfile,
  ]);

  useEffect(() => {
    if (!isNull(surveyResults) && !isEmpty(dataUser) && employeeProfile) {
      if (!isEmpty(surveyResults)) {
        dispatch(getSurveyResults(surveyResults[INDEX.zero].id, false));
      } else {
        setData([]);
      }
      setActiveResult(true);
    }
  }, [
    surveyResults,
    dataUser,
    dispatch,
    setData,
    employeeProfile,
  ]);

  useEffect(() => {
    const EVAL = getEvaluations();
    if (!isEmpty(EVAL) && !isEmpty(listPerformanceProcess)) {
      setValues({ evaluation: toNumber(processId) || EVAL[EVAL.length - INDEX.one]?.value });
    }
  }, [listPerformanceProcess, getEvaluations, processId, processParam]);

  useEffect(() => {
    if (!isEqual(values.survey_process?.id, values.evaluation)) {
      getDataByProfile();
    }
  }, [values.evaluation, values.survey_process, getDataByProfile]);

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Card data-testid={ "performance-tab" }>
      <CardContent className={ classes.content }>
        {isActiveDataResult(
          activeResult,
          performanceData,
          isLoadingPerformanceProcess,
          isLoadingSurveyResults,
        )
          ? (
            <>
              <Grid container className={ classes.header }>
                <Grid item md={ 9 } xs={ 12 }>
                  <Box className={ classes.classification }>
                    <Box pr={ 1 }>
                      <img src={ starImg } alt={ t("evaluation") } />
                    </Box>
                    <Box>
                      <Typography
                        variant={ VARIANT.h5 }
                        className={ classes.evaluationTitle }
                      >
                        {t("evaluation")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={ 3 } xs={ 12 }>
                  <Select
                    id={ OBJECT_KEYS.evaluation }
                    label={ t("dashboard.filter_evaluation") }
                    menuItems={ getEvaluations() }
                    value={ values.evaluation }
                    onChange={ handleChange }
                    disabled={ isEmpty(listPerformanceProcess) }
                  />
                </Grid>
              </Grid>
              {isEmpty(performanceData) ? (
                <>
                  <AccordionRate
                    title={ t("performanceEvaluationNote") }
                  />
                  <NoDataMessage />
                </>
              ) : (
                <>
                  <AccordionRate
                    title={ t("performanceEvaluationNote") }
                    text={ !isNull(performanceData.comment)
                      ? performanceData.comment
                      : "" }
                    label={ performanceData.result }
                  />
                  <Box pt={ 3 }>
                    {enableComponent(companySlug, COMPANIES_WITHOUT_PERCENTAGE_PROFILE_PERFORMANCE)
                    && performanceData?.results_by_evaluation_type
                    && (
                      <SimpleAlert
                        type={ INFO }
                        message={ getAlert(
                          performanceData.results_by_evaluation_type,
                          t,
                        ) }
                      />
                    )}
                    <Box pb={ 0.5 }>
                      <SimpleTable
                        header={ getTotalHeader(t) }
                        data={ performanceData.results_by_evaluation_type }
                        hideHeader
                        hideBorder
                      />
                    </Box>
                  </Box>
                  <Grid container>
                    <Grid item xs={ 12 }>
                      {!isEmpty(performanceData)
                      && performanceData.complete_results_by_section?.map(
                        (evaluation) => (
                          <Box key={ evaluation.name } pb={ 3 }>
                            <AccordionRate
                              title={ evaluation.name }
                              text={ evaluation.description }
                              label={ evaluation.result }
                              children={ getTotalEvaluationTable(
                                evaluation,
                                evaluation.evaluation_results,
                                user,
                                performanceData,
                                t,
                                classes,
                                  evaluation?.open,
                              ) }
                              isOpen={ evaluation?.open }
                            />
                          </Box>
                        ),
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
          )}
      </CardContent>
    </Card>
  );
};

Performance.propTypes = {
  user: PropTypes.object.isRequired,
  reducerToUse: PropTypes.object.isRequired,
  from: PropTypes.string,
  searchBy: PropTypes.string,
};

Performance.defaultProps = {
  from: null,
  searchBy: "",
};

export default Performance;
