import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import palette from "theme/palette";

export const StyledGridContainer = styled(Grid)`
  &.MuiGrid-container {
    width: 380px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 30px;
  }
  &.MuiButton-text {
    padding: 6px 30px;
  }
  @media (max-width: 600px) {
    &.MuiButton-root {
      margin-left: 10px;
    }
  }
`;

export const StyledStepsContainer = styled.div`
  .MuiStepLabel-root {
    align-items: center;
  }
  .MuiStepConnector-alternativeLabel {
    left: calc(-50% + 25px);
    right: calc(50% + 25px);
  }
  .MuiButton-root {
    margin-top: 30px;
  }
`;

export const StyledCardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${palette.background.lightPurple};
  border-radius: 4px;
  img {
    padding-top: 4px;
    padding-left: 16px;
  }
  h6 {
    font-weight: 700;
  }
`;

export const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-h6 {
    margin-left: 10px;
    font-size: 15px;
  }
`;

export const StyledBold = styled.span`
  font-weight: bold;
`;

export const StyledItemText = styled.span`
  padding: 10px 0;
  font-size: 15px;
  .MuiIconButton-root {
    padding: 5px;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  color: ${palette.table.blue};
  width: 20px;
  height: 20px;
`;

export const StyledTag = styled.span`
  height: 24px;
  background-color: ${palette.background.inputTags};
  padding: 5px 12px;
  margin-right: 5px;
  border-radius: 20px;
`;
