import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";

export const StyledContent = styled.div`
  position: relative;
`;

export const StyledLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  position: absolute;
  align-items: center;
  cursor: ${(prop) => (prop.isComplete ? "pointer" : "auto") };

  .MuiTypography-body1 {
    width: 100%;
    padding: 5px;
    color: white;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
  }
`;

export const StyledButtonProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    height: 38px;
    border-radius: 4px;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${(prop) => prop.disableMainColor };
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(prop) => (prop.isComplete ? prop.mainColor : prop.lightMainColor) };
  }
`;
