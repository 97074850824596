import styled from "styled-components";
import Stack from "@mui/material/Stack";
import palette from "theme/palette";

export const StyledStack = styled(Stack)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: ${palette.background.default};
  border-radius: 4px;
  button {
    svg {
      color: ${palette.table.placeholder};
    }
    &:hover {
      background: none;
      svg {
        color: ${palette.background.purple};
      }
    }
  }
`;
