import makeStyles from "@mui/styles/makeStyles";
import { bgColor } from "theme/palette";

const useStyles = makeStyles((theme) => ({
  alertNoData: {
    backgroundColor: bgColor.noDataHexa,
    marginTop: "15px",
    marginBottom: "25px",
    height: "108px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0em",
    textAlign: "left",
    "& .MuiAlert-message": {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  imageGrid: {
    textAlign: "center",
  },
  alertImage: {
    height: "80px",
    width: "80px",
  },
  alertMessageContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  alertTitle: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "42px",
    marginLeft: "15px",
    color: theme.palette.text.darkBlue,
  },
  alertMessage: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    marginLeft: "15px",
  },
}));

export default useStyles;
