import { useState } from "react";
import PropTypes from "prop-types";
import {
  SwitchSelectorWrapper,
  OptionItem,
  OptionItemLabel,
  OptionInput,
} from "./styles";

const Switch = (props) => {
  const {
    options,
    onChange,
    selected,
    customStyles,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(selected);

  const handleOptionClick = (optionSelected) => {
    setSelectedIndex(optionSelected);
    if (onChange) {
      onChange(optionSelected);
    }
  };

  return (
    <SwitchSelectorWrapper
      selectedIndex={ selectedIndex }
      optionsAmount={ options.length }
      { ...customStyles }
    >
      {options.map((option, index) => (
        <OptionItem
          key={ option.value }
          optionsAmount={ options.length }
        >
          <OptionItemLabel
            selected={ selectedIndex === index }
            onClick={ () => handleOptionClick(index) }
            { ...customStyles }
          >
            {option.label}
          </OptionItemLabel>
          <OptionInput type={ "radio" } name={ "options" } value={ option.value } />
        </OptionItem>
      ))}
    </SwitchSelectorWrapper>
  );
};

Switch.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number,
  customStyles: PropTypes.object,
};

Switch.defaultProps = {
  selected: 0,
  customStyles: null,
};

export default Switch;
