import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import MenuPopup from "components/MenuPopup";
import { SIZE } from "common/constants";
import { handleRowDelete, handleRowEdit, pushValidOptions } from "../index";

export const getContractActions = (t, thisModule, functions, classes) => (rowData) => {
  const DELETE_ACTION_MENU = {
    title: (
      <div className={ classes.delete }>{t("table.actions.delete")}</div>
    ),
    icon: (
      <HighlightOffIcon fontSize={ SIZE.small } className={ classes.delete } />
    ),
    onClick: () => {
      handleRowDelete(functions, t, thisModule, rowData);
    },
  };

  const ACTIONS_MENU = [
    {
      title: t("table.actions.edit"),
      icon: <EditIcon fontSize={ SIZE.small } />,
      onClick: () => {
        handleRowEdit(functions, thisModule, rowData);
      },
    },
  ];

  pushValidOptions(
    rowData,
    ACTIONS_MENU,
    DELETE_ACTION_MENU,
    thisModule,
    t,
    functions,
  );

  return <MenuPopup items={ ACTIONS_MENU } />;
};
