import styled from "styled-components";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import palette from "theme/palette";

export const StyledErrorIcon = styled(ErrorOutlineIcon)`
  font-size: 70px;
  color: ${palette.table.red};
  margin-right: 15px;
`;

export const StyledDeleteTitle = styled.div`
  color: ${palette.table.red};
`;

export const StyledGridContainer = styled(Grid)`
  &.MuiGrid-container {
    width: 380px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-body1 {
    margin: 10px 0 20px;
    font-weight: 500;
    font-size: 15px;
  }
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 30px;
  }
  &.MuiButton-text {
    padding: 6px 30px;
  }
  @media (max-width: 600px) {
    &.MuiButton-root {
      margin-left: 10px;
    }
  }
`;
