import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { SessionContext } from "modules/session/context";
import {
  ALIGN_ITEMS,
  LOCAL_STORAGE_NAMES,
  MEDIA_QUERY,
  VARIANT,
} from "common/constants";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { getItemFromLocalStorage } from "common/utils";
import Profile from "./components/Profile";
import SidebarNav from "./components/SidebarNav";
import Brand from "./components/Brand";
import CompanyBrand from "./components/CompanyBrand";
import SidebarMenuButtons from "./components/SidebarMenuButtons";
import useStyles from "./styles";

const SidebarRefactored = (props) => {
  const {
    open, variant, onClose, className,
  } = props;
  const classes = useStyles();
  const {
    state: { user },
  } = useContext(SessionContext);
  const { t } = useTranslation("common");

  const pages = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.userMenuOptions);
  return (
    <Drawer
      anchor={ window.innerWidth <= MEDIA_QUERY.tablet
        ? ALIGN_ITEMS.right
        : ALIGN_ITEMS.left }
      classes={ { paper: classes.drawer } }
      onClose={ onClose }
      open={ open }
      variant={ variant }
    >
      <div className={ clsx(classes.root, className) }>
        <Profile onClose={ onClose } />
        <Brand onClose={ onClose } />
        <Box
          display={ {
            xs: VARIANT.none,
            md: VARIANT.block,
            lg: VARIANT.none,
            xl: VARIANT.none,
          } }
        >
          <Divider className={ classes.divider } />
        </Box>
        <CompanyBrand user={ user } />
        <StyledScrollBar maxHeight={ "100%" }>
          <SidebarNav className={ classes.nav } pages={ pages } t={ t } />
        </StyledScrollBar>
        <SidebarMenuButtons />
      </div>
    </Drawer>
  );
};

SidebarRefactored.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

SidebarRefactored.defaultProps = {
  className: "",
  onClose: null,
};

export default SidebarRefactored;
