import styled from "styled-components";
import palette from "theme/palette";
import { ALIGN_ITEMS } from "common/constants";

export const StyledTitle = styled.div`
  font-size: 20px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyledFeedbackContainer = styled.div`
  .MuiTab-textColorPrimary {
    color: ${palette.text.secondary};
  }
  .Mui-selected{
    background: ${palette.backgroundTab};
    color: ${palette.black};
  }
  .MuiTab-root {
    min-width: auto;
  }
`;

export const StyledFeedbackListContainer = styled.div`
  border-top: ${palette.border.tabsContentTop};
  .MuiAccordion-rounded{
    border-radius: 0px;
    .MuiAccordionDetails-root{
      border-bottom: 1px solid ${palette.background.divider}
    }
  }
  .MuiPaper-elevation1{
    box-shadow: ${(props) => props.isCollapse && "none"};
  }
`;

export const StyledAccordionContainer = styled.div`
  margin-left: 16px;  
  font-size: 14px;
  p{
    font-weight: normal;
  }
`;

export const StyledFixedContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

export const StyledAcknowledgementElement = styled.div`
  display: flex;
  align-items: center;
  span{
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    border-bottom: none;
  }
  img{
    width: 50px;
  }
`;

export const StyledAcknowledgementContainer = styled.div`
  p{
    margin: 0;
    font-size: 14px;
    margin-top: 8px;
    padding-bottom: 8px;
  }
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  font-size: 14px;
  text-align: ${(props) => (props.isCenter ? ALIGN_ITEMS.center : ALIGN_ITEMS.right)};
  margin-top: ${(props) => (props.isCenter ? "8px" : "0")};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledMessage = styled.div`
  font-size: 12px;
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  p {
    font-size: 12px;
    margin-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
