import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarRating from "components/StarRating";
import { ALIGN_ITEMS, VARIANT } from "common/constants";
import { findDataInScale } from "common/utils";
import {
  StyledAccordionContainer,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "./styles";

const AccordionRate = (props) => {
  const {
    title,
    text,
    src,
    label,
    children,
    isOpen,
    scaleMaxIndex,
    scaleIndices,
  } = props;

  const scaleIndex = scaleIndices ? findDataInScale(scaleIndices, label) : null;
  const position = scaleIndex?.position || null;

  const totalStars = !isOpen && (
    <StarRating
      name={ title }
      value={ position }
      isReadOnly
      maxRating={ scaleMaxIndex }
      max={ scaleMaxIndex }
      precision={ 1 }
      hasNoFormattedValue
      label={ label }
    />
  );

  return (
    <StyledAccordionContainer>
      <StyledAccordionSummary
        expandIcon={ (
          <ExpandMoreIcon
            className={ children ? "icon" : "disabledIcon" }
          />
        ) }
        aria-controls={ "panel1c-content" }
        id={ "panel1c-header" }
      >
        <Grid
          container
          alignItems={ ALIGN_ITEMS.center }
        >
          <Grid item xs={ 12 } md={ 8 }>
            <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
              {src && (
                <Box pr={ 1 }>
                  <Avatar alt={ "Icon" } src={ src } />
                </Box>
              )}
              <Box flexGrow={ 1 }>
                <Typography variant={ VARIANT.h5 }>{title}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={ 12 } md={ 4 }>
            <Box pt={ 0.5 } pb={ 0.5 }>
              { totalStars }
            </Box>
          </Grid>
          {text && (
            <Grid item md={ 12 }>
              <Typography variant={ VARIANT.bodyOne } className={ "details" }>
                {text}
              </Typography>
            </Grid>
          )}
        </Grid>
      </StyledAccordionSummary>
      {children && (
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      )}
    </StyledAccordionContainer>
  );
};
AccordionRate.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  src: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  scaleMaxIndex: PropTypes.number,
  scaleIndices: PropTypes.array,
};

AccordionRate.defaultProps = {
  text: "",
  src: "",
  label: "",
  children: null,
  isOpen: false,
  scaleMaxIndex: 0,
  scaleIndices: [],
};

export default AccordionRate;
