import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledPaperContainer = styled(Paper)`
  padding: 32px;
  margin-top: 16px;
  margin-bottom: 25px;
`;

export const StyledTitleText = styled(Typography)`
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${palette.black};
`;

export const StyledSubtitleText = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${palette.subtitle};
`;

export const StyledGridHeatMap = styled(Grid)`
  direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledGridContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledGridCards = styled(Grid)`
  margin-top: 5px;
`;

export const StyledGridButton = styled(Grid)`
  margin-right: ${(props) => (props.isModal && "15px")};
  float: ${(props) => (props.isModal && "right")};
  margin-top: ${(props) => (props.isModal ? "20px" : "5px")};
  svg {
    display: ${(props) => (props.isModal && "none")};
  }
  .MuiButtonBase-root{
    background: ${(props) => (props.isModal && palette.primaryApp)};
    color: ${(props) => (props.isModal && palette.white)};
  }
  .MuiButton-outlinedSizeSmall{
    padding: ${(props) => (props.isModal && "5px 10px !important")};
  }
  .buttonIconContainer .left {
    margin-right: ${(props) => (props.isModal && "5px")};
  }
`;

export const StyledGridParticipation = styled(Grid)`
  width: 97%;
`;
