import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  titleText: {
    padding: "1rem 0 1rem 1rem",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
  },
  imgIframe: {
    height: 500,
    [theme.breakpoints.down("xs")]: {
      height: 280,
    },
  },
}));

export default useStyles;
