import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SimpleAlert from "components/SimpleAlert";
import Button from "components/Button";
import DownloadExcelButton from "components/DownloadExcelButton";
import SimpleTable from "components/SimpleTable";
import LinearProgress from "components/LinearProgress";
import AlertModal from "components/AlertModal";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import previewImg from "assets/images/forms/preview.svg";
import downloadImg from "assets/images/forms/descargar.svg";
import fileLoad from "assets/images/forms/file.svg";
import {
  BUTTON_STYLE_TYPES,
  SUCCESS,
  ERROR,
  BULK_UPLOAD_FORMAT,
  ACCEPTED_FORMATS,
  BULK_UPLOAD,
  VARIANT,
  GENDER_STATUS as GS,
  OBJECT_KEYS,
  DATE_FORMATS,
  INFO,
} from "common/constants";
import { isEmpty, isEqual, isNull } from "common/helpers";
import { STATUS } from "common/constants/gender";
import { formatDateToSubmit } from "common/utils";
import {
  uploadUsers,
  getUploadStatus,
  resetState as resetUpload,
} from "redux/actions/bulkUploadActions";
import { getList as getGender } from "redux/actions/common/genderActions";
import { getList as getDynamicAttribute } from "redux/actions/common/dynamicAttributeActions";
import { getList as getTournoverReasons } from "redux/actions/common/turnoverReasonActions";
import { getItemSelected } from "views/Account/functions/personalData";
import {
  downloadExcelCustom,
  getHeaderByDefault,
  validationData,
  setAlert,
  hasAlert,
  hasPreviewData,
  isFileEmptyOrIsLoadingOrHasError,
  isFileEmptyOrIsLoading,
  readData,
  getModalData,
  getTimeout,
  getDynamicAttributesFormat,
} from "./functions";
import ColumnSwitch from "./components/ColumnSwitch";
import {
  useStyles, SaveAltIconStyled, StyledTitle, StyledCardContent, StyledAlertContainer,
} from "./styles";

const BulkUpload = () => {
  const classes = useStyles();
  const { t } = useTranslation(["bulkUpload", "common"]);
  const dispatch = useDispatch();

  const {
    successProcess: uploadStatus,
    isLoadingProcess,
    errorList,
  } = useSelector(({ bulkUploadReducer }) => bulkUploadReducer);

  const { list: allGenderList } = useSelector(
    ({ genderReducer }) => genderReducer,
  );

  const {
    list: dynamicAttributesList,
    isLoadingList: isLoadingDynamicAttributes,
  } = useSelector(({ dynamicAttributeReducer }) => dynamicAttributeReducer);

  const {
    list: turnoverReasonsList,
    isLoadingList: isLoadingTurnoverReasons,
  } = useSelector(({ turnoverReasonReducer }) => turnoverReasonReducer);

  const [stateFile, setStateFile] = useState(null);
  const [preview, setPreview] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [allData, setAllData] = useState({});
  const [modalData, setModalData] = useState([]);
  const [modal, setModal] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [updateColumns, setUpdateColumns] = useState(false);
  const genderList = allGenderList?.map((item) => ({
    ...item,
    value: t(GS[item.id]),
    label: t(GS[item.id]),
  }));

  const turnoverReasonsOptions = turnoverReasonsList && [
    { name: `- ${t("turnoverTypes.optionsVoluntary")}:` },
    ...turnoverReasonsList?.filter((e) => e.voluntary),
    { name: `- ${t("turnoverTypes.optionsInvoluntary")}:` },
    ...turnoverReasonsList?.filter((e) => !e.voluntary),
  ];

  const openCloseModal = () => {
    setModal(!modal);
  };

  const resetState = () => {
    setPreview({});
    setIsLoading(false);
    setStateFile(null);
    setUpload(false);
    setAllData({});
  };

  const setLoading = () => {
    setIsLoading(false);
  };

  const handleUpload = (event) => {
    resetState();
    const file = event.target.files[BULK_UPLOAD.xlsx.firstData];
    if (file) {
      if (!BULK_UPLOAD_FORMAT.exec(file.name)) {
        setPreview({ alert: setAlert(ERROR, t("invalidFileFormat")) });
      } else {
        const header = getHeaderByDefault(t, classes, columnList);
        setStateFile({
          name: file.name,
          file,
          header,
        });
      }
      event.target.value = "";
    }
  };

  const parseData = useCallback(() => {
    setIsLoading(true);
    setUpload(true);
    const reader = new FileReader();
    const readAsBinaryString = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const { result } = e.target;
      const data = readData(
        result,
        readAsBinaryString,
        stateFile,
        t,
        columnList,
      );

      if (!isEmpty(data)) {
        const dataPreviewValidate = validationData(data, columnList, t);
        setPreview(dataPreviewValidate);
        if (!dataPreviewValidate.hasError) {
          setAllData(data);
          dataPreviewValidate.alert = setAlert(
            SUCCESS,
            t("alertInfo.bulkUploadSuccessfully"),
          );
        } else {
          dataPreviewValidate.alert = setAlert(
            ERROR,
            t("alertInfo.bulkUploadError"),
          );
        }
      }
    };
    if (readAsBinaryString) {
      reader.readAsBinaryString(stateFile.file);
    } else {
      reader.readAsArrayBuffer(stateFile.file);
    }
  }, [stateFile, columnList, t]);

  useEffect(() => {
    dispatch(getGender());
    dispatch(getDynamicAttribute());
    dispatch(getTournoverReasons());
  }, [dispatch]);

  useEffect(() => {
    if (dynamicAttributesList) {
      setColumnList(getDynamicAttributesFormat(dynamicAttributesList));
    }
  }, [dynamicAttributesList]);

  useEffect(() => {
    if (!isNull(stateFile) && updateColumns) {
      const headerColumns = stateFile?.header.map((obj) => {
        const val = columnList.find((e) => isEqual(e.key, obj.id));
        if (val) {
          return Object.assign(obj, { active: val.active });
        }
        return obj;
      });
      setStateFile({ ...stateFile, header: headerColumns });
      setUpdateColumns(false);
    }
  }, [columnList, stateFile, updateColumns]);

  useEffect(() => {
    if (!isNull(stateFile) && !upload) {
      setColumnList(getDynamicAttributesFormat(dynamicAttributesList));
      parseData();
    }
  }, [stateFile, upload, dynamicAttributesList, parseData]);

  const otherValue = getItemSelected(genderList, STATUS.other, OBJECT_KEYS.id)
    ?.value;

  const formatData = (data) => {
    const inactiveColumns = columnList.filter((e) => isEqual(e.active, false));
    data.forEach((item) => {
      inactiveColumns.forEach((column) => {
        delete item[column.key];
      });

      if (isEqual(item.gender, otherValue)) {
        item.gender = item.other_gender;
      }
      item.starting_date = formatDateToSubmit(item.starting_date, DATE_FORMATS.es.timeline);
      if (item?.birthdate) {
        item.birthdate = formatDateToSubmit(item.birthdate, DATE_FORMATS.es.timeline);
      }
      if (item?.termination_date) {
        item.termination_date = formatDateToSubmit(item.termination_date, DATE_FORMATS.es.timeline);
      }
    });
    return data;
  };

  const sendData = () => {
    const users = {
      users: formatData(JSON.parse(JSON.stringify(allData))),
    };
    dispatch(uploadUsers(users));
  };

  const getProcessStatus = useCallback(() => {
    if (!isLoadingProcess && uploadStatus?.aasm_state) {
      setModalData(
        getModalData(
          uploadStatus?.aasm_state,
          t,
          classes,
          uploadStatus?.response,
        ),
      );
      setModal(true);
      if (isEqual(uploadStatus?.aasm_state, BULK_UPLOAD?.state.pending)) {
        const timeout = getTimeout(allData.length);
        setTimeout(() => {
          dispatch(getUploadStatus(uploadStatus?.id));
        }, timeout);
      } else {
        dispatch(resetUpload());
        resetState();
      }
    }
  }, [dispatch, t, classes, allData, isLoadingProcess, uploadStatus]);

  useEffect(() => {
    if (!isEmpty(uploadStatus)) {
      getProcessStatus();
    }
  }, [uploadStatus, getProcessStatus]);

  const getErrors = useCallback(
    (error) => {
      const message = error.data.message
        ? error.data.message
        : t("alertInfo.error");
      preview.alert = setAlert(ERROR, message);
      dispatch(resetUpload());
    },
    [dispatch, preview, t],
  );

  useEffect(() => {
    if (errorList) {
      getErrors(errorList);
    }
  }, [errorList, getErrors]);

  const showPreview = hasPreviewData(preview, isLoading);

  return (
    <div className={ classes.root } data-testid={ "bulk-upload" }>
      <Grid container>
        <Grid item xs={ 12 }>
          <StyledTitle>{t("title")}</StyledTitle>
          <Typography variant={ "body1" }>{t("subtitle")}</Typography>
          <Card className={ classes.container }>
            <StyledCardContent>
              <Grid container className={ classes.upload }>
                <Grid item xs={ 12 } sm={ 6 }>
                  <Box display={ "flex" }>
                    <Box pr={ 1 }>
                      <img alt={ t("upload_document") } src={ uploadImg } />
                    </Box>
                    <Box flexGrow={ 1 }>
                      <Typography variant={ "h6" } className={ classes.cardTitle }>
                        {t("upload_document")}
                      </Typography>
                      <Typography variant={ "body2" }>
                        {t("filesFormat")}
                      </Typography>
                    </Box>
                  </Box>
                  {hasAlert(preview, isLoading) && (
                    <Box pt={ 1 }>
                      <SimpleAlert
                        type={ preview.alert.type }
                        message={ preview.alert.message }
                        customStyle={ classes.alert }
                      />
                    </Box>
                  )}
                  <Box
                    pt={ 1 }
                    pb={ 2 }
                    display={ "flex" }
                    alignItems={ "center" }
                    className={ classes.file }
                  >
                    <Button
                      variant={ "outlined" }
                      typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                      component={ "label" }
                      customStyle={ classes.button }
                      isDisabled={ isLoading }
                    >
                      {t("select_file")}
                      <input
                        accept={ ACCEPTED_FORMATS }
                        type={ "file" }
                        onChange={ handleUpload }
                      />
                    </Button>
                    {isLoading && (
                      <Box
                        display={ "flex" }
                        alignItems={ "center" }
                        className={ classes.fileName }
                        pl={ 1 }
                      >
                        <Box pr={ 0.5 }>
                          <img alt={ "download" } src={ downloadImg } />
                        </Box>
                        <Box>
                          <Typography
                            variant={ "body2" }
                            component={ "span" }
                            className={ classes.docName }
                          >
                            {stateFile.name}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {upload && (
                    <Box>
                      <Typography variant={ "h6" } className={ classes.cardTitle }>
                        {t("loadData")}
                      </Typography>
                      <Box pt={ 2 } pb={ 1 } display={ "flex" } alignItems={ "center" }>
                        <Box pr={ 1 }>
                          <img alt={ t("preview") } src={ fileLoad } />
                        </Box>
                        {isLoading ? (
                          <Box pr={ 1 } className={ classes.progress }>
                            <LinearProgress
                              isActive={ isLoading }
                              setLoading={ setLoading }
                            />
                          </Box>
                        ) : (
                          <Box pr={ 1 }>
                            <Typography
                              variant={ "body2" }
                              component={ "span" }
                              className={ classes.docName }
                            >
                              {stateFile.name}
                            </Typography>
                          </Box>
                        )}
                        {isLoading && (
                          <Box className={ classes.iconButton }>
                            <IconButton onClick={ resetState }>
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Grid>
                <StyledAlertContainer item xs={ 12 } sm={ 6 }>
                  <SimpleAlert
                    type={ INFO }
                    title={ t("alert.title") }
                    message={ t("alert.text") }
                  >
                    <Box pt={ 2 }>
                      <DownloadExcelButton
                        customStyle={ classes.downloadButton }
                        onClick={ () => downloadExcelCustom(
                          t("importTemplate"),
                          t,
                          genderList,
                          dynamicAttributesList,
                          turnoverReasonsOptions,
                        ) }
                        isDisabled={ isLoadingTurnoverReasons || isLoadingDynamicAttributes }
                      >
                        {t("buttons.download")}
                        {" "}
                        <SaveAltIconStyled />
                      </DownloadExcelButton>
                    </Box>
                  </SimpleAlert>
                </StyledAlertContainer>
              </Grid>
            </StyledCardContent>
          </Card>
          <Card className={ classes.container }>
            <StyledCardContent>
              <Grid container className={ classes.upload }>
                {showPreview && dynamicAttributesList && (
                  <ColumnSwitch
                    columnList={ columnList }
                    setColumnList={ setColumnList }
                    setUpdateColumns={ setUpdateColumns }
                  />
                )}
                <Grid item xs={ 12 }>
                  <Typography variant={ "h6" } className={ classes.cardTitle }>
                    {t("preview")}
                  </Typography>
                  {showPreview ? (
                    <SimpleTable
                      header={ stateFile.header.filter((e) => isEqual(e.active, true)) }
                      data={ preview.data }
                      size={ "small" }
                    />
                  ) : (
                    <Box>
                      <Box
                        pt={ 2 }
                        pb={ 1 }
                        display={ "flex" }
                        justifyContent={ "center" }
                      >
                        <img alt={ t("preview") } src={ previewImg } />
                      </Box>
                      <Typography variant={ "body2" } align={ "center" }>
                        {t("textPreview")}
                      </Typography>
                    </Box>
                  )}
                  <Grid container className={ classes.submitButtons }>
                    <Grid item xs={ 12 }>
                      <Box display={ "flex" } justifyContent={ "flex-end" } pt={ 2 }>
                        <Box>
                          <Button
                            variant={ VARIANT.contained }
                            typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                            isDisabled={ isFileEmptyOrIsLoading(
                              stateFile,
                              isLoading,
                            ) }
                            onClick={ resetState }
                          >
                            {t("buttons.cancel")}
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            variant={ VARIANT.contained }
                            type={ "submit" }
                            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                            isDisabled={ isFileEmptyOrIsLoadingOrHasError(
                              isLoading,
                              preview.hasError,
                              allData,
                            ) }
                            onClick={ sendData }
                          >
                            {t("buttons.upload")}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </StyledCardContent>
          </Card>
          {modalData && (
            <AlertModal
              disableBackdropClick
              disableEscapeKeyDown
              title={ modalData.title }
              open={ modal }
              handleClose={ openCloseModal }
              isDisabledIcon={ modalData.isDisabledIcon }
            >
              {modalData.children}
            </AlertModal>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default BulkUpload;
