import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import gt from "lodash/gt";
import get from "lodash/get";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { SIZE, SORT_COMPARATOR } from "common/constants";
import NoDataMessage from "components/NoDataMessage";
import TableHeadComponent from "./components/TableHead";
import TableToolbar from "./components/TableToolbar";
import { useStyles } from "./styles";

import DeleteIcon from "@mui/icons-material/Delete";

const SimpleTableCheckbox = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const {
    header,
    data,
    handleItemsSelected,
    handleUnselectedAll,
    isUnselectAll,
    handleClickRemove,
    hasToolbar,
    isMainTable,
  } = props;
  const [selected, setSelected] = useState([]);
  const [tbodyElements] = useState(header);

  useEffect(() => {
    if (isUnselectAll) {
      setSelected([]);
      handleItemsSelected([]);
    }
  }, [isUnselectAll, handleItemsSelected]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => n.id);
      setSelected(newSelecteds);
      handleItemsSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    handleUnselectedAll(false);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (isEqual(selectedIndex, SORT_COMPARATOR.minusOne)) {
      newSelected = newSelected.concat(selected, id);
    } else if (isEqual(selectedIndex, SORT_COMPARATOR.zero)) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (isEqual(selectedIndex, selected.length - 1)) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (gt(selectedIndex, SORT_COMPARATOR.zero)) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    handleItemsSelected(newSelected);
  };

  const isSelected = (id) => !isEqual(selected.indexOf(id), -1);
  
  return (
    <Paper className={classes.paper} data-testid={"simple-table-checkbox"}>
      {hasToolbar && (
        <TableToolbar
          tooltipTitle={t("common.delete_from_list")}
          tooltipIcon={DeleteIcon}
          numSelected={selected.length}
          itemsSelected={selected}
          onClick={handleClickRemove}
          isMainTable={isMainTable}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size={SIZE.small}>
          <TableHeadComponent
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={data?.length}
            headCells={header}
            classes={classes}
          />
          <TableBody>
            {data?.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.label}
                  selected={isItemSelected}
                  onClick={(event) => handleClick(event, row.id)}
                  className={classes.fadeIn}
                >
                  <TableCell
                    key={"checkbox-" + index}
                    className={classes.tableCellCheckbox}
                  >
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  {tbodyElements.map((item, index) => {
                    let valueToRender = get(row, item.id);
                    return item.customRender ? (
                      <TableCell
                        key={`row ${index}`}
                        component="td"
                        id={labelId}
                        scope="row"
                        className={clsx(
                          classes.tableCell,
                          item.isAvatar ? classes.tableCellAvatar : ""
                        )}
                      >
                        {item.customRender(row)}
                      </TableCell>
                    ) : (
                      <TableCell
                        key={`row ${index}`}
                        component="td"
                        id={labelId}
                        scope="row"
                        className={classes.tableCell}
                      >
                        {valueToRender}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {isEmpty(data) && (
              <TableRow className={classes.fadeIn}>
                <TableCell
                  className={classes.tableCellNoInfo}
                  colSpan={header?.length + 1}
                >
                  <NoDataMessage isHappyFace={isMainTable} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

SimpleTableCheckbox.propTypes = {
  header: PropTypes.array,
  data: PropTypes.array,
  handleItemsSelected: PropTypes.func,
  handleUnselectedAll: PropTypes.func,
  isUnselectAll: PropTypes.bool,
  handleClickRemove: PropTypes.func,
  hasToolbar: PropTypes.bool,
  isMainTable: PropTypes.bool,
};

export default SimpleTableCheckbox;
