import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormHelperText from "components/FormHelperText";
import { VARIANT, SIZE } from "common/constants";
import palette from "theme/palette";

const SelectController = (props) => {
  const {
    id,
    label,
    menuItems,
    defaultValue,
    disabled,
    name,
    control,
    required,
    size,
    sx,
  } = props;

  const useStyles = makeStyles({
    customOption: {
      backgroundColor: `${palette.text.purpleLight} !important`,
      fontWeight: 500,
    },
  });

  const classes = useStyles();

  return (
    <>
      <Controller
        data-testid={ "select-controller" }
        name={ name }
        as={ (
          <TextField
            id={ id }
            variant={ VARIANT.outlined }
            select
            label={ label }
            disabled={ disabled }
            size={ size }
            sx={ sx }
          >
            {menuItems?.map((menuItem, index) => (
              <MenuItem key={ index } value={ menuItem.value } className={ menuItem.value === "custom" ? classes.customOption : "" }>
                <Typography variant={ VARIANT.bodyOne }>{menuItem.label}</Typography>
              </MenuItem>
            ))}
          </TextField>
        ) }
        defaultValue={ defaultValue }
        control={ control }
      />
      {!disabled && required && <FormHelperText />}
    </>
  );
};

SelectController.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  menuItems: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  customStyles: PropTypes.string,
  control: PropTypes.object,
  required: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.object,
};

SelectController.defaultProps = {
  menuItems: [],
  disabled: false,
  required: false,
  size: SIZE.medium,
  sx: {},
};

export default SelectController;
