import styled from "styled-components";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import palette from "theme/palette";

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${palette.primaryApp};
  font-size: 20px;
  font-weight: 700;
`;

export const StyledDialogActions = styled(DialogActions)`
  margin-top: 10px;
  padding: 0px 24px 25px;
  .MuiButton-root {
    min-width: 120px;
  }
`;

export const StyledLabel = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
  span {
    font-weight: 400;
    font-size: 12px;
    color: ${palette.text.grayDark};
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  overflow-y: hidden;
  .MuiGrid-item {
    padding-top: ${(props) => props.isView && "0px"};
    margin-top: ${(props) => props.isView && "10px"};

    &.result {
      ${(props) => props.isView && `
        border-right: 4px solid ${palette.background.iconBtn};
        padding-right: 20px;
      `}
    }
  }
`;

export const StyledContainer = styled(Grid)`
  .MuiInputBase-root {
    background: ${palette.table.oddRow} !important;
    padding-bottom: 30px;
  }
  fieldset {
    border: 0 !important;
  }
`;

export const StyledForm = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  background: ${palette.background.lightCard};
  .MuiSelect-select {
    padding: 10px 15px;
  }
  .Mui-disabled {
    -webkit-text-fill-color: ${palette.background.gray} !important;
    .MuiSelect-icon {
      display: none;
    }
  }
`;

export const StyledGridArrow = styled(Grid)`
  text-align: center;
  align-self: end;
  margin-bottom: 10px;
  align-items: center;
  svg {
    width: 2em !important;
    height: 2em !important;
    color: ${palette.background.gray}
  }
`;

export const StyledGridContainer = styled(Grid)`
  align-items: center;
`;

export const StyledGridDetail = styled(Grid)`
  padding: 0 20px;
`;

export const StyledInputContainer = styled.div`
  position: relative;
  .Mui-disabled {
    -webkit-text-fill-color: ${palette.text.grayDark} !important;
  }
`;

export const StyledCalibrationInfo = styled.p`
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 12px;
  font-weight: 700;
  color: ${palette.text.iconDisabled};
`;

export const StyledSkill = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
  -webkit-text-fill-color: ${ (props) => props.color } !important;
  p {
    font-size: 18px;
    font-weight: 400;
  }
  img {
    width: 28px;
    margin-right: 2px;
  }
`;
