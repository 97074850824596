import _ from "lodash";
import { NINEBOX, CATEGORY_PROCESS } from "common/constants";
import { NINEBOX_COLORS, STATE_COLORS } from "theme/palette";

const defaultBoxRange = {
  xBox: {
    low: {
      min: 0,
      max: 54,
    },
    medium: {
      min: 55,
      max: 84,
    },
    high: {
      min: 85,
      max: 100,
    },
  },
  yBox: {
    low: {
      min: 0,
      max: 75,
    },
    medium: {
      min: 75.1,
      max: 87.5,
    },
    high: {
      min: 87.51,
      max: 100,
    },
  },
};

export const getDataBox = (t, boxRangeByScales = defaultBoxRange) => [
  [
    NINEBOX.zero,
    NINEBOX.zero,
    NINEBOX.maxBox,
    STATE_COLORS.SUCCESS,
    t("ninebox:title-box.a"),
    NINEBOX.box.a.x,
    NINEBOX.box.a.y,
    t("ninebox:title-box.text_a"),
    boxRangeByScales.xBox.low.min,
    boxRangeByScales.xBox.low.max,
    boxRangeByScales.yBox.high.min,
    boxRangeByScales.yBox.high.max,
    NINEBOX.maxBox,
    NINEBOX.total,
    NINEBOX.quadrantIndex.zero,
    boxRangeByScales,
  ],
  [
    NINEBOX.maxBox,
    NINEBOX.zero,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lightBlue,
    t("ninebox:title-box.b"),
    NINEBOX.box.b.x,
    NINEBOX.box.a.y,
    t("ninebox:title-box.text_b"),
    boxRangeByScales.xBox.medium.min,
    boxRangeByScales.xBox.medium.max,
    boxRangeByScales.yBox.high.min,
    boxRangeByScales.yBox.high.max,
    NINEBOX.xMaxBox,
    NINEBOX.total,
    NINEBOX.quadrantIndex.first,
    boxRangeByScales,
  ],
  [
    NINEBOX.xMaxBox,
    NINEBOX.zero,
    NINEBOX.maxBox,
    STATE_COLORS.INFO,
    t("ninebox:title-box.c"),
    NINEBOX.box.c.x,
    NINEBOX.box.a.y,
    t("ninebox:title-box.text_c"),
    boxRangeByScales.xBox.high.min,
    boxRangeByScales.xBox.high.max,
    boxRangeByScales.yBox.high.min,
    boxRangeByScales.yBox.high.max,
    NINEBOX.total,
    NINEBOX.total,
    NINEBOX.quadrantIndex.second,
    boxRangeByScales,
  ],
  [
    NINEBOX.zero,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.orange,
    t("ninebox:title-box.d"),
    NINEBOX.box.a.x,
    NINEBOX.box.d.y,
    t("ninebox:title-box.text_d"),
    boxRangeByScales.xBox.low.min,
    boxRangeByScales.xBox.low.max,
    boxRangeByScales.yBox.medium.min,
    boxRangeByScales.yBox.medium.max,
    NINEBOX.maxBox,
    NINEBOX.xMaxBox,
    NINEBOX.quadrantIndex.third,
    boxRangeByScales,
  ],
  [
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lightGreen,
    t("ninebox:title-box.e"),
    NINEBOX.box.b.x,
    NINEBOX.box.d.y,
    t("ninebox:title-box.text_e"),
    boxRangeByScales.xBox.medium.min,
    boxRangeByScales.xBox.medium.max,
    boxRangeByScales.yBox.medium.min,
    boxRangeByScales.yBox.medium.max,
    NINEBOX.xMaxBox,
    NINEBOX.xMaxBox,
    NINEBOX.quadrantIndex.fourth,
    boxRangeByScales,
  ],
  [
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lighterBlue,
    t("ninebox:title-box.f"),
    NINEBOX.box.c.x,
    NINEBOX.box.d.y,
    t("ninebox:title-box.text_f"),
    boxRangeByScales.xBox.high.min,
    boxRangeByScales.xBox.high.max,
    boxRangeByScales.yBox.medium.min,
    boxRangeByScales.yBox.medium.max,
    NINEBOX.total,
    NINEBOX.xMaxBox,
    NINEBOX.quadrantIndex.fifth,
    boxRangeByScales,
  ],
  [
    NINEBOX.zero,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    STATE_COLORS.ERROR,
    t("ninebox:title-box.g"),
    NINEBOX.box.a.x,
    NINEBOX.box.g.y,
    t("ninebox:title-box.text_g"),
    boxRangeByScales.xBox.low.min,
    boxRangeByScales.xBox.low.max,
    boxRangeByScales.yBox.low.min,
    boxRangeByScales.yBox.low.max,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX.quadrantIndex.sixth,
    boxRangeByScales,
  ],
  [
    NINEBOX.maxBox,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lightRed,
    t("ninebox:title-box.h"),
    NINEBOX.box.b.x,
    NINEBOX.box.g.y,
    t("ninebox:title-box.text_h"),
    boxRangeByScales.xBox.medium.min,
    boxRangeByScales.xBox.medium.max,
    boxRangeByScales.yBox.low.min,
    boxRangeByScales.yBox.low.max,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX.quadrantIndex.seventh,
    boxRangeByScales,
  ],
  [
    NINEBOX.xMaxBox,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lighterGreen,
    t("ninebox:title-box.i"),
    NINEBOX.box.c.x,
    NINEBOX.box.g.y,
    t("ninebox:title-box.text_i"),
    boxRangeByScales.xBox.high.min,
    boxRangeByScales.xBox.high.max,
    boxRangeByScales.yBox.low.min,
    boxRangeByScales.yBox.low.max,
    NINEBOX.total,
    NINEBOX.maxBox,
    NINEBOX.quadrantIndex.eighth,
    boxRangeByScales,
  ],
];

const createNineBoxResultToResultMap = (yScale, language) => {
  const map = {};
  yScale.forEach((item) => {
    map[item.nine_box_result] = item[`result_${language}`];
  });
  return map;
};

export const getDefaultFooterBox = (t) => [
  [NINEBOX.footerBox.xa, t("ninebox:subtitle-box.xa")],
  [NINEBOX.footerBox.xb, t("ninebox:subtitle-box.xb")],
  [NINEBOX.footerBox.xc, t("ninebox:subtitle-box.xc")],
];

export const getFooterBox = (yScale, language) => {
  const nineBoxResultToResultMap = createNineBoxResultToResultMap(yScale, language);

  return [
    [NINEBOX.footerBox.xa, nineBoxResultToResultMap[CATEGORY_PROCESS.low]],
    [NINEBOX.footerBox.xb, nineBoxResultToResultMap[CATEGORY_PROCESS.medium]],
    [NINEBOX.footerBox.xc, nineBoxResultToResultMap[CATEGORY_PROCESS.high]],
  ];
};
