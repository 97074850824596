import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { STATE_COLORS } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  formButtons: {
    textAlign: "right",
  },
  titleText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.black,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  subtitleText: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
    color: theme.palette.black,
  },
  statusContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
    fontSize: "14px",
  },
  success: {
    color: STATE_COLORS.SUCCESS,
  },
  error: {
    color: STATE_COLORS.ERROR,
  },
  btnRight: {
    textAlign: "right",
  },
  container: {
    background: theme.palette.white,
    padding: "25px",
    borderTop: `3px solid${theme.palette.text.iconDisabled}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  simpleContainer: {
    background: theme.palette.white,
    padding: "25px",
    borderRadius: "4px",
    marginTop: "25px",
  },
  submitButtons: {
    marginTop: "25px",
    marginBottom: "25px",
    marginRight: theme.spacing(2),
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },
  tablePaper: {
    marginTop: "25px",
  },
  deleteIcon: {
    color: STATE_COLORS.ERROR,
  },
  gridProfile: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  gridActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  userNameTitle: {
    fontWeight: 500,
    color: theme.palette.text.secondaryTitle,
  },
  userPositionTitle: {
    color: theme.palette.text.secondaryParagraph,
    fontSize: "13px",
  },
  arrowBack: {
    fontSize: 12,
    color: `${theme.palette.text.secondaryTitle} !important`,
  },
  customStyleNoData: {
    padding: 0,
  },
  potentialTitle: {
    marginBottom: "10px",
    fontSize: "18px !important",
  },
  currency: {
    background: theme.palette.background.lightPurple,
    padding: theme.spacing(2),
    "& h6": {
      fontWeight: 700,
    },
  },
  money: {
    paddingBottom: theme.spacing(2),
  },
  inputTagContainer: {
    marginBottom: theme.spacing(4),
  },
  inputRow: {
    width: "50%",
  },
  "@media (max-width: 600px)": {
    inputRow: {
      width: "100%",
    },
  },
  pulse: {
    animation: "pulse 2s infinite",
  },
  simpleAlertModal: {
    textAlign: "left",
  },
  actionButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
  },
}));

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 32px;
  row-gap: 20px;
`;

export const StyledPaper = styled(Paper)`
  padding: 32px;
  margin-bottom: 32px;
`;

export const StyledFile = styled.div`
  width: 300px;
  word-wrap: break-word;
`;

export const StyledFormContainer = styled(Grid)`
    .MuiTextField-root {
    width: 100%;
    margin: 0;
  }
`;

export const StyledLinkContainer = styled.div`
  display: contents;
  a {
    width: fit-content;
  }
`;

export const StyledTopInfoContainer = styled(Grid)`
  margin-top: 0px;
`;

export const StyledProfileContainer = styled(Grid)`
  padding-top: 0px;
`;

export const StyledProfileBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
`;

export const StyledGrid = styled(Grid)`
  padding-top: 0px !important;
`;
