/**
 * Sorts an array of objects by a given field.
 *
 * @param {string} orderBy The name of the field to sort by.
 * @param {boolean} isDescending Whether to sort in descending order.
 * @returns {Array<Object>} The sorted array of objects.
 */
const sortObjects = (data, orderBy, isDescending) => data?.sort((a, b) => {
  // If the orderBy field is nested, we need to split it and get the value of the nested field.
  const [index, subIndex] = orderBy?.split(".").length > 1 ? orderBy.split(".") : [orderBy, null];

  const valueA = (a[index] && subIndex) ? a[index][subIndex] : a[index] || "";
  const valueB = (b[index] && subIndex) ? b[index][subIndex] : b[index] || "";

  const isNumber = typeof valueA === "number" || typeof valueB === "number";

  if (isNumber) {
    return isDescending ? valueB - valueA : valueA - valueB;
  }

  const valueACleaned = valueA?.trim();
  const valueBCleaned = valueB?.trim();

  return isDescending
    ? valueBCleaned.localeCompare(valueACleaned)
    : valueACleaned.localeCompare(valueBCleaned);
});

/**
 * Get the value to render in a table cell.
 * @param {Object} headerItem - Header item to verify if exist a customRender property.
 * @param {Object} row - Row data.
 * @returns {any} - Value to render.
 */
const getValueToRender = (headerItem, row) => (headerItem?.customRender
  ? headerItem.customRender(row)
  : row[headerItem.id]);

export { sortObjects, getValueToRender };
