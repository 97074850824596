import isEmpty from "lodash/isEmpty";
import inRange from "lodash/inRange";
import Grid from "@mui/material/Grid";
import AlertNoData from "components/AlertNoData";
import { isRolAdmin } from "common/validators";
import {
  TYPES_RESULTS,
  TYPES_TALENTS,
  RISK_RESULT_VALUES,
  GRAPHIC_TOP_PERCENTAGE,
  INDEX,
} from "common/constants";
import { getPercent, getUserRoles } from "common/utils";
import selfAwarenessImage from "assets/images/potential/icon-conciencia.svg";
import resultsAgilityImage from "assets/images/potential/icon-recursividad.svg";
import peopleAgilityImage from "assets/images/potential/icon-relaciones.svg";
import changeAgilityImage from "assets/images/potential/icon-cambio.svg";
import mentalAgilityImage from "assets/images/potential/icon-manejo.svg";

export const getPersonalityData = (data) => ({
  decision: data[INDEX.zero].decision,
  interaction: data[INDEX.zero].interaction,
  compliance: data[INDEX.zero].compliance,
  serenity: data[INDEX.zero].serenity,
});

export const getTalentName = (talentName) => {
  switch (talentName) {
  case TYPES_TALENTS.PEOPLE_AGILITY.name:
    return {
      title: TYPES_TALENTS.PEOPLE_AGILITY.id,
      image: peopleAgilityImage,
    };
  case TYPES_TALENTS.MENTAL_AGILITY.name:
    return {
      title: TYPES_TALENTS.MENTAL_AGILITY.id,
      image: mentalAgilityImage,
    };
  case TYPES_TALENTS.CHANGE_AGILITY.name:
    return {
      title: TYPES_TALENTS.CHANGE_AGILITY.id,
      image: changeAgilityImage,
    };
  case TYPES_TALENTS.RESULTS_AGILITY.name:
    return {
      title: TYPES_TALENTS.RESULTS_AGILITY.id,
      image: resultsAgilityImage,
    };
  case TYPES_TALENTS.SELF_AWARENESS.name:
    return {
      title: TYPES_TALENTS.SELF_AWARENESS.id,
      image: selfAwarenessImage,
    };
  default:
    break;
  }
};

export const showAlertNoData = (
  user,
  recommendations,
  surveyResultsReducer,
  badges,
) => user
  && getUserRoles(user?.userCookies)
  && !isRolAdmin(user?.userCookies)
  && isEmpty(surveyResultsReducer.surveyDetails)
  && isEmpty(recommendations)
  && isEmpty(badges);

export const getLastResult = (data) => data.filter((item) => !isEmpty(item.evaluations));

export const noDataMessage = (t) => (
  <Grid item xs={ 12 }>
    <AlertNoData
      title={ t("tabPotential.alertNoDataTitle") }
      message={ t("tabPotential.alertNoDataMessage") }
    />
  </Grid>
);

export const getRecommendationsAndBadges = (userSurveyResult, t) => {
  const auxRecommendations = [];
  const auxBadges = [];
  if (userSurveyResult && userSurveyResult.complete_results_by_section) {
    userSurveyResult.complete_results_by_section.forEach((talent) => {
      const talentParsed = getTalentName(talent.name);
      if (talent.result === TYPES_RESULTS.LARGE) {
        auxBadges.push({
          title: t(`talent_dictionary.${talentParsed.title}.title`),
          image: talentParsed.image,
          strengths: t(
            `talent_dictionary.${talentParsed.title}.broad_strengths`,
          ),
          risks: t(`talent_dictionary.${talentParsed.title}.broad_risks`),
        });
      }
      if (talent.result === TYPES_RESULTS.DEEP) {
        auxRecommendations.push({
          title: t(`talent_dictionary.${talentParsed.title}.title`),
          text: t(
            `talent_dictionary.${talentParsed.title}.deep_recommendations`,
          ),
        });
      }
    });
  }

  return [auxRecommendations, auxBadges];
};

const hasEmployee = (user) => user && user.person && user.person.employee;
export const getCompanyId = (user) => hasEmployee(user) && user.person.employee.company_id;

export const getEmployeeId = (user) => hasEmployee(user) && user.person.employee.id;

export const isLoadingOrEmpty = (isLoading, data) => isLoading || isEmpty(data);

export const hasDataNineBox = (values) => !isEmpty(values.nineBoxResult)
&& values.nineBoxResult
&& values.nineBoxResult[INDEX.zero]?.results[INDEX.zero];

export const hasProcessNotActive = (
  initialDataNineBox,
  nineBoxProcess,
  isActive,
) => (
  isEmpty(initialDataNineBox) && !isEmpty(nineBoxProcess) && !isActive
);

// risk
const getInRangeValidation = (percent, rangeInit, rangeEnd) => inRange(percent, rangeInit - 1, rangeEnd + 1);

export const getRange = (data) => {
  let rangeLimit;
  const percent = !isEmpty(data)
    ? getPercent(data[INDEX.zero].attrition, false)
    : RISK_RESULT_VALUES.zero;

  if (
    getInRangeValidation(
      percent,
      RISK_RESULT_VALUES.zero,
      RISK_RESULT_VALUES.none,
    )
  ) {
    rangeLimit = RISK_RESULT_VALUES.none;
  } else if (
    getInRangeValidation(
      percent,
      RISK_RESULT_VALUES.none,
      RISK_RESULT_VALUES.low,
    )
  ) {
    rangeLimit = RISK_RESULT_VALUES.low;
  } else if (
    getInRangeValidation(
      percent,
      RISK_RESULT_VALUES.low,
      RISK_RESULT_VALUES.medium,
    )
  ) {
    rangeLimit = RISK_RESULT_VALUES.medium;
  } else if (
    getInRangeValidation(
      percent,
      RISK_RESULT_VALUES.medium,
      RISK_RESULT_VALUES.high,
    )
  ) {
    rangeLimit = RISK_RESULT_VALUES.high;
  } else if (
    getInRangeValidation(
      percent,
      RISK_RESULT_VALUES.high,
      RISK_RESULT_VALUES.critic,
    )
  ) {
    rangeLimit = RISK_RESULT_VALUES.critic;
  }
  return rangeLimit;
};

export const validateTooltipRange = (data, index) => {
  let isValidated = false;
  if (!isEmpty(data)) {
    if (index === RISK_RESULT_VALUES.index.zero) {
      isValidated = getRange(data) === RISK_RESULT_VALUES.none;
    } else if (index === RISK_RESULT_VALUES.index.one) {
      isValidated = getRange(data) === RISK_RESULT_VALUES.low;
    } else if (index === RISK_RESULT_VALUES.index.two) {
      isValidated = getRange(data) === RISK_RESULT_VALUES.medium;
    } else if (index === RISK_RESULT_VALUES.index.three) {
      isValidated = getRange(data) === RISK_RESULT_VALUES.high;
    } else if (index === RISK_RESULT_VALUES.index.four) {
      isValidated = getRange(data) === RISK_RESULT_VALUES.critic;
    }
  }
  return isValidated;
};

export const getRiskTextColor = (t, data) => {
  const range = getRange(data);
  let text;
  let colorClass;
  if (range === RISK_RESULT_VALUES.none) {
    text = t("tabPotential.noneRisk");
    colorClass = "dotGreen";
  } else if (range === RISK_RESULT_VALUES.low) {
    text = t("tabPotential.lowRisk");
    colorClass = "dotYellow";
  } else if (range === RISK_RESULT_VALUES.medium) {
    text = t("tabPotential.mediumRisk");
    colorClass = "dotOrange";
  } else if (range === RISK_RESULT_VALUES.high) {
    text = t("tabPotential.highRisk");
    colorClass = "dotRedLight";
  } else {
    text = t("tabPotential.criticRisk");
    colorClass = "dotRed";
  }
  return { text, colorClass };
};

export const calculateTooltipPadding = (data) => {
  const percent = getPercent(data[INDEX.zero].attrition, false);
  const scale = getRange(data);
  const prevScale = scale - RISK_RESULT_VALUES.none;
  const substraction = scale === RISK_RESULT_VALUES.none
    ? RISK_RESULT_VALUES.zero
    : prevScale;
  const valueRest = percent - substraction;
  const value = (valueRest * GRAPHIC_TOP_PERCENTAGE) / RISK_RESULT_VALUES.none;
  return `${value}%`;
};
// end risk
