import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  classification: {
    display: "flex",
    alignItems: "center",
  },
}));
