import Typography from "@mui/material/Typography";
import ProfileImageDetail from "components/ProfileImageDetail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import StarRating from "components/StarRating";
import MenuPopup from "components/MenuPopup";
import { VARIANT } from "common/constants";
import { findDataInScale } from "common/utils";
import { ReactComponent as Location } from "assets/images/icons/location.svg";
import { viewPerformanceProfile } from "common/functions";
import {
  StyledContent, StyledTitleWithSubtitle,
  StyledText, StyledCalibrationRow,
} from "styledComponents/Tables";

export const getHeader = (evaluations, t, hasCalibration = false) => {
  const header = [{
    id: "name",
    label: t("tables:headers.name"),
    isParent: true,
  },
  {
    id: "manager",
    label: t("tables:headers.manager"),
    key: "name",
  },
  {
    id: "location",
    label: t("tables:headers.location"),
    key: "name",
  },
  {
    id: "position",
    label: t("tables:headers.position"),
    key: "name",
  }];

  evaluations.forEach((evaluation) => {
    header.push({
      id: evaluation,
      label: t(`tables:headers.${evaluation}`),
    });
  });

  header.push({
    id: hasCalibration ? "calibrated" : "total_evaluation",
    label: hasCalibration ? `${t("tables:headers.performance")} ${t("common:calibration.general")}` : t("tables:headers.performance"),
    hasSorting: true,
    sortingColumn: "score",
  });

  if (hasCalibration) {
    header.push({
      id: "total_evaluation",
      label: `${t("tables:headers.performance")} ${t("common:calibration.calibrated")}`,
      hasSorting: true,
      sortingColumn: "score",
    });
  }

  header.push({
    id: "actions",
    label: t("tables:headers.actions"),
  });

  return header;
};

export const ORDER = {
  total_evaluation: "score",
};

const getActions = (
  t,
  history,
  employeeId,
  processId,
  hasCalibration,
  handleOpenCalibrationModal,
  result,
  hasCalibrationActivated,
) => {
  const baseMenu = [{
    title: t("common:common.viewMore"),
    icon: <VisibilityIcon fontSize={ "small" } />,
    onClick: () => viewPerformanceProfile(
      history,
      employeeId,
      processId,
    ),
  },
  ];
  const isValidToView = (
    !hasCalibrationActivated && result?.calibration?.id) || hasCalibrationActivated;
  if (hasCalibration && isValidToView) {
    baseMenu.push({
      title: t(`common:calibration.actions.${hasCalibrationActivated ? "calibrate" : "view"}`),
      icon: <EqualizerIcon fontSize={ "small" } />,
      onClick: () => handleOpenCalibrationModal(result),
    });
  }
  return baseMenu;
};

export const getRowsDesktop = (data, t, history, extraParam) => {
  const scaleIndices = extraParam.resultScale?.result_scale_indices;
  const scaleMaxIndex = scaleIndices?.length || null;
  const rows = data?.map((rowData) => {
    const resultIndex = scaleMaxIndex && rowData.result
      ? findDataInScale(scaleIndices, rowData.result)
      : null;

    const baseRows = [
      {
        content: (
          <ProfileImageDetail collaborator={ rowData.employee } isShortVersion />
        ),
      },
      {
        content: <StyledText>{rowData.job_position?.manager_name}</StyledText>,
      },
      {
        content: (
          <StyledTitleWithSubtitle>
            <Typography variant={ VARIANT.h6 }>
              {rowData.job_position?.country?.name}
            </Typography>
            <Typography variant={ VARIANT.bodyOne }>
              {rowData.job_position?.city?.name && (
                <StyledContent mt={ 5 }>
                  <Location />
                  {rowData.job_position.city.name}
                </StyledContent>
              )}
            </Typography>
          </StyledTitleWithSubtitle>
        ),
      },
      {
        content: (
          <StyledText>{rowData.job_position?.position_name}</StyledText>
        ),
      },
      ...extraParam.evaluations.map((evaluation) => ({
        content: evaluation.customData(rowData, scaleIndices),
      })),
    ];

    if (extraParam.hasCalibration) {
      const scalePreviousIndex = findDataInScale(
        scaleIndices,
        rowData.calibration?.previous_score,
        false,
      );

      const scaleNewIndex = findDataInScale(
        scaleIndices,
        rowData.calibration?.calibrated_score,
        false,
      );

      baseRows.push(
        {
          content:
            rowData?.calibration?.id ? (
              <StarRating
                name={ `star-rating-${rowData.id}` }
                value={ scalePreviousIndex?.position }
                isReadOnly
                maxRating={ scaleMaxIndex }
                max={ scaleMaxIndex }
                precision={ 1 }
                hasNoFormattedValue
                label={ scalePreviousIndex?.result }
                hasTooltip
              />
            )
              : (
                <StarRating
                  name={ `star-rating-${rowData.id}` }
                  value={ resultIndex?.position }
                  isReadOnly
                  maxRating={ scaleMaxIndex }
                  max={ scaleMaxIndex }
                  precision={ 1 }
                  hasNoFormattedValue
                  label={ rowData.result }
                  hasTooltip
                />
              ),
        },
        {
          content:
          rowData?.calibration?.id ? (
            <StarRating
              name={ `star-rating-${rowData.id}` }
              value={ scaleNewIndex?.position }
              isReadOnly
              maxRating={ scaleMaxIndex }
              max={ scaleMaxIndex }
              precision={ 1 }
              hasNoFormattedValue
              label={ scaleNewIndex?.result }
              detail={ t("calibration.last_update_simple", { name: rowData.calibration.last_updated_by }) }
              hasTooltip
            />
          ) : (
            <StyledCalibrationRow>
              <EqualizerIcon />
              <h4>{t("common:calibration.uncalibrated")}</h4>
            </StyledCalibrationRow>
          ),
        },
      );
    } else {
      baseRows.push({
        content: (<StarRating
          name={ `star-rating-${rowData.id}` }
          value={ resultIndex?.position }
          isReadOnly
          maxRating={ scaleMaxIndex }
          max={ scaleMaxIndex }
          precision={ 1 }
          hasNoFormattedValue
          label={ rowData.result }
          hasTooltip
        />),
      });
    }

    baseRows.push({
      content: <MenuPopup items={ getActions(
        t,
        history,
        rowData.employee.id,
        extraParam.processId,
        extraParam.hasCalibration,
        extraParam.handleOpenCalibrationModal,
        rowData,
        extraParam.hasCalibrationActivated,
      ) }
      />,
    });

    return baseRows;
  });

  return rows;
};
