import styled from "styled-components";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import theme from "theme/palette";

export const StyledRoundTableContainer = styled(TableContainer)`
  border-radius: 8px;
`;

export const StyledTableRow = styled(TableRow)`
  background-color: ${(props) => (props.even ? theme.table.evenRow : theme.table.oddRow)};
`;

export const StyledTableCell = styled(TableCell)`
  font-size: 14px;
`;

export const StyledRoundPaper = styled(Paper)`
  border-radius: 8px;
`;
