import styled from "styled-components";
import theme from "theme/palette";
import DialogContent from "@mui/material/DialogContent";

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: ${ theme.primaryApp };
  color: ${ theme.white };
  h4 {
    font-size: 18px;
    margin-left: 10px;
  }
  .MuiButton-root {
    padding: 0;
    min-width: 0;
    color: ${ theme.white };
    font-size: 24px;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDialogContent = styled(DialogContent)`
  margin-top: 20px;
  p {
    color: ${ theme.black };
  }
`;
