import styled from "styled-components";
import Paper from "@mui/material/Paper";

export const StyledContainer = styled(Paper)`
  padding: 32px;
  margin-top: 20px;
  @media (max-width: 450px) {
    padding: 0px;
    &.MuiPaper-elevation1 {
      box-shadow: none;
    }
    &.MuiPaper-root {
      background-color: transparent;
    }
  }
`;

export const StyledContent = styled.div`
  margin-bottom: 30px;
`;
