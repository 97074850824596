import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

export const StyledScale = styled.div`
  width: 65px;
  padding: 4px;
  background: ${ (props) => props.color };
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
`;

export const StyledTitle = styled.div`
  margin-left: 8px;
  font-size: 14px;
`;

export const StyledBox = styled(Box)`
  text-align: right;
`;

export const StyledGridScale = styled(Grid)`
  column-gap: 8px;
  justify-content: flex-end;
`;
