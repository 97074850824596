import styled from "styled-components";
import DateInputController from "components/DateInputController";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  display: flex;
  .Mui-error {
    color: ${palette.black};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${palette.background.mediumGrey} !important;
  }
`;

export const StyledDateInputController = styled(DateInputController)`
  .enhancedInputController {
    width: 35%;
  }
`;
