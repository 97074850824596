import { INDEX } from "common/constants";

export const getQueryWithSurveyResults = (query) => {
  let lastQuery = query;
  if (lastQuery) {
    lastQuery.q.score_not_null = INDEX.one;
  } else {
    lastQuery = { q: { score_not_null: INDEX.one } };
  }
  return lastQuery;
};
