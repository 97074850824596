import styled from "styled-components";
import theme, { TOP_BAR } from "theme/palette";

export const StyledContainer = styled.div`
  width: 100%;
  border-top: ${`3px solid ${TOP_BAR.lightBlue}`};
  background-color: ${theme.white};
  padding: 25px;
  h4 {
    font-weight: bold;
    margin: 10px 0 20px;
  }
`;
