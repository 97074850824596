import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import theme, { TOP_BAR } from "theme/palette";
import { StyledTabsContainer } from "styledComponents/Tabs";

export const StyledPaperContainer = styled.div`
  padding: 24px;
`;

export const StyledAlignRight = styled(Grid)`
  text-align: right;
`;

export const StyledContainer = styled.div`
  width: 100%;
  border-top: ${`3px solid ${TOP_BAR.lightBlue}`};
  background-color: ${theme.white};
  padding: 25px;
`;

export const StyledImageContainer = styled.div`
  position: relative;
  &.isMobile {
    display: inline-flex;
    text-align: center;
  }
`;

export const StyledStatus = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isActive ? theme.table.green : theme.table.red)};
  svg {
    font-size: 14px;
    color: ${theme.white};
  }
`;

export const StyledTabContainer = styled(StyledTabsContainer)`
  margin-top: 36px;
`;

export const StyledBoxContainer = styled(Box)`
  display: ${(props) => props.isMobile ? "inline-block": "flex"};
  align-items: center;
  &.boxContainerMobile{
    text-align: center;
    width: 100%;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 15px 0;
`;

export const StyledTurnover = styled.div`
  &.subtitle {
    color: ${theme.table.red} !important;
    svg {
      color: ${theme.table.red};
    }
  }
`;

export const StyledBox = styled(Box)`
  .MuiButton-root {
    height: 38px;
  }
`;

export const StyledPositionName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    margin-left: 2px;
  }
`;
