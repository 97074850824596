/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Breadcrumbs from "components/Breadcrumbs";
import AlertDialog from "components/AlertDialog";
import Box from "@mui/material/Box";
import InputText from "components/InputTextController";
import Button from "components/Button";
import SimpleAlert from "components/SimpleAlert";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  ALIGN_ITEMS,
  INFO,
  SKELETONS_NUMBER,
} from "common/constants";
import SkeletonLoader from "components/SkeletonLoader";
import LanguageSelector from "./components/LanguageSelector";
import Stakeholders from "./components/Stakeholders";
import SectionList from "./components/SectionList";
import { dataBreadcrumbs, getStakeholderTotalLabel, filterItemsWithEmptyNames } from "./functions";
import {
  StyledContainer,
  StyledCardContainer,
  StyledTypography,
  StyledImageContainer,
  StyledFormContainer,
  StyledContent,
  StyledHeader,
  StyledTemplateName,
  StyledTotal,
  StyledBanner,
} from "./styles";

const SurveyTemplateView = (props) => {
  const {
    t, processData, templateData, templateStates,
    control, watch, onSubmit, handleCancelClick, isFetchingTemplate,
    isLoadingEvaluationScales, isLoadingTemplate, isLoadingSectionsTemplate, formValues, i18n,
    isMobile, history,
  } = props;
  const { formLanguage, isValid, stakeholders } = templateStates;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const result = await onSubmit();
    const savedValues = JSON.parse(localStorage.getItem("formValues"));

    if (result.success) {
      const { response } = result;
      const { id } = response;
      setIsLoading(false);
      setConfirmationModal(false);
      localStorage.setItem("formValues", JSON.stringify({
        ...savedValues,
        template: id,
      }));
      localStorage.setItem("templateSelected", JSON.stringify(response));
      history.push("/planning", { from: "/planning/templates" });
    } else {
      const { error } = result;
      toast(MESSAGE_TYPES.error, { title: error.message });
      setIsLoading(false);
      setConfirmationModal(false);
    }
  };

  const handleSubmitData = () => {
    const { sections } = formValues;
    const sectionsToSaveOrUpdate = sections?.filter((section) => !section.isDeleted);
    const emptyData = filterItemsWithEmptyNames(sectionsToSaveOrUpdate);
    if (emptyData.length > 0) {
      setConfirmationModal(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <Breadcrumbs data={ dataBreadcrumbs(t) } />
        <ViewHeaderTitle
          title={ t("planning:customize_process") }
        />
        <Typography>
          {t("planning:subtitle")}
          <strong>{t("planning:subtitle2") }</strong>
        </Typography>
      </StyledHeader>
      {isMobile && (
        <SimpleAlert
          type={ INFO }
          message={ t("planning:alert.mobile") }
        />
      )}
      {!isMobile
      && templateData
      && !isLoadingEvaluationScales
      && !isLoadingTemplate
      && !isLoadingSectionsTemplate
      && !isFetchingTemplate
        ? (
          <>
            <LanguageSelector { ...props } value={ formLanguage } />
            <StyledCardContainer>
              <Box display={ "flex" } alignItems={ "center" }>
                <Box flexGrow={ 1 }>
                  <StyledTypography>
                    {processData.label}
                  </StyledTypography>
                  <StyledTemplateName>
                    <InputText
                      name={ `name_${formLanguage}` }
                      defaultValue={ formValues[`name_${formLanguage}`] }
                      control={ control }
                      variant={ VARIANT.outlined }
                    />
                    <EditIcon />
                  </StyledTemplateName>
                </Box>
                <StyledImageContainer>
                  <img src={ processData.icon } alt={ processData.label } />
                </StyledImageContainer>
              </Box>
            </StyledCardContainer>
            <StyledFormContainer>
              <form
                id={ "template-form" }
                onKeyDown={ (e) => (e.key === "Enter") && e.preventDefault() }
              >
                <StyledContent>
                  <Stakeholders
                    { ...props }
                    control={ control }
                    watch={ watch }
                    evaluations={ templateData?.evaluation_templates }
                    formLanguage={ formLanguage }
                  />
                </StyledContent>
                <SectionList
                  { ...props }
                  control={ control }
                  watch={ watch }
                  evaluations={ templateData?.evaluation_templates }
                  formLanguage={ formLanguage }
                />
                <Box mt={ 3 } mb={ 2 }>
                  {Object.keys(stakeholders).map((key) => stakeholders[key].checked && (
                    <StyledTotal
                      key={ `total_${stakeholders[key].key}` }
                      isInvalid={ stakeholders[key].total !== 100 }
                    >
                      {`${getStakeholderTotalLabel(stakeholders[key].type, i18n)[formLanguage]}`}
                      <span>{`${stakeholders[key].total}%`}</span>
                    </StyledTotal>
                  ))}
                </Box>
                <StyledBanner>
                  <InfoOutlinedIcon />
                  <Typography>
                    {t("planning:banner")}
                  </Typography>
                </StyledBanner>
                <Box
                  display={ ALIGN_ITEMS.flex }
                  justifyContent={ ALIGN_ITEMS.flexEnd }
                >
                  <Box mt={ 2 } ml={ 2 }>
                    <Button
                      onClick={ handleCancelClick }
                      variant={ VARIANT.contained }
                      color={ COLORS_NAME.primary }
                      type={ BUTTON_STYLE_TYPES.CANCEL }
                      typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                    >
                      {t("common:common.cancel")}
                    </Button>
                  </Box>
                  <Box mt={ 2 } ml={ 2 }>
                    <Button
                      variant={ VARIANT.contained }
                      color={ COLORS_NAME.primary }
                      typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                      isDisabled={ !isValid }
                      onClick={ handleSubmitData }
                    >
                      {t("common:common.save")}
                    </Button>
                  </Box>
                </Box>
                <AlertDialog
                  isOpen={ confirmationModal }
                  onClose={ () => setConfirmationModal(false) }
                  title={ t("planning:templates.emptyTranslation.title") }
                  message={ t("planning:templates.emptyTranslation.message") }
                  onSubmit={ handleSubmit }
                  buttons={ {
                    isLoading,
                  } }
                />
              </form>
            </StyledFormContainer>
          </>
        ) : (
          <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.SIX } columnWidth={ 12 } />
        ) }
    </StyledContainer>
  );
};

SurveyTemplateView.propTypes = {
  t: PropTypes.func.isRequired,
  processData: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  templateData: PropTypes.shape({
    name_es: PropTypes.string,
    name_en: PropTypes.string,
    name_pt: PropTypes.string,
    evaluation_templates: PropTypes.array,
    nala_default: PropTypes.bool,
  }),
  templateStates: PropTypes.shape({
    formLanguage: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    stakeholders: PropTypes.object.isRequired,
  }).isRequired,
  i18n: PropTypes.any.isRequired,
  control: PropTypes.object,
  watch: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  isFetchingTemplate: PropTypes.bool.isRequired,
  isLoadingEvaluationScales: PropTypes.bool.isRequired,
  isLoadingTemplate: PropTypes.bool.isRequired,
  isLoadingSectionsTemplate: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    name_es: PropTypes.string,
    name_en: PropTypes.string,
    name_pt: PropTypes.string,
    sections: PropTypes.array,
  }),
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

SurveyTemplateView.defaultProps = {
  templateData: null,
  control: null,
  watch: null,
  formValues: null,
};

export default SurveyTemplateView;
