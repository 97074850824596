import makeStyles from "@mui/styles/makeStyles";
import palette from "theme/palette";

// TODO: refactor - styled-components
const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDialog-paper": {
      padding: "15px",
    },
    "& .MuiTypography-h6": {
      fontSize: "18px",
      fontWeight: "700",
      color: palette.primaryApp,
    },
    "& p": {
      fontWeight: "450",
    },
    "& .MuiAlert-root": {
      marginTop: "20px",
      marginBottom: "0px",
    },
  },
  buttonActionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "25px",
  },
  buttonActions: {
    marginLeft: "15px",
  },
}));

export default useStyles;
