import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: theme.palette.backgroundTab,
    },
  },
  tabStyle: {
    fontSize: "12px",
    textTransform: "capitalize",
    width: "40px !important",
  },
  labelType: {
    margin: "15px 0 15px 0",
  },
  btnSubmit: {
    textAlign: "right",
    marginTop: "20px",
  },
}));
