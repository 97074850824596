import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components/Filter";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { OBJECT_KEYS } from "common/constants";
import { StyledDashboardContainer } from "styledComponents/View";
import AnalysisSummary from "./components/AnalysisSummary";

const NalaAnalyticsView = (props) => {
  const { t, handleExternalQuery } = props;

  return (
    <StyledDashboardContainer>
      <ViewHeaderTitle title={ t("common:sidebar:nala-analytics") } />
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } >
          <Filter
            isLabel
            type={ FILTER_ACTIONS_TYPES.nalaAnalytics }
            isLoading={ false }
            externalParams={ [
              OBJECT_KEYS.company,
              OBJECT_KEYS.topTalent,
              OBJECT_KEYS.newHires,
            ] }
            handleExternalQuery={ handleExternalQuery }
          />
        </Grid>
        <Grid item xs={ 12 } >
          <AnalysisSummary { ...props } />
        </Grid>
      </Grid>
    </StyledDashboardContainer>
  );
};

NalaAnalyticsView.propTypes = {
  t: PropTypes.func.isRequired,
  handleExternalQuery: PropTypes.func.isRequired,
};

export default NalaAnalyticsView;
