import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import isString from "lodash/isString";
import toLower from "lodash/toLower";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DateInputController from "components/DateInputController";
import SelectController from "components/SelectController";
import Select from "components/Select";
import UploadImage from "components/UploadImage";
import Button from "components/Button";
import AlertModal from "components/AlertModal";
import InputTextController from "components/InputTextController";
import {
  toast, handleMessages, MESSAGE_TYPES, HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  ROLES,
  STATE,
  BUTTON_STYLE_TYPES,
  PROFILE_INDEX_TABS,
  TIMEOUT_REDIRECT,
  LOCAL_STORAGE_NAMES,
  VARIANT,
  OBJECT_KEYS,
  TYPES,
  GENDER_STATUS as GS,
  SIZE,
} from "common/constants";
import { isEqual, isNull, isEmpty } from "common/helpers";
import { STATUS } from "common/constants/gender";
import {
  historyPush,
  getItemFromLocalStorage,
  isCandidate,
  formatDateToSubmit,
} from "common/utils";
import { removeDataEmpty, isNullOrUndefined } from "common/validators";
import AdditionalData from "views/Account/components/AccountInductionTab/AdditionalData";
import { isInvalidAnswersRequired } from "views/Preboarding/functions";
import { getList as getGender } from "redux/actions/common/genderActions";
import {
  resetStateOne as resetStateCandidate,
  resetStateProcess as resetStateProcessCandidate,
} from "redux/actions/candidateActions";
import {
  validateRoleCondition,
  resetProfileData,
} from "../../functions";
import {
  resetDefaultValues,
  getMaritalList,
  getItemSelected,
} from "../../functions/personalData";
import { dispatchUpdate } from "../../functions/profile";
import { useStyles, StyledFormContainer } from "../../styles";

const PersonalData = (props) => {
  const {
    isEdit,
    user,
    from,
    searchBy,
    employee,
    reducerToUse,
    selectedId,
    sendNextStep,
    isPreboarding,
  } = props;

  const { t } = useTranslation("common");
  const [modal, setModal] = useState(false);
  const [answers, setAnswers] = useState(null);
  const [selectedGender, setSelectedGender] = useState();
  const [otherGender, setOtherGender] = useState();
  const classes = useStyles();
  const history = useHistory();
  const {
    list: allGenderList,
  } = useSelector(({ genderReducer }) => genderReducer);
  const dispatch = useDispatch();
  const {
    successProcess: successCandidateProcess,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const genderList = allGenderList?.map((item) => (
    { ...item, value: t(GS[item.id]), label: t(GS[item.id]) }
  ));

  const genderSelected = getItemSelected(genderList, selectedGender);

  const otherValue = getItemSelected(genderList, STATUS.other, OBJECT_KEYS.id)?.label;

  useEffect(() => {
    dispatch(getGender());
  }, [dispatch]);

  const {
    handleSubmit, control, reset,
  } = useForm({
    defaultValues: resetDefaultValues(employee),
  });

  const [values, setValues] = useState({
    profile_img_url: "",
  });

  const MARITAL_STATUS = getMaritalList(t);

  // reset form values
  const resetForm = useCallback(
    (user) => {
      if (!isNull(user?.person) && !isEmpty(user)) {
        setValues({
          profile_img_url: user?.profile_img_url,
        });

        const answersToSave = {};
        if (user?.requested_infos) {
          user.requested_infos.filter((item) => !isNull(item.answer)).forEach((answer) => {
            answersToSave[answer.id] = answer.answer;
          });
          setAnswers(answersToSave);
        }

        const userToUse = {
          birth_date: null,
          personal_id: null,
          personal_email: null,
          nationality: null,
          marital_status: null,
          gender: null,
          address: null,
          phone_number: null,
          linkedin_profile: null,
        };

        userToUse.birth_date = isNullOrUndefined(user?.person?.birth_date)
          ? ""
          : user?.person?.birth_date;
        userToUse.personal_id = isNullOrUndefined(user?.person?.personal_id)
          ? ""
          : user?.person?.personal_id;
        userToUse.personal_email = isNullOrUndefined(user?.person?.personal_email)
          ? ""
          : user?.person?.personal_email;
        userToUse.nationality = isNullOrUndefined(user?.person?.nationality)
          ? ""
          : user?.person?.nationality;
        userToUse.marital_status = isNullOrUndefined(user?.person?.marital_status)
          ? ""
          : user?.person?.marital_status;
        userToUse.gender = isNullOrUndefined(user?.gender)
          ? ""
          : user?.gender;
        userToUse.address = isNullOrUndefined(user?.person?.address)
          ? ""
          : user?.person?.address;
        userToUse.phone_number = isNullOrUndefined(user?.person?.phone_number)
          ? ""
          : user?.person?.phone_number;
        userToUse.linkedin_profile = isNullOrUndefined(
          user?.person?.linkedin_profile,
        )
          ? ""
          : user?.person?.linkedin_profile;
        reset({
          [`${employee}.person_attributes.birth_date`]: userToUse.birth_date,
          [`${employee}.person_attributes.personal_id`]: userToUse.personal_id,
          [`${employee}.person_attributes.personal_email`]: userToUse.personal_email,
          [`${employee}.person_attributes.nationality`]: userToUse.nationality,
          [`${employee}.person_attributes.marital_status`]: userToUse.marital_status,
          [`${employee}.person_attributes.gender`]: userToUse.gender,
          [`${employee}.person_attributes.address`]: userToUse.address,
          [`${employee}.person_attributes.phone_number`]: userToUse.phone_number,
          [`${employee}.person_attributes.linkedin_profile`]: userToUse.linkedin_profile,
        });
      }
    },
    [reset, employee],
  );

  useEffect(() => {
    if (user) {
      validateRoleCondition(
        from,
        user,
        [ROLES.ADMIN, ROLES.ADMIN_NALA],
        [ROLES.COLLABORATOR],
      )
        ? reset(resetDefaultValues(employee))
        : resetForm(reducerToUse?.one);
    }
  }, [
    reducerToUse,
    user,
    from,
    reset,
    resetForm,
    employee,
    dispatch,
    t,
  ]);

  useEffect(() => {
    if (!selectedGender
      && !isNullOrUndefined(reducerToUse?.one?.gender)
      && !isEmpty(genderList)) {
      const existGender = getItemSelected(genderList, reducerToUse?.one?.gender);
      setSelectedGender(existGender ? existGender?.label : otherValue);
      setOtherGender(!existGender ? reducerToUse?.one?.gender : "");
    }
  }, [genderList, otherValue, reducerToUse, selectedGender, otherGender]);

  // end reset form values

  const handleChange = (prop, event) => {
    setValues({
      ...values,
      [prop]: isEqual(prop, "profile_img_url") ? event : event.target.value,
    });
  };

  const openCloseModal = () => {
    if (!isPreboarding && isInvalidAnswersRequired(reducerToUse?.one?.requested_infos, answers)) {
      toast(MESSAGE_TYPES.warning, {
        title: t("preboarding:alert.noComplete.title"),
        message: t("preboarding:alert.noComplete.message"),
      });
    } else {
      setModal(!modal);
    }
  };

  const onSubmit = async () => {
    let imgProfile = null;
    if (
      values?.profile_img_url
      && !isEmpty(values?.profile_img_url)
      && !isString(values?.profile_img_url)) {
      const formData = new FormData();
      formData.append(`${employee}[profile_img]`, values?.profile_img_url?.file);
      if (reducerToUse?.one?.person?.employee
      ) {
        imgProfile = formData;
      }
    }

    const data = control.getValues();
    data[employee].person_attributes.birth_date = formatDateToSubmit(
      data[employee].person_attributes.birth_date,
    );
    data[employee].state_transition = STATE.FILL_DATA;
    data[employee].person_attributes.gender = isEqual(genderSelected?.id, STATUS.other)
      ? otherGender : selectedGender;

    if (isEqual(toLower(reducerToUse?.one?.type), ROLES.CANDIDATE) && !isPreboarding) {
      const answersList = reducerToUse?.one?.requested_infos.map((info) => ({ id: info.id, answer: answers[info.id] }));
      data[employee].requested_infos_attributes = answersList;
    }

    removeDataEmpty(data[employee]);

    dispatchUpdate(dispatch, employee, data, reducerToUse, imgProfile);
  };

  const resetData = useCallback(() => {
    resetProfileData(
      dispatch,
      from,
      searchBy,
      user,
      selectedId,
    );
    toast(
      MESSAGE_TYPES.success,
      handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
    );
    setModal(false);
    if (isEmpty(from) && isEqual(toLower(reducerToUse?.one?.type), ROLES.CANDIDATE)) {
      setTimeout(() => {
        historyPush(history, "/onboarding_profile", "", {
          tabId: PROFILE_INDEX_TABS.induction,
        });
      }, TIMEOUT_REDIRECT);
    }
  }, [t, dispatch, from, searchBy, selectedId, user, history, reducerToUse]);

  useEffect(() => {
    if (reducerToUse?.successProcess && !isPreboarding) {
      resetData();
    }
  }, [reducerToUse, isPreboarding, resetData]);

  useEffect(() => {
    if (successCandidateProcess && isPreboarding) {
      dispatch(resetStateCandidate());
      dispatch(resetStateProcessCandidate());
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      setModal(false);
      sendNextStep(true);
    }
  }, [dispatch, sendNextStep, t, successCandidateProcess, isPreboarding]);

  useEffect(() => {
    if (!reducerToUse?.isLoading && reducerToUse?.one) {
      if (reducerToUse?.one?.id === user?.employee?.id && values?.profile_img_url) {
        const updatedProfileImage = reducerToUse?.one?.profile_img_url;
        const updatedLocalStorage = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user);
        updatedLocalStorage.profile_img_url = updatedProfileImage;
        localStorage.setItem(LOCAL_STORAGE_NAMES.user, JSON.stringify(updatedLocalStorage));
      }
    }
  }, [reducerToUse, employee, user, values.profile_img_url]);

  const handleChangeGender = (prop, event) => {
    setSelectedGender(event.target.value);
    if (!isEqual(event.target.value, otherValue)) {
      setOtherGender("");
    }
  };

  const handleChangeInputOtherGender = (event) => {
    setOtherGender(event.target.value);
  };

  const handleChangeAnswers = (prop, event) => {
    setAnswers({
      ...answers,
      [prop]: event.target.value,
    });
  };

  return (
    <div data-testid={ "account-personaldata-tab" }>
      <form id={ "personal-data-form" } autoComplete={ "off" } onSubmit={ handleSubmit(openCloseModal) }>
        <StyledFormContainer container spacing={ 3 }>
          <UploadImage
            id={ "profile_img_url" }
            value={ values?.profile_img_url }
            readOnly={ !isEdit }
            onChange={ handleChange }
            label={ `${t("common:common.upload_image")} 200x200 px` }
          />
          <Grid item xs={ 12 } sm={ 6 }>
            <DateInputController
              id={ "birth_date" }
              label={ t("common.birthday") }
              control={ control }
              name={ `${employee}.person_attributes.birth_date` }
              openTo={ "year" }
              value={ null }
              views={ ["year", "month", "day"] }
              disabled={ !isEdit }
              maxDate={ new Date() }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <InputTextController
              type={ TYPES.text }
              label={ t("common.id") }
              required
              disabled={ !isEdit }
              control={ control }
              name={ `${employee}.person_attributes.personal_id` }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <InputTextController
              type={ TYPES.email }
              label={ t("common.personal_mail") }
              required={ isEqual(employee, ROLES.CANDIDATE) }
              disabled={ !isEdit }
              control={ control }
              name={ `${employee}.person_attributes.personal_email` }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <InputTextController
              type={ TYPES.text }
              label={ t("common.nationality") }
              disabled={ !isEdit }
              control={ control }
              name={ `${employee}.person_attributes.nationality` }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <Select
              id={ OBJECT_KEYS.gender }
              label={ t("common.gender") }
              menuItems={ genderList }
              value={ selectedGender }
              onChange={ handleChangeGender }
              disabled={ !isEdit }
              size={ SIZE.medium }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <TextField
              type={ TYPES.text }
              label={ t("common.gender_status.other") }
              variant={ VARIANT.outlined }
              margin={ VARIANT.dense }
              onChange={ handleChangeInputOtherGender }
              value={ otherGender }
              disabled={ !isEqual(genderSelected?.id, STATUS.other) }
              fullWidth
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <SelectController
              id={ OBJECT_KEYS.maritalStatus }
              label={ t("common.marital_status.main") }
              control={ control }
              menuItems={ MARITAL_STATUS }
              disabled={ !isEdit }
              name={ `${employee}.person_attributes.marital_status` }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <InputTextController
              type={ TYPES.text }
              label={ t("common.address") }
              disabled={ !isEdit }
              control={ control }
              name={ `${employee}.person_attributes.address` }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <InputTextController
              type={ TYPES.text }
              label={ t("common.phone_number") }
              disabled={ !isEdit }
              control={ control }
              name={ `${employee}.person_attributes.phone_number` }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <InputTextController
              type={ TYPES.text }
              label={ t("common.linkedin_profile") }
              disabled={ !isEdit }
              control={ control }
              name={ `${employee}.person_attributes.linkedin_profile` }
            />
          </Grid>
          { !isCandidate(user?.userCookies) && (
            <Grid item xs={ 12 }>
              <AdditionalData
                data={ reducerToUse?.one?.requested_infos }
                handleChange={ handleChangeAnswers }
                answers={ answers }
                isDisabled={ !isEqual(toLower(reducerToUse?.one?.type), ROLES.CANDIDATE) }
              />
            </Grid>
          )}
          {isEdit && !isPreboarding
            && (
              <Grid item xs={ 12 } className={ classes.formButtons }>
                <Button
                  variant={ VARIANT.contained }
                  type={ "submit" }
                  isLoading={ reducerToUse?.isLoadingProcess }
                  typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                >
                  {t("common.save")}
                </Button>
              </Grid>
            )}
        </StyledFormContainer>
      </form>
      <AlertModal
        title={ t("common.modal_messages.sure_question") }
        text={ t("common.modal_messages.sure_text") }
        textDisagree={ t("common.modal_messages.no_cancel") }
        textAgree={ t("common.modal_messages.yes_confirm") }
        onClick={ onSubmit }
        open={ modal }
        handleClose={ openCloseModal }
        isLoading={ reducerToUse?.isLoadingProcess }
      />
    </div>
  );
};

PersonalData.propTypes = {
  isEdit: PropTypes.bool,
  user: PropTypes.object,
  from: PropTypes.string,
  searchBy: PropTypes.string,
  employee: PropTypes.string,
  reducerToUse: PropTypes.object,
  selectedId: PropTypes.string,
  isPreboarding: PropTypes.bool,
  sendNextStep: PropTypes.func,
};

export default PersonalData;
