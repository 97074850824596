export const STATES = {
  pending: "pending",
  finished: "finished",
  archived: "archived",
};

export const STATE_TRANSITION = {
  pending: "pending",
  finish: "finish",
  archive: "archive",
};
