import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

const TableHeadComponent = (props) => {
  const { onSelectAllClick, numSelected, rowCount, headCells, classes } = props;

  return (
    <TableHead data-testid={"simple-table-checkbox-thead"}>
      <TableRow>
        <TableCell className={classes?.tableCellHeader}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && isEqual(numSelected, rowCount)}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            className={classes.tableCellHeader}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeadComponent.propTypes = {
  onSelectAllClick: PropTypes.func,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  headCells: PropTypes.array,
  classes: PropTypes.object,
};

export default TableHeadComponent;
