import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
import charts from "theme/charts";
import { replacePath } from "common/utils";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { ReactComponent as SuccessionIcon } from "assets/images/icons/succession.svg";
import Modals from "../Modals";
import localRoutes from "../../../../functions/routes";
import { StyledButton, StyledButtonContainer, StyledButtonOuterContainer } from "./styles";

const ProfileEdit = (props) => {
  const {
    history,
    canEditPersonalData,
    handleOpenModal,
    collaborator,
    quadrant,
    isSuccessionsAdmin,
    t,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const collaboratorResults = {
    nineboxQuadrant: quadrant,
  };

  if (collaborator && quadrant) {
    collaborator.results = collaboratorResults;
  }

  return (
    <>
      { canEditPersonalData && (
        <StyledButtonOuterContainer>
          {isSuccessionsAdmin && !isMobile
            ? (
              <StyledButtonContainer>
                <StyledButton onClick={ () => replacePath(history, localRoutes.personalInfo) }>
                  <EditIcon />
                </StyledButton>
                <StyledButton
                  onClick={ () => {
                    handleOpenModal(
                      history.location.pathname,
                      collaborator.id,
                      collaborator,
                    );
                  } }
                  isDisabledIcon={ !collaborator?.is_a_successor && !collaborator?.has_successors }
                >
                  <Tooltip title={ t("successions:mark_as_successor") } placement={ "top" }>
                    <SuccessionIcon />
                  </Tooltip>
                </StyledButton>
              </StyledButtonContainer>
            )
            : (
              <StyledButton onClick={ () => replacePath(history, localRoutes.personalInfo) }>
                <EditIcon />
              </StyledButton>
            )}
        </StyledButtonOuterContainer>
      )}
      <Modals { ...props } />
    </>
  );
};

ProfileEdit.propTypes = {
  history: PropTypes.func.isRequired,
  canEditPersonalData: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  currentTalentScore: PropTypes.object.isRequired,
  resultScales: PropTypes.any.isRequired,
  isLoadingCurrentTalentScore: PropTypes.bool.isRequired,
  quadrant: PropTypes.number.isRequired,
  riskCategory: PropTypes.string.isRequired,
  isSuccessionsAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default ProfileEdit;
