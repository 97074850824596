import qs from "qs";
import { FEEDBACK_TYPE, PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import { orderByAsc } from "common/helpers";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE_RECEIVED,
  GET_LIST_RECEIVED,
  GET_LIST_LOADING_RECEIVED,
  GET_LIST_ERROR_RECEIVED,

  RESET_STATE_SENT,
  GET_LIST_SENT,
  GET_LIST_LOADING_SENT,
  GET_LIST_ERROR_SENT,

  RESET_STATE_PROCESS_FEEDBACK,
  PROCESS_FEEDBACK,
  PROCESS_FEEDBACK_LOADING,
  PROCESS_FEEDBACK_ERROR,

  RESET_STATE_FEEDBACKS_TO_DOWNLOAD,
  GET_LIST_FEEDBACKS_TO_DOWNLOAD,
  GET_LIST_FEEDBACKS_TO_DOWNLOAD_LOADING,
  GET_LIST_FEEDBACKS_TO_DOWNLOAD_ERROR,
} from "../actionTypes/feedback";

const URL = {
  collaborators: "/collaborators",
  main: "feedbacks",
  type: {
    simple: "feedback_simples",
    ssc: "feedback_start_stop_continues",
  },
};

export const resetStateReceived = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_RECEIVED,
  });
};

export const resetStateSent = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_SENT,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PROCESS_FEEDBACK,
  });
};

export const resetStateToDownload = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_FEEDBACKS_TO_DOWNLOAD,
  });
};

// feedbacks by employee and type
// URL example: /api/v1/collaborators/id/feedbacks/?company_id=&sent=true (sent param is optional)

export const getList = (collaboratorId, isSent = false) => async (dispatch) => {
  dispatch({
    type: isSent ? GET_LIST_LOADING_SENT : GET_LIST_LOADING_RECEIVED,
  });
  let query = {};
  if (isSent) {
    query = {
      sent: true,
    };
  }
  try {
    const response = await configAxios.get(
      `${URL.collaborators}/${collaboratorId}/${URL.main}`,
      { params: query },
    );
    dispatch({
      type: isSent ? GET_LIST_SENT : GET_LIST_RECEIVED,
      payload: response.data?.feedbacks,
    });
  } catch (error) {
    errorHandler(error, dispatch, isSent ? GET_LIST_ERROR_SENT : GET_LIST_ERROR_RECEIVED);
  }
};

// URL example: /api/v1/feedback_simples?company_id=

export const sendFeedback = (data, type) => async (dispatch) => {
  const urlByType = type === FEEDBACK_TYPE.feedbackSimpleSN ? URL.type.simple : URL.type.ssc;

  dispatch({
    type: PROCESS_FEEDBACK_LOADING,
  });

  try {
    const response = await configAxios.post(urlByType, data);
    dispatch({
      type: PROCESS_FEEDBACK,
      payload: response.data[type],
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_FEEDBACK_ERROR);
  }
};

// TODO: remove - use getSurveyResultsToDownloadAsync
export const getFeedbackToDownload = () => async (dispatch) => {
  dispatch({
    type: GET_LIST_FEEDBACKS_TO_DOWNLOAD_LOADING,
  });

  try {
    const response = await configAxios.get(URL.main);
    dispatch({
      type: GET_LIST_FEEDBACKS_TO_DOWNLOAD,
      payload: orderByAsc(response.data.feedbacks, "employee_from_id"),
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_FEEDBACKS_TO_DOWNLOAD_ERROR);
  }
};

// TODO: It does not accept the collaborator dashboard filters; customization is needed.
export const getFeedbackToDownloadAsync = async (query, page = 1, size = 200) => {
  const newQuery = { ...query };
  if (newQuery.q) {
    newQuery.q.s = "employee_from_id asc";
  }
  const pagination = { number: page, size };
  const response = await configAxios.get(URL.main, {
    params: { ...query, page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return {
    data: response.data.feedbacks,
    total: response.headers["x-total"],
    page,
  };
};
