import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import { getPercent } from "common/utils";
import { isEmpty, isNull, isNullOrUndefined } from "common/helpers";
import { SKELETONS_NUMBER, VARIANT } from "common/constants";
import { RISK_CATEGORY } from "views_refactor/Profile/functions/lossRisk";
import {
  StyledAttritionProbability,
  StyledContainer,
  StyledPercent,
  StyledRisk,
} from "./styles";

const AttritionProbabilityCard = (props) => {
  const {
    t,
    data,
    isLoading,
    hasBorder,
  } = props;
  const noData = !hasBorder && <NoDataMessage />;

  const renderRisk = isEmpty(data?.responseList)
    || isNullOrUndefined(data)
    || (isNullOrUndefined(data?.attrition_probability) && isNullOrUndefined(data?.risk_category))
    ? noData : (
      <StyledAttritionProbability hasBorder={ hasBorder }>
        <StyledContainer>
          <StyledPercent>
            { getPercent(data.attrition_probability, true) }
          </StyledPercent>
          <StyledRisk color={ RISK_CATEGORY[data.risk_category]?.color }>
            <Typography variant={ VARIANT.h4 }>{t(`talentDrain:${data.risk_category}Risk`)}</Typography>
            <LinearProgress
              variant={ VARIANT.determinate }
              value={ getPercent(data.attrition_probability) }
            />
          </StyledRisk>
        </StyledContainer>
      </StyledAttritionProbability>
    );

  return isNull(data) || isLoading ? (
    <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } />
  ) : renderRisk;
};

AttritionProbabilityCard.propTypes = {
  t: PropTypes.func.isRequired,
  attritionStates: PropTypes.object.isRequired,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  hasBorder: PropTypes.bool,
};

AttritionProbabilityCard.defaultProps = {
  hasBorder: false,
  data: {},
  isLoading: false,
};

export default AttritionProbabilityCard;
