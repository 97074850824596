import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormHelperText from "components/FormHelperText";
import { ALIGN_ITEMS, VARIANT } from "common/constants";
import { getCurrentLanguage, getValidDateFormat } from "common/utils";

function DateInputController(props) {
  const {
    disabled,
    id,
    label,
    value,
    name,
    maxDate,
    minDate,
    control,
    openTo,
    views,
    required,
    customStyles,
    size,
  } = props;

  const localLanguage = getCurrentLanguage();
  const language = getCurrentLanguage();
  moment.locale(language);

  return (
    <>
      <LocalizationProvider
        adapterLocale={ language }
        dateAdapter={ AdapterMoment }
      >
        <Controller
          data-testid={ "date-input-controller" }
          render={ ({ value: dateValue, ref, onChange }) => {
            const normalizedValue = dateValue ? moment.utc(dateValue).startOf("day") : dateValue;
            const normalizedMinDate = minDate ? moment.utc(minDate).startOf("day") : null;
            const normalizedMaxDate = maxDate ? moment.utc(maxDate).endOf("day") : null;
            return (
              <DatePicker
                inputRef={ ref }
                data-testid={ "dateInput" }
                variant={ ALIGN_ITEMS.inline }
                inputVariant={ VARIANT.outlined }
                className={ customStyles }
                KeyboardButtonProps={ { "aria-label": "change date" } }
                format={ getValidDateFormat(localLanguage)?.dash }
                emptyLabel={ getValidDateFormat(localLanguage)?.dash }
                formatDensity={ VARIANT.dense }
                maxDate={ normalizedMaxDate }
                minDate={ normalizedMinDate }
                id={ id }
                disabled={ disabled }
                label={ label }
                openTo={ openTo }
                views={ views }
                value={ normalizedValue }
                fullWidth
                renderInput={ (params) => <TextField { ...params } size={ size } /> }
                onChange={ (date) => onChange(date) }
              />
            );
          } }
          control={ control }
          name={ name }
          defaultValue={ value }
        />
      </LocalizationProvider>
      {!disabled && required && <FormHelperText />}
    </>
  );
}

DateInputController.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.object,
  name: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  openTo: PropTypes.oneOf(["date", "day", "year", "month"]), // TODO: remove 'date' option when calls to this components has updated
  views: PropTypes.array,
  control: PropTypes.object,
  required: PropTypes.bool,
  customStyles: PropTypes.string,
  size: PropTypes.string,
};

DateInputController.defaultProps = {
  disabled: false,
  id: null,
  label: "",
  value: moment(),
  name: "date",
  maxDate: null,
  minDate: null,
  openTo: "day",
  control: null,
  required: false,
  customStyles: null,
  size: "medium",
};

export default DateInputController;
