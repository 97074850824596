import styled from "styled-components";
import palette from "theme/palette";

export const StyledInfo = styled.h4`
  padding-top: 10px;
  color: ${palette.text.purpleMaxDark};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  font-size: 18px;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    width: 16px;
  }
`;
