import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { StyledText } from "../../styles";

const CardResults = ({ participation, color }) => {
  const { t } = useTranslation("common");

  return (
    <Box mt={ 1 } mb={ 1 }>
      <Box display={ "flex" } alignItems={ "baseline" }>
        <StyledText fontSize={ 24 } color={ color } isBold>
          {participation.total || 0}
        </StyledText>
        <StyledText fontSize={ 14 }>
          {`/${participation.max || 0} ${t("participation.cardSubtitleResult")}`}
        </StyledText>
      </Box>
    </Box>
  );
};

CardResults.propTypes = {
  participation: PropTypes.shape({
    total: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  color: PropTypes.string.isRequired,
};

export default CardResults;
