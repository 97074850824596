import styled from "styled-components";
import palette from "theme/palette";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";

export const StyledContainer = styled.div`
  position: relative;
  min-height: ${(props) => (props.open ? "100%" : "255px")};
  margin: 0 10px 10px;
  padding-right: 40px;
  border: ${palette.border.actionLight};
  border-radius: 2px;

  .question-actions {
    display: none;
    position: absolute;
    right: 0;
  }

  &:hover {
    border: ${(props) => props.isNew && palette.border.mediumCyan};
    .question-actions {
      display: block;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 20px;
  padding: 10px;
  margin: 10px;
  p {
    font-weight: 500;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: ${(props) => props.display};
  padding: 20px 0;
`;

export const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: ${(props) => props.alignItems};
  font-size: 12px;
  margin: 20px 40px;
`;

export const StyledQuestionContainer = styled.div`
  width: 95%;
  .MuiFormControl-root {
    margin-top: 4px;
  }

  input {
    font-size: 18px;
    line-height: 40px;
    font-weight: 700;
  }
`;

export const StyledSelectContainer = styled.div`
  width: 30%;
`;

export const StyledEvaluationScaleForm = styled.div`
  margin: 0 20px;
  border-radius: 4px;
  border: 1px dashed ${palette.background.inputTags};
  padding: 16px;
`;
