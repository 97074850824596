import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SimpleAccordion from "components/SimpleAccordion";
import NoDataMessage from "components/NoDataMessage";
import { isEmpty } from "common/helpers";
import commentIcon from "assets/images/potential/icon-recomendacion.svg";
import { renderComments } from "views_refactor/Profile/functions/performance";
import { StyledGrid } from "./styles";

const Comments = (props) => {
  const { values } = props;
  const { t } = useTranslation("performance", "engagement");

  return (
    <StyledGrid
      item
      xs={ 12 }
      data-testid={ "good-leader-comments" }
    >
      <SimpleAccordion
        title={ t("engagement:comments") }
        subtitle={ t("performance:leaderComments") }
        src={ commentIcon }
      >
        {isEmpty(values) ? (
          <NoDataMessage />
        ) : (
          <Grid container spacing={ 2 }>
            {renderComments(values)}
          </Grid>
        )}
      </SimpleAccordion>
    </StyledGrid>
  );
};

Comments.propTypes = {
  values: PropTypes.array,
};

Comments.defaultProps = {
  values: [],
};

export default Comments;
