export const CARD_CLASS = {
  blue: "blueCard",
  green: "greenCard",
  purple: "purpleCard",
};

export const DOUGHNUT_ROUNDED = {
  text: {
    max: "100%",
  },
  fontStyle: {
    normal: "normal",
  },
  fontSize: {
    min: 1,
    max: 24,
  },
  cutoutPercentage: 80,
  min: 0,
  align: {
    center: "center",
  },
  middle: "middle",
  width: {
    min: 10,
  },
  height: {
    min: 5,
  },
};

export const HR_ANALYTICS = {
  hc: "hc",
  ctc: "ctc",
};

export const LINE_CHART = {
  axesLabel: 130,
};
