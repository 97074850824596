import configAxios from "../configAxios";
import errorHandler from "../errorHandler";

import {
  RESET_STATE_HC_MONTHLY,
  GET_HC_MONTHLY,
  GET_HC_MONTHLY_LOADING,
  GET_HC_MONTHLY_ERROR,
  RESET_STATE_CTC_MONTHLY,
  GET_CTC_MONTHLY,
  GET_CTC_MONTHLY_LOADING,
  GET_CTC_MONTHLY_ERROR,
} from "../actionTypes/hrAnalytics";

const URL = {
  main: "/charts",
  hcMonth: "/hc_month",
  ctcMonth: "/ctc_month",
};

export const resetStateHc = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_HC_MONTHLY,
  });
};

export const resetStateCtc = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_CTC_MONTHLY,
  });
};

export const getListHc = () => async (dispatch) => {
  dispatch({ type: GET_HC_MONTHLY_LOADING });
  try {
    const response = await configAxios.get(`${URL.main}${URL.hcMonth}`);
    dispatch({
      type: GET_HC_MONTHLY,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_HC_MONTHLY_ERROR);
  }
};

export const getListCtc = () => async (dispatch) => {
  dispatch({ type: GET_CTC_MONTHLY_LOADING });
  try {
    const response = await configAxios.get(`${URL.main}${URL.ctcMonth}`);
    dispatch({
      type: GET_CTC_MONTHLY,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_CTC_MONTHLY_ERROR);
  }
};
