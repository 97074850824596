import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { default as DefaultCardMedia } from "@mui/material/CardMedia";
import { default as DefaultCardActions } from "@mui/material/CardActions";
import palette from "theme/palette";

export const CardContainer = styled(Card)`
  max-width: 345px;
  margin: 0px 15px 25px 15px;
`;

export const CardMedia = styled(DefaultCardMedia)`
  height: 140px;
`;

export const CardTitle = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardActions = styled(DefaultCardActions)`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: ${palette.text.link};
  & svg {
    font-size: 15px;
  }
`;
