import styled from "styled-components";
import Card from "@mui/material/Card";

export const StyledCard = styled(Card)`
  padding: 10px;
  margin-bottom: 30px;
  height: 100%;
  &.MuiPaper-root {
    box-shadow: none;
  }
`;

export const StyledTable = styled(Card)`
  margin-top: 20px;
  padding: 10px 30px;
  &.MuiPaper-root {
    box-shadow: none;
  }
`;

export const StyledGlobalActions = styled.g`
  :hover {
    cursor: pointer;
  }
`;
