import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { getAvatarPromise } from "common/utils";
import { StyledContainer } from "./styles";

const EvaluatorList = ({
  t, onSave, onCancel, allEmployees, isLoading,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    let isMounted = true; 

    const loadImageUrls = async () => {
      const promises = allEmployees.map((employee) => getAvatarPromise(employee)
        .then((url) => ({ id: employee.id, url })));
      const results = await Promise.all(promises);
      if (isMounted) {
        const urls = results.reduce((acc, { id, url }) => {
          acc[id] = url;
          return acc;
        }, {});
        setImageUrls(urls);
      }
    };

    loadImageUrls();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [allEmployees]);

  return (
    <StyledContainer>
      <Autocomplete
        options={ allEmployees }
        getOptionLabel={ (option) => option.full_name }
        value={ selectedEmployee }
        size={ "small" }
        onChange={ (event, newValue) => setSelectedEmployee(newValue) }
        isOptionEqualToValue={ (option, value) => option.id === value.id }
        renderOption={ (props, option, { selected }) => (
          <li { ...props } key={ option.id } style={ { display: "flex", alignItems: "center" } }>
            <Avatar
              src={ imageUrls[option.id] || "" }
              alt={ option.full_name }
              style={ { width: 30, height: 30, marginRight: 8 } }
            />
            {option.full_name}
          </li>
        ) }
        renderInput={ (params) => (
          <TextField { ...params } label={ t("participation:actions.select_evaluator") } variant={ "outlined" } fullWidth />
        ) }
        sx={ { flex: 1 } }
      />
      <Button
        onClick={ () => onSave(selectedEmployee) }
        variant={ "contained" }
        color={ "primary" }
        sx={ { ml: 1, flexShrink: 0 } }
        disabled={ isLoading }
      >
        {t("common:common.save")}
      </Button>
      <Button
        onClick={ onCancel }
        variant={ "outlined" }
        sx={ { ml: 1, flexShrink: 0 } }
        disabled={ isLoading }
      >
        {t("common:common.cancel")}
      </Button>
    </StyledContainer>
  );
};

EvaluatorList.propTypes = {
  t: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  allEmployees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string.isRequired,
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EvaluatorList;
