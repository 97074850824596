import { useQuery, useQueryClient } from "react-query";
import { getCities } from "redux/actions/common/cityActions";

const fetchCities = async () => {
  const response = await getCities();
  return response;
};

export const useCities = () => {
  const queryClient = useQueryClient();

  const { data: cities, isLoading, isError } = useQuery("cities", fetchCities, {
    staleTime: Infinity,
  });

  return {
    cities,
    isLoading,
    isError,
    queryClient,
  };
};
