import styled from "styled-components";
import Box from "@mui/material/Box";

export const StyledBox = styled(Box)`
  text-align: center;
  .MuiTypography-h4 {
    margin: 16px 0px 8px 0px;
  }
`;

export const StyledBoldText = styled.span`
  font-weight: bold;
`;
