import { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import AutocompleteCreable from "components/AutocompleteCreable";
import { REMOVE_OPTION, CLEAR } from "common/constants";
import InputText from "components/InputText";
import useStyles from "./styles";

const CustomFilterToolbar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { id, name, onChange, isDisabled } = props;
  const [filters, setFilters] = useState([]);
  const [values, setValues] = useState([]);

  const areasReducer = useSelector((state) => state.areasReducer);
  const citiesReducer = useSelector((state) => state.citiesReducer);
  const subareasReducer = useSelector((state) => state.subareasReducer);
  const countriesReducer = useSelector((state) => state.countriesReducer);
  const managersFirebaseReducer = useSelector(
    (state) => state.managersFirebaseReducer
  );
  const departmentsReducer = useSelector((state) => state.departmentsReducer);

  const getReducer = useCallback(
    (value) => {
      switch (value) {
        case "manager":
          return {
            data: managersFirebaseReducer.managers,
            loading: managersFirebaseReducer.isLoading,
          };
        case "division":
          return {
            data: departmentsReducer.departments,
            loading: departmentsReducer.isLoading,
          };
        case "area":
          return {
            data: areasReducer.areas,
            loading: areasReducer.isLoading,
          };
        case "subarea":
          return {
            data: subareasReducer.subareas,
            loading: subareasReducer.isLoading,
          };
        case "country":
          return {
            data: countriesReducer.countries,
            loading: countriesReducer.isLoading,
          };
        case "city":
          return {
            data: citiesReducer.cities,
            loading: citiesReducer.isLoading,
          };

        default:
          return [];
      }
    },
    [
      managersFirebaseReducer,
      citiesReducer,
      subareasReducer,
      countriesReducer,
      departmentsReducer,
      areasReducer,
    ]
  );

  const FILTER_OPTIONS = [
    {
      value: "manager",
      label: t("common.manager"),
    },
    {
      value: "division",
      label: t("common.division"),
    },
    {
      value: "area",
      label: t("common.area"),
    },
    {
      value: "subarea",
      label: t("common.sub-area"),
    },
    {
      value: "country",
      label: t("common.country"),
    },
    {
      value: "city",
      label: t("common.city"),
    },
  ];

  const getOptionLabel = (option) => option.label;

  const getOptionSelected = (option, value) => option.value === value.value;

  const renderInput = (params) => (
    <InputText
      params={{ ...params }}
      name={name}
      label={t("common.filter_adding")}
      inputProps={{
        ...params.InputProps,
        endAdornment: (
          <Fragment>
            <Divider orientation="vertical" flexItem />
            {params.InputProps.endAdornment}
          </Fragment>
        ),
      }}
    />
  );

  const handleChange = (ev, newValue, typeOption, option) => {
    setFilters(newValue);

    if (_.isEqual(typeOption, REMOVE_OPTION) || _.isEqual(typeOption, CLEAR)) {
      let filtToSave = _.isEqual(typeOption, REMOVE_OPTION)
        ? values.filter((value) => value.tag !== option.option.value)
        : [];
      setValues(filtToSave);
      onChange(filtToSave);
    }
  };

  const handleChangeInputs = (prop, ev, newValue) => {
    let filt = values.filter((value) => value.tag !== prop);
    let filtToSave = _.isNull(newValue)
      ? filt
      : [...filt, { tag: prop, id: newValue.id }];

    setValues(filtToSave);
    onChange(filtToSave);
  };

  return (
    <div data-testid="filter-toolbar-container">
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.grid}
      >
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id={id}
            disabled={isDisabled}
            options={FILTER_OPTIONS}
            onChange={handleChange}
            getOptionLabel={getOptionLabel}
            renderInput={renderInput}
            getOptionSelected={getOptionSelected}
            className={classes.whiteAutocomplete}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {filters &&
          filters.map((filter) => (
            <Grid
              key={filter.value}
              item
              xs={6}
              sm={4}
              className={classes.whiteAutocomplete}
            >
              <AutocompleteCreable
                options={
                  getReducer(filter.value).data.length > 0
                    ? getReducer(filter.value).data
                    : []
                }
                id={filter.value}
                loading={getReducer(filter.value).loading}
                onChange={handleChangeInputs}
                label={filter.label}
                isMarginNormal={true}
                nameOfAttr="name"
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

CustomFilterToolbar.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default CustomFilterToolbar;
