import styled from "styled-components";
import { ENGAGEMENT } from "theme/palette";

export const SwitchSelectorWrapper = styled("div")`
  display: flex;
  border-radius: ${(props) => `${props.wrapperBorderRadius || 18}px`};
  border: ${(props) => props.border || `1px solid ${ENGAGEMENT.selector.bgDefault}`};
  background: ${(props) => props.backgroundColor || ENGAGEMENT.selector.bgDefault};
  width: ${(props) => (props.isFullWidth ? "100%" : "auto")};
  height: ${(props) => (props.height ? props.height : "100%")};
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  margin: ${(props) => props.margin || "0"};

  &::before {
    top: 50%;
    left: ${(props) => (props.selectedIndex / props.optionsAmount) * 100}%;
    content: "";
    position: absolute;
    height: 100%;
    width: ${(props) => (1 / props.optionsAmount) * 100}%;
    border-radius: ${(props) => `${props.optionBorderRadius || 18}px`};
    border: ${(props) => props.selectedBorderWidth || 1}px solid
      ${(props) => props.selectedBorderColor || ENGAGEMENT.selectedFont};
    background: ${(props) => props.selectedBackgroundColor || ENGAGEMENT.selector.selectedBg};
    color: ${(props) => props.selectedFontColor || ENGAGEMENT.selector.selectedFont};
    box-shadow: ${`0 4px 4px 0 ${ENGAGEMENT.selector.selectedShadow}`};
    transition: left 0.15s ease-in-out, background 0.15s ease-in-out;
    transform: translateY(-50%);
    z-index: 1;
    box-sizing: content-box;
  }
`;

export const OptionItem = styled("div")`
  display: flex;
  align-items: center;
  height: 100%;
  width: ${(props) => (100 / props.optionsAmount).toFixed(2)}%;
  border-radius: ${({ optionBorderRadius }) => (typeof optionBorderRadius === "number"
  ? `${optionBorderRadius}px`
  : optionBorderRadius)};
`;

export const OptionItemLabel = styled("label")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.height || "36px"};
  z-index: 2;
  transition: color 0.15s ease-in-out;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: ${(props) => `${props.fontSize || 12}px`};
  font-weight: ${(props) => (props.selected ? 700 : 500)};
  color: ${(props) => (props.selected
  ? (props.selectedFontColor || ENGAGEMENT.selector.selectedFont)
  : (props.fontColor || ENGAGEMENT.selector.textDefault))};
  text-align: center;
  padding: 0 5px;
`;

export const OptionInput = styled("input")`
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
  position: absolute;
  pointer-events: none;
`;
