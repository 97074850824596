import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import forEachRight from "lodash/forEachRight";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuPopup from "components/MenuPopup";
import SimpleAlert from "components/SimpleAlert";
import Button from "components/Button";
import InputTextController from "components/InputTextController";
import AlertModal from "components/AlertModal";
import AutoCompleteCreable from "components/AutocompleteCreable";
import InputTag from "components/InputTag";
import DateInputController from "components/DateInputController";
import AlertDialog from "components/AlertDialog";
import {
  toast, handleMessages, MESSAGE_TYPES, HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import ManagerSelect from "components/commons/ManagerSelect";
import InputCheckbox from "components/InputCheckbox";
import { StyledActionItem } from "components/TablePagination/styles";
import Modal from "components/Modal";
import {
  isEmpty, isEqual, isNotValid, isNull, isUndefined,
} from "common/helpers";
import {
  ROLES,
  BUTTON_STYLE_TYPES,
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
  SIZE,
  ENTITY_OPTION,
  VARIANT,
} from "common/constants";
import {
  getItemFromLocalStorage,
  isNotCandidate,
  historyPush,
  hasAllValidatedFields,
  hasEmptyInput,
  getAutocompleteAttr,
  getDivisions,
  getAreas,
  getElementNewValue,
  dispatchIfNotLocalStorage,
  isColombianAdmin,
  getUserRoles,
  getCitiesByCountry,
} from "common/utils";
import { getValidUser, isNullOrUndefined } from "common/validators";
import { getList as getPositions, create as createPosition } from "redux/actions/position/positionActions";
import { getList as getHierarchyLevelList, create as createLevel } from "redux/actions/common/hierarchyLevelActions";
import { getList as getLegalEntityList, create as createEntity } from "redux/actions/common/legalEntityActions";
import { getList as getListOrgUnits } from "redux/actions/organizationUnits/orgUnitsActions";
import { create as createCity } from "redux/actions/common/cityActions";
import {
  terminateEmployment,
  getListSearch as getCollaborators,
  getCollaboratorChildrens,
  getMainList as getManagerList,
} from "redux/actions/collaboratorActions";
import { resetStateProcess as resetStateProcessMovements } from "redux/actions/historicalMovementActions";
import { useUserBenefits } from "hooks/useUserBenefits";
import { getSalaryAttributesData } from "views/Account/functions/salary";
import { useCities } from "hooks/useCities";
import RelocateEmployee from "../../../Collaborators/components/RelocateEmployee";
import TerminateEmploymentForm from "../../../Collaborators/components/TerminateEmploymentForm";
import {
  validateRoleCondition,
  resetProfileData,
} from "../../functions";
import SalaryPromotion from "./components/SalaryPromotion";
import RolesModal from "./components/RolesModal";
import DynamicAttributesData from "../DynamicAttributesData";
import {
  resetDefaultValues,
  dispatchUpdate,
  getOrganizationUnitName,
  isValidToDispatch,
  getRolesListName,
  getRolesApp,
  handleMovement,
  getPromotionMessage,
  getLateralMovementMessage,
  validateAllFields,
  getDivisionId,
  getAreaId,
  getRolesWithScope,
  getActionRoles,
} from "../../functions/profile";
import { StyledGrid } from "./styles";

const Profile = (props) => {
  const {
    locationState,
    user,
    from,
    classes,
    searchBy,
    employee,
    reducerToUse,
    selectedId,
  } = props;

  const { t } = useTranslation(["common", "collaborators", "accounts"]);
  const [
    userBenefits,
  ] = useUserBenefits(isNotCandidate(from, user?.userCookies) ? reducerToUse?.one?.id : null);

  const [cities, setCities] = useState([]);
  const [isManagerRequired, setIsManagerRequired] = useState(true);

  const countries = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries);
  const [positions] = useState(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.positions));
  const [levels, setLevels] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [newCreation, setNewCreation] = useState();
  const [dynamicFieldsList, setDynamicFieldsList] = useState([]);
  const organizationUnits = getItemFromLocalStorage(
    LOCAL_STORAGE_NAMES.orgUnits,
  );
  const [isDispatched, setIsDispatched] = useState({
    organizationUnits: false,
    positions: false,
  });
  const { list: roleList } = useSelector(({ rolesReducer }) => rolesReducer);
  const {
    list: hierarchyLevelsList,
    isLoadingList: isLoadingHierarchyLevels,
  } = useSelector(({ hierarchyLevelReducer }) => hierarchyLevelReducer);

  const {
    list: legalEntitiesList,
    isLoadingList: isLoadingLegalEntities,
  } = useSelector(({ legalEntityReducer }) => legalEntityReducer);

  const [inputTextValue, setInputTextValue] = useState("");
  const [dialog, setDialog] = useState(false);
  const [countrySelected, setCountrySelected] = useState();

  const isValidColombianAdmin = isColombianAdmin(user?.userCookies) && from;

  const divisions = getDivisions(organizationUnits);
  const employeeData = reducerToUse?.one;

  const [areas, setAreas] = useState([]);

  const history = useHistory();

  /* No refactor fixing relocate bug, we should do the profile refactor */
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleModalContent = (content) => {
    setModalContent(content);
  };
  /* END */

  const {
    handleSubmit, control, reset, register,
  } = useForm({
    defaultValues: resetDefaultValues(employee),
  });

  // FIXME: temporal solution until dinamic front will be implemented
  const onAutocompleteChangeOrgUnits = (nameOfAttr, orgUnit) => (
    prop,
    event,
    newValue,
  ) => {
    const orgUnitName = getAutocompleteAttr(newValue, nameOfAttr);
    pulse[orgUnit]
      && setPulse({ ...pulse, [orgUnit]: !isEmpty(areas) && isNull(newValue) });

    if (isEqual(orgUnit, OBJECT_KEYS.division)) {
      setValues({
        ...values,
        division: getElementNewValue(newValue, orgUnitName),
        division_id: newValue?.id,
        area: "",
      });
      setAreas(getAreas(organizationUnits, newValue?.id));
    } else {
      setValues({
        ...values,
        area: getElementNewValue(newValue, orgUnitName),
        area_id: newValue?.id,
      });
    }
  };

  // Cities
  const {
    cities: allCities, isLoading, queryClient,
  } = useCities();

  useEffect(() => {
    if (countrySelected && allCities && !isLoading) {
      setCities(getCitiesByCountry(countrySelected, allCities));
    }
    // eslint-disable-next-line
  }, [countrySelected, allCities, isLoading]);

  const [values, setValues] = useState({
    manager: null,
    position: null,
    level: null,
    legalEntity: null,
    country: null,
    city: null,
    division: null,
    division_id: null,
    area: null,
    area_id: null,
    roles: null,
  });

  const onAutocompleteChange = () => (prop, event, newValue) => {
    if (isEqual(prop, ENTITY_OPTION.country)) {
      setCountrySelected(newValue?.id);
      setValues({
        ...values,
        [prop]: newValue,
        city: null,
      });
    } else {
      setValues({
        ...values,
        [prop]: newValue,
      });
    }
  };

  const handleTags = (tags) => {
    setUserRoles(tags);
    setValues({
      ...values,
      roles: getRolesListName(tags),
    });
    setPulse({ ...pulse, roles: isEmpty(getRolesListName(tags)) });
  };

  const [editable, setEditable] = useState(
    locationState?.edit
      || locationState?.promotion
      || locationState?.lateralMovement,
  );
  const validUser = getValidUser(user);
  const rolesCollaborator = reducerToUse?.one?.roles_with_scope;

  const companyId = validUser?.company?.id;
  const [rolesModal, setRolesModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [pulse, setPulse] = useState({
    managerId: false,
    positionId: false,
    levelId: false,
    legalEntityId: false,
    division: false,
    area: false,
    country: false,
    city: false,
    roles: false,
    movementDate: false,
    contract: false,
    salary: false,
    currency: false,
    salaryDate: false,
  });
  const [showSalary, setShowSalary] = useState(locationState?.promotion);
  const [showMovementDate, setShowMovementDate] = useState(
    locationState?.lateralMovement,
  );

  const dispatch = useDispatch();

  const {
    isLoadingProcess: isLoadingProcessMovements,
    successProcess: successProcessMovements,
  } = useSelector(({ historicalMovementsReducer }) => historicalMovementsReducer);
  const {
    managersList: collaboratorsMainList,
    isLoadingList: isLoadingManagerList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const ROLES_LIST = getRolesApp(roleList, t, isValidColombianAdmin);

  const [inputTagsSelected, setInputTagsSelected] = useState([]);

  useEffect(() => {
    dispatch(getManagerList({ q: { user_roles_name_eq: ROLES.MANAGER, active_in: [true] } }));
  }, [dispatch]);

  const resetForm = useCallback(
    (user) => {
      if (!isNull(user?.person) && !isEmpty(user)) {
        const userToUse = {
          full_name: null,
          email: null,
          manager: null,
          position: null,
          hierarchy_level: null,
          legal_entity: null,
          division_name: null,
          division_id: null,
          area_name: null,
          area_id: null,
          country: null,
          city: null,
        };
        userToUse.full_name = user?.person?.full_name;

        userToUse.email = user?.email;

        userToUse.manager = { full_name: user?.manager?.full_name || "", id: user?.manager?.id || "" };
        if (!userToUse?.manager?.id) {
          setIsManagerRequired(false);
        }

        userToUse.position = user?.job_position?.position;
        userToUse.hierarchy_level = user?.job_position?.hierarchy_level;
        userToUse.legal_entity = user?.legal_entity;
        userToUse.division_name = getOrganizationUnitName(
          user?.job_position?.organization_units,
        ).division;

        userToUse.area_name = getOrganizationUnitName(
          user?.job_position?.organization_units,
        ).area;

        const orgUnitIds = [];
        userToUse.division_id = getDivisionId(user?.job_position?.organization_units);
        userToUse.area_id = getAreaId(user?.job_position?.organization_units);
        if (userToUse.division_id) orgUnitIds.push(userToUse.division_id);
        if (userToUse.area_id) orgUnitIds.push(userToUse.area_id);
        userToUse.organization_unit_ids = orgUnitIds;

        if (!isEmpty(userToUse.division_name)) {
          setAreas(getAreas([], getDivisionId(user?.job_position?.organization_units)));
        }

        userToUse.country = user?.job_position?.country;
        setCountrySelected(user?.job_position?.country?.id);
        userToUse.city = user?.job_position?.city;

        userToUse.roles_name = user?.roles_name;
        userToUse.roles = user?.roles_with_scope;
        // salary section
        userToUse.starting_date = isNullOrUndefined(user?.starting_date)
          ? ""
          : user?.starting_date;

        userToUse.type_of_contract = user?.job_position?.type_of_contract?.id;

        userToUse.salary = user?.salary?.gross_salary;

        userToUse.currency = user?.salary?.currency;

        setValues({
          position: userToUse.position,
          level: userToUse.hierarchy_level,
          legalEntity: userToUse.legal_entity,
          country: userToUse.country,
          city: userToUse.city,
          division: userToUse.division_name,
          division_id: userToUse.division_id,
          area: userToUse.area_name,
          area_id: userToUse.area_id,
          currency: userToUse.currency,
          manager: userToUse.manager,
          roles:
            isNotCandidate(from, user?.userCookies)
            && getRolesListName(userToUse.roles),
        });

        reset({
          [`${employee}.person_attributes.full_name`]: userToUse.full_name,
          [`${employee}.manager_id`]: userToUse.manager,
          [`${employee}.job_position_attributes.division_name`]: userToUse.division_name,
          [`${employee}.job_position_attributes.organization_unit_ids`]: userToUse.organization_unit_ids,
          [`${employee}.job_position_attributes.area_name`]: userToUse.area_name,
          [`${employee}.email`]: userToUse.email,
          // salary section
          [`${employee}.starting_date`]: userToUse.starting_date,
          [`${employee}.job_position_attributes.type_of_contract_id`]: userToUse.type_of_contract,
          [`${employee}.salary_attributes.gross_salary`]: userToUse.salary,
          [`${employee}.salary_attributes.currency`]: userToUse.currency,
        });
      }
    },
    [reset, employee, from],
  );

  const openCloseModal = (externalHandler) => {
    setModal(externalHandler ? false : !modal);
  };

  const viewModal = (
    title,
    children,
    text = "",
    approved = "",
    cancel = "",
    onClick,
    isLoader,
  ) => {
    setModalData(
      createModal(title, text, approved, cancel, onClick, children, isLoader),
    );
    openCloseModal();
  };

  const createModal = (
    title,
    text,
    approved,
    cancel,
    onClick,
    children,
    isLoader,
  ) => ({
    title, text, approved, cancel, onClick, children, isLoader,
  });

  // const terminateEmployeeCollaborator = (collaboratorId, data) => {
  //   setModal(false);
  //   dispatch(terminateEmployee(
  //     collaboratorId,
  //     data,
  //   ));
  // };
  const handleTerminateEmployment = (id, data) => {
    dispatch(terminateEmployment(id, data));
  };
  useEffect(() => {
    if (isEmpty(organizationUnits) && !isDispatched.organizationUnits) {
      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.orgUnits,
        getListOrgUnits,
        dispatch,
      );
      setIsDispatched({
        ...isDispatched,
        organizationUnits: true,
      });
    }
    if (isEmpty(positions) && !isDispatched.positions) {
      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.positions,
        getPositions,
        dispatch,
      );
      setIsDispatched({
        ...isDispatched,
        positions: true,
      });
    }
  }, [companyId, dispatch, organizationUnits, isDispatched, positions]);

  useEffect(() => {
    if (user) {
      if (!isNotValid(rolesCollaborator)) {
        setInputTagsSelected(getRolesWithScope(rolesCollaborator, t));
      }

      validateRoleCondition(
        from,
        user,
        [ROLES.ADMIN, ROLES.ADMIN_NALA],
        [ROLES.COLLABORATOR],
      )
        ? reset(resetDefaultValues(employee))
        : resetForm(employeeData);

      if (
        isValidToDispatch(reducerToUse)
        && isNotCandidate(from, user?.userCookies)
      ) {
        dispatch(getCollaborators());
      }
    }
  }, [
    reducerToUse,
    user,
    from,
    reset,
    resetForm,
    searchBy,
    employee,
    t,
    dispatch,
    companyId,
    roleList,
    rolesCollaborator,
    employeeData,
  ]);

  useEffect(() => {
    dispatch(getHierarchyLevelList());
    dispatch(getLegalEntityList());
  }, [dispatch]);

  useEffect(() => {
    if (!isNull(hierarchyLevelsList) && !isNull(legalEntitiesList)) {
      setLevels(hierarchyLevelsList);
      setEntities(legalEntitiesList);
    }
  }, [hierarchyLevelsList, legalEntitiesList]);

  const handleToggleEdit = (isModalToggle) => {
    if (!isModalToggle) {
      setShowSalary(false);
      setShowMovementDate(false);
    }

    setPulse({
      managerId: false,
      positionId: false,
      levelId: false,
      legalEntityId: false,
      division: false,
      area: false,
      country: false,
      city: false,
      roles: false,
      movementDate: false,
      contract: false,
      salary: false,
      currency: false,
      salaryDate: false,
    });

    resetForm(employeeData);

    return setEditable(isModalToggle);
  };

  const handlePromotion = () => {
    setShowSalary(true);
    setShowMovementDate(false);
    handleToggleEdit(true);
  };

  const handleLateralMovement = () => {
    setShowSalary(false);
    setShowMovementDate(true);
    handleToggleEdit(true);
  };

  const handleModalSubmit = () => {
    const auxPulse = {
      country: isEmpty(values.country),
      roles: isEqual(employee, ROLES.COLLABORATOR) && isEmpty(values.roles),
    };

    if (showMovementDate || showSalary) {
      auxPulse.movementDate = hasEmptyInput(
        control,
        OBJECT_KEYS.date,
        validateAllFields,
      );
    }

    if (showSalary) {
      auxPulse.contract = hasEmptyInput(
        control,
        OBJECT_KEYS.typeOfContractId,
        validateAllFields,
      );
      auxPulse.salary = hasEmptyInput(
        control,
        OBJECT_KEYS.grossSalary,
        validateAllFields,
      );
      auxPulse.currency = hasEmptyInput(
        control,
        OBJECT_KEYS.currency,
        validateAllFields,
      );
    }

    setPulse(auxPulse);

    if (hasAllValidatedFields(auxPulse) && !pulse.roles && (
      (!isManagerRequired || (isManagerRequired && values?.manager?.id)))) {
      return (
        !pulse.roles
        && viewModal(
          t("common:common.modal_messages.sure_question"),
          showSalary || showMovementDate ? (
            <SimpleAlert
              type={ "info" }
              message={ t("account:accountDetails.movementHistoryMessage") }
              customStyle={ classes.simpleAlertModal }
            />
          ) : (
            ""
          ),
          showSalary
            ? getPromotionMessage(t)
            : showMovementDate
              ? getLateralMovementMessage(t)
              : t("common:common.modal_messages.sure_text"),
          t("common:common.modal_messages.yes_confirm"),
          t("common:common.modal_messages.no_cancel"),
          onSubmit,
        )
      );
    }
  };

  // temporal validation 'till service for "start contract" will be ready
  const TERMINATION_OPTION = {
    title: (
      <StyledActionItem>
        {t(
          `collaborators:table.table-body.${
            reducerToUse
            && employeeData?.is_active ? "finish_contract" : "start_contract"
          }`,
        )}
      </StyledActionItem>
    ),
    icon: (
      <StyledActionItem>
        <HighlightOffIcon fontSize={ SIZE.small } />
      </StyledActionItem>
    ),
    divider: true,
    onClick: async () => {
      const query = {
        q: {
          manager_id_special_in: [employeeData?.id],
          active_in: [true],
        },
      };
      const childrens = await getCollaboratorChildrens(query);
      let modalContent = {
        title: t("collaborators:forms.finish_contract_title"),
        children: (
          <TerminateEmploymentForm
            id={ employeeData?.id }
            action={ handleTerminateEmployment }
            modalHandler={ modal.set }
          />
        ),
      };

      if (!isEmpty(childrens)) {
        modalContent = {
          title: "",
          children: (
            <RelocateEmployee
              id={ employeeData?.id }
              title={ t("collaborators:forms.finish_contract_title") }
              subtitle={ t("collaborators:forms.finish_contract_subtitle_relocate") }
              options={ collaboratorsMainList }
              collaborators={ childrens }
              action={ handleTerminateEmployment }
              modalHandler={ setIsOpen }
            />
          ),
        };
      }
      handleModalContent(modalContent);
      setIsOpen(!isOpen);
    },
  };

  const ACTIVE_COLLABORATOR_ACTIONS = [
    {
      title: t("account:accountDetails.promote"),
      isDisabled: showSalary,
      onClick: () => handlePromotion(),
    },
    {
      title: t("account:accountDetails.lateral_movement"),
      isDisabled: showMovementDate,
      onClick: () => handleLateralMovement(),
    },
  ];

  const ACTIONS_MENU = [
    {
      title: t("account:accountDetails.edit_info"),
      isDisabled: editable,
      onClick: () => handleToggleEdit(true),
    },
  ];

  const addActiveCollaboratorActions = () => {
    forEachRight(ACTIVE_COLLABORATOR_ACTIONS, (item) => {
      ACTIONS_MENU.unshift(item);
    });
    ACTIONS_MENU.push(TERMINATION_OPTION);
  };

  reducerToUse
    && employeeData?.is_active
    && addActiveCollaboratorActions();

  const menuActions = (grid) => (
    <Grid item xs={ 12 } sm={ grid } className={ classes?.btnRight }>
      <MenuPopup
        button={ t("collaborators:buttons.actions") }
        items={ ACTIONS_MENU }
        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
      />
    </Grid>
  );

  const getMenu = () => {
    if ((user && (includes(getUserRoles(user?.userCookies), ROLES.ADMIN) || includes(getUserRoles(user?.userCookies), ROLES.ADMIN_NALA)))
        || isValidColombianAdmin) {
      if (editable) {
        return menuActions(2); // width of two grids
      }
      return menuActions(12); // width of show 12 grids
    }
    return "";
  };

  const profileTitleInfo = editable && (
    <Grid item xs={ 12 } sm={ 6 }>
      <Typography className={ classes.subtitleText }>
        {t("account:accountDetails.job_information")}
      </Typography>
      <Typography variant={ VARIANT.subtitleTwo }>
        {t("account:accountDetails.all_required")}
      </Typography>
    </Grid>
  );

  const messageWorkInfo = editable && (
    <Grid item xs={ 12 } sm={ 10 }>
      <Typography className={ classes.subtitleText }>
        {showSalary
          ? t("account:accountDetails.promote")
          : showMovementDate
            ? t("account:accountDetails.lateral_movement")
            : t("account:accountDetails.edit_info")}
      </Typography>
      <SimpleAlert
        type={ "info" }
        message={ showSalary || showMovementDate
          ? t("account:accountDetails.promote_message")
          : t("account:accountDetails.edit_message") }
      />
    </Grid>
  );

  const onUpdateDispatch = () => {
    handleToggleEdit(undefined);
    setModal(false);
  };

  const generateAttributesList = () => dynamicFieldsList.filter((item) => !isEmpty(item.value)).map(
    (item) => (item.custom_attribute_id ? {
      id: item.custom_attribute_id,
      value: item.value,
    } : {
      dynamic_attribute_id: item.id,
      value: item.value,
    }),
  );

  const onSubmit = () => {
    const data = control.getValues();
    // FIXME: Temporal solution till AUTOCOMPLETE component can handle it!
    data[employee].job_position_attributes.position_id = values?.position && values?.position?.id;
    data[employee].job_position_attributes.hierarchy_level_id = values?.level && values?.level?.id;
    data[employee].legal_entity_id = values?.legalEntity && values?.legalEntity?.id;
    data[employee].job_position_attributes.country_id = values?.country && values?.country?.id;
    data[employee].job_position_attributes.city_id = values?.city && values?.city?.id;

    const orgUnitIds = [];
    if (values.division_id) orgUnitIds.push(values.division_id);
    if (values.area_id) orgUnitIds.push(values.area_id);
    data[employee].job_position_attributes.organization_unit_ids = orgUnitIds;

    if (isNotCandidate(from, user?.userCookies) && !isEmpty(userRoles)) {
      data[employee].user_roles = getActionRoles(rolesCollaborator, userRoles);
    }
    data[employee].custom_attributes_attributes = generateAttributesList();

    if (!showMovementDate && !showSalary) {
      data[employee].manager_id = values?.manager ? values?.manager?.id : null;
      // edit profile
      if (!pulse.roles) {
        dispatchUpdate(dispatch, employee, data, reducerToUse).then(
          () => {
            onUpdateDispatch();
          },
        );
      }
    } else {
      // lateral movement or promotion
      data[employee].job_position_attributes.manager_id = values?.manager ? values?.manager?.id : null;
      delete data[employee].manager_id;
      // Temporary FIX: Send type_of_contract_id also in lateral movement
      if (showMovementDate) {
        data[employee].job_position_attributes.type_of_contract_id = reducerToUse.collaborator?.job_position?.type_of_contract?.id;
      }
      const constToHandle = {
        data,
        dispatch,
        employeeId: employeeData?.id,
        companyId,
        movement: showMovementDate
          ? OBJECT_KEYS.lateralMovement
          : showSalary && OBJECT_KEYS.promotion,
      };

      if (!pulse.roles) {
        handleMovement(constToHandle).then(() => {
          // FIXME: For now, send the benefits to keep when you make a salary increase or promotion.
          if (!isEmpty(userBenefits)) {
            dispatchUpdate(
              dispatch,
              ROLES.COLLABORATOR,
              getSalaryAttributesData(employeeData?.id, userBenefits),
              reducerToUse,
            );
          }
          // End FIXME
          // DEPRECATED: because a candidate does not have any movements
          !isEmpty(from)
            ? historyPush(
              history,
              "/profile",
                `?collaborator=${
                  employeeData?.id
                }`,
            )
            : historyPush(history, "/profile");
        });
      }
    }
  };

  const handleDialog = (option) => {
    setDialog(option);
  };

  const handleDialogSubmit = (text, prop) => {
    setInputTextValue(text);
    setNewCreation(prop);
    handleDialog(true);
  };

  const creations = {
    city: {
      list: cities,
      data: {
        country_id: countrySelected,
        name: inputTextValue,
      },
      create: createCity,
      queryName: "cities",
    },
    position: {
      list: positions,
      localName: LOCAL_STORAGE_NAMES.positions,
      data: {
        name: inputTextValue,
      },
      create: createPosition,
    },
    level: {
      list: levels,
      data: {
        name: inputTextValue,
      },
      create: createLevel,
    },
    legalEntity: {
      list: entities,
      data: {
        name: inputTextValue,
        code: inputTextValue,
      },
      create: createEntity,
    },
  };

  const handleNew = async () => {
    const newItem = creations[newCreation];
    const createdElement = await dispatch(newItem.create(newItem.data));

    if (!isEmpty(createdElement)) {
      //  error handle
      if (createdElement.error) {
        toast(MESSAGE_TYPES.error, createdElement, t);
      } else {
        newItem.list.push(createdElement);
        setValues({
          ...values,
          [newCreation]: createdElement,
        });

        if (newItem?.localName) {
          const tempLocalstorage = getItemFromLocalStorage(newItem.localName);
          tempLocalstorage.push(createdElement);
          localStorage.setItem(newItem.localName, JSON.stringify(tempLocalstorage));
        }
        if (newItem?.queryName) {
          queryClient.setQueryData(newItem.queryName, (old) => [...old, createdElement]);
        }
      }

      handleDialog(false);
    }
  };

  const resetData = useCallback(() => {
    resetProfileData(
      dispatch,
      from,
      searchBy,
      user,
      selectedId,
    );
    dispatch(resetStateProcessMovements());
    toast(
      MESSAGE_TYPES.success,
      handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
    );
    setIsOpen(false);
  }, [t, dispatch, from, searchBy, selectedId, user]);

  useEffect(() => {
    if (reducerToUse?.successProcess || successProcessMovements) {
      resetData();
    }
  }, [reducerToUse, successProcessMovements, resetData]);

  const handleRolesModal = () => {
    setRolesModal(!rolesModal);
  };

  const renderRoleModal = isEqual(employee, OBJECT_KEYS.collaborator) && (
    <RolesModal
      isOpen={ rolesModal }
      onClose={ handleRolesModal }
      hasSelected={ userRoles.map((e) => ({
        name: e.value,
        managed_countries: e.countries?.map((country) => ({ id: country.id, name: country.value })),
      })) }
      reducerToUse={ reducerToUse }
      from={ from }
    />
  );

  const handleManagerChange = (data) => {
    setValues({
      ...values,
      manager: data,
    });
  };

  const checkHandler = (value) => {
    setIsManagerRequired(value);
    if (!value) {
      handleManagerChange(null);
    }
  };

  return (
    <div className={ classes?.container } data-testid={ "account-profile-tab" }>
      <Grid container spacing={ 4 }>
        {messageWorkInfo}
        {getMenu()}
        {profileTitleInfo}
      </Grid>
      <form
        autoComplete={ "off" }
        onSubmit={ handleSubmit(handleModalSubmit) }
        className={ classes?.tablePaper }
      >
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 } md={ 6 }>
            <InputTextController
              type={ "text" }
              label={ t("common:common.name") }
              required
              disabled={ !editable || showMovementDate || showSalary }
              control={ control }
              name={ `${employee}.person_attributes.full_name` }
            />
          </Grid>
          {editable && (
            <Grid item xs={ 6 } md={ 3 }>
              <InputCheckbox
                name={ `${employee}.checkboxManager` }
                control={ control }
                label={ t("account:accountDetails.allowNoManager") }
                onClick={ () => checkHandler(!isManagerRequired) }
                isDefaultChecked={ !isManagerRequired }
              />
            </Grid>
          )}
          <Grid item xs={ editable ? 6 : 12 } md={ editable ? 3 : 6 }>
            <ManagerSelect
              name={ `${employee}.manager_id` }
              isDisabled={ !editable || !isManagerRequired }
              control={ control }
              value={ values?.manager }
              onChange={ handleManagerChange }
              isRequired={ isManagerRequired }
              employee={ employeeData }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteCreable
              id={ OBJECT_KEYS.position }
              options={ positions }
              label={ t("common:common.position") }
              register={ register }
              value={ values.position && values.position?.name }
              disabled={ !editable }
              onChange={ onAutocompleteChange() }
              name={ `${employee}.job_position_attributes.position_id` }
              nameOfAttr={ "name" }
              addNew={ {
                handleNew: handleDialogSubmit,
                text: t("common.add_new_position"),
                prop: OBJECT_KEYS.position,
              } }
              isRequired
              customStyles={ pulse.positionId ? classes.pulse : "" }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteCreable
              id={ "division" }
              options={ divisions }
              label={ t("common:common.division") }
              register={ register }
              value={ values.division }
              disabled={ !editable }
              onChange={ onAutocompleteChangeOrgUnits(
                OBJECT_KEYS.name,
                OBJECT_KEYS.division,
              ) }
              name={ `${employee}.job_position_attributes.division_name` }
              nameOfAttr={ OBJECT_KEYS.name }
              customStyles={ pulse.division ? classes.pulse : "" }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteCreable
              id={ "area" }
              options={ areas }
              label={ t("common:common.area") }
              register={ register }
              disabled={ !editable }
              onChange={ onAutocompleteChangeOrgUnits(
                OBJECT_KEYS.name,
                OBJECT_KEYS.area,
              ) }
              value={ values.area }
              name={ `${employee}.job_position_attributes.area_name` }
              nameOfAttr={ OBJECT_KEYS.name }
              customStyles={ pulse.area ? classes.pulse : "" }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteCreable
              id={ "level" }
              options={ levels }
              label={ t("common:common.hierarchyLevel") }
              register={ register }
              value={ values.level && values.level?.name }
              onChange={ onAutocompleteChange() }
              name={ `${employee}.job_position_attributes.hierarchy_level_id` }
              nameOfAttr={ "name" }
              addNew={ {
                handleNew: handleDialogSubmit,
                text: t("common.add_new_level"),
                prop: OBJECT_KEYS.level,
              } }
              loading={ isLoadingHierarchyLevels }
              disabled={ !editable }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteCreable
              id={ "legalEntity" }
              options={ entities }
              label={ t("common:common.legalEntity") }
              register={ register }
              value={ values.legalEntity && values.legalEntity?.name }
              onChange={ onAutocompleteChange() }
              name={ `${employee}.legal_entity_id` }
              nameOfAttr={ OBJECT_KEYS.name }
              addNew={ {
                handleNew: handleDialogSubmit,
                text: t("common.add_new_legalEntity"),
                prop: OBJECT_KEYS.legalEntity,
              } }
              loading={ isLoadingLegalEntities }
              disabled={ !editable }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteCreable
              id={ "country" }
              options={ countries }
              label={ t("common:common.country") }
              register={ register }
              value={ values.country && values.country?.name }
              disabled={ !editable }
              onChange={ onAutocompleteChange() }
              name={ `${employee}.job_position_attributes.country_id` }
              nameOfAttr={ OBJECT_KEYS.name }
              isRequired
              customStyles={ pulse.country ? classes.pulse : "" }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteCreable
              id={ OBJECT_KEYS.city }
              options={ cities }
              label={ t("common.city") }
              register={ register }
              value={ values.city && values.city?.name }
              onChange={ onAutocompleteChange() }
              name={ `${employee}.job_position_attributes.city_id` }
              nameOfAttr={ OBJECT_KEYS.name }
              addNew={ {
                handleNew: handleDialogSubmit,
                text: t("common.add_new_city"),
                prop: OBJECT_KEYS.city,
              } }
              disabled={ !editable || isUndefined(countrySelected) }
              customStyles={ pulse.city ? classes.pulse : "" }
            />
          </Grid>
          {!isEqual(employee, ROLES.CANDIDATE) && (
            <Grid item xs={ 12 } md={ 6 }>
              <InputTextController
                type={ "email" }
                label={ t("common:common.email") }
                control={ control }
                name={ `${employee}.email` }
                required
                disabled={ !editable || showMovementDate || showSalary }
                rules={ { required: true } }
              />
            </Grid>
          )}
          {isNotCandidate(from, user?.userCookies) && (
            <Grid xs={ 12 } item md={ 6 }>
              <InputTag
                label={ t("common:common.roles.main") }
                size={ SIZE.small }
                itemsSelected={ inputTagsSelected }
                customStyle={ pulse.roles ? classes.pulse : "" }
                name={ `${employee}.user_roles` }
                register={ register }
                onChange={ handleTags }
                data={ ROLES_LIST }
                isDisabled={ !editable || isNull(rolesCollaborator) }
                onAddButtonField={ handleRolesModal }
                disableClearable
                forcePopupIcon={ false }
                isRequired
              />
            </Grid>
          )}
        </Grid>
        <StyledGrid container spacing={ 3 }>
          <DynamicAttributesData
            isEdit={ editable }
            attributesList={ reducerToUse?.one?.custom_attributes }
            dynamicFieldsList={ dynamicFieldsList }
            setDynamicFieldsList={ setDynamicFieldsList }
          />
        </StyledGrid>
        <Grid container spacing={ 3 }>
          {showMovementDate && (
            <>
              {/* verify this when services are ready */}
              <Grid item xs={ 12 } md={ 6 }>
                <DateInputController
                  id={ "date" }
                  label={ t("common:common.lateral_movement_starting_date") }
                  control={ control }
                  name={ `${employee}.date` }
                  openTo={ "year" }
                  value={ new Date() }
                  views={ ["year", "month", "day"] }
                  disabled={ !editable }
                  required={ showMovementDate || showSalary }
                  customStyles={ pulse.movementDate ? classes.pulse : "" }
                />
              </Grid>
            </>
          )}
          {showSalary && values.roles && (
            <SalaryPromotion
              employee={ employee }
              control={ control }
              register={ register }
              editable={ editable }
              classes={ classes }
              values={ values }
              isDisabled={ showSalary }
              validations={ pulse }
            />
          )}
          {editable && (
            <Grid container className={ classes.submitButtons }>
              <Grid item xs={ 12 }>
                <Box display={ "flex" } justifyContent={ "flex-end" }>
                  <Box>
                    <Button
                      onClick={ () => handleToggleEdit(false) }
                      variant={ VARIANT.contained }
                      typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                    >
                      {t("collaborators:buttons.cancel")}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant={ VARIANT.contained }
                      type={ "submit" }
                      isLoading={ reducerToUse?.isLoadingProcess }
                      typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    >
                      {t("collaborators:buttons.save_edit")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>

      {modalData && (
        <AlertModal
          title={ modalData.title }
          text={ modalData.text }
          textDisagree={ modalData.cancel }
          textAgree={ modalData.approved }
          onClick={ modalData.onClick }
          open={ modal }
          isLoading={ isLoadingProcessMovements || reducerToUse?.isLoadingProcess || isLoadingManagerList }
          handleClose={ openCloseModal }
          isLoader={ modalData.isLoader }
        >
          {modalData.children}
        </AlertModal>
      )}
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => handleDialog(false) }
        title={ `${t(`common.add_new_${newCreation}`)}: ${inputTextValue}` }
        message={ t("common.modal_messages.sure_text") }
        onSubmit={ handleNew }
        buttons={ {
          isLoading: isLoadingProcessMovements || reducerToUse?.isLoadingProcess,
        } }
      />

      <Modal
        isOpen={ isOpen }
        onClose={ handleCloseModal }
        title={ modalContent?.title }
      >
        <div>
          {modalContent?.children}
        </div>
      </Modal>
      { renderRoleModal }
    </div>
  );
};

Profile.propTypes = {
  locationState: PropTypes.object,
  user: PropTypes.object,
  from: PropTypes.string,
  classes: PropTypes.object,
  searchBy: PropTypes.string,
  employee: PropTypes.string,
  reducerToUse: PropTypes.object,
  selectedId: PropTypes.string,
};

export default Profile;
