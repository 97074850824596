import PropTypes from "prop-types";
import {
  INFO, SUCCESS, WARNING, ERROR,
} from "common/constants";
import AlertTitle from "@mui/material/AlertTitle";
import {
  StyledAlert,
  InfoIconStyled,
  CheckCircleIconStyled,
  WarningIconStyled,
  ErrorIconStyled,
} from "./styles";

const SimpleAlert = (props) => {
  const {
    type, message, title, onClose, customStyle, icon, children,
  } = props;

  const getIcon = (iconType) => {
    switch (iconType) {
    case INFO:
      return <InfoIconStyled />;
    case SUCCESS:
      return <CheckCircleIconStyled />;
    case WARNING:
      return <WarningIconStyled />;
    case ERROR:
      return <ErrorIconStyled />;
    default:
      break;
    }
    return null;
  };

  return (
    <StyledAlert
      data-testid={ "simplealert" }
      icon={ icon || getIcon(type) }
      severity={ type }
      onClose={ onClose }
      variant={ customStyle }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
      {children}
    </StyledAlert>
  );
};

SimpleAlert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  customStyle: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.element,
};

export default SimpleAlert;
