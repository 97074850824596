import { useQuery, useQueryClient } from "react-query";
import { getLegalEntities } from "redux/actions/common/legalEntityActions";

const fetchLegalEntities = async () => {
  const response = await getLegalEntities();
  return response;
};

export const useLegalEntity = () => {
  const queryClient = useQueryClient();

  const { data: legalEntities, isLoading, isError } = useQuery("legalEntities", fetchLegalEntities, {
    staleTime: Infinity,
  });

  return {
    legalEntities,
    isLoading,
    isError,
    queryClient,
  };
};
