import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import TooltipIcon from "components/TooltipIcon";
import { StyledErrorItem } from "components/UploadDocument/styles";
import { ALIGN_ITEMS } from "common/constants";

const TooltipIconTable = (props) => {
  const { data } = props;

  return (
    <Box data-testid={ "tooltip-icon-table-component" } display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
      <Box>
        <TooltipIcon
          element={ data?.errors?.hasError && <CancelIcon /> }
        >
          <StyledErrorItem>
            {data?.errors?.errorList.map((error) => <li key={ error }>{error}</li>)}
          </StyledErrorItem>
        </TooltipIcon>
      </Box>
      <Box pl={ 2 }>{data?.row_number}</Box>
    </Box>
  );
};

TooltipIconTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TooltipIconTable;
