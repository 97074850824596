import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  chartContainer: {
    width: "100%",
    height: "auto",
  },
  cardHeader: {
    paddingBottom: 0,
    paddingLeft: 20,
  },
}));

export default useStyles;
