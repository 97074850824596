import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "components/Select";
import ProfileImageDetailSimple from "components/ProfileImageDetailSimple";
import { OBJECT_KEYS } from "common/constants";
import { StyledGridHeader, StyledTitleContent, StyledTitle } from "./styles";

const Header = (props) => {
  const {
    evaluation, evaluations, collaborator, handleChange, isMobile, showProfile,
  } = props;
  const { t } = useTranslation("performance");

  const renderTitle = () => (
    <StyledTitle>
      {t(showProfile
        ? "leadership:good_leader_score"
        : "common:sidebar:leadership")}
    </StyledTitle>
  );

  return (
    <>
      <StyledGridHeader container data-testid={ "good-leader-header" }>
        <Grid item xs={ 12 } md={ 9 }>
          { (collaborator && (!isMobile || showProfile)) && (
            <StyledTitleContent data-testid={ "good-leader-profile" }>
              <Box>
                <ProfileImageDetailSimple
                  collaborator={ collaborator }
                  width={ 140 }
                  height={ 140 }
                />
              </Box>
            </StyledTitleContent>
          )}
        </Grid>
        {isMobile && renderTitle()}
        <Grid item xs={ 12 } md={ 3 }>
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("performance:dashboard.filter_evaluation") }
            menuItems={ evaluations }
            value={ evaluation }
            onChange={ handleChange }
            disabled={ evaluations?.length === 0 }
          />
        </Grid>
      </StyledGridHeader>
      {!isMobile && showProfile && renderTitle()}
    </>
  );
};

Header.propTypes = {
  evaluation: PropTypes.number.isRequired,
  evaluations: PropTypes.array.isRequired,
  collaborator: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showProfile: PropTypes.bool.isRequired,
};

export default Header;
