import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import Box from "@mui/material/Box";
import {
  ALIGN_ITEMS, COLORS_NAME, EVENT_STATE_KEYS, TARGET, VARIANT,
} from "common/constants";
import { formatDate } from "common/utils";
import { STATE_TYPES } from "views/Planning/components/ProcessDetail/functions";
import {
  StyledCard, StyledState, StyledLink, StyledLinkEvaluation,
} from "./styles";

const EventCard = (props) => {
  const { t, data } = props;

  const renderEventDetail = () => {
    if ((data.processDetail?.status === EVENT_STATE_KEYS.future)) {
      return (
        <Typography variant={ VARIANT.h6 } color={ COLORS_NAME.primary }>
          {t(`planning:processDetail.status.${data.processDetail?.status}`, { date: formatDate(data?.startDate) })}
        </Typography>
      );
    }
    const followUpData = data.processDetail?.follow_up_data;
    const followUpLink = followUpData?.link;
    const followUpIsActive = followUpData?.is_active;
    const teamLink = followUpData?.team;
    const link = `/cover?process_id=${data.id}`;
    return (
      <div>
        {followUpLink && followUpIsActive && (
          <Box display={ "flex" } justifyContent={ "space-between" } alignItems={ "center" }>
            <Box display={ "flex" } alignItems={ "center" } flexGrow={ 1 } mr={ 2 }>
              <Typography>{`${t("planning:processDetail.title")}:`}</Typography>
              <StyledState>
                {STATE_TYPES[data.processDetail?.status]?.icon}
                <Typography variant={ VARIANT.h6 }>
                  {t(`planning:processDetail.status.${data.processDetail?.status}`)}
                </Typography>
              </StyledState>
            </Box>
            <StyledLink href={ followUpLink } target={ TARGET.self }>
              <StyledLinkEvaluation>
                {t("common:answer")}
                <LaunchOutlined />
              </StyledLinkEvaluation>
            </StyledLink>
          </Box>
        )}
        {followUpData && !followUpIsActive && teamLink?.length === 0 && (
          <Typography variant={ VARIANT.h6 }>
            {`${formatDate(followUpData.date)} - ${formatDate(followUpData.finishes_at)}`}
          </Typography>
        )}
        {teamLink?.length > 0
         && (
           <Box display={ "flex" } alignItems={ "center" } flexGrow={ 1 } mb={ 0.5 }>
             <Typography>{`${t("planning:processDetail.title")}:`}</Typography>
             <StyledState>
               {STATE_TYPES[data.processDetail?.status]?.icon}
               <Typography variant={ VARIANT.h6 }>
                 {t(`planning:processDetail.status.${data.processDetail?.status}`)}
               </Typography>
             </StyledState>
           </Box>
         )}
        {teamLink && teamLink.map((team) => (
          <Box key={ team.id } display={ "flex" } justifyContent={ "space-between" } alignItems={ "center" }>
            <Box display={ "flex" } alignItems={ "center" } flexGrow={ 1 } mr={ 2 } mb={ 1 }>
              <StyledState>
                <Typography variant={ VARIANT.h6 }>{team.full_name}</Typography>
              </StyledState>
            </Box>
            <StyledLink href={ team?.link } target={ TARGET.self }>
              <StyledLinkEvaluation>
                {t("common:answer")}
                <LaunchOutlined />
              </StyledLinkEvaluation>
            </StyledLink>
          </Box>
        ))}
        {!followUpLink && !teamLink && (
          <Box display={ "flex" } justifyContent={ "space-between" }>
            <Box display={ "flex" } alignItems={ "center" }>
              <Typography>{`${t("planning:processDetail.title")}:`}</Typography>
              <StyledState>
                {STATE_TYPES[data.processDetail?.status]?.icon}
                <Typography variant={ VARIANT.h6 }>
                  {t(`planning:processDetail.status.${data.processDetail?.status}`)}
                </Typography>
              </StyledState>
            </Box>
            <StyledLink href={ link } target={ TARGET.self }>
              {t("common:common.viewMore")}
            </StyledLink>
          </Box>
        )}
      </div>
    );
  };

  return (
    <StyledCard>
      <CardContent>
        <Box mb={ 1 } display={ ALIGN_ITEMS.flex }>
          <img src={ data.processType?.icon } alt={ "icon" } />
          <Box ml={ 2 }>
            <Typography variant={ VARIANT.h5 }>{data.name}</Typography>
          </Box>
        </Box>
        {renderEventDetail()}
      </CardContent>
    </StyledCard>
  );
};

EventCard.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EventCard;
