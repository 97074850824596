import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 440,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableCell: {
    padding: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableCellAvatar: {
    width: 55,
  },
  tableCellCheckbox: {
    padding: 0,
    width: 10,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableCellHeader: {
    padding: 0,
  },
  tableCellNoInfo: {
    borderBottom: "none",
  },
  fadeIn: {
    animation: "fadeIn ease 2s",
  },
}));
