import styled from "styled-components";
import palette from "theme/palette";
import Grid from "@mui/material/Grid";

export const StyledBoxTitle = styled.text`
  font-size: 2px;
  font-weight: 700;
`;

export const StyledRectBox = styled.rect`
  stroke: ${ palette.white };
  stroke-width: 2;
  cursor: pointer;
  fill: ${ (props) => (props.isActive ? palette.background.purpleBox : palette.background.grayBox) };
`;

export const StyledGridContainer = styled(Grid)`
  position: relative;
`;

export const StyledSvgContainer = styled.svg`
  width: 100%;
  outline: none;
`;

export const StyledLine = styled.line`
  stroke: ${ palette.white };
`;
