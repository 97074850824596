import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const StyledGridContainer = styled(Grid)`
  .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6 {
    font-weight: 700;
  }
`;

export const StyledSubtitle = styled(Typography)`
  text-align: left;
  font-size: 20px;
  line-height: 25px;
`;

export const StyledAttritionRiskTable = styled.div`
  margin-top: 20px;
`;
