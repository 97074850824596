import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import Box from "@mui/material/Box";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components/Filter";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { StyledContainer } from "./styles";

const Header = () => {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(charts.breakpoints.small),
  );

  return (
    <StyledContainer data-testid={ "organization-chart-view" }>
      <ViewHeaderTitle title={ t("common.organization_chart") } />
      <Box pt={ 1 } pb={ isMobile ? 0 : 1 } pr={ isMobile ? 8 : 0 }>
        <Filter
          isLabel
          type={ FILTER_ACTIONS_TYPES.orgChart }
          externalParams={ [false] }
          isButton={ isMobile }
          className={ isMobile && "mt-3" }
        />
      </Box>
    </StyledContainer>
  );
};

export default Header;
