import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ALIGN_ITEMS } from "common/constants";
import { StyledLink } from "./styles";

const SeeMoreLink = (props) => {
  const { t, onClick, align } = props;
  return (
    <Grid container>
      <Grid item xs={ 12 }>
        <StyledLink onClick={ onClick } align={ align }>
          {t("common:common.viewMore")}
        </StyledLink>
      </Grid>
    </Grid>
  );
};

SeeMoreLink.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  align: PropTypes.string,
};

SeeMoreLink.defaultProps = {
  align: ALIGN_ITEMS.right,
};

export default SeeMoreLink;
