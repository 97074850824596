import { STATE_COLORS } from "theme/palette";
import { STATE } from "common/constants";
import {
  ClearIconStyled,
  ContactSupportIconStyled,
  DoneIconStyled,
  FeedbackIconStyled,
  InfoIconStyled,
  SendIconStyled,
} from "../styles";

export const getIcon = (state) => {
  switch (state) {
  case STATE.SELECTED:
    return <SendIconStyled />;
  case STATE.DATA_FILLED:
    return <FeedbackIconStyled />;
  case STATE.DATA_REQUIRED:
    return <InfoIconStyled />;
  case STATE.HIRED:
    return <DoneIconStyled />;
  case STATE.DESISTED:
    return <ClearIconStyled />;
  default:
    return <ContactSupportIconStyled />;
  }
};

export const getColor = (state) => {
  switch (state) {
  case STATE.SELECTED:
    return STATE_COLORS.SUCCESS;
  case STATE.DATA_FILLED:
    return STATE_COLORS.INFO;
  case STATE.DATA_REQUIRED:
    return STATE_COLORS.INFO;
  case STATE.HIRED:
    return STATE_COLORS.GREY;
  case STATE.DESISTED:
    return STATE_COLORS.ERROR;
  default:
    return STATE_COLORS.INFO;
  }
};

export const createModal = (title, text, approved, cancel, onClick, children) => ({
  title, text, approved, cancel, onClick, children,
});
