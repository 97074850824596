import {
  useEffect, useState, useContext, useRef, useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  isNull, isEmpty, isNumber, isObject, isBoolean, isEqual,
} from "common/helpers";
import merge from "lodash/merge";
import {
  MESSAGE_TYPES,
  toast,
  handleMessages,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import Modal from "components/Modal";
import { SessionContext } from "modules/session/context";
import {
  getUploadStatus, resetStateProcess,
  resetState, getList as getSurveyProcesses,
} from "redux/actions/surveyProcessesActions";
import { resetState as resetStateSlack, getListSlackPaginated } from "redux/actions/integrations/slackActions";
import QueryString from "query-string";
import {
  OBJECT_KEYS,
  LOCAL_STORAGE_NAMES,
  ERROR, METHODS, PAGINATION, BULK_UPLOAD, TIMEOUT_NOTIFICATIONS,
  INDEX, SUCCESS, TRUE,
} from "common/constants";
import { getResultByEmployeeToDownload } from "common/validators";
import {
  handleAlertAutohide,
  onCloseAlertAutohide,
  historyPush,
  getItemFromLocalStorage,
  getObjectStructure,
  getAllExceptSelected,
  mainDownloadExcel,
} from "common/utils";
import AlertAutohide from "components/AlertAutohide";
import AlertModal from "components/AlertModal";
import usePagination from "hooks/utils/usePagination";
import {
  getTableHeaderByModule, getDispatch, getReducerToUse, getFinalObject, postDispatch, putDispatch,
  deleteDispatch, pushChildren, getModuleData, getHeaderModule, getAllExceptDeleted, getListUpdated,
  UNITS, sendEmailDispatch, getSubmitDataObject, getDefaultValues,
  removeOrGetFromLocalStorage, getAsyncDispatch, getModalMoveTitle, downloadExcel, loadDataDispatch,
  updateDispatch, enableDispatch,
} from "../../functions";
import { buildNewOptionArray, getUpdatedList } from "../../functions/units";
import MainView from "../../components/MainView";
import InputRow from "../../components/InputRow";
import MainCustomView from "../../components/MainCustomView";
import MoveUnits from "../../components/units/MoveUnits";
import { useStyles } from "../../styles";

const MainModule = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["administrator", "common"]);
  const history = useHistory();
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [temporalList, setTemporalList] = useState(null);
  const temporalListRef = useRef();
  temporalListRef.current = temporalList;
  const [isLoading, setIsLoading] = useState(false);
  const [newIdElement, setNewIdElement] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [editableRow, setEditableRow] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [alertAutohide, setAlertAutohide] = useState({
    open: false,
    type: ERROR,
    message: "",
    title: "",
  });
  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  /* No refactor fixing relocate bug */
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleModalContent = (content) => {
    setModalContent(content);
  };
  /* END */

  const {
    state: { user },
  } = useContext(SessionContext);
  const companyId = user?.company?.id || null;

  const thisModule = history?.location?.pathname.split("/")[2] || props.location?.pathname.split("/")[2];
  const locationSearch = history?.location?.search || props.location?.search;
  const params = QueryString.parse(locationSearch);
  const searchBy = Object.keys(params)[INDEX.zero];
  const searchValue = params[searchBy];

  const dispatch = useDispatch();
  const reducerToUse = getReducerToUse(thisModule, useSelector, companyId, t);

  const openCloseModal = (isOpen) => {
    setModal(isBoolean(isOpen) ? isOpen : !isOpen);
  };

  const createModal = (title, text, approved, cancel, onClick, children, isLoader) => ({
    title, text, approved, cancel, onClick, children, isLoader,
  });

  const viewModal = (title, children, text = "", approved = "", cancel = "", onClick, isLoader) => {
    setModalData(createModal(title, text, approved, cancel, onClick, children, isLoader));
    openCloseModal(true);
  };

  const resetStates = () => {
    setModalData(false);
    setModal(false);
    setIsLoading(false);
    setNewIdElement(null);
    setIsButtonDisabled(false);
  };

  useEffect(() => {
    if (!isNull(companyId)) {
      const query = {
        q: {
          name_cont: searchFilter,
        },
        page: { size: PAGINATION.maxPerPage, number: pagination },
      };
      getDispatch(thisModule, dispatch, query);
      setTemporalList(null);
    }
  }, [thisModule, dispatch, companyId, searchFilter, pagination]);

  const { handleSubmit, control } = useForm();

  const setOnSubmit = (objData, method, editableRow) => {
    onSubmit(
      thisModule,
      getSubmitDataObject(thisModule, objData, editableRow, selectedParentId),
      method,
      editableRow?.id,
    );
  };

  const handleNewElement = (data, parentId) => {
    setNewIdElement(null);
    setEditableRow();
    (isNumber(parentId) || isNull(parentId)) && setSelectedParentId(parentId);

    isObject(data) ? setOnSubmit(data, METHODS.post) : setIsButtonDisabled(!isButtonDisabled);
  };

  const handleNewElementNoChild = (data, parentId, id, isOpen) => {
    setEditableRow();
    const currentParentId = !isOpen ? null : id;
    setSelectedParentId(currentParentId);
    setNewIdElement(currentParentId);

    isObject(data) ? setOnSubmit(data, METHODS.post) : setIsButtonDisabled(!isButtonDisabled);
  };

  const handleDelete = async (module, id, alternativeId) => {
    const deletedElement = await deleteDispatch(dispatch, module, id, companyId, alternativeId);
    if (deletedElement?.message) {
      handleAlertAutohide(deletedElement.message, setAlertAutohide, t);
      setIsLoading(false);
      setNewIdElement(null);
      setModal(false);
    } else {
      // temporal list could be null (first time adding a element)
      const temporalListAux = isNull(temporalListRef.current) ? reducerToUse?.data : temporalListRef.current;

      const listToUse = getAllExceptDeleted(temporalListAux, id);
      setTemporalList(listToUse);
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      removeOrGetFromLocalStorage(module, true);
      setIsOpen(false);
      resetStates();
    }
  };

  const onSubmitEdit = (editableRow) => {
    setOnSubmit(control.getValues(), METHODS.put, editableRow);
  };

  const onSubmitValidation = () => (isNull(newIdElement) ? handleNewElement : handleNewElementNoChild);

  const handleCancel = () => {
    setIsButtonDisabled(false);
    setSelectedParentId(null);
    setNewIdElement(null);
    setEditableRow();
  };

  const getEditableRow = (editableRow) => (
    <form autoComplete={ "off" } onSubmit={ handleSubmit(() => onSubmitEdit(editableRow)) }>
      <InputRow
        module={ thisModule }
        parentId={ null }
        control={ control }
        classes={ classes }
        onCancel={ handleCancel }
        isLoading={ isLoading }
        isDisabled={ isLoading }
        defaultValues={ getDefaultValues(thisModule, editableRow) }
        onAdd={ handleSubmit(() => onSubmitEdit(editableRow)) }
      />
    </form>
  );

  const handleEdit = (module, rowData) => {
    setNewIdElement(null);
    setIsLoading(false);
    setIsButtonDisabled(false);
    setEditableRow({
      rowData,
      action: { getEditableRow },
    });
  };

  const handleMove = async (module, data, moduleKey) => {
    const movedItemId = data[moduleKey].id;
    const movedItemParentId = data[moduleKey].parent_id;

    const movedElement = await putDispatch(dispatch, module, data[moduleKey].id, data, companyId);
    if (!isEmpty(movedElement)) {
      //  error handle
      if (movedElement.error) {
        handleAlertAutohide(movedElement.message, setAlertAutohide, t);
      } else {
        //  temporal list could be null (first time adding a element)
        const temporalListAux = isNull(temporalListRef.current) ? reducerToUse?.data : temporalListRef.current;
        const listDeletedItem = getAllExceptDeleted(temporalListAux, movedItemId);

        //  finding the "parent" object to push the child
        const listToPush = getFinalObject(listDeletedItem, movedItemParentId);

        pushChildren(listToPush, movedElement, movedItemParentId);
        setNewIdElement(null);
        setTemporalList(listDeletedItem);
        removeOrGetFromLocalStorage(module, true);

        let messageType;
        let toastMessage;

        if (movedElement?.employees_count) {
          messageType = MESSAGE_TYPES.info;
          toastMessage = t("administrator:modules.units.alert_message");
        } else {
          messageType = MESSAGE_TYPES.success;
        }

        toast(messageType, handleMessages(messageType, HTTP_STATUS_RESPONSE.ok, t, toastMessage));
      }
      resetStates();
    }
  };

  const handleMoveMassive = async (module, data, id) => {
    const deletedElement = await deleteDispatch(dispatch, module, id, companyId, data, true);
    if (deletedElement?.message) {
      handleAlertAutohide(deletedElement.message, setAlertAutohide, t);
      setIsLoading(false);
      setNewIdElement(null);
      setIsButtonDisabled(!isButtonDisabled);
    } else {
      const temporalListAux = isNull(temporalListRef.current) ? reducerToUse?.data : temporalListRef.current;

      const listToUse = getAllExceptDeleted(temporalListAux, id); // NOTE: Here I have the list with the item deleted
      const updatedListToUse = getUpdatedList(listToUse, data); // NOTE: Here I have the updated list (# collaborators)

      setTemporalList(updatedListToUse);
      removeOrGetFromLocalStorage(module, true);
      setIsOpen(false);
      resetStates();
    }
  };

  const handleMoveModal = async (module, rowData, t) => {
    const elements = getItemFromLocalStorage(LOCAL_STORAGE_NAMES[removeOrGetFromLocalStorage(module)]);

    let allDataSelected;

    if (isEmpty(elements)) {
      allDataSelected = await getAsyncDispatch(dispatch, module);
    } else {
      allDataSelected = elements;
    }

    const elementsAvailable = module === UNITS ? buildNewOptionArray(allDataSelected, rowData.id) : getAllExceptSelected(allDataSelected, rowData.id);

    if (allDataSelected) {
      viewModal(
        getModalMoveTitle(module, t),
        <MoveUnits module={ module } data={ rowData } onSubmit={ module === UNITS ? handleMove : handleDelete } options={ elementsAvailable } />,
      );
    }
  };

  const getNewCustomRow = (module, parentId) => (
    selectedParentId === parentId && (
      <form autoComplete={ "off" } onSubmit={ handleSubmit(onSubmitValidation()) }>
        <InputRow
          module={ module }
          parentId={ selectedParentId }
          control={ control }
          classes={ classes }
          onCancel={ handleCancel }
          isLoading={ isLoading }
          isDisabled={ isLoading }
          onAdd={ handleSubmit(onSubmitValidation()) }
          isNewId
        />
      </form>
    )
  );

  const onSubmit = async (module, data, method, editableId) => {
    // await dispatch for new a element
    const element = method === METHODS.post
      ? await postDispatch(dispatch, module, getObjectStructure(module, data), companyId)
      : await putDispatch(dispatch, module, editableId, getObjectStructure(module, data), companyId);
    // validates if the element was created (just a preventive case)
    if (!isEmpty(element)) {
      // error handle
      if (element.error) {
        handleAlertAutohide(element.message, setAlertAutohide, t);
      } else {
        // temporal list could be null (first time adding a element)
        const temporalListAux = isNull(temporalListRef.current) ? reducerToUse?.data : temporalListRef.current;

        if (method === METHODS.post) {
          // finding the "parent" object to push the child
          const listToUse = isNull(temporalListAux) ? [] : getFinalObject(temporalListAux, selectedParentId);

          if (module === UNITS) {
            element.children = []; // need it 'cause backend doesn't return it!
          }

          // listAux: the "new" element pushed
          const listAux = pushChildren(listToUse, element, selectedParentId);

          setTemporalList(isNull(temporalListAux) ? [element] : merge(temporalListAux, listAux));
        } else {
          const listToUse = getListUpdated(temporalListAux, element);

          setEditableRow();
          setTemporalList(listToUse);
        }
        removeOrGetFromLocalStorage(module, true);
        toast(
          MESSAGE_TYPES.success,
          handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
        );
      }
      resetStates();
    }
  };

  const handlePathByModule = (module, id, searchBy, actionPath) => {
    const moduleData = getModuleData(module);
    historyPush(history, `${moduleData.path}/${actionPath}`, `?${searchBy}=${id}`);
  };

  // TODO: You have to do a refactor after all these files
  const { successProcess: uploadStatus, loadingProcess: isLoadingProcess } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );

  // success message
  const viewMessageAndResetData = useCallback((data) => {
    dispatch(resetStateProcess());
    if (isEqual(data?.aasm_state, BULK_UPLOAD?.state.failed)) {
      const toastMessage = {
        title: t("common:common.api_responses.error.title"),
        message: data?.response,
      };
      toast(MESSAGE_TYPES.error, toastMessage);
    } else {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t, t("common:common.api_responses.success.submitted")),
      );
    }
    resetStates();
  }, [t, dispatch]);

  useEffect(() => {
    if (!isLoadingProcess && uploadStatus && uploadStatus?.aasm_state) {
      if (isFirstLoad && !isEqual(uploadStatus?.aasm_state, BULK_UPLOAD?.state.failed)) {
        toast(
          MESSAGE_TYPES.info,
          handleMessages(MESSAGE_TYPES.info, HTTP_STATUS_RESPONSE.ok, t, t("common:common.api_responses.info.notify_shipped")),
        );
        setModalData(false);
        setModal(false);
        setIsLoading(false);
        setIsFirstLoad(false);
      }
      if (isEqual(uploadStatus?.aasm_state, BULK_UPLOAD?.state.pending)) {
        setTimeout(() => {
          dispatch(getUploadStatus(uploadStatus?.id));
        }, TIMEOUT_NOTIFICATIONS);
      } else {
        viewMessageAndResetData(uploadStatus);
        setIsFirstLoad(true);
      }
    }
    // eslint-disable-next-line
  }, [uploadStatus, viewMessageAndResetData, t, dispatch]);

  const handleSendEmail = (module, id, isReminder) => {
    sendEmailDispatch(
      module,
      dispatch,
      id,
      isReminder,
    );
  };

  const handleEnableData = async (module, id, processType) => {
    const data = await enableDispatch(module, dispatch, id, processType);
    if (data) {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      resetStates();
      dispatch(resetState());
      dispatch(getSurveyProcesses());
    }
  };

  const handleLoadData = async (module, id) => {
    const data = await loadDataDispatch(module, dispatch, id);
    if (isEqual(data, HTTP_STATUS_RESPONSE.created)) {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
    }
  };

  const handleDownloadExcel = async (module, id) => {
    const message = t("common:common.download_message");
    toast(
      MESSAGE_TYPES.info,
      handleMessages(MESSAGE_TYPES.info, HTTP_STATUS_RESPONSE.ok, t, message),
    );
    const dataToDownload = await downloadExcel(
      module,
      dispatch,
      id,
    );
    const data = getResultByEmployeeToDownload(dataToDownload, t);
    const filename = t("common:common.participation");
    mainDownloadExcel(data, filename, true);
  };

  const handleSearch = (name = "") => {
    setSearchFilter(name);
    setPagination(PAGINATION.next);
  };

  const handleUpdate = async (module) => {
    const data = await updateDispatch(module, dispatch);
    dispatch(resetStateSlack());
    if (data?.message) {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t, data.message),
      );
      dispatch(getListSlackPaginated());
    }
    resetStates();
  };

  useEffect(() => {
    if (isEqual(searchBy, OBJECT_KEYS.slackConfig)) {
      // remove url params
      history.push({ search: "?" });

      const slackState = isEqual(searchValue, TRUE);

      const messageType = MESSAGE_TYPES[slackState ? SUCCESS : ERROR];
      const toastMessage = t(`administrator:modules.integrations.slack.alert.${slackState ? SUCCESS : ERROR}.config`);
      toast(messageType, handleMessages(messageType, HTTP_STATUS_RESPONSE.ok, t, toastMessage));
    }
    // eslint-disable-next-line
  }, [searchValue, searchBy, t]);

  return (
    <div className={ thisModule === "positions" ? null : classes.root } data-testid={ "general-administrator-main" } >
      {getModuleData(thisModule)?.simple ? (
        <MainCustomView
          moduleName={ thisModule }
          header={ getHeaderModule(t, thisModule) }
          handleSearch={ handleSearch }
          table={ {
            header: getTableHeaderByModule(dispatch, t, thisModule, {
              handlePathByModule,
              handleSendEmail,
              handleDownloadExcel,
              handleLoadData,
              handleUpdate,
              viewModal,
              handleEnableData,
            }),
            data: reducerToUse.data,
            search: OBJECT_KEYS.name,
            isLoading: reducerToUse.isLoading,
            handlePagination,
            pagination,
            total: reducerToUse?.total,
          } }
          classes={ classes }
        />
      ) : (
        <MainView
          moduleName={ thisModule }
          header={ thisModule === "positions" ? null : getHeaderModule(t, thisModule) }
          handleSearch={ handleSearch }
          table={ {
            header: getTableHeaderByModule(
              dispatch,
              t,
              thisModule,
              {
                viewModal,
                handleEdit,
                handleMoveModal,
                handleDelete,
                handleMoveMassive,
                openCloseModal,
                handleModalContent,
                handleUpdate,
                setIsOpen,
              },
              classes,
              {
                companyId,
              },
            ),
            data: isNull(temporalListRef.current) ? reducerToUse?.data : temporalListRef.current,
            search: OBJECT_KEYS.name,
            isLoading: reducerToUse?.isLoading,
            handlePagination,
            pagination,
            total: reducerToUse?.total,
          } }
          actions={ {
            module: thisModule,
            addNew: handleNewElement,
            addNewNoChild: handleNewElementNoChild,
            getNewCustomRow,
            isDisabled: isButtonDisabled,
            parentId: selectedParentId,
            newIdElement,
            editableRow,
          } }
          classes={ classes }
        />
      )}
      <AlertAutohide
        open={ alertAutohide.open }
        onClose={ onCloseAlertAutohide(setAlertAutohide) }
        message={ alertAutohide.message }
        title={ alertAutohide.title }
        type={ alertAutohide.type }
      />
      {modalData && (
        <AlertModal
          title={ modalData.title }
          text={ modalData.text }
          textDisagree={ modalData.cancel }
          textAgree={ modalData.approved }
          onClick={ modalData.onClick }
          open={ modal }
          isLoading={ reducerToUse?.isLoading || isLoading || isLoadingProcess }
          handleClose={ openCloseModal }
          isLoader={ modalData.isLoader }
        >
          {modalData.children}
        </AlertModal>
      )}

      <Modal
        isOpen={ isOpen }
        onClose={ handleCloseModal }
        title={ modalContent?.title }
      >
        <div>
          {modalContent?.children}
        </div>
      </Modal>
    </div>
  );
};

export default MainModule;
