import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "components/Modal";
import InputForm from "components/InputForm";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleAlert from "components/SimpleAlert";
import {
  DATE_PARTS,
  DEFAULT_MIN_DECIMAL,
  INDEX,
  INPUT_RULES,
  INPUT_TYPE,
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
  SKELETONS_NUMBER,
  VARIANT,
  WARNING,
} from "common/constants";
import { getErrorMessage } from "common/formValidators";
import { isEqual, isNull } from "common/helpers";
import { getFormattedItems, replacePath } from "common/utils";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import moneyImage from "assets/images/profile/money.svg";
import localRoutes from "../../../../functions/routes";
import { StyledCardContainer, StyledInputForm, StyledGrid } from "./styles";

const SalaryModal = (props) => {
  const {
    t,
    history,
    collaborator,
    isAdmin,
    salaryInfoStates,
    hookSalaryForm,
    modalButtons,
    onSalarySubmit,
    salaryErrors,
    process,
  } = props;

  const {
    isModalOpen,
    optionsList,
    isAdminOrManager,
    contractEmploymentRelationship,
    projectedCtc,
    exchange,
  } = salaryInfoStates;
  const { control } = hookSalaryForm;

  const projection = (
    <StyledCardContainer>
      <Grid container>
        <Grid item xs={ 12 } sm={ 8 }>
          <Typography>
            {t("account:accountDetails.payrollProjection.local")}
          </Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 4 }>
          <Typography variant={ VARIANT.h6 }>
            <NumberFormat
              value={ projectedCtc }
              displayType={ INPUT_TYPE.text }
              thousandSeparator
              prefix={ "$" }
              fixedDecimalScale
              decimalScale={ DEFAULT_MIN_DECIMAL }
            />
          </Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 8 }>
          <Typography>
            {t("account:accountDetails.payrollProjection.usd")}
          </Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 4 }>
          <Typography variant={ VARIANT.h6 }>
            <NumberFormat
              value={ exchange }
              displayType={ INPUT_TYPE.text }
              thousandSeparator
              prefix={ "$" }
              fixedDecimalScale
              decimalScale={ DEFAULT_MIN_DECIMAL }
            />
          </Typography>
        </Grid>
      </Grid>
      <img src={ moneyImage } alt={ "Money" } />
    </StyledCardContainer>
  );

  const initialRender = isNull(collaborator)
    ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
    : (
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } md={ 6 }>
          <StyledInputForm
            type={ INPUT_TYPE.date }
            label={ t("common:common.startingDate") }
            control={ control }
            name={ "collaborator.starting_date" } // TODO: do it for candidate as well
            openTo={ DATE_PARTS.year }
            views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
          />
        </Grid>
        <StyledGrid item xs={ 12 } md={ 6 }>
          <InputForm
            type={ INPUT_TYPE.select }
            id={ OBJECT_KEYS.typeOfContract }
            label={ t("common:common.typeOfContract") }
            control={ control }
            name={ "collaborator.job_position_attributes.type_of_contract_id" } // TODO: do it for candidate as well
            menuItems={ getFormattedItems(LOCAL_STORAGE_NAMES.typeOfContract) }
          />
        </StyledGrid>
        <Grid item xs={ 12 } md={ 6 }>
          <InputForm
            type={ INPUT_TYPE.currency }
            id={ OBJECT_KEYS.salary }
            label={ t("common:common.salary") }
            control={ control }
            name={ "collaborator.salary_attributes.gross_salary" } // TODO: do it for candidate as well
          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <InputForm
            type={ INPUT_TYPE.autocomplete }
            id={ OBJECT_KEYS.currency }
            options={ optionsList.currencies }
            label={ t("common:common.currency.main") }
            control={ control }
            name={ "collaborator.salary_attributes.currency" } // TODO: do it for candidate as well
            nameOfAttr={ OBJECT_KEYS.nameWithCode }
            rules={ INPUT_RULES.required }
            error={ getErrorMessage(salaryErrors, "collaborator.salary_attributes.currency_id", "currency") }
            isRequired
          />
        </Grid>
        {isAdminOrManager && (
          <>
            <Grid item xs={ 12 } md={ 6 }>
              { projection }
            </Grid>
            {isNull(contractEmploymentRelationship) && (
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleAlert
                  type={ WARNING }
                  message={ isAdmin ? (
                    <Link to={ "/administrator/contract-types" }>
                      {t("account:accountDetails.contract_relationship_message")}
                    </Link>
                  ) : t("account:accountDetails.contract_relationship_message") }
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    );

  return (
    <Modal
      isOpen={ isModalOpen.salaryInfo }
      onClose={ () => replacePath(history, localRoutes.root) }
      title={ t("profile:editSalary") }
      onSubmit={ hookSalaryForm.handleSubmit(onSalarySubmit) }
      buttons={ modalButtons }
      isDisabled={ !isEqual(Object.entries(salaryErrors)?.length, INDEX.zero) }
      isLoading={ process.isLoadingProcess }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "780px" }>
        { initialRender }
      </StyledScrollBar>
    </Modal>
  );
};

SalaryModal.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  salaryInfoStates: PropTypes.object.isRequired,
  hookSalaryForm: PropTypes.object.isRequired,
  modalButtons: PropTypes.object.isRequired,
  onSalarySubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  salaryErrors: PropTypes.object,
  process: PropTypes.object,
};

SalaryModal.defaultProps = {
  isAdmin: false,
  salaryErrors: null,
  process: null,
};

export default SalaryModal;
