import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import includes from "lodash/includes";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SessionContext } from "modules/session/context";
import {
  IFRAME_URL_HR_ANALYTICS,
  COMPANY_SLUGS,
  BASE_URL_HR_ANALYTICS,
  ROLES,
  DIRECTION,
} from "common/constants";
import { getUserRoles } from "common/utils";
import useStyles from "./styles";

const HrAnalyticsBeta = () => {
  const { t } = useTranslation("HRAnalytics");
  const {
    state: { user },
  } = useContext(SessionContext);
  const classes = useStyles();

  const getIframeUrl = () => {
    let url = BASE_URL_HR_ANALYTICS;
    if (user && getUserRoles(user?.userCookies)) {
      switch (user.company.slug) {
      case COMPANY_SLUGS.blu:
        url += IFRAME_URL_HR_ANALYTICS.blu;
        break;
      case COMPANY_SLUGS.haulmer:
        url += IFRAME_URL_HR_ANALYTICS.haulmer;
        break;
      case COMPANY_SLUGS.denda:
        url += IFRAME_URL_HR_ANALYTICS.denda;
        break;
      case COMPANY_SLUGS.nala_demo:
        url += IFRAME_URL_HR_ANALYTICS.nala_demo;
        break;
      case COMPANY_SLUGS.tecno_diagnostica:
        url += IFRAME_URL_HR_ANALYTICS.tecno_diagnostica;
        break;
      case COMPANY_SLUGS.comunidad_feliz:
        url += IFRAME_URL_HR_ANALYTICS.comunidad_feliz;
        break;
      case COMPANY_SLUGS.dutrafer:
        url += IFRAME_URL_HR_ANALYTICS.dutrafer;
        break;
      case COMPANY_SLUGS.aprende:
        // FIXME: We need fix this with cancan in backend but while we need harcoded it!
        if (
          includes(getUserRoles(user?.userCookies), ROLES.ADMIN)
          || includes(getUserRoles(user?.userCookies), ROLES.ADMIN_NALA)
        ) {
          url += IFRAME_URL_HR_ANALYTICS.aprende;
          return url;
        }
        url += IFRAME_URL_HR_ANALYTICS.aprende_managers;
        break;
      case COMPANY_SLUGS.trebleAi:
        url += IFRAME_URL_HR_ANALYTICS.trebleAi;
        break;
      case COMPANY_SLUGS.dlds:
        url += IFRAME_URL_HR_ANALYTICS.dlds;
        break;
      case COMPANY_SLUGS.sugo:
        url += IFRAME_URL_HR_ANALYTICS.sugo;
        break;
      case COMPANY_SLUGS.loft:
        url += IFRAME_URL_HR_ANALYTICS.loft;
        break;
      case COMPANY_SLUGS.lahaus:
        if (
          includes(getUserRoles(user?.userCookies), ROLES.ADMIN)
          || includes(getUserRoles(user?.userCookies), ROLES.ADMIN_NALA)
        ) {
          url += IFRAME_URL_HR_ANALYTICS.lahaus;
        }
        break;
      case COMPANY_SLUGS.urbvan:
        url += IFRAME_URL_HR_ANALYTICS.urbvan;
        break;
      case COMPANY_SLUGS.omni:
        url += IFRAME_URL_HR_ANALYTICS.omni;
        break;
      case COMPANY_SLUGS.gsf:
        url += IFRAME_URL_HR_ANALYTICS.gsf;
        break;
      case COMPANY_SLUGS.hunty:
        url += IFRAME_URL_HR_ANALYTICS.hunty;
        break;
      case COMPANY_SLUGS.houm:
        url += IFRAME_URL_HR_ANALYTICS.houm;
        break;
      case COMPANY_SLUGS.tiba:
        url += IFRAME_URL_HR_ANALYTICS.tiba;
        break;
      case COMPANY_SLUGS.sumer:
        url += IFRAME_URL_HR_ANALYTICS.sumer;
        break;
      case COMPANY_SLUGS.movii:
        url += IFRAME_URL_HR_ANALYTICS.movii;
        break;
      default:
        break;
      }
      return url;
    }
  };

  return (
    <div className={ classes.root }>
      <Typography className={ classes.titleText }>{t("beta-title")}</Typography>
      <Grid
        container
        direction={ DIRECTION.row }
        justify={ "center" }
        alignItems={ "center" }
      >
        <Iframe
          url={ getIframeUrl() }
          width={ "100%" }
          className={ classes.imgIframe }
          allowFullScreen
        />
      </Grid>
    </div>
  );
};

export default HrAnalyticsBeta;
