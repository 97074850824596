import styled from "styled-components";
import StepLabel from "@mui/material/StepLabel";
import palette, { STATE_COLORS, STATE_STEPPER_COLOR } from "theme/palette";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 0 0 0 12px;
`;

export const StyledStepLabel = styled(StepLabel)`
  margin-left: -8px;
  .MuiStepIcon-root {
    width: 40px;
    height: 40px;

    .MuiStepIcon-text {
      font-size: 14px;
      font-weight: 400;
    }
  }
  &.Mui-disabled {
    .MuiStepIcon-root {
      color: ${STATE_COLORS.GREY};
    }
    svg {
      fill: ${STATE_COLORS.GREY};
    }
    .MuiStepLabel-label {
      .MuiSvgIcon-root {
        color: ${STATE_STEPPER_COLOR.pending};
      }
      .MuiTypography-root {
        color: ${STATE_STEPPER_COLOR.pending};
      }
    }
  }

  .MuiStepIcon-root.Mui-active {
    color: ${palette.primaryApp};
  }

  .Mui-active {
    svg {
      fill: ${palette.primaryApp};
    }
  }

  .MuiStepLabel-label.Mui-completed {
    .MuiGrid-root.MuiGrid-item {
      cursor: pointer;
    }
  }

  .MuiStepIcon-root.Mui-completed {
    color: ${palette.primaryApp};
    cursor: pointer;
  }
`;
