import { getPercent } from "common/utils";

export const getRows = (data, t) => data?.map((row, index) => ({
  id: index,
  position_name: row.position_name,
  organization_unit_name: row.organization_unit?.complete_path,
  active_collaborators_count: row.active_collaborators_count,
  is_key_position: row.is_key_position,
  key_position: row.key_position,
  active_successors_count: row.active_successors_count,
  position_id: row.position.id,
  organization_unit_id: row.organization_unit?.id,
}));

export const getLeadershipDataToDownload = (leadershipResult, t, language) => {
  const data = [];
  leadershipResult.forEach((leadership) => {
    const { employee } = leadership;
    const performanceResult = employee?.performance_results?.result;
    const riskCategory = employee?.attrition_chart?.risk_category;
    const leadershipScore = leadership?.leadership_score;
    const isValidToShowNps = leadershipScore?.nps_total_response >= 3;
    data.push({
      [t("collaborators:table.table-head.personal_id")]: employee?.personal_id,
      [t("collaborators:table.table-head.name")]: employee?.full_name,
      [t("collaborators:table.table-head.manager")]: employee?.manager_name,
      [t("collaborators:table.table-head.position")]: employee?.position_name,
      [t("collaborators:table.table-head.country")]: employee?.country_name,
      [t("collaborators:table.table-head.city")]: employee?.city_name,
      [t("tables:headers.good_leader")]: getPercent(leadership.x_axis_score, true),
      [t("tables:headers.leadership_score")]: getPercent(leadership.y_axis_score, true),
      [`${t("engagement:nps")} ${t("tables:headers.team")}`]: isValidToShowNps ? getPercent(leadershipScore.children_nps) : "",
      [`${t("tables:headers.performance")} ${t("tables:headers.team")}`]: leadershipScore ? getPercent(leadershipScore?.children_performance, true) : "",
      [`${t("nalaAnalytics:rotation")} ${t("tables:headers.team")}`]: leadershipScore ? getPercent(leadershipScore?.children_turnover, true) : "",
      [t("leadership:leadership_types_matrix")]: leadership.leadership_type[`name_${language}`],
      [t("tables:headers.performance")]: performanceResult || "",
      [t("tables:headers.lossRisk")]: riskCategory ? t(`talentDrain:${riskCategory}`) : "",
    });
  });

  return data;
};
