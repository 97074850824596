import styled from "styled-components";
import Card from "@mui/material/Card";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  padding: 0px 68px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 1280px) {
    padding: 0px 30px;
  }
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

export const StyledNumber = styled.div`
  font-size: 62px;
  color: ${palette.text.link};
  font-weight: bold;
  margin-right: 8px;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  box-shadow: ${palette.boxShadow.card3};
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
  .MuiAvatar-root {
    width: 50px;
    height: 50px;
  }
`;

export const StyledTotal = styled.div`
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  background: ${palette.background.light};
  color: ${palette.text.link};
  font-weight: bold;
`;

export const StyledCategory = styled.span`
  background: ${palette.background.light};
  margin-right: 6px;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 25px;
  display: inline-flex;
  margin-top: 5px;
  img {
    width: 16px;
    margin-right: 4px;
  }
`;
