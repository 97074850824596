import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const Container = styled.div`
  button {
    width: 100% !important;
    border: 1px solid ${palette.text.link};
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
`;

export const StyledGrid = styled(Grid)`
  margin-top: 15px;
  h6 {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  p {
    line-height: 15px;
  }
`;

export const StyledCurrentQuadrant = styled.div`
  margin-bottom: 10px;
`;
