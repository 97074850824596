import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import gt from "lodash/gt";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useStyles } from "./styles";

const TableToolbar = (props) => {
  const {
    tooltipTitle,
    tooltipIcon: TooltipIcon,
    numSelected,
    itemsSelected,
    onClick,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  const handleOnClick = () => {
    onClick(itemsSelected);
  };

  const selectedText = `${numSelected} ${
    numSelected > 1 ? t("selected_plural") : t("selected")
  }`;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: gt(numSelected, 0),
      })}
      data-testid={"table-checkbox-toolbar"}
    >
      {numSelected > 0 && (
        <>
          <Typography className={classes.title}>{selectedText}</Typography>
          <Tooltip title={tooltipTitle}>
            <IconButton aria-label={tooltipTitle} onClick={handleOnClick}>
              {<TooltipIcon />}
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  tooltipTitle: PropTypes.string,
  tooltipIcon: PropTypes.any,
  numSelected: PropTypes.number.isRequired,
  itemsSelected: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export default TableToolbar;
