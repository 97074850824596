import { getCurrentLanguage } from "common/utils";

export const isAtLeastOneLanguageComplete = (formData, optionIds, languageCodes) => languageCodes.some((langCode) => {
  const areAllOptionsPresent = optionIds.every((id) => formData[`${id}_option_${langCode}`]);
  return areAllOptionsPresent;
});

const findCompleteLanguage = (formData, optionIds, languageCodes, currentLanguage) => languageCodes.find((langCode) => {
  const areAllOptionsPresent = optionIds.every((id) => !!formData[`${id}_option_${langCode}`]);

  return areAllOptionsPresent;
}) || currentLanguage;

export const transformFormData = (formData, optionIds, scale) => {
  const languageCodes = ["es", "en", "pt"];
  let hasIndexChanges = false;
  let hasDescriptionChanges = false;

  const indicesAttributes = optionIds.flatMap((id) => {
    const indexData = scale.indices.find((index) => index.id === id);
    if (!indexData) return [];

    const updatedData = { id };
    languageCodes.forEach((langCode) => {
      const fieldName = `${id}_option_${langCode}`;
      if (formData[fieldName] && formData[fieldName] !== indexData[`answer_${langCode}`]) {
        updatedData[`answer_${langCode}`] = formData[fieldName];
        hasIndexChanges = true;
      }
    });

    return Object.keys(updatedData).length > 1 ? [updatedData] : [];
  });

  // Check for description changes
  const transformedScale = {};
  languageCodes.forEach((langCode) => {
    const descriptionField = `description_${langCode}`;
    if (formData[descriptionField] && formData[descriptionField] !== scale[descriptionField]) {
      transformedScale[descriptionField] = formData[descriptionField];
      hasDescriptionChanges = true;
    }
  });

  if (hasIndexChanges) {
    transformedScale.evaluation_scale_indices_attributes = indicesAttributes;
  }

  if (!hasIndexChanges && !hasDescriptionChanges) {
    return null;
  }

  return { evaluation_scale: transformedScale };
};
