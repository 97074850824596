import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ResultScale from "../ResultScale";
import GeneralResult from "../GeneralResult";
import SwitchView from "../SwitchView";
import { StyledContainer, StyledGrid } from "./styles";

const TopContent = (props) => {
  const { goodLeaderValues, isMobile, handleSelection } = props;

  const renderSwitch = () => (
    <Grid container spacing={ 1 }>
      <Grid item xs={ false } md={ 8 } />
      <SwitchView handleChangeSelection={ handleSelection } />
    </Grid>
  );

  return (
    <StyledContainer container spacing={ 1 }>
      {goodLeaderValues.scale && !isMobile && (
        <Grid container>
          <Grid item xs={ false } md={ 8 } />
          <ResultScale scale={ goodLeaderValues.scale } />
        </Grid>
      )}
      {!isMobile && renderSwitch()}
      <StyledGrid item xs={ 12 }>
        <GeneralResult
          data={ goodLeaderValues.data }
          scale={ goodLeaderValues.scale }
        />
      </StyledGrid>
      {isMobile && renderSwitch()}
    </StyledContainer>
  );
};

TopContent.propTypes = {
  goodLeaderValues: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleSelection: PropTypes.func.isRequired,
};

export default TopContent;
