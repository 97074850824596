import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "common/helpers";
import { MODULE_ACTIONS, MODULES } from "common/constants";
import {
  getGoalsByEmployeeId,
} from "redux/actions/goalsActions";

export const useGoalsHook = (externalHooks, internalHooks) => {
  const { dispatch, ability } = externalHooks;
  const { collaborator } = internalHooks;

  const {
    one: collaboratorGoals,
    isLoadingOne: isLoadingCollaboratorGoals,
  } = useSelector(({ goalsReducer }) => goalsReducer);

  const goalAbility = ability?.goals;
  let hasAccessGoals = false;

  if (goalAbility) {
    hasAccessGoals = goalAbility.can(MODULE_ACTIONS.view, MODULES.goals);
  }

  useEffect(() => {
    if (collaborator?.id && !isEqual(collaborator?.id, collaboratorGoals?.id)
    && hasAccessGoals) {
      dispatch(getGoalsByEmployeeId(collaborator?.id));
    }
    // eslint-disable-next-line
  }, [dispatch, collaborator]);

  return {
    goalsStates: {
      list: collaboratorGoals?.goals,
      isLoading: isLoadingCollaboratorGoals,
    },
  };
};
