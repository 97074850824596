import styled from "styled-components";
import palette from "theme/palette";

export const StyledSectionContainer = styled.div`
  margin-top: 20px;
`;

export const StyledContainer = styled.div`
  margin-top: 10px;
  background: ${ palette.white };
  border-radius: 5px;
  @media (max-width: 960px) {
    margin-top: 0px;
  }
`;

export const StyledContent = styled.div`
  padding: 20px 30px 10px;
  @media (max-width: 960px) {
    padding: 0px;
    .MuiAvatar-root {
      width: 40px;
      height: 40px;
    }
    .MuiTypography-body1 {
      font-size: 12px;
      line-height: 14px;
      margin-top: 4px;
      font-family: "Lato";
    }
    .MuiFormControl-root {
      margin-bottom: 0px;
    }
  }
`;
