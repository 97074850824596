import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE_PROCESS_BULK_UPLOAD,
  PROCESS_BULK_UPLOAD,
  PROCESS_BULK_UPLOAD_LOADING,
  PROCESS_BULK_UPLOAD_ERROR,
} from "../actionTypes/bulkUpload";

const URL = {
  main: "/users/import",
  async: "/async_task",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PROCESS_BULK_UPLOAD,
  });
};

export const uploadUsers = (data) => async (dispatch) => {
  dispatch({
    type: PROCESS_BULK_UPLOAD_LOADING,
  });
  try {
    const response = await configAxios.post(URL.main, data);
    dispatch({
      type: PROCESS_BULK_UPLOAD,
      payload: response.data.async_task,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_BULK_UPLOAD_ERROR);
  }
};

export const getUploadStatus = (uploadProcessId) => async (dispatch) => {
  dispatch({
    type: PROCESS_BULK_UPLOAD_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.async}/${uploadProcessId}`);
    dispatch({
      type: PROCESS_BULK_UPLOAD,
      payload: response.data.async_task,
    });
  } catch (error) {
    errorHandler(error.status, dispatch, PROCESS_BULK_UPLOAD_ERROR);
  }
};
