import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import {
  isEmpty, isEven, isNull, isEqual,
} from "common/helpers";
import { INDEX } from "common/constants";
import Modal from "components/Modal";
import TableDesktop from "./components/TableDesktop";
import TableMobile from "./components/TableMobile";
import Paginator from "./components/Paginator";
import { errorTable, noDataTable } from "./functions";
import { StyledTable, StyledTableCard, StyledRow } from "./styles";

const TablePagination = (props) => {
  const {
    list,
    isLoading,
    error,
    getRows,
    extraParam,
    pagination,
    handlePagination,
    total,
    modalOptions,
    noStriped,
    onClickRow,
  } = props;
  const { t } = useTranslation(["common", "collaborators"]);

  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [selectedRow, setSelectedRow] = useState(INDEX.zero);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleModalContent = (content) => {
    setModalContent(content);
  };

  const rowsOptions = [
    list,
    t,
    history,
    extraParam,
    isMobile,
    {
      value: isOpen,
      set: setIsOpen,
      handleModalContent,
      modalOptions,
    },
  ];

  const showContent = () => {
    if (error) {
      return (errorTable(t));
    }

    if (isEmpty(list) && !isLoading) {
      return noDataTable();
    }

    if (isMobile && getRows.mobile) {
      return getRows.mobile(...rowsOptions)?.map((item) => (
        <StyledTableCard key={ item }>
          {item?.map((cell) => (
            <div key={ item }>{cell.content}</div>
          ))}
        </StyledTableCard>
      ));
    }

    const handleClickRow = (index) => {
      if (!isNull(onClickRow)) {
        onClickRow(list[index]);
        setSelectedRow(index);
      }
    };

    return getRows.desktop(...rowsOptions)?.map((item, index) => (
      <StyledRow
        role={ "button" }
        tabIndex={ INDEX.zero }
        key={ item }
        className={ `${ noStriped || isEven(index) ? "even" : "odd"} rowContent` }
        onClick={ () => handleClickRow(index) }
        onKeyDown={ () => handleClickRow(index) }
        isClickeable={ !isNull(onClickRow) }
        isSelected={ !isNull(onClickRow) && isEqual(index, selectedRow) }
      >
        {item?.map((cell) => (
          <div key={ item }>{cell.content}</div>
        ))}
      </StyledRow>
    ));
  };

  return (
    <StyledTable data-testid={ "table-pagination" }>
      {isMobile && getRows.mobile
        ? <TableMobile contentTable={ showContent } { ...props } />
        : <TableDesktop contentTable={ showContent } { ...props } isMobile={ isMobile } />}
      {pagination && (
        <Paginator
          total={ total }
          pagination={ pagination }
          handlePagination={ handlePagination }
        />
      )}
      {modalOptions && (
        <Modal
          isOpen={ isOpen }
          onClose={ handleCloseModal }
          title={ modalContent?.title }
        >
          <div>
            {modalContent?.children}
          </div>
        </Modal>
      )}

    </StyledTable>
  );
};

TablePagination.propTypes = {
  getRows: PropTypes.object.isRequired,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  extraParam: PropTypes.object,
  pagination: PropTypes.number,
  handlePagination: PropTypes.func,
  total: PropTypes.number,
  modalOptions: PropTypes.array,
  withoutActions: PropTypes.bool,
  noStriped: PropTypes.bool,
  onClickRow: PropTypes.func,
};

TablePagination.defaultProps = {
  list: null,
  isLoading: false,
  error: "",
  extraParam: null,
  pagination: null,
  handlePagination: null,
  total: null,
  modalOptions: null,
  withoutActions: false,
  noStriped: false,
  onClickRow: null,
};

export default TablePagination;
