import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import GroupIcon from "@mui/icons-material/Group";
import TitleIcon from "components/TitleIcon";
import Button from "components/Button";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import SkeletonLoader from "components/SkeletonLoader";
import MenuPopup from "components/MenuPopup";
import AlertDialog from "components/AlertDialog";
import { StyledTabsContainer } from "styledComponents/Tabs";
import TemplateIcon from "assets/images/planning/template.svg";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  ALIGN_ITEMS,
  STATE,
  ICON_TYPES,
  SKELETONS_NUMBER,
  SIZE,
  SURVEY_PROCESS_TYPE,
} from "common/constants";
import { preventEnter, formatDate } from "common/utils";
import { isNotValid } from "common/helpers";
import AudienceByCollaboratorModal from "views/Collaborators/components/AudienceByCollaboratorModal";
import {
  deleteItem as deleteSurveyProcess,
} from "redux/actions/surveyProcessesActions";
import {
  getAllTabs, getProcessTypes, iconStyles, getValidData,
} from "../../functions";
import ProcessData from "../ProcessData";
import { StyledFormContainer } from "../../styles";
import {
  tabsContainerStyles, mainTabsStyles, StyledAddButton,
  StyledEditContent, StyledText, StyledDeleteIcon, StyledEditIcon,
  StyledEditText, StyledButton,
} from "./styles";

const ProcessForm = (props) => {
  const {
    t,
    formStates,
    setFormStates,
    formValues,
    setFormValues,
    changeProcess,
    handleSubmit,
    onSubmit,
    saveDraft,
    reset,
    surveysReducerStates,
    handleAddModal,
    control,
    audienceReducerStates,
    handleCloseModal,
    handleTags,
    dataStates,
    isLoadingForm,
    isLoadingAudienceList,
    templatesList,
    isLoadingTemplatesList,
    isFetchingTemplatesList,
    handleCustomizeProcess,
    isLoadingTemplate,
    isFetchingTemplate,
    templateData,
    draftSurveys,
    isLoadingDraftSurveys,
    dispatch,
    handleItemSelected,
    resetProcess,
    resultScaleList,
    isLoadingScaleList,
    saveResultScale,
    restoreResultScale,
    handleOpenModal,
    saveDraftAndOpenModal,
    participationModal,
    setParticipationModal,
    defaultValues,
    dialogUpdate,
    setDialogUpdate,
  } = props;
  const [showTabs, setShowTabs] = useState(false);
  const [tabHandler, setTabHandler] = useState(null);
  const {
    processType, processState, modal, audienceSelected,
    processId, templateSelected, evaluations, resultScaleSelected,
    surveyResultDialog, restoreSurveyResultDialog,
  } = formStates;
  const { loadingProcess } = surveysReducerStates;
  const { audiencesList } = audienceReducerStates;
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const process = getProcessTypes(t);

  const handleValueChanged = (value) => {
    changeProcess(process[value]);
    setTabHandler(value);
  };

  useEffect(() => {
    if (processType) {
      const index = process.findIndex((item) => item.key === processType.key);
      setTabHandler(index);
      setShowTabs(true);
      setFormValues({
        ...formValues,
        type: processType.value,
        survey_process_template_id: "",
      });
    } else {
      setTabHandler(null);
      setShowTabs(false);
    }
  }, [processType]);

  const deleteProcess = () => {
    const draftProcessType = data.processType.value;
    dispatch(deleteSurveyProcess(draftProcessType, data.id));
    setTimeout(() => {
      setDialog(false);
    }, 1000);
  };

  const deleteDraftSurvey = (draftData) => {
    setData(getValidData(draftData, t));
    setDialog(true);
  };

  const handleEdit = (draftData) => {
    handleItemSelected(getValidData(draftData, t));
  };

  const getMenu = (draftData) => [
    {
      title: <StyledEditText>{ t("common:common.edit") }</StyledEditText>,
      icon: <StyledEditIcon fontSize={ SIZE.small } className={ "edit" } />,
      onClick: () => handleEdit(draftData),
    },
    {
      title: <StyledText>{ t("common:common.delete") }</StyledText>,
      icon: <StyledDeleteIcon fontSize={ SIZE.small } className={ "delete" } />,
      onClick: () => deleteDraftSurvey(draftData),
    },
  ];

  useEffect(
    () => {
      if (participationModal !== null) {
        handleOpenModal("/planning", participationModal.id, participationModal, true);
        setParticipationModal(null);
        setIsLoadingUpdate(false);
        setDialogUpdate(false);
      }
    },
    // eslint-disable-next-line
    [participationModal],
  );

  return (
    <div data-testid={ "process-form-view" }>
      <Grid container>
        {!showTabs ? (
          <>
            <TitleIcon
              icon={ { src: TemplateIcon } }
              title={ t("planning:surveys.title") }
              subtitle={ t("planning:surveys.subtitle") }
            />
            <StyledAddButton onClick={ () => setShowTabs(true) }>
              <AddCircleOutlineIcon />
              <span>{t("planning:createProcess")}</span>
            </StyledAddButton>
            {/* // Draft surveys */}
            {draftSurveys && draftSurveys.length > 0 && (
              <TitleIcon
                icon={ {
                  src: EditIcon,
                  ...iconStyles,
                } }
                iconType={ ICON_TYPES.icon }
                title={ t("planning:draft.title") }
                subtitle={ t("planning:draft.subtitle") }
              />
            )}
            {isLoadingDraftSurveys
              ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } columnWidth={ 12 } />
              : draftSurveys?.map((draftSurvey) => (
                <StyledEditContent key={ draftSurvey.id }>
                  <MenuPopup items={ getMenu(draftSurvey) } />
                  <span>{draftSurvey.name}</span>
                  <p>{t("planning:draft.created", { date: formatDate(draftSurvey.start_date) })}</p>
                  {SURVEY_PROCESS_TYPE.engagement.key !== draftSurvey.type
                   && (
                     <Tooltip title={ t("participation:actions.previewParticipation") }>
                       <IconButton onClick={ () => {
                         handleOpenModal("/planning", draftSurvey.id, draftSurvey);
                       } }
                       >
                         <GroupIcon />
                       </IconButton>
                     </Tooltip>
                   )}
                </StyledEditContent>
              ))}
          </>
        ) : (
          <StyledTabsContainer
            item
            xs={ 12 }
            fontsize={ 14 }
            textcolor={ "grey" }
            sx={ tabsContainerStyles }
            variant={ VARIANT.fullWidth }
            normaltext
            withbackground
          >
            <Tab
              tabs={ getAllTabs(t, processId) }
              onChange={ handleValueChanged }
              tabValue={ tabHandler }
              sx={ mainTabsStyles }
            />
            {process.map((item, index) => (
              <TabPanel value={ tabHandler } index={ index } key={ `tabPanel-${item.key}` }>
                <StyledFormContainer hidden={ isNotValid(processType) }>
                  <form
                    id={ "survey-form" }
                    onSubmit={ handleSubmit(onSubmit) }
                    onKeyDown={ preventEnter } // TODO: remove this - it is a temporary solution
                  >
                    {!isNotValid(processType) && (
                      <Box mt={ 2 }>
                        <ProcessData
                          t={ t }
                          control={ control }
                          process={ processType }
                          processId={ processId }
                          handleAddModal={ handleAddModal }
                          audienceSelected={ audienceSelected }
                          setFormStates={ setFormStates }
                          formValues={ formValues }
                          setFormValues={ setFormValues }
                          handleCloseModal={ handleCloseModal }
                          audiencesList={ audiencesList }
                          handleTags={ handleTags }
                          dataStates={ dataStates }
                          isLoading={ isLoadingForm }
                          isLoadingAudienceList={ isLoadingAudienceList }
                          templatesList={ templatesList }
                          isLoadingTemplatesList={ isLoadingTemplatesList }
                          isFetchingTemplatesList={ isFetchingTemplatesList }
                          templateSelected={ templateSelected }
                          reset={ reset }
                          handleCustomizeProcess={ handleCustomizeProcess }
                          templateData={ templateData }
                          isLoadingTemplate={ isLoadingTemplate }
                          isFetchingTemplate={ isFetchingTemplate }
                          evaluations={ evaluations }
                          resultScaleList={ resultScaleList }
                          isLoadingScaleList={ isLoadingScaleList }
                          resultScaleSelected={ resultScaleSelected }
                          saveResultScale={ saveResultScale }
                          surveyResultDialog={ surveyResultDialog }
                          restoreSurveyResultDialog={ restoreSurveyResultDialog }
                          restoreResultScale={ restoreResultScale }
                          handleOpenModal={ handleOpenModal }
                          saveDraft={ saveDraftAndOpenModal }
                          isLoadingProcess={ loadingProcess }
                          defaultValues={ defaultValues }
                          dialogUpdate={ dialogUpdate }
                          setDialogUpdate={ setDialogUpdate }
                          participationModal={ participationModal }
                          isLoadingUpdate={ isLoadingUpdate }
                          setIsLoadingUpdate={ setIsLoadingUpdate }

                        />
                        {!isNotValid(process) && !isLoadingForm
                        && (
                          <Box
                            display={ ALIGN_ITEMS.flex }
                            justifyContent={ ALIGN_ITEMS.spaceBetween }
                            m={ 4 }
                          >
                            <Box>
                              {(processState === null) && (
                                <Button
                                  onClick={ saveDraft }
                                  variant={ VARIANT.outlined }
                                  color={ COLORS_NAME.primary }
                                  type={ BUTTON_STYLE_TYPES.BUTTON }
                                  typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                                >
                                  {t("common:common.saveDraft")}
                                </Button>
                              )}
                            </Box>
                            <Box>
                              <Button
                                onClick={ () => resetProcess() }
                                variant={ VARIANT.contained }
                                color={ COLORS_NAME.primary }
                                type={ BUTTON_STYLE_TYPES.CANCEL }
                                typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                              >
                                {t("common:common.cancel")}
                              </Button>
                              <StyledButton
                                variant={ VARIANT.contained }
                                color={ COLORS_NAME.primary }
                                type={ BUTTON_STYLE_TYPES.SUBMIT }
                                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                                isLoading={ loadingProcess }
                                isDisabled={ loadingProcess }
                              >
                                {processState === STATE.drafted || processState === null ? t("planning:programAndSave") : t("common:common.save")}
                              </StyledButton>
                              {processState === STATE.drafted && (
                                <StyledButton
                                  onClick={ saveDraft }
                                  variant={ VARIANT.outlined }
                                  color={ COLORS_NAME.primary }
                                  type={ BUTTON_STYLE_TYPES.BUTTON }
                                  typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                                >
                                  {t("common:common.save")}
                                </StyledButton>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </form>
                  {!isLoadingAudienceList && (
                    <AudienceByCollaboratorModal isOpen={ modal } onClose={ handleAddModal } />
                  )}
                </StyledFormContainer>
              </TabPanel>
            ))}
          </StyledTabsContainer>
        )}
      </Grid>
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => setDialog(false) }
        title={ t("planning:draft.alertMessage.title_delete") }
        message={ `${t("planning:draft.alertMessage.text_delete")} - ${data?.name}` }
        onSubmit={ deleteProcess }
        buttons={ { isLoading: loadingProcess } }
      />
    </div>
  );
};

ProcessForm.propTypes = {
  t: PropTypes.func,
  formStates: PropTypes.object,
  setFormStates: PropTypes.func,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func,
  changeProcess: PropTypes.func,
  methods: PropTypes.object,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  saveDraft: PropTypes.func,
  reset: PropTypes.func,
  surveysReducerStates: PropTypes.object,
  handleAddModal: PropTypes.func,
  control: PropTypes.object,
  audienceReducerStates: PropTypes.object,
  handleCloseModal: PropTypes.func,
  handleTags: PropTypes.func,
  dataStates: PropTypes.object,
  isLoadingForm: PropTypes.bool,
  isLoadingAudienceList: PropTypes.bool,
  templatesList: PropTypes.array,
  isLoadingTemplatesList: PropTypes.bool,
  isFetchingTemplatesList: PropTypes.bool,
  handleCustomizeProcess: PropTypes.func.isRequired,
  templateData: PropTypes.shape({
    evaluation_templates: PropTypes.array,
  }),
  isLoadingTemplate: PropTypes.bool,
  isFetchingTemplate: PropTypes.bool,
  draftSurveys: PropTypes.array,
  isLoadingDraftSurveys: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleItemSelected: PropTypes.func.isRequired,
  resetProcess: PropTypes.func.isRequired,
  resultScaleList: PropTypes.array.isRequired,
  isLoadingScaleList: PropTypes.bool.isRequired,
  saveResultScale: PropTypes.func.isRequired,
  restoreResultScale: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  saveDraftAndOpenModal: PropTypes.func.isRequired,
  participationModal: PropTypes.object,
  setParticipationModal: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  dialogUpdate: PropTypes.bool.isRequired,
  setDialogUpdate: PropTypes.func.isRequired,
};

ProcessForm.defaultProps = {
  participationModal: null,
  defaultValues: null,
};

export default ProcessForm;
