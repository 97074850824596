import { ImageLoader } from "react-progressive-img-loader";
import PropTypes from "prop-types";
import defaultImg from "assets/images/icons/account.svg";

const LoaderImageProfile = (props) => {
  const { collaborator, component } = props;

  return (
    <ImageLoader
      src={ collaborator?.profile_img_url || defaultImg }
      srcPreview={ collaborator?.profile_img_url_thumb || defaultImg }
      customImageComponent={ component }
    />
  );
};

LoaderImageProfile.propTypes = {
  collaborator: PropTypes.object.isRequired,
  component: PropTypes.any.isRequired,
};

export default LoaderImageProfile;
