import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { formatDate } from "common/utils";
import { ALIGN_ITEMS, VARIANT } from "common/constants";
import NalaBot from "assets/images/general/nala_bot_normal.svg";
import { StyledBoldText, StyledBox } from "./styles";

const LastStep = (props) => {
  const { startDate } = props;
  const { t } = useTranslation("candidates");
  return (
    <Box
      display={ ALIGN_ITEMS.flex }
      data-testid={ "last-step" }
      justifyContent={ ALIGN_ITEMS.center }
      mt={ 5 }
      mb={ 5 }
    >
      <StyledBox>
        <img src={ NalaBot } alt={ "NalaBot" } />
        <Typography variant={ VARIANT.h4 }>{t("induction.ready")}</Typography>
        <Typography>
          {t("induction.ready_text")}
          {startDate ? ", " : "."}
          {startDate
            && <StyledBoldText>{`${t("induction.start_date")} ${formatDate(startDate)}.`}</StyledBoldText>}
        </Typography>
        <Typography>{t("induction.message_text")}</Typography>
      </StyledBox>
    </Box>
  );
};

LastStep.propTypes = {
  startDate: PropTypes.string,
};

LastStep.defaultProps = {
  startDate: null,
};

export default LastStep;
