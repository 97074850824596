import round from "lodash/round";
import includes from "lodash/includes";
import isInteger from "lodash/isInteger";
import getStar from "lodash/get";
import reduce from "lodash/reduce";
import startCase from "lodash/startCase";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import StarRating from "components/StarRating";
import Comments from "components/Comments";
import SimpleTable from "components/SimpleTable";
import StaticProgressBar from "components/StaticProgressBar";
import {
  LEADER_CLASSIFICATION_RANK,
  VISIBILITY,
  PERFORMANCE_MAX_PERCENTAGE,
  DEFAULT_MIN_DECIMAL,
  OBJECT_KEYS,
  VARIANT,
  SIZE,
  MIN_VALUE,
} from "common/constants";
import {
  isEmpty, isUndefined,
} from "common/helpers";
import { RESULT_SECTIONS } from "common/constants/goodLeader";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { getPercent, getScaleByScore, findDataInScale } from "common/utils";
import { getEvaluationType, isNullOrUndefined } from "common/validators";
import {
  StyledAccordionHeader, StyledAnswers, StyledQuestion, StyledLink,
} from "views/Engagement/styledComponents";
import { StyledComment } from "../styles";
import OpenQuestions from "../components/ProfileInfo/components/Modals/components/PerformanceModal/components/OpenQuestions";
import { StyledGridContent, StyledLeaderComment } from "../components/ProfileInfo/components/Modals/components/GoodLeaderModal/components/Comments/styles";

export const locationPath = "performance";

export const createClassificationLeader = (top, bottom, result) => ({ top, bottom, result });

export const setEvaluationData = (
  evaluation_type,
  weighing,
  score,
  result,
  comment,
) => ({
  evaluation_type, weighing, score, result, comment,
});

export const createEvaluatorsHeader = (
  id,
  label,
  customRender,
  newCustomRender,
) => ({
  id, label, customRender, newCustomRender,
});

export const evaluationScale = (value, scales) => {
  const adjustedValue = Math.round(value);

  const scaleResult = scales.find((currentScale) => {
    const top = getPercent(currentScale.top);
    const bottom = getPercent(currentScale.bottom);

    return adjustedValue >= bottom && adjustedValue <= top;
  })?.result || "";

  return scaleResult;
};

export const getResults = (results, t) => {
  const data = results.map((result) => setEvaluationData(
    getEvaluationType(result.evaluation_type, t),
    getPercent(result.weighing),
    getPercent(result?.score),
    result.result,
    result.comment || "",
  ));
  return data;
};

export const setEvaluatorsData = (name, title, percentage, comments) => ({
  name, title, percentage, comments,
});

const isNotConfidentialComment = (evaluationType) => !includes(
  [EVALUATION_TYPE.PAIRS, EVALUATION_TYPE.COLLABORATORS],
  evaluationType,
);

export const setComments = (comments, evaluationType) => {
  const commentList = comments.map((comment) => ({
    name: isNotConfidentialComment(evaluationType) ? comment.name : "",
    img:
        !isNullOrUndefined(comment.image)
        && isNotConfidentialComment(evaluationType)
          ? comment.image
          : "",
    text: comment.comment,
  }));
  return commentList;
};

const getResultByQuestion = (results, question) => {
  const resultByQuestion = [];
  results.map((result) => result.evaluation_answers
    .filter((filterAnswer) => question.question_id === filterAnswer.question_id)
    .map((answer) => resultByQuestion.push(answer)));
  return resultByQuestion;
};

const getResultByOpenQuestion = (results, question) => {
  const resultByQuestion = [];
  results.map((result) => result.evaluation_answers
    .filter((filterAnswer) => question.question_id === filterAnswer.question_id)
    .map((answer) => resultByQuestion.push({ type: result.evaluation_type, answers: answer?.answers })));
  return resultByQuestion;
};

const getOpenComments = (answers) => answers.map((answer) => (
  <StyledComment key={ answer }>
    {answer?.answered_by && <span>{`${answer.answered_by?.full_name}: `}</span>}
    {answer.answer}
  </StyledComment>
));

const getTotalScoreAndAnswers = (results, resultByQuestion, isOpen = false) => {
  const answersToSave = {};
  const total = getPercent(
    reduce(
      results,
      (result, value) => result + value.weighing,
      0,
    ),
  );
  let totalScore = 0;

  results.forEach((evaluator, index) => {
    const comments = resultByQuestion[index]?.answers
      ? getOpenComments(resultByQuestion[index]?.answers)
      : [];
    const answer = isOpen ? comments : resultByQuestion[index]?.result;
    const score = getPercent(resultByQuestion[index]?.score);
    const weighing = getPercent(evaluator.weighing);

    if (!isEmpty(answer)) {
      answersToSave[evaluator.evaluation_type] = {
        result: score,
        label: answer,
      };
    }

    const weighingFormatted = round(
      (weighing / total) * 100,
      2,
    );
    const weightedScore = (score * weighingFormatted) / 100;
    totalScore += weightedScore;
  });

  return {
    total: round(totalScore, 2),
    answers: answersToSave,
  };
};

const getCommentsByAnswers = (results, resultByQuestion) => {
  const answersToSave = {};

  const resultsByEvaluator = results;
  resultsByEvaluator.forEach((evaluator) => {
    const answersByQuestion = resultByQuestion.find((question) => question.type === evaluator.evaluation_type);
    const answer = !isUndefined(answersByQuestion) && answersByQuestion.answers ? getOpenComments(answersByQuestion.answers) : [];
    if (!isEmpty(answer)) {
      answersToSave[evaluator.evaluation_type] = {
        result: null,
        label: answer,
      };
    }
  });

  return {
    total: null,
    answers: answersToSave,
  };
};

export const getQuestions = (results, scales, isOpen = false) => {
  const questions = [];

  const resultByEvaluator = isOpen ? results.filter((result) => !isEmpty(result.evaluation_answers)) : results;

  const questionsAndAnswers = [];

  if (isOpen) {
    resultByEvaluator.forEach((result) => {
      result.evaluation_answers.forEach((evaluationAnswer) => {
        const hasQuestion = questions.find((question) => question.question_id === evaluationAnswer.question_id);
        if (isEmpty(questions) || !hasQuestion) {
          questions.push(evaluationAnswer);
        }
      });
    });

    questions.forEach((questionItem) => {
      const resultByQuestion = getResultByOpenQuestion(resultByEvaluator, questionItem);
      const totalScoreAndAnswers = getCommentsByAnswers(
        resultByEvaluator,
        resultByQuestion,
      );
      const questionResult = Object(totalScoreAndAnswers.answers);
      questionsAndAnswers.push({
        title: questionItem.question,
        questionResult,
        total: null,
        label: "",
        answers: [],
      });
    });
  } else {
    resultByEvaluator[0].evaluation_answers.forEach(
      (question) => {
        const resultByQuestion = getResultByQuestion(resultByEvaluator, question);
        const totalScoreAndAnswers = getTotalScoreAndAnswers(
          resultByEvaluator,
          resultByQuestion,
          isOpen,
        );
        const questionResult = Object(totalScoreAndAnswers.answers);
        questionsAndAnswers.push({
          title: question.question,
          questionResult,
          total: totalScoreAndAnswers.total,
          label: evaluationScale(totalScoreAndAnswers.total, scales),
          answers: question.answers,
        });
      },
    );
  }
  return questionsAndAnswers;
};

export const evaluatorHeader = (results) => {
  const data = results.map((result) => setEvaluatorsData(
    result.evaluation_type,
    result.evaluation_type,
    result.weighing,
    !isEmpty(result.comments)

        && setComments(result.comments, result.evaluation_type),
  ));
  return data;
};

export const getFinalWeighing = (weighing, total) => {
  const value = round(
    (weighing / total) * PERFORMANCE_MAX_PERCENTAGE,
    DEFAULT_MIN_DECIMAL,
  );
  return isInteger(value) ? value : value.toFixed(DEFAULT_MIN_DECIMAL);
};

export const getAlert = (evaluations, t) => {
  const total = getPercent(
    reduce(
      evaluations,
      (result, value) => result + value.weighing,
      0,
    ),
  );

  let evaluationsDetail = "";
  evaluations.map((evaluation) => {
    const weighing = getPercent(evaluation.weighing);
    return (evaluationsDetail += ` ${getEvaluationType(
      evaluation.evaluation_type,
      t,
    )} (${getFinalWeighing(weighing, total)}%).`);
  });
  return `${t("profile:modals.performance.weightedPerformance")} : ${evaluationsDetail}`;
};

export const getEmployeeId = (dataUser) => dataUser.employee?.id || dataUser.id;

export const getScaleMaxIndex = (indices) => indices.reduce((max, current) => (
  current.top > max ? current.top : max), 0);

export const getStarRating = (rowData, scaleMaxIndex, scaleIndices, isSmall, label) => {
  const starRatingData = {
    name: isSmall ? rowData.title : rowData.evaluation_type,
    label: isSmall ? getStar(rowData, label) : rowData.result,
    scaleMaxIndex,
  };
  const scaleIndex = findDataInScale(scaleIndices, starRatingData.label);
  const position = scaleIndex?.position || null;
  return (
    <StarRating
      name={ starRatingData.name }
      size={ isSmall ? SIZE.small : SIZE.medium }
      value={ position }
      isReadOnly
      maxRating={ starRatingData.scaleMaxIndex }
      max={ starRatingData.scaleMaxIndex }
      precision={ 1 }
      hasNoFormattedValue
      label={ starRatingData.label }
    />
  );
};

export const getReducerToUse = (searchBy, reducerToUse) => reducerToUse.one;

export const isValidToSearch = (
  dataUser,
  performanceData,
  surveyResults,
  surveyProcess,
) => (
  !isEmpty(dataUser)
    && isEmpty(performanceData)
    && surveyResults === null
    && surveyProcess === null
);

export const isValidToSearchSurveys = (isLoadingPerformanceProcess, listPerformanceProcess, dataUser) => (
  !isLoadingPerformanceProcess
    && !isEmpty(listPerformanceProcess)
    && !isEmpty(dataUser)
);

export const isActiveDataResult = (
  isActiveResult,
  performanceData,
  isLoading,
  isLoadingProfile,
) => (
  isActiveResult && performanceData !== null && !isLoading && !isLoadingProfile
);

export const getEvaluationHeader = (t, scaleMaxIndex, scaleIndices) => [
  { id: OBJECT_KEYS.evaluationType, label: "" },
  {
    id: OBJECT_KEYS.score,
    label: t("profile:modals.performance.grades"),
    customRender: (rowData) => getStarRating(rowData, scaleMaxIndex, scaleIndices),
  },
  { id: OBJECT_KEYS.comment, label: t("profile:modals.performance.comments") },
];

export const getTotalHeader = (t, scaleMaxIndex, scaleIndices) => [
  {
    id: OBJECT_KEYS.evaluationType,
    label: "",
    customRender: (rowData) => getEvaluationType(rowData.evaluation_type, t),
  },
  {
    id: OBJECT_KEYS.score,
    label: "",
    customRender: (rowData) => getStarRating(rowData, scaleMaxIndex, scaleIndices),
  },
];

const getCommentDetails = (comments, t) => (
  <Comments
    textLink={ t("profile:modals.performance.seeComments") }
    title={ t("profile:modals.performance.comments") }
    data={ comments }
  />
);

const getCommentsContent = (t, comments) => (!isEmpty(comments)
  ? (getCommentDetails(comments, t)) : (
    <Typography
      variant={ VARIANT.subtitleTwo }
    >
      { t("profile:modals.performance.noComments") }
    </Typography>
  ));

const getEvaluatorsHeader = (evaluators, t, scaleMaxIndex, scaleIndices, isOpen = false) => {
  const evaluatorsHeader = [
    { id: OBJECT_KEYS.title, label: "" },
  ];
  evaluators.map((header) => {
    if (!isOpen && header.comments) {
      evaluatorsHeader[0].newCustomRender = t("profile:modals.performance.comments");
    }
    const head = createEvaluatorsHeader(
      `questionResult.${header.name}.result`,
      getEvaluationType(header.title, t),
      (rowData) => {
        const openAnswerText = isOpen ? getStar(rowData, `questionResult.${header.name}.label`) : "";
        return !isOpen ? getStarRating(
          rowData,
          scaleMaxIndex,
          scaleIndices,
          true,
          `questionResult.${header.name}.label`,
        )
          : (
            <Typography
              key={ openAnswerText }
              variant={ VARIANT.bodyOne }
            >
              { openAnswerText }
            </Typography>
          );
      },
      !isOpen && header.comments ? getCommentsContent(t, header.comments) : "",
    );
    return evaluatorsHeader.push(head);
  });
  if (!isOpen) {
    evaluatorsHeader.push({
      id: OBJECT_KEYS.total,
      label: startCase(OBJECT_KEYS.total),
      customRender: (rowData) => getStarRating(
        rowData,
        scaleMaxIndex,
        scaleIndices,
        true,
        OBJECT_KEYS.label,
      ),
    });
  }
  return evaluatorsHeader;
};

const getOpenQuestion = (results, t) => results.map((result) => ({
  label: getEvaluationType(result.evaluation_type, t),
  questions: result?.evaluation_answers.map((evalQuestion) => ({
    title: evalQuestion.question,
    answers: evalQuestion.answers?.map((questionAnswer) => ({
      answer: questionAnswer?.answer,
      answeredBy: questionAnswer?.answered_by ?? null,
    })),
  })),
}));

export const getTotalEvaluationTable = (
  evaluation,
  results,
  performanceData,
  t,
  isOpen,
  scaleMaxIndex,
  scaleIndices,
) => {
  if (isOpen) {
    return (
      <OpenQuestions data={ getOpenQuestion(results, t) } />
    );
  }

  return evaluation.visibility === VISIBILITY.COMPACT ? (
    <SimpleTable
      header={ getEvaluationHeader(t, scaleMaxIndex, scaleIndices) }
      data={ getResults(results, t) }
    />
  ) : (
    <SimpleTable
      header={ getEvaluatorsHeader(
        evaluatorHeader(results),
        t,
        scaleMaxIndex,
        scaleIndices,
        isOpen,
      ) }
      data={ getQuestions(results, performanceData?.result_scale_indices, isOpen) }
      size={ SIZE.small }
      newRow
    />
  );
};

export const accordionData = (t, results, scale, handleModal) => results?.map((item, index) => {
  const isWide = item.visibility === VISIBILITY.WIDE;
  const resultSection = RESULT_SECTIONS(t).filter((section) => section.translations.includes(item?.name));

  const data = {
    title: item?.name,
    customHeader: (
      <StyledAccordionHeader container spacing={ 2 } isWide={ isWide }>
        <Grid item xs={ 12 } md={ 6 }>
          <Typography variant={ VARIANT.h6 }>{ isWide ? item?.name : `${index + 1}. ${item?.name}` }</Typography>
        </Grid>
        {item?.score !== null && (
          <Grid item xs={ 12 } md={ 4 }>
            <StaticProgressBar
              title={ " " }
              percentage={ getPercent(item?.score || MIN_VALUE) }
              color={ getScaleByScore(item?.score, scale)?.color }
            />
          </Grid>
        )}
        { !isEmpty(resultSection)
          && item.score < LEADER_CLASSIFICATION_RANK.EXEMPLARY_LEADER[0]
          && (
            <Grid item xs={ 12 } md={ 2 }>
              <StyledLink onClick={ () => handleModal({
                name: item?.name,
                key: resultSection[0].key,
                urls: resultSection[0].urls,
              }) }
              >
                { t("goodLeader:wantToImproveIt") }
              </StyledLink>
            </Grid>
          )}
      </StyledAccordionHeader>
    ),
  };
  if (isWide && !isEmpty(item.evaluationResults)) {
    data.customRender = (
      <StyledAnswers>
        {item?.evaluationResults[0].evaluation_answers.map((answer, indexAnswer) => (
          <StyledQuestion container spacing={ 3 } key={ `answer-${answer.question_id}` }>
            <Grid item xs={ 12 } md={ 6 }>
              <Typography variant={ VARIANT.bodyOne }>{ `${indexAnswer + 1}. ${answer?.question}` }</Typography>
            </Grid>
            <Grid item xs={ 12 } md={ 5 }>
              <StaticProgressBar
                title={ " " }
                percentage={ getPercent(answer?.score || MIN_VALUE) }
                color={ getScaleByScore(answer?.score, scale)?.color }
              />
            </Grid>
          </StyledQuestion>
        ))}
      </StyledAnswers>
    );
  }

  return data;
});

export const renderComments = (comments) => comments.map((item, index) => (
  <StyledGridContent
    item
    xs={ 12 }
    md={ 6 }
    key={ `result-${index}` }
  >
    <StyledLeaderComment>
      <Typography variant={ VARIANT.bodyOne }>{item}</Typography>
    </StyledLeaderComment>
  </StyledGridContent>
));

const evaluationResults = (data) => (
  data?.results_by_evaluation_type
    && data?.results_by_evaluation_type[0]
);

export const getAnswers = (data) => {
  const answers = data?.complete_results_by_section?.map((answer) => ({
    name: answer?.name,
    result: answer?.result,
    score: answer?.score,
    visibility: answer?.visibility,
    evaluationResults: answer?.evaluation_results,
  }));

  return answers;
};

export const getComments = (data) => evaluationResults(data)?.comments;

export const getRange = (scale) => `${getPercent(scale.bottom, false)}-${getPercent(scale.top, true)}`;
