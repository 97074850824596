import {
  useCallback, useContext, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import AlertModal from "components/AlertModal";
import Stepper from "components/Stepper";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import { ROLES } from "common/constants";
import { isEmpty, isEqual, isNull } from "common/helpers";
import useComplexState from "hooks/utils/useComplexState";
import { SessionContext } from "modules/session/context";
import {
  getOne as getCandidate,
  update as updateCandidate,
} from "redux/actions/candidateActions";
import LastStep from "views/Account/components/AccountInductionTab/LastStep";
import PersonalData from "views/Account/components/PersonalData";
import AdditionalData from "views/Account/components/AccountInductionTab/AdditionalData";
import DocumentForm from "views/Account/components/AccountInductionTab/DocumentForm";
import { resetCandidate } from "views/Account/functions";
import { StyledSimpleDashboardContainer } from "styledComponents/View";
import CompanyData from "./components/CompanyData";
import {
  addFile,
  addInfo,
  isInvalidAnswersRequired,
  isInvalidDocumentsRequired,
  handleUpload,
  getCompanyResourcesFormat,
} from "./functions";
import { StyledContainer, StyledContent } from "./styles";

const Preboarding = () => {
  const { t } = useTranslation(["preboarding", "candidates"]);
  const [savePersonalData, setSavePersonalData] = useState(false);
  const [companyResources, setCompanyResources] = useState([]);
  const [preboardingStates, setPreboardingStates] = useComplexState({
    modal: false,
    end: false,
    sent: false,
    state: { files: null },
    answers: null,
  });

  // FIXME: remove and fix all file
  const candidateReducer = useSelector((state) => state.candidateReducer);

  const {
    state: { user },
  } = useContext(SessionContext);

  const {
    one: candidate,
    isLoadingOne: isLoadingCandidate,
    isLoadingProcess: isLoadingCandidateProcess,
    successProcess: successCandidateProcess,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      candidateReducer?.one?.requested_section_items
      && !isEmpty(candidateReducer?.one?.requested_section_items)
    ) {
      setCompanyResources(
        getCompanyResourcesFormat(
          candidateReducer?.one?.requested_section_items,
        ),
      );
    }
  }, [candidateReducer.one]);

  useEffect(() => {
    const employeeId = user.employee.id;
    if (isNull(candidate)) {
      dispatch(getCandidate(employeeId));
    }
  }, [dispatch, candidate, user.employee.id]);

  const getFilesToSave = useCallback(() => {
    const filesToSave = [];
    if (
      candidateReducer.one?.requested_documents
      && !isEmpty(candidateReducer.one.requested_documents)
    ) {
      candidateReducer.one.requested_documents.forEach((file) => {
        if (!isNull(file.filename)) {
          filesToSave.push(addFile(file.id, file.filename, null));
        }
      });
    }
    return filesToSave;
  }, [candidateReducer.one]);

  const getAnswersToSave = useCallback(() => {
    const answersToSave = {};
    if (
      candidateReducer.one?.requested_infos
      && !isEmpty(candidateReducer.one.requested_infos)
    ) {
      candidateReducer.one.requested_infos.forEach((answer) => {
        if (!isNull(answer.answer)) {
          answersToSave[answer.id] = answer.answer;
        }
      });
    }
    return Object(answersToSave);
  }, [candidateReducer.one]);

  useEffect(() => {
    setPreboardingStates({
      state: { files: getFilesToSave() },
      answers: getAnswersToSave(),
    });
    // eslint-disable-next-line
  }, [getFilesToSave, getAnswersToSave]);

  const updateFiles = (event, id) => {
    const file = event.target?.files[0];
    if (file) {
      const uploadFile = handleUpload(file, id, t);
      if (uploadFile) {
        const updatedFiles = preboardingStates.state.files.filter((f) => f.id !== id);
        setPreboardingStates({
          state: {
            files: [...updatedFiles, { id, ...uploadFile }],
          },
        });
      }
    }
  };

  useEffect(() => {
    setPreboardingStates({ answers: getAnswersToSave() });
    // eslint-disable-next-line
  }, [getAnswersToSave]);

  const isValidData = () => {
    let step = "";
    if (
      isInvalidAnswersRequired(
        candidateReducer?.one?.requested_infos,
        preboardingStates.answers,
      )
    ) {
      step = t("steps.additionalInfo");
    }
    if (
      isInvalidDocumentsRequired(
        candidateReducer?.one?.requested_documents,
        preboardingStates.state.files,
      )
    ) {
      step = t("steps.uploadDocuments");
    }
    if (
      companyResources?.find((item) => !item.has_confirmed && item.required)
    ) {
      step = t("steps.companyInfo");
    }
    if (!isEmpty(step)) {
      toast(MESSAGE_TYPES.warning, {
        title: t("alert.noComplete.title"),
        message: `${t("alert.noComplete.message")} "${step}"`,
      });
      setPreboardingStates({ modal: false });
    }
    return isEmpty(step);
  };

  const openCloseModal = () => {
    if (isValidData()) {
      setPreboardingStates({ modal: !preboardingStates.modal });
    }
  };

  const handleChange = (prop, event) => {
    setPreboardingStates({
      answers: {
        ...preboardingStates.answers,
        [prop]: event.target.value,
      },
    });
  };

  const lastStep = {
    label: t("steps.ready"),
    content: <LastStep startDate={ candidateReducer?.one?.starting_date } />,
  };

  const steps = (candidate) => {
    const stepDetails = [];
    stepDetails.push({
      label: t("steps.personalInfo"),
      content: (
        <StyledContent>
          <PersonalData
            user={ user }
            employee={ ROLES.CANDIDATE }
            reducerToUse={ candidate }
            sendNextStep={ setSavePersonalData }
            isPreboarding
            isEdit
          />
        </StyledContent>
      ),
      idForm: "personal-data-form",
      sendNext: savePersonalData,
      setNext: setSavePersonalData,
    });
    if (!isEmpty(candidate?.one?.requested_infos)) {
      stepDetails.push({
        label: t("steps.additionalInfo"),
        content: (
          <AdditionalData
            data={ candidate?.one?.requested_infos }
            handleChange={ handleChange }
            answers={ preboardingStates.answers }
          />
        ),
        disabled: isInvalidAnswersRequired(
          candidate?.one?.requested_infos,
          preboardingStates.answers,
        ),
        isLoading: isLoadingCandidate,
      });
    }
    if (!isEmpty(candidate?.one?.requested_documents)) {
      stepDetails.push({
        label: t("steps.uploadDocuments"),
        content: (
          <DocumentForm
            data={ candidate?.one?.requested_documents }
            handleUpload={ updateFiles }
            state={ preboardingStates.state }
            isCandidate
            isPreboarding
          />
        ),
        disabled: isInvalidDocumentsRequired(
          candidate?.one?.requested_documents,
          preboardingStates.state.files,
        ),
        isLoading: isLoadingCandidate,
      });
    }
    if (!isEmpty(companyResources)) {
      stepDetails.push({
        label: t("steps.companyInfo"),
        content: (
          <CompanyData data={ companyResources } setData={ setCompanyResources } />
        ),
        disabled: companyResources?.find(
          (item) => !item.has_confirmed && item.required,
        ),
        isLoading: isLoadingCandidate,
      });
    }
    return stepDetails;
  };

  const sendData = () => {
    const answersList = [];
    const sectionsList = [];
    candidate.requested_infos.map((info) => answersList.push(addInfo(info.id, preboardingStates.answers[info.id])));
    candidate.requested_section_items.map((info) => sectionsList.push({
      id: info.id,
      has_confirmed: companyResources.find((e) => isEqual(e.id, info.id))
        ?.has_confirmed,
    }));

    const values = {
      requested_infos_attributes: answersList,
      requested_section_items_attributes: sectionsList,
    };
    dispatch(updateCandidate(values, candidate.id));
    preboardingStates.state.files.forEach((file) => {
      if (!isNull(file.file)) {
        const formData = new FormData();
        formData.append("candidate[requested_documents_attributes]id", file.id);
        formData.append(
          "candidate[requested_documents_attributes]file",
          file.file,
        );
        dispatch(updateCandidate(formData, candidate.id));
      }
    });
    setPreboardingStates({ modal: false, end: true });
  };

  const resetData = useCallback(() => {
    setPreboardingStates({ modal: false });
    resetCandidate(dispatch, candidate.id);
    toast(
      MESSAGE_TYPES.success,
      handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
    );
    // eslint-disable-next-line
  }, [t, dispatch, candidate]);

  useEffect(() => {
    if (successCandidateProcess && preboardingStates.end) {
      resetData();
      setPreboardingStates({ end: false, sent: true });
    }
    // eslint-disable-next-line
  }, [successCandidateProcess, resetData, preboardingStates.end]);

  return (
    <StyledSimpleDashboardContainer>
      <ViewHeaderTitle title={ t("title") } />
      <StyledContainer>
        <Stepper
          steps={ steps(candidateReducer) }
          onClick={ openCloseModal }
          end={ preboardingStates.end }
          lastStep={ lastStep }
          sent={ preboardingStates.sent }
        />
      </StyledContainer>
      <AlertModal
        title={ t("candidates:alertMessage.title") }
        text={ t("candidates:alertMessage.send") }
        textDisagree={ t("candidates:alertMessage.cancel") }
        textAgree={ t("candidates:alertMessage.approved") }
        onClick={ () => {
          sendData();
        } }
        open={ preboardingStates.modal }
        handleClose={ openCloseModal }
        isLoading={ isLoadingCandidateProcess }
      />
    </StyledSimpleDashboardContainer>
  );
};

export default Preboarding;
