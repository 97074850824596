import PropTypes from "prop-types";
import StarRating from "components/StarRating";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { StyledLabel, StyledSkill } from "../../styles";
import { skillInformationLevels } from "../../functions";

const CalibratedScore = (props) => {
  const {
    scale, selectedValue, handleRatingChange,
    t, type, isValidToUpdate, result,
  } = props;
  const resultScaleLength = scale.length;

  const skillInfo = skillInformationLevels(t);

  const performanceOption = (row, index = null) => (
    <StarRating
      name={ `star-rating-${row.result}` }
      value={ index !== null ? resultScaleLength - index : row?.position }
      isReadOnly
      maxRating={ resultScaleLength }
      max={ resultScaleLength }
      precision={ 1 }
      hasNoFormattedValue
      detail={ row.result }
    />
  );

  const potentialOption = (row) => {
    const nineBoxResult = row?.nine_box_result;
    const skillInfoDetails = skillInfo[nineBoxResult];
    return (
      <StyledSkill color={ skillInfoDetails.color }>
        {skillInfoDetails.icon}
        <p>{row?.result}</p>
      </StyledSkill>
    );
  };

  return (
    <>
      <StyledLabel>{`${t("calibration.modal.calibrated")}:`}</StyledLabel>
      {isValidToUpdate
        ? (
          <Select
            value={ selectedValue }
            onChange={ handleRatingChange }
            displayEmpty
            fullWidth
          >
            {scale.map((row, index) => (
              <MenuItem key={ row.id } value={ row.id }>
                <div style={ { display: "flex", alignItems: "center" } }>
                  {type === "performance" && performanceOption(row, index)}
                  {type === "potential" && potentialOption(row)}
                </div>
              </MenuItem>
            ))}
          </Select>
        )
        : (
          <div style={ { display: "flex", alignItems: "center" } }>
            {type === "performance" && performanceOption(result)}
            {type === "potential" && potentialOption(result)}
          </div>
        )}
    </>
  );
};

CalibratedScore.propTypes = {
  scale: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    result: PropTypes.string.isRequired,
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    result_es: PropTypes.string,
    result_en: PropTypes.string,
    result_pt: PropTypes.string,
    nine_box_result: PropTypes.string.isRequired,
  })).isRequired,
  selectedValue: PropTypes.number.isRequired,
  handleRatingChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isValidToUpdate: PropTypes.bool.isRequired,
  result: PropTypes.object.isRequired,
};

export default CalibratedScore;
