import styled from "styled-components";
import Box from "@mui/material/Box";
import EmailIcon from "@mui/icons-material/Email";
import theme from "theme/palette";

export const StyledImageContainer = styled.div`
  position: relative;
  &.isMobile {
    display: inline-flex;
    text-align: center;
  },
  .MuiAvatar-root {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
  }
`;

export const StyledStatus = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isActive ? theme.table.green : theme.table.red)};
  svg {
    font-size: 14px;
    color: ${theme.white};
  }
`;

export const StyledBoxContainer = styled(Box)`
  display: ${(props) => (props.isMobile ? "inline-block" : "flex")};
  align-items: center;
  &.boxContainerMobile{
    text-align: center;
    width: 100%;
  }
`;

export const StyledEmailIcon = styled(EmailIcon)`
  font-size: 12px;
  color: ${theme.text.link};
  vertical-align: middle;
  margin-right: 3px;
`;

export const StyledTitleWithSubtitle = styled.div`
  display: inline;
  margin-left: ${(props) => (props.isMobile ? "0px" : "5px") };
  text-align: ${(props) => (props.isMobile ? "center" : "left") };
  padding-left: 5px;
  .cardMobile{
    font-size: 14px;
  }
`;

export const StyledTitle = styled.h2`
  font-weight: 500;
  font-size: ${(props) => (props.size || 14)}px;
`;

export const StyledSubtitle = styled.h3`
  font-weight: 400;
  font-size: 12px;
  color: ${theme.text.content};
  display: flex;
  align-items: center;
  svg{
    margin-right: 5px;
    width: 14px;
  }
  a:hover {
    color: ${theme.text.purple};
  }
`;

export const StyledLink = styled.a`
  color: ${theme.text.link};
  text-decoration: underline;
  cursor: ${(props) => (props.canViewProfile ? "pointer" : "default")};
  font-weight: 500;
  &:hover {
    color: ${theme.text.purple};
  }
`;

export const StyledText = styled.p`
  font-size: ${(props) => (props.size || 12)}px;
  display: flex;
`;
