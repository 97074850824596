import styled from "styled-components";

export const StyledText = styled.p`
  font-size: ${(props) => (props.size || 12)}px;
  display: flex;
  text-transform: capitalize;
`;

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: ${(props) => (props.size || 14)}px;
  text-transform: capitalize;
`;
