import styled from "styled-components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import palette from "theme/palette";

export const StyledCard = styled(Card)`
  border-radius: 5px;
  max-width: 100%;
`;
export const StyledCardHeader = styled(CardHeader)`
  background-color: ${palette.table.header};
  min-height: 24px;
  padding: 4px 16px;

  .MuiCardHeader-title {
    color: ${palette.white};
  }
`;

export const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8px;
  padding: 16px 12px 8px;

  h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .MuiChip-root {
    height: 24px;
    padding: 0px 8px;
  }
`;

export const StyledCardActions = styled(CardActions)`
  padding-top: 0px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0px 8px 4px;
  margin-left: auto;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)") };
  transition: transform 0.2s ease-in-out,
`;

export const StyledCollapse = styled(Collapse)`
    background-color: ${palette.background.collapse};

    .MuiCardContent-root:last-child {
      padding-bottom: 16px;
    }
`;
