import qs from "qs";
import {
  OBJECT_KEYS,
  PARAMS_SERIALIZER_OPTIONS,
  LOCAL_STORAGE_NAMES,
  INDEX,
  PAGINATION,
} from "common/constants";
import { isEmpty, orderByAsc, isNull } from "common/helpers";
import { getItemFromLocalStorage, getEmployeeId } from "common/utils";
import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";

import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_PROCESS_AGREEMENT,
  PROCESS_AGREEMENT,
  PROCESS_AGREEMENT_LOADING,
  PROCESS_AGREEMENT_ERROR,
  GET_GENERAL_LIST_LOADING,
  GET_GENERAL_LIST,
  GET_GENERAL_LIST_ERROR,
  RESET_GENERAL_STATE,
} from "../../actionTypes/agreement";

const URL = {
  main: "/agreements",
  collaboratorsAgreements: "employees_agreements",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_PROCESS_AGREEMENT,
  });
};

export const resetStateGeneral = () => (dispatch) => {
  dispatch({
    type: RESET_GENERAL_STATE,
  });
};

export const getList = () => async (dispatch) => {
  dispatch({ type: GET_GENERAL_LIST_LOADING });
  try {
    const response = await configAxios.get(URL.main);
    dispatch({
      type: GET_GENERAL_LIST,
      payload: orderByAsc(response.data.agreements, OBJECT_KEYS.employee, OBJECT_KEYS.id),
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_GENERAL_LIST_ERROR);
  }
};

export const getCollaboratorsAgreements = () => async (dispatch) => {
  dispatch({ type: GET_LIST_LOADING });
  try {
    const response = await configAxios.get(URL.collaboratorsAgreements);
    dispatch({
      type: GET_LIST,
      payload: response.data.agreements,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getListPaginated = (page, query, managerData = null) => async (dispatch) => {
  const employeeId = getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  dispatch({
    type: GET_LIST_LOADING,
  });

  const pagination = { size: PAGINATION.maxPerPage, number: page };
  try {
    const response = await configAxios.get(URL.collaboratorsAgreements, {
      params: { ...query, [OBJECT_KEYS.employeeId]: employeeId, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    const data = response.data.employees;
    if (!isNull(managerData) && data) {
      await data.unshift(managerData[INDEX.zero]);
    }

    dispatch({
      type: GET_LIST,
      payload: data,
      total: response.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getTotalAgreementsByEmployeeId = (employeeId) => async () => {
  try {
    const query = {
      q: {
        active_in: [true],
        id_eq: employeeId,
      },
    };
    const response = await configAxios.get(URL.collaboratorsAgreements, {
      params: { ...query, [OBJECT_KEYS.employeeId]: employeeId },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    return response.data.employees || [];
  } catch (error) {
    return error;
  }
};

export const getAgreementsByEmployeeId = (employeeId) => async () => {
  const query = {
    q: {
      employee_id_eq: employeeId,
    },
  };

  try {
    const response = await configAxios.get(URL.main, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data?.agreements || [];
  } catch (error) {
    return error;
  }
};

export const getAgreementsChildrensByManager = (managerId) => async () => {
  const query = {
    q: {
      manager_id_special_in: [managerId],
    },
  };

  try {
    const response = await configAxios.get(URL.main, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.agreements || [];
  } catch (error) {
    return error;
  }
};

export const create = (data) => async (dispatch) => {
  dispatch({
    type: PROCESS_AGREEMENT_LOADING,
  });
  try {
    const response = await configAxios.post(URL.main, data);
    dispatch({
      type: PROCESS_AGREEMENT,
      payload: response.data.agreement,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_AGREEMENT_ERROR);
  }
};

export const update = (agreementId, data) => async (dispatch) => {
  dispatch({
    type: PROCESS_AGREEMENT_LOADING,
  });
  try {
    const response = await configAxios.put(`${URL.main}/${agreementId}`, data);
    dispatch({
      type: PROCESS_AGREEMENT,
      payload: response.data.agreement,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_AGREEMENT_ERROR);
  }
};

export const deleteItem = (agreementId) => async (dispatch) => {
  dispatch({
    type: PROCESS_AGREEMENT_LOADING,
  });
  try {
    const response = await configAxios.delete(`${URL.main}/${agreementId}`);
    dispatch({
      type: PROCESS_AGREEMENT,
      payload: isEmpty(response.data),
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_AGREEMENT_ERROR);
  }
};

// React query
export const getTotalAgreementsByEmployee = async (employeeId) => {
  if (employeeId) {
    const query = {
      q: {
        active_in: [true],
        id_eq: employeeId,
      },
    };
    const response = await configAxios.get(URL.collaboratorsAgreements, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.employees || [];
  }
};

export const getAgreementsPaginated = async (page, query, size = PAGINATION.maxPerPage) => {
  const pagination = { size, number: page };

  const response = await configAxios.get(URL.collaboratorsAgreements, {
    params: { ...query, page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { employees } = response.data;
  return {
    data: employees,
    total: response.headers["x-total"],
  };
};

export const getAgreementsPaginatedByEmployeeId = async (employeeId, size = PAGINATION.maxPerPage, page = 1) => {
  const pagination = { size, number: page };
  const query = {
    q: {
      employee_id_eq: employeeId,
    },
  };

  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    const { agreements } = response.data;
    return agreements;
  } catch (error) {
    return error;
  }
};
