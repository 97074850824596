import { getPercent } from "common/utils";

export const getRows = (data, language, t) => data?.map((row) => ({
  id: row.id,
  name: row.employee?.full_name,
  manager: row.employee?.manager_name,
  location: row.employee?.country_name,
  position: row.employee?.position_name,
  good_leader_score: row.x_axis_score,
  leadership_score: getPercent(row.y_axis_score, true),
  children_nps: row?.leadership_score?.nps_total_response >= 3 ? getPercent(row.leadership_score.children_nps) : "",
  children_performance: row?.leadership_score ? getPercent(row.leadership_score?.children_performance, true) : "",
  voluntary_turnover: row?.leadership_score ? getPercent(row.leadership_score?.children_turnover, true) : "",
  leadership_types_matrix: row.leadership_type[`name_${language}`],
  performance: row.employee?.performance_results?.score,
  lossRisk: t(`talentDrain:${row.employee?.attrition_chart?.risk_category}`),
  survey_process_id: row.survey_process_id,
  employee: row.employee,
}));

export const getLeadershipDataToDownload = (leadershipResult, t, language) => {
  const data = [];
  leadershipResult.forEach((leadership) => {
    const { employee } = leadership;
    const performanceResult = employee?.performance_results?.result;
    const riskCategory = employee?.attrition_chart?.risk_category;
    const leadershipScore = leadership?.leadership_score;
    const isValidToShowNps = leadershipScore?.nps_total_response >= 3;
    data.push({
      [t("collaborators:table.table-head.personal_id")]: employee?.personal_id,
      [t("collaborators:table.table-head.name")]: employee?.full_name,
      [t("collaborators:table.table-head.manager")]: employee?.manager_name,
      [t("collaborators:table.table-head.position")]: employee?.position_name,
      [t("collaborators:table.table-head.country")]: employee?.country_name,
      [t("collaborators:table.table-head.city")]: employee?.city_name,
      [t("tables:headers.good_leader")]: getPercent(leadership.x_axis_score, true),
      [t("tables:headers.leadership_score")]: getPercent(leadership.y_axis_score, true),
      [`${t("engagement:nps")} ${t("tables:headers.team")}`]: isValidToShowNps ? getPercent(leadershipScore.children_nps) : "",
      [`${t("tables:headers.performance")} ${t("tables:headers.team")}`]: leadershipScore ? getPercent(leadershipScore?.children_performance, true) : "",
      [`${t("nalaAnalytics:rotation")} ${t("tables:headers.team")}`]: leadershipScore ? getPercent(leadershipScore?.children_turnover, true) : "",
      [t("leadership:leadership_types_matrix")]: leadership.leadership_type[`name_${language}`],
      [t("tables:headers.performance")]: performanceResult || "",
      [t("tables:headers.lossRisk")]: riskCategory ? t(`talentDrain:${riskCategory}`) : "",
    });
  });

  return data;
};
