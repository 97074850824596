import styled from "styled-components";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { ENGAGEMENT } from "theme/palette";

export const StyledTableRow = styled(TableRow)`
  .MuiTableCell-root {
    border-bottom: 0;
  }
  .MuiTableCell-head {
    background-color: ${ ENGAGEMENT.bgPrincipal };
    padding: 15px 24px 15px 16px;
    color: ${ ENGAGEMENT.white };
    .MuiIconButton-root, .MuiSvgIcon-root {
      color: ${ ENGAGEMENT.white };
    }
  }
  .MuiTableCell-root:first-child {
    width: 300px;
    text-align: left;
  }
  &:nth-of-type(even) {
    background-color: ${ ENGAGEMENT.row };
  }
`;

export const StyledTableCell = styled(TableCell)`
  background: ${(props) => props.color};
  display: ${(props) => props.disabled && "none" };
  font-size: 14px;
  padding: 7px 16px;
`;

export const StyledIcon = styled.img`
  width: 24px;
  margin-left: 4px;
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  color: ${ ENGAGEMENT.selector.selectedFont };
`;

export const StyledRemoveCircleIcon = styled(RemoveCircleIcon)`
  color: ${ ENGAGEMENT.selector.selectedFont };
`;
