import qs from "qs";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import {
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
  SURVEY_PROCESS_TYPE,
  PARAMS_SERIALIZER_OPTIONS,
  METHODS,
  INDEX,
  EVENT_STATE_KEYS,
  PAGINATION,
  STATE,
} from "common/constants";
import {
  getItemFromLocalStorage, getUserId, getEmployeeId, isAdmin as isAdminFunction, isMainCompany,
} from "common/utils";
import { isEqual, isNull, isNullOrUndefined } from "common/helpers";
import { getProcessTypes } from "views/SurveyProcess/functions";
import axios from "axios";
import { getQueryWithSurveyResults } from "./functions";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_FOLLOW_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  GET_TEMPLATES_LIST_ERROR,
  RESET_STATE_ONE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE_PROCESS_SURVEY_PROCESSES,
  PROCESS_SURVEY_PROCESSES,
  PROCESS_SURVEY_PROCESSES_LOADING,
  PROCESS_SURVEY_PROCESSES_ERROR,
  RESET_STATE_PERFORMANCE_EVOLUTION,
  GET_LIST_PERFORMANCE_EVOLUTION_LOADING,
  GET_LIST_PERFORMANCE_EVOLUTION,
  GET_LIST_PERFORMANCE_EVOLUTION_ERROR,

  RESET_STATE_GOOD_LEADER_EVOLUTION,
  GET_LIST_GOOD_LEADER_EVOLUTION_LOADING,
  GET_LIST_GOOD_LEADER_EVOLUTION,
  GET_LIST_GOOD_LEADER_EVOLUTION_ERROR,

  RESET_STATE_SURVEY_RESULTS,
  GET_LIST_SURVEY_RESULTS,
  GET_LIST_LOADING_SURVEY_RESULTS,
  GET_LIST_ERROR_SURVEY_RESULTS,

  RESET_STATE_SURVEY_RESULTS_TO_DOWNLOAD,
  GET_LIST_SURVEY_RESULTS_TO_DOWNLOAD,
  GET_LIST_LOADING_SURVEY_RESULTS_TO_DOWNLOAD,
  GET_LIST_ERROR_SURVEY_RESULTS_TO_DOWNLOAD,

  RESET_STATE_EVALUATION,
  GET_LIST_LOADING_EVALUATION,
  GET_LIST_EVALUATION,
  GET_LIST_ERROR_EVALUATION,

  RESET_STATE_ONE_EVALUATION,
  GET_ONE_LOADING_EVALUATION,
  GET_ONE_EVALUATION,
  GET_ONE_ERROR_EVALUATION,

  RESET_STATE_ONE_PROCESS,
  GET_ONE_PROCESS,
  GET_ONE_PROCESS_LOADING,
  GET_ONE_PROCESS_ERROR,

  RESET_STATE_BY_EMPLOYEE,
  GET_LIST_BY_EMPLOYEE,
  GET_LIST_BY_EMPLOYEE_LOADING,
  GET_LIST_BY_EMPLOYEE_ERROR,

  RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION,
  GET_COLLABORATOR_PERFORMANCE_EVOLUTION,
  GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING,
  GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR,

  RESET_GET_EMPLOYEES_BY_AUDIENCE,
  GET_EMPLOYEES_BY_AUDIENCE,
  GET_EMPLOYEES_BY_AUDIENCE_LOADING,
  GET_EMPLOYEES_BY_AUDIENCE_ERROR,

  GET_LEADER_EVALUATORS,
  GET_LEADER_EVALUATORS_LOADING,
  GET_LEADER_EVALUATORS_ERROR,
  RESET_LEADER_EVALUATORS,

  GET_COLLABORATOR_EVALUATORS,
  GET_COLLABORATOR_EVALUATORS_LOADING,
  GET_COLLABORATOR_EVALUATORS_ERROR,
  RESET_COLLABORATOR_EVALUATORS,

  RESET_STATE_PROCESS_SCHEMA,
  PROCESS_SCHEMA,
  PROCESS_SCHEMA_LOADING,
  PROCESS_SCHEMA_ERROR,
} from "../actionTypes/surveyProcesses";

const URL = {
  main: "/survey_processes",
  asyncTask: "/async_task",
  evolution: "evolution",
  surveyResults: "survey_results",
  userIdParam: "user_id=",
  sendProcess: "send_process",
  sendProcessReminder: "send_process_reminder",
  evaluations: "evaluations",
  evaluationResults: "evaluation_results",
  evaluationAnswers: "save_evaluation_answers",
  resultsByEvaluationType: "results_by_evaluation_type",
  loadDataResponse: "load_data_response",
  participation: "participation",
  collaborators: "collaborators",
  downloadParticipation: "download_participation",
  surveyProcessTemplates: "survey_process_templates",
  evaluationSectionTemplates: "evaluation_section_templates",
  evaluators: "evaluators",
  participationSchema: "participation_schema",
  participationSchemas: "/participation_schemas",
  participations: "participations",
  sendProcessEmails: "send_process_emails",
  refresh: "refresh",
  publish: "publish",
  calibrationReasons: "calibration_reasons",
  calibrations: "calibrations",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PROCESS_SURVEY_PROCESSES,
  });
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ONE,
  });
};

export const resetStateEvaluations = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_EVALUATION,
  });
};

export const resetStateEvaluation = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ONE_EVALUATION,
  });
};

export const resetStateOneProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ONE_PROCESS,
  });
};

export const resetStateEmployeesByAudience = () => (dispatch) => {
  dispatch({
    type: RESET_GET_EMPLOYEES_BY_AUDIENCE,
  });
};

export const resetStateManagerEvaluators = () => (dispatch) => {
  dispatch({
    type: RESET_LEADER_EVALUATORS,
  });
};

export const resetStateCollaboratorEvaluators = () => (dispatch) => {
  dispatch({
    type: RESET_COLLABORATOR_EVALUATORS,
  });
};

export const resetStateProcessSchema = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PROCESS_SCHEMA,
  });
};

export const getList = (followUpFilter = "", query = null) => async (dispatch, getState) => {
  const { list } = getState().surveysReducer;

  const lastQuery = query || {
    q: {
      follow_up_process_id_not_null: followUpFilter,
    },
  };

  if (!isNull(query) || !list?.length || !!followUpFilter) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main, {
        params: {
          ...lastQuery,
        },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });

      dispatch({
        type: followUpFilter ? GET_FOLLOW_LIST : GET_LIST,
        payload: response.data.survey_processes,
        total: response.headers["x-total"],
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

export const resetStateByEmployee = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_BY_EMPLOYEE,
  });
};

export const getListByEmployee = (query = null) => async (dispatch) => {
  const user = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user);
  const employeeIdParam = isMainCompany(user) ? { [OBJECT_KEYS.employeeId]: getEmployeeId(user) } : null;

  dispatch({
    type: GET_LIST_BY_EMPLOYEE_LOADING,
  });
  try {
    const response = await configAxios.get(URL.main, {
      params: {
        ...query,
        ...employeeIdParam,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    const data = response.data.survey_processes;
    const hasDetails = (item) => !isNullOrUndefined(item?.process_detail);
    const statusValid = (item) => !isEqual(item?.process_detail?.status, EVENT_STATE_KEYS.doesNotApply);
    dispatch({
      type: GET_LIST_BY_EMPLOYEE,
      payload: data.filter(hasDetails).filter(statusValid),
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_BY_EMPLOYEE_ERROR);
  }
};

export const getSurveyProcessByUser = (employeeId, followUpFilter) => async () => {
  const query = {
    q: {
      follow_up_process_id_not_null: followUpFilter,
    },
  };

  try {
    const response = await configAxios.get(URL.main, {
      params: {
        [OBJECT_KEYS.employeeId]: employeeId,
        ...query,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.survey_processes.filter((item) => item.process_detail);
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export const getProcessById = (processId, query) => async (dispatch, getState) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  dispatch({
    type: GET_ONE_PROCESS_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${processId}`, {
      params: { ...query, [OBJECT_KEYS.userId]: userId },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_ONE_PROCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_PROCESS_ERROR);
  }
};

export const getEvaluationResultBySurveyProcessId = (surveyProcessId, query = null) => async () => {
  try {
    const EVALUATION_RESULT_URL = `${URL.main}/${surveyProcessId}/${URL.evaluationResults}`;
    const response = await configAxios.get(EVALUATION_RESULT_URL, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.evaluation_results;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export const getOne = (processType, processId) => async (dispatch, getState) => {
  const { one } = getState().surveysReducer;
  const SURVEY_PROCESS_URL = `${processType}/${processId}`;

  if (!one?.length) {
    dispatch({
      type: GET_ONE_LOADING,
    });
    try {
      const response = await configAxios.get(SURVEY_PROCESS_URL);
      dispatch({
        type: GET_ONE,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_ONE_ERROR);
    }
  }
};

export const createOrUpdate = (
  data,
  processType,
  processId,
  t,
  transition = null,
) => async (dispatch, getState) => {
  // Retrieve successProcess flag from the surveysReducer state
  const { successProcess } = getState().surveysReducer;
  // Construct the URL for the survey process
  const SURVEY_PROCESS_URL = `${processType}/${processId || ""}`;
  // Determine the axios method (PUT or POST) based on the presence of a processId
  const configAxiosMethod = configAxios[processId ? METHODS.put : METHODS.post];
  // Find the processKey based on the data type and translation function (t)
  const processKey = getProcessTypes(t).find(
    (process) => isEqual(data.type, process.value),
  )?.itemData;

  // Only proceed if there is not an ongoing successProcess
  if (!successProcess) {
    // Dispatch a loading action before making the request
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });

    // Prepare the data for the survey process
    const surveyProcessData = {
      [`${processKey}`]: data,
    };

    try {
      // First request with surveyProcessData
      let response = await configAxiosMethod(SURVEY_PROCESS_URL, surveyProcessData);

      // If `transition` is provided, make a second request with transition data
      if (transition) {
        const transitionData = {
          [`${processKey}`]: transition,
        };
        response = await configAxiosMethod(SURVEY_PROCESS_URL, transitionData);
      }

      // Dispatch the result of the last request made
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: response.data,
      });
    } catch (error) {
      // Handle any errors that occur during the process
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const deleteItem = (
  processType,
  processId,
) => async (dispatch, getState) => {
  const { successProcess } = getState().surveysReducer;
  const SURVEY_PROCESS_URL = `${processType}/${processId}`;

  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });

    try {
      const response = await configAxios.delete(SURVEY_PROCESS_URL);
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: isEmpty(response.data),
      });
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const sendSurveyProcess = (processId, isReminder = false) => async (dispatch, getState) => {
  const { successProcess } = getState().surveysReducer;
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${isReminder ? URL.sendProcessReminder : URL.sendProcess}`;

  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });

    try {
      const response = await configAxios.post(SURVEY_PROCESS_URL);
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: response.data.async_task,
      });
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const getUploadStatus = (uploadProcessId) => async (dispatch) => {
  dispatch({
    type: PROCESS_SURVEY_PROCESSES_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.asyncTask}/${uploadProcessId}`);
    dispatch({
      type: PROCESS_SURVEY_PROCESSES,
      payload: response.data.async_task,
    });
  } catch (error) {
    errorHandler(error.status, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

// performance evolution

export const resetStatePerformanceEvolution = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PERFORMANCE_EVOLUTION,
  });
};

export const getPerformanceEvolution = (query) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));

  dispatch({
    type: GET_LIST_PERFORMANCE_EVOLUTION_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${URL.evolution}`, {
      params: {
        ...query,
        [OBJECT_KEYS.userId]: userId,
        [OBJECT_KEYS.type]: SURVEY_PROCESS_TYPE.performance.key,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GET_LIST_PERFORMANCE_EVOLUTION,
      payload: response.data.performance_processes,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_PERFORMANCE_EVOLUTION_ERROR);
  }
};

export const resetStateCollaboratorPerformanceEvolution = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION,
  });
};

export const getCollaboratorPerformanceEvolution = (collaboratorId) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));

  const query = {
    q: {
      employee_id_eq: collaboratorId,
    },
  };

  dispatch({
    type: GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${URL.evolution}`, {
      params: {
        ...query,
        [OBJECT_KEYS.userId]: userId,
        [OBJECT_KEYS.type]: SURVEY_PROCESS_TYPE.performance.key,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    let data = response.data.performance_processes;
    if (data) {
      data = data.filter((item) => item?.id && !isNull(item.average_result));
    }
    dispatch({
      type: GET_COLLABORATOR_PERFORMANCE_EVOLUTION,
      payload: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR);
  }
};

// survey results

export const resetStateSurveyResults = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_SURVEY_RESULTS,
  });
};

export const getSurveyResults = (processId, isEmptyFilter, page, query) => async (dispatch) => {
  let object = null;
  let dispatchRequest = true;
  const lastQuery = getQueryWithSurveyResults(query);

  if (isEmptyFilter) {
    object = lastQuery.q;
    dispatchRequest = Object.values(object).map((item) => !isEmpty(item));
  }

  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const SURVEY_RESULTS_URL = `${URL.main}/${processId}/${URL.surveyResults}`;
  const pagination = { size: PAGINATION.maxPerPage, number: page };

  dispatch({
    type: GET_LIST_LOADING_SURVEY_RESULTS,
  });
  try {
    let responseList = [];
    let total = 0;
    if (!isEmptyFilter || (isEmptyFilter && dispatchRequest.find((item) => item === true))) {
      const response = await configAxios.get(SURVEY_RESULTS_URL, {
        params: { ...lastQuery, [OBJECT_KEYS.userId]: userId, page: pagination },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });

      responseList = response.data.survey_results;
      total = response.headers["x-total"];
    }

    dispatch({
      type: GET_LIST_SURVEY_RESULTS,
      payload: responseList,
      total,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR_SURVEY_RESULTS);
  }
};

export const resetStateSurveyResultsToDownload = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_SURVEY_RESULTS_TO_DOWNLOAD,
  });
};

export const getSurveyResultsToDownload = (processId, query, total, downloadSectionQuestions = false) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const SURVEY_RESULTS_URL = `${URL.main}/${processId}/${URL.surveyResults}`;
  query.q.score_not_null = INDEX.one;

  if (downloadSectionQuestions) {
    query.download = true;
  }

  dispatch({
    type: GET_LIST_LOADING_SURVEY_RESULTS_TO_DOWNLOAD,
  });
  try {
    const response = await configAxios.get(SURVEY_RESULTS_URL, {
      params: {
        ...query,
        [OBJECT_KEYS.userId]: userId,
        page: { size: total, number: INDEX.one },
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GET_LIST_SURVEY_RESULTS_TO_DOWNLOAD,
      payload: response.data.survey_results,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR_SURVEY_RESULTS_TO_DOWNLOAD);
  }
};

// goodleader evolution

export const resetStateGoodLeaderEvolution = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_GOOD_LEADER_EVOLUTION,
  });
};

export const getGoodLeaderEvolution = (query) => async (dispatch, getState) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const { goodLeaderEvolution } = getState().surveysReducer;
  if (!isNull(query) || !goodLeaderEvolution) {
    dispatch({
      type: GET_LIST_GOOD_LEADER_EVOLUTION_LOADING,
    });
    try {
      const response = await configAxios.get(`${URL.main}/${URL.evolution}`, {
        params: {
          ...query,
          [OBJECT_KEYS.userId]: userId,
          [OBJECT_KEYS.type]: SURVEY_PROCESS_TYPE.goodLeader.key,
        },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });

      let data = response.data.good_leader_processes;
      if (data) {
        data = data.filter((item) => item?.id && !isNull(item.average_result));
      }
      dispatch({
        type: GET_LIST_GOOD_LEADER_EVOLUTION,
        payload: data,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_GOOD_LEADER_EVOLUTION_ERROR);
    }
  }
};

// evaluations
export const getEvaluationsByProcess = (processId) => async (dispatch) => {
  const SURVEY_PROCESS_EVALUATIONS_URL = `${URL.main}/${processId}/${URL.evaluations}`;

  dispatch({
    type: GET_LIST_LOADING_EVALUATION,
  });

  try {
    const response = await configAxios.get(SURVEY_PROCESS_EVALUATIONS_URL);

    const responseList = response.data.evaluations;

    dispatch({
      type: GET_LIST_EVALUATION,
      payload: responseList,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR_EVALUATION);
  }
};

export const getEvaluationById = (evaluationId) => async (dispatch, getState) => {
  const { evaluation } = getState().surveysReducer;
  if (!evaluation) {
    dispatch({
      type: GET_ONE_LOADING_EVALUATION,
    });
    try {
      const response = await configAxios.get(`${URL.evaluations}/${evaluationId}`);

      dispatch({
        type: GET_ONE_EVALUATION,
        payload: response.data.evaluation,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_ONE_ERROR_EVALUATION);
    }
  }
};

export const saveEvaluationAnswers = (
  data,
  processId,
) => async (dispatch, getState) => {
  const { successProcess } = getState().surveysReducer;

  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });

    try {
      const response = await configAxios.post(`${URL.main}/${processId}/${URL.evaluationAnswers}`, data);
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const saveEvaluationResultBySurveyProcessId = (
  data,
  processId,
) => async (dispatch) => {
  const EVALUATION_RESULT_URL = `${URL.main}/${processId}/${URL.evaluationResults}`;
  try {
    const response = await configAxios.post(EVALUATION_RESULT_URL, data);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const saveEvaluationResultAsync = (
  data,
  processId,
) => async (dispatch) => {
  const EVALUATION_RESULT_URL = `${URL.main}/${processId}/${URL.evaluationResults}`;
  try {
    const response = await configAxios.post(EVALUATION_RESULT_URL, data);
    return response.data.evaluation_result;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

export const updateEvaluationResultBySurveyProcessId = (
  data,
  processId,
  evaluationResultId,
) => async (dispatch, getState) => {
  const { successProcess } = getState().surveysReducer;

  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });
    const EVALUATION_RESULT_URL = `${URL.main}/${processId}/${URL.evaluationResults}/${evaluationResultId}`;

    try {
      const response = await configAxios.put(EVALUATION_RESULT_URL, data);
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

/**
 * @param {string} processId
 * @param {Object} query - optional query filters
 * @returns {Object} - data to download for the process id and query filters
 */
export const getDataToDownload = (processId, query = null) => async (dispatch) => {
  const user = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user);
  const isAdmin = isAdminFunction(user.userCookies);

  const employeeIdParam = isAdmin ? {} : { [OBJECT_KEYS.employeeId]: getEmployeeId(user) };
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${URL.resultsByEvaluationType}`;
  const EVALUATION_RESULT_URL = `${URL.main}/${processId}/${URL.evaluationResults}`;

  const newQuery = { ...query };
  if (newQuery.q) {
    delete newQuery.q.s;
    delete newQuery.q.score_not_null;
    delete newQuery.q.performance_score_not_null;
  }

  try {
    const process = await configAxios.get(`${URL.main}/${processId}`);
    const response = await configAxios.get(SURVEY_PROCESS_URL, {
      params: {
        ...employeeIdParam,
        ...newQuery,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    const evaluationResults = await configAxios.get(EVALUATION_RESULT_URL, {
      params: {
        ...employeeIdParam,
        ...newQuery,
        "q[aasm_state_eq]": STATE.answered,
        page: { size: INDEX.one },
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    const total = evaluationResults.headers["x-total"];
    const pagination = { size: total, number: INDEX.one };
    const evaluationResultsResponse = await configAxios.get(EVALUATION_RESULT_URL, {
      params: {
        ...employeeIdParam,
        ...newQuery,
        "q[aasm_state_eq]": STATE.answered,
        page: pagination,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    const data = {
      employees: response.data?.employees,
      results: evaluationResultsResponse.data?.evaluation_results,
      process: process.data,
    };
    return data || {};
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

/**
 * @param {string} processId
 * @param {Object} query - optional query filters
 * @returns {Object} - participation data to download for the process id and query filters
 */
export const getDataToDownloadParticipation = (processId, query = null) => async (dispatch) => {
  const user = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user);
  const isAdmin = isAdminFunction(user.userCookies);

  const employeeIdParam = isAdmin ? {} : { [OBJECT_KEYS.employeeId]: getEmployeeId(user) };
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${URL.resultsByEvaluationType}`;

  const newQuery = { ...query };
  if (newQuery.q) {
    delete newQuery.q.s;
    delete newQuery.q.score_not_null;
    delete newQuery.q.performance_score_not_null;
  }

  try {
    const process = await configAxios.get(`${URL.main}/${processId}`);
    const response = await configAxios.get(SURVEY_PROCESS_URL, {
      params: {
        ...employeeIdParam,
        ...newQuery,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    const data = {
      employees: response.data?.employees,
      process: process.data,
    };
    return data || {};
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

export const loadDataResponse = (processId) => async (dispatch, getState) => {
  const { successProcess } = getState().surveysReducer;
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${URL.loadDataResponse}`;
  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });
    try {
      const response = await configAxios.post(SURVEY_PROCESS_URL);
      return response.status;
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const getSurveyProcessParticipation = (processId, query) => async (dispatch, getState) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const newQuery = { ...query };
  delete newQuery.q.s;
  delete newQuery.q.score_not_null;
  delete newQuery.q.performance_score_not_null;

  dispatch({
    type: GET_ONE_PROCESS_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${processId}/${URL.participation}`, {
      params: { ...newQuery, [OBJECT_KEYS.userId]: userId },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_ONE_PROCESS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_PROCESS_ERROR);
  }
};

export const updateEvaluationResult = (
  data,
  processId,
  evaluationResultId,
) => async (dispatch) => {
  const EVALUATION_RESULT_URL = `${URL.main}/${processId}/${URL.evaluationResults}/${evaluationResultId}`;
  try {
    const response = await configAxios.put(EVALUATION_RESULT_URL, data);
    return response.data.evaluation_result;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAudienceListPaginated = (processId, page, query, maxPerPage = PAGINATION.maxPerPage) => async (dispatch) => {
  dispatch({
    type: GET_EMPLOYEES_BY_AUDIENCE_LOADING,
  });
  const pagination = { size: maxPerPage, number: page };
  try {
    const response = await configAxios.get(`${URL.main}/${processId}/${URL.collaborators}`, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GET_EMPLOYEES_BY_AUDIENCE,
      payload: response.data.collaborators,
      total: response.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_EMPLOYEES_BY_AUDIENCE_ERROR);
  }
};

export const sendParticipationToEmail = (
  processId,
  query,
) => async (dispatch) => {
  const DOWNLOAD_PARTICIPATION_URL = `${URL.main}/${processId}/${URL.downloadParticipation}`;
  try {
    const response = await configAxios.get(DOWNLOAD_PARTICIPATION_URL, {
      params: {
        ...query,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data?.async_task;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

/**
 * @param {number} evaluationResultId
 * @returns {none}
 */
export const deleteEvaluationResultById = (evaluationResultId) => async (dispatch) => {
  const DELETE_EVALUATION_RESULT_URL = `${URL.evaluationResults}/${evaluationResultId}`;
  try {
    await configAxios.delete(DELETE_EVALUATION_RESULT_URL);
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

export const getTemplatesList = (query = {}) => async (dispatch) => {
  try {
    const response = await configAxios.get(URL.surveyProcessTemplates, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.survey_process_templates;
  } catch (error) {
    errorHandler(error, dispatch, GET_TEMPLATES_LIST_ERROR);
  }
};

export const getEvaluationSectionTemplates = (templateId) => async (dispatch) => {
  const SECTION_URL = `${URL.surveyProcessTemplates}/${templateId}/${URL.evaluationSectionTemplates}`;
  try {
    const response = await configAxios.get(SECTION_URL);
    return response.data.evaluation_section_templates;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getTemplate = (templateId) => async (dispatch) => {
  const TEMPLATE_URL = `${URL.surveyProcessTemplates}/${templateId}`;
  try {
    const response = await configAxios.get(TEMPLATE_URL);
    return response.data.survey_process_template;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const createTemplate = (data) => async (dispatch) => {
  try {
    const response = await configAxios.post(URL.surveyProcessTemplates, data);
    return response.data.survey_process_template;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const updateTemplate = (data, templateId) => async (dispatch) => {
  const TEMPLATE_URL = `${URL.surveyProcessTemplates}/${templateId}`;
  try {
    const response = await configAxios.put(TEMPLATE_URL, data);
    return response.data.survey_process_template;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const createEvaluationSection = (data, templateId) => async (dispatch) => {
  const SECTION_URL = `${URL.surveyProcessTemplates}/${templateId}/${URL.evaluationSectionTemplates}`;
  try {
    const response = await configAxios.post(SECTION_URL, data);
    return response.data.evaluation_section_template;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const updateEvaluationSection = (data, sectionId) => async (dispatch) => {
  const SECTION_URL = `${URL.evaluationSectionTemplates}/${sectionId}`;
  try {
    const response = await configAxios.put(SECTION_URL, data);
    return response.data.evaluation_section_template;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const deleteEvaluationSection = (sectionId) => async (dispatch) => {
  const SECTION_URL = `${URL.evaluationSectionTemplates}/${sectionId}`;
  try {
    await configAxios.delete(SECTION_URL);
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getDraftSurveys = () => async (dispatch) => {
  const query = {
    q: {
      follow_up_process_id_not_null: false,
      state_eq: "drafted",
    },
  };
  try {
    const response = await configAxios.get(URL.main, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.survey_processes;
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const updateState = (
  processId,
  state,
  processType,
) => async (dispatch) => {
  const { value, itemData: processKey } = processType;

  const SURVEY_PROCESS_URL = `${value}/${processId}`;
  const surveyProcessData = {
    [`${processKey}`]: {
      state_transition: state,
      end_date: moment.utc().add(7, "days").endOf("day"),
    },
  };

  try {
    const response = await configAxios.put(SURVEY_PROCESS_URL, surveyProcessData);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

// evaluators

export const getLeaderEvaluators = (
  processId,
  page,
  originalQuery,
  isPending = true,
  maxPerPage = PAGINATION.maxPerPage,
) => async (dispatch) => {
  dispatch({
    type: GET_LEADER_EVALUATORS_LOADING,
  });
  const pagination = { size: maxPerPage, number: page };
  const query = JSON.parse(JSON.stringify(originalQuery));

  try {
    query.q.user_roles_name_eq = "manager";
    if (isPending) {
      query.pending = true;
    } else {
      query.finished = true;
    }
    const response = await configAxios.get(`${URL.main}/${processId}/${URL.evaluators}`, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GET_LEADER_EVALUATORS,
      payload: response.data.collaborators,
      leaderEvaluatorsTotal: response.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LEADER_EVALUATORS_ERROR);
  }
};

export const getCollaboratorEvaluators = (
  processId,
  page,
  originalQuery,
  isPending = true,
  maxPerPage = PAGINATION.maxPerPage,
) => async (dispatch) => {
  dispatch({
    type: GET_COLLABORATOR_EVALUATORS_LOADING,
  });
  const pagination = { size: maxPerPage, number: page };
  const query = JSON.parse(JSON.stringify(originalQuery));

  try {
    query.q.id_exclude_role = "manager";
    if (isPending) {
      query.pending = true;
    } else {
      query.finished = true;
    }
    const response = await configAxios.get(`${URL.main}/${processId}/${URL.evaluators}`, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GET_COLLABORATOR_EVALUATORS,
      payload: response.data.collaborators,
      collaboratorEvaluatorsTotal: response.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_COLLABORATOR_EVALUATORS_ERROR);
  }
};

export const getParticipationSchema = async (processId) => {
  const query = {
    q: {
      active_in: [true],
    },
  };
  // const pagination = { size: "50" };
  const response = await configAxios.get(`${URL.main}/${processId}/${URL.participationSchema}`, {
    params: { ...query /* page: pagination */ },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { participations } = response.data;
  return participations;
};

export const createOrUpdateAsync = (
  data,
  processType,
  processId,
  t,
  transition = null,
) => async (dispatch, getState) => {
  // Retrieve successProcess flag from the surveysReducer state
  const { successProcess } = getState().surveysReducer;
  // Construct the URL for the survey process
  const SURVEY_PROCESS_URL = `${processType}/${processId || ""}`;
  // Determine the axios method (PUT or POST) based on the presence of a processId
  const configAxiosMethod = configAxios[processId ? METHODS.put : METHODS.post];
  // Find the processKey based on the data type and translation function (t)
  const processKey = getProcessTypes(t).find(
    (process) => isEqual(data.type, process.value),
  )?.itemData;

  // Only proceed if there is not an ongoing successProcess
  if (!successProcess) {
    // Prepare the data for the survey process
    const surveyProcessData = {
      [`${processKey}`]: data,
    };

    try {
      // First request with surveyProcessData
      let response = await configAxiosMethod(SURVEY_PROCESS_URL, surveyProcessData);

      // If `transition` is provided, make a second request with transition data
      if (transition) {
        const transitionData = {
          [`${processKey}`]: transition,
        };
        response = await configAxiosMethod(SURVEY_PROCESS_URL, transitionData);
      }
      return response.data;
    } catch (error) {
      // Handle any errors that occur during the process
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const createParticipationSchema = (
  processId,
  isNalaProtected = false,
) => async (dispatch) => {
  const data = {
    participation_schema: {
      survey_process_id: processId,
      nala_protected: isNalaProtected,
    },
  };

  dispatch({
    type: PROCESS_SCHEMA_LOADING,
  });

  try {
    const response = await configAxios.post(URL.participationSchemas, data);
    dispatch({
      type: PROCESS_SCHEMA,
      payload: response.data.participation_schema,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SCHEMA_ERROR);
  }
};

export const getParticipationSchemaBySchema = async (participationSchemaId) => {
  // const pagination = { size: "50" };
  const response = await configAxios.get(`${URL.participationSchemas}/${participationSchemaId}/${URL.participations}`, {
    params: { /* page: pagination */ },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { participations } = response.data;
  return participations;
};

export const createEvaluator = (participationSchemaId, data) => async (dispatch) => {
  try {
    const response = await configAxios.post(`${URL.participationSchemas}/${participationSchemaId}/${URL.participations}`, data);
    return response.data.participation;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SCHEMA_ERROR);
  }
};

let lastRequestTime = 0;
const THROTTLE_TIME = 2000;

export const updateEvaluatorData = (participationId, data) => async (dispatch) => {
  const now = Date.now();
  if (now - lastRequestTime < THROTTLE_TIME) {
    return;
  }

  lastRequestTime = now;

  try {
    const response = await configAxios.put(`/${URL.participations}/${participationId}`, data);
    return response.data.participation;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SCHEMA_ERROR);
  }
};

export const createParticipationSchemaAsync = (
  processId,
  isNalaProtected = false,
) => async (dispatch) => {
  const data = {
    participation_schema: {
      survey_process_id: processId,
      nala_protected: isNalaProtected,
    },
  };
  try {
    const response = await configAxios.post(URL.participationSchemas, data);
    return response.data.participation_schema;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SCHEMA_ERROR);
  }
};

export const getProcessByIdAsync = (processId) => async (dispatch) => {
  try {
    const response = await configAxios.get(`${URL.main}/${processId}`, {
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_PROCESS_ERROR);
  }
};

export const deleteEvaluatorData = (participationId) => async (dispatch) => {
  const now = Date.now();
  if (now - lastRequestTime < THROTTLE_TIME) {
    return;
  }
  lastRequestTime = now;

  try {
    const response = await configAxios.delete(`/${URL.participations}/${participationId}`);
    return isEmpty(response.data);
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SCHEMA_ERROR);
  }
};

export const getParticipationSchemaBySchemaPaginated = async (
  participationSchemaId,
  page = 1,
  size = 200,
) => {
  const pagination = { number: page, size };
  const response = await configAxios.get(`${URL.participationSchemas}/${participationSchemaId}/${URL.participations}`, {
    params: { page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { participations, meta } = response.data;
  const total = parseInt(meta["X-Total"], 10);
  return {
    participations,
    total,
    page,
  };
};

export const getParticipationSchemaPaginated = async (
  processId,
  page = 1,
  size = 200,
) => {
  const query = {
    q: {
      active_in: [true],
    },
  };
  const pagination = { number: page, size };
  const response = await configAxios.get(`${URL.main}/${processId}/${URL.participationSchema}`, {
    params: { ...query, page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { participations, meta } = response.data;
  const total = parseInt(meta["X-Total"], 10);
  return {
    participations,
    total,
    page,
  };
};
export const getSurveyResultsToDownloadAsync = async (processId, query, page = 1, size = 200) => {
  const SURVEY_RESULTS_URL = `${URL.main}/${processId}/${URL.surveyResults}`;

  const newQuery = { ...query };
  newQuery.q.score_not_null = 1;
  newQuery.download = true;

  const pagination = { number: page, size };
  const response = await configAxios.get(SURVEY_RESULTS_URL, {
    params: { ...newQuery, page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });

  return {
    data: response.data.survey_results,
    page,
  };
};

export const updateParticipationSchema = (
  id,
  isNalaProtected = false,
) => async (dispatch) => {
  const data = {
    participation_schema: {
      nala_protected: isNalaProtected,
    },
  };

  dispatch({
    type: PROCESS_SCHEMA_LOADING,
  });

  try {
    const response = await configAxios.put(`${URL.participationSchemas}/${id}`, data);
    dispatch({
      type: PROCESS_SCHEMA,
      payload: response.data.participation_schema,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SCHEMA_ERROR);
  }
};

export const sendSurveyProcessReminder = (processId, data) => async (dispatch) => {
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${URL.sendProcessEmails}`;

  try {
    const response = await configAxios.post(SURVEY_PROCESS_URL, data);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

export const sendSurveyProcessReminders = (processId) => async (dispatch) => {
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${URL.sendProcessReminder}`;

  try {
    const response = await configAxios.post(SURVEY_PROCESS_URL);
    return response.data.async_task;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

export const getStatus = (uploadProcessId) => async (dispatch) => {
  try {
    const response = await configAxios.get(`${URL.asyncTask}/${uploadProcessId}`);
    return response.data.async_task;
  } catch (error) {
    errorHandler(error.status, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

const targetUrl = "https://backoffice.nala.rocks/template_trans";

export const translateSectionAndQuestions = async (data) => {
  try {
    const response = await axios.post(targetUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const sendUpdateParticipation = (processId) => async (dispatch) => {
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${URL.participationSchema}/${URL.refresh}`;

  try {
    const response = await configAxios.post(SURVEY_PROCESS_URL);
    return response.data.async_task;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

export const publishProcess = (processId) => async (dispatch) => {
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${URL.publish}`;

  try {
    const response = await configAxios.post(SURVEY_PROCESS_URL);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
  }
};

export const getCalibrationReasons = async () => {
  const response = await configAxios.get(`/${URL.calibrationReasons}`);
  return response.data.calibration_reasons;
};

export const getCalibrations = async () => {
  const response = await configAxios.get(`/${URL.calibrations}`);
  return response.data.calibrations;
};

export const createCalibration = async (data) => {
  const response = await configAxios.post(`/${URL.calibrations}`, data);
  return response.data.calibration;
};

export const getCalibrationBySurveyResult = async (surveyResultId) => {
  const query = {
    q: {
      survey_result_id_eq: surveyResultId,
    },
  };
  const response = await configAxios.get(`/${URL.calibrations}`, {
    params: { ...query },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data.calibrations[0];
};

export const updateCalibration = async (id, data) => {
  const response = await configAxios.put(`/${URL.calibrations}/${id}`, data);
  return response.data.calibration;
};
