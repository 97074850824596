import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledPaperContainer = styled(Paper)`
  margin-bottom: 16px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledGridContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const StyledSubtitleText = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${palette.subtitle};
`;

export const StyledGridEnd = styled(Grid)`
  text-align: right;
`;
