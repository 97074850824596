// Function to generate table rows
export const generateRows = (participation) => participation.map((row) => ({
  id: row.evaluator_id,
  name: row.employee.full_name,
  is_active: row.employee.is_active,
  position: row.employee?.job_position?.position?.name,
  manager: row.employee?.manager_info?.full_name,
  organization_unit_path: row.employee?.job_position?.units_complete_path,
  participation_types: row.participation_types,
  employee: row.employee,
  filters: {
    managerId: row.employee?.manager_info?.id,
    countryId: row.employee?.job_position?.country?.id,
    cityId: row.employee?.job_position?.city?.id,
    unitIds: row.employee?.job_position?.units_path_ids || [],
    isActive: row.employee.is_active ? 1 : 0,
  },
  participationId: row?.id,
}));

// Order of evaluation types to be displayed in the table
export const evaluationOrder = [
  "autoevaluation",
  "collaborators_evaluation",
  "manager_evaluation",
  "pairs_evaluation",
];

// Function to truncate name to a maximum of 12 characters
export const truncateName = (name, maxLength = 12) => (name.length > maxLength ? `${name.substring(0, maxLength)}...` : name);

export const getParticipationDataToDownload = (filteredRows, t) => {
  const data = [];

  // Get evaluation types from the first element
  const firstRowEvaluations = filteredRows[0]?.participation_types.map(
    (evaluation) => evaluation.evaluation_type,
  ) || [];

  // Order evaluation types according to evaluationOrder and filter out non-existing types
  const orderedEvaluationTypes = evaluationOrder.filter(
    (type) => firstRowEvaluations.includes(type),
  );

  filteredRows.forEach((evaluator) => {
    const row = {
      [t("tables:headers.state")]: t(`tables:state.${ evaluator.is_active ? "active" : "inactive"}`),
      [t("collaborators:table.table-head.personal_id")]: evaluator.employee.personal_id,
      [t("collaborators:table.table-head.name")]: evaluator.name,
      [t("collaborators:table.table-head.email")]: evaluator.employee?.email,
      [t("collaborators:table.table-head.manager")]: evaluator?.manager || "",
      [t("collaborators:table.table-head.position")]: evaluator?.position || "",
      [t("collaborators:table.table-head.country")]: evaluator.employee.job_position?.country?.name || "",
      [t("collaborators:table.table-head.city")]: evaluator.employee.job_position?.city?.name || "",
      [t("administrator:modules.positions.header.unit")]: evaluator?.organization_unit_path || "",
    };

    // Add evaluation columns
    orderedEvaluationTypes.forEach((type) => {
      const evaluation = evaluator.participation_types.find(
        (item) => item.evaluation_type === type,
      );

      if (type === "autoevaluation") {
        row[t(`participation:evaluations.${type}`)] = evaluation && evaluation.evaluatees.length > 0
          ? t("common:common.yes")
          : t("common:common.no");
      } else {
        const employeesToEvaluate = evaluation
          ? evaluation.evaluatees.map((emp) => emp.full_name).join(", ")
          : "";
        row[t(`participation:evaluations.${type}`)] = employeesToEvaluate;
      }
    });

    data.push(row);
  });

  return data;
};
