import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS, PAGINATION } from "common/constants";
import configAxios from "../../configAxios";

const URL = {
  main: "/leadership_type_results",
};

export const getLeadershipTypeResults = async (query, page) => {
  if (query?.q) {
    query.q.currents = true;
    query.q.employee_active_in = [true];
    const pagination = { size: PAGINATION.maxResultPerPage, number: page };

    const response = await configAxios.get(URL.main, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return {
      data: response.data.leadership_type_results,
      total: Number(response.data.meta["X-Total"]),
    };
  }
};

export const getLeadershipTypeResultsByEmployee = async (employeeId, currentLeadershipTypeId = null) => {
  if (employeeId) {
    const query = {
      q: {
        employee_id_eq: employeeId,
        s: "calculated_at desc",
      },
    };
    if (currentLeadershipTypeId) {
      query.q.id_not_eq = currentLeadershipTypeId;
    }
    const response = await configAxios.get(URL.main, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.leadership_type_results;
  }
};
