import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  link: {
    textTransform: "none",
    padding: 0,
    textDecoration: "underline",
    fontSize: "11px",
    "& :hover": {
      backgroundColor: theme.palette.white,
    },
  },
  comments: {
    boxShadow: theme.palette.boxShadow.strong,
  },
  popper: {
    zIndex: 9999,
  },
}));
