import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ACTIONS, CODE_EDITOR, VARIANT } from "common/constants";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { Transition } from "./functions";
import { StyledDialog, StyledHeader, StyledDialogTitle } from "./styles";

const HalfScreenDialog = (props) => {
  const {
    isOpen, onClose, title, children, width,
  } = props;

  return (
    <StyledDialog
      fullScreen
      open={ isOpen }
      onClose={ onClose }
      TransitionComponent={ Transition }
      width={ width }
    >
      <StyledHeader>
        <IconButton
          color={ VARIANT.inherit }
          aria-label={ ACTIONS.close }
          onClick={ onClose }
        >
          <CloseIcon />
        </IconButton>
      </StyledHeader>
      <StyledDialogTitle>{ title }</StyledDialogTitle>
      <StyledScrollBar maxHeight={ CODE_EDITOR.totalPercentage }>
        <DialogContent>
          { children }
        </DialogContent>
      </StyledScrollBar>
    </StyledDialog>
  );
};

HalfScreenDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  children: PropTypes.element,
};

HalfScreenDialog.defaultProps = {
  children: null,
  width: null,
};

export default HalfScreenDialog;
