import styled from "styled-components";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const Container = styled.div`
  padding-top: 15px;
  & h6 {
    font-size: 11px;
    font-weight: 600;
  }
`;

export const Title = styled(Typography)`
  color: ${palette.linearProgress.text};
`;

export const Results = styled.div`
  display: flex;
`;

export const Bubble = styled.div`
  position: absolute;
  left: ${(props) => props.value}%;
  width: ${(props) => (props.size / 1.6) }px;
  height: ${(props) => (props.size / 1.6) }px;
  background: ${palette.linearProgress.completeLight};
  z-index: 999;
  border-radius: 50%;
  -webkit-border-radius: 50%; 
  -moz-border-radius: 50%; 
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const LinearProgressContainer = styled.div`
  & .MuiLinearProgress-root {
    height: 8px;
    border-radius: 5px;
  }
  & .MuiLinearProgress-colorPrimary {
    background-color: ${palette.linearProgress.incomplete};
  }
  & .MuiLinearProgress-bar {
    border-radius: 5 !important;
    background-color: ${palette.linearProgress.complete};
  }
`;

export const Progress = styled.div`
  position: relative;
`;
