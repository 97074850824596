import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Select from "components/Select";
import palette from "theme/palette";

export const StyledSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const StyledProfileImage = styled(Avatar)`
  &.MuiAvatar-root {
    width: 145px;
    height: 145px;
  }
  border: 7px solid ${palette.text.purpleLight};
  @media (max-width: 960px) {
    &.MuiAvatar-root {
      width: 120px;
      height: 120px;
    }
  }
`;

export const StyledContent = styled.div`
  margin-left: 25px;
  h3 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  p {
    font-size: 26px;
  }
`;

export const StyledSelect = styled(Select)`
  background-color: ${ palette.white };
  padding: 10px 0 10px 0;
  label + & {
    margin-top: 8px;
  }
`;

export const StyledGrid = styled(Grid)`
  margin: 20px 0 10px;
`;

export const StyledTitle = styled(Typography)`
  padding: 25px 0 8px;
  font-weight: 700;
  font-size: 20px;
`;

export const StyledName = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;
export const StyledPosition = styled(Typography)`
  &.MuiTypography-root {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;
