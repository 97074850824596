import PropTypes from "prop-types";
import HorizontalTimeline from "react-horizontal-timeline";
import NoDataMessage from "components/NoDataMessage";
import { isEmpty } from "common/helpers";
import palette from "theme/palette";
import LabelTimeline from "../LabelTimeline";
import { StyledContent } from "./styles";

const Timeline = (props) => {
  const {
    t,
    isNotValidToShowSalary,
    isOwnProfile,
    movementsHistoryListState,
    movementStates,
    handleTimelineValue,
  } = props;

  return (
    <StyledContent>
      {isEmpty(movementsHistoryListState.list) ? (
        <NoDataMessage />
      ) : (
        <HorizontalTimeline
          styles={ {
            background: palette.white,
            foreground: palette.linearProgress.primaryColor,
            marginTop: "40px",
            outline: palette.linearProgress.primaryColor,
          } }
          index={ movementStates.value }
          indexClick={ (index) => handleTimelineValue(index) }
          values={ movementsHistoryListState.list?.map((item) => item.date) }
          // eslint-disable-next-line react/no-unstable-nested-components
          getLabel={ (_, index) => (
            <LabelTimeline
              t={ t }
              data={ movementsHistoryListState.list[index] }
              notIncludeSalary={ isNotValidToShowSalary && !isOwnProfile }
            />
          ) }
          minEventPadding={ 80 }
          isOpenEnding={ false }
        />
      ) }
    </StyledContent>
  );
};

Timeline.propTypes = {
  t: PropTypes.func.isRequired,
  isNotValidToShowSalary: PropTypes.bool.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  movementsHistoryListState: PropTypes.object.isRequired,
  movementStates: PropTypes.object.isRequired,
  handleTimelineValue: PropTypes.func.isRequired,
};

export default Timeline;
