import {
  RESET_STATE_HC_MONTHLY,
  GET_HC_MONTHLY,
  GET_HC_MONTHLY_LOADING,
  GET_HC_MONTHLY_ERROR,
  RESET_STATE_CTC_MONTHLY,
  GET_CTC_MONTHLY,
  GET_CTC_MONTHLY_LOADING,
  GET_CTC_MONTHLY_ERROR,
} from "../actionTypes/hrAnalytics";

const initialState = {
  listHc: [],
  isLoadingHc: false,
  errorHc: "",
  listCtc: [],
  isLoadingCtc: false,
  errorCtc: "",
};

// eslint-disable-next-line default-param-last
const hrAnalyticsData = (state = initialState, action) => {
  switch (action.type) {
  case RESET_STATE_HC_MONTHLY:
    return {
      ...state,
      listHc: [],
      isLoadingHc: false,
      errorHc: "",
    };
  case RESET_STATE_CTC_MONTHLY:
    return {
      ...state,
      listCtc: [],
      isLoadingCtc: false,
      errorCtc: "",
    };
  case GET_HC_MONTHLY:
    return {
      ...state,
      listHc: action.payload,
      isLoadingHc: false,
    };
  case GET_HC_MONTHLY_LOADING:
    return {
      ...state,
      isLoadingHc: true,
    };
  case GET_HC_MONTHLY_ERROR:
    return {
      ...state,
      errorHc: action.payload,
      isLoadingHc: false,
    };
  case GET_CTC_MONTHLY:
    return {
      ...state,
      listCtc: action.payload,
      isLoadingCtc: false,
    };
  case GET_CTC_MONTHLY_LOADING:
    return {
      ...state,
      isLoadingCtc: true,
    };
  case GET_CTC_MONTHLY_ERROR:
    return {
      ...state,
      errorCtc: action.payload,
      isLoadingCtc: false,
    };
  default:
    return state;
  }
};

export default hrAnalyticsData;
