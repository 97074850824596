import Typography from "@mui/material/Typography";
import Button from "components/Button";
import palette from "theme/palette";
import styled from "styled-components";

export const StyledContent = styled.div`
  padding: 24px
`;

export const StyledTitle = styled(Typography)`
  color: ${ palette.black };
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 15px;
`;

export const StyledButton = styled(Button)`
  margin-top: 2%;
  padding: 10px 44px;
`;
