import PropTypes from "prop-types";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import DefaultBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

const Breadcrumbs = (props) => {
  const { data } = props;
  return (
    <div data-testid={ "breadcrumbs" }>
      {data && (
        <DefaultBreadcrumbs aria-label={ "breadcrumb" }>
          {data.map((link) => (_.isEmpty(link.path) ? (
            <Typography color={ "textPrimary" } key={ link.label }>
              {link.label}
            </Typography>
          ) : (
            <Link color={ "inherit" } href={ link.path } key={ link.label } underline={ "hover" }>
              {link.label}
            </Link>
          )))}
        </DefaultBreadcrumbs>
      )}
    </div>
  );
};

Breadcrumbs.propTypes = {
  data: PropTypes.array,
};

export default Breadcrumbs;
