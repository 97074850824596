import set from "lodash/set";
import {
  OBJECT_KEYS,
  STATE,
  DATE_FORMATS,
  LOCAL_STORAGE_NAMES,
} from "common/constants";
import {
  formatDate,
  getAmountFormat,
  getCurrencyCode,
  getValueByProp,
  getDivisions,
  getItemFromLocalStorage,
  getAreas,
} from "common/utils";
import { isNotValid } from "common/helpers";
import { findIdByName } from "common/functions/organizationUnits";

export const getSortedMovements = (mappedMovements) => {
  const sortedMovements = mappedMovements?.sort((a, b) => {
    const keyA = new Date(a.date);
    const keyB = new Date(b.date);
    return keyA > keyB ? 1 : -1;
  });
  return sortedMovements;
};

export const getMappedMovementList = (movements, t, employee) => {
  const mappedMovements = movements?.map((movement) => {
    const movementObject = {
      id: movement?.id,
      type: movement?.type,
      date: formatDate(movement?.date, DATE_FORMATS.es.timeline),
      position: movement?.job_position?.position_name,
      managerName: movement?.job_position?.manager_name,
    };
    if ((movement?.type === STATE.turnover)) {
      movementObject.reason = movement?.reason;
      movementObject.comments = movement?.comments;
      movementObject.finishContractType = movement?.voluntary ? t("account:movementsHistory.voluntary") : t("account:movementsHistory.involuntary");
    } else {
      movementObject.salary = `${getAmountFormat(movement?.salary?.gross_salary)} ${getCurrencyCode(movement?.salary?.currency) || ""}`;
    }

    return movementObject;
  });

  if (mappedMovements) {
    mappedMovements.push(
      {
        type: STATE.start,
        date: formatDate(employee?.starting_date, DATE_FORMATS.es.timeline),
        position: employee?.starting_job_position?.position_name,
        salary: `${getAmountFormat(employee?.starting_salary?.gross_salary)} ${getCurrencyCode(employee?.starting_salary?.currency) || ""}`,
        managerName: employee?.starting_job_position?.manager_name,
      },
    );
  }

  return mappedMovements || [];
};

export const stepContentTitle = (t, notIncludeSalary) => {
  const content = [
    { title: t("account:movementsHistory.date"), element: "date" },
    { title: t("account:movementsHistory.position"), element: "position" },
    { title: t("account:movementsHistory.salary"), element: "salary" },
    { title: t("account:movementsHistory.type"), element: "finishContractType" },
    { title: t("account:movementsHistory.reason"), element: "reason" },
    { title: t("account:movementsHistory.comments"), element: "comments" },
    { title: t("common:common.manager"), element: "managerName" },
  ];
  return notIncludeSalary ? content.filter((item) => (item.element !== "salary")) : content;
};

export const setMovementData = (data, collaborator, type) => {
  // Create an object to store movement data
  const movementData = {};

  // Check if the movement type is lateral or promotion
  if (type === OBJECT_KEYS.lateralMovement || type === OBJECT_KEYS.promotion) {
    // Set position, country, city and hierarchy level ID in movementData
    set(movementData, "job_position_attributes.position_id", getValueByProp(data?.job_position_attributes?.position_id, OBJECT_KEYS.id));
    set(movementData, "job_position_attributes.country_id", collaborator?.job_position?.country?.id);
    set(movementData, "job_position_attributes.city_id", collaborator?.job_position?.city?.id);
    set(movementData, "job_position_attributes.hierarchy_level_id", collaborator?.job_position?.hierarchy_level?.id);
    // Initialize an empty array to store organization unit IDs
    const listUnits = [];
    const divisionId = data?.job_position_attributes?.division_id;
    const areaId = data?.job_position_attributes?.area_id;

    if (divisionId) {
      listUnits.push(divisionId);
      if (areaId) {
        listUnits.push(areaId);
      }
    }
    // Set organization unit IDs in movementData
    set(movementData, "job_position_attributes.organization_unit_ids", listUnits);

    // Check and set manager ID in movementData
    if (data?.job_position_attributes?.manager_id) {
      set(movementData, "job_position_attributes.manager_id", getValueByProp(data?.job_position_attributes.manager_id, OBJECT_KEYS.id));
    }
  }

  // Check if the movement type is promotion or salary raise
  if (type === OBJECT_KEYS.promotion || type === OBJECT_KEYS.salaryRaise) {
    // Set gross salary and currency in movementData
    set(movementData, "salary_attributes.gross_salary", data?.salary_attributes?.gross_salary.replaceAll(",", ""));
    set(movementData, "salary_attributes.currency", getValueByProp(data?.salary_attributes?.currency, OBJECT_KEYS.nameWithCode));
  }
  // Set type of contract ID in movementData
  set(movementData, "job_position_attributes.type_of_contract_id", data?.job_position_attributes?.type_of_contract_id
    || collaborator?.job_position?.type_of_contract?.id);

  // Return the complete movement data
  return movementData;
};

export const getMinDate = (list) => ((list?.length === 0)
  ? new Date(0, 0, 0) : new Date(list[list.length - 1]?.date));
