import styled from "styled-components";
import palette from "theme/palette";
import constants from "../constants";

export const StyledTheadTitle = styled.p`
  font-size: ${constants.fontSize.standar};
  font-weight: ${constants.fontWeight.medium};
  color: ${palette.text.secondaryTitle};
`;

export const StyledThedSubtitle = styled.p`
  font-size: ${ (props) => props.fontSize || constants.fontSize.small};
  font-weight: ${(props) => props.fontWeight || constants.fontWeight.normal};
  color: ${(props) => props.color || palette.text.theadSubtitleText};
`;

export const StyledTitleWithSubtitle = styled.div`
  padding-left: ${ (props) => props.pl || "0" }px;
  h6 {
    line-height: 15px;
    font-weight: 500;
    margin-top: 6px;
  }
  svg {
    width: 16px;
    color: ${palette.text.content};
  }
  .MuiTypography-body1 {
    color: ${palette.text.content};
    font-size: 12px;
    margin-top: 2px;
  }
`;

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${ (props) => props.mt || "0" }px;
  .MuiTypography-body1 {
    margin-left: 4px;
  }
`;

export const StyledText = styled.div`
  font-weight: 400;
`;

export const StyledTextBold = styled.div`
  font-weight: 500;
`;

export const StyledCalibrationRow = styled.div`
  color: ${palette.background.lightDark};
  display: flex;
  align-items: center;
  gap: 6px;
  svg {
    width: 20px;
    color: ${palette.background.lightDark};
  }
`;
