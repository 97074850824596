import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SimpleNineBoxModal from "components/SimpleNineBoxModal";
import { RISK_CATEGORY } from "views_refactor/Profile/functions/lossRisk";
import { StyledTitleWithSubtitle, StyledRiskCategoryContent, StyledRiskCategory } from "../../styles";

const CollaboratorDetails = ({ collaborator }) => {
  const { t } = useTranslation(["common", "talentDrain"]);

  return (
    <>
      {collaborator?.results?.nineboxQuadrant?.quadrant >= 0 && (
        <SimpleNineBoxModal
          quadrantSelected={ collaborator.results.nineboxQuadrant.quadrant }
          updatedAt={ collaborator.results.nineboxQuadrant.updatedAt }
        />
      )}
      <StyledTitleWithSubtitle>
        {(collaborator?.job_position?.country?.name || collaborator?.country_name) && (
          <p>
            <span>
              {`${t("common:common.location")}: ${(collaborator?.job_position?.country?.name || collaborator?.country_name)}`}
            </span>
          </p>
        )}
      </StyledTitleWithSubtitle>
      {(collaborator?.risk_category || collaborator?.attrition_chart?.risk_category) && (
        <StyledRiskCategoryContent>
          <StyledRiskCategory
            color={ RISK_CATEGORY[(collaborator?.risk_category
                || collaborator?.attrition_chart?.risk_category)]?.color }
          />
          <h4>
            <span>{`${t("common:common.lossRisk")}:`}</span>
            {" "}
            {t(`talentDrain:${(collaborator?.risk_category || collaborator?.attrition_chart?.risk_category)}`)}
          </h4>
        </StyledRiskCategoryContent>
      )}
    </>
  );
};

CollaboratorDetails.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorDetails;
