import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledStepsContainer = styled.div`
  .MuiStepLabel-root {
    align-items: center;
  }
  .MuiStepConnector-alternativeLabel {
    left: calc(-50% + 25px);
    right: calc(50% + 25px);
  }
  .MuiButton-root {
    margin-top: 30px;
  }
`;

export const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-h6 {
    margin-left: 10px;
    font-size: 15px;
  }
`;

export const StyledBold = styled.span`
  font-weight: bold;
`;

export const StyledItemText = styled.span`
  padding: 10px 0;
  font-size: 15px;
`;

export const StyledGrid = styled(Grid)`
 margin-top: 8px;
`;
