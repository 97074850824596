import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import {
  TARGET, FORMAT_TYPE, VARIANT, INDEX,
} from "common/constants";
import { urlToEmbed } from "common/utils";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import {
  StyledVideo, StyledVideoMp4, StyledLink, StyledWebsite,
} from "./styles";

const ContentByFormat = (props) => {
  const { type, url, title } = props;
  const { t } = useTranslation("common");

  const getContent = {
    [FORMAT_TYPE.mp4.key]: (
      <StyledVideoMp4>
        <video
          controls
          src={ url }
        >
          <track
            default
            kind={ "captions" }
          />
        </video>
      </StyledVideoMp4>
    ),
    [FORMAT_TYPE.video.key]: (
      <StyledVideo>
        <iframe
          title={ title }
          src={ urlToEmbed(url) }
          frameBorder={ INDEX.zero }
          allowFullScreen
        />
      </StyledVideo>
    ),
    [FORMAT_TYPE.pdf.key]: (
      <StyledLink href={ url } target={ TARGET.blank }>
        <img alt={ t("upload_document") } src={ uploadImg } />
        <Typography variant={ VARIANT.h6 } >
          { `${t("common.see")} ${title}.pdf` }
        </Typography>
      </StyledLink>
    ),
    [FORMAT_TYPE.website.key]: (
      <Link href={ url } target={ TARGET.blank }>
        <StyledWebsite>
          { `${t("common.see")} ${title}` }
          <LaunchOutlined />
        </StyledWebsite>
      </Link>
    ),
  };

  return getContent[type];
};

ContentByFormat.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
};

ContentByFormat.defaultProps = {
  type: FORMAT_TYPE.website.key,
  title: "",
};

export default ContentByFormat;
