import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { TARGET } from "common/constants";
import { StyledLinkMore } from "./styles";

const CustomLink = (props) => {
  const {
    t, data, setShowAll, showMore, isPeerEvaluation, isTeamEvaluation, handleModal,
  } = props;

  if (showMore) {
    return (
      <StyledLinkMore onClick={ () => setShowAll(data.type) } data-testid={ "custom-link-show-more" }>
        {t("common:common.viewMore")}
      </StyledLinkMore>
    );
  }

  if (isPeerEvaluation) {
    return (
      <StyledLinkMore onClick={ () => handleModal() } data-testid={ "custom-link-peer-evaluation" }>
        {t("surveys:addPeer")}
      </StyledLinkMore>
    );
  }

  if (!data?.isCompleted && data.link) {
    return (
      <Link href={ data?.link } target={ TARGET.self }>
        { isTeamEvaluation ? t("surveys:evaluateAll") : t("common:common.evaluate")}
      </Link>
    );
  }
  return "";
};

CustomLink.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
  setShowAll: PropTypes.func,
  showMore: PropTypes.bool,
  isPeerEvaluation: PropTypes.bool,
  isTeamEvaluation: PropTypes.bool,
  handleModal: PropTypes.func,
};

CustomLink.defaultProps = {
  data: {},
  setShowAll: null,
  showMore: false,
  isPeerEvaluation: false,
  isTeamEvaluation: false,
  handleModal: null,
};

export default CustomLink;
