import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import theme from "theme";
import palette from "theme/palette";

const tabColors = {
  black: palette.black,
  grey: palette.title.subtitleColor,
  default: palette.text.link,
};

export const StyledTabContent = styled.div`
  margin: ${`${theme.spacing(2)}px`};
`;

export const StyledTabsContainer = styled(Grid)`
  margin-top: ${(props) => (props.mt ?? 20)}px;
  .MuiTab-root {
    padding: ${(props) => (props.spacing ? theme.spacing(1, props.spacing) : theme.spacing(1, 5))};
    font-size: ${(props) => (props.fontsize ? props.fontsize : 16)}px;
    font-weight: ${(props) => (props.normaltext ? 500 : 700)};
    letter-spacing: ${(props) => (props.uppercase ? "0.02857em" : "")};
    text-transform: ${(props) => (props.uppercase ? "uppercase" : "capitalize")};
  }
  .fuixUS .indicator {
    display: none;
  }
  .MuiButtonBase-root.Mui-selected {
    background: ${(props) => (props.withbackground ? palette.backgroundTab : "transparent")};
    color: ${(props) => (props.textcolor ? tabColors[props.textcolor] : tabColors.default)};
  }
  @media (min-width: 600px) {
    .MuiTab-root {
      min-width: 160px;
    }
  }
`;

export const StyledTitle = styled(Typography)`
  font-weight: ${ (props) => props.fontWeight || 500 };
  font-size: ${ (props) => props.fontSize || "18px" };
  line-height: ${ (props) => props.lineHeigth || "21px" };
  color: ${ (props) => props.color || theme.palette.subtitle };
  margin: 30px 0;
`;
