import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingBottom: 15,
    marginTop: "1rem",
  },
  checkbox: { marginRight: 8 },
  whiteAutocomplete: {
    "& .MuiInputBase-root": {
      background: "#FFF !important",
    },
  },
}));

export default useStyles;
