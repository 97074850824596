import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components/NoDataMessage";
import { MIN_VALUE, VARIANT } from "common/constants";
import { optionsBar, optionsSatistactionBarByDivision } from "../../functions/ChartOptions";
import LegendBySatisfaction from "../LegendBySatisfaction";
import { StyledSubtitle, StyledNpsTitle } from "../../styledComponents";

const NpsAndSatisfaction = (props) => {
  const { nps, satisfaction } = props;
  const { t } = useTranslation("engagement");

  return (
    <Grid container spacing={ 4 } data-testid={ "nps-and-satisfaction-view-component" }>
      {/* nps by department */}
      <Grid item xs={ 12 } md={ 6 }>
        <StyledSubtitle variant={ VARIANT.h4 }>
          {t("npsByDivision")}
        </StyledSubtitle>
        {nps?.labels?.length !== MIN_VALUE ? (
          <>
            <StyledNpsTitle variant={ VARIANT.bodyOne }>
              {t("npsDescription")}
            </StyledNpsTitle>
            <Bar data={ nps } options={ optionsBar } />
          </>
        ) : (
          <NoDataMessage />
        )}
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        {/* satisfaction by department */}
        <StyledSubtitle variant={ VARIANT.h4 }>
          {t("satisfactionByDivision")}
        </StyledSubtitle>
        {satisfaction?.labels?.length !== MIN_VALUE ? (
          <>
            <LegendBySatisfaction />
            <Bar
              data={ satisfaction }
              options={ optionsSatistactionBarByDivision }
            />
          </>
        ) : (
          <NoDataMessage />
        )}
      </Grid>
    </Grid>
  );
};

NpsAndSatisfaction.propTypes = {
  nps: PropTypes.object.isRequired,
  satisfaction: PropTypes.object.isRequired,
};

export default NpsAndSatisfaction;
