import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import ErrorIcon from "@mui/icons-material/ErrorOutlined";
import styled from "styled-components";
import { bgColor, iconColor } from "../../theme/palette";

export const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  background: ${(props) => bgColor[props.severity]};
  svg {
    width: 47px;
    height: 47px;
  }
  @media (max-width: 600px) {
    &.MuiAlert-root {
      display: block;
    }
    .MuiAlert-icon {
      display: block;
      text-align: center;
    }
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${iconColor.infoHexa};
`;
export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${iconColor.successHexa};
`;
export const StyledWarningIcon = styled(WarningIcon)`
  color: ${iconColor.warningHexa};
`;
export const StyledErrorIcon = styled(ErrorIcon)`
  color: ${iconColor.errorHexa};
`;
