import PropTypes from "prop-types";
import DownloadExcelButton from "components/DownloadExcelButton";
import TableGrid from "components/TableGrid";
import { EXCEL_FILES_NAME, MAX_PER_PAGE, ROWS_PER_PAGE } from "common/constants";
import { mainDownloadExcel, getCurrentLanguage } from "common/utils";
import { getLeadershipDataToDownload } from "./functions";
import { useDataTable } from "./hooks/useDataTable";

const LeadershipTable = (props) => {
  const {
    t,
    leadershipStates,
    handleOpenModal,
    history,
    isLoading,
  } = props;

  const {
    leadershipResult,
  } = leadershipStates;

  const language = getCurrentLanguage();

  const { header, rows, isMobile } = useDataTable({
    t,
    history,
    handleOpenModal,
    data: leadershipResult,
  });

  const handleDownload = () => {
    const data = getLeadershipDataToDownload(leadershipResult, t, language);
    mainDownloadExcel(data, EXCEL_FILES_NAME.leadersReview);
  };

  return (
    <TableGrid
      rows={ rows }
      id={ "leadership-table-grid" }
      header={ header }
      paginationOptions={ {
        maxPerPage: MAX_PER_PAGE,
        rowsRange: ROWS_PER_PAGE,
      } }
      topContent={ (
        <DownloadExcelButton
          onClick={ () => handleDownload() }
        />
      ) }
      isMobile={ isMobile }
      isLoading={ isLoading }
    />
  );
};

LeadershipTable.propTypes = {
  processId: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  setTableSort: PropTypes.func.isRequired,
  defaultOrderName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  leadershipStates: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LeadershipTable;
