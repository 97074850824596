import inRange from "lodash/inRange";
import gte from "lodash/gte";
import { HEAT_MAP_VALUE_RANGE, HEAT_MAP_VALUE_RANGE_LEADER } from "common/constants";

export const tCellRangeColor = (value, isGoodLeaderDashboard = false) => {
  const valueRange = isGoodLeaderDashboard ? HEAT_MAP_VALUE_RANGE_LEADER : HEAT_MAP_VALUE_RANGE;
  const validationRange = [
    inRange(value, valueRange.critic.min, valueRange.critic.max),
    inRange(value, valueRange.low.min, valueRange.low.max),
    inRange(value, valueRange.medium.min, valueRange.medium.max),
    inRange(value, valueRange.normal.min, valueRange.normal.max),
    inRange(value, valueRange.excellent.min, valueRange.excellent.max),
    gte(value, valueRange.great.min),
  ];

  const getValidIndex = validationRange.indexOf(true);

  const classColors = ["tCellRed", "tCellRedLight", "tCellOrange", "tCellYellow", "tCellGreenDarker", "tCellGreen"];

  return classColors[getValidIndex] || "";
};

export const tCellOdd = (index) => (index % 2 === 0 ? "tCellWhite" : "tCellOdd");
