const FAQsList = (t) => [
  {
    id: "faq",
    name: t("common:faq"),
    href: t("common:faqUrl"),
  },
  {
    id: "userGuide",
    name: t("common:userGuide"),
    href: t("common:userGuideUrl"),
  },
  {
    id: "support",
    name: t("common:top-nav-bar.support"),
    href: t("common:contactFormSupport"),
  },
];

const FAQ_CONFIG = {
  es: ["faq", "userGuide", "support"],
  en: ["userGuide", "support"],
  pt: ["support"],
};

export const getFilteredFAQsList = (t, language = "es") => {
  const faqs = FAQsList(t);
  const config = FAQ_CONFIG[language];
  return faqs.filter((faq) => config.includes(faq.id));
};
