import PropTypes from "prop-types";
import AlertTitle from "@mui/material/AlertTitle";
import { INFO } from "common/constants";
import {
  StyledAlert,
  StyledInfoIcon,
  StyledCheckCircleIcon,
  StyledWarningIcon,
  StyledErrorIcon,
} from "./styles";

const Alert = (props) => {
  const {
    type, title, text,
  } = props;

  const alertIcon = {
    info: <StyledInfoIcon />,
    success: <StyledCheckCircleIcon />,
    warning: <StyledWarningIcon />,
    error: <StyledErrorIcon />,
  };

  return (
    <StyledAlert
      data-testid={ "alert-component" }
      icon={ alertIcon[type] }
      severity={ type }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {text}
    </StyledAlert>
  );
};

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
};

Alert.defaultProps = {
  type: INFO,
  title: null,
};

export default Alert;
