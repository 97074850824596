import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledFormControl = styled(FormControl)`
  margin-bottom: 8px;
`;

export const StyledSelect = styled(Select)`
  line-height: inherit !important;
  &:before,&:after{
    border-color: inherit!important;
    border-bottom: inherit!important;
  }
  .MuiSelect-select{
    display: flex;
    align-items: center;
    padding: 6px 0 7px;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    white-space: initial;
  }
  .MuiSvgIcon-root{
    width: 35px;
    height: 35px;
    margin-right: -10px;
    margin-top: -5px;
    color: ${palette.text.link};
    display: ${(props) => props.disabled && "none"};
  }
  .MuiAvatar-root {
    width: 45px;
    height: 45px;
  }
  .MuiListItem-root.Mui-selected, .MuiSelect-select:focus{
    background-color: transparent;
  }
  fieldset {
    border: 0;
  }
`;

export const StyledLabel = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
`;

export const StyledListSubheader = styled(ListSubheader)`
  &.MuiListSubheader-sticky {
    background-color: ${palette.white};
  }
`;
