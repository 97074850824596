import styled from "styled-components";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

export const StyledCard = styled(Card)`
  margin-top: 16px;
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiCardContent-root {
    padding: 18px 36px 30px;
  }
  .MuiCardContent-root.noPadding {
    padding: 0px;
    margin-bottom: 20px;
  }
  .card-title {
    margin-bottom: 30px
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;
