import { useState, useEffect } from "react";
import { getPatternImageByProcess } from "views/MatrixNineBox/functions";

export const usePatternImageNineBox = (results) => {
  // Declare a state variable patternImage and its updater function setPatternImage
  const [patternImage, setPatternImage] = useState("");

  useEffect(() => {
    if (results?.length > 0) {
      const fetchPatternImage = async () => {
        const result = await getPatternImageByProcess(results);
        // Set the pattern image in the state using setPatternImage
        setPatternImage(result);
      };
      // Call the fetchPatternImage function
      fetchPatternImage();
    }
  }, [results]);

  return patternImage;
};
