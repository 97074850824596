import makeStyles from "@mui/styles/makeStyles";
import { STATE_COLORS } from "theme/palette";
import withStyles from "@mui/styles/withStyles";
import Switch from "@mui/material/Switch";

export const SwitchStyled = withStyles((theme) => ({
  root: {
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: theme.palette.text.link,
    },
  },
}))(Switch);

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderTop: `3px solid ${theme.palette.text.iconDisabled}`,
  },
  paper: {
    background: theme.palette.white,
    padding: theme.spacing(4),
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    border: theme.palette.borderTab,
    borderRadius: "4px",
  },
  link: {
    color: theme.palette.link,
    textDecoration: "underline",
  },
  avatar: {
    margin: "5px",
  },
  deleteIcon: {
    color: STATE_COLORS.ERROR,
  },
  total_goals: {
    fontWeight: 500,
    textTransform: "lowercase",
  },
  smallImage: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
