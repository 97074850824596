import PropTypes from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ASC } from "common/constants";
import { useStyles, StyledCellHeader } from "../../styles";

const TableHeader = (props) => {
  const {
    order, orderBy, header, onRequestSort
  } = props;
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead data-testid={ "table-header" }>
      <TableRow>
        {header
          && header.map((headCell) => (
            <StyledCellHeader
              key={ headCell.id }
              align={ headCell.align }
              sortDirection={ orderBy === headCell.id ? order : false }
            >
              <TableSortLabel
                active={ orderBy === headCell.id }
                direction={ orderBy === headCell.id ? order : ASC }
                onClick={ createSortHandler(headCell.id) }
              >
                {headCell.label} {headCell.tooltip}
              </TableSortLabel>
            </StyledCellHeader>
          ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  header: PropTypes.array,
  onRequestSort: PropTypes.func,
};

export default TableHeader;
