import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import palette, { bgColor } from "theme/palette";

export const StyledGrid = styled(Grid)`
  &.MuiGrid-container{
    margin-top: 20px;
  }
  .MuiAlert-root {
    margin-bottom: 0px;
    margin-top: 16px;
  }
  h4 {
    font-weight: 700;
  }
  h5 {
    margin-top: 34px;
    font-weight: 700;
  }
  @media (max-width: 600px) {
    h5 {
      margin-top: 0px;
    }
  }
`;

export const StyledBox = styled(Box)`
  border: ${palette.border.simpleGray};
  border-radius: 4px;
  padding: 16px;
  min-height: 400px;
  background-color: ${palette.white};
  @media (max-width: 600px) {
    border: 0px;
    padding: 0px;
    min-height: 100%;
    background-color: transparent;
  }
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-body1 {
    margin: 10px 0;
  }
`;

export const StyledContent = styled.div`
  @media (max-width: 450px) {
    .MuiPaper-root {
      background-color: ${bgColor.infoHexa};
    }
  }
`;
