import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { SORT_COMPARATOR } from "common/constants";

export const getNewSelected = (selected, id) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];
  if (isEqual(selectedIndex, SORT_COMPARATOR.minusOne)) {
    newSelected = newSelected.concat(selected, id);
  } else if (isEqual(selectedIndex, SORT_COMPARATOR.zero)) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (isEqual(selectedIndex, selected.length - 1)) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > SORT_COMPARATOR.zero) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  return newSelected;
};

export const renderTableBody = (
  data,
  isSelected,
  findBy,
  isPulse,
  counter,
  classes,
  handleClick,
  header,
) => data?.map((row, index) => {
  const isItemSelected = isSelected(row[findBy]);
  const labelId = `table-checkbox-${index}`;
  return (
    <TableRow
      hover
      aria-checked={ isItemSelected }
      tabIndex={ -1 }
      key={ `${row.label}-${index}` }
      selected={ isItemSelected }
      className={ isPulse && isEqual(index, 0) && !isEqual(counter, 0)
        ? classes.newRowAdded
        : "" }
	>
      <TableCell padding={ "checkbox" }>
        <Checkbox
          checked={ isItemSelected }
          onClick={ (event) => handleClick(event, row[findBy]) }
          inputProps={ { "aria-labelledby": labelId } }
	/>
 </TableCell>
      {header.map((element, index) => {
        const valueToRender = get(row, element[findBy]);
        return element.customRender ? (
          <TableCell
            component={ "th" }
            id={ labelId }
            scope={ "row" }
            key={ `${row.label}-${index}` }
	>
            {element.customRender(row)}
 </TableCell>
        ) : (
          <TableCell key={ `${row.label}-${index}` }>{valueToRender}</TableCell>
        );
      })}
 </TableRow>
  );
});
