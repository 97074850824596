import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS, PAGINATION } from "common/constants";
import configAxios from "../../configAxios";

const URL = {
  main: "/talent_scores",
};

export const getList = async (query, page) => {
  if (query?.q) {
    query.q.currents = true;
    query.q.performance_score_not_null = 1;
    query.q.potential_score_not_null = 1;
    const pagination = { size: PAGINATION.maxResultPerPage, number: page };

    const response = await configAxios.get(URL.main, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return {
      data: response.data.talent_scores,
      total: Number(response.data.meta["X-Total"]),
    };
  }
};

export const getListByEmployee = async (employeeId, talentScoreId = null) => {
  if (employeeId) {
    const query = {
      q: {
        employee_id_eq: employeeId,
        performance_score_not_null: 1,
        potential_score_not_null: 1,
        s: "id desc", // TODO: order by date?
      },
    };
    if (talentScoreId) {
      query.q.id_not_eq = talentScoreId;
    }
    const response = await configAxios.get(URL.main, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.talent_scores;
  }
};

export const getCurrentTalentScoreByEmployee = async (employeeId) => {
  if (employeeId) {
    const query = {
      q: {
        employee_id_eq: employeeId,
        performance_score_not_null: 1,
        potential_score_not_null: 1,
        currents: true,
      },
    };
    const response = await configAxios.get(URL.main, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    if (response.data.talent_scores && response.data.talent_scores.length > 0) {
      return response.data.talent_scores[0];
    }
  }
  return {};
};
