import { ALIGN_ITEMS } from "common/constants";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledCardTitle = styled.div`
  font-size: 20px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyledFixedContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  font-size: 14px;
  text-align: ${(props) => (props.isCenter ? ALIGN_ITEMS.center : ALIGN_ITEMS.right)};
  margin-top: ${(props) => (props.isCenter ? "8px" : "0")};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
