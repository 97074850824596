import styled from "styled-components";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import ListItem from "@mui/material/ListItem";

export const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  downloadButton: {
    color: theme.palette.text.link,
    textTransform: "none",
    padding: 0,
    "&:hover": {
      background: "none",
      color: theme.palette.text.secondary,
    },
  },
  upload: {
    "& .MuiAvatar-root": {
      width: "auto",
      height: "auto",
      borderRadius: 0,
    },
  },
  uploadImg: {
    "& .MuiAvatar-root": {
      width: 20,
    },
  },
  docName: {
    paddingLeft: theme.spacing(0.5),
  },
  btnLink: {
    "& a": {
      color: theme.palette.text.link,
      fontWeight: 600,
      cursor: "pointer",
    },
  },
}));

export const ListItemStyled = withStyles((theme) => ({
  root: {
    paddingLeft: 0,
    "& img": {
      width: 20,
      marginRight: theme.spacing(1),
    },
  },
}))(ListItem);

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-bottom: 25px;
  }
`;
