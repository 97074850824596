import { useHomeCalendarHook } from "./hooks/homeCalendar.hook";
import HomeView from "./Home.view";

const HomeControllerTest = (props) => {
  const { dispatch, t } = props;
  const homeCalendarStates = useHomeCalendarHook({ dispatch, t });
  return <HomeView {...props} {...homeCalendarStates} />;
};

export default HomeControllerTest;
