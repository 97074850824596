import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import { iconColor } from "theme/palette";

export const StyledDialog = styled(Dialog)`
  text-align: center;
  .MuiDialog-paperWidthSm {
    padding: 16px;
    max-width: 100%;
  }
  .MuiDialogContent-root {
    padding: 8px;
  }
  .MuiTextField-root {
    margin-bottom: 8px;
  }
  .MuiDialogContent-root .MuiDialogContentText-root {
    max-width: 100%;
  }
  .MuiTypography-h6 {
    font-size: 20px;
  }
  .MuiIconButton-root {
    top: 8px;
    color: ${iconColor.closeHexa};
    right: 8px;
    position: absolute;
  }
  .MuiDialogActions-root{
    justify-content: center;
  }
`;

export const StyledDialogLoader = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 360px;
    background: transparent;
    box-shadow: none;
    min-height: 260px;
  }
`;

