import PropTypes from "prop-types";
import { ImageLoader } from "react-progressive-img-loader";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FEEDBACK_TYPE, VARIANT } from "common/constants";
import { isEqual } from "common/helpers";
import { getCurrentLanguage, getParseValue } from "common/utils";
import {
  Avatar,
  AcknowledgmentIcon,
  AcknowledgmentIconTitle,
  SSCMessageType,
} from "./styledComponents";
import { StyledGrid, StyledAvatarGrid, StyledCategoryTypography } from "./styles";

const CardMessage = (props) => {
  const {
    t,
    imageUrl,
    imageThumbnailUrl,
    name,
    position,
    date,
    type,
    acknowledgementElement,
    TypeImage,
    message,
    sscData,
  } = props;

  const avatar = imageThumbnailUrl && imageUrl ? (
    <ImageLoader
      src={ imageUrl }
      srcPreview={ imageThumbnailUrl }
      customImageComponent={ Avatar }
    />
  ) : !imageThumbnailUrl && (
    <Avatar src={ imageUrl } />
  );

  const feedbaackMessage = isEqual(type, FEEDBACK_TYPE.feedbackSSC) ? (
    <>
      <Grid item xs={ 12 }>
        <SSCMessageType>
          <Typography variant={ VARIANT.h6 }>{t("feedback:should_stop_do")}</Typography>
          <Typography variant={ VARIANT.bodyOne }>{getParseValue(sscData.should_stop_do)}</Typography>
        </SSCMessageType>
      </Grid>
      <Grid item xs={ 12 }>
        <SSCMessageType>
          <Typography variant={ VARIANT.h6 }>{t("feedback:should_start_do")}</Typography>
          <Typography variant={ VARIANT.bodyOne }>{getParseValue(sscData.should_start_do)}</Typography>
        </SSCMessageType>
      </Grid>
      <Grid item xs={ 12 }>
        <SSCMessageType>
          <Typography variant={ VARIANT.h6 }>{t("feedback:should_continue_do")}</Typography>
          <Typography variant={ VARIANT.bodyOne }>{getParseValue(sscData.should_continue_do)}</Typography>
        </SSCMessageType>
      </Grid>
    </>
  ) : (
    message
  );

  return (
    <Card data-testid={ "card-message-view" }>
      <Grid container>
        <StyledGrid container item xs={ 12 } sm={ 3 } md={ 5 } lg={ 3 }>
          <StyledAvatarGrid item xs={ 12 } sm={ 5 } md={ 3 }>
            { avatar }
          </StyledAvatarGrid>
          <Grid item xs={ 12 } sm={ 8 } md={ 9 } >
            <Typography variant={ VARIANT.h6 }>{name}</Typography>
            <Typography variant={ VARIANT.subtitleOne }>{position}</Typography>
            <Typography variant={ VARIANT.subtitleTwo }>{date}</Typography>
            <StyledCategoryTypography variant={ "subtitle2" }>
              {TypeImage && <TypeImage />}
              {t(`feedback:${type}`)}
            </StyledCategoryTypography>
          </Grid>
        </StyledGrid>
        <StyledGrid item xs={ 8 } sm={ 7 } md={ 5 } lg={ 7 }>
          { feedbaackMessage }
        </StyledGrid>
        {acknowledgementElement && (
          <Grid item xs={ 4 } sm={ 2 } md={ 2 } lg={ 2 }>
            <AcknowledgmentIcon>
              <img src={ acknowledgementElement.external_img } alt={ acknowledgementElement.title } />
              <AcknowledgmentIconTitle>
                { acknowledgementElement[`title_${getCurrentLanguage()}`] }
              </AcknowledgmentIconTitle>
            </AcknowledgmentIcon>
          </Grid>
        )}

      </Grid>
    </Card>
  );
};

CardMessage.propTypes = {
  t: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageThumbnailUrl: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  position: PropTypes.string,
  type: PropTypes.string,
  acknowledgementElement: PropTypes.object,
  typeImage: PropTypes.element,
  message: PropTypes.string,
  sscData: PropTypes.object,
};

CardMessage.defaultProps = {
  imageThumbnailUrl: null,
  name: null,
  date: null,
  position: null,
  type: null,
  acknowledgementElement: null,
  typeImage: null,
  message: null,
  sscData: null,
};

export default CardMessage;
