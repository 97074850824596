import styled from "styled-components";
import theme from "theme";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LINEAL_GRAPHICS } from "theme/palette";

export const StyledContainer = styled.div`
  .MuiTab-root.Mui-selected {
    background: ${theme.palette.backgroundTab};
  }
`;

export const StyledCardContainer = styled(Card)`
  border-top: 3px solid ${theme.palette.text.iconDisabled};
  padding-top: 16px;
  box-shadow: none;
  border-radius: 0;
  .noDataCustom {
    padding: 0px;
    div {
      padding: 8px 0 0 0;
    }
  }
`;

export const StyledCard = styled(Paper)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
`;

export const StyledCardIcon = styled.div`
  display: flex;
  alignItems: center;
  margin: 10px 20px;
  .cardImage {
    margin-right: ${theme.spacing(0.7)};
  }
`;

export const StyledCardTitle = styled.div`
  font-weight: bold;
  margin: 10px;
`;

export const StyledEvaluationCard = styled(Grid)`
  .cardTitleBordered {
    border-left: 2px solid ${ LINEAL_GRAPHICS.LINE_COLOR};
    margin: 10px 20px;
  }
  .noDataCustom {
    padding: 0px;
    div {
      padding: 8px 0 0 0;
    }
  }
`;

export const StyledCardText = styled(Typography)`
  margin: 10px 20px;
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 32px;
`;

export const StyledPotentialDescription = styled(Typography)`
  margin-top: 16px;
`;

export const StyleBoxContainer = styled(Box)`
  width: 200px;
  .maxResultIcon {
    color: ${theme.palette.btnIcon.color};
    font-size: 24px;
    padding-left: 5px;
  }
`;
