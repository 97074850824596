import {
  RESET_STATE_RECEIVED,
  GET_LIST_RECEIVED,
  GET_LIST_LOADING_RECEIVED,
  GET_LIST_ERROR_RECEIVED,
  RESET_STATE_SENT,
  GET_LIST_SENT,
  GET_LIST_LOADING_SENT,
  GET_LIST_ERROR_SENT,
  RESET_STATE_PROCESS_FEEDBACK,
  PROCESS_FEEDBACK,
  PROCESS_FEEDBACK_LOADING,
  PROCESS_FEEDBACK_ERROR,
  RESET_STATE_FEEDBACKS_TO_DOWNLOAD,
  GET_LIST_FEEDBACKS_TO_DOWNLOAD,
  GET_LIST_FEEDBACKS_TO_DOWNLOAD_LOADING,
  GET_LIST_FEEDBACKS_TO_DOWNLOAD_ERROR,
} from "../actionTypes/feedback";

const INITIAL_STATE = {
  // received
  listReceived: null,
  isLoadingListReceived: false,
  errorListReceived: "",
  // sent
  listSent: null,
  isLoadingListSent: false,
  errorListSent: "",
  // success
  successProcess: null,
  isLoadingProcess: false,
  errorProcess: null,

  downloadList: null,
  isLoadingDownloadList: false,
  errorDownloadList: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE_RECEIVED:
    return {
      ...state,
      isLoadingListReceived: false,
      errorListReceived: null,
      listReceived: null,
    };
  case GET_LIST_RECEIVED:
    return {
      ...state,
      listReceived: action.payload,
      isLoadingListReceived: false,
      errorListReceived: "",
    };
  case GET_LIST_LOADING_RECEIVED:
    return {
      ...state,
      listReceived: null,
      isLoadingListReceived: true,
      errorListReceived: "",
    };
  case GET_LIST_ERROR_RECEIVED:
    return {
      ...state,
      isLoadingListReceived: false,
      errorListReceived: action.payload,
    };
  case RESET_STATE_SENT:
    return {
      ...state,
      isLoadingListSent: false,
      errorListSent: null,
      listSent: null,
    };
  case GET_LIST_SENT:
    return {
      ...state,
      listSent: action.payload,
      isLoadingListSent: false,
      errorListSent: "",
    };
  case GET_LIST_LOADING_SENT:
    return {
      ...state,
      listSent: null,
      isLoadingListSent: true,
      errorListSent: "",
    };
  case GET_LIST_ERROR_SENT:
    return {
      ...state,
      isLoadingListSent: false,
      errorListSent: action.payload,
    };
  case RESET_STATE_PROCESS_FEEDBACK:
    return {
      ...state,
      isLoadingProcess: false,
      errorProcess: null,
      successProcess: null,
    };
  case PROCESS_FEEDBACK:
    return {
      ...state,
      successProcess: action.payload,
      isLoadingProcess: false,
      errorProcess: "",
    };
  case PROCESS_FEEDBACK_LOADING:
    return {
      ...state,
      successProcess: null,
      isLoadingProcess: true,
      errorProcess: "",
    };
  case PROCESS_FEEDBACK_ERROR:
    return {
      ...state,
      isLoadingProcess: false,
      errorProcess: action.payload,
    };
  case RESET_STATE_FEEDBACKS_TO_DOWNLOAD:
    return {
      ...state,
      downloadList: null,
      isLoadingDownloadList: false,
      errorDownloadList: "",
    };
  case GET_LIST_FEEDBACKS_TO_DOWNLOAD_LOADING:
    return {
      ...state,
      isLoadingDownloadList: true,
    };
  case GET_LIST_FEEDBACKS_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: false,
      downloadList: action.payload,
    };
  case GET_LIST_FEEDBACKS_TO_DOWNLOAD_ERROR:
    return {
      ...state,
      isLoadingDownloadList: false,
      errorDownloadList: action.payload,
    };
  default:
    return state;
  }
};
