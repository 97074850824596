import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import palette, { bgColor } from "theme/palette";

export const StyledMainTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 15px;
`;

export const StyledIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 700;
`;

export const StyledCol = styled(Grid)`
  padding: 5px;
  .noDataCustom {
    padding: 0px;
    div {
      padding: 0;
    }
  }
`;

export const StyledContent = styled.div`
  padding: 10px;
  font-size: 14px;
  color: ${palette.text.secondaryTitle};
`;

export const StyledAdvise = styled.div`
  padding: 15px;
  margin-top: 15px;
  border-radius: 10px;
  font-size: 14px;
  background: ${bgColor.warningHexa};
  color: ${palette.text.secondaryTitle};
`;

export const StyledAdviseTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & svg {
    color: ${palette.text.link};
    margin-right: 5px;
  }
`;
