import { StyledPageContainer } from "./styles";

const PageContainerContainer = (props) => {
  const { children } = props;
  return (
    <StyledPageContainer>
      {children}
    </StyledPageContainer>
  );
};

export default PageContainerContainer;
