export const CIRCLE_SIZE = {
  0: 2.7,
  1: 2.3,
  2: 2,
  3: 1.8,
  4: 1.7,
  5: 1.6,
  6: 1.5,
  7: 1.4,
};

export const TRANSITIONS = {
  popper: "transitions-popper",
};

export const MATRIX_SWITCH_OPTIONS = {
  manager: 0,
  all: 1,
};
