import palette from "theme/palette";

export const options = (yMax) => ({
  legend: { display: false },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "nearest",
    intersect: true,
  },
  scales: {
    yAxes: [
      {
        afterTickToLabelConversion: (scaleInstance) => {
          scaleInstance.ticks[0] = null;
          scaleInstance.ticksAsNumbers[0] = null;
        },
        ticks: {
          fontSize: 10,
          fontFamily: "Roboto",
          color: palette.text.subtitle,
          min: 0,
          max: yMax,
          padding: 15,
          maxRotation: 0,
          minRotation: 0,
          callback(label) {
            return `${label }`;
          },
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 12,
          fontFamily: "Roboto",
          padding: 10,
          color: palette.black,
          maxRotation: 0,
          minRotation: 0,
          paddingLeft: 25,
        },
      },
    ],
  },
  responsive: true,
});
