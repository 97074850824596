import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette, { bgColor } from "theme/palette";

export const StyledGridHeader = styled(Grid)`
  align-items: center;
  margin-bottom: 16px;
`;

export const StyledTitleContent = styled.div`
  display: flex;
  align-items: center;

  img {
    border: 8px solid ${bgColor.info};
    border-radius: 50%;
  }

  h1 {
    max-width: 200px;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: ${palette.text.link};
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  margin: 2.5rem 0;
`;
