import { CRITIC_COLORS_RANGE } from "theme/palette";
import strengthsIcon from "assets/images/potential/skills/talented.svg";
import developmentIcon from "assets/images/potential/skills/developed.svg";
import opportunitiesIcon from "assets/images/potential/skills/to_develop.svg";

export const SECTIONS = {
  beAGoodCoach: "beAGoodCoach",
  empowerTeam: "empowerTeam",
  expressInterest: "expressInterest",
  beProductive: "beProductive",
  beAGoodCommunicator: "beAGoodCommunicator",
  helpTeam: "helpTeam",
  visionOfArea: "visionOfArea",
  technicalSkills: "technicalSkills",
  teamEnvironment: "teamEnvironment",
  collaborateUsCompany: "collaborateUsCompany",
  strongDecision: "strongDecision",
};

const urlTranslations = (t) => ({
  [SECTIONS.beAGoodCoach]: t("goodLeader:urls.beAGoodCoach", { returnObjects: true }),
  [SECTIONS.empowerTeam]: t("goodLeader:urls.empowerTeam", { returnObjects: true }),
  [SECTIONS.expressInterest]: t("goodLeader:urls.expressInterest", { returnObjects: true }),
  [SECTIONS.beProductive]: t("goodLeader:urls.beProductive", { returnObjects: true }),
  [SECTIONS.beAGoodCommunicator]: t("goodLeader:urls.beAGoodCommunicator", { returnObjects: true }),
  [SECTIONS.helpTeam]: t("goodLeader:urls.helpTeam", { returnObjects: true }),
  [SECTIONS.visionOfArea]: t("goodLeader:urls.visionOfArea", { returnObjects: true }),
  [SECTIONS.technicalSkills]: t("goodLeader:urls.technicalSkills", { returnObjects: true }),
  [SECTIONS.teamEnvironment]: t("goodLeader:urls.teamEnvironment", { returnObjects: true }),
  [SECTIONS.collaborateUsCompany]: t("goodLeader:urls.collaborateUsCompany", { returnObjects: true }),
  [SECTIONS.strongDecision]: t("goodLeader:urls.strongDecision", { returnObjects: true }),
});

export const RESULT_SECTIONS = (t) => {
  const allUrls = urlTranslations(t);
  return [
    {
      key: SECTIONS.beAGoodCoach,
      translations: [
        "Es un buen Coach",
        "Is a good Coach",
        "É um bom Coach",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.beAGoodCoach][0],
          url: "https://www.quantumworkplace.com/future-of-work/12-rules-for-effective-employee-coaching",
        },
        {
          title: allUrls[SECTIONS.beAGoodCoach][1],
          url: "https://hbr.org/2018/08/most-managers-dont-know-how-to-coach-people-but-they-can-learn",
        },
        {
          title: allUrls[SECTIONS.beAGoodCoach][2],
          url: "https://www.youtube.com/watch?v=C48hhI1P6XQ",
        },
        {
          title: allUrls[SECTIONS.beAGoodCoach][3],
          url: "https://www.youtube.com/watch?v=eQbRWBlAV5I",
        },
        {
          title: allUrls[SECTIONS.beAGoodCoach][4],
          url: "https://www.youtube.com/watch?v=e14EZlDT73o",
        },
      ],
    },
    {
      key: SECTIONS.empowerTeam,
      translations: [
        "Empodera y no hace micromanagement",
        "Empowers the team and does not micromanage",
        "Empodera e não faz microgerenciamento",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.empowerTeam][0],
          url: "https://online.hbs.edu/blog/post/how-to-empower-employees",
        },
        {
          title: allUrls[SECTIONS.empowerTeam][1],
          url: "https://leadershipchoice.com/empower-employees-in-the-workplace/",
        },
        {
          title: allUrls[SECTIONS.empowerTeam][2],
          url: "https://www.business.com/articles/trust-the-process-10-tips-to-empower-and-encourage-your-staff/",
        },
        {
          title: allUrls[SECTIONS.empowerTeam][3],
          url: "https://www.shrm.org/resourcesandtools/hr-topics/employee-relations/pages/empowering-employees-before-during-and-after-the-pandemic.aspx",
        },
        {
          title: allUrls[SECTIONS.empowerTeam][4],
          url: "https://www.youtube.com/watch?v=vBwdavUKHU4",
        },
        {
          title: allUrls[SECTIONS.empowerTeam][5],
          url: "https://www.youtube.com/watch?v=UFL57Es4mn8",
        },
        {
          title: allUrls[SECTIONS.empowerTeam][6],
          url: "https://www.youtube.com/watch?v=PYJ22-YYNW8",
        },
      ],
    },
    {
      key: SECTIONS.expressInterest,
      translations: [
        "Muestra preocupación e interés",
        "Expresses interest/concern for the team members success and personal well-being",
        "Expressa preocupação e interesse com o sucesso e o bem-estar pessoal dos membros da equipe",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.expressInterest][0],
          url: "https://www.linkedin.com/pulse/valuing-employees-workplace-manoj-guntupalli?trk=articles_directory",
        },
        {
          title: allUrls[SECTIONS.expressInterest][1],
          url: "https://www.forbes.com/sites/williamarruda/2017/11/08/9-ways-to-show-your-people-you-value-them/?sh=42da900228a0",
        },
        {
          title: allUrls[SECTIONS.expressInterest][2],
          url: "https://www.youtube.com/watch?v=aljb6ZXBwV0",
        },
      ],
    },
    {
      key: SECTIONS.beProductive,
      translations: [
        "Es productivo",
        "Es productivo/a y orientado/a a resultados",
        "Is productive and results oriented",
        "É produtivo e focado no resultado",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.beProductive][0],
          url: "https://hive.com/blog/how-to-be-a-productive-leader/",
        },
        {
          title: allUrls[SECTIONS.beProductive][1],
          url: "https://www.inc.com/peter-economy/7-daily-habits-of-exceptionally-productive-leaders.html",
        },
        {
          title: allUrls[SECTIONS.beProductive][2],
          url: "https://www.youtube.com/watch?v=-39xCZSXAsU",
        },
        {
          title: allUrls[SECTIONS.beProductive][3],
          url: "https://www.youtube.com/watch?v=RPVFVZC_ec0",
        },
        {
          title: allUrls[SECTIONS.beProductive][4],
          url: "https://www.youtube.com/watch?v=H9LSopkLbpw",
        },
      ],
    },
    {
      key: SECTIONS.beAGoodCommunicator,
      translations: [
        "Es un buen comunicador",
        "Es un buen comunicador - escucha y comparte información",
        "Is a good comunicator - listens and shares information",
        "É um bom comunicador - escuta e compartilha informações",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.beAGoodCommunicator][0],
          url: "https://www.ccl.org/articles/leading-effectively-articles/communication-1-idea-3-facts-5-tips/",
        },
        {
          title: allUrls[SECTIONS.beAGoodCommunicator][1],
          url: "https://crestcom.com/blog/2020/09/25/improve-communication-skills/",
        },
        {
          title: allUrls[SECTIONS.beAGoodCommunicator][2],
          url: "https://online.hbs.edu/blog/post/leadership-communication",
        },
        {
          title: allUrls[SECTIONS.beAGoodCommunicator][3],
          url: "https://www.indeed.com/career-advice/career-development/leadership-communication-skills",
        },
        {
          title: allUrls[SECTIONS.beAGoodCommunicator][4],
          url: "https://www.youtube.com/watch?v=zMUSEYR80E8",
        },
        {
          title: allUrls[SECTIONS.beAGoodCommunicator][5],
          url: "https://www.youtube.com/watch?v=6wN8OGngtFQ",
        },
      ],
    },
    {
      key: SECTIONS.helpTeam,
      translations: [
        "Ayuda al desarrollo del equipo",
        "Helps with career development",
        "Ajuda ao desenvolvimento da equipe",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.helpTeam][0],
          url: "https://www.betterup.com/blog/developing-teams",
        },
        {
          title: allUrls[SECTIONS.helpTeam][1],
          url: "https://theundercoverrecruiter.com/develop-your-team-steps/",
        },
        {
          title: allUrls[SECTIONS.helpTeam][2],
          url: "https://www.masterclass.com/articles/how-to-build-a-strong-team",
        },
        {
          title: allUrls[SECTIONS.helpTeam][3],
          url: "https://www.youtube.com/watch?v=W5qQJhe7sLE",
        },
        {
          title: allUrls[SECTIONS.helpTeam][4],
          url: "https://www.youtube.com/watch?v=VlTPD5wAGjg",
        },
        {
          title: allUrls[SECTIONS.helpTeam][5],
          url: "https://www.youtube.com/watch?v=ISiMslGqoSA",
        },
      ],
    },
    {
      key: SECTIONS.visionOfArea,
      translations: [
        "Crea una visión de área",
        "Tiene una visión/estrategia clara para el equipo",
        "Has a clear vision/strategy for the team",
        "Tem uma visão/estratégia clara para a equipe",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.visionOfArea][0],
          url: "https://www.indeed.com/hire/c/info/vision-for-a-team",
        },
        {
          title: allUrls[SECTIONS.visionOfArea][1],
          url: "https://www.dameleadership.com/research-and-insights/create-team-vision/",
        },
        {
          title: allUrls[SECTIONS.visionOfArea][2],
          url: "https://www.youtube.com/watch?v=-pLe7_5xLTs",
        },
        {
          title: allUrls[SECTIONS.visionOfArea][3],
          url: "https://www.youtube.com/watch?v=sbK0LhuWRoE",
        },
      ],
    },
    {
      key: SECTIONS.technicalSkills,
      translations: [
        "Tiene conocimiento técnico para el cargo",
        "Tiene habilidades técnicas clave que ayudan a asesorar al equipo",
        "Has key technical skills that help him/her advise the team",
        "Tem competências técnicas chave que ajudam no acompanhamento da equipe",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.technicalSkills][0],
          url: "https://criteriaforsuccess.com/6-ways-leaders-solve-problems/",
        },
        {
          title: allUrls[SECTIONS.technicalSkills][1],
          url: "https://www.forbes.com/sites/glennllopis/2013/11/04/the-4-most-effective-ways-leaders-solve-problems/?sh=648b9644f974",
        },
        {
          title: allUrls[SECTIONS.technicalSkills][2],
          url: "https://www.youtube.com/watch?v=DBFL_40vjRA",
        },
        {
          title: allUrls[SECTIONS.technicalSkills][3],
          url: "https://www.youtube.com/watch?v=6OpPd39mHfg",
        },
        {
          title: allUrls[SECTIONS.technicalSkills][4],
          url: "https://www.youtube.com/watch?v=q54E260_tjU",
        },
      ],
    },
    {
      key: SECTIONS.teamEnvironment,
      translations: [
        "Crea un ambiente de equipo inclusivo, mostrando preocupación por el éxito y el bienestar",
        "Creates an inclusive team environment, showing concern for success and well-being",
        "Cria um ambiente de equipe inclusivo, mostrando preocupação com o sucesso e o bem-estar",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.teamEnvironment][0],
          url: "https://www.linkedin.com/pulse/valuing-employees-workplace-manoj-guntupalli?trk=articles_directory",
        },
        {
          title: allUrls[SECTIONS.teamEnvironment][1],
          url: "https://www.forbes.com/sites/williamarruda/2017/11/08/9-ways-to-show-your-people-you-value-them/?sh=42da900228a0",
        },
        {
          title: allUrls[SECTIONS.teamEnvironment][2],
          url: "https://www.youtube.com/watch?v=aljb6ZXBwV0",
        },
      ],
    },
    {
      key: SECTIONS.collaborateUsCompany,
      translations: [
        "Colabora a través de la compañía",
        "Collaborates across the company",
        "Colabora através da empresa",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.collaborateUsCompany][0],
          url: "https://www.betterup.com/blog/collaboration-at-work/",
        },
        {
          title: allUrls[SECTIONS.collaborateUsCompany][1],
          url: "https://www.thebalancecareers.com/collaboration-skills-with-examples-2059686",
        },
        {
          title: allUrls[SECTIONS.collaborateUsCompany][2],
          url: "https://www.ringcentral.com/us/en/blog/collaboration-in-the-workplace/",
        },
        {
          title: allUrls[SECTIONS.collaborateUsCompany][3],
          url: "https://www.youtube.com/watch?v=VmQVNE-MbKI",
        },
        {
          title: allUrls[SECTIONS.collaborateUsCompany][4],
          url: "https://www.youtube.com/watch?v=AMG8ObDmbaM",
        },
        {
          title: allUrls[SECTIONS.collaborateUsCompany][5],
          url: "https://www.youtube.com/watch?v=DanqXMDEVUk",
        },
      ],
    },
    {
      key: SECTIONS.strongDecision,
      translations: [
        "Toma decisiones difíciles",
        "Is a strong decision maker",
        "Toma decisões difíceis",
      ],
      urls: [
        {
          title: allUrls[SECTIONS.strongDecision][0],
          url: "https://hbr.org/2016/09/how-to-tackle-your-toughest-decisions",
        },
        {
          title: allUrls[SECTIONS.strongDecision][1],
          url: "https://www.forbes.com/sites/francesbridges/2021/09/30/how-to-make-a-hard-decision/?sh=600f8956c0a5",
        },
        {
          title: allUrls[SECTIONS.strongDecision][2],
          url: "https://ca.indeed.com/career-advice/career-development/how-to-make-hard-decision",
        },
        {
          title: allUrls[SECTIONS.strongDecision][3],
          url: "https://www.youtube.com/watch?v=d7Jnmi2BkS8",
        },
        {
          title: allUrls[SECTIONS.strongDecision][4],
          url: "https://www.youtube.com/watch?v=WPFDtijUbMg",
        },
      ],
    },
  ];
};

export const GOOD_LEADER_RESULTS_GROUPS = [
  {
    id: "strengths",
    top: 1,
    bottom: 0.9,
    color: CRITIC_COLORS_RANGE.EXCELLENT,
    icon: strengthsIcon,
  },
  {
    id: "development",
    top: 0.89,
    bottom: 0.75,
    color: CRITIC_COLORS_RANGE.NORMAL,
    icon: developmentIcon,
  },
  {
    id: "opportunities",
    top: 0.74,
    bottom: 0,
    color: CRITIC_COLORS_RANGE.LOW,
    icon: opportunitiesIcon,
  },
];
