import PropTypes from "prop-types";
import { CARD_TYPE, PERSONALITY_EVALUATION } from "common/constants";
import { getFullInformation } from "views_refactor/Profile/functions/skills";
import EvaluationCard from "../EvaluationCard";
import { StyledCard } from "../../styles";

const EvaluationDetail = (props) => {
  const { t, data } = props;
  return (
    <StyledCard>
      <EvaluationCard
        t={ t }
        info={ getFullInformation(data).description }
        archetype={ getFullInformation(data).archetype }
        type={ CARD_TYPE.evaluation }
      />
      <EvaluationCard
        t={ t }
        info={ getFullInformation(data).info.energizes }
        type={ CARD_TYPE.badge }
        index={ PERSONALITY_EVALUATION.energizes }
      />
      <EvaluationCard
        t={ t }
        info={ getFullInformation(data).info.drains }
        type={ CARD_TYPE.badge }
        index={ PERSONALITY_EVALUATION.drains }
      />
    </StyledCard>
  );
};

EvaluationDetail.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
};

export default EvaluationDetail;
