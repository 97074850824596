import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputTextController from "components/InputTextController";
import InputNumberController from "components/InputNumberController";
import CheckboxInput from "components/CheckboxInput";
import { INDEX, TYPES, VARIANT } from "common/constants";
import { StyledContainer } from "./styles";

const EvaluationType = (props) => {
  const {
    type, index, control, watch, register, data,
  } = props;
  const { t } = useTranslation("surveys");

  return (
    <StyledContainer
      container
      spacing={ 3 }
      data-testid={ "evaluation-type-form" }
    >
      <Grid item xs={ 12 }>
        <InputTextController
          type={ TYPES.text }
          label={ t("surveys:form.fields.answers_file_location") }
          control={ control }
          name={ `evaluations_attributes[${index}].answers_file_location` }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 9 }>
        <InputTextController
          type={ TYPES.text }
          label={ t("surveys:form.fields.link") }
          control={ control }
          name={ `evaluations_attributes[${index}].link` }
          disabled={ watch("has_nala_form") }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 3 }>
        <InputNumberController
          label={ t("surveys:form.fields.weighing") }
          control={ control }
          name={ `evaluations_attributes[${index}].weighing` }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <Typography variant={ VARIANT.h5 }>
          {t("surveys:form.fields.params.title")}
        </Typography>
        <Typography variant={ VARIANT.bodyOne }>
          {t("surveys:form.fields.params.subtitle")}
        </Typography>
      </Grid>
      {type.evaluatorEmail && (
        <Grid item xs={ 12 } md={ 3 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("surveys:form.fields.params.evaluator_email") }
            control={ control }
            name={ `evaluations_attributes[${index}].params[${type.evaluatorEmail
              - INDEX.one}]` }
            disabled={ watch("has_nala_form") }
          />
        </Grid>
      )}
      {type.evaluatedName && (
        <Grid item xs={ 12 } md={ 3 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("surveys:form.fields.params.evaluated_name") }
            control={ control }
            name={ `evaluations_attributes[${index}].params[${type.evaluatedName
              - INDEX.one}]` }
            disabled={ watch("has_nala_form") }
          />
        </Grid>
      )}
      {type.evaluatedEmail && (
        <Grid item xs={ 12 } md={ 3 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("surveys:form.fields.params.evaluated_email") }
            control={ control }
            name={ `evaluations_attributes[${index}].params[${type.evaluatedEmail
              - INDEX.one}]` }
            disabled={ watch("has_nala_form") }
          />
        </Grid>
      )}
      { watch("has_nala_form")
        && (
          <Grid item xs={ 12 }>
            <FormControlLabel
              control={ (
                <CheckboxInput
                  name={ `evaluations_attributes[${index}].has_comments` }
                  inputRef={ register }
                  defaultChecked={ data?.has_comments }
                />
              ) }
              label={ t("surveys:form.fields.general_data.general_comments") }
            />
          </Grid>
        ) }
    </StyledContainer>
  );
};

EvaluationType.propTypes = {
  type: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  data: PropTypes.object,
};

EvaluationType.defaultProps = {
  data: {},
};

export default EvaluationType;
