export const extractUniqueManagers = (data) => {
  const managerMap = new Map();
  data.forEach((item) => {
    const manager = item.manager_info;
    if (manager && !managerMap.has(manager.id)) {
      managerMap.set(manager.id, { id: manager.id, name: manager.full_name });
    }
  });

  return Array.from(managerMap.values()).sort((a, b) => a.name.localeCompare(b.name));
};

export const extractUniqueCountries = (data) => {
  const countryMap = new Map();
  data.forEach((item) => {
    const country = item.job_position?.country;
    if (country && !countryMap.has(country.id)) {
      countryMap.set(country.id, { id: country.id, name: country.name });
    }
  });

  return Array.from(countryMap.values()).sort((a, b) => a.name.localeCompare(b.name));
};

export const extractUniqueCities = (data) => {
  const cityMap = new Map();
  data.forEach((item) => {
    const city = item.job_position?.city;
    if (city && !cityMap.has(city.id)) {
      cityMap.set(city.id, { id: city.id, name: city.name });
    }
  });

  return Array.from(cityMap.values()).sort((a, b) => a.name.localeCompare(b.name));
};

// Function to extract unique and hierarchical organization units
export const extractOrganizationUnit = (data) => {
  const unitMap = new Map();

  // Function to get the full name of a unit
  const getFullUnitName = (unit) => {
    if (!unit.parent) return unit.name;
    return `${getFullUnitName(unit.parent)}/${unit.name}`;
  };

  // Function to add a unit to the map
  const addUnitToMap = (unit) => {
    const fullUnitName = getFullUnitName(unit);
    if (!unitMap.has(unit.id)) {
      unitMap.set(unit.id, {
        id: unit.id,
        name: fullUnitName,
        depth: unit.depth,
        parentId: unit.parent ? unit.parent.id : null,
        children: [],
      });
    }

    // Add parent if not in the map
    let { parent } = unit;
    while (parent) {
      const parentFullName = getFullUnitName(parent);
      if (!unitMap.has(parent.id)) {
        unitMap.set(parent.id, {
          id: parent.id,
          name: parentFullName,
          depth: parent.depth,
          parentId: parent.parent ? parent.parent.id : null,
          children: [],
        });
      }
      parent = parent.parent;
    }
  };

  // Traverse collaborator list to extract organization units
  data.forEach((collaborator) => {
    const unit = collaborator.job_position?.organization_unit;
    if (unit) {
      addUnitToMap(unit);
    }
  });

  // Add children to each unit
  unitMap.forEach((unit) => {
    if (unit.parentId !== null && unitMap.has(unit.parentId)) {
      unitMap.get(unit.parentId).children.push(unit);
    }
  });

  // Function to sort units hierarchically
  const buildHierarchicalList = (units, list) => {
    units.forEach((unit) => {
      list.push({ id: unit.id, name: unit.name });
      if (unit.children.length) {
        buildHierarchicalList(
          unit.children.sort((a, b) => a.name.localeCompare(b.name)),
          list,
        );
      }
    });
  };

  // Start the ordered list from root units
  const hierarchicalUnits = [];
  const rootUnits = Array.from(unitMap.values()).filter(
    (unit) => unit.parentId === null,
  );

  buildHierarchicalList(
    rootUnits.sort((a, b) => a.name.localeCompare(b.name)),
    hierarchicalUnits,
  );

  return hierarchicalUnits;
};
