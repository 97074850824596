import styled from "styled-components";
import Paper from "@mui/material/Paper";
import palette, { TABLE } from "theme/palette";

export const StyledAdd = styled.div`
  background-color: ${TABLE.ODD};
  color: ${palette.black};
  font-size: 12px;
  padding: 10px 15px;
  :hover {
    cursor: pointer;
  }
`;

export const StyledIconRemove = styled.div`
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
`;

export const StyledPaper = styled(Paper)`
  background-color: ${palette.white};
  & .MuiAutocomplete-option {
    background-color: ${palette.white} !important;

    :hover {
      background-color: ${palette.background.lightGray} !important;
    }
  }
`;
