import axios from "axios";
import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS, MANDRILL_KEY } from "common/constants";
import errorHandler from "../../errorHandler";

const URL = "https://mandrillapp.com/api/1.0/templates/list";

export const getTemplateListByProcess = (type) => async (dispatch) => {
  const query = {
    key: MANDRILL_KEY,
    label: type,
  };
  try {
    const response = await axios.get(
      URL,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: { ...query },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      },
    );
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};
