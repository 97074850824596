import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: theme.palette.border.strong,
    borderTop: `3px solid ${theme.palette.text.iconDisabled}`,
    borderRadius: theme.spacing(0),
  },
  iconType: {
    marginRight: "4px",
  },
  btnViewMore: {
    width: "100%",
  },
}));
