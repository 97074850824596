import styled from "styled-components";

export const StyledContainer = styled.div`
  min-width: 320px;
  margin-right:15px;
  .MuiFormControl-root {
    width: 100%;
    margin-top: 8px;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;
