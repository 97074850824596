import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

export const StyledPaperContainer = styled(Paper)`
  padding: 16px;
`;

export const StyledGrid = styled(Grid)`
 margin-top: 8px;
`;
