import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledContainer = styled.div`
  min-width: 320px;
  .MuiFormControl-root {
    margin-top: 8px;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 8px;
`;
