import {
  useContext, useEffect, useState, useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import Alert from "components/Alert";
import SkeletonLoader from "components/SkeletonLoader";
import ProfileImageDetail from "components/ProfileImageDetail";
import { SessionContext } from "modules/session/context";
import {
  ATTRITION_AXES_MAX_FOR_RECOMMENDATION,
  ROLES,
} from "common/constants";
import {
  getAverageResult,
  getPercent,
  isValidRole,
  isValidAdmin as isValidAdminFunction,
  getProcessNameById,
  getCurrentLanguage,
} from "common/utils";
import PerformanceChart from "components/PerformanceChart";
import TalentSpiderChart from "components/TalentSpiderChart";
import { getFormattedIndividualObject, getFilterNames } from "components/TalentSpiderChart/functions";
import {
  getOne as getCollaborator,
  resetStateOne as resetStateOneCollaborator,
} from "redux/actions/collaboratorActions";
import {
  getList as getAttritionCollaboratorChart,
  resetState as resetAttritionCollaboratorChart,
} from "redux/actions/collaborators/attritionActions";
import {
  getList as getPerformanceProcess,
  resetState as resetPerformanceProcess,
} from "redux/actions/performance/performanceActions";
import {
  getCollaboratorPerformanceEvolution,
  resetStateCollaboratorPerformanceEvolution,
} from "redux/actions/surveyProcessesActions";
import { getRecommendations, INDEX_TABS, TABS } from "views_refactor/Profile/functions/lossRisk";
import { RISK_CATEGORY } from "views/TalentDrain/functions";
import Recommendations from "./components/Recommendations";
import AttritionProbabilityCard from "./components/AttritionProbabilityCard";
import {
  StyledChartContainer,
  StyledContainer,
  StyledGridAlert,
  StyledTitle,
  StyledTabContent,
  StyledGridProfileImageDetail,
} from "./styles";

const LossRisk = (props) => {
  // TODO: refactor and use new architecture
  const { employeeId } = props;
  const { t } = useTranslation("talentDrain");

  const [tabHandler, setTabHandler] = useState(0);

  const {
    state: { user },
  } = useContext(SessionContext);

  const dispatch = useDispatch();

  const language = getCurrentLanguage();

  // TODO: remove validations
  const isAdmin = isValidAdminFunction(user?.userCookies);
  const isOwnProfile = (user?.employee?.id === Number(employeeId));
  const isTalentManager = isValidRole(user?.userCookies, ROLES.TALENT_MANAGER);
  const hasPermissionAsTalentManager = isTalentManager && !isOwnProfile;
  const isValidToShowAttrition = (collaborator) => isAdmin || hasPermissionAsTalentManager
  || collaborator?.is_descendant || !isOwnProfile;

  const {
    one: collaborator,
    isLoadingOne: isLoadingCollaboratorReducer,
  } = useSelector((state) => state.collaboratorReducer);

  const {
    list: collaboratorAttrition,
    loadingList: isLoadingCollaboratorAttrition,
  } = useSelector(({ collaboratorAttritionReducer }) => collaboratorAttritionReducer);

  const {
    collaboratorPerformanceEvolution: performanceEvolution,
    loadingCollaboratorPerformanceEvolution: isLoadingPerformanceEvolution,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const {
    list: performanceProcessesList,
    loadingList: isLoadingPerformanceProcessesList,
  } = useSelector(({ performanceReducer }) => performanceReducer);

  useEffect(() => {
    if (employeeId) {
      dispatch(getCollaborator(employeeId));
    }
    // eslint-disable-next-line
  }, [employeeId]);

  const resetStateAndGetData = useCallback(async () => {
    const query = { q: { employee_id_eq: collaborator.id } };
    await dispatch(resetAttritionCollaboratorChart());
    await dispatch(resetStateCollaboratorPerformanceEvolution());
    await dispatch(resetPerformanceProcess());
    dispatch(getAttritionCollaboratorChart(collaborator.id, query));
    dispatch(getPerformanceProcess());
    dispatch(getCollaboratorPerformanceEvolution(collaborator.id));
  }, [collaborator, dispatch]);

  useEffect(() => {
    if (collaborator !== null && isValidToShowAttrition(collaborator)) {
      resetStateAndGetData();
    }
    // eslint-disable-next-line
  }, [collaborator]);

  const isValidForTheRecommendation = () => {
    let isValid = false;
    const riskCategory = collaboratorAttrition?.responseList[0]?.risk_category;
    const averageResults = collaboratorAttrition?.averages[0]?.values;
    if (riskCategory && (riskCategory !== RISK_CATEGORY.low.key) && averageResults.length > 0) {
      const averageResultsArray = Object.values(averageResults);
      isValid = averageResultsArray.every(
        (item) => item > ATTRITION_AXES_MAX_FOR_RECOMMENDATION,
      );
    }
    return isValid;
  };

  useEffect(() => {
    dispatch(resetStateOneCollaborator());
  }, [dispatch]);

  const spiderChart = (
    <TalentSpiderChart
      dataFormatted={ getFormattedIndividualObject(collaboratorAttrition?.responseList[0]?.attrition_axis_values) }
      filterNames={ getFilterNames(collaboratorAttrition?.averages) }
      isPersonal
    />
  );

  const recommendations = (
    <Recommendations
      t={ t }
      data={ getRecommendations(collaboratorAttrition?.responseList, t) }
      isLoading={ isLoadingCollaboratorAttrition }
    />
  );

  const recommendationByExceptionalCase = () => {
    let recommendation = "";
    if (isValidForTheRecommendation()) {
      const attritionProbability = collaboratorAttrition?.responseList[0]?.attrition_probability;
      recommendation = (
        <Alert text={ t(
          "talentDrain:chart.recommendation",
          { attrition: getPercent(attritionProbability, true) },
        ) }
        />
      );
    }
    return recommendation;
  };

  const handleValueChangedTabLossRisk = (value) => {
    setTabHandler(value);
  };

  const getAttritionEvaluations = useCallback(() => {
    let evaluations = [];
    if (!isLoadingPerformanceProcessesList && !isLoadingPerformanceEvolution
      && performanceEvolution && performanceProcessesList) {
      evaluations = performanceEvolution.filter((evolution) => evolution?.id).map((item) => ({
        value: item.id,
        label: getProcessNameById(performanceProcessesList, item.id, language),
      }));
    }
    return evaluations.map((evaluation) => evaluation.label);
  }, [
    isLoadingPerformanceEvolution,
    isLoadingPerformanceProcessesList,
    performanceEvolution,
    performanceProcessesList,
    language,
  ]);

  const isLoading = isLoadingCollaboratorReducer || collaborator === null;

  const attrition = (
    <AttritionProbabilityCard
      t={ t }
      data={ collaboratorAttrition?.responseList[0] }
      isLoading={ isLoadingCollaboratorAttrition && isLoadingCollaboratorReducer }
      hasBorder
    />
  );

  return isLoading && collaborator === null
    ? <SkeletonLoader numberOfSkeletons={ 8 } />
    : (
      <Grid container>
        <StyledGridProfileImageDetail xs={ 12 }>
          <ProfileImageDetail
            collaborator={ collaborator }
            width={ 70 }
            height={ 70 }
            isModalVersion
          />
        </StyledGridProfileImageDetail>
        <Grid item xs={ 12 } sm={ 7 } md={ 8 }>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={ 12 }>
                <StyledTitle>{t("talentDrain:satisfactionMap")}</StyledTitle>
                {spiderChart}
              </Grid>
              <StyledGridAlert item xs={ 12 }>
                {collaboratorAttrition?.averages && recommendationByExceptionalCase()}
              </StyledGridAlert>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={ 12 } sm={ 5 } md={ 4 }>
            <StyledContainer padding={ "10px" }>
              {attrition}
              {collaboratorAttrition?.responseList && recommendations}
            </StyledContainer>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={ 12 }>
            <Tab
              tabs={ TABS(t) }
              onChange={ handleValueChangedTabLossRisk }
              tabValue={ tabHandler }
            />
            <TabPanel
              value={ tabHandler }
              index={ INDEX_TABS.map }
            >
              <StyledTabContent>
                {attrition}
                {spiderChart}
                {collaboratorAttrition?.averages && recommendationByExceptionalCase()}
              </StyledTabContent>
            </TabPanel>
            <TabPanel
              value={ tabHandler }
              index={ INDEX_TABS.recommendations }
            >
              <StyledTabContent>
                {attrition}
                {recommendations}
              </StyledTabContent>
            </TabPanel>
          </Grid>
        </Hidden>
        {performanceEvolution
        && (
          <Grid item xs={ 12 } md={ 10 }>
            <StyledTitle>{t("performance:evolution")}</StyledTitle>
            <StyledChartContainer>
              <PerformanceChart
                title={ t("performance:dashboard.graphic_title") }
                labels={ getAttritionEvaluations() }
                data={ performanceEvolution ? getAverageResult(performanceEvolution) : [] }
                isLoading={ isLoadingPerformanceProcessesList || isLoadingPerformanceEvolution }
              />
            </StyledChartContainer>
          </Grid>
        )}
      </Grid>
    );
};

LossRisk.propTypes = {
  employeeId: PropTypes.number.isRequired,
};

export default LossRisk;
