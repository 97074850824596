import format from "date-fns/format";
import { FULLDATE_FORMATS_STANDARD } from "common/constants";

export const TABS = (t, receivedCount = 0, sentCount = 0) => [
  { label: `${t("received")} (${receivedCount || 0})` },
  { label: `${t("sent")} (${sentCount || 0})` },
];

export const TABS_SEND_FORM = (t) => [
  { label: t("simple") },
  { label: t("stop_start_continue") },
];

export const INDEX_TABS = {
  received: 0,
  sent: 1,
};

export const INDEX_TABS_SEND = {
  simple: 0,
  stop_start_continue: 1,
};

export const FEEDBACK_TYPE = {
  received: "received",
  sent: "sent",
};

export const FEEDBACKABLES = {
  feedback: "Feedback",
  feedbackSimple: "FeedbackSimple",
  feedbackStartStopContinue: "FeedbackStartStopContinue",
  feedbackAcknowledgement: "FeedbackAcknowledgement",
};

export const EMPLOYEE_TYPE = {
  receiver: "employee_from",
  sender: "employee_to",
};

export const getFullDateFormatStandard = (date) => format(new Date(date), FULLDATE_FORMATS_STANDARD.dash);

export const isWithData = (data) => data?.length > 0;

export const isSent = (type) => type === FEEDBACK_TYPE.sent;

export const filterMeAndInactives = (collaborators, myEmployeeId) => collaborators?.filter((collab) => collab.id !== myEmployeeId && collab?.is_active);

export const LIMIT_PAGINATE_INDEX = {
  start: 0,
  end: 5,
};
