import styled from "styled-components";
import palette from "theme/palette";

export const StyledBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0 10px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  .MuiAvatar-root {
    margin-right: 8px;
  }
`;

export const StyledOneContent = styled.div`
  padding: 0 40px;
  text-align: center;
  margin-bottom: 20px;
  .MuiTypography-h5 {
    text-align: left;
  }
  .MuiButton-containedPrimary {
    font-size: 16px;
    background-color: ${palette.text.link};
    margin-top: 30px;
    padding: 10px 25px;
  }
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

export const StyledProgressContainer = styled.div`
  .MuiLinearProgress-root {
    width: 128px;
    border-radius: 5px;
    height: 10px;
    margin: auto;
    background-color: ${palette.linearProgress.incomplete};
  }
  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${palette.linearProgress.complete};
  }
  .MuiTypography-body1 {
    font-size: 10px;
    text-align: center;
  }
`;
