import {
  useState,
  useContext,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "components/TablePagination";
import usePagination from "hooks/utils/usePagination";
import { PAGINATION, INDEX, TRUE } from "common/constants";
import { SessionContext } from "modules/session/context";
import { getListPaginated } from "redux/actions/collaboratorActions";
import {
  HEADER,
  getRowsDesktop,
} from "./functions";

const NotConnectedSlackUsers = () => {
  const {
    state: { user },
  } = useContext(SessionContext);

  const { pagination, setPagination, handlePagination } = usePagination(INDEX.one);
  const [searchFilter, setSearchFilter] = useState("");

  const dispatch = useDispatch();

  const {
    list: collaboratorList,
    listTotal: collaboratorListTotal,
    isLoadingList: isLoadingCollaborators,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const handleSearch = (name = "") => {
    setSearchFilter(name);
    setPagination(PAGINATION.next);
  };

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        slack_id_not_null: INDEX.zero,
        active_in: [TRUE],
      },
    };
    dispatch(getListPaginated(pagination, query, INDEX.four));
  }, [dispatch, searchFilter, pagination]);

  return (
    <TablePagination
      header={ HEADER }
      list={ collaboratorList }
      isLoading={ isLoadingCollaborators }
      getRows={ {
        mobile: getRowsDesktop,
        desktop: getRowsDesktop,
      } }
      extraParam={ user }
      handlePagination={ handlePagination }
      pagination={ pagination }
      total={ collaboratorListTotal }
      handleSearch={ handleSearch }
    />
  );
};

export default NotConnectedSlackUsers;
