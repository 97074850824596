import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import Step from "@mui/material/Step";
import { StepperStyled, TitleStyled, StepContentStyled } from "./styles";

const SimpleVerticalStepper = (props) => {
  const {
    data,
    getStepIcon,
    stepContentTitle,
    extraStep,
    mainTranslate,
  } = props;

  return (
    <div data-testid="simple-vertical-stepper">
      <StepperStyled orientation="vertical">
        {data?.map((item, index) => (
          <Step key={`step-${index}`} active>
            <TitleStyled icon={getStepIcon(item.type)}>
              {mainTranslate.t(`${mainTranslate.main}.${item.type}`)}
            </TitleStyled>
            {stepContentTitle.map((content, index) => {
              return (
                <StepContentStyled key={`steper-${index}`}>
                  {!isEmpty(item[content.element]) &&
                    `${content.title}:
                    ${item[content.element]}`}
                </StepContentStyled>
              );
            })}
          </Step>
        ))}
        {extraStep && (
          <Step>
            {/*This is an exception: must pass this props has a string in order to be used as "Mui" styled prop*/}
            <TitleStyled isinactive={"true"} icon={extraStep.icon}>
              {extraStep.title}
            </TitleStyled>
          </Step>
        )}
      </StepperStyled>
    </div>
  );
};

SimpleVerticalStepper.propTypes = {
  data: PropTypes.array,
  getStepIcon: PropTypes.func,
  stepContentTitle: PropTypes.array,
  extraStep: PropTypes.object,
  mainTranslate: PropTypes.object,
};

export default SimpleVerticalStepper;
