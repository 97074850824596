import toNumber from "lodash/toNumber";
import isString from "lodash/isString";
import moment from "moment";
import {
  STATE,
  MIN_VALUE,
  PERCENTAGE,
  SURVEY_PROCESS_TYPE,
} from "common/constants";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { getNoRoundPercent } from "common/utils";
import { getEvaluationType, isNullOrUndefined } from "common/validators";
import { isEmpty } from "validate.js";

export const getProcessTypes = (t) => [
  {
    key: SURVEY_PROCESS_TYPE.performance.key,
    value: SURVEY_PROCESS_TYPE.performance.value,
    itemData: SURVEY_PROCESS_TYPE.performance.itemData,
    label: t("surveys:processes_types.performance"),
  },
  {
    key: SURVEY_PROCESS_TYPE.potential.key,
    value: SURVEY_PROCESS_TYPE.potential.value,
    itemData: SURVEY_PROCESS_TYPE.potential.itemData,
    label: t("surveys:processes_types.potential"),
  },
  {
    key: SURVEY_PROCESS_TYPE.goodLeader.key,
    value: SURVEY_PROCESS_TYPE.goodLeader.value,
    itemData: SURVEY_PROCESS_TYPE.goodLeader.itemData,
    label: t("surveys:processes_types.good_leader"),
  },
  {
    key: SURVEY_PROCESS_TYPE.engagement.key,
    value: SURVEY_PROCESS_TYPE.engagement.value,
    itemData: SURVEY_PROCESS_TYPE.engagement.itemData,
    label: t("surveys:processes_types.engagement"),
  },
  {
    key: SURVEY_PROCESS_TYPE.training.key,
    value: SURVEY_PROCESS_TYPE.training.value,
    itemData: SURVEY_PROCESS_TYPE.training.itemData,
    label: t("surveys:processes_types.training"),
  },
];

export const getEvaluationTypes = (t) => [
  {
    id: EVALUATION_TYPE.AUTOEVALUATION,
    name: getEvaluationType(EVALUATION_TYPE.AUTOEVALUATION, t),
    evaluatedEmail: 1,
  },
  {
    id: EVALUATION_TYPE.MANAGER,
    name: getEvaluationType(EVALUATION_TYPE.MANAGER, t),
    evaluatorEmail: 1,
    evaluatedName: 2,
    evaluatedEmail: 3,
  },
  {
    id: EVALUATION_TYPE.PAIRS,
    name: getEvaluationType(EVALUATION_TYPE.PAIRS, t),
    evaluatorEmail: 1,
  },
  {
    id: EVALUATION_TYPE.COLLABORATORS,
    name: getEvaluationType(EVALUATION_TYPE.COLLABORATORS, t),
    evaluatorEmail: 1,
    evaluatedName: 2,
    evaluatedEmail: 3,
  },
  {
    id: EVALUATION_TYPE.OVERALL,
    name: getEvaluationType(EVALUATION_TYPE.OVERALL, t),
    evaluatorEmail: 1,
  },
];

export const buildSurveyProcess = (surveyProcess, processId) => {
  const params = [];

  surveyProcess.evaluations_attributes.map((attr) => {
    if (attr.type && attr.params) {
      attr.params.filter((param) => !isNullOrUndefined(param));
      attr.params = attr.params.join(", ");
    }
    attr.weighing = attr.weighing ? attr.weighing / PERCENTAGE.max : MIN_VALUE;
    return attr.type && params.push(attr);
  });

  surveyProcess.end_date = moment.utc(surveyProcess.end_date).endOf("day");

  surveyProcess.evaluations_attributes = params;

  if (isString(surveyProcess.min_worked_days)) {
    surveyProcess.min_worked_days = surveyProcess.min_worked_days.replaceAll(
      ",",
      "",
    );
  }

  surveyProcess.min_worked_days = toNumber(surveyProcess.min_worked_days);
  if (processId) {
    delete surveyProcess.state_transition;
  } else {
    surveyProcess.state_transition = STATE.program;
  }

  return surveyProcess;
};

export const processDefaults = {
  id: "",
  type: "",
  name_es: "",
  name_en: "",
  name_pt: "",
  start_date: moment(),
  end_date: moment(),
  min_worked_days: MIN_VALUE,
  evaluations_attributes: [],
  follow_up_process_id: "",
  has_nala_form: false,
  state_transition: "",
};

export const getParams = (params) => {
  params = params.split(", ");
  const evaluatorEmail = params[0];
  const evaluatedName = params[1];
  const evaluatedEmail = params[2];
  return [evaluatorEmail, evaluatedName, evaluatedEmail];
};
export const getEvaluationsData = (evaluations, evaluationTypes) => {
  const data = evaluationTypes.map((evaluationType) => {
    const result = evaluations.find((evaluation) => evaluation.type === evaluationType.id);

    return !isEmpty(result)
      ? {
        id: result.id,
        link: result.link,
        answers_file_location: result.answers_file_location,
        params: result.params && getParams(result.params),
        type: result.type,
        weighing: getNoRoundPercent(result.weighing),
        has_comments: result.has_comments,
      }
      : [];
  });
  return data;
};

export const processEditData = (processData, processType, evaluationTypes) => ({
  id: processData.id,
  type: processType.value,
  name_es: processData.name_es,
  name_en: processData.name_en,
  name_pt: processData.name_pt,
  start_date: processData.start_date,
  end_date: processData.end_date,
  min_worked_days: processData.min_worked_days,
  evaluations_attributes: processData.evaluations
    ? getEvaluationsData(processData.evaluations, evaluationTypes)
    : [],
  follow_up_process_id: processData.follow_up_process_id,
  has_nala_form: processData.has_nala_form,
  state: processData.state,
});

export const resetFormData = (processType, data, t) => {
  const processTypes = getProcessTypes(t);
  const evaluationTypes = getEvaluationTypes(t);
  const processTypeValues = processTypes.find((process) => processType === process.value);
  const formData = data[processTypeValues.itemData];
  return formData
    ? processEditData(formData, processTypeValues, evaluationTypes)
    : [];
};
export const validEvaluationProcess = (data, previousEvaluations) => {
  const editedEvaluations = data.evaluations_attributes.filter(
    (evaluation) => evaluation.id !== "",
  );

  previousEvaluations
    .filter((previousEvaluation) => !isEmpty(previousEvaluation))
    .map((previousData) => {
      const isNotSelected = editedEvaluations.find(
        (evaluation) => evaluation.id === previousData.id,
      );
      // If the previous evaluation has not been selected send data to delete
      return (
        !isNotSelected
        && data.evaluations_attributes.push({
          id: previousData.id,
          _destroy: 1,
        })
      );
    });
  return data;
};

export const isEditProcess = (processType, processId) => !isEmpty(processType) && !isEmpty(processId);

export const getFollowUp = (followUpList) => {
  let followUpProcesses = [];
  if (followUpList) {
    followUpProcesses = followUpList.map((followUp) => {
      return {
        key: followUp.id,
        value: followUp.id,
        label: followUp.name,
      };
    });
  }
  return followUpProcesses;
};
