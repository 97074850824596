import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import { Link } from "react-router-dom";
import palette from "theme/palette";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const StyledContainer = styled(Grid)`
  border: ${palette.border.card};
  border-radius: 2px;
  margin-bottom: 20px;
  position: relative;
`;

export const StyledBoxContainer = styled(Box)`
  padding: 15px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${palette.background.lightGrey};
`;

export const StyledButtons = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  button {
    min-width: 50px;
    min-height: 35px;
    margin-left: 2px;
    padding: 5px;
    margin-top: 2px;
    &.cancel {
      margin-right: 0 !important;
    }
  }
  button.cancel:disabled .MuiSvgIcon-root {
    color: ${palette.white};
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 100px;
  padding: 0px 10px;
  margin: 8px;
  font-size: 13px;
  &.outlined {
    border: 1px solid ${palette.background.purpleBox};
    color: ${palette.background.purpleBox};
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
`;

export const StyledButtonAddContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledSuccessionPlan = styled.div`
  margin: 10px;
`;

export const StyledSuccessionItem = styled.div`
  border: ${palette.border.card};
  border-radius: 2px;
  margin-top: 10px;
`;

export const StyledSuccessionContainer = styled(Grid)`
  padding: 15px;
`;

export const StyledAddCircleOutlineIcon = styled(AddCircleOutlineIcon)`
  color: ${(props) => (props.isDisabled ? palette.text.disabled : palette.background.purpleBox)} !important;
`;

export const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 10px;
  width: 30px;
`;

export const StyledRequiredInfo = styled.h4`
  padding: 10px 10px 0;
  text-align: right;
  color: ${palette.text.red};
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  padding: 10px;
  font-size: 13px;
  margin-top: 2px;
  letter-spacing: 0.02857em;
  font-weight: 500;
  color: ${palette.background.purpleBox};
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    width: 16px;
  }
`;

export const StyledSuccessor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  font-size: 18px;
`;

export const StyledSuccessorContainer = styled.div`
  padding: 10px;
`;
