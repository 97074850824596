/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { VARIANT, TARGET } from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import Member from "../Member";
import { getDetails } from "../ProcessDetail/functions";
import { StyledGrid, StyledLinkEvaluation } from "./styles";

const Evaluation = (props) => {
  const {
    data, manager, hasNalaForm, t,
  } = props;
  const processData = getDetails(t, data);

  const subtitle = processData.subtitle
    && (
      <Typography variant={ VARIANT.bodyOne }>
        { processData.subtitle }
      </Typography>
    );

  const leader = isEqual(processData.evaluation_type, EVALUATION_TYPE.COLLABORATORS)
    && <Member data={ manager } link={ data.link } hasNalaForm={ hasNalaForm } />;

  const team = !isEmpty(processData.team_members)
    && processData?.team_members?.map((item) => (<Member key={ item?.full_name } data={ item } link={ item.link } hasNalaForm={ hasNalaForm } />));

  const action = processData.action
    && (
      <Link href={ processData.link } target={ hasNalaForm ? TARGET.self : TARGET.blank }>
        <StyledLinkEvaluation>
          { `${processData.action} ` }
          <LaunchOutlined />
        </StyledLinkEvaluation>
      </Link>
    );

  return (
    <Grid container spacing={ 2 } data-testid={ "evaluation-view-component" }>
      <StyledGrid item xs={ 12 }>
        <Typography variant={ VARIANT.h6 }>
          { t(`common:common.evaluations.${processData.evaluation_type}.title`) }
        </Typography>
        { subtitle }
        { leader }
        { team }
        { action }
      </StyledGrid>
    </Grid>
  );
};

export default Evaluation;
