import makeStyles from "@mui/styles/makeStyles";
import styled from "styled-components";
import palette from "theme/palette";

const useStyles = makeStyles((theme) => ({
  svg: {
    viewBox: "0",
  },
  generalSvg: {
    outline: "none",
    padding: 2,
    marginTop: 2,
  },
  rectBox: {
    stroke: theme.palette.white,
    strokeWidth: 4,
    fill: palette.background.grayBox,
  },
  rectBoxActive: {
    fill: palette.background.purpleBox,
  },
  nineBoxContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const StyledQuadrantName = styled.h4`
  text-align: center;
`;

export default useStyles;
