import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledGrid = styled(Grid)`
  .MuiTab-textColorInherit.Mui-selected {
    background: ${palette.backgroundTab}
  }
  .MuiTab-wrapper {
    font-size: 12px;
    text-transform: capitalize;
  }
`;

export const StyledLabel = styled(Typography)`
  margin: 15px 0;
`;
