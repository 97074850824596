import { useState } from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "./styles";

const SimpleCustomAccordion = (props) => {
  const {
    header, content, isActive, isDisabled,
  } = props;
  const [expanded, setExpanded] = useState(isActive ? "panel1-content" : false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <StyledAccordion
        elevation={ 0 }
        expanded={ expanded === "panel1-content" }
        onChange={ isDisabled ? () => {} : handleChange("panel1-content") }
      >
        <StyledAccordionSummary
          expandIcon={ isDisabled ? "" : <ExpandMoreIcon /> }
          aria-controls={ "panel1-content" }
          id={ "panel1-header" }
        >
          {header}
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {content}
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

SimpleCustomAccordion.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SimpleCustomAccordion.defaultProps = {
  isActive: false,
  isDisabled: false,
};

export default SimpleCustomAccordion;
