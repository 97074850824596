import styled from "styled-components";
import palette from "theme/palette";

export const CalendarContainer = styled.div`
  & .calendar {
    padding: 0;
    & .month-container {
      margin: 5px;
      border-radius: 4px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border: 1px solid ${palette.background.divider};
      padding: 15px 0 15px 0;
      height: ${(props) => (props.isMonthMode ? "100%" : "215px")};
      &.visible {
        visibility: visible;
        width: 98% !important;
        margin-top: 10px;
      }
      &.invisible {
        display: none;
        margin: 0px;
        padding: 0px;
      }
    }
    & .calendar-header {
      margin-bottom: 5px;
    }
  }
  & .calendar table.month {
    td.day .day-content {
      border-radius: 50%;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
    td.day .day-content .day-route {
      border-radius: 0 !important;
    }
    td.day .day-content:hover, .active-date, .active-date .day-start, .active-date .day-end {
      background: ${palette.text.link};
      color: ${palette.white};
      border-radius: 50%;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
    th.day-header {
      font-size: ${(props) => (props.isMonthMode ? "14px" : "11px")};
      color: ${palette.text.gray};
      text-transform: uppercase;
    }
    .month-title {
      text-align: left;
      padding-left: 5px;
      font-size: ${(props) => (props.isMonthMode ? "24px" : "16px")};
    }
    tr td, th {
      padding: ${(props) => (props.isMonthMode ? "8px" : "none")};
      font-size: ${(props) => (props.isMonthMode ? "14px" : "12px")};
    }
    @media (max-width: 600px) {
      tr td, th {
        padding: ${(props) => (props.isMonthMode ? "4px" : "none")};
      }
    }
  }
  .day-start, .day-end {
    background-color: ${palette.btnIcon.color};
  }
  .day-route {
    background-color: rgba(25,226,214,0.15);
  }
  & .start-border {
    background-color: rgba(25,226,214,0.15);
    -webkit-border-top-left-radius: 100%;
    -webkit-border-bottom-left-radius: 100%;
    -moz-border-radius-topleft: 100%;
    -moz-border-radius-bottomleft: 100%;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }
  & .end-border {
    background-color: rgba(25,226,214,0.15);
    -webkit-border-top-right-radius: 100%;
    -webkit-border-bottom-right-radius: 100%;
    -moz-border-radius-topright: 100%;
    -moz-border-radius-bottomright: 100%;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }
  .month-2 {
    width: calc(16.66666667% - 10px) !important;
  }
  .month-3 {
    width: calc(25% - 10px) !important;
  }
  .month-4 {
    width: calc(33.33333333% - 10px) !important;
  }
  .month-6 {
    width: calc(${(props) => (props.isMonthMode ? "100%" : "50%")} - 10px) !important;
  }
  .month-12 {
    width: calc(100% - 10px) !important;
  }
`;
