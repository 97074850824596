import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";

import { Notifications, Password } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Settings = function () {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <Grid
        container
        spacing={ 4 }
	>
        <Grid
          item
          md={ 7 }
          xs={ 12 }
	>
          <Notifications />
 </Grid>
        <Grid
          item
          md={ 5 }
          xs={ 12 }
	>
          <Password />
 </Grid>
 </Grid>
 </div>
  );
};

export default Settings;
