import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ALIGN_ITEMS, COMPONENT } from "common/constants";
import { StyledList, StyledListItemText } from "./styles";

const CommentListModal = (props) => {
  const { data } = props;

  const commentsDetail = data.map((item) => (
    <ListItem key={ item.name } alignItems={ ALIGN_ITEMS.flexStart }>
      <ListItemAvatar>
        <Avatar
          alt={ item.name }
          src={ item.img }
        />
      </ListItemAvatar>
      <StyledListItemText
        primary={ (
          <Typography component={ COMPONENT.span }>
            {item.name}
          </Typography>
        ) }
        secondary={ item.text }
      />
    </ListItem>
  ));

  return (
    <StyledList>
      { commentsDetail}
    </StyledList>
  );
};
CommentListModal.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CommentListModal;
