import styled from "styled-components";
import palette from "theme/palette";
import { isNotValid } from "common/helpers";

export const StyledSliderContent = styled.div`
  height: ${(prop) => (prop.isVertical ? prop.height : "auto")};
  padding: ${(prop) => (prop.isVertical ? "0" : "0 14px")};
  margin-bottom: ${(prop) => (prop?.marginBottom)};
  margin-left: ${(prop) => (prop.isVertical ? "0" : "10px")};
  margin-right: ${(prop) => (prop.isVertical ? "0" : "10px")};
  .MuiSlider-vertical .MuiSlider-markLabel {
    width: calc(100vw - 35vw); // Necessary to be responsive
    white-space: pre-wrap;
    word-break: normal;
  }
  .MuiSlider-thumb {
    color: ${palette.background.purple};
    width: 26px;
    height: 26px;
    margin-top: 0;
    display: ${(props) => (isNotValid(props.value) ? "none" : "block")};
  }
  .MuiSlider-vertical .MuiSlider-thumb {
    margin-bottom: 0;
  }
  .MuiSlider-vertical .MuiSlider-markLabel {
    left: 40px;
    top: auto;
  }
  .MuiSlider-markLabel {
    font-size: ${(prop) => (prop.isShortAnswer ? "14px" : "12px")};
    color: ${palette.input.borderControl};
    top: -10px;
  }
  .MuiSlider-markLabelActive {
    font-size: ${(prop) => (prop.isShortAnswer ? "14px" : "12px")};
    font-weight: ${(props) => (!isNotValid(props.value) && "700")};
  }
  .MuiSlider-root {
    color: ${palette.background.purple};
    margin-top: ${(prop) => (prop?.marginTop)};
  }
  .MuiSlider-rail {
    width: 100%;
    height: 8px;
    opacity: 1;
    color: ${palette.background.lightGreyBorder};
    border-radius: 10px;
  }
  .MuiSlider-vertical .MuiSlider-rail {
    width: 8px;
    height: 100%;
  }
  .Mui-disabled {
    pointer-events: none;
  }
  @media (max-width: 600px)
    .MuiSlider-vertical .MuiSlider-markLabel {
      width: calc(100vw - 33vw);
    }
  }
  @media (max-width: 500px) {
    .MuiSlider-vertical .MuiSlider-markLabel {
      width: calc(100vw - 37vw);
    }
  }
  @media (max-width: 450px) {
    .MuiSlider-vertical .MuiSlider-markLabel {
      width: calc(100vw - 31vw);
    }
  }
  @media (max-width: 360px){
    .MuiSlider-vertical .MuiSlider-markLabel {
      width: calc(100vw - 33vw);
    }
  }
  .multiple-answers .MuiSlider-mark {
    width: 18px;
    height: 18px;
    border-radius: 50px;
    color: ${palette.background.lightGrey};
    background-color: ${(props) => (isNotValid(props.value) ? palette.background.lightGrey : "")};
    :hover {
      color: ${palette.background.purple};
      background-color: ${(props) => (isNotValid(props.value) ? palette.background.purple : "")};
    }
  }
  .multiple-answers .MuiSlider-thumb {
    margin-left: 10px;
  }
  .multiple-answers {
    .MuiSlider-root {
      position: relative;
    }

    .MuiSlider-markLabel {
      display: flex;
      justify-content: center;
    }

    .MuiSlider-valueLabel {
      left: auto !important;
      top: -10px !important;
      // transform: none !important;
      background: none;
      color: ${palette.background.purple};
      font-size: 11px;
      // & * {
      //   transform: none !important;
      // }
    }

    .MuiSlider-valueLabel::before {
      display: none !important;
    }

    .last {
      margin-left: -100px;
    }
  }
`;
