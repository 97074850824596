import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PrivateRoute from "../../shared/auth/PrivateRoute";

const PrivateRouteWithLayout = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    layout: Layout,
    component: Component,
    ...rest
  } = props;

  return (
    <PrivateRoute
      { ...rest }
      // eslint-disable-next-line react/no-unstable-nested-components
      component={ (matchProps) => (
        <Layout>
          <Component
            { ...matchProps }
            t={ t }
            language={ i18n.language }
            dispatch={ dispatch }
          />
        </Layout>
      ) }
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

PrivateRouteWithLayout.defaultProps = {
  path: "",
};

export default PrivateRouteWithLayout;
