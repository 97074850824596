import { useState } from "react";
import ImageUploading from "react-images-uploading";
import PropTypes from "prop-types";
import isUndefined from "lodash/isUndefined";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import { DATA_URL_KEY, SIZE } from "common/constants";
import { useStyles, StyledAvatar } from "./styles";

const UploadImage = (props) => {
  const {
    id, value, readOnly, onChange, label,
  } = props;
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = useState([]);

  const handleChange = (image) => {
    setProfilePicture(image);
    onChange(id, image[0]);
  };

  return (
    <Grid
      container
      direction={ "row" }
      justify={ "center" }
      alignItems={ "center" }
      marginTop={ "20px" }
      data-testid={ "upload-image-container" }
    >
      <Grid item xs={ 12 }>
        <ImageUploading
          value={ profilePicture }
          onChange={ handleChange }
          dataURLKey={ DATA_URL_KEY }
          acceptType={ ["jpg", "png", "jpeg"] }
        >
          {({ imageList, onImageUpload }) => (
            <div className={ classes.root }>
              <Badge
                overlap={ "circle" }
                anchorOrigin={ {
                  vertical: "bottom",
                  horizontal: "right",
                } }
                badgeContent={ (
                  <div
                    className={
                      !readOnly
                        ? classes.badgeIconContainer
                        : classes.badgeProfileDisabled
                    }
                    onClick={
                      !readOnly ? onImageUpload : (e) => e.preventDefault()
                    }
                  >
                    <EditIcon
                      fontSize={ SIZE.small }
                      className={ classes.badgeIcon }
                    />
                  </div>
                ) }
              >
                <StyledAvatar
                  alt={ "Profile picture" }
                  src={ isUndefined(imageList[0])
                    ? value
                    : imageList[0][DATA_URL_KEY] }
                  className={ classes.avatar }
                />
              </Badge>
            </div>
          )}
        </ImageUploading>
        {label && (
          <span className={ classes.spanContainer }>
            <InfoIcon className={ classes.messageIcon } />
            {label}
          </span>
        )}
      </Grid>
    </Grid>
  );
};

UploadImage.propTypes = {
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default UploadImage;
