import { useState, useEffect } from "react";
import { createUserAbilities } from "ability/UserAbilities";

// Custom hook to manage user abilities
const useUserAbility = (user) => {
  const [ability, setAbility] = useState({});

  useEffect(() => {
    if (user) {
      // Create user abilities using 'user' data
      const rules = createUserAbilities(user);
      setAbility(rules);
    } else {
      setAbility({});
    }
    // eslint-disable-next-line
  }, [user]);

  return ability;
};

export default useUserAbility;
