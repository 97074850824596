import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `3px solid ${theme.palette.text.iconDisabled}`,
    borderRadius: theme.spacing(0),
  },
  content: {
    marginTop: "20px",
  },
  btnSubmit: {
    textAlign: "right",
    marginTop: "20px",
  },
}));
