import { useState } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import {
  TIMEOUT_TRANSITION,
  BUTTON_STYLE_TYPES,
  COLORS_NAME,
  ALIGN_ITEMS,
  COMMENTS,
} from "common/constants";
import Button from "components/Button";
import Modal from "components/Modal";
import CommentList from "./CommentList";
import CommentListModal from "./CommentListModal";
import { useStyles } from "./styles";

const Comments = (props) => {
  const { textLink, title, data } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const hasMaxLengthComment = data && data?.find((item) => item.text.length > COMMENTS.maxLength);

  const handleClick = (newPlacement, closeComment) => (event) => {
    if (hasMaxLengthComment) {
      setIsOpen(true);
    } else if (!closeComment) {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    } else {
      setOpen(false);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const commentList = hasMaxLengthComment
    ? (
      <Modal
        isOpen={ isOpen }
        onClose={ handleCloseModal }
        title={ title }
      >
        <CommentListModal
          data={ data }
        />
      </Modal>
    ) : (
      <Popper
        open={ open }
        anchorEl={ anchorEl }
        placement={ placement }
        transition
        className={ classes.popper }
      >
        {({ TransitionProps }) => (
          <Fade { ...TransitionProps } timeout={ TIMEOUT_TRANSITION }>
            <Paper
              elevation={ 3 }
              variant={ BUTTON_STYLE_TYPES.OUTLINED }
              className={ classes.comments }
            >
              <CommentList
                title={ title }
                onClick={ handleClick(ALIGN_ITEMS.bottom, true) }
                data={ data }
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    );

  return (
    <div data-testid={ "comments" }>
      <Button
        onClick={ handleClick(ALIGN_ITEMS.bottom) }
        color={ COLORS_NAME.primary }
        customStyle={ classes.link }
        onBlur={ !hasMaxLengthComment && handleClick(ALIGN_ITEMS.bottom) }
      >
        {textLink}
      </Button>
      { commentList }
    </div>
  );
};
Comments.propTypes = {
  textLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
};

Comments.defaultProps = {
  data: [],
};
export default Comments;
