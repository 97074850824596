import { useTranslation } from "react-i18next";
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip,
} from "recharts";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import palette from "theme/palette";
import charts from "theme/charts";
import { OBJECT_KEYS } from "common/constants";
import { renderCustomLabelTicks } from "common/utils";
import { StyledSpiderChartContainer } from "styledComponents/Charts";
import DotTooltipDetail from "./components/DotTooltipDetail";
import { renderCustomAxisTick, getRandomColor } from "./functions";

const TalentSpiderChart = (props) => {
  const { dataFormatted, filterNames, isPersonal } = props;
  const { t } = useTranslation("talentDrain");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  return (
    <StyledSpiderChartContainer data-testid={ "spider-chart-component-view" }>
      <ResponsiveContainer>
        <RadarChart
          margin={ isMobile ? charts.margin.mobile : charts.margin.standar }
          data={ dataFormatted }
        >
          <PolarGrid stroke={ palette.charts.radarStroke } />
          <PolarAngleAxis dataKey={ OBJECT_KEYS.name } tick={ (axisTicks) => renderCustomAxisTick(axisTicks, t, isMobile) } />
          <PolarRadiusAxis
            angle={ charts.angles.ninety }
            domain={ charts.rangeDomain.main }
            tick={ renderCustomLabelTicks }
          />
          {filterNames?.map((employee, index) => (
            <Radar
              key={ `filter_${employee}` }
              dataKey={ `${employee}_filter` }
              stroke={ getRandomColor(index) }
              fill={ getRandomColor(index) }
              fillOpacity={ charts.opacity.fillOpacity }
              connectNulls
              dot
            />
          ))}
          { isPersonal && <Tooltip content={ <DotTooltipDetail /> } /> }
        </RadarChart>
      </ResponsiveContainer>
    </StyledSpiderChartContainer>
  );
};

TalentSpiderChart.propTypes = {
  dataFormatted: PropTypes.array.isRequired,
  filterNames: PropTypes.array.isRequired,
  isPersonal: PropTypes.bool,
};

TalentSpiderChart.propTypes = {
  isPersonal: false,
};

export default TalentSpiderChart;
