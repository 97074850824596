import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const StyledAvatarGrid = styled(Grid)`
  .MuiAvatar-root { 
    width: 35px;
    height: 35px;
  }
  @media (max-width:1280px){
    .MuiAvatar-root { 
      width: 55px;
      height: 55px;
    }
  }
`;

export const StyledGrid = styled(Grid)`
  padding: 20px;
`;

export const StyledCategoryTypography = styled(Typography)`
  display: flex;
  margin-top: 10px;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
`;
