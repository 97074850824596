import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { VARIANT, MIN_VALUE } from "common/constants";
import palette from "theme/palette";
import { getPercentage } from "../StaticProgressBar/functions";
import {
  StyledContent, StyledText, StyledSubText,
  StyledLinearProgress, StyledLink, StyledAlert,
} from "./styles";

const StaticCustomProgressBar = (props) => {
  const {
    title,
    percentage,
    description,
    isSublevel,
    color,
    hasOpacity,
    name,
    t,
  } = props;

  return (
    <StyledContent hasOpacity={ hasOpacity }>
      {hasOpacity && name && (
        <StyledAlert>
          <StyledLink to={ `/development-plan?employee-name=${name}` }>
            {t("successions:actions.no_agreements")}
          </StyledLink>
        </StyledAlert>
      )}
      <StyledText>
        <Typography variant={ VARIANT.h6 }>{title}</Typography>
        <Typography variant={ VARIANT.h6 }>{`${percentage}%`}</Typography>
      </StyledText>
      <StyledLinearProgress
        variant={ VARIANT.determinate }
        value={ getPercentage(percentage) }
        isSublevel={ isSublevel }
        customColor={ isSublevel ? palette.background.purple : color }
      />
      <StyledSubText>
        <Typography variant={ VARIANT.h5 }>
          {description}
        </Typography>
      </StyledSubText>
    </StyledContent>
  );
};

StaticCustomProgressBar.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  percentage: PropTypes.number,
  isSublevel: PropTypes.bool,
  description: PropTypes.string,
  color: PropTypes.string,
  hasOpacity: PropTypes.bool,
  name: PropTypes.string,
};

StaticCustomProgressBar.defaultProps = {
  title: null,
  percentage: MIN_VALUE,
  isSublevel: false,
  description: "",
  color: palette.linearProgress.complete,
  hasOpacity: false,
  name: null,
};

export default StaticCustomProgressBar;
