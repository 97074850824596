import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import { isEqual } from "common/helpers";
import { FOLLOW_UP_STATUS, TARGET, MIN_VALUE } from "common/constants";
import {
  StyledCheckCircleIcon,
  StyledCircleIcon,
  StyledProcessContent,
  StyledLink,
  StyledLinkEvaluation,
  StyledPaperProcess,
  StyledProcessDetail,
  StyledProcessDescription,
} from "./styles";

/*
  status:
  - complete
  - incomplete
  - pending
  - closed
*/

const TaskStatusCard = (props) => {
  const {
    title, subtitle, status, link,
  } = props;
  const { t } = useTranslation("common");

  const isCompleted = isEqual(status, FOLLOW_UP_STATUS.complete);
  const statusIcon = isCompleted ? <StyledCheckCircleIcon /> : <StyledCircleIcon />;
  const isActiveLink = link && !isCompleted && !isEqual(FOLLOW_UP_STATUS.closed, status);

  const activeLink = isActiveLink && (
    <Box>
      <StyledLink href={ link } target={ TARGET.blank }>
        <StyledLinkEvaluation>
          { t("answer") }
          <LaunchOutlined />
        </StyledLinkEvaluation>
      </StyledLink>
    </Box>
  );

  return (
    <StyledPaperProcess
      data-testid={ "task-status-card-component" }
      key={ process.id }
      elevation={ MIN_VALUE }
      status={ status }
    >
      <StyledProcessContent isComplete={ isCompleted }>
        <Box>
          { statusIcon }
        </Box>
        <StyledProcessDetail>
          <h2>{ title }</h2>
          <StyledProcessDescription>
            <Box flexGrow={ 1 }>
              <p>{ subtitle }</p>
            </Box>
            { activeLink }
          </StyledProcessDescription>
        </StyledProcessDetail>
      </StyledProcessContent>
    </StyledPaperProcess>
  );
};

TaskStatusCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  link: PropTypes.string,
};

TaskStatusCard.defaultProps = {
  link: null,
};
export default TaskStatusCard;
