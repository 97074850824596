import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StaticCustomProgressBar from "components/StaticCustomProgressBar";
import { ReactComponent as UserIcon } from "assets/images/icons/user.svg";
import InputForm from "components/InputForm";
import Button from "components/Button";
import TooltipIcon from "components/TooltipIcon";
import CollaboratorProfileSummary from "components/CollaboratorProfileSummary";
import SkeletonLoader from "components/SkeletonLoader";
import AlertDialog from "components/AlertDialog";
import {
  addSuccessionsToCollaborator,
  getSuccessionPlansByEmployeeId,
  updateSuccessionPlanByCollaborator,
  deleteSuccessionPlanByCollaborator,
} from "redux/actions/collaboratorActions";
import { getPositionsPaginated, getSuccessionPlansByPosition } from "redux/actions/position/positionActions";
import SimpleCustomAccordion from "components/SimpleCustomAccordion";
import {
  INPUT_RULES, INPUT_TYPE, OBJECT_KEYS,
  BUTTON_STYLE_TYPES, SIZE, VARIANT,
  SKELETONS_NUMBER,
} from "common/constants";
import palette from "theme/palette";
import Successors from "../Successors";
import { calculateTimePassedPercentageAndRemainingTime, getEstimatedTime } from "../../functions";
import {
  StyledContainer, StyledBoxContainer, StyledButtons, StyledButton,
  StyledButtonAddContainer, StyledSuccessionPlan, StyledSuccessionItem,
  StyledSuccessionContainer, StyledAddCircleOutlineIcon, StyledCloseButton,
  StyledRequiredInfo, StyledLink, StyledInfoContainer, StyledSuccessor,
  StyledSuccessorContainer,
} from "./styles";

const CollaboratorInfo = React.memo((props) => {
  const {
    collaborator,
    t,
    positions,
    estimatedTime,
    handleCloseEmployeeInfo,
    unitsCache,
    setUnitsCache,
  } = props;

  const {
    control, handleSubmit, setValue,
    reset, getValues,
  } = useForm();
  const dispatch = useDispatch();

  const employeeId = collaborator.id;
  const [activeForm, setActiveForm] = useState(null);
  const [showAddButton, setShowAddButton] = useState(true);
  const [successionPlans, setSuccessionPlans] = useState([]);
  const [editingSuccessionId, setEditingSuccessionId] = useState(null);
  const [dialog, setDialog] = useState({ isOpen: false, selectedId: null });
  const [units, setUnits] = useState([]);
  const [successorPlans, setSuccessorPlans] = useState([]);

  const {
    data: successionPlansByEmployee,
    isLoading: isLoadingSuccessionPlans,
    refetch,
    // error,
  } = useQuery(["successionPlansByEmployee", employeeId], () => getSuccessionPlansByEmployeeId(employeeId), {
    staleTime: Infinity,
  });

  const getSuccessionPlans = useCallback(async () => {
    if (collaborator?.has_successors) {
      const lowestOrgUnit = collaborator?.job_position?.lowest_level_organization_unit_id;
      const positionId = collaborator?.job_position?.position?.id;
      if (positionId && lowestOrgUnit) {
        const successionQuery = {
          q: {
            organization_unit_id_eq: lowestOrgUnit,
            active_in: [true],
          },
        };
        try {
          const successions = await getSuccessionPlansByPosition(positionId, successionQuery);
          setSuccessorPlans(successions);
        } catch (error) {
          setSuccessorPlans([]);
        }
      }
    }
  });

  useEffect(() => {
    if (successionPlansByEmployee) {
      setSuccessionPlans(successionPlansByEmployee);
      successionPlansByEmployee.forEach((successionData) => {
        const matchingOption = getEstimatedTime(successionData, estimatedTime);

        setValue(`estimated_time_${successionData.id}`, matchingOption);
      });
    }
    // eslint-disable-next-line
  }, [successionPlansByEmployee]);

  useEffect(() => {
    refetch();
    getSuccessionPlans();
  }, []);

  const handlePositionChange = async (value) => {
    if (unitsCache[value]) {
      setUnits(unitsCache[value]);
    } else {
      const query = { q: { position_id_eq: value } };
      const unitsByPosition = await getPositionsPaginated(0, query);
      const unitValues = unitsByPosition.total > 0 ? unitsByPosition.data.reduce((acc, unit) => {
        if (unit.organization_unit) {
          acc.push(unit.organization_unit);
        }
        return acc;
      }, []) : [];
      setUnits(unitValues);
      setUnitsCache((prevCache) => ({
        ...prevCache,
        [value]: unitValues,
      }));
    }
  };

  const onAutocompleteChange = (name, value) => {
    setValue(name, value);
    if (name === "position_id") {
      setValue("organization_unit_id", null);
      setUnits([]);
      if (value !== "") {
        handlePositionChange(value);
      }
    }
  };

  const addForm = () => {
    setActiveForm({ id: Math.random().toString() });
    setShowAddButton(false);
  };

  const onSubmit = async (data) => {
    const dataToSend = {
      succession_plan: {
        position_id: data?.position_id.id,
        organization_unit_id: data?.organization_unit_id.id,
        estimated_time: data?.estimated_time,
      },
    };
    const succession = await dispatch(addSuccessionsToCollaborator(collaborator.id, dataToSend));

    if (succession?.id) {
      setSuccessionPlans((prevPlans) => [...prevPlans, succession]);
    }
    setActiveForm(null);
    setShowAddButton(true);
    reset();
  };

  const handleCloseForm = () => {
    setActiveForm(null);
    setShowAddButton(true);
    reset();
  };

  const handleDeleteSuccession = async (successionId) => {
    await dispatch(deleteSuccessionPlanByCollaborator(successionId));
    setSuccessionPlans((currentPlans) => currentPlans.filter((plan) => plan.id !== successionId));
    setDialog({ isOpen: false, selectedId: null });
  };

  const handleUpdateSuccession = (successionId) => {
    setEditingSuccessionId(successionId);
  };

  const handleUpdateSuccessionPlan = async (successionId) => {
    const fieldName = `estimated_time_${successionId}`;
    const newEstimatedTime = getValues(fieldName);

    if (typeof newEstimatedTime === "string") {
      const dataToSend = {
        succession_plan: {
          estimated_time: newEstimatedTime,
        },
      };
      const succession = await dispatch(
        updateSuccessionPlanByCollaborator(successionId, dataToSend),
      );
      setSuccessionPlans((currentPlans) => currentPlans.map(
        (plan) => (plan.id === successionId ? succession : plan),
      ));
    }
    setEditingSuccessionId(null);
  };

  const handleCloseEditForm = () => {
    setEditingSuccessionId(null);
    setUnits([]);
  };

  const handleOpenDeleteDialog = (id) => {
    setDialog({ isOpen: true, selectedId: id });
  };

  const handleCloseDialog = () => {
    setDialog({ isOpen: false, selectedId: null });
  };

  const handleDeleteSuccessor = (successionPlanId) => {
    setSuccessorPlans((currentSuccessorPlan) => currentSuccessorPlan.filter(
      (plan) => plan.id !== successionPlanId,
    ));
  };

  const getSuccessionContent = (successionPlan) => {
    let defaultValue = null;
    if (editingSuccessionId) {
      defaultValue = getEstimatedTime(successionPlan, estimatedTime) || null;
    }

    const {
      percentage,
      remainingTimeKey,
      remainingTimeValue,
    } = calculateTimePassedPercentageAndRemainingTime(successionPlan);
    const description = t(`successions:plan.${remainingTimeKey}`, { months: remainingTimeValue, years: remainingTimeValue });

    return (
      <Grid item xs={ 12 } md={ 5 }>
        {editingSuccessionId === successionPlan.id ? (
          <Controller
            control={ control }
            name={ `estimated_time_${successionPlan.id}` }
            rules={ INPUT_RULES.required }
            render={ () => (
              <InputForm
                id={ `estimated_time_${successionPlan.id}` }
                options={ estimatedTime }
                type={ INPUT_TYPE.autocomplete }
                label={ t("successions:estimated_time") }
                control={ control }
                name={ `estimated_time_${successionPlan.id}` }
                nameOfAttr={ "name" }
                fieldValue={ "value" }
                size={ SIZE.small }
                defaultValue={ defaultValue }
              />
            ) }
          />
        ) : (
          <StaticCustomProgressBar
            title={ t("successions:plan.progress") }
            percentage={ percentage }
            color={ palette.background.purpleBox }
            description={ description }
            hasOpacity={ successionPlan?.total_agreements === 0 }
            t={ t }
            name={ collaborator.full_name }
          />
        )}
      </Grid>
    );
  };

  const successorsContent = successorPlans.map((succession) => (
    <Successors
      key={ succession.id }
      succession={ succession }
      t={ t }
      estimatedTime={ estimatedTime }
      handleDeleteSuccession={ handleDeleteSuccessor }
    />
  ));

  const successorsCountComponent = (
    <StyledSuccessor>
      <h4>{t("administrator:modules.positions.successions.title")}</h4>
      <StyledInfoContainer>
        <h5>{successorPlans?.length || 0}</h5>
        <UserIcon />
      </StyledInfoContainer>
    </StyledSuccessor>
  );

  return (
    <StyledContainer>
      <StyledCloseButton
        onClick={ () => handleCloseEmployeeInfo(collaborator.id) }
      >
        <CloseIcon />
      </StyledCloseButton>
      <CollaboratorProfileSummary collaborator={ collaborator } />
      {isLoadingSuccessionPlans
      && <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.SIX } />}
      {!isLoadingSuccessionPlans && successionPlans?.length > 0
      && (
        <StyledSuccessionPlan>
          <h4>{`${t("successions:plan.title")}:`}</h4>
          {successionPlans.map((successionPlan) => (
            <StyledSuccessionItem key={ successionPlan.id }>
              <StyledSuccessionContainer container spacing={ 3 } alignItems={ "center" }>
                <Grid item xs={ 12 } md={ 5 }>
                  <h2>{successionPlan.position_name}</h2>
                  <p>{successionPlan.organization_unit_complete_path}</p>
                </Grid>
                {getSuccessionContent(successionPlan)}
                {editingSuccessionId === successionPlan.id
                  ? (
                    <StyledButtons item xs={ 12 } md={ 2 }>
                      <Button
                        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                        size={ SIZE.small }
                        onClick={ () => handleUpdateSuccessionPlan(successionPlan.id) }
                      >
                        <TooltipIcon title={ t("common:common.save") } element={ <SaveIcon /> } />
                      </Button>
                      <Button
                        typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                        onClick={ handleCloseEditForm }
                        size={ SIZE.small }
                      >
                        <TooltipIcon title={ t("common:common.cancel") } element={ <CloseIcon /> } />
                      </Button>
                    </StyledButtons>
                  ) : (
                    <StyledButtons item xs={ 12 } md={ 2 }>
                      <Button
                        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                        size={ SIZE.small }
                        onClick={ () => handleUpdateSuccession(successionPlan.id) }
                        isDisabled={ editingSuccessionId !== null }
                      >
                        <TooltipIcon title={ t("common:common.edit") } element={ <EditIcon /> } />
                      </Button>
                      <Button
                        typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                        size={ SIZE.small }
                        isDisabled={ editingSuccessionId !== null }
                        onClick={ () => handleOpenDeleteDialog(successionPlan.id) }
                      >
                        <TooltipIcon title={ t("common:common.delete") } element={ <DeleteIcon /> } />
                      </Button>
                    </StyledButtons>
                  )}
              </StyledSuccessionContainer>
            </StyledSuccessionItem>
          ))}
        </StyledSuccessionPlan>
      )}
      {activeForm
        && (
          <form onSubmit={ handleSubmit(onSubmit) }>
            <StyledBoxContainer>
              <Grid container spacing={ 2 } alignItems={ "center" } justifyContent={ "center" }>
                <Grid item xs={ 12 } md={ 3 }>
                  <Controller
                    control={ control }
                    name={ "position_id" }
                    rules={ INPUT_RULES.required }
                    render={ () => (
                      <InputForm
                        id={ OBJECT_KEYS.position }
                        options={ positions }
                        type={ INPUT_TYPE.autocomplete }
                        label={ t("successions:position_to_succeed") }
                        control={ control }
                        name={ "position_id" }
                        nameOfAttr={ OBJECT_KEYS.name }
                        fieldValue={ OBJECT_KEYS.id }
                        onChangeValue={ (event, newValue) => {
                          const value = newValue ? newValue[OBJECT_KEYS.id] : "";
                          onAutocompleteChange("position_id", value);
                        } }
                        size={ SIZE.small }
                      />
                    ) }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                  <Controller
                    control={ control }
                    name={ "organization_unit_id" }
                    rules={ INPUT_RULES.required }
                    render={ () => (
                      <InputForm
                        id={ OBJECT_KEYS.unit }
                        options={ units }
                        type={ INPUT_TYPE.autocomplete }
                        label={ t("common:common.unit") }
                        control={ control }
                        name={ "organization_unit_id" }
                        nameOfAttr={ OBJECT_KEYS.completePath }
                        fieldValue={ OBJECT_KEYS.id }
                        onChangeValue={ (event, newValue) => {
                          const value = newValue ? newValue[OBJECT_KEYS.id] : "";
                          onAutocompleteChange("organization_unit_id", value);
                        } }
                        size={ SIZE.small }
                        isDisabled={ units.length === 0 }
                      />
                    ) }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 3 }>
                  <Controller
                    control={ control }
                    name={ "estimated_time" }
                    rules={ INPUT_RULES.required }
                    render={ () => (
                      <InputForm
                        id={ "estimated_time" }
                        options={ estimatedTime }
                        type={ INPUT_TYPE.autocomplete }
                        label={ t("successions:estimated_time") }
                        control={ control }
                        name={ "estimated_time" }
                        nameOfAttr={ "name" }
                        fieldValue={ "value" }
                        size={ SIZE.small }
                      />
                    ) }
                  />
                </Grid>
                <StyledButtons item xs={ 12 } md={ 2 }>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    type={ BUTTON_STYLE_TYPES.SUBMIT }
                    size={ SIZE.small }
                  >
                    <TooltipIcon title={ t("common:common.save") } element={ <SaveIcon /> } />
                  </Button>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                    onClick={ handleCloseForm }
                    size={ SIZE.small }
                  >
                    <TooltipIcon title={ t("common:common.cancel") } element={ <CloseIcon /> } />
                  </Button>
                </StyledButtons>
              </Grid>
            </StyledBoxContainer>
            <StyledRequiredInfo>
              { t("common:common.api_responses.warning.all_fields_required") }
            </StyledRequiredInfo>
          </form>
        )}
      <StyledButtonAddContainer>
        <StyledLink to={ `/development-plan?employee-name=${collaborator.full_name}` }>
          {t("successions:actions.agreements")}
        </StyledLink>
        <StyledButton
          variant={ VARIANT.outlined }
          typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
          isDisabled={ !showAddButton }
          onClick={ addForm }
        >
          <StyledAddCircleOutlineIcon isDisabled={ !showAddButton } />
          {t("successions:actions.add")}
        </StyledButton>
      </StyledButtonAddContainer>
      <AlertDialog
        isOpen={ dialog.isOpen }
        onClose={ handleCloseDialog }
        title={ t("successions:actions.delete.title") }
        message={ t("successions:actions.delete.description") }
        onSubmit={ () => handleDeleteSuccession(dialog.selectedId) }
      />
      {collaborator?.has_successors && (
        <StyledSuccessorContainer>
          <SimpleCustomAccordion
            header={ successorsCountComponent }
            content={ successorsContent }
            isActive={ false }
          />
        </StyledSuccessorContainer>
      )}
    </StyledContainer>
  );
});

CollaboratorInfo.propTypes = {
  collaborator: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  positions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  estimatedTime: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  handleCloseEmployeeInfo: PropTypes.func.isRequired,
  unitsCache: PropTypes.object.isRequired,
  setUnitsCache: PropTypes.func.isRequired,
};

export default CollaboratorInfo;
