import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { VARIANT } from "common/constants";
import { StyledBox, StyledCard } from "./styles";

const CardSection = (props) => {
  const { title, icon, children, noPadding } = props;
  const havePadding = noPadding ? "noPadding" : "";
  const cardIcon = icon && (
    <Box pr={ 1 }>
      <img src={ icon } alt={ title } />
    </Box>
  )

  return (
    <StyledCard>
      <CardContent className={ havePadding }>
        <Grid container>
          <Grid item xs={ 12 }  className={ "card-title" }>
            <StyledBox>
              { cardIcon }
              <Box>
                <Typography variant={ VARIANT.h5 } >
                  { title }
                </Typography>
              </Box>
            </StyledBox>
          </Grid>
          <Grid item xs={ 12 }>
            { children }
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

CardSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
  ]).isRequired,
  icon: PropTypes.string,
  noPadding: PropTypes.bool,
};

CardSection.defaultProps = {
  icon: null,
  noPadding: false,
};

export default CardSection;
