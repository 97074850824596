export const RESET_STATE = "RESET_STATE_TEMPLATE";
export const GET_LIST = "GET_LIST_TEMPLATES";
export const GET_LIST_LOADING = "GET_LIST_TEMPLATES_LOADING";
export const GET_LIST_ERROR = "GET_LIST_TEMPLATES_ERROR";

export const RESET_STATE_ONE = "RESET_STATE_ONE_TEMPLATE";
export const GET_ONE = "GET_ONE_TEMPLATE"
export const GET_ONE_LOADING = "GET_ONE_TEMPLATE_LOADING"
export const GET_ONE_ERROR = "GET_ONE_TEMPLATE_ERROR"

export const RESET_STATE_PROCESS_TEMPLATE = "RESET_STATE_PROCESS_TEMPLATE"
export const PROCESS_TEMPLATE = "PROCESS_TEMPLATE";
export const PROCESS_TEMPLATE_LOADING = "PROCESS_TEMPLATE_LOADING";
export const PROCESS_TEMPLATE_ERROR = "PROCESS_TEMPLATE_ERROR";
