import {
  RESET_STATE,
  GET_LIST,
  GET_FOLLOW_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  GET_TEMPLATES_LIST,
  GET_TEMPLATES_LIST_LOADING,
  GET_TEMPLATES_LIST_ERROR,
  RESET_STATE_ONE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE_PROCESS_SURVEY_PROCESSES,
  PROCESS_SURVEY_PROCESSES,
  PROCESS_SURVEY_PROCESSES_LOADING,
  PROCESS_SURVEY_PROCESSES_ERROR,
  RESET_STATE_PERFORMANCE_EVOLUTION,
  GET_LIST_PERFORMANCE_EVOLUTION,
  GET_LIST_PERFORMANCE_EVOLUTION_LOADING,
  GET_LIST_PERFORMANCE_EVOLUTION_ERROR,
  RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION,
  GET_COLLABORATOR_PERFORMANCE_EVOLUTION,
  GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING,
  GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR,
  RESET_STATE_GOOD_LEADER_EVOLUTION,
  GET_LIST_GOOD_LEADER_EVOLUTION,
  GET_LIST_GOOD_LEADER_EVOLUTION_LOADING,
  GET_LIST_GOOD_LEADER_EVOLUTION_ERROR,
  RESET_STATE_EVALUATION,
  GET_LIST_LOADING_EVALUATION,
  GET_LIST_EVALUATION,
  GET_LIST_ERROR_EVALUATION,
  RESET_STATE_ONE_EVALUATION,
  GET_ONE_LOADING_EVALUATION,
  GET_ONE_EVALUATION,
  GET_ONE_ERROR_EVALUATION,

  RESET_STATE_ONE_PROCESS,
  GET_ONE_PROCESS,
  GET_ONE_PROCESS_LOADING,
  GET_ONE_PROCESS_ERROR,

  RESET_STATE_BY_EMPLOYEE,
  GET_LIST_BY_EMPLOYEE,
  GET_LIST_BY_EMPLOYEE_LOADING,
  GET_LIST_BY_EMPLOYEE_ERROR,

  RESET_GET_EMPLOYEES_BY_AUDIENCE,
  GET_EMPLOYEES_BY_AUDIENCE,
  GET_EMPLOYEES_BY_AUDIENCE_LOADING,
  GET_EMPLOYEES_BY_AUDIENCE_ERROR,

  GET_LEADER_EVALUATORS,
  GET_LEADER_EVALUATORS_LOADING,
  GET_LEADER_EVALUATORS_ERROR,
  RESET_LEADER_EVALUATORS,

  GET_COLLABORATOR_EVALUATORS,
  GET_COLLABORATOR_EVALUATORS_LOADING,
  GET_COLLABORATOR_EVALUATORS_ERROR,
  RESET_COLLABORATOR_EVALUATORS,

  RESET_STATE_PROCESS_SCHEMA,
  PROCESS_SCHEMA,
  PROCESS_SCHEMA_LOADING,
  PROCESS_SCHEMA_ERROR,
} from "../actionTypes/surveyProcesses";

const INITIAL_STATE = {
  list: null,
  listTotal: 0,
  followList: null,
  loadingList: false,
  errorlist: null,

  listByEmployee: null,
  isLoadingListByEmployee: false,
  errorListByEmployee: null,

  one: null,
  loadingOne: false,
  errorOne: null,

  oneProcess: null,
  isLoadingOneProcess: false,
  errorOneProces: null,

  successProcess: null,
  loadingProcess: false,
  errorProcess: null,

  performanceEvolution: null,
  loadingPerformanceEvolution: false,
  errorPerformanceEvolution: null,

  collaboratorPerformanceEvolution: null,
  loadingCollaboratorPerformanceEvolution: false,
  errorCollaboratorPerformanceEvolution: null,

  goodLeaderEvolution: null,
  isLoadingGoodLeaderEvolution: false,
  errorGoodLeaderEvolution: null,

  evaluations: null,
  isLoadingEvaluations: false,
  errorEvaluations: null,

  evaluation: null,
  isLoadingEvaluation: false,
  errorEvaluation: null,

  collaboratorListByAudience: null,
  collaboratorListByAudienceTotal: 0,
  isLoadingCollaboratorListByAudience: false,
  errorCollaboratorListByAudience: "",

  leaderEvaluators: null,
  collaboratorEvaluators: null,
  leaderEvaluatorsIsLoadingList: false,
  collaboratorEvaluatorsIsLoadingList: false,
  leaderEvaluatorsTotal: 0,
  collaboratorEvaluatorsTotal: 0,
  errorLeaderEvaluators: null,
  errorCollaboratorEvaluators: null,

  successProcessSchema: null,
  isLoadingProcessSchema: false,
  errorProcessSchema: null,
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      loadingList: false,
      errorList: null,
      list: null,
      listTotal: 0,
      followList: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      listTotal: action.total,
      loadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: null,
      followList: null,
      loadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      loadingList: false,
      errorList: action.payload,
    };
  case GET_TEMPLATES_LIST:
    return {
      ...state,
      templatesList: action.payload,
      loadingTemplatesList: false,
      errorTemplatesList: "",
    };
  case GET_TEMPLATES_LIST_LOADING:
    return {
      ...state,
      templatesList: null,
      loadingTemplatesList: true,
      errorTemplatesList: "",
    };
  case GET_TEMPLATES_LIST_ERROR:
    return {
      ...state,
      loadingTemplatesList: false,
      errorTemplatesList: action.payload,
    };
  case GET_FOLLOW_LIST:
    return {
      ...state,
      followList: action.payload,
      loadingList: false,
      errorList: "",
    };
  case RESET_STATE_ONE:
    return {
      ...state,
      loadingOne: false,
      errorOne: null,
      one: null,
    };
  case GET_ONE:
    return {
      ...state,
      one: action.payload,
      loadingOne: false,
      errorOne: "",
    };
  case GET_ONE_LOADING:
    return {
      ...state,
      one: null,
      loadingOne: true,
      errorOne: "",
    };
  case GET_ONE_ERROR:
    return {
      ...state,
      loadingOne: false,
      errorOne: action.payload,
    };
  case RESET_STATE_PROCESS_SURVEY_PROCESSES:
    return {
      ...state,
      loadingProcess: false,
      errorProcess: null,
      successProcess: null,
    };
  case PROCESS_SURVEY_PROCESSES:
    return {
      ...state,
      successProcess: action.payload,
      loadingProcess: false,
      errorProcess: "",
    };
  case PROCESS_SURVEY_PROCESSES_LOADING:
    return {
      ...state,
      successProcess: null,
      loadingProcess: true,
      errorProcess: "",
    };
  case PROCESS_SURVEY_PROCESSES_ERROR:
    return {
      ...state,
      loadingProcess: false,
      errorProcess: action.payload,
    };
  case RESET_STATE_PERFORMANCE_EVOLUTION:
    return {
      ...state,
      loadingPerformanceEvolution: false,
      errorPerformanceEvolution: null,
      performanceEvolution: null,
    };
  case GET_LIST_PERFORMANCE_EVOLUTION:
    return {
      ...state,
      performanceEvolution: action.payload,
      loadingPerformanceEvolution: false,
      errorPerformanceEvolution: "",
    };
  case GET_LIST_PERFORMANCE_EVOLUTION_LOADING:
    return {
      ...state,
      performanceEvolution: null,
      loadingPerformanceEvolution: true,
      errorPerformanceEvolution: "",
    };
  case GET_LIST_PERFORMANCE_EVOLUTION_ERROR:
    return {
      ...state,
      loadingPerformanceEvolution: false,
      errorPerformanceEvolution: action.payload,
    };
  case RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION:
    return {
      ...state,
      loadingCollaboratorPerformanceEvolution: false,
      errorCollaboratorPerformanceEvolution: null,
      collaboratorPerformanceEvolution: null,
    };
  case GET_COLLABORATOR_PERFORMANCE_EVOLUTION:
    return {
      ...state,
      collaboratorPerformanceEvolution: action.payload,
      loadingCollaboratorPerformanceEvolution: false,
      errorCollaboratorPerformanceEvolution: "",
    };
  case GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING:
    return {
      ...state,
      collaboratorPerformanceEvolution: null,
      loadingCollaboratorPerformanceEvolution: true,
      errorCollaboratorPerformanceEvolution: "",
    };
  case GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR:
    return {
      ...state,
      loadingCollaboratorPerformanceEvolution: false,
      errorCollaboratorPerformanceEvolution: action.payload,
    };
  case RESET_STATE_GOOD_LEADER_EVOLUTION:
    return {
      ...state,
      isLoadingGoodLeaderEvolution: false,
      errorGoodLeaderEvolution: null,
      goodLeaderEvolution: null,
    };
  case GET_LIST_GOOD_LEADER_EVOLUTION:
    return {
      ...state,
      goodLeaderEvolution: action.payload,
      isLoadingGoodLeaderEvolution: false,
      errorGoodLeaderEvolution: "",
    };
  case GET_LIST_GOOD_LEADER_EVOLUTION_LOADING:
    return {
      ...state,
      goodLeaderEvolution: null,
      isLoadingGoodLeaderEvolution: true,
      errorGoodLeaderEvolution: "",
    };
  case GET_LIST_GOOD_LEADER_EVOLUTION_ERROR:
    return {
      ...state,
      isLoadingGoodLeaderEvolution: false,
      errorGoodLeaderEvolution: action.payload,
    };
  case RESET_STATE_EVALUATION:
    return {
      ...state,
      isLoadingEvaluations: false,
      errorEvaluations: null,
      evaluations: null,
    };
  case GET_LIST_EVALUATION:
    return {
      ...state,
      evaluations: action.payload,
      isLoadingEvaluations: false,
      errorEvaluations: "",
    };
  case GET_LIST_LOADING_EVALUATION:
    return {
      ...state,
      evaluations: null,
      isLoadingEvaluations: true,
      errorEvaluations: "",
    };
  case GET_LIST_ERROR_EVALUATION:
    return {
      ...state,
      isLoadingEvaluations: false,
      errorEvaluations: action.payload,
    };
  case RESET_STATE_ONE_EVALUATION:
    return {
      ...state,
      isLoadingEvaluation: false,
      errorEvaluation: null,
      evaluation: null,
    };
  case GET_ONE_EVALUATION:
    return {
      ...state,
      evaluation: action.payload,
      isLoadingEvaluation: false,
      errorEvaluation: "",
    };
  case GET_ONE_LOADING_EVALUATION:
    return {
      ...state,
      evaluation: null,
      isLoadingEvaluation: true,
      errorEvaluation: "",
    };
  case GET_ONE_ERROR_EVALUATION:
    return {
      ...state,
      isLoadingEvaluation: false,
      errorEvaluation: action.payload,
    };
  case RESET_STATE_ONE_PROCESS:
    return {
      ...state,
      isLoadingOneProcess: false,
      errorOneProcess: null,
      oneProcess: null,
    };
  case GET_ONE_PROCESS:
    return {
      ...state,
      oneProcess: action.payload,
      isLoadingOneProcess: false,
      errorOneProcess: "",
    };
  case GET_ONE_PROCESS_LOADING:
    return {
      ...state,
      oneProcess: null,
      isLoadingOneProcess: true,
      errorOneProcess: "",
    };
  case GET_ONE_PROCESS_ERROR:
    return {
      ...state,
      isLoadingOneProcess: false,
      errorOneProcess: action.payload,
    };
  case RESET_STATE_BY_EMPLOYEE:
    return {
      ...state,
      isLoadingListByEmployee: false,
      errorListByEmployee: null,
      listByEmployee: null,
    };
  case GET_LIST_BY_EMPLOYEE:
    return {
      ...state,
      listByEmployee: action.payload,
      isLoadingListByEmployee: false,
      errorListByEmployee: "",
    };
  case GET_LIST_BY_EMPLOYEE_LOADING:
    return {
      ...state,
      listByEmployee: null,
      isLoadingListByEmployee: true,
      errorListByEmployee: "",
    };
  case GET_LIST_BY_EMPLOYEE_ERROR:
    return {
      ...state,
      isLoadingListByEmployee: false,
      errorListByEmployee: action.payload,
    };
  case GET_EMPLOYEES_BY_AUDIENCE:
    return {
      ...state,
      collaboratorListByAudience: action.payload,
      collaboratorListByAudienceTotal: action.total,
      isLoadingCollaboratorListByAudience: false,
      errorCollaboratorListByAudience: "",
    };
  case GET_EMPLOYEES_BY_AUDIENCE_LOADING:
    return {
      ...state,
      collaboratorListByAudience: null,
      isLoadingCollaboratorListByAudience: true,
      errorCollaboratorListByAudience: "",
    };
  case GET_EMPLOYEES_BY_AUDIENCE_ERROR:
    return {
      ...state,
      isLoadingCollaboratorListByAudience: false,
      errorCollaboratorListByAudience: action.payload,
    };
  case RESET_GET_EMPLOYEES_BY_AUDIENCE:
    return {
      ...state,
      collaboratorListByAudience: null,
      collaboratorListByAudienceTotal: 0,
      isLoadingCollaboratorListByAudience: false,
      errorCollaboratorListByAudience: null,
    };
  // Evaluators
  case GET_LEADER_EVALUATORS:
    return {
      ...state,
      leaderEvaluators: action.payload,
      leaderEvaluatorsTotal: action.leaderEvaluatorsTotal,
      leaderEvaluatorsLoading: false,
      errorLeaderEvaluators: "",
    };
  case GET_LEADER_EVALUATORS_LOADING:
    return {
      ...state,
      leaderEvaluators: null,
      leaderEvaluatorsLoading: true,
      errorLeaderEvaluators: "",
    };
  case GET_LEADER_EVALUATORS_ERROR:
    return {
      ...state,
      leaderEvaluatorsLoading: false,
      errorLeaderEvaluators: action.payload,
    };
  case RESET_LEADER_EVALUATORS:
    return {
      ...state,
      leaderEvaluators: null,
      leaderEvaluatorsTotal: 0,
      leaderEvaluatorsLoading: false,
      errorLeaderEvaluators: null,
    };
  case GET_COLLABORATOR_EVALUATORS:
    return {
      ...state,
      collaboratorEvaluators: action.payload,
      collaboratorEvaluatorsTotal: action.collaboratorEvaluatorsTotal,
      collaboratorEvaluatorsLoading: false,
      errorCollaboratorEvaluators: "",
    };
  case GET_COLLABORATOR_EVALUATORS_LOADING:
    return {
      ...state,
      collaboratorEvaluators: null,
      collaboratorEvaluatorsLoading: true,
      errorCollaboratorEvaluators: "",
    };
  case GET_COLLABORATOR_EVALUATORS_ERROR:
    return {
      ...state,
      collaboratorEvaluatorsLoading: false,
      errorCollaboratorEvaluators: action.payload,
    };
  case RESET_COLLABORATOR_EVALUATORS:
    return {
      ...state,
      collaboratorEvaluators: null,
      collaboratorEvaluatorsTotal: 0,
      collaboratorEvaluatorsLoading: false,
      errorCollaboratorEvaluators: null,
    };
  case RESET_STATE_PROCESS_SCHEMA:
    return {
      ...state,
      isLoadingProcessSchema: false,
      errorProcessSchema: null,
      successProcessSchema: null,
    };
  case PROCESS_SCHEMA:
    return {
      ...state,
      successProcessSchema: action.payload,
      isLoadingProcessSchema: false,
      errorProcessSchema: "",
    };
  case PROCESS_SCHEMA_LOADING:
    return {
      ...state,
      successProcessSchema: null,
      isLoadingProcessSchema: true,
      errorProcessSchema: "",
    };
  case PROCESS_SCHEMA_ERROR:
    return {
      ...state,
      isLoadingProcessSchema: false,
      errorProcessSchema: action.payload,
    };
  default:
    return state;
  }
};
