import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SimpleNineBoxModal from "components/SimpleNineBoxModal";
import { RISK_CATEGORY } from "views_refactor/Profile/functions/lossRisk";
import {
  StyledResultContainer,
  StyledRiskCategory,
  StyledRiskCategoryContent,
} from "../styles";

const Results = ({ collaborator, t }) => (
  <StyledResultContainer container>
    <Grid item md={ 6 }>
      {collaborator?.results?.nineboxQuadrant?.quadrant >= 0
            && (
              <SimpleNineBoxModal
                quadrantSelected={ collaborator.results.nineboxQuadrant.quadrant }
                updatedAt={ collaborator.results.nineboxQuadrant.updatedAt }
              />
            )}
    </Grid>
    <Grid item md={ 6 }>
      {(collaborator?.risk_category || collaborator?.attrition_chart?.risk_category) && (
        <StyledRiskCategoryContent>
          <StyledRiskCategory
            color={ RISK_CATEGORY[(collaborator?.risk_category
              || collaborator?.attrition_chart?.risk_category)]?.color }
          />
          <h4>
            <span>{`${t("common:common.lossRisk")}:`}</span>
            {" "}
            {t(`talentDrain:${(collaborator?.risk_category || collaborator?.attrition_chart?.risk_category)}`)}
          </h4>
        </StyledRiskCategoryContent>
      )}
    </Grid>
  </StyledResultContainer>
);

Results.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
};

export default Results;
