import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledSubtitle = styled(Typography)`
  margin: 5px 0 20px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 10px 2px;
`;
export const SwitchStyled = styled(Switch)`
  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${palette.text.link}
  }
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
`;
