import styled from "styled-components";
import TableHead from "@mui/material/TableHead";
import theme from "theme/palette";

export const StyledTableHead = styled(TableHead)`
  background-color: ${theme.table.header};
  .MuiTableCell-head {
    color: ${theme.white};
  }
`;
