import PropTypes from "prop-types";
import TabPanel from "components/TabPanel";
import Tab from "components/Tab";
import { AUTOCOMPLETE } from "common/constants";
import {
  TABS,
  INDEX_TABS,
  FEEDBACK_TYPE,
} from "../../../../functions";
import { StyledFeedbackContainer } from "../../styles";
import FeedbackList from "../FeedbackList";

const FeedbackTabs = (props) => {
  const {
    t,
    feedbackStates,
    handleFeedbackTab,
    feedbackData,
    showAsCards,
  } = props;
  const { tab } = feedbackStates;
  const {
    feedbacksReceived, isLoadingListReceived, feedbacksSent, isLoadingListSent,
  } = feedbackData;

  return (
    <StyledFeedbackContainer>
      <Tab
        tabs={ TABS(t, feedbacksReceived?.length, feedbacksSent?.length) }
        onChange={ handleFeedbackTab }
        tabValue={ tab }
        scrollButtons={ AUTOCOMPLETE.off }
      />
      <TabPanel value={ tab } index={ INDEX_TABS.received }>
        <FeedbackList
          { ...props }
          data={ feedbacksReceived }
          viewType={ FEEDBACK_TYPE.from }
          isLoading={ isLoadingListReceived }
          showAsCards={ showAsCards }
        />
      </TabPanel>
      <TabPanel value={ tab } index={ INDEX_TABS.sent }>
        <FeedbackList
          { ...props }
          data={ feedbacksSent }
          viewType={ FEEDBACK_TYPE.to }
          isLoading={ isLoadingListSent }
          showAsCards={ showAsCards }
        />
      </TabPanel>
    </StyledFeedbackContainer>
  );
};

FeedbackTabs.propTypes = {
  t: PropTypes.func.isRequired,
  feedbackStates: PropTypes.object.isRequired,
  handleFeedbackTab: PropTypes.func.isRequired,
  feedbackData: PropTypes.array.isRequired,
  showAsCards: PropTypes.bool,
};

FeedbackTabs.defaultProps = {
  showAsCards: false,
};

export default FeedbackTabs;
