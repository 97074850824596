import styled from "styled-components";
import palette from "theme/palette";
import Tabs from "@mui/material/Tabs";

export const StyledTabs = styled(Tabs)`
  .indicator {
    background-color: ${palette.text.link};
    height: 3px;
    bottom: 0px;
  }
  &.tabs {
    margin-bottom: -3px;
  }
`;
