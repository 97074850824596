import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import InputTextSearch from "components/InputTextSearch";
import { ALIGN_ITEMS } from "common/constants";
import TableSkeletonLoader from "../TableSkeletonLoader";
import {
  StyledTableRow,
  StyledTableBody,
  StyledSearchContainer,
  StyledDownLoad,
  StyledOrder,
  StyledArrowUpwardIcon,
  StyledTableCell,
  StyledAddCircleIcon,
  StyledRemoveCircleIcon,
  StyledTableScroll,
  StyledTableContainer,
} from "../../styles";

const TableDesktop = (props) => {
  const {
    header,
    isLoading,
    handleSearch,
    rightOptions,
    contentTable,
    noBorder,
    isMobile,
    isDisabledSearch,
    handleSort,
    hidden,
    setHidden,
    defaultOrderName,
    hasScroll,
    defaultSearchValue,
  } = props;

  const [orderData, setOrderData] = useState({ [defaultOrderName]: false });

  const activeOrInactiveData = (parentId, isDisabled) => {
    setHidden((prevValues) => (
      { ...prevValues, [parentId]: isDisabled }
    ));
  };

  const orderByColumn = (columnName, direction) => {
    setOrderData({ [columnName]: direction });
    if (handleSort) handleSort(columnName, direction);
  };

  const setParents = useCallback(() => {
    const parentsData = {};
    const parents = header?.filter((item) => item.isParent);
    if (parents) {
      parents.forEach((element) => { parentsData[element.id] = true; });
    }
    setHidden(parentsData);
  }, [header, setHidden]);

  useEffect(() => {
    setParents();
  }, [header, setParents]);

  const content = isLoading ? (
    <StyledTableBody>
      <TableRow>
        <TableSkeletonLoader cols={ header.length } />
      </TableRow>
    </StyledTableBody>
  ) : (
    <StyledTableBody hasBorder={ !noBorder }>
      {contentTable()}
    </StyledTableBody>
  );

  const getOrderIcon = (headCell) => {
    let orderIcon = <StyledArrowUpwardIcon isDisabled />;
    if (orderData[headCell.sortingColumn] || orderData[headCell.sortingColumn] === false) {
      orderIcon = orderData[headCell.sortingColumn] ? <StyledArrowUpwardIcon /> : <ArrowDownwardIcon />;
    }
    return orderIcon;
  };

  const headerContent = header?.map((headCell) => (
    <StyledTableCell
      key={ headCell.id }
      disabled={ headCell.key && hidden[headCell.key] }
    >
      <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
        {headCell.hasSorting && (
          <StyledOrder>
            <IconButton
              onClick={ (e) => (
                orderByColumn(headCell.sortingColumn, !orderData[headCell.sortingColumn], e)
              ) }
            >
              { getOrderIcon(headCell) }
            </IconButton>
          </StyledOrder>
        )}
        {headCell.label}
        {headCell.tooltip}
        {headCell.isParent && (
          <Box>
            <IconButton
              onClick={ () => (
                activeOrInactiveData(headCell.id, !hidden[headCell.id])
              ) }
            >
              {hidden[headCell.id]
                ? <StyledAddCircleIcon />
                : <StyledRemoveCircleIcon /> }
            </IconButton>
          </Box>
        )}
      </Box>
    </StyledTableCell>
  ));

  const table = (
    <StyledTableScroll hasScroll={ hasScroll }>
      <TableHead>
        <StyledTableRow>
          { headerContent }
        </StyledTableRow>
      </TableHead>
      { content }
    </StyledTableScroll>
  );

  return (
    <>
      <StyledSearchContainer isMobile={ isMobile }>
        { handleSearch
        && (
          <InputTextSearch
            handleInput={ handleSearch }
            isDisabled={ isDisabledSearch }
            defaultValue={ defaultSearchValue }
          />
        )}
        { rightOptions && <StyledDownLoad>{ rightOptions }</StyledDownLoad>}
      </StyledSearchContainer>
      <StyledTableContainer component={ Paper } hasScroll={ hasScroll }>
        {table}
      </StyledTableContainer>
    </>
  );
};

TableDesktop.propTypes = {
  header: PropTypes.array.isRequired,
  contentTable: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleSearch: PropTypes.func,
  rightOptions: PropTypes.any,
  noBorder: PropTypes.bool,
  isMobile: PropTypes.bool,
  isDisabledSearch: PropTypes.bool,
  handleSort: PropTypes.func,
  defaultOrderName: PropTypes.string,
  hidden: PropTypes.object,
  setHidden: PropTypes.func,
  hasScroll: PropTypes.bool,
  defaultSearchValue: PropTypes.string,
};

TableDesktop.defaultProps = {
  isLoading: false,
  handleSearch: null,
  rightOptions: null,
  noBorder: false,
  isMobile: false,
  isDisabledSearch: false,
  handleSort: null,
  defaultOrderName: null,
  hidden: {},
  setHidden: () => {},
  hasScroll: false,
  defaultSearchValue: null,
};

export default TableDesktop;
