import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export const StyledTitle = styled(Typography)`
  font-weight: 700;
`;

export const StyledHeader = styled(Grid)`
  .download-satisfaction div {
    justify-content: flex-start;
  }
`;
