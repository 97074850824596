import PropTypes from "prop-types";
import ViewModalComponent from "components/ViewModalComponent";
import { useNalaAnalyticsCardsHook } from "./hooks/nalaAnalyticsCards.hook";
import NalaAnalyticsView from "./NalaAnalytics.view";

const NalaAnalyticsController = (props) => {
  const { t, handleOpenModal } = props;
  const nalaAnalyticsCardStates = useNalaAnalyticsCardsHook({ t, handleOpenModal });

  return (
    <ViewModalComponent viewComponent={ (
      <NalaAnalyticsView
        { ...props }
        { ...nalaAnalyticsCardStates }
      />
    ) }
    />
  );
};

NalaAnalyticsController.propTypes = {
  t: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default NalaAnalyticsController;
