import withStyles from "@mui/styles/withStyles";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import { RobotoFont } from "common/constants";

export const StepperStyled = withStyles({
  root: {
    padding: 0,
    "& .MuiStepConnector-vertical": {
      padding: 0,
      paddingLeft: "3px",
    },
    "& .MuiStepConnector-lineVertical": {
      borderLeftWidth: "3px",
    },
  },
})(Stepper);

export const TitleStyled = withStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontFamily: RobotoFont,
    paddingLeft: "20px",
    color: theme.palette.text.subtitle,
  },
  iconContainer: {
    borderRadius: "100%",
    padding: "6px 7px",
    background: (props) => (props.isinactive
      ? theme.palette.btnIcon.light
      : theme.palette.btnIcon.color),
  },
}))(StepLabel);

export const StepContentStyled = withStyles((theme) => ({
  root: {
    marginTop: 0,
    marginLeft: "15px",
    paddingLeft: "37px",
    fontSize: "14px",
    fontFamily: RobotoFont,
    borderLeft: `3px solid ${theme.palette.btnIcon.light}`,
    color: theme.palette.text.subtitle,
  },
}))(StepContent);
