import {
  RESET_STATE,
  RESET_STATE_RULES,
  RESET_PROCCESS_GOAL,
  GET_GOALS,
  GET_GOALS_LOADING,
  GET_GOALS_ERROR,
  GET_GENERAL_GOALS,
  GET_GOALS_RULES,
  GET_GOALS_RULES_LOADING,
  GET_GOALS_RULES_ERROR,
  PROCCESS_GOALS,
  PROCCESS_GOALS_LOADING,
  PROCCESS_GOALS_ERROR,
  RESET_STATE_ONE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
} from "../actionTypes/goals";

const initialState = {
  list: null,
  listTotal: 0,
  isLoadingList: false,
  errorList: "",
  generalList: null,

  rulesList: null,
  isLoadingRulesList: false,
  errorRulesList: "",

  successProcess: null,
  isLoadingProcess: false,
  errorProcess: "",

  one: null,
  isLoadingOne: false,
  errorOne: "",
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      list: null,
      listTotal: 0,
      isLoadingList: false,
      errorList: "",
    };
  case RESET_STATE_RULES:
    return {
      ...state,
      rulesList: null,
      isLoadingRulesList: false,
      errorRulesList: "",
    };
  case RESET_PROCCESS_GOAL:
    return {
      ...state,
      successProcess: null,
      isLoadingProcess: false,
      errorProcess: "",
    };
  case GET_GOALS_LOADING:
    return {
      ...state,
      isLoadingList: true,
    };
  case GET_GOALS:
    return {
      ...state,
      isLoadingList: false,
      list: action.payload,
      listTotal: action.total,
    };
  case GET_GOALS_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case GET_GENERAL_GOALS:
    return {
      ...state,
      isLoadingList: false,
      generalList: action.payload,
      listTotal: action.total,
    };
  case GET_GOALS_RULES_LOADING:
    return {
      ...state,
      isLoadingRulesList: true,
    };

  case GET_GOALS_RULES:
    return {
      ...state,
      isLoadingRulesList: false,
      rulesList: action.payload,
    };
  case GET_GOALS_RULES_ERROR:
    return {
      ...state,
      isLoadingRulesList: false,
      errorRulesList: action.payload,
    };
  case PROCCESS_GOALS_LOADING:
    return {
      ...state,
      isLoadingProcess: true,
    };
  case PROCCESS_GOALS:
    return {
      ...state,
      isLoadingProcess: false,
      successProcess: action.payload,
    };
  case PROCCESS_GOALS_ERROR:
    return {
      ...state,
      isLoadingProcess: false,
      errorProcess: action.payload,
    };
  case RESET_STATE_ONE:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: null,
      one: null,
    };
  case GET_ONE:
    return {
      ...state,
      one: action.payload,
      isLoadingOne: false,
      errorOne: "",
    };
  case GET_ONE_LOADING:
    return {
      ...state,
      one: null,
      isLoadingOne: true,
      errorOne: "",
    };
  case GET_ONE_ERROR:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: action.payload,
    };
  default:
    return state;
  }
};
