import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Modal from "components/Modal";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import NineBoxProfile from "../SkillsModal/components/NineBoxProfile";

const NineBoxModal = (props) => {
  const {
    t,
    profileStates,
    onCloseModals,
  } = props;

  const { isModalOpen } = profileStates;

  return (
    <Modal
      isOpen={ isModalOpen.ninebox }
      title={ t("profile:modals.skills.matrix.title") }
      onClose={ () => onCloseModals() }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "900px" }>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 }>
            <NineBoxProfile { ...props } />
          </Grid>
        </Grid>
      </StyledScrollBar>
    </Modal>
  );
};

NineBoxModal.propTypes = {
  t: PropTypes.func.isRequired,
  profileStates: PropTypes.object.isRequired,
  onCloseModals: PropTypes.func.isRequired,
};

export default NineBoxModal;
