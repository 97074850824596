import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputForm from "components/InputForm";
import {
  DEFAULT_MIN_DECIMAL,
  INPUT_RULES,
  INPUT_TYPE,
  LOCAL_STORAGE_NAMES,
  MIN_VALUE,
  OBJECT_KEYS,
  VARIANT,
} from "common/constants";
import { getErrorMessage } from "common/formValidators";
import { isEqual } from "common/helpers";
import { getCurrencyFormat, getFormattedItems, getItemFromLocalStorage } from "common/utils";
import useComplexState from "hooks/utils/useComplexState";
import moneyImage from "assets/images/profile/money.svg";
import { StyledCardContainer } from "../../styles";

const SalaryForm = (props) => {
  const {
    collaborator,
    hookEmployeeForm,
  } = props;
  const { t } = useTranslation("collaborators");
  const { control, errors } = hookEmployeeForm;
  const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);
  const [salaryInfoStates, setSalaryInfoStates] = useComplexState({
    codeCurrency: "",
    contractEmploymentRelationship: null,
    projectedCtc: MIN_VALUE,
    exchange: MIN_VALUE,
    currencies,
  });

  useEffect(() => {
    if (collaborator) {
      const contractEmploymentRelationship = collaborator.job_position?.type_of_contract?.employment_relationship_id;
      setSalaryInfoStates({
        codeCurrency: currencies.find(
          (currency) => isEqual(currency.name_with_code, collaborator?.salary?.currency),
        )?.code,
        contractEmploymentRelationship,
        projectedCtc: contractEmploymentRelationship || collaborator?.salary?.projected_ctc
          ? getCurrencyFormat(collaborator?.salary?.projected_ctc) : MIN_VALUE,
        exchange: contractEmploymentRelationship || collaborator?.salary?.projected_ctc
          ? getCurrencyFormat(
            collaborator?.salary?.projected_ctc,
            collaborator?.salary?.currency,
            currencies,
          ) : MIN_VALUE,
      });
    }
    // eslint-disable-next-line
  }, [collaborator]);

  const projection = (
    <StyledCardContainer>
      <Grid container>
        <Grid item xs={ 12 } sm={ 8 }>
          <Typography>
            {t("account:accountDetails.payrollProjection.local")}
          </Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 4 }>
          <Typography variant={ VARIANT.h6 }>
            <NumberFormat
              value={ salaryInfoStates.projectedCtc }
              displayType={ INPUT_TYPE.text }
              thousandSeparator
              prefix={ "$" }
              fixedDecimalScale
              decimalScale={ DEFAULT_MIN_DECIMAL }
            />
          </Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 8 }>
          <Typography>
            {t("account:accountDetails.payrollProjection.usd")}
          </Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 4 }>
          <Typography variant={ VARIANT.h6 }>
            <NumberFormat
              value={ salaryInfoStates.exchange }
              displayType={ INPUT_TYPE.text }
              thousandSeparator
              prefix={ "$" }
              fixedDecimalScale
              decimalScale={ DEFAULT_MIN_DECIMAL }
            />
          </Typography>
        </Grid>
      </Grid>
      <img src={ moneyImage } alt={ "Money" } />
    </StyledCardContainer>
  );

  return (
    <>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } md={ 6 }>
          <InputForm
            type={ INPUT_TYPE.select }
            id={ OBJECT_KEYS.typeOfContract }
            label={ t("common:common.typeOfContract") }
            control={ control }
            name={ "collaborator.job_position_attributes.type_of_contract_id" }
            menuItems={ getFormattedItems(LOCAL_STORAGE_NAMES.typeOfContract) }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } md={ 6 }>
          <InputForm
            type={ INPUT_TYPE.autocomplete }
            id={ OBJECT_KEYS.currency }
            options={ salaryInfoStates.currencies }
            label={ t("common:common.currency.main") }
            control={ control }
            name={ "collaborator.salary_attributes.currency" }
            nameOfAttr={ OBJECT_KEYS.nameWithCode }
            rules={ INPUT_RULES.required }
            error={ getErrorMessage(errors, "collaborator.salary_attributes.currency_id", "currency") }
            isRequired
          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <InputForm
            type={ INPUT_TYPE.currency }
            id={ OBJECT_KEYS.salary }
            label={ t("common:common.salary") }
            control={ control }
            name={ "collaborator.salary_attributes.gross_salary" }
          />
        </Grid>
        <Grid item xs={ 12 }>
          { projection }
        </Grid>
      </Grid>
    </>
  );
};

SalaryForm.propTypes = {
  collaborator: PropTypes.object.isRequired,
  hookEmployeeForm: PropTypes.object.isRequired,
};

export default SalaryForm;
