import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_FEEDBACK,
  PROCESS_FEEDBACK,
  PROCESS_FEEDBACK_LOADING,
  PROCESS_FEEDBACK_ERROR,
  RESET_STATE_RANKING,
  GET_RANKING,
  GET_RANKING_LOADING,
  GET_RANKING_ERROR,
} from "../actionTypes/acknowledgementTypes";

const INITIAL_STATE = {
  list: null,
  isLoadingList: false,
  errorList: "",

  rankingList: null,
  isLoadingRankingList: false,
  errorRankingList: "",

  isLoadingProcessFeedback: false,
  successProcessFeedback: null,
  errorProcessFeedback: null,
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      errorList: null,
      list: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: null,
      isLoadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case RESET_STATE_FEEDBACK:
    return {
      ...state,
      isLoadingProcessFeedback: false,
      errorProcessFeedback: null,
      successProcessFeedback: null,
    };
  case PROCESS_FEEDBACK:
    return {
      ...state,
      isLoadingProcessFeedback: false,
      errorProcessFeedback: null,
      successProcessFeedback: action.payload,
    };
  case PROCESS_FEEDBACK_LOADING:
    return {
      ...state,
      isLoadingProcessFeedback: true,
      errorProcessFeedback: null,
      successProcessFeedback: null,
    };
  case PROCESS_FEEDBACK_ERROR:
    return {
      ...state,
      errorProcessFeedback: action.payload,
      isLoadingProcessFeedback: false,
    };
  case RESET_STATE_RANKING:
    return {
      ...state,
      isLoadingRankingList: false,
      errorRankingList: null,
      rankingList: null,
    };
  case GET_RANKING:
    return {
      ...state,
      rankingList: action.payload,
      isLoadingRankingList: false,
      errorRankingList: "",
    };
  case GET_RANKING_LOADING:
    return {
      ...state,
      rankingList: null,
      isLoadingRankingList: true,
      errorRankingList: "",
    };
  case GET_RANKING_ERROR:
    return {
      ...state,
      isLoadingRankingList: false,
      errorRankingList: action.payload,
    };
  default:
    return state;
  }
};
