import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useComplexState from "hooks/utils/useComplexState";
import { NINEBOX, TIMEOUT_TRANSITION } from "common/constants";
import { getList as getResultScales } from "redux/actions/resultScalesActions";
import { isNotValid, isEmpty } from "common/helpers";
import HistoricalEmployee from "../components/HistoricalEmployee";
import { getFilteredData } from "../functions";

export const useNineboxHook = (externalHooks) => {
  const { dispatch, t } = externalHooks;

  const [nineboxStates, setNineboxStates] = useComplexState({
    nineBoxResult: null,
    quadrantResult: [],
    dataBox: null,
    circleSelected: null,
    accordionSelected: undefined,
    selectedQuadrant: NINEBOX.quadrantIndex.all,
    selectedPanel: null,
    anchorEl: null,
    isLoading: false,
    employeeGroup: null,
    filteredData: null,
    optionSelected: null,
    data: null,
  });

  const {
    list: resultScaleList,
    isLoadingList: isLoadingScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const resetQuadrants = () => {
    setNineboxStates({
      circleSelected: null,
      accordionSelected: null,
      selectedPanel: null,
      selectedQuadrant: NINEBOX.quadrantIndex.all,
    });
  };

  useEffect(() => {
    dispatch(getResultScales());
  }, [dispatch]);

  const getQuadrantDetail = (result) => {
    setNineboxStates({
      circleSelected: null,
      accordionSelected: null,
      selectedPanel: null,
      quadrantResult: result,
    });
  };

  const filterData = useCallback(
    async () => {
      if (!isNotValid(nineboxStates.quadrantResult) && !isNotValid(nineboxStates.data)) {
        const filteredData = await getFilteredData(
          nineboxStates.data,
          nineboxStates.quadrantResult,
        );
        setNineboxStates({
          dataBox: filteredData,
        });
      }
    },
    [
      nineboxStates.quadrantResult,
      nineboxStates.data,
      setNineboxStates,
    ],
  );

  useEffect(() => {
    filterData();
    // eslint-disable-next-line
  }, [
    nineboxStates.quadrantResult,
  ]);

  const handleSelectedQuadrant = (quadrant = null) => {
    setNineboxStates({
      selectedQuadrant: quadrant,
    });
  };

  const handleEmployeeList = (event, result) => {
    if (result) setNineboxStates({ employeeGroup: result });
    setNineboxStates({ anchorEl: nineboxStates.anchorEl ? null : event.currentTarget });
  };

  const getHistoricalComponent = (panel) => {
    if (!isEmpty(panel)) {
      const employeeData = nineboxStates.data.find((item) => item.id === panel.id);
      employeeData.historical = (
        <HistoricalEmployee
          data={ panel }
          t={ t }
          dispatch={ dispatch }
          resultScaleList={ resultScaleList }
          isLoadingScaleList={ isLoadingScaleList }
        />
      );
    }
  };

  const handleSelectedPanel = async (panel) => {
    setNineboxStates({
      selectedPanel: panel,
    });
    getHistoricalComponent(panel);
  };

  const handleCircleSelected = (result) => {
    resetQuadrants();
    setTimeout(() => {
      getHistoricalComponent(result);
      setNineboxStates({
        circleSelected: result.index,
        accordionSelected: result.index,
        selectedPanel: result,
      });
    }, TIMEOUT_TRANSITION);
  };

  const handleCircleAndReset = (e, result) => {
    handleEmployeeList(e);
    handleCircleSelected(result);
  };

  // When a filter is applied, the query is received for use in the Matrix Ninebox component.
  const handleExternalQuery = (newQuery) => {
    resetQuadrants();
    setNineboxStates({
      data: null,
      filteredData: null,
      nineBoxResult: null,
      query: newQuery,
    });
  };

  const handleChangeDataByOption = (optionSelected) => {
    resetQuadrants();
    setNineboxStates({
      data: optionSelected === 0 ? nineboxStates.filteredData : nineboxStates.nineBoxResult,
      optionSelected,
    });
  };

  return {
    nineboxStates,
    setNineboxStates,
    resultScaleReducerStates: {
      resultScaleList,
      isLoadingScaleList,
    },
    getQuadrantDetail,
    resetQuadrants,
    handleSelectedQuadrant,
    handleSelectedPanel,
    handleEmployeeList,
    handleCircleSelected,
    handleCircleAndReset,
    handleExternalQuery,
    handleChangeDataByOption,
  };
};
