import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledDragItem = styled(Grid)`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const StyledItemContainer = styled.div`
  transform: ${(props) => props.transform};
  transition: ${(props) => props.transition};
  align-items: center;
  user-select: none;
  z-index: ${(props) => (props.isDragging ? 100 : "auto")};
  opacity: ${(props) => (props.isDragging ? 0.3 : 1)};
  border: ${(props) => (props.isSelectedItem ? `1px solid ${palette.text.link}` : "none")};
  cursor: pointer;
`;

export const StyledDragButton = styled.a`
  margin-right: 10px;
  cursor: grab;
  border: 0;
  background: none;
`;
