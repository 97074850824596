import { ImageLoader } from "react-progressive-img-loader";
import Avatar from "@mui/material/Avatar";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import { ReactComponent as Envelop } from "assets/images/icons/envelop.svg";
import { StyledTitleWithSubtitle } from "components/TablePagination/styles";
import {
  StyledImageContainer, StyledStatus, StyledBoxContainer,
} from "views/Collaborators/styles";

export const HEADER = ["collaborator_list", ""];

export const getRowsDesktop = (data) => {
  const rows = data?.map((rowData) => [
    {
      content: (
        <StyledBoxContainer>
          <StyledImageContainer>
            {(rowData.profile_img_url_thumb && rowData.profile_img_url) ? (
              <ImageLoader
                src={ rowData.profile_img_url }
                srcPreview={ rowData.profile_img_url_thumb }
                customImageComponent={ Avatar }
              />
            ) : !rowData.profile_img_url_thumb && (
              <Avatar src={ rowData.profile_img_url } />
            )}
            <StyledStatus isActive={ rowData.is_active }>
              {rowData.is_active ? <Check className={ "active" } /> : <Clear className={ "inactive" } />}
            </StyledStatus>
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <div className={ "title" }>{rowData.full_name}</div>
            <div className={ "subtitle" }>
              <Envelop />
              {rowData.email}
            </div>
          </StyledTitleWithSubtitle>
        </StyledBoxContainer>
      ),
    },
  ]);
  return rows;
};
