import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ImageLoader } from "react-progressive-img-loader";
import parse from "html-react-parser";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FEEDBACK_TYPE, VARIANT } from "common/constants";
import { getCurrentLanguage } from "common/utils";
import {
  Avatar,
  AcknowledgmentIcon,
  AcknowledgmentIconTitle,
  SSCMessageType,
} from "./styledComponents";
import { useStyles } from "./styles";

const CardMessage = (props) => {
  const {
    imageUrl,
    imageThumbnailUrl,
    name,
    position,
    date,
    type,
    acknowledgementElement,
    TypeImage,
    message,
    sscData,
  } = props;

  const { t } = useTranslation("feedback");
  const classes = useStyles();
  return (
    <Card data-testid={ "card-message-view" }>
      <Grid container>
        <Grid container item xs={ 12 } sm={ 3 } md={ 5 } lg={ 3 } className={ classes.grid }>
          <Grid item xs={ 12 } sm={ 5 } md={ 3 }>
            <ImageLoader src={ imageUrl } srcPreview={ imageThumbnailUrl } customImageComponent={ Avatar } />
          </Grid>
          <Grid item xs={ 12 } sm={ 8 } md={ 9 } >
            <Typography variant={ VARIANT.h6 }>{name}</Typography>
            <Typography variant={ VARIANT.subtitleOne }>{position}</Typography>
            <Typography variant={ VARIANT.subtitleTwo }>{date}</Typography>
            <Typography className={ classes.typeMessage } variant={ "subtitle2" }>
              {TypeImage && <TypeImage />}
              {t(type)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={ 8 } sm={ 7 } md={ 5 } lg={ 7 } className={ classes.grid }>
          {type === FEEDBACK_TYPE.feedbackSSC ? (
            <>
              <Grid item xs={ 12 }>
                <SSCMessageType>
                  <Typography variant={ VARIANT.h6 }>{t("should_stop_do")}</Typography>
                  <Typography variant={ VARIANT.bodyOne }>{sscData.should_stop_do ? parse(sscData.should_stop_do) : ""}</Typography>
                </SSCMessageType>
              </Grid>
              <Grid item xs={ 12 }>
                <SSCMessageType>
                  <Typography variant={ VARIANT.h6 }>{t("should_start_do")}</Typography>
                  <Typography variant={ VARIANT.bodyOne }>{sscData.should_start_do ? parse(sscData.should_start_do) : ""}</Typography>
                </SSCMessageType>
              </Grid>
              <Grid item xs={ 12 }>
                <SSCMessageType>
                  <Typography variant={ VARIANT.h6 }>{t("should_continue_do")}</Typography>
                  <Typography variant={ VARIANT.bodyOne }>{sscData.should_continue_do ? parse(sscData.should_continue_do) : ""}</Typography>
                </SSCMessageType>
              </Grid>
            </>
          ) : (
            message
          )}
        </Grid>
        {acknowledgementElement && (
          <Grid item xs={ 4 } sm={ 2 } md={ 2 } lg={ 2 }>
            <AcknowledgmentIcon>
              <img src={ acknowledgementElement.external_img } alt={ acknowledgementElement.title } />
              <AcknowledgmentIconTitle>
                { acknowledgementElement[`title_${getCurrentLanguage()}`] }
              </AcknowledgmentIconTitle>
            </AcknowledgmentIcon>
          </Grid>
        )}

      </Grid>
    </Card>
  );
};

CardMessage.propTypes = {
  imageUrl: PropTypes.string,
  imageThumbnailUrl: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  position: PropTypes.string,
  type: PropTypes.string,
  acknowledgementElement: PropTypes.object,
  typeImage: PropTypes.element,
  message: PropTypes.string,
  sscData: PropTypes.object,
};

export default CardMessage;
