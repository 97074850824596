import { useState } from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledTitle,
  StyledAccordionDetails,
} from "./styles";

const IndividualAccordion = (props) => {
  const { title, content, isActive } = props;
  const [expanded, setExpanded] = useState(isActive ? "panel1-content" : false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <StyledAccordion elevation={ 0 } expanded={ expanded === "panel1-content" } onChange={ handleChange("panel1-content") }>
        <StyledAccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          aria-controls={ "panel1-content" }
          id={ "panel1-header" }
          sx={ {
            flexDirection: "row-reverse",
          } }
        >
          <StyledTitle>{title}</StyledTitle>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {content}
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

IndividualAccordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  isActive: PropTypes.bool,
};

IndividualAccordion.defaultProps = {
  title: "",
  content: "",
  isActive: true,
};

export default IndividualAccordion;
