import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Accordion from "components/Accordion";
import { getPercent } from "common/utils";
import { BORDER_ACCORDION_TYPE } from "common/constants";
import dominantImg from "assets/images/potential/dominante.svg";
import influentialImg from "assets/images/potential/influyente.svg";
import consciousImg from "assets/images/potential/concienzudo.svg";
import stableImg from "assets/images/potential/estable.svg";
import useStyles from "./styles";

const DefinitionResults = (props) => {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation("potential");

  const isMaxResult = (result) => {
    let results = [
      data.decision,
      data.interaction,
      data.compliance,
      data.serenity,
    ];
    return _.isEqual(result, _.max(results));
  };

  const getResult = (result, title) => {
    let customResult = "";
    if (!_.isNull(result)) {
      customResult = (
        <Box display="flex">
          <Box display="flex" className={classes.title}>
            <Box>
              <Typography variant="button">{title}</Typography>
            </Box>
            <Box>
              {isMaxResult(result) && (
                <CheckCircle className={classes.maxResultIcon} />
              )}
            </Box>
          </Box>
          <Box>
            <Typography variant="h6">{getPercent(result, true)}</Typography>
          </Box>
        </Box>
      );
    }
    return customResult;
  };

  return (
    <div data-testid="definition-results">
      {data && (
        <Accordion
          type={BORDER_ACCORDION_TYPE.rounded}
          background="light"
          isBackgroundContent
          isPadding
          data={[
            {
              title: t("chart.dominant"),
              src: dominantImg,
              variant: "button",
              customHeader:
                !_.isEmpty(data) &&
                getResult(data.decision, t("chart.dominant")),

              customRender: (
                <Typography variant="body1">
                  {t("personality-test.dominant_text")}
                </Typography>
              ),
            },
            {
              title: t("chart.influential"),
              src: influentialImg,
              variant: "button",
              customHeader:
                !_.isEmpty(data) &&
                getResult(data.interaction, t("chart.influential")),
              customRender: (
                <Typography variant="body1">
                  {t("personality-test.influential_text")}
                </Typography>
              ),
            },
            {
              title: t("chart.conscious"),
              src: consciousImg,
              variant: "button",
              customHeader:
                !_.isEmpty(data) &&
                getResult(data.compliance, t("chart.conscious")),
              customRender: (
                <Typography variant="body1">
                  {t("personality-test.conscious_text")}
                </Typography>
              ),
            },
            {
              title: t("chart.stable"),
              src: stableImg,
              variant: "button",
              customHeader:
                !_.isEmpty(data) && getResult(data.serenity, t("chart.stable")),
              customRender: (
                <Typography variant="body1">
                  {t("personality-test.stable_text")}
                </Typography>
              ),
            },
          ]}
        />
      )}
    </div>
  );
};

DefinitionResults.propTypes = {
  data: PropTypes.object,
};

export default DefinitionResults;
