import styled from "styled-components";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledContent = styled.div`
  padding: 30px;
`;

export const StyledDescription = styled(Typography)`
  text-align: justify;
  max-width: 500px;
`;

export const StyledSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 20px;
`;

export const StyledList = styled.ul`
  width: 100%;
  padding: 20px;
  li {
    margin-bottom: 10px;
    color: ${palette.text.link};
  }
  a {
    color: ${palette.text.link};
  }
`;
