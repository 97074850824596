import qs from "qs";
import { INDEX, PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";

import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_PROCESS,
  PROCCESS_HISTORICAL_MOVEMENT,
  PROCCESS_HISTORICAL_MOVEMENT_LOADING,
  PROCCESS_HISTORICAL_MOVEMENT_ERROR,
  RESET_STATE_TO_DOWNLOAD,
  GET_LIST_TO_DOWNLOAD,
  GET_LIST_TO_DOWNLOAD_LOADING,
  GET_LIST_TO_DOWNLOAD_ERROR,
} from "../actionTypes/historicalMovement";

const URL = {
  main: "/employees",
  historicalMovement: "/historical_movements",
  movementsHistory: "historical_movements",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PROCESS,
  });
};

export const getList = (employeeId) => async (dispatch) => {
  dispatch({ type: GET_LIST_LOADING });
  try {
    const response = await configAxios.get(`${URL.main}/${employeeId}/${URL.movementsHistory}`);
    dispatch({
      type: GET_LIST,
      payload: response.data.historical_movements,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const create = (data, employeeId) => async (dispatch) => {
  dispatch({ type: PROCCESS_HISTORICAL_MOVEMENT_LOADING });
  try {
    const response = await configAxios.post(`${URL.main}/${employeeId}/${URL.movementsHistory}`, data);
    dispatch({
      type: PROCCESS_HISTORICAL_MOVEMENT,
      payload: response.data.historical_movement,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_HISTORICAL_MOVEMENT_ERROR);
  }
};

export const resetStateToDownload = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_TO_DOWNLOAD,
  });
};

export const getHistoricalMovementsToDownload = (query) => async (dispatch) => {
  dispatch({ type: GET_LIST_TO_DOWNLOAD_LOADING });
  query.q.employee_person_full_name_cont = query.q.person_full_name_cont;
  delete query.q.person_full_name_cont;
  delete query.q.employee_active_in;
  try {
    const movements = await configAxios.get(URL.historicalMovement, {
      params: {
        ...query,
        download: true,
        page: { size: INDEX.one },
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    const total = movements.headers["x-total"];
    const pagination = { size: total, number: INDEX.one };

    const response = await configAxios.get(URL.historicalMovement, {
      params: { ...query, download: true, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_TO_DOWNLOAD,
      payload: response.data.historical_movements,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_TO_DOWNLOAD_ERROR);
  }
};

export const deleteMovementById = (movementId) => async (dispatch) => {
  dispatch({ type: PROCCESS_HISTORICAL_MOVEMENT_LOADING });
  try {
    const response = await configAxios.delete(`${URL.movementsHistory}/${movementId}`);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_HISTORICAL_MOVEMENT_ERROR);
  }
};
