import styled from "styled-components";
import palette from "theme/palette";

export const StyledInfoContainer = styled.div`
  p{
    margin-top: 8px 
  }
  .imageTextContainer{
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    svg{
      width: 16px;
      height: 16px;
      path{
        fill: ${props => props.color && palette.text[props.color]};
      }
    }
    a{
      color: ${props => props.color && palette.text[props.color]};
      margin: 0;
      margin-left: 4px;
    }
  }
  
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyleRolesContainer = styled.p`
  overflow-wrap: break-word;
  span{
    color: ${palette.text.link};
    padding: 0 4px;
  }
`;

export const StyledLink = styled.p`
  color: ${palette.text.link};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
