import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Charts from "theme/charts";
import { hexToRgbA } from "common/utils";

export const StyledTitle = styled(Typography)`
  text-align: center;
  font-size: ${(props) => (props.size)}px;
  color: ${(props) => props.color};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const StyledCard = styled(Card)`
  padding: 20px;
  &.MuiPaper-root {
    background: ${(props) => (props.background ? hexToRgbA(props.background, Charts.opacity.fillOpacity) : "none")};
    box-shadow: none;
    margin: 10px 0;
  }
`;

export const StyledImage = styled.img`
  margin-top: 5px;
  width: ${(props) => (props.size)}px;
  height: ${(props) => (props.size)}px;
  @media (max-width: 600px) {
    width: 36px;
    height: 36px;
  }
`;

export const StyledResult = styled.div`
  font-size: ${(props) => (props.size)}px;
  color: ${(props) => props.color};
  font-weight: 700;
  margin: 5px 0;
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

export const StyledDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-left: 5px;
  }
`;
