import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import IconButton from "@mui/material/IconButton";
import MenuPopup from "components/MenuPopup";
import AlertModal from "components/AlertModal";
import {
  BUTTON_STYLE_TYPES,
  COLORS_NAME, SIZE,
  VARIANT,
  ACTIONS,
} from "common/constants";
import { isEqual } from "common/helpers";
import TemplateModal from "../TemplateModal";
import {
  StyledButtonAdd,
  StyledRadio,
  StyledTitle,
  StyledText,
  StyledDeleteIcon,
} from "./styles";

const RadioButtons = (props) => {
  const {
    control,
    controlName,
    radioName,
    options,
    selected,
    onClick,
    deleteOption,
    setDocs,
    setInfo,
    setSectionItems,
  } = props;
  const { t } = useTranslation(["templates", "common"]);
  const activeStyle = (item) => (isEqual(selected?.id, item.id) ? "active" : "");
  const [modal, setModal] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isOpenModalForm, setIsOpenModalForm] = useState(false);

  const ACTIONS_MENU = [
    {
      title: <StyledText>{ t("actions.delete") }</StyledText>,
      icon: <StyledDeleteIcon fontSize={ SIZE.small } className="delete"/>,
      onClick: () => openCloseModal(),
    },
  ];

  const openCloseModal = () => {
    setModal(!modal);
  };

  const showModal = (newTemplate = false) => {
    setIsOpenModalForm(true);
    if (newTemplate) {
      setIsNew(newTemplate);
      onClick(null);
    } else {
      setIsNew(false);
    }
  };

  const handleCloseModalForm = () => {
    setIsOpenModalForm(false);
  };

  return (
    <Controller
      data-testid={ "radio-button-view-component" }
      control={ control }
      name={ controlName }
      as={ (
        <RadioGroup row aria-label={ radioName } name={ radioName } >
          <Grid container spacing={ 4 }>
            <Grid item xs={ 6 } md={ 2 }>
              <StyledButtonAdd
                onClick={ () => showModal(true) }
                variant={ VARIANT.contained }
                color={ COLORS_NAME.primary }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              >
                <Box>
                  <AddCircleOutline />
                </Box>
                { t("actions.create") }
              </StyledButtonAdd>
            </Grid>
            {options?.map((item) => (
              <Grid item xs={ 6 } md={ 2 } key={ item.id }>
                <StyledRadio
                  className={ activeStyle(item) }
                  onClick={ () => onClick(item) }
                >
                  <Grid container>
                    <Grid item xs={ 8 }>
                      <FormControlLabel
                        value={ item.id }
                        control={ (
                          <Radio
                            checked={ isEqual(selected?.id, item.id) }
                          />
                        ) }
                      />
                    </Grid>
                    <Grid item xs={ 4 } className={ "actions" }>
                      <IconButton
                        onClick={ () => showModal() }
                        aria-label={ ACTIONS.edit }
                      >
                        <RemoveRedEyeOutlined />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <StyledTitle>{ item.title }</StyledTitle>
                  <Box>
                    <MenuPopup items={ ACTIONS_MENU } />
                  </Box>
                </StyledRadio>
              </Grid>
            ))}
          </Grid>
          <TemplateModal
            data={ isNew ? null : selected }
            isOpen={ isOpenModalForm }
            onClose={ handleCloseModalForm }
            setDocs={ setDocs }
            setInfo={ setInfo }
            setSectionItems={ setSectionItems }
          />
          <AlertModal
            title={ t("common:common.modal_messages.sure_question") }
            text={ t("alertMessage.delete_template") }
            textDisagree={ t("common:common.modal_messages.no_cancel") }
            textAgree={ t("common:common.modal_messages.yes_confirm") }
            onClick={ deleteOption }
            open={ modal }
            handleClose={ openCloseModal }
          />
        </RadioGroup>
      ) }
    />

  );
};

RadioButtons.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  radioName: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
  setDocs: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setSectionItems: PropTypes.func.isRequired,
};

export default RadioButtons;
