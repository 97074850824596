import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import palette from "theme/palette";

export const StyledImageLoaderContainer = styled.div`
  position: relative;
  margin: auto;
  width: 190px;
  height: 190px;
  cursor: ${ (props) => (props.canEdit ? "pointer" : "auto") };
  margin-bottom: 12px;
`;

export const StyledProfileImage = styled(Avatar)`
  margin: auto;
  border: 10px solid ${palette.text.purpleLight};
  &.MuiAvatar-root {
    width: 190px;
    height: 190px;
  }
`;

export const StyledEditLayer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.3);
  width: 170px;
  height: 170px;
  border-radius: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
`;

export const StyledEditIcon = styled.div`
  background: rgba(255, 255, 255, 0.5);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;
