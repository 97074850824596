import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import TooltipIcon from "components/TooltipIcon";
import { COMPONENT, RATING, VARIANT } from "common/constants";
import { getRating, getTooltipDescription } from "./functions";
import { StyledRating, StyledTypography, StyledEmptyStar } from "./styles";

const StarRating = (props) => {
  const {
    name,
    value,
    isReadOnly,
    size,
    label,
    maxRating,
    max,
    precision,
    hasNoFormattedValue,
    tooltipText,
    detail,
    hasTooltip,
  } = props;
  const { t } = useTranslation(["performance"]);
  const [rating, setRating] = useState(RATING.initial);

  const setFormatRating = useCallback(
    () => {
      const rate = getRating(value, maxRating);
      return rate;
    },
    [maxRating],
  );

  useEffect(() => {
    setRating(hasNoFormattedValue ? value : setFormatRating(value));
  }, [value, setFormatRating]);

  const getToolTip = () => {
    const data = getTooltipDescription(label, t);
    if (tooltipText || data) {
      return (
        <TooltipIcon isHelpIcon>
          {tooltipText || data.value}
        </TooltipIcon>
      );
    }
    return "";
  };

  const content = (
    <Box>
      {label && !hasTooltip && (
        <StyledTypography component={ COMPONENT.legend } variant={ VARIANT.caption }>
          {label}
          {getToolTip(label)}
        </StyledTypography>
      )}
      <StyledRating
        id={ name }
        data-testid={ "rating" }
        name={ name }
        value={ rating }
        precision={ precision }
        // TODO: add in case it can be used for editing
        // onChange={(event, newValue) => {
        //   setRating(newValue);
        // }}
        readOnly={ isReadOnly }
        size={ size }
        emptyIcon={ <StyledEmptyStar fontSize={ VARIANT.inherit } /> }
        max={ max }
      />
      {detail && (
        <StyledTypography component={ COMPONENT.legend } variant={ VARIANT.caption }>
          {detail}
        </StyledTypography>
      )}
    </Box>
  );

  return hasTooltip ? (
    <Tooltip title={ label || value }>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

StarRating.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.string,
  maxRating: PropTypes.number,
  max: PropTypes.number,
  precision: PropTypes.number,
  hasNoFormattedValue: PropTypes.bool,
  tooltipText: PropTypes.string,
  detail: PropTypes.string,
  hasTooltip: PropTypes.bool,
};

StarRating.defaultProps = {
  max: 5,
  maxRating: 5,
  precision: RATING.precision,
  hasNoFormattedValue: false,
  tooltipText: null,
  name: "star-rating",
  detail: null,
  hasTooltip: false,
};

export default StarRating;
