import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarRating from "components/StarRating";
import { RATING } from "common/constants";
import {
  useStyles,
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionDetailsStyled,
} from "./styles";

// TODO: remove
const AccordionRate = (props) => {
  const {
    title, text, percentage, src, label, children, isOpen,
  } = props;
  const classes = useStyles();

  const totalStars = !isOpen
  && (
    <StarRating
      name={ title }
      value={ percentage }
      isReadOnly
      label={ label }
      maxRating={ RATING.total_max }
    />
  );

  return (
    <div className={ classes.root } data-testid={ "accordion-rate" }>
      <AccordionStyled>
        <AccordionSummaryStyled
          expandIcon={ (
            <ExpandMoreIcon
              className={ children ? classes.icon : classes.disabledIcon }
            />
          ) }
          aria-controls={ "panel1c-content" }
          id={ "panel1c-header" }
        >
          <Grid
            container
            className={ classes.staticProgressBorder }
            alignItems={ "center" }
          >
            <Grid item xs={ 12 } md={ 8 }>
              <Box display={ "flex" } alignItems={ "center" }>
                {src && (
                  <Box pr={ 1 }>
                    <Avatar alt={ "Icon" } src={ src } />
                  </Box>
                )}
                <Box flexGrow={ 1 }>
                  <Typography variant={ "h5" }>{title}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={ 12 } md={ 4 }>
              <Box pt={ 0.5 } pb={ 0.5 }>
                { totalStars }
              </Box>
            </Grid>
            {text && (
              <Grid item md={ 12 }>
                <Typography variant={ "body1" } className={ classes.details }>
                  {text}
                </Typography>
              </Grid>
            )}
          </Grid>
        </AccordionSummaryStyled>
        {children && (
          <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
        )}
      </AccordionStyled>
    </div>
  );
};
AccordionRate.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  src: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};
export default AccordionRate;
