import { CARD_RESULT } from "theme/palette";
import { RobotoFont, INDEX } from "common/constants";
import { DOUGHNUT_ROUNDED } from "common/constants/charts";

export const roundCorners = (doughnutChart) => {
  if (doughnutChart) {
    doughnutChart.pluginService.register({
      afterUpdate(chart) {
        const { roundedCornersFor } = chart.config.options.elements.arc;
        if (roundedCornersFor !== undefined) {
          const arc = chart.getDatasetMeta(INDEX.zero).data[roundedCornersFor];
          const { chartArea } = chart;
          arc.round = {
            x: (chartArea.left + chartArea.right) / INDEX.two,
            y: (chartArea.top + chartArea.bottom) / INDEX.two,
            radius: (chart.outerRadius + chart.innerRadius) / INDEX.two,
            thickness: (chart.outerRadius - chart.innerRadius) / INDEX.two - INDEX.one,
            backgroundColor: arc._model.backgroundColor,
          };
        }
      },

      afterDraw(chart) {
        const { roundedCornersFor } = chart.config.options.elements.arc;
        if (roundedCornersFor !== undefined) {
          const { ctx } = chart.chart;
          const arc = chart.getDatasetMeta(INDEX.zero).data[roundedCornersFor];
          const startAngle = Math.PI / INDEX.two - arc._view.startAngle;
          const endAngle = Math.PI / INDEX.two - arc._view.endAngle;

          ctx.save();
          ctx.translate(arc.round.x, arc.round.y);
          ctx.fillStyle = arc.round.backgroundColor;
          ctx.beginPath();
          ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, INDEX.zero, INDEX.two * Math.PI);
          ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, INDEX.zero, INDEX.two * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        }
      },
    });
  }
};

export const textResult = (doughnutChart) => {
  if (doughnutChart) {
    doughnutChart.pluginService.register({
      afterUpdate(chart) {
        if (chart.config.options.elements.center) {
          const { helpers } = doughnutChart;
          const centerConfig = chart.config.options.elements.center;
          const globalConfig = doughnutChart.defaults.global;
          const { ctx } = chart.chart;

          const fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
          const fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);
          let fontSize = INDEX.zero;
          if (centerConfig.fontSize) {
            fontSize = centerConfig;
          } else {
            ctx.save();
            fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, INDEX.one);
            const maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, DOUGHNUT_ROUNDED.fontSize.max);
            const maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

            do {
              ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
              const textWidth = ctx.measureText(maxText).width;

              if (textWidth < chart.innerRadius * INDEX.two && fontSize < maxFontSize) fontSize += INDEX.one;
              else {
                fontSize -= INDEX.one;
                break;
              }
            } while (true);
            ctx.restore();
          }

          chart.center = {
            font: helpers.fontString(fontSize, fontStyle, fontFamily),
            fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor),
          };
        }
      },
      afterDraw(chart) {
        if (chart.center) {
          const centerConfig = chart.config.options.elements.center;
          const { ctx } = chart.chart;
          const { chartArea } = chart;

          ctx.save();
          ctx.font = chart.center.font;
          ctx.fillStyle = chart.center.fillStyle;
          ctx.textAlign = DOUGHNUT_ROUNDED.align.center;
          ctx.textBaseline = DOUGHNUT_ROUNDED.middle;
          const centerX = (chartArea.left + chartArea.right) / INDEX.two;
          const centerY = (chartArea.top + chartArea.bottom) / INDEX.two;
          ctx.fillText(centerConfig.text, centerX, centerY);
          ctx.restore();
        }
      },
    });
  }
};

export const optionsDoughnut = (result, symbol, isEmptyResult) => ({
  defaultFontFamily: RobotoFont,
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false,
  },
  cutoutPercentage: DOUGHNUT_ROUNDED.cutoutPercentage,
  tooltips: {
    enabled: false,
  },
  plugins: {
    padding: DOUGHNUT_ROUNDED.min,
    datalabels: {
      display: false,
    },
  },
  elements: {
    arc: {
      roundedCornersFor: DOUGHNUT_ROUNDED.min,
    },
    center: {
      maxText: DOUGHNUT_ROUNDED.text.max,
      text: isEmptyResult ? "" : `${result}${symbol}`,
      fontColor: CARD_RESULT.colorResult,
      fontFamily: RobotoFont,
      fontStyle: DOUGHNUT_ROUNDED.fontStyle.normal,
      minFontSize: DOUGHNUT_ROUNDED.fontSize.min,
      maxFontSize: DOUGHNUT_ROUNDED.fontSize.max,
    },
  },
});

export const getResult = (result, maxResult, title, color) => ({
  labels: [
    title,
    "",
  ],
  datasets: [
    {
      data: [result, maxResult - result],
      backgroundColor: [CARD_RESULT[color], CARD_RESULT.default],
      hoverBackgroundColor: [CARD_RESULT[color], CARD_RESULT.default],
      borderWidth: INDEX.zero,
    },
  ],
});
