import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { HC_PLANNING_CALCULATOR_IFRAME } from "common/constants";
import useStyles from "./styles";

const HCPlanning = () => {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <div className={ classes.root } data-testid={ "HCPlanning-view" }>
      <Typography className={ classes.titleText }>
        {t("common.hc-planning")}
      </Typography>
      <Grid container direction={ "row" } justify={ "center" } alignItems={ "center" }>
        <Iframe
          url={ HC_PLANNING_CALCULATOR_IFRAME }
          width={ "100%" }
          className={ classes.imgIframe }
          allowFullScreen
        />
      </Grid>
    </div>
  );
};

export default HCPlanning;
