import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { VARIANT } from "common/constants";
import { GOOD_LEADER_RESULTS_GROUPS } from "common/constants/goodLeader";
import { getPercent } from "common/utils";
import {
  StyledContainer,
  StyledTitle,
  StyledLinearProgress,
  StyledLink,
  StyledSpace,
  StyledPoint,
  StyledName,
} from "./styles";

const CardResult = (props) => {
  const {
    item,
    color,
    resultSection,
    handleModal = () => {},
    isProgressLine,
  } = props;
  const { t } = useTranslation("performance");
  const hasRecommendation = item.category !== GOOD_LEADER_RESULTS_GROUPS[0].id;

  const renderSpace = () => (isProgressLine ? <StyledSpace /> : null);

  return (
    <StyledContainer key={ item.name } data-testid={ "good-leader-card-result" }>
      <StyledName item xs={ 12 } point={ !isProgressLine ? 1 : 0 }>
        {!isProgressLine && (
          <StyledPoint customcolor={ color } />
        )}
        <StyledTitle variant={ VARIANT.bodyOne }>{ item.name }</StyledTitle>
      </StyledName>
      {isProgressLine && item?.score && (
        <Grid item xs={ 12 }>
          <StyledLinearProgress
            variant={ VARIANT.determinate }
            value={ getPercent(item.score || 0) }
            customcolor={ color }
          />
        </Grid>
      )}
      { hasRecommendation && resultSection !== null && resultSection?.length > 0
        ? (
          <StyledLink onClick={ () => handleModal({
            name: item?.name,
            key: resultSection[0].key,
            urls: resultSection[0].urls,
          }) }
          >
            { t("goodLeader:wantToImproveIt") }
          </StyledLink>
        ) : renderSpace() }
    </StyledContainer>
  );
};

CardResult.propTypes = {
  item: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  resultSection: PropTypes.array.isRequired,
  handleModal: PropTypes.func.isRequired,
  isProgressLine: PropTypes.bool.isRequired,
};

export default CardResult;
