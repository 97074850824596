import styled from "styled-components";
import palette from "theme/palette";
import Grid from "@mui/material/Grid";

export const StyledAttritionProbability = styled.div`
  padding: 10px;
  border: 1px solid ${palette.background.divider};
  margin-bottom: 20px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
`;

export const StyledGridAlert = styled(Grid)`
  .MuiAlert-root {
    width: 97%;
  }
`;
