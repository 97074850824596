import styled from "styled-components";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import palette from "theme/palette";

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0px 24px 25px;
`;

export const StyledItemDetailContainer = styled(Grid)`
  padding: 10px;
  display: grid;
  align-items: center;
  border-left: 1px solid ${palette.tooltip.border};
`;

export const StyledTypographyItem = styled(Typography)`
  margin-bottom: 10px;
`;

export const StyledSelectedContainer = styled(Grid)`
  border: 1px solid ${palette.tooltip.border};
  padding: 20px;
  margin-top: 10px;
`;

export const StyledUpload = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const StyledItemDetail = styled.div`
  border: 1px solid ${palette.tooltip.border};
  padding: 20px;
  & video {
    display: flex;
    margin: 15px auto;
    width: 250px;
  }
  .MuiButton-outlined.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .MuiButton-outlined.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
`;

export const StyledDialogItemActions = styled(DialogActions)`
  padding: 0px 14px 20px;
  flex: auto;
`;

export const StyledUploadContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
`;

export const StyledButtonFile = styled(Button)`
  margin-right: 10px;
`;

export const StyledUploadFileContainer = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
`;
