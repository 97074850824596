import styled from "styled-components";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledMatrixTitle = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  img {
    width: 60px;
    padding-right: 10px;
  }
  h4 {
    width: 100px;
  }
`;

export const StyledDescription = styled(Typography)`
  padding-top: 20px;
`;

export const StyledContainer = styled.div`
  padding: 20px;
  h4 {
    color: ${palette.text.link};
    font-weight: 700;
    font-size: 18px;
  }
`;

export const StyledDataList = styled.div`
  margin: 20px 0;
`;
