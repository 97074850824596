import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MultiSelectFilter from "components/MultiSelectFilter";
import {
  extractUniqueManagers,
  extractUniqueCountries,
  extractUniqueCities,
  extractOrganizationUnit,
} from "./functions";
import { StyledFilters } from "./styles";

const Filters = ({
  collaboratorList, isDisabled, onFiltersChange, t, handleResetFilter, resetFilters,
}) => {
  const [filterIds, setFilterIds] = useState({
    manager: [],
    country: [],
    city: [],
    unit: [],
    state: [],
  });

  // Memoize the callback to avoid unnecessary updates
  const stableOnFiltersChange = useCallback(onFiltersChange, []);

  const handleManagerChange = (selectedIds) => {
    setFilterIds((prev) => ({ ...prev, manager: selectedIds }));
  };

  const handleCountryChange = (selectedIds) => {
    setFilterIds((prev) => ({ ...prev, country: selectedIds }));
  };

  const handleCityChange = (selectedIds) => {
    setFilterIds((prev) => ({ ...prev, city: selectedIds }));
  };

  const handleUnitChange = (selectedIds) => {
    setFilterIds((prev) => ({ ...prev, unit: selectedIds }));
  };

  const handleStateChange = (selectedIds) => {
    setFilterIds((prev) => ({ ...prev, state: selectedIds }));
  };

  useEffect(() => {
    stableOnFiltersChange(filterIds);
  }, [filterIds, stableOnFiltersChange]);

  const stateOptions = [
    {
      name: t("tables:filters.collaborator_states.active"),
      id: 1,
    },
    {
      name: t("tables:filters.collaborator_states.inactive"),
      id: 0,
    },
  ];

  useEffect(() => {
    if (resetFilters) {
      handleResetFilter(false);
    }
  }, [resetFilters]);

  return (
    <StyledFilters container spacing={ 2 }>
      <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
        <MultiSelectFilter
          label={ t("tables:filters.manager") }
          options={ extractUniqueManagers(collaboratorList) }
          onSelectionChange={ handleManagerChange }
          isDisabled={ isDisabled }
          resetFilters={ resetFilters }
        />
      </Grid>
      <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
        <MultiSelectFilter
          label={ t("tables:filters.country") }
          options={ extractUniqueCountries(collaboratorList) }
          onSelectionChange={ handleCountryChange }
          isDisabled={ isDisabled }
        />
      </Grid>
      <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
        <MultiSelectFilter
          label={ t("tables:filters.city") }
          options={ extractUniqueCities(collaboratorList) }
          onSelectionChange={ handleCityChange }
          isDisabled={ isDisabled }
        />
      </Grid>
      <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
        <MultiSelectFilter
          label={ t("tables:filters.unit") }
          options={ extractOrganizationUnit(collaboratorList) }
          onSelectionChange={ handleUnitChange }
          isDisabled={ isDisabled }
        />
      </Grid>
      <Grid item xs={ 12 } sm={ 6 } md={ 2 }>
        <MultiSelectFilter
          label={ t("tables:filters.collaboratorsStates") }
          options={ stateOptions }
          onSelectionChange={ handleStateChange }
          isDisabled={ isDisabled }
        />
      </Grid>
    </StyledFilters>
  );
};

Filters.propTypes = {
  collaboratorList: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.bool.isRequired,
};

export default Filters;
