import { inRange } from "lodash";
import {
  DATE_FORMATS,
  DAYS_TO_FINISH_ONBOARDING,
  MONTHS,
  OBJECT_KEYS,
} from "common/constants";
import {
  formatDate,
  getPercent,
  getAmountFormat,
} from "common/utils";
import { isEqual } from "common/helpers";
import { NPS_RANGE } from "common/constants/nps";
import SatisfactionIcon from "assets/images/engagement/satisfied_legend.svg";
import NeutralIcon from "assets/images/engagement/neutral.svg";
import UnsatisfiedIcon from "assets/images/engagement/unsatisfied.svg";
import { CARD_RESULT, CARD_TEXT_COLOR, RISK_CATEGORY_COLORS } from "theme/palette";

export const COLOR_BY_LEVEL_RISKS = {
  [OBJECT_KEYS.high]: RISK_CATEGORY_COLORS.CRITIC,
  [OBJECT_KEYS.medium]: RISK_CATEGORY_COLORS.MEDIUM,
  [OBJECT_KEYS.low]: RISK_CATEGORY_COLORS.EXCELENT,
};

export const getFormatData = (t, data) => {
  const turnoverData = data.slice(0, -1);
  return turnoverData.map((item) => (
    {
      month: t(`common:months.${MONTHS[item.month - 1]}`),
      score: getPercent(item.turnovers_average, false, 2),
      // the param 0 indicates the number of decimals, by default it is 2.
      turnoverCost: `$${getAmountFormat(item.turnovers_cost, 0)}`,
    }
  ));
};

export const getDataBar = (data, color, darkColor) => ({
  // Display month and cost
  labels: data.map((item) => (`${item.month} (${item.score}%)\n${item.turnoverCost}`)),
  datasets: [
    {
      data: data.map((item) => item.score),
      backgroundColor: data.map((item, index) => (
        isEqual(index, data.length - 1) ? darkColor : color
      )),
      hoverBackgroundColor: color,
    },
  ],
});

export const optionsBar = {
  responsive: true,
  scales: {
    yAxes: [{
      display: false,
      ticks: {
        beginAtZero: true,
      },
    }],
    xAxes: [{
      display: false,
    }],
  },
  tooltips: {
    callbacks: {
      label: () => "",
      title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
    },
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    bodyFontSize: 9,
    mode: "index",
    intersect: false,
    position: "nearest",
    enabled: true,
    displayColors: false,
    caretSize: 0,
    titleFontSize: 11,
    bodySpacing: 0,
    titleSpacing: 0,
    xPadding: 6,
    yPadding: 6,
    cornerRadius: 2,
    titleMarginBottom: 2,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  legend: {
    display: false,
  },
};

const satisfactionIcons = [
  {
    minValue: NPS_RANGE.satisfied.min,
    maxValue: NPS_RANGE.satisfied.max,
    icon: SatisfactionIcon,
  },
  {
    minValue: NPS_RANGE.neutral.min,
    maxValue: NPS_RANGE.neutral.max,
    icon: NeutralIcon,
  },
  {
    minValue: NPS_RANGE.dissatisfied.min,
    maxValue: NPS_RANGE.dissatisfied.max,
    icon: UnsatisfiedIcon,
  },
];

export const getIconByNPSScore = (score) => satisfactionIcons.find(
  (e) => inRange(score, e.minValue, e.maxValue),
);

const getDifferenceScores = (lastScore, firstScore) => (lastScore - firstScore) / firstScore;

export const getPercentageVariation = (scoreList) => {
  let result = 0;
  if (scoreList.length > 1) {
    const firstScore = scoreList[0].score;
    const secondScore = scoreList[1].score;
    if (firstScore > 0 && secondScore > 0) {
      result = getDifferenceScores(firstScore, secondScore);
    } else if (firstScore > 0 && isEqual(secondScore, 0)) {
      result = 1;
    } else {
      result = 0;
    }
  }
  return { result: getPercent(Math.abs(result)), isPositive: result <= 0 };
};

export const getCardsDetail = (t, data, loadings) => [
  {
    key: OBJECT_KEYS.topTalent,
    title: t("nalaAnalytics:cards.topTalent"),
    cardColor: CARD_RESULT.purpleCard,
    textColor: CARD_TEXT_COLOR.purple,
    lastBarColor: CARD_TEXT_COLOR.purpleDark,
    data: data.topTalent,
    isLoading: loadings.topTalent,
    viewMore: true,
  },
  {
    key: OBJECT_KEYS.newHires,
    title: t("nalaAnalytics:cards.newHires"),
    cardColor: CARD_RESULT.greenCard,
    textColor: CARD_TEXT_COLOR.green,
    lastBarColor: CARD_TEXT_COLOR.greenDark,
    data: data.newHires,
    isLoading: loadings.newHires,
    viewMore: true,
  },
  {
    key: OBJECT_KEYS.company,
    title: t("nalaAnalytics:cards.company"),
    cardColor: CARD_RESULT.orangeCard,
    textColor: CARD_TEXT_COLOR.orange,
    lastBarColor: CARD_TEXT_COLOR.orangeDark,
    data: data.company,
    isLoading: loadings.company,
    viewMore: true,
  },
];

export const getMaxValue = (list) => {
  const detail = {};
  let value = {};
  list.forEach((item) => {
    const level = Object.keys(item)[0];
    detail[level] = item[level];
  });

  if (detail.high > 0) {
    value = { key: OBJECT_KEYS.high, value: detail.high };
  } else if (detail.medium > 0) {
    value = { key: OBJECT_KEYS.medium, value: detail.medium };
  } else if (detail.low > 0) {
    value = { key: OBJECT_KEYS.low, value: detail.low };
  } else {
    value = null;
  }
  return value;
};

export const getQueryByCard = (query, type) => {
  let newQuery = {};
  if (type === OBJECT_KEYS.topTalent) {
    newQuery = { nine_box_result_eq: "top_talent" };
  }
  if (type === OBJECT_KEYS.newHires) {
    const pastDate = new Date();
    pastDate.setHours(0);
    pastDate.setDate(pastDate.getDate() - DAYS_TO_FINISH_ONBOARDING);
    const formattedDate = formatDate(pastDate.toDateString(), DATE_FORMATS.es.dash);
    newQuery = { employee_starting_date_gteq: formattedDate };
  }
  return {
    ...query,
    q:
    {
      ...query.q,
      active_in: [true],
      employee_active_in: [true],
      ...newQuery,
    },
  };
};
