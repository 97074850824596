import styled from "styled-components";
import Button from "components/Button";
import theme from "theme";
import palette from "theme/palette";

export const StyledButton = styled(Button)`
  color: ${palette.text.link};
  border-color: ${palette.text.link};
  margin-bottom: 15px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 36px;
  background: ${palette.white};
  font-size: 12px;
  &.MuiButton-outlinedSizeSmall {
    padding: ${theme.spacing(0, 1)};
  }

  &:hover {
    background: ${palette.background.default};
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  .MuiGrid-root {
    width: auto;
  }
`;
