import IconButton from "@mui/material/IconButton";
import TooltipIcon from "components/TooltipIcon";
import NotConnectedSlackUsers from "../../components/NotConnectedSlackUsers";
import { StyledUsersViewFinder } from "../../styles";

const handleUploadClick = (
  functions, t, thisModule, notConnectedUsers, connectedUsers,
) => functions.viewModal(
  `${connectedUsers} ${t("common:common.modal_messages.associate_employees")}`,
  <NotConnectedSlackUsers />,
  `${notConnectedUsers} ${t("common:common.modal_messages.not_found_slack_users")}`,
  t("common:common.modal_messages.search_in_slack"),
  t("common:common.cancel"),
  () => {
    functions.handleUpdate(thisModule);
  },
);

export const getIntegrationActions = (t, thisModule, functions) => (rowData) => {
  const { url, notConnectedUsers, connectedUsers } = rowData;

  return (
    <>
      { url }
      <IconButton
        onClick={ () => handleUploadClick(functions, t, thisModule, notConnectedUsers, connectedUsers) }
      >
        <TooltipIcon
          title={ t("common:locate") }
          element={ <StyledUsersViewFinder /> }
        />
      </IconButton>
    </>
  );
};
