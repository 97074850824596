import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import palette from "theme/palette";

export const StyledTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

export const StyledTableCell = styled(TableCell)`
  text-align: center;
  background: ${palette.background.lightBlue};
`;

export const StyledTotalTableCell = styled(TableCell)`
  width: 120px;
  text-align: center;
  background: ${palette.primaryApp};
  color: ${palette.white};
  p {
    font-weight: 500;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  box-shadow: none;
  border: none;
  margin-top: 20px;
  table {
    table-layout: fixed;
    border-collapse: separate;
  }
  td {
    border-collapse: none;
    padding: 2px;
    border: 1px solid ${palette.table.blueLight};
  }
  tr:first-child td:first-child {
    border: none;
  }
  tr:first-child td:nth-child(2) {
    border-top: 1px solid ${palette.table.blueLight};
    border-left: 1px solid ${palette.table.blueLight};
    border-right: none;
    border-top-left-radius: 4px;
  }
  tr:nth-child(2) td:first-child {
    border: none;
    border-top-left-radius: 4px;
  }
  tr td:not(:last-child) {
    border-right: none;
  }
  tr:first-child td {
    border-bottom: none;
  }
`;

export const StyledHeader = styled.div`
  p {
    margin-top:10px;
  }
`;

export const StyledTotalTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${palette.black};
`;
