import PropTypes from "prop-types";
import { replacePath } from "common/utils";
import FeedbackFormModal from "./components/FeedbackFormModal";
import FeedbackModal from "./components/FeedbackModal";
import AcknowledgmentFormModal from "./components/AcknowledgmentFormModal";
import localRoutes from "../../../../functions/routes";

const Modals = (props) => {
  const { history } = props;
  return (
    <>
      <FeedbackModal { ...props } />
      <FeedbackFormModal
        { ...props }
        onClose={ () => replacePath(history, localRoutes.feedback) }
      />
      <AcknowledgmentFormModal
        { ...props }
        onClose={ () => replacePath(history, localRoutes.feedback) }
      />
    </>
  );
};

Modals.propTypes = {
  history: PropTypes.func.isRequired,
};

export default Modals;
