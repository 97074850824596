import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import styled from "styled-components";
import palette from "theme/palette";

export const MessageBox = styled(Box)`
  margin-top: 8px;
  border: 1px dashed ${palette.background.inputTags};
  padding: 16px;
  border-radius: 4px;
`;

export const AddSectionButton = styled(Button)`
  width: 100%;
  height: 100px;
  min-height: ${(props) => (props.empty ? "260px" : "100px")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  border: 1px dashed ${palette.background.inputTags};
  padding: 16px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 700;
  text-transform: none;
  color: ${palette.text.create};
  svg {
    margin-right: 8px;
    color: ${palette.background.inputTags};
    font-size: 24px;
  }
`;

export const NewSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 100%;
  height: 100px;
`;
