import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledGrid = styled(Grid)`
  &.MuiGrid-container { 
    align-items: center;
  }
`;

export const StyledTitle = styled.div`
  margin-left: -25px;
  @media (min-width: 1700px) {
    margin-left: -50px;
  }
  @media (max-width: 1700px) {
    margin-left: -30px;
  }
  @media (max-width: 1420px) {
    margin-left: -25px;
  }
  @media (max-width: 1050px) {
    margin-left: -20px;
  }
  @media (max-width: 960px) {
    margin-left: -70px;
  }
  @media (max-width: 780px) {
    margin-left: -50px;
  }
  @media (max-width: 640px) {
    margin-left: -25px;
  }
`;

export const StyledCenter = styled.div`
  text-align: center;
  margin-bottom: 6px;
`;
