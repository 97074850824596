import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS, INDEX, PAGINATION } from "common/constants";
import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,

  RESET_STATE_ONE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
} from "../../actionTypes/performance/surveyResults";

const URL = {
  main: "/collaborators",
  survey_results: "survey_results",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ONE,
  });
};

export const getList = (userId, query = null) => async (dispatch, getState) => {
  dispatch({
    type: GET_LIST_LOADING,
  });
  const lastQuery = query || {
    q: {
      score_not_null: INDEX.one,
    },
  };

  try {
    const mainUrl = `${URL.main}/${userId}/${URL.survey_results}`;
    const response = await configAxios.get(mainUrl, {
      params: { ...lastQuery },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST,
      payload: response.data.survey_results,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getResultList = (userId, query) => async () => {
  try {
    const mainUrl = `${URL.main}/${userId}/${URL.survey_results}`;
    const response = await configAxios.get(mainUrl, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.survey_results;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export const getResultsByCollaborator = (userId) => async () => {
  const query = {
    q: {
      score_not_null: INDEX.one,
    },
  };
  try {
    const response = await configAxios.get(`${URL.main}/${userId}/${URL.survey_results}`, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.survey_results.filter((item) => item.survey_process.follow_up_process_id !== null);
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export const getOne = (id) => async (dispatch) => {
  dispatch({
    type: GET_ONE_LOADING,
  });
  try {
    const response = await configAxios.get(`/${URL.survey_results}/${id}`);
    dispatch({
      type: GET_ONE,
      payload: response.data.survey_result,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_ERROR);
  }
};

export const getLastResultByProcessTypeAndEmployee = async (employeeId, processType) => {
  if (employeeId) {
    const pagination = { size: 1, number: 1 };
    const query = {
      q: {
        survey_process_follow_up_process_id: null,
        score_not_null: 1,
        survey_process_type_eq: processType,
        s: "end_date desc",
      },
    };
    const mainUrl = `${URL.main}/${employeeId}/${URL.survey_results}`;
    const response = await configAxios.get(mainUrl, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.survey_results;
  }
};
