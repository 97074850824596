import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Accordion from "components/Accordion";
import Button from "components/Button";
import NoDataMessage from "components/NoDataMessage";
import defaultImg from "assets/images/general/avatar.png";
import {
  BORDER_ACCORDION_TYPE,
  BUTTON_STYLE_TYPES,
  VARIANT,
} from "common/constants";
import { isEmpty, isNullOrUndefined } from "common/helpers";
import { getImageURL } from "helpers/presignedUrl";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { isDataNineBox } from "../../functions";
import {
  StyledDescription,
  StyledQuadrantDescription,
  StyledHistorical,
} from "./styles";

const Historical = (props) => {
  const {
    t,
    data,
    nineboxStates,
    resetQuadrants,
    handleSelectedPanel,
  } = props;

  const [historical, setHistorical] = useState([]);

  const getData = useCallback(async () => {
    const historicalResult = [];
    if (!isNullOrUndefined(data.results)) {
      await Promise.all(data.results.map(async (result) => {
        if (result.id) {
          let profileImage = result?.img || defaultImg;
          if (result?.imgKey) {
            try {
              profileImage = await getImageURL(result.imgKey);
            } catch (error) {
              profileImage = defaultImg;
            }
          }
          historicalResult.push({
            src: profileImage,
            title: result.name,
            customRender: result.historical || <div data={ result }>{""}</div>,
          });
        }
      }));
    }
    setHistorical(historicalResult);
  }, [data]);

  useEffect(() => {
    if (isDataNineBox(data)) {
      getData();
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <StyledDescription square>
        <Typography variant={ VARIANT.h4 }>
          {`${data?.results?.length || 0} ${t("ninebox:historical_total")}`}
        </Typography>
        <Typography variant={ VARIANT.h6 }>
          {`${t("ninebox:quadrant")}: ${data?.quadrant}`}
        </Typography>
        <StyledQuadrantDescription variant={ VARIANT.body1 }>
          {data?.quadrantDescription}
        </StyledQuadrantDescription>
        {data?.details
        && (
          <StyledQuadrantDescription variant={ VARIANT.body1 }>
            {data?.details}
          </StyledQuadrantDescription>
        )}
        <Button
          variant={ VARIANT.contained }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          isDisabled={ isEmpty(nineboxStates.quadrantResult) }
          onClick={ resetQuadrants }
        >
          {t("ninebox:view_all")}
        </Button>
      </StyledDescription>
      { historical.length > 0 ? (
        <StyledScrollBar maxHeight={ "400px" }>
          <StyledHistorical>
            <Accordion
              isPadding
              isDefaultIconStyle
              type={ BORDER_ACCORDION_TYPE.square }
              data={ historical }
              activePanel={ nineboxStates.circleSelected }
              accordionSelected={ nineboxStates.accordionSelected }
              setSelectedPanel={ handleSelectedPanel }
            />
          </StyledHistorical>
        </StyledScrollBar>
      ) : (
        <NoDataMessage
          message={ data?.emptyDataMessage || t("ninebox:historical_empty") }
        />
      )}
    </>
  );
};

Historical.propTypes = {
  t: PropTypes.func.isRequired,
  nineboxStates: PropTypes.object.isRequired,
  getQuadrantDetail: PropTypes.func.isRequired,
  resetQuadrants: PropTypes.func.isRequired,
  handleSelectedPanel: PropTypes.func.isRequired,
  data: PropTypes.object,
};

Historical.defaultProps = {
  data: {},
};

export default Historical;
