import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputTextController from "components/InputTextController";
import SelectController from "components/SelectController";
import DateInputController from "components/DateInputController";
import Select from "components/Select";
import { MARITAL_STATUS as CS, OBJECT_KEYS, TYPES } from "common/constants";
import { isEqual } from "common/helpers";
import { STATUS } from "common/constants/gender";
import { getItemSelected } from "views/Account/functions/personalData";
import { StyledPaperContainer, StyledGrid } from "./styles";

const PersonalInformation = (props) => {
  const {
    genderList, control, setValue, otherValue, selectedGender, setSelectedGender,
  } = props;
  const { t } = useTranslation("common");

  const MARITAL_STATUS = [
    { value: "single", label: t(CS.SINGLE) },
    { value: "married", label: t(CS.MARRIED) },
    { value: "freeUnion", label: t(CS.FREE_UNION) },
    { value: "separated", label: t(CS.SEPARATED) },
    { value: "divorced", label: t(CS.DIVORCED) },
    { value: "widower", label: t(CS.WIDOWER) },
  ];

  const handleChange = (prop, event) => {
    setSelectedGender(event.target.value);
    if (!isEqual(event.target.value, otherValue)) {
      setValue("collaborator.person_attributes.other_gender", "");
    }
  };

  const itemSelected = getItemSelected(genderList, selectedGender);

  return (
    <StyledPaperContainer
      data-testid={ "personal-information-collaborator" }
    >
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } md={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.name") }
            required
            control={ control }
            rules={ { required: true } }
            name={ "collaborator.person_attributes.full_name" }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.id") }
            required
            control={ control }
            rules={ { required: true } }
            name={ "collaborator.person_attributes.personal_id" }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.email }
            label={ t("common.personal_mail") }
            control={ control }
            name={ "collaborator.person_attributes.personal_email" }
          />
        </Grid>
        <StyledGrid item xs={ 12 } sm={ 6 }>
          <DateInputController
            id={ "birth_date" }
            label={ t("common.birthday") }
            control={ control }
            name={ "collaborator.person_attributes.birth_date" }
            openTo={ "year" }
            views={ ["year", "month", "day"] }
            maxDate={ new Date() }
          />
        </StyledGrid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.nationality") }
            control={ control }
            name={ "collaborator.person_attributes.nationality" }
          />
        </Grid>
        <StyledGrid item xs={ 12 } sm={ 6 }>
          <SelectController
            id={ OBJECT_KEYS.maritalStatus }
            label={ t("common.marital_status.main") }
            menuItems={ MARITAL_STATUS }
            control={ control }
            name={ "collaborator.person_attributes.marital_status" }
            defaultValue={ MARITAL_STATUS[0].value }
          />
        </StyledGrid>
        <StyledGrid item xs={ 12 } sm={ 6 }>
          <Select
            id={ OBJECT_KEYS.gender }
            label={ t("common.gender") }
            menuItems={ genderList }
            value={ selectedGender }
            onChange={ handleChange }
            size={ "normal" }
          />
        </StyledGrid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.gender_status.other") }
            control={ control }
            name={ "collaborator.person_attributes.other_gender" }
            disabled={ !isEqual(itemSelected?.id, STATUS.other) }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.address") }
            control={ control }
            name={ "collaborator.person_attributes.address" }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.phone_number") }
            control={ control }
            name={ "collaborator.person_attributes.phone_number" }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.linkedin_profile") }
            control={ control }
            name={ "collaborator.person_attributes.linkedin_profile" }
          />
        </Grid>
      </Grid>
    </StyledPaperContainer>
  );
};

PersonalInformation.propTypes = {
  genderList: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  selectedGender: PropTypes.string.isRequired,
  setSelectedGender: PropTypes.func.isRequired,
};

export default PersonalInformation;
