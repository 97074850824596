import styled from "styled-components";

export const StyledSelect = styled.div`
  .MuiSelect-select {
    width: 150px;
  }
  @media (max-width: 950px) {
    .MuiSelect-select {
      width: 100%;
    }
  };
`;
