import Alert from "@mui/material/Alert";
import styled from "styled-components";
import { bgColorAlert, colorAlert } from "theme/palette";

export const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  background: ${(props) => bgColorAlert[props.severity]};
  color: ${(props) => (props.action ? colorAlert.primaryInfo : colorAlert[props.severity])};
  svg {
    width: 47px;
    height: 47px;
    color: ${(props) => (props.action ? colorAlert.primaryInfo : colorAlert[props.severity])};
  }
  .MuiAlertTitle-root {
    color: ${(props) => (props.action ? colorAlert.primaryInfo : colorAlert[props.severity])};
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 4px;
  }
  @media (max-width: 600px) {
    &.MuiAlert-root {
      display: block;
    }
    .MuiAlert-icon {
      display: block;
      text-align: center;
    }
  }
`;
