import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { getList as getCandidates } from "redux/actions/candidateActions";
import CandidatesToolbar from "./components/CandidatesToolbar";
import CandidatesTable from "./components/CandidatesTable";
import { StyledCandidate, StyledContent, StyledPaper } from "./styles";

const Candidates = (props) => {
  const { t } = useTranslation("candidates");
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm"),
  );

  const {
    list: candidateList,
    isLoadingList: isCandidateLoadingList,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  useEffect(() => {
    if (props.location?.state) {
      // Delete state to remove from reload the snackbar ;)
      window.history.replaceState(null, "");
    }
    dispatch(getCandidates());
  }, [dispatch, props]);

  return (
    <StyledCandidate data-testid={ "candidates" }>
      <Typography className={ "title" }>{t("title")}</Typography>
      <CandidatesToolbar />
      <StyledContent>
        <StyledPaper isMobile={ isMobile }>
          <CandidatesTable
            candidates={ candidateList }
            isLoading={ isCandidateLoadingList }
          />
        </StyledPaper>
      </StyledContent>
    </StyledCandidate>
  );
};

export default Candidates;
