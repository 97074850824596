import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledGrid = styled(Grid)`
  background-color: ${palette.background.lightPurple};
  padding: 15px 16px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

export const StyledChart = styled(Grid)`
  margin: 30px 0;
  .MuiTypography-h4 {
    margin-bottom: 20px;
    font-size: 18px;
  }
`;
