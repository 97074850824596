import styled from "styled-components";
import palette from "theme/palette";

export const StyledActions = styled.div`
  text-align: ${(props) => (props.isCenter ? "center" : "right") };
  margin-top: 25px;
  .MuiButton-containedPrimary {
    background-color: ${palette.text.link};
  }
`;

export const StyledMessage = styled.div`
  padding: 120px 100px 0;
  @media (max-width: 600px) {
    padding: 30px 5px 0;
  }
`;
