import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import Grid from "@mui/material/Grid";

import {
  PaginationContainer,
  PaginationContent,
  useStyles,
  CustomSelect,
} from "./styles";
import {
  FIRST_PAGE,
  PREV_PAGE,
  NEXT_PAGE,
  LAST_PAGE,
} from "../../common/constants";

const Pagination = (props) => {
  let {
    id,
    rowText,
    maxPerPage = "",
    rowsRange,
    currentPage,
    totalRows,
    lastPage,
    onClick,
    onChange,
  } = props;
  const { t } = useTranslation("common");
  const classes = useStyles();
  const [perPage, setPerPage] = useState(maxPerPage);

  const firstDisableStyle = clsx({ [classes.disabled]: currentPage === 1 });
  const lastDisableStyle = clsx({
    [classes.disabled]: currentPage === lastPage,
  });

  const handleChange = (props) => (event) => {
    setPerPage(event.target.value);
    onChange(event);
  };

  const handleControls = (mode) => {
    switch (mode) {
      case FIRST_PAGE:
        onClick(1);
        break;
      case PREV_PAGE:
        onClick(currentPage - 1);
        break;
      case NEXT_PAGE:
        onClick(currentPage + 1);
        break;
      case LAST_PAGE:
        onClick(lastPage);
        break;
      default:
        break;
    }
  };

  return (
    <PaginationContainer data-testid="paginationContainer">
      <PaginationContent>
        <Grid container className={classes.contentContainer}>
          <div className={classes.contentDivider}>
            <Typography color="textSecondary" variant="body1">
              {rowText}
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                labelId={`select-label` + id}
                id={`select-` + id}
                value={perPage}
                onChange={handleChange(id)}
                input={<CustomSelect />}
              >
                {rowsRange &&
                  rowsRange.map((menuItem, index) => {
                    return (
                      <MenuItem key={index} value={menuItem}>
                        <Typography color="textSecondary" variant="body1">
                          {menuItem}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.contentDivider}>
            <IconButton
              disabled={currentPage === 1}
              className={classes.controls}
              onClick={() => handleControls(FIRST_PAGE)}
              aria-label="first page"
            >
              <FirstPageOutlinedIcon className={firstDisableStyle} />
            </IconButton>
            <IconButton
              disabled={currentPage === 1}
              className={classes.controls}
              onClick={() => handleControls(PREV_PAGE)}
              aria-label="prev page"
            >
              <NavigateBeforeOutlinedIcon className={firstDisableStyle} />
            </IconButton>
            <Typography color="textSecondary" variant="caption" display="block">
              <Chip
                className={classes.page}
                size="small"
                label={t("common.page") + " " + currentPage}
              />
              {currentPage === 1
                ? currentPage
                : maxPerPage * currentPage - maxPerPage + 1}{" "}
              -{" "}
              {maxPerPage * currentPage <= totalRows
                ? maxPerPage * currentPage
                : totalRows}
              /{totalRows}
            </Typography>
            <IconButton
              disabled={currentPage === lastPage}
              className={classes.controls}
              onClick={() => handleControls(NEXT_PAGE)}
              aria-label="next page"
            >
              <NavigateNextOutlinedIcon className={lastDisableStyle} />
            </IconButton>
            <IconButton
              disabled={currentPage === lastPage}
              className={classes.controls}
              onClick={() => handleControls(LAST_PAGE)}
              aria-label="last page"
            >
              <LastPageOutlinedIcon className={lastDisableStyle} />
            </IconButton>
          </div>
        </Grid>
      </PaginationContent>
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  id: PropTypes.string,
  rowText: PropTypes.string,
  maxPerPage: PropTypes.number,
  rowsRange: PropTypes.array,
  currentPage: PropTypes.number,
  totalRows: PropTypes.number,
  lastPage: PropTypes.number,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default Pagination;
