import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

}));

export const StepperStyled = withStyles({
  root: {
    padding: 0,
    "& .MuiStepConnector-vertical": {
      padding: "0 !important",
    },
  },
})(Stepper);

export const StepLabelStyled = withStyles((theme) => ({
  label: {
    fontSize: "14px",
  },
  iconContainer: {
    color: theme.palette.btnIcon.color,
  },
}))(StepLabel);

export const StepContentStyled = withStyles({
  root: {
    marginTop: 0,
  },
})(StepContent);
