import PropTypes from "prop-types";
import AvatarGroup from "@mui/lab/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import {
  MAX_PERCENTAGE,
  TARGET,
} from "common/constants";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import CheckIcon from "assets/images/planning/check.svg";
import {
  StyledEmployee,
  StyledAvatar,
  StyledClickedAvatar,
  StyledLinkName,
  StyledPlusIcon,
  StyledCancelIcon,
  StyledCheckIcon,
  StyledCheckBox,
} from "./styles";
import CustomLink from "../CustomLink";

const Employee = (props) => {
  const {
    t,
    data,
    employee,
    size,
    showAll,
    setShowAll,
    isPeerEvaluation,
    isTeamEvaluation,
    allEmployees,
    isPeerPreview,
    handleSelected,
    deleteEvaluationResult,
  } = props;
  const renderStateIcon = (actualEmployee) => {
    const message = actualEmployee.isPending
      ? t("common:common:delete")
      : t("common:common:completed");
    const icon = actualEmployee.isPending
      ? (
        <StyledCancelIcon
          showAll={ showAll }
          aria-label={ message }
          onClick={ () => deleteEvaluationResult(actualEmployee.evaluationResultId) }
        />
      )
      : <StyledCheckIcon src={ CheckIcon } showAll={ showAll } aria-label={ message } />;
    return (
      <Tooltip title={ message } placement={ "top-start" }>
        {icon}
      </Tooltip>
    );
  };

  // return initial block of pairs evaluation with show more link
  if (isPeerPreview) {
    const completedEvaluations = allEmployees?.filter(
      (singleEmployee) => !singleEmployee.isPending
    ).length;
    const pendingEvaluations = allEmployees ? allEmployees.length - completedEvaluations : 0;

    return (
      <StyledEmployee width={ `${ MAX_PERCENTAGE / size }%` } >
        <AvatarGroup>
          {allEmployees?.length === 0 ? (
            <StyledPlusIcon alt={ t("surveys:evaluation_types.pairs") } >{"+"}</StyledPlusIcon>
          ) : data.employees.slice(0, 1).map((peer) => (
            <Avatar
              key={ peer?.name }
              alt={ peer?.name }
              src={ peer?.profile_img_url }
            />
          ))}
          {data.employees.length > 1 && (
            <StyledClickedAvatar onClick={ () => setShowAll(EVALUATION_TYPE.PAIRS) } alt={ t("surveys:evaluation_types.pairs") }>
              {`+${data.employees.length - 1 }`}
            </StyledClickedAvatar>
          ) }
        </AvatarGroup>
        <p>
          {completedEvaluations !== 0 && `${completedEvaluations} ${t(`surveys:peer${completedEvaluations > 1 ? "s" : ""}Evaluated`)}`}
          <br />
          {`${pendingEvaluations !== 0 ? `${pendingEvaluations} ${t("common:common.notEvaluated")}` : ""}`}
        </p>
      </StyledEmployee>
    );
  }

  // return individual employee
  return (
    <StyledEmployee
      width={ `${ MAX_PERCENTAGE / size}%` }
      showAll={ showAll }
      isDisabled={ !employee?.isPending }
    >
      <StyledAvatar>
        {employee.id ? (
          <Avatar
            alt={ employee?.name }
            src={ employee?.profile_img_url }
          />
        ) : (
          <StyledPlusIcon alt={ t("surveys:evaluation_types.pairs") }>{"+"}</StyledPlusIcon>
        )}
        {isTeamEvaluation && showAll && employee?.isPending && (
          <StyledCheckBox
            id={ employee?.id }
            size={ "small" }
            showAll={ showAll }
            onChange={ () => handleSelected(employee?.evaluationResultId) }
            defaultChecked
          />
        )}
        {!employee?.isPeerEvaluation && isPeerEvaluation && renderStateIcon(employee)}
        {!isPeerEvaluation && !employee.isPending && renderStateIcon(employee)}
      </StyledAvatar>
      {employee?.isPeerEvaluation
        ? (
          <CustomLink { ...props } />
        ) : (
          <StyledLinkName
            href={ employee.isPending && employee.link }
            target={ TARGET.self }
            showAll={ showAll }
            isDisabled={ !employee?.isPending }
          >
            {employee?.name}
          </StyledLinkName>
        )}
    </StyledEmployee>
  );
};

Employee.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  showAll: PropTypes.bool.isRequired,
  setShowAll: PropTypes.func.isRequired,
  isPeerEvaluation: PropTypes.bool,
  isTeamEvaluation: PropTypes.bool,
  allEmployees: PropTypes.array,
  isPeerPreview: PropTypes.bool,
  handleSelected: PropTypes.func,
  deleteEvaluationResult: PropTypes.func,
};

Employee.defaultProps = {
  allEmployees: [],
  isPeerPreview: false,
  isTeamEvaluation: false,
  handleSelected: () => {},
  deleteEvaluationResult: () => {},
};

export default Employee;
