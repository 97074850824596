import {
	CALENDAR_MODULE,
	TOP_ACKNOWLEDGEMENTS_MODULE,
} from "../container.constants";
import PersonalCalendar from "./PersonalCalendar";

const Module = (props) => {
	const { module } = props;
	const ALL_MODULES = {
		[CALENDAR_MODULE]: <PersonalCalendar {...props}/>,
		[TOP_ACKNOWLEDGEMENTS_MODULE]: (
			<div>TOP_ACKNOWLEDGEMENTS_MODULE module here</div>
		),
	};
	return ALL_MODULES[module];
};

export default Module;
