import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import PasswordInput from "components/PasswordInputController";
import {
  AUTH_METHODS,
  BUTTON_STYLE_TYPES,
  BUTTON_TYPE,
  INPUT_RULES,
  VARIANT,
  signInTitle,
  ALIGN_ITEMS,
  INFO,
  US_CODE,
  TARGET,
  REL,
} from "common/constants";
import { isEmpty } from "common/helpers";
import { getUsername, isMulticompanyUser } from "common/utils";
import QueryString from "query-string";
import useCreatePasswordService from "hooks/auth/useCreatePasswordService";
import googleLogoImage from "assets/images/sign-in/google-logo.svg";
import microsoftLogoImage from "assets/images/sign-in/microsoft.svg";
// TODO: uncomment me when you are working in register
// import RegisterNowBox from "./components/RegisterNowBox";
import useSignInService from "hooks/auth/useSignInService";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import SimpleAlert from "components/SimpleAlert";
import useComplexState from "hooks/utils/useComplexState";
import Button from "components/Button";
import ProviderButton from "./components/ProviderButton";
import CreatePassword from "./components/CreatePassword";
import { LOCALIZATION, showToast, switchOptions } from "./functions";
import "react-phone-input-2/lib/material.css";
import {
  StyledContentSwitch,
  StyledOrBox,
  StyledEmailInput,
  StyledInfoQuestion,
  StyledLinkText,
  StyledTextBold,
  StyledTitle,
  StyledSubtitle,
  StyledGrid,
  StyledButtonContainer,
  StyledProvidersContainer,
  StyledButtonSignIn,
} from "./styles";

const SignIn = (props) => {
  const { t, i18n } = useTranslation(["common", "authentication", "formValidations"]);
  const { location } = props;
  const stateSearch = location?.search;
  const { language } = i18n;
  const username = getUsername();
  const hasUsername = !isEmpty(username);
  const [buttonTypeStyle, setButtonTypeStyle] = useState(BUTTON_STYLE_TYPES.DISABLED);
  const [resetTokenPasswordCreation, setResetTokenPasswordCreation] = useState(
    null,
  );
  const [emailCreationPassword, setEmailCreationPassword] = useState(null);
  const [title, setTitle] = useState(signInTitle);
  const [authMethod, setAuthMethod] = useState(signInTitle);
  const [optionSelected, setOptionSelected] = useState(0);
  const [signInStates, setSignInStates] = useComplexState({
    phoneNumber: username,
    isLoadingCountries: false,
    localization: {},
    showAlert: false,
    isManualSignIn: false,
  });
  const options = [
    switchOptions(t("authentication:phoneNumber"), 0),
    switchOptions(t("authentication:emailAddress"), 1),
  ];
  const isLoginByEmail = optionSelected === 1;

  const { isLoading, signInPost } = useSignInService({ location, authMethod });

  const {
    validateTokenPost,
    isLoadingTokenValidation,
    createPasswordPost,
    isLoadingPasswordCreation,
  } = useCreatePasswordService(setResetTokenPasswordCreation, setTitle);

  const {
    handleSubmit, control, formState, errors, watch,
  } = useForm({
    mode: "onChange",
  });

  // Manage url params (Password creation & token sign in)
  useEffect(() => {
    if (stateSearch) {
      const {
        email,
        auth_token: token,
        token: passwordToken,
        client_id: clientId,
        expiry,
        uid,
        error,
      } = QueryString.parse(stateSearch);

      if (isEmpty(error)) {
        showToast(error, authMethod, null, t);
      }

      // We need to check if email was set
      if (passwordToken && email && title === signInTitle) {
        validateTokenPost({
          passwordToken,
          email,
        });
        setEmailCreationPassword(email);
        setResetTokenPasswordCreation(passwordToken);
        setTitle("create_password");
      }

      // We need to check if token was set
      if (token && authMethod !== AUTH_METHODS.token) {
        setAuthMethod(AUTH_METHODS.token);
        signInPost({
          token,
          clientId,
          expiry,
          uid,
          auth_method: AUTH_METHODS.token,
        });
      }
    }
  }, [stateSearch, validateTokenPost, authMethod, signInPost, title, t]);

  useEffect(() => {
    setSignInStates({ isLoadingCountries: true });
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (signInStates.isLoadingCountries) {
      setSignInStates({
        isLoadingCountries: false,
        localization: LOCALIZATION[language],
      });
    }
    // eslint-disable-next-line
  }, [signInStates.isLoadingCountries, LOCALIZATION]);

  // Handle manual sign in
  const handleManualSignIn = () => {
    setSignInStates({ isManualSignIn: true });
  };

  // Password creation & SignIn
  const onSubmit = (formData) => {
    if (resetTokenPasswordCreation) {
      formData.email = emailCreationPassword;
      formData.reset_password_token = resetTokenPasswordCreation;
      createPasswordPost(formData);
      return;
    }
    if (hasUsername && optionSelected === 0) {
      formData.login = signInStates.phoneNumber;
    }
    // We need to add auth_method to the object of auth
    formData.auth_method = AUTH_METHODS.password;
    signInPost(formData);
  };

  useEffect(() => {
    setButtonTypeStyle(
      !formState.isValid || (isEmpty(signInStates.phoneNumber) && !isLoginByEmail && hasUsername)
        ? BUTTON_STYLE_TYPES.DISABLED
        : BUTTON_STYLE_TYPES.SUBMIT,
    );
  }, [formState.isValid, isLoginByEmail, signInStates.phoneNumber, hasUsername]);

  // buttons for sign in with providers: google and microsoft
  const signInWithProviders = (
    <StyledProvidersContainer>
      <Box>
        <Typography>
          {!signInStates.isManualSignIn
            ? t("authentication:signInWith")
            : t("authentication:orSignInWith")}
        </Typography>
      </Box>
      <StyledGrid
        container
        justifyContent={ ALIGN_ITEMS.center }
      >
        <div>
          <ProviderButton
            src={ googleLogoImage }
            isLoading={ isLoading }
            auth={ "google_oauth2" }
            isMobile={ hasUsername }
          >
            {"Google"}
          </ProviderButton>
        </div>
        <div>
          <ProviderButton
            src={ microsoftLogoImage }
            isLoading={ isLoading }
            auth={ "microsoft_graph" }
            isMobile={ hasUsername }
          >
            {"Microsoft"}
          </ProviderButton>
        </div>
      </StyledGrid>
    </StyledProvidersContainer>
  );

  const inputUsernameRender = !hasUsername || isLoginByEmail ? (
    <StyledEmailInput
      control={ control }
      margin={ VARIANT.normal }
      label={ t("authentication:username") }
      name={ isMulticompanyUser() ? "email" : "login" }
      rules={ INPUT_RULES.isValidUserName }
    />
  ) : !signInStates.isLoadingCountries && (
    <PhoneInput
      localization={ signInStates.localization }
      country={ US_CODE }
      value={ signInStates.phoneNumber }
      onChange={ (value) => setSignInStates({ phoneNumber: value }) }
      searchPlaceholder={ t("common:common.search") }
      inputProps={ {
        name: "login",
        required: true,
      } }
      autocompleteSearch
      enableSearch
      enableLongNumbers
    />
  );

  const recoverPasswordLink = (
    <Link to={ "/recover-password" }>
      <StyledLinkText variant={ VARIANT.h6 } >
        {t("authentication:requestNewPassword")}
      </StyledLinkText>
    </Link>
  );

  const phoneMessageAlert = (
    <StyledInfoQuestion>
      <StyledLinkText
        variant={ VARIANT.h6 }
        onClick={ () => setSignInStates({ showAlert: !signInStates.showAlert }) }
      >
        {t("authentication:alert.title")}
      </StyledLinkText>
      {signInStates.showAlert && (
        <SimpleAlert
          message={ (
            <>
              <StyledTextBold>{t("authentication:alert.password")}</StyledTextBold>
              {` ${t("authentication:alert.message")}`}
            </>
          ) }
          type={ INFO }
          icon={ <InfoIcon /> }
          onClose={ () => setSignInStates({ showAlert: !signInStates.showAlert }) }
        />
      )}
    </StyledInfoQuestion>
  );

  return (
    <div data-testid={ "signIn" }>
      <Box>
        <StyledTitle variant={ VARIANT.h2 }>
          { t(`authentication:${title}`) }
        </StyledTitle>
        <StyledSubtitle variant={ VARIANT.h6 }>
          { t("authentication:welcome") }
        </StyledSubtitle>
      </Box>
      {hasUsername && (
        <SwitchSelectorContent
          options={ options }
          hasTitle={ false }
          onChangeOption={ setOptionSelected }
          isFull
        />
      )}
      {!hasUsername && !signInStates.isManualSignIn && signInWithProviders}
      {!hasUsername && !signInStates.isManualSignIn && (
        <StyledOrBox>
          <Typography variant={ VARIANT.bodyOne }>
            {t("authentication:orManualInput")}
          </Typography>
        </StyledOrBox>
      )}
      {!hasUsername && !signInStates.isManualSignIn && (
        <StyledButtonContainer>
          <Button
            variant={ VARIANT.outlined }
            isFullWidth
            onClick={ handleManualSignIn }
          >
            {t("authentication:emailAddress")}
          </Button>
        </StyledButtonContainer>
      )}
      {(signInStates.isManualSignIn || hasUsername) && (
        <>
          <form onSubmit={ handleSubmit(onSubmit) }>
            {resetTokenPasswordCreation ? (
              <CreatePassword
                control={ control }
                watch={ watch }
                errors={ errors }
                isLoadingTokenValidation={ isLoadingTokenValidation }
              />
            ) : (
              <StyledContentSwitch>
                { inputUsernameRender }
                <PasswordInput
                  control={ control }
                  name={ "password" }
                  rules={ INPUT_RULES.required }
                />
                {isLoginByEmail || !hasUsername ? recoverPasswordLink : phoneMessageAlert }
              </StyledContentSwitch>
            )}
            <StyledButtonSignIn
              typeStyle={ buttonTypeStyle }
              isFullWidth
              type={ BUTTON_TYPE.submit }
              isLoading={ isLoading || isLoadingPasswordCreation }
              isDisabled={ !formState.isValid
            || (isEmpty(signInStates.phoneNumber) && !isLoginByEmail && hasUsername) }
            >
              {t(`authentication:${title}`)}
            </StyledButtonSignIn>
          </form>
          { signInWithProviders }
        </>
      )}
      {/* FIXME: uncomment me when you are working in register */}
      {/* <RegisterNowBox/> */}
      <StyledLinkText
        underline={ 1 }
        align={ "center" }
        marginTop={ "50px" }
      >
        <a
          href={ t("common:contactFormSupport") }
          target={ TARGET.blank }
          rel={ REL }
        >
          {t("authentication:needHelp")}
        </a>
      </StyledLinkText>
    </div>
  );
};

export default SignIn;
