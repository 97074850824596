import styled from "styled-components";
import InputForm from "components/InputForm";

export const StyledInputForm = styled(InputForm)`
  margin-left: 25px;
  .MuiFormControl-root {
    margin: 0;
  }
  input {
    padding: 9px 8px;
  }
  @media (max-width: 600px) {
    margin-left: 0;
    .MuiFormControl-root {
      margin: 4px 0;
    }
  };
`;
