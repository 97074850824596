import styled from "styled-components";

export const StyledMember = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-body1, .MuiBox-root, h6 {
    font-size: 16px;
    margin-left: 12px;
  }
  .MuiAvatar-root, .MuiSvgIcon-root.MuiAvatar-fallback {
    width: 40px;
    height: 40px;
  }
  margin: 20px 0;
`;
