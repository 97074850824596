import clsx from "clsx";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { VARIANT } from "common/constants";
import { useStyles, StyledCardTitle } from "./styles";

const CardModule = (props) => {
  const classes = useStyles();
  const { icon, text, path, isDisabled } = props;
  return [
    <Link
      data-testid={ "card-module" }
      onClick={ (e) => isDisabled && e.preventDefault() } //FIXME: this line is temporal, until all path will available
      href={ path }
      key={ `module-${text}` }
      className={ isDisabled ? classes.linkDisabled : "" }
      underline={ VARIANT.none }
    >
      <Card>
        <CardContent className={ classes.cardContent }>
          <Grid container className={ classes.cardContainer }>
            <Grid item xs={ 3 }>
              <div
                className={ clsx(
                  classes.circleIcon,
                  isDisabled ? classes.disabledCircle : ""
                ) }
              >
                {icon}
              </div>
            </Grid>
            <Grid item xs={ 8 }>
              <StyledCardTitle
                className={ isDisabled ? classes.disabledText : "" }
              >
                {text}
              </StyledCardTitle>
            </Grid>
            <Grid item xs={ 1 }>
              <ArrowForwardIosIcon
                className={ isDisabled ? classes.disabledText : classes.icon }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>,
  ];
};

CardModule.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default CardModule;
