import PropTypes from "prop-types";
import UploadAvatar from "components_refactor/UploadAvatar";
import { OBJECT_KEYS } from "common/constants";

const ProfileImage = (props) => {
  const {
    collaborator, profileStates, handleProfileImage, canEditPersonalData,
  } = props;
  return (
    <UploadAvatar
      id={ OBJECT_KEYS.profileImgUrl }
      value={ profileStates?.profileImgUrl }
      isReadOnly={ !canEditPersonalData }
      onChange={ (key, image) => handleProfileImage(key, image) }
      altValue={ collaborator?.full_name }
    />
  );
};

ProfileImage.propTypes = {
  collaborator: PropTypes.object.isRequired,
  profileStates: PropTypes.object.isRequired,
  handleProfileImage: PropTypes.func.isRequired,
  canEditPersonalData: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

ProfileImage.defaultProps = {
  canEditPersonalData: false,
  isReadOnly: false,
};

export default ProfileImage;
