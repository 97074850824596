import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  chartContainer: {
    position: "relative",
  },
  titleStyle: {
    fontSize: "18px",
    fontWeight: "500",
  },
}));

export default useStyles;
