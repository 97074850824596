import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import palette from "theme/palette";

export const StyledEmployee = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  align-self: center;
  text-align: -webkit-center;
  .MuiAvatar-root {
    margin-bottom: 8px;
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1) };
  }
  .MuiAvatarGroup-root {
    justify-content: center;
  }
  @media (max-width: 600px) {
    width: 100%;
    ${(props) => props.showAll
      && "display: flex; margin: 10px; align-items: center; .MuiAvatar-root { margin-bottom: 0px; margin-right: 10px;}"}
  }
  p {
    text-transform: lowercase;
  }
`;

export const StyledAvatar = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledClickedAvatar = styled(Avatar)`
  :hover {
    background-color: ${ palette.text.link };
    cursor: pointer;
  }
`;

export const StyledLinkName = styled.a`
  color: ${(props) => ((props.isDisabled ? palette.background.gray
  : (props.showAll ? palette.text.link : palette.input.borderControl)))};
  :hover {
    color: ${(props) => (props.isDisabled ? palette.background.gray : palette.text.link)};
    cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
    text-decoration: ${(props) => (props.isDisabled ? "none" : "underline")};
  }
`;

export const StyledPlusIcon = styled(Avatar)`
  &.MuiAvatar-root{
    font-size: 30px;
  }
`;

export const StyledCancelIcon = styled(CancelIcon)`
  &.MuiSvgIcon-root {
    position: absolute;
    margin-top: -25px;
    margin-right: -80px;
    background: ${ palette.white };
    color: ${ palette.error.main };
    cursor: pointer;
    border-radius: 20px;

    &:hover {
      color: ${ palette.error.dark };
    }

    @media (max-width: 600px) {
    &.MuiSvgIcon-root {
      margin-top: ${(props) => (props.showAll ? "20" : "-25")}px;
      margin-right: ${(props) => (props.showAll ? "-20" : "-80")}px;
    }
  }
  }
`;

export const StyledCheckIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  margin-top: -25px;
  margin-right: -80px;
  background: ${ palette.white };
  cursor: pointer;
  border-radius: 20px;

  @media (max-width: 600px) {
    margin-top: ${(props) => (props.showAll ? "20" : "-25")}px;
    margin-right: ${(props) => (props.showAll ? "-20" : "-80")}px;
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    position: absolute;
    margin-top: -25px;
    margin-right: -80px;
    cursor: pointer;

    .MuiIconButton-label {
      background: ${ palette.white };
      border-radius: 5px;
    }

    &.Mui-checked {
      color: ${ palette.primaryApp };
    }

    @media (max-width: 600px) {
      margin-top: ${(props) => (props.showAll ? "20" : "-25")}px;
      margin-right: ${(props) => (props.showAll ? "-20" : "-80")}px;
    }
  }
`;
