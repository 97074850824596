import PropTypes from "prop-types";

const Footer = function (_) {
  return null;
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
