import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import palette, { STATE_COLORS, iconColor } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: "25px",
  },
  container: {
    marginTop: theme.spacing(4),
  },
  upload: {
    "& .MuiAvatar-root": {
      width: "auto",
      height: "auto",
      borderRadius: 0,
    },
    "& .MuiTableCell-head": {
      minWidth: "180px",
      fontSize: "13px",
      background: theme.palette.background.light,
    },
    "& .MuiTableCell-root": {
      fontSize: "13px",
    },
    "& .MuiTableContainer-root": {
      "& td:first-child": {
        paddingLeft: theme.spacing(1),
      },
      "& th:first-child": {
        minWidth: "100px",
      },
      "& td": {
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
      },
      overflowX: "scroll",
      padding: theme.spacing(0),
      overflow: "auto",
      "&::-webkit-scrollbar": {
        height: "7px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.scroll.light,
        borderRadius: "7px",
      },
      "&::-webkit-scrollbar-thumb:active": {
        backgroundColor: theme.palette.scroll.thumbActive,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.palette.scroll.thumbHover,
        boxShadow: theme.palette.scroll.boxShadow,
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "7px",
      },
      scrollbarHeight: "thin",
      scrollbarColor: theme.palette.scroll.firefoxColor,
    },
  },
  cardTitle: {
    fontWeight: "700 !important",
    marginBottom: "15px",
  },
  customAlert: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  downloadButton: {
    fontSize: "13px !important",
    color: theme.palette.text.link,
    textTransform: "none",
    padding: "5px 0 !important",
    "&:hover": {
      background: "none",
      color: theme.palette.text.secondary,
    },
    "& svg": {
      fontSize: "inherit",
    },
  },
  submitButtons: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  button: {
    width: "50%",
  },
  docName: {
    fontWeight: 500,
  },
  iconButton: {
    "& .MuiIconButton-root": {
      color: `${theme.palette.btnIcon.light} !important`,
      padding: theme.spacing(0),
    },
    "& .MuiIconButton-root:hover": {
      color: `${STATE_COLORS.ERROR} !important`,
    },
  },
  disabledIcon: {
    color: theme.palette.btnIcon.light,
  },
  enabledIcon: {
    color: STATE_COLORS.ERROR,
  },
  largeIcon: {
    fontSize: 48,
  },
  checkIcon: {
    color: iconColor.successHexa,
  },
  failedIcon: {
    color: STATE_COLORS.ERROR,
  },
  circular: {
    color: theme.palette.text.link,
  },
  nalaLogoMin: {
    position: "absolute",
    top: "calc(50% - 40px)",
    left: "calc(50% - 15px)",
  },
  textTitle: {
    margin: 5,
  },
  textSubtitle: {
    margin: 5,
  },
  errorIcon: {
    color: STATE_COLORS.ERROR,
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: theme.palette.boxShadow.error,
    animation: "pulse 2s infinite",
  },
  iconHidden: {
    width: "14px",
    opacity: 0,
  },
  progress: {
    width: "60%",
  },
  alert: {
    width: "95%",
  },
  errorList: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    fontSize: "12px",
  },
  modal: {
    minWidth: "300px",
    minHeight: "100px",
  },
  "@media (max-width: 540px)": {
    button: {
      width: "100%",
    },
    file: {
      display: "block",
    },
    fileName: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
    },
    alert: {
      width: "100%",
    },
    nalaLogoMin: {
      top: "calc(50% - 50px)",
    },
    textTitle: {
      fontSize: 20,
    },
    textSubtitle: {
      fontSize: 14,
    },
  },
}));

export const SaveAltIconStyled = withStyles((theme) => ({
  root: {
    fontSize: "0.8rem",
    marginLeft: theme.spacing(0.5),
  },
}))(SaveAltIcon);

export const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${palette.black};
  padding-bottom: 15px;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 24px;
`;

export const StyledAlertContainer = styled(Grid)`
  .MuiAlert-root {
  padding: 16px;
  div {
    justify-content: flex-start;
  }
  }
`;
