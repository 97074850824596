export const RESET_STATE = "RESET_STATE_SURVEY_PROCESSES";
export const GET_LIST = "GET_LIST_SURVEY_PROCESSES";
export const GET_FOLLOW_LIST = "GET_FOLLOW_LIST";
export const GET_LIST_LOADING = "GET_LIST_LOADING_SURVEY_PROCESSES";
export const GET_LIST_ERROR = "GET_LIST_ERROR_SURVEY_PROCESSES";

export const GET_TEMPLATES_LIST = "GET_TEMPLATES_LIST";
export const GET_TEMPLATES_LIST_LOADING = "GET_TEMPLATES_LIST_LOADING";
export const GET_TEMPLATES_LIST_ERROR = "GET_TEMPLATES_LIST_ERROR";

export const RESET_STATE_ONE = "RESET_STATE_ONE_SURVEY_PROCESSES";
export const GET_ONE = "GET_ONE_SURVEY_PROCESSES";
export const GET_ONE_LOADING = "GET_ONE_LOADING_SURVEY_PROCESSES";
export const GET_ONE_ERROR = "GET_ONE_ERROR_SURVEY_PROCESSES";

export const RESET_STATE_PROCESS_SURVEY_PROCESSES = "RESET_STATE_PROCESS_SURVEY_PROCESSES";
export const PROCESS_SURVEY_PROCESSES = "PROCESS_COLLABORATOR_SURVEY_PROCESSES";
export const PROCESS_SURVEY_PROCESSES_LOADING = "PROCESS_COLLABORATOR_SURVEY_PROCESSES_LOADING";
export const PROCESS_SURVEY_PROCESSES_ERROR = "PROCESS_COLLABORATOR_SURVEY_PROCESSES_ERROR";

export const RESET_STATE_PERFORMANCE_EVOLUTION = "RESET_STATE_PERFORMANCE_EVOLUTION";
export const GET_LIST_PERFORMANCE_EVOLUTION_LOADING = "GET_LIST_PERFORMANCE_EVOLUTION_LOADING";
export const GET_LIST_PERFORMANCE_EVOLUTION = "GET_LIST_PERFORMANCE_EVOLUTION";
export const GET_LIST_PERFORMANCE_EVOLUTION_ERROR = "GET_LIST_PERFORMANCE_EVOLUTION_ERROR";

export const RESET_STATE_GOOD_LEADER_EVOLUTION = "RESET_STATE_GOOD_LEADER_EVOLUTION";
export const GET_LIST_GOOD_LEADER_EVOLUTION_LOADING = "GET_LIST_GOOD_LEADER_EVOLUTION_LOADING";
export const GET_LIST_GOOD_LEADER_EVOLUTION = "GET_LIST_GOOD_LEADER_EVOLUTION";
export const GET_LIST_GOOD_LEADER_EVOLUTION_ERROR = "GET_LIST_GOOD_LEADER_EVOLUTION_ERROR";

// Survey result is part of survey process so:
export const RESET_STATE_SURVEY_RESULTS = "RESET_STATE_SURVEY_RESULTS";
export const GET_LIST_SURVEY_RESULTS = "GET_LIST_SURVEY_RESULTS";
export const GET_LIST_LOADING_SURVEY_RESULTS = "GET_LIST_LOADING_SURVEY_RESULTS";
export const GET_LIST_ERROR_SURVEY_RESULTS = "GET_LIST_ERROR_SURVEY_RESULTS";

export const RESET_STATE_SURVEY_RESULTS_TO_DOWNLOAD = "RESET_STATE_SURVEY_RESULTS_TO_DOWNLOAD";
export const GET_LIST_SURVEY_RESULTS_TO_DOWNLOAD = "GET_LIST_SURVEY_RESULTS_TO_DOWNLOAD";
export const GET_LIST_LOADING_SURVEY_RESULTS_TO_DOWNLOAD = "GET_LIST_LOADING_SURVEY_RESULTS_TO_DOWNLOAD";
export const GET_LIST_ERROR_SURVEY_RESULTS_TO_DOWNLOAD = "GET_LIST_ERROR_SURVEY_RESULTS_TO_DOWNLOAD";

export const RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION = "RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION";
export const GET_COLLABORATOR_PERFORMANCE_EVOLUTION = "GET_COLLABORATOR_PERFORMANCE_EVOLUTION";
export const GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING = "GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING";
export const GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR = "GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR";

export const RESET_STATE_EVALUATION = "RESET_STATE_EVALUATION";
export const GET_LIST_LOADING_EVALUATION = "GET_LIST_LOADING_EVALUATION";
export const GET_LIST_EVALUATION = "GET_LIST_EVALUATION";
export const GET_LIST_ERROR_EVALUATION = "GET_LIST_ERROR_EVALUATION";

export const RESET_STATE_ONE_EVALUATION = "RESET_STATE_ONE_EVALUATION";
export const GET_ONE_LOADING_EVALUATION = "GET_ONE_LOADING_EVALUATION";
export const GET_ONE_EVALUATION = "GET_ONE_EVALUATION";
export const GET_ONE_ERROR_EVALUATION = "GET_ONE_ERROR_EVALUATION";

export const RESET_STATE_ONE_PROCESS = "RESET_STATE_ONE_PROCESS";
export const GET_ONE_PROCESS = "GET_ONE_PROCESS";
export const GET_ONE_PROCESS_LOADING = "GET_ONE_PROCESS_LOADING";
export const GET_ONE_PROCESS_ERROR = "GET_ONE_PROCESS_ERROR";

export const RESET_STATE_BY_EMPLOYEE = "RESET_STATE_SURVEY_PROCESSES";
export const GET_LIST_BY_EMPLOYEE = "GET_LIST_BY_EMPLOYEE_SURVEY_PROCESSES";
export const GET_LIST_BY_EMPLOYEE_LOADING = "GET_LIST_BY_EMPLOYEE_LOADING_SURVEY_PROCESSES";
export const GET_LIST_BY_EMPLOYEE_ERROR = "GET_LIST_BY_EMPLOYEE_ERROR_SURVEY_PROCESSES";

export const RESET_GET_EMPLOYEES_BY_AUDIENCE = "RESET_GET_EMPLOYEES_BY_AUDIENCE";
export const GET_EMPLOYEES_BY_AUDIENCE = "GET_EMPLOYEES_BY_AUDIENCE";
export const GET_EMPLOYEES_BY_AUDIENCE_LOADING = "GET_EMPLOYEES_BY_AUDIENCE_LOADING";
export const GET_EMPLOYEES_BY_AUDIENCE_ERROR = "GET_EMPLOYEES_BY_AUDIENCE_ERROR";

export const RESET_LEADER_EVALUATORS = "RESET_LEADER_EVALUATORS";
export const GET_LEADER_EVALUATORS = "GET_LEADER_EVALUATORS";
export const GET_LEADER_EVALUATORS_LOADING = "GET_LEADER_EVALUATORS_LOADING";
export const GET_LEADER_EVALUATORS_ERROR = "GET_LEADER_EVALUATORS_ERROR";

export const RESET_COLLABORATOR_EVALUATORS = "RESET_COLLABORATOR_EVALUATORS";
export const GET_COLLABORATOR_EVALUATORS = "GET_COLLABORATOR_EVALUATORS";
export const GET_COLLABORATOR_EVALUATORS_LOADING = "GET_COLLABORATOR_EVALUATORS_LOADING";
export const GET_COLLABORATOR_EVALUATORS_ERROR = "GET_COLLABORATOR_EVALUATORS_ERROR";

export const RESET_STATE_PROCESS_SCHEMA = "RESET_STATE_PROCESS_SCHEMA";
export const PROCESS_SCHEMA = "PROCESS_SCHEMA";
export const PROCESS_SCHEMA_LOADING = "PROCESS_SCHEMA_LOADING";
export const PROCESS_SCHEMA_ERROR = "PROCESS_SCHEMA_ERROR";
