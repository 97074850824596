import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList as getAttritionChart } from "redux/actions/attritionActions";
import { PAGINATION } from "common/constants";
import useComplexState from "hooks/utils/useComplexState";
import { RISK_CATEGORY } from "../functions";

/**
* Custom hook to get the data for RiskCardCollapse component
* and handle the collapse state
*/
export const useCards = ({
  query, searchFilter,
}) => {
  const [states, setStates] = useComplexState({
    highRiskIsOpen: false,
    mediumRiskIsOpen: false,
    lowRiskIsOpen: false,
  });

  const {
    highRiskList,
    mediumRiskList,
    lowRiskList,
    listTotal,
    highRiskListTotal,
    mediumRiskListTotal,
    lowRiskListTotal,
    highRiskIsLoadingList,
    mediumRiskIsLoadingList,
    lowRiskIsLoadingList,
  } = useSelector(({ attritionReducer }) => attritionReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (query && searchFilter !== undefined) {
      const lastQuery = query;
      lastQuery.q.employee_person_full_name_cont = searchFilter;
      dispatch(getAttritionChart(PAGINATION.next, null, lastQuery));
    }
    // eslint-disable-next-line
  }, [searchFilter]);

  const handleCollapseState = (level, value = null) => {
    setStates({
      [`${level}RiskIsOpen`]: value === null ? !states[`${level}RiskIsOpen`] : value,
    });
  };

  const riskCardsData = [
    {
      type: RISK_CATEGORY.high.key,
      data: highRiskList?.responseList,
      total: listTotal,
      totalByCategory: highRiskListTotal,
      isLoading: highRiskIsLoadingList,
      isOpenCollapse: states.highRiskIsOpen,
    },
    {
      type: RISK_CATEGORY.medium.key,
      data: mediumRiskList?.responseList,
      total: listTotal,
      totalByCategory: mediumRiskListTotal,
      isLoading: mediumRiskIsLoadingList,
      isOpenCollapse: states.mediumRiskIsOpen,
    },
    {
      type: RISK_CATEGORY.low.key,
      data: lowRiskList?.responseList,
      total: listTotal,
      totalByCategory: lowRiskListTotal,
      isLoading: lowRiskIsLoadingList,
      isOpenCollapse: states.lowRiskIsOpen,
    },
  ];

  return { riskCardsData, handleCollapseState };
};
