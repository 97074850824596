import styled from "styled-components";
import palette from "theme/palette";

export const StyledInfoContainer = styled.div`
  margin-top: 8px;
  svg{
    width: 16px;
    color: ${palette.background.gray};
  }
  p{
    margin-top: 8px;
    margin-left: 20px;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
  svg{
    margin-right: 5px;
  }
`;

export const StyledSalaryInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledContent = styled.div`
  text-align: right;
`;
