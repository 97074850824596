import {
  useCallback, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import isString from "lodash/isString";
import Grid from "@mui/material/Grid";
import { getList as getGender } from "redux/actions/common/genderActions";
import { resetState as resetStateManagers } from "redux/actions/common/managerActions";
import {
  create as createCollaborator,
} from "redux/actions/collaboratorActions";
import Breadcrumbs from "components/Breadcrumbs";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  MIN_VALUE,
  GENDER_STATUS as GS,
  OBJECT_KEYS,
} from "common/constants";
import {
  preventEnter, getValueByProp, formatDateToSubmit,
} from "common/utils";
import { removeDataEmpty } from "common/validators";
import { STATUS } from "common/constants/gender";
import { isEqual } from "common/helpers";
import { getItemSelected } from "views/Account/functions/personalData";
import PersonalInformation from "./components/PersonalInformation";
import JobInformation from "./components/JobInformation";
import Salary from "./components/Salary";
import {
  useStyles, StyledMainContainer, StyledTitle, StyledTitleCard, StyledButton,
} from "./styles";

const Forms = () => {
  const { t } = useTranslation("collaborators", "Onboarding", "common");
  const [selectedGender, setSelectedGender] = useState();
  const [defaultValue, setDefaultValue] = useState({
    position: null,
    country: null,
    city: null,
    level: null,
    legalEntity: null,
  });
  const classes = useStyles();

  const {
    successProcess: successProcessCollaborator,
    isLoadingProcess: isLoadingProcessCollaborator,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    list: allGenderList,
  } = useSelector(({ genderReducer }) => genderReducer);

  const dispatch = useDispatch();

  const {
    register, control, handleSubmit, errors, setValue,
  } = useForm({
    defaultValues: {
      "collaborator.person_attributes.personal_id": "",
      "collaborator.person_attributes.personal_email": "",
      "collaborator.person_attributes.nationality": "",
      "collaborator.person_attributes.address": "",
      "collaborator.person_attributes.gender": "",
      "collaborator.person_attributes.phone_number": "",
      "collaborator.person_attributes.linkedin_profile": "",
      "collaborator.person_attributes.additional_information": "",
      "collaborator.person_attributes.full_name": "",
      "collaborator.person_attributes.birth_date": null,
      "collaborator.salary_attributes.currency": "",
      "collaborator.salary_attributes.gross_salary": MIN_VALUE,
      "collaborator.job_position_attributes.type_of_contract_id": "",
      "collaborator.job_position_attributes.position_id": "",
      "collaborator.job_position_attributes.hierarchy_level_id": "",
      "collaborator.job_position_attributes.country_id": "",
      "collaborator.job_position_attributes.city_id": "",
      "collaborator.starting_date": moment(),
      "collaborator.email": "",
      "collaborator.legal_entity_id": "",
    },
  });

  const dataBreadcrumbs = [
    {
      label: t("title"),
      path: "/collaborators",
    },
    {
      label: t("forms.title"),
      path: "",
    },
  ];

  const history = useHistory();

  useEffect(() => {
    dispatch(getGender());
  }, [dispatch]);

  const genderList = allGenderList?.map((item) => (
    { ...item, value: t(GS[item.id]), label: t(GS[item.id]) }
  ));

  const itemSelected = getItemSelected(genderList, selectedGender);

  const otherValue = getItemSelected(genderList, STATUS.other, OBJECT_KEYS.id)?.value;

  const resetDataAndRedirect = useCallback(() => {
    history.push("/collaborators");
  }, [history]);

  useEffect(() => {
    if (successProcessCollaborator) {
      resetDataAndRedirect();
    }
  }, [successProcessCollaborator, resetDataAndRedirect]);

  const onSubmit = (collaboratorData) => {
    const { collaborator } = collaboratorData;
    collaborator.job_position_attributes.position_id = defaultValue?.position && defaultValue?.position?.id;
    collaborator.job_position_attributes.hierarchy_level_id = defaultValue?.level ? defaultValue?.level?.id : "";
    collaborator.legal_entity_id = defaultValue?.legalEntity ? defaultValue?.legalEntity?.id : "";
    collaborator.job_position_attributes.country_id = defaultValue?.country && defaultValue?.country?.id;
    collaborator.job_position_attributes.city_id = defaultValue?.city ? defaultValue?.city?.id : "";

    collaborator.person_attributes.gender = isEqual(itemSelected?.id, STATUS.other)
      ? collaborator.person_attributes.other_gender : selectedGender;

    collaborator.manager_id = getValueByProp(collaborator?.manager_id, OBJECT_KEYS.id);
    collaborator.person_attributes.birth_date = formatDateToSubmit(
      collaborator.person_attributes.birth_date,
    );
    collaborator.starting_date = formatDateToSubmit(
      collaborator.starting_date,
    );
    const salary = collaborator.salary_attributes.gross_salary;

    if (isString(salary)) {
      collaborator.salary_attributes.gross_salary = salary.replaceAll(
        ",",
        "",
      );
    }

    removeDataEmpty(collaborator);
    dispatch(createCollaborator(collaboratorData));
    dispatch(resetStateManagers());
  };

  return (
    <StyledMainContainer data-testid={ "collaborator-forms" }>
      <Breadcrumbs data={ dataBreadcrumbs } />
      <StyledTitle>{t("collaborators:forms.title")}</StyledTitle>
      <div className={ classes.content }>
        <form
          id={ "collaborator-form" }
          onSubmit={ handleSubmit(onSubmit) }
          onKeyPress={ preventEnter }
          autoComplete={ "off" }
        >
          <Grid>
            <StyledTitleCard variant={ VARIANT.h5 }>
              {t("Onboarding:personal_information_title")}
            </StyledTitleCard>

            <PersonalInformation
              genderList={ genderList }
              control={ control }
              setValue={ setValue }
              otherValue={ otherValue }
              selectedGender={ selectedGender }
              setSelectedGender={ setSelectedGender }
              errors={ errors }
            />

            <StyledTitleCard variant={ VARIANT.h5 }>
              {t("Onboarding:job_information_title")}
            </StyledTitleCard>

            <JobInformation
              control={ control }
              register={ register }
              setValue={ setValue }
              defaultValue={ defaultValue }
              setDefaultValue={ setDefaultValue }
            />

            <StyledTitleCard variant={ VARIANT.h5 }>
              {t("Onboarding:salary_information_title")}
            </StyledTitleCard>

            <Salary control={ control } register={ register } setValue={ setValue } />
          </Grid>
          <Grid container justifyContent={ "flex-end" }>
            <StyledButton
              variant={ VARIANT.contained }
              isLoading={ isLoadingProcessCollaborator }
              color={ COLORS_NAME.primary }
              type={ BUTTON_STYLE_TYPES.SUBMIT }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            >
              {t("Onboarding:saveButton")}
            </StyledButton>
          </Grid>
        </form>
      </div>
    </StyledMainContainer>
  );
};

export default Forms;
