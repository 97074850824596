import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import Breadcrumbs from "components/Breadcrumbs";
import PageContainerContainer from "components_refactor/PageContainer";
import {
  ALIGN_ITEMS, DIRECTION, MODULES, MODULE_ACTIONS, COMPANY_SLUGS,
} from "common/constants";
import constants from "styledComponents/constants";
import ProfileInfo from "./components/ProfileInfo";
import LeaderInfo from "./components/LeaderInfo";
import FeedbackInfo from "./components/FeedbackInfo";
import TasksTabs from "./components/TaskTabs";
import EmployeeInfo from "./components/EmployeeInfo";
import SalaryInfo from "./components/SalaryInfo";
// import CompanyInfo from "./components/CompanyInfo";
import LossRiskInfo from "./components/LossRiskInfo";
import MovementHistoryInfo from "./components/MovementHistoryInfo";
import NineboxInfo from "./components/NineboxInfo";
import PerformanceInfo from "./components/PerformanceInfo";
import PotentialInfo from "./components/PotentialInfo";
import LeadershipInfo from "./components/LeadershipInfo";
import {
  StyledContainer, StyledTitleContainer, StyledTitle, StyledBox,
} from "./styles";

const ProfileView = (props) => {
  const {
    t,
    isOwnProfile,
    collaborator,
    dataBreadcrumbs,
    isValidToShowAttrition,
    isValidToShowNineBox,
    ability,
    resultScales,
    isManager,
    user,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const employeeFullName = collaborator?.full_name || "";
  const name = employeeFullName.split(" ")[0];

  const acknowledgmentAbility = ability?.acknowledgments;
  const goalAbility = ability?.goals;
  const developmentPlanAbility = ability?.developmentPlan;
  const hasAccessAcknowledgments = acknowledgmentAbility?.can(MODULE_ACTIONS.view, MODULES.acknowledgments);
  const hasAccessGoals = goalAbility?.can(MODULE_ACTIONS.view, MODULES.goals);
  const hasAccessDevelopmentPlan = developmentPlanAbility?.can(MODULE_ACTIONS.view, MODULES.developmentPlan);

  const hasAccessToViewOwnPerformance = !(isOwnProfile && user.company?.slug === COMPANY_SLUGS.optimal);

  const hasAccessToViewOwnPotential = !(isOwnProfile && user.company?.slug === COMPANY_SLUGS.btf);

  const profileTitle = isOwnProfile ? `${t("profile:regard")} ${name},` : (
    <Breadcrumbs data={ dataBreadcrumbs } />
  );

  const [quadrantNumber, setQuadrantNumber] = useState(null);

  const handleQuadrantSelected = (quadrant) => {
    setQuadrantNumber(quadrant);
  };

  return (
    <PageContainerContainer>
      <StyledContainer>
        <StyledTitleContainer>
          <StyledTitle weight={ constants.fontConstants.bold }>
            {profileTitle}
          </StyledTitle>
          {isOwnProfile && (
            <StyledTitle weight={ constants.fontConstants.normal }>
              {t("profile:welcome")}
            </StyledTitle>
          )}
        </StyledTitleContainer>
        <StyledBox display={ ALIGN_ITEMS.grid } flexDirection={ DIRECTION.column }>
          <StyledBox display={ isMobile ? "block" : ALIGN_ITEMS.flex }>
            <StyledBox display={ isMobile ? "block" : ALIGN_ITEMS.flex } flexDirection={ DIRECTION.row }>
              <StyledBox pr={ isMobile ? 0 : 2 } width={ isMobile ? "100%" : "35%" } display={ ALIGN_ITEMS.grid } flexDirection={ DIRECTION.column }>
                <StyledBox>
                  <ProfileInfo
                    { ...props }
                    quadrant={ quadrantNumber }
                  />
                </StyledBox>
                {hasAccessAcknowledgments
                  && (
                    <StyledBox pt={ 2 }>
                      <FeedbackInfo { ...props } />
                    </StyledBox>
                  ) }
                <StyledBox pt={ 2 }>
                  <EmployeeInfo { ...props } />
                </StyledBox>
                <StyledBox pt={ 2 }>
                  <SalaryInfo { ...props } />
                </StyledBox>
              </StyledBox>

              <StyledBox width={ isMobile ? "100%" : "65%" } display={ ALIGN_ITEMS.grid } flexDirection={ DIRECTION.column }>
                <StyledBox display={ isMobile ? "block" : ALIGN_ITEMS.flex } flexDirection={ DIRECTION.row }>
                  <StyledBox display={ ALIGN_ITEMS.grid } flexDirection={ DIRECTION.column }>
                    <StyledBox pt={ isMobile ? 2 : 0 }>
                      <LeaderInfo { ...props } />
                    </StyledBox>
                    {isValidToShowNineBox && resultScales
                    && (
                      <StyledBox pt={ 2 }>
                        <NineboxInfo
                          { ...props }
                          handleQuadrantSelected={ handleQuadrantSelected }
                        />
                      </StyledBox>
                    )}
                    {(!isValidToShowNineBox
                      && user.company?.slug !== COMPANY_SLUGS.shn)
                        && (
                          <>
                            {hasAccessToViewOwnPerformance
                            && (
                              <StyledBox pt={ 2 }>
                                <PerformanceInfo { ...props } />
                              </StyledBox>
                            )}
                            {hasAccessToViewOwnPotential
                            && (
                              <StyledBox pt={ 2 }>
                                <PotentialInfo { ...props } />
                              </StyledBox>
                            )}
                            {isManager
                            && (
                              <StyledBox pt={ 2 }>
                                <LeadershipInfo { ...props } />
                              </StyledBox>
                            )}
                          </>
                        )}
                  </StyledBox>
                  {(hasAccessDevelopmentPlan || hasAccessGoals) && collaborator?.full_name
                  && (
                    <StyledBox pt={ isMobile ? 2 : 0 } pl={ isMobile ? 0 : 2 }>
                      <TasksTabs
                        { ...props }
                        hasAccessGoals={ hasAccessGoals }
                        hasAccessDevelopmentPlan={ hasAccessDevelopmentPlan }
                      />
                    </StyledBox>
                  )}

                </StyledBox>
                {((isManager && isValidToShowNineBox) || isValidToShowAttrition)
                && (
                  <StyledBox display={ isMobile ? "block" : ALIGN_ITEMS.flex } flexDirection={ DIRECTION.row }>
                    <StyledBox display={ ALIGN_ITEMS.grid } flexDirection={ DIRECTION.column }>
                      {(((!isManager && isValidToShowNineBox)
                      || (isValidToShowNineBox && isValidToShowAttrition))
                      && user.company?.slug !== COMPANY_SLUGS.shn)
                    && (
                      <StyledBox display={ isMobile ? "block" : ALIGN_ITEMS.flex } flexDirection={ isMobile ? DIRECTION.column : DIRECTION.row }>
                        {hasAccessToViewOwnPerformance
                        && (
                          <StyledBox width={ isMobile || !hasAccessToViewOwnPotential ? "100%" : "50%" } pt={ 2 } pr={ isMobile || !hasAccessToViewOwnPotential ? 0 : 2 }>
                            <PerformanceInfo { ...props } />
                          </StyledBox>
                        )}
                        {hasAccessToViewOwnPotential
                        && (
                          <StyledBox width={ isMobile || !hasAccessToViewOwnPerformance ? "100%" : "50%" } pt={ 2 }>
                            <PotentialInfo { ...props } />
                          </StyledBox>
                        )}
                      </StyledBox>
                    )}
                      {isValidToShowAttrition && (
                        <StyledBox pt={ 2 }>
                          <LossRiskInfo { ...props } />
                        </StyledBox>
                      )}
                    </StyledBox>
                    {isManager && isValidToShowNineBox
                    && (
                      <StyledBox pt={ 2 } pl={ isMobile ? 0 : 2 }>
                        <LeadershipInfo { ...props } />
                      </StyledBox>
                    )}
                  </StyledBox>
                )}
                <StyledBox pt={ 2 } >
                  <MovementHistoryInfo { ...props } />
                </StyledBox>
              </StyledBox>
            </StyledBox>
          </StyledBox>
        </StyledBox>
      </StyledContainer>
    </PageContainerContainer>
  );
};

ProfileView.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  profileStates: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  dataBreadcrumbs: PropTypes.array.isRequired,
  isValidToShowAttrition: PropTypes.bool.isRequired,
  isValidToShowNineBox: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  resultScales: PropTypes.array,
  ability: PropTypes.shape({
    acknowledgments: PropTypes.shape({
      can: PropTypes.func.isRequired,
      cannot: PropTypes.func.isRequired,
      rules: PropTypes.arrayOf(
        PropTypes.shape({
          actions: PropTypes.arrayOf(PropTypes.string).isRequired,
          subject: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    goals: PropTypes.shape({
      can: PropTypes.func.isRequired,
      cannot: PropTypes.func.isRequired,
      rules: PropTypes.arrayOf(
        PropTypes.shape({
          actions: PropTypes.arrayOf(PropTypes.string).isRequired,
          subject: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    developmentPlan: PropTypes.shape({
      can: PropTypes.func.isRequired,
      cannot: PropTypes.func.isRequired,
      rules: PropTypes.arrayOf(
        PropTypes.shape({
          actions: PropTypes.arrayOf(PropTypes.string).isRequired,
          subject: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }),
};

ProfileView.defaultProps = {
  ability: null,
  resultScales: null,
};

export default ProfileView;
