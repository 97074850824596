import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import { ICON, VARIANT } from "common/constants";
import TooltipIcon from "components/TooltipIcon";
import { StyledTabs } from "./styles";

const TabComponent = (props) => {
  const {
    tabValue, tabs, onChange, customStyle, byValue, sx,
  } = props;

  const getNewValue = (isByValue, newValue) => (
    isByValue
      ? tabs.findIndex((tab) => tab.value === newValue)
      : newValue
  );
  const [value, setValue] = useState(getNewValue(byValue, tabValue));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(byValue ? tabs[newValue]?.value : newValue);
  };

  useEffect(() => {
    setValue(getNewValue(byValue, tabValue));
  }, [tabValue]);

  return (
    <StyledTabs
      data-testid={ "tabs-component" }
      value={ value }
      onChange={ handleChange }
      variant={ VARIANT.scrollable }
      scrollButtons={ "on" }
      aria-label={ "Tabs" }
      TabIndicatorProps={ { className: "indicator" } }
      className={ "tabs" }
      initialSelectedIndex={ value }
      sx={ sx }
    >
      {tabs?.map((item, index) => (
        <Tab
          key={ `simple-tabs-${item.label}` }
          label={ (
            <span style={ { display: "flex", alignItems: "center" } }>
              {item.label}
              {item.tooltip && (
                <TooltipIcon
                  title={ item.tooltip }
                  isHelpIcon
                />
              )}
            </span>
          ) }
          id={ `simple-tab-${index}` }
          aria-controls={ `simple-tabpanel-${index}` }
          disabled={ item.isDisabled }
          className={ customStyle }
          icon={ item.icon || null }
          iconPosition={ item.iconPosition || ICON.start }
          sx={ item.sx || null }
        />
      ))}
    </StyledTabs>
  );
};

TabComponent.propTypes = {
  tabs: PropTypes.array,
  tabValue: PropTypes.number,
  onChange: PropTypes.func,
  customStyle: PropTypes.string,
  byValue: PropTypes.bool,
  sx: PropTypes.object,
};

TabComponent.defaultProps = {
  byValue: false,
  sx: {},
};

export default TabComponent;
