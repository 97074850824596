import { ReactFlowProvider } from "reactflow";
import OrganizationChartView from "./components/OrganizationChartView";

const OrganizationChart = () => (
  <ReactFlowProvider>
    <OrganizationChartView />
  </ReactFlowProvider>
);

export default OrganizationChart;
