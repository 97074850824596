import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import palette, { EVENT_STATE_COLORS } from "theme/palette";

export const StyledCard = styled(Card)`
  margin: 10px 0 30px;
  .MuiFormControlLabel-labelPlacementBottom {
    margin: 0;
  }
  .opacityLabel {
    .MuiTypography-root {
      opacity: 0;
    }
  }
  .starsLabel {
    &.MuiFormControlLabel-labelPlacementBottom {
      width: 120px;
    }
    &.MuiFormControlLabel-labelPlacementTop .MuiTypography-body1 {
      font-size: 12px;
    }
  }
  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
  .Mui-checked {
    color: ${palette.background.purple};
  }
  .startFlex {
    &.MuiFormControlLabel-root {
      align-items: flex-start;
      margin-bottom: 20px;
      width: 100%;
    }
    .MuiIconButton-root {
      padding: 0 20px;
    }
  }
  .MuiTableCell-root {
    border-bottom: 0px;
  }
  .MuiAccordionDetails-root {
    padding: ${(props) => (props.isScale ? "20px 0px 55px" : "0 0 20px") };
  }
  .MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordionSummary-content {
    display: block;
  }
`;

export const StyledTitle = styled(Typography)`
  font-size: 16px;
  margin-bottom: 40px;
  font-weight: 500;
`;

export const StyledAnswerContent = styled.div`
  text-align: center;
  h5 {
    font-size: 52px;
    font-weight: 700;
    line-height: 48px;
    margin-top: 8px;
  }
`;

export const StyledMember = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-body1 {
    font-size: 14px;
    margin-left: 8px;
  }
  .MuiAvatar-root, .MuiSvgIcon-root.MuiAvatar-fallback {
    width: 25px;
    height: 25px;
  }
`;

export const StyledResults = styled.div`
  padding: 5px 0;
  display: flex;
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

export const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  &.MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    color: ${ EVENT_STATE_COLORS.green };
  }
`;

export const StyledTextInfo = styled.div`
  color: ${ palette.text.gray };
  font-size: 14px;
`;
