import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { ALIGN_ITEMS } from "common/constants";
import {
  StyledCardContainer,
  StyledTypography,
  StyledImageContainer,
} from "./styles";

const Banner = (props) => {
  const {
    title, text, icon,
  } = props;

  return (
    <StyledCardContainer>
      <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
        <Box flexGrow={ 1 }>
          <StyledTypography>
            {title}
          </StyledTypography>
          {text
          && (
            <StyledTypography>
              {text}
            </StyledTypography>
          ) }
        </Box>
        { icon
        && (
          <StyledImageContainer>
            <img src={ icon } alt={ title } />
          </StyledImageContainer>
        ) }
      </Box>
    </StyledCardContainer>
  );
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.string,
};

Banner.defaultProps = {
  text: null,
  icon: null,
};

export default Banner;
