import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import StarRating from "components/StarRating";
import NoData from "components/NoData";
import NoDataResult from "components/NoDataResult";
import SkeletonLoader from "components/SkeletonLoader";
import SectionContainer from "components_refactor/SectionContainer";
import constants from "styledComponents/constants";
import { replacePath, findDataInScale } from "common/utils";
import { SKELETONS_NUMBER } from "common/constants";
import localRoutes from "../../functions/routes";
import {
  StyledCardTitle,
  StyledFixedContainer,
  StyledLink,
  StyledResultContainer,
} from "../../styles";

const PerformanceInfo = ({
  t, history, currentPerformance, isLoadingCurrentPerformance,
}) => {
  // Render content based on the component's state
  const renderContent = () => {
    if (isLoadingCurrentPerformance) {
      return <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } />;
    }

    if (currentPerformance[0]) {
      const scaleIndices = currentPerformance[0]?.result_scale_indices;

      const scaleIndex = findDataInScale(scaleIndices, currentPerformance[0].result);
      const scaleMaxIndex = scaleIndices?.length;
      return (
        <>
          {currentPerformance[0]?.score
            ? (
              <StyledResultContainer>
                <StarRating
                  name={ "current-score" }
                  value={ scaleIndex?.position }
                  isReadOnly
                  maxRating={ scaleMaxIndex }
                  max={ scaleMaxIndex }
                  precision={ 1 }
                  hasNoFormattedValue
                  label={ currentPerformance[0].result }
                />
              </StyledResultContainer>
            ) : <NoDataResult /> }
          <StyledFixedContainer>
            <StyledLink onClick={ () => replacePath(history, localRoutes.performance) }>
              { t("common:common.viewMore") }
            </StyledLink>
          </StyledFixedContainer>
        </>
      );
    }

    return <NoData />;
  };

  return (
    <SectionContainer>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <StyledCardTitle weight={ constants.fontConstants.bold }>
            {t("profile:performance")}
          </StyledCardTitle>
          {renderContent()}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

PerformanceInfo.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentPerformance: PropTypes.array,
  isLoadingCurrentPerformance: PropTypes.bool,
};

PerformanceInfo.defaultProps = {
  currentPerformance: [],
  isLoadingCurrentPerformance: false,
};

export default PerformanceInfo;
