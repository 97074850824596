import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledNineboxMatrix = styled(CardContent)`
  background: ${palette.white};
  .MuiBox-root {
    padding: 5px 0 10px;
  }
`;

export const StyledNineboxContent = styled(CardContent)`
  background: ${palette.white};
`;

export const StyledCard = styled(Card)`
  padding: 10px;
  height: 100%;
  &.MuiPaper-root {
    box-shadow: none;
  }
`;

export const StyledGlobalActions = styled.g`
  :hover {
    cursor: pointer;
  }
`;

export const StyledOptionDescription = styled(Typography)`
  padding: 20px 20px 5px 25px;
  @media (max-width: 600px) {
    padding: 0;
  }
`;
