import Grid from "@mui/material/Grid";
import theme from "theme/palette";
import styled from "styled-components";

export const StyledGrid = styled(Grid)`
  margin-top: 16px;
`;

export const StyledLinkEvaluation = styled.span`
  color: ${theme.text.link};
  font-size: 14px;
  .MuiSvgIcon-root {
    width:  12px;
    height:  12px;
  }
`;
