import { range } from "lodash";
import filter from "lodash/filter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import GradeIcon from "@mui/icons-material/Grade";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import {
  OBJECT_KEYS,
  COLLABORATORS_TABLE_ACTIONS,
  SIZE,
  PROFILE_INDEX_TABS,
  MAX_EMPLOYEES_QUERY,
} from "common/constants";
import { isEqual } from "common/helpers";
import { getCollaboratorByEmails } from "redux/actions/collaboratorActions";

export const getActiveCollaborators = (collaborators, isActive) => filter(collaborators, [OBJECT_KEYS.isActive, isActive]);

const getOptionValues = (option, t) => {
  switch (option) {
  case COLLABORATORS_TABLE_ACTIONS.view:
    return {
      title: t("collaborators:table.table-body.view_profile"),
      icon: <VisibilityIcon fontSize={ SIZE.small } />,
      state: {},
    };
  case COLLABORATORS_TABLE_ACTIONS.edit:
    return {
      title: t("collaborators:table.table-body.edit_profile"),
      icon: <EditIcon fontSize={ SIZE.small } />,
      state: { edit: true },
    };
  case COLLABORATORS_TABLE_ACTIONS.promote:
    return {
      title: t("account:accountDetails.promote"),
      icon: <GradeIcon fontSize={ SIZE.small } />,
      state: { salary: true },
    };
  case COLLABORATORS_TABLE_ACTIONS.lateralMovement:
    return {
      title: t("account:accountDetails.lateral_movement"),
      icon: <SwapHorizIcon fontSize={ SIZE.small } />,
      state: { lateralMovement: true },
    };
  case COLLABORATORS_TABLE_ACTIONS.salaryRaise:
    return {
      title: t("account:accountDetails.salary_increase"),
      icon: <MonetizationOnIcon fontSize={ SIZE.small } />,
      state: { salary: true, tabId: PROFILE_INDEX_TABS.salary },
    };
  default:
    break;
  }
};

export const getMenuOption = (varCommons, option) => ({
  title: getOptionValues(option, varCommons.t).title,
  icon: getOptionValues(option, varCommons.t).icon,
  onClick: () => {
    varCommons.history.push({
      pathname: "/profile",
      search: `?collaborator=${varCommons.collaboratorId}`,
      state: getOptionValues(option, varCommons.t).state,
    });
  },
});

export const getCollaboratorByParts = async (emails) => new Promise((resolve) => {
  let data = [];
  let numIterations = 0;
  const iterations = range(Math.ceil(emails.length / MAX_EMPLOYEES_QUERY));
  iterations.forEach(async (iter) => {
    const result = await getCollaboratorByEmails(emails.slice(
      iter * MAX_EMPLOYEES_QUERY,
      (iter * MAX_EMPLOYEES_QUERY) + MAX_EMPLOYEES_QUERY,
    ));
    data = await [...data, ...result];
    numIterations += 1;
    if (isEqual(numIterations, iterations.length)) {
      resolve(data);
    }
  });
});
