export const EVALUATION_TYPE = {
  AUTOEVALUATION: "Autoevaluation",
  MANAGER: "ManagerEvaluation",
  PAIRS: "PairsEvaluation",
  COLLABORATORS: "CollaboratorsEvaluation",
  OVERALL: "OverallEvaluation",
};

export const GOOGLE_FORMS = {
  url: "https://docs.google.com/forms/d/e",
  view: "viewform",
  params: {
    employee: "599750823", // key to fill employee email
    manager: "860257928", // key to fill manager email
  },
};

export const SURVEY_PROCESS_IFRAMES = {
  performance: [
    {
      id: EVALUATION_TYPE.AUTOEVALUATION,
      key: "1FAIpQLSeB6RZlcyzU2W6zzewLs6DDACusDY8bUfILAkrTLlo3CipAuQ",
    },
    {
      id: EVALUATION_TYPE.MANAGER,
      key: "1FAIpQLSdk_uJ4BNexyF-RyleZk67qSFvpcDrJ2HvU_YTIxrqXTEAywg",
    },
    {
      id: EVALUATION_TYPE.PAIRS,
      key: "1FAIpQLSc7-amW7ixFwCIs2hq2cEstgnmbSvHBrVR1Q07fyOpZlIdBow",
    },
    {
      id: EVALUATION_TYPE.COLLABORATORS,
      key: "1FAIpQLScCnuILr552x5N8zCtJVl_14TLL2p6vIWqljDwr2OmFZl1dzQ",
    },
  ],
  potential: [
    {
      id: EVALUATION_TYPE.COLLABORATORS,
      key: "1FAIpQLSeP3EwEhGUueDU6ogngoar4IpfE7yFzdm8G4ygLMJziq8kHWw",
    },
    {
      id: EVALUATION_TYPE.MANAGER,
      key: "1FAIpQLSefdH9uYyhHIMUEnSmOPpA6q4FA5zBnVeSL2GuSaDI0IZtb8Q",
    },
    {
      id: EVALUATION_TYPE.PAIRS,
      key: "1FAIpQLSci8ViTlJmMoM9u7Fm5ifZGOALO_aMigFskYn3f2mi91i3_EA",
    },
  ],
  goodLeader: [
    {
      id: EVALUATION_TYPE.OVERALL,
      key: "1FAIpQLSeV3NmJpsCBMd-yUb1KQGsl1pecIwRdSw7GknTBxK3yvas3gQ",
    },
  ],
  engagement: [
    {
      id: EVALUATION_TYPE.OVERALL,
      key: "1FAIpQLSe0fswMOk7sYfCxrMan6NBli3CCGxeCoGHUsKvYlffivrFRTQ",
    },
  ],
  training: [
    {
      id: EVALUATION_TYPE.OVERALL,
      key: "1FAIpQLSdxHObAjHj6lhoIdCNrJ_HXl9n-nLCmtFkE4OkT_mOSz5Q4wQ",
    },
  ],
};

export const SLIDER = {
  min: 1,
  max: 20,
  breakpoint: {
    simple: {
      375: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1360: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      2000: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
    },
  },
};

export const MAX_PARTICIPATION_TO_DOWNLOAD = 200;

export const ROWS_HEADER = ["dateUpdate", "empty", "headers"];

export const PARTICIPATION_COLUMNS = [
  "collaborators:table.table-head.personal_id",
  "collaborators:table.table-head.name",
  "collaborators:table.table-head.email",
  "collaborators:table.excel.manager_name",
  "collaborators:table.table-head.position",
  "collaborators:table.excel.department",
  "surveys:report",
  "surveys:evaluations_to_answers",
];

export const RESULTS_COLUMNS = [
  "collaborators:table.table-head.personal_id",
  "collaborators:table.table-head.name",
  "surveys:leader_name",
  "collaborators:table.table-head.position",
  "surveys:country",
  "common:common.city",
];
