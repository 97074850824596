import {
  useCallback,
  useState,
  forwardRef,
  useEffect,
} from "react";
import includes from "lodash/includes";
import { NavLink as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import charts from "theme/charts";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import Button from "components/Button";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components/Filter";
import MenuPopup from "components/MenuPopup";
import AlertDialog from "components/AlertDialog";
import ViewModalComponent from "components/ViewModalComponent";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import usePagination from "hooks/utils/usePagination";
import useHandleDispatch from "hooks/utils/useHandleDispatch";
import { isNullOrUndefined } from "common/validators";
import {
  BUTTON_STYLE_TYPES,
  COLLABORATOR_INDEX_TABS,
  ROLES,
  PAGINATION,
  ALIGN_ITEMS,
  COLORS_NAME,
} from "common/constants";
import { getUserRoles, isAdmin, isManager } from "common/utils";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { isNull } from "common/helpers";
import {
  getListPaginated,
  terminateEmployment,
  deleteCollaborator,
  resetStateProcess,
} from "redux/actions/collaboratorActions";
import { submitKeyPosition } from "redux/actions/position/positionActions";
import TablePagination from "components/TablePagination";
import { ReactComponent as Audiences } from "assets/images/icons/audiences.svg";
import {
  HEADER,
  getRowsMobile,
  getRowsDesktop,
} from "./functions/collaborators";
import DownloadExcel from "./components/DownloadExcel";
import AudienceByCollaboratorModal from "./components/AudienceByCollaboratorModal";
import {
  StyledPaperContainer,
  StyledAlignRight,
  StyledContainer,
  StyledTabContainer,
  StyledBox,
} from "./styles";

const CollaboratorsView = (props) => {
  const { t, user, handleOpenModal } = props;

  const [tabHandler, setTabHandler] = useState(0);
  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [dispatched, setDispatched] = useState(false);
  const [filterQuery, setFilterQuery] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [modal, setModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [criticalPosition, setCriticalPosition] = useState(null);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(charts.breakpoints.small),
  );

  const getTabs = useCallback(() => {
    const TABS = [
      { label: t("collaborators:tabs.all") },
      // { label: t("collaborators:tabs.hiring") }, // commented untill service is ready
    ];
    return TABS;
  }, [t]);

  const {
    managersList: collaboratorsMainList,
    list: collaboratorList,
    listTotal: collaboratorListTotal,
    isLoadingList: isLaodingCollaboratorList,
    errorList: collaboratorErrorList,
    successProcess,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  useEffect(() => {
    if (successProcess) {
      dispatch(getListPaginated(pagination, filterQuery));
      dispatch(resetStateProcess());
      toast(
        MESSAGE_TYPES.success,
        handleMessages(
          MESSAGE_TYPES.success,
          HTTP_STATUS_RESPONSE.ok,
          t,
          successMessage,
        ),
      );
      setSuccessMessage(null);
    }
  }, [successProcess, filterQuery, successMessage, pagination, dispatch, t]);

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const resetDispatched = (isReset) => {
    setDispatched(isReset);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ ref }>
      <RouterLink { ...props } />
    </div>
  ));

  useHandleDispatch(pagination, setDispatched);

  const handleSearch = (name = "") => {
    setSearchFilter(name);
  };

  useEffect(() => {
    if (filterQuery) {
      const query = filterQuery;
      query.q.person_full_name_cont = searchFilter;
      if (!isLaodingCollaboratorList && !isNull(collaboratorList)) {
        dispatch(getListPaginated(PAGINATION.next, query));
        setPagination(PAGINATION.next);
      }
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [searchFilter]);

  // triggers when update any external element besides the filter
  const handleExternalQuery = (query) => {
    const tempFilterQuery = query;
    tempFilterQuery.q.person_full_name_cont = searchFilter;
    setFilterQuery(tempFilterQuery);
  };

  const handleTerminateEmployment = (id, data) => {
    setSuccessMessage(t("collaborators:success.termination_date"));
    dispatch(terminateEmployment(id, data));
  };

  const handleDeleteEmployment = (id) => {
    setSuccessMessage(t("collaborators:success.delete"));
    dispatch(deleteCollaborator(id));
  };

  const handleDialog = (state, data = null) => {
    setCriticalPosition(data);
    setDialog(state);
  };

  const handleMarkCriticalPosition = async () => {
    const keyPosition = await dispatch(submitKeyPosition(criticalPosition));
    if (keyPosition?.id) {
      const message = t(`collaborators:success.${keyPosition.critical ? "mark_critical" : "unmarked_critical"}`);
      dispatch(getListPaginated(pagination, filterQuery));
      dispatch(resetStateProcess());
      toast(
        MESSAGE_TYPES.success,
        handleMessages(
          MESSAGE_TYPES.success,
          HTTP_STATUS_RESPONSE.ok,
          t,
          message,
        ),
      );
      setSuccessMessage(null);
    }
    setCriticalPosition(null);
    setDialog(false);
  };

  const validateIsAdmin = isAdmin(user?.userCookies);
  const validateIsManager = isManager(user?.userCookies);

  const createCollaboratorButton = user
    && !isNullOrUndefined(getUserRoles(user?.userCookies))
    && (includes(getUserRoles(user?.userCookies), ROLES.ADMIN)
      || includes(getUserRoles(user?.userCookies), ROLES.ADMIN_COLOMBIA)
      || includes(getUserRoles(user?.userCookies), ROLES.ADMIN_NALA)) && (
    <Button
        component={ CustomRouterLink }
        href={ "/collaborators/new" }
        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
      >
        {t("collaborators:buttons.create_collaborator")}
      </Button>
  );

  const handleAddModal = () => {
    setModal(!modal);
  };

  const ACTIONS_MENU = [
    {
      title: t("collaborators:audienceOptions.create"),
      onClick: () => handleAddModal(),
    },
  ];

  return (
    <ViewModalComponent viewComponent={ (
      <StyledPaperContainer data-testid={ "collaborators-container" }>
        <Grid container>
          <Grid item xs={ 12 } sm={ 6 }>
            <ViewHeaderTitle
              title={ t("collaborators:title") }
              subtitle={ t("collaborators:sub_title") }
            />
          </Grid>
          <StyledAlignRight item xs={ 12 } sm={ 6 }>
            {createCollaboratorButton}
          </StyledAlignRight>
          <Grid item xs={ 12 }>
            <Filter
              isButton={ isMobile }
              isLabel
              type={ FILTER_ACTIONS_TYPES.collaborators }
              pagination={ pagination }
              externalParams={ [pagination] }
              dispatched={ { value: dispatched, reset: resetDispatched } }
              handleExternalQuery={ handleExternalQuery }
            />
          </Grid>
        </Grid>
        <StyledTabContainer
          spacing={ 2 }
          fontsize={ 14 }
          textcolor={ COLORS_NAME.black }
          normaltext={ "true" }
          uppercase={ "true" }
        >
          <Tab
            tabs={ getTabs() }
            onChange={ handleValueChanged }
            tabValue={ tabHandler }
          />
          <TabPanel
            value={ tabHandler }
            index={ COLLABORATOR_INDEX_TABS.active }
            isLoading={ false }
          >
            <StyledContainer>
              <TablePagination
                header={ HEADER }
                list={ collaboratorList }
                isLoading={ isLaodingCollaboratorList }
                error={ collaboratorErrorList }
                getRows={ {
                  mobile: getRowsMobile,
                  desktop: getRowsDesktop,
                } }
                extraParam={ user }
                handlePagination={ handlePagination }
                pagination={ pagination }
                total={ collaboratorListTotal }
                handleSearch={ handleSearch }
                rightOptions={ (validateIsAdmin || validateIsManager) && (
                  <Box
                    display={ ALIGN_ITEMS.flex }
                    alignItems={ ALIGN_ITEMS.center }
                  >
                    {validateIsAdmin
                    && (
                      <StyledBox pr={ 1 }>
                        <MenuPopup
                          button={ t("collaborators:audiences") }
                          items={ ACTIONS_MENU }
                          typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                          icon={ <Audiences /> }
                          iconSide={ ALIGN_ITEMS.left }
                        />
                      </StyledBox>
                    )}
                    <Box flexGrow={ 1 }>
                      <DownloadExcel user={ user } query={ filterQuery } />
                    </Box>
                  </Box>
                ) }
                modalOptions={ [
                  collaboratorsMainList?.filter((item) => item.is_active),
                  handleTerminateEmployment,
                  handleDeleteEmployment,
                  handleDialog,
                  handleOpenModal,
                ] }
              />
            </StyledContainer>
          </TabPanel>
          <AlertDialog
            isOpen={ dialog }
            onClose={ () => handleDialog(false) }
            title={ t("collaborators:key_position.title") }
            message={ t(`collaborators:key_position.${criticalPosition?.key_position?.critical ? "" : "un"}mark_critical`) }
            onSubmit={ handleMarkCriticalPosition }
            buttons={ {
              isLoading: false,
            } }
          />
          <AudienceByCollaboratorModal isOpen={ modal } onClose={ handleAddModal } />
          {/* <TabPanel
          value={ tabHandler }
          index={ COLLABORATOR_INDEX_TABS.inactive }
          isLoading={ false }
        >
        </TabPanel> */}
        </StyledTabContainer>
      </StyledPaperContainer>
    ) }
    />
  );
};

CollaboratorsView.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default CollaboratorsView;
