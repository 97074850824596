import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Loader from "components/Loader";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

const EvolutionPayroll = (props) => {
  const { data } = props;
  const { t } = useTranslation("common", "HRAnalytics");

  const [chartData, setChartData] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (data && data !== null && data.length === 2) {
      let dataPayroll = {
        labels: data[0].labels,
        datasets: [],
      };

      data.forEach((getData) => {
        dataPayroll.datasets.push({
          label:
            getData.title === "P&A PAYROLL EVOLUTION"
              ? t("HRAnalytics:charts.CTCMonth")
              : t("HRAnalytics:charts.FTC"),
          data: getData.data,
          fill: false,
          borderColor:
            getData.title === "P&A PAYROLL EVOLUTION" ? "#8D29FF" : "#19E2D6",
          pointBackgroundColor:
            getData.title === "P&A PAYROLL EVOLUTION" ? "#8D29FF" : "#19E2D6",
        });
      });

      setChartData(dataPayroll);
    }
  }, [data, t]);

  const totalAddToAlig = () => {
    let totalAdd =
      chartData.datasets &&
      chartData.datasets.map((chart) => {
        return (
          !_.isEmpty(chart.data) &&
          chart.data.reduce((acumulator, current) => acumulator + current)
        );
      });
    return totalAdd;
  };

  const maxNumber = () => {
    if (chartData !== null && chartData !== "") {
      //totalAdd is sum all numbers from FlatCTC and CTCMonth and divide in array ==> example: [9540724, 10385203]
      let totalAdd = totalAddToAlig();
      //result is the min sum from FlatCTC and CTCMonth, this represent the number of top in charts ==> example: 9540724
      let result = Math.min(...totalAdd);
      //format result ==> example: [9,5,4,0,7,2,4]
      let resultFormat = ("" + result).split("");
      //format result to max number of integer adding ==> example: [10,0,0,0,0,0,0]
      resultFormat.map((number, index) =>
        index !== 0
          ? (resultFormat[index] = 0)
          : (resultFormat[index] = parseInt(number) + 1)
      );
      //
      if (parseInt(resultFormat.join("")) > result) {
        return parseInt(resultFormat.join(""));
      } else {
        //The spacing to the top its too much, for that divided the porcent of two ==> example: 10000000 / 2 = 5000000 this is top of charts
        return parseInt(resultFormat.join("")) / 2;
      }
    } else {
      return 0;
    }
  };

  //Chart Options
  const options = {
    plugins: {
      datalabels: {
        formatter: function(value, context) {
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);
          value = value.join(".");
          return `$ ${value}`;
        },
        display: true,
        color: "#050620",
        //return Degrees of alignment in datalabels Charts
        align: function(context) {
          let totalAdd = totalAddToAlig();
          let i = totalAdd.indexOf(Math.max(...totalAdd));

          if (context.datasetIndex === i) {
            if (context.dataIndex === 0) {
              return "-55";
            } else if (context.dataIndex === context.dataset.data.length - 1) {
              return "-120";
            } else {
              return "-90";
            }
          } else {
            if (context.dataIndex === 0) {
              return "55";
            } else if (context.dataIndex === context.dataset.data.length - 1) {
              return "120";
            } else {
              return "90";
            }
          }
        },
        fontSize: 12,
        fontFamily: "Roboto",
        padding: 8,
      },
    },
    legend: {
      labels: {
        useLineStyle: true,
        fontColor: "#A3A3A3",
        fontSize: 12,
        fontFamily: "Roboto",
        fontStyle: "normal",
      },
      position: "top",
      align: "center",
    },
    tooltips: { enabled: false },
    hover: { mode: null },
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 10,
            fontFamily: "Roboto",
            color: "#A3A3A3",
            beginAtZero: true,
            min: 0,
            max: maxNumber(),
            maxRotation: 0.00001,
            padding: 15,
            userCallback: function(value, index, values) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(".");
              return `$ ${value}`;
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 10,
            fontFamily: "Roboto",
            padding: 2,
            color: "#A3A3A3",
          },
        },
      ],
    },
    responsive: true,
  };

  return (
    <Card data-testid="evolution-payroll">
      <CardHeader
        className={classes.cardHeader}
        title={t("HRAnalytics:charts.FTC_CTCMonth")}
      />
      <CardContent>
        {!_.isNull(chartData) && !_.isNull(data) && data.length === 2 ? (
          <Grid container direction="row" justify="center" alignItems="center">
            <div className={classes.chartContainer}>
              <Line data={chartData} options={options} height={200} />
            </div>
          </Grid>
        ) : (
          <Loader />
        )}
      </CardContent>
    </Card>
  );
};

EvolutionPayroll.propTypes = {
  data: PropTypes.array,
};

export default EvolutionPayroll;
