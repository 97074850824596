import styled from "styled-components";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  width: 100%;
  .MuiTabs-root {
    display: flex;
    justify-content: space-between;
  }
  .MuiTab-root {
    flex-grow: 1;
    max-width: none;
    min-width: auto;
  }
  .MuiTab-textColorPrimary {
    color: ${palette.text.secondary};
    border-bottom: 3px solid ${palette.background.lightBlue};
  }
  .Mui-selected {
    background: ${palette.backgroundTab};
    color: ${palette.black};
  }
  .MuiTabs-root {
    margin-bottom: 10px;
  }
`;

export const StyledSectionContainer = styled.div`
  background: ${palette.white};
  border-radius: 4px;
  height: 100%;
  position: relative;
  display: block;
`;
