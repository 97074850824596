import {
  useCallback, useContext, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import InputTextSearch from "components/InputTextSearch";
import Filter from "components/Filter";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import MultidataCard from "components/MultidataCard";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import {
  DIRECTION,
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
} from "common/constants";
import { getSearchParams, isManager as isRolManager } from "common/utils";
import { isEmpty } from "common/helpers";
import { SessionContext } from "modules/session/context";
import { getSurveyProcessByUser } from "redux/actions/surveyProcessesActions";
import { getList as getFollowUpList } from "redux/actions/followUpActions";
import { getFollowUpProcess } from "views/OnboardingFollowUp/functions";
import { StyledSimpleDashboardContainer } from "styledComponents/View";
import { StyledTabsContainer } from "styledComponents/Tabs";
import OverView from "./components/OverView";
import { getFormatCards } from "./functions";
import {
  StyledSpacedGrid,
  StyledTasksGrid,
  StyledFiltersGrid,
  StyledCardsGrid,
} from "./styles";

const CollaboratorsFollowUp = () => {
  // TODO: upgrades to the new architecture
  const { t } = useTranslation(["onboardingFollowUp"]);
  const history = useHistory();
  const params = history.location.search;
  const allParams = getSearchParams(params);
  const [tabHandler, setTabHandler] = useState(0);
  const [searchFilter, setSearchFilter] = useState(allParams?.search || "");
  const [filterQuery, setFilterQuery] = useState();
  const [surveyProcesses, setSurveyProcesses] = useState(null);
  const {
    state: { user },
  } = useContext(SessionContext);
  const employeeId = user?.employee?.id;
  const startingDate = user?.person?.employee?.starting_date;
  const isManager = isRolManager(user?.userCookies);
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(charts.breakpoints.small),
  );

  const dispatch = useDispatch();

  const {
    list: followUpList,
    isLoadingList: isLoadingFollowUpList,
    collaboratorMeta: followUpCollaboratorMeta,
    isLoadingCollaboratorList: isLoadingFollowUpCollaboratorList,
  } = useSelector(({ followUpReducer }) => followUpReducer);

  const allTabs = [
    { label: t("tabs.overview") },
    { label: t("tabs.finished") },
  ];

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const handleInput = (name = "") => {
    setSearchFilter(name);
  };

  const getSurveyResults = useCallback(async () => {
    const response = await dispatch(getSurveyProcessByUser(employeeId, true));
    if (response?.error) {
      const toastMessage = {
        title: t("common:common.api_responses.error.title"),
        message: response?.message,
      };
      toast(MESSAGE_TYPES.error, toastMessage);
    } else {
      setSurveyProcesses(response);
    }
  }, [employeeId, dispatch, t]);

  useEffect(() => {
    dispatch(getFollowUpList());
    if (employeeId) {
      getSurveyResults();
    }
  }, [getSurveyResults, employeeId, dispatch]);

  const getSurveyDetail = (startDate) => {
    if (!isEmpty(followUpList)) {
      return getFollowUpProcess(
        followUpList,
        surveyProcesses,
        startDate,
        true,
        true,
      );
    }
    return <NoDataMessage />;
  };

  const handleExternalQuery = (query) => {
    setFilterQuery(query);
  };

  const surveyProcess = isLoadingFollowUpList ? (
    <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FOUR } />
  ) : (
    getSurveyDetail(startingDate)
  );

  const getCards = () => (isLoadingFollowUpCollaboratorList ? (
    <SkeletonLoader
      numberOfSkeletons={ SKELETONS_NUMBER.FOUR }
      variant={ SKELETON_VARIANT.rectangular }
      height={ 250 }
      columnWidth={ isMobile ? 12 : 3 }
    />
  ) : (
    getFormatCards(followUpCollaboratorMeta, t, history).map((item, index) => (
      <Grid item xs={ 12 } sm={ 6 } md={ 3 } key={ `card-${index}` }>
        <MultidataCard { ...item } />
      </Grid>
    ))
  ));

  return (
    <StyledSimpleDashboardContainer
      data-testid={ "collaborators-follow-up-view" }
    >
      <StyledSpacedGrid container direction={ DIRECTION.row }>
        <Grid item>
          <ViewHeaderTitle title={ t("onboardingFollowUp") } />
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <InputTextSearch
            handleInput={ handleInput }
            label={ t("searchCollaborators") }
            defaultValue={ allParams?.search || null }
          />
        </Grid>
      </StyledSpacedGrid>
      <StyledFiltersGrid container>
        <Grid item xs={ 12 } md={ 10 }>
          <Filter
            type={ FILTER_ACTIONS_TYPES.noActions }
            isLabel
            handleExternalQuery={ handleExternalQuery }
            isLoading={ isLoadingFollowUpList || isLoadingFollowUpCollaboratorList }
            isMultiple
          />
        </Grid>
      </StyledFiltersGrid>
      <StyledCardsGrid container spacing={ 2 }>
        {getCards()}
      </StyledCardsGrid>
      <Grid container spacing={ 4 }>
        <StyledTabsContainer item xs={ 12 }>
          <Tab
            tabs={ allTabs }
            onChange={ handleValueChanged }
            tabValue={ tabHandler }
          />
          <TabPanel value={ tabHandler } index={ 0 }>
            <OverView
              searchFilter={ searchFilter }
              filterQuery={ filterQuery }
            />
            {isManager && (
              <StyledTasksGrid container>
                <Grid item xs={ 12 }>
                  <ViewHeaderTitle
                    title={ t("onboardingFollowUp:tasks.titleManager") }
                    subtitle={ t("onboardingFollowUp:tasks.subtitle") }
                  />
                  <Grid container>{surveyProcess}</Grid>
                </Grid>
              </StyledTasksGrid>
            )}
          </TabPanel>
          <TabPanel value={ tabHandler } index={ 1 }>
            <OverView
              searchFilter={ searchFilter }
              filterQuery={ filterQuery }
              isFinished
            />
          </TabPanel>
        </StyledTabsContainer>
      </Grid>
    </StyledSimpleDashboardContainer>
  );
};

export default CollaboratorsFollowUp;
