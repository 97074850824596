import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import { SKELETONS_NUMBER } from "common/constants";
import {
  getValueToRender,
  getCustomStyle,
  getCustomColumn,
  getStyleByCell,
} from "./functions";
import { useStyles, StyledTableCell } from "./styles";

const SimpleTable = (props) => {
  const {
    title,
    header,
    data,
    size,
    hideHeader,
    hideBorder,
    newRow,
    isLoading,
    colSpan,
  } = props;

  const classes = useStyles();

  return (
    <TableContainer
      component={ hideBorder ? Box : Paper }
      className={ hideBorder ? classes.simpleTable : classes.table }
      data-testid={ "simpletable" }
    >
      {isLoading ? (
        <SkeletonLoader
          numberOfSkeletons={ SKELETONS_NUMBER.FIVE }
          isInline={ false }
        />
      ) : (
        <Table
          aria-label={ "simple table" }
          size={ size && size }
          className={ size && classes.paddingSmall }
        >
          {data && (
            <TableHead className={ classes.bgHead }>
              {title && (
                <TableRow>
                  <StyledTableCell colSpan={ colSpan || data.length }>
                    <Typography variant={ "h5" }>{title}</Typography>
                  </StyledTableCell>
                </TableRow>
              )}
              {!hideHeader && (
                <TableRow>
                  {header
                    && header.map((headCell) => (
                      <StyledTableCell
                        key={ headCell.id }
                        className={ clsx(
                          getCustomStyle(headCell),
                          getCustomColumn(headCell)
                        ) }
                      >
                        {headCell.label}
                      </StyledTableCell>
                    ))}
                </TableRow>
              )}
            </TableHead>
          )}
          <TableBody>
            {data
              && !_.isEmpty(data)
              && data.map((item, index) => (
                <TableRow key={ index }>
                  {header.map((element, index) => {
                    const valueToRender = _.get(item, element.id);
                    return element.customRender ? (
                      <StyledTableCell
                        key={ index }
                        className={ getStyleByCell(element, item) }
                      >
                        {element.customRender(item)}
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell
                        key={ index }
                        className={ getCustomStyle(element) }
                      >
                        { getValueToRender(valueToRender, element.id) }
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              ))}
            {newRow && (
              <TableRow key={ "newField" }>
                {header
                  && header.map((item, index) => (
                    <StyledTableCell key={ index }>{item.newCustomRender}</StyledTableCell>
                  ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {!isLoading && _.isEmpty(data) && <NoDataMessage />}
    </TableContainer>
  );
};

SimpleTable.propTypes = {
  title: PropTypes.string,
  header: PropTypes.array.isRequired,
  data: PropTypes.array,
  size: PropTypes.string,
  hideHeader: PropTypes.bool,
  hideBorder: PropTypes.bool,
  newRow: PropTypes.bool,
  isLoading: PropTypes.bool,
  colSpan: PropTypes.number,
};
export default SimpleTable;
