import styled from "styled-components";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDialogActions = styled(DialogActions)`
  margin-top: 20px;
  padding: 0px 24px 25px;
`;

export const StyledLabel = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
`;

export const StyledDialogContent = styled(DialogContent)`
  overflow-y: hidden;
`;
