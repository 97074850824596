import {
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import gt from "lodash/gt";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import VisibilityIcon from "@mui/icons-material/Visibility";
/* import CreateIcon from "@mui/icons-material/Create"; StandBy: icon for "edit_candidate" */
import AlarmIcon from "@mui/icons-material/Alarm";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuPopup from "components/MenuPopup";
import HorizontalMenu from "components/HorizontalMenu";
import AlertModal from "components/AlertModal";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import AlertAutohide from "components/AlertAutohide";
import TooltipIcon from "components/TooltipIcon";
import TableGrid from "components/TableGrid";
import ProfileImageDetail from "components/ProfileImageDetail";
import SkeletonLoader from "components/SkeletonLoader";
import GridItems from "components/GridItems";
import { STATE, SIZE, OBJECT_KEYS, ROLES, MAX_PER_PAGE, ROWS_PER_PAGE, GRID_ITEMS_TYPES } from "common/constants";
import {
  isMainAdmin,
  isValidScope,
  isAdminNala,
  isValidRole,
  formatDate,
} from "common/utils";
import { SessionContext } from "modules/session/context";
import {
  getList as getCandidates,
  update as updateCandidate,
  sendReminder as sendReminderCandidate,
  sendFeedback as sendFeedbackCandidate,
  resetStateProcess,
  resetState,
} from "redux/actions/candidateActions";
import { getListSearch as getCollaborators } from "redux/actions/collaboratorActions";
import FeedbackForm from "../FeedbackForm";
import ReminderForm from "../ReminderForm";
import CandidateHireFormModal from "./CandidateHireFormModal";
import { getIcon, getColor, createModal } from "./functions";
import {
  ChipStyled,
  useStyles,
} from "./styles";

const CandidatesTable = (props) => {
  const { candidates, isLoading } = props;
  const { t } = useTranslation(["candidates", "common"]);
  const {
    state: { user },
  } = useContext(SessionContext);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const dispatch = useDispatch();
  const companyId = user?.company?.id;
  const [hireModalOpen, setHireModalOpen] = useState(false);
  const [candidateId, setCandidateId] = useState(null);

  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: "",
    type: "",
  });

  const {
    successProcess,
    isLoadingProcess,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm"),
  );
  const stylesProps = { isMobile };
  const classes = useStyles(stylesProps);

  const openCloseModal = () => {
    setModal(!modal);
  };

  const sendFeedback = (candidateId, values) => {
    const message = { message: values.message };
    dispatch(sendFeedbackCandidate(candidateId, message));
    setModal(false);
  };

  /**
   * Change status of the candidate
   * @param candidateId
   * @param stateTransition
   */
  const markAs = (candidateId, stateTransition) => {
    const state = { candidate: { state_transition: stateTransition } };
    markAsState(candidateId, state);
  };

  const sendReminder = (candidateId, values) => {
    const message = { message: values.message };
    dispatch(sendReminderCandidate(candidateId, message));
    setModal(false);
  };

  const markAsState = (candidateId, state) => {
    dispatch(updateCandidate(state, candidateId));
    setModal(false);
  };

  const viewModal = (
    title,
    children,
    text = "",
    approved = "",
    cancel = "",
    onClick,
  ) => {
    setModalData(createModal(title, text, approved, cancel, onClick, children));
    openCloseModal();
  };

  const getChipStatus = (rowData) => (
    <ChipStyled
      icon={ getIcon(rowData.state) }
      bgcolor={ getColor(rowData.state) }
      color={ "secondary" }
      label={ t(`candidates:status.${rowData.state}`) }
    />
  );

  const isNowDateGreater = (date) => gt(new Date(date).getTime(), new Date().getTime());

  const markAsHireModal = (candidateId) => {
    setCandidateId(candidateId);
    setHireModalOpen(true);
  };

  const onCloseHireModal = () => {
    setHireModalOpen(false);
  };

  const getCandidateProfile = (rowData) => (
    <ProfileImageDetail collaborator={ rowData } type={ "candidate" } isShortVersion />
  );

  const CANDIDATE_TABLE_KEYS = [
    {
      id: "full_name",
      label: t("candidates:table.table-head.name"),
      isParent: true,
      customRender: (rowData) => getCandidateProfile(rowData),
    },
    {
      id: "position_name",
      label: t("candidates:table.table-head.position"),
      parent: "full_name",
    },
    {
      id: "city.name",
      label: t("candidates:table.table-head.city"),
      parent: "full_name",
      customRender: (rowData) => rowData?.city?.name,
    },
    {
      id: "state",
      label: t("candidates:table.table-head.status"),
      customRender: (rowData) => getChipStatus(rowData),
    },
    {
      id: "starting_date",
      label: t("candidates:table.table-head.admission_date"),
      customRender: (rowData) => (
        rowData?.starting_date
          && formatDate(rowData.starting_date)
      ),
    },
    {
      id: "actions",
      label: t("candidates:table.table-head.action"),
      noSortable: true,
      customRender: (rowData) => {
        const NO_OPTIONS = [
          {
            title: t("common:common.no_options"),
          },
        ];
        const ACTIONS = [
          {
            title: t("candidates:actions.view_profile"),
            icon: <VisibilityIcon fontSize={ SIZE.small } />,
            onClick: () => {
              history.push({
                pathname: "/onboarding_profile",
                search: `?candidate=${ rowData.id}`,
              });
            },
          },
          /* { // Stand-by: action not enable yet
            title: t("candidates:actions.edit_candidate"),
            icon: <CreateIcon fontSize={SIZE.small} />,
            onClick: () => {},
          }, */
          {
            title: t("candidates:actions.send_reminder"),
            icon: <AlarmIcon fontSize={ SIZE.small } />,
            onClick: () => {
              viewModal(
                t("candidates:actions.send_reminder"),
                <ReminderForm id={ rowData.id } action={ sendReminder } />,
              );
            },
            isDisabled:
              isEqual(STATE.DESISTED, rowData.state)
              || isEqual(STATE.HIRED, rowData.state)
              || isEqual(STATE.DATA_FILLED, rowData.state),
          },
          {
            title: t("actions.send_feedback"),
            icon: <ThumbsUpDownIcon fontSize={ SIZE.small } />,
            onClick: () => {
              viewModal(
                t("candidates:actions.send_feedback"),
                <FeedbackForm id={ rowData.id } action={ sendFeedback } />,
              );
            },
            isDisabled: !isEqual(STATE.DATA_FILLED, rowData.state),
          },
          {
            title: t("candidates:actions.mark_as_hired"),
            icon: <CheckCircleIcon fontSize={ SIZE.small } />,
            tooltip: isNowDateGreater(rowData.starting_date)
              && !isEqual(STATE.DESISTED, rowData.state) && (
              <TooltipIcon
                title={ t("candidates:actions.disabled_mark_as_hired_by_date") }
                isHelpIcon
              />
            ),
            onClick: () => markAsHireModal(rowData.id),
            isDisabled:
              (isNowDateGreater(rowData.starting_date)
                && !isEqual(STATE.DESISTED, rowData.state))
              || !(
                isEqual(STATE.SELECTED, rowData.state)
                || isEqual(STATE.DATA_FILLED, rowData.state)
              ),
          },
          {
            title: t("candidates:actions.mark_as_desisted"),
            icon: <HighlightOffIcon fontSize={ SIZE.small } />,
            onClick: () => {
              viewModal(
                t("candidates:alertMessage.title"),
                "",
                t("candidates:alertMessage.text_delete"),
                t("candidates:alertMessage.approved"),
                t("candidates:alertMessage.cancel"),
                () => {
                  markAs(rowData.id, STATE.DESIST);
                },
              );
            },
            isDisabled: isEqual(STATE.DESISTED, rowData.state),
          },
        ];
        const organizationUnitIds = rowData?.organization_units?.flat().map((unit) => unit.id);
        const hasValidScope = isValidScope(user, {
          countries: [rowData.country?.id],
          [OBJECT_KEYS.organizationUnits]: organizationUnitIds,
        });
        const isValidToActions = hasValidScope && (isAdminNala(user?.userCookies)
        || isValidRole(user?.userCookies, ROLES.ONBOARDING_ADMIN)
        || (isMainAdmin(user?.userCookies)));
        const ACTIONS_MENU = isValidToActions ? ACTIONS : NO_OPTIONS;

        return isMobile
          ? <HorizontalMenu menuItems={ ACTIONS_MENU } />
          : <MenuPopup items={ ACTIONS_MENU } />;
      },
    },
  ];

  const showMobileView = () => (
    isLoading ? (
      <SkeletonLoader
        numberOfSkeletons={ 3 }
        variant={ "rectangular" }
        columnWidth={ 12 }
        height={ 125 }
        padding={ "8px 0px" }
        hasPadding
      />
    ) : (
      <GridItems
        typeElement={ GRID_ITEMS_TYPES.cardProfile }
        items={ candidates }
        formattedFields={ CANDIDATE_TABLE_KEYS }
        withSearch
        isVertical
        gap={ 15 }
      />
    ));

  useEffect(() => {
    dispatch(getCandidates());
  }, [dispatch, companyId]);

  const closeAlert = (title, message, type) => {
    setAlert({
      open: false,
      title,
      message,
      type,
    });
  };

  const resetData = useCallback(() => {
    onCloseHireModal();
    dispatch(resetStateProcess());
    dispatch(resetState());
    dispatch(getCandidates());
    dispatch(getCollaborators());
    toast(
      MESSAGE_TYPES.success,
      handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
    );
  }, [t, dispatch]);

  useEffect(() => {
    if (successProcess) {
      resetData();
    }
  }, [successProcess, resetData]);

  return (
    <div className={ classes.root } data-testid={ "candidates-table-container" }>
      <AlertAutohide
        open={ alert.open }
        onClose={ () => closeAlert(alert.title, alert.message, alert.type) }
        title={ alert.title }
        message={ alert.message }
        type={ alert.type }
      />
      {modalData && (
        <AlertModal
          title={ modalData.title }
          text={ modalData.text }
          textDisagree={ modalData.cancel }
          textAgree={ modalData.approved }
          onClick={ modalData.onClick }
          open={ modal }
          isLoading={ isLoadingProcess }
          handleClose={ openCloseModal }
        >
          {modalData.children}
        </AlertModal>
      )}
      {isMobile ? (
        showMobileView()
      ) : (
        <TableGrid
          rows={ candidates }
          id={ "candidates-table-grid" }
          header={ CANDIDATE_TABLE_KEYS }
          searchPlaceHolder={ t("candidates:search") }
          paginationOptions={ {
            rowText: t("candidates:row"),
            maxPerPage: MAX_PER_PAGE,
            rowsRange: ROWS_PER_PAGE,
          } }
          isLoading={ isLoading }
          searchBy={ "full_name" }
        />
      )}
      {candidateId
        && (
          <CandidateHireFormModal
            candidateId={ candidateId }
            isOpen={ hireModalOpen }
            onClose={ onCloseHireModal }
          />
        )}
    </div>
  );
};

CandidatesTable.propTypes = {
  candidates: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

export default CandidatesTable;
