import PropTypes from "prop-types";
import InputTextSearch from "components/InputTextSearch";
import TableSkeletonLoader from "../TableSkeletonLoader";
import {
  StyledSearchContainer,
  StyledDownLoad,
} from "../../styles";

const TableMobile = (props) => {
  const {
    isLoading,
    handleSearch,
    rightOptions,
    contentTable,
  } = props;
  return (
    <>
      <StyledSearchContainer isMobile>
        {handleSearch && <InputTextSearch handleInput={ handleSearch } />}
        {rightOptions && <StyledDownLoad>{ rightOptions }</StyledDownLoad>}
      </StyledSearchContainer>
      {isLoading ? (
        <TableSkeletonLoader cols={ 1 } rows={ 3 } />
      ) : contentTable()}
    </>
  );
};

TableMobile.propTypes = {
  contentTable: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleSearch: PropTypes.func,
  rightOptions: PropTypes.any,
};

TableMobile.defaultProps = {
  isLoading: false,
  handleSearch: null,
  rightOptions: null,
};

export default TableMobile;
