import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { VARIANT } from "common/constants";
import { isNull } from "common/helpers";
import { getPercent } from "common/utils";
import { getIconByNPSScore } from "views/NalaAnalytics/functions";
import CardResult from "../CardIconResult";
import AttritionRiskTable from "../AttritionRiskTable";
import {
  StyledGridContainer,
  StyledSubtitle,
  StyledAttritionRiskTable,
} from "./styles";

const CardDetail = (props) => {
  const {
    t, data, card, query,
  } = props;
  return (
    <StyledGridContainer container spacing={ 1 }>
      <Grid item xs={ 6 }>
        <StyledSubtitle variant={ VARIANT.h6 }>{ t("nalaAnalytics:nps") }</StyledSubtitle>
        <CardResult
          title={ t("nalaAnalytics:nps") }
          color={ card.textColor }
          backgroundColor={ card.cardColor }
          icon={ isNull(data?.nps) ? null : getIconByNPSScore(data?.nps)?.icon }
          result={ isNull(data?.nps) ? null : getPercent(data?.nps) }
          sizeTitle={ 30 }
          sizeImage={ 70 }
          sizeResult={ 60 }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <StyledSubtitle variant={ VARIANT.h6 }>
          {t("nalaAnalytics:lossRisk")}
        </StyledSubtitle>
        <StyledSubtitle variant={ VARIANT.h6 }>
          {t("common:common.collaborators").toLowerCase()}
        </StyledSubtitle>
        <StyledAttritionRiskTable>
          <AttritionRiskTable { ...props } query={ query } />
        </StyledAttritionRiskTable>
      </Grid>
    </StyledGridContainer>
  );
};

CardDetail.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
};

export default CardDetail;
