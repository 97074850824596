import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import constants from "styledComponents/constants";
import { StyledInfoContainer, StyledTitle } from "../../styles";

const SalaryInfoDetail = (props) => {
  const {
    icon, iconText, detail, hasEditButton,
  } = props;

  return (
    <Grid item xs={ hasEditButton ? 10 : 12 }>
      <StyledInfoContainer>
        <StyledTitle weight={ constants.fontConstants.bold }>
          {icon}
          {iconText}
        </StyledTitle>
        <p>{detail}</p>
      </StyledInfoContainer>
    </Grid>
  );
};

SalaryInfoDetail.propTypes = {
  icon: PropTypes.element.isRequired,
  iconText: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  hasEditButton: PropTypes.bool,
};

SalaryInfoDetail.defaultProps = {
  hasEditButton: false,
};

export default SalaryInfoDetail;
