import { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { Handle, Position } from "reactflow";
import { VARIANT } from "common/constants";
import defaultImg from "assets/images/general/avatar.png";
import {
  StyledCard,
  StyledContent,
  StyledAvatar,
  StyledFullName,
  StyledTitle,
  StyledButton,
  StyledCriticalPosition,
  AvatarIconContainer,
  StyledCriticalPositionIcon,
  StyledSuccessionIcon,
} from "./styles";

const NodeCard = (props) => {
  const {
    data: {
      item,
      childrenCount,
      childrenHidden,
      onToggleChildren,
      parentId,
      onOpenModal,
    },
    isConnectable,
  } = props;
  const { t } = useTranslation(["collaborators"]);

  return (
    <>
      {parentId !== "0" && <Handle type={ "target" } position={ Position.Top } isConnectable={ isConnectable } />}
      <StyledCard
        active={ !childrenHidden ? 1 : 0 }
        data-testid={ "nodecard-view-component" }
        hasCriticalPosition={ item?.hasCriticalPosition }
      >
        <StyledContent >
          <AvatarIconContainer>
            <StyledAvatar
              alt={ item.name }
              src={ item?.avatar || defaultImg }
            />
          </AvatarIconContainer>
          <StyledCriticalPosition>
            <StyledFullName
              variant={ VARIANT.text }
              onClick={ onOpenModal }
            >
              { item.isASuccessor
              && (
                <Tooltip title={ t("successions:plan.title") } placement={ "top" }>
                  <StyledSuccessionIcon />
                </Tooltip>
              )}
              { item.name }
            </StyledFullName>
          </StyledCriticalPosition>
          <StyledCriticalPosition>
            <StyledTitle>
              { item?.title }
            </StyledTitle>
            {item?.hasCriticalPosition
            && (
              <Tooltip title={ t("collaborators:key_position.title") } placement={ "top" }>
                <StyledCriticalPositionIcon />
              </Tooltip>
            )}
          </StyledCriticalPosition>
          <div>
            <StyledTitle>{ item?.department }</StyledTitle>
            <StyledTitle bold={ 1 }>{ item?.country }</StyledTitle>
          </div>
          {childrenCount > 0 && (
            <StyledButton
              active={ !childrenHidden ? 1 : 0 }
              variant={ VARIANT.contained }
              onClick={ onToggleChildren }
            >
              { `${childrenCount} ${t("title")}` }
            </StyledButton>
          )}
        </StyledContent>
      </StyledCard>
      <Handle type={ "source" } position={ Position.Bottom } isConnectable={ isConnectable } />
    </>
  );
};

NodeCard.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      department: PropTypes.string,
      country: PropTypes.string,
      hasCriticalPosition: PropTypes.bool,
      isASuccessor: PropTypes.bool,
    }).isRequired,
    childrenCount: PropTypes.number.isRequired,
    childrenHidden: PropTypes.bool.isRequired,
    parentId: PropTypes.string,
    onToggleChildren: PropTypes.func,
    onOpenModal: PropTypes.func,
  }).isRequired,
  isConnectable: PropTypes.bool,
  selected: PropTypes.bool,
};

NodeCard.defaultProps = {
  isConnectable: false,
  selected: false,
};

export default memo(NodeCard);
