import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  column-gap: 20px;
`;

export const StyledCard = styled(Card)`
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  box-shadow: ${palette.boxShadow.card2};
  cursor: pointer;
`;

export const StyledContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  height: 100%;
  text-align: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin: 0 auto;
  width: 70px;
  height: 70px;
`;
export const StyledFullName = styled(Typography)`
  color: ${palette.text.secondaryTitle};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
`;

export const StyledTitle = styled(Typography)`
  font-size: 12px;
  line-height: 12.5px;
  font-weight: ${(props) => (props.bold === 1 ? 700 : 400)};
  text-transform: uppercase;
`;

export const AvatarIconContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 auto;
`;

export const StyledCriticalPosition = styled.div`
  position: absolute;
  bottom: 0;
  right: 18px;
  background-color: ${palette.background.lightGrey};
  border-radius: 50%;
  transform: translate(50%, 50%);
  width: 18px;
  height: 18px;
  display: flex;
  padding: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
