import styled from "styled-components";
import palette from "theme/palette";
import Grid from "@mui/material/Grid";

export const StyledTitleContainer = styled.div`
  text-align: center;
  position: relative;
  height: 90%;
`;

export const StyledTitleLeft = styled.h5`
  font-weight: 700;
  font-size: 13px;
  bottom: 42%;
  left: 10px;
  position: absolute;
  writingMode: vertical-rl;
  transform: rotate(-90deg);
  width: 120px;
  height: 40px;
  @media (max-width: 1100px) {
    left: 10px;
  }
  @media (max-width: 970px) {
    left: 0;
  }
  @media (max-width: 570px) {
    left: -30px;
    width: 140px;
    font-size: 12px;
  }
  @media (max-width: 350px) {
    height: 70px;
    bottom: 30%;
  }
`;

export const StyledTitleBottom = styled.h5`
  font-weight: 700;
  font-size: 14px;
`;

export const StyledBoxTitle = styled.text`
  font-size: 2px;
  font-weight: 700;
`;

export const StyledRectBox = styled.rect`
  stroke: ${ (props) => (props.isActive ? palette.background.purple : palette.white) };
  stroke-width: ${(props) => props.border };
  cursor: pointer;
`;

export const StyledGridContainer = styled(Grid)`
  position: relative;
`;

export const StyledSvgContainer = styled.svg`
  width: 100%;
  outline: none;
`;

export const StyledLine = styled.line`
  stroke: ${ (props) => (props.isActive ? palette.background.purple : palette.white) };
  stroke-width: 1;
`;
