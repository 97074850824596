import PropTypes from "prop-types";
import { StyledHelpIcon, TooltipStyled, useStyles } from "./styles";

const TooltipIcon = (props) => {
  const { title, element, children, open, placement, isHelpIcon } = props;
  const classes = useStyles();
  return (
    <TooltipStyled
      title={ title || children }
      arrow
      open={ open }
      placement={ placement }
    >
      {element ? (
        element
      ) : isHelpIcon ? (
        <StyledHelpIcon />
      ) : (
        <span className={ classes.emptySpan }>""</span>
      )}
    </TooltipStyled>
  );
};
TooltipIcon.propTypes = {
  title: PropTypes.string,
  element: PropTypes.node,
  children: PropTypes.node,
};
export default TooltipIcon;
