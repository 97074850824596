import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { SearchInput } from "components";
import { VARIANT } from "common/constants";
import { bluMapper } from "../../../../common/adapters/blu-adapter";
import { UserAPI } from "../../../../api";
import Button from "../../../../components/Button";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const UserCompanyListToolbar = function (props) {
  const { className, ...rest } = props;
  const fileReader = new FileReader();
  const handleFile = ({ target }) => {
    // we need use fileReader of js and read data as text
    fileReader.readAsText(target.files[0]);
    // when the file is load
    fileReader.onload = (e) => {
      // we use mapper in this case we have just one mapper but in the future
      // we need check company of the user logged and use the mapper of the company
      const data = bluMapper(JSON.parse(e.target.result));
      // call to the de API sending a array of the data
      UserAPI.addUsers(data)
        .then((_) => alert("Successfull"))
        .catch((_) => alert("Error"));
    };
  };

  const classes = useStyles();

  return (
    <div
      { ...rest }
      className={ clsx(classes.root, className) }
    >
      <div className={ classes.row }>
        <span className={ classes.spacer } />
        <Button className={ classes.importButton } component={ "label" }>
          {"Import"}
          <input
            type={ "file" }
            style={ { display: "none" } }
            onChange={ handleFile }
          />
        </Button>
        <Button className={ classes.exportButton }>{"Export"}</Button>
        <Button
          variant={ VARIANT.contained }
        >
          {"Add user"}
        </Button>
      </div>
      <div className={ classes.row }>
        <SearchInput
          className={ classes.searchInput }
          placeholder={ "Search user" }
        />
      </div>
    </div>
  );
};

UserCompanyListToolbar.propTypes = {
  className: PropTypes.string,
};

export default UserCompanyListToolbar;
