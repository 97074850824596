import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import theme from "theme";
import palette from "theme/palette";
import Button from "components/Button";

export const useStyles = makeStyles((localTheme) => ({
  paper: {
    width: "100%",
    marginBottom: localTheme.spacing(2),
  },
  content: {
    marginTop: localTheme.spacing(2),
  },
  skeleton: {
    width: 250,
    height: 450,
    transformOrigin: "0 45%",
    transform: "scale(1, 0.9)",
  },
  textSkeleton: {
    marginLeft: 25,
  },
}));

export const StyledMainContainer = styled.div`
  padding: ${theme.spacing(3)};
  .MuiTextField-root {
    width: 100%;
  }
`;

export const StyledTitle = styled(Typography)`
  padding-top: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(2)};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: ${palette.black};
`;

export const StyledTitleCard = styled(Typography)`
  margin-bottom: ${theme.spacing(2)};
  margin-top: ${theme.spacing(3)};
`;

export const StyledButton = styled(Button)`
  margin-top: 2%;
`;
