import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import TableCell from "@mui/material/TableCell";
import palette from "theme/palette";

export const useStyles = makeStyles((localTheme) => ({
  bgHead: {
    backgroundColor: localTheme.palette.white,
  },
  table: {
    padding: localTheme.spacing(0),
  },
  simpleTable: {
    paddingLeft: localTheme.spacing(0.5),
    paddingRight: localTheme.spacing(0.5),
    "& td": {
      border: 0,
      fontSize: "14px",
    },
    "& tbody": {
      display: "initial",
    },
  },
  paddingSmall: {
    "& td, th": {
      padding: localTheme.spacing(1),
      fontSize: "14px",
      borderBottom: localTheme.palette.border.simpleThin,
    },
    "& td:last-child": {
      paddingRight: localTheme.spacing(2),
    },
    "& td:first-child": {
      paddingLeft: localTheme.spacing(2),
    },
  },
}));

export const StyledTableCell = styled(TableCell)`
  font-size: 14px;
  border-bottom: ${palette.border.simpleThin};
`;
