import inRange from "lodash/inRange";
import {
  INDEX,
  ALIGN_ITEMS,
  PLACEMENT_LENGTH,
  SCALE_COUNT,
} from "common/constants";
import { isEqual } from "common/helpers";

export const getLabelAnswer = (id, label, scale, firstIdScale, lastIdScale, isMobile = false) => {
  let answerLabel = label;
  const numericChar = isMobile ? "" : "_";
  if (scale?.indices?.length > SCALE_COUNT.min) {
    answerLabel = isEqual(id, firstIdScale) || isEqual(id, lastIdScale) ? label : numericChar;
  }
  return answerLabel;
};

export const getLabelPlacement = (scale) => {
  let placement = ALIGN_ITEMS.bottom;
  if (inRange(
    scale?.indices[INDEX.zero]?.answer?.length,
    PLACEMENT_LENGTH.min,
    PLACEMENT_LENGTH.max,
  )
  ) {
    placement = ALIGN_ITEMS.top;
  }
  if (scale?.indices[INDEX.zero]?.answer?.length > PLACEMENT_LENGTH.max) {
    placement = ALIGN_ITEMS.left;
  }
  return placement;
};

export const getLabelClassName = (labelType, label) => {
  const classNames = {
    _: "opacityLabel",
    [ALIGN_ITEMS.top]: "starsLabel",
    [ALIGN_ITEMS.left]: "startFlex",
    empty: "",
  };

  return classNames[label] || classNames[labelType] || classNames.empty;
};
