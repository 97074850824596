import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { VARIANT } from "common/constants";
import { StyledLinearProgress, StyledPercent } from "../../styles";

const CardProgress = ({ participation, isModal }) => {
  const calculatedPercentage = participation.total && participation.max
    ? ((participation.total * 100) / participation.max).toFixed(2)
    : "0";

  return (
    <Box mt={ 1 }>
      <StyledPercent>{`${calculatedPercentage}%`}</StyledPercent>
      <StyledLinearProgress
        variant={ VARIANT.determinate }
        value={ calculatedPercentage }
        isModal={ isModal }
      />
    </Box>
  );
};

CardProgress.propTypes = {
  participation: PropTypes.shape({
    total: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  isModal: PropTypes.bool.isRequired,
};

export default CardProgress;
