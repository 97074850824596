import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StaticProgressBar from "components/StaticProgressBar";
import { SIZE } from "common/constants";
import palette from "theme/palette";
import {
  StyledTableRow,
  StyledTableCell,
  StyledIconButton,
  StyledChip,
  StyledLabel,
  StyledProgressContainer,
} from "./styles";

const CollapsibleRow = (props) => {
  const {
    id,
    label,
    status,
    goalsCount,
    progress,
    open,
    toggleOpen,
    isYear = false,
  } = props;
  const { t } = useTranslation("goals");
  const year = isYear ? 1 : 0;

  return (
    <StyledTableRow year={ year }>
      <StyledTableCell onClick={ () => toggleOpen(id) }>
        <StyledIconButton year={ year } size={ SIZE.small } role={ "button" }>
          {open[id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </StyledIconButton>
        <StyledChip
          label={ label }
          year={ year }
          active={ open[id] ? 1 : 0 }
        />
        <StyledLabel bold={ status === t("generalStatus.inProgress") ? 1 : 0 }>{status}</StyledLabel>
      </StyledTableCell>
      <StyledTableCell goals={ 1 }>
        {goalsCount && <StyledLabel small>{`${goalsCount} ${t("title")}`}</StyledLabel>}
        <StyledProgressContainer>
          <StaticProgressBar
            percentage={ progress }
            color={ palette.linearProgress.completeLighter }
            isPrimaryColor={ false }
            labelCustomColor={ palette.text.secondaryParagraph }
          />
        </StyledProgressContainer>
      </StyledTableCell>
    </StyledTableRow>
  );
};

CollapsibleRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string,
  goalsCount: PropTypes.number,
  progress: PropTypes.number.isRequired,
  open: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  isYear: PropTypes.bool,
};

export default CollapsibleRow;
