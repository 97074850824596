import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty, isEqual, isNotValid } from "common/helpers";
import { ATTRITION_AXES_MAX_FOR_RECOMMENDATION, INDEX } from "common/constants";
import { getProcessNameById, replacePath } from "common/utils";
import useComplexState from "hooks/utils/useComplexState";
import { getList as getAttritionCollaboratorChart } from "redux/actions/collaborators/attritionActions";
import { getList as getPerformanceProcess } from "redux/actions/performance/performanceActions";
import { getCollaboratorPerformanceEvolution } from "redux/actions/surveyProcessesActions";
import { RISK_CATEGORY } from "views/TalentDrain/functions";
import { getSelectedModal } from "../functions/profile";
import localRoutes from "../functions/routes";

export const useLossRiskHook = (externalHooks, internalHooks) => {
  const { dispatch, location, language } = externalHooks;
  const {
    collaborator,
    isValidToShowAttrition,
  } = internalHooks;

  const [lossRiskStates, setLossRiskStates] = useComplexState({
    isModalOpen: {
      lossRisk: false,
    },
    tabHandler: INDEX.zero,
  });
  const isLossRiskModalOpen = isEqual(location.pathname, localRoutes.lossRisk)
    && isValidToShowAttrition;

  const {
    list: collaboratorAttrition,
    loadingList: isLoadingCollaboratorAttrition,
  } = useSelector(({ collaboratorAttritionReducer }) => collaboratorAttritionReducer);

  const {
    collaboratorPerformanceEvolution: performanceEvolution,
    loadingCollaboratorPerformanceEvolution: loadingPerformanceEvolution,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const {
    list: performanceProcessesList,
    loadingList: isLoadingPerformanceProcessesList,
  } = useSelector(({ performanceReducer }) => performanceReducer);

  useEffect(() => {
    if (!isEqual(location.pathname, localRoutes.root)) {
      getSelectedModal(location.pathname).then((resolve) => {
        const { path, key } = resolve;
        replacePath(path);
        setLossRiskStates({
          isModalOpen: {
            ...lossRiskStates.isModalOpen,
            [key]: true,
          },
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (collaborator && isValidToShowAttrition) {
      const query = { q: { employee_id_eq: collaborator.id } };
      dispatch(getAttritionCollaboratorChart(collaborator.id, query));
    }
  }, [dispatch, collaborator, isValidToShowAttrition]);

  useEffect(() => {
    if (isLossRiskModalOpen && collaborator) {
      dispatch(getPerformanceProcess());
      dispatch(getCollaboratorPerformanceEvolution(collaborator.id));
    }
  }, [dispatch, collaborator, isLossRiskModalOpen]);

  const handleValueChangedTabLossRisk = (value) => {
    setLossRiskStates({ tabHandler: value });
  };

  const getAttritionEvaluations = useCallback(() => {
    let evaluations = [];
    if (!isLoadingPerformanceProcessesList && !loadingPerformanceEvolution && performanceEvolution && performanceProcessesList) {
      evaluations = performanceEvolution.filter((evolution) => evolution?.id).map((item) => ({
        value: item.id,
        label: getProcessNameById(performanceProcessesList, item.id, language),
      }));
    }
    return evaluations.map((evaluation) => evaluation.label);
  }, [
    loadingPerformanceEvolution,
    isLoadingPerformanceProcessesList,
    performanceEvolution,
    performanceProcessesList,
    language,
  ]);

  const isValidForTheRecommendation = () => {
    let isValid = false;
    const riskCategory = collaboratorAttrition?.responseList[INDEX.zero]?.risk_category;
    const averageResults = collaboratorAttrition?.averages[INDEX.zero]?.values;
    if (riskCategory && !isEqual(riskCategory, RISK_CATEGORY.low.key) && !isEmpty(averageResults)) {
      const averageResultsArray = Object.values(averageResults);
      isValid = averageResultsArray.every(
        (item) => item > ATTRITION_AXES_MAX_FOR_RECOMMENDATION,
      );
    }
    return isValid;
  };

  return {
    lossRiskStates,
    attritionStates: {
      data: collaboratorAttrition || { responseList: [] },
      collaborator: collaboratorAttrition?.responseList[INDEX.zero],
      isLoading: isLoadingCollaboratorAttrition || (
        !isNotValid(collaboratorAttrition?.responseList)
        && !isEqual(collaboratorAttrition?.responseList[INDEX.zero]?.employee_id, collaborator?.id)
      ),
    },
    attritionEvolutionStates: {
      performanceEvolution,
      loadingPerformanceEvolution,
    },
    attritionProcessStates: {
      performanceProcessesList,
      isLoadingPerformanceProcessesList,
    },

    handleValueChangedTabLossRisk,
    getAttritionEvaluations,
    isValidForTheRecommendation,
  };
};
