import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StaticProgressBar from "components/StaticProgressBar";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import { INDEX, ROUND, SKELETONS_NUMBER } from "common/constants";
import { getPercent } from "common/utils";
import { isEmpty } from "common/helpers";
import constants from "styledComponents/constants";
import NalaBot from "assets/images/general/nala_bot_normal.svg";
import {
  StyledSubTitle,
  StyledDivider,
  StyledFixedContainer,
  StyledGoalContainer,
  StyledLink,
  StyledContentText,
  StyledSectionContainer,
} from "./styles";

const GoalsInfo = (props) => {
  const {
    t, isOwnProfile, goalsStates, collaborator,
  } = props;
  const { list, isLoading } = goalsStates;

  const initialRender = isLoading ? (
    <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.SIX } isInline={ false } />
  ) : (
    <>
      <Grid item xs={ 12 }>
        {isEmpty(list) ? (
          isOwnProfile && (
            <StyledSubTitle weight={ constants.fontConstants.bold }>
              {t("profile:goalsCard.noGoals")}
            </StyledSubTitle>
          )
        ) : (
          <StyledSubTitle weight={ constants.fontConstants.bold }>
            {t(`profile:currently${ isOwnProfile ? "YouHave" : "Have" }`)}
            {list?.length}
            {" "}
            {t("profile:goals").toLowerCase()}
            {":"}
          </StyledSubTitle>
        )}
      </Grid>
      <Grid item xs={ 12 }>
        {isEmpty(list) ? (
          <NoDataMessage
            iconImage={ NalaBot }
            message={ (
              <StyledContentText>
                <Typography>{ t(`profile:goalsCard.startTitle${isOwnProfile ? "" : "ForManager"}`) }</Typography>
                { isOwnProfile && <Typography>{t("profile:goalsCard.startSubtitle")}</Typography> }
                <StyledLink to={ `/goals?employee-name=${collaborator.full_name}` }>
                  { t("profile:goalsCard.startNow") }
                </StyledLink>
              </StyledContentText>
            ) }
          />
        ) : list?.slice(INDEX.zero, INDEX.five)?.map((item) => (
          <StyledGoalContainer key={ item.description }>
            <p>{item.description}</p>
            <StaticProgressBar
              percentage={ getPercent(
                item.completion_percentage,
                false,
                ROUND.max,
              ) }
              isNotShowLabel
              isPrimaryColor
            />
            <StyledDivider />
          </StyledGoalContainer>
        ))}
      </Grid>
      <Grid item xs={ 12 }>
        {!isEmpty(list) && (
          <StyledFixedContainer>
            <StyledLink to={ `/goals?employee-name=${collaborator.full_name}` }>
              { t("common:common.viewMore") }
            </StyledLink>
          </StyledFixedContainer>
        )}
      </Grid>
    </>
  );

  return (
    <StyledSectionContainer>
      <Grid container>
        { initialRender }
      </Grid>
    </StyledSectionContainer>
  );
};

GoalsInfo.propTypes = {
  t: PropTypes.func.isRequired,
  goalsStates: PropTypes.shape({
    list: PropTypes.array,
    isLoading: PropTypes.bool,
  }).isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  collaborator: PropTypes.shape({
    full_name: PropTypes.string,
  }).isRequired,
};

export default GoalsInfo;
