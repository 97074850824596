import includes from "lodash/includes";
import startCase from "lodash/startCase";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Archive from "@mui/icons-material/Archive";
import Assignment from "@mui/icons-material/Assignment";
import Calendar from "@mui/icons-material/Today";
import {
  ROLES, SIZE, INDEX, VARIANT,
} from "common/constants";
import { isAdmin as isRolAdmin, formatDate } from "common/utils";
import { isEqual } from "common/helpers";
import MenuPopup from "components/MenuPopup";
import { StyledTitleWithSubtitle, StyledLink } from "components/TablePagination/styles";
import DeleteDocumentModal from "../components/Documents/components/DeleteDocumentModal";
import EditDocumentFormModal from "../components/Documents/components/EditDocumentFormModal";
import { StyledFile } from "../styles";

export const getUserData = (searchBy, user, reducerToUse) => (includes(searchBy, ROLES.CANDIDATE)
    || isEqual(user.employee && user.employee.type, startCase(ROLES.CANDIDATE))
  ? reducerToUse.candidate
  : reducerToUse.collaborator);

export const openFile = (file) => window.open(file, "_blank");

export const getDocumentsByCandidate = (documents) => documents.filter((item) => item.file_url !== null);

export const getNewFormat = (data) => data?.map((item) => ({
  id: item.id,
  label: item.name,
  name: item.name,
  required: item.required,
}));

export const getDocumentsInitFormat = (data, index) => {
  let documents = [];
  documents = data?.map((item) => {
    const defaultData = {
      id: item[index],
      idParent: item.id,
      label: item.name,
      name: item.name,
      required: item.required,
    };
    if (item.order >= INDEX.zero) {
      defaultData.order = item.order;
    }
    return defaultData;
  });
  return documents;
};

// new table implementation
export const HEADER = ["document_name", "document_type", "date", "hidden", "actions"];

const Actions = (props) => {
  const { data } = props;
  const {
    rowData, t, user, isMobile, modal,
  } = data;
  const isAdmin = isRolAdmin(user?.userCookies);
  const url = rowData?.file_url || rowData?.url;
  const ADMIN_ACTIONS_MENU_DELETE = {
    title: t("account:accountDocuments.table.table-body.delete_document"),
    icon: <DeleteIcon fontSize={ SIZE.small } />,
    onClick: () => {
      const modalContent = {
        title: t("common:common.modal_messages.sure_question"),
        children: (
          <DeleteDocumentModal
            itemSelected={ rowData }
            modal={ modal }
            handleDelete={ modal.modalOptions[INDEX.zero] }
          />
        ),
      };
      modal.handleModalContent(modalContent);
      modal.set(!modal.value);
    },
  };

  const ADMIN_ACTIONS_MENU_EDIT = {
    title: t("account:accountDocuments.table.table-body.edit_document"),
    icon: <EditIcon fontSize={ SIZE.small } />,
    onClick: () => {
      const modalContent = {
        title: t("account:accountDocuments.table.table-body.edit_document"),
        children: (
          <EditDocumentFormModal
            itemSelected={ rowData }
            modal={ modal }
            handleUpdate={ modal.modalOptions[INDEX.one] }
            currentCollaboratorId={ modal.modalOptions[INDEX.two] }
          />
        ),
      };
      modal.handleModalContent(modalContent);
      modal.set(!modal.value);
    },
  };

  const ACTIONS_MENU = [
    {
      title: t("account:accountDocuments.table.table-body.view_document"),
      icon: <VisibilityIcon fontSize={ SIZE.small } />,
      onClick: () => {
        openFile(url);
      },
    },
  ];

  if (isAdmin) {
    ACTIONS_MENU.push(ADMIN_ACTIONS_MENU_EDIT, ADMIN_ACTIONS_MENU_DELETE);
  }

  const optionsToUse = ACTIONS_MENU;

  return <MenuPopup items={ optionsToUse } isMobile={ isMobile } />;
};
export const getRowsDesktop = (data, t, history, user, isMobile, modal) => {
  const isAdmin = isRolAdmin(user?.userCookies);
  const rows = data?.map((rowData) => {
    const url = rowData?.file_url || rowData?.url;
    const rowDefault = [
      {
        content: (
          <StyledFile>
            <StyledLink
              role={ VARIANT.button }
              tabIndex={ INDEX.zero }
              onClick={ () => {
                openFile(url);
              } }
              onKeyDown={ () => {
                openFile(url);
              } }
            >
              {rowData?.filename || url}
            </StyledLink>
          </StyledFile>
        ),
      },
      {
        content: rowData.document_type.name,
      },
      {
        content: formatDate(rowData.created_at),
      },
    ];
    if (isAdmin) {
      rowDefault.push(
        {
          content: t(`account:accountDocuments.table.table-body.${rowData.hidden ? "yes" : "no"}`),
        },
      );
    }
    rowDefault.push(
      {
        content: <Actions data={ {
          rowData, t, user, isMobile, modal,
        } }
        />,
      },
    );
    return rowDefault;
  });
  return rows;
};

export const getRowsMobile = (data, t, history, user, isMobile, modal) => {
  const isAdmin = isRolAdmin(user?.userCookies);
  const rows = data?.map((rowData) => [
    {
      content: (
        <div>
          <StyledTitleWithSubtitle className={ "cardMobile" }>
            <div className={ "subtitle" }>
              <Archive />
              {rowData?.filename || rowData?.url }
            </div>
          </StyledTitleWithSubtitle>
          <StyledTitleWithSubtitle className={ "cardMobile" }>
            <div className={ "subtitle" }>
              <Assignment />
              {rowData.document_type.name}
            </div>
          </StyledTitleWithSubtitle>
          <StyledTitleWithSubtitle className={ "cardMobile" }>
            <div className={ "subtitle" }>
              <Calendar />
              {formatDate(rowData.created_at)}
            </div>
          </StyledTitleWithSubtitle>
          {isAdmin && (
            <StyledTitleWithSubtitle className={ "cardMobile" }>
              <div className={ "subtitle" }>
                <VisibilityIcon />
                {t(`account:accountDocuments.table.table-body.${rowData.hidden ? "yes" : "no"}`)}
              </div>
            </StyledTitleWithSubtitle>
          )}
        </div>
      ),
    },
    {
      content: <Actions data={ {
        rowData, t, user, isMobile, modal,
      } }
      />,
    },
  ]);
  return rows;
};
