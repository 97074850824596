export const NPS_RANGE = {
  satisfied: {
    min: 0.51,
    max: 1.01,
  },
  neutral: {
    min: 0.01,
    max: 0.51,
  },
  dissatisfied: {
    min: -1,
    max: 0.01,
  },
};
