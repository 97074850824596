import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { BODY, TABLE } from "theme/palette";

export const StyledContainer = styled.div`
  background-color: ${BODY.BG_MAIN};
  padding: 15px;
  text-align: center;
  margin-top: 25px;
`;

export const StyledTitleText = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

export const StyledListItem = styled.li`
  display: inline-block;
`;

export const StyledListItemLegend = styled.div`
  width: 65px;
  height: 16px;
  margin: 8px;
`;

export const StyledListItemText = styled.div`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${TABLE.TEXT_THEAD};
`;
