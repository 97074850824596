import { useEffect } from "react";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";

const useHandleProcess = (externalHooks, process, message, reset, callback, callback2) => {
  const { t, dispatch } = externalHooks;
  const { errorProcess, successProcess } = process;
  useEffect(() => {
    if (successProcess || errorProcess) {
      const toastMessage = {
        title: t(`common:common.api_responses.${
          errorProcess ? "error.title" : "success.title"}`),
        message: errorProcess || message,
      };
      toast(
        successProcess ? MESSAGE_TYPES.success : MESSAGE_TYPES.error,
        toastMessage,
        callback,
        callback2,
      );
      dispatch(reset());
    }
    // eslint-disable-next-line
  }, [process]);
};

export default useHandleProcess;
