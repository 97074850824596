import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "40px",
    "& .MuiTab-textColorInherit.Mui-selected": {
      background: theme.palette.backgroundTab,
    },
  },
}));
