import PropTypes from "prop-types";
import CircleLoader from "components_refactor/CircleLoader";
import Modal from "components/Modal";
import { replacePath } from "common/utils";
import { isActiveDataResult } from "views_refactor/Profile/functions/documents";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import Documents from "./components/Documents";
import localRoutes from "../../../../../../functions/routes";

const DocumentsModal = (props) => {
  const {
    t,
    history,
    profileStates,
    documentsHookInfo,
  } = props;

  const { collaboratorDocument, documentsType } = documentsHookInfo;

  const { isModalOpen } = profileStates;
  let initialRender = <CircleLoader />;

  if (isActiveDataResult(collaboratorDocument, documentsType)) {
    initialRender = <Documents t={ t } documentsHookInfo={ documentsHookInfo } />;
  }
  return (
    <Modal
      isOpen={ isModalOpen.documents }
      title={ t("profile:modals.documents.title") }
      onClose={ () => replacePath(history, localRoutes.root) }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "700px" }>
        {initialRender}
      </StyledScrollBar>
    </Modal>
  );
};

DocumentsModal.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  profileStates: PropTypes.object.isRequired,
  documentsHookInfo: PropTypes.object.isRequired,
};

export default DocumentsModal;
