import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  padding: 32px;
`;

export const StyledCardContainer = styled(Card)`
  margin-top: 0;
  padding: 20px 40px;
  background: ${palette.text.link};
  border-radius: 0;
  @media (max-width: 450px) {
    padding: 10px;
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${palette.white};
  line-height: 50px;
  font-size: 40px;
  font-weight: bold;
  @media (max-width: 450px) {
    line-height: 20px;
    font-size: 16px;
  }
`;

export const StyledImageContainer = styled(Box)`
  background: ${palette.white};
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  img {
    width: 100px;
    height: 100px;
    padding: 20px;
  }
  @media (max-width: 450px) {
    img {
      width: 50px;
      height: 50px;
      padding: 5px;
    }
  }
`;

export const StyledContent = styled.div`
  margin-bottom: 20px;
  @media (max-width: 960px) {
    padding: 0px;
    .MuiAvatar-root {
      width: 40px;
      height: 40px;
    }
    .MuiTypography-body1 {
      font-size: 12px;
      line-height: 14px;
      margin-top: 4px;
      font-family: "Lato";
    }
    .MuiFormControl-root {
      margin-bottom: 0px;
    }
  }
`;

export const StyledFormContainer = styled.div`
  background: ${palette.white};
  border-radius: 5px;
  padding: 20px 30px;
`;

export const StyledHeader = styled.div`
  margin-bottom: 20px;
`;

export const StyledTemplateName = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  align-items: center;
  gap: 10px;
  color: ${palette.white};
  line-height: 30px;
  font-size: 25px;
  font-weight: bold;

  .MuiInputBase-root {
    margin-right: 10px;
  }

  input {
    color: ${palette.white};
    line-height: 30px;
    font-size: 25px;
    font-weight: bold;
    padding: 5px 10px;
  }
  fieldset {
    width: 100%;
    border: ${palette.border.actionLight};
  }
  @media (max-width: 450px) {
    line-height: 20px;
    font-size: 16px;
  }
`;

export const StyledTotal = styled(Typography)`
  width: 100%;
  display: block;
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
  text-align: right;
  margin-bottom: 5px;

  span {
    display: inline-block;
    text-align: center;
    min-width: 60px;
    color: ${(props) => (props.isInvalid ? palette.table.red : palette.black) };
    border: ${palette.border.grayLight};
    border-radius: 5px;
    padding: 0 5px;
  }
`;

export const StyledBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  background: ${palette.background.lightCyan};
  border-radius: 5px;
  padding: 10px 20px;
  margin: 30px 0;
  font-size: 16px;

  svg {
    width: 64px;
    height: 64px;
    color: ${palette.background.cyan};
  }
`;
