import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,

  RESET_STATE_ATTRITION_TO_DOWNLOAD,
  GET_LIST_ATTRITION_TO_DOWNLOAD,
  GET_LIST_ATTRITION_TO_DOWNLOAD_LOADING,
  GET_LIST_ATTRITION_TO_DOWNLOAD_ERROR,
} from "../actionTypes/attrition";

const INITIAL_STATE = {
  list: null,
  highRiskList: null,
  mediumRiskList: null,
  lowRiskList: null,

  isLoadingList: false,
  highRiskIsLoadingList: false,
  mediumRiskIsLoadingList: false,
  lowRiskIsLoadingList: false,

  listTotal: 0,
  highRiskListTotal: 0,
  mediumRiskListTotal: 0,
  lowRiskListTotal: 0,
  errorList: "",

  downloadList: null,
  isLoadingDownloadList: false,
  errorDownloadList: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  const labelList = action.category ? `${action.category}RiskList` : "list";
  const labelLoadingList = action.category ? `${action.category}RiskIsLoadingList` : "isLoadingList";
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      highRiskIsLoadingList: false,
      mediumRiskIsLoadingList: false,
      lowRiskIsLoadingList: false,
      errorList: null,
      list: null,
      highRiskList: null,
      mediumRiskList: null,
      listTotal: 0,
      highRiskListTotal: 0,
      mediumRiskListTotal: 0,
      lowRiskListTotal: 0,
    };
  case GET_LIST:
    return {
      ...state,
      [labelList]: action.payload,
      [`${labelList}Total`]: action.total,
      [labelLoadingList]: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      [labelList]: null,
      [labelLoadingList]: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      [labelLoadingList]: false,
      errorList: action.payload,
    };
  case RESET_STATE_ATTRITION_TO_DOWNLOAD:
    return {
      ...state,
      downloadList: null,
      isLoadingDownloadList: false,
      errorDownloadList: "",
    };
  case GET_LIST_ATTRITION_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: false,
      downloadList: action.payload,
    };
  case GET_LIST_ATTRITION_TO_DOWNLOAD_LOADING:
    return {
      ...state,
      isLoadingDownloadList: true,
    };
  case GET_LIST_ATTRITION_TO_DOWNLOAD_ERROR:
    return {
      ...state,
      isLoadingDownloadList: false,
      errorDownloadList: action.payload,
    };
  default:
    return state;
  }
};
