import styled from "styled-components";
import charts from "theme/charts";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";
import palette from "theme/palette";

export const StyledIcon = styled(HelpIcon)`
  cursor: pointer;
`;

export const StyledTooltip = styled(Tooltip)`
&.MuiTooltip-tooltip {
    background: ${palette.white};
    box-shadow: ${charts.boxShadow.standar};
    color: ${palette.text.secondaryTitle};
    font-size: 13px;
    border: 1px solid ${palette.tooltip?.border};
    max-width: 220px;
    padding: 10px;
    & > p {
      margin-top: 5px;
    }
}
  & .MuiTooltip-arrow {
    color: ${(props) => props.background || "rgba(51, 51, 51, 0.76)"};
  }
`;

export const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    "&::before": {
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.tooltip?.border}`,
    },
  },
  popper: {
    zIndex: 9999,
  },
}))(Tooltip);
