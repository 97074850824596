import styled from "styled-components";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledContent = styled(Card)`
  margin-top: 32px;
  padding: 32px;
`;

export const StyledAppliedFilter = styled(Box)`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  svg{
    margin-right: 10px;
  }
`;

export const StyledAppliedFilterOption = styled.div`
  display: block;
  margin-bottom: 15px;
`;

export const StyledOptionItems = styled.div`
  display: flex;
  align-items: center;
  svg{
    width: 24px;
    margin-right: 5px;
  }
`;

export const StyledSubtitleText = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  margin-bottom: 20px;
  color: ${palette.subtitle};
`;

export const StyledSearchContainer = styled.div`
  display: ${(props) => (props.isMobile ? "inline-block" : "flex")};
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "8px" : "0")};
  .searchInput{
    margin-bottom: 15px;
    input{
      width: 250px;
      @media (max-width: 600px) {
        width: 100%
      };
    }
  }
`;

export const StyledTimeText = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  .MuiSvgIcon-root {
    margin-right: 5px;
    color: ${palette.text.gray};
  }
`;

export const StyledDownLoad = styled.div`
  margin-bottom: 8px;
`;

export const StyledHeaderCard = styled.div`
  border-radius: 4px;
  padding: 10px;
  button {
    color: ${palette.text.link};
  }
`;
