import { useContext } from "react";
import PropTypes from "prop-types";
import { SessionContext } from "modules/session/context";
import ViewModalComponent from "components/ViewModalComponent";
import LeadershipView from "./Leadership.view";
import { useLeadershipHook } from "./hooks/leadership.hook";

const LeadershipController = (props) => {
  const { t, dispatch, handleOpenModal } = props;
  const {
    state: { user },
  } = useContext(SessionContext);

  const leadershipStates = useLeadershipHook({ dispatch, t, handleOpenModal });

  return (
    <ViewModalComponent viewComponent={ (
      <LeadershipView
        user={ user }
        { ...props }
        { ...leadershipStates }
      />
    ) }
    />
  );
};

LeadershipController.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default LeadershipController;
