export const RESET_STATE = "RESET_STATE_FOLLOW_UP";
export const GET_LIST = "GET_LIST_FOLLOW_UP";
export const GET_LIST_LOADING = "GET_LIST_LOADING_FOLLOW_UP";
export const GET_LIST_ERROR = "GET_LIST_ERROR_FOLLOW_UP";
export const RESET_STATE_BY_COLLABORATORS = "RESET_STATE_BY_COLLABORATORS";
export const GET_LIST_BY_COLLABORATORS = "GET_LIST_FOLLOW_UP_BY_COLLABORATORS";
export const GET_LIST_BY_COLLABORATORS_LOADING = "GET_LIST_LOADING_FOLLOW_UP_BY_COLLABORATORS";
export const GET_LIST_BY_COLLABORATORS_ERROR = "GET_LIST_ERROR_FOLLOW_UP_BY_COLLABORATORS";
export const RESET_STATE_BY_COLLABORATORS_TO_DOWNLOAD = "RESET_STATE_BY_COLLABORATORS_TO_DOWNLOAD";
export const GET_LIST_BY_COLLABORATORS_TO_DOWNLOAD = "GET_LIST_FOLLOW_UP_BY_COLLABORATORS_TO_DOWNLOAD";
export const GET_LIST_LOADING_BY_COLLABORATORS_TO_DOWNLOAD = "GET_LIST_LOADING_FOLLOW_UP_BY_COLLABORATORS_TO_DOWNLOAD";
export const GET_LIST_ERROR_BY_COLLABORATORS_TO_DOWNLOAD = "GET_LIST_ERROR_FOLLOW_UP_BY_COLLABORATORS_TO_DOWNLOAD";
