import styled from "styled-components";
import InputBase from "@mui/material/InputBase";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

export const PaginationContainer = styled.div`
  display: flex;
  padding: 5px 0 5px 0;
  margin-top: 15px;
  align-items: center;
`;

export const PaginationContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  controls: {
    alignItems: "center",
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
    padding: 5,
    margin: 0,
  },
  page: {
    marginRight: 10,
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.2)",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "inherit",
      justifyContent: "flex-end",
    },
  },
  contentDivider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const CustomSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    border: "0px",
    fontSize: 12,
    padding: "10px 26px 10px 12px",
  },
}))(InputBase);
