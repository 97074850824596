import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useIsMounted } from "react-tidy";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import includes from "lodash/includes";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import forEachRight from "lodash/forEachRight";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CurrencyInputTextController from "components/CurrencyInputTextController";
import DateInputController from "components/DateInputController";
import SelectController from "components/SelectController";
import SimpleAlert from "components/SimpleAlert";
import Button from "components/Button";
import MenuPopup from "components/MenuPopup";
import AlertModal from "components/AlertModal";
import AutoCompleteCreable from "components/AutocompleteCreable";
import {
  toast, handleMessages, MESSAGE_TYPES, HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import { getList as getTypeOfContract } from "redux/actions/typeOfContractActions";
import { getList as getBenefits } from "redux/actions/benefitsActions";
import {
  getList as getMovementHistory,
  resetStateProcess as resetStateProcessMovements,
} from "redux/actions/historicalMovementActions";
import {
  ROLES,
  BUTTON_STYLE_TYPES,
  LOCAL_STORAGE_NAMES,
  DEFAULT_MIN_DECIMAL,
  OBJECT_KEYS,
  ALIGN_ITEMS,
  MIN_VALUE,
  INPUT_TYPE,
} from "common/constants";
import {
  getItemFromLocalStorage,
  isNotCandidate,
  getCurrencyFormat,
  hasAllValidatedFields,
  hasEmptyInput,
  getAutocompleteAttr,
  isColombianAdmin,
  getEmployeeCompanyId,
  getFormattedItems,
  getUserRoles,
} from "common/utils";
import { isNullOrUndefined, isAdminOrManager } from "common/validators";
import moneyImage from "assets/images/profile/money.svg";
import { useUserBenefits } from "hooks/useUserBenefits";
import MovementsHistory from "./components/MovementsHistory";
import BenefitsTable from "./components/BenefitsTable";
import {
  validateRoleCondition,
  resetProfileData,
} from "../../functions";
import {
  resetDefaultValues,
  getMappedMovementList,
  handleMovement,
  validateAllFields,
  dispatchEmployeeAndMovements,
  getAlertContractRelationship,
  getSalaryAttributesData,
} from "../../functions/salary";
import { dispatchUpdate } from "../../functions/profile";
import { StyledFormContainer } from "../../styles";

const Salary = (props) => {
  const {
    locationState,
    user,
    from,
    classes,
    searchBy,
    employee,
    reducerToUse,
    selectedId,
  } = props;
  const companyId = getEmployeeCompanyId(user);
  const { t } = useTranslation(["common", "account", "generalAdministrator"]);
  const [
    userBenefits,
  ] = useUserBenefits(isNotCandidate(from, user?.userCookies) ? reducerToUse?.one?.id : null);

  const isMounted = useIsMounted();
  const [editable, setEditable] = useState(
    locationState?.edit || locationState?.salary,
  );
  const [showSalaryIncreaseDate, setShowSalaryIncreaseDate] = useState(
    locationState?.salary,
  );
  const [isBenefitsEditable, setIsBenefitsEditable] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [pulse, setPulse] = useState({
    contract: false,
    currency: false,
    salary: false,
    date: false,
  });

  const dispatch = useDispatch();
  const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);

  const [values, setValues] = useState({
    type_of_contract: "",
    contract_employment_relationship: "",
    currency: "",
    projectedCtc: null,
    exchange: null,
  });

  const employeeData = reducerToUse?.one;
  const collaboratorId = employeeData?.id;

  const {
    isLoadingList: isLoadingBenefits,
  } = useSelector(({ benefitsReducer }) => benefitsReducer);

  const {
    list: historicalMomenentList,
    isLoadingList: isLoadingHistoricalMomenentList,
    successProcess: successProcessMovements,
  } = useSelector(({ historicalMovementsReducer }) => historicalMovementsReducer);

  const viewModal = (
    title,
    children,
    text = "",
    approved = "",
    cancel = "",
    onClick,
  ) => {
    setModalData(createModal(title, text, approved, cancel, onClick, children));
    openCloseModal();
  };

  const createModal = (title, text, approved, cancel, onClick, children) => ({
    title, text, approved, cancel, onClick, children,
  });

  const handleToggleEdit = (opc) => {
    !opc && setShowSalaryIncreaseDate(false);

    setPulse({
      contract: false,
      currency: false,
      salary: false,
      date: false,
    });

    resetForm(employeeData);
    return setEditable(opc);
  };

  const handleToggleBenefits = (opc) => {
    setEditable(false);
    setIsBenefitsEditable(opc);
    !opc
      && dispatch(getBenefits());
  };

  const handleSalaryIncreaseDate = () => {
    setShowSalaryIncreaseDate(true);
    handleToggleEdit(true);
  };

  const handleModalSubmit = () => {
    const auxPulse = {
      contract: hasEmptyInput(
        control,
        OBJECT_KEYS.typeOfContractId,
        validateAllFields,
      ),
      salary: hasEmptyInput(
        control,
        OBJECT_KEYS.grossSalary,
        validateAllFields,
      ),
      currency: hasEmptyInput(control, OBJECT_KEYS.currency, validateAllFields),
      date: hasEmptyInput(control, OBJECT_KEYS.date, validateAllFields),
    };

    setPulse(auxPulse);

    if (hasAllValidatedFields(auxPulse)) {
      return viewModal(
        t("common:common.modal_messages.sure_question"),
        showSalaryIncreaseDate ? (
          <SimpleAlert
            type={ "info" }
            message={ t("account:accountDetails.movementHistoryMessage") }
            customStyle={ classes.simpleAlertModal }
          />
        ) : (
          ""
        ),
        showSalaryIncreaseDate
          ? `${t("account:accountDetails.sure_about")}${t(
            "account:accountDetails.salary_increase",
          )}`
          : t("common:common.modal_messages.sure_text"),
        t("common:common.modal_messages.yes_confirm"),
        t("common:common.modal_messages.no_cancel"),
        onSubmit,
      );
    }
  };

  const ACTIVE_COLLABORATOR_ACTIONS = [
    {
      title: t("account:accountDetails.salary_increase"),
      isDisabled: showSalaryIncreaseDate,
      onClick: () => handleSalaryIncreaseDate(),
    },
    {
      title: t("account:accountDetails.benefits.edit"),
      isDisabled: isBenefitsEditable,
      onClick: () => handleToggleBenefits(true),
    },
  ];

  const ACTIONS_MENU = [
    {
      title: t("account:accountDetails.edit_salary"),
      isDisabled: editable,
      onClick: () => handleToggleEdit(true),
    },
  ];

  const addActiveCollaboratorActions = () => {
    forEachRight(ACTIVE_COLLABORATOR_ACTIONS, (item) => {
      ACTIONS_MENU.unshift(item);
    });
  };

  if (reducerToUse && employeeData?.is_active) {
    addActiveCollaboratorActions();
  }

  const menuActions = (grid) => (
    <Grid item xs={ 12 } sm={ grid } className={ classes?.btnRight }>
      <MenuPopup
        button={ t("collaborators:buttons.actions") }
        items={ ACTIONS_MENU }
        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
      />
    </Grid>
  );

  const {
    handleSubmit, control, reset, register,
  } = useForm({
    defaultValues: resetDefaultValues(employee),
  });

  const resetForm = useCallback(
    (user) => {
      if (user) {
        const userToUse = {
          starting_date: null,
          type_of_contract: null,
          contract_employment_relationship: null,
          salary: null,
          currency: null,
        };

        userToUse.starting_date = isNullOrUndefined(user.starting_date)
          ? ""
          : user.starting_date;

        userToUse.type_of_contract = isNullOrUndefined(user.job_position)
          ? ""
          : isNull(user.job_position?.type_of_contract)
            ? ""
            : user.job_position?.type_of_contract?.id;

        userToUse.contract_employment_relationship = user?.job_position?.type_of_contract?.employment_relationship_id;

        userToUse.salary = isNullOrUndefined(user.salary)
          ? ""
          : user.salary.gross_salary;

        userToUse.currency = isNullOrUndefined(user.salary)
          ? ""
          : user.salary.currency;

        userToUse.projected_ctc = isNullOrUndefined(user.salary)
          ? ""
          : user.salary.projected_ctc;

        setValues({
          type_of_contract: userToUse.type_of_contract,
          contract_employment_relationship: userToUse.contract_employment_relationship,
          currency: userToUse.currency,
          projectedCtc: getCurrencyFormat(userToUse.projected_ctc),
          exchange: getCurrencyFormat(
            userToUse.projected_ctc,
            userToUse.currency,
            getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies),
          ),
        });

        reset({
          [`${employee}.starting_date`]: userToUse.starting_date,
          [`${employee}.job_position_attributes.type_of_contract_id`]: userToUse.type_of_contract,
          [`${employee}.salary_attributes.gross_salary`]: userToUse.salary,
          [`${employee}.salary_attributes.currency`]: userToUse.currency,
        });
      }
    },
    [reset, employee],
  );

  useEffect(() => {
    if (isMounted) {
      const contractTypes = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.typeOfContract);
      if (isEmpty(contractTypes)) {
        dispatch(getTypeOfContract());
      }
      if (user) {
        validateRoleCondition(
          from,
          user,
          [ROLES.ADMIN, ROLES.ADMIN_NALA],
          [ROLES.COLLABORATOR],
        )
          ? reset(resetDefaultValues(employee))
          : resetForm(employeeData);
      }
      if (
        isNotCandidate(from, user?.userCookies)
        && !isUndefined(collaboratorId)
      ) {
        dispatch(getMovementHistory(collaboratorId));
      }
    }
  }, [
    reducerToUse,
    user,
    from,
    reset,
    resetForm,
    searchBy,
    employee,
    dispatch,
    isMounted,
    collaboratorId,
    companyId,
    employeeData,
    t,
  ]);

  const openCloseModal = () => {
    setModal(!modal);
  };

  const onAutocompleteChange = (nameOfAttr) => (prop, event, newValue) => {
    const autocompleteAttr = getAutocompleteAttr(newValue, nameOfAttr);
    setValues({
      ...values,
      currency: !isNull(newValue) ? autocompleteAttr : "",
    });
  };

  const onUpdateDispatch = (isSalaryRaise) => {
    handleToggleEdit(undefined);
    setModal(false);
    if (isSalaryRaise) {
      dispatchEmployeeAndMovements(
        dispatch,
        collaboratorId,
      );
    }
  };

  const onSubmit = async () => {
    const data = control.getValues();
    const salary = employee === ROLES.CANDIDATE
      ? data.candidate.salary_attributes.gross_salary
      : data.collaborator.salary_attributes.gross_salary;

    if (isString(salary)) {
      if (employee === ROLES.CANDIDATE) {
        data.candidate.salary_attributes.gross_salary = salary.replaceAll(
          ",",
          "",
        );
      } else {
        data.collaborator.salary_attributes.gross_salary = salary.replaceAll(
          ",",
          "",
        );
      }
    }

    if (!showSalaryIncreaseDate) {
      // edit salary information
      dispatchUpdate(dispatch, employee, data, reducerToUse).then(
        () => {
          onUpdateDispatch();
        },
      );
    } else {
      // Salary increase
      const constToHandle = {
        data,
        dispatch,
        employeeId: employeeData?.id,
        companyId,
      };

      handleMovement(constToHandle).then(() => {
        // FIXME: For now, send the benefits to keep when you make a salary increase or promotion.
        if (!isEmpty(userBenefits)) {
          dispatchUpdate(
            dispatch,
            ROLES.COLLABORATOR,
            getSalaryAttributesData(collaboratorId, userBenefits),
            reducerToUse,
          );
        }
        // End FIXME
        onUpdateDispatch(true);
      });
    }
  };

  const salaryTitleInfo = editable && (
    <Grid item xs={ 12 } sm={ 6 }>
      <Typography className={ classes.subtitleText }>
        {t("common:common.salary")}
      </Typography>
      <Typography variant={ "subtitle2" }>
        {t("account:accountDetails.all_required")}
      </Typography>
    </Grid>
  );

  const messageInfo = editable && (
    <Grid item xs={ 12 } sm={ 10 }>
      <Typography className={ classes.subtitleText }>
        {showSalaryIncreaseDate
          ? t("account:accountDetails.salary_increase")
          : t("account:accountDetails.edit_info")}
      </Typography>
      <SimpleAlert
        type={ "info" }
        message={ showSalaryIncreaseDate
          ? t("account:accountDetails.salary_increase_message")
          : t("account:accountDetails.edit_message") }
      />
    </Grid>
  );

  const resetData = useCallback(() => {
    resetProfileData(
      dispatch,
      from,
      searchBy,
      user,
      selectedId,
    );
    dispatch(resetStateProcessMovements());
    toast(
      MESSAGE_TYPES.success,
      handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
    );
  }, [t, dispatch, from, searchBy, selectedId, user]);

  useEffect(() => {
    if (reducerToUse?.successProcess || successProcessMovements) {
      resetData();
    }
  }, [reducerToUse, successProcessMovements, resetData]);

  return (
    <>
      <div className={ classes?.container } data-testid={ "account-salary-tab" }>
        <Grid container spacing={ 4 }>
          {messageInfo}
          {(user
            && (includes(getUserRoles(user?.userCookies), ROLES.ADMIN)
              || includes(getUserRoles(user?.userCookies), ROLES.ADMIN_NALA)))
          || (isColombianAdmin(user?.userCookies) && from)
            ? editable
              ? menuActions(2) // width of two grids
              : menuActions(12) // width of show 12 grids
            : ""}
          {salaryTitleInfo}
        </Grid>
        <form
          autoComplete={ "off" }
          onSubmit={ handleSubmit(handleModalSubmit) }
          className={ classes?.tablePaper }
        >
          <StyledFormContainer container spacing={ 4 }>
            <Grid item xs={ 12 } md={ 6 }>
              <DateInputController
                id={ "starting_date" }
                label={ t("common:common.startingDate") }
                control={ control }
                name={ `${employee}.starting_date` }
                openTo={ "year" }
                value={ null }
                views={ ["year", "month", "day"] }
                disabled={ !editable || showSalaryIncreaseDate }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SelectController
                id={ "type_of_contract" }
                label={ t("common:common.typeOfContract") }
                control={ control }
                name={ `${employee}.job_position_attributes.type_of_contract_id` }
                menuItems={ getFormattedItems(LOCAL_STORAGE_NAMES.typeOfContract) }
                disabled={ !editable || showSalaryIncreaseDate }
                defaultValue={ values.type_of_contract }
                customStyles={ pulse.contract ? classes.pulse : "" }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <CurrencyInputTextController
                id={ "salary" }
                label={ t("common:common.salary") }
                isDisabled={ !editable }
                control={ control }
                name={ `${employee}.salary_attributes.gross_salary` }
                customStyles={ pulse.salary ? classes.pulse : "" }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ "currency" }
                options={ currencies }
                label={ t("common:common.currency.main") }
                register={ register }
                disabled={ !editable }
                onChange={ onAutocompleteChange("name_with_code") }
                name={ `${employee}.salary_attributes.currency` }
                value={ values.currency }
                nameOfAttr={ "name_with_code" }
                isRequired={ !isEmpty(values.currency) }
                customStyles={ pulse.currency ? classes.pulse : "" }
              />
            </Grid>
            {isNotCandidate(from, user?.userCookies)
              && isAdminOrManager(user?.userCookies, from) && (
              <>
                <Grid item xs={ 12 } md={ 6 }>
                  <Paper className={ classes?.currency }>
                    <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
                      <Box flexGrow={ 1 }>
                        <Grid container>
                          <Grid item xs={ 12 } sm={ 8 }>
                            <Typography>
                              {t(
                                "account:accountDetails.payrollProjection.local",
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 4 } className={ classes?.money }>
                            <Typography variant={ "h6" }>
                              <NumberFormat
                                value={ isNull(values.contract_employment_relationship) ? MIN_VALUE : values.projectedCtc }
                                displayType={ INPUT_TYPE.text }
                                thousandSeparator
                                prefix={ "$" }
                                fixedDecimalScale
                                decimalScale={ DEFAULT_MIN_DECIMAL }
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 8 }>
                            <Typography>
                              {t(
                                "account:accountDetails.payrollProjection.usd",
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 4 }>
                            <Typography variant={ "h6" }>
                              <NumberFormat
                                value={ isNull(values.contract_employment_relationship) ? MIN_VALUE : values.exchange }
                                displayType={ INPUT_TYPE.text }
                                thousandSeparator
                                prefix={ "$" }
                                fixedDecimalScale
                                decimalScale={ DEFAULT_MIN_DECIMAL }
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box pl={ 2 } pt={ 0.5 }>
                        <img src={ moneyImage } alt={ "Money" } />
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                {isNull(values.contract_employment_relationship) && (
                  <Grid item xs={ 12 } md={ 6 }>
                    {getAlertContractRelationship(t, user.userCookies)}

                  </Grid>
                )}

              </>
            )}

            {showSalaryIncreaseDate && (
              <>
                <Grid item xs={ 12 } md={ 6 }>
                  <DateInputController
                    id={ "date" }
                    label={ t("common:common.salary_increase_starting_date") }
                    control={ control }
                    name={ `${employee}.date` }
                    openTo={ "year" }
                    value={ new Date() }
                    views={ ["year", "month", "day"] }
                    disabled={ !editable }
                    customStyles={ pulse.date ? classes.pulse : "" }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 } />
              </>
            )}
            {editable && (
              <Grid container className={ classes.submitButtons }>
                <Grid item xs={ 12 }>
                  <Box display={ ALIGN_ITEMS.flex } justifyContent={ ALIGN_ITEMS.flexEnd }>
                    <Box>
                      <Button
                        onClick={ () => handleToggleEdit(false) }
                        variant={ BUTTON_STYLE_TYPES.CONTAINED }
                        typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                      >
                        {t("collaborators:buttons.cancel")}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant={ BUTTON_STYLE_TYPES.CONTAINED }
                        type={ BUTTON_STYLE_TYPES.SUBMIT }
                        isLoading={ reducerToUse?.isLoadingProcess }
                        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                      >
                        {t("collaborators:buttons.save_edit")}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </StyledFormContainer>
        </form>

        {modalData && (
          <AlertModal
            title={ modalData.title }
            text={ modalData.text }
            textDisagree={ modalData.cancel }
            textAgree={ modalData.approved }
            onClick={ modalData.onClick }
            open={ modal }
            isLoading={ reducerToUse?.isLoadingProcess }
            handleClose={ openCloseModal }
          >
            {modalData.children}
          </AlertModal>
        )}
      </div>
      <Box mt={ 4 }>
        {user
          && !includes(from, ROLES.CANDIDATE)
          && !includes(getUserRoles(user?.userCookies), ROLES.CANDIDATE) && collaboratorId && (
          <>
            <Grid item xs={ 12 }>
              <BenefitsTable
                isLoading={ isLoadingBenefits }
                isEditable={ isBenefitsEditable }
                classes={ classes }
                control={ control }
                handleToggleBenefits={ handleToggleBenefits }
                collaboratorId={ collaboratorId }
                reducerToUse={ reducerToUse }
                isCollaborator={ isNotCandidate(from, user?.userCookies) }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <MovementsHistory
                data={ getMappedMovementList(
                  historicalMomenentList, t, employeeData,
                ) }
                isLoading={ isLoadingHistoricalMomenentList }
              />
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

Salary.propTypes = {
  locationState: PropTypes.object,
  user: PropTypes.object,
  from: PropTypes.string,
  classes: PropTypes.object,
  searchBy: PropTypes.string,
  employee: PropTypes.string,
  reducerToUse: PropTypes.object,
  selectedId: PropTypes.string,
};

export default Salary;
