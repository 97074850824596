import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ALIGN_ITEMS, VARIANT } from "common/constants";
import { getRange } from "views_refactor/Profile/functions/performance";
import {
  StyledBox,
  StyledScale,
  StyledGridScale,
} from "./styles";

const ResultScale = (props) => {
  const { scale } = props;
  const orderedScale = scale.sort((a, b) => a?.top - b?.top);
  const { t } = useTranslation("performance");

  return (
    <Grid item xs={ 12 } md={ 4 } data-testid={ "good-leader-result-scale" } >
      <StyledBox>
        <Typography variant={ VARIANT.bodyOne }>
          {t("goodLeaderDescriptionScale")}
        </Typography>
        <StyledGridScale container>
          {orderedScale.map((item, index) => (
            <Box key={ `scale-${index}` } mt={ 1 } display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
              <StyledScale color={ item?.color }>
                {getRange(item)}
              </StyledScale>
            </Box>
          ))}
        </StyledGridScale>
      </StyledBox>
    </Grid>
  );
};

ResultScale.propTypes = {
  scale: PropTypes.array.isRequired,
};

export default ResultScale;
