export const RESET_STATE_VALIDATE_PASSWORD = "RESET_STATE_VALIDATE_PASSWORD";
export const PROCESS_VALIDATE_PASSWORD = "PROCESS_VALIDATE_PASSWORD";
export const PROCESS_VALIDATE_PASSWORD_LOADING = "PROCESS_VALIDATE_PASSWORD_LOADING";
export const PROCESS_VALIDATE_PASSWORD_ERROR = "PROCESS_VALIDATE_PASSWORD_ERROR";

export const RESET_STATE_CREATE_PASSWORD = "RESET_STATE_CREATE_PASSWORD";
export const PROCESS_CREATE_PASSWORD = "PROCESS_CREATE_PASSWORD";
export const PROCESS_CREATE_PASSWORD_LOADING = "PROCESS_CREATE_PASSWORD_LOADING";
export const PROCESS_CREATE_PASSWORD_ERROR = "PROCESS_CREATE_PASSWORD_ERROR";

export const RESET_STATE_PASSWORD_RECOVERY = "RESET_STATE_PASSWORD_RECOVERY";
export const PROCESS_PASSWORD_RECOVERY = "PROCESS_PASSWORD_RECOVERY";
export const PROCESS_PASSWORD_RECOVERY_LOADING = "PROCESS_LOADING_PASSWORD_RECOVERY";
export const PROCESS_PASSWORD_RECOVERY_ERROR = "PROCESS_ERROR_PASSWORD_RECOVERY";
