import { getPercent } from "common/utils";
import palette, { LEADERSHIP_TYPE_COLORS } from "theme/palette";

export const dataBox = [
  {
    index: 0,
    id: "productive-development-leader",
    x: 0,
    y: 0,
    width: 63,
    fill: LEADERSHIP_TYPE_COLORS.development,
    xText: 2,
    yText: 3.5,
    xPercentage: 63,
    yPercentage: 100,
    quadrant: 0,
    height: 27,
    border: 1,
    textColor: palette.black,
  },
  {
    index: 1,
    id: "great-leader",
    x: 63,
    y: 0,
    width: 37,
    fill: LEADERSHIP_TYPE_COLORS.exemplary,
    xText: 65,
    yText: 3.5,
    xPercentage: 100,
    yPercentage: 100,
    quadrant: 1,
    height: 27,
    border: 1,
    textColor: palette.text.grayDark,
  },
  {
    index: 2,
    id: "boss",
    x: 0,
    y: 27,
    width: 48,
    fill: LEADERSHIP_TYPE_COLORS.chief,
    xText: 2,
    yText: 31,
    xPercentage: 48,
    yPercentage: 54,
    quadrant: 2,
    height: 54,
    border: 1,
    textColor: palette.white,
  },
  {
    index: 3,
    id: "leader-in-low-productivity-development",
    x: 48,
    y: 27,
    width: 52,
    fill: LEADERSHIP_TYPE_COLORS.development,
    xText: 50,
    yText: 78.5,
    xPercentage: 100,
    yPercentage: 54,
    quadrant: 3,
    height: 54,
    border: 1,
    textColor: palette.black,
  },
  {
    index: 4,
    id: "unproductive-boss",
    x: 0.5,
    y: 53.5,
    width: 27,
    fill: LEADERSHIP_TYPE_COLORS.unproductive,
    xText: 2,
    yText: 57,
    xPercentage: 27,
    yPercentage: 33.333333333333336,
    quadrant: 4,
    height: 27,
    border: 0,
    textColor: palette.white,
  },
];

export const getDataBox = (leadershipTypes) => {
  dataBox.forEach((box) => {
    const leadershipType = leadershipTypes?.find((type) => type.slug === box.id);
    if (leadershipType) {
      box.name_es = leadershipType.name_es;
      box.name_en = leadershipType.name_en;
      box.name_pt = leadershipType.name_pt;
      box.description_es = leadershipType.description_es;
      box.description_en = leadershipType.description_en;
      box.description_pt = leadershipType.description_pt;
      box.xMinScale = getPercent(leadershipType.x_axis_bottom, false);
      box.xMaxScale = getPercent(leadershipType.x_axis_top, false);
      box.yMinScale = getPercent(leadershipType.y_axis_bottom, false);
      box.yMaxScale = getPercent(leadershipType.y_axis_top, false);
    }
  });
  return dataBox;
};

export const getRectValues = (values) => ({
  x: values.x,
  y: values.y,
  width: values.width,
  height: values.height,
});
