import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImageLoader } from "react-progressive-img-loader";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { SessionContext } from "modules/session/context";
import Stepper from "components/Stepper";
import TooltipIcon from "components/TooltipIcon";
import {
  DEFAULT_MIN_DECIMAL,
  FORM_MODE, INDEX, INPUT_TYPE, LOCAL_STORAGE_NAMES, OBJECT_KEYS, ROLES_NAMES, VARIANT,
} from "common/constants";
import {
  isEmpty, isEmptyObject, isEqual, isNull,
} from "common/helpers";
import {
  formatDate, getItemFromLocalStorage, getValueByProp, isColombianAdmin,
} from "common/utils";
import useComplexState from "hooks/utils/useComplexState";
import {
  getOne as getCollaborator,
  resetStateOne as resetStateOneCollaborator,
  rehireEmployee,
  resetStateProcess,
} from "redux/actions/collaboratorActions";
import {
  formatRehireData,
  getUpdatedData,
  resetDefaultRehireValues,
  setUpdatedEmployeeData,
  getNewValue,
} from "views/Collaborators/functions/collaborators";
import RolesModal from "views_refactor/Profile/components/EmployeeInfo/components/EmployeeModal/components/RolesModal";
import { getOrganizationUnitName, getRolesApp, getRolesWithScope } from "views_refactor/Profile/functions/profile";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import NumberFormat from "react-number-format";
import SalaryForm from "./components/SalaryForm";
import EmployeeForm from "./components/EmployeeForm";
import {
  StyledAvatar, StyledBold, StyledEditIcon, StyledItemText, StyledStepsContainer, StyledTag,
} from "./styles";

const RehireEmployee = (props) => {
  const {
    id, modalHandler,
  } = props;
  const {
    state: { user },
  } = useContext(SessionContext);

  const { t } = useTranslation("collaborators");
  const employee = OBJECT_KEYS.collaborator;
  const isValidColombianAdmin = isColombianAdmin(user?.userCookies);
  const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);
  const dispatch = useDispatch();

  const [rehireStates, setRehireState] = useComplexState({
    date: null,
    codeCurrency: null,
    firstStep: [],
    secondStep: [],
    inputTagsSelected: [],
    employeeRoles: [],
    allRolesList: getRolesApp(t, user, isValidColombianAdmin),
    isModalOpen: {
      rolesInfo: false,
    },
  });

  const {
    one: collaborator,
    isLoadingOne: isLoadingCollaboratorReducer,
    successProcess,
    isLoadingProcess,
  } = useSelector((state) => state.collaboratorReducer);

  const {
    control, handleSubmit, reset, register, watch, errors, setValue, getValues,
  } = useForm({
    defaultValues: resetDefaultRehireValues(employee, null),
    mode: FORM_MODE.onChange,
  });
  const watchDate = watch(`${employee}.date`);

  const hookEmployeeForm = {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    errors,
  };

  useEffect(() => {
    if (id) {
      dispatch(getCollaborator(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (collaborator) {
      if (collaborator?.job_position) {
        const orgUnits = getOrganizationUnitName(collaborator?.job_position?.organization_units);
        collaborator.job_position.division = orgUnits.division?.name;
        collaborator.job_position.area = orgUnits.area?.name;
      }
      reset(setUpdatedEmployeeData(employee, collaborator, {
        date: rehireStates.date,
        firstStep: rehireStates.firstStep,
        secondStep: rehireStates.secondStep,
      }));
      if (!isEmpty(collaborator?.roles_with_scope)) {
        setRehireState({
          inputTagsSelected: getRolesWithScope(collaborator?.roles_with_scope, t),
        });
      }
      if (collaborator?.salary?.currency) {
        setRehireState({
          codeCurrency: currencies.find(
            (currency) => isEqual(currency.name_with_code, collaborator?.salary?.currency),
          )?.code,
        });
      }
    }
    // eslint-disable-next-line
  }, [collaborator]);

  useEffect(() => {
    if (!isLoadingProcess && successProcess) {
      dispatch(resetStateProcess());
      if (rehireStates.isModalOpen.rolesInfo) { // Submit roles
        setRehireState({ isModalOpen: { ...rehireStates.isModalOpen, rolesInfo: false } });
        dispatch(getCollaborator(id));
      } else {
        dispatch(resetStateOneCollaborator());
        modalHandler(false);
      }
    }
    // eslint-disable-next-line
  }, [dispatch, id, successProcess, isLoadingProcess, modalHandler]);

  const sendNextStep = (step = INDEX.one) => {
    const newValues = watch().collaborator;
    const updatedData = getUpdatedData(collaborator, newValues, step);
    if (isEqual(step, INDEX.one)) {
      setRehireState({ date: newValues.date });
    }
    setRehireState({
      [`${isEqual(step, INDEX.one) ? "first" : "second"}Step`]: updatedData,
    });
    reset(setUpdatedEmployeeData(employee, collaborator, {
      date: isEqual(step, INDEX.one) ? newValues.date : rehireStates.date,
      firstStep: isEqual(step, INDEX.one) ? updatedData : rehireStates.firstStep,
      secondStep: isEqual(step, INDEX.two) ? updatedData : rehireStates.secondStep,
    }));
  };

  const onSubmit = () => {
    const data = watch();
    const formatData = {
      rehire: formatRehireData(data[employee]),
    };
    dispatch(rehireEmployee(collaborator.id, formatData));
  };

  const handleRolesModal = () => {
    setRehireState({
      isModalOpen: {
        ...rehireStates.isModalOpen,
        rolesInfo: !rehireStates.isModalOpen.rolesInfo,
      },
    });
  };

  const dataRow = (item) => (
    <>
      <Grid item xs={ 12 } md={ isEqual(item.key, OBJECT_KEYS.salary) ? 6 : 12 } >
        <StyledItemText>
          <StyledBold>{`${t(item.label)}: `}</StyledBold>
          { isEqual(item.key, OBJECT_KEYS.salary)
            ? (
              <NumberFormat
                value={ item.lastValue }
                displayType={ INPUT_TYPE.text }
                thousandSeparator
                fixedDecimalScale
                decimalScale={ DEFAULT_MIN_DECIMAL }
                suffix={ ` ${getValueByProp(
                  getNewValue(
                    rehireStates.secondStep,
                    OBJECT_KEYS.currency,
                    rehireStates.codeCurrency,
                  ),
                  OBJECT_KEYS.code,
                )}` }
              />
            ) : getValueByProp(item.lastValue, item.prop) || t("profile:noData")}
        </StyledItemText>
      </Grid>
      {isEqual(item.key, OBJECT_KEYS.salary) && (
        <Grid item xs={ 6 }>
          <StyledItemText>
            {`${t("common:common.oldSalary")}: `}
            {(
              <NumberFormat
                value={ item.firstValue }
                displayType={ INPUT_TYPE.text }
                thousandSeparator
                fixedDecimalScale
                decimalScale={ DEFAULT_MIN_DECIMAL }
                suffix={ ` ${rehireStates.codeCurrency}` }
              />
            ) || INDEX.zero}
          </StyledItemText>
        </Grid>
      )}
    </>
  );

  const previewItems = (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <StyledAvatar>
          {(collaborator && collaborator?.profile_img_url_thumb && collaborator?.profile_img_url) ? (
            <ImageLoader
              src={ collaborator.profile_img_url }
              srcPreview={ collaborator.profile_img_url_thumb }
              customImageComponent={ Avatar }
            />
          ) : collaborator && !collaborator?.profile_img_url_thumb && (
            <Avatar src={ collaborator.profile_img_url } />
          )}
          <Typography variant={ VARIANT.h6 }>{ collaborator?.full_name }</Typography>
        </StyledAvatar>
      </Grid>
      <Grid item xs={ 12 } >
        <StyledItemText>
          <StyledBold>{`${t("common:common.roles.main")}: `}</StyledBold>
          { collaborator?.roles_with_scope?.map((item) => ROLES_NAMES[item.name] && (
            <StyledTag>{ t(ROLES_NAMES[item.name]) }</StyledTag>
          )) }
          <IconButton
            onClick={ handleRolesModal }
          >
            <TooltipIcon
              title={ t("tables:actions.edit") }
              element={ <StyledEditIcon /> }
            />

          </IconButton>
        </StyledItemText>
      </Grid>
      {[
        ...rehireStates.firstStep,
        ...rehireStates.secondStep,
      ].map((item) => dataRow(item))}
      <Grid item xs={ 12 }>
        <StyledItemText>
          <StyledBold>{`${t("common:common.asOf")}: `}</StyledBold>
          {formatDate(rehireStates.date)}
        </StyledItemText>
      </Grid>
    </Grid>
  );

  const allSteps = () => [
    {
      content: (
        <EmployeeForm
          collaborator={ collaborator }
          hookEmployeeForm={ hookEmployeeForm }
        />
      ),
      additionalInNext: sendNextStep,
      disabled: !isEmptyObject(errors) || isNull(watchDate),
      isLoading: isLoadingCollaboratorReducer,
    },
    {
      content: (<SalaryForm collaborator={ collaborator } hookEmployeeForm={ hookEmployeeForm } />),
      additionalInNext: () => sendNextStep(INDEX.two),
      disabled: !isEmptyObject(errors),
      isLoading: isLoadingCollaboratorReducer,
    },
    {
      content: previewItems,
      disabled: !isEmptyObject(errors) || isLoadingProcess,
      isLoading: isLoadingCollaboratorReducer,
    },
  ];

  return (
    <StyledStepsContainer>
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "700px" }>
        <Stepper
          steps={ allSteps() }
          onClick={ onSubmit }
          noShowState
        />
      </StyledScrollBar>
      <RolesModal
        isOpen={ rehireStates.isModalOpen.rolesInfo }
        onClose={ handleRolesModal }
        hasSelected={ rehireStates.employeeRoles?.map((e) => ({
          name: e.value,
          managed_countries: e.countries?.map((country) => ({ id: country.id, name: country.value })),
        })) }
        collaborator={ collaborator }
        allRolesList={ rehireStates.allRolesList }
      />
    </StyledStepsContainer>
  );
};

RehireEmployee.propTypes = {
  id: PropTypes.number.isRequired,
  modalHandler: PropTypes.func.isRequired,
};

export default RehireEmployee;
