import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,

  RESET_STATE_ENGAGEMENT_EVOLUTION,
  GET_LIST_ENGAGEMENT_EVOLUTION,
  GET_LIST_ENGAGEMENT_EVOLUTION_LOADING,
  GET_LIST_ENGAGEMENT_EVOLUTION_ERROR,

  RESET_STATE_ENGAGEMENT_COMMENTS,
  GET_LIST_ENGAGEMENT_COMMENTS,
  GET_LIST_ENGAGEMENT_COMMENTS_LOADING,
  GET_LIST_ENGAGEMENT_COMMENTS_ERROR,

  RESET_STATE_ENGAGEMENT_NPS,
  GET_LIST_ENGAGEMENT_NPS,
  GET_LIST_ENGAGEMENT_NPS_LOADING,
  GET_LIST_ENGAGEMENT_NPS_ERROR,

  RESET_STATE_ENGAGEMENT_SATISFACTION,
  GET_LIST_ENGAGEMENT_SATISFACTION,
  GET_LIST_ENGAGEMENT_SATISFACTION_LOADING,
  GET_LIST_ENGAGEMENT_SATISFACTION_ERROR,

  RESET_STATE_ENGAGEMENT_OVERALL_STATISTICS,
  GET_LIST_ENGAGEMENT_OVERALL_STATISTICS,
  GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_LOADING,
  GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_ERROR,

  RESET_STATE_ENGAGEMENT_PARTICIPATION,
  GET_LIST_ENGAGEMENT_PARTICIPATION,
  GET_LIST_ENGAGEMENT_PARTICIPATION_LOADING,
  GET_LIST_ENGAGEMENT_PARTICIPATION_ERROR,

  RESET_STATE_ENGAGEMENT_TOPIC,
  GET_LIST_ENGAGEMENT_TOPIC,
  GET_LIST_ENGAGEMENT_TOPIC_LOADING,
  GET_LIST_ENGAGEMENT_TOPIC_ERROR,

  RESET_STATE_COLLABORATORS_BY_PROCESS,
  GET_LIST_COLLABORATORS_BY_PROCESS,
  GET_LIST_COLLABORATORS_BY_PROCESS_LOADING,
  GET_LIST_COLLABORATORS_BY_PROCESS_ERROR,
} from "../actionTypes/engagement";

const INITIAL_STATE = {
  list: null,
  isLoadingList: false,
  errorList: "",

  engagementEvolution: null,
  isLoadingEngagementEvolution: false,
  errorEngagementEvolution: null,

  engagementComments: null,
  isLoadingEngagementComments: false,
  errorEngagementComments: null,

  engagementNps: null,
  isLoadingEngagementNps: false,
  errorEngagementNps: null,

  engagementSatisfaction: null,
  isLoadingEngagementSatisfaction: false,
  errorEngagementSatisfaction: null,

  engagementOverall: null,
  isLoadingEngagementOverall: false,
  errorEngagementOverall: "",

  engagementParticipation: null,
  isLoadingEngagementParticipation: false,
  errorEngagementParticipation: "",

  engagementTopic: null,
  isLoadingEngagementTopic: false,
  errorEngagementTopic: "",

  listByProcess: null,
  isLoadingListByProcess: false,
  errorListByProcess: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      errorList: null,
      list: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: [],
      isLoadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case RESET_STATE_ENGAGEMENT_EVOLUTION:
    return {
      ...state,
      isLoadingEngagementEvolution: false,
      errorEngagementEvolution: null,
      engagementEvolution: null,
    };
  case GET_LIST_ENGAGEMENT_EVOLUTION:
    return {
      ...state,
      engagementEvolution: action.payload,
      isLoadingEngagementEvolution: false,
      errorEngagementEvolution: "",
    };
  case GET_LIST_ENGAGEMENT_EVOLUTION_LOADING:
    return {
      ...state,
      engagementEvolution: null,
      isLoadingEngagementEvolution: true,
      errorEngagementEvolution: "",
    };
  case GET_LIST_ENGAGEMENT_EVOLUTION_ERROR:
    return {
      ...state,
      isLoadingEngagementEvolution: false,
      errorEngagementEvolution: action.payload,
    };
  case RESET_STATE_ENGAGEMENT_COMMENTS:
    return {
      ...state,
      isLoadingEngagementComments: false,
      errorEngagementComments: null,
      engagementComments: null,
    };
  case GET_LIST_ENGAGEMENT_COMMENTS:
    return {
      ...state,
      engagementComments: action.payload,
      isLoadingEngagementComments: false,
      errorEngagementComments: "",
    };
  case GET_LIST_ENGAGEMENT_COMMENTS_LOADING:
    return {
      ...state,
      engagementComments: null,
      isLoadingEngagementComments: true,
      errorEngagementComments: "",
    };
  case GET_LIST_ENGAGEMENT_COMMENTS_ERROR:
    return {
      ...state,
      isLoadingEngagementComments: false,
      errorEngagementComments: action.payload,
    };
  case RESET_STATE_ENGAGEMENT_NPS:
    return {
      ...state,
      isLoadingEngagementNps: false,
      errorEngagementNps: null,
      engagementNps: null,
    };
  case GET_LIST_ENGAGEMENT_NPS:
    return {
      ...state,
      engagementNps: action.payload,
      isLoadingEngagementNps: false,
      errorEngagementNps: "",
    };
  case GET_LIST_ENGAGEMENT_NPS_LOADING:
    return {
      ...state,
      engagementNps: null,
      isLoadingEngagementNps: true,
      errorEngagementNps: "",
    };
  case GET_LIST_ENGAGEMENT_NPS_ERROR:
    return {
      ...state,
      isLoadingEngagementNps: false,
      errorEngagementNps: action.payload,
    };
  case RESET_STATE_ENGAGEMENT_SATISFACTION:
    return {
      ...state,
      isLoadingEngagementSatisfaction: false,
      errorEngagementSatisfaction: null,
      engagementSatisfaction: null,
    };
  case GET_LIST_ENGAGEMENT_SATISFACTION:
    return {
      ...state,
      engagementSatisfaction: action.payload,
      isLoadingEngagementSatisfaction: false,
      errorEngagementSatisfaction: "",
    };
  case GET_LIST_ENGAGEMENT_SATISFACTION_LOADING:
    return {
      ...state,
      engagementSatisfaction: null,
      isLoadingEngagementSatisfaction: true,
      errorEngagementSatisfaction: "",
    };
  case GET_LIST_ENGAGEMENT_SATISFACTION_ERROR:
    return {
      ...state,
      isLoadingEngagementSatisfaction: false,
      errorEngagementSatisfaction: action.payload,
    };
  case RESET_STATE_ENGAGEMENT_OVERALL_STATISTICS:
    return {
      ...state,
      isLoadingEngagementOverall: false,
      errorEngagementOverall: null,
      engagementOverall: null,
    };
  case GET_LIST_ENGAGEMENT_OVERALL_STATISTICS:
    return {
      ...state,
      engagementOverall: action.payload,
      isLoadingEngagementOverall: false,
      errorEngagementOverall: "",
    };
  case GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_LOADING:
    return {
      ...state,
      engagementOverall: null,
      isLoadingEngagementOverall: true,
      errorEngagementOverall: "",
    };
  case GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_ERROR:
    return {
      ...state,
      isLoadingEngagementOverall: false,
      errorEngagementOverall: action.payload,
    };
  case RESET_STATE_ENGAGEMENT_PARTICIPATION:
    return {
      ...state,
      isLoadingEngagementParticipation: false,
      errorEngagementParticipation: null,
      engagementParticipation: null,
    };
  case GET_LIST_ENGAGEMENT_PARTICIPATION:
    return {
      ...state,
      engagementParticipation: action.payload,
      isLoadingEngagementParticipation: false,
      errorEngagementParticipation: "",
    };
  case GET_LIST_ENGAGEMENT_PARTICIPATION_LOADING:
    return {
      ...state,
      engagementParticipation: null,
      isLoadingEngagementParticipation: true,
      errorEngagementParticipation: "",
    };
  case GET_LIST_ENGAGEMENT_PARTICIPATION_ERROR:
    return {
      ...state,
      isLoadingEngagementParticipation: false,
      errorEngagementParticipation: action.payload,
    };
  case RESET_STATE_ENGAGEMENT_TOPIC:
    return {
      ...state,
      isLoadingEngagementTopic: false,
      errorEngagementTopic: null,
      engagementTopic: null,
    };
  case GET_LIST_ENGAGEMENT_TOPIC:
    return {
      ...state,
      engagementTopic: action.payload,
      isLoadingEngagementTopic: false,
      errorEngagementTopic: "",
    };
  case GET_LIST_ENGAGEMENT_TOPIC_LOADING:
    return {
      ...state,
      engagementTopic: null,
      isLoadingEngagementTopic: true,
      errorEngagementTopic: "",
    };
  case GET_LIST_ENGAGEMENT_TOPIC_ERROR:
    return {
      ...state,
      isLoadingEngagementTopic: false,
      errorEngagementTopic: action.payload,
    };
  case RESET_STATE_COLLABORATORS_BY_PROCESS:
    return {
      ...state,
      isLoadingListByProcess: false,
      errorListByProcess: null,
      listByProcess: null,
    };
  case GET_LIST_COLLABORATORS_BY_PROCESS:
    return {
      ...state,
      listByProcess: action.payload,
      isLoadingListByProcess: false,
      errorListByProcess: "",
    };
  case GET_LIST_COLLABORATORS_BY_PROCESS_LOADING:
    return {
      ...state,
      listByProcess: null,
      isLoadingListByProcess: true,
      errorListByProcess: "",
    };
  case GET_LIST_COLLABORATORS_BY_PROCESS_ERROR:
    return {
      ...state,
      isLoadingListByProcess: false,
      errorListByProcess: action.payload,
    };
  default:
    return state;
  }
};
