import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import palette from "theme/palette";

export const StyledDragItem = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 10px 0;
  p {
    font-size: 18px;
    line-height: 40px;
    font-weight: 700;
  }
  svg {
    width: 24px;
    color: ${palette.background.mediumGrey};
  }
  svg:hover {
    color: ${palette.primaryApp};
  }
`;

export const StyledItemContainer = styled.div`
  transform: ${(props) => props.transform};
  transition: ${(props) => props.transition};
  align-items: center;
  user-select: none;
  z-index: ${(props) => (props.isDragging ? 100 : "auto")};
  opacity: ${(props) => (props.isDragging ? 0.3 : 1)};
  margin-bottom: 5px;
  .MuiToggleButtonGroup-root {
    margin: 10px 0;
  }
  .MuiAccordionDetails-root {
    border-top: 1px solid ${palette.background.lightBlue};
    padding: 0;
  }
`;

export const StyledDragButton = styled.a`
  margin-right: 10px;
  cursor: grab;
  border-radius: 6px;
  background: none;
  &:hover {
    color: ${palette.primaryApp};
  }
`;

export const StyledAccordion = styled(Accordion)`
  border-radius: 6px;
  border: ${({ expanded }) => (`1.5px solid ${expanded ? palette.background.lightBlue : palette.background.mediumGrey}`)};
  transition: background-color 0.3s;
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  width: 37px;
  height: 37px;
  color: ${(props) => (props.expanded ? palette.primaryApp : palette.background.mediumGrey)};
`;

export const StyledWeighingTitle = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.open ? "1fr" : "auto 56px 30px")};
  gap: 5px;
  justify-content: end;
  -webkit-align-items: center;
  align-items: center;
  margin: ${(props) => (props.open ? "10px 0px" : "5px 0px")} !important;
  padding-right: ${(props) => (props.open ? "20px" : "10px")};
  font-size: 18px;
  font-weight: 700;
  text-align: right;

  span {
    text-align: left;
  }

  .MuiFormControl-root {
    margin-top: 4px;
  }

  input {
    padding: 5px;
    width: 40px;
    text-align: center;
    font-size: 18px;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: 100%;

  .MuiFormControl-root {
    margin-top: 4px;
  }

  input {
    font-size: 18px;
    line-height: 40px;
    font-weight: 700;
    padding: 8px 10px 5px 10px;
    min-width: 400px;
  }

  svg {
    width: 24px;
    color: ${palette.background.inputTags};
  }
`;

export const StyledStack = styled(Stack)`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
`;

export const StyledNewQuestion = styled.div`
  margin: 10px;
`;

export const StyledImage = styled.img`
  width: 120px;
  height: 120px;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: center;
  justify-content: center;
  width: 100%;
`;
