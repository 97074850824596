import makeStyles from "@mui/styles/makeStyles";
import { ENGAGEMENT } from "theme/palette";

const useStyles = makeStyles((theme) => ({
  legend: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2.5),
    alignItems: "center",
  },
  icon: {
    width: 24,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(0.5),
  },
  color: {
    width: 13,
    height: 13,
    borderRadius: "50%",
  },
  satisfied: {
    background: ENGAGEMENT.default,
  },
  neutral: {
    background: ENGAGEMENT.neutral_purple,
  },
  unsatisfied: {
    background: ENGAGEMENT.unsatisfied_purple,
  },
}));

export default useStyles;
