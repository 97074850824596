import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { formatDateExcel, mainDownloadExcel } from "common/utils";
import {
  getListToDownload,
  resetDownloadState,
} from "redux/actions/attritionActions";
import { RISK_CATEGORY, getSeniority } from "../functions";

/* Custom hook to handle the download data to excel of TalentDrain view */
export const useDownload = ({
  t,
  isLoadingDownloadList,
  downloadList,
  states,
}) => {
  const dispatch = useDispatch();
  const handleDownload = () => {
    dispatch(getListToDownload(states.filterQuery));
  };

  const orderByRisk = (data) => {
    let newList = [];
    [
      RISK_CATEGORY.high.key,
      RISK_CATEGORY.medium.key,
      RISK_CATEGORY.low.key,
    ].forEach((category) => {
      newList = [
        ...newList,
        ...data.filter((item) => item.risk_category === category),
      ];
    });
    return newList;
  };

  const formatAttritionData = (data) => orderByRisk(data).map((item) => ({
    [t("tables:headers.riskLevel")]: t(`talentDrain:${item.risk_category}Risk`),
    [t("tables:headers.name")]: item.employee.full_name,
    [t("tables:headers.email")]: item.employee.email,
    [t("tables:headers.position")]: item.employee.position_name,
    [t("tables:headers.manager")]: item.employee.manager_name,
    [t("tables:headers.country")]: item.employee.country_name,
    [t("tables:headers.start_date")]: formatDateExcel(item.employee.starting_date),
    [t("tables:headers.seniority")]: getSeniority(item.employee.starting_date, t).basic,
  }));

  useEffect(() => {
    if (!isLoadingDownloadList && downloadList) {
      const data = formatAttritionData(downloadList);
      mainDownloadExcel(data, t("title"));
      dispatch(resetDownloadState());
    }
    // eslint-disable-next-line
  }, [isLoadingDownloadList, downloadList, dispatch, t]);

  return {
    handleDownload,
  };
};
