import styled from "styled-components";
import palette from "theme/palette";

export const StyledAccordionContainer = styled.div`
  margin-top: 30px;
  & .MuiAccordionSummary-root {
    border-bottom: ${palette.border.light};
  }
  & .MuiCollapse-wrapperInner {
    padding: 16px 0;
  }
  & .MuiPaper-rounded .MuiButtonBase-root {
    border-radius: 0;
  }
  & .MuiAccordion-root {
    box-shadow: none;
  }
`;

export const StyledAccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  padding-left: 0;
  padding-right: 16px;
  align-items: center;
  cursor: pointer;
`;

export const StyledTitle = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const StyledIcon = styled.img`
  width: 38px;
  height: 38px;
`;

export const StyledCardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
`;
