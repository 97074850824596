import { ORG_UNITS } from "common/constants";
import { isEqual, isEmpty, isNotValid } from "common/helpers";
import { getCurrencyFormat, formatDateExcel } from "common/utils";

const getAdditionalInfo = (requestedInfos) => {
  const questionWithAnswer = requestedInfos?.filter((info) => info.answer);
  const infoDetail = [];
  if (!isNotValid(questionWithAnswer)) {
    questionWithAnswer.forEach((item) => {
      infoDetail.push(`${item.name}: ${item.answer}. `);
    });
  }
  return infoDetail.join("");
};

export const getDataExcelCandidates = (
  candidates,
  dynamicAttributesList,
  companyId,
  currencies,
  t,
  // eslint-disable-next-line default-param-last
  country = null,
  isTalentManager,
) => {
  const data = [];
  const candidateList = country ? candidates.filter((item) => isEqual(item.country_name, country)) : candidates;
  candidateList.forEach((candidate, index) => {
    const organizationUnits = candidate.organization_units;

    let department = "";
    let area = "";
    let subarea = "";

    if (organizationUnits) {
      department = organizationUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.division))?.name;
      area = organizationUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.area))?.name;
      subarea = organizationUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.subarea))?.name;
    }

    const ctcInUSD = getCurrencyFormat(
      candidate.projected_ctc,
      candidate.currency,
      currencies,
    );

    const additionalInfo = getAdditionalInfo(candidate.requested_infos);

    const excelData = {
      [t("collaborators:table.table-head.company_id")]: companyId,
      [t("collaborators:table.excel.status")]: t(`candidates:status.${candidate.state}`),
      [t(
        "collaborators:table.table-head.personal_id",
      )]: candidate.personal_id,
      [t("collaborators:table.table-head.name")]: candidate.full_name,
      [t(
        "collaborators:table.table-head.starting_date",
      )]: candidate.starting_date
        ? formatDateExcel(candidate.starting_date)
        : "",
      [t("collaborators:table.excel.email_corp")]: candidate.email,
      [t(
        "collaborators:table.excel.manager_personal_id",
      )]: candidate.manager_personal_id,
      [t("collaborators:table.excel.manager_name")]: candidate.manager_name,
      [t(
        "collaborators:table.table-head.position",
      )]: candidate.position_name,
      [t(
        "collaborators:table.excel.hierarchy_level",
      )]: candidate.hierarchy_level_name,
    };
    if (!isTalentManager) {
      Object.assign(excelData, {
        [t("collaborators:table.excel.contract_type")]: candidate
          .type_of_contract?.name,
      });
    }
    Object.assign(excelData, {
      [t("collaborators:table.excel.company_name")]: candidate.company_name,
      [t("collaborators:table.excel.country")]: candidate.country_name,
      [t("collaborators:table.table-head.city")]: candidate.city_name,
      [t("collaborators:table.excel.department")]: department,
      [t("collaborators:table.excel.area")]: area,
      [t("collaborators:table.excel.subarea")]: subarea,
      [t("collaborators:table.excel.birth_date")]: candidate.birth_date
        ? formatDateExcel(candidate.birth_date)
        : "",
      [t("collaborators:table.excel.gender")]: candidate.gender,
    });
    if (!isTalentManager) {
      Object.assign(excelData, {
        [t("collaborators:table.excel.currency_type")]: candidate.currency,
        [t("collaborators:table.excel.gross_salary")]: Number(
          candidate.gross_salary,
        ),
        [t("collaborators:table.excel.projected_ctc")]: Number(
          candidate.projected_ctc,
        ),
        [t("collaborators:table.excel.projected_ctc_usd")]: Number(ctcInUSD),
      });
    }
    Object.assign(excelData, {
      [t("common:common:personal_mail")]: candidate.personal_email,
      [t("common:common.phone_number")]: candidate.phone_number,
      [t("common:common:nationality")]: candidate.nationality,
      [t("common:common:marital_status.main")]: candidate.marital_status,
      [t("common:common:address")]: candidate.address,
      [t("common:common:linkedin_profile")]: candidate.linkedin_profile,
      [t("common:common:additional_information")]: additionalInfo,
    });
    data.push(excelData);

    if (!isTalentManager && data[index] && !isEmpty(dynamicAttributesList)) {
      dynamicAttributesList.forEach((item) => {
        data[index][item.name] = candidate?.custom_attributes?.find((e) => isEqual(e.dynamic_attribute_id, item.id))?.value || "";
      });
    }

    if (!isTalentManager && data[index] && candidate.benefits_list) {
      candidate.benefits_list.forEach((benefit) => {
        data[index][benefit.name] = benefit.value;
        data[index][`${t("account:accountDetails.benefits.paymentFrequency")} (${benefit.name})`] = benefit.frequency;
      });
    }
  });

  return data;
};
