import styled from "styled-components";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import InputForm from "components/InputForm";
import palette from "theme/palette";

export const StyledGrid = styled(Grid)`
  padding: 30px 0px;
`;

export const StyledContent = styled.div`
  width: 70%;
`;

export const StyledFooter = styled.div`
  a{
    padding: 10px 45px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDialogActions = styled(DialogActions)`
  margin-top: 10px;
  padding: 0px 24px 25px;
`;

export const StyledLabel = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  span {
    font-weight: 400;
    font-size: 12px;
    color: ${palette.text.grayDark};
  }
`;

export const StyledAddLinkGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  .MuiButton-root{
    color: ${palette.text.link};
  }
`;

export const StyledText = styled.div`
  text-transform: capitalize;
`;

export const StyledDialogContent = styled(DialogContent)`
  overflow-y: hidden;
`;

export const StyledInputForm = styled(InputForm)`
    margin-top: 8px;
`;

export const StyledAlert = styled(Typography)`
  font-weight: 500;
  font-size: 11px;
  color: ${palette.text.red};
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
