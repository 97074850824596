import {
  useEffect,
  useState,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { SessionContext } from "modules/session/context";
import Button from "components/Button";
import InputTag from "components/InputTag";
import { getFormattedTags, getEmployeeId } from "common/utils";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES,
  OBJECT_KEYS,
  PAGINATION,
  SIZE,
  TIMEOUT_DISPATCH,
  STATE,
} from "common/constants";
import { saveEvaluationResultAsync, getAudienceListPaginated as getListPaginated } from "redux/actions/surveyProcessesActions";
import {
  StyledDialogTitle, StyledDialogActions, StyledLabel, StyledDialogContent,
} from "./styles";

const PeersModal = (props) => {
  const {
    t,
    isOpen,
    onClose,
    handleSubmit,
    evaluation,
    processId,
    getEvaluationResults,
  } = props;

  const {
    state: { user },
  } = useContext(SessionContext);
  const employeeId = getEmployeeId(user);

  const [searchFilter, setSearchFilter] = useState();
  const [tagsList, setTagsList] = useState([]);

  const {
    collaboratorListByAudience: collaboratorList,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    // Employees already associated with an evaluation result
    const employeeIds = [...new Set(evaluation?.evaluatedList.concat(employeeId))];
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        active_in: [true],
        id_not_in: employeeIds,
      },
    };
    dispatch(getListPaginated(processId, PAGINATION.next, query));
    // eslint-disable-next-line
  }, [searchFilter]);

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  const handleTags = (tags) => {
    setTagsList(tags?.map((tag) => tag.id));
  };

  const onSubmit = async () => {
    try {
      const promises = tagsList.map(async (evaluatedId) => {
        const dataToSave = {
          evaluation_result: {
            evaluation_id: evaluation.id,
            state_transition: STATE.draft,
            evaluated_id: evaluatedId,
          },
        };
        await dispatch(saveEvaluationResultAsync(
          dataToSave,
          processId,
        ));
      });

      await Promise.all(promises);

      toast(MESSAGE_TYPES.success, {
        title: t("surveys:alert.success.title"),
        message: t("surveys:alert.peersAdded"),
      });
      // Update survey results associated with the process
      setTimeout(() => {
        getEvaluationResults(processId, true);
      }, TIMEOUT_DISPATCH);
    } catch (error) {
      getEvaluationResults(processId, true);
    }
  };

  return (
    <div data-testid={ "add-modal-agreement-component" }>
      <Dialog
        open={ isOpen }
        onClose={ onClose }
        maxWidth={ "sm" }
        fullWidth
      >
        <form onSubmit={ handleSubmit(onSubmit) }>
          <StyledDialogTitle disableTypography>
            <h2>{ t("surveys:addPeer") }</h2>
            <IconButton onClick={ onClose }>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <StyledLabel>
                  { t("surveys:peersAdded") }
                </StyledLabel>
                <InputTag
                  id={ "employeeIds" }
                  size={ SIZE.small }
                  placeholder={ t("common:common.searchCollaborators") }
                  onInputTextChange={ handleInputText }
                  onChange={ handleTags }
                  data={ getFormattedTags(collaboratorList, OBJECT_KEYS.fullname) }
                  hasCheckbox
                />
              </Grid>
            </Grid>
          </StyledDialogContent>
          <StyledDialogActions>
            <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ onClose }>
              { t("Onboarding:cancelButton") }
            </Button>
            <Button
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              type={ BUTTON_STYLE_TYPES.SUBMIT }
            >
              { t("common:common.add") }
            </Button>
          </StyledDialogActions>
        </form>
      </Dialog>
    </div>
  );
};

PeersModal.propTypes = {
  t: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  evaluation: PropTypes.object.isRequired,
  processId: PropTypes.number.isRequired,
  getEvaluationResults: PropTypes.func.isRequired,
};

export default PeersModal;
