import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DIRECTION } from "common/constants";
import { StyledStack } from "./styles";

const QuestionActions = ({
  onAdd, onDuplicate, onDelete, editScale, t,
}) => (
  <StyledStack direction={ DIRECTION.column }>
    <Tooltip title={ t("planning:actions.new") } placement={ "left" }>
      <IconButton onClick={ onAdd } aria-label={ "add section" }>
        <AddCircleIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={ t("planning:actions.duplicate") } placement={ "left" }>
      <IconButton onClick={ onDuplicate } aria-label={ "copy section" }>
        <CopyAllIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title={ t("planning:actions.delete") } placement={ "left" }>
      <IconButton onClick={ onDelete } aria-label={ "delete" }>
        <DeleteOutlineIcon />
      </IconButton>
    </Tooltip>
    {editScale && (
      <Tooltip title={ t("planning:templates.evaluation_scales.edit") } placement={ "left" }>
        <IconButton onClick={ editScale } aria-label={ "edit evaluation scale" }>
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
    )}
  </StyledStack>
);

QuestionActions.propTypes = {
  t: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editScale: PropTypes.func,
};

QuestionActions.defaultProps = {
  editScale: null,
};

export default QuestionActions;
