import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  VARIANT, ALIGN_ITEMS, SURVEY_PROCESS_TYPE, EVENT_STATE_KEYS,
} from "common/constants";
import { isEqual } from "common/helpers";
import smileIcon from "assets/images/planning/smile.svg";
import Evaluation from "../Evaluation";
import { STATE_TYPES } from "./functions";
import { StyledSmileIcon, StyledTypography, StyledStateIcon } from "./styles";

const ProcessDetail = (props) => {
  const {
    data, type, t, hasNalaForm,
  } = props;
  const isCompletedProcess = isEqual(data?.status, EVENT_STATE_KEYS.complete);
  const alignDetail = isCompletedProcess ? ALIGN_ITEMS.center : ALIGN_ITEMS.left;

  const showCompleteIcon = () => isCompletedProcess
      && (
        <StyledSmileIcon>
          <img src={ smileIcon } alt={ "Icon" } />
        </StyledSmileIcon>
      );

  const getListEvaluations = () => data?.evaluations?.map(
    (item) => (
      <Evaluation
        key={ item }
        data={ item }
        manager={ data?.manager }
        hasNalaForm={ hasNalaForm }
        t={ t }
      />

    ),
  );

  const getMessage = () => {
    if (isEqual(data?.status, EVENT_STATE_KEYS.doesNotApply)) {
      if (!data.manager && isEqual(type, SURVEY_PROCESS_TYPE.goodLeader.key)) {
        return `${data?.status}.unassigned`;
      }
      return `${data?.status}.by_time`;
    }
    return data?.status;
  };

  return (
    <Grid container spacing={ 2 } data-testid={ "process-detail-view-component" }>
      <Grid item xs={ 12 }>
        <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
          <StyledTypography>
            { `${t("planning:processDetail.title")}:` }
          </StyledTypography>
          <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
            <StyledStateIcon>{ STATE_TYPES[data?.status]?.icon }</StyledStateIcon>
            <Typography variant={ VARIANT.h6 }>
              { t(`planning:processDetail.status.${data?.status}`) }
            </Typography>
          </Box>
        </Box>
        <Typography variant={ VARIANT.bodyTwo }>
          { t("planning:processDetail.subtitle") }
        </Typography>
      </Grid>
      <Grid item xs={ 12 }>
        { showCompleteIcon() }
        <Typography align={ alignDetail }>
          { t(`planning:processDetail.status_detail.${getMessage()}`) }
        </Typography>

        {data && getListEvaluations()}
      </Grid>
    </Grid>
  );
};

ProcessDetail.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  hasNalaForm: PropTypes.bool,
};

ProcessDetail.defaultProps = {
  hasNalaForm: false,
};

export default ProcessDetail;
