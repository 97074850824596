import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StarRating from "components/StarRating";
import { VARIANT, INDEX } from "common/constants";
import { historyPush, getPercent } from "common/utils";
import { isEmpty, isEqual } from "common/helpers";
import { viewLeaderProfile } from "common/functions";
import { ReactComponent as Location } from "assets/images/icons/location.svg";
import { StyledContent, StyledTitleWithSubtitle, StyledText } from "styledComponents/Tables";
import localRoutes from "../../../../../views_refactor/Profile/functions/routes";
import {
  StyledLink,
  StyledGridComments,
  StyledGridComment,
  StyledNoResultContainer,
  StyledNoResult,
  StyledCollaboratorName,
} from "../../../styles";

export const getHeader = (t) => [
  {
    id: "name",
    label: t("tables:headers.name"),
    isParent: true,
  },
  {
    id: "manager",
    label: t("tables:headers.manager"),
    key: "name",
  },
  {
    id: "location",
    label: t("tables:headers.location"),
    key: "name",
  },
  {
    id: "position",
    label: t("tables:headers.position"),
    key: "name",
  },
  {
    id: "good_leader_score",
    label: t("tables:headers.good_leader"),
    // "hasSorting" is used to establish that the column can be sorted,
    // and "sortingColumn" refers to the name of the property by which it will be sorted,
    // that is, it is the value that will be sent later when querying.
    hasSorting: true,
    sortingColumn: "score",
  },
  {
    id: "leadership_score",
    label: t("tables:headers.leadership_score"),
    // "isParent" is added to indicate that there are columns that depend on it, based on this,
    // the "+" is displayed to expand the child columns to the right.
    isParent: true,
    hasSorting: true,
    sortingColumn: "leadership_score",
  },
  // child columns of "leadership_score", a "key" is added to indicate to which column it relates.
  {
    id: "children_nps",
    label: `${t("engagement:nps")} ${t("tables:headers.team")}`,
    key: "leadership_score",
  },
  {
    id: "children_performance",
    label: `${t("tables:headers.performance")} ${t("tables:headers.team")}`,
    key: "leadership_score",
  },
  {
    id: "voluntary_turnover",
    label: `${t("nalaAnalytics:rotation")} ${t("tables:headers.team")}`,
    key: "leadership_score",
  },
  {
    id: "performance",
    label: t("tables:headers.performance"),
    hasSorting: true,
    sortingColumn: "performance_score",
  },
  // end child column
  {
    id: "lossRisk",
    label: t("tables:headers.lossRisk"),
    hasSorting: true,
    sortingColumn: "attrition_probability",
  },
  {
    id: "actions",
    label: "",
  },
];

export const getTab = (history, employeeId, route) => {
  historyPush(
    history,
    route,
    `?collaborator=${employeeId}`,
  );
};

const getComments = (comments) => {
  const totalComments = comments.length;
  const isEvenComments = isEqual(totalComments % INDEX.two, INDEX.zero);
  return comments.map((item, index) => {
    const mdNumber = isEvenComments || (!isEvenComments && !isEqual(totalComments - INDEX.one, index)) ? 6 : 12;
    return (
      <StyledGridComment
        item
        xs={ 12 }
        md={ mdNumber }
        key={ item }
      >
        <Typography variant={ VARIANT.bodyOne }>{item}</Typography>
      </StyledGridComment>
    );
  });
};

export const getRowsDesktop = (data, t, history, extraParam) => {
  const rows = data?.map((rowData) => {
    const comments = rowData.results_by_evaluation_type[INDEX.zero]?.comments;
    const performanceProcess = rowData.employee?.performance_results;
    const leadershipScore = rowData.employee?.leadership_score;
    const isValidToShowNps = leadershipScore?.nps_total_response >= 3;
    return [
      {
        content: (
          <StyledCollaboratorName>
            <StyledContent>
              <Avatar src={ rowData.employee?.profile_img_url } />
              <StyledTitleWithSubtitle pl={ 5 } >
                <StyledLink onClick={ () => getTab(
                  history,
                  rowData.employee.id,
                  localRoutes.root,
                ) }
                >
                  {rowData.employee.full_name}
                </StyledLink>
                <StyledContent>
                  <AssignmentIndIcon />
                  <Typography variant={ VARIANT.bodyOne }>{rowData.employee?.personal_id}</Typography>
                </StyledContent>
              </StyledTitleWithSubtitle>
            </StyledContent>
          </StyledCollaboratorName>
        ),
      },
      {
        content: <StyledText>{ rowData.job_position?.manager_name }</StyledText>,
      },
      {
        content: (
          <StyledTitleWithSubtitle>
            { rowData.job_position?.country?.name
            && (
              <Typography variant={ VARIANT.h6 }>
                {rowData.job_position.country.name}
              </Typography>
            )}
            { rowData.job_position?.city?.name
            && (
              <Typography variant={ VARIANT.bodyOne }>
                <StyledContent mt={ 5 }>
                  <Location />
                  {rowData.job_position.city.name}
                </StyledContent>
              </Typography>
            )}
          </StyledTitleWithSubtitle>
        ),
      },
      {
        content: <StyledText>{ rowData.job_position?.position_name }</StyledText>,
      },
      {
        content: <StyledLink onClick={ () => viewLeaderProfile(history, rowData.employee.id, extraParam) }>{ getPercent(rowData.score, true) }</StyledLink>,
      },
      {
        content: leadershipScore ? getPercent(leadershipScore?.score, true) : "",
      },
      {
        content: isValidToShowNps ? getPercent(leadershipScore.children_nps) : "",
      },
      {
        content: leadershipScore ? getPercent(leadershipScore?.children_performance, true) : "",
      },
      {
        content: leadershipScore ? getPercent(leadershipScore?.children_turnover, true) : "",
      },
      {
        content: performanceProcess?.scale_index_count ? (
          <StarRating
            name={ rowData?.id }
            value={ performanceProcess?.position }
            isReadOnly
            maxRating={ performanceProcess?.scale_index_count }
            max={ performanceProcess?.scale_index_count }
            precision={ 1 }
            label={ performanceProcess?.result }
            hasNoFormattedValue
          />
        ) : "",
      },
      {
        content: rowData.employee?.attrition_chart?.risk_category
          ? (
            <StyledLink onClick={ () => getTab(
              history,
              rowData.employee.id,
              localRoutes.lossRisk,
            ) }
            >
              { t(`talentDrain:${rowData.employee.attrition_chart.risk_category}`) }
            </StyledLink>
          ) : (
            <StyledNoResultContainer>
              <StyledNoResult>{t("onboardingFollowUp:noResult")}</StyledNoResult>
            </StyledNoResultContainer>
          ),
      },
      {
        content: "",
        collapseData: !isEmpty(comments) && <StyledGridComments container spacing={ 2 }>{ getComments(comments) }</StyledGridComments>,
      },
    ];
  });
  return rows;
};
export const getGoodLeaderDataToDownload = (surveyResultsList, t) => {
  const data = [];
  surveyResultsList.forEach((surveyResult, index) => {
    const { employee } = surveyResult;
    const sectionQuestions = surveyResult.results_by_sections_questions;
    const performanceResult = employee?.performance_results?.result;
    const riskCategory = employee?.attrition_chart?.risk_category;
    const leadershipScore = employee?.leadership_score;
    const jobPosition = surveyResult.job_position;
    const isValidToShowNps = leadershipScore?.nps_total_response >= 3;
    data.push({
      [t("collaborators:table.table-head.personal_id")]: employee?.personal_id,
      [t("collaborators:table.table-head.name")]: employee?.full_name,
      [t("collaborators:table.table-head.manager")]: jobPosition?.manager_name,
      [t("collaborators:table.table-head.position")]: jobPosition?.position_name,
      [t("collaborators:table.table-head.country")]: jobPosition?.country?.name,
      [t("collaborators:table.table-head.city")]: jobPosition?.city?.name,
      [t("tables:headers.good_leader")]: getPercent(surveyResult.score),
      [t("tables:headers.leadership_score")]: leadershipScore ? getPercent(leadershipScore?.score, true) : "",
      [`${t("engagement:nps")} ${t("tables:headers.team")}`]: isValidToShowNps ? getPercent(leadershipScore.children_nps) : "",
      [`${t("tables:headers.performance")} ${t("tables:headers.team")}`]: leadershipScore ? getPercent(leadershipScore?.children_performance, true) : "",
      [`${t("nalaAnalytics:rotation")} ${t("tables:headers.team")}`]: leadershipScore ? getPercent(leadershipScore?.children_turnover, true) : "",
      [t("tables:headers.performance")]: performanceResult || "",
      [t("tables:headers.lossRisk")]: riskCategory ? t(`talentDrain:${riskCategory}`) : "",
    });
    if (sectionQuestions) {
      // Create column by sections/question
      sectionQuestions.forEach((sectionQuestion) => {
        sectionQuestion.questions.forEach((question) => {
          data[index][`${question.name} (${sectionQuestion.name})`] = question.result || "";
        });
        if (!sectionQuestion.open) {
          data[index][sectionQuestion.name] = sectionQuestion.result || "";
        }
      });
    }
  });

  return data;
};
