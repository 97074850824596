import styled from "styled-components";
import Box from "@mui/material/Box";

export const StyledBox = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 5px;
`;
