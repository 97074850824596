import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const Title = styled(Typography)`
  font-weight: 700;
  padding: 0 5px 0 5px;
  text-transform: capitalize;
`;
