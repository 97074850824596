import styled from "styled-components";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";

export const StyledTypography = styled(Typography)`
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const StyledTitleBox = styled(Box)`
  text-align: center;
  margin-left: 48px;
  margin-top: 25px;
  font-weight: bold;
  font-size: 32px;
`;

export const StyledDialogActions = styled(DialogActions)`
  margin-top: 20px;
  padding: 0px 24px 25px;
`;

export const StyledDialogContent = styled(DialogContent)`
  overflow-y: hidden;
`;
