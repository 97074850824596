import { useQuery, useQueryClient } from "react-query";
import { getCalibrationReasons } from "redux/actions/surveyProcessesActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

export const useCalibrationReasons = () => {
  const queryClient = useQueryClient();

  const {
    data, isLoading, isError, error,
  } = useQuery("calibrationReasons", getCalibrationReasons, {
    staleTime: Infinity,
    onError: (queryError) => toast(
      MESSAGE_TYPES.error,
      {
        message: queryError.message
          || queryError.toString(),

      },
    ),
  });

  const calibrationReasons = data?.map((reason) => ({
    value: reason.id,
    label: reason.name,
  })) || null;

  return {
    calibrationReasons,
    isLoading,
    isError,
    error,
    queryClient,
  };
};
