import { useTranslation } from "react-i18next";
import { getUncontrollerRender, getDataBreadcrumbs } from "../../functions";
import UncontrolledView from "../../components/UncontrolledView";
import { StyledContainer } from "../../styles";

const UncontrolledModule = (props) => {
  const { t } = useTranslation(["administrator", "common"]);
  const thisModule = props.location?.pathname.split("/")[2];
  const action = props.location?.pathname.split("/")[3];

  return (
    <StyledContainer
      data-testid={ "general-administrator-uncontrolled" }
    >
      <UncontrolledView
        header={ {
          title: action ? t(`modules.${thisModule}.actions.${action}.title`) : t(`modules.${thisModule}.submodule.title`),
          subtitle: action ? t(`modules.${thisModule}.actions.${action}.subtitle`) : t(`modules.${thisModule}.subtitle`),
          breadcrumb: getDataBreadcrumbs(t, thisModule, true),
        } }
        view={ getUncontrollerRender(thisModule, action) }
      />
    </StyledContainer>
  );
};

export default UncontrolledModule;
