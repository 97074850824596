import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const Content = styled(Grid)`
  margin-top: 20px;
`;

export const Title = styled(Typography)`
  margin-top: 25px;
  color: ${palette.title.subtitleColor};
  font-weight: 500;
`;

export const Actions = styled(Grid)`
  text-align: right;
  margin-top: 20px;
`;

export const StyledContainer = styled(Grid)`
  padding-bottom: 40px;
  .MuiFormControlLabel-label {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    width: 80px;
  }
`;
