import styled from "styled-components";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledTitle = styled(Typography)`
  color: ${palette.text.link};
  font-weight: 700;
`;

export const StyledImage = styled.img`
  width: 40px;
  padding-right: 10px;
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledNineboxTitle = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
