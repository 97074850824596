import styled from "styled-components";
import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Brightness1";
import { Typography } from "@mui/material";
import { RISK_CATEGORY_COLORS } from "theme/palette";

export const StyledCardsGrid = styled(Grid)`
  margin-top: 10px;
`;

export const StyledGridContainer = styled(Grid)`
  padding: 10px 10px 0;
  min-height: 290px;
  .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6 {
    color: ${(props) => props.color};
    font-weight: 700;
  }
  .MuiTypography-h4 {
    margin-bottom: 10px;
  }
  .MuiTypography-h6 {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const StyledGrid = styled(Grid)`
  text-align: center;
  border-right: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : "none") };
  min-height: 182px;
`;

export const StyledResult = styled.div`
  font-size: ${(props) => (props.size ? props.size : 30)}px;
  color: ${(props) => props.color};
  font-weight: 700;
  margin: 5px 0;
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 40px;
  }
`;

export const StyledDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-top: 5px;
    width: 36px;
    height: 36px;
  }
  div {
    margin-left: 5px;
  }
`;

export const StyledText = styled.div`
  font-size: 12px;
  align-items: center;
  display: flex;
  margin-top: 5px;
  span {
    margin-right: 5px;
  }
`;

export const StyledComparativeResults = styled.span`
  color: ${(props) => (props.isPositive ? RISK_CATEGORY_COLORS.EXCELLENT : RISK_CATEGORY_COLORS.CRITIC)};
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
`;

export const StyledSubtitle = styled.div`
  color: ${(props) => props.color};
`;

export const StyledData = styled.div`
  text-align: center;
  border-bottom: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : "none") };
  .MuiTypography-h5 {
    font-size: 16px;
  }
  img {
    width: 36px;
    height: 36px;
  }
  @media (max-width: 600px) {
    img {
      width: 36px;
      height: 36px;
    }
  }
`;

export const StyledSubGrid = styled(Grid)`
  &.MuiGrid-container {
    height: 100%;
  }
`;

export const StyledCircleIcon = styled(CircleIcon)`
  color: ${(props) => props.color};
  width: 15px;
  height: 15px;
  margin-right: 4px;
`;

export const StyledNoData = styled.img`
  width: 36px;
  height: 36px;
`;

export const StyledNPSGrid = styled(Grid)`
  &.MuiGrid-item {
    margin: auto;
  }
`;

export const StyledRows = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 2px 4px;
`;

export const StyledRotationTitle = styled(Typography)`
  display: flex;
`;
