import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { iconColor } from "theme/palette";

export const StyledNotification = styled(Typography)`
  h6 {
    color: ${iconColor.warningHexa};
  }
  .MuiAlert-icon {
    align-items: center;
  }
`;
