import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { OBJECT_KEYS } from "common/constants";
import { OVERVIEW_TYPES } from "common/constants/nalaAnalytics";
import useComplexState from "hooks/utils/useComplexState";
import { getFormatData, getMaxValue, getPercentageVariation } from "../functions";

export const useNalaAnalyticsCardsHook = (externalHooks) => {
  const { t } = externalHooks;

  const [cardsStates, setCardsStates] = useComplexState({
    company: {
      rotationList: [],
      lossRisk: null,
      nps: null,
      percentageVariation: {},
    },
    topTalent: {
      rotationList: [],
      lossRisk: null,
      nps: null,
      percentageVariation: {},
    },
    newHires: {
      rotationList: [],
      lossRisk: null,
      nps: null,
      percentageVariation: {},
    },
    query: {},
  });

  const {
    companyOverview, isLoadingCompanyOverview,
    topTalentOverview, isLoadingTopTalentOverview,
    newHiresOverview, isLoadingNewHiresOverview,
  } = useSelector(({ nalaAnalyticsReducer }) => nalaAnalyticsReducer);

  const setCardOverviewByType = useCallback((dataCard, type) => {
    const chartData = dataCard?.turnover_details
      ? getFormatData(t, dataCard.turnover_details) : [];

    setCardsStates({
      [type]: {
        ...cardsStates.company,
        lossRisk: dataCard?.attrition?.length === 0
          ? null : getMaxValue(dataCard.attrition),
        nps: dataCard?.nps,
        rotationList: [...chartData].reverse(),
        percentageVariation: getPercentageVariation(chartData),
      },
    });
  }, [t, setCardsStates, cardsStates]);

  useEffect(() => {
    if (!isLoadingCompanyOverview && companyOverview) {
      setCardOverviewByType(
        companyOverview,
        OVERVIEW_TYPES[OBJECT_KEYS.company],
      );
    }
    // eslint-disable-next-line
  }, [companyOverview, isLoadingCompanyOverview]);

  useEffect(() => {
    if (!isLoadingTopTalentOverview && topTalentOverview) {
      setCardOverviewByType(
        topTalentOverview,
        OVERVIEW_TYPES[OBJECT_KEYS.topTalent],
      );
    }
    // eslint-disable-next-line
  }, [topTalentOverview, isLoadingTopTalentOverview]);

  const handleExternalQuery = (query) => {
    setCardsStates({ query });
  };
  useEffect(() => {
    if (!isLoadingNewHiresOverview && newHiresOverview) {
      setCardOverviewByType(
        newHiresOverview,
        OVERVIEW_TYPES[OBJECT_KEYS.newHires],
      );
    }
    // eslint-disable-next-line
  }, [newHiresOverview, isLoadingNewHiresOverview]);

  return {
    cardsStates,
    nalaAnalyticsLoadings: {
      company: isLoadingCompanyOverview,
      topTalent: isLoadingTopTalentOverview,
      newHires: isLoadingNewHiresOverview,
    },
    handleExternalQuery,
  };
};
