import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import LoaderImageProfile from "views/OnboardingFollowUp/components/LoaderImageProfile";
import { getAvatarPromise, historyPush } from "common/utils";
import { PATHS } from "common/constants";
import {
  StyledImageContainer, StyledTitle,
  StyledSubtitle, StyledLink,
} from "../../styles";

const CollaboratorProfile = ({ collaborator }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;
    const loadImage = async () => {
      setLoading(true);
      try {
        const url = await getAvatarPromise(collaborator);
        if (isMounted) setImageUrl(url);
      } catch (error) {
        if (isMounted) setImageUrl(null);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    loadImage();

    return () => {
      isMounted = false;
    };
  }, [collaborator]);

  const getProfileImage = () => {
    if (loading) return <LoaderImageProfile collaborator={ collaborator } component={ Avatar } />;
    return <Avatar src={ imageUrl } />;
  };

  return (
    <StyledImageContainer>
      {getProfileImage()}
      <StyledLink
        onClick={ () => {
          historyPush(
            history,
            PATHS.profile,
            `${PATHS.search.collaborators}${collaborator?.id}`,
          );
        } }
      >
        <StyledTitle>
          {collaborator.full_name}
        </StyledTitle>
      </StyledLink>
      <StyledSubtitle>
        {collaborator?.job_position?.position_name || collaborator?.position_name}
      </StyledSubtitle>
    </StyledImageContainer>
  );
};

CollaboratorProfile.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorProfile;
