import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Typography from "@mui/material/Typography";
import { getRandomColor } from "common/utils";
import { VARIANT, SIZE } from "common/constants";
import theme from "theme/palette";
import { StyledChip } from "styledComponents/Chip";
import { ReactComponent as NoInfoFace } from "assets/images/tables/noInfoFace.svg";
import { StyledAppliedFilterOption, StyledOptionItems } from "../../styles";

const FilterOption = (props) => {
  const {
    type, title, options, handleDelete, filterName,
  } = props;
  const { t } = useTranslation("talentDrain");

  const tags = isEmpty(options) ? (
    <StyledOptionItems>
      <NoInfoFace />
      <Typography variant={ VARIANT.bodyOne }>{t("filters.nofilter")}</Typography>
    </StyledOptionItems>
  ) : options.map((item) => {
    const indexOfFilter = filterName.indexOf(item.name || item.label);
    return (
      <div key={ `${title}-${item.id}` } >
        <StyledChip
          size={ SIZE.small }
          label={ item.label }
          background={ getRandomColor(indexOfFilter) }
          textcolor={ theme.white }
          onDelete={ () => handleDelete(type, item.id) }
        />
      </div>
    );
  });

  return (
    <StyledAppliedFilterOption data-testid={ "filter-options-component-view" }>
      <Typography variant={ VARIANT.h6 }>{title}</Typography>
      { tags }
    </StyledAppliedFilterOption>
  );
};

FilterOption.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  filterName: PropTypes.array.isRequired,
  title: PropTypes.string,
};

FilterOption.defaultProps = {
  title: "",
};

export default FilterOption;
