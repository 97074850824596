import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TABLE_SIZE, TIMEOUT_DISPATCH } from "common/constants";
import NoDataMessage from "components/NoDataMessage";
import TableToolbar from "./components/TableToolbar";
import TableHeadComponent from "./components/TableHead";
import { getNewSelected, renderTableBody } from "./functions";
import { useStyles } from "./styles";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const TableCheckbox = (props) => {
  const classes = useStyles();
  const {
    id,
    header,
    data,
    toolbarTipTitle,
    toolbarTipIcon,
    toolbarOnClick,
    isPulse,
    isToolbarAction,
    findBy,
  } = props;
  const [selected, setSelected] = useState([]);
  const [counter, setCounter] = useState(3); //FIXME: not implemented YET

  const prevData = usePrevious({ data, counter });

  useEffect(() => {
    if (counter > 0) {
      const timeout = setTimeout(() => {
        setCounter(counter - 1);
      }, TIMEOUT_DISPATCH);

      return () => {
        clearTimeout(timeout);
      };
    } else if (prevData?.data !== data) {
      setCounter(3);
    }
  }, [data, prevData, counter]);

  useEffect(() => {
    setSelected([]);
  }, [isToolbarAction]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[findBy]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    setSelected(getNewSelected(selected, id));
  };

  const isSelected = (id) => !isEqual(selected.indexOf(id), -1);
  return (
    <div className={classes.root} data-testid={"table-checkbox"}>
      <Paper className={classes.paper}>
        <TableToolbar
          tooltipTitle={toolbarTipTitle}
          tooltipIcon={toolbarTipIcon}
          numSelected={selected.length}
          itemsSelected={selected}
          onClick={toolbarOnClick}
        />
        <TableContainer>
          <Table id={id} className={classes.table} size={TABLE_SIZE.small}>
            <TableHeadComponent
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data?.length}
              headCells={header}
            />
            <TableBody>
              {renderTableBody(
                data,
                isSelected,
                findBy,
                isPulse,
                counter,
                classes,
                handleClick,
                header
              )}
              {isEmpty(data) && (
                <TableRow>
                  <TableCell colSpan={header?.length + 1}>
                    <NoDataMessage />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

TableCheckbox.propTypes = {
  id: PropTypes.string,
  header: PropTypes.array,
  data: PropTypes.array,
  toolbarTipTitle: PropTypes.string,
  toolbarTipIcon: PropTypes.any,
  toolbarOnClick: PropTypes.func,
  isPulse: PropTypes.bool,
  isToolbarAction: PropTypes.bool,
  findBy: PropTypes.string,
};

export default TableCheckbox;
