import styled from "styled-components";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import palette from "theme/palette";

export const StyledHelpIcon = styled(HelpIcon)`
  color: ${palette.text.darkBlue};
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
`;

export const TooltipStyled = withStyles((theme) => ({
  popper: {
    zIndex: 9999,
  },
  tooltipArrow: {
    background: theme.palette.background.lightBlack,
    fontSize: theme.spacing(1.5),
  },
  arrow: {
    color: theme.palette.background.lightBlack,
  },
}))(Tooltip);

export const useStyles = makeStyles((theme) => ({
  emptySpan: {
    color: theme.palette.text.transparent,
  },
}));
