import { Grid } from "@mui/material";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledScore = styled.h2`
  font-weight: 700;
  font-size: 40px;
  color: ${palette.text.grayStrong};
`;

export const StyledResult = styled.h4`
  font-weight: 700;
  color: ${palette.text.grayStrong};
`;

export const StyledInfoTitle = styled.p`
  color: ${palette.text.link};
  font-weight: bold;
  margin-bottom: 0;
  font-size: 14px;
`;

export const StyledTeam = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 18px;
  svg {
    color: ${palette.text.link};
    font-size: 18px;
  }
`;

export const StyledTeamResult = styled(Grid)`
  margin-top: 10px;
  p {
    color: ${palette.black};
  }
`;
