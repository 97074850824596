import Box from "@mui/material/Box";
import styled from "styled-components";

export const StyledContainer = styled(Box)`
  h4 {
    margin-bottom: 20px;
  }
  h5 {
    margin: 10px 0;
  }
`;
