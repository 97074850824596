import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useComplexState from "hooks/utils/useComplexState";
import { getOne as getSurveyProcess } from "redux/actions/surveyProcessesActions";
import { getCalendarData, getEventsFromDate } from "../functions";

export const usePlanningCalendarHook = (externalHooks, internalHooks) => {
  const { dispatch, t } = externalHooks;
  const { setFormStates } = internalHooks;
  const currentDate = new Date();
  const [calendarStates, setCalendarStates] = useComplexState({
    data: null,
    currentYear: currentDate.getFullYear(),
    values: "",
  });

  const {
    list, loadingList, loadingProcess, successProcess,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const handleEventToUpdate = (eventToUpdate) => {
    // scroll because the form is at the beginning of the same page
    window.scrollTo(1, 1);
    const eventId = eventToUpdate.id;
    setFormStates({
      processId: eventId,
      processType: eventToUpdate.processType,
    });
    // search process to edit
    dispatch(getSurveyProcess(eventToUpdate.processType.value, eventId));
  };

  const handleEvent = (events) => {
    setCalendarStates({
      values: events,
    });
  };

  // events of the day
  const setEventsByCurrentDate = useCallback((calendarData) => {
    const generalData = getEventsFromDate(calendarData, currentDate);
    const allData = {
      events: generalData,
      date: currentDate,
      endDate: null,
    };

    setCalendarStates({
      values: allData,
    });

    setCalendarStates({
      currentYear: currentDate.getFullYear(),
    });
  }, [currentDate, setCalendarStates]);

  // reset events on calendar if there is any change
  const refreshData = useCallback(() => {
    const calendarData = getCalendarData(list, t);
    setEventsByCurrentDate(calendarData);
    setCalendarStates({
      data: calendarData,
    });
  }, [list, t, setEventsByCurrentDate, setCalendarStates]);

  // set current year
  const handleControls = (year) => {
    setCalendarStates({
      currentYear: year,
    });
  };

  // event to be updated
  const handleItemSelected = (eventToUpdate) => {
    handleEventToUpdate(eventToUpdate);
  };

  useEffect(() => {
    if (!loadingList && list) {
      refreshData();
    }
    // eslint-disable-next-line
  }, [loadingList, list]);

  useEffect(() => {
    if (successProcess) {
      setCalendarStates({
        data: null,
      });
    }
  // eslint-disable-next-line
  }, [successProcess]);

  return {
    calendarStates,
    setCalendarStates,
    surveysReducerStates: {
      list,
      loadingList,
      successProcess,
      loadingProcess,
    },
    handleControls,
    handleItemSelected,
    setEventsByCurrentDate,
    handleEvent,
  };
};
