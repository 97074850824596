import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledTitle = styled(Typography)`
  font-size: 26px;
  font-weight: 700;
  color: ${(props) => (props.isSelected ? palette.primaryApp : palette.black)};
  svg {
    width: 20px !important;
    height: 20px !important;
    color: ${palette.background.gray};
    margin-right: 20px;
  }
`;

export const StyledOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  padding: 12px;
  min-height: 65px;
  border: ${palette.border.simpleThin};
  border-radius: 4px;
  box-shadow: ${palette.boxShadow.card3};
  &.active {
    background-color: ${palette.background.lightBlue};
  }
  .Mui-checked {
    color: ${palette.primaryApp};
  }
  svg {
    width: 2em;
    height: 2em;
    color: ${palette.primaryApp};
  }
`;

export const StyledFullWidthButton = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAccordionOption = styled.div`
  width: 100%;
  padding: 12px 0;
`;

export const StyledAccordion = styled(Accordion)`
  border: ${palette.border.simpleThin};
  border-radius: 4px;
  background-color: ${({ isSelected }) => (isSelected ? palette.background.lightBlue : "transparent")};
  transition: background-color 0.3s;
  svg {
    width: 2em;
    height: 2em;
    color: ${palette.primaryApp};
  }
  &.active .Mui-expanded {
    background-color: ${palette.background.lightBlue};
  }
  .MuiTab-root {
    padding: 10px 20px;
    font-size: 12px;
  }
`;
