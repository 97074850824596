import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { FORM_MODE, OBJECT_KEYS } from "common/constants";
import { getValueByProp, formatDateMovementToSubmit } from "common/utils";
import useComplexState from "hooks/utils/useComplexState";
import {
  create as createMovementHistory,
} from "redux/actions/historicalMovementActions";
import {
  resetManagerDefaultValues,
} from "../functions/profile";

export const useManagerHook = (externalHooks, internalHooks) => {
  const { dispatch } = externalHooks;
  const {
    collaborator,
  } = internalHooks;

  const [managerStates, setManagerStates] = useComplexState({
    isEditable: false,
  });

  const employee = "collaborator"; // TODO: change this to dynamic when candidate view is ready

  const {
    isLoadingProcess,
  } = useSelector(({ historicalMovementsReducer }) => historicalMovementsReducer);

  const {
    control, handleSubmit, reset,
  } = useForm({
    defaultValues: resetManagerDefaultValues(employee),
    mode: FORM_MODE.onChange,
  });

  useEffect(() => {
    reset(resetManagerDefaultValues(employee, collaborator));
    // eslint-disable-next-line
  }, [collaborator]);

  const handleEditManager = () => {
    setManagerStates({ isEditable: !managerStates.isEditable });
  };

  const onSubmit = (data) => {
    const { manager, job_position: jobPosition } = collaborator;
    const lastManagerId = manager?.id;
    const managerId = getValueByProp(data[employee].manager_id, OBJECT_KEYS.id);

    if (lastManagerId !== managerId) {
      const {
        position, country, city,
        hierarchy_level: hierarchyLevel,
        type_of_contract: typeOfContract,
        organization_units: orgUnits,
      } = jobPosition || {};
      const orgUnitIds = orgUnits?.map(({ id }) => id);

      const lateralMovementManagerData = {
        date: formatDateMovementToSubmit(new Date()),
        type: OBJECT_KEYS.lateralMovement,
        job_position_attributes: {
          manager_id: managerId,
          position_id: position?.id,
          country_id: country?.id,
          city_id: city?.id,
          hierarchy_level_id: hierarchyLevel?.id,
          organization_unit_ids: orgUnitIds,
          type_of_contract_id: typeOfContract?.id,
        },
      };
      dispatch(createMovementHistory(lateralMovementManagerData, collaborator.id));
    }
  };

  return {
    managerStates,
    hookManagerForm: {
      control,
      handleSubmit,
      reset,
      isLoadingProcess,
    },
    onManagerSubmit: onSubmit,
    handleEditManager,
  };
};
