import {
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import {
  SKELETONS_NUMBER, VARIANT, TIMEOUT_DISPATCH,
} from "common/constants";
import { isNull, isEmpty } from "common/helpers";
import {
  getDaysFromToday,
  hasPreviewImage,
  getMaxFollowUpDays,
} from "common/utils";
import { getOne as getCollaborator, getTeamByManager, resetStateOne } from "redux/actions/collaboratorActions";
import { getList as getSurveyProcesses, getSurveyProcessByUser } from "redux/actions/surveyProcessesActions";
import { getList as getFollowUpList } from "redux/actions/followUpActions";
import { SessionContext } from "modules/session/context";
import { StyledPaperContainer } from "styledComponents/View";
import { getFollowUpProcess } from "../../functions";
import LoaderImageProfile from "../LoaderImageProfile";
import TeamDetail from "../TeamDetail";
import {
  StyledContainer,
  StyledProfileImageContainer,
  StyledContent,
  StyledDays,
  StyledProfileImage,
  StyledProcessContainer,
} from "../../styles";

const OverView = (props) => {
  const { collaboratorId } = props;
  const { t } = useTranslation(["onboardingFollowUp", "planning"]);
  const [team, setTeam] = useState(null);
  const [surveyProcesses, setSurveyProcesses] = useState(null);

  const dispatch = useDispatch();

  const {
    state: { user },
  } = useContext(SessionContext);
  const selectCollaboratorId = collaboratorId || user.employee?.id;

  const {
    one: collaboratorData,
    isLoadingOne: isLoadingCollaboratorData,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    followList: surveyProcessList,
    loadingList: isLoadingSurveyProcessList,
  } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );

  const {
    list: followUpList,
    isLoadingList: isLoadingFollowUpList,
  } = useSelector(({ followUpReducer }) => followUpReducer);

  const getSurveyResults = useCallback(async () => {
    if (selectCollaboratorId) {
      setSurveyProcesses(await dispatch(getSurveyProcessByUser(selectCollaboratorId, true)));
    } else {
      dispatch(getSurveyProcesses(true));
    }
  }, [selectCollaboratorId, dispatch]);

  useEffect(() => {
    dispatch(resetStateOne());
    dispatch(getFollowUpList());
    getSurveyResults();
  }, [getSurveyResults, dispatch]);

  useEffect(() => {
    if (isNull(collaboratorData)) {
      dispatch(getCollaborator(selectCollaboratorId));
    }
  }, [collaboratorId, collaboratorData, selectCollaboratorId, dispatch]);

  const getTeamData = useCallback(async () => {
    let teamList = [];
    const managerData = collaboratorData?.manager;
    if (managerData?.id) {
      teamList = await dispatch(getTeamByManager(managerData.id, collaboratorData?.id));
      managerData.isManager = true;
      teamList.unshift(managerData);
    }
    setTeam(teamList);
  }, [collaboratorData, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (!isLoadingCollaboratorData && collaboratorData && isNull(team)) {
        getTeamData();
      }
    }, TIMEOUT_DISPATCH);
  }, [isLoadingCollaboratorData, collaboratorData, getTeamData, team]);

  const hasData = !isLoadingCollaboratorData && !isLoadingSurveyProcessList && !isLoadingFollowUpList && team;

  const processes = selectCollaboratorId && surveyProcesses && !isEmpty(surveyProcesses) ? surveyProcesses : surveyProcessList;

  const getSurveyDetail = (startingDate) => {
    if (!isEmpty(followUpList)) {
      return getFollowUpProcess(followUpList, processes, startingDate, isNull(collaboratorId));
    }
    return <NoDataMessage />;
  };

  const overViewTab = () => {
    const teamData = team ? <TeamDetail team={ team } /> : <NoDataMessage />;
    const startingDate = collaboratorData?.starting_date;
    const surveyProcess = getSurveyDetail(startingDate);
    const days = getDaysFromToday(startingDate);
    const maxFollowUpDays = getMaxFollowUpDays(followUpList);
    const hasFollowUpValidDays = maxFollowUpDays >= days;
    const percentDays = hasFollowUpValidDays ? (days * 100) / maxFollowUpDays : 100;

    const profileImage = collaboratorData && hasPreviewImage(collaboratorData)
      ? (
        <LoaderImageProfile
          collaborator={ collaboratorData }
          component={ StyledProfileImage }
        />
      )
      : <StyledProfileImage src={ collaboratorData?.profile_img_url } />;

    return (
      <StyledContainer container spacing={ 2 }>
        <Grid item xs={ 12 } md={ 4 } >
          <ViewHeaderTitle
            title={ t("onboardingFollowUp:team.title") }
            subtitle={ t("onboardingFollowUp:team.subtitle") }
          />
          <StyledPaperContainer
            minheight={ "325px" }
            maxheight={ "325px" }
            elevation={ 0 }
            padding={ "25px" }
            marginTop={ "0px" }
          >
            {teamData}
          </StyledPaperContainer>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <ViewHeaderTitle
            title={ t("onboardingFollowUp:tasks.title") }
            subtitle={ t("onboardingFollowUp:tasks.subtitle") }
          />
          <StyledProcessContainer>
            {surveyProcess}
          </StyledProcessContainer>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <StyledPaperContainer minheight={ "390px" } elevation={ 0 }>
            <StyledProfileImageContainer>
              <StyledDays>
                {hasFollowUpValidDays
                  ? `${t("planning:calendar.day")} ${days}/${maxFollowUpDays}`
                  : `${days} ${t("planning:calendar.days")}`}
              </StyledDays>
              <CircularProgress variant={ VARIANT.determinate } value={ percentDays } />
              {profileImage}
            </StyledProfileImageContainer>
            <StyledContent>
              <h3>{collaboratorData?.full_name}</h3>
              <p>{collaboratorData?.job_position?.position_name}</p>
            </StyledContent>
          </StyledPaperContainer>
        </Grid>
      </StyledContainer>
    );
  };

  const overViewDetail = hasData ? overViewTab() : <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FOUR } />;

  return overViewDetail;
};

OverView.propTypes = {
  collaboratorId: PropTypes.number,
};

OverView.defaultProps = {
  collaboratorId: null,
};

export default OverView;
