import styled from "styled-components";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  padding: 32px;
  margin-bottom: 25px;
`;

export const StyledTypographyTitle = styled(Typography)`
  &.MuiTypography-body1 {
    color: ${palette.text.secondaryTitle};
    font-size: 38px;
    margin-top: 25px;
    font-weight: bold;
    line-height: 38px;
    margin-bottom: 15px;
  }
`;
