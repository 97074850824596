import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ThumbsUpDown from "@mui/icons-material/ThumbsUpDown";
import StarsIcon from "@mui/icons-material/Stars";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import {
  FEEDBACK_TYPE, FORM_MODE, INDEX, OBJECT_KEYS,
} from "common/constants";
import { isEmpty, isEqual, isNull } from "common/helpers";
import { getEmployeeId, getValueByProp, replacePath } from "common/utils";
import useComplexState from "hooks/utils/useComplexState";
import {
  getList as getFeedback,
  getList as getFeedbacksByEmployeeIdAndType,
  sendFeedback,
  resetStateProcess,
} from "redux/actions/feedbackActions";
import {
  getList as getAcknowledgementType,
  create as sendAcknowledgement,
  resetStateFeedback as resetStateAcknowledgement,
} from "redux/actions/acknowledgementTypeActions";
import { getListSearchPaginated } from "redux/actions/collaboratorActions";
import FeedbackIcon from "assets/images/feedback/feedback.svg";
import AcknowledgementIcon from "assets/images/feedback/acknowledgement.svg";
import { FEEDBACKABLES, INDEX_TABS_SEND } from "../functions";
import { getSelectedModal } from "../functions/profile";
import localRoutes from "../functions/routes";

export const useFeedbackHook = (externalHooks, internalHooks) => {
  const {
    t, history, dispatch, location, user,
  } = externalHooks;
  const { collaborator } = internalHooks;

  const [feedbackStates, setFeedbackStates] = useComplexState({
    tab: INDEX.zero,
    isModalOpen: {
      feedback: false,
      addFeedback: false,
      addAcknowledgment: false,
    },
    isShowAllData: false,
    feedbackTab: INDEX.zero,
    typeSelected: FEEDBACK_TYPE.feedbackSimpleSN,
    searchFilter: "",
    hasEmployee: false,
    selectedAcknowledgmentItem: null,
    isDisabledFeedbackSimple: true,
    isDisabledFeedbackSSC: true,
  });

  const {
    listSent: feedbacksSent,
    isLoadingListSent,
    listReceived: feedbacksReceived,
    isLoadingListReceived,
    successProcess,
    isLoadingProcess,
    errorProcess,
  } = useSelector(({ feedbackReducer }) => feedbackReducer);

  const {
    list: acknowledgementTypesList,
    successProcessFeedback: successProcessAcknowledgementTypes,
    isLoadingProcessFeedback: isLoadingProcessAcknowledgementTypes,
  } = useSelector(({ acknowledgementTypesReducer }) => acknowledgementTypesReducer);

  const {
    searchList: collaboratorsList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    reValidateMode: FORM_MODE.onChange,
    mode: FORM_MODE.onChange,
  });

  const handleFeedbackTab = (value) => {
    setFeedbackStates({
      tab: value,
    });
  };

  useEffect(() => {
    if (!isEqual(location.pathname, localRoutes.root)) {
      getSelectedModal(location.pathname).then((resolve) => {
        const { path, key } = resolve;
        replacePath(path);
        setFeedbackStates({
          isModalOpen: {
            ...feedbackStates.isModalOpen,
            [key]: true,
          },
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isNull(feedbacksSent)
      && isNull(feedbacksReceived)
      && isNull(acknowledgementTypesList)
      && collaborator?.id
    ) {
      dispatch(getFeedback(collaborator?.id));
      dispatch(getFeedback(collaborator?.id, true));
      dispatch(getAcknowledgementType());
    }
  // eslint-disable-next-line
  }, [collaborator, feedbacksSent, feedbacksReceived, acknowledgementTypesList]);

  const handleOnClickViewLessOrMore = () => {
    setFeedbackStates({
      isShowAllData: !feedbackStates.isShowAllData,
    });
  };

  const getIconFeedback = (type) => {
    switch (type) {
    case FEEDBACKABLES.feedbackSimple:
      return FeedbackIcon;
    case FEEDBACKABLES.feedbackAcknowledgement:
      return AcknowledgementIcon;
    default:
      return FeedbackIcon;
    }
  };

  const feedbackActions = [
    {
      title: t("feedback:buttons.send_feedback"),
      icon: <ThumbsUpDown />,
      onClick: () => replacePath(history, localRoutes.addFeedback),
    },
    {
      title: t("feedback:buttons.send_acknowledgment"),
      icon: <StarsIcon />,
      onClick: () => replacePath(history, localRoutes.addAcknowledgment),
    },
  ];

  const showAlertErrorOrSuccess = (isError) => {
    const toastMessage = {
      message: t(`feedback:${isError ? "error_feedback" : "send_feedback_successfully"}`),
      title: t(`common:common.api_responses.${isError ? "error.title" : "success.title"}`),
    };
    toast(MESSAGE_TYPES.success, toastMessage, t);
  };

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: feedbackStates.searchFilter,
        active_in: [true],
        id_not_in: [collaborator?.id],
      },
    };
    dispatch(getListSearchPaginated(query));
  }, [feedbackStates.searchFilter, collaborator, dispatch]);

  useEffect(() => {
    if (!isLoadingProcess && successProcess) {
      showAlertErrorOrSuccess(false);
      replacePath(history, localRoutes.feedback);
      dispatch(resetStateProcess());
      dispatch(getFeedbacksByEmployeeIdAndType(collaborator.id, true));
    } else if (!isLoadingProcess && errorProcess) {
      showAlertErrorOrSuccess(true);
    }
    // eslint-disable-next-line
  }, [
    history,
    isLoadingProcess,
    successProcess,
    errorProcess,
    dispatch,
    collaborator,
  ]);

  const handleValueChanged = (value) => {
    setFeedbackStates({
      feedbackTab: value,
      typeSelected: isEqual(value, INDEX_TABS_SEND.simple)
        ? FEEDBACK_TYPE.feedbackSimpleSN : FEEDBACK_TYPE.feedbackSSCSN,
    });
  };

  const handleInputText = (text) => {
    setFeedbackStates({ searchFilter: text });
  };

  const onFeedbackSumit = (formData) => {
    if (formData) {
      if (feedbackStates.hasEmployee) {
        const fromId = getEmployeeId(user);
        formData[feedbackStates.typeSelected].feedback_attributes = {
          employee_from_id: fromId,
        };
        formData[feedbackStates.typeSelected].feedback_attributes.employee_to_id = collaborator.id;
      } else {
        formData[feedbackStates.typeSelected].feedback_attributes.employee_from_id = collaborator.id;
        formData[feedbackStates.typeSelected].feedback_attributes.employee_to_id = getValueByProp(
          formData[feedbackStates.typeSelected].feedback_attributes.employee_to_id,
          OBJECT_KEYS.id,
        );
      }
      dispatch(sendFeedback(formData, feedbackStates.typeSelected));
      setFeedbackStates({ searchFilter: "" });
    }
  };

  const onAcknowledgmentSubmit = (formData) => {
    if (formData) {
      if (feedbackStates.hasEmployee) {
        const fromId = getEmployeeId(user);
        formData.feedback_acknowledgement.feedback_attributes = {
          employee_from_id: fromId,
        };
        formData.feedback_acknowledgement.feedback_attributes.employee_to_id = collaborator.id;
      } else {
        formData.feedback_acknowledgement.feedback_attributes.employee_from_id = collaborator.id;
        formData.feedback_acknowledgement.feedback_attributes.employee_to_id = getValueByProp(
          formData.feedback_acknowledgement.feedback_attributes.employee_to_id,
          OBJECT_KEYS.id,
        );
      }
      formData.feedback_acknowledgement.acknowledgement_type_id = Number(formData.feedback_acknowledgement.acknowledgement_type_id);
      dispatch(sendAcknowledgement(formData));
      setFeedbackStates({ searchFilter: "" });
    }
  };

  useEffect(() => {
    if (!isLoadingProcessAcknowledgementTypes && successProcessAcknowledgementTypes) {
      dispatch(resetStateAcknowledgement());
      replacePath(history, localRoutes.feedback);
      showAlertErrorOrSuccess(false);
      dispatch(getFeedbacksByEmployeeIdAndType(collaborator?.id || collaborator, true));
    }
    // eslint-disable-next-line
  }, [successProcessAcknowledgementTypes, dispatch, collaborator, showAlertErrorOrSuccess, isLoadingProcessAcknowledgementTypes]);

  const handleAcknowledgementSelected = (id) => {
    setFeedbackStates({ selectedAcknowledgmentItem: id });
  };

  const validateInputEditByLabel = (label) => (value) => {
    setFeedbackStates({ [label]: isEmpty(value) || false });
  };

  return {
    handleFeedbackTab,
    handleOnClickViewLessOrMore,
    getIconFeedback,
    showAlertErrorOrSuccess,
    feedbackStates,
    feedbackData: {
      feedbacksSent,
      isLoadingListSent,
      feedbacksReceived,
      isLoadingListReceived,
    },
    acknowledgementTypesList,
    feedbackActions,
    // Add feedback and acknowledgment
    hookFeedbackForm: {
      control,
      handleSubmit,
      isValid,
    },
    feedbackProcess: {
      successProcess,
      errorProcess,
      isLoadingProcess,
    },
    collaboratorsList,
    acknowledgementTypesProcess: {
      successProcessAcknowledgementTypes,
      isLoadingProcessAcknowledgementTypes,
    },
    handleValueChanged,
    handleInputText,
    onFeedbackSumit,
    onAcknowledgmentSubmit,
    handleAcknowledgementSelected,
    validateInputEdit: validateInputEditByLabel("isDisabledFeedbackSimple"),
    validateInputSSCEdit: validateInputEditByLabel("isDisabledFeedbackSSC"),
  };
};
