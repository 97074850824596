import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import Grid from "@mui/material/Grid";
import { SessionContext } from "modules/session/context";
import {
  ALIGN_ITEMS,
  BASE_URL_HR_ANALYTICS,
  COMPANY_SLUGS, DIRECTION,
  IFRAME_URL_TRAINING_DASHBOARD,
  VARIANT,
} from "common/constants";
import { StyledContent, StyledTypography } from "./styles";

const TrainingDashboard = () => {
  const { t } = useTranslation("common");
  const {
    state: { user },
  } = useContext(SessionContext);

  const getIframeUrl = () => {
    let url = BASE_URL_HR_ANALYTICS;
    if (user?.company?.slug === COMPANY_SLUGS.blu) {
      url += IFRAME_URL_TRAINING_DASHBOARD.blu;
    }
    return url;
  };

  return (
    <StyledContent data-testid={ "training-dashboard-view-component" }>
      <StyledTypography variant={ VARIANT.h3 }>
        {t("common.trainingDashboard")}
      </StyledTypography>
      <Grid
        container
        direction={ DIRECTION.row }
        justify={ ALIGN_ITEMS.center }
        alignItems={ ALIGN_ITEMS.center }
      >
        <Iframe
          url={ getIframeUrl() }
          width={ "100%" }
          allowFullScreen
        />
      </Grid>
    </StyledContent>
  );
};

export default TrainingDashboard;
