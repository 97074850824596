import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledContainerLoader } from "./styles";

const CircleLoader = (props) => {
  const { size } = props;
  return (
    <StyledContainerLoader container>
      <Grid item xs={ 12 }>
        <CircularProgress size={ size } />
      </Grid>
    </StyledContainerLoader>
  );
};

CircleLoader.propTypes = {
  size: PropTypes.number,
};

CircleLoader.defaultProps = {
  size: 90,
};

export default CircleLoader;
