import { clarity } from "react-microsoft-clarity";
import posthog from "posthog-js";
import {
  LOCAL_STORAGE_NAMES,
  TIMEOUT_DISPATCH,
  ROLES,
  TRACKING_TAGS,
} from "common/constants";
import { getCurrentLanguage, getItemFromLocalStorage } from "common/utils";

// User attributes script
export const setUserAttributes = (trackingType) => {
  // await until language is loaded
  const language = new Promise((resolve) => {
    const interval = setInterval(() => {
      if (getCurrentLanguage()) {
        clearInterval(interval);
        resolve(getCurrentLanguage());
      }
    }, TIMEOUT_DISPATCH);
  });
  const user = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user);
  const isManager = user?.roles_name?.includes(ROLES.MANAGER);
  const isAdmin = user?.roles_name?.includes(ROLES.ADMIN);
  const isNalaAdmin = user?.roles_name?.includes(ROLES.ADMIN_NALA);
  const isVacationAdmin = user?.roles_name?.includes(ROLES.VACATION_ADMIN);
  const isOnboardingAdmin = user?.roles_name?.includes(ROLES.ONBOARDING_ADMIN);
  const isTalentManager = user?.roles_name?.includes(ROLES.TALENT_MANAGER);
  // send only result of language promise
  language.then((lang) => {
    const userAttributes = {
      language: lang,
      id: user?.id,
      is_manager: isManager,
      is_admin: isAdmin,
      is_nala_admin: isNalaAdmin,
      is_vacation_admin: isVacationAdmin,
      is_onboarding_admin: isOnboardingAdmin,
      is_talent_manager: isTalentManager,
      company: user?.company?.name,
    };
    if (user?.employee?.id) {
      const userId = user.id.toString();
      const employeeId = user.employee.id.toString();
      if (trackingType === "hotjar") {
        window.hj("identify", user.id, userAttributes);
      } else if (trackingType === "clarity") {
        clarity.identify(employeeId, { id: employeeId });

        if (user?.roles_name) {
          user.roles_name.forEach((roleName) => {
            clarity.setTag(TRACKING_TAGS.rol, roleName);
          });
        }
        clarity.setTag(TRACKING_TAGS.userId, userId);
        clarity.setTag(TRACKING_TAGS.language, lang);
        clarity.setTag(TRACKING_TAGS.company, user.company?.name);
        clarity.setTag(TRACKING_TAGS.employeeId, employeeId);
      } else if (trackingType === "posthog") {
        posthog.identify(employeeId, {
          id: employeeId,
          [TRACKING_TAGS.userId]: userId,
          [TRACKING_TAGS.language]: lang,
          [TRACKING_TAGS.company]: user.company?.name,
          [TRACKING_TAGS.employeeId]: employeeId,
          roles: user?.roles_name || [],
        });
      }
    }
  });
};
