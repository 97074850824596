import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { NINEBOX } from "common/constants";
import { isNotValid } from "common/helpers";
import { extractTextWithoutNumber } from "common/utils";
import { getDataBox } from "../NineBox/functions";
import useStyles, { StyledQuadrantName } from "./styles";

const SimpleNineBox = (props) => {
  const { quadrantSelected } = props;
  const classes = useStyles();
  const { t } = useTranslation(["potential", "common", "performance"]);

  const dataBox = getDataBox(t);

  const cleanedQuadrantText = quadrantSelected === null ? "" : extractTextWithoutNumber(
    dataBox[quadrantSelected][NINEBOX.quadrantData.name],
  );

  const getValueRect = (value, isSelectedQuadrant, type) => {
    const isMaxQuadrant = quadrantSelected === 2;
    let rectValue = value;
    if (isSelectedQuadrant) {
      if (type === "x") {
        rectValue = value + 1.8;
      } else if (type === "y") {
        rectValue = isMaxQuadrant ? value - 12 : value + 1.7;
      } else if (type === "width") {
        rectValue = isMaxQuadrant ? value + 9.5 : value - 4;
      } else if (type === "height") {
        rectValue = isMaxQuadrant ? value + 9.5 : value - 4;
      }
    }
    return rectValue;
  };

  return (
    <Box data-testid={ "simple-nine-box" }>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } className={ classes.nineBoxContainer }>
          <Grid className={ classes.nineBox }>
            <svg
              className={ clsx(
                classes.generalSvg,
              ) }
              viewBox={ NINEBOX.generalPaddingViewBox }
              width={ 150 }
              height={ 150 }
            >
              {dataBox
                && !isNotValid(dataBox)
                && dataBox.map((item, index) => {
                  const isQuadrantSelected = quadrantSelected === index;
                  return (
                    <g key={ `box${index}` }>
                      <rect
                        x={ getValueRect(
                          item[NINEBOX.quadrantData.x],
                          isQuadrantSelected,
                          "x",
                        ) }
                        y={ getValueRect(
                          item[NINEBOX.quadrantData.y],
                          isQuadrantSelected,
                          "y",
                        ) }
                        width={ getValueRect(
                          item[NINEBOX.quadrantData.width],
                          isQuadrantSelected,
                          "width",
                        ) }
                        height={ getValueRect(
                          item[NINEBOX.quadrantData.width],
                          isQuadrantSelected,
                          "height",
                        ) }
                        className={ isQuadrantSelected
                          ? classes.rectBoxActive
                          : classes.rectBox }
                      />
                    </g>
                  );
                })}
            </svg>
          </Grid>
        </Grid>
      </Grid>
      <StyledQuadrantName>{cleanedQuadrantText}</StyledQuadrantName>
    </Box>
  );
};

SimpleNineBox.propTypes = {
  quadrantSelected: PropTypes.number.isRequired,
};

export default SimpleNineBox;
