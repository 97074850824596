import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import StarRating from "components/StarRating";
import InputTextController from "components/InputTextController";
import { getPercent } from "common/utils";
import { orderByDesc } from "common/helpers";
import { SIZE, TYPES } from "common/constants";
import { StyledTableContainer, StyledTableHead } from "./styles";

const ResultScaleTable = (props) => {
  const {
    resultScaleIndices, control, t, hasIcon, isDisabled,
  } = props;

  const [resultScaleIndicesDesc, setResultScaleIndicesDesc] = useState(null);

  useEffect(() => {
    setResultScaleIndicesDesc(orderByDesc(resultScaleIndices, "top"));
  }, [resultScaleIndices]);

  return (
    <StyledTableContainer component={ Paper } hasIcon={ hasIcon }>
      <Table aria-label={ "customized table" }>
        <StyledTableHead>
          <TableRow>
            {hasIcon && <TableCell>{ t("planning:result_scale.table.icon") }</TableCell> }
            <TableCell>
              { t("planning:result_scale.table.label_es") }
              <EditIcon />
            </TableCell>
            <TableCell>
              { t("planning:result_scale.table.label_en") }
              <EditIcon />
            </TableCell>
            <TableCell>
              { t("planning:result_scale.table.label_pt") }
              <EditIcon />
            </TableCell>
            <TableCell>{ t("planning:result_scale.table.scale") }</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {resultScaleIndicesDesc && resultScaleIndicesDesc.map((row, index) => (
            <TableRow key={ row.result_es }>
              {hasIcon
              && (
                <TableCell>
                  <StarRating
                    name={ `star-rating-${index}` }
                    value={ resultScaleIndicesDesc.length - index }
                    isReadOnly
                    maxRating={ resultScaleIndicesDesc.length }
                    max={ resultScaleIndicesDesc.length }
                    precision={ 1 }
                    hasNoFormattedValue
                  />
                </TableCell>
              )}
              <TableCell>
                <InputTextController
                  key={ `${row.id}_${row.result_es}` }
                  type={ TYPES.text }
                  control={ control }
                  name={ `result_scale_indices_attributes.${row.id}_result_es` }
                  defaultValue={ row.result_es }
                  size={ SIZE.small }
                  disabled={ isDisabled }
                />
              </TableCell>
              <TableCell>
                <InputTextController
                  key={ `${row.id}_${row.result_en}` }
                  type={ TYPES.text }
                  control={ control }
                  name={ `result_scale_indices_attributes.${row.id}_result_en` }
                  defaultValue={ row.result_en }
                  size={ SIZE.small }
                  disabled={ isDisabled }
                />
              </TableCell>
              <TableCell>
                <InputTextController
                  key={ `${row.id}_${row.result_pt}` }
                  type={ TYPES.text }
                  control={ control }
                  name={ `result_scale_indices_attributes.${row.id}_result_pt` }
                  defaultValue={ row.result_pt }
                  size={ SIZE.small }
                  disabled={ isDisabled }
                />
              </TableCell>
              <TableCell>{`${getPercent(row.bottom, true)} - ${getPercent(row.top, true)}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

ResultScaleTable.propTypes = {
  control: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  resultScaleIndices: PropTypes.array.isRequired,
  hasIcon: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ResultScaleTable;
