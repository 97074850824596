export const getDefaultValuesItem = (data) => ({
  "item.section_id": data.section_id,
  "item.id": null,
  "item.title": "",
  "item.description": "",
  "item.url": "",
  "item.file": "",
});

export const getNewFormat = (data) => data.map((item) => ({
  id: item.id,
  label: item.name,
  name: item.name,
  required: item.required,
}));

export const resetFormData = (data) => ({
  section_id: data.section_id,
  name: data.name,
  description: data.description,
  url: data.url,
});
