import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DoughnutChart from "components/DoughnutChart";
import { CARD_RESULT } from "theme/palette";
import {
  ALIGN_ITEMS, INDEX, VARIANT,
} from "common/constants";
import { isEqual } from "common/helpers";
import {
  StyledCardContainer,
  StyledCard,
  StyledCardContent,
  StyledIcon,
  StyledText,
  StyledContent,
} from "./styles";

const StatisticsCard = (props) => {
  const {
    title,
    subTitle,
    participation,
    icon,
    color,
    hasSymbol,
    isFullWidth,
    detailList,
  } = props;

  return (
    <StyledCardContainer marginTop={ 10 } data-testid={ "statistics-card-component" }>
      <StyledCard
        color={ color }
        isFullWidth={ isFullWidth }
      >
        <StyledCardContent >
          <Grid container spacing={ 2 }>
            <Grid item xs={ detailList ? 6 : 8 }>
              <Box pb={ 2 } display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
                <StyledIcon src={ icon } alt={ title } size={ 50 } />
                <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.baseline }>
                  <StyledText fontSize={ 36 } color={ color } isBold >
                    {participation?.total}
                  </StyledText>
                  <StyledText fontSize={ 18 }>
                    {participation?.max ? `/${participation?.max}` : ""}
                  </StyledText>
                </Box>
              </Box>
              <Box mt={ 1 }>
                <StyledText fontSize={ 14 }>
                  {title}
                </StyledText>
                <StyledText fontSize={ 16 } isBold>
                  {subTitle}
                </StyledText>
              </Box>
            </Grid>
            <Grid item xs={ detailList ? 6 : 4 }>
              {detailList ? (
                detailList.map((item, index) => (
                  <StyledContent key={ item }>
                    <Typography variant={ VARIANT.h5 }>{ item.title }</Typography>
                    <Box pb={ 1 } display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
                      <StyledIcon src={ item.icon } alt={ title } size={ 24 } />
                      <StyledText fontSize={ 24 } color={ isEqual(index, INDEX.zero) ? color : CARD_RESULT.normal } isBold >
                        {item.total}
                      </StyledText>
                    </Box>
                  </StyledContent>
                ))
              ) : (
                <DoughnutChart
                  participation={ participation }
                  color={ color }
                  hasSymbol={ hasSymbol }
                />
              )}
            </Grid>
          </Grid>
        </StyledCardContent>
      </StyledCard>
    </StyledCardContainer>
  );
};

StatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  participation: PropTypes.object.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  hasSymbol: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  detailList: PropTypes.array,
};

StatisticsCard.defaultProps = {
  color: CARD_RESULT.purpleCard,
  hasSymbol: true,
  isFullWidth: false,
  detailList: null,
};

export default StatisticsCard;
