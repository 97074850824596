import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { isEqual } from "common/helpers";
import { PAGINATION, MIN_VALUE, VARIANT } from "common/constants";
import ButtonAddTable from "components/ButtonAddTable";
import {
  StyledButtonPaginator, StyledPaginationContainer, StyledAddButton, StyledActionCell,
} from "../../styles";

const Paginator = (props) => {
  const {
    total, pagination, handlePagination, actions,
  } = props;
  const { t } = useTranslation("tables");

  const limitPagination = pagination * PAGINATION.maxPerPage >= total
    ? total
    : pagination * PAGINATION.maxPerPage;

  const paginationLegend = `${
    total < PAGINATION.limited
      ? ""
      : `${(pagination - PAGINATION.next) * PAGINATION.maxPerPage + PAGINATION.next} - `
  }${limitPagination} ${t("tables:footer.of")} ${total}`;

  const handleCreation = () => {
    actions.addNew(actions.module, actions.parentId);
  };

  return !isEqual(total, MIN_VALUE) && (
    <StyledActionCell onlyOne={ !actions }>
      {actions && (
        <StyledAddButton>
          <ButtonAddTable
            variant={ VARIANT.outlined }
            onClick={ handleCreation }
            icon={ <AddCircleOutlineIcon /> }
            text={ t("common:common.add") }
            isDisabled={ actions?.isDisabled }
          />
        </StyledAddButton>
      )}
      {pagination && (
        <StyledPaginationContainer>
          <div>{paginationLegend}</div>
          <StyledButtonPaginator
            onClick={ () => handlePagination(-1) }
            isDisabled={ isEqual(pagination, 1) }
          >
            <ChevronLeft />
          </StyledButtonPaginator>
          <StyledButtonPaginator
            onClick={ () => handlePagination(1) }
            isDisabled={ isEqual(limitPagination, total) }
          >
            <ChevronRight />
          </StyledButtonPaginator>
        </StyledPaginationContainer>
      )}
    </StyledActionCell>
  );
};

Paginator.propTypes = {
  pagination: PropTypes.number,
  handlePagination: PropTypes.func,
  actions: PropTypes.object,
  total: PropTypes.number,
};

Paginator.defaultProps = {
  pagination: null,
  handlePagination: null,
  actions: null,
  total: null,
};

export default Paginator;
