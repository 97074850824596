import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { useStyles } from "./styles";
import { useForm } from "react-hook-form";
import InputTextController from "components/InputTextController";
import { BUTTON_STYLE_TYPES } from "common/constants";

const ReminderForm = (props) => {
  const { id, action } = props;
  const { t } = useTranslation(["candidates", "common"]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    action(id, data);
  };

  return (
    <div data-testid="reminder-form">
      <form
        autoComplete="off"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputTextController
          type="text"
          label={t("alertMessage.message")}
          control={control}
          multiline={5}
          name="message"
          required={true}
        />
        <div className={classes.actionButton}>
          <Button
            variant="contained"
            autoFocus
            type="submit"
            typeStyle={BUTTON_STYLE_TYPES.SUBMIT}
            isLoading={loading}
          >
            {t("common:common.submit")}
          </Button>
        </div>
      </form>
    </div>
  );
};
ReminderForm.propTypes = {
  id: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
};

export default ReminderForm;
