import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DateInputController from "components/DateInputController";
import SelectController from "components/SelectController";
import CurrencyInputTextController from "components/CurrencyInputTextController";
import AutoCompleteCreable from "components/AutocompleteCreable";
import { getItemFromLocalStorage, getAutocompleteAttr } from "common/utils";
import { LOCAL_STORAGE_NAMES } from "common/constants";
import { getTypeOfContract } from "../../../../functions";

const SalaryPromotion = (props) => {
  const {
    employee,
    control,
    register,
    editable,
    classes,
    values,
    isDisabled,
    validations,
  } = props;

  const { t } = useTranslation(["common", "accounts"]);
  const typesOfContract = getItemFromLocalStorage(
    LOCAL_STORAGE_NAMES.typeOfContract
  );
  const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);

  const [currency, setCurrency] = useState(values.currency);

  const onAutocompleteChange = (nameOfAttr) => (prop, event, newValue) => {
    const autocompleteAttr = getAutocompleteAttr(newValue, nameOfAttr);
    setCurrency(!isNull(newValue) ? autocompleteAttr : "");
  };

  const salaryTitleInfo = editable && (
    <Grid item xs={12} sm={6}>
      <Typography className={classes.subtitleText}>
        {t("account:accountDetails.salary_information")}
      </Typography>
      <Typography variant="subtitle2">
        {t("account:accountDetails.all_required")}
      </Typography>
    </Grid>
  );

  return (
    <Grid item xs={12} data-testid={"salary-promotion-section"}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {salaryTitleInfo}
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInputController
            id="starting_date"
            label={t("common:common.startingDate")}
            control={control}
            name={`${employee}.starting_date`}
            openTo={"year"}
            value={null}
            views={["year", "month", "day"]}
            disabled={!editable || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectController
            id="type_of_contract"
            label={t("common:common.typeOfContract")}
            control={control}
            name={`${employee}.job_position_attributes.type_of_contract_id`}
            menuItems={getTypeOfContract(typesOfContract)}
            disabled={!editable}
            customStyles={validations.contract ? classes.pulse : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CurrencyInputTextController
            id="salary"
            label={t("common:common.salary")}
            isDisabled={!editable}
            control={control}
            name={`${employee}.salary_attributes.gross_salary`}
            customStyles={validations.salary ? classes.pulse : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoCompleteCreable
            id={"currency"}
            options={currencies}
            label={t("common:common.currency.main")}
            register={register}
            disabled={!editable}
            onChange={onAutocompleteChange("name_with_code")}
            name={`${employee}.salary_attributes.currency`}
            value={currency}
            nameOfAttr={"name_with_code"}
            isRequired={!isEmpty(currency)}
            customStyles={validations.currency ? classes.pulse : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInputController
            id="date"
            label={t("common:common.promotion_starting_date")}
            control={control}
            name={`${employee}.date`}
            openTo={"year"}
            value={new Date()}
            views={["year", "month", "day"]}
            disabled={!editable}
            customStyles={validations.movementDate ? classes.pulse : ""}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

SalaryPromotion.propTypes = {
  employee: PropTypes.string,
  control: PropTypes.func,
  register: PropTypes.func,
  editable: PropTypes.bool,
  classes: PropTypes.object,
  values: PropTypes.object,
  isDisabled: PropTypes.bool,
  validations: PropTypes.object,
};

export default SalaryPromotion;
