import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import {
  NEXT_PAGE, PREV_PAGE, INDEX, VARIANT, ALIGN_ITEMS,
} from "common/constants";
import { StyledIconButton, Title } from "./styles";

const Years = (props) => {
  const { currentYear, onClick } = props;

  const handleYear = (control) => {
    onClick(currentYear + ((control === NEXT_PAGE) ? INDEX.one : -INDEX.one));
  };

  return (
    <Box data-testid={ "years-component" } display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
      <StyledIconButton
        disabled={ currentYear === INDEX.one }
        onClick={ () => handleYear(PREV_PAGE) }
        aria-label={ PREV_PAGE }
      >
        <NavigateBeforeOutlinedIcon />
      </StyledIconButton>
      <Title variant={ VARIANT.h4 }>
        { currentYear }
      </Title>
      <StyledIconButton
        onClick={ () => handleYear(NEXT_PAGE) }
        aria-label={ NEXT_PAGE }
      >
        <NavigateNextOutlinedIcon />
      </StyledIconButton>
    </Box>
  );
};

Years.propTypes = {
  currentYear: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Years;
