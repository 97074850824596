import styled from "styled-components";
import palette from "theme/palette";

export const RadioContainer = styled.div`
  display: grid;
  .MuiFormControlLabel-label{
    color: ${palette.title.subtitleColor};
  }
  .MuiFormControlLabel-labelPlacementBottom{
    margin: 0;
  }
`;

export const RadioIcon = styled.img`
  padding: 10px;
  border-radius: 8px;
  margin: 0 auto;
  width: 72px;
  height: 72px;
  border: ${(props) => (props.selected ? palette.border.radioSelected : palette.border.radio)};
  box-shadow: ${(props) => (props.selected ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none")};
`;
