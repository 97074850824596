import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { COLORS_NAME, INDEX, MIN_PERCENTAGE_TO_RECOMMEND } from "common/constants";
import theme, { CARD_RESULT } from "theme/palette";
import {
  StyledAccordeonContainer,
  StyledAccordeonTitleContainer,
  StyledAccordeonDetailContainer,
  StyledAccordeonTitle,
  StyledAccordeonIcon,
} from "styledComponents/Accordeon";

const Card = (props) => {
  const {
    title,
    percentage,
    children,
    isEmpty,
    icon,
  } = props;
  const hasBorderLeft = percentage.split("%")[INDEX.zero] >= MIN_PERCENTAGE_TO_RECOMMEND;

  return (
    <StyledAccordeonContainer
      data-testid={ "recommendations-card-view-component" }
      expanded={ isEmpty }
      borderRadius={ "4px" }
      isBorderLeft={ hasBorderLeft }
      isBorderRadius
      borderLeft={ `8px solid ${CARD_RESULT.greenCard}` }
    >
      <StyledAccordeonTitleContainer
        expandIcon={ <ExpandMoreIcon color={ COLORS_NAME.primary } /> }
        background={ theme.background.light }

      >
        <StyledAccordeonIcon>{icon}</StyledAccordeonIcon>
        <StyledAccordeonTitle color={ theme.text.secondaryTitle }>{title}</StyledAccordeonTitle>
        <StyledAccordeonTitle color={ theme.text.secondaryTitle } percentage>{percentage}</StyledAccordeonTitle>

      </StyledAccordeonTitleContainer>
      <StyledAccordeonDetailContainer background={ theme.background.light }>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </StyledAccordeonDetailContainer>
    </StyledAccordeonContainer>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Card;
