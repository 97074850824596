import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { mainDownloadExcel, mainDownloadExcelCustom } from "common/utils";
import {
  BUTTON_STYLE_TYPES, TYPES, SIZE, ALIGN_ITEMS,
} from "common/constants";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { StyledContainer, StyledButton } from "./styles";

const DownloadExcelButton = (props) => {
  const {
    isDisabled, onClick, customStyle, isDownloadApart, children, isLoading, className,
  } = props;
  const { t } = useTranslation("potential");

  const [isLoadingPromise, setIsLoadingPromise] = useState(false);

  const handleClick = () => {
    if (isDownloadApart) {
      onClick();
    } else {
      const result = onClick();
      if (result instanceof Promise) {
        setIsLoadingPromise(true);
        result.then((downloadData) => {
          setIsLoadingPromise(false);
          if (downloadData) {
            const [excelData, filename, isCustom, isMultiple] = downloadData;
            if (isCustom) {
              mainDownloadExcelCustom(excelData, filename, t);
            } else {
              mainDownloadExcel(excelData, filename, isMultiple);
            }
          }
        }).catch(() => {
          setIsLoadingPromise(false);
        });
      } else if (result) {
        const [excelData, filename, isCustom, isMultiple] = result;
        if (isCustom) {
          mainDownloadExcelCustom(excelData, filename, t);
        } else {
          mainDownloadExcel(excelData, filename, isMultiple);
        }
      }
    }
  };

  const getName = () => (children || t("potential:download-excel"));

  const buttonProps = customStyle ? { customStyle } : {
    variant: BUTTON_STYLE_TYPES.OUTLINED,
    type: TYPES.text,
    size: SIZE.small,
    icon: <CloudDownloadIcon />,
    iconSide: ALIGN_ITEMS.left,
  };

  return (
    <div data-testid={ "downloadExcelButton" } className={ className }>
      <StyledContainer>
        {/* TODO: Need to improve the way isLoading is displayed */}
        <StyledButton
          isDisabled={ isDisabled || isLoadingPromise }
          isLoading={ isLoading }
          onClick={ handleClick }
          { ...buttonProps }
        >
          {getName()}
        </StyledButton>

      </StyledContainer>
    </div>
  );
};

DownloadExcelButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyle: PropTypes.string,
  isDownloadApart: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

DownloadExcelButton.defaultProps = {
  isDisabled: false,
  onClick: () => {},
  customStyle: null,
  isDownloadApart: false,
  children: null,
  isLoading: false,
  className: "",
};
export default DownloadExcelButton;
