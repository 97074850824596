// hc
export const RESET_STATE_HC_MONTHLY = "RESET_STATE_HC_MONTHLY";
export const GET_HC_MONTHLY = "GET_HC_MONTHLY";
export const GET_HC_MONTHLY_LOADING = "GET_HC_MONTHLY_LOADING";
export const GET_HC_MONTHLY_ERROR = "GET_HC_MONTHLY_ERROR";

// ctc
export const RESET_STATE_CTC_MONTHLY = "RESET_STATE_CTC_MONTHLY";
export const GET_CTC_MONTHLY = "GET_CTC_MONTHLY";
export const GET_CTC_MONTHLY_LOADING = "GET_CTC_MONTHLY_LOADING";
export const GET_CTC_MONTHLY_ERROR = "GET_CTC_MONTHLY_ERROR";
