import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import palette, { STATE_COLORS, iconColor } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  checkIcon: {
    color: iconColor.successHexa,
  },
  failedIcon: {
    color: STATE_COLORS.ERROR,
  },
  circular: {
    color: theme.palette.text.link,
  },
  nalaLogoMin: {
    position: "absolute",
    top: "calc(50% - 40px)",
    left: "calc(50% - 15px)",
  },
  textTitle: {
    margin: 5,
  },
  textSubtitle: {
    margin: 5,
  },

  modal: {
    minWidth: "300px",
    minHeight: "100px",
  },
  "@media (max-width: 540px)": {
    nalaLogoMin: {
      top: "calc(50% - 50px)",
    },
    textTitle: {
      fontSize: 20,
    },
    textSubtitle: {
      fontSize: 14,
    },
  },
}));

export const StyledContent = styled.div`
  padding: 24px
`;

export const StyledTitle = styled(Typography)`
  color: ${ palette.black };
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  padding-top: 8px;
  padding-bottom: 16px;
`;
