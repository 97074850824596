export const getAccordionIndex = (result, index) => (result.customRender
    && result.customRender.props
    && result.customRender.props.data
    && result.customRender.props.data.index
  ? result.customRender.props.data.index
  : index);

export const getAccordionData = (result) => (result.customRender
    && result.customRender.props
    && result.customRender.props.data
  ? result.customRender.props.data
  : "");
