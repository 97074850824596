import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import SkeletonLoader from "components/SkeletonLoader";
import Button from "components/Button";
import NoDataMessage from "components/NoDataMessage";
import {
  COLORS_NAME, SKELETONS_NUMBER, SKELETON_VARIANT, VARIANT,
} from "common/constants";
import { isEmpty } from "common/helpers";
import AcknowledgmentFormModal from "views/Account/components/Feedback/components/AcknowledgmentFormModal";
import AcknowledgmentCard from "../AcknowledgmentCard";
import {
  StyledCardContainer,
  StyledTitle, StyledSubtitle,
  StyledBold,
} from "../../styles";
import { StyledActions, StyledMessage } from "./styles";

const TopAcknowledgments = (props) => {
  const {
    t,
    acknowledgmentStates,
    acknowledgementReducerStates,
    employeeId,
    companyId,
    isEnable,
    isMobile,
    handleOpenAcknowledgement,
    showAlertErrorOrSuccess,
  } = props;

  const { rankingList, isLoadingRankingList } = acknowledgementReducerStates;
  const { isOpen } = acknowledgmentStates;

  const content = isLoadingRankingList ? (
    <>
      <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.ONE } />
      <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.ONE } isInline={ false } />
      <SkeletonLoader
        numberOfSkeletons={ SKELETONS_NUMBER.FIVE }
        variant={ SKELETON_VARIANT.rectangular }
        height={ 100 }
        columnWidth={ 12 }
      />
    </>
  ) : (
    <>
      <StyledTitle>
        <StarOutlineIcon />
        <Typography>{ t("home:acknowledgments.title") }</Typography>
      </StyledTitle>
      <StyledSubtitle>
        <StyledBold>{ `${t("home:acknowledgments.firstSubtitle")} ` }</StyledBold>
        { t("home:acknowledgments.secondSubtitle") }
      </StyledSubtitle>
      {isEmpty(rankingList) ? (
        <StyledMessage>
          <NoDataMessage message={ t("home:noData") } isHappyFace />
        </StyledMessage>
      ) : rankingList?.map((item, index) => (
        <AcknowledgmentCard key={ `card-${index}` } data={ item } index={ index + 1 } isMobile={ isMobile } />
      ))}
      {isEnable && (
        <StyledActions isCenter={ isEmpty(rankingList) }>
          <Button
            onClick={ handleOpenAcknowledgement }
            variant={ VARIANT.contained }
            color={ COLORS_NAME.primary }
          >
            { t("common:common.send_acknowledgment") }
          </Button>
        </StyledActions>
      )}
      <AcknowledgmentFormModal
        employeeId={ employeeId }
        companyId={ companyId }
        isOpen={ isOpen }
        onClose={ handleOpenAcknowledgement }
        onSend={ showAlertErrorOrSuccess }
      />
    </>
  );

  return (
    <StyledCardContainer data-testid={ "ranking-view-component" }>
      { content }
    </StyledCardContainer>
  );
};

TopAcknowledgments.propTypes = {
  t: PropTypes.func.isRequired,
  acknowledgmentStates: PropTypes.object.isRequired,
  acknowledgementReducerStates: PropTypes.object.isRequired,
  employeeId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  isEnable: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleOpenAcknowledgement: PropTypes.func.isRequired,
  showAlertErrorOrSuccess: PropTypes.func.isRequired,
};

export default TopAcknowledgments;
