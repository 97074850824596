import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputTag from "components/InputTag";
import {
  toast, handleMessages, MESSAGE_TYPES, HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import { COLORS_NAME, SIZE, STATE } from "common/constants";
import { isEmpty, isEmptyObject } from "common/helpers";
import { getNewFormat } from "views/Account/functions/documents";
import { StyledIconButton, StyledItem, StyledSwitch } from "./styles";

const DocumentsList = (props) => {
  const {
    id, objectLabels, setValue, data, handleAdd, defaultValues, isDocument,
  } = props;
  const { t } = useTranslation("Onboarding");
  const [newDocument, setNewDocument] = useState({});
  const [removeDocument, setRemoveDocument] = useState({});
  const [inputTextValue, setInputTextValue] = useState("");
  const [inputTagsSelected, setInputTagsSelected] = useState([]);

  useEffect(() => {
    setInputTagsSelected(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (data && !isEmptyObject(newDocument)) {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      /** TODO: Refactor this assignment, data is a props and push method
       * to add newDocument is a bad practice because we are mutating the props
       * we should use a state to handle this or a callback function to update
       */
      data.push(newDocument);
      setInputTagsSelected([...inputTagsSelected, newDocument]);
      setNewDocument({});
    }

    if (!isEmptyObject(removeDocument)) {
      const newList = inputTagsSelected.filter((item) => item !== removeDocument);
      setInputTagsSelected(newList);
      setRemoveDocument({});
    }
    setValue(
      inputTagsSelected.map((item) => ({
        id: item.id,
        idParent: item.idParent,
        required: item.required,
      })),
    );
  }, [t, data, removeDocument, newDocument, inputTagsSelected, setValue]);

  const handleTags = (addedValues) => {
    setInputTagsSelected(addedValues);
  };

  const handleAddDocument = async () => {
    if (!isEmpty(inputTextValue)) {
      const newItem = await handleAdd(isDocument, { name: inputTextValue });
      if (newItem && !isEmptyObject(newItem)) {
        setNewDocument(getNewFormat([newItem])[0]);
      }
    }
  };

  const handleInputText = (text) => setInputTextValue(text);

  const handleInputData = () => getNewFormat(data);

  const handleChange = (index, item) => {
    const newState = [...inputTagsSelected];
    newState[index].required = !item.required;
    setInputTagsSelected(newState);
  };

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } sm={ 6 }>
        <InputTag
          id={ id }
          label={ objectLabels.select }
          size={ SIZE.small }
          itemsSelected={ inputTagsSelected }
          onChange={ handleTags }
          data={ handleInputData() }
          hasCheckbox
          textAddDinamicTag={ objectLabels.add }
          onAddDinamicTag={ handleAddDocument }
          onInputTextChange={ handleInputText }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <Grid container spacing={ 2 }>
          {inputTagsSelected.map((field, index) => (
            <Grid item xs={ 12 } sm={ 6 } key={ field.name }>
              <StyledItem>
                <Grid item xs={ 10 }>
                  {field.name}
                </Grid>
                <Grid item xs={ 2 }>
                  <StyledIconButton
                    onClick={ () => setRemoveDocument(field) }
                  >
                    <DeleteIcon />
                  </StyledIconButton>
                </Grid>
                <Grid item xs={ 4 }>
                  <FormControlLabel
                    labelPlacement={ STATE.start }
                    label={ t("required") }
                    control={ (
                      <StyledSwitch
                        color={ COLORS_NAME.primary }
                        checked={ field.required }
                        onChange={ () => handleChange(index, field) }
                      />
                    ) }
                  />
                </Grid>
              </StyledItem>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

DocumentsList.propTypes = {
  setValue: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  handleAdd: PropTypes.func.isRequired,
  id: PropTypes.string,
  objectLabels: PropTypes.object,
  isDocument: PropTypes.bool,
  defaultValues: PropTypes.array,
};

DocumentsList.defaultProps = {
  id: "",
  objectLabels: {},
  isDocument: true,
  defaultValues: [],
};

export default DocumentsList;
