import { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { SessionContext } from "modules/session/context";
import { useCoverSummaryHook } from "./hooks/coverSummary.hook";
import CoverView from "./Cover.view";

const CoverController = (props) => {
  const { t, dispatch } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  const history = useHistory();

  const coverSummaryStates = useCoverSummaryHook({
    t, dispatch, history, user,
  });

  return (
    <CoverView
      { ...coverSummaryStates }
      { ...props }
    />
  );
};

CoverController.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default CoverController;
