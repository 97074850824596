import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const Container = styled(Paper)`
  padding: 36px;
  margin-bottom: 36px; 
`;

export const Filters = styled(Grid)`
  padding-bottom: 16px;
`;

export const Content = styled(Paper)`
  height: 100%; 
`;

export const AvatarProfile = styled(Avatar)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
  color: ${palette.black};
`;

export const NoDataContainer = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  height: calc(100% - 150px);
`;

export const EmployeeData = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 8px 24px;
`;

export const History = styled.div`
  padding: 0px 24px 24px 24px;
`;

export const HistoryContent = styled.div`
  h6 {
    padding: 2px 0 2px;
  }
`;
