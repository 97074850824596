import styled from "styled-components";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "components/Button";
import palette, { STATE_COLORS } from "theme/palette";

export const StyledChip = styled(Chip)`
  font-size: 10px;
  color: ${palette.background.valid};
  border-color: ${palette.background.valid};
  cursor: pointer;
  &.MuiChip-root {
    margin: 1px;
    height: 18px;
    span {
      width: 60px;
      text-align: left;
    }
    .MuiChip-deleteIcon {
      color: ${palette.background.valid};
    }
  }
`;

export const StyledContainer = styled.div`
  .MuiTableCell-root {
    padding: 10px;
    font-size: 12px;
    line-height: 14px;
  }
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(-n+${({ evaluations }) => evaluations}) {
    border: 1px solid #BCBCBC;
  }
  .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-last-child(-n+${({ evaluations }) => evaluations}) .MuiTableSortLabel-root {
    display: none;
  }
  p, h2 {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const StyledError = styled.span`
  color: ${palette.table.red};
`;

export const StyledSwitch = styled(Switch)`
  .Mui-disabled {
    color: ${palette.background.valid};
  }
  color: ${palette.background.valid};
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 0px !important;
  padding: 0;
  svg {
    width: 20px;
    height: 20px;
    fill: ${(props) => (props.disabled ? palette.background.gray : palette.background.valid)};
  }
`;

export const StyledEmptyTitle = styled.p`
  font-size: 10px;
  padding-right: 5px;
`;

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    color: ${palette.text.link};
    border-color: ${palette.text.link};
    margin-right: 10px;

    ${({ isDisabled }) => isDisabled
      && `
      color: rgba(0,0,0,0.26) !important;
      border: 1px solid rgba(0,0,0,0.12) !important;
      `}

    &.MuiButton-outlinedSizeSmall {
      padding: 2px 8px;
    }
    svg {
      color: ${palette.text.link};
    }
  }
  @media (max-width: 600px) {
    &.MuiButtonBase-root {
      &.MuiButton-outlinedSizeSmall {
        width: 100%;
      }
    }
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${ STATE_COLORS.ERROR };
`;
