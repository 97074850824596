import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { VARIANT, TYPES } from "common/constants";

const DynamicAttributesData = (props) => {
  const {
    otherFields,
    handleFunctions,
  } = props;
  const { dynamicFieldsList } = otherFields;
  const { handleChangeDynamicFields } = handleFunctions;

  return dynamicFieldsList && dynamicFieldsList?.map((item, index) => (
    <Grid item xs={ 12 } sm={ 6 } key={ `item-${index}` }>
      <TextField
        type={ TYPES.text }
        label={ item.label }
        value={ item.value }
        variant={ VARIANT.outlined }
        margin={ VARIANT.dense }
        onChange={ handleChangeDynamicFields(item) }
        fullWidth
      />
    </Grid>
  ));
};

DynamicAttributesData.propTypes = {
  otherFields: PropTypes.object.isRequired,
  handleFunctions: PropTypes.object.isRequired,
};

export default DynamicAttributesData;
