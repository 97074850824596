import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import { VARIANT } from "common/constants";
import { StyledContainer } from "./styles";

const MultiSelect = (props) => {
  const { data, onChange, defaultValue } = props;

  const defaultData = defaultValue?.parent?.id ? defaultValue : {
    parent: { id: data[0].id, label: data[0].label },
    child: { id: "", label: "" },
  };

  const [values, setValues] = useState(defaultData);

  // State to control the open/close state of the menu
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to handle opening the menu when a parent value is selected
  const handleOpenMenu = () => {
    if (values.parent.label) {
      setMenuOpen(true);
    }
  };

  // Function to handle closing the menu
  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  // Function to handle selecting a parent value
  const handleParentSelect = (event) => {
    const selectedValue = event.target.value;
    const valuesInfo = data.find(
      (parent) => parent.label === selectedValue,
    );
    const valuesDetail = {
      parent: { id: valuesInfo?.id || "", label: selectedValue },
      child: { id: "", label: "" },
    };
    setValues(valuesDetail);

    if (
      data.find((parent) => parent.label === selectedValue)?.children.length > 0
    ) {
      handleOpenMenu();
    } else {
      onChange(valuesDetail);
    }
  };

  // Function to handle selecting a child value
  const handleChildSelect = (id, label) => {
    const valuesDetail = {
      ...values,
      child: { id, label },
    };
    setValues(valuesDetail);
    handleCloseMenu();
    onChange(valuesDetail);
  };

  return (
    <StyledContainer>
      <FormControl variant={ VARIANT.outlined } fullWidth>
        <Select
          labelId={ "multi-select-label" }
          id={ "multi-select" }
          value={ values.child?.id || values.parent.id }
          onChange={ handleParentSelect }
          renderValue={ () => (values.child.label
            ? values.child.label
            : values.parent.label) }
        >
          {data.map((parent) => (
            <MenuItem key={ parent.id } value={ parent.label }>
              {parent.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Menu
        anchorEl={ document.getElementById("multi-select") }
        open={ menuOpen }
        onClose={ handleCloseMenu }
        PaperProps={ {
          style: {
            maxHeight: 150,
            width: 150,
            overflowY: "auto",
          },
        } }
      >
        {values.parent.label
    && data
      .find((parent) => parent.id === values.parent.id)
      .children?.map((child) => (
        <MenuItem
          key={ child.id }
          value={ child.label }
          onClick={ () => handleChildSelect(child.id, child.label) }
        >
          {child.label}
        </MenuItem>
      ))}
      </Menu>
    </StyledContainer>
  );
};

MultiSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.shape({
    parent: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
    child: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

MultiSelect.defaultProps = {
  defaultValue: null,
};

export default MultiSelect;
