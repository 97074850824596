import {
  useCallback, useState, useEffect,
} from "react";
import { useSelector } from "react-redux";
import { toNumber } from "lodash";
import { LOCATION_PROPS, SURVEY_PROCESS_TYPE } from "common/constants";
import { getAverageResult, getPropertyByLocation } from "common/utils";
import paths from "common/paths";
import useComplexState from "hooks/utils/useComplexState";
import { getList as getIndividualSurveyResult } from "redux/actions/performance/surveyResultsActions";
import { getScaleWithColors } from "../functions/goodLeader";
import { getAnswers, getComments } from "../functions/performance";
import localRoutes from "../functions/routes";

export const useGoodLeaderHook = (externalHooks, internalHooks) => {
  const { dispatch, location } = externalHooks;
  const { collaborator, searchCollaborator } = internalHooks;

  const {
    list: surveyResults,
    isLoadingList: isLoadingSurveyResults,
  } = useSelector(
    ({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer,
  );

  // TODO: Uncommenting when the evolution comes by collaborator and not through their descendants
  // const {
  //   goodLeaderEvolution,
  //   isLoadingGoodLeaderEvolution,
  // } = useSelector(({ surveysReducer }) => surveysReducer);

  // search process id by url
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processParam = locationSearch?.split(paths.goodLeader)[1];
  const processId = processParam ? locationSearch?.split("=")[2] : null;
  const isGoodLeaderModalOpen = location.pathname === localRoutes.goodLeader;

  const [goodLeaderStates, setGoodLeaderStates] = useComplexState({
    isOpenRecommendationModal: false,
    answerSelected: null,
    evaluationList: null,
    evaluationSelected: null,
  });

  const [goodLeaderValues, setGoodLeaderValues] = useState({
    data: null,
    scale: [],
    answers: [],
    comments: [],
  });

  const getGeneralData = useCallback((employeeId) => {
    // TODO: Uncommenting when the evolution comes by collaborator and not through their descendants
    // const query = {
    //   q: {
    //     employee_id_eq: employeeId,
    //   },
    // };
    if (employeeId) {
      const query = {
        q: {
          score_not_null: 1,
          survey_process_type_eq: SURVEY_PROCESS_TYPE.goodLeader.key,
        },
      };
      dispatch(getIndividualSurveyResult(employeeId, query));
      // dispatch(getGoodLeaderEvolution(query));
    }
  }, [dispatch]);

  useEffect(() => {
    if (collaborator
      && isGoodLeaderModalOpen
      && collaborator?.id === searchCollaborator
      && surveyResults === null
    ) {
      getGeneralData(collaborator.id);
    }
  }, [collaborator, isGoodLeaderModalOpen, surveyResults, searchCollaborator, getGeneralData]);

  useEffect(() => {
    if (
      !isLoadingSurveyResults
      && surveyResults !== null
      && isGoodLeaderModalOpen
    ) {
      if (surveyResults?.length === 0) {
        setGoodLeaderStates({
          evaluationList: [],
        });
        setGoodLeaderValues({
          data: {},
        });
      } else {
        const list = surveyResults.map((item) => ({
          value: item.survey_process?.id,
          label: item.survey_process?.name,
        }));
        const evaluationId = toNumber(processId) || list[list.length - 1]?.value;
        setGoodLeaderStates({
          evaluationList: list,
          evaluationSelected: evaluationId,
        });
      }
    }
    // eslint-disable-next-line
  }, [surveyResults, processId]);

  useEffect(() => {
    if (goodLeaderStates?.evaluationSelected !== null
      && surveyResults !== null
      && surveyResults?.length > 0
    ) {
      const surveysProcessResult = surveyResults.find(
        (result) => result.survey_process
          && result.survey_process.id === goodLeaderStates.evaluationSelected,
      );

      setGoodLeaderValues((prevgoodLeaderValues) => ({
        ...prevgoodLeaderValues,
        data: surveysProcessResult,
        scale: surveysProcessResult?.result_scale_indices
          && getScaleWithColors(surveysProcessResult?.result_scale_indices),
        answers: getAnswers(surveysProcessResult),
        comments: getComments(surveysProcessResult),
      }));
    }
    // eslint-disable-next-line
  }, [goodLeaderStates.evaluationSelected, surveyResults]);

  const handleRecommendationModal = (data) => {
    setGoodLeaderStates({
      answerSelected: data,
      isOpenRecommendationModal: !goodLeaderStates.isOpenRecommendationModal,
    });
  };

  const handleChange = (prop, event) => {
    setGoodLeaderStates({
      evaluationSelected: event.target.value,
    });
  };

  return {
    goodLeaderStates,
    goodLeaderValues,
    surveyStates: {
      surveyResults,
      isLoadingSurveyResults,
    },
    // evolution: {
    //   goodLeaderEvolution,
    //   isLoadingGoodLeaderEvolution,
    // },
    handleChange,
    getAverageResult,
    handleRecommendationModal,
  };
};
