import makeStyles from "@mui/styles/makeStyles";
import { NINEBOX } from "common/constants";

// TODO: refactor - add styled components
const useStyles = makeStyles((theme) => ({
  boxTitle: {
    fill: theme.palette.text.subtitle,
    fontSize: theme.spacing(0.25),
    fontWeight: 400,
  },
  performance: {
    fontSize: "24px !important",
    fontWeight: "700 !important",
    top: "40%",
    left: "20%",
  },
  boxSubtitleA: {
    bottom: "5%",
    right: "-30px",
  },
  boxSubtitleB: {
    bottom: "35%",
    right: "-30px",
  },
  boxSubtitleC: {
    top: "10%",
    right: "-30px",
  },
  boxFooter: {
    "& text": {
      fontSize: "1.9px",
      textAlign: "center",
    },
  },
  potentialText: {
    fontSize: "3px",
    fontWeight: 700,
  },
  potential: {
    position: "relative",
    height: "90%",
    "& h5, p": {
      position: "absolute",
      writingMode: "vertical-rl",
      transform: "rotate(-180deg)",
    },
  },
  potentialTitle: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
  },
  svg: {
    viewBox: "0",
  },
  generalSvg: {
    width: "100%",
    outline: "none",
  },
  rectBox: {
    stroke: theme.palette.white,
    strokeWidth: NINEBOX.quadrantBorder,
    cursor: "pointer",
  },
  rectBoxActive: {
    stroke: theme.palette.background.purple,
    strokeWidth: NINEBOX.quadrantBorder,
    cursor: "pointer",
  },
  footerSvg: {
    height: "100%",
    width: "100%",
  },
  "@media (max-width: 1700px)": {
    boxSubtitle: {
      fontSize: "11px !important",
    },
    boxSubtitleA: {
      bottom: "4%",
    },
    boxSubtitleB: {
      bottom: "35%",
    },
    boxSubtitleC: {
      top: "8%",
    },
  },
  "@media (max-width: 1600px)": {
    boxSubtitleB: {
      bottom: "33%",
    },
  },
  "@media (max-width: 1500px)": {
    boxSubtitleA: {
      bottom: "7%",
    },
    boxSubtitleB: {
      bottom: "36%",
    },
    boxSubtitleC: {
      top: "6%",
    },
  },
  "@media (max-width: 1420px)": {
    performance: {
      top: "36%",
    },
    boxSubtitleA: {
      fontSize: "9px",
      bottom: "10%",
    },
    boxSubtitleB: {
      fontSize: "9px",
      bottom: "38%",
    },
    boxSubtitleC: {
      fontSize: "9px",
    },
  },
  "@media (max-width: 1300px)": {
    boxFooter: {
      "& text": {
        fontSize: "2.3px",
      },
    },
    boxSubtitle: {
      fontSize: "10px",
    },
    boxSubtitleA: {
      bottom: "5%",
    },
    boxSubtitleB: {
      bottom: "35%",
    },
    boxSubtitleC: {
      top: "7%",
    },
  },
  "@media (max-width: 1210px)": {
    boxFooter: {
      "& text": {
        fontSize: "2.3px",
      },
    },
    boxSubtitle: {
      fontSize: "10px",
    },
    boxSubtitleA: {
      bottom: "16%",
    },
    boxSubtitleB: {
      bottom: "42%",
    },
    boxSubtitleC: {
      top: "8%",
    },
  },
  "@media (max-width: 1190px)": {
    boxSubtitle: {
      fontSize: "10px",
    },
    boxSubtitleA: {
      bottom: "8%",
    },
    boxSubtitleB: {
      bottom: "37%",
    },
    boxSubtitleC: {
      top: "8%",
    },
  },
  "@media (max-width: 1160px)": {
    boxSubtitle: {
      fontSize: "10px",
    },
    boxSubtitleA: {
      bottom: "7%",
    },
    boxSubtitleB: {
      bottom: "38%",
    },
    boxSubtitleC: {
      top: "10%",
    },
  },
  "@media (max-width: 1024px)": {
    boxSubtitleB: {
      bottom: "36%",
    },
  },
  "@media (max-width: 940px)": {
    boxSubtitle: {
      fontSize: "14px",
    },
    boxSubtitleA: {
      bottom: "15%",
    },
    boxSubtitleB: {
      bottom: "40%",
    },
    boxSubtitleC: {
      top: "10%",
    },
  },
  "@media (max-width: 850px)": {
    boxSubtitle: {
      fontSize: "11px",
    },
    boxSubtitleA: {
      bottom: "5%",
    },
    boxSubtitleB: {
      bottom: "32%",
    },
    boxSubtitleC: {
      top: "5%",
    },
  },
  "@media (max-width: 821px)": {
    boxSubtitle: {
      fontSize: "12px",
    },
    boxSubtitleB: {
      bottom: "37%",
    },
    boxSubtitleC: {
      top: "10%",
    },
  },
  "@media (max-width: 740px)": {
    boxSubtitle: {
      fontSize: "9px",
    },
    boxSubtitleA: {
      bottom: "15%",
    },
    boxSubtitleB: {
      bottom: "40%",
    },
    boxSubtitleC: {
      top: "10%",
    },
  },
  "@media (max-width: 540px)": {
    boxTitle: {
      display: "none",
    },
    iconSvg: {
      display: "none",
    },
    rectBox: {
      strokeWidth: 2,
    },
    rectBoxActive: {
      strokeWidth: 2,
    },
    potentialTitle: {
      paddingTop: 0,
    },
    boxSubtitle: {
      display: "none",
    },
    boxFooter: {
      display: "none",
    },
    performance: {
      left: "10%",
      fontSize: "14px",
      top: "34%",
    },
    footerSvg: {
      display: "none",
    },
  },
}));

export default useStyles;
