import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import { StyledSectionTitle } from "styledComponents/View";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import Card from "../Card";
import { StyledRecommendationTitle, StyledRecommendationDescription, StyledRecommendationContainer } from "./styles";

const Recommendations = (props) => {
  const { data, isLoading } = props;
  const { t } = useTranslation("talentDrain");
  const dataValidation = isLoading
    ? (
      <SkeletonLoader
        numberOfSkeletons={ 7 }
        isInline={ false }
      />
    ) : isEmpty(data) && <NoDataMessage isHappyFace />;

  return (
    <div data-testid={ "recommendations-view-component" }>
      <StyledSectionTitle>
        {t("recommendations")}
      </StyledSectionTitle>
      { dataValidation }
      <StyledScrollBar maxHeight={ "480px" } padding={ "10px" }>
        <StyledRecommendationContainer>
          { data?.map((item) => (
            <Card key={ item.title + item.percentage } icon={ item.icon } title={ item.title } percentage={ item.percentage }>
              <StyledRecommendationTitle>
                {item.recommendation.icon}
                {item.recommendation.title}
              </StyledRecommendationTitle>
              <StyledRecommendationDescription>
                {item.recommendation.text}
              </StyledRecommendationDescription>
            </Card>
          ))}
        </StyledRecommendationContainer>
      </StyledScrollBar>
    </div>
  );
};

Recommendations.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

Recommendations.defaultProps = {
  isLoading: false,
};

export default Recommendations;
