import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "components/Button";
import SimpleTable from "components/SimpleTable";
import SimpleAlert from "components/SimpleAlert";
import LinearProgress from "components/LinearProgress";
import DownloadExcelButton from "components/DownloadExcelButton";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import downloadImg from "assets/images/forms/descargar.svg";
import previewImg from "assets/images/forms/preview.svg";
import fileLoad from "assets/images/forms/file.svg";
import { isEmpty, isEqual, isNull } from "common/helpers";
import {
  BULK_UPLOAD_FORMAT,
  ERROR,
  ACCEPTED_FORMATS,
  ALIGN_ITEMS,
  BUTTON_STYLE_TYPES,
  COMPONENT,
  INFO,
  INPUT_TYPE,
  VARIANT,
  BULK_UPLOAD,
  SUCCESS,
  TYPE_BULK_UPLOAD,
  COLORS_NAME,
} from "common/constants";
import {
  hasAlert,
  downloadExcelCustom,
  getAlert,
  headerByDefault,
  validationData,
  readData,
  hasPreviewData,
} from "./functions";
import {
  useStyles,
  StyledCard,
  StyledCardTitle,
  StyledAlert,
  StyledBoxFile,
  StyledFileName,
  StyledName,
  StyledUploadTitle,
  StyledBoxProgress,
  StyledIconButton,
  StyledInfoGrid,
  StyledSaveAltIcon,
  StyledPreviewGrid,
  StyledButton,
} from "./styles";

const UploadDocument = (props) => {
  const {
    filename,
    excelHeader,
    sendData,
    setAllData,
  } = props;
  const { t } = useTranslation(["bulkUpload"]);
  const classes = useStyles();
  const [tableHeader] = useState(excelHeader);
  const [stateFile, setStateFile] = useState(null);
  const [preview, setPreview] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [upload, setUpload] = useState(false);

  const {
    successProcess: uploadStatus,
  } = useSelector(({ bulkUploadReducer }) => bulkUploadReducer);

  const setLoading = () => {
    setIsLoading(false);
  };

  const resetState = useCallback(() => {
    setPreview({});
    setIsLoading(false);
    setStateFile(null);
    setUpload(false);
    setAllData({});
  }, [setAllData]);

  const handleUpload = (event) => {
    resetState();
    const file = event.target.files[BULK_UPLOAD.xlsx.firstData];
    if (file) {
      if (BULK_UPLOAD_FORMAT.exec(file.name)) {
        const header = headerByDefault(tableHeader);
        setStateFile({
          name: file.name,
          file,
          header,
        });
      } else {
        setPreview({ alert: getAlert(ERROR, t("invalidFileFormat")) });
      }
      event.target.value = "";
    }
  };

  const parseData = useCallback(() => {
    setIsLoading(true);
    setUpload(true);
    const reader = new FileReader();
    const readAsBinaryString = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const { result } = e.target;
      const data = readData(result, readAsBinaryString, stateFile, TYPE_BULK_UPLOAD.onboarding);
      if (!isEmpty(data)) {
        const dataPreviewValidate = validationData(data, tableHeader, t);
        setPreview(dataPreviewValidate);
        if (!dataPreviewValidate.hasError) {
          setAllData(data);
          dataPreviewValidate.alert = getAlert(
            SUCCESS,
            t("alertInfo.bulkUploadSuccessfully"),
          );
        } else {
          dataPreviewValidate.alert = getAlert(
            ERROR,
            t("alertInfo.bulkUploadError"),
          );
        }
      }
    };
    if (readAsBinaryString) {
      reader.readAsBinaryString(stateFile.file);
    } else {
      reader.readAsArrayBuffer(stateFile.file);
    }
  }, [stateFile, setAllData, tableHeader, t]);

  useEffect(() => {
    if (!isNull(stateFile)) {
      parseData();
    }
  }, [stateFile, parseData]);

  useEffect(() => {
    if (!isEqual(uploadStatus?.aasm_state, BULK_UPLOAD?.state.pending)) {
      resetState();
    }
  }, [uploadStatus, resetState]);

  const previewContent = hasPreviewData(stateFile, preview, isLoading) ? (
    <SimpleTable
      header={ stateFile.header }
      data={ preview.data }
      size={ "small" }
    />
  ) : (
    <Box>
      <Box
        pt={ 2 }
        pb={ 1 }
        display={ ALIGN_ITEMS.flex }
        justifyContent={ ALIGN_ITEMS.center }
      >
        <img alt={ t("preview") } src={ previewImg } />
      </Box>
      <Typography variant={ VARIANT.bodyTwo } align={ ALIGN_ITEMS.center }>
        {t("textPreview")}
      </Typography>
    </Box>
  );

  return (
    <div data-testid={ "bulk-upload" }>
      <StyledCard>
        <CardContent>
          <Grid container>
            <Grid item xs={ 12 } sm={ 6 }>
              <Box display={ ALIGN_ITEMS.flex }>
                <Box pr={ 1 }>
                  <img alt={ t("upload_document") } src={ uploadImg } />
                </Box>
                <Box flexGrow={ 1 }>
                  <StyledCardTitle variant={ VARIANT.h6 }>
                    {t("upload_document")}
                  </StyledCardTitle>
                  <Typography variant={ VARIANT.bodyTwo }>
                    {t("filesFormat")}
                  </Typography>
                </Box>
              </Box>
              {hasAlert(preview, isLoading) && (
                <StyledAlert pt={ 1 }>
                  <SimpleAlert
                    type={ preview.alert.type }
                    message={ preview.alert.message }
                  />
                </StyledAlert>
              )}
              <StyledBoxFile
                pt={ 1 }
                pb={ 2 }
                display={ ALIGN_ITEMS.flex }
                alignItems={ ALIGN_ITEMS.center }
              >
                <Button
                  variant={ VARIANT.outlined }
                  typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                  component={ "label" }
                  isDisabled={ isLoading }
                >
                  {t("select_file")}
                  <input
                    accept={ ACCEPTED_FORMATS }
                    type={ INPUT_TYPE.file }
                    onChange={ handleUpload }
                  />
                </Button>
                {isLoading && (
                  <StyledFileName
                    display={ ALIGN_ITEMS.flex }
                    alignItems={ ALIGN_ITEMS.center }
                    pl={ 1 }
                  >
                    <Box pr={ 0.5 }>
                      <img alt={ "download" } src={ downloadImg } />
                    </Box>
                    <Box>
                      <StyledName
                        variant={ VARIANT.bodyTwo }
                        component={ COMPONENT.span }
                      >
                        { stateFile.name }
                      </StyledName>
                    </Box>
                  </StyledFileName>
                )}
              </StyledBoxFile>
              {upload && (
                <Box>
                  <StyledUploadTitle variant={ VARIANT.h6 }>
                    {t("loadData")}
                  </StyledUploadTitle>
                  <Box
                    pt={ 2 }
                    pb={ 1 }
                    display={ ALIGN_ITEMS.flex }
                    alignItems={ ALIGN_ITEMS.center }
                  >
                    <Box pr={ 1 }>
                      <img alt={ t("preview") } src={ fileLoad } />
                    </Box>
                    {isLoading ? (
                      <StyledBoxProgress pr={ 1 }>
                        <LinearProgress
                          isActive={ isLoading }
                          setLoading={ setLoading }
                        />
                      </StyledBoxProgress>
                    ) : (
                      <Box pr={ 1 }>
                        <StyledName
                          variant={ VARIANT.bodyTwo }
                          component={ COMPONENT.span }
                        >
                          {stateFile.name}
                        </StyledName>
                      </Box>
                    )}
                    {isLoading && (
                      <StyledIconButton onClick={ resetState }>
                        <CancelIcon />
                      </StyledIconButton>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
            <StyledInfoGrid item xs={ 12 } sm={ 6 }>
              <SimpleAlert
                type={ INFO }
                title={ t("alert.title") }
                message={ t("alert.text") }
              >
                <Box pt={ 2 }>
                  <DownloadExcelButton
                    customStyle={ classes.downloadButton }
                    onClick={ () => downloadExcelCustom(filename, excelHeader) }
                  >
                    {t("buttons.download")}
                    {" "}
                    <StyledSaveAltIcon />
                  </DownloadExcelButton>
                </Box>
              </SimpleAlert>
            </StyledInfoGrid>
          </Grid>
        </CardContent>
      </StyledCard>
      {/* PREVIEW */}
      <StyledCard>
        <CardContent>
          <Grid container>
            <StyledPreviewGrid item xs={ 12 }>
              <StyledUploadTitle variant={ VARIANT.h6 }>
                {t("preview")}
              </StyledUploadTitle>
              { previewContent }
            </StyledPreviewGrid>
          </Grid>
        </CardContent>
      </StyledCard>
      <Grid container justify={ "flex-end" }>
        <StyledButton
          variant={ VARIANT.contained }
          isLoading={ isLoading }
          color={ COLORS_NAME.primary }
          type={ BUTTON_STYLE_TYPES.SUBMIT }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          onClick={ sendData }
        >
          { t("Onboarding:send") }
        </StyledButton>
      </Grid>
    </div>
  );
};

UploadDocument.propTypes = {
  filename: PropTypes.string.isRequired,
  excelHeader: PropTypes.array.isRequired,
  sendData: PropTypes.func.isRequired,
  setAllData: PropTypes.func.isRequired,
};

export default UploadDocument;
