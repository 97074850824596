import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import {
  StyledAccordionContainer,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledButtonGroup,
  StyledButton,
  StyledMenuContainer,
  StyledLabelTypography,
} from "./styles";

const OpenQuestions = ({ data }) => {
  const { t } = useTranslation(["performance"]);

  const [selectedLabel, setSelectedLabel] = useState(data[0]?.label || "");

  const handleMenuClick = (label) => {
    setSelectedLabel(label);
  };

  const renderMenu = () => (
    <StyledMenuContainer>
      <StyledLabelTypography variant={ "body1" }>{t("view_by")}</StyledLabelTypography>
      <StyledButtonGroup orientation={ "horizontal" }>
        {data.map((item) => (
          <StyledButton
            key={ item.label }
            active={ selectedLabel === item.label }
            onClick={ () => handleMenuClick(item.label) }
          >
            {item.label}
          </StyledButton>
        ))}
      </StyledButtonGroup>
    </StyledMenuContainer>
  );

  const renderQuestions = () => {
    const selectedData = data.find((item) => item.label === selectedLabel);
    if (!selectedData) return null;

    return selectedData.questions.map((question) => (
      <StyledAccordionContainer disableGutters elevation={ 0 } key={ `${question.title}` }>
        <StyledAccordionSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography>
            {question.title}
          </Typography>
          <h4>{`(${question.answers?.length})`}</h4>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledScrollBar maxHeight={ "200px" }>
            {question.answers.map((answer) => (
              <Typography key={ answer.answer }>
                {answer?.answeredBy?.full_name
            && (
              <b>
                {`${ answer.answeredBy.full_name}: `}
              </b>
            )}

                {`"${answer.answer}"`}
              </Typography>
            ))}
          </StyledScrollBar>
        </StyledAccordionDetails>
      </StyledAccordionContainer>
    ));
  };

  return (
    <div >
      {renderMenu()}
      {renderQuestions()}
    </div>
  );
};

OpenQuestions.propTypes = {
  data: PropTypes.array,
};

OpenQuestions.defaultProps = {
  data: [],
};

export default OpenQuestions;
