import styled from "styled-components";
import palette, { TABLE, STATE_COLORS } from "theme/palette";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as UsersViewFinder } from "assets/images/administrator/users-viewfinder-solid.svg";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: "25px",
  },
  container: {
    marginTop: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3),
  },
  cardsContainer: {
    marginTop: theme.spacing(4),
    "& >.MuiGrid-item": {
      padding: theme.spacing(2),
    },
  },
  tableContainer: {
    background: theme.palette.white,
    padding: theme.spacing(4),
    borderRadius: "4px",
  },
  tableContene: {
    border: `1px solid ${TABLE.DIVIDER}`,
  },
  tableNested: {
    background: theme.palette.background.default,
  },
  inputRowContainer: {
    display: "flex",
    alignItems: "center",
  },
  inputRow: {
    background: theme.palette.white,
    width: "250px",
    marginRight: "15px",
  },
  rowButtons: {
    marginTop: "-8px",
    marginLeft: "5px",
    cursor: "pointer",
  },
  iconCancel: {
    color: STATE_COLORS.ERROR,
  },
  iconSave: {
    color: STATE_COLORS.SUCCESS,
  },
  iconDisabled: {
    color: STATE_COLORS.GREY,
    cursor: "default",
  },
  button: {
    marginBottom: theme.spacing(2),
    justifyContent: "flex-end",
  },
  delete: {
    color: STATE_COLORS.ERROR,
  },
  autocomplete: {
    width: "250px",
  },
  actionButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  pulse: {
    animation: "pulse 2s infinite",
  },
}));

export const StyledContainer = styled.div`
  padding: 32px;
  margin-bottom: 25px;
  @media (max-width: 600px) {
    padding: 25px 15px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledInputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`;

export const StyledActions = styled.div`
  .MuiButton-root {
    margin-right: 6px;
  }
  .MuiButton-outlinedPrimary {
    color: ${palette.text.link};
    border-color: ${palette.text.link};
  }
  .MuiButton-containedPrimary {
    background-color: ${palette.text.link};
  }
`;

export const StyledUsersViewFinder = styled(UsersViewFinder)`
  width: 30px;
  margin-bottom: 5px;
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 250px;
  margin-top: ${(props) => (props.simple ? "8px" : "0px")};
`;
