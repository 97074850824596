import PropTypes from "prop-types";
import Accordion from "components/Accordion";
import NoDataMessage from "components/NoDataMessage";
import { BORDER_ACCORDION_TYPE } from "common/constants";
import { StyledAccordionContainer } from "../../styledComponents";

const ResultsByQuestion = (props) => {
  const { values } = props;

  return (
    <StyledAccordionContainer data-testid={ "result-by-question-view-component" }>
      {values?.length !== 0
        ? (
          <Accordion
            isDefaultIconStyle
            type={ BORDER_ACCORDION_TYPE.square }
            data={ values }
            iconLeft
          />
        ) : <NoDataMessage />}
    </StyledAccordionContainer>
  );
};

ResultsByQuestion.propTypes = {
  values: PropTypes.array.isRequired,
};

export default ResultsByQuestion;
