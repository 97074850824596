import moment from "moment";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import SectionContainer from "components_refactor/SectionContainer";
import { formatDateStringShort, formatDate } from "common/utils";
import { ReactComponent as BirthdayIcon } from "assets/images/icons/birthday.svg";
import { ReactComponent as LocationIcon } from "assets/images/icons/location.svg";
import { ReactComponent as ClockIcon } from "assets/images/icons/clock.svg";
import { ReactComponent as CriticalPosition } from "assets/images/icons/critic.svg";
import { ReactComponent as SuccessionIcon } from "assets/images/icons/succession.svg";
import ProfileEdit from "./components/ProfileEdit";
import ProfileImage from "./components/ProfileImage";
import InfoDetail from "./components/InfoDetail";
import { StyledProfileFullName, StyledPositionName } from "./styles";

const ProfileInfo = (props) => {
  const {
    t, collaborator, currentTalentScore, quadrant,
  } = props;

  let quadrantInfo = null;

  if (quadrant && currentTalentScore?.performance && currentTalentScore?.potential) {
    const performanceDate = new Date(currentTalentScore.performance.score_updated_at);
    const potentialDate = new Date(currentTalentScore.potential.score_updated_at);
    const latestDate = performanceDate > potentialDate ? performanceDate : potentialDate;
    quadrantInfo = {
      quadrant,
      updatedAt: formatDate(latestDate),
    };
  }

  return (
    <SectionContainer>
      <Grid container>
        <Grid item xs={ 12 }>
          <ProfileEdit { ...props } quadrant={ quadrantInfo } />
        </Grid>
        <Grid item xs={ 12 }>
          <ProfileImage { ...props } />
        </Grid>
        <Grid item xs={ 12 }>
          <StyledProfileFullName>
            <p>
              { collaborator?.is_a_successor
              && (
                <Tooltip title={ t("successions:plan.title") } placement={ "top" }>
                  <SuccessionIcon />
                </Tooltip>
              )}
              {collaborator?.full_name}
            </p>
          </StyledProfileFullName>
          <StyledPositionName>
            <p>{collaborator?.job_position?.position?.name}</p>
            {collaborator?.key_position?.critical
          && <Tooltip title={ t("collaborators:key_position.title") } placement={ "top" }><CriticalPosition /></Tooltip>}
          </StyledPositionName>
        </Grid>
        <Grid item xs={ 6 } md={ 4 }>
          <InfoDetail
            title={ t("profile:birthday") }
            subtitle={ formatDateStringShort(collaborator?.person?.birth_date?.split("T")) }
            icon={ <BirthdayIcon /> }
          />
        </Grid>
        <Grid item xs={ 6 } md={ 4 }>
          <InfoDetail
            title={ t("profile:form.nationality") }
            subtitle={ collaborator?.person?.nationality }
            icon={ <LocationIcon /> }
          />
        </Grid>
        <Grid item xs={ 6 } md={ 4 }>
          <InfoDetail
            title={ t("profile:antiquity") }
            subtitle={ moment(collaborator?.starting_date, "YYYYMMDD").fromNow() }
            icon={ <ClockIcon /> }
          />
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

ProfileInfo.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.shape({
    full_name: PropTypes.string,
    job_position: PropTypes.shape({
      position: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    key_position: PropTypes.shape({
      critical: PropTypes.bool,
    }),
    person: PropTypes.shape({
      birth_date: PropTypes.string,
      nationality: PropTypes.string,
    }),
    starting_date: PropTypes.string,
    is_a_successor: PropTypes.bool,
  }).isRequired,
  currentTalentScore: PropTypes.shape({
    performance: PropTypes.shape({
      score_updated_at: PropTypes.string.isRequired,
    }),
    potential: PropTypes.shape({
      score_updated_at: PropTypes.string.isRequired,
    }),
  }),
  quadrant: PropTypes.number,
};

ProfileInfo.defaultProps = {
  currentTalentScore: null,
  quadrant: null,
};

export default ProfileInfo;
