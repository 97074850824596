import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SimpleAlert from "components/SimpleAlert";
import {
  COMPONENT,
  ERROR,
  MIN_VALUE,
  SIZE,
  SCOPE,
} from "common/constants";
import { getPercent } from "common/utils";
import { StyledTableRow, StyledTableCell, StyledAlertContainer } from "./styles";

const FavorabilityRankingTable = (props) => {
  const { values, prop } = props;
  const { t, i18n } = useTranslation("engagement");
  const { language } = i18n;

  return (
    <Box mt={ 2 } data-testid={ "favorability-ranking-view-component" }>
      <TableContainer component={ Paper }>
        <Table aria-label={ "favorability ranking table" } size={ SIZE.small }>
          <TableHead>
            <StyledTableRow>
              <TableCell>{ t("questions") }</TableCell>
              <TableCell>{ "%" }</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {values?.questionList?.map((item) => (
              <StyledTableRow key={ `${item.question}-${item.id}` }>
                <TableCell component={ COMPONENT.th } scope={ SCOPE.row }>
                  {item[`question_${language}`]}
                </TableCell>
                <StyledTableCell color={ item.cellColor }>
                  { getPercent(item[prop], true) }
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {values?.criticalTopics?.length !== MIN_VALUE && (
        <StyledAlertContainer>
          <SimpleAlert type={ ERROR } message={ `${ t("criticalTopics") }: ${ values.criticalTopics?.join(", ")}` } />
        </StyledAlertContainer>
      )}
    </Box>
  );
};

FavorabilityRankingTable.propTypes = {
  values: PropTypes.object.isRequired,
  prop: PropTypes.string,
};

export default FavorabilityRankingTable;
