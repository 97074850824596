export const RESET_STATE = "RESET_STATE_AGREEMENT";
export const GET_LIST = "GET_LIST_AGREEMENT";
export const GET_LIST_LOADING = "GET_LIST_LOADING_AGREEMENT";
export const GET_LIST_ERROR = "GET_LIST_ERROR_AGREEMENT";

export const RESET_PROCESS_AGREEMENT = "RESET_PROCESS_AGREEMENT";
export const PROCESS_AGREEMENT = "PROCESS_AGREEMENT";
export const PROCESS_AGREEMENT_LOADING = "PROCESS_AGREEMENT_LOADING";
export const PROCESS_AGREEMENT_ERROR = "PROCESS_AGREEMENT_ERROR";

export const RESET_GENERAL_STATE = "RESET_STATE_GENERAL_AGREEMENTS";
export const GET_GENERAL_LIST = "GET_GENERAL_AGREEMENTS";
export const GET_GENERAL_LIST_LOADING = "GET_GENERAL_AGREEMENTS_LOADING";
export const GET_GENERAL_LIST_ERROR = "GET_GENERAL_AGREEMENTS_ERROR";
