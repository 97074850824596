import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import SkeletonLoader from "components/SkeletonLoader";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { SKELETONS_NUMBER } from "common/constants";
import { LINEAL_GRAPHICS } from "theme/palette";
import { options } from "../ChartOptions";
import useStyles from "./styles";

const HrEvolutionChart = (props) => {
  const {
    labels,
    data,
    title,
    isLoading,
    chartType,
    yMax,
  } = props;

  const classes = useStyles();

  const chartData = {
    labels,
    datasets: [
      {
        label: title,
        backgroundColor: chartType === "hc" ? LINEAL_GRAPHICS.BG_COLOR : "transparent",
        borderColor: LINEAL_GRAPHICS.LINE_COLOR,
        borderWidth: 2,
        data,
        pointBackgroundColor: LINEAL_GRAPHICS.DOT_COLOR,
      },
    ],
  };

  return (
    <div
      className={ classes.chartContainer }
      data-testid={ "hr-evolution-chart-container" }
    >
      <ViewHeaderTitle
        title={ title }
        customTitleStyle={ classes.titleStyle }
      />
      {isLoading ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
      ) : (
        <Line data={ chartData } options={ options(yMax) } />
      )}
    </div>
  );
};

HrEvolutionChart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  chartType: PropTypes.string,
  yMax: PropTypes.number,
};

HrEvolutionChart.defaultProps = {
  title: "",
  isLoading: null,
  chartType: "",
  yMax: 100,
};

export default HrEvolutionChart;
