import { useEffect } from "react";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import useComplexState from "hooks/utils/useComplexState";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import {
  getEmployeeCompanyId, getEmployeeId, isAdminNala, isMainCompany,
} from "common/utils";
import { getRanking, resetStateRanking } from "redux/actions/acknowledgementTypeActions";

export const useHomeAcknowledgmentHook = (externalHooks) => {
  const {
    t, user, dispatch, theme,
  } = externalHooks;

  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const employeeId = getEmployeeId(user);
  const companyId = getEmployeeCompanyId(user);
  const isEnable = isMainCompany(user) && !isAdminNala(user?.userCookies);

  const [acknowledgmentStates, setAcknowledgmentStates] = useComplexState({
    isOpen: false,
  });

  const {
    rankingList, isLoadingRankingList,
  } = useSelector(({ acknowledgementTypesReducer }) => acknowledgementTypesReducer);

  useEffect(() => {
    dispatch(getRanking());
  }, [dispatch]);

  const handleOpenAcknowledgement = () => {
    setAcknowledgmentStates({
      isOpen: !acknowledgmentStates.isOpen,
    });
  };

  const showAlertErrorOrSuccess = (isError) => {
    const message = {
      title: t(`common:common.api_responses.${isError ? "error.title" : "success.title"}`),
      message: t(`feedback:${isError ? "error_acknowledgment" : "send_acknowledgment_successfully"}`),
    };
    toast(isError ? MESSAGE_TYPES.error : MESSAGE_TYPES.success, message);
    dispatch(resetStateRanking());
    dispatch(getRanking());
  };

  return {
    acknowledgmentStates,
    setAcknowledgmentStates,
    employeeId,
    companyId,
    isEnable,
    isMobile,
    acknowledgementReducerStates: {
      rankingList,
      isLoadingRankingList,
    },
    handleOpenAcknowledgement,
    showAlertErrorOrSuccess,
  };
};
