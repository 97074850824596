import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AlertModal from "components/AlertModal";
import Breadcrumbs from "components/Breadcrumbs";
import InputTextController from "components/InputTextController";
import {
  toast, MESSAGE_TYPES,
} from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  OBJECT_KEYS,
  TYPES,
  ONBOARDING_KEYS,
} from "common/constants";
import titleIcon from "assets/images/onboarding/title.svg";
import documentsIcon from "assets/images/onboarding/document.svg";
import additionalInfoIcon from "assets/images/onboarding/aditional_info.svg";
import {
  create as createTemplate,
  resetState as resetStateTemplate,
  resetStateProcess as resetStateProcessTemplate,
  getOne as getTemplate,
  update as updateTemplate,
} from "redux/actions/template/templateActions";
import {
  getList as getDocumentTypes,
  create as createDocumentType,
  resetStateProcess as resetStateDocumentType,
} from "redux/actions/documentTypeActions";
import {
  getList as getInfoTypes,
  create as createInfoType,
  resetStateProcess as resetStateInfoType,
} from "redux/actions/infoTypeActions";
import DocumentsList from "views/Onboarding/components/DocumentsList";
import CardSection from "views/Onboarding/components/CardSection";
import { resetFormData } from "views/Templates/functions";
import { getDocumentsInitFormat } from "views/Account/functions/documents";
import { StyledTitle, StyledButton, StyledContent, StyledTypography } from "../../styles";

const TemplateInformation = (props) => {
  const { header, templateId } = props;
  const { t } = useTranslation(["templates", "Onboarding", "candidates", "common"]);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [defaultDocs, setDefaultDocs] = useState([]);
  const [info, setInfo] = useState([]);
  const [defaultInfo, setDefaultInfo] = useState([]);
  const dispatch = useDispatch();

  const {
    list: documentTypesList,
  } = useSelector(({ documentTypesReducer }) => documentTypesReducer);

  const {
    list: infoTypesList,
  } = useSelector(({ infoTypesReducer }) => infoTypesReducer);

  const {
    isLoadingProcessTemplate,
    successProcessTemplate,
    one: oneTemplate,
  } = useSelector(({ templateReducer }) => templateReducer);

  const {
    control, handleSubmit, reset,
  } = useForm({
    defaultValues: {
      "onboarding_template.title": "",
      "onboarding_template.description": "",
    },
  });

  useEffect(() => {
    dispatch(getDocumentTypes());
    dispatch(getInfoTypes());
    if (templateId) {
      dispatch(getTemplate(templateId));
    }
  }, [dispatch, templateId]);

  const resetForm = useCallback(() => {
    const editData = resetFormData(oneTemplate);
    if (editData) reset(editData);
    setDefaultDocs(getDocumentsInitFormat(oneTemplate.document_types, ONBOARDING_KEYS.documentTypeId));
    setDefaultInfo(getDocumentsInitFormat(oneTemplate.info_types, ONBOARDING_KEYS.infoTypeId));
  }, [reset, oneTemplate]);

  useEffect(() => {
    if (oneTemplate && templateId) {
      resetForm();
    }
  }, [oneTemplate, templateId, resetForm]);

  const handleActionAdd = (isDocument, data) => {
    if (isDocument) {
      dispatch(resetStateDocumentType());
      return dispatch(createDocumentType(data));
    }
    dispatch(resetStateInfoType());
    return dispatch(createInfoType(data));
  };

  const onSubmit = async () => {
    const templateData = control.getValues();
    templateData.onboarding_template.document_type_ids = docs;
    templateData.onboarding_template.info_type_ids = info;
    templateId ? (
      dispatch(updateTemplate(templateData, templateId))
    ) : (
      dispatch(createTemplate(templateData))
    );
    setModal(false);
  };

  useEffect(() => {
    if (successProcessTemplate) {
      const message = {
        title: header.title,
        message: t("candidates:alertMessage.success"),
      };
      toast(MESSAGE_TYPES.success, message, t);
      dispatch(resetStateTemplate());
      dispatch(resetStateProcessTemplate());
      history.push("/templates", true);
    }
  }, [successProcessTemplate, dispatch, history, header, t]);

  const openCloseModal = () => {
    setModal(!modal);
  };

  return (
    <StyledContent data-testid={ "template-information-view-component" }>
      <Breadcrumbs data={ header.breadcrumb } />
      <StyledTitle>
        { header.title }
      </StyledTitle>
      <Grid>
        <form onSubmit={ handleSubmit(openCloseModal) }>
          <CardSection
            title={ t("template_title.title") }
            icon={ titleIcon }
          >
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } md={ 6 }>
                <InputTextController
                  type={ TYPES.text }
                  label={ t("template_title.label") }
                  control={ control }
                  rules={ { required: true } }
                  name={ "onboarding_template.title" }
                  required
                />
              </Grid>
            </Grid>
          </CardSection>

          <CardSection
            title={ t("required_documents.title") }
            icon={ documentsIcon }
          >
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <StyledTypography>{ t("required_documents.description") }</StyledTypography>
                <DocumentsList
                  id={ OBJECT_KEYS.documentType }
                  objectLabels={ {
                    select: t("required_documents.select"),
                    add: t("required_documents.add"),
                  } }
                  setValue={ setDocs }
                  data={ documentTypesList }
                  handleAdd={ handleActionAdd }
                  defaultValues={ defaultDocs }
                  isDocument
                />
              </Grid>
            </Grid>
          </CardSection>
          <CardSection
            title={ t("additional_information.title") }
            icon={ additionalInfoIcon }
          >
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <StyledTypography>{ t("additional_information.description") }</StyledTypography>
                <DocumentsList
                  id={ OBJECT_KEYS.infoType }
                  objectLabels={ {
                    select: t("additional_information.select"),
                    add: t("additional_information.add"),
                  } }
                  setValue={ setInfo }
                  data={ infoTypesList }
                  handleAdd={ handleActionAdd }
                  defaultValues={ defaultInfo }
                  isDocument={ false }
                />
              </Grid>
            </Grid>
          </CardSection>

          <Grid container justify={ "flex-end" }>
            <StyledButton
              variant={ VARIANT.contained }
              isLoading={ isLoadingProcessTemplate }
              color={ COLORS_NAME.primary }
              type={ BUTTON_STYLE_TYPES.SUBMIT }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            >
              { t("actions.send") }
            </StyledButton>
          </Grid>
        </form>
      </Grid>
      <AlertModal
        title={ t("common:common.modal_messages.sure_question") }
        text={ header.alertMessage }
        textDisagree={ t("common:common.modal_messages.no_cancel") }
        textAgree={ t("common:common.modal_messages.yes_confirm") }
        onClick={ onSubmit }
        open={ modal }
        handleClose={ openCloseModal }
      />
    </StyledContent>
  );
};

TemplateInformation.propTypes = {
  header: PropTypes.object.isRequired,
  templateId: PropTypes.string
};

TemplateInformation.defaultProps = {
  templateId: null
};

export default TemplateInformation;
