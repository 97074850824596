import PropTypes from "prop-types";
import {
  StyledContainer, StyledTitleText, StyledListItem, StyledListItemLegend, StyledListItemText,
} from "./styles";

const Legend = (props) => {
  const { data, title } = props;
  return (
    <StyledContainer data-testid={ "legend-container" }>
      <StyledTitleText>{title}</StyledTitleText>
      <ul>
        {data?.map((item) => (
          <StyledListItem key={ item.label }>
            <StyledListItemLegend className={ item.bgColor } />
            <StyledListItemText>{item.label}</StyledListItemText>
          </StyledListItem>
        ))}
      </ul>
    </StyledContainer>
  );
};

Legend.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default Legend;
