export const RESET_STATE = "RESET_STATE";
export const RESET_STATE_RULES = "RESET_STATE_RULES";
export const RESET_PROCCESS_GOAL = "RESET_PROCCESS_GOAL";

export const GET_GOALS = "GET_GOALS";
export const GET_GOALS_LOADING = "GET_GOALS_LOADING";
export const GET_GOALS_ERROR = "GET_GOALS_ERROR";
export const GET_GENERAL_GOALS = "GET_GENERAL_GOALS";

export const GET_GOALS_RULES = "GET_GOALS_RULES";
export const GET_GOALS_RULES_LOADING = "GET_GOALS_RULES_LOADING";
export const GET_GOALS_RULES_ERROR = "GET_GOALS_RULES_ERROR";

export const PROCCESS_GOALS = "PROCCESS_GOALS";
export const PROCCESS_GOALS_LOADING = "PROCCESS_GOALS_LOADING";
export const PROCCESS_GOALS_ERROR = "PROCCESS_GOALS_ERROR";

export const RESET_STATE_ONE = "RESET_STATE_COLLABORATOR_GOALS";
export const GET_ONE = "GET_ONE_COLLABORATOR_GOALS";
export const GET_ONE_LOADING = "GET_ONE_LOADING_COLLABORATOR_GOALS";
export const GET_ONE_ERROR = "GET_ONE_ERROR_COLLABORATOR_GOALS";
