import {
  useState, useContext, useEffect, useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SkeletonLoader from "components/SkeletonLoader";
import DownloadExcelButton from "components/DownloadExcelButton";
import HalfScreenDialog from "components/HalfScreenDialog";
import {
  PAGINATION,
  VARIANT,
  SKELETONS_NUMBER,
  ROLES,
  SURVEY_PROCESS_TYPE,
  DAYS_TO_FINISH_ONBOARDING,
  DATE_FORMATS,
} from "common/constants";
import {
  isAdmin as isAdminFunction,
  getEmployeeId,
  isValidRole,
  formatDate,
} from "common/utils";
import { SessionContext } from "modules/session/context";
import { getCollaboratorsFollowUpProcesses, getCollaboratorsFollowUpProcessesToDownload } from "redux/actions/followUpActions";
import { getList as getSurveyProcesses } from "redux/actions/surveyProcessesActions";
import {
  getExcelData, getHeader, getRows,
} from "views/CollaboratorsFollowUp/functions";
import SatisfactionByCollaborator from "../SatisfactionByCollaborator";
import FollowUpTable from "../FollowUpTable";
import { StyledContainer } from "./styles";

const OverView = (props) => {
  const {
    searchFilter,
    filterQuery,
    isFinished,
  } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  const { t } = useTranslation(["onboardingFollowUp", "engagement"]);
  const history = useHistory();
  const employeeId = user && getEmployeeId(user);
  const isOnboardingAdmin = isValidRole(user?.userCookies, ROLES.ONBOARDING_ADMIN);
  const isAdmin = isAdminFunction(user?.userCookies);
  const [header, setHeader] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableSort, setTableSort] = useState();
  const [dialog, setDialog] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState();
  const [pageFilter, setPageFilter] = useState({
    number: 0,
    size: PAGINATION.maxPerPage,
  });
  const pastDate = new Date();
  pastDate.setHours(0, 0, 0, 0);
  pastDate.setDate(pastDate.getDate() - DAYS_TO_FINISH_ONBOARDING);
  const formattedDate = formatDate(pastDate.toDateString(), DATE_FORMATS.es.dash);

  const {
    list: followUpList,
    collaboratorList: followUpCollaboratorList,
    isLoadingCollaboratorList: isLoadingFollowUpCollaboratorList,
    collaboratorListTotal: followUpCollaboratorTotal,
  } = useSelector(({ followUpReducer }) => followUpReducer);

  const {
    followList: surveyProcessList,
    loadingList: isLoadingSurveyProcessList,
  } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );

  const dispatch = useDispatch();

  const pageHandler = async (event, newPage) => {
    window.scroll(0, 0);
    await setPageFilter({ size: PAGINATION.maxPerPage, number: newPage });
  };

  const onDownloadExcel = async () => {
    const query = {
      q: {
        ...filterQuery.q,
        ...{
          employee_id: employeeId,
          [`starting_date_${isFinished ? "lt" : "gteq" }`]: formattedDate,
        },
      },
    };
    if (!isAdmin && !isOnboardingAdmin) {
      query.q.managers_ids_special_in = [employeeId];
    }
    query.q.person_full_name_cont = searchFilter;
    const downloadData = await dispatch(
      getCollaboratorsFollowUpProcessesToDownload(query, followUpCollaboratorTotal),
    );
    if (downloadData) {
      const data = getExcelData(downloadData, t, surveyProcessList, followUpList);
      return [data, t("downloadTitle"), false, true];
    }
    return null;
  };

  useEffect(() => {
    if (filterQuery.q) {
      const query = {
        q: {
          ...filterQuery.q,
          ...{
            employee_id: employeeId,
            [`starting_date_${isFinished ? "lt" : "gteq" }`]: formattedDate,
          },
        },
      };

      if (tableSort) {
        query.q.s = tableSort;
      }
      if (!isAdmin && !isOnboardingAdmin) {
        query.q.managers_ids_special_in = [employeeId];
      }
      query.q.person_full_name_cont = searchFilter;
      dispatch(getCollaboratorsFollowUpProcesses(query, pageFilter));
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [employeeId, isAdmin, isOnboardingAdmin, searchFilter, filterQuery, dispatch, tableSort, pageFilter]);

  const getSurveyResults = useCallback(() => {
    const performanceProcesses = surveyProcessList.filter((process) => process.type === SURVEY_PROCESS_TYPE.performance.key);
    const potentialProcesses = surveyProcessList.filter((process) => process.type === SURVEY_PROCESS_TYPE.engagement.key);
    setHeader(getHeader(performanceProcesses, potentialProcesses, t, followUpList));
    setIsLoading(false);
  }, [surveyProcessList, t, followUpList]);

  useEffect(() => {
    if (!isLoadingSurveyProcessList && surveyProcessList !== null) {
      setIsLoading(true);
      getSurveyResults();
    }
  }, [isLoadingSurveyProcessList, surveyProcessList, getSurveyResults]);

  useEffect(() => {
    dispatch(getSurveyProcesses(true));
  }, [dispatch]);

  const handleSatisfaction = (collaborator) => {
    setSelectedCollaborator(collaborator);
    setDialog(true);
  };

  const rows = getRows(followUpCollaboratorList, header, history, handleSatisfaction, t);

  const followTable = (isLoading || isLoadingSurveyProcessList ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } /> : (
    <FollowUpTable
      header={ header }
      data={ rows }
      isLoading={ isLoadingFollowUpCollaboratorList }
      setTableSort={ setTableSort }
      pagination={ {
        page: pageFilter.number, pageSize: pageFilter.size, handlePageChange: pageHandler,
      } }
      total={ followUpCollaboratorTotal }
    />
  ));

  return (
    <StyledContainer data-testid={ "overview-follow-up-view" }>
      <Grid container>
        <Grid item xs={ 12 } md={ 8 }>
          <Typography
            variant={ VARIANT.h4 }
          >
            {t("collaboratorsInFollowUp")}
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <DownloadExcelButton
            onClick={ () => onDownloadExcel() }
            isDisabled={ followUpCollaboratorTotal === 0 }
          />
        </Grid>
      </Grid>
      { followTable }
      <HalfScreenDialog
        isOpen={ dialog }
        onClose={ () => setDialog(false) }
        title={ t("modalTitle") }
      >
        <SatisfactionByCollaborator employee={ selectedCollaborator } />
      </HalfScreenDialog>
    </StyledContainer>
  );
};

OverView.propTypes = {
  searchFilter: PropTypes.string,
  filterQuery: PropTypes.object,
  isFinished: PropTypes.bool,
};

OverView.defaultProps = {
  searchFilter: "",
  filterQuery: {},
  isFinished: false,
};

export default OverView;
