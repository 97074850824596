import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import StaticProgressBar from "components/StaticProgressBar";
import { getPercent, getScaleByScore } from "common/utils";
import { StyledGrid, StyledTitle } from "./styles";

const GeneralResult = (props) => {
  const { data, scale } = props;
  const { t } = useTranslation("leadership");

  return (
    <StyledGrid container spacing={ 2 } data-testid={ "good-leader-general-result" }>
      <Grid item xs={ 7 } md={ 8 }>
        <StyledTitle>{ t("good_leader_score") }</StyledTitle>
      </Grid>
      <Grid item xs={ 5 } md={ 4 }>
        { data ? (
          <StaticProgressBar
            title={ " " }
            percentage={ getPercent(data?.score || 0) }
            color={ getScaleByScore(data?.score, scale)?.color }
          />
        ) : (
          <StaticProgressBar
            title={ " " }
            percentage={ 0 }
          />
        )}
      </Grid>
    </StyledGrid>
  );
};

GeneralResult.propTypes = {
  data: PropTypes.object.isRequired,
  scale: PropTypes.array,
};

GeneralResult.defaultProps = {
  scale: [],
};

export default GeneralResult;
