import { TYPES_RESULTS } from "common/constants";
import largeIcon from "assets/images/potential/large.svg";
import balancedIcon from "assets/images/potential/balanced.svg";
import deepIcon from "assets/images/potential/deep.svg";
import { POTENTIAL_COLORS } from "theme/palette";

export const skillInformationLevels = (t) => ({
  high: {
    icon: <img alt={ TYPES_RESULTS.large } src={ largeIcon } />,
    color: POTENTIAL_COLORS.purple,
    tooltip: t("potential:potential-measures.comprehensive"),
  },
  medium: {
    icon: <img alt={ TYPES_RESULTS.balanced } src={ balancedIcon } />,
    color: POTENTIAL_COLORS.orange,
    tooltip: t("potential:potential-measures.balanced"),
  },
  low: {
    icon: <img alt={ TYPES_RESULTS.deep } src={ deepIcon } />,
    color: POTENTIAL_COLORS.green,
    tooltip: t("potential:potential-measures.deep"),
  },
});
