import PropTypes from "prop-types";
import Step from "@mui/material/Step";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import {
  useStyles,
  StepperStyled,
  StepLabelStyled,
  StepContentStyled,
} from "./styles";

const VerticalStepperList = (props) => {
  const { data } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="vertical-stepper-list">
      <StepperStyled orientation="vertical">
        {data &&
          data.map((content) => (
            <Step key={content.title} active>
              <StepLabelStyled completed icon={<CheckCircleOutline />}>
                {content.title}
              </StepLabelStyled>
              <StepContentStyled>{content.customRender}</StepContentStyled>
            </Step>
          ))}
      </StepperStyled>
    </div>
  );
};

VerticalStepperList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VerticalStepperList;
