import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import NoDataMessage from "components/NoDataMessage";
import ProfileImageDetailSimple from "components/ProfileImageDetailSimple";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import EmployeeHistory from "../EmployeeHistory";
import { StyledEmployeeContainer } from "./styles";

const LeadershipTypeEmployee = (props) => {
  const {
    t,
    leadershipStates,
  } = props;

  const {
    leadershipResult,
    quadrantResult,
    selectedPanel,
  } = leadershipStates;

  const scrollRef = useRef(null);

  const data = quadrantResult ? leadershipResult?.filter(
    (result) => result.leadership_type.slug === quadrantResult.id,
  ) : leadershipResult;

  // Every time the state of selectedPanel changes, the position in the component
  // is searched using the reference and then moved there
  useEffect(() => {
    if (scrollRef.current) {
      const containerRect = scrollRef.current.getBoundingClientRect();
      const selectedElement = scrollRef.current.querySelector(`#employee${selectedPanel?.employee?.id}`);
      if (selectedElement) {
        const elementRect = selectedElement.getBoundingClientRect();
        const offset = elementRect.top - containerRect.top;
        scrollRef.current.scrollTo({ top: offset, behavior: "smooth" });
      }
    }
  }, [selectedPanel]);

  return (
      data?.length > 0 ? (
        <div>
          <StyledScrollBar maxHeight={ "240px" } ref={ scrollRef }>
            { data?.map((result) => {
              const isSelectedEmployee = selectedPanel?.id === result.id;
              // TODO: Improve the image loading, because if it's too heavy it
              // takes a long time to generate, smaller images will display as black.
              return (
                <StyledEmployeeContainer key={ result.employee.full_name } id={ `employee${result.employee.id}` }>
                  <ProfileImageDetailSimple
                    collaborator={ result.employee }
                  />
                  {isSelectedEmployee && <EmployeeHistory data={ result } { ...props } />}
                </StyledEmployeeContainer>
              );
            })}
          </StyledScrollBar>
        </div>
      ) : (
        // Display happy face if none in unproductive boss, boss or Leader
        // in Low-Productivity Development quadrant.
        <NoDataMessage
          message={ t("leadership:quadrant.empty") }
          isHappyFace={ quadrantResult.quadrant > 1 }
        />
      )
  );
};

LeadershipTypeEmployee.propTypes = {
  t: PropTypes.func.isRequired,
  leadershipStates: PropTypes.object.isRequired,
};

export default LeadershipTypeEmployee;
