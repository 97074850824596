import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

export const AccordionStyled = withStyles((theme) => ({
  root: {
    boxShadow: theme.palette.boxShadow.default,
    "& .MuiAccordionSummary-root.Mui-disabled": {
      opacity: 1,
      pointerEvents: "all",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
      minHeight: "0 !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "56px !important",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  expanded: {},
}))(Accordion);

export const AccordionDetailsStyled = withStyles((theme) => ({
  root: {
    border: 0,
    padding: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))(AccordionDetails);

export const useStyles = makeStyles((theme) => ({
  details: {
    color: theme.palette.text.darkPurple,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  icon: {
    color: theme.palette.text.link,
  },
  disabledIcon: {
    opacity: 0,
  },
  smallIcon: {
    width: "32px !important",
    height: "32px !important",
  },
  bgPurple: {
    backgroundColor: theme.palette.background.lightPurple,
  },
  bgLight: {
    backgroundColor: theme.palette.background.light,
  },
  customAccordion: {
    marginBottom: theme.spacing(1),
    "&.MuiPaper-rounded .MuiButtonBase-root": {
      borderRadius: "4px !important",
    },
  },
  simpleAccordion: {
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
  },
  customExpanded: {
    "&.MuiAccordion-rounded": {
      borderRadius: "4px !important",
    },
  },
  defaultIcon: {
    color: `${theme.palette.black} !important`,
  },
  customDetails: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  iconLeft: {
    flexDirection: "row-reverse",
    padding: theme.spacing(0),
  },
}));
