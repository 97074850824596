import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import InputEditor from "components/InputEditor";
import Button from "components/Button";
import { BUTTON_STYLE_TYPES, BUTTON_TYPE } from "common/constants";
import { Actions, Container, Content } from "./styles";

const FeedbackStartStopContinue = (props) => {
  const { control, isSubmitDisabled, isLoading } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const { t } = useTranslation("feedback");

  const validateInputEdit = (value) => setIsDisabled(isEmpty(value) || false);

  return (
    <Container container data-testid={ "feedback-start-stop-continue-view" }>
      <Content item xs={ 12 }>
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputEdit } /> }
          name={ "feedback_start_stop_continue.should_stop_do" }
          control={ control }
          label={ t("should_stop_do") }
        />
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputEdit } /> }
          name={ "feedback_start_stop_continue.should_start_do" }
          control={ control }
          label={ t("should_start_do") }
        />
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputEdit } /> }
          name={ "feedback_start_stop_continue.should_continue_do" }
          control={ control }
          label={ t("should_continue_do") }
        />
      </Content>
      <Actions item xs={ 12 } >
        <Button
          type={ BUTTON_TYPE.submit }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          isDisabled={ isSubmitDisabled || isDisabled }
          isLoading={ isLoading }
        >
          {t("send_feedback")}
        </Button>
      </Actions>
    </Container>
  );
};

FeedbackStartStopContinue.propTypes = {
  control: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

FeedbackStartStopContinue.defaultProps = {
  isSubmitDisabled: false,
  isLoading: false,
};

export default FeedbackStartStopContinue;
