/**
 * Custom hook for managing collapsible elements.
 * @returns {object} - An object containing the hiddenCell state and the toggleData function.
 */
import { useState } from "react";

export const useCollapse = () => {
  // State to track hidden cells.
  const [hiddenCell, setHiddenCell] = useState({});

  /**
   * Function to toggle the visibility of cells.
   * @param {string} parentId - The identifier of the parent element.
   * @param {boolean} collapsed - Whether the element should be collapsed or not.
   */
  const toggleData = (parentId, collapsed) => {
    setHiddenCell((prevValues) => ({ ...prevValues, [parentId]: collapsed }));
  };

  return {
    hiddenCell,
    toggleData,
  };
};
