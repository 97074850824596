import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import palette from "theme/palette";

export const StyledHeader = styled(Grid)`
  padding-bottom: 16px;
`;

export const StyledTitle = styled(Typography)`
  &.MuiTypography-body1 {
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${ palette.black }
  }
`;
export const StyledDashboardContainer = styled(Grid)`
  .custom-select {
    background-color: ${palette.white};
    padding: 10px 0 10px 0;
    label + & {
      margin-top: 8;
    }
  }
`;

export const StyledGridContainer = styled(Grid)`
  &.MuiGrid-container{
    margin-bottom: 16px;
  }
`;

export const StyledParticipationResult = styled.div`
  text-align: right;
  font-weight: 700;
  & span {
    color: ${ palette.text.secondary };
  }
`;

export const StyledParticipationTitle = styled(Typography)`
  margin-top: 25px;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 24px;
`;

export const StyledCard = styled(Card)`
  margin-top: 16px;
`;

export const StyledFilterGrid = styled(Grid)`
  margin-bottom: 20px;
`;
