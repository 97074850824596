import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getList as getSurveyProcesses } from "redux/actions/surveyProcessesActions";
import useComplexState from "hooks/utils/useComplexState";
import { getCalendarData, getEventsFromDate } from "views/Planning/functions";

export const useHomeCalendarHook = (externalHooks) => {
  const { t } = externalHooks;

  const currentDate = new Date();
  const [calendarStates, setCalendarStates] = useComplexState({
    data: null,
    currentYear: currentDate.getFullYear(),
    currentMonth: currentDate.getMonth(),
    values: "",
    isOpenEventModal: false,
    selectedEvent: null,
  });

  const { list, loadingList } = useSelector(
    ({ surveysReducer }) => surveysReducer
  );

  const handleEvent = (events) => {
    setCalendarStates({
      values: events,
    });
  };

  // events of the day
  const setEventsByCurrentDate = useCallback(
    (calendarData) => {
      const generalData = getEventsFromDate(calendarData, currentDate);
      const allData = {
        events: generalData,
        date: currentDate,
        endDate: null,
      };

      setCalendarStates({
        values: allData,
      });

      setCalendarStates({
        currentYear: currentDate.getFullYear(),
        currentMonth: currentDate.getMonth(),
      });
    },
    // eslint-disable-next-line
    [currentDate]
  );

  // reset events on calendar if there is any change
  const refreshData = useCallback(() => {
    const calendarData = getCalendarData(list, t);
    setEventsByCurrentDate(calendarData);
    setCalendarStates({
      data: calendarData,
    });
    // eslint-disable-next-line
  }, [list, t, setEventsByCurrentDate]);

  // set current year or month
  const handleControls = (index, value) => {
    setCalendarStates({
      [index]: value,
    });
  };

  useEffect(() => {
    if (!loadingList && list && !calendarStates.data) {
      refreshData();
    }
  }, [loadingList, list, refreshData, calendarStates.data]);

  const handleEventModal = (data) => {
    setCalendarStates({
      isOpenEventModal: !calendarStates.isOpenEventModal,
      selectedEvent: data,
    });
  };

  return {
    calendarStates,
    setCalendarStates,
    surveysReducerStates: {
      list,
      loadingList,
    },
    dispatchs: [
      {
        action: getSurveyProcesses,
        params: [false, null, true],
      },
    ],
    handleControls,
    setEventsByCurrentDate,
    handleEvent,
    handleEventModal,
  };
};
