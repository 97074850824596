import { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { create } from "redux/actions/acknowledgementTypeActions";
import { SessionContext } from "modules/session/context";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import Modal from "components/Modal";
import AutoCompleteCreableController from "components/AutocompleteCreableController";
import AcknowledgmentIcon from "assets/images/feedback/acknowledgment.svg";
import { getListSearchPaginated } from "redux/actions/collaboratorActions";
import {
  FORM_MODE, OBJECT_KEYS, VARIANT, SIZE,
} from "common/constants";
import { isObject } from "common/helpers";
import { getEmployeeId, getValueByProp } from "common/utils";
import AcknowledgmentForm from "../AcknowledgmentForm";
import { ModalContent } from "./styles";

const AcknowledgmentFormModal = (props) => {
  const {
    companyId,
    employeeId,
    isOpen,
    onSend = () => {},
    onClose = () => {},
  } = props;
  const { t } = useTranslation("feedback");
  const [searchFilter, setSearchFilter] = useState();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    reValidateMode: FORM_MODE.onChange,
    mode: FORM_MODE.onChange,
  });

  const {
    state: { user },
  } = useContext(SessionContext);

  const {
    searchList: collaboratorsList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const employee = employeeId;

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        active_in: [true],
        id_not_in: [employeeId],
      },
    };
    if (isObject(employeeId)) {
      query.q.id_not_in = [employeeId.id];
    }
    dispatch(getListSearchPaginated(query));
  }, [searchFilter, employeeId, dispatch]);

  const onSubmit = (formData) => {
    if (formData) {
      if (companyId) {
        formData.feedback_acknowledgement.feedback_attributes.employee_from_id = employee;
        formData.feedback_acknowledgement.feedback_attributes.employee_to_id = getValueByProp(
          formData.feedback_acknowledgement.feedback_attributes.employee_to_id,
          OBJECT_KEYS.id,
        );
      } else {
        const fromId = getEmployeeId(user);
        formData.feedback_acknowledgement.feedback_attributes = {
          employee_from_id: fromId,
        };
        formData.feedback_acknowledgement.feedback_attributes.employee_to_id = employee?.id;
      }
      formData.feedback_acknowledgement.acknowledgement_type_id = Number(formData.feedback_acknowledgement.acknowledgement_type_id);
      dispatch(create(formData));
      setSearchFilter("");
    }
  };

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  return (
    <div data-testid={ "acknowledgement-modal-view" }>
      <Modal
        isOpen={ isOpen }
        onClose={ onClose }
        title={ t("title_send_acknowledgment") }
        iconTitle={ AcknowledgmentIcon }
        maxWidth={ SIZE.md }
        fullWidth
      >
        <ModalContent>
          <StyledScrollBar maxHeight={ "600px" } padding={ "10px" }>
            <form onSubmit={ handleSubmit(onSubmit) }>
              <Grid item xs={ 8 }>
                {companyId ? (
                  <AutoCompleteCreableController
                    options={ collaboratorsList || [] }
                    label={ t("select_collaborator") }
                    control={ control }
                    rules={ { required: true } }
                    name={ "feedback_acknowledgement.feedback_attributes.employee_to_id" }
                    nameOfAttr={ OBJECT_KEYS.fullname }
                    isWithHelperText={ false }
                    isRequired
                    onInputTextChange={ handleInputText }
                  />
                ) : (
                  <Typography variant={ VARIANT.h5 }>{employee?.name}</Typography>
                )}

              </Grid>
              <Grid item xs={ 12 }>
                <AcknowledgmentForm
                  control={ control }
                  employee={ employee }
                  isSubmitDisabled={ !isValid }
                  handleFinish={ onClose }
                  handleSend={ onSend }
                />
              </Grid>
            </form>
          </StyledScrollBar>

        </ModalContent>

      </Modal>

    </div>
  );
};

AcknowledgmentFormModal.propTypes = {
  employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  companyId: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func,
};

export default AcknowledgmentFormModal;
