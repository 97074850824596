import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import { OBJECT_KEYS } from "common/constants";
import { renderCustomLabelTicks } from "common/utils";
import { StyledSpiderChartContainer } from "styledComponents/Charts";
import { renderCustomAxisTick } from "./functions";

const PersonalitySpiderChart = (props) => {
  const { dataFormatted } = props;
  const { t } = useTranslation("potential");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  return (
    <StyledSpiderChartContainer data-testid={ "personality-spider-chart-component" }>
      <ResponsiveContainer>
        <RadarChart
          margin={ isMobile ? charts.margin.mobile : charts.margin.standar }
          data={ dataFormatted }
        >
          <PolarGrid />
          <PolarAngleAxis
            dataKey={ OBJECT_KEYS.name }
            tick={ (axisTicks) => renderCustomAxisTick(axisTicks, t, isMobile) }
          />
          <PolarRadiusAxis angle={ charts.angles.ninety } domain={ charts.rangeDomain.main } tick={ renderCustomLabelTicks } />
          <Radar
            name={ OBJECT_KEYS.collaborator }
            dataKey={ OBJECT_KEYS.value }
            stroke={ charts.colors.purple }
            fill={ charts.colors.purple }
            fillOpacity={ charts.opacity.main }
            dot
          />
        </RadarChart>
      </ResponsiveContainer>
    </StyledSpiderChartContainer>
  );
};

PersonalitySpiderChart.propTypes = {
  dataFormatted: PropTypes.array.isRequired,
};

export default PersonalitySpiderChart;
