import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import charts from "theme/charts";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SessionContext } from "modules/session/context";
import { useTemplateHook } from "./hooks/surveyTemplate.hook";
import SurveyTemplateView from "./SurveyTemplate.view";

const SurveyTemplateController = (props) => {
  const { t, dispatch } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const templateStates = useTemplateHook({
    dispatch,
    user,
    theme,
    t,
  });

  return (
    <SurveyTemplateView
      user={ user }
      { ...templateStates }
      { ...props }
      isMobile={ isMobile }
    />
  );
};

SurveyTemplateController.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SurveyTemplateController;
