import styled from "styled-components";
import Link from "@mui/material/Link";
import palette from "theme/palette";

export const StyledVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%; // This is in percent to make it responsive
  height: 0;
  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`;

export const StyledVideoMp4 = styled.div`
  & video {
    display: flex;
    width: 250px;
  }
  @media (max-width: 600px) {
    & video {
      width: 100%;
    }
  }
`;

export const StyledLink = styled(Link)`
  border: ${palette.border.radioSelected};
  padding: 10px 16px;
  border-radius: 4px;
  width: fit-content;
  align-items: center;
  display: flex;
  img {
    margin-right: 5px;
  }
  h6 {
    color: ${palette.text.link};
  }
`;

export const StyledWebsite = styled.div`
  color: ${palette.text.link};
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  display: flex;
  width: fit-content;
  border: ${palette.border.radioSelected};
  padding: 10px 16px;
  border-radius: 4px;
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
`;
