import { useEffect } from "react";
import i18n from "i18next";
import Calendar from "rc-year-calendar";
import PropTypes from "prop-types";
import "rc-year-calendar/locales/rc-year-calendar.es";
import "rc-year-calendar/locales/rc-year-calendar.pt";
import { moduleByMonths } from "common/utils";
import { isEqual } from "common/helpers";
import { customData, customDate } from "./functions";
import { CalendarContainer } from "./styles";

const CustomCalendar = (props) => {
  const {
    data, onSelectedEvent, currentDate, currentYear, currentMonth, isMonthMode,
  } = props;
  const { language } = i18n;

  const handleEventChange = (e) => {
    onSelectedEvent(
      {
        events: e.events,
        date: e.date || e.startDate,
        endDate: e.endDate || null,
      },
    );
  };

  const styleProp = { style: "custom" };

  useEffect(() => {
    if (isMonthMode) {
      const elements = document.querySelectorAll("[data-month-id]");
      elements.forEach((element) => {
        const value = element.getAttribute("data-month-id");
        if (isEqual(parseInt(value), moduleByMonths(currentMonth))) {
          element.classList.remove("invisible");
          element.classList.add("visible");
        } else {
          element.classList.remove("visible");
          element.classList.add("invisible");
        }
      });
    }
  }, [data, currentMonth, currentYear, isMonthMode]);

  return (
    <CalendarContainer data-testid={ "calendar-component" } isMonthMode={ isMonthMode }>
      <Calendar
        { ... styleProp }
        language={ language }
        dataSource={ data }
        year={ currentYear }
        customDataSourceRenderer={ (e, dataRender, events) => customData(e, dataRender, events) }
        customDayRenderer={ (e, date) => customDate(e, date, currentDate) }
        onDayClick={ (e) => onSelectedEvent && handleEventChange(e) }
        displayHeader={ false }
        enableRangeSelection
        onRangeSelected={ (e) => handleEventChange(e) }
      />
    </CalendarContainer>
  );
};

CustomCalendar.propTypes = {
  data: PropTypes.array.isRequired,
  onSelectedEvent: PropTypes.func,
  currentDate: PropTypes.instanceOf(Date),
  currentYear: PropTypes.number,
  currentMonth: PropTypes.number,
  isMonthMode: PropTypes.bool,
};

CustomCalendar.defaultProps = {
  onSelectedEvent: () => {},
  currentDate: new Date(),
  currentYear: new Date().getFullYear(),
  currentMonth: new Date().getMonth(),
  isMonthMode: false,
};

export default CustomCalendar;
