import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { getParticipationSchemaBySchemaPaginated, getParticipationSchemaPaginated } from "redux/actions/surveyProcessesActions";

const useParticipationSchema = (queryKey, hasParticipationSchema, shouldRefetch) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [manualLoading, setManualLoading] = useState(false);

  const fetchParticipationSchema = ({ pageParam = 1 }) => (
    hasParticipationSchema
      ? getParticipationSchemaBySchemaPaginated(queryKey[2], pageParam)
      : getParticipationSchemaPaginated(queryKey[1], pageParam)
  );

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch: refetchParticipationSchema,
    error,
  } = useInfiniteQuery(
    queryKey,
    fetchParticipationSchema,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      retry: false,
      // onError: handleParticipationError,
      enabled: !manualLoading,
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.reduce((acc, page) => acc + page.participations.length, 0);
        return totalFetched < lastPage.total ? lastPage.page + 1 : undefined;
      },
    },
  );

  useEffect(() => {
    if (isInitialLoad && !isLoading) {
      setIsInitialLoad(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isInitialLoad && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, isInitialLoad, isFetchingNextPage]);

  useEffect(() => {
    if (shouldRefetch) {
      setManualLoading(true);
      refetchParticipationSchema();
      setManualLoading(false);
    }
    // eslint-disable-next-line
  }, [shouldRefetch]);

  // Flatten the paginated data
  const participationSchema = data?.pages.flatMap((page) => page.participations) || [];

  const isLoadingParticipation = (isLoading && isInitialLoad) || manualLoading;
  const isFetchingParticipation = isFetchingNextPage && !isInitialLoad;

  return {
    participationSchema,
    isLoadingParticipation,
    isFetchingParticipation,
    participationError: error,
  };
};

export default useParticipationSchema;
