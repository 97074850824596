import { useState } from "react";
import { getFilterNames } from "components/TalentSpiderChart/functions";
import useComplexState from "hooks/utils/useComplexState";
import { FILTERS_KEYS } from "common/constants";

/* Custom hook to get the filters data and handle the filters state */
export const useFilters = ({ t, list }) => {
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [citiesFilter, setCitiesFilter] = useState([]);
  const [unitiesFilter, setUnitiesFilter] = useState([]);
  const [managersFilter, setManagersFilter] = useState([]);
  const [collaboratorsStatesFilter, setCollaboratorsStatesFilter] = useState([
    {
      label: t("tables:filters.collaborator_states.active"),
      id: true,
    },
  ]);
  const [states, setStates] = useComplexState({
    dispatched: false,
    filterQuery: null,
    searchFilter: null,
    isFirstLoad: false,
  });

  const defaultFilters = {
    countries: countriesFilter,
    cities: citiesFilter,
    unities: unitiesFilter,
    managers: managersFilter,
    collaboratorsStates: collaboratorsStatesFilter,
  };

  const FILTERS = {
    countries: {
      state: countriesFilter,
      set: setCountriesFilter,
    },
    cities: {
      state: citiesFilter,
      set: setCitiesFilter,
    },
    unities: {
      state: unitiesFilter,
      set: setUnitiesFilter,
    },
    managers: {
      state: managersFilter,
      set: setManagersFilter,
    },
    collaboratorsStates: {
      state: collaboratorsStatesFilter,
      set: setCollaboratorsStatesFilter,
    },
  };

  const filterOptions = [
    {
      type: FILTERS_KEYS.managers,
      title: t("filters.managers"),
      options: managersFilter,
    },
    {
      type: FILTERS_KEYS.countries,
      title: t("filters.countries"),
      options: countriesFilter,
    },
    {
      type: FILTERS_KEYS.cities,
      title: t("filters.cities"),
      options: citiesFilter,
    },
    {
      type: FILTERS_KEYS.unities,
      title: t("filters.orgUnits"),
      options: unitiesFilter,
    },
    {
      type: FILTERS_KEYS.collaboratorsStates,
      title: t("filters.states"),
      options: collaboratorsStatesFilter,
    },
  ];

  const handleExternalFilter = (selectedFilter, values) => {
    const filterSet = FILTERS[selectedFilter].set;
    const filteredValues = values[selectedFilter];
    filterSet(filteredValues || []);
  };

  const handleDeleteOption = (type, itemId) => {
    const filterState = FILTERS[type].state;
    const filterSet = FILTERS[type].set;
    const updatedFilter = filterState.filter((item) => item.id !== itemId);
    setStates({ dispatched: false });
    filterSet(updatedFilter);
  };

  const resetDispatched = (isReset) => {
    setStates({ dispatched: isReset });
  };

  const filterName = list?.averages ? getFilterNames(list?.averages) : [];

  const handleExternalQuery = (query) => {
    const tempFilterQuery = query;
    tempFilterQuery.q.employee_person_full_name_cont = states.searchFilter;
    setStates({ filterQuery: tempFilterQuery, isFirstLoad: false });
  };

  const handleSearch = (name = "") => {
    if (!states.isFirstLoad) {
      setStates({ searchFilter: name });
    }
  };

  return {
    defaultFilters,
    states,
    handleExternalFilter,
    handleDeleteOption,
    resetDispatched,
    filterName,
    handleExternalQuery,
    handleSearch,
    filterOptions,
  };
};
