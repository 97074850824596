import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useUserAbility from "hooks/useUserAbility";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import SimpleAlert from "components/SimpleAlert";
import Filter from "components/Filter";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import { SessionContext } from "modules/session/context";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import {
  GOALS_INDEX_TABS,
  VARIANT,
  INFO,
  MODULE_ACTIONS,
  MODULES,
} from "common/constants";
import { isOnlyCollaborator } from "common/utils";
import {
  getRulesList as getGoalsRulesList,
  resetStateProcess as resetStateGoalProcess,
} from "redux/actions/goalsActions";
import { getList as getPeriods } from "redux/actions/periods/periodsActions";
import { StyledTabsContainer } from "styledComponents/Tabs";
import TableGoals from "./components/TableGoals";
import {
  StyledContainer, StyledTitle, StyledCard,
} from "./styles";

// TODO: refactor and use new architecture
const Goals = (props) => {
  const { t } = useTranslation(["goals", "tables"]);
  const dispatch = useDispatch();

  const filter = props?.location?.search;
  let filterName = null;
  if (filter) {
    const params = new URLSearchParams(filter);
    filterName = params.get("employee-name");
  }

  const {
    successProcess: successGoalsProcess,
    isLoadingList: isLoadingGoalsList,
  } = useSelector(
    ({ goalsReducer }) => goalsReducer,
  );

  const {
    state: { user },
  } = useContext(SessionContext);

  const ability = useUserAbility(user);
  const goalAbility = ability?.goals;
  const isUpdateAllowed = goalAbility?.can(MODULE_ACTIONS.update, MODULES.goals);

  const [tabHandler, setTabHandler] = useState(0);
  const [filterQuery, setFilterQuery] = useState();

  useEffect(() => {
    dispatch(getGoalsRulesList());
    dispatch(getPeriods());
  }, [dispatch]);

  useEffect(() => {
    if (successGoalsProcess) {
      dispatch(resetStateGoalProcess());
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
    }
  }, [successGoalsProcess, dispatch, t]);

  const allTabs = [
    { label: t("tabs.in-progress") },
    { label: t("tabs.general") },
  ];

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const handleExternalQuery = (query) => {
    setFilterQuery(query);
  };

  const getGoalsTable = (isGeneral = false) => (
    <StyledCard>
      <CardContent>
        <Grid container>
          <Grid item xs={ 12 }>
            <SimpleAlert
              type={ INFO }
              title={ t("alert.title") }
              message={ t("alert.text") }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <TableGoals
              isGeneral={ isGeneral }
              filterQuery={ filterQuery }
              isUpdateAllowed={ isUpdateAllowed }
              filterName={ filterName }
            />
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );

  const inProgressTab = getGoalsTable();
  const archivedTab = getGoalsTable(true);

  const getFilters = () => (!isOnlyCollaborator(user?.userCookies) ? (
    <Grid container>
      <Grid item xs={ 12 } md={ 10 }>
        <Filter
          type={ FILTER_ACTIONS_TYPES.goals }
          isLabel
          handleExternalQuery={ handleExternalQuery }
          isLoading={ isLoadingGoalsList }
          isMultiple
        />
      </Grid>
    </Grid>
  ) : null);

  return (
    <StyledContainer data-testid={ "goals-view" }>
      <Grid container>
        <Grid item xs={ 12 }>
          <Box pb={ 3 }>
            <StyledTitle>{t("title")}</StyledTitle>
            <Typography variant={ VARIANT.bodyOne }>{t("subtitle")}</Typography>
          </Box>
          { getFilters() }
          <Grid container spacing={ 4 }>
            <StyledTabsContainer
              item
              xs={ 12 }
              spacing={ 4 }
              fontsize={ 14 }
              textcolor={ "black" }
              normaltext
              uppercase
              withbackground
            >
              <Tab
                tabs={ allTabs }
                onChange={ handleValueChanged }
                tabValue={ tabHandler }
              />
              <TabPanel value={ tabHandler } index={ GOALS_INDEX_TABS.inProgress }>
                {inProgressTab}
              </TabPanel>
              <TabPanel value={ tabHandler } index={ GOALS_INDEX_TABS.archived }>
                {archivedTab}
              </TabPanel>
            </StyledTabsContainer>
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Goals;
