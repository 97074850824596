import { getAvatarPromise } from "common/utils";

export const fetchAvatarUrlsByMembers = (members) => {
  const promises = members.map((member) => {
    const evaluationResult = member?.evaluationResult || member;
    const evaluated = evaluationResult?.evaluation_details?.evaluated;
    return getAvatarPromise(evaluated);
  });
  return Promise.all(promises);
};
