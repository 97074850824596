import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { PERCENTAGE, VARIANT, MIN_VALUE } from "common/constants";
import {
  Container, LinearProgressContainer, Title, Results, Bubble, Progress,
} from "./styledComponents";

const BubbleProgress = (props) => {
  const { data } = props;

  return (
    <Container data-testid={ "bubble-progress-component" }>
      <Progress>
        {!isEmpty(data) && data.map((item) => (
          <Bubble
            key={ item.name }
            value={ item.value }
            size={ item.size } // 0 - 100
          />
        ))}
        <LinearProgressContainer>
          <LinearProgress
            variant={ VARIANT.determinate }
            value={ MIN_VALUE }
          />
        </LinearProgressContainer>
      </Progress>
      <Results>
        <Box flexGrow={ 1 }>
          <Title variant={ VARIANT.h6 }>
            {`${MIN_VALUE}%`}
          </Title>
        </Box>
        <Box>
          <Title variant={ VARIANT.h6 }>
            {`${PERCENTAGE.max}%`}
          </Title>
        </Box>
      </Results>
    </Container>
  );
};

BubbleProgress.propTypes = {
  data: PropTypes.array,
};

BubbleProgress.defaultProps = {
  data: [],
};

export default BubbleProgress;
