import makeStyles from "@mui/styles/makeStyles";
import { TABLE_TOOLBAR } from "theme/palette";
import { COLORS_NAME } from "common/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 44,
  },
  highlight:
    theme.palette.type === COLORS_NAME.light
      ? {
        color: theme.palette.black,
        backgroundColor: TABLE_TOOLBAR.background,
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
