import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  ASC,
  DESC,
  ALIGN_HEADER,
  ALIGN_ITEMS,
} from "common/constants";
import {
  StyledHeaderItem, StyledTableHead, StyledHeaderTitle, StyledTableCell,
} from "../../styles";

const TableHeader = (props) => {
  const {
    headerOptions,
    order,
    orderBy,
    onRequestSort,
    hiddenCell,
    toggleData,
  } = props;

  const handlerSortRequest = (property) => (event) => {
    onRequestSort(event, property);
  };

  const createSortIcon = (headCell) => !headCell?.noSortable && (
    <TableSortLabel
      active={ orderBy === headCell.id }
      direction={ orderBy === headCell.id ? order : ASC }
      onClick={ handlerSortRequest(headCell.id) }
      aria-label={ `sort ${headCell.label}` }
    />
  );

  const createCollapseIcon = (headCell) => headCell.isParent && (
    <Box>
      <IconButton
        aria-label={ `collapse ${headCell.label}` }
        onClick={ () => (
          toggleData(headCell.id, !hiddenCell[headCell.id])
        ) }
      >
        {hiddenCell[headCell.id]
          ? <RemoveCircleIcon />
          : <AddCircleIcon /> }
      </IconButton>
    </Box>
  );

  return (
    <StyledTableHead>
      <TableRow>
        {headerOptions.map((headCell) => {
          const headCellParent = headCell?.parent;
          return (
            <StyledTableCell
              key={ headCell.id }
              sortDirection={ orderBy === headCell.id ? order : false }
              collapsible={ headCellParent }
              show={ !!(headCellParent && hiddenCell[headCellParent]) }
              align={ ALIGN_ITEMS[headCell?.align] || ALIGN_ITEMS.center }
              data-testid={ `table-header-${headCell.id}` }
            >
              <StyledHeaderItem
                onlyLabel={ headCell?.noSortable }
                align={ ALIGN_HEADER[headCell?.align] || ALIGN_ITEMS.center }
              >
                {createSortIcon(headCell)}
                <StyledHeaderTitle>{headCell.label}</StyledHeaderTitle>
                {createCollapseIcon(headCell)}
              </StyledHeaderItem>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </StyledTableHead>
  );
};

TableHeader.propTypes = {
  headerOptions: PropTypes.array,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf([ASC, DESC]),
  orderBy: PropTypes.string,
  hiddenCell: PropTypes.object,
  toggleData: PropTypes.func,
};

TableHeader.defaultProps = {
  headerOptions: [],
  onRequestSort: () => {},
  order: ASC,
  orderBy: "",
  hiddenCell: {},
  toggleData: () => {},
};

export default TableHeader;
