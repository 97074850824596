import { ImageLoader } from "react-progressive-img-loader";
import NumberFormat from "react-number-format";
import moment from "moment";
import reduce from "lodash/reduce";
import sum from "lodash/sum";
import multiply from "lodash/multiply";
import divide from "lodash/divide";
import isUndefined from "lodash/isUndefined";
import toNumber from "lodash/toNumber";
import isString from "lodash/isString";
import findIndex from "lodash/findIndex";
import lodashFind from "lodash/find";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MenuPopup from "components/MenuPopup";
import TooltipIcon from "components/TooltipIcon";
import StaticProgressBar from "components/StaticProgressBar";
import InputTextController from "components/InputTextController";
import SelectController from "components/SelectController";
import DateInputController from "components/DateInputController";
import InputNumberController from "components/InputNumberController";
import Button from "components/Button";
import ProfileImageDetail from "components/ProfileImageDetail";
import MultiSelectController from "components/MultiSelectController";

import {
  PERCENTAGE,
  ROUND,
  OBJECT_KEYS,
  SORT_COMPARATOR,
  GOAL_RULES,
  DEFAULT_MIN_DECIMAL,
  TYPES,
  MULTILINE,
  SIZE,
  WIDTH,
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
} from "common/constants";
import { STATUS } from "common/constants/goals";
import {
  getPercent,
  formatDate,
  formatDateToSubmit,
  formatDateStartByLanguage,
  getUTCYearFromDate,
} from "common/utils";
import { orderByAsc } from "common/helpers";
import palette, { GOAL_STATUS_COLORS } from "theme/palette";
import { StyledChip, StyledResult, StyledGrid } from "../styles";

export const calculatedTotalPercentage = (goalList) => {
  const filteredGoalList = goalList.filter((goal) => goal.completion_percentage !== null);
  if (filteredGoalList.length > 0) {
    const totalWeight = filteredGoalList.reduce((total, value) => total + value.weight, 0);

    return goalList.reduce((result, value) => sum([
      result,
      divide(
        multiply(
          getPercent(value.completion_percentage, false, ROUND.max),
          getPercent(value.weight, false, ROUND.max),
        ),
        getPercent(totalWeight, false, ROUND.max),
      ),
    ]), 0).toFixed(DEFAULT_MIN_DECIMAL);
  }
  return 0;
};

// This function, getSelectedPeriod, is used to retrieve selected period and subperiod information
export const getSelectedPeriod = (periodListTransformed, periodId = null, SubperiodId = null) => {
  // Initialize default values as empty objects
  let parent = { id: "", label: "" };
  let child = { id: "", label: "" };

  // Set as the default period the one that has no subperiods
  const defaultPeriod = periodListTransformed.find((item) => item.children.length === 0);
  if (defaultPeriod) {
    parent = { id: defaultPeriod.id, label: defaultPeriod.label };
  }

  // Check if periodId is null or undefined
  if (periodId !== null && periodId !== undefined) {
    // Only look for the period if periodId is not null or undefined
    const period = periodListTransformed.find((item) => item.id === periodId);

    if (period) {
      // If the period is found, update the parent object
      parent = { id: period.id, label: period.label };

      // Check if SubperiodId is null or undefined
      if (SubperiodId !== null && SubperiodId !== undefined) {
        // If SubperiodId is not null or undefined, search for the subperiod in children
        const subperiod = (period.children || []).find((item) => item.id === SubperiodId);
        if (subperiod) {
          // If the subperiod is found, update the child object
          child = { id: subperiod.id, label: subperiod.label };
        }
      }
    }
  }

  // Return the resulting object containing parent and child information
  return { parent, child };
};

export const getGoals = (goals) => {
  const goalList = goals?.filter(
    (value) => (value.id !== OBJECT_KEYS.total) && (value.id !== 0),
  );
  return goalList;
};

export const totalCell = {
  id: OBJECT_KEYS.total,
  metric: OBJECT_KEYS.total,
  weight: 0,
};

export const getDataEmpty = (periods) => ({
  isEdit: true,
  id: 0,
  description: "",
  metric: "",
  weight: "",
  completion_percentage: "",
  completed_value: "",
  starting_date: "",
  end_date: "",
  goal_rule_id: 1,
  selected_period: getSelectedPeriod(periods),
});

export const getTotalGoals = (idEdit, weightEdit, values) => {
  const generalSum = !isUndefined(idEdit)
    ? values.filter(
      (value) => (value.id !== idEdit) && (value.id !== OBJECT_KEYS.total),
    )
    : values;
  let sumTotal = reduce(
    generalSum,
    (result, value) => sum([result, getPercent(value.weight, false, ROUND.max)]),
    0,
  );
  sumTotal = !isUndefined(idEdit) && !isUndefined(weightEdit)
    ? sum([toNumber(weightEdit), sumTotal])
    : sumTotal;

  return sumTotal;
};

export const transformedPeriods = (periods, t) => periods?.map((period) => ({
  id: period.id,
  label: t(`periods:${period.slug}.name`),
  children: period.subperiods?.map((subperiod) => ({
    id: subperiod.id,
    label: t(`periods:${period.slug}.subperiods.${subperiod.value.toString()}`),
  })),
}));

export const formatData = (goalsData) => {
  // Perform your data formatting operations
  goalsData.goals.starting_date = formatDateToSubmit(goalsData.goals.starting_date);
  goalsData.goals.end_date = formatDateToSubmit(goalsData.goals.end_date);
  if (isString(goalsData.goals.metric)) {
    goalsData.goals.metric = goalsData.goals.metric.replaceAll(",", "");
  }
  if (isString(goalsData.goals.completed_value)) {
    goalsData.goals.completed_value = goalsData.goals.completed_value.replaceAll(",", "");
  }
  goalsData.goals.metric = toNumber(goalsData.goals.metric);
  const completeValue = goalsData.goals.completed_value;
  goalsData.goals.completed_value = completeValue ? toNumber(completeValue) : null;
  const goalsDataWeight = toNumber(goalsData.goals.weight);
  goalsData.goals.weight = goalsDataWeight / PERCENTAGE.max;

  // Assign 'period_id' and 'subperiod_id' properties if needed
  goalsData.goals.period_id = goalsData.goals.selected_period?.parent?.id;
  goalsData.goals.subperiod_id = goalsData.goals.selected_period?.child?.id;

  // Return the modified 'goalsData.goals' object
  return goalsData.goals;
};

export const getUpdatedGoals = (goals, goalSaved, deleteValue, sumTotal) => {
  lodashFind(goals, [OBJECT_KEYS.id, OBJECT_KEYS.total]) && goals.pop();
  const hasPreviewGoal = findIndex(goals, {
    id: 0 || deleteValue,
  });
  if (goalSaved) {
    goalSaved.isEdit = false;
    const hasNewGoal = findIndex(goals, {
      id: goalSaved.id,
    });
    if (hasNewGoal === SORT_COMPARATOR.minusOne) { goals.push(goalSaved); }

    if (hasNewGoal !== SORT_COMPARATOR.minusOne) {
      goals[hasNewGoal] = goalSaved;
    }
  }

  if ((deleteValue && (goalSaved?.length === 0 || !goalSaved)) || goalSaved) {
    if (hasPreviewGoal !== SORT_COMPARATOR.minusOne) {
      goals.splice(hasPreviewGoal, SORT_COMPARATOR.one);
    }
  }

  sumTotal(goals);

  !lodashFind(goals, [OBJECT_KEYS.id, OBJECT_KEYS.total])
    && goals.push(totalCell);

  return orderByAsc(goals, OBJECT_KEYS.id);
};

export const isUpdatedGoals = (
  employeeId,
  updatedEmployeeId,
  isLoading,
  goalSaved,
  errors,
  newData,
) => (
  (employeeId === updatedEmployeeId)
    && !isLoading
    && ((goalSaved !== null) || (goalSaved?.length === 0))
    && !errors
    && !newData
);

export const getRuleName = (name, t) => (t(`tables:rules.${GOAL_RULES.lessIsBetter === name ? "less_is_better" : "more_is_better"}`));

export const getRules = (rules, t) => {
  const goalRules = rules?.map((rule) => ({
    value: rule.id,
    label: getRuleName(rule.name, t),
  }));
  return goalRules;
};

export const getColor = (state) => {
  const customColorMap = {
    [STATUS.notStarted]: GOAL_STATUS_COLORS.notStarted,
    [STATUS.notCompleted]: GOAL_STATUS_COLORS.notCompleted,
    [STATUS.requiresAttention]: GOAL_STATUS_COLORS.requiresAttention,
    [STATUS.goodStart]: GOAL_STATUS_COLORS.goodStart,
    [STATUS.halfwayThere]: GOAL_STATUS_COLORS.halfwayThere,
    [STATUS.almostThere]: GOAL_STATUS_COLORS.almostThere,
    [STATUS.wellDone]: GOAL_STATUS_COLORS.wellDone,
    [STATUS.wonderful]: GOAL_STATUS_COLORS.wonderful,
  };

  return customColorMap[state] || GOAL_STATUS_COLORS.notStarted;
};

const getChipStatus = (status, t) => (
  <StyledChip
    background={ getColor(status).background }
    textcolor={ getColor(status).text }
    label={ t(`goals:status.${status}`) }
    marginRight={ "20px" }
  />
);

export const getGoalsHeader = (
  control,
  rules,
  classes,
  totalSum,
  functions,
  t,
  isGeneral,
  periodListTransformed,
  isUpdateAllowed,
) => {
  const {
    sumTotal, editGoal, handleDeleteGoal, closeForm, handleDuplicate,
  } = functions;
  return [
    {
      id: OBJECT_KEYS.description,
      label: t("tables:headers.description"),
      width: WIDTH.text,
      tooltip: (
        <TooltipIcon
          children={ t("tables:helpers.description") }
          isHelpIcon
        />
      ),
      customRender: (rowData) => (rowData.isEdit ? (
        <>
          <div className={ classes.hiddenInput }>
            <InputTextController
              type={ TYPES.hidden }
              control={ control }
              name={ "goals.id" }
              defaultValue={ rowData.id }
            />
          </div>
          <InputTextController
            required
            type={ TYPES.text }
            control={ control }
            name={ "goals.description" }
            defaultValue={ rowData.description }
            multiline={ MULTILINE.min }
            showHelperText={ false }
            size={ SIZE.small }
          />
        </>
      ) : (
        rowData.description
      )),
    },
    {
      id: OBJECT_KEYS.goalRuleId,
      label: t("tables:headers.rule"),
      width: WIDTH.select,
      tooltip: (
        <TooltipIcon children={ t("tables:helpers.rule") } isHelpIcon />
      ),
      customRender: (rowData) => {
        const goalRules = getRules(rules, t);
        return rowData.isEdit ? (
          <SelectController
            id={ `${OBJECT_KEYS.goalRuleId}-${rowData.id}` }
            control={ control }
            menuItems={ goalRules }
            name={ "goals.goal_rule_id" }
            defaultValue={ "" }
            size={ SIZE.small }
          />
        ) : (
          lodashFind(goalRules, [OBJECT_KEYS.value, rowData.goal_rule_id])?.label
        );
      },
    },
    {
      id: OBJECT_KEYS.metric,
      label: t("tables:headers.metrics"),
      tooltip: (
        <TooltipIcon children={ t("tables:helpers.metrics") } isHelpIcon />
      ),
      customRender: (rowData) => {
        const isTotal = rowData.id === OBJECT_KEYS.total;
        return rowData.isEdit ? (
          <div className={ classes.number }>
            <InputNumberController
              id={ `${OBJECT_KEYS.metric}-${rowData.id}` }
              control={ control }
              name={ "goals.metric" }
              isRequired
              // showHelperText
              decimalScale={ DEFAULT_MIN_DECIMAL }
              size={ SIZE.small }
              maxLength
            />
          </div>
        ) : (
          <StyledResult className={ isTotal ? classes.totalCell : "" }>
            {isTotal ? (
              !isGeneral ? rowData.metric : ""
            ) : (
              <NumberFormat
                value={ rowData.metric }
                displayType={ TYPES.text }
                thousandSeparator
                decimalScale={ DEFAULT_MIN_DECIMAL }
              />
            )}
          </StyledResult>
        );
      },
      width: WIDTH.number,
    },
    {
      id: OBJECT_KEYS.weight,
      label: t("tables:headers.weight"),
      tooltip: (
        <TooltipIcon children={ t("tables:helpers.weight") } isHelpIcon />
      ),
      customRender: (rowData) => {
        const isTotal = rowData.id === OBJECT_KEYS.total;
        return rowData.isEdit ? (
          <div className={ classes.number }>
            <InputNumberController
              id={ `${OBJECT_KEYS.weight}-${rowData.id}` }
              control={ control }
              name={ "goals.weight" }
              isPercentage
              decimalScale={ DEFAULT_MIN_DECIMAL }
              size={ SIZE.small }
            />
          </div>
        ) : isTotal && isGeneral ? "" : (
          <StyledResult className={ isTotal ? classes.totalCell : "" }>
            {`${
            isTotal
              ? (isUndefined(totalSum) ? sumTotal() : sumTotal() || totalSum).toFixed(DEFAULT_MIN_DECIMAL)
              : getPercent(rowData.weight, false, ROUND.max)
          }%`}
          </StyledResult>
        );
      },
      width: WIDTH.number,
    },
    {
      id: OBJECT_KEYS.completedValue,
      label: t("tables:headers.status"),
      tooltip: (
        <TooltipIcon children={ t("tables:helpers.status") } isHelpIcon />
      ),
      customRender: (rowData) => (rowData.isEdit ? (
        <div className={ classes.number }>
          <InputNumberController
            id={ `${OBJECT_KEYS.completedValue}-${rowData.id}` }
            control={ control }
            name={ "goals.completed_value" }
            decimalScale={ DEFAULT_MIN_DECIMAL }
            size={ SIZE.small }
            maxLength
          />
        </div>
      ) : (
        <StyledResult>
          {rowData.completed_value === null ? "-" : (
            <NumberFormat
              value={ rowData.completed_value }
              displayType={ TYPES.text }
              thousandSeparator
              decimalScale={ DEFAULT_MIN_DECIMAL }
            />
          )}
        </StyledResult>
      )),
      width: WIDTH.number,
    },
    {
      id: OBJECT_KEYS.state,
      label: t("tables:headers.state"),
      customRender: (rowData) => rowData.status && getChipStatus(rowData.status, t),
    },
    {
      id: OBJECT_KEYS.startingDate,
      label: t("tables:headers.start_date"),
      width: WIDTH.date,
      customRender: (rowData) => (rowData.isEdit ? (
        <StyledGrid>
          <DateInputController
            id={ `${OBJECT_KEYS.startingDate}-${rowData.id}` }
            control={ control }
            name={ "goals.starting_date" }
            openTo={ "year" }
            value={ moment() }
            views={ ["year", "month", "day"] }
            size={ SIZE.small }
          />
        </StyledGrid>
      ) : (
        rowData.starting_date && formatDateStartByLanguage(rowData.starting_date)
      )),
    },
    {
      id: OBJECT_KEYS.end_date,
      label: t("tables:headers.deadline_date"),
      width: WIDTH.date,
      customRender: (rowData) => (rowData.isEdit ? (
        <StyledGrid>
          <DateInputController
            id={ `${OBJECT_KEYS.end_date}-${rowData.id}` }
            control={ control }
            name={ "goals.end_date" }
            openTo={ "year" }
            value={ moment() }
            views={ ["year", "month", "day"] }
            size={ SIZE.small }
          />
        </StyledGrid>
      ) : (
        rowData.end_date && formatDateStartByLanguage(rowData.end_date)
      )),
    },
    {
      id: OBJECT_KEYS.lastUpdated,
      label: t("tables:headers.last_updated"),
      customRender: (rowData) => {
        const formattedLastUpdated = rowData.last_updated_at && formatDate(
          rowData.last_updated_at, null, true,
        );
        const formattedLastUpdatedBy = rowData.last_updated_by;
        return (
          <div>
            {formattedLastUpdated}
            {formattedLastUpdatedBy && <div>{formattedLastUpdatedBy}</div>}
          </div>
        );
      },
    },
    {
      id: "period",
      label: t("tables:headers.measurement_period"),
      customRender: (rowData) => {
        if (rowData.isEdit) {
          const defaultSelectedPeriod = getSelectedPeriod(
            periodListTransformed, rowData.period?.id, rowData.subperiod?.id,
          );
          return (
            <MultiSelectController
              control={ control }
              name={ "goals.selected_period" }
              data={ periodListTransformed }
              defaultValue={ defaultSelectedPeriod }
            />
          );
        }
        if (rowData.period?.id) {
          let periodLabel = t(`periods:${rowData.period.slug}.name`);
          if (rowData.subperiod?.id) {
            periodLabel = t(`periods:${rowData.period.slug}.subperiods.${rowData.subperiod.value.toString()}`);
          }
          return (
            <StyledChip
              label={ periodLabel }
              minWidth={ "70px" }
              blacktextcolor={ 1 }
            />
          );
        } return "";
      },
      width: WIDTH.progressBar,
    },
    {
      id: OBJECT_KEYS.completionPercentage,
      label: t("tables:headers.compliance"),
      customRender: (rowData) => {
        const isTotal = rowData.id === OBJECT_KEYS.total;
        return rowData.isEdit ? (
          <Button
            variant={ VARIANT.contained }
            color={ COLORS_NAME.primary }
            type={ BUTTON_STYLE_TYPES.SUBMIT }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            customStyle={ classes.fullWidth }
          >
            {t("common:common.save")}
          </Button>
        ) : (
          !isTotal && (
            <StaticProgressBar
              percentage={ Number(getPercent(
                rowData.completion_percentage,
                false,
                ROUND.max,
              )) }
              isNotShowLabel={ rowData.completion_percentage === null }
              color={ palette.linearProgress.complete }
              isPrimaryColor={ false }
            />
          )
        );
      },
      width: WIDTH.progressBar,
    },
    {
      id: OBJECT_KEYS.actions,
      label: t("tables:headers.actions"),
      customRender: (rowData) => {
        const isTotal = rowData.id === OBJECT_KEYS.total;
        const EDIT_OPTION = {
          title: t("tables:actions.update"),
          icon: <CheckCircleOutlineIcon fontSize={ SIZE.small } />,
          onClick: () => {
            editGoal(rowData.id);
          },
        };
        const DUPLICATE_OPTION = {
          title: t("tables:actions.duplicate"),
          icon: <NoteAddIcon fontSize={ SIZE.small } />,
          onClick: () => {
            handleDuplicate(rowData);
          },
        };
        const DELETE_OPTION = {
          title: <div className={ classes.delete }>{t("tables:actions.delete")}</div>,
          icon: <DeleteIcon fontSize={ SIZE.small } className={ classes.delete } />,
          onClick: () => {
            handleDeleteGoal(rowData.id);
          },
        };
        const GENERAL_ACTIONS_MENU = [EDIT_OPTION, DUPLICATE_OPTION, DELETE_OPTION];
        const ACTIONS_MENU = [EDIT_OPTION, DELETE_OPTION];
        const filteredGeneralActionsMenu = isUpdateAllowed
          ? GENERAL_ACTIONS_MENU : [DUPLICATE_OPTION];
        const filteredActionsMenu = isUpdateAllowed ? ACTIONS_MENU : [];
        const actionsToUse = isGeneral ? filteredGeneralActionsMenu : filteredActionsMenu;

        return rowData.isEdit ? (
          <Button
            variant={ VARIANT.contained }
            onClick={ () => closeForm(rowData.id) }
            color={ COLORS_NAME.primary }
            typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
          >
            {t("collaborators:buttons.cancel")}
          </Button>
        ) : (
          !isTotal && actionsToUse.length > 0 && (
            <MenuPopup
              items={ actionsToUse }
            />
          )
        );
      },
      width: WIDTH.actions,
    },
  ];
};

export const getPrincipalHeader = (t, classes, isGeneral) => {
  const goalsHeader = [
    {
      id: OBJECT_KEYS.employeeFullName,
      label: t("tables:headers.name"),
      collapseCell: { isActive: true },
      width: WIDTH.name,
      customRender: (rowData) => {
        const customImage = (rowData.employee_profile_img_url_thumb
          && rowData.employee_profile_img_url && (
          <ImageLoader
            src={ rowData.employee_profile_img_url }
            srcPreview={ rowData.employee_profile_img_url_thumb }
            customImageComponent={ Avatar }
          />
        ))
        || (!rowData.employee_profile_img_url_thumb && (
          <Avatar src={ rowData.employee_profile_img_url } />
        ));
        return (
          <>
            <Box pr={ 2 } className={ classes.smallImage }>
              {customImage}
            </Box>
            <Box>{rowData.employee_full_name}</Box>
          </>
        );
      },
    },
    {
      id: OBJECT_KEYS.totalGoals,
      label: "",
      customRender: (rowData) => (
        <div className={ classes.total_goals }>
          {`${
            (rowData.goals.length > 0)
              ? getGoals(rowData.goals).length
              : 0
          } ${t("goals:title")}`}
        </div>
      ),
    },
  ];

  if (!isGeneral) {
    goalsHeader.push(
      {
        id: OBJECT_KEYS.compliance,
        label: t("tables:headers.compliance"),
        customRender: (rowData) => (
          <StaticProgressBar
            percentage={ rowData.goals.length > 0
              ? Number(calculatedTotalPercentage(getGoals(rowData.goals)))
              : 0 }
          />
        ),
        width: WIDTH.progressBar,
      },
      {
        id: OBJECT_KEYS.principalActions,
        label: t("tables:headers.actions"),
        customRender: () => (
          // TODO: define general options
          // <MenuPopup items={ [{ title: t("common:common.no_options") }] } />
          <div>{""}</div>
        ),
        width: WIDTH.actions,
      },
    );
  }
  return goalsHeader;
};

export const getTotalWeightGoals = (goals) => reduce(
  goals,
  (result, value) => sum([result, getPercent(value.weight, false, ROUND.max)]),
  0,
).toFixed(DEFAULT_MIN_DECIMAL);

export const formatGoalsData = (t, goalsData) => {
  const consolidatedGoals = [];
  const generalGoals = [];

  goalsData.forEach((item) => {
    // Add a default row for employees without goals
    if (item.goals.length === 0) {
      const rowData = {
        [t("goals:download.id_number")]: item.personal_id,
        [t("tables:headers.name")]: item.full_name,
        [t("tables:headers.email")]: item.email,
        [t("tables:headers.manager")]: item.manager_name,
        [t("goals:download.manager_email")]: item.manager_email,
        [t("common:common.country")]: item.country_name,
        [t("common:common.city")]: item.city_name,
        [t("goals:download.division")]: item.division_name,
        [t("goals:download.area")]: item.area_name,
        [t("common:common.position")]: item.position_name,
        [t("goals:download.assigned_goals")]: 0,
        [t("goals:download.total_weight")]: "-",
        [t("goals:download.period")]: "-",
        [t("goals:download.year")]: "-",
        [t("tables:headers.compliance")]: "-",
      };
      consolidatedGoals.push(rowData);

      const individualGoalData = {
        [t("tables:headers.name")]: item.full_name,
        [t("tables:headers.email")]: item.email,
        [t("tables:headers.manager")]: item.manager_name,
        [t("goals:download.manager_email")]: item.manager_email,
        [t("common:common.country")]: item.country_name,
        [t("common:common.city")]: item.city_name,
        [t("goals:download.division")]: item.division_name,
        [t("common:common.area")]: item.area_name,
        [t("common:common.position")]: item.position_name,
        [t("tables:headers.description")]: "",
        [t("tables:headers.start_date")]: "",
        [t("tables:headers.deadline_date")]: "",
        [t("tables:headers.weight")]: "",
        [t("tables:headers.rule")]: "",
        [t("tables:headers.metrics")]: "",
        [t("goals:download.progress")]: "",
        [t("tables:headers.status")]: "-",
        [t("goals:download.period")]: "",
        [t("goals:download.year")]: "",
        [t("tables:headers.compliance")]: "-",
        [t("goals:download.identifier")]: item.email || item.personal_id,
        [t("goals:download.id")]: "",
      };

      generalGoals.push(individualGoalData);
    } else {
      // Object to store goals grouped by year, period, and subperiod
      const goalsByPeriod = {};

      // Group goals by year, period and subperiod
      item.goals.forEach((goal) => {
        const goalYear = getUTCYearFromDate(goal.starting_date);
        const periodKey = goal.subperiod
          ? `subperiod-${goalYear}-${goal.period.slug}-${goal.subperiod.value}`
          : `period-${goalYear}-${goal.period.slug}`;

        // Create a structure to store goals if it doesn't exist
        if (!goalsByPeriod[periodKey]) {
          goalsByPeriod[periodKey] = {
            count: 0,
            totalWeight: 0,
            goals: [],
          };
        }

        // Sum up the goals to the period/subperiod structure
        goalsByPeriod[periodKey].count += 1;
        goalsByPeriod[periodKey].totalWeight += goal.weight;
        goalsByPeriod[periodKey].goals.push(goal);
      });

      // Create rows for each period/subperiod with assigned goals
      Object.keys(goalsByPeriod).forEach((periodKey) => {
        const [periodType, year, periodSlug, subperiodValue] = periodKey.split("-");
        const periodTranslation = t(`periods:${periodSlug}.name`);
        let subperiodTranslation = "";

        if (periodType === "subperiod") {
          subperiodTranslation = t(`periods:${periodSlug}.subperiods.${subperiodValue}`);
        }

        const {
          count, totalWeight, goals,
        } = goalsByPeriod[periodKey];
        const compliance = count > 0 ? calculatedTotalPercentage(goals) : 0;

        const rowData = {
          [t("goals:download.id_number")]: item.personal_id,
          [t("tables:headers.name")]: item.full_name,
          [t("tables:headers.email")]: item.email,
          [t("tables:headers.manager")]: item.manager_name,
          [t("goals:download.manager_email")]: item.manager_email,
          [t("common:common.country")]: item.country_name,
          [t("common:common.city")]: item.city_name,
          [t("goals:download.division")]: item.division_name,
          [t("goals:download.area")]: item.area_name,
          [t("common:common.position")]: item.position_name,
          [t("goals:download.assigned_goals")]: count,
          [t("goals:download.total_weight")]: getPercent(totalWeight, true, ROUND.max),
          [t("goals:download.period")]: subperiodTranslation || periodTranslation,
          [t("goals:download.year")]: year,
          [t("tables:headers.compliance")]: `${compliance}%`,
        };
        consolidatedGoals.push(rowData);
      });

      // Add individual goals to generalGoals
      item.goals.forEach((goal) => {
        let periodLabel = t(`periods:${goal.period.slug}.name`);
        if (goal.subperiod?.id) {
          periodLabel = t(`periods:${goal.period.slug}.subperiods.${goal.subperiod.value.toString()}`);
        }
        const individualGoalData = {
          [t("tables:headers.name")]: item.full_name,
          [t("tables:headers.email")]: item.email,
          [t("tables:headers.manager")]: item.manager_name,
          [t("goals:download.manager_email")]: item.manager_email,
          [t("common:common.country")]: item.country_name,
          [t("common:common.city")]: item.city_name,
          [t("goals:download.division")]: item.division_name,
          [t("common:common.area")]: item.area_name,
          [t("common:common.position")]: item.position_name,
          [t("tables:headers.description")]: goal.description,
          [t("tables:headers.start_date")]: formatDate(goal.starting_date),
          [t("tables:headers.deadline_date")]: formatDate(goal.end_date),
          [t("tables:headers.weight")]: getPercent(goal.weight, true, ROUND.max),
          [t("tables:headers.rule")]: getRuleName(goal.goal_rule_name, t),
          [t("tables:headers.metrics")]: goal.metric,
          [t("goals:download.progress")]: t(`goals:status.${goal.status}`),
          [t("tables:headers.status")]: goal.completed_value === null ? "-" : goal.completed_value,
          [t("goals:download.period")]: periodLabel,
          [t("goals:download.year")]: getUTCYearFromDate(goal.starting_date),
          [t("tables:headers.compliance")]: goal.completion_percentage === null ? "-" : getPercent(goal.completion_percentage, true, ROUND.max),
          [t("goals:download.identifier")]: item.email || item.personal_id,
          [t("goals:download.id")]: goal.id,
        };

        generalGoals.push(individualGoalData);
      });
    }
  });

  const goalsInfo = [
    {
      name: t("goals:download.consolidated_goals"),
      data: consolidatedGoals,
    },
    {
      name: t("goals:download.detail"),
      data: generalGoals,
    },
  ];

  return goalsInfo;
};

export const getFormattedDownloadData = (t, goalsData) => () => formatGoalsData(t, goalsData);

export const getHeader = (t) => [
  {
    id: "name",
    label: t("tables:headers.name"),
  },
  {
    id: "goals",
    label: "",
  },
  {
    id: "realized",
    label: t("tables:headers.realized"),
  },
  {
    id: "actions",
    label: "",
  },
];

export const getRowsDesktop = (data, t, history, user, isMobile, modal) => {
  const rows = data?.map((rowData) => {
    const employee = {
      id: rowData.id,
      full_name: rowData.full_name,
      profile_img_url: rowData.profile_img_url,
      profile_img_url_thumb: rowData.profile_img_url_thumb,
      profile_img_url_thumb_sm: rowData.profile_img_url_thumb_sm,
      is_active: rowData.is_active,
    };
    const goals = rowData.goals?.length > 0 ? getGoals(rowData.goals) : [];
    return [
      {
        content: (
          <ProfileImageDetail collaborator={ employee } isShortVersion hasStatus />
        ),
        collapseData: modal.modalOptions[0](rowData),
      },
      {
        content: `${ goals.length } ${t("goals:title")}`,
      },
      {
        content: (
          <StaticProgressBar
            percentage={ goals.length > 0 ? Number(calculatedTotalPercentage(goals)) : 0 }
            color={ palette.linearProgress.complete }
            isPrimaryColor={ false }
          />),
      },
      {
        content: "",
      },
    ];
  });
  return rows;
};
