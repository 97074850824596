import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import { VARIANT } from "common/constants";
import { getYearlyCompliance } from "../../functions/PeriodCollapse";
import CollapsibleRow from "./components/CollapsibleRow/CollapsibleRow";
import Period from "./components/Period/Period";

const PeriodCollapse = (props) => {
  const { dataByYear, showTableContent } = props;
  const [open, setOpen] = useState({});
  const [orderedData, setOrderedData] = useState([]);

  const toggleOpen = (id) => {
    setOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    if (dataByYear) {
      const sortedData = [...dataByYear].sort((a, b) => b.year - a.year);
      setOrderedData(sortedData);
    }
  }, [dataByYear]);

  return (
    orderedData?.map(({ year, periods }) => (
      <div key={ year }>
        <CollapsibleRow
          id={ year }
          label={ year }
          status={ null }
          goalsCount={ null }
          progress={ Number(getYearlyCompliance(periods)) }
          open={ open }
          toggleOpen={ toggleOpen }
          isYear
        />
        <Collapse in={ open[year] } timeout={ VARIANT.auto } unmountOnExit>
          {periods.map((period) => (
            <Period
              key={ period.id }
              period={ period }
              rowId={ `${year}-${period.id}` }
              showTableContent={ showTableContent }
              open={ open }
              toggleOpen={ toggleOpen }
            />
          ))}
        </Collapse>
      </div>
    ))
  );
};

PeriodCollapse.propTypes = {
  dataByYear: PropTypes.array.isRequired,
  showTableContent: PropTypes.func.isRequired,
};

export default PeriodCollapse;
