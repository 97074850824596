import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledGrid = styled(Grid)`
  align-items: center;
  padding: 15px;

  @media (max-width: 600px) {
    padding: 0 12px;
  }
`;

export const StyledTitle = styled.h4`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;
