import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { VARIANT, ALIGN_ITEMS } from "common/constants";
import { satisfactionRangeData } from "../../functions";
import { StyledSubtitle } from "../../styledComponents";
import { StyledRange } from "./styles";

const LegendByRanking = () => {
  const { t } = useTranslation("engagement");

  return (
    <Grid container data-testid={ "legend-by-ranking-view-component" }>
      <Grid item xs={ 12 } md={ 4 }>
        <Box pb={ 2 }><StyledSubtitle variant={ VARIANT.h4 }>{ t("favorabilityRanking") }</StyledSubtitle></Box>
      </Grid>
      <Grid item xs={ 12 } md={ 8 }>
        <Box display={ ALIGN_ITEMS.flex }>
          {satisfactionRangeData()?.map((item) => (<StyledRange key={ item.title } color={ item.color }>{`${item.minValue} - ${item.maxValue}%`}</StyledRange>))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LegendByRanking;
