import NoDataMessage from "components/NoDataMessage";

export const errorTable = (t) => (
  <NoDataMessage
    message={ `${t("common:common.api_responses.error.title")} \n ${t(
      "common:common.api_responses.error.contact_administrator",
    )}` }
    customStyles={ "noData" }
  />
);

export const noDataTable = () => <NoDataMessage customStyles={ "noData" } />;
