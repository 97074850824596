import { ImageLoader } from "react-progressive-img-loader";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LockIcon from "@mui/icons-material/Lock";
import MenuPopup from "components/MenuPopup";
import TooltipIcon from "components/TooltipIcon";
import { ReactComponent as Envelop } from "assets/images/icons/envelop.svg";
import { ReactComponent as SuccessionIcon } from "assets/images/icons/succession.svg";
import {
  OBJECT_KEYS,
  SIZE,
  WIDTH,
} from "common/constants";
import { formatDate, capitalizeFirstLetter, getNormalizedString } from "common/utils";
import { STATES } from "common/constants/agreements";
import { StyledTitleWithSubtitle } from "components/TablePaginationCollapse/styles";
import {
  StyledBox,
  StyledBoldText,
  StyledChartIcon,
  StyledBoxContainer,
  StyledImageContainer,
} from "../components/InProgress/styles";
import {
  StyledDelete,
  StyledText,
  StyledEmployeeGroup,
  StyledState,
} from "../components/CollapseAgreementsTable/styles";
import { StyledSuccessionLabel } from "../styles";

export const getState = (list, state, name) => list.find((e) => e[name] === state);

export const getCategoryId = (categoryName, categories) => categories?.find((item) => item.name === categoryName)?.id;

const getFinishedCount = (agreements) => agreements?.filter((obj) => obj.state === STATES.finished).length || 0;

export const getPrincipalHeader = (t, setSelectId, employeeId) => {
  const principalHeader = [
    {
      id: OBJECT_KEYS.fullname,
      label: t("tables:headers.name"),
      collapseCell: { isActive: true },
      width: WIDTH.name,
      customRender: (rowData) => {
        const customImage = (rowData.profile_img_url_thumb
          && rowData.profile_img_url && (
          <ImageLoader
            src={ rowData.profile_img_url }
            srcPreview={ rowData.profile_img_url_thumb }
            customImageComponent={ Avatar }
          />
        ))
        || (!rowData.profile_img_url_thumb && (
          <Avatar src={ rowData.profile_img_url } />
        ));
        return (
          <>
            <StyledBox pr={ 2 }>
              {customImage}
            </StyledBox>
            <Box>{rowData.full_name}</Box>
          </>
        );
      },
    },
    {
      id: OBJECT_KEYS.total,
      label: "",
      customRender: (rowData) => (
        <StyledBoldText>
          {`${rowData?.agreements ? rowData?.agreements?.length : 0} ${t("agreements")}`}
        </StyledBoldText>
      ),
    },
    {
      id: "",
      label: "",
      customRender: (rowData) => {
        if (rowData.id !== employeeId) {
          return (<StyledChartIcon onClick={ () => setSelectId(rowData.id) } />);
        }
        return "";
      },
      width: WIDTH.progressBar,
    },
    {
      id: OBJECT_KEYS.result,
      label: t("tables:headers.realized"),
      customRender: (rowData) => (
        <StyledBoldText>
          {`${getFinishedCount(rowData?.agreements)}/${rowData?.agreements ? rowData?.agreements?.length : 0}`}
        </StyledBoldText>
      ),
      width: WIDTH.progressBar,
    },
    {
      id: OBJECT_KEYS.principalActions,
      label: t("tables:headers.actions"),
      customRender: () => (
        <div>{""}</div>
      ),
      width: WIDTH.actions,
    },
  ];

  return principalHeader;
};

const getStateAgreement = (state, statesList, t) => {
  const selectState = statesList.find((item) => item.id === state);
  return selectState ? t(`developmentPlan:states.${selectState.value}`) : "";
};

export const getAgreementsHeader = (
  t,
  statesList,
  editAgreement,
  handleDeleteAgreement,
  onClickNode,
  viewAgreement,
  employeeId,
) => {
  const agreementsHeader = [
    {
      id: OBJECT_KEYS.id,
      label: "",
      width: WIDTH.state,
      customRender: (rowData) => <StyledState state={ rowData.state } />,
    },
    {
      id: OBJECT_KEYS.title,
      label: t("agreement"),
      width: WIDTH.text,
      customRender: (rowData) => (
        <div>
          <StyledText>
            {rowData.hidden
            && (
              <Tooltip title={ t("developmentPlan:hidden_tooltip") } placement={ "top" }>
                <LockIcon />
              </Tooltip>
            )}
            <StyledBoldText>{rowData.title}</StyledBoldText>
          </StyledText>
        </div>
      ),
    },
    {
      id: OBJECT_KEYS.startingDate,
      label: t("tables:headers.start_date"),
      customRender: (rowData) => (
        <StyledText>{formatDate(rowData.starting_date)}</StyledText>
      ),
    },
    {
      id: OBJECT_KEYS.end_date,
      label: t("tables:headers.review-date"),
      customRender: (rowData) => (
        <StyledText>{formatDate(rowData.ending_date)}</StyledText>
      ),
    },
    {
      id: OBJECT_KEYS.category,
      label: t("tables:headers.category"),
      customRender: (rowData) => {
        let successionPlanInfo = null;
        if (rowData?.succession_plan) {
          successionPlanInfo = `${rowData?.succession_plan?.position_name} (${rowData?.succession_plan?.organization_unit_complete_path})`;
        }
        return (
          <>
            {successionPlanInfo !== null
             && (
               <>
                 <StyledSuccessionLabel>
                   <SuccessionIcon />
                   <h4>
                     {t("successions:succession_plan.title")}
                   </h4>
                 </StyledSuccessionLabel>
                 <StyledText>{successionPlanInfo}</StyledText>
               </>
             )}
            {rowData.agreement_category?.name
              ? <StyledText>{rowData.agreement_category.name}</StyledText> : ""}
          </>

        );
      },
    },
    {
      id: OBJECT_KEYS.state,
      label: t("tables:headers.state"),
      customRender: (rowData) => (
        <StyledBoldText>{getStateAgreement(rowData.state, statesList, t)}</StyledBoldText>
      ),
    },
    {
      id: "",
      label: t("labels.peopleInvolved"),
      customRender: (rowData) => {
        if (rowData.involved_employees?.length > 0) {
          return (
            <StyledEmployeeGroup className={ "involvedImage" }>
              {rowData.involved_employees.map((item) => {
                const employeeData = {
                  id: item.id,
                  name: capitalizeFirstLetter(getNormalizedString(item?.full_name)),
                  avatar: item?.profile_img_url,
                  title: item?.position_name ? getNormalizedString(item.position_name) : "",
                  city: item?.city_name,
                  email: item.email,
                };
                return (
                  <IconButton
                    aria-label={ "mentor" }
                    key={ item.id }
                    onClick={ () => onClickNode(employeeData) }
                  >
                    <TooltipIcon
                      title={ item.full_name }
                      element={ (
                        <Avatar
                          src={ item?.profile_img_url }
                        />
                      ) }
                    />
                  </IconButton>
                );
              })}
            </StyledEmployeeGroup>
          );
        }
        return "";
      },
    },
    {
      id: OBJECT_KEYS.principalActions,
      label: "",
      customRender: (rowData) => {
        const deleteItem = {
          title: <StyledDelete>{ t("tables:actions.delete") }</StyledDelete>,
          icon: <StyledDelete><DeleteIcon fontSize={ SIZE.small } /></StyledDelete>,
          onClick: () => {
            handleDeleteAgreement(rowData.id);
          },
        };
        const ACTIONS_MENU = [
          {
            title: t("tables:actions.view_details"),
            icon: <AssignmentOutlinedIcon fontSize={ SIZE.small } />,
            onClick: () => {
              viewAgreement(rowData);
            },
          },
          {
            title: t("tables:actions.update"),
            icon: <CheckCircleOutlineIcon fontSize={ SIZE.small } />,
            onClick: () => {
              editAgreement(rowData);
            },
          },
        ];
        if (rowData.employee.id !== employeeId) {
          ACTIONS_MENU.push(deleteItem);
        }
        return <MenuPopup items={ ACTIONS_MENU } />;
      },
    },
  ];

  return agreementsHeader;
};

export const formatAgreementsData = (t, agreementsData, statesList) => agreementsData.map((item) => ({
  [t("tables:headers.name")]: item.employee.full_name,
  [t("tables:headers.email")]: item.employee?.email,
  [t("tables:headers.title")]: item.title,
  [t("tables:headers.description")]: item?.description,
  [t("tables:headers.start_date")]: formatDate(item.starting_date),
  [t("tables:headers.review-date")]: formatDate(item.ending_date),
  [t("tables:headers.category")]: item.agreement_category?.name,
  [t("tables:headers.state")]: getStateAgreement(item.state, statesList, t),
  [t("labels.peopleInvolved")]: item.involved_employees?.map((e) => e.full_name).join(", "),
}));

export const downloadExcel = (t, agreementsData, statesList) => () => {
  const filename = t("title");
  const data = formatAgreementsData(t, agreementsData, statesList);
  return [data, filename];
};

export const getHeader = (t) => [
  {
    id: "name",
    label: t("tables:headers.name"),
  },
  {
    id: "agreements",
    label: "",
  },
  {
    id: "chart",
    label: "",
  },
  {
    id: "realized",
    label: t("tables:headers.realized"),
  },
  {
    id: "actions",
    label: t("tables:headers.actions"),
  },
];

export const getRowsDesktop = (data, t, history, user, isMobile, modal) => {
  const rows = data?.map((rowData) => [
    {
      content: (
        <StyledBoxContainer>
          <StyledImageContainer>
            {(rowData?.profile_img_url_thumb && rowData?.profile_img_url) ? (
              <ImageLoader
                src={ rowData?.profile_img_url }
                srcPreview={ rowData?.profile_img_url_thumb }
                customImageComponent={ Avatar }
              />
            ) : !rowData?.profile_img_url_thumb && (
              <Avatar src={ rowData?.profile_img_url } />
            )}
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <div className={ "title" }>{rowData.full_name}</div>
            {rowData?.email
            && (
              <div className={ "subtitle" }>
                <Envelop />
                {rowData.email}
              </div>
            )}
          </StyledTitleWithSubtitle>
        </StyledBoxContainer>
      ),
      collapseData: modal.modalOptions[1](rowData),
    },
    {
      content: `${rowData.total_agreements} ${t("agreements")}`,
    },
    {
      content: rowData.id !== modal.modalOptions[2] ? <StyledChartIcon onClick={ () => modal.modalOptions[0](rowData.id) } /> : "",
    },
    {
      content: `${rowData?.total_completed_agreements}/${rowData?.total_agreements}`,
    },
    {
      content: "",
    },
  ]);
  return rows;
};
