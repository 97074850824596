import { jsPDF } from "jspdf";
import {
  OBJECT_KEYS, ORG_UNITS, ENTITY_OPTION,
} from "common/constants";
import { mainDownloadExcel, getPercent } from "common/utils";
import { StyledLink } from "styledComponents/View";
import { CARD_RESULT } from "theme/palette";
import Participation from "assets/images/performance/participation.svg";
import EvaluatedManagers from "assets/images/performance/evaluated_managers.svg";
import { viewLeaderProfile } from "common/functions";

export const getTableTopsKeys = (history, processId) => [
  {
    id: OBJECT_KEYS.employeeFullName,
    label: "",
    customRender: (rowData) => (
      <StyledLink
        onClick={ () => viewLeaderProfile(history, rowData.employee_id, processId) }
      >
        {rowData.employee_full_name}
      </StyledLink>
    ),
  },
  {
    id: OBJECT_KEYS.result,
    label: "",
    customRender: (rowData) => <div>{ getPercent(rowData.score, true) }</div>,
  },
];

export const getHeatPicker = (t) => [
  { value: ORG_UNITS.division, label: t("dashboard.division") },
  { value: ORG_UNITS.area, label: t("dashboard.area") },
  { value: ORG_UNITS.subarea, label: t("dashboard.subarea") },
  { value: ENTITY_OPTION.country, label: t("dashboard.country") },
  { value: ENTITY_OPTION.city, label: t("dashboard.city") },
];

const getFirstTH = (t, selectedOpc) => {
  let title = t("dashboard.division");
  switch (selectedOpc) {
  case "area":
    title = t("dashboard.area");
    break;
  case "subarea":
    title = t("dashboard.subarea");
    break;
  case "country":
    title = t("dashboard.country");
    break;
  case "city":
    title = t("dashboard.city");
    break;
  default:
    break;
  }
  return title;
};

export const getCriticTableHeader = (t, selectedOpc) => [
  getFirstTH(t, selectedOpc),
  t("performance:dashboard.manager"),
];

export const downloadHeatMapExcel = (heatMapValues, t) => {
  const data = [];
  heatMapValues.forEach((heatValue) => {
    data.push({
      [t("collaborators:table.excel.department")]: heatValue?.name,
      [t("collaborators:table.table-head.manager")]: heatValue?.CollaboratorsEvaluation,
    });
  });

  mainDownloadExcel(data, "good-leader-heat-map");
};

export const downloadHeatMapPDF = (filter) => {
  const htmlObject = document.createElement("div");
  htmlObject.innerHTML = document.getElementById("goodLeaderHeatMap").outerHTML;
  const select = htmlObject.getElementsByClassName("MuiGrid-grid-md-3")[0];
  const select1 = htmlObject.getElementsByClassName("MuiGrid-grid-md-3")[1];
  const thead = htmlObject.getElementsByClassName("MuiTableRow-head")[0];

  htmlObject.firstChild.style.padding = "0px";
  htmlObject.firstChild.style.paddingLeft = "30px";
  htmlObject.style.padding = "300px";
  htmlObject.style.pageBreakAfter = "auto";
  htmlObject.style.paddingTop = "0px";
  htmlObject.style.display = "flex";
  htmlObject.style.justifyContent = "center";
  thead.style.display = "none";
  select.style.display = "none";
  select1.style.display = "none";

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "px",
  });
  doc.html(htmlObject, {
    callback(doc) {
      doc.save(`heat-map-filter-by-${filter}`);
    },
  });
};

export const getLeaderData = (data) => data?.map((item) => ({
  name: item.name,
  CollaboratorsEvaluation: item.CollaboratorsEvaluation,
}));

export const allCards = (t, data) => [
  {
    title: t("goodLeaderData.participation"),
    subtitle: t("goodLeaderData.numberSurveysAnswered"),
    participation: {
      total: data?.collaborators_evaluation?.surveys_answered,
      max: data?.collaborators_evaluation?.surveys_sent,
    },
    icon: Participation,
  }, {
    title: t("goodLeaderData.participation"),
    subtitle: t("goodLeaderData.numberManagersEvaluated"),
    participation: {
      total: data?.collaborators_evaluation?.evaluated_leaders,
      max: data?.collaborators_evaluation?.leaders_to_evaluate,
    },
    icon: EvaluatedManagers,
    color: CARD_RESULT.greenCard,
  },
];
