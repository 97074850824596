// Abilities.js
import { createMongoAbility } from "@casl/ability";
import { createHomeAbilities } from "./abilities/HomeAbility";
import { createProfileAbilities } from "./abilities/ProfileAbility";
import { createOrganizationChartAbilities } from "./abilities/OrganizationChartAbility";
import { createAcknowledgmentAbilities } from "./abilities/AcknowledgmentAbility";
import { createGoalAbilities } from "./abilities/GoalAbility";
import { createDevelopmentPlanAbilities } from "./abilities/DevelopmentPlanAbility";

export const createUserAbilities = (user) => {
  // Abilities by module
  const homeAbility = createHomeAbilities(user);
  const profileAbility = createProfileAbilities(user);
  const organizationChartAbility = createOrganizationChartAbilities(user);
  const acknowledgmentModuleAbility = createAcknowledgmentAbilities(user);
  const goalModuleAbility = createGoalAbilities(user);
  const developmentPlanAbility = createDevelopmentPlanAbilities(user);

  const abilities = {
    // Create a new instance of MongoAbility from all ability'rules'
    home: createMongoAbility(homeAbility),
    profile: createMongoAbility(profileAbility),
    organizationChart: createMongoAbility(organizationChartAbility),
    acknowledgments: createMongoAbility(acknowledgmentModuleAbility),
    goals: createMongoAbility(goalModuleAbility),
    developmentPlan: createMongoAbility(developmentPlanAbility),
  };

  return abilities;
};
