import PropTypes from "prop-types";
import { VARIANT } from "common/constants";
import { isNull } from "common/helpers";
import NoInfoFaceBlack from "assets/images/general/noInfoFaceBlack.svg";
import {
  StyledCard,
  StyledTitle,
  StyledResult,
  StyledImage,
  StyledDetail,
} from "./styles";

const CardIconResult = (props) => {
  const {
    title,
    color,
    result,
    backgroundColor,
    icon,
    sizeImage,
    sizeResult,
    sizeTitle,
  } = props;

  return (
    <StyledCard background={ backgroundColor }>
      <StyledTitle
        variant={ VARIANT.h5 }
        color={ color }
        size={ sizeTitle }
      >
        { title }
      </StyledTitle>
      <StyledDetail>
        <StyledImage
          size={ sizeImage }
          src={ icon || NoInfoFaceBlack }
          alt={ title }
        />
        {!isNull(result) && (
          <StyledResult
            color={ color }
            size={ sizeResult }
          >
            { result }
          </StyledResult>
        )}
      </StyledDetail>
    </StyledCard>
  );
};

CardIconResult.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  result: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  icon: PropTypes.object,
  sizeImage: PropTypes.number,
  sizeResult: PropTypes.number,
  sizeTitle: PropTypes.number,
};

CardIconResult.defaultProps = {
  backgroundColor: null,
  icon: null,
  sizeImage: 36,
  sizeResult: 30,
  sizeTitle: 16,
};

export default CardIconResult;
