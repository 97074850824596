import { DEFAULT_MIN_DECIMAL } from "common/constants";
import { calculatedTotalPercentage } from "./index";

/**
 * Get the yearly compliance with average of all periods
 * @param {Array} periods
 * @returns {Number} average of all periods compliance
 */
export const getYearlyCompliance = (periods) => {
  let totalCompletion = 0;
  let periodCount = 0;

  periods?.forEach((period) => {
    if (period.goals && period.goals.length > 0) {
      totalCompletion += parseFloat(calculatedTotalPercentage(period.goals));
      periodCount += 1;
    }

    // if there are children, get the compliance of all children
    if (period.children && period.children.length > 0) {
      period.children.forEach((child) => {
        if (child.goals && child.goals.length > 0) {
          totalCompletion += parseFloat(calculatedTotalPercentage(child.goals));
          periodCount += 1;
        }
      });
    }
  });

  // if there are no periods, return 0
  if (periodCount === 0) return 0;

  const average = totalCompletion / periodCount;
  return average.toFixed(DEFAULT_MIN_DECIMAL);
};
