import { TYPES_RESULTS } from "common/constants";

export const dataAccordingUser = (
  isCollaboratorProfile,
  user,
  collaboratorReducer,
) => (!isCollaboratorProfile
  ? {
    userId: user && user.employee && user.employee.id,
    companyId: user && user.company && user.company.id,
  }
  : {
    userId: collaboratorReducer && collaboratorReducer.id,
    companyId:
          collaboratorReducer && collaboratorReducer.person.employee.company_id,
  });

export const skillLevels = [
  {
    key: TYPES_RESULTS.large,
    name: TYPES_RESULTS.large,
  },
  {
    key: TYPES_RESULTS.balanced,
    name: TYPES_RESULTS.balanced,
  },
  {
    key: TYPES_RESULTS.deep,
    name: TYPES_RESULTS.deep,
  },
  {
    key: TYPES_RESULTS.large,
    name: TYPES_RESULTS.en.hight,
  },
  {
    key: TYPES_RESULTS.balanced,
    name: TYPES_RESULTS.en.moderate,
  },
  {
    key: TYPES_RESULTS.deep,
    name: TYPES_RESULTS.en.limited,
  },
];
export const skillTypeKeys = [
  {
    id: TYPES_RESULTS.skillKeys.mental,
    value: TYPES_RESULTS.skills.mental,
  },
  {
    id: TYPES_RESULTS.skillKeys.interpersonal,
    value: TYPES_RESULTS.skills.interpersonal,
  },
  {
    id: TYPES_RESULTS.skillKeys.change,
    value: TYPES_RESULTS.skills.change,
  },
  {
    id: TYPES_RESULTS.skillKeys.results,
    value: TYPES_RESULTS.skills.results,
  },
  {
    id: TYPES_RESULTS.skillKeys.awareness,
    value: TYPES_RESULTS.skills.awareness,
  }];

export const skillType = [
  TYPES_RESULTS.skills.mental,
  TYPES_RESULTS.skills.interpersonal,
  TYPES_RESULTS.skills.change,
  TYPES_RESULTS.skills.results,
  TYPES_RESULTS.skills.awareness,
];
