import { useState } from "react";
import { searchData } from "common/utils";

export const useDataGrid = (items) => {
  const [itemsToShow, setItemsToShow] = useState(items);
  const [activeItem, setActiveItem] = useState(null);

  // Handles the active item by toggling the active item.
  const toggleActiveItem = (id) => {
    setActiveItem(activeItem === id ? null : id);
  };

  // Handles the search functionality by filtering the items based on the search text.
  const handleSearch = (searchText) => {
    const newData = searchData(items, searchText);
    setItemsToShow(newData);
  };

  return {
    itemsToShow,
    activeItem,
    toggleActiveItem,
    handleSearch,
  };
};
