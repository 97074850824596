export const SATISFACTION_RANGE = {
  totallySatisfied: {
    min: 81,
    max: 101,
  },
  satisfied: {
    min: 61,
    max: 81,
  },
  neutral: {
    min: 41,
    max: 61,
  },
  dissatisfied: {
    min: 21,
    max: 41,
  },
  totallyDissatisfied: {
    min: 0,
    max: 21,
  },
};

export const ENGAGEMENT_SEARCH = {
  name: "name",
  nps: "nps",
  satisfied: "satisfied",
  neutral: "neutral",
  unsatisfied: "unsatisfied",
  satisfaction: "satisfaction",
  insatisfaction: "insatisfaction",
  neutrality: "neutrality",
};

export const MIN_FORMS_FILLED = {
  withFollow: 1,
  withoutFollow: 3,
};
