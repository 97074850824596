// Note: in order to optimize certain thing, this file will be the main file for those "paths" who will be used around the entired app
// Lets make this file small!!
const paths = {
  process: "process=",
  collaborator: "collaborator=",
  goodLeader: "goodLeader=",
  invalidToken: "invalid-token=",
  expiredToken: "expired-token=",
};

export default paths;
