import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import MenuPopup from "components/MenuPopup";
import { getItemFromLocalStorage } from "common/utils";
import {
  SIZE, LOCAL_STORAGE_NAMES, MIN_VALUE,
} from "common/constants";
import {
  handleRowDelete, handleRowEdit, pushValidOptions,
} from "../index";

export const getCountry = (selected) => {
  const countries = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries);
  return countries.find((country) => country.id === selected);
};

export const getCityActions = (t, thisModule, functions, classes) => (rowData) => {
  const DELETE_ACTION_MENU = {
    title: (
      <div className={ classes.delete }>{t("table.actions.delete")}</div>
    ),
    icon: (
      <HighlightOffIcon fontSize={ SIZE.small } className={ classes.delete } />
    ),
    onClick: () => {
      if (rowData.employees_count > MIN_VALUE) {
        functions.handleMoveModal(thisModule, rowData, t);
      } else {
        handleRowDelete(functions, t, thisModule, rowData);
      }

    },
  };

  const ACTIONS_MENU = [
    {
      title: t("table.actions.edit"),
      icon: <EditIcon fontSize={ SIZE.small } />,
      onClick: () => {
        handleRowEdit(functions, thisModule, rowData);
      },
    },
  ];

  pushValidOptions(
    rowData,
    ACTIONS_MENU,
    DELETE_ACTION_MENU,
    thisModule,
    t,
    functions,
  );

  return <MenuPopup items={ ACTIONS_MENU } />;
};
