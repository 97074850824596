import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_PROCESS_AGREEMENT,
  PROCESS_AGREEMENT,
  PROCESS_AGREEMENT_LOADING,
  PROCESS_AGREEMENT_ERROR,
  RESET_GENERAL_STATE,
  GET_GENERAL_LIST,
  GET_GENERAL_LIST_LOADING,
  GET_GENERAL_LIST_ERROR,
} from "../../actionTypes/agreement";

const INITIAL_STATE = {
  list: null,
  listTotal: 0,
  isLoadingList: false,
  errorList: "",

  isLoadingProcessAgreement: false,
  successProcessAgreement: null,
  errorProcessAgreement: null,

  generalList: null,
  isLoadingGeneralList: false,
  errorGeneralList: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      errorList: null,
      list: null,
      listTotal: 0,
    };
  case RESET_PROCESS_AGREEMENT:
    return {
      ...state,
      isLoadingProcessAgreement: false,
      successProcessAgreement: null,
      errorProcessAgreement: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      listTotal: action.total,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      isLoadingList: true,
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case PROCESS_AGREEMENT:
    return {
      ...state,
      isLoadingProcessAgreement: false,
      errorProcessAgreement: null,
      successProcessAgreement: action.payload,
    };
  case PROCESS_AGREEMENT_LOADING:
    return {
      ...state,
      isLoadingProcessAgreement: true,
      errorProcessAgreement: null,
      successProcessAgreement: null,
    };
  case PROCESS_AGREEMENT_ERROR:
    return {
      ...state,
      errorProcessAgreement: action.payload,
      isLoadingProcessAgreement: false,
    };
  case RESET_GENERAL_STATE:
    return {
      ...state,
      isLoadingGeneralList: false,
      errorGeneralList: null,
      generalList: null,
    };
  case GET_GENERAL_LIST:
    return {
      ...state,
      generalList: action.payload,
      isLoadingGeneralList: false,
      errorGeneralList: "",
    };
  case GET_GENERAL_LIST_LOADING:
    return {
      ...state,
      isLoadingGeneralList: true,
    };
  case GET_GENERAL_LIST_ERROR:
    return {
      ...state,
      isLoadingGeneralList: false,
      errorGeneralList: action.payload,
    };
  default:
    return state;
  }
};
