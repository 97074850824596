import configAxios from "../configAxios";
import errorHandler from "../errorHandler";

import {
  RESET_STATE_SCALE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
} from "../actionTypes/resultScales";

const URL = {
  main: "/result_scales",
  restore: "restore",
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_SCALE,
  });
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getOne = (id) => async (dispatch) => {
  dispatch({ type: GET_ONE_LOADING });
  try {
    const response = await configAxios.get(`${URL.main}/${id}`);
    dispatch({
      type: GET_ONE,
      payload: response.data.result_scales,
    });
    return response.data.result_scales;
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_ERROR);
  }
};

export const getList = () => async (dispatch, getState) => {
  const { list } = getState().resultScalesReducer;

  if (!list) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main);
      dispatch({
        type: GET_LIST,
        payload: response.data.result_scales,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

export const updateSurveyResult = (surveyResultId, data) => async (dispatch) => {
  try {
    const response = await configAxios.put(`${URL.main}/${surveyResultId}`, data);
    return response.data.result_scales;
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_ERROR);
  }
};

export const restoreSurveyResult = (surveyResultId) => async (dispatch) => {
  try {
    const response = await configAxios.post(`${URL.main}/${surveyResultId}/${URL.restore}`);
    return response.data.result_scales;
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_ERROR);
  }
};
