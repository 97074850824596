import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputForm from "components/InputForm";
import ManagerSelect from "components/commons/ManagerSelect";
import AlertDialog from "components/AlertDialog";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import {
  DATE_PARTS,
  ENTITY_OPTION,
  INPUT_RULES,
  INPUT_TYPE,
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
} from "common/constants";
import { getErrorMessage } from "common/formValidators";
import { isEmpty, isEqual } from "common/helpers";
import {
  getAreas, getDivisions, getItemFromLocalStorage, getCitiesByCountry,
} from "common/utils";
import useComplexState from "hooks/utils/useComplexState";
import { create as createPosition } from "redux/actions/position/positionActions";
import { getList as getHierarchyLevelList, create as createLevel } from "redux/actions/common/hierarchyLevelActions";
import { create as createCity } from "redux/actions/common/cityActions";
import { getOrganizationUnitName } from "views_refactor/Profile/functions/profile";
import { useCities } from "hooks/useCities";

const EmployeeForm = (props) => {
  const { collaborator, hookEmployeeForm } = props;

  const { t } = useTranslation(["profile"]);
  const dispatch = useDispatch();
  const {
    control, setValue, getValues, errors,
  } = hookEmployeeForm;

  const {
    list: hierarchyLevelsList,
    isLoadingList: isLoadingHierarchyLevels,
  } = useSelector(({ hierarchyLevelReducer }) => hierarchyLevelReducer);

  // Cities
  const {
    cities: allCities, queryClient,
  } = useCities();

  const employee = OBJECT_KEYS.collaborator;
  const organizationUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [levels, setLevels] = useState([]);
  const [employeeInfoStates, setEmployeeInfoStates] = useComplexState({
    inputTextValue: "",
    newCreation: null,
    isOpenDialog: false,
    optionsList: {
      countries: getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries),
      positions: getItemFromLocalStorage(LOCAL_STORAGE_NAMES.positions),
      divisions: getDivisions(organizationUnits),
    },
  });

  const {
    countries, positions, divisions,
  } = employeeInfoStates.optionsList;
  const {
    inputTextValue, newCreation, isOpenDialog,
  } = employeeInfoStates;

  useEffect(() => {
    if (collaborator && collaborator?.job_position) {
      const orgUnits = getOrganizationUnitName(collaborator?.job_position?.organization_units);
      collaborator.job_position.division = orgUnits.division?.name;
      collaborator.job_position.area = orgUnits.area?.name;
      if (!isEmpty(collaborator.job_position.country) && allCities) {
        setCities(getCitiesByCountry(collaborator?.job_position?.country?.id, allCities));
      }
      if (!isEmpty(collaborator.job_position.division)) {
        setAreas(getAreas(organizationUnits, orgUnits.division?.id));
      }
    }
    // eslint-disable-next-line
  }, [collaborator]);

  useEffect(() => {
    dispatch(getHierarchyLevelList());
  }, [dispatch]);

  useEffect(() => {
    if (hierarchyLevelsList && !isEmpty(hierarchyLevelsList)) {
      setLevels(hierarchyLevelsList);
    }
  }, [hierarchyLevelsList]);

  // OnAutoComplete fields
  const onAutocompleteChange = () => (prop, newValue) => {
    if (isEqual(prop, ENTITY_OPTION.country)) {
      setValue(`${employee}.job_position_attributes.city_id`, null);
      if (allCities) {
        setCities(getCitiesByCountry(newValue?.id, allCities));
      }
    }
  };

  const onAutocompleteChangeOrgUnits = (nameOfAttr, orgUnit, setValueForm) => (
    prop,
    newValue,
  ) => {
    if (isEqual(orgUnit, OBJECT_KEYS.division)) {
      setValueForm(`${employee}.job_position_attributes.area_name`, null);
      setAreas(getAreas(organizationUnits, newValue?.id));
    }
  };

  // Handles
  const handleDialog = (option) => {
    setEmployeeInfoStates({ isOpenDialog: option });
  };

  const handleDialogSubmit = (text, prop) => {
    setEmployeeInfoStates({
      inputTextValue: text,
      newCreation: prop,
    });
    handleDialog(true);
  };

  const creations = {
    city: {
      list: cities,
      data: {
        country_id: getValues(`${employee}.job_position_attributes.country_id`)?.id,
        name: employeeInfoStates.inputTextValue,
      },
      create: createCity,
      name: `${employee}.job_position_attributes.city_id`,
      queryName: "cities",
    },
    position: {
      list: employeeInfoStates.optionsList.positions,
      localName: LOCAL_STORAGE_NAMES.positions,
      data: {
        name: employeeInfoStates.inputTextValue,
      },
      create: createPosition,
      name: `${employee}.job_position_attributes.position_id`,
    },
    level: {
      list: levels,
      data: {
        name: employeeInfoStates.inputTextValue,
      },
      create: createLevel,
      name: `${employee}.job_position_attributes.hierarchy_level_id`,
    },
  };

  const handleNew = async () => {
    const newItem = creations[employeeInfoStates.newCreation];
    const createdElement = await dispatch(newItem.create(newItem.data));

    if (!isEmpty(createdElement)) {
      //  error handle
      if (createdElement.error) {
        toast(MESSAGE_TYPES.error, createdElement, t);
      } else {
        newItem.list.push(createdElement);
        setValue(newItem.name, createdElement);

        if (newItem?.localName) {
          const tempLocalstorage = getItemFromLocalStorage(newItem.localName);
          tempLocalstorage.push(createdElement);
          localStorage.setItem(newItem.localName, JSON.stringify(tempLocalstorage));
        }
        if (newItem?.queryName) {
          queryClient.setQueryData(newItem.queryName, (old) => [...old, createdElement]);
        }
      }
      handleDialog(false);
    }
  };

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          type={ INPUT_TYPE.date }
          label={ t("profile:form.movementDate") }
          control={ control }
          name={ "collaborator.date" }
          openTo={ DATE_PARTS.year }
          views={ [DATE_PARTS.date, DATE_PARTS.month, DATE_PARTS.year] }
          minDate={ new Date(collaborator?.job_position?.termination_date) }
          required
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <ManagerSelect
          name={ "collaborator.job_position_attributes.manager_id" }
          control={ control }
          employee={ collaborator }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          id={ OBJECT_KEYS.position }
          options={ positions }
          type={ INPUT_TYPE.autocomplete }
          label={ t("common:common.position") }
          control={ control }
          name={ "collaborator.job_position_attributes.position_id" } // TODO: do it for candidate as well
          nameOfAttr={ OBJECT_KEYS.name }
          fieldValue={ OBJECT_KEYS.id }
          onChangeValue={ onAutocompleteChange() }
          addNew={ {
            handleNew: handleDialogSubmit,
            text: t("common:common.add_new_position"),
            prop: OBJECT_KEYS.position,
          } }
          rules={ INPUT_RULES.required }
          error={ getErrorMessage(errors, "collaborator.job_position_attributes.position_id", "position") }
          isRequired
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          id={ "division" }
          options={ divisions }
          type={ INPUT_TYPE.autocomplete }
          label={ t("common:common.division") }
          control={ control }
          name={ "collaborator.job_position_attributes.division_name" }
          onChangeValue={ onAutocompleteChangeOrgUnits(
            OBJECT_KEYS.name,
            OBJECT_KEYS.division,
            setValue,
          ) }
          nameOfAttr={ OBJECT_KEYS.name }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          id={ "area" }
          options={ areas }
          type={ INPUT_TYPE.autocomplete }
          label={ t("common:common.area") }
          control={ control }
          onChangeValue={ onAutocompleteChangeOrgUnits(
            OBJECT_KEYS.name,
            OBJECT_KEYS.area,
            setValue,
          ) }
          name={ "collaborator.job_position_attributes.area_name" }
          nameOfAttr={ OBJECT_KEYS.name }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          id={ "level" }
          options={ levels }
          type={ INPUT_TYPE.autocomplete }
          label={ t("common:common.hierarchyLevel") }
          control={ control }
          onChangeValue={ onAutocompleteChange() }
          name={ "collaborator.job_position_attributes.hierarchy_level_id" }
          nameOfAttr={ OBJECT_KEYS.name }
          addNew={ {
            handleNew: handleDialogSubmit,
            text: t("common:common.add_new_level"),
            prop: OBJECT_KEYS.level,
          } }
          loading={ isLoadingHierarchyLevels }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          id={ "country" }
          options={ countries }
          type={ INPUT_TYPE.autocomplete }
          label={ t("common:common.country") }
          control={ control }
          name={ "collaborator.job_position_attributes.country_id" }
          onChangeValue={ onAutocompleteChange() }
          nameOfAttr={ OBJECT_KEYS.name }
          rules={ INPUT_RULES.required }
          error={ getErrorMessage(errors, "collaborator.job_position_attributes.country_id", "country") }
          isRequired
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          id={ OBJECT_KEYS.city }
          options={ cities }
          type={ INPUT_TYPE.autocomplete }
          label={ t("common:common.city") }
          control={ control }
          onChangeValue={ onAutocompleteChange() }
          name={ "collaborator.job_position_attributes.city_id" }
          nameOfAttr={ OBJECT_KEYS.name }
          addNew={ {
            handleNew: handleDialogSubmit,
            text: t("common:common.add_new_city"),
            prop: OBJECT_KEYS.city,
          } }
        />
      </Grid>
      <AlertDialog
        isOpen={ isOpenDialog }
        onClose={ () => handleDialog(false) }
        title={ `${t(`common:common.add_new_${newCreation}`)}: ${inputTextValue}` }
        message={ t("common:common.modal_messages.sure_text") }
        onSubmit={ handleNew }
      />
    </Grid>
  );
};

EmployeeForm.propTypes = {
  collaborator: PropTypes.object.isRequired,
  hookEmployeeForm: PropTypes.object.isRequired,
};

export default EmployeeForm;
