import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Modal from "components/Modal";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import SkeletonLoader from "components/SkeletonLoader";
import Alert from "components/Alert";
import { SKELETONS_NUMBER } from "common/constants";
import { replacePath, getAverageResult, getPercent } from "common/utils";
import PerformanceChart from "components/PerformanceChart";
import TalentSpiderChart from "components/TalentSpiderChart";
import { getFormattedIndividualObject, getFilterNames } from "components/TalentSpiderChart/functions";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { getRecommendations, INDEX_TABS, TABS } from "views_refactor/Profile/functions/lossRisk";
import Recommendations from "./components/Recommendations";
import AttritionProbabilityCard from "../AttritionProbabilityCard";
import localRoutes from "../../../../functions/routes";
import {
  StyledChartContainer, StyledContainer, StyledGridAlert, StyledTabContent, StyledTitle,
} from "./styles";

const AttritionModal = (props) => {
  const {
    t,
    history,
    lossRiskStates,
    attritionStates,
    attritionEvolutionStates,
    attritionProcessStates,
    handleValueChangedTabLossRisk,
    getAttritionEvaluations,
    isValidForTheRecommendation,
  } = props;

  const { isModalOpen, tabHandler } = lossRiskStates;
  const { data, isLoading, collaborator } = attritionStates;
  const { performanceEvolution, loadingPerformanceEvolution } = attritionEvolutionStates;
  const { isLoadingPerformanceProcessesList } = attritionProcessStates;

  const spiderChart = (
    <TalentSpiderChart
      dataFormatted={ getFormattedIndividualObject(collaborator?.attrition_axis_values) }
      filterNames={ getFilterNames(data?.averages) }
      isPersonal
    />
  );

  const recommendations = (
    <Recommendations
      t={ t }
      data={ getRecommendations(data?.responseList, t) }
      isLoading={ isLoading }
    />
  );

  const recommendationByExceptionalCase = isValidForTheRecommendation() ? <Alert text={ t("talentDrain:chart.recommendation", { attrition: getPercent(collaborator.attrition_probability, true) }) } /> : "";

  const initialRender = isLoading
    ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
    : (
      <Grid container>
        <Grid item xs={ 12 } sm={ 7 } md={ 8 }>
          <Grid container>
            <Grid item xs={ 12 } md={ 6 }>
              <StyledTitle>{t("talentDrain:satisfactionMap")}</StyledTitle>
            </Grid>
            <Hidden smDown>
              <Grid item xs={ 12 }>
                {spiderChart}
              </Grid>
              <StyledGridAlert item xs={ 12 }>
                {recommendationByExceptionalCase}
              </StyledGridAlert>
            </Hidden>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={ 12 } sm={ 5 } md={ 4 }>
            <StyledContainer padding={ "16px" } height={ "580px" }>
              <AttritionProbabilityCard t={ t } attritionStates={ attritionStates } hasBorder />
              {recommendations}
            </StyledContainer>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={ 12 }>
            <Tab
              tabs={ TABS(t) }
              onChange={ handleValueChangedTabLossRisk }
              tabValue={ tabHandler }
            />
            <TabPanel
              value={ tabHandler }
              index={ INDEX_TABS.map }
            >
              <StyledTabContent>
                {spiderChart}
                {recommendationByExceptionalCase}
              </StyledTabContent>
            </TabPanel>
            <TabPanel
              value={ tabHandler }
              index={ INDEX_TABS.recommendations }
            >
              <StyledTabContent>
                <AttritionProbabilityCard t={ t } attritionStates={ attritionStates } hasBorder />
                {recommendations}
              </StyledTabContent>
            </TabPanel>
          </Grid>
        </Hidden>
        <Grid item xs={ 12 } md={ 10 }>
          <StyledTitle>{t("performance:evolution")}</StyledTitle>
          <StyledChartContainer>
            <PerformanceChart
              title={ t("performance:dashboard.graphic_title") }
              labels={ getAttritionEvaluations() }
              data={ performanceEvolution ? getAverageResult(performanceEvolution) : [] }
              isLoading={ isLoadingPerformanceProcessesList || loadingPerformanceEvolution }
            />
          </StyledChartContainer>
        </Grid>
      </Grid>
    );

  return (
    <Modal
      isOpen={ isModalOpen.lossRisk }
      onClose={ () => replacePath(history, localRoutes.root) }
      title={ t("common:common.lossRisk") }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "900px" }>
        { initialRender }
      </StyledScrollBar>
    </Modal>
  );
};

AttritionModal.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  lossRiskStates: PropTypes.object.isRequired,
  attritionStates: PropTypes.object.isRequired,
  attritionEvolutionStates: PropTypes.object.isRequired,
  attritionProcessStates: PropTypes.object.isRequired,
  handleValueChangedTabLossRisk: PropTypes.func.isRequired,
  getAttritionEvaluations: PropTypes.func.isRequired,
  isValidForTheRecommendation: PropTypes.func.isRequired,
};

export default AttritionModal;
