import styled from "styled-components";
import Button from "components/Button";
import palette from "theme/palette";

export const Container = styled.div`
  width: 100%;
`;

export const Results = styled.div`
  display: flex;
`;

export const PerformanceData = styled.div`
  flex-grow: 1;
`;

export const ButtonStyled = styled(Button)`
  color: ${palette.text.link};
`;

export const TextButton = styled.div`
  color: ${palette.text.link};
`;
