import Box from "@mui/material/Box";
import styled from "styled-components";
import palette from "theme/palette";
import { ALIGN_ITEMS } from "common/constants";

export const StyledContainer = styled.div`
  margin-bottom: 25px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: 600px) {
    margin-bottom: 12px;
  }
`;

export const StyledTitle = styled.p`
  margin-top: 15px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: ${(props) => props.weight};
  font-size: 38px;
  line-height: 28px;
  color: ${palette.black};
  @media (max-width: 600px) {
    font-size: 16px;
  }
  .MuiTypography-body1 {
    font-size: 28px;
    color: ${palette.black};
    line-height: 35px;
    font-weight: 700;
  }
  .MuiLink-underlineAlways {
    font-weight: 400;
    text-decoration: none;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled.div`
  padding: 8px 8px 4px 10px;
  border-radius: 100%;
  &:hover{
    cursor: pointer;
    background: ${palette.text.inactive};
    transition: 0.3s ease-in-out;
  }
`;

export const StyledNoData = styled.div`
  color: ${palette.text.gray};
`;

export const StyledFile = styled.div`
  width: 130px;
  word-wrap: break-word;
`;

export const StyledBox = styled(Box)`
  width: ${(props) => (props.width ? props.width : "100%")};
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledCardTitle = styled.div`
  font-size: 20px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyledFixedContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  font-size: 14px;
  text-align: ${(props) => (props.isCenter ? ALIGN_ITEMS.center : ALIGN_ITEMS.right)};
  margin-top: ${(props) => (props.isCenter ? "8px" : "0")};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledResultContainer = styled.div`
  margin-top: 20px;
`;

export const StyledComment = styled.p`
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  margin-bottom: 10px;
  background: ${palette.background.default};
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  span {
    font-weight: 700;
    color: ${palette.primaryApp};
  }
`;
