import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { COLORS_NAME, VARIANT } from "common/constants";
import { isEqual } from "common/helpers";
import {
  StyledSubtitle,
  SwitchStyled,
  StyledFormControlLabel,
  StyledGrid,
} from "./styles";

const ColumnSwitch = (props) => {
  const {
    columnList, setColumnList, setUpdateColumns,
  } = props;
  const { t } = useTranslation("bulkUpload");

  const handleChange = (item) => {
    const updateColumns = columnList.map((obj) => (isEqual(obj.key, item.key)
      ? Object.assign(obj, { active: !obj.active }) : obj));
    setColumnList(updateColumns);
    setUpdateColumns(true);
  };
  const switchList = columnList?.map((item, index) => (
    <Grid item xs={ 3 } key={ `column-${index}` }>
      <StyledFormControlLabel
        control={ (
          <SwitchStyled
            color={ COLORS_NAME.primary }
            checked={ item.active }
            onChange={ () => handleChange(item) }
          />
        ) }
        label={ item.name }
      />
    </Grid>
  ));

  return (
    <>
      <Grid item xs={ 12 }>
        <Typography variant={ VARIANT.h4 }>
          { t("customizeColumns.title") }
        </Typography>
      </Grid>
      <Grid item xs={ 5 }>
        <StyledSubtitle variant={ VARIANT.bodyTwo }>
          { t("customizeColumns.subtitle") }
        </StyledSubtitle>
      </Grid>
      <Grid item xs={ 12 }>
        <StyledGrid container>
          { switchList }
        </StyledGrid>
      </Grid>
    </>
  );
};

ColumnSwitch.propTypes = {
  columnList: PropTypes.array.isRequired,
  setColumnList: PropTypes.func.isRequired,
  setUpdateColumns: PropTypes.func.isRequired,
};

export default ColumnSwitch;
