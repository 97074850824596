import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import isString from "lodash/isString";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import AutoCompleteCreable from "components/AutocompleteCreable";
import { BUTTON_STYLE_TYPES, OBJECT_KEYS } from "common/constants";
import { UNITS, CITIES } from "../../../functions";
import { useStyles } from "../../../styles";

const MoveUnits = (props) => {
  const {
    module, data, onSubmit, options,
  } = props;
  const [unitSelected, setUnitSelected] = useState();
  const { t } = useTranslation("administrator");
  const classes = useStyles();

  const handleSubmit = () => {
    let updateData;
    if (module === UNITS) {
      updateData = {
        organization_unit: {
          id: data.id,
          parent_id: unitSelected.id,
          name: data.name,
        },
      };
      onSubmit(module, updateData, OBJECT_KEYS.organizationUnit);
    } else if (module === CITIES) {
      onSubmit(module, data.id, unitSelected.id);
    }
  };

  const onAutocompleteChange = (nameOfAttr) => (prop, event, newValue) => {
    setUnitSelected({
      ...unitSelected,
      [prop]:
        isNull(newValue) || isString(newValue)
          ? newValue
          : newValue[nameOfAttr],
    });
  };

  return (
    <div data-testid={ "general-administrator-units-move" }>
      <Grid item xs={ 12 }>
        <Typography className={ classes.tableTitle }>
          {`${t(`modules.${module}.move`)} ${data?.name} ${t("common:common.to")}:`}
        </Typography>
      </Grid>
      <Grid item xs={ 12 }>
        <AutoCompleteCreable
          id={ "id" }
          options={ options }
          label={ t(`modules.${module}.title`) }
          onChange={ onAutocompleteChange("id") }
          name={ "element" }
          value={ unitSelected }
          nameOfAttr={ "name" }
          customStyles={ classes.autocomplete }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <div className={ classes.actionButton }>
          <Button
            variant={ BUTTON_STYLE_TYPES.CONTAINED }
            autoFocus
            type={ BUTTON_STYLE_TYPES.SUBMIT }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            onClick={ handleSubmit }
            isDisabled={ isEmpty(unitSelected) }
          >
            {t("table.actions.move")}
          </Button>
        </div>
      </Grid>
    </div>
  );
};

MoveUnits.propTypes = {
  module: PropTypes.string,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  options: PropTypes.array,
};

export default MoveUnits;
