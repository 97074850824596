import PropTypes from "prop-types";
import getInitials from "helpers/getInitials";
import {
  StyledAvatarCell, StyledBadge, StyledAvatar, StyledLabels, StyledBold, StyledAvatarContainer,
} from "./styles";

const CustomAvatar = (props) => {
  const {
    isVisibleBadge,
    avatarImg,
    name,
    position,
  } = props;

  return (
    <StyledAvatarCell data-testid={ "custom-avatar-view-component" } >
      <StyledAvatarContainer>
        <StyledAvatar
          src={ avatarImg }
          name={ name }
        >
          { avatarImg ? "" : getInitials(name)}
        </StyledAvatar>
        <StyledBadge invisible={ isVisibleBadge } />
      </StyledAvatarContainer>
      <StyledLabels>
        <StyledBold>{name}</StyledBold>
        <p>{position}</p>
      </StyledLabels>
    </StyledAvatarCell>
  );
};

CustomAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  isVisibleBadge: PropTypes.bool.isRequired,
  avatarImg: PropTypes.string,
  position: PropTypes.string,
};

CustomAvatar.defaultProps = {
  avatarImg: "",
  position: "",
};

export default CustomAvatar;
