import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import styled from "styled-components";
import palette from "theme/palette";

const useStyles = makeStyles((theme) => ({
  svg: {
    viewBox: "0",
  },
  generalSvg: {
    outline: "none",
    padding: 2,
    marginTop: 2,
  },
  rectBox: {
    stroke: theme.palette.white,
    strokeWidth: 4,
    fill: palette.background.grayBox,
  },
  rectBoxActive: {
    fill: palette.primaryApp,
  },
  nineBoxContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const StyledQuadrantName = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  color: ${palette.text.grayDark};
  p {
    color: ${palette.text.secondaryParagraph};
    font-size: 11px;
    font-weight: 400;
  }
`;

export default useStyles;
