import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import palette, { TABLE } from "theme/palette";

export const CustomAutocomplete = styled(Autocomplete)`
  margin-top: 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    margin-right: 8px;
    color: ${palette.text.link};
  }
  .MuiIconButton-label {
    color: ${palette.text.link};
  }
`;

export const useStyles = makeStyles((theme) => ({
  addNew: {
    display: "block",
    background: TABLE.ODD,
    color: theme.palette.black,
    fontSize: 12,
    "&:hover": {
      cursor: "pointer",
    },
    padding: "10px",
  },
  empty: {
    background: TABLE.ODD,
    color: theme.palette.black,
    fontSize: 12,
    padding: "10px",
  },
}));

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: 24px;
    background-color: ${(props) => (palette.background[props.active ? "iconTags" : "inputTags"])};
  }
  .MuiChip-label{
    color: ${(props) => (props.active ? palette.white : palette.text.subtitle) };
  }
  .MuiChip-deleteIcon {
    color: ${(props) => (props.active ? palette.white : palette.background.iconTags) };
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: ${(props) => (palette.background[props.clickable ? "iconTags" : "inputTags"])};
    cursor: ${(props) => (props.clickable ? "pointer" : "alias") };
    .MuiChip-label, .MuiChip-deleteIcon {
      color: ${(props) => props.clickable && palette.white };
    }
  }
`;

export const StyledSearchText = styled.div`
  padding: 14px 14px 0;
  color: ${palette.text.grayDark};
`;

export const StyledLoad = styled.div`
  padding: 16px;
  text-align: center;
`;
