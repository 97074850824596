import {
  createRef, useEffect, useCallback, useState,
} from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import NineBox from "components/NineBox";
import SimpleAlert from "components/SimpleAlert";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import { NINEBOX, PAGINATION, VARIANT } from "common/constants";
import { TRANSITIONS, MATRIX_SWITCH_OPTIONS } from "common/constants/ninebox";
import {
  isNullOrUndefined,
  isEmpty,
  isNull,
  isNotValid,
} from "common/helpers";
import { switchOptions } from "common/utils";
import { usePatternImageNineBox } from "hooks/usePatternImageNineBox";
import { getList as getCurrentTalentScore } from "redux/actions/talentScore/talentScoreActions";
import Historical from "../Historical";
import HistoricalHeader from "../HistoricalHeader";
import {
  getQuadrantData,
  getCircleByGroup,
  getTooltipName,
  getCircleImage,
  getDataList,
  getDataNineBoxResult,
} from "../../functions";
import {
  StyledNineboxMatrix,
  StyledNineboxContent,
  StyledCard,
  StyledGlobalActions,
  StyledOptionDescription,
} from "./styles";

const Matrix = (props) => {
  const {
    t,
    nineboxStates,
    getQuadrantDetail,
    handleSelectedQuadrant,
    handleEmployeeList,
    handleCircleSelected,
    handleCircleAndReset,
    resultScaleReducerStates,
    resetQuadrants,
    setNineboxStates,
    handleChangeDataByOption,
  } = props;

  const {
    query,
    nineBoxResult,
    selectedPanel,
    accordionSelected,
    selectedQuadrant,
    quadrantResult,
    dataBox,
    anchorEl,
    employeeGroup,
    data,
    optionSelected,
  } = nineboxStates;
  const { isLoadingScaleList, resultScaleList } = resultScaleReducerStates;

  const [currentPage, setCurrentPage] = useState();
  const [concatenatedTalentScores, setConcatenatedTalentScores] = useState([]);
  const [maxPage, setMaxPage] = useState(null);

  // TODO: Check the filter component because the current one generates
  // different queries when nothing is selected.
  let cleanedQuery = {};
  if (query) {
    cleanedQuery = {
      q: Object.fromEntries(
        Object.entries(query.q).filter(([, value]) => !isNull(value)),
      ),
    };
  }

  const {
    data: { data: currentTalentScores, total } = {},
    isLoading: isLoadingCurrentTalentScores,
    error,
    refetch, // This function is used to manually trigger the execution of a query.
  } = useQuery(["currentTalentScores", cleanedQuery, currentPage], () => getCurrentTalentScore(cleanedQuery, currentPage), {
    staleTime: Infinity,
    enabled: false, // property is used to determine if the query should be executed or not
  });

  const setResults = useCallback(async () => {
    resetQuadrants();
    // if it is the first load set empty
    if (currentPage === 1) {
      setNineboxStates({ nineBoxResult: [] });
    }
    if (!isNotValid(concatenatedTalentScores) && !isNotValid(resultScaleList)) {
      const results = await getDataNineBoxResult(concatenatedTalentScores, resultScaleList, t);
      const filteredDataByManagerAnswered = results.filter((result) => result.hasManagerEvaluationAnswered);
      setTimeout(() => {
        setNineboxStates({
          nineBoxResult: results,
          filteredData: filteredDataByManagerAnswered,
          optionSelected: optionSelected !== null ? optionSelected : MATRIX_SWITCH_OPTIONS.manager,
          data: optionSelected === MATRIX_SWITCH_OPTIONS.all ? results : filteredDataByManagerAnswered,
        });
      }, 200);
    }
  }, [
    concatenatedTalentScores,
    resetQuadrants,
    resultScaleList,
    t,
    setNineboxStates,
    currentPage,
    optionSelected,
  ]);

  useEffect(() => {
    if (!isLoadingCurrentTalentScores && !isLoadingScaleList) {
      setResults();
    }
    // eslint-disable-next-line
  }, [
    currentTalentScores,
    concatenatedTalentScores,
    isLoadingCurrentTalentScores,
    isLoadingScaleList,
  ]);

  // Pagination
  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    if (currentPage !== null && (currentPage === 1 || currentPage <= maxPage)) {
      handleRefetch();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setConcatenatedTalentScores([]);
    setMaxPage(null);
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (!isLoadingCurrentTalentScores && currentTalentScores) {
      const actualMaxPage = Math.ceil(total / PAGINATION.maxResultPerPage);
      if (isNull(maxPage) && total) {
        setMaxPage(actualMaxPage);
      }
      setConcatenatedTalentScores((prevScores) => [...prevScores, ...currentTalentScores]);
      if (maxPage && (currentPage === maxPage || actualMaxPage !== maxPage)) {
        setCurrentPage(maxPage + 1);
      } else {
        // Increment the current page by 1
        setCurrentPage(currentPage + 1);
      }
    }
    // eslint-disable-next-line
  }, [isLoadingCurrentTalentScores, currentTalentScores]);

  const circleRef = createRef();

  const isValidData = (!isNullOrUndefined(selectedPanel?.quadrantData)
  && !isNullOrUndefined(selectedPanel?.potentialResult)
  && !isNullOrUndefined(selectedPanel?.performanceResult));

  // Switch options
  const options = [
    switchOptions(t("ninebox:switch.manager"), MATRIX_SWITCH_OPTIONS.manager),
    switchOptions(t("ninebox:switch.all"), MATRIX_SWITCH_OPTIONS.all),
  ];

  const handleChangeSwitchOption = (option) => {
    handleChangeDataByOption(option);
  };

  const patternImage = usePatternImageNineBox(nineBoxResult);

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        {(error && error?.response?.status && !isLoadingCurrentTalentScores)
        && (
          <SimpleAlert
            type={ "error" }
            title={ t("common:common.api_responses.error.title") }
            message={ t(`common:common.api_responses.error.${error.response.status}`) }
          />
        )}
      </Grid>
      <Grid item xs={ 12 } md={ 7 }>
        <StyledCard>
          <StyledNineboxMatrix>
            <Grid container>
              <Grid item xs={ 12 } md={ 5 } />
              <Grid item xs={ 12 } md={ 7 }>
                <SwitchSelectorContent
                  options={ options }
                  hasTitle={ false }
                  onChangeOption={ handleChangeSwitchOption }
                  isFull
                />
              </Grid>
              <Grid item xs={ 12 } md={ 1 } />
              <Grid item xs={ 12 } md={ 11 }>
                <StyledOptionDescription variant={ VARIANT.body1 }>
                  {t(`ninebox:switch.${optionSelected === MATRIX_SWITCH_OPTIONS.all ? "allDescription" : "managerDescription" }`)}
                </StyledOptionDescription>
              </Grid>
            </Grid>
            <NineBox
              getQuadrantDetail={ getQuadrantDetail }
              quadrant={ selectedQuadrant }
              setSelectedQuadrant={ handleSelectedQuadrant }
              accordionSelected={ accordionSelected }
            >
              {/* circle patterns */}
              {patternImage !== "" && patternImage}
              {/* circle details */}
              {(!isLoadingCurrentTalentScores || data) && getCircleByGroup(
                data,
                handleEmployeeList,
                handleCircleSelected,
                circleRef,
                0, // current results
              )}
              {/* actions on matrix  */}
              {!isNullOrUndefined(selectedPanel)
                && !isEmpty(selectedPanel) && (
                <StyledGlobalActions
                  onClick={ () => handleCircleSelected(selectedPanel) }
                >
                  {isValidData
                  && getCircleImage(
                    null,
                    NINEBOX.group.initial,
                    selectedPanel.quadrantData,
                    selectedPanel.potentialResult,
                    selectedPanel.performanceResult,
                    `url(#${NINEBOX.image.id}${selectedPanel.id})`,
                    circleRef,
                    true,
                  )}
                  {selectedPanel.name
                  && getTooltipName(selectedPanel.name, circleRef)}
                </StyledGlobalActions>
              )}
            </NineBox>
          </StyledNineboxMatrix>
        </StyledCard>
      </Grid>
      <Grid item xs={ 12 } md={ 5 }>
        <StyledCard>
          <StyledNineboxContent>
            <HistoricalHeader t={ t } nineBoxResult={ data } />
            <Historical
              data={ getQuadrantData(
                data,
                quadrantResult,
                dataBox,
                t,
              ) }
              { ...props }
            />
            <Popper
              id={ anchorEl && TRANSITIONS.popper }
              open={ !isNull(anchorEl) }
              anchorEl={ anchorEl }
            >
              <Paper elevation={ 3 }>
                {getDataList(
                  handleEmployeeList,
                  employeeGroup,
                  handleCircleAndReset,
                )}
              </Paper>
            </Popper>
          </StyledNineboxContent>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

Matrix.propTypes = {
  t: PropTypes.func.isRequired,
  nineboxStates: PropTypes.object.isRequired,
  getQuadrantDetail: PropTypes.func.isRequired,
  handleSelectedQuadrant: PropTypes.func.isRequired,
  handleEmployeeList: PropTypes.func.isRequired,
  handleCircleSelected: PropTypes.func.isRequired,
  handleCircleAndReset: PropTypes.func.isRequired,
  resultScaleReducerStates: PropTypes.object.isRequired,
  resetQuadrants: PropTypes.func.isRequired,
  setNineboxStates: PropTypes.func.isRequired,
  handleExternalQuery: PropTypes.func.isRequired,
  handleChangeDataByOption: PropTypes.func.isRequired,
};

export default Matrix;
