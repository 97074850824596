import styled from "styled-components";
import Card from "@mui/material/Card";
import Button from "components/Button";
import palette from "theme/palette";

export const StyledCard = styled(Card)`
  margin-bottom: 12px;
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
  img {
    width: 30px;
  }
`;

export const StyledState = styled.div`
  display: flex;
  align-content: center;
  margin-left: 5px;
  svg {
    margin-right: 4px;
  }
`;

export const StyledButton = styled(Button)`
  color: ${palette.text.link};
  font-weight: 400;
`;
