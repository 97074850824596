import styled from "styled-components";
import Button from "components/Button";
import Grid from "@mui/material/Grid";
import palette, { STATE_COLORS } from "theme/palette";

export const StyledButton = styled(Button)`
  color: ${ (props) => props.customColor };
  width: 100%;
  min-height: 35px;
  padding: 0;
`;

export const StyledActions = styled(Grid)`
  h6 {
    margin-bottom:5px;
    font-weight: 700;
  }
  .MuiIconButton-root {
    border-radius: 0;
    border-left: ${palette.border.simple};

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      color: ${STATE_COLORS.ERROR};
    }
  }
`;
