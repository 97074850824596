import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import InputTextSearch from "components/InputTextSearch";
import { isEmpty, isEqual } from "common/helpers";
import { PAGINATION } from "common/constants";
import TableSkeletonLoader from "../TableSkeletonLoader";
import {
  StyledTableBody,
  StyledSearchContainer,
  StyledDownLoad,
  StyledLegend,
  StyledTableContainer,
  StyledTableCell,
} from "../../styles";

const TableMobile = (props) => {
  const {
    header,
    isLoading,
    handleSearch,
    rightOptions,
    contentTable,
    tableRef,
    list,
    total,
  } = props;
  const { t } = useTranslation();

  const content = (
    <StyledTableBody className={ "content" }>
      {contentTable()}
      {!isLoading && !isEmpty(list) && list.length >= PAGINATION.maxPerPage && (
        <TableRow>
          <StyledTableCell colSpan={ header.length }>
            <StyledLegend>
              {isEqual(list.length, total) ? t("common:common.no_more_information") : `(${list.length}/${total})`}
              {list.length < total && ` ${t("common:common.loading")}...`}
            </StyledLegend>
          </StyledTableCell>
        </TableRow>
      )}
      { isLoading && <TableSkeletonLoader cols={ header.length } />}
    </StyledTableBody>
  );

  return (
    <>
      <StyledSearchContainer>
        { handleSearch && <InputTextSearch handleInput={ handleSearch } />}
        { rightOptions && <StyledDownLoad>{ rightOptions }</StyledDownLoad>}
      </StyledSearchContainer>
      <StyledTableContainer ref={ tableRef } height={ 200 }>
        <Table stickyHeader >
          { content }
        </Table>
      </StyledTableContainer>
    </>
  );
};

TableMobile.propTypes = {
  header: PropTypes.array.isRequired,
  contentTable: PropTypes.func.isRequired,
  tableRef: PropTypes.element.isRequired,
  list: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  handleSearch: PropTypes.func,
  rightOptions: PropTypes.any,
  total: PropTypes.number,
};

TableMobile.defaultProps = {
  isLoading: false,
  handleSearch: null,
  rightOptions: null,
  total: 0,
};

export default TableMobile;
