import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    width: "200px",
  },
  maxResultIcon: {
    color: theme.palette.btnIcon.color,
    fontSize: "1.5em",
    paddingLeft: "5px",
  },
}));

export default useStyles;
