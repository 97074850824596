import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import InputTag from "components/InputTag";
import { isEmpty, isEqual } from "common/helpers";
import { getFormattedTags, isColombianAdmin } from "common/utils";
import {
  ALIGN_ITEMS,
  BUTTON_STYLE_TYPES, OBJECT_KEYS, ROLES, SIZE, VARIANT,
} from "common/constants";
import { getList as getCountriesList } from "redux/actions/common/countryActions";
import {
  dispatchUpdate,
  generateRoles,
  getActionRolesWithScope,
  getRolesApp,
  getRolesWithScope,
} from "views/Account/functions/profile";
import {
  StyledDialogActions,
  StyledTitleBox,
  StyledTypography,
  StyledDialogContent,
  StyledLabel,
  StyledAvatar,
  StyledPosition,
} from "./styles";

const RolesModal = (props) => {
  const {
    isOpen,
    onClose,
    reducerToUse,
    from,
    hasSelected,
  } = props;
  const { t } = useTranslation("account");
  const user = reducerToUse?.one;
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [inputCountryTagsSelected, setInputCountryTagsSelected] = useState([]);
  const isValidColombianAdmin = isColombianAdmin(user?.userCookies) && from;
  const [roleSelected, setRoleSelected] = useState({});
  const [userRoles, setUserRoles] = useState({});
  const {
    list: countriesList,
  } = useSelector(({ countryReducer }) => countryReducer);
  const { list: roleList } = useSelector(({ rolesReducer }) => rolesReducer);

  const ROLES_LIST = getRolesApp(roleList, t, isValidColombianAdmin);

  const rolesCollaborator = isEmpty(hasSelected) ? user?.roles_with_scope : hasSelected;

  const dispatch = useDispatch();

  const {
    handleSubmit, register,
  } = useForm();

  const onCancel = () => {
    setUserRoles(generateRoles(getRolesWithScope(rolesCollaborator, t), null, true));
    setRoleSelected({});
    setInputCountryTagsSelected([]);
    onClose();
  };

  const onSubmit = async () => {
    const data = {
      collaborator: { user_roles: getActionRolesWithScope(user, userRoles) },
    };
    dispatchUpdate(dispatch, OBJECT_KEYS.collaborator, data, reducerToUse);
  };

  const handleTags = (tags) => {
    setUserRoles(generateRoles(tags, userRoles));
    setRoleSelected({});
    setInputCountryTagsSelected([]);
  };

  const handleCountryTags = (tags) => {
    setUserRoles({ ...userRoles, [roleSelected.value]: tags });
  };

  useEffect(() => {
    if (user) {
      dispatch(getCountriesList());
      if (!isEmpty(rolesCollaborator)) {
        const rolesWithScope = getRolesWithScope(rolesCollaborator, t);
        setInputTagsSelected(rolesWithScope);
        setUserRoles(generateRoles(rolesWithScope, null, true));
      }
    }
  }, [t, dispatch, roleList, rolesCollaborator, user]);

  const handleRole = (role) => {
    setRoleSelected(role);
    setInputCountryTagsSelected(userRoles[role.value]);
  };

  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      fullWidth
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <DialogTitle disableTypography>
          <Box display={ ALIGN_ITEMS.flex }>
            <StyledTitleBox flexGrow={ 1 }>
              { t("addRoles.title") }
            </StyledTitleBox>
            <Box>
              <IconButton onClick={ onClose }>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <StyledDialogContent>
          <StyledTypography>{ t("addRoles.subtitle") }</StyledTypography>
          <Box display={ ALIGN_ITEMS.flex }>
            <Box marginBottom={ 0 } marginRight={ 1 }>
              <StyledAvatar
                src={ user?.profile_img_url }
                alt={ user?.full_name }
              />
            </Box>
            <Box m={ 2 } marginLeft={ 0 } marginBottom={ 0 }>
              <Typography variant={ VARIANT.h4 }>
                {user?.full_name}
              </Typography>
              <StyledPosition
                variant={ VARIANT.h5 }
              >
                { user?.job_position?.position_name }
                { user?.legal_entity && ` / ${user?.legal_entity?.name}` }
                { user?.job_position && ` / ${user?.job_position?.country?.name}` }
              </StyledPosition>
            </Box>
          </Box>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } >
              <StyledLabel>
                { t("addRoles.rolesAssigned") }
              </StyledLabel>
              <InputTag
                id={ "user_roles" }
                label={ t("common:common.roles.main") }
                size={ SIZE.small }
                itemsSelected={ inputTagsSelected }
                name={ "user_roles" }
                register={ register }
                onChange={ handleTags }
                data={ ROLES_LIST }
                onClickTag={ handleRole }
                isRequired
              />
            </Grid>
            <Grid item xs={ 12 } >
              <StyledLabel>
                { t("addRoles.rolLevel") }
              </StyledLabel>
              <InputTag
                id={ "country_ids" }
                label={ t("common:common.country") }
                size={ SIZE.small }
                itemsSelected={ inputCountryTagsSelected }
                name={ "country_ids" }
                register={ register }
                onChange={ handleCountryTags }
                data={ getFormattedTags(countriesList, OBJECT_KEYS.name) }
                isDisabled={ !isEqual(roleSelected.value, ROLES.ADMIN) }
              />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ onCancel }>
            { t("Onboarding:cancelButton") }
          </Button>
          <Button
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            type={ BUTTON_STYLE_TYPES.SUBMIT }
            isLoading={ reducerToUse?.isLoadingProcess }
          >
            { t("common:common.save") }
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

RolesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasSelected: PropTypes.array.isRequired,
  reducerToUse: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
};

export default RolesModal;
