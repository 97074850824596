import styled from "styled-components";
import palette from "theme/palette";

export const StyledEditorContainer = styled.div`
  border: ${palette.border.light};
  margin-bottom: 15px;
  .rdw-editor-main{
    height: 150px;
    margin: 10px;
  }
`;
