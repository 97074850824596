import qs from "qs";
import isEmpty from "lodash/isEmpty";
import {
  PARAMS_SERIALIZER_OPTIONS,
  OBJECT_KEYS,
  LOCAL_STORAGE_NAMES,
  PAGINATION,
} from "common/constants";
import { getItemFromLocalStorage, getEmployeeId } from "common/utils";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_SEARCH,
  GET_LIST_SEARCH,
  GET_LIST_SEARCH_LOADING,
  GET_LIST_SEARCH_ERROR,
  RESET_STATE_COLLABORATOR,
  RESET_CHILDRENS_STATE,
  GET_CHILDRENS_LIST,
  GET_CHILDRENS_LIST_LOADING,
  GET_CHILDRENS_LIST_ERROR,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE_ORG_CHART,
  GET_LIST_ORG_CHART,
  GET_LIST_ORG_CHART_LOADING,
  GET_LIST_ORG_CHART_ERROR,
  PROCCESS_COLLABORATOR,
  PROCCESS_COLLABORATOR_LOADING,
  PROCCESS_COLLABORATOR_ERROR,
  RESET_STATE_COLLABORATOR_PROCCESS,
  RESET_STATE_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_COLLABORATORS_TO_DOWNLOAD_LOADING,
  GET_LIST_COLLABORATORS_TO_DOWNLOAD_ERROR,
  RESET_STATE_MANAGERS,
  GET_MANAGERS_LIST,
  GET_MANAGERS_LIST_LOADING,
  GET_MANAGERS_LIST_ERROR,
  RESET_SUCCESSION_COLLABORATOR_STATE,
  GET_SUCCESSION_COLLABORATOR_LIST,
  GET_SUCCESSION_COLLABORATOR_LIST_LOADING,
  GET_SUCCESSION_COLLABORATOR_LIST_ERROR,
  RESET_COLLABORATOR_SUCCESSION_STATE,
  GET_COLLABORATOR_SUCCESSION_LIST,
  GET_COLLABORATOR_SUCCESSION_LIST_LOADING,
  GET_COLLABORATOR_SUCCESSION_LIST_ERROR,
} from "../actionTypes/collaborator";

const URL = {
  main: "/collaborators",
  employees: "/employees",
  download: "?download=true",
  managerId: "?manager_id=",
  companyId: "?company_id=",
  turnovers: "turnovers",
  rehires: "rehires",
  successions: "succession_plans",
  light: "light",
  audiences: "audiences",
  talentFramework: "download_talent_framework",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_COLLABORATOR,
  });
};

export const resetStateOrgChart = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ORG_CHART,
  });
};

export const resetStateSearch = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_SEARCH,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_COLLABORATOR_PROCCESS,
  });
};

export const resetCollaboratorsToDownload = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_COLLABORATORS_TO_DOWNLOAD,
  });
};

export const getListSearch = (isEmptyFilter, query) => async (dispatch) => {
  const employeeId = getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));

  let object = null;
  let dispatchRequest = true;
  if (isEmptyFilter) {
    object = query.q;
    dispatchRequest = Object.values(object).map((item) => !isEmpty(item));
  }

  dispatch({
    type: GET_LIST_LOADING,
  });

  try {
    let responseList = [];
    if (!isEmptyFilter || (isEmptyFilter && dispatchRequest.find((item) => item === true))) {
      const response = await configAxios.get(URL.main, {
        params: { ...query, [OBJECT_KEYS.employeeId]: employeeId },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      responseList = response.data.collaborators;
    }
    dispatch({
      type: GET_LIST,
      payload: responseList,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getOne = (collaboratorId) => async (dispatch, getState) => {
  dispatch({
    type: GET_ONE_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${collaboratorId}`);
    dispatch({
      type: GET_ONE,
      payload: response.data.collaborator,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_ERROR);
  }
};

export const resetSteteManagers = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_MANAGERS,
  });
};

export const getMainList = (query, page = null) => async (dispatch, getState) => {
  const { managersList } = getState().collaboratorReducer;
  const pagination = page ? { size: PAGINATION.maxPerPage, number: page } : null;

  if (!managersList || page) {
    dispatch({
      type: GET_MANAGERS_LIST_LOADING,
    });
    try {
      const responseManagers = await configAxios.get(URL.main, {
        params: { ...query, page: pagination },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      dispatch({
        type: GET_MANAGERS_LIST,
        payload: responseManagers.data.collaborators,
        total: responseManagers.headers["x-total"],
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_MANAGERS_LIST_ERROR);
    }
  }
};

export const getOrchartList = (isEmptyFilter, query) => async (dispatch) => {
  let object = null;
  let dispatchRequest = true;
  if (isEmptyFilter) {
    object = query.q;
    dispatchRequest = Object.values(object).map((item) => !isEmpty(item));
  }

  dispatch({
    type: GET_LIST_ORG_CHART_LOADING,
  });

  try {
    let responseList = [];
    if (!isEmptyFilter || (isEmptyFilter && dispatchRequest.find((item) => item === true))) {
      const response = await configAxios.get(URL.main, {
        params: { ...query, tree: true },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      responseList = response.data.collaborators;
    }

    dispatch({
      type: GET_LIST_ORG_CHART,
      payload: responseList,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ORG_CHART_ERROR);
  }
};

export const create = (data) => async (dispatch) => {
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const response = await configAxios.post(URL.main, data);
    dispatch({
      type: PROCCESS_COLLABORATOR,
      payload: response.data.collaborator,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const update = (data, collaboratorId, dataImage = null) => async (dispatch) => {
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    // TODO: remove dataImage from here when normalization comes
    const UPDATE_COLLABORATOR_URL = `${URL.main}/${collaboratorId}`;
    if (dataImage) {
      await configAxios.put(UPDATE_COLLABORATOR_URL, dataImage);
    }
    const response = await configAxios.put(UPDATE_COLLABORATOR_URL, data);
    dispatch({
      type: PROCCESS_COLLABORATOR,
      payload: response.data.collaborator,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const updateAvatar = (collaboratorId, dataImage) => async (dispatch) => {
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const UPDATE_COLLABORATOR_URL = `${URL.main}/${collaboratorId}`;
    const response = await configAxios.put(UPDATE_COLLABORATOR_URL, dataImage);
    dispatch({
      type: PROCCESS_COLLABORATOR,
      payload: response.data.collaborator,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const getTeamByManager = (managerId, employeeId) => async (dispatch) => {
  const query = {
    q: {
      manager_id_special_in: [managerId],
      active_in: [true],
      id_not_in: [employeeId],
    },
  };
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.collaborators;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export const getCollaboratorByManager = (collaboratorId, managerId = null) => async (dispatch) => {
  const employeeId = managerId || getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const query = {
    q: {
      managers_ids_special_in: [employeeId],
      id_eq: collaboratorId,
    },
  };

  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  const pagination = { size: PAGINATION.maxPerPage, number: PAGINATION.next };
  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, [OBJECT_KEYS.employeeId]: employeeId, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.collaborators;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

// get list collaborators with pagination (create this new function in order to not affect the other filters who use the previous function)
// We should clean this up later!
export const getListPaginated = (page, query, maxPerPage = PAGINATION.maxPerPage) => async (dispatch) => {
  const employeeId = getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  dispatch({
    type: GET_LIST_LOADING,
  });
  // size: 10
  // temporal technical implementention:  page size will be 10 always (for now) untill we implement the row handling component for tables
  const pagination = { size: maxPerPage, number: page };
  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, [OBJECT_KEYS.employeeId]: employeeId, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GET_LIST,
      payload: response.data.collaborators,
      total: response.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getListSearchPaginated = (query) => async (dispatch) => {
  const employeeId = getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  dispatch({
    type: GET_LIST_SEARCH_LOADING,
  });
  // size: 10
  // temporal technical implementention:  page size will be 10 always (for now) untill we implement the row handling component for tables
  const pagination = { size: PAGINATION.maxPerPage };
  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, [OBJECT_KEYS.employeeId]: employeeId, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    dispatch({
      type: GET_LIST_SEARCH,
      payload: response.data.collaborators,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_SEARCH_ERROR);
  }
};

export const getCollaboratorByEmails = async (emails) => {
  const query = {
    q: {
      email_in: emails,
    },
  };
  try {
    const resp = await configAxios.get(URL.main, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return resp.data.collaborators;
  } catch (err) {
    return err;
  }
};

export const getCollaboratorsToDownload = (query) => async (dispatch) => {
  const ORGANIZATION_CHART_URL = `${URL.main}${URL.download}`;
  dispatch({
    type: GET_LIST_COLLABORATORS_TO_DOWNLOAD_LOADING,
  });

  try {
    const response = await configAxios.get(ORGANIZATION_CHART_URL, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_COLLABORATORS_TO_DOWNLOAD,
      payload: response.data.collaborators,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_COLLABORATORS_TO_DOWNLOAD_ERROR);
  }
};

// This fn is not handle it with redux (no need it)
export const getCollaboratorChildrens = async (query) => {
  try {
    const resp = await configAxios.get(URL.main, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return resp.data.collaborators;
  } catch (err) {
    return err;
  }
};

export const resetChildrenState = () => (dispatch) => {
  dispatch({
    type: RESET_CHILDRENS_STATE,
  });
};

export const getChildren = (query) => async (dispatch) => {
  dispatch({
    type: GET_CHILDRENS_LIST_LOADING,
  });
  const pagination = { size: PAGINATION.maxPerPage };
  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_CHILDRENS_LIST,
      payload: response.data.collaborators,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_CHILDRENS_LIST_ERROR);
  }
};

export const setChildrensList = (collaborators) => async (dispatch) => {
  dispatch({
    type: GET_CHILDRENS_LIST,
    payload: collaborators,
  });
};

export const terminateEmployment = (id, data) => async (dispatch) => {
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const response = await configAxios.post(`${URL.employees}/${id}/${URL.turnovers}`, data);
    dispatch({
      type: PROCCESS_COLLABORATOR,
      payload: response.data.turnover,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const rehireEmployee = (id, data) => async (dispatch) => {
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const response = await configAxios.post(`${URL.employees}/${id}/${URL.rehires}`, data);
    dispatch({
      type: PROCCESS_COLLABORATOR,
      payload: response.data.rehire,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const getAsyncCollaboratorChildrens = (findById, type) => async (dispatch) => {
  const query = {
    q: {
      [type]: findById,
    },
  };
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const response = await configAxios.get(URL.employees, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.employees;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export const getAsyncCollaborator = (collaboratorId) => async () => {
  try {
    const response = await configAxios.get(`${URL.main}/${collaboratorId}`);
    return response.data.collaborator;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export const deleteCollaborator = (id) => async (dispatch) => {
  dispatch({ type: PROCCESS_COLLABORATOR_LOADING });
  try {
    const response = await configAxios.delete(`${URL.main}/${id}`);
    dispatch({
      type: PROCCESS_COLLABORATOR,
      payload: isEmpty(response.data),
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const addSuccessionsToCollaborator = (id, data) => async (dispatch) => {
  dispatch({ type: PROCCESS_COLLABORATOR_LOADING });
  try {
    const response = await configAxios.post(`${URL.main}/${id}/${URL.successions}`, data);
    return response.data?.succession_plan;
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const updateSuccessionPlanByCollaborator = (id, data) => async (dispatch) => {
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const response = await configAxios.put(`/${URL.successions}/${id}`, data);
    return response.data?.succession_plan;
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const deleteSuccessionPlanByCollaborator = (id) => async (dispatch) => {
  dispatch({
    type: PROCCESS_COLLABORATOR_LOADING,
  });
  try {
    const response = await configAxios.delete(`/${URL.successions}/${id}`);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, PROCCESS_COLLABORATOR_ERROR);
  }
};

export const getTotalCollaboratorByPositionAndOrgUnit = async (positionId, organizationUnitId) => {
  const pagination = { size: 1, number: 1 };

  const query = {
    q: {
      organization_unit_id_in: [organizationUnitId],
      position_id: [positionId],
    },
  };

  const response = await configAxios.get(URL.main, {
    params: { ...query, page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.headers["x-total"];
};

export const getSuccessionPlansByEmployeeId = async (employeeId) => {
  const response = await configAxios.get(`${URL.main}/${employeeId}/${URL.successions}`);
  const successionPlans = response.data?.succession_plans;

  if (successionPlans && successionPlans.length > 0) {
    return successionPlans.map((plan) => ({
      ...plan,
      name: `${plan.position_name} (${plan.organization_unit_complete_path})`,
    }));
  }

  return successionPlans;
};

export const resetSuccessionCollaboratorsState = () => (dispatch) => {
  dispatch({
    type: RESET_SUCCESSION_COLLABORATOR_STATE,
  });
};

export const getSuccessionCollaborators = (query) => async (dispatch) => {
  dispatch({
    type: GET_SUCCESSION_COLLABORATOR_LIST_LOADING,
  });
  const pagination = { size: PAGINATION.maxPerPage };
  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_SUCCESSION_COLLABORATOR_LIST,
      payload: response.data.collaborators,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_SUCCESSION_COLLABORATOR_LIST_ERROR);
  }
};

export const getCollaborator = (collaboratorId) => async (dispatch, getState) => {
  try {
    const response = await configAxios.get(`${URL.main}/${collaboratorId}`);
    return response.data.collaborator;
  } catch (error) {
    errorHandler(error, dispatch, GET_ONE_ERROR);
  }
};

export const resetCollaboratorsSuccessionState = () => (dispatch) => {
  dispatch({
    type: RESET_COLLABORATOR_SUCCESSION_STATE,
  });
};

export const getSuccessionByCollaboratorsLight = (query) => async (dispatch) => {
  dispatch({
    type: GET_COLLABORATOR_SUCCESSION_LIST_LOADING,
  });
  const pagination = { number: 1, size: 200 };
  try {
    const response = await configAxios.get(`${URL.main}/${URL.light}`, {
      params: { ...query, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_COLLABORATOR_SUCCESSION_LIST,
      payload: response.data.collaborators,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_COLLABORATOR_SUCCESSION_LIST_ERROR);
  }
};

export const getCollaboratorLightInfo = async (audienceId = null) => {
  const query = {
    q: {
      active_in: [true],
    },
  };
  // const pagination = { size: "50" };
  const COLLABORATOR_URL = audienceId
    ? `${URL.audiences}/${audienceId}${URL.main}`
    : `${URL.main}/${URL.light}`;
  const response = await configAxios.get(COLLABORATOR_URL, {
    params: { ...query /* page: pagination */ },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { collaborators } = response.data;
  return collaborators;
};

export const getCollaboratorLightInfoPaginated = async (
  audienceId = null,
  page = 1,
  size = 200,
) => {
  const query = {
    q: {
      active_in: [true],
    },
  };
  const pagination = { number: page, size };
  const COLLABORATOR_URL = audienceId
    ? `${URL.audiences}/${audienceId}${URL.main}`
    : `${URL.main}/${URL.light}`;
  const response = await configAxios.get(COLLABORATOR_URL, {
    params: { ...query, page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { collaborators } = response.data;
  const total = parseInt(response.headers["x-total"], 10);
  return {
    collaborators,
    total,
    page,
  };
};

export const getCollaboratorGeneralLightInfoPaginated = async (
  audienceId = null,
  page = 1,
  size = 200,
) => {
  const pagination = { number: page, size };
  const COLLABORATOR_URL = audienceId
    ? `${URL.audiences}/${audienceId}${URL.main}`
    : `${URL.main}/${URL.light}`;
  const response = await configAxios.get(COLLABORATOR_URL, {
    params: { page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  const { collaborators } = response.data;
  const total = parseInt(response.headers["x-total"], 10);
  return {
    collaborators,
    total,
    page,
  };
};

export const downloadTalentFramework = (query, isOnlyManager = false) => async (dispatch) => {
  const employeeId = getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const newQuery = { ...query };
  if (isOnlyManager) {
    newQuery.manager_id_special_in = [employeeId];
  }
  try {
    const response = await configAxios.get(`${URL.main}/${URL.talentFramework}`, {
      params: { ...newQuery },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data?.async_task;
  } catch (error) {
    errorHandler(error, dispatch);
  }
};
