import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 5,
    width: 28,
    height: 28,
  },
  itemGrid: {
    paddingLeft: 12,
    paddingRight: 12,
    textAlign: "left",
  },
  subtitleText: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
    color: theme.palette.black,
  },
  actionButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px",
  },
  cancel: {
    marginRight: "15px",
  },
}));