import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { getListHc, getListCtc } from "redux/actions/hrAnalyticsActions";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { getLabelCharts, getDataCharts, getMaxYLabel } from "common/utils";
import { HR_ANALYTICS } from "common/constants/charts";
import { DIRECTION } from "common/constants";
import HrEvolutionChart from "./components/HrEvolutionChart";
import useStyles from "./styles";

const HrAnalytics = () => {
  const classes = useStyles();
  const { t } = useTranslation("HRAnalytics");
  const dispatch = useDispatch();

  const { listHc, listCtc } = useSelector(
    ({ hrAnalyticsReducer }) => hrAnalyticsReducer,
  );

  useEffect(() => {
    dispatch(getListHc());
    dispatch(getListCtc());
  }, [dispatch]);

  const hcLabelChart = getLabelCharts(listHc, t);
  const hcDataChart = getDataCharts(listHc, HR_ANALYTICS.hc);
  const ctcLabelChart = getLabelCharts(listCtc, t);
  const ctcDataChart = getDataCharts(listCtc, HR_ANALYTICS.ctc);

  return (
    <div className={ classes.root } data-testid={ "hr-analytics-container" }>
      <Grid container spacing={ 4 } direction={ DIRECTION.row }>
        <Grid item xs={ 12 }>
          <ViewHeaderTitle title={ t("title") } />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <Paper className={ classes.root }>
            <HrEvolutionChart
              title={ t("charts.HCEvolution") }
              labels={ hcLabelChart }
              data={ hcDataChart }
              chartType={ HR_ANALYTICS.hc }
              yMax={ getMaxYLabel(hcDataChart) }
            />
          </Paper>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <Paper className={ classes.root }>
            <HrEvolutionChart
              title={ t("charts.CTCMonth") }
              labels={ ctcLabelChart }
              data={ ctcDataChart }
              chartType={ HR_ANALYTICS.ctc }
              yMax={ getMaxYLabel(ctcDataChart) }
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default HrAnalytics;
