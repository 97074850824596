import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import palette from "theme/palette";

export const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0;
  h6 {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    width: ${(props) => 70 / props.total || 30}%;
    border-right: ${(props) => (props.hasBorderRight && !props.isLastOne ? `1px solid ${palette.background.lightGrey}` : "none")};
    border-bottom: ${(props) => (props.isMember ? `1px solid ${palette.background.lightGrey}` : "none")};
    border-top: ${(props) => (props.hasBorderTop ? `1px solid ${palette.background.lightGrey}` : "none")};
    text-align: ${(props) => props.align || "none"};
    vertical-align: ${(props) => props.isHeader && "baseline"};
    font-size: ${(props) => props.isHeader && "12px"};
  }
  .MuiFormControlLabel-root {
    margin: 0px;
  }
`;

export const StyledScale = styled.div`
  justify-content: space-between;
  display: flex;
`;
