import styled from "styled-components";
import Grid from "@mui/material/Grid";
import InputForm from "components/InputForm";
import palette from "theme/palette";

export const StyledCardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${palette.background.lightPurple};
  border-radius: 4px;
  img {
    padding-top: 4px;
    padding-left: 16px;
  }
  h6 {
    font-weight: 700;
  }
`;

export const StyledInputForm = styled(InputForm)`
    margin-top: 8px;
`;

export const StyledGrid = styled(Grid)`
 margin-top: 8px;
`;
