import { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import useComplexState from "hooks/utils/useComplexState";
import { getImageURL } from "helpers/presignedUrl";
import {
  getCurrentLanguage, replacePath, historyPush, getValueByProp,
} from "common/utils";
import {
  isEmpty, isEqual, isNull, isNullOrUndefined,
} from "common/helpers";
import { FORM_MODE, OBJECT_KEYS, STATE } from "common/constants";
import { STATUS } from "common/constants/gender";
import useHandleProcess from "hooks/utils/useHandleProcess";
import { VIEW_MORE_OPTIONS } from "../functions";
import {
  resetDefaultValues,
  dispatchUpdate,
  updateCollaboratorAvatar,
  getSelectedModal,
  getOnlyUpdatedFields,
  getItemSelected,
} from "../functions/profile";
import localRoutes from "../functions/routes";

export const useProfileHook = (externalHooks, internalHooks) => {
  const {
    t, dispatch, history, location,
  } = externalHooks;
  const {
    isOwnProfile,
    isManager,
    genderList,
    collaborator,
    process,
    dispatchCollaborator,
    searchCollaborator,
  } = internalHooks;

  const { processStates, resetStateCollaborator } = process;
  const lang = getCurrentLanguage();

  const [profileStates, setProfileStates] = useComplexState({
    isManager,
    isModalOpen: {
      personalInfo: false,
      performance: false,
      skills: false,
      documentation: false,
      goodLeader: false,
      ninebox: false,
    },
    allGendersList: genderList,
    lastGender: null,
    isDisableInputOther: true,
    editImageClassName: false,
    profileImgUrl: null,
  });

  const employee = "collaborator"; // TODO: change this to dynamic when candidate view is ready

  const {
    control, handleSubmit, reset, setValue, errors,
  } = useForm({
    defaultValues: resetDefaultValues(employee),
    mode: FORM_MODE.onChange,
  });

  const successfullyUpdated = t("common:common.api_responses.success.update");

  const dataBreadcrumbs = [
    {
      label: t("collaborators:title"),
      path: "/collaborators",
    },
    {
      label: collaborator?.full_name,
      path: "",
    },
  ];

  // use effect need it to handle the routes (for modals)
  useEffect(() => {
    if (!isEqual(location.pathname, localRoutes.root)) {
      getSelectedModal(location.pathname).then((resolve) => {
        const { path, key } = resolve;
        replacePath(path);
        setProfileStates({
          isModalOpen: {
            ...profileStates.isModalOpen,
            [key]: true,
          },
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  const getPresignedImg = useCallback(async () => {
    if (isNull(profileStates.profileImgUrl)) {
      if (collaborator.profile_img_key) {
        const signedUrl = await getImageURL(collaborator.profile_img_key);
        setProfileStates({
          profileImgUrl: signedUrl,
        });
      } else {
        setProfileStates({
          profileImgUrl: collaborator.external_img_url || "",
        });
      }
    }
    // eslint-disable-next-line
  }, [collaborator]);

  useEffect(() => {
    if (collaborator && isEqual(collaborator?.id, searchCollaborator)) {
      getPresignedImg();
      if (!isNullOrUndefined(collaborator?.gender) && !isEmpty(profileStates.allGendersList)) {
        const existGender = getItemSelected(profileStates.allGendersList, collaborator?.gender);
        const otherOption = getItemSelected(genderList, STATUS.other, OBJECT_KEYS.id);
        const seleted = existGender || otherOption;
        const other = !existGender ? collaborator?.gender : "";
        reset(resetDefaultValues(employee, collaborator, seleted?.label, other));
        setProfileStates({
          isDisableInputOther: !isNullOrUndefined(existGender) && !isEqual(seleted.id, STATUS.other),
          lastGender: existGender || otherOption,
        });
      } else {
        reset(resetDefaultValues(employee, collaborator));
      }
    }
    // eslint-disable-next-line
	}, [collaborator, searchCollaborator, profileStates.allGendersList]);

  useEffect(() => {
    setProfileStates({ isManager });
    // eslint-disable-next-line
	}, [isManager]);

  const processCallback = () => {
    replacePath(history, localRoutes.root);
    dispatchCollaborator();
  };

  useHandleProcess(
    { t, dispatch },
    processStates,
    successfullyUpdated,
    resetStateCollaborator,
    processCallback,
  );

  const onSubmit = (data) => {
    data[employee].state_transition = STATE.FILL_DATA;
    data[employee].person_attributes = getOnlyUpdatedFields(collaborator, data.collaborator);

    Object.keys(data[employee].person_attributes).forEach((key) => {
      if (data[employee].person_attributes[key] === "") {
        data[employee].person_attributes[key] = null;
      }
    });

    data[employee].person_attributes.gender = isEqual(profileStates.lastGender?.id, STATUS.other)
      ? data[employee].person_attributes.gender_other
      : getValueByProp(data[employee].person_attributes.gender, OBJECT_KEYS.value);
    delete data[employee].person_attributes.gender_other;

    dispatchUpdate(dispatch, employee, data, collaborator.id);
  };

  const handleViewMore = (mode) => {
    switch (mode) {
    case VIEW_MORE_OPTIONS.received:
      // TODO: should hadle it functionallity
      break;
    case VIEW_MORE_OPTIONS.sent:
      // TODO: should hadle it functionallity
      break;
    case VIEW_MORE_OPTIONS.goals:
      historyPush(history, "/goals");
      break;
    case VIEW_MORE_OPTIONS.benefits:
      // TODO: should hadle it functionallity
      break;
    case VIEW_MORE_OPTIONS.movementsHistory:
      // TODO: should hadle it functionallity
      break;
    default:
      break;
    }
  };

  const handleProfileImage = (key, image) => {
    let imgProfile = null;
    if (image) {
      const formData = new FormData();
      formData.append(`${employee}[profile_img]`, image?.file);
      imgProfile = formData;
    }
    updateCollaboratorAvatar(dispatch, collaborator.id, imgProfile);
  };

  const onAutocompleteGenderChange = () => (prop, newValue) => {
    setValue(`${employee}.person_attributes.gender_other`, "");
    if (newValue) {
      setProfileStates({
        lastGender: newValue,
        isDisableInputOther: !isEqual(newValue.id, STATUS.other),
      });
    } else {
      setProfileStates({ lastGender: null, isDisableInputOther: true });
    }
  };

  return {
    profileStates,
    lang,
    control,
    hookForm: {
      control,
      handleSubmit,
      reset,
    },
    modalButtons: {
      disagree: t("buttons:cancel"),
      agree: t("buttons:saveChanges"),
    },
    errors,
    isReadOnly: isOwnProfile, // TODO: add admin validation here.
    dataBreadcrumbs,
    handleViewMore,
    handleSubmit,
    reset,
    onSubmit,
    handleProfileImage,
    onAutocompleteGenderChange,
  };
};
