import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  customContainer: {
    padding: 0,
    paddingTop: "16px",
    width: "auto",
    maxWidth: "448px",
  },
  content: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "10px 25px 25px",
  },
  avatar: {
    width: "70px !important",
    height: "70px !important",
  },
  descriptionContainer: {
    margin: "10px 15px 15px",
  },
  fullName: {
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "5px",
  },
  descriptions: {
    color: theme.palette.text.secondaryParagraph,
  },
  descriptionDivider: {
    marginTop: "15px"
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.text.link,
    padding: "10px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    color: theme.palette.white,
    padding: "0 15px 0 15px",
    "& svg": {
      marginRight: "10px",
    },
    "& a": {
      color: theme.palette.white,
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  borderButton: {
    borderLeft: `1px solid ${theme.palette.white}`,
  },
}));

export default useStyles;
