import styled from "styled-components";
import palette from "theme/palette";

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .MuiTextField-root {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
  .error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${palette.table.red};
    }
  }
`;
