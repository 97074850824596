import PropTypes from "prop-types";
import { VARIANT } from "common/constants";
import defaultImg from "assets/images/general/avatar.png";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as CriticalPosition } from "assets/images/icons/critic.svg";
import {
  StyledGrid,
  StyledCard,
  StyledContent,
  StyledAvatar,
  StyledFullName,
  StyledTitle,
  AvatarIconContainer,
  StyledCriticalPosition,
} from "./styles";

const UnassignedGrid = (props) => {
  const { unassignedList, t, onOpenModal } = props;

  return (
    <StyledGrid
      data-testid={ "unassigned-grid" }
      my={ 4 }
      pb={ 4 }
      container
    >
      {unassignedList.map(({ person }) => (
        <StyledGrid
          key={ `unassigned-${person.id}` }
          item
        >
          <StyledCard onClick={ () => onOpenModal({ person }) }>
            <StyledContent>
              <AvatarIconContainer>
                <StyledAvatar
                  alt={ person.name }
                  src={ person?.avatar || defaultImg }
                />
                {person?.hasCriticalPosition && (
                  <StyledCriticalPosition>
                    <Tooltip title={ t("collaborators:key_position.title") } placement={ "top" }>
                      <CriticalPosition />
                    </Tooltip>
                  </StyledCriticalPosition>
                )}
              </AvatarIconContainer>
              <StyledFullName variant={ VARIANT.text }>
                { person.name }
              </StyledFullName>
              <StyledTitle>{ person?.title }</StyledTitle>
              <div>
                <StyledTitle>{ person?.department }</StyledTitle>
                <StyledTitle bold={ 1 }>{ person?.country }</StyledTitle>
              </div>
            </StyledContent>
          </StyledCard>
        </StyledGrid>
      ))}
    </StyledGrid>
  );
};



UnassignedGrid.propTypes = {
  unassignedList: PropTypes.arrayOf(PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    department: PropTypes.string,
    country: PropTypes.string,
  })),
  t: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
};

UnassignedGrid.defaultProps = {
  unassignedList: [],
};

export default UnassignedGrid;
