import { useContext, useState } from "react";
import { SessionContext } from "modules/session/context";
import PropTypes from "prop-types";
import useUserAbility from "hooks/useUserAbility";
import { MODULES, MODULE_ACTIONS } from "common/constants";
import AlertAutohide from "components/AlertAutohide";
import FeedbackFormModal from "../../../Account/components/Feedback/components/FeedbackFormModal";
import AcknowledgmentFormModal from "../../../Account/components/Feedback/components/AcknowledgmentFormModal";
import EmployeeModal from "../EmployeeModal";
import { defaultAlertData, getAlertMessage } from "../../functions";

const Modals = (props) => {
  const {
    t, employeeSelected, isOpenMain, setIsOpenMain,
  } = props;

  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  const [isOpenAcknowledgement, setIsOpenAcknowledgement] = useState(false);
  const [alert, setAlert] = useState(defaultAlertData);

  const {
    state: { user },
  } = useContext(SessionContext);

  // Check if user has access to acknowledgments module
  const ability = useUserAbility(user);
  let hasAccessAcknowledgments = false;
  if (ability && ability?.acknowledgments) {
    const acknowledgementAbility = ability?.acknowledgments;
    hasAccessAcknowledgments = acknowledgementAbility?.can(
      MODULE_ACTIONS.view,
      MODULES.acknowledgments,
    );
  }

  // Handle open feedback modal
  const handleOpenFeedback = () => {
    setIsOpenMain(false);
    setIsOpenFeedback(true);
  };

  // Handle open acknowledgement modal
  const handleOpenAcknowledgement = () => {
    setIsOpenMain(false);
    setIsOpenAcknowledgement(true);
  };

  const handleCloseAlert = () => {
    setAlert(defaultAlertData);
  };

  // Show alert message on send feedback or acknowledgement
  const showAlertErrorOrSuccess = (isError) => {
    setAlert(getAlertMessage(isError, t));
  };

  return (
    <>
      <EmployeeModal
        employee={ employeeSelected }
        isOpen={ isOpenMain }
        onOpenFeedback={ () => handleOpenFeedback() }
        onOpenAcknowledgement={ () => handleOpenAcknowledgement() }
        onClose={ () => setIsOpenMain(false) }
        hasAccessAcknowledgments={ hasAccessAcknowledgments }
      />

      <FeedbackFormModal
        employeeId={ employeeSelected }
        isOpen={ isOpenFeedback }
        onClose={ () => setIsOpenFeedback(false) }
        onSend={ showAlertErrorOrSuccess }
      />

      <AcknowledgmentFormModal
        employeeId={ employeeSelected }
        isOpen={ isOpenAcknowledgement }
        onClose={ () => setIsOpenAcknowledgement(false) }
        onSend={ showAlertErrorOrSuccess }
      />

      <AlertAutohide
        open={ alert.isAlertOpen }
        onClose={ handleCloseAlert }
        title={ alert.title }
        message={ alert.message }
        type={ alert.typeOfAlert }
      />
    </>
  );
};

Modals.propTypes = {
  t: PropTypes.func.isRequired,
  employeeSelected: PropTypes.string,
  isOpenMain: PropTypes.bool,
  setIsOpenMain: PropTypes.func,
};

Modals.defaultProps = {
  employeeSelected: "",
  isOpenMain: false,
  setIsOpenMain: () => {},
};

export default Modals;
