import isEmpty from "lodash/isEmpty";
import Hidden from "@mui/material/Hidden";
import { ReactComponent as Dominant } from "assets/images/potential/dominante.svg";
import { ReactComponent as Stable } from "assets/images/potential/estable.svg";
import { ReactComponent as Influential } from "assets/images/potential/influyente.svg";
import { ReactComponent as Conscientious } from "assets/images/potential/concienzudo.svg";
import charts from "theme/charts";
import { INDEX } from "common/constants";
import { isEqual } from "common/helpers";
import { StyledSvg, StyledText } from "styledComponents/Svg";

const EMPTY_CHART = [
  {
    name: "dominant",
    slug: "decision",
  },
  {
    name: "stable",
    slug: "serenity",
  },
  {
    name: "influential",
    slug: "interaction",
  },
  {
    name: "conscious",
    slug: "compliance",
  },
];

export const getFormattedObject = (data) => {
  if (isEmpty(data)) {
    return EMPTY_CHART.map((item) => ({ name: item.name }));
  }
  const eachObj = EMPTY_CHART.map((item) => {
    const mainObject = {
      name: item.name,
    };

    mainObject.value = data[INDEX.zero][item.slug] * 100;
    return mainObject;
  });
  return eachObj;
};

export const renderCustomAxisTick = (props, t, isMobile) => {
  const { x, y, payload } = props;

  const ATTRITION_AXIS = {
    dominant: <Dominant />,
    stable: <Stable />,
    influential: <Influential />,
    conscious: <Conscientious />,
  };

  const X_POSITIONS = {
    dominant: x - 15,
    stable: x + 5,
    influential: x - 15,
    conscious: x - 45,
  };

  const Y_POSITIONS = {
    dominant: y - 60,
    stable: y - 45,
    influential: y,
    conscious: y - 50,
  };

  const TEXT_X_POSITIONS = {
    dominant: x - 25,
    stable: x,
    influential: x - 25,
    conscious: x - 65,
  };

  const icon = ATTRITION_AXIS[payload.value];
  const axisX = X_POSITIONS[payload.value];
  const axisY = Y_POSITIONS[payload.value];
  const textAxisX = TEXT_X_POSITIONS[payload.value];

  const TEXT_Y_POSITIONS = {
    dominant: axisY + 45,
    stable: axisY + 45,
    influential: axisY + 50,
    conscious: axisY + 50,
    collaboration: axisY + 40,
    compensation: axisY + 50,
    engagement: axisY + 50,
  };

  const textAxisY = TEXT_Y_POSITIONS[payload.value];

  const mobileXyPositions = {
    x: x - (!isEqual(payload.index, INDEX.two) ? 12 : 8),
    y: y - (isEqual(payload.index, INDEX.zero) ? 30 : 15),
  };

  return (
    <>
      <Hidden smDown>
        <StyledText x={ textAxisX } y={ textAxisY }>
          {t(`profile:modals.skills.skills.chart.${payload.value}`)}
        </StyledText>
      </Hidden>
      <StyledSvg
        x={ isMobile ? mobileXyPositions.x : axisX }
        y={ isMobile ? mobileXyPositions.y : axisY }
        width={ isMobile ? charts.sizeIcon.standar : charts.sizeIcon.medium }
        height={ isMobile ? charts.sizeIcon.standar : charts.sizeIcon.medium }
        viewBox={ `0 0 ${charts.sizeIcon.medium} ${charts.sizeIcon.medium}` }
      >
        {icon}
      </StyledSvg>
    </>
  );
};
