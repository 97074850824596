import makeStyles from "@mui/styles/makeStyles";
import { ENGAGEMENT } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginBottom: "25px",
  },
  titleText: {
    paddingBottom: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: theme.palette.black,
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(3),
  },
  paperResult: {
    textAlign: "center",
    padding: theme.spacing(2),
    "& img": {
      height: 50,
    },
  },
  result: {
    color: theme.palette.text.results,
  },
  guests: {
    display: "grid",
  },
  table: {
    "& th, td": {
      textAlign: "center",
      fontSize: "14px",
    },
    "& th:first-child, td:first-child": {
      textAlign: "left",
    },
    "& thead tr": {
      background: `${theme.palette.background.head} !important`,
    },
  },
  infoTitle: {
    marginBottom: theme.spacing(3),
  },
  satisfied: {
    background: ENGAGEMENT.satisfied,
  },
  neutral: {
    background: ENGAGEMENT.neutral,
  },
  unsatisfied: {
    background: ENGAGEMENT.unsatisfied,
  },
  satisfiedLight: {
    background: ENGAGEMENT.satisfiedLight,
  },
  neutralLight: {
    background: ENGAGEMENT.neutralLight,
  },
  unsatisfiedLight: {
    background: ENGAGEMENT.unsatisfiedLight,
  },
  chartTitle: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  chart: {
    padding: theme.spacing(4),
  },
  customColumn: {
    width: "120px",
  },
  customBar: {
    height: "400px",
    marginBottom: theme.spacing(4),
  },
  tableByTheme: {
    paddingTop: theme.spacing(3),
    "& .MuiTableCell-root": {
      verticalAlign: "initial",
      fontSize: "12px",
      lineHeight: "16px",
    },
    "& li": {
      listStyle: "none",
      paddingBottom: theme.spacing(2),
    },
  },
  themeDescription: {
    fontSize: "12px",
    lineHeight: "16px",
    paddingTop: theme.spacing(1),
  },
  description: {
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  select: {
    backgroundColor: theme.palette.white,
    padding: "10px 0 10px 0",
    "label + &": {
      marginTop: 8,
    },
  },
  comparativeHeader: {
    textAlign: "center",
    color: theme.palette.white,
    background: theme.palette.text.results,
    fontSize: "12px",
    lineHeight: "14px",
  },
  comparativeTable: {
    "& th, td": {
      textAlign: "left",
      width: "100px",
    },
    "& thead tr": {
      background: `${theme.palette.background.head} !important`,
    },
  },
  chartTitleByArea: {
    textAlign: "center",
  },
  comments: {
    "& .MuiAvatar-img": {
      objectFit: "none",
    },
  },
  commentList: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    marginBottom: theme.spacing(2),
  },
  "@media (max-width: 540px)": {
    paperResult: {
      minHeight: "150px",
    },
    chart: {
      padding: theme.spacing(0),
    },
    customBar: {
      height: "300px",
    },
  },
}));
