import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import {
  INDEX, KEY_PRESS, SKELETONS_NUMBER, VARIANT,
} from "common/constants";
import {
  isEmpty, isEqual, isNull, isUndefined,
} from "common/helpers";
import { getEmployeeImage } from "common/utils";
import { AvatarProfile } from "views/Account/components/PotentialTab/components/NineBoxProfile/styles";
import {
  StyledFormControl, StyledSelect, StyledLabel, StyledListSubheader,
} from "./styles";

const SelectWithImage = (props) => {
  const {
    id,
    label,
    menuItems,
    value,
    onChange,
    disabled,
    searchText,
    setSearchText,
    isLoading,
    defaultValue,
    message,
  } = props;
  const { t } = useTranslation("common");
  const selectedItem = defaultValue || (!isEmpty(menuItems) && value && menuItems?.find((e) => isEqual(e.value, value)));

  const handleChange = (item) => {
    if (!isUndefined(item)) {
      onChange(item);
    }
  };

  const closeSelect = () => {
    if (isEmpty(menuItems) && !isNull(setSearchText)) {
      setSearchText("");
    }
  };

  const getItems = () => {
    const allItems = menuItems ? [...menuItems] : [];
    if (isEmpty(menuItems)) {
      return (
        <NoDataMessage
          message={ isEmpty(searchText) ? message : null }
          isHappyFace={ isEmpty(searchText) }
        />
      );
    }

    if (defaultValue && !allItems.find((e) => isEqual(e.value, defaultValue.value))) {
      allItems.push(defaultValue);
    }

    return allItems?.map((menuItem) => (
      <MenuItem key={ menuItem.value } value={ menuItem.value }>
        <AvatarProfile src={ getEmployeeImage(menuItem.avatar) } />
        <Typography variant={ VARIANT.bodyOne }>{menuItem.label}</Typography>
      </MenuItem>
    ));
  };

  const itemsContent = isLoading
    ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FOUR } isInline={ false } />
    : getItems();

  return (
    <StyledFormControl>
      {label && (
        <StyledLabel>
          {label}
        </StyledLabel>
      )}
      <StyledSelect
        data-testid={ "select-with-image" }
        labelId={ `select-label${id}` }
        id={ id }
        value={ value || INDEX.zero }
        label={ label }
        disabled={ disabled }
        IconComponent={ ExpandMoreIcon }
        onChange={ (e) => handleChange(e.target.value) }
        MenuProps={ { autoFocus: false } }
        onClose={ closeSelect }
        renderValue={ () => (isLoading && !defaultValue ? (
          <SkeletonLoader isInline={ false } hasPadding={ false } />
        ) : (
          <>
            <AvatarProfile src={ getEmployeeImage(selectedItem?.avatar) } />
            <Typography variant={ VARIANT.bodyOne }>{selectedItem?.label || t("common.find_collaborator")}</Typography>
          </>
        )) }
      >
        {!isNull(setSearchText) && (
          <StyledListSubheader>
            <TextField
              label={ t("common.search") }
              variant={ VARIANT.outlined }
              margin={ VARIANT.dense }
              fullWidth
              autoFocus
              onChange={ (e) => setSearchText(e.target.value) }
              onKeyDown={ (e) => {
                if (!isEqual(e.key, KEY_PRESS.escape)) {
                  e.stopPropagation();
                }
              } }
            />
          </StyledListSubheader>
        )}
        { itemsContent }
      </StyledSelect>
    </StyledFormControl>
  );
};

SelectWithImage.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.object,
};

SelectWithImage.defaultProps = {
  disabled: false,
  searchText: null,
  setSearchText: null,
  isLoading: false,
  defaultValue: null,
  message: "",
};

export default SelectWithImage;
