import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`;

export const StyledGrid = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isVertical ? "column" : "row")};
  flex-wrap: wrap;
  gap: ${(props) => (props.gap ? props.gap : "15")}px;
  width: 100%;
`;
