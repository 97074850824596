import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import palette from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px",
  },
  badgeIconContainer: {
    background: theme.palette.primary.main,
    padding: "5px 6px",
    borderRadius: "100%",
    "& :hover": {
      cursor: "pointer",
    },
  },
  badgeProfileDisabled: {
    backgroundColor: theme.palette.text.disabled,
    padding: "5px 6px",
    borderRadius: "100%",
  },
  badgeIcon: {
    color: theme.palette.white,
    fontSie: "16px",
  },
  spanContainer: {
    display: "flex",
    alignItems: "center",
    padding: "6px",
    marginLeft: "15px",
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: "13px",
  },
  messageIcon: {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
}));

export const StyledAvatar = styled(Avatar)`
  width: 110px;
  height: 110px;
  box-shadow: ${palette.boxShadow.radioIcon};
`;
