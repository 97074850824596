import styled from "styled-components";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";

export const StyledContainerList = styled(List)`
  width: 250px;
`;

export const StyledContentFlex = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledIconButton = styled(Button)`
  padding: 0px !important;
`;

export const StyledListItem = styled(ListItem)`
  align-items: center;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  padding: 5px;
`;
