import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import toLower from "lodash/toLower";
import capitalize from "lodash/capitalize";
import Link from "@mui/material/Link";
import CardContent from "@mui/material/CardContent";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { YOUTUBE_URLS, VARIANT } from "common/constants";
import {
  CardContainer, CardMedia, CardTitle, CardActions,
} from "./styledComponents";

const YoutubeCard = (props) => {
  const { videoId, viewMoreLabel } = props;
  const [videoData, setVideoData] = useState({});

  const fetchVideoInformation = async (url) => {
    try {
      const response = await axios.get(
        url,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      return response.data;
    } catch (err) {
      return {
        // TODO: define what message show when youtube fails
        message: "",
      };
    }
  };

  const getFromYoutube = useCallback(
    async () => {
      const url = YOUTUBE_URLS.main + videoId + YOUTUBE_URLS.format;
      const data = await fetchVideoInformation(url);
      // TODO: define how to show when youtube fails
      setVideoData(data.message ? {
        thumbnail_url: "",
        title: "",
      } : data);
    },
    [videoId],
  );

  useEffect(() => {
    getFromYoutube();
  }, [getFromYoutube]);

  return (
    <CardContainer data-testid={ "youtube-card-component" } >
      <CardMedia
        image={ videoData?.thumbnail_url }
        title={ videoData?.title }
      />
      <CardContent>
        <CardTitle gutterBottom variant={ VARIANT.h5 } component={ "h2" }>
          {capitalize(toLower(videoData?.title))}
        </CardTitle>
      </CardContent>
      <CardActions>
        <Link href={ YOUTUBE_URLS.external + videoId } key={ videoId } target={ "_blank" }>
          {viewMoreLabel}
        </Link>
        <ChevronRightIcon />
      </CardActions>
    </CardContainer>
  );
};

YoutubeCard.propTypes = {
  videoId: PropTypes.string.isRequired,
  viewMoreLabel: PropTypes.string.isRequired,
};

export default YoutubeCard;
