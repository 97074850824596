import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { SessionContext } from "modules/session/context";
import ChangePasswordModal from "../../../ChangePasswordModal";
import { useStyles, StyledItem, StyledButton, StyledIcon } from "./styles";

const SidebarMenuButtons = (props) => {
  const { className } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const {
    actions: { signOut },
  } = useContext(SessionContext);
  const { t } = useTranslation("common");

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const onCloseChangePasswordModal = () => setChangePasswordModal(!changePasswordModal);

  return (
    <div className={ clsx(classes.root, className) }>
      <Box
        display={ {
          xs: "block",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        } }
      >
        <Divider className={ classes.divider } />
        <List>
          <StyledItem disableGutters>
            <StyledButton
              onClick={ onCloseChangePasswordModal }
              mobile={ isMobile }
            >
              <StyledIcon>
                <VpnKeyIcon className={ classes.icon } />
                {t("top-nav-bar.change-password")}
              </StyledIcon>
            </StyledButton>
          </StyledItem>
          <StyledItem disableGutters>
            <StyledButton onClick={ signOut } mobile={ isMobile }>
              <StyledIcon>
                <ExitToAppIcon className={ classes.icon } />
                {t("top-nav-bar.logout")}
              </StyledIcon>
            </StyledButton>
          </StyledItem>
        </List>
      </Box>

      <ChangePasswordModal
        open={ changePasswordModal }
        onClose={ onCloseChangePasswordModal }
      />
    </div>
  );
};

SidebarMenuButtons.propTypes = {
  className: PropTypes.string,
};

export default SidebarMenuButtons;
