import styled from "styled-components";
import Paper from "@mui/material/Paper";
import palette from "theme/palette";

export const StyledCandidate = styled.div`
  padding: 24px;
  .title{
    color: ${ palette.black };
    display: flex;
    padding: 40px 0 24px 18px;
    font-size: 24px;
    font-style: normal;
    align-items: center;
    font-family: Roboto;
    font-weight: bold;
    line-height: 28px;
  }
`;

export const StyledContent = styled.div`
  margin-top: 16px;
`;

export const StyledPaper = styled(Paper)`
    width: 100%;
    margin-bottom: 16px;

    ${(props) => props.isMobile && `
      background-color: transparent;
      box-shadow: none;
    `}
`;
