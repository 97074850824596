import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { moduleByMonths, toMonthName } from "common/utils";
import {
  NEXT_PAGE, PREV_PAGE, INDEX, VARIANT, ALIGN_ITEMS,
} from "common/constants";
import { isEqual } from "common/helpers";
import { StyledIconButton, Title } from "./styles";

const CalendarControl = (props) => {
  const {
    currentValue, onClick, index, byMonth,
  } = props;
  const { i18n } = useTranslation();

  const handleYear = (control) => {
    const value = currentValue + (isEqual(control, NEXT_PAGE) ? INDEX.one : -INDEX.one);
    onClick(index, byMonth ? moduleByMonths(value) : value);
  };

  const titleValue = byMonth
    ? toMonthName(currentValue, i18n.language)
    : currentValue;

  return (
    <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
      <StyledIconButton
        disabled={ !byMonth && isEqual(currentValue, INDEX.one) }
        onClick={ () => handleYear(PREV_PAGE) }
        aria-label={ PREV_PAGE }
      >
        <NavigateBeforeOutlinedIcon />
      </StyledIconButton>
      <Title variant={ VARIANT.h4 }>
        { titleValue }
      </Title>
      <StyledIconButton
        onClick={ () => handleYear(NEXT_PAGE) }
        aria-label={ NEXT_PAGE }
      >
        <NavigateNextOutlinedIcon />
      </StyledIconButton>
    </Box>
  );
};

CalendarControl.propTypes = {
  currentValue: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  byMonth: PropTypes.bool,
};

CalendarControl.defaultProps = {
  byMonth: false,
};

export default CalendarControl;
