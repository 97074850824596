import styled from "styled-components";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import palette from "theme/palette";

export const StyledAccordion = styled(MuiAccordion)`
  margin-bottom: 10px;
  box-shadow: none;
  border: ${palette.border.card};
  background: transparent;
  .MuiButtonBase-root {
    padding: 0;
  }
  &:before {
    display: none;
  }
  .Mui-disabled {
    background-color: ${palette.white};
    opacity: 1;
    svg {
      color: ${palette.white};
    }
  }
`;

export const StyledAccordionSummary = styled(MuiAccordionSummary)`
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
  background-color: none !important;
  svg {
    color: ${palette.text.purpleMaxDark};
    font-size: 30px;
    width: 80px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;
