import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_PROCESS,
  PROCESS_CANDIDATE,
  PROCESS_CANDIDATE_LOADING,
  PROCESS_CANDIDATE_ERROR,
  RESET_STATE_CANDIDATE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE_CANDIDATES_TO_DOWNLOAD,
  GET_LIST_CANDIDATES_TO_DOWNLOAD,
  GET_LIST_CANDIDATES_TO_DOWNLOAD_LOADING,
  GET_LIST_CANDIDATES_TO_DOWNLOAD_ERROR,
} from "../actionTypes/candidate";

const INITIAL_STATE = {
  list: [],
  isLoadingList: false,
  errorList: "",
  isLoadingProcess: false,
  successProcess: null,
  errorProcess: null,
  one: null,
  isLoadingOne: false,
  errorOne: "",
  downloadList: null,
  isLoadingDownloadList: false,
  errorDownloadList: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      errorList: null,
      list: [],
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: [],
      isLoadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case RESET_STATE_PROCESS:
    return {
      ...state,
      isLoadingProcess: false,
      successProcess: null,
      errorProcess: null,
    };
  case PROCESS_CANDIDATE:
    return {
      ...state,
      isLoadingProcess: false,
      errorProcess: null,
      successProcess: action.payload,
    };
  case PROCESS_CANDIDATE_LOADING:
    return {
      ...state,
      isLoadingProcess: true,
      errorProcess: null,
      successProcess: null,
    };
  case PROCESS_CANDIDATE_ERROR:
    return {
      ...state,
      errorProcess: action.payload,
      isLoadingProcess: false,
    };
  case RESET_STATE_CANDIDATE:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: null,
      one: null,
    };
  case GET_ONE:
    return {
      ...state,
      one: action.payload,
      isLoadingOne: false,
      errorOne: "",
    };
  case GET_ONE_LOADING:
    return {
      ...state,
      one: null,
      isLoadingOne: true,
      errorOne: "",
    };
  case GET_ONE_ERROR:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: action.payload,
    };
  case RESET_STATE_CANDIDATES_TO_DOWNLOAD:
    return {
      ...state,
      downloadList: null,
      isLoadingDownloadList: false,
      errorDownloadList: "",
    };
  case GET_LIST_CANDIDATES_TO_DOWNLOAD_LOADING:
    return {
      ...state,
      isLoadingDownloadList: true,
    };
  case GET_LIST_CANDIDATES_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: false,
      downloadList: action.payload,
    };
  case GET_LIST_CANDIDATES_TO_DOWNLOAD_ERROR:
    return {
      ...state,
      isLoadingDownloadList: false,
      errorDownloadList: action.payload,
    };
  default:
    return state;
  }
};
