import PropTypes from "prop-types";
import { useCards } from "views/TalentDrain/hooks/useCards";
import RiskCardCollapse from "../RiskCardCollapse";

const TalentDrainChart = (props) => {
  const { query, searchFilter, handleOpenModal } = props;
  const { riskCardsData, handleCollapseState } = useCards({
    query,
    searchFilter,
  });

  const additionalProps = {
    handleCollapseState,
    query,
    searchFilter,
    handleOpenModal,
  };

  return (
    <div data-testid={ "talent-drain-chart-component-view" }>
      {riskCardsData.map((riskCardData) => (
        <RiskCardCollapse
          key={ riskCardData.type }
          { ...riskCardData }
          { ...additionalProps }
        />
      ))}
    </div>
  );
};

TalentDrainChart.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  query: PropTypes.object,
  searchFilter: PropTypes.string,
};

TalentDrainChart.defaultProps = {
  query: null,
  searchFilter: null,
};

export default TalentDrainChart;
