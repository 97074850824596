import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoDataMessage from "components/NoDataMessage";
import StarRating from "components/StarRating";
import TooltipIcon from "components/TooltipIcon";
import { VARIANT } from "common/constants";
import { isEqual, isNull } from "common/helpers";
import useStyles, { StyledIcon, StyledSubmenu } from "./styles";

const SimpleAccordion = (props) => {
  const {
    title,
    subtitle,
    children,
    isEmpty,
    messageNoData,
    icon,
    src,
    isDisabled,
    customImage,
    variant,
    customStyles,
    rating,
    hasTitleTooltip,
    isTitleImage,
    isExpanded,
    setIsExpanded,
    customSubtitle,
    expandedPanel,
    isMain,
    avatar,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(expandedPanel);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setIsExpanded && setIsExpanded(panel);
  };

  return (
    <Accordion
      data-testid={ "simple-accordion-component" }
      expanded={ isEmpty || isNull(isExpanded) ? isEqual(expanded, expandedPanel) && expanded : isExpanded }
      disabled={ isDisabled }
      className={ customStyles?.accordion || "" }
      onChange={ handleChange(expandedPanel) }
    >
      <AccordionSummary
        className={ customStyles?.headerContainer || "" }
        expandIcon={ isEmpty || isDisabled || isMain ? null : <ExpandMoreIcon color={ "primary" } /> }
      >
        <StyledSubmenu>
          {icon && <StyledIcon>{icon}</StyledIcon>}
          {src && (
            <img
              src={ src }
              alt={ "Accordion description" }
              className={ customImage || classes.image }
            />
          )}
          {avatar}

          <div className={ isTitleImage ? classes.titleContainer : "" }>
            <Typography className={ customStyles?.title || "" } variant={ variant }>
              {title}
              {hasTitleTooltip && (
                <TooltipIcon
                  title={ hasTitleTooltip }
                  isHelpIcon
                />
              )}
            </Typography>

            {subtitle && <Typography variant={ VARIANT.bodyTwo }>{subtitle}</Typography>}
            {customSubtitle}
            {rating?.maxRating && (
              <StarRating
                name={ `star-rating-${rating?.name}` }
                value={ rating?.value }
                isReadOnly
                maxRating={ rating?.maxRating }
                max={ rating?.maxRating }
                precision={ 1 }
                hasNoFormattedValue
              />
            )}
          </div>
        </StyledSubmenu>
      </AccordionSummary>
      <AccordionDetails className={ clsx(classes.detailsRoot, customStyles ? customStyles?.container : "") }>
        {isEmpty ? (
          <NoDataMessage
            message={ messageNoData }
            customStyless={ classes.customStylesNoDataMessage }
          />
        ) : (
          children
        )}
      </AccordionDetails>
    </Accordion>
  );
};

SimpleAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.any,
  isEmpty: PropTypes.bool,
  messageNoData: PropTypes.string,
  isDisabled: PropTypes.bool,
  customImage: PropTypes.string,
  variant: PropTypes.string,
  customStyles: PropTypes.object,
  rating: PropTypes.object,
  hasTitleTooltip: PropTypes.string,
  isTitleImage: PropTypes.bool,
  customSubtitle: PropTypes.object,
  expandedPanel: PropTypes.string,
  isMain: PropTypes.bool,
  avatar: PropTypes.bool,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  icon: PropTypes.element,
};

SimpleAccordion.defaultProps = {
  variant: VARIANT.h5,
  icon: null,
  isExpanded: null,
  setIsExpanded: null,
};

export default SimpleAccordion;
