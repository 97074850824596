import styled from "styled-components";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import palette from "theme/palette";

export const StyledCard = styled(Card)`
  margin-bottom: 12px;
  box-shadow: ${palette.boxShadow.card3};

  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
  img {
    width: 30px;
  }
`;

export const StyledState = styled.div`
  display: flex;
  align-content: center;
  margin-left: 5px;
  svg {
    margin-right: 4px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${palette.text.link};
  font-weight: 400;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLinkEvaluation = styled.span`
  color: ${palette.text.link};
  font-size: 14px;
  text-decoration: none;
  svg{
    font-size: 14px;
    vertical-align: middle;
    margin-left: 2px;
  }
`;
