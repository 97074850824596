import styled from "styled-components";
import palette from "theme/palette";

export const StyledTitle = styled.div`
  font-size: 20px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyledContent = styled.div`
  text-align: right;
  button {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  & .MuiButton-outlined {
    color: ${palette.text.delete};
    border-color: ${palette.text.delete};
  }
  @media (max-width: 600px) {
    button {
      margin-left: 0;
      width: 100%;
    }
  }
`;
