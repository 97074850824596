import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {
  VARIANT,
  ALIGN_ITEMS,
  ICON_TYPES,
  WRAP,
} from "common/constants";
import { StyledIconContainer, StyledSubtitle } from "./styles";

const TitleIcon = (props) => {
  const {
    icon, title, subtitle, iconType, bottomSubtitle,
  } = props;

  return (
    <>
      <Grid
        spacing={ 1 }
        alignItems={ ALIGN_ITEMS.center }
        wrap={ WRAP.nowrap }
        container
      >
        <Grid item>
          {iconType === ICON_TYPES.icon ? (
            <StyledIconContainer
              color={ icon?.color }
              width={ icon?.width }
              height={ icon?.height }
            >
              <icon.src />
            </StyledIconContainer>
          ) : (
            <Avatar
              src={ icon.src }
              variant={ icon.variant ? VARIANT[icon.variant] : VARIANT.square }
              sx={ { width: icon.width, height: icon.height } }
            />
          )}
        </Grid>
        <Grid item>
          {title && (<Typography variant={ VARIANT.h4 }>{title}</Typography>)}
          {!bottomSubtitle && subtitle && (<Typography>{subtitle}</Typography>)}
        </Grid>
      </Grid>
      {bottomSubtitle && subtitle && (<StyledSubtitle>{subtitle}</StyledSubtitle>)}
    </>
  );
};

TitleIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconType: PropTypes.string,
  bottomSubtitle: PropTypes.bool,
};

TitleIcon.defaultProps = {
  title: "",
  subtitle: "",
  iconType: ICON_TYPES.url,
  bottomSubtitle: false,
};

export default TitleIcon;
