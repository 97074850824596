/* eslint-disable arrow-body-style */
import { createContext } from "react";
import { app } from "./lib/firebase";

const Context = createContext(null);

const Provider = ({ children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={ { firebase: app } }>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
