export const RESET_STATE = "RESET_STATE_COLLABORATORS";
export const GET_LIST = "GET_LIST_COLLABORATORS";
export const GET_MAIN_LIST = "GET_MAIN_LIST_COLLABORATORS";
export const GET_LIST_LOADING = "GET_LIST_LOADING_COLLABORATORS";
export const GET_LIST_ERROR = "GET_LIST_ERROR_COLLABORATORS";

export const RESET_STATE_SEARCH = "RESET_STATE_SEARCH_COLLABORATORS";
export const GET_LIST_SEARCH = "GET_LIST_SEARCH_COLLABORATORS";
export const GET_LIST_SEARCH_LOADING = "GET_LIST_SEARCH_LOADING_COLLABORATORS";
export const GET_LIST_SEARCH_ERROR = "GET_LIST_SEARCH_ERROR_COLLABORATORS";

export const RESET_CHILDRENS_STATE = "RESET_STATE_CHILDRENS_COLLABORATORS";
export const GET_CHILDRENS_LIST = "GET_CHILDRENS_LIST_COLLABORATORS";
export const GET_CHILDRENS_LIST_LOADING = "GET_CHILDRENS_LIST_LOADING_COLLABORATORS";
export const GET_CHILDRENS_LIST_ERROR = "GET_CHILDRENS_LIST_ERROR_COLLABORATORS";

export const RESET_STATE_COLLABORATOR = "RESET_STATE_COLLABORATOR";
export const GET_ONE = "GET_ONE_COLLABORATOR";
export const GET_ONE_LOADING = "GET_ONE_LOADING_COLLABORATOR";
export const GET_ONE_ERROR = "GET_ONE_ERROR_COLLABORATOR";

export const RESET_STATE_ORG_CHART = "RESET_STATE_ORG_CHART";
export const GET_LIST_ORG_CHART = "GET_LIST_ORG_CHART_COLLABORATOR";
export const GET_LIST_ORG_CHART_LOADING = "GET_LIST_ORG_CHART_LOADING_COLLABORATOR";
export const GET_LIST_ORG_CHART_ERROR = "GET_LIST_ORG_CHART_ERROR_COLLABORATOR";

export const PROCCESS_COLLABORATOR = "PROCCESS_COLLABORATOR";
export const PROCCESS_COLLABORATOR_LOADING = "PROCCESS_COLLABORATOR_LOADING";
export const PROCCESS_COLLABORATOR_ERROR = "PROCCESS_COLLABORATOR_ERROR";

export const RESET_STATE_COLLABORATOR_PROCCESS = "RESET_STATE_COLLABORATOR_PROCCESS";
export const RESET_STATE_COLLABORATORS_TO_DOWNLOAD = "RESET_STATE_COLLABORATORS_TO_DOWNLOAD";
export const GET_LIST_COLLABORATORS_TO_DOWNLOAD = "GET_LIST_COLLABORATORS_TO_DOWNLOAD";
export const GET_LIST_COLLABORATORS_TO_DOWNLOAD_LOADING = "GET_LIST_COLLABORATORS_TO_DOWNLOAD_LOADING";
export const GET_LIST_COLLABORATORS_TO_DOWNLOAD_ERROR = "GET_LIST_COLLABORATORS_TO_DOWNLOAD_ERROR";

export const RESET_STATE_MANAGERS = "RESET_STATE_MANAGERS";
export const GET_MANAGERS_LIST = "GET_MANAGERS_LIST";
export const GET_MANAGERS_LIST_LOADING = "GET_MANAGERS_LIST_LOADING_COLLABORATORS";
export const GET_MANAGERS_LIST_ERROR = "GET_MANAGERS_LIST_ERROR_COLLABORATORS";

export const RESET_SUCCESSION_COLLABORATOR_STATE = "RESET_STATE_SUCCESSION_COLLABORATOR_COLLABORATORS";
export const GET_SUCCESSION_COLLABORATOR_LIST = "GET_SUCCESSION_COLLABORATOR_LIST_COLLABORATORS";
export const GET_SUCCESSION_COLLABORATOR_LIST_LOADING = "GET_SUCCESSION_COLLABORATOR_LIST_LOADING_COLLABORATORS";
export const GET_SUCCESSION_COLLABORATOR_LIST_ERROR = "GET_SUCCESSION_COLLABORATOR_LIST_ERROR_COLLABORATORS";

export const RESET_COLLABORATOR_SUCCESSION_STATE = "RESET_STATE_COLLABORATOR_SUCCESSION_COLLABORATORS";
export const GET_COLLABORATOR_SUCCESSION_LIST = "GET_COLLABORATOR_SUCCESSION_LIST_COLLABORATORS";
export const GET_COLLABORATOR_SUCCESSION_LIST_LOADING = "GET_COLLABORATOR_SUCCESSION_LIST_LOADING_COLLABORATORS";
export const GET_COLLABORATOR_SUCCESSION_LIST_ERROR = "GET_COLLABORATOR_SUCCESSION_LIST_ERROR_COLLABORATORS";