import styled from "styled-components";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { ENGAGEMENT } from "theme/palette";

export const StyledTableRow = styled(TableRow)`
  .MuiTableCell-root {
    font-size: 14px;
  }
  .MuiTableCell-sizeSmall {
    padding: 7px 16px;
  }
  .MuiTableCell-head {
    background-color: ${ ENGAGEMENT.bgPrincipal };
    color: ${ ENGAGEMENT.white };
  }
  .MuiTableCell-root:last-child {
    width: 150px;
    text-align: center;
  }
  &:nth-of-type(even) {
    background-color: ${ ENGAGEMENT.row };
  }
  @media (max-width: 540px) {
    .MuiTableCell-root:last-child {
      width: 80px;
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  background: ${(props) => props.color};
`;

export const StyledAlertContainer = styled.div`
  .MuiAlert-root {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .MuiAlert-standardError {
    color: ${ ENGAGEMENT.selector.textDefault };
    font-size: 14px;
    font-weight: 500;
  }
`;
