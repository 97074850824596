import { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { getSurveyResultsToDownloadAsync } from "redux/actions/surveyProcessesActions";

const useSurveyResults = (processId, query, queryKey, total) => {
  const [allSurveyResults, setAllSurveyResults] = useState([]);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const pageSize = 200;

  const fetchSurveyResults = async ({ pageParam = 1 }) => {
    const response = await getSurveyResultsToDownloadAsync(processId, query, pageParam, pageSize);
    return response;
  };

  const {
    isLoading,
    error,
  } = useInfiniteQuery(
    queryKey,
    fetchSurveyResults,
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.reduce((acc, page) => acc + page.data.length, 0);
        return totalFetched < total ? lastPage.page + 1 : undefined;
      },
      enabled: false,
    },
  );

  const fetchAllSurveyResults = async () => {
    setIsFetchingAll(true);
    setAllSurveyResults([]);
    let results = [];

    const fetchPage = async (page) => {
      const response = await fetchSurveyResults({ pageParam: page });
      results = results.concat(response.data);
      if (results.length < total && response.data.length === pageSize) {
        await fetchPage(page + 1);
      }
    };

    await fetchPage(1);

    setAllSurveyResults(results);
    setIsFetchingAll(false);
  };

  return {
    surveyResult: allSurveyResults,
    isLoadingSurveyResult: isLoading,
    isFetchingSurveyResult: isFetchingAll,
    surveyResultError: error,
    refetchSurveyResults: fetchAllSurveyResults,
  };
};

export default useSurveyResults;
