import styled from "styled-components";
import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import theme from "theme";
import { bgColor, iconColor } from "../../theme/palette";

const bgColors = {
  info: bgColor.infoHexa,
  success: bgColor.successHexa,
  warning: bgColor.warningHexa,
  error: bgColor.errorHexa,
};

export const StyledAlert = styled(Alert)`
&.MuiAlert-standard {
  margin-bottom: ${theme.spacing(2)};
  font-size: ${theme.spacing(1.6)};
  background-color: ${
    (props) => (props.severity ? bgColors[props.severity] : bgColor.infoHexa)
  };
}
`;

const iconPropsInfo = {
  color: iconColor.infoHexa,
};

const iconPropsSuccess = {
  color: iconColor.successHexa,
};

const iconPropsWarning = {
  color: iconColor.warningHexa,
};

const iconPropsError = {
  color: iconColor.errorHexa,
};

export const InfoIconStyled = styled(InfoIcon)`
  ${iconPropsInfo}
`;
export const CheckCircleIconStyled = styled(CheckCircleIcon)`
  ${iconPropsSuccess}
`;
export const WarningIconStyled = styled(WarningIcon)`
  ${iconPropsWarning}
`;
export const ErrorIconStyled = styled(ErrorIcon)`
  ${iconPropsError}
`;
