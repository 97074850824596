import styled from "styled-components";
import Card from "@mui/material/Card";
import palette from "theme/palette";
import { StyledTabsContainer } from "styledComponents/Tabs";

export const StyledContainer = styled.div`
  padding: 32px;
  margin-bottom: 25px;
`;

export const StyledContent = styled.div`
  margin-top: 32px;
`;

export const StyledTabContainer = styled(StyledTabsContainer)`
  margin-top: 36px;
`;

export const StyledCard = styled(Card)`
  padding: 16px;
  border-top: 3px solid ${palette.text.iconDisabled};
  border-radius: 0;
`;

export const StyledSuccessionLabel = styled.div`
  display: flex;
  align-items: center;
  h4 {
    font-size: 11px;
  }
  svg {
    width: 15px;
    padding-right: 5px;
  }
`;
