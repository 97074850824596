import { useState, useEffect, useCallback } from "react";
import isEqual from "lodash/isEqual";
import { default as LinearProgressStyle } from "@mui/material/LinearProgress";
import { LINEAR_PROGRESS, VARIANT } from "common/constants";

const LinearProgress = (props) => {
  const { isActive, setLoading } = props;
  const [progress, setProgress] = useState(LINEAR_PROGRESS.min);

  const handleProgress = useCallback(() => {
    setLoading && setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    if (isActive) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (isEqual(oldProgress, LINEAR_PROGRESS.max)) {
            handleProgress();
            return LINEAR_PROGRESS.max;
          }
          const diff = Math.random() * LINEAR_PROGRESS.diff;
          return Math.min(oldProgress + diff, LINEAR_PROGRESS.max);
        });
      }, LINEAR_PROGRESS.interval);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isActive, handleProgress]);

  return (
    <LinearProgressStyle
      variant={VARIANT.determinate}
      value={progress}
      data-testid="linear-progress"
    />
  );
};

export default LinearProgress;
