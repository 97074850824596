import DeleteIcon from "@mui/icons-material/Delete";
import Button from "components/Button";
import palette, { STATE_COLORS } from "theme/palette";
import styled from "styled-components";

export const StyledButtonAdd = styled(Button)`
  height: 138px;
  width: 100%;
  padding: 0 2%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  .MuiButton-label {
    display: inline-grid;
    text-align: center;
    line-height: 21px;
  }
  .MuiSvgIcon-root {
    font-size: 43px;
  }
  .MuiBox-root {
    text-align: center;
  }
`;

export const StyledRadio = styled.div`
  padding: 6px 16px;
  border-radius: 10px;
  width: 100%;
  height: 138px;
  border: ${ palette.border.radio };
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  :hover {
    border: ${ palette.border.radioSelected };
  }
  &.active {
    border: ${ palette.border.radioSelected };
    color: ${ palette.text.link };
  }
  button > .MuiButton-label svg{
    color: ${ STATE_COLORS.GREY };
  }
  .MuiButton-text {
    padding: 7px 0px;
  }
  .MuiButton-text:hover {
    background-color: transparent;
  }
  .MuiButton-root {
    min-width: 0px;
  }
  .actions {
    text-align: end;
    margin: auto;
    color: ${ STATE_COLORS.GREY };
  }
  .MuiBox-root {
    position: absolute;
    right: 14px;
    bottom: 7px;
  }
`;

export const StyledTitle = styled.div`
  width: 85%;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.05rem;
  padding-right: 16px;
  position: absolute;
  top: 50%;
`;

export const StyledText = styled.div`
  color: ${ STATE_COLORS.ERROR };
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${ STATE_COLORS.ERROR };
`;
