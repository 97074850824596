import qs from "qs";
import {
  PARAMS_SERIALIZER_OPTIONS,
  OBJECT_KEYS,
  LOCAL_STORAGE_NAMES,
  TRUE,
  INDEX,
} from "common/constants";
import { getItemFromLocalStorage, getEmployeeId } from "common/utils";
import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
} from "../../actionTypes/integrations/slack";
const URL = {
  main: "/slack",
  users: "users",
  collaborators: "/collaborators",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getListSlackPaginated = () => async (dispatch) => {
  const employeeId = getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  dispatch({
    type: GET_LIST_LOADING,
  });
  const pagination = { size: INDEX.four, number: INDEX.one };
  try {
    const activeCollaboratorsQuery = {
      q: {
        active_in: [TRUE],
      },
    };
    const responseActiveCollaborators = await configAxios.get(URL.collaborators, {
      params: { ...activeCollaboratorsQuery, [OBJECT_KEYS.employeeId]: employeeId, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });

    const slackQuery = {
      q: {
        slack_id_not_null: INDEX.one,
        active_in: [TRUE],
      },
    };
    const responseConnectedSlack = await configAxios.get(URL.collaborators, {
      params: { ...slackQuery, [OBJECT_KEYS.employeeId]: employeeId, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST,
      totalActiveUsers: responseActiveCollaborators.headers["x-total"],
      totalSlackUsers: responseConnectedSlack.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const updateSlackUsers = () => async (dispatch) => {
  dispatch({
    type: GET_LIST_LOADING,
  });
  try {
    const response = await configAxios.patch(`${URL.main}/${URL.users}`);
    return response.data;
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};
