import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CollaboratorProfile from "./components/CollaboratorProfile";
import CollaboratorDetails from "./components/CollaboratorDetails";
import { StyledResultContainer } from "./styles";

const CollaboratorProfileSummaryCard = ({ collaborator }) => (
  <StyledResultContainer container spacing={ 2 }>
    <Grid item xs={ 12 } md={ 4 }>
      <CollaboratorProfile collaborator={ collaborator } />
    </Grid>
    <Grid item xs={ 12 } md={ 8 }>
      <CollaboratorDetails collaborator={ collaborator } />
    </Grid>
  </StyledResultContainer>
);

CollaboratorProfileSummaryCard.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorProfileSummaryCard;
