import PropTypes from "prop-types";
import Button from "components/Button";
import {
  VARIANT,
  API_URL_BASE_RAILS,
  CURRENT_DOMAIN,
  SIZE,
} from "common/constants";
import { isMulticompanyUser } from "common/utils";
import { StyledButton, StyledContent, StyledTitle } from "./styles";

const ProviderButton = (props) => {
  const {
    isLoading, src, auth, children, isMobile,
  } = props;

  const onClickHandler = () => {
    window.location.replace(
      `${API_URL_BASE_RAILS}/api/v1${isMulticompanyUser() ? "/holdings" : ""}/auth/${auth}/?auth_origin_url=${CURRENT_DOMAIN}/sign-in`,
    );
  };

  return (
    <StyledButton>
      <Button
        onClick={ onClickHandler }
        isDisabled={ isLoading }
        size={ isMobile ? SIZE.small : SIZE.large }
        variant={ VARIANT.outlined }
      >
        <StyledContent>
          <img src={ src } alt={ `${children} Logo` } />
          <StyledTitle>
            {children}
          </StyledTitle>
        </StyledContent>
      </Button>
    </StyledButton>
  );
};

ProviderButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  auth: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

ProviderButton.defaultProps = {
  isMobile: false,
};

export default ProviderButton;
