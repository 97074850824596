import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState, convertToRaw, ContentState, convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { StyledEditorContainer } from "./styles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const InputEditor = (props) => {
  const {
    onChange, validateInputEdit, label, text,
  } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const getEditValidation = () => draftToHtml(editorState.getCurrentContent().hasText() ? convertToRaw(editorState.getCurrentContent()) : null);
  const onEditorStateChange = (value) => {
    setEditorState(value);
    return onChange(getEditValidation());
  };
  useEffect(() => {
    if (text) {
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(text),
        ),
      ));
    }
  }, [text]);

  return (
    <div data-testid={ "input-edit-component" } >
      <Typography>{label}</Typography>
      <StyledEditorContainer>
        <Editor
          editorState={ editorState }
          onEditorStateChange={ onEditorStateChange }
          onChange={ () => validateInputEdit(getEditValidation()) }
        />
      </StyledEditorContainer>
    </div>
  );
};

InputEditor.propTypes = {
  validateInputEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  text: PropTypes.string,
};

InputEditor.defaultProps = {
  onChange: () => {},
  label: null,
  text: null,
};

export default InputEditor;
