import orderBy from "lodash/orderBy";
import includes from "lodash/includes";
import { ERROR, SUCCESS, DESC, ZOOM } from "common/constants";
import { getNormalizedString, truncateString } from "common/utils";
// import { getImageURL } from "helpers/presignedUrl";
import { getOrganizationUnitName } from "views/Account/functions/profile";
import defaultImg from "assets/images/general/avatar.png";

export const defaultAlertData = () => ({
  isAlertOpen: false,
  typeOfAlert: "",
  message: "",
  title: "",
});

/**
 * Function to get the alert message
 * @param {Boolean} isError - Flag to indicate if the alert is an error
 * @param {Function} t - Function to translate
 * @returns {Object} - Object with alert data
 */
export const getAlertMessage = (isError, t) => ({
  isAlertOpen: true,
  typeOfAlert: isError ? ERROR : SUCCESS,
  message: t(
      `feedback:${isError ? "error_feedback" : "send_feedback_successfully"}`,
  ),
  title: t(
      `common:common.api_responses.${
        isError ? "error.title" : "success.title"
      }`,
  ),
});

/**
 * Function to convert the collaborators list to data from generate the nodes
 * @param {Object} collaborator - Collaborator data
 * @param {Function} t - Function to translate
 * @returns {Object} - Collaborator data formatted
 */
export const dataToNode = (collaborator, t) => {
  // TODO: set new image
  // if (isUndefined(collaborator?.profile_signed_img_url)
  // && !isNull(collaborator?.profile_img_key)) {
  //   collaborator.profile_signed_img_url = getImageURL(collaborator.profile_img_key);
  // }
  const imageProfileUrl = collaborator?.profile_img_url_thumb_sm
    || collaborator?.profile_img_url_thumb || collaborator?.profile_img_url;
  const organizationUnits = getOrganizationUnitName(collaborator.job_position?.organization_units);

  const totalReports = collaborator?.children.filter((child) => child.is_active).length;
  let label = "";
  if (totalReports > 0) {
    label = t(`common:common.${totalReports === 1
      ? "roles.collaborator" : "collaborators"}`).toLowerCase();
  }
  const data = {
    id: collaborator.id,
    person: {
      parentId: collaborator?.ancestry,
      id: collaborator.id,
      avatar: imageProfileUrl || defaultImg,
      department: getNormalizedString(organizationUnits?.division),
      email: collaborator?.email,
      city: getNormalizedString(collaborator?.job_position?.city?.name),
      name: getNormalizedString(collaborator?.full_name),
      title: getNormalizedString(truncateString(collaborator?.position_name)),
      totalReports,
      label,
      country: getNormalizedString(collaborator?.job_position?.country?.name),
      hasCriticalPosition: collaborator?.key_position?.critical,
      isASuccessor: collaborator?.is_a_successor,
    },
    hasChild: collaborator?.children?.length > 0,
    hasParent: collaborator.ancestry !== null,
    ancestry: collaborator.ancestry || null,
    children: collaborator?.children.filter((child) => child.is_active).map(
      (activeChild) => dataToNode(activeChild, t),
    ),
  };
  if (collaborator?.children?.length > 0) {
    const { children } = collaborator;
    children.filter((parent) => parent.is_active).map(
      (activeParent) => dataToNode(activeParent, t),
    );
  }
  return (data);
};

/**
 * Function to order the collaborators list by the number of children of mayor to minor
 * @param {Array} collaboratorsList - Collaborators list
 * @param {Function} t - Function to translate
 * @returns {Array} - Ordered collaborators list
 */
export const orderByNumberOfChildren = (collaboratorsList, t) => orderBy(
  collaboratorsList
    .filter((parent) => parent.is_active)
    .map((collaborator) => dataToNode(collaborator, t)),
  (collaborator) => collaborator.children.length,
  DESC,
);

/**
 * Function to separate the collaborators list in two lists:
 * 1. Main tree (with CEO and all collaborators with parent)
 * 2. Unassigned collaborators
 * @param {Array} data - Collaborators list
 * @returns {Object} - Object with mainTree and unassigned
*/
export const getCEOandUnassigned = (data, isChildNode) => {
  const unassigned = [];
  const withChildren = [];
  const inactiveLeader = [];
  const mainTree = {
    id: 0,
    manager_id: 0,
    person: {
      parentId: null,
      id: 0,
      avatar: "",
      department: "",
      email: "",
      city: "",
      name: "",
      title: "",
      totalReports: "",
      label: "",
      country: "",
      hasCriticalPosition: false,
      isASuccessor: false,
    },
    hasChild: true,
    hasParent: false,
    ancestry: null,
    children: withChildren,
  };

  if (data.length > 0) {
    data.forEach((item) => {
      const parentExists = data.some((collaborator) => collaborator.id.toString()
       === item.ancestry);
      if (item.children?.length === 0 && item.hasParent && !parentExists && !isChildNode) {
        inactiveLeader.push(item);
      } else if (item.children?.length === 0 && !item.hasParent) {
        unassigned.push(item);
      } else {
        const hardId = 0;
        item.person.parentId = hardId.toString();
        withChildren.push(item);
      }
    });
    mainTree.children = withChildren;
    return { mainTree, unassigned, inactiveLeader };
  }
  return { mainTree, unassigned, inactiveLeader };
};

export const includesParentId = (collaborator, parentId) => (
  includes(collaborator?.parentId, parentId) || collaborator?.id === parentId);

export const getTabs = (t, unassignedList, inactiveLeaderList) => {
  const TABS = [
    { label: t("common:common.organization_chart"), isDisabled: false },
    { label: t("common:common.unassigned"), isDisabled: unassignedList.length === 0, tooltip: t("common:common.org_chart_tabs.tooltip_unassigned") },
    { label: t("common:common.inactive_leaders"), isDisabled: inactiveLeaderList.length === 0, tooltip: t("common:common.org_chart_tabs.tooltip_inactive") },
  ];
  return TABS;
};

/**
 * Function to convert the collaborators list to nodes and edges
 * @param {Array} nodesArray - Collaborators list
 * @param {Function} toggleChildrenVisibility - Function to toggle children visibility
 * @param {Function} handleOpenModal - Function to open modal
 * @returns {Object} - Object with nodes and edges
 */
export const convertToFlowNodesAndEdges = (nodesArray, toggleChildrenVisibility, handleOpenModal) => {
  const nodes = [];
  const edges = [];
  let currentX = (nodesArray.length * 120);
  const siblingSpacing = 240;

  nodesArray.forEach((item) => {
    const nodeId = item.id.toString();
    const x = currentX;
    const y = 0;
    const childrenWidth = (item.children.length * siblingSpacing) / 2;

    nodes.push({
      id: nodeId,
      type: "customNode",
      position: { x, y },
      data: {
        item: {
          avatar: item?.person.avatar || "",
          name: item?.person.name || "",
          title: item?.person.title || "",
          department: item?.person.department || "",
          country: item?.person.country || "",
          hasCriticalPosition: item?.person.hasCriticalPosition,
          isASuccessor: item?.person.isASuccessor,
        },
        parentId: item?.person.parentId || null,
        childrenCount: item?.children?.length || 0,
        childrenHidden: true,
        childrenWidth,
        onToggleChildren: () => toggleChildrenVisibility(nodeId),
        onOpenModal: () => handleOpenModal(item),
      },
    });

    currentX += siblingSpacing;
  });

  return { newNodes: nodes, newEdges: edges };
};

/**
 * Function to get the collaborators list formatted
 * @param {Array} actualCollaborators - Collaborators list
 * @param {Function} t - Function to translate
 * @returns {Object} - Object with mainTree and unassigned
 */
export const getFormattedData = (actualCollaborators, t, isChildNode = false) => {
  const data = orderByNumberOfChildren(actualCollaborators, t);
  const separatedList = getCEOandUnassigned(data, isChildNode);
  return separatedList;
};

/**
 * Function to adjust the zoom level of the chart
 * @param {Number} childrenWidth - Width of the children nodes
 * @returns {Number} - Zoom level
 */
export const adjustZoom = (childrenWidth) => {
  const defaultZoom = ZOOM.defaultOrgChart;
  const widthThreshold = 800; // Threshold to start zooming out
  let zoomLevel = defaultZoom;

  if (childrenWidth > widthThreshold) {
    // Zooms out as 'childrenWidth' increases
    zoomLevel = Math.max((defaultZoom - (
      ((childrenWidth - widthThreshold) / widthThreshold) * 0.1
    )) - 0.1, 0.2);
  }

  return zoomLevel;
};

/**
 * Function to get the new center and zoom of the chart
 * @param {Array} nodes - Collaborators list (nodes)
 * @returns {Object} - New center and zoom of the chart
 */
export const getNewCenter = (nodes) => {
  const rootNodes = nodes.filter((node) => node.data.parentId === "0");
  const rootNodesWidth = ((rootNodes.length * 240) + (rootNodes.length * 60) + 120);
  const newZoom = adjustZoom(rootNodesWidth);

  const newXCenter = rootNodes.length === 1
    ? (rootNodes[0].position.x + 120)
    : (rootNodes[rootNodes.length - 1].position.x / 2) + (rootNodes.length * 60);

  const newYCenter = newZoom >= ZOOM.defaultOrgChart
    ? rootNodes[0].position.y + 300
    : rootNodes[0].position.y + 420;

  return { x: newXCenter, y: newYCenter, zoom: newZoom };
};

/**
 * Recursive function to find a collaborator by ID and its ancestors if is a child
 * @param {String} searchedCollaborator - Id of the searched collaborator
 * @param {Array} ancestors - Ancestors of the searched collaborator
 * @param {Array} collaboratorsGroup - Collaborators list
 * @returns {Object} - Collaborator found
 */
export const recursiveSearch = (searchedCollaborator, ancestors, collaboratorsGroup) => {
  // If the collaborator not have ancestors, search it in the collaborators group
  if (ancestors.length === 0) {
    return collaboratorsGroup.find((c) => c.id.toString() === searchedCollaborator) || null;
  }

  // If the collaborator have ancestors, search start in the first ancestor
  // and remove the ancestor of the array from the first to the last
  const currentParentId = ancestors.shift();
  const parent = collaboratorsGroup.find((c) => c.id.toString() === currentParentId);

  // If the parent is found, search the collaborator in the children of the parent
  if (parent && parent.children) {
    return recursiveSearch(searchedCollaborator, ancestors, parent.children);
  }

  return recursiveSearch(searchedCollaborator, ancestors, collaboratorsGroup);
};

/**
 * Function to get the new position of nodes after expanding or collapsing a node
 * @param {String} selectedNodeId - Id of the selected node
 * @param {Array} allNodes - Collaborators list (nodes)
 * @param {Boolean} isExpanding - Flag to indicate if the node is expanding or collapsing
 * @returns {Array} - New collaborators list (nodes) with updated positions
 */
export const getNewNodePosition = (selectedNodeId, allNodes, isExpanding) => {
  const mainNode = allNodes.find((node) => node.id === selectedNodeId);
  const offset = isExpanding
    ? (mainNode.data.childrenWidth / 2) : -(mainNode.data.childrenWidth / 2);

  // Offset for the root nodes
  const rootOffset = isExpanding
    ? (offset / 4) + 120
    : -(
      (mainNode.data.childrenWidth / mainNode.data.childrenCount)
      + (mainNode.data.childrenCount * 10)
    );

  // Adjust the position of the node and its descendants
  const adjustNodePositions = (node, moveLeft, localNodes, customOffset = offset) => {
    node.position.x += moveLeft ? -customOffset : customOffset;

    // Adjust the position of the descendants
    const children = localNodes.filter((localNode) => localNode.data.parentId === node.id);
    children.forEach((child) => {
      adjustNodePositions(child, moveLeft, localNodes, customOffset);
    });
  };

  // Adjust the position of sibling nodes and their descendants
  const adjustSiblingPositions = (node, localNodes, useRootOffset = false) => {
    const siblings = localNodes.filter(
      (n) => n.data.parentId === node.data.parentId && n.id !== node.id
    );

    // Adjust the position of the siblings
    // If the sibling is on the left, move it to the left
    // If the sibling is on the right, move it to the right
    siblings.forEach((sibling) => {
      const siblingOffset = useRootOffset ? rootOffset : offset;
      const moveLeft = sibling.position.x < node.position.x;
      adjustNodePositions(sibling, moveLeft, localNodes, siblingOffset);
    });
  };

  adjustSiblingPositions(mainNode, allNodes);

  // Adjust the position of the parent node and move up the tree if necessary
  let currentNode = mainNode;
  const getParentNode = () => allNodes.find((n) => n.id === currentNode.data.parentId);

  // Adjust the position of the parent node while it has a parent
  // and the parent is not the root node
  while (currentNode && currentNode.data.parentId !== "0") {
    const parentNode = getParentNode();
    if (parentNode) {
      adjustSiblingPositions(parentNode, allNodes);
      currentNode = parentNode;
    } else {
      break;
    }
  }

  // Adjust nodes at the root if necessary
  // ParentId of the root nodes is always 0
  if (currentNode && currentNode.data.parentId === "0") {
    adjustSiblingPositions(currentNode, allNodes, true);
  }

  return allNodes;
};
