import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import palette from "theme/palette";

export const AccordionStyled = withStyles((theme) => ({
  root: {
    boxShadow: theme.palette.boxShadow.default,
    "& .MuiAccordionSummary-root.Mui-disabled": {
      opacity: 1,
    },
  },
  expanded: {
    margin: 0,
  },
}))(Accordion);

export const AccordionSummaryStyled = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    background-color: ${palette.background.lightPurple};
    border-radius: 4px;
  }
`;

export const AccordionDetailsStyled = withStyles((theme) => ({
  root: {
    border: 0,
    padding: 0,
    marginTop: "20px",
  },
}))(AccordionDetails);

export const useStyles = makeStyles((theme) => ({
  details: {
    color: theme.palette.text.darkPurple,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  icon: {
    color: theme.palette.text.link,
  },
  disabledIcon: {
    opacity: 0,
  },
}));
