import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";

export const StyledFormControlLabel = styled(FormControlLabel)`
  display: ${(props) => (props.isMobile ? "flex" : "inline-flex")};
`;

export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledHelpIcon = styled(HelpIcon)`
  width: 15px !important;
  height: 15px !important;
  position: absolute;
  margin-left: 5px;
`;
