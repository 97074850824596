import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TablePagination from "@mui/material/TablePagination";
import { ROWS_PER_PAGE } from "common/constants";
import { StyledScrollBarDual } from "styledComponents/ScrollBarDual";
import InputTextSearch from "components/InputTextSearch";
import TableHeader from "./components/TableHeader";
import TableBodyComponent from "./components/TableBody";
import TableLoader from "./components/TableLoader";
import { useTable } from "./hooks/useTable";
import { useCollapse } from "./hooks/useCollapse";
import {
  TopTableContainer,
  StyledPaper,
  StyledContainer,
  StyledTableContainer,
  StyledTable,
} from "./styles";

const TableGrid = ({
  rows,
  header,
  isLoading,
  paginationOptions,
  searchPlaceHolder,
  hasPagination,
  hasSearch,
  topContent,
  isMobile,
  maxHeight,
  isDisabledSearch,
  searchBy,
}) => {
  const { t } = useTranslation("tables");
  const {
    dataToShow,
    tableConfig,
    handleRequestSort,
    handleChangePage,
    handleSearch,
    handleChangeRowsPerPage,
  } = useTable({
    rows,
    paginationOptions,
    searchBy,
  });

  const { hiddenCell, toggleData } = useCollapse();

  return (
    <StyledContainer data-testid={ "table-grid-container" }>
      <TopTableContainer isMobile={ isMobile }>
        { hasSearch && !isLoading && (
          <InputTextSearch
            handleInput={ handleSearch }
            label={ searchPlaceHolder || t("tables:search") }
            defaultValue={ null }
            isDisabled={ isDisabledSearch }
          />
        )}
        { topContent }
      </TopTableContainer>
      <StyledPaper>
        <StyledTableContainer>
          <StyledScrollBarDual maxHeight={ maxHeight }>
            <StyledTable>
              <TableHeader
                headerOptions={ header }
                order={ tableConfig.order }
                orderBy={ tableConfig.orderBy }
                onRequestSort={ handleRequestSort }
                hiddenCell={ hiddenCell }
                toggleData={ toggleData }
              />
              {isLoading ? (
                <TableLoader cols={ header.length } />
              ) : (
                <TableBodyComponent
                  dataToShow={ dataToShow }
                  header={ header }
                  emptyRows={ tableConfig.emptyRows }
                  hiddenCell={ hiddenCell }
                />
              )}
            </StyledTable>
          </StyledScrollBarDual>
        </StyledTableContainer>
        { hasPagination && !isLoading && (
          <TablePagination
            rowsPerPageOptions={ paginationOptions?.rowsRange || ROWS_PER_PAGE }
            component={ "div" }
            count={ tableConfig.totalFilteredRows >= 0
              ? tableConfig.totalFilteredRows : rows?.length }
            rowsPerPage={ tableConfig.rowsPerPage }
            page={ tableConfig.page }
            onPageChange={ handleChangePage }
            onRowsPerPageChange={ handleChangeRowsPerPage }
            labelRowsPerPage={ paginationOptions?.rowText || t("tables:footer.rows") }
            labelDisplayedRows={ ({ from, to, count }) => `${from}-${to} ${t("tables:footer.of")} ${count}` }
          />
        )}
      </StyledPaper>
    </StyledContainer>
  );
};

TableGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  paginationOptions: PropTypes.object,
  searchPlaceHolder: PropTypes.string,
  hasPagination: PropTypes.bool,
  hasSearch: PropTypes.bool,
  topContent: PropTypes.node || PropTypes.string,
  isMobile: PropTypes.bool,
  maxHeight: PropTypes.string,
  isDisabledSearch: PropTypes.bool,
  searchBy: PropTypes.string,
};

TableGrid.defaultProps = {
  isLoading: false,
  paginationOptions: {},
  searchPlaceHolder: "",
  hasPagination: true,
  hasSearch: true,
  topContent: null,
  isMobile: false,
  maxHeight: "inherit",
  isDisabledSearch: false,
  searchBy: "name",
};

export default TableGrid;
