import styled from "styled-components";
import Button from "@mui/material/Button";
import palette from "theme/palette";

export const StyledButton = styled(Button)`
  border-radius: 100px;
  background: ${palette.white};
  padding: 0px 10px;
  margin: 5px 10px;
  font-size: 13px;
  border-color: ${palette.text.link};
  color: ${palette.text.link};
  text-transform: none;
  & input {
    display: none;
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    width: 0.7em;
    margin-right: 5px;
  }
`;
