import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import { ALIGN_ITEMS, TARGET, DIRECTION } from "common/constants";
import { StyledLinkEvaluation } from "../Evaluation/styles";
import { StyledAvatar } from "./styles";

const Member = (props) => {
  const { data, link, hasNalaForm } = props;

  const getAvatar = () => (data?.profile_img_url
    ? <StyledAvatar src={ data?.profile_img_url } />
    : <StyledAvatar>{ data?.full_name?.charAt(0) }</StyledAvatar>);

  const nameContent = link ? (
    <Link href={ link } target={ hasNalaForm ? TARGET.self : TARGET.blank }>
      <StyledLinkEvaluation>
        { `${data?.full_name} ` }
        <LaunchOutlined />
      </StyledLinkEvaluation>
    </Link>
  ) : (
    <div>
      {data?.full_name}
    </div>
  );

  return (
    <Grid container direction={ DIRECTION.row } alignItems={ ALIGN_ITEMS.center } spacing={ 1 }>
      <Grid item>
        { getAvatar() }
      </Grid>
      <Grid item>
        { nameContent }
      </Grid>
    </Grid>
  );
};

Member.propTypes = {
  data: PropTypes.object.isRequired,
  link: PropTypes.string,
  hasNalaForm: PropTypes.bool,
};

Member.defaultProps = {
  link: null,
  hasNalaForm: false,
};

export default Member;
