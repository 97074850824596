import styled from "styled-components";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  display: block;
  position: relative;
`;

export const StyledDate = styled.p`
  font-size: 10px;
  position: absolute;
  bottom: -8px;
  left: 34px;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  p{
    padding: 5px;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const StyledIcon = styled.div`
  background: ${palette.text.link};
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 5px;
  svg{
    path{
      fill: white;
    }
  }
`;

export const StyledDetail = styled.p`
  font-weight: 400;
  font-size: 14px;
  span {
    font-weight: bold;
  }
`;
