import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledGrid = styled(Grid)`
  margin-bottom: 16px;
`;

export const StyledScale = styled.div`
  width: 90px;
  padding: 4px;
  background: ${ (props) => props.color };
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
  border-radius: 50px;
  text-align: center;
`;

export const StyledTitle = styled.div`
  margin-left: 8px;
  font-size: 14px;
`;
