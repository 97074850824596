import { capitalizeFirstLetter } from "common/utils";
import {
  RESET_STATE,
  GET_OVERVIEW,
  GET_OVERVIEW_LOADING,
  GET_OVERVIEW_ERROR,
} from "../../actionTypes/nalaAnalytics";

const INITIAL_STATE = {
  companyOverview: null,
  isLoadingCompanyOverview: false,
  errorCompanyOverview: "",

  topTalentOverview: null,
  isLoadingTopTalentOverview: false,
  errorTopTalentOverview: "",

  newHiresOverview: null,
  isLoadingNewHiresOverview: false,
  errorNewHiresOverview: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  const label = `${action.label}Overview`;
  const capitalizedLabel = capitalizeFirstLetter(label);
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingCompanyOverview: false,
      errorCompanyOverview: null,
      companyOverview: null,

      isLoadingTopTalentOverview: false,
      errorTopTalentOverview: null,
      topTalentOverview: null,

      isLoadingNewHiresOverview: false,
      errorNewHiresOverview: null,
      newHiresOverview: null,
    };
  case GET_OVERVIEW:
    return {
      ...state,
      [label]: action.payload,
      [`isLoading${capitalizedLabel}`]: false,
      [`error${capitalizedLabel}`]: "",
    };
  case GET_OVERVIEW_LOADING:
    return {
      ...state,
      [`isLoading${capitalizedLabel}`]: true,
    };
  case GET_OVERVIEW_ERROR:
    return {
      ...state,
      [`isLoading${capitalizedLabel}`]: false,
      [`error${capitalizedLabel}`]: action.payload,
    };
  default:
    return state;
  }
};
