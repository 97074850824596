import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
} from "../actionTypes/personalityTypes";

const URL = {
  main: "/personalities",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getList = (personId) => async (dispatch, getState) => {
  const { list } = getState().personalityReducer;
  const query = {
    q: {
      person_id_eq: personId,
    },
  };
  if (!list) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main, {
        params: query,
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      dispatch({
        type: GET_LIST,
        payload: response.data.personalities,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};
