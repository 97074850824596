import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import emptyDataImage from "assets/images/icons/empty.svg";
import { ALIGN_ITEMS } from "common/constants";
import { StyledContainer, StyledMessage, StyledContent } from "./styles";

const NoData = (props) => {
  const {
    message,
  } = props;
  const { t } = useTranslation("common");

  return (
    <StyledContainer>
      <StyledContent>
        <Box
          display={ ALIGN_ITEMS.flex }
          justifyContent={ ALIGN_ITEMS.center }
        >
          <img
            src={ emptyDataImage }
            alt={ "No info to load" }
          />
        </Box>
        <StyledMessage>
          {message || t("common.no_results")}
        </StyledMessage>
      </StyledContent>
    </StyledContainer>
  );
};

NoData.propTypes = {
  message: PropTypes.string,
};

NoData.defaultProps = {
  message: null,
};

export default NoData;
