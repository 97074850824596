import { useContext } from "react";
import PropTypes from "prop-types";
import { SessionContext } from "modules/session/context";
import MatrixNineBoxView from "./MatrixNineBox.view";
import { useNineboxHook } from "./hooks/ninebox.hook";

const MatrixNineBoxController = (props) => {
  const { t, dispatch } = props;
  const {
    state: { user },
  } = useContext(SessionContext);

  const nineboxStates = useNineboxHook({ dispatch, t });

  return (
    <MatrixNineBoxView
      user={ user }
      { ...props }
      { ...nineboxStates }
    />
  );
};

MatrixNineBoxController.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default MatrixNineBoxController;
