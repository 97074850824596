import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InputCheckbox from "components/InputCheckbox";
import { getEvaluationType } from "../../../functions";
import { StyledToggleButton, StyledToggleButtonGroup } from "./styles";

const StakeholdersSection = (props) => {
  const {
    onEvaluationTypeChange,
    evaluationTypes,
    defaultValue,
    i18n,
    formLanguage,
    templateStates,
    section,
    control,
    setValue,
    formValues,
    setWatchedFields,
    updateEvaluations,
  } = props;
  const { stakeholders } = templateStates;
  const [evaluationType, setEvaluationType] = useState(defaultValue);
  const [enabledStakeholders, setEnabledStakeholders] = useState([]);

  // Set the stakeholders that are enabled by default and watch the checkboxes
  useEffect(() => {
    const sectionId = section.id;
    const newStakeholders = [];
    let newWatchedCheckboxes = [];
    let newWatchedWeighings = [];
    evaluationTypes.forEach((type) => {
      const actualKey = Object.keys(stakeholders)?.find(
        (key) => stakeholders[key].type === type,
      );
      const isChecked = stakeholders[actualKey]?.checked;
      const isFormChecked = formValues[`checkbox_${type}_${sectionId}`];
      const existEvaluation = section?.evaluations.find(
        (evaluation) => evaluation.type === type,
      );
      const shouldCheck = (isChecked && (isFormChecked || existEvaluation !== undefined)) ?? false;
      newStakeholders.push({ type, checked: shouldCheck });
      setValue(`checkbox_${type}_${sectionId}`, shouldCheck);
      newWatchedCheckboxes = [...newWatchedCheckboxes, `checkbox_${type}_${sectionId}`];
      newWatchedWeighings = [...newWatchedWeighings, `weighing_${type}_${sectionId}`];
    });

    setEnabledStakeholders(newStakeholders);
    // Add the checkboxes to the watched fields on load
    setWatchedFields((prev) => ({
      ...prev,
      stakeholders: [
        ...prev.stakeholders,
        ...newWatchedCheckboxes,
      ],
      weighings: [
        ...prev.weighings,
        ...newWatchedWeighings,
      ],
    }));
    // eslint-disable-next-line
  }, []);

  // Update the section stakeholders when the template stakeholders change
  useEffect(() => {
    const sectionId = section.id;
    evaluationTypes.forEach((type) => {
      const actualKey = Object.keys(stakeholders)?.find(
        (key) => stakeholders[key].type === type,
      );
      const isChecked = stakeholders[actualKey]?.checked;
      const isFormChecked = formValues[`checkbox_${type}_${sectionId}`];
      const shouldCheck = (isChecked && isFormChecked) ?? false;
      setEnabledStakeholders((prevStakeholders) => {
        // verify if the stakeholder is already in the list
        const existingStakeholderIndex = prevStakeholders.findIndex(
          (stakeholder) => stakeholder.type === type
        );

        if (existingStakeholderIndex !== -1) {
          const updatedStakeholders = [...prevStakeholders];
          updatedStakeholders[existingStakeholderIndex] = {
            ...updatedStakeholders[existingStakeholderIndex],
            checked: shouldCheck,
          };
          return updatedStakeholders;
        }
        return [...prevStakeholders, { type, checked: shouldCheck || false }];
      });
      setValue(`checkbox_${type}_${sectionId}`, shouldCheck);
    });
    // eslint-disable-next-line
  }, [stakeholders]);

  // Update the active evaluation type when the section stakeholders change
  const handleEvaluationType = (event, newType) => {
    if (newType !== null && newType !== undefined) {
      setEvaluationType(newType);
      onEvaluationTypeChange(newType);
    }
  };

  // Enable or disable the stakeholders when the checkbox is clicked
  const handleClickCheckbox = (event, type) => {
    event.stopPropagation();
    const checked = !!event.target.checked;

    setEnabledStakeholders((prevStakeholders) => {
      // verify if the stakeholder is already in the list
      const existingStakeholderIndex = prevStakeholders.findIndex(
        (stakeholder) => stakeholder.type === type
      );

      const updatedStakeholders = [...prevStakeholders];
      updatedStakeholders[existingStakeholderIndex] = {
        ...updatedStakeholders[existingStakeholderIndex],
        checked,
      };
      return updatedStakeholders;
    });

    updateEvaluations(type, checked);
    setValue(`weighing_${type}_${section.id}`, 0);
  };

  return (
    <StyledToggleButtonGroup
      value={ evaluationType }
      exclusive
      onChange={ handleEvaluationType }
      aria-label={ "evaluation-type" }
    >
      {evaluationTypes.map((type) => (
        <StyledToggleButton
          key={ type }
          value={ type }
          aria-label={ type.toLowerCase() }
          disabled={ !stakeholders[Object.keys(stakeholders)?.find(
            (key) => stakeholders[key].type === type,
          )]?.checked }
        >
          <InputCheckbox
            name={ `checkbox_${type}_${section.id}` }
            control={ control }
            onClick={ (e) => handleClickCheckbox(e, type) }
            isDefaultChecked={ enabledStakeholders.filter(
              (stakeholder) => stakeholder.type === type,
            )[0]?.checked }
          />
          {getEvaluationType(type, i18n)[formLanguage]}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

StakeholdersSection.propTypes = {
  onEvaluationTypeChange: PropTypes.func.isRequired,
  evaluationTypes: PropTypes.array.isRequired,
  defaultValue: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  formLanguage: PropTypes.string.isRequired,
  templateStates: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setWatchedFields: PropTypes.func.isRequired,
  updateEvaluations: PropTypes.func.isRequired,
};

export default StakeholdersSection;
