import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import palette, { STATE_COLORS } from "theme/palette";

export const StyledItem = styled(Grid)`
  border: 1px solid ${palette.tooltip.border};
  padding: 5px 15px;
  display: flex;
  align-items: center;
`;

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root{
    color: ${STATE_COLORS.ERROR}
  }
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${palette.text.link}
  }
`;
