import palette from "theme/palette";
import isEqual from "lodash/isEqual";
import {
  GRAPHIC_LABEL_OFFSET,
  GRAPHIC_TOP_PERCENTAGE,
} from "common/constants";
import { LINE_CHART } from "common/constants/charts";

export const options = {
  plugins: {
    datalabels: {
      display: true,
      formatter: (label) => `${label} %`,
      color: palette.text.subtitle,
      offset(context) {
        return isEqual(
          context.dataset.data[context.dataIndex],
          GRAPHIC_TOP_PERCENTAGE,
        )
          ? -GRAPHIC_LABEL_OFFSET
          : GRAPHIC_LABEL_OFFSET;
      },
      padding: -2,
      align(context) {
        return "top";
      },
    },
  },
  legend: { display: false },
  tooltips: { enabled: false },
  hover: { mode: null },
  scales: {
    yAxes: [
      {
        ticks: {
          fontSize: 10,
          fontFamily: "Roboto",
          color: palette.text.subtitle,
          max: 130,
          min: 0,
          padding: 15,
          maxRotation: 0,
          minRotation: 0,
          callback(label) {
            const value = label === LINE_CHART.axesLabel ? "" : `${ label }%`;
            return value;
          },
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 12,
          fontFamily: "Roboto",
          padding: 10,
          color: palette.black,
          maxRotation: 0,
          minRotation: 0,
          paddingLeft: 25,
        },
      },
    ],
  },
  responsive: true,
};
