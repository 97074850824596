import { calculatedTotalPercentage } from "../../../../functions";

const usePeriod = (t) => {
  const getStatus = (goals) => {
    let actualStatus = t("generalStatus.finished");
    const currentDate = new Date();

    goals.forEach((goal) => {
      const goalEndDate = new Date(goal.end_date);
      if (goalEndDate > currentDate) {
        actualStatus = t("generalStatus.inProgress");
      }
    });

    return actualStatus;
  };

  const getPeriodData = (targetGoals) => {
    const status = getStatus(targetGoals);
    const compliance = calculatedTotalPercentage(targetGoals);
    const totalGoals = targetGoals.length;
    return { status, compliance, totalGoals };
  };

  const getAllGoalsFromPeriod = (period) => {
    let allGoals = [];

    const extractGoals = (currentPeriod) => {
      if (currentPeriod.goals && currentPeriod.goals.length > 0) {
        allGoals = allGoals.concat(currentPeriod.goals);
      }

      if (currentPeriod.children && currentPeriod.children.length > 0) {
        currentPeriod.children.forEach((child) => extractGoals(child));
      }
    };

    extractGoals(period);
    return allGoals;
  };

  const getTargetGoals = (period) => (
    period.children !== null ? getAllGoalsFromPeriod(period) : period.goals);

  return {
    getTargetGoals,
    getPeriodData,
    getStatus,
  };
};

export default usePeriod;
