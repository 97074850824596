import styled from "styled-components";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "components/Button";
import IconButton from "@mui/material/IconButton";
import { STATE_COLORS } from "theme/palette";

export const StyledInputFile = styled(Box)`
  display: flex;
  justify-content: flex-end;
  .MuiFormControl-root {
    margin-top: 0
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 24px;
`;
export const StyledTableContainer = styled.div`
  margin-top: 24px;
`;

export const StyledUploadFileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButtonFile = styled(Button)`
  margin-top: 5px;
  margin-right: 10px;
`;

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root{
    color: ${STATE_COLORS.ERROR}
  }
`;

export const StyledUpload = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const StyledDialogItemActions = styled(DialogActions)`
  padding: 0;
  margin-top: 5px;
  button {
    width: 100%;
    line-height: 1.88;
  }
`;
