import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import moment from "moment";
import { FULLDATE_FORMATS } from "common/constants";
import { StyledContainer, StyledDateInputController } from "./styles";

const DateFilter = (props) => {
  const {
    startingDateHandler,
    endingDateHandler,
    isFullWidth,
    size,
    startingDate,
    endingDate,
  } = props;
  const { control, watch } = useForm();
  const { t } = useTranslation(["common"]);

  const fromDay = watch("from", "");

  const toDay = watch("to", "");

  useEffect(() => {
    if (fromDay !== "") {
      startingDateHandler(moment(fromDay).format(FULLDATE_FORMATS.dash_calendar));
    }
    if (toDay !== "") {
      endingDateHandler(moment(toDay).format(FULLDATE_FORMATS.dash_calendar));
    }
  }, [fromDay, toDay, control, startingDateHandler, endingDateHandler]);

  return (
    <StyledContainer>
      <StyledDateInputController
        customStyles={ isFullWidth ? null : "enhancedInputController" }
        control={ control }
        label={ t("common.from") }
        name={ "from" }
        fullWidth={ isFullWidth }
        required={ false }
        size={ size }
        value={ startingDate }
      />

      <StyledDateInputController
        customStyles={ isFullWidth ? null : "enhancedInputController" }
        control={ control }
        label={ t("common.to") }
        name={ "to" }
        fullWidth={ isFullWidth }
        required={ false }
        size={ size }
        value={ endingDate }
      />
    </StyledContainer>
  );
};

DateFilter.propTypes = {
  startingDateHandler: PropTypes.func,
  endingDateHandler: PropTypes.func,
  isFullWidth: PropTypes.bool,
  size: PropTypes.string,
  startingDate: PropTypes.object || PropTypes.string,
  endingDate: PropTypes.object || PropTypes.string,
};

DateFilter.defaultProps = {
  startingDateHandler: () => {},
  endingDateHandler: () => {},
  isFullWidth: false,
  size: "medium",
  startingDate: "",
  endingDate: "",
};

export default DateFilter;
