export default {
  root: "/profile",
  personalInfo: "/profile/personal_info",
  performance: "/profile/performance",
  skills: "/profile/skills",
  documents: "/profile/documents",
  goodLeader: "/profile/good_leader",
  employeeInfo: "/profile/employee_info",
  feedback: "/profile/feedback",
  addFeedback: "/profile/add_feedback",
  addAcknowledgment: "/profile/add_acknowledgment",
  salaryInfo: "/profile/salary_info",
  addMovement: "/profile/add_movement",
  lossRisk: "/profile/loss_risk",
  ninebox: "/profile/ninebox",
};
