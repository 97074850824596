import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SectionContainer from "components_refactor/SectionContainer";
import constants from "styledComponents/constants";
import FeedbackTabs from "./components/FeedbackTabs";
import Modals from "./components/Modals";
import { StyledTitle } from "./styles";

const FeesbackInfo = (props) => {
  const { t } = props;

  return (
    <SectionContainer>
      <Grid container>
        <Grid item xs={ 12 }>
          <StyledTitle weight={ constants.fontConstants.bold } isTitle>
            {t("profile:feedback")}
          </StyledTitle>
        </Grid>
        <Grid item xs={ 12 }>
          <FeedbackTabs { ...props } />
        </Grid>
      </Grid>
      <Modals { ...props } />
    </SectionContainer>
  );
};

FeesbackInfo.propTypes = {
  t: PropTypes.func.isRequired,
  feedbackStates: PropTypes.object.isRequired,
  handleFeedbackTab: PropTypes.func.isRequired,
  feedbackData: PropTypes.array,
  acknowledgementTypesList: PropTypes.array,
};

export default FeesbackInfo;
