import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stepper from "components/Stepper";
import _ from "lodash";
import PropTypes from "prop-types";
import SkeletonLoader from "components/SkeletonLoader";
import AlertModal from "components/AlertModal";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import { SKELETONS_NUMBER } from "common/constants";
import { isEmpty, isEqual, isNull } from "common/helpers";
import { update as updateCandidate } from "redux/actions/candidateActions";
import {
  addFile,
  addInfo,
  isInvalidAnswersRequired,
  isInvalidDocumentsRequired,
  handleUpload,
  getCompanyResourcesFormat,
} from "views/Preboarding/functions";
import CompanyData from "views/Preboarding/components/CompanyData";
import { resetCandidate } from "../../functions";
import LastStep from "./LastStep";
import AdditionalData from "./AdditionalData";
import DocumentForm from "./DocumentForm";
import { useStyles } from "./styles";

const AccountInductionTab = ({ user, from }) => {
  const { t } = useTranslation(["candidates", "common"]);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [sent, setSent] = useState(false);
  const [end, setEnd] = useState(false);
  const dispatch = useDispatch();
  const [companyResources, setCompanyResources] = useState([]);

  const {
    one: candidate,
    isLoadingOne: isLoadingCandidate,
    isLoadingProcess: isLoadingCandidateProcess,
    successProcess: successCandidateProcess,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const candidateReducer = useSelector((state) => state.candidateReducer);

  useEffect(() => {
    if (candidateReducer?.one?.requested_section_items
      && !isEmpty(candidateReducer?.one?.requested_section_items)) {
      setCompanyResources(
        getCompanyResourcesFormat(candidateReducer?.one?.requested_section_items),
      );
    }
  }, [candidateReducer.one]);

  const getFilesToSave = useCallback(() => {
    const filesToSave = [];
      candidate?.requested_documents
      && !_.isEmpty(candidate.requested_documents)
      && candidate.requested_documents.forEach((file) => {
        !_.isEmpty(file.filename)
          && filesToSave.push(addFile(file.id, file.filename, null));
      });
      return filesToSave;
  }, [candidate]);

  const getAnswersToSave = useCallback(() => {
    const answersToSave = {};
      candidate?.requested_infos
      && !_.isEmpty(candidate.requested_infos)
      && candidate.requested_infos.forEach((answer) => {
        if (!_.isEmpty(answer.answer)) {
          answersToSave[answer.id] = answer.answer;
        }
      });
      return Object(answersToSave);
  }, [candidate]);

  const [state, setState] = useState({ files: null });
  const [answers, setAnswers] = useState(null);

  useEffect(() => {
    setState({ files: getFilesToSave() });
    setAnswers(getAnswersToSave());
  }, [getFilesToSave, getAnswersToSave]);

  const updateFiles = (event, id) => {
    const file = event.target?.files[0];
    if (file) {
      const uploadFile = handleUpload(file, id, t);
      if (uploadFile) {
        setState((prevState) => {
          const updatedFiles = Object.values(prevState.files).filter((f) => f.id !== id);
          return {
            ...prevState,
            files: [...updatedFiles, { id, ...uploadFile }],
          };
        });
      }
    }
  };

  const handleChange = (prop, event) => {
    setAnswers({
      ...answers,
      [prop]: event.target.value,
    });
  };
  const isValidData = () => {
    let step = "";
    if (isInvalidAnswersRequired(candidate?.requested_infos, answers)) {
      step = t("preboarding:steps.additionalInfo");
    } if (isInvalidDocumentsRequired(candidate?.requested_documents, state.files)) {
      step = t("preboarding:steps.uploadDocuments");
    } if (companyResources?.find((item) => !item.has_confirmed && item.required)) {
      step = t("preboarding:steps.companyInfo");
    }
    if (!isEmpty(step)) {
      toast(MESSAGE_TYPES.warning, {
        title: t("preboarding:alert.noComplete.title"),
        message: `${t("preboarding:alert.noComplete.message")} "${step}"`,
      });
      setModal(false);
    }
    return isEmpty(step);
  };

  const openCloseModal = () => {
    if (isValidData()) {
      setModal(!modal);
    }
  };

  const sendData = () => {
    const answersList = [];
    const sectionsList = [];
    candidate.requested_infos.map((info) => answersList.push(addInfo(info.id, answers[info.id])));

    candidate.requested_section_items.map((info) => sectionsList.push({
      id: info.id,
      has_confirmed: companyResources.find((e) => isEqual(e.id, info.id))?.has_confirmed,
    }));

    state.files.forEach((file) => {
      if (!isNull(file.file)) {
        const formData = new FormData();
        formData.append("candidate[requested_documents_attributes]id", file.id);
        formData.append(
          "candidate[requested_documents_attributes]file",
          file.file,
        );
        dispatch(updateCandidate(formData, candidate.id));
      }
    });

    const values = {
      requested_infos_attributes: answersList,
      requested_section_items_attributes: sectionsList,
    };

    dispatch(updateCandidate(values, candidate.id));
    setEnd(true);
  };

  const steps = (candidate) => {
    const stepDetails = [];
    if (!isEmpty(candidate?.requested_documents)) {
      stepDetails.push({
        label: t("candidates:induction.personal_info"),
        content: (
          <DocumentForm
            data={ candidate.requested_documents }
            handleUpload={ updateFiles }
            state={ state }
            isCandidate={ isEmpty(from) }
          />
        ),
        disabled: isInvalidDocumentsRequired(candidate?.requested_documents, state.files),
        isLoading: isLoadingCandidate,
      });
    }
    if (candidate.requested_infos && !isEmpty(candidate.requested_infos)) {
      stepDetails.push({
        label: t("candidates:induction.additional_info"),
        content: (
          <AdditionalData
            data={ candidate.requested_infos }
            handleChange={ handleChange }
            answers={ answers }
          />
        ),
        disabled: isInvalidAnswersRequired(candidate?.requested_infos, answers),
        isLoading: isLoadingCandidate,
      });
    }
    if (!isEmpty(companyResources)) {
      stepDetails.push({
        label: t("preboarding:steps.companyInfo"),
        content: (
          <CompanyData
            data={ companyResources }
            setData={ setCompanyResources }
          />
        ),
        disabled: companyResources?.find((item) => !item.has_confirmed && item.required),
        isLoading: isLoadingCandidate,
      });
    }
    return stepDetails;
  };

  const lastStep = {
    label: t("candidates:induction.ready"),
    content: <LastStep startDate={ candidate?.starting_date } />,
  };

  const resetData = useCallback(() => {
    setModal(false);
    resetCandidate(dispatch, candidate.id);
    toast(
      MESSAGE_TYPES.success,
      handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
    );
  }, [t, dispatch, candidate]);

  useEffect(() => {
    if (successCandidateProcess && end) {
      resetData();
      setEnd(false);
      setSent(true);
    }
  }, [successCandidateProcess, resetData, end]);

  return (
    <Card data-testid={ "induction-tab" } className={ classes.root }>
      <CardContent className={ classes.content }>
        <AlertModal
          title={ t("candidates:alertMessage.title") }
          text={ t("candidates:alertMessage.send") }
          textDisagree={ t("candidates:alertMessage.cancel") }
          textAgree={ t("candidates:alertMessage.approved") }
          onClick={ () => {
            sendData();
          } }
          open={ modal }
          handleClose={ openCloseModal }
          isLoading={ isLoadingCandidateProcess }
        />
        <Typography variant={ "h5" } className={ classes.head }>
          {t("candidates:induction.title")}
        </Typography>
        {state && !isNull(state.files) && !isNull(answers) && candidate ? (
          <>
            <Typography variant={ "body1" } className={ classes.head }>
              {user
              && _.isEmpty(from)
              && `${t("candidates:induction.welcome")} ${
                candidate?.person?.full_name} ${t(
                  "candidates:induction.to",
                )} ${user.company.name}. ${t("candidates:induction.message")}`}
            </Typography>
            <Stepper
              steps={ steps(candidate) }
              onClick={ openCloseModal }
              end={ end }
              lastStep={ lastStep }
              sent={ sent }
            />
          </>
        ) : (
          <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } />
        )}
      </CardContent>
    </Card>
  );
};

AccountInductionTab.propTypes = {
  user: PropTypes.object,
  from: PropTypes.string,
};

export default AccountInductionTab;
