import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import TableComponent from "components/Table";
import { isNull, isEqual } from "common/helpers";
import { OBJECT_KEYS } from "common/constants";
import { getRowsDesktop, getParentId } from "../../functions";

const MainView = (props) => {
  const {
    moduleName, header, table, classes, actions, handleSearch,
  } = props;
  const { t } = useTranslation(["common"]);

  const getNewRowParentId = (willReturnString) => {
    const newElement = actions?.newIdElement;
    if (willReturnString) {
      return isNull(newElement) ? OBJECT_KEYS.null : newElement;
    }
    return isNull(newElement) ? actions?.isDisabled : true;
  };

  const getCollapseData = (item) => {
    const parentId = getParentId(actions, item);
    return (
      <TableComponent
        header={ table?.header }
        list={ item.children }
        customStyles={ classes.tableNested }
        getCollapseData={ getCollapseData }
        isSimpleTable
        isNotHeader
        isNotPagination
        paginationOptions={ {
          maxPerPage: 100,
        } }
        actions={ {
          ...actions,
          parentId,
          [`isNewRow_${parentId}`]: getNewRowParentId(false),
        } }
      />
    );
  };

  return (
    <div data-testid={ "general-administrator-main-view" }>
      {header && (
        <ViewHeaderTitle
          title={ header?.title }
          subtitle={ header?.subtitle }
          breadcrumb={ header?.breadcrumb }
        />
      )}
      {table?.header.head ? (
        <TablePaginationCollapse
          header={ table?.header.head }
          list={ table?.data }
          isLoading={ table?.isLoading }
          getRows={ {
            mobile: getRowsDesktop,
            desktop: getRowsDesktop,
          } }
          extraParam={ table?.header.actions }
          handleSearch={ handleSearch }
          actions={ {
            ...actions,
            [`isNewRow_${getNewRowParentId(true)}`]: actions?.isDisabled,
            isMainTable: true,
          } }
          modalOptions={ [getCollapseData, actions.module] }
          handlePagination={ table?.handlePagination }
          pagination={ table?.pagination }
          total={ table?.total }
          isDisabledSearch={ isEqual(moduleName, OBJECT_KEYS.integrations) }
        />
      ) : (
        <div className={ classes?.tableContainer }>
          <TableComponent
            id={ `general-administrator-table-${moduleName}` }
            header={ table?.header }
            list={ table?.data }
            searchPlaceHolder={ t("common:common.search") }
            searchBy={ table?.search }
            customStyles={ classes?.tableContent }
            isLoading={ table?.isLoading }
            getCollapseData={ getCollapseData }
            isNotPagination
            isDragable
            actions={ {
              ...actions,
              [`isNewRow_${getNewRowParentId(true)}`]: actions?.isDisabled,
              isMainTable: true,
            } }
          />
        </div>
      )}
    </div>
  );
};

MainView.propTypes = {
  moduleName: PropTypes.string,
  header: PropTypes.object,
  table: PropTypes.object,
  classes: PropTypes.object,
  actions: PropTypes.object,
  handleSearch: PropTypes.func,
};
export default MainView;
