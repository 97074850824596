import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  padding: 32px;
  @media (max-width: 600px) {
    padding: 16px 12px;
    font-family: "Lato";
    background: ${ palette.white };
    min-height: 100%;
  }
`;

export const StyledCard = styled.div`
  background: ${ palette.white };
  padding: 28px;
  border-radius: 5px;
  .MuiTypography-h5 {
    padding: 10px 0;
  }
  @media (max-width: 600px) {
    .MuiTypography-h5 {
      padding: 0px;
      font-family: "Lato";
    }
    padding: 0px;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  align-items: center;
  .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
    color: ${(props) => props.color || palette.background.gray};
    margin-right: 8px;
  }
`;

export const StyledItem = styled.div`
  padding-right: 50px;
  .MuiTypography-body1 {
    font-size: 16px;
    color: ${palette.input.borderControl}
  }
`;

export const StyledGrid = styled(Grid)`
  &.MuiGrid-container {
    padding: 35px 35px 40px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    &.MuiGrid-container {
      padding: 20px 0;
    }
  }
`;

export const StyledEvaluationTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  .MuiTypography-h3 {
    font-weight: 700;
    font-size: 26px;
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
`;
