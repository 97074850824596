import { getCurrentLanguage } from "common/utils";

export const percentages = {
  1: [120, 50],
  2: [120, 90, 50],
  3: [120, 90, 70, 40],
  4: [120, 96, 75, 48, 24],
  5: [120, 108, 96, 72, 60, 24],
  6: [120, 108, 96, 84, 72, 36, 24],
  7: [120, 108, 96, 84, 72, 60, 36, 24],
  8: [120, 108, 96, 84, 72, 60, 48, 24, 12],
  9: [120, 108, 96, 84, 72, 60, 48, 36, 24, 12],
  10: [120, 110, 100, 90, 80, 70, 60, 50, 40, 30, 20],
  11: [120, 110, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
};

export const getCustomTextTranslations = (i18n, date) => {
  const getT = (lang) => i18n.getFixedT(lang);

  const customTextTranslations = {
    en: "",
    es: "",
    pt: "",
  };

  Object.keys(customTextTranslations).forEach((lang) => {
    const t = getT(lang);
    customTextTranslations[lang] = t("planning:templates.evaluation_scales.customText", { date });
  });

  return customTextTranslations;
};

const findCompleteLanguage = (formData, numberOfOptions, languageCodes, currentLanguage) => languageCodes.find((langCode) => formData[`scale_name_${langCode}`]
    && Array.from({ length: numberOfOptions }, (_, i) => formData[`${i + 1}_option_${langCode}`])
      .every((value) => value)) || currentLanguage;

export const transformFormData = (formData, numberOfOptions) => {
  const languageCodes = ["es", "en", "pt"];
  const currentLanguage = getCurrentLanguage();
  const referenceLanguage = findCompleteLanguage(
    formData,
    numberOfOptions,
    languageCodes,
    currentLanguage,
  );
  const fallbackName = languageCodes.find((langCode) => formData[`scale_name_${langCode}`]) || currentLanguage;

  const transformedData = {
    evaluation_scale: {
      name_es: formData.scale_name_es || formData[`scale_name_${fallbackName}`],
      name_en: formData.scale_name_en || formData[`scale_name_${fallbackName}`],
      name_pt: formData.scale_name_pt || formData[`scale_name_${fallbackName}`],
      description_es: formData.description_es,
      description_en: formData.description_en,
      description_pt: formData.description_pt,
      evaluation_scale_indices_attributes: Array.from({ length: numberOfOptions }).map((_, i) => {
        const answers = languageCodes.reduce((acc, langCode) => {
          acc[langCode] = formData[`${i + 1}_option_${langCode}`] || formData[`${i + 1}_option_${referenceLanguage}`];
          return acc;
        }, {});

        return {
          answer_es: answers.es,
          answer_en: answers.en,
          answer_pt: answers.pt,
          value: formData[`${i + 1}_percentage`] / 100,
        };
      }),
    },
  };

  return transformedData;
};

export const isAtLeastOneLanguageComplete = (formData, numberOfOptions, languageCodes) => languageCodes.some((langCode) => {
  const isNamePresent = !!formData[`scale_name_${langCode}`];
  const areAllOptionsPresent = Array.from({ length: numberOfOptions }, (_, i) => formData[`${i + 1}_option_${langCode}`]).every((value) => value);

  return isNamePresent && areAllOptionsPresent;
});
