export const RESET_STATE_RECEIVED = "RESET_STATE_RECEIVED_FEEDBACK";
export const GET_LIST_RECEIVED = "GET_LIST_RECEIVED_FEEDBACK";
export const GET_LIST_LOADING_RECEIVED = "GET_LIST_LOADING_RECEIVED_FEEDBACK";
export const GET_LIST_ERROR_RECEIVED = "GET_LIST_ERROR_RECEIVED_FEEDBACK";

export const RESET_STATE_SENT = "RESET_STATE_SENT_FEEDBACK";
export const GET_LIST_SENT = "GET_LIST_SENT_FEEDBACK";
export const GET_LIST_LOADING_SENT = "GET_LIST_LOADING_SENT_FEEDBACK";
export const GET_LIST_ERROR_SENT = "GET_LIST_ERROR_SENT_FEEDBACK";

export const RESET_STATE_PROCESS_FEEDBACK = "RESET_STATE_PROCESS_FEEDBACK";
export const PROCESS_FEEDBACK = "PROCESS_FEEDBACK";
export const PROCESS_FEEDBACK_LOADING = "PROCESS_FEEDBACK_LOADING";
export const PROCESS_FEEDBACK_ERROR = "PROCESS_FEEDBACK_ERROR";

export const RESET_STATE_FEEDBACKS_TO_DOWNLOAD = "RESET_STATE_FEEDBACKS_TO_DOWNLOAD";
export const GET_LIST_FEEDBACKS_TO_DOWNLOAD = "GET_LIST_FEEDBACKS_TO_DOWNLOAD";
export const GET_LIST_FEEDBACKS_TO_DOWNLOAD_LOADING = "GET_LIST_FEEDBACKS_TO_DOWNLOAD_LOADING";
export const GET_LIST_FEEDBACKS_TO_DOWNLOAD_ERROR = "GET_LIST_FEEDBACKS_TO_DOWNLOAD_ERROR";
