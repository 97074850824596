import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
import { VARIANT } from "common/constants";
import CollapsibleRow from "../CollapsibleRow/CollapsibleRow";
import { calculatedTotalPercentage } from "../../../../functions";
import usePeriod from "./usePeriod";

const Period = (props) => {
  const {
    period,
    rowId,
    showTableContent,
    open,
    toggleOpen,
  } = props;

  const { t } = useTranslation("goals");
  const {
    getTargetGoals,
    getPeriodData,
    getStatus,
  } = usePeriod(t);
  const targetGoals = getTargetGoals(period);
  const { status, compliance, totalGoals } = getPeriodData(targetGoals);

  // render the table with the period or subperiod data
  const renderTableContent = (goals, id) => (
    <Collapse in={ open[id] } timeout={ VARIANT.auto } unmountOnExit>
      {showTableContent(goals)}
    </Collapse>
  );

  return (
    <div key={ period.id }>
      {/* render the period collapsible row */}
      {period.children === null
        && (
          <CollapsibleRow
            id={ rowId }
            label={ period.label }
            status={ status }
            goalsCount={ totalGoals }
            progress={ Number(compliance) }
            open={ open }
            toggleOpen={ toggleOpen }
          />
        )}
      {/* render the table with the period data */}
      {period.goals && renderTableContent(period.goals, rowId)}
      {/* render the subperiods if period has children */}
      {period.children && (
        period.children.map((subPeriod) => (
          <div key={ subPeriod.id }>
            <CollapsibleRow
              id={ `${rowId}-${subPeriod.id}` }
              label={ subPeriod.label }
              status={ getStatus(subPeriod.goals) }
              goalsCount={ subPeriod.goals.length }
              progress={ Number(calculatedTotalPercentage(subPeriod.goals)) }
              open={ open }
              toggleOpen={ toggleOpen }
            />
            {renderTableContent(subPeriod.goals, `${rowId}-${subPeriod.id}`)}
          </div>
        ))
      )}
    </div>
  );
};

Period.propTypes = {
  period: PropTypes.object.isRequired,
  rowId: PropTypes.string.isRequired,
  showTableContent: PropTypes.func.isRequired,
  open: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default Period;
