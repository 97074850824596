import { INDEX } from "common/constants";
import { formatDate } from "common/utils";

const CALENDAR_CLASSES = {
  startDate: {
    active: "day-start",
    border: "start-border",
  },
  endDate: {
    active: "day-end",
    border: "end-border",
  },
  dayRoute: {
    active: "day-route",
  },
  activeDate: "active-date",
};

export const customData = (e, dataRender, events) => {
  const currentDate = formatDate(dataRender);
  const currentData = events[INDEX.zero];
  const currentStartDate = formatDate(currentData.startDate);
  const currentEndDate = formatDate(currentData.endDate);

  if (currentDate === currentStartDate) {
    e.parentElement.classList.add(CALENDAR_CLASSES.startDate.border);
    e.classList.add(CALENDAR_CLASSES.startDate.active);
  } else if (currentDate === currentEndDate) {
    e.parentElement.classList.add(CALENDAR_CLASSES.endDate.border);
    e.classList.add(CALENDAR_CLASSES.endDate.active);
  } else {
    e.parentElement.classList.add(CALENDAR_CLASSES.dayRoute.active);
  }
};

export const customDate = (e, date, currentDate) => {
  if (date.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)) {
    e.parentElement.classList.add(CALENDAR_CLASSES.activeDate);
  }
};
