import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledToggleButton = styled(ToggleButton)`
  width: 70px;
  height: 30px;
  border: 1px solid ${palette.table.blueLight};
  &.Mui-selected, &.Mui-selected:hover {
    background-color: ${palette.background.lightBlue};
    color: ${palette.primaryApp};
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  justify-content: flex-end;
`;
