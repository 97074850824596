import Module from "./modules";

const ModuleView = (props) => {
  const { moduleList, moduleToShow } = props;
  let finalModuleList = moduleList || [];

  if (moduleToShow) {
    finalModuleList = finalModuleList.filter((item) => {
      return moduleToShow.includes(item);
    });
  }

  return (
    <>
      {finalModuleList.map((module, index) => {
        return <Module key={module + index} module={module} {...props} />;
      })}
    </>
  );
};

export default ModuleView;
