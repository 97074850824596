import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "components/Button";
import palette from "theme/palette";

export const StyledCardContainer = styled.div`
  margin-top: 10px;
  @media (max-width: 600px) {
    padding: 10px 0;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  &.MuiPaper-root {
    background: ${(props) => (props.color || palette.background.blueAction)};
  }
  svg {
    color: ${palette.text.blueAction};
    margin-right: 5px;
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 24px 16px 16px 16px !important;
`;

export const StyledIcon = styled.img`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  margin-right: 5px;
`;

export const StyledText = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${palette.text.blueAction};
    font-weight: ${(props) => (props.isBold ? "bold" : "normal") };
    line-height: ${(props) => `${props.fontSize + 5}px`};
    padding: ${(props) => !props.isTitle && "10px 0"}
  }
`;

export const StyledButton = styled(Button)`
  float: right;
  background: ${(props) => (props.isSecondary ? palette.background.newButton : palette.primaryApp)};
  margin-left: 10px;
`;
