import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import SkeletonLoader from "components/SkeletonLoader";
import {
  BUTTON_STYLE_TYPES,
  OBJECT_KEYS,
  ONBOARDING_KEYS,
  TYPES,
} from "common/constants";
import { isNull, orderByAsc } from "common/helpers";
import useComplexState from "hooks/utils/useComplexState";
import titleIcon from "assets/images/onboarding/title.svg";
import documentsIcon from "assets/images/onboarding/document.svg";
import additionalInfoIcon from "assets/images/onboarding/aditional_info.svg";
import {
  create as createTemplate,
  resetStateOne as resetStateOneTemplate,
  update as updateTemplate,
} from "redux/actions/template/templateActions";
import {
  getList as getDocumentTypes,
  create as createDocumentType,
  resetStateProcess as resetStateDocumentType,
} from "redux/actions/documentTypeActions";
import {
  getList as getInfoTypes,
  create as createInfoType,
  resetStateProcess as resetStateInfoType,
} from "redux/actions/infoTypeActions";
import DocumentsList from "views/Onboarding/components/DocumentsList";
import CardSection from "views/Onboarding/components/CardSection";
import { getActionByAttributes, resetFormData } from "views/Templates/functions";
import { getDocumentsInitFormat } from "views/Account/functions/documents";
import { StyledTypography } from "views/Templates/styles";
import SectionItems from "./SectionItems";
import {
  StyledDialogTitle,
  StyledDialogActions,
} from "./styles";

const TemplateModal = (props) => {
  const {
    data,
    isOpen,
    onClose,
    setDocs,
    setInfo,
    setSectionItems,
  } = props;
  const { t } = useTranslation(["templates", "Onboarding", "candidates", "common"]);
  const [docs, setModalDocs] = useState([]);
  const [info, setModalInfo] = useState([]);
  const [sectionItem, setModalSectionItem] = useState([]);
  const [templateModalStates, setTemplateModalStates] = useComplexState({
    defaultDocs: [],
    defaultInfo: [],
    defaultSectionItem: [],
  });
  const dispatch = useDispatch();

  const {
    list: documentTypesList,
  } = useSelector(({ documentTypesReducer }) => documentTypesReducer);

  const {
    list: infoTypesList,
  } = useSelector(({ infoTypesReducer }) => infoTypesReducer);

  const {
    list: sectionList,
    isLoadingList: isLoadingSectionList,
  } = useSelector(({ sectionReducer }) => sectionReducer);

  const {
    control, reset, handleSubmit,
  } = useForm({
    defaultValues: {
      "onboarding_template.title": "",
      "onboarding_template.description": "",
    },
  });

  useEffect(() => {
    dispatch(getDocumentTypes());
    dispatch(getInfoTypes());
  }, [dispatch, data]);

  const resetForm = useCallback(() => {
    const editData = resetFormData(data);
    if (editData) reset(editData);
    const sectionItemsByOrder = orderByAsc(data?.onboarding_template_section_items, OBJECT_KEYS.order);
    const documentInitData = getDocumentsInitFormat(data?.onboarding_template_documents, ONBOARDING_KEYS.documentTypeId);
    const infoInitData = getDocumentsInitFormat(data?.onboarding_template_infos, ONBOARDING_KEYS.infoTypeId);
    const sectionItemInitData = getDocumentsInitFormat(sectionItemsByOrder, ONBOARDING_KEYS.sectionItemId);

    setTemplateModalStates({ defaultDocs: documentInitData });
    setTemplateModalStates({ defaultInfo: infoInitData });
    setTemplateModalStates({ defaultSectionItem: sectionItemInitData });
    // eslint-disable-next-line
  }, [reset, data]);

  useEffect(() => {
    setDocs(docs);
    setInfo(info);
    setSectionItems(sectionItem);
  }, [docs, info, sectionItem, setDocs, setInfo, setSectionItems]);

  useEffect(() => {
    if (isNull(data)) {
      dispatch(resetStateOneTemplate());
    }
    resetForm();
  }, [data, resetForm, dispatch]);

  const handleActionAdd = (isDocument, newData) => {
    if (isDocument) {
      dispatch(resetStateDocumentType());
      return dispatch(createDocumentType(newData));
    }
    dispatch(resetStateInfoType());
    return dispatch(createInfoType(newData));
  };

  const onSubmit = async () => {
    const templateData = control.getValues();
    templateData.onboarding_template.onboarding_template_documents_attributes = getActionByAttributes(
      data?.onboarding_template_documents || [],
      docs,
      ONBOARDING_KEYS.documentTypeId,
    );
    templateData.onboarding_template.onboarding_template_infos_attributes = getActionByAttributes(
      data?.onboarding_template_infos || [],
      info,
      ONBOARDING_KEYS.infoTypeId,
    );
    templateData.onboarding_template.onboarding_template_section_items_attributes = getActionByAttributes(
      data?.onboarding_template_section_items || [],
      sectionItem,
      ONBOARDING_KEYS.sectionItemId,
    );

    if (data) {
      dispatch(updateTemplate(templateData, data.id));
    } else {
      dispatch(createTemplate(templateData));
    }

    onClose(false);
  };

  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      maxWidth={ "md" }
      fullWidth
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <StyledDialogTitle disableTypography>
          <h2>{ t("actions.show") }</h2>
          <IconButton onClick={ onClose }>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent>
          <CardSection
            title={ t("template_title.title") }
            icon={ titleIcon }
            noPadding
          >
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } md={ 6 }>
                <InputTextController
                  type={ TYPES.text }
                  label={ t("template_title.label") }
                  control={ control }
                  rules={ { required: true } }
                  name={ "onboarding_template.title" }
                  required
                />
              </Grid>
            </Grid>
          </CardSection>

          <CardSection
            title={ t("required_documents.title") }
            icon={ documentsIcon }
            noPadding
          >
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <StyledTypography>{ t("required_documents.description") }</StyledTypography>
                <DocumentsList
                  id={ OBJECT_KEYS.documentType }
                  objectLabels={ {
                    select: t("required_documents.select"),
                    add: t("required_documents.add"),
                  } }
                  setValue={ setModalDocs }
                  data={ documentTypesList }
                  handleAdd={ handleActionAdd }
                  defaultValues={ templateModalStates.defaultDocs }
                  isDocument
                />
              </Grid>
            </Grid>
          </CardSection>
          <CardSection
            title={ t("additional_information.title") }
            icon={ additionalInfoIcon }
            noPadding
          >
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <StyledTypography>{ t("additional_information.description") }</StyledTypography>
                <DocumentsList
                  id={ OBJECT_KEYS.infoType }
                  objectLabels={ {
                    select: t("additional_information.select"),
                    add: t("additional_information.add"),
                  } }
                  setValue={ setModalInfo }
                  data={ infoTypesList }
                  handleAdd={ handleActionAdd }
                  defaultValues={ templateModalStates.defaultInfo }
                  isDocument={ false }
                />
              </Grid>
            </Grid>
          </CardSection>
          { isLoadingSectionList ? <SkeletonLoader /> : sectionList?.map((section) => (
            <SectionItems
              key={ section.id }
              data={ section }
              setValue={ setModalSectionItem }
              defaultValues={ templateModalStates.defaultSectionItem }
            />
          ))}
        </DialogContent>
        <StyledDialogActions>
          <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ onClose }>
            { t("Onboarding:cancelButton") }
          </Button>
          <Button
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            type={ BUTTON_STYLE_TYPES.SUBMIT }
          >
            { t("select_template") }
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

TemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setDocs: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setSectionItems: PropTypes.func.isRequired,
  data: PropTypes.object,
};

TemplateModal.defaultProps = {
  data: null,
};

export default TemplateModal;
