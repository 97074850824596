import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import { TABLE } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  addNew: {
    background: TABLE.ODD,
    color: theme.palette.black,
    fontSize: 12,
    "&:hover": {
      cursor: "pointer",
    },
    padding: "10px",
  },
}));

export const StyledIconRemove = styled.div`
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
`;
