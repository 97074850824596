import PropTypes from "prop-types";
import palette from "theme/palette";
import charts from "theme/charts";
import { StyledTooltip, StyledIcon } from "./styles";

const CustomChartTooltip = (props) => {
  const {
    children, bgcolor, color, border, shadow
  } = props;
  return (
    <StyledTooltip
      data-testid={ "custom-chart-tooltip-component" }
      title={ children }
      componentsProps={ {
        tooltip: {
          sx: {
            bgcolor,
            color,
            border,
            boxShadow: shadow,
          },
        },
      } }
      arrow
    >
      <StyledIcon />
    </StyledTooltip>
  );
};

CustomChartTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  shadow: PropTypes.string,
};

CustomChartTooltip.defaultProps = {
  bgcolor: palette.white,
  color: palette.text.secondaryTitle,
  border: `1px solid ${palette.tooltip.border}`,
  shadow: charts.boxShadow.standar,
};

export default CustomChartTooltip;
