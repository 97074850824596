import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "components/Select";
import NoDataMessage from "components/NoDataMessage";
import { OBJECT_KEYS, VARIANT } from "common/constants";
import { getResultsBySection } from "views_refactor/Profile/functions/skills";
import Tips from "assets/images/potential/skills/tips.svg";
import SkillCard from "../SkillCard";
import { StyledPotentialDescription } from "../../styles";

const BadgeRecommendation = (props) => {
  const {
    t,
    isOwnProfile,
    skillStates,
    handlePotentialChange,
    resultScales,
  } = props;
  const { resultScaleList, isLoadingScaleList } = resultScales;
  const resultScaleId = skillStates?.userSurveyResult?.survey_process?.result_scale_id || null;
  let resultScaleData = null;
  if (resultScaleList.length > 0 && resultScaleId !== null && !isLoadingScaleList) {
    resultScaleData = resultScaleList.find((scale) => scale.id === resultScaleId);
  }

  const completeResultsBySection = skillStates?.userSurveyResult?.complete_results_by_section || [];
  const enableTip = completeResultsBySection?.filter((item) => item.enableTip);

  const getSkillCards = completeResultsBySection.length > 0
    ? getResultsBySection(t, completeResultsBySection, isOwnProfile)?.map((item) => (
      <SkillCard
        key={ item.title }
        title={ item.title }
        icon={ item.icon }
        skills={ item.skills }
        resultScale={ resultScaleData }
      />
    )) : <NoDataMessage />;

  return (
    <>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 8 }>
          <Typography variant={ VARIANT.h4 }>
            {t("profile:modals.skills.skills.title")}
          </Typography>
          <StyledPotentialDescription variant={ VARIANT.bodyOne }>
            { t(`profile:modals.skills.skills.introduction${isOwnProfile ? "" : "ForManager"}`) }
          </StyledPotentialDescription>
        </Grid>
        <Grid item xs={ 12 } sm={ 4 }>
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("performance:dashboard.filter_evaluation") }
            menuItems={ skillStates.evaluationList }
            value={ skillStates.evaluationSelected || "" }
            onChange={ handlePotentialChange }
            className={ "custom-select" }
            disabled={ skillStates.evaluationList?.length === 0 }
          />
        </Grid>
      </Grid>
      { getSkillCards }
      { enableTip?.length > 0 && (
        <SkillCard
          title={ t(`profile:modals.skills.skills.categories.tips${ isOwnProfile ? "" : "ForManager" }`) }
          icon={ Tips }
          tips={ completeResultsBySection?.filter((item) => item.enableTip) }
        />
      )}
    </>
  );
};

BadgeRecommendation.propTypes = {
  t: PropTypes.func.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  skillStates: PropTypes.object.isRequired,
  handlePotentialChange: PropTypes.func.isRequired,
  resultScales: PropTypes.array.isRequired,
};

export default BadgeRecommendation;
