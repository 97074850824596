/**
 * GridItems - A component extensible for displaying a grid of items
 * with search and item activation functionality.
 */
import PropTypes from "prop-types";
import InputTextSearch from "components/InputTextSearch";
import BuildComponent from "./components/BuildComponent";
import { useDataGrid } from "./hooks/useDataGrid";
import { StyledContainer, StyledGrid } from "./styles";

const GridItems = ({
  typeElement,
  items,
  formattedFields,
  withSearch,
  isVertical,
  gap,
}) => {
  const {
    itemsToShow, activeItem, toggleActiveItem, handleSearch,
  } = useDataGrid(items);

  return (
    <StyledContainer data-testid={ "grid-container" }>
      {withSearch && (
        <InputTextSearch
          handleInput={ handleSearch }
          defaultValue={ null }
        />
      )}
      <StyledGrid isVertical={ isVertical } gap={ gap } data-testid={ "grid-items" }>
        {itemsToShow.map((item) => (
          <BuildComponent
            key={ item.id }
            element={ item }
            typeElement={ typeElement }
            formattedFields={ formattedFields }
            activeItem={ activeItem }
            handleActiveItem={ toggleActiveItem }
          />
        ))}
      </StyledGrid>
    </StyledContainer>
  );
};

GridItems.propTypes = {
  typeElement: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  formattedFields: PropTypes.array,
  withSearch: PropTypes.bool,
  isVertical: PropTypes.bool,
  gap: PropTypes.number,
};

GridItems.defaultProps = {
  formattedFields: [],
  withSearch: false,
  isVertical: false,
  gap: null,
};

export default GridItems;
