import isEqual from "lodash/isEqual";
import remove from "lodash/remove";
import isEmpty from "lodash/isEmpty";
import hasIn from "lodash/hasIn";
import isNull from "lodash/isNull";
import includes from "lodash/includes";
import { getItemFromLocalStorage } from "common/utils";
import { LOCAL_STORAGE_NAMES, OBJECT_KEYS } from "common/constants";
import { buildNewOptionArray } from "views/GeneralAdministrator/functions/units";
import { getAsyncDispatch, UNITS } from "views/GeneralAdministrator/functions";

export const getRelocatedCollaborators = (
  managerCollaborators,
  collaboratorsSelected,
  newManager,
) => {
  let collaborators = [];
  collaborators = managerCollaborators.filter((collaborator) => collaboratorsSelected.find((id) => {
    if (newManager) {
      collaborator.manager_name = newManager;
    }
    return isEqual(collaborator.id, id);
  }));

  return collaborators;
};

export const getAvailableCollaborators = (
  fullList,
  mainList,
  isObject,
  currentEmployeeId,
) => {
  remove(fullList, (collaborator) => mainList.find((element) => (
    isEqual(collaborator.id, isObject ? element.id : element)
        || isEqual(collaborator.id, currentEmployeeId)
  )));
  return fullList;
};

export const removeFromRelocated = (temporalArray, itemsToRemove, employeeKey) => {
  const relocated = [];
  temporalArray.forEach((collaborator) => {
    remove(collaborator[employeeKey], (collabId) => itemsToRemove.find((id) => isEqual(collabId, id)));

    !isEmpty(collaborator[employeeKey]) && relocated.push(collaborator);
  });
  return relocated;
};

export const isButtonDisabled = (itemsSelected, values, findBy) => (
  isEmpty(itemsSelected)
    || !hasIn(values, findBy.main)
    || isNull(values[findBy.main])
);

export const getManagerSelected = (collaborators, managerId) => collaborators.find((item) => isEqual(item.id, managerId));

export const getAdditionalInfoRow = async (dispatch, additionalInfo, dataRelocated, employeeKey) => {
  const { data, objKey } = additionalInfo;
  const aux = dataRelocated;

  const getLocalstorageName = () => { // internal validation
    let localName;
    switch (objKey.key) {
    case OBJECT_KEYS.unit:
      localName = LOCAL_STORAGE_NAMES.orgUnits;
      break;
    case OBJECT_KEYS.position:
      localName = LOCAL_STORAGE_NAMES.positions;
      break;
    default:
      break;
    }
    return localName;
  };

  const organizationUnits = getItemFromLocalStorage(getLocalstorageName());
  let allOrganizationUnits = organizationUnits;
  if (isEmpty(allOrganizationUnits)) {
    allOrganizationUnits = await getAsyncDispatch(dispatch, UNITS);
  }

  const organizationUnitsfullname = objKey.key === OBJECT_KEYS.unit ? buildNewOptionArray(allOrganizationUnits) : allOrganizationUnits;

  return data?.forEach((collabElement) => {
    const itemOrgUnitName = organizationUnitsfullname.find((orgUnit) => collabElement.id === orgUnit.id);
    aux.map((item) => {
      if (includes(collabElement[employeeKey], item.id)) {
        item[objKey.key] = itemOrgUnitName[objKey.name];
      }
      return item;
    });
  });
};
