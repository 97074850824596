import styled from "styled-components";

export const StyledRange = styled.div`
  line-height: 30px;
  padding: 0 10;
  height: 30px;
  min-width: calc(100%/6.3);
  background-color: ${ (props) => props.color };
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-right: 3px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  @media (max-width: 1120px) {
    font-size: 12px;
    padding: 0;
  }
  @media (max-width: 960px) {
    min-width: calc(100%/6.1);
  }
  @media (max-width: 541px) {
    font-size: 10px;
    padding: 0;
    min-width: calc(100%/6.3);
  }
  @media (max-width: 420px) {
    font-size: 8px;
  }
  @media (max-width: 300px) {
    font-size: 6px;
    line-height: 14px;
    height: 14px;
  }
`;
