import PropTypes from "prop-types";
import Modal from "components/Modal";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { replacePath } from "common/utils";
import localRoutes from "views_refactor/Profile/functions/routes";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import FeedbackTabs from "../../../FeedbackTabs";

const FeedbackModal = (props) => {
  const {
    t,
    history,
    isOwnProfile,
    collaborator,
    feedbackStates,
    feedbackActions,
  } = props;
  const { isModalOpen } = feedbackStates;

  return (
    <Modal
      isOpen={ isModalOpen.feedback }
      title={ t("feedback:title") }
      onClose={ () => replacePath(history, localRoutes.root) }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "850px" }>
        {isOwnProfile && (
          <ViewHeaderTitle
            isWithMenuButton={ collaborator?.id }
            menuItems={ feedbackActions }
            menuButtonLabel={ t("feedback:buttons.actions") }
          />
        )}
        <FeedbackTabs
          { ...props }
          showAsCards
        />
      </StyledScrollBar>
    </Modal>
  );
};

FeedbackModal.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  collaborator: PropTypes.object.isRequired,
  feedbackStates: PropTypes.object.isRequired,
  feedbackActions: PropTypes.array.isRequired,
};

export default FeedbackModal;
