import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledProfileFullName = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 4px;
  color: ${palette.text.secondaryTitle};
  font-weight: bold;
  svg {
    width: 20px;
    height: 18px;
    padding-right: 4px;
    cursor: pointer;
  }
`;

export const StyledInfoDetail = styled.div`
  text-align: center;
  margin-bottom: 12px;
  font-size: 14px;
`;

export const StyledInfoTitle = styled.p`
  color: ${palette.text.link};
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 14px;
  + p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledIcon = styled.span`
  margin-right: 5px
`;

export const StyledButtonLink = styled.div`
  background: ${palette.background.linkButton};
  border: 1px solid ${palette.input.focused};
  color: ${palette.input.focused};
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover{
    cursor: pointer;
    text-decoration: none
  }
`;

export const StyledGridContainer = styled(Grid)`
  text-align: center;
`;

export const StyledPositionName = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
  gap: 8px;
  svg {
    margin-left: 2px;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
  }
`;
