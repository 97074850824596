import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import theme from "theme/palette";

export const StyledAvatar = styled(Avatar)`
  background-color: ${(props) => (props.src ? null : theme.table.blue)};
`;

export const StyledAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledBadge = styled.span`
  display: ${(props) => (props.invisible ? "none" : "flex")};
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${theme.table.red};
  bottom: 2px;
  right: 2px;
  z-index: 1;
`;

export const StyledBold = styled.p`
  font-weight: 700;
`;

export const StyledLabels = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAvatarCell = styled.div`
  display: flex;
  column-gap: 10px;
`;
