import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Accordion from "components/Accordion";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import { BORDER_ACCORDION_TYPE, SKELETONS_NUMBER, MIN_VALUE } from "common/constants";
import { isEmpty, isNull } from "common/helpers";
import { getCommentsByDivision } from "../../functions";
import { StyledTitle } from "../GeneralContent/styles";
import { StyledAccordionContainer } from "../../styledComponents";

const CommentsByDivision = () => {
  const { t } = useTranslation(["engagement"]);

  const [commentsByDepartment, setCommentsByDepartment] = useState(null);

  const {
    engagementComments,
    isLoadingEngagementComments,
  } = useSelector(({ engagementProcessReducer }) => engagementProcessReducer);

  useEffect(() => {
    if (engagementComments && !isLoadingEngagementComments) {
      const comments = engagementComments?.filter((item) => item.keep_doing?.length !== MIN_VALUE || item.stop_doing?.length !== MIN_VALUE);
      setCommentsByDepartment(comments);
    }
  }, [engagementComments, isLoadingEngagementComments]);

  if (isLoadingEngagementComments) {
    return (<SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />);
  }

  return (
    <Grid container spacing={ 4 } data-testid={ "comments-by-division-view" }>
      <Grid item xs={ 12 }>
        <StyledTitle>{t("comments")}</StyledTitle>
        <StyledAccordionContainer>
          { !isEmpty(commentsByDepartment) && !isNull(commentsByDepartment)
            ? (
              <Accordion
                isDefaultIconStyle
                type={ BORDER_ACCORDION_TYPE.square }
                data={ getCommentsByDivision(commentsByDepartment, t) }
                iconLeft
              />
            )
            : <NoDataMessage />}
        </StyledAccordionContainer>
      </Grid>
    </Grid>
  );
};

export default CommentsByDivision;
