import max from "lodash/max";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Accordion from "components/Accordion";
import { getPercent } from "common/utils";
import { isNull, isEqual, isNullOrUndefined } from "common/helpers";
import {
  ALIGN_ITEMS,
  BORDER_ACCORDION_TYPE,
  COLORS_NAME,
  VARIANT,
} from "common/constants";
import dominantImg from "assets/images/potential/dominante.svg";
import influentialImg from "assets/images/potential/influyente.svg";
import consciousImg from "assets/images/potential/concienzudo.svg";
import stableImg from "assets/images/potential/estable.svg";
import { StyleBoxContainer } from "../../styles";

const DefinitionResults = (props) => {
  const { t, data } = props;

  const isMaxResult = (result) => {
    const results = [
      data.decision,
      data.interaction,
      data.compliance,
      data.serenity,
    ];
    return isEqual(result, max(results));
  };

  const getResult = (result, title) => {
    let customResult = "";
    if (!isNull(result)) {
      customResult = (
        <Box display={ ALIGN_ITEMS.flex }>
          <StyleBoxContainer display={ ALIGN_ITEMS.flex }>
            <Box>
              <Typography variant={ VARIANT.button }>{title}</Typography>
            </Box>
            <Box>
              {isMaxResult(result) && (
                <CheckCircle className={ "maxResultIcon" } />
              )}
            </Box>
          </StyleBoxContainer>
          <Box>
            <Typography variant={ VARIANT.h6 }>{getPercent(result, true)}</Typography>
          </Box>
        </Box>
      );
    }
    return customResult;
  };

  return (
    <div>
      {data && (
        <Accordion
          type={ BORDER_ACCORDION_TYPE.rounded }
          background={ COLORS_NAME.light }
          isBackgroundContent
          isPadding
          data={ [
            {
              title: t("profile:modals.skills.skills.chart.dominant"),
              src: dominantImg,
              variant: VARIANT.button,
              customHeader:
                !isNullOrUndefined(data.decision)
                && getResult(data.decision, t("profile:modals.skills.skills.chart.dominant")),

              customRender: (
                <Typography variant={ VARIANT.bodyOne }>
                  {t("profile:modals.skills.skills.personality_test.dominant_text")}
                </Typography>
              ),
            },
            {
              title: t("profile:modals.skills.skills.chart.influential"),
              src: influentialImg,
              variant: VARIANT.button,
              customHeader:
                !isNullOrUndefined(data.interaction)
                && getResult(data.interaction, t("profile:modals.skills.skills.chart.influential")),
              customRender: (
                <Typography variant={ VARIANT.bodyOne }>
                  {t("profile:modals.skills.skills.personality_test.influential_text")}
                </Typography>
              ),
            },
            {
              title: t("profile:modals.skills.skills.chart.conscious"),
              src: consciousImg,
              variant: VARIANT.button,
              customHeader:
                !isNullOrUndefined(data.compliance)
                && getResult(data.compliance, t("profile:modals.skills.skills.chart.conscious")),
              customRender: (
                <Typography variant={ VARIANT.bodyOne }>
                  {t("profile:modals.skills.skills.personality_test.conscious_text")}
                </Typography>
              ),
            },
            {
              title: t("profile:modals.skills.skills.chart.stable"),
              src: stableImg,
              variant: VARIANT.button,
              customHeader: !isNullOrUndefined(data.serenity) && getResult(data.serenity, t("profile:modals.skills.skills.chart.stable")),
              customRender: (
                <Typography variant={ VARIANT.bodyOne }>
                  {t("profile:modals.skills.skills.personality_test.stable_text")}
                </Typography>
              ),
            },
          ] }
        />
      )}
    </div>
  );
};

DefinitionResults.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default DefinitionResults;
