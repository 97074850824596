import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledMainContainer = styled(Grid)`
    .MuiTextField-root {
    width: 100%;
  }
`;

export const StyledGrid = styled(Grid)`
 margin-top: 8px;
`;
