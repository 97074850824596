import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  customTable: {
    background: "red",
  },
  tableTitle: {
    color: theme.palette.text.secondaryTitle,
    fontSize: 14,
    marginBottom: 5,
    textAlign: "left",
    fontWeight: "bold",
  },
  button: {
    borderColor: theme.palette.text.link,
    color: theme.palette.text.link,
    marginTop: 8,
    "&:hover": {
      backgroundColor: theme.palette.text.link,
      color: theme.palette.white,
    },
  },
  itemGrid: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));
