import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import {
  BUTTON_STYLE_TYPES, BUTTON_TYPE, INPUT_TYPE, VARIANT,
} from "common/constants";
import { isNotValid, isEqual, isEmpty } from "common/helpers";
import { getList as getListAcknowledgementType, resetStateFeedback } from "redux/actions/acknowledgementTypeActions";
import { getList as getFeedbacksByEmployeeIdAndType } from "redux/actions/feedbackActions";
import RadioButtons from "./components/RadioButtons";
import {
  Content, Title, Actions, StyledContainer,
} from "./styles";

const AcknowledgmentForm = (props) => {
  const {
    control, employee, isSubmitDisabled, handleFinish, handleSend,
  } = props;
  const [selectedItem, setSelectedItem] = useState();
  const { t } = useTranslation("feedback");
  const dispatch = useDispatch();

  const {
    list, successProcessFeedback, isLoadingProcessFeedback,
  } = useSelector(({ acknowledgementTypesReducer }) => acknowledgementTypesReducer);

  useEffect(() => {
    dispatch(getListAcknowledgementType());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingProcessFeedback && successProcessFeedback) {
      dispatch(resetStateFeedback());
      handleFinish();
      handleSend(false);
      dispatch(getFeedbacksByEmployeeIdAndType(employee?.id || employee, true));
    }
  }, [
    successProcessFeedback,
    dispatch,
    handleFinish,
    employee,
    handleSend,
    isLoadingProcessFeedback,
  ]);

  const handleAcknowledgementSelected = (id) => {
    setSelectedItem(id);
  };

  const globalAcknowledgementTypes = list?.filter((item) => isEqual(item?.is_global, true));
  const localAcknowledgementTypes = list?.filter((item) => isEqual(item?.is_global, false));

  const radioButtons = (data) => (
    <RadioButtons
      control={ control }
      controlName={ "feedback_acknowledgement.acknowledgement_type_id" }
      radioName={ "acknowledgment" }
      options={ data }
      selected={ selectedItem }
      onClick={ handleAcknowledgementSelected }
    />
  );
  const renderAccordion = () => {
    const globalAccordion = (isExpanded) => (
      <Accordion
        defaultExpanded={ isExpanded }
      >
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          aria-controls={ "panel1a-content" }
          id={ "panel1a-header" }
          isExpanded
        >
          {t("FeedbackTypesOfNala")}
        </AccordionSummary>
        <AccordionDetails>
          {radioButtons(globalAcknowledgementTypes)}
        </AccordionDetails>
      </Accordion>
    );
    const localAccordion = (isExpanded) => (

      <Accordion
        defaultExpanded={ isExpanded }
      >
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          aria-controls={ "panel2a-content" }
          id={ "panel2a-header" }
          isExpanded
        >
          {t("FeedbackTypesOfCompany")}
        </AccordionSummary>
        <AccordionDetails>
          {radioButtons(localAcknowledgementTypes)}
        </AccordionDetails>
      </Accordion>
    );
    return (
      // if localaccordion is empty, then only show expanded global accordion
      isEmpty(localAcknowledgementTypes) ? globalAccordion(true) : (
        <>
          {localAccordion(true)}
          {globalAccordion(false)}
        </>
      )
    );
  };

  return (
    <StyledContainer data-testid={ "acknowledgment-form-view" } container >
      <Content item xs={ 12 }>
        <InputTextController
          type={ INPUT_TYPE.text }
          label={ t("acknowledgment") }
          placeholder={ t("write_message") }
          control={ control }
          rules={ { required: true } }
          multiline={ 6 }
          showHelperText={ false }
          required
          name={ "feedback_acknowledgement.message" }
        />
        <Title
          variant={ VARIANT.bodyOne }
        >
          {`${t("select_an_acknowledgment")} *`}
        </Title>
        {renderAccordion()}
      </Content>
      <Actions item xs={ 12 }>
        <Button
          type={ BUTTON_TYPE.submit }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          isDisabled={ isSubmitDisabled || isNotValid(selectedItem) }
          isLoading={ isLoadingProcessFeedback }
        >
          {t("buttons.send_acknowledgment")}
        </Button>
      </Actions>
    </StyledContainer>
  );
};

AcknowledgmentForm.propTypes = {
  control: PropTypes.object.isRequired,
  employee: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
};

export default AcknowledgmentForm;
