import styled from "styled-components";

export const StyledButtons = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  margin-top: 8px;
  button {
    margin-left: 10px;
    padding: 9px 8px;
  }
`;
