import styled from "styled-components";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import palette, { STATE_COLORS, STATE_AGREEMENT_COLOR } from "theme/palette";

export const StyledCollapse = styled.div`
  .MuiTableContainer-root{
    background: ${palette.background.default};
  }
  .MuiButton-root {
    margin: 8px;
    padding: 0px 10px;
    font-size: 13px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
  }
`;

export const StyledText = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: flex-start;
  svg {
    padding-right: 5px;
    font-size: 12px;
    cursor: pointer;
  }
`;

export const StyledAddCircleIcon = styled(AddCircleOutlineIcon)`
  width: 0.7em;
  margin-right: 4px;
`;

export const StyledDelete = styled.div`
  color: ${STATE_COLORS.ERROR}
`;

export const StyledEmployeeGroup = styled.div`
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .MuiIconButton-root {
    padding: 5px;
  }
`;

export const StyledState = styled.div`
  background: ${(props) => STATE_AGREEMENT_COLOR[props.state]};
  width: 10px;
  height: 50px;
  border-radius: 10px;
`;

export const StyledAssignmentIcon = styled(AssignmentOutlinedIcon)`
  display: flex;
  width: 28px;
  height: 28px;
  color: ${palette.text.secondaryTitle};
  :hover {
    cursor: pointer;
    color: ${palette.text.link};
  }
`;
