import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,

  RESET_STATE_PROCESS,
  PROCESS_CANDIDATE,
  PROCESS_CANDIDATE_LOADING,
  PROCESS_CANDIDATE_ERROR,

  RESET_STATE_CANDIDATE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,

  RESET_STATE_CANDIDATES_TO_DOWNLOAD,
  GET_LIST_CANDIDATES_TO_DOWNLOAD,
  GET_LIST_CANDIDATES_TO_DOWNLOAD_LOADING,
  GET_LIST_CANDIDATES_TO_DOWNLOAD_ERROR,
} from "../actionTypes/candidate";

// NOTE: we need manteing the URL's close to the actions
const URL = {
  main: "/candidates",
  feedback: "feedback",
  reminder: "reminder",
  download: "?download=true",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PROCESS,
  });
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_CANDIDATE,
  });
};

export const resetCandidatesToDownload = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_CANDIDATES_TO_DOWNLOAD,
  });
};

export const getOne = (candidateId) => async (dispatch, getState) => {
  const { one } = getState().candidateReducer;
  if (!one) {
    dispatch({
      type: GET_ONE_LOADING,
    });
    try {
      const response = await configAxios.get(`${URL.main}/${candidateId}`);
      dispatch({
        type: GET_ONE,
        payload: response.data.candidate,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_ONE_ERROR);
    }
  }
};

export const getList = () => async (dispatch, getState) => {
  const { list } = getState().candidateReducer;

  if (!list.length) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main);
      dispatch({
        type: GET_LIST,
        payload: response.data.candidates,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

export const create = (data) => async (dispatch) => {
  dispatch({
    type: PROCESS_CANDIDATE_LOADING,
  });
  try {
    const response = await configAxios.post(URL.main, data);
    dispatch({
      type: PROCESS_CANDIDATE,
      payload: response.data.candidate,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_CANDIDATE_ERROR);
  }
};

export const update = (data, candidateId, dataImage = null) => async (dispatch) => {
  dispatch({
    type: PROCESS_CANDIDATE_LOADING,
  });
  try {
    const UPDATE_CANDIDATE_URL = `${URL.main}/${candidateId}`;
    if (dataImage) {
      await configAxios.put(UPDATE_CANDIDATE_URL, dataImage);
    }
    const response = await configAxios.put(UPDATE_CANDIDATE_URL, data);
    dispatch({
      type: PROCESS_CANDIDATE,
      payload: response.data.candidate,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_CANDIDATE_ERROR);
  }
};

export const sendFeedback = (candidateId, message) => async (dispatch) => {
  dispatch({
    type: PROCESS_CANDIDATE_LOADING,
  });
  try {
    const URL_FEEDBACK_CANDIDATE = `${URL.main}/${candidateId}/${URL.feedback}`;
    const response = await configAxios.post(URL_FEEDBACK_CANDIDATE, JSON.stringify(message));
    dispatch({
      type: PROCESS_CANDIDATE,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_CANDIDATE_ERROR);
  }
};

export const sendReminder = (candidateId, message) => async (dispatch) => {
  dispatch({
    type: PROCESS_CANDIDATE_LOADING,
  });
  try {
    const URL_REMINDER_CANDIDATE = `${URL.main}/${candidateId}/${URL.reminder}`;
    const response = await configAxios.post(URL_REMINDER_CANDIDATE, JSON.stringify(message));
    dispatch({
      type: PROCESS_CANDIDATE,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_CANDIDATE_ERROR);
  }
};

export const getCandidatesToDownload = () => async (dispatch) => {
  const CANDIDATES_TO_DOWNLOAD_URL = `${URL.main}${URL.download}`;
  dispatch({
    type: GET_LIST_CANDIDATES_TO_DOWNLOAD_LOADING,
  });

  try {
    const response = await configAxios.get(CANDIDATES_TO_DOWNLOAD_URL, {
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_CANDIDATES_TO_DOWNLOAD,
      payload: response.data.candidates,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_CANDIDATES_TO_DOWNLOAD_ERROR);
  }
};
