import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Button from "components/Button";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import { BUTTON_STYLE_TYPES, ACTIONS } from "common/constants";
import { getRowsDesktop } from "views/GeneralAdministrator/functions";
import { getModuleData } from "../../functions";

const MainCustomView = (props) => {
  const {
    moduleName, header, table, classes, handleSearch,
  } = props;
  const { t } = useTranslation(["administrator", "common"]);

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ ref }>
      <RouterLink { ...props } />
    </div>
  ));

  const moduleData = getModuleData(moduleName);
  return (
    <div data-testid={ "general-administrator-main-custom-view" }>
      {header && (
        <ViewHeaderTitle
          title={ header?.title }
          subtitle={ header?.subtitle }
          breadcrumb={ header?.breadcrumb }
        />
      )}

      {table?.header.head && (
        <TablePaginationCollapse
          header={ table?.header.head }
          list={ table?.data }
          isLoading={ table?.isLoading }
          getRows={ {
            mobile: getRowsDesktop,
            desktop: getRowsDesktop,
          } }
          extraParam={ table?.header.actions }
          handleSearch={ handleSearch }
          rightOptions={ moduleData?.new && (
            <Grid container className={ classes.button }>
              <Button
                component={ CustomRouterLink }
                href={ `${moduleData.path}/${ACTIONS.new}` }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              >
                {t(`modules.${moduleName}.button_name`)}
              </Button>
            </Grid>
          ) }
          handlePagination={ table?.handlePagination }
          pagination={ table?.pagination }
          total={ table?.total }
        />
      )}
    </div>
  );
};

MainCustomView.propTypes = {
  moduleName: PropTypes.string,
  header: PropTypes.object,
  table: PropTypes.object,
  classes: PropTypes.object,
  handleSearch: PropTypes.func,
};

export default MainCustomView;
