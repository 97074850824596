import styled from "styled-components";
import palette from "theme/palette";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export const StyledModalContent = styled.div`
  background: ${palette.white};
  position: absolute;
  min-width: 500px;
  padding: 0px;
  outline: 0;
  top: 50%;
  left: 50%;
  box-shadow: 7px 8px 7px 0px rgba(0,0,0,0.54);
  -webkit-box-shadow: 7px 8px 7px 0px rgba(0,0,0,0.54);
  -moz-box-shadow: 7px 8px 7px 0px rgba(0,0,0,0.54);
  transform: translate(-50%, -50%);
  word-wrap: break-word;
  @media (max-width: 600px) {
    max-width: 500px;
  };
  @media (max-width: 430px) {
    max-width: 300px;
    min-width: 380px;
  };
`;

export const StyledCloseButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${palette.white};
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  background: ${palette.text.link};
  min-height:  ${(prop) => (prop.noPicture ? "100%" : "140px") };
`;

export const StyledImage = styled.img`
  position: absolute;
  right: 20px;
  top: 40px;
`;

export const StyledBanner = styled.div`
  padding: 20px 30px;
  width: ${(prop) => (prop.noPicture ? "90%" : "70%") };
  min-height: 140px;
  display: inline-grid;
  align-items: center;
`;

export const StyledTitle = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
    color: ${palette.white};
  }
`;

export const StyledSubtitleHeader = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 16px;
    font-weight: 500;
    color: ${palette.white};
  }
`;

export const StyledContent = styled.div`
  background: ${palette.white};
  padding: 30px;
`;

export const StyledDescription = styled(Typography)`
  text-align: justify;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin-top: 20px;
`;

export const StyledList = styled.ul`
  width: 100%;
  padding: 20px;
  li {
    margin-bottom: 10px;
  }
`;

export const StyledContainer = styled.div`
  background: ${palette.white};
`;
