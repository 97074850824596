import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  padding: 32px;
  margin-bottom: 25px;
`;

export const StyledTypographyTitle = styled(Typography)`
  &.MuiTypography-body1 {
    color: ${palette.text.secondaryTitle};
    font-size: 38px;
    margin-top: 25px;
    font-weight: bold;
    line-height: 38px;
    margin-bottom: 15px;
  }
`;

export const StyledCardContainer = styled(Card)`
  margin-top: 24px;
  padding: 22px 16px;
  box-shadow: none;
  height: 98%;
`;

export const StyledTitle = styled.div`
  color: ${palette.text.link};
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .MuiTypography-body1 {
    color: ${palette.text.link};
    font-size: 25px;
    font-weight: 700;
    margin-left: 8px;
  }

  .MuiSvgIcon-root {
    font-size: 28px;
  }
`;
export const StyledSubtitle = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const StyledBold = styled.span`
  font-weight: bold;
`;
