import styled from "styled-components";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import palette from "theme/palette";

export const useStyles = makeStyles((localTheme) => ({
  blueTheme: {
    color: `${localTheme.palette.text.link} !important`,
  },
  icon: {
    color: localTheme.palette.white,
  },
}));

export const StyledContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  fieldset {
    border: none;
  }
`;

export const StyledSelect = styled(Select)`
  .MuiSelect-select {
    padding: 0;
    line-height: inherit !important;
    color: ${(props) => (props.custom ? palette.text.link : palette.white) };
    &::before {
      border-color: inherit!important;
      border-bottom: inherit!important;
    },
    &::after {
      border-color: inherit!important;
      border-bottom: inherit!important;
    },
  }
`;
