import PropTypes from "prop-types";
import { ImageLoader } from "react-progressive-img-loader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Modal from "components/Modal";
import Stepper from "components/Stepper";
import {
  INDEX,
  MOVEMENT_TYPES,
  OBJECT_KEYS,
  VARIANT,
} from "common/constants";
import { formatDate, replacePath } from "common/utils";
import { isEqual, isNull } from "common/helpers";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import localRoutes from "../../../../functions/routes";
import MovementForm from "./components/MovementForm";
import {
  StyledStepsContainer, StyledAvatar, StyledBold, StyledItemText,
} from "./styles";

const MovementModal = (props) => {
  const {
    t,
    history,
    collaborator,
    movementStates,
    isLoadingMovement,
    onMovementSubmit,
    sendPreview,
    getMovementValue,
    movementsHistoryListState,
  } = props;

  const {
    isModalOpen, typeSelected, updatedData, date,
  } = movementStates;

  const allSteps = () => [
    {
      content: (<MovementForm { ...props } />),
      additionalInNext: sendPreview,
      disabled: isNull(getMovementValue("collaborator.job_position_attributes.position_id"))
        || isNull(getMovementValue("collaborator.salary_attributes.currency")),
      isLoading: movementsHistoryListState.isLoading,
    },
    {
      content: (
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <StyledAvatar>
              {(collaborator && collaborator?.profile_img_url_thumb && collaborator?.profile_img_url) ? (
                <ImageLoader
                  src={ collaborator.profile_img_url }
                  srcPreview={ collaborator.profile_img_url_thumb }
                  customImageComponent={ Avatar }
                />
              ) : collaborator && !collaborator?.profile_img_url_thumb && (
                <Avatar src={ collaborator.profile_img_url } />
              )}
              <Typography variant={ VARIANT.h6 }>{ collaborator?.full_name }</Typography>
            </StyledAvatar>
          </Grid>
          {updatedData.map((item, index) => (
            <>
              <Grid item xs={ 12 } md={ isEqual(item.key, OBJECT_KEYS.salary) ? 6 : 12 } >
                <StyledItemText>
                  <StyledBold>{`${t(item.label)}: `}</StyledBold>
                  { item.lastValue || t("profile:noData")}
                </StyledItemText>
              </Grid>
              {isEqual(item.key, OBJECT_KEYS.salary) && (
                <Grid item xs={ 6 }>
                  <StyledItemText>
                    {`${t("common:common.oldSalary")}: ${item.firstValue || INDEX.zero}`}
                  </StyledItemText>
                </Grid>
              )}
            </>
          ))}
          <Grid item xs={ 12 }>
            <StyledItemText>
              <StyledBold>{`${t("common:common.asOf")}: `}</StyledBold>
              {formatDate(date)}
            </StyledItemText>
          </Grid>
          <Grid item xs={ 12 }>
            <StyledItemText>
              <StyledBold>{`${t("profile:form.movementType")}: `}</StyledBold>
              {t(MOVEMENT_TYPES[typeSelected])}
            </StyledItemText>
          </Grid>
        </Grid>
      ),
      isLoading: isLoadingMovement,
    },
  ];

  return (
    <Modal
      isOpen={ isModalOpen.addMovement }
      onClose={ () => replacePath(history, localRoutes.root) }
      title={ t("profile:addMovement") }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "500px" }>
        <StyledStepsContainer>
          <Stepper
            steps={ allSteps() }
            onClick={ onMovementSubmit }
            noShowState
          />
        </StyledStepsContainer>
      </StyledScrollBar>
    </Modal>
  );
};

MovementModal.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  movementStates: PropTypes.object.isRequired,
  isLoadingMovement: PropTypes.bool.isRequired,
  onMovementSubmit: PropTypes.func.isRequired,
  sendPreview: PropTypes.func.isRequired,
  getMovementValue: PropTypes.func.isRequired,
  movementsHistoryListState: PropTypes.object.isRequired,
};

export default MovementModal;
