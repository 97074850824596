import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { VARIANT } from "common/constants";
import { getPercent } from "common/utils";
import { getChartTitle } from "./functions";
import {
  Title, Percentage, ProgressBar, ContainerChart, ChartTitle,
} from "./styledComponents";

const HorizontalBarChart = (props) => {
  const { data, label } = props;

  return (
    <div data-testid={ "horizontal-bar-chart-component" }>
      <ChartTitle container>
        <Grid item xs={ 12 } md={ 9 } >
          <Title variant={ VARIANT.bodyOne }>{ getChartTitle(label) }</Title>
        </Grid>
      </ChartTitle>
      {data.map((item) => (
        <ContainerChart container key={ item.name }>
          <Grid item xs={ 6 } md={ 3 }>
            <Typography variant={ VARIANT.bodyOne }>{ item.name }</Typography>
          </Grid>
          <Grid item xs={ 6 } md={ 1 }>
            <Typography variant={ VARIANT.bodyOne }>{ getChartTitle(item.total) }</Typography>
          </Grid>
          <Grid item xs={ 12 } md={ 8 }>
            <ProgressBar value={ getPercent(item?.score) }>
              <Percentage variant={ VARIANT.h6 }>
                { getPercent(item?.score, true) }
              </Percentage>
            </ProgressBar>
          </Grid>
        </ContainerChart>
      ))}
    </div>
  );
};

HorizontalBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default HorizontalBarChart;
