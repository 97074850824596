import styled from "styled-components";
import palette from "theme/palette";
import AvatarMUI from "@mui/material/Avatar";

export const AcknowledgmentIcon = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
  border-left: ${palette.border.light};
  img {
    width: 50px;
  }
`;

export const AcknowledgmentIconTitle = styled.span`
  font-weight: 700;
  text-align: center;
  font-size: 12px;
  padding: 10px;
`;

export const Avatar = styled(AvatarMUI)`
  width: 55px;
  height: 55px;
`;

export const SSCMessageType = styled.div`
  margin-top: 5px;
`;
