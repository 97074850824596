import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  iframe: {
    width: "100%",
    minHeight: 640,
    border: 0,
  },
}));

const Icons = function () {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <iframe
        className={ classes.iframe }
        src={ "https://material.io/tools/icons/?icon=accessibility&style=outline" }
        title={ "Material Design icons" }
	/>
 </div>
  );
};

export default Icons;
