import find from "lodash/find";
import { OBJECT_KEYS } from "common/constants";

/**
   * Groups the goals by year, period and subperiod.
   * @param {Array} goals - The goals to group.
   * @returns {Array} - The goals grouped by year, period and subperiod.
   */
export const groupGoalsByPeriod = (goals, periodListTransformed) => {
  const goalsGroupedByYear = {};
  goals.filter((goal) => goal.id !== "total").forEach((goal) => {
    const parts = goal.starting_date.split("-");
    const year = parseInt(parts[0], 10);

    // Find the period for the current goal.
    const period = find(periodListTransformed, [OBJECT_KEYS.id, goal.period.id]);

    // Find the subperiod for the current goal, if it exists.
    const subperiod = period?.children && goal.subperiod !== null
      ? find(period.children, [OBJECT_KEYS.id, goal.subperiod.id])
      : null;

    // Initialize the year if it doesn't exist in the result object.
    if (!goalsGroupedByYear[year]) {
      goalsGroupedByYear[year] = {};
    }

    // If the period doesn't exist under the year, initialize it.
    if (!goalsGroupedByYear[year][period.id]) {
      goalsGroupedByYear[year][period.id] = {
        id: period.id,
        label: period.label,
        goals: subperiod ? null : [], // Set goals to null if there's a subperiod.
        children: subperiod ? [] : null,
      };
    }

    const currentPeriod = goalsGroupedByYear[year][period.id];
    // If there's a subperiod, add the goal to the subperiod (children).
    if (subperiod) {
      if (!currentPeriod.children.find((sp) => sp.id === subperiod.id)) {
        currentPeriod.children.push({
          id: subperiod.id,
          label: subperiod.label,
          goals: [],
        });
      }

      const currentSubperiod = currentPeriod.children.find((sp) => sp.id === subperiod.id);
      currentSubperiod.goals.push(goal);
    } else {
      // If there's no subperiod, add the goal to the period.
      currentPeriod.goals?.push(goal);
    }
  });

  // Convert the result object to an array structure: [year, [periods], [subperiods]]
  const result = Object.entries(goalsGroupedByYear).map(([year, periods]) => ({
    year,
    periods: Object.values(periods),
  }));

  return result;
};
