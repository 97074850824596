import { useState, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleAlert from "components/SimpleAlert";
import { getLeadershipTypes } from "redux/actions/leadershipTypes/leadershipTypesActions";
import { SKELETONS_NUMBER } from "common/constants";
import { getDataBox, getRectValues } from "./functions";
import {
  StyledTitleLeft,
  StyledTitleContainer,
  StyledTitleBottom,
  StyledBoxTitle,
  StyledRectBox,
  StyledGridContainer,
  StyledSvgContainer,
  StyledLine,
} from "./styles";

const LeadershipMatrix = (props) => {
  const { getQuadrantDetail, quadrant } = props;
  const { t, i18n } = useTranslation(["leadership", "common"]);
  const { language } = i18n;

  const [quadrantSelected, setQuadrantSelected] = useState(null);
  const [dataBox, setDataBox] = useState([]);

  useEffect(() => {
    setQuadrantSelected(quadrant);
  }, [quadrant]);

  const {
    data: leadershipTypes,
    isLoading: isLoadingLeadershipTypes,
    error,
  } = useQuery("leadershipTypes", () => getLeadershipTypes(), {
    // Infinity is used because leadership types don't change
    staleTime: Infinity,
  });

  const getDataByBoxes = useCallback(() => {
    const data = getDataBox(leadershipTypes);
    setDataBox(data);
  }, [leadershipTypes]);

  useEffect(() => {
    if (leadershipTypes && !isLoadingLeadershipTypes) {
      getDataByBoxes();
    }
  }, [leadershipTypes, getDataByBoxes, isLoadingLeadershipTypes]);

  const setQuadrant = useCallback(
    (quadrantIndex) => {
      setQuadrantSelected(quadrantIndex);
      if (getQuadrantDetail) getQuadrantDetail(dataBox[quadrantIndex]);
    },
    [dataBox, getQuadrantDetail],
  );

  const errorMessage = (error && error?.response?.status && !isLoadingLeadershipTypes)
    && (
      /* TODO: Replace the alert with something that is visually
      more appealing and indicates that an error occurred during the query.
      Perhaps even add a button to reload without needing to refresh the page. */
      <Grid item xs={ 12 }>
        <SimpleAlert
          type={ "error" }
          title={ t("common:common.api_responses.error.title") }
          message={ t(`common:common.api_responses.error.${error.response.status}`) }
        />
      </Grid>
    );

  const matrix = (
    <>
      <Grid item xs={ 1 } md={ 1 }>
        <StyledTitleContainer>
          <StyledTitleLeft>{ t("leadership:leadership_score") }</StyledTitleLeft>
        </StyledTitleContainer>
      </Grid>
      <StyledGridContainer item xs={ 11 }>
        <Grid>
          {/* Add tooltips */}
          <StyledSvgContainer viewBox={ "-4 -4 108 88" }>
            {dataBox.map((item, index) => {
              const isSelectedQuadrant = quadrantSelected === index;
              // shouldAddBorder only if not quadrant 4
              // quadrant 4 has a distinct border
              const shouldAddBorder = isSelectedQuadrant && index < 4;
              const rectValues = getRectValues(item, shouldAddBorder);
              return (
                <g key={ `box${item.id}` }>
                  <StyledRectBox
                    x={ rectValues.x }
                    y={ rectValues.y }
                    width={ rectValues.width }
                    height={ rectValues.height }
                    fill={ item.fill }
                    border={ item.border }
                    onClick={ () => setQuadrant(index) }
                    isActive={ shouldAddBorder }
                  />
                  {index === 4 && (
                    <>
                      <StyledLine
                        x1={ item.x + item.width }
                        y1={ item.y - 0.55 }
                        x2={ item.x + item.width }
                        y2={ item.y + item.height }
                        isActive={ isSelectedQuadrant }
                      />
                      <StyledLine
                        x1={ 0.55 }
                        y1={ item.y }
                        x2={ item.x + item.width }
                        y2={ item.y }
                        isActive={ isSelectedQuadrant }
                      />
                    </>
                  )}
                  <StyledBoxTitle
                    x={ item.xText }
                    y={ item.yText }
                    fill={ item.textColor }
                  >
                    {item[`name_${language}`]}
                  </StyledBoxTitle>
                </g>
              );
            })}
            { props.children }
          </StyledSvgContainer>
        </Grid>
        <StyledTitleContainer>
          <StyledTitleBottom>{ t("leadership:good_leader_score") }</StyledTitleBottom>
        </StyledTitleContainer>
      </StyledGridContainer>
    </>
  );

  return (
    <Box>
      <Grid container spacing={ 2 }>
        {errorMessage}
        {!error && (dataBox.length > 0 && !isLoadingLeadershipTypes ? matrix
          : <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } />)}
      </Grid>
    </Box>
  );
};

LeadershipMatrix.propTypes = {
  getQuadrantDetail: PropTypes.func,
  quadrant: PropTypes.number,
  setSelectedQuadrant: PropTypes.func,
};

LeadershipMatrix.defaultProps = {
  getQuadrantDetail: null,
  quadrant: null,
  setSelectedQuadrant: null,
};

export default LeadershipMatrix;
