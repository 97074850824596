import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import configAxios from "../../configAxios";

const URL = {
  main: "/leadership_types",
};

export const getLeadershipTypes = async () => {
  const pagination = { size: 10, number: 0 };
  const response = await configAxios.get(URL.main, {
    params: { page: pagination },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data.leadership_types;
};
