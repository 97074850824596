import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Paper from "@mui/material/Paper";
import { CARD_TYPE, PERSONALITY_EVALUATION, INDEX } from "common/constants";
import Card from "./components/Card";
import { useStyles } from "../../styles";

const EvaluationDetail = (props) => {
  const { data } = props;
  const classes = useStyles();

  const getFullInformationByDefault = (dataToUse) => ({
    archetype: dataToUse.archetype || dataToUse.archetype_es,
    description: dataToUse.general_description || dataToUse.general_description_es,
    info: {
      energizes: dataToUse.what_energizes || dataToUse.what_energizes_es,
      drains: dataToUse.what_drains || dataToUse.what_drains_es,
    },
  });

  const getFullInformation = () => {
    let fullData = {
      archetype: "",
      description: "",
      info: {
        energizes: "",
        drains: "",
      },
    };

    if (!isEmpty(data)) {
      const dataToUse = data[INDEX.zero];
      fullData = getFullInformationByDefault(dataToUse);
    }
    return fullData;
  };

  return (
    <div data-testid={ "personality-test-evaluation-detail" }>
      <Paper className={ classes.cardContainer }>
        <Card
          info={ getFullInformation().description }
          archetype={ getFullInformation().archetype }
          type={ CARD_TYPE.evaluation }
        />
        <Card
          info={ getFullInformation().info.energizes }
          type={ CARD_TYPE.badge }
          index={ PERSONALITY_EVALUATION.energizes }
        />
        <Card
          info={ getFullInformation().info.drains }
          type={ CARD_TYPE.badge }
          index={ PERSONALITY_EVALUATION.drains }
        />
      </Paper>
    </div>
  );
};

EvaluationDetail.propTypes = {
  data: PropTypes.array,
};

export default EvaluationDetail;
