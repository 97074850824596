import moment from "moment";
import {
  MARITAL_STATUS,
  GENDER_STATUS,
  ROLES,
  ROLES_NAMES,
  MOVEMENT_TYPES,
  OBJECT_KEYS,
  LOCAL_STORAGE_NAMES,
} from "common/constants";
import {
  isEmpty, // TODO: remove
  arraysEqual,
} from "common/helpers";
import {
  getDivisionAndAreaType,
  getItemFromLocalStorage,
  getValueByProp,
  isValidToKeyPositionModule,
} from "common/utils";
import {
  update as updateCollaborator,
  updateAvatar,
} from "redux/actions/collaboratorActions";
import localRoutes from "./routes";

export const resetDefaultValues = (employee, collaborator, genderSelected = null, otherGender = null) => ({
  [`${employee}.person_attributes.birth_date`]:
		collaborator?.person?.birth_date ?? "",
  [`${employee}.person_attributes.personal_id`]:
		collaborator?.person?.personal_id ?? "",
  [`${employee}.person_attributes.personal_email`]:
		collaborator?.person?.personal_email ?? "",
  [`${employee}.person_attributes.nationality`]:
		collaborator?.person?.nationality ?? "",
  [`${employee}.person_attributes.gender`]: genderSelected ?? "",
  [`${employee}.person_attributes.gender_other`]: otherGender ?? "",
  [`${employee}.person_attributes.address`]:
		collaborator?.person?.address ?? "",
  [`${employee}.person_attributes.marital_status`]:
		collaborator?.person?.marital_status ?? "",
  [`${employee}.person_attributes.linkedin_profile`]:
		collaborator?.person?.linkedin_profile ?? "",
  [`${employee}.person_attributes.phone_number`]:
		collaborator?.person?.phone_number ?? "",
});

export const getOnlyUpdatedFields = (collaborator, lastData) => {
  const updatedData = lastData && Object.entries(lastData?.person_attributes).filter(
    (attr) => (collaborator.person[attr[0]] || "") !== attr[0],
  );
  return Object.fromEntries(updatedData);
};

export const getMaritalStatusList = (t) => [
  { value: "single", label: t(MARITAL_STATUS.SINGLE) },
  { value: "married", label: t(MARITAL_STATUS.MARRIED) },
  { value: "freeUnion", label: t(MARITAL_STATUS.FREE_UNION) },
  { value: "separated", label: t(MARITAL_STATUS.SEPARATED) },
  { value: "divorced", label: t(MARITAL_STATUS.DIVORCED) },
  { value: "widower", label: t(MARITAL_STATUS.WIDOWER) },
];

export const getGenderList = (t, originalGenderlist) => originalGenderlist?.map((item) => ({
  value: item.id,
  label: t(GENDER_STATUS[item.id]),
}));

export const dispatchUpdate = (dispatch, employee, data, collaboratorId) => ((employee === ROLES.CANDIDATE)
  ? "TODO: update candidate"
  : dispatch(updateCollaborator(data, collaboratorId)));

export const updateCollaboratorAvatar = (dispatch, collaboratorId, image) => {
  dispatch(updateAvatar(collaboratorId, image));
};

export const getSelectedModal = (currentPathname) => new Promise((resolve) => {
  const resolveModal = {
    [localRoutes.personalInfo]: {
      path: localRoutes.personalInfo,
      key: "personalInfo",
    },
    [localRoutes.performance]: {
      path: localRoutes.performance,
      key: "performance",
    },
    [localRoutes.skills]: {
      path: localRoutes.skills,
      key: "skills",
    },
    [localRoutes.documents]: {
      path: localRoutes.documents,
      key: "documents",
    },
    [localRoutes.goodLeader]: {
      path: localRoutes.goodLeader,
      key: "goodLeader",
    },
    [localRoutes.employeeInfo]: {
      path: localRoutes.employeeInfo,
      key: "employeeInfo",
    },
    [localRoutes.feedback]: {
      path: localRoutes.feedback,
      key: "feedback",
    },
    [localRoutes.addFeedback]: {
      path: localRoutes.addFeedback,
      key: "addFeedback",
    },
    [localRoutes.addAcknowledgment]: {
      path: localRoutes.addAcknowledgment,
      key: "addAcknowledgment",
    },
    [localRoutes.salaryInfo]: {
      path: localRoutes.salaryInfo,
      key: "salaryInfo",
    },
    [localRoutes.addMovement]: {
      path: localRoutes.addMovement,
      key: "addMovement",
    },
    [localRoutes.lossRisk]: {
      path: localRoutes.lossRisk,
      key: "lossRisk",
    },
    [localRoutes.ninebox]: {
      path: localRoutes.ninebox,
      key: "ninebox",
    },
  };
  resolve(resolveModal[currentPathname]);
});

export const getRolesListName = (roles) => roles && roles?.map((role) => role.name);

const getRolesName = (otherAdmin, roleName, isValidToKeyPosition) => {
  const keyPositionValidation = isValidToKeyPosition || roleName !== ROLES.SUCCESSIONS_ADMIN;

  if (otherAdmin) {
    return (
      roleName !== ROLES.ADMIN_COLOMBIA
      && roleName !== ROLES.ADMIN
      && roleName !== ROLES.ADMIN_NALA
      && roleName !== ROLES.CANDIDATE
      && keyPositionValidation
    );
  }

  return (
    roleName !== ROLES.ADMIN_NALA
    && roleName !== ROLES.CANDIDATE
    && keyPositionValidation
  );
};

export const getRolesApp = (t, user, otherAdmin) => {
  const isValidToKeyPosition = isValidToKeyPositionModule(user);
  const appRoles = Object.entries(ROLES_NAMES)
    .filter(([roleName]) => getRolesName(otherAdmin, roleName, isValidToKeyPosition))
    .map(([roleName, roleLabel]) => ({
      value: roleName,
      label: t(roleLabel),
    }));

  return appRoles;
};

export const resetDefaultEmployeeValues = (employee, collaborator) => ({
  [`${employee}.person_attributes.full_name`]:
    collaborator?.person?.full_name ?? "",
  [`${employee}.job_position_attributes.position_id`]:
    collaborator?.job_position?.position ?? "",
  [`${employee}.job_position_attributes.division_id`]:
    collaborator?.job_position?.division_id ?? "",
  [`${employee}.job_position_attributes.area_id`]:
      collaborator?.job_position?.area_id ?? "",
  [`${employee}.job_position_attributes.hierarchy_level_id`]:
      collaborator?.job_position?.hierarchy_level ?? "",
  [`${employee}.legal_entity_id`]:
      collaborator?.legal_entity ?? "",
  [`${employee}.job_position_attributes.country_id`]:
      collaborator?.job_position?.country ?? "",
  [`${employee}.job_position_attributes.city_id`]:
      collaborator?.job_position?.city ?? "",
  [`${employee}.email`]:
      collaborator?.email ?? "",
});

export const getOrganizationUnitName = (userUnits) => {
  const organizationUnitsTypes = getDivisionAndAreaType();

  const levelMap = {
    division: organizationUnitsTypes?.division?.id,
    area: organizationUnitsTypes?.area?.id,
    subarea: organizationUnitsTypes?.subarea?.id,
  };

  const result = Object.keys(levelMap).reduce((acc, unitType) => {
    const unitTypeId = levelMap[unitType];
    acc[unitType] = userUnits?.find((orgUnit) => orgUnit.organization_unit_type_id === unitTypeId);
    return acc;
  }, {});

  return result;
};

export const getRolesWithScope = (roles, t) => roles && roles.filter((rol) => ROLES_NAMES[rol.name]).map((item) => ({
  value: item.name,
  label: t(ROLES_NAMES[item.name]),
  countries: item.managed_countries?.map((country) => ({
    id: country.id,
    value: country.name,
    label: country.name,
  })),
  units: item.managed_organization_units?.map((unit) => ({
    id: unit.id,
    value: unit.complete_path,
    label: unit.complete_path,
  })),
}));

export const generateRoles = (
  roles,
  rolesEdited,
  firstTime = false,
) => roles.reduce((acc, item) => {
  const countries = firstTime ? item.countries : rolesEdited?.[item.value]?.countries || [];
  const units = firstTime ? item.units : rolesEdited?.[item.value]?.units || [];
  return {
    ...acc,
    [item.value]: {
      countries,
      units,
    },
  };
}, {});

export const getActionRoles = (rolesCollaborator, userRoles) => {
  const actions = [];
  const beforeRoles = rolesCollaborator.map((rol) => {
    if (!userRoles.find((userRol) => (userRol.value === rol.name))) {
      actions.push({ name: rol.name, _revoke: true });
    }
    return rol;
  });
  userRoles.forEach((userRol) => {
    if (!beforeRoles.find((rol) => (rol.name === userRol.value))) {
      actions.push({ name: userRol.value });
    }
  });
  return actions;
};

const getActionsOfCountries = (beforeCountries, afterCountries) => {
  const removeItems = beforeCountries.filter((id) => !afterCountries.includes(id));
  const hasChanges = !arraysEqual(beforeCountries, afterCountries);

  return { removeItems, hasChanges };
};

const getActionsOfUnits = (beforeUnits, afterUnits) => {
  const removeItems = beforeUnits.filter((id) => !afterUnits.includes(id));
  const hasChanges = !arraysEqual(beforeUnits, afterUnits);

  return { removeItems, hasChanges };
};

export const getActionRolesWithScope = (user, userRoles) => {
  const actions = [];
  const oldRolesCollaborator = user?.roles_with_scope || [];

  oldRolesCollaborator.forEach((rol) => {
    const roleName = rol.name;
    const beforeCountries = rol.managed_countries?.map((country) => country.id) || [];
    const beforeUnits = rol.managed_organization_units?.map((unit) => unit.id) || [];

    if (!(roleName in userRoles)) {
      // When a previous role was deleted
      actions.push({ name: roleName, _revoke: true });
    } else {
      const afterCountries = userRoles[roleName]?.countries?.map((country) => country.id) || [];
      const afterUnits = userRoles[roleName]?.units?.map((unit) => unit.id) || [];

      const {
        removeItems: removeCountries,
        hasChanges: countriesChanged,
      } = getActionsOfCountries(beforeCountries, afterCountries);
      const {
        removeItems: removeUnits,
        hasChanges: unitsChanged,
      } = getActionsOfUnits(beforeUnits, afterUnits);

      if (countriesChanged || unitsChanged) {
        const combinedScope = {};
        if (!isEmpty(afterCountries)) {
          combinedScope.country_ids = afterCountries;
        }
        if (!isEmpty(afterUnits)) {
          combinedScope.organization_unit_ids = afterUnits;
        }

        if (!isEmpty(combinedScope)) {
          actions.push({ name: roleName, scope: combinedScope });
        }

        if (!isEmpty(removeCountries) || !isEmpty(removeUnits)) {
          const revokeScope = {};
          if (!isEmpty(removeCountries)) {
            revokeScope.country_ids = removeCountries;
          }
          if (!isEmpty(removeUnits)) {
            revokeScope.organization_unit_ids = removeUnits;
          }
          actions.push({ name: roleName, _revoke: true, scope: revokeScope });
        }
      }
    }
  });

  Object.keys(userRoles).forEach((roleName) => {
    if (!oldRolesCollaborator.find((rol) => rol.name === roleName)) {
      const afterCountries = userRoles[roleName]?.countries?.map((country) => country.id) || [];
      const afterUnits = userRoles[roleName]?.units?.map((unit) => unit.id) || [];

      const combinedScope = {};
      if (!isEmpty(afterCountries)) {
        combinedScope.country_ids = afterCountries;
      }
      if (!isEmpty(afterUnits)) {
        combinedScope.organization_unit_ids = afterUnits;
      }

      if (!isEmpty(combinedScope)) {
        actions.push({ name: roleName, scope: combinedScope });
      }
    }
  });

  return actions;
};

export const generateAttributesList = (
  dynamicFieldsList,
) => dynamicFieldsList.filter(
  (item) => item.value !== null,
).map(
  (item) => (item.custom_attribute_id ? {
    id: item.custom_attribute_id,
    value: item.value,
  } : {
    dynamic_attribute_id: item.id,
    value: item.value,
  }),
);

export const resetManagerDefaultValues = (employee, collaborator) => ({
  [`${employee}.manager_id`]: collaborator?.manager ?? "",
});

export const resetDefaultSalaryValues = (employee, collaborator) => ({
  [`${employee}.starting_date`]:
    collaborator?.starting_date ?? "",
  [`${employee}.job_position_attributes.type_of_contract_id`]:
    collaborator?.job_position?.type_of_contract?.id ?? "",
  [`${employee}.salary_attributes.gross_salary`]:
    collaborator?.salary?.gross_salary ?? "",
  [`${employee}.salary_attributes.currency`]:
    collaborator?.salary?.currency ?? "",
});

export const resetDefaultMovementValues = (employee, collaborator, date = null, type = null) => ({
  [`${employee}.date`]: date || moment(),
  [`${employee}.type`]: type || OBJECT_KEYS.lateralMovement,
  [`${employee}.job_position_attributes.position_id`]:
    collaborator?.job_position?.position ?? "",
  [`${employee}.job_position_attributes.division_id`]:
    collaborator?.job_position?.division_id ?? "",
  [`${employee}.job_position_attributes.area_id`]:
    collaborator?.job_position?.area_id ?? "",
  [`${employee}.job_position_attributes.manager_id`]:
    collaborator?.manager ?? "",
  [`${employee}.salary_attributes.gross_salary`]:
    collaborator?.salary?.gross_salary ?? "",
  [`${employee}.salary_attributes.currency`]:
    collaborator?.salary?.currency ?? "",
  [`${employee}.job_position_attributes.type_of_contract_id`]:
    collaborator?.job_position?.type_of_contract?.id ?? "",
});

export const getMovementsList = (t, list) => list?.map((item) => ({
  value: item,
  label: t(MOVEMENT_TYPES[item]),
}));

const getOrgUnitNameById = (OrgUnitId) => {
  if (OrgUnitId === null || OrgUnitId === undefined) {
    return "";
  }

  const organizationUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);

  const foundUnit = organizationUnits.find((orgUnit) => orgUnit.id === OrgUnitId);

  return foundUnit ? foundUnit.name : "";
};

export const fields = (collaborator, newValues, type) => [
  {
    key: OBJECT_KEYS.position,
    firstValue: getValueByProp(collaborator?.job_position?.position, OBJECT_KEYS.name),
    lastValue: getValueByProp(newValues?.job_position_attributes?.position_id, OBJECT_KEYS.name),
    label: "common:common.newPosition",
    isValid: (type === OBJECT_KEYS.lateralMovement) || (type === OBJECT_KEYS.promotion),
  },
  {
    key: OBJECT_KEYS.division,
    firstValue: getValueByProp(collaborator?.job_position?.division, OBJECT_KEYS.name),
    lastValue: getOrgUnitNameById(newValues?.job_position_attributes?.division_id),
    label: "common:common.division",
    isValid: (type === OBJECT_KEYS.lateralMovement) || (type === OBJECT_KEYS.promotion),
  },
  {
    key: OBJECT_KEYS.area,
    firstValue: getValueByProp(collaborator?.job_position?.area, OBJECT_KEYS.name),
    lastValue: getOrgUnitNameById(newValues?.job_position_attributes?.area_id),
    label: "common:common.area",
    isValid: (type === OBJECT_KEYS.lateralMovement) || (type === OBJECT_KEYS.promotion),
  },
  {
    key: OBJECT_KEYS.managerId,
    firstValue: getValueByProp(collaborator?.manager, OBJECT_KEYS.fullname),
    lastValue: getValueByProp(newValues?.job_position_attributes?.manager_id, OBJECT_KEYS.fullname),
    label: "common:common.manager",
    isValid: (type === OBJECT_KEYS.lateralMovement) || (type === OBJECT_KEYS.promotion),
  },
  {
    key: OBJECT_KEYS.salary,
    firstValue: parseFloat(collaborator?.salary?.gross_salary),
    lastValue: parseFloat(newValues?.salary_attributes?.gross_salary.replaceAll(",", "")) || 0,
    label: "common:common.newSalary",
    isValid: (type === OBJECT_KEYS.salaryRaise) || (type === OBJECT_KEYS.promotion),
  },
  {
    key: OBJECT_KEYS.currency,
    firstValue: getValueByProp(collaborator?.salary?.currency, OBJECT_KEYS.name),
    lastValue: getValueByProp(newValues?.salary_attributes?.currency, OBJECT_KEYS.name),
    label: "common:common.currency.main",
    isValid: (type === OBJECT_KEYS.salaryRaise) || (type === OBJECT_KEYS.promotion),
  },
  {
    key: OBJECT_KEYS.typeOfContract,
    firstValue: getValueByProp(collaborator?.job_position?.type_of_contract, OBJECT_KEYS.name),
    lastValue: getItemFromLocalStorage(LOCAL_STORAGE_NAMES.typeOfContract).find(
      (type) => (type.id === newValues?.job_position_attributes?.type_of_contract_id),
    )?.name,
    label: "common:common.typeOfContract",
    isValid: (type === OBJECT_KEYS.salaryRaise) || (type === OBJECT_KEYS.promotion),
  },
];

export const getNewValues = (collaborator, newValues, type) => {
  const updates = [];
  fields(collaborator, newValues, type).filter((item) => item.isValid).forEach((item) => {
    if ((item.firstValue || "") !== item.lastValue) {
      updates.push(item);
    }
  });
  return updates;
};

export const setUpdatedData = (employee, newValues) => ({
  [`${employee}.date`]: newValues.date,
  [`${employee}.type`]: newValues.type,
  [`${employee}.job_position_attributes.position_id`]:
    newValues?.job_position_attributes?.position_id,
  [`${employee}.job_position_attributes.division_id`]:
    newValues?.job_position_attributes?.division_id,
  [`${employee}.job_position_attributes.area_id`]:
    newValues?.job_position_attributes?.area_id,
  [`${employee}.job_position_attributes.manager_id`]:
    newValues?.job_position_attributes?.manager_id,
  [`${employee}.salary_attributes.gross_salary`]:
    newValues?.salary_attributes?.gross_salary,
  [`${employee}.salary_attributes.currency`]:
    newValues?.salary_attributes?.currency,
  [`${employee}.job_position_attributes.type_of_contract_id`]:
    newValues?.job_position_attributes?.type_of_contract_id,
});

export const getItemSelected = (list, selected, prop = OBJECT_KEYS.value) => list?.find(
  (e) => (e[prop] === selected)
    || (e.es?.toLowerCase() === selected?.toLowerCase())
    || (e.en?.toLowerCase() === selected?.toLowerCase())
    || (e.pt?.toLowerCase() === selected?.toLowerCase()),
);

export const generateSubmitData = (
  data,
  employee,
  rolesWithScope,
  employeeInfoStates,
  dynamicFieldsList,
  allRolesList,
) => {
  const listUnits = [];
  const divisionId = data[employee].job_position_attributes?.division_id;
  const areaId = data[employee].job_position_attributes?.area_id;

  if (divisionId) {
    listUnits.push(divisionId);
    if (areaId) {
      listUnits.push(areaId);
    }
  }

  const modifiedData = { ...data };

  delete modifiedData[employee].job_position_attributes.division_id;
  delete modifiedData[employee].job_position_attributes.area_id;

  Object.entries(modifiedData[employee].job_position_attributes).forEach(([key, value]) => {
    modifiedData[employee].job_position_attributes[key] = getValueByProp(value, OBJECT_KEYS.id);
  });

  modifiedData[employee].job_position_attributes.organization_unit_ids = listUnits;

  modifiedData[employee].legal_entity_id = getValueByProp(
    modifiedData[employee].legal_entity_id,
    OBJECT_KEYS.id,
  );

  const updatedRoles = [...employeeInfoStates.employeeRoles];

  const hasSuccessionsAdmin = updatedRoles.some((tag) => tag.value === ROLES.SUCCESSIONS_ADMIN);
  const hasAdministrator = updatedRoles.some((tag) => tag.value === ROLES.ADMIN);

  if (hasSuccessionsAdmin && !hasAdministrator) {
    const administratorRole = allRolesList.find((role) => role.value === ROLES.ADMIN);
    if (administratorRole) {
      administratorRole.countries = [];
      updatedRoles.push(administratorRole);
    }
  }

  if (updatedRoles.length > 0) {
    modifiedData[employee].user_roles = getActionRoles(
      rolesWithScope,
      updatedRoles,
    );
  }

  modifiedData[employee].custom_attributes_attributes = generateAttributesList(dynamicFieldsList);

  return modifiedData;
};
