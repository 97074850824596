import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";
import SkeletonLoader from "components/SkeletonLoader";
import { SKELETONS_NUMBER } from "common/constants";
import { getPercent } from "common/utils";
import { LINEAL_GRAPHICS } from "theme/palette";
import { options } from "./functions/ChartOptions";
import { StyledChartContainer } from "./styles";

const PerformanceChart = (props) => {
  const {
    labels, data, title, isLoading,
  } = props;

  const getDataPercent = (values) => {
    return values.map((item) => {
      return getPercent(item);
    });
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: title,
        backgroundColor: LINEAL_GRAPHICS.BG_COLOR,
        borderColor: LINEAL_GRAPHICS.LINE_COLOR,
        borderWidth: 2,
        data: getDataPercent(data),
        pointBackgroundColor: LINEAL_GRAPHICS.DOT_COLOR,
      },
    ],
  };

  return (
    <StyledChartContainer data-testid={ "performance-chart-component" } >
      {isLoading ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
      ) : (
        <Line data={ chartData } plugins={ ChartDataLabels } options={ options } />
      )}
    </StyledChartContainer>
  );
};

PerformanceChart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default PerformanceChart;
