import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const useStyles = makeStyles(() => ({
  btnRight: {
    textAlign: "right",
  },
}));

export const StyledTitle = styled(Typography)`
  color: ${palette.black};
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-top: 15px;
  margin-bottom: 10px;
  svg {
    margin-left:10px;
  }
`;

export const StyledSubTitle = styled(Typography)`
  margin-bottom: 15px;
`;
