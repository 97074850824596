import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { getFeedbackToDownloadAsync } from "redux/actions/feedbackActions";

const useFeedbacks = (query, queryKey) => {
  const [allFeedbacks, setAllFeedbacks] = useState([]);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const pageSize = 200;

  const fetchFeedbacks = async ({ pageParam = 1 }) => {
    const response = await getFeedbackToDownloadAsync(query, pageParam, pageSize);
    return response;
  };

  const {
    data,
    isLoading,
    error,
  } = useInfiniteQuery(
    queryKey,
    fetchFeedbacks,
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalFetched = allPages.reduce((acc, page) => acc + page.data.length, 0);
        return totalFetched < lastPage.total ? lastPage.page + 1 : undefined;
      },
      enabled: false,
    },
  );

  const fetchAllFeedbacks = async () => {
    setIsFetchingAll(true);
    setAllFeedbacks([]);
    const currentPage = 1;
    let results = [];
    let total = Infinity;

    const fetchPage = async (page) => {
      const response = await fetchFeedbacks({ pageParam: page });
      results = results.concat(response.data);
      total = parseInt(response.total, 10);
      if (results.length < total && response.data.length === pageSize) {
        await fetchPage(page + 1);
      }
    };

    await fetchPage(currentPage);

    setAllFeedbacks(results);
    setIsFetchingAll(false);
  };

  useEffect(() => {
    if (data && data.pages) {
      const combinedResults = data.pages.flatMap((page) => page.data);
      setAllFeedbacks(combinedResults);
    }
  }, [data]);

  return {
    feedbacks: allFeedbacks,
    isLoadingFeedbacks: isLoading,
    isFetchingFeedbacks: isFetchingAll,
    feedbacksError: error,
    refetchFeedbacks: fetchAllFeedbacks,
  };
};

export default useFeedbacks;
