import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledTeamList = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  h3 {
    font-size: 14px;
    font-weight: 500;
  }
  h4 {
    font-size: 14px;
  }
  a {
    font-size: 12px;
  }
`;

export const StyledDays = styled.div`
  font-weight: 500;
  text-transform: lowercase;
  display: flex;
  align-items: center;
`;

export const StyledSpacedGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

export const StyledSatisfaction = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
    width: 25px;
    height: 25px;
  }
`;

export const StyledNoResult = styled.div`
  color: ${palette.primary.light};
`;

export const StyledTasksContainer = styled.div`
  min-height: 320px;
  max-height: 320px;
  overflow-y: scroll;
`;

export const StyledTasksGrid = styled(Grid)`
  margin-top: 25px;
`;

export const StyledAnswer = styled.div`
  padding: 12px;
  font-size: 14px;
  line-height: 21px;
`;

export const StyledTeamContent = styled.div`
  font-size: 20px;
  text-align: center;
  color: ${palette.text.link};
  font-weight: 700;
  padding: 0 10px;
  .MuiTypography-h4 {
    color: ${palette.text.link};
    font-size: 110px;
    line-height: 115px;
    font-weight: 700;
  }
`;

export const StyledFiltersGrid = styled(Grid)`
  margin: 24px 0;
`;

export const StyledCardsGrid = styled(Grid)`
  margin-bottom: 10px;
`;
