import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CARD_RESULT } from "theme/palette";

const cardColors = {
  blueCard: CARD_RESULT.blue,
  purpleCard: CARD_RESULT.purple,
  greenCard: CARD_RESULT.green,
};

export const StyledCard = styled(Card)`
  width: 100%;
  &.MuiPaper-root {
    height: 100%;
    background: ${(props) => cardColors[props.color]};
    box-shadow: none;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    padding: 16px 16px 24px;
  }
  @media (max-width: 1040px) {
    &.MuiCardContent-root {
    min-height: 160px;
    }
  }
  @media (max-width: 910px) {
    &.MuiCardContent-root {
      min-height: 0;
    }
  }
`;

export const StyledTitle = styled(Typography)`
  font-weight: 700;
`;

export const StyledIcon = styled.img`
  width: 50px;
  height: 50px;
`;
