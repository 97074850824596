import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

export const useStyles = makeStyles((theme) => ({
  input: {
    padding: "25px 10px",
    display: "flex",
    alignItems: "flex-end",
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  iconHover: {
    "& :hover": {
      cursor: "pointer",
    },
  },
  iconDisabled: {
    color: theme.palette.text.iconDisabled,
  },
}));

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    fieldset {
      border: none;
    }
  }
`;
