import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import Box from "@mui/material/Box";
import { ReactComponent as JobSatisfaction } from "assets/images/talent-drain/job_satisfaction.svg";
import { ReactComponent as WellBalanced } from "assets/images/talent-drain/well_balanced.svg";
import { ReactComponent as InclusiveGrowth } from "assets/images/talent-drain/inclusive_growth.svg";
import { ReactComponent as LeaderImpact } from "assets/images/talent-drain/leader_impact.svg";
import { ReactComponent as Collaboration } from "assets/images/talent-drain/collaboration.svg";
import { ReactComponent as Compensation } from "assets/images/talent-drain/compensation.svg";
import { ReactComponent as Engagement } from "assets/images/talent-drain/engagement.svg";
import charts from "theme/charts";
import { getCurrentLanguage } from "common/utils";
import { isEqual } from "common/helpers";
import {
  INDEX, LANGUAGES, ALIGN_ITEMS, FILTER_TYPE,
} from "common/constants";
import CustomChartTooltip from "components/CustomChartTooltip";
import { StyledSvg, StyledText } from "styledComponents/Svg";
import { StyledTooltipTitle, StyledTooltipText } from "styledComponents/View";
import { StyledBox } from "../styles";

const EMPTY_CHART = ["job_satisfaction", "well_balanced", "inclusive_growth", "leader_impact", "collaboration", "compensation", "engagement"];

// const getVertices = (data) => {
//   const currentVertices = data[INDEX.zero].attrition_axis_values;
//   return [...Array(currentVertices.length).keys()].map((item) => currentVertices[item].slug);
// };

export const getFormattedObject = (data) => {
  if (isEmpty(data) || isNull(data)) {
    return EMPTY_CHART.map((item) => ({ name: item }));
  }
  // const vertices = getVertices(data);
  // FIXME: remove EMPTY_CHART and use getVertices
  const eachObj = EMPTY_CHART.map((item) => {
    const mainObject = {
      name: item,
    };
    let tempData = data;
    if (tempData.length > 1) {
      tempData = data.filter((filter) => filter.type !== FILTER_TYPE.general);
    }
    tempData.forEach((empValue) => {
      const attritionValues = empValue.values;
      const attritionName = empValue.name || empValue.type;
      mainObject[`${attritionName}_filter`] = attritionValues[item] * 100;
    });
    return mainObject;
  });

  return eachObj;
};
export const getFilterNames = (data) => {
  let tempData = data;
  if (tempData?.length > INDEX.one) {
    tempData = data.filter((filter) => filter.type !== FILTER_TYPE.general);
  }
  return tempData?.map((item) => item.name || item.type);
};

// Returns an array of the slugs from the given vertices array, excluding the ones with null values
// const getIndividualVertices = (currentVertices) => [...Array(currentVertices.length).keys()]
//   .filter((item) => currentVertices[item].value !== null)
//   .map((item) => currentVertices[item].slug);

// Takes data as input and returns a formatted object based on the data
export const getFormattedIndividualObject = (data) => {
  // If data is not empty
  if (data?.length > 0) {
    // Create a new array based on the empty chart items
    const eachObj = EMPTY_CHART.map((item) => {
      const mainObject = {
        name: item,
      };
      // Find the corresponding value in the data array based on the slug
      const value = data.find((attrition) => attrition.slug === item)?.value || null;
      // Set the general_filter property of the mainObject based on the value
      mainObject.general_filter = value === null ? null : value * 100;
      return mainObject;
    });
    // Return the array of formatted objects
    return eachObj;
  }
  // If data is empty or null, return an array with empty chart items
  return EMPTY_CHART.map((item) => ({ name: item }));
};

export const renderCustomAxisTick = (props, t, isMobile) => {
  const { x, y, payload } = props;

  const ATTRITION_AXIS = {
    job_satisfaction: <JobSatisfaction />,
    well_balanced: <WellBalanced />,
    inclusive_growth: <InclusiveGrowth />,
    leader_impact: <LeaderImpact />,
    collaboration: <Collaboration />,
    compensation: <Compensation />,
    engagement: <Engagement />,
  };

  const X_POSITIONS = {
    job_satisfaction: x - 10,
    well_balanced: x + 30,
    inclusive_growth: x + 40,
    leader_impact: x + 50,
    collaboration: x - 20,
    compensation: x - 70,
    engagement: x - 50,
  };

  const Y_POSITIONS = {
    job_satisfaction: y - 60,
    well_balanced: y - 40,
    inclusive_growth: y - 50,
    leader_impact: y - 40,
    collaboration: y,
    compensation: y - 50,
    engagement: y - 50,
  };

  const CURRENT_LANG = getCurrentLanguage();
  const jobSatisfactionLabel = CURRENT_LANG === LANGUAGES.es ? 50 : CURRENT_LANG === LANGUAGES.en ? 30 : 60;
  const jobSatisfactionIcon = CURRENT_LANG === LANGUAGES.es ? 110 : CURRENT_LANG === LANGUAGES.en ? 90 : 120;

  const wellBalancedIcon = CURRENT_LANG === LANGUAGES.es ? 90 : CURRENT_LANG === LANGUAGES.en ? 80 : 90;
  const inclusiveGrowthIcon = CURRENT_LANG === LANGUAGES.es ? 120 : CURRENT_LANG === LANGUAGES.en ? 90 : 120;
  const leaderImpactIcon = CURRENT_LANG === LANGUAGES.es ? 90 : CURRENT_LANG === LANGUAGES.en ? 80 : 90;
  const compensationIcon = CURRENT_LANG === LANGUAGES.es ? 85 : CURRENT_LANG === LANGUAGES.en ? 80 : 80;

  const engagementLabel = CURRENT_LANG === LANGUAGES.es ? 80 : CURRENT_LANG === LANGUAGES.en ? 70 : 110;
  const engagementIcon = CURRENT_LANG === LANGUAGES.es ? 70 : CURRENT_LANG === LANGUAGES.en ? 70 : 100;

  const TEXT_X_POSITIONS = {
    job_satisfaction: x - jobSatisfactionLabel,
    well_balanced: x,
    inclusive_growth: x,
    leader_impact: x + 5,
    collaboration: x - 60,
    compensation: x - 100,
    engagement: x - engagementLabel,
  };

  const icon = ATTRITION_AXIS[payload.value];
  const axisX = X_POSITIONS[payload.value];
  const axisY = Y_POSITIONS[payload.value];
  const textAxisX = TEXT_X_POSITIONS[payload.value];

  const TEXT_Y_POSITIONS = {
    job_satisfaction: axisY + 50,
    well_balanced: axisY + 50,
    inclusive_growth: axisY + 50,
    leader_impact: axisY + 50,
    collaboration: axisY + 40,
    compensation: axisY + 50,
    engagement: axisY + 50,
  };

  const textAxisY = TEXT_Y_POSITIONS[payload.value];

  const ICON_X_POSITIONS = {
    job_satisfaction: textAxisX + jobSatisfactionIcon,
    well_balanced: textAxisX + wellBalancedIcon,
    inclusive_growth: textAxisX + inclusiveGrowthIcon,
    leader_impact: textAxisX + leaderImpactIcon,
    collaboration: textAxisX + 75,
    compensation: textAxisX + compensationIcon,
    engagement: textAxisX + engagementIcon,
  };

  const iconAxisX = ICON_X_POSITIONS[payload.value];

  // Mobile
  const mobileXyPositions = {
    x: x - 14,
    y: y - (isEqual(payload.index, 0) ? 30 : 10),
  };

  const TEXT_X_MOBILE_POSITIONS = {
    job_satisfaction: x - jobSatisfactionLabel,
    well_balanced: x - 40,
    inclusive_growth: x - 95,
    leader_impact: x - 30,
    collaboration: x - 30,
    compensation: x - 25,
    engagement: x - 30,
  };

  const textMobileAxisX = TEXT_X_MOBILE_POSITIONS[payload.value];

  const TEXT_Y_MOBILE_POSITIONS = {
    job_satisfaction: axisY + 20,
    well_balanced: axisY + 20,
    inclusive_growth: axisY + 30,
    leader_impact: axisY + 70,
    collaboration: axisY + 30,
    compensation: axisY + 30,
    engagement: axisY + 30,
  };

  const textMobileAxisY = TEXT_Y_MOBILE_POSITIONS[payload.value];

  return (
    <>
      {isMobile
        ? <StyledText x={ textMobileAxisX } y={ textMobileAxisY } >{t(`chart.labels.${payload.value}`)}</StyledText>
        : (
          <>
            <StyledText x={ textAxisX } y={ textAxisY } >{t(`chart.labels.${payload.value}`)}</StyledText>
            <StyledSvg x={ iconAxisX } y={ textAxisY - 12 } width={ "14" } height={ "14" } viewBox={ "0 0 14 14" }>
              <CustomChartTooltip>
                <StyledBox>
                  {icon}
                  <StyledTooltipTitle>{t("chart.tooltips.description")}</StyledTooltipTitle>
                </StyledBox>
                <StyledTooltipText>{t(`chart.tooltips.${payload?.value}`)}</StyledTooltipText>
              </CustomChartTooltip>
            </StyledSvg>
          </>
        )}
      <StyledSvg
        x={ isMobile ? mobileXyPositions.x : axisX }
        y={ isMobile ? mobileXyPositions.y : axisY }
        width={ isMobile ? charts.sizeIcon.standar : charts.sizeIcon.medium }
        height={ isMobile ? charts.sizeIcon.standar : charts.sizeIcon.medium }
        viewBox={ `0 0 ${charts.sizeIcon.medium} ${charts.sizeIcon.medium}` }
      >
        {icon}
      </StyledSvg>
    </>
  );
};

export const getVerticesLabelIcon = (label) => {
  const icons = [
    <JobSatisfaction />,
    <WellBalanced />,
    <InclusiveGrowth />,
    <LeaderImpact />,
    <Collaboration />,
    <Compensation />,
    <Engagement />,
  ];

  const vertices = EMPTY_CHART.map((item, index) => ({
    label: item,
    icon: icons[index],
  }));

  const verticeIcon = vertices.find((item) => item.label === label);
  return verticeIcon;
};

export const getRandomColor = (index) => charts.colors.spiderChart[index];
