import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Mental from "assets/images/potential/skills/mental.svg";
import Interpersonal from "assets/images/potential/skills/interpersonal.svg";
import Change from "assets/images/potential/skills/change.svg";
import Results from "assets/images/potential/skills/results.svg";
import Awareness from "assets/images/potential/skills/awareness.svg";
import { TYPES_RESULTS, VARIANT } from "common/constants";
import {
  skillLevels,
  skillTypeKeys,
} from "views_refactor/Profile/functions/skills";
import {
  StyledTitle,
  StyledContent,
  StyledAdvise,
  StyledAdviseTitle,
} from "../styles";

export const getIcon = (skillType) => {
  let imageIcon;
  switch (skillType) {
  case TYPES_RESULTS.skills.mental:
    imageIcon = Mental;
    break;
  case TYPES_RESULTS.skills.interpersonal:
    imageIcon = Interpersonal;
    break;
  case TYPES_RESULTS.skills.change:
    imageIcon = Change;
    break;
  case TYPES_RESULTS.skills.results:
    imageIcon = Results;
    break;
  case TYPES_RESULTS.skills.awareness:
    imageIcon = Awareness;
    break;
  default:
    break;
  }
  return imageIcon;
};

export const getTooltip = (skillType, t) => skillTypeKeys
  .filter((item) => item.value === skillType)
  .map((item) => t(`tabPotential.skills.tooltips.${item.id}`));

const getResult = (skillResult, skill, t, resultToSkillLevelMap) => {
  let result = "";
  const skillLevelName = resultToSkillLevelMap[skillResult];
  const list = skillLevels
    .filter((item) => item.name === skillLevelName)
    .map((level) => ({
      skillLevel: level.key,
      skillTitle: t(`tabPotential.skills.titleContent.${level.key}`),
      skillContent: t(`tabPotential.skills.content.${skill}.${level.key}`, {
        returnObjects: true,
      }),
      skillAdvise: t(`tabPotential.skills.advise.${skill}`),
    }));

  const {
    skillLevel, skillTitle, skillContent, skillAdvise,
  } = [
    ...list,
  ].shift();
  const ul = skillContent.map((content) => <li key={ content }>{content}</li>);

  result = (
    <>
      <StyledTitle variant={ VARIANT.h5 }>{skillTitle}</StyledTitle>
      <StyledContent>{ul}</StyledContent>
      {(skillLevel === TYPES_RESULTS.large) && (
        <StyledAdvise>
          <StyledAdviseTitle>
            <ReportProblemOutlinedIcon />
            <StyledTitle variant={ VARIANT.h5 }>
              {t("tabPotential.careful")}
            </StyledTitle>
          </StyledAdviseTitle>
          {skillAdvise}
        </StyledAdvise>
      )}
    </>
  );
  return result;
};

export const getContent = (skillType, skillResult, t, resultToSkillLevelMap) => skillTypeKeys
  .filter((item) => item.value === skillType)
  .map((result) => getResult(skillResult, result.id, t, resultToSkillLevelMap));
