import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Accordion from "components/Accordion";
import { BORDER_ACCORDION_TYPE } from "common/constants";
import { RESULT_SECTIONS, GOOD_LEADER_RESULTS_GROUPS } from "common/constants/goodLeader";
import CardResult from "../CardResult";
import {
  StyledAccordionContainer, StyledAccordionHeader, StyledCardsContainer, StyledIcon, StyledTitle,
} from "./styles";

const CategoriesResults = (props) => {
  const { values, handleModal, isMobile } = props;
  const completeResultsBySection = values?.complete_results_by_section || [];
  const { t } = useTranslation("performance");

  const getAccordionData = () => {
    const resultsGroupedByScales = GOOD_LEADER_RESULTS_GROUPS.map((group) => {
      const resultsByScale = completeResultsBySection.filter(
        (result) => (result.category === group.id)
      );
      return { ...group, results: resultsByScale };
    });

    const groupsWithResults = resultsGroupedByScales.filter((group) => (
      group?.results?.length > 0
    ));

    const accordionData = groupsWithResults.map((group) => {
      const groupData = {
        title: t(`goodLeaderGroupScales.${group.id}`),
        customHeader: (
          <StyledAccordionHeader key={ group.id }>
            <StyledIcon src={ group.icon } alt={ group.id } />
            <StyledTitle>{ t(`goodLeaderGroupScales.${group.id}`) }</StyledTitle>
          </StyledAccordionHeader>
        ),
      };

      const resultsByGroup = group?.results?.map((item) => {
        const isProgressLine = (!isMobile && item?.evaluation_results?.length > 0);
        const resultSection = RESULT_SECTIONS(t).filter(
          (section) => section.translations.includes(item?.name),
        );

        return (
          <CardResult
            key={ item?.name }
            item={ item }
            color={ group.color }
            resultSection={ resultSection }
            handleModal={ handleModal }
            isProgressLine={ isProgressLine }
          />
        );
      });

      groupData.customRender = (
        <StyledCardsContainer container>{ resultsByGroup }</StyledCardsContainer>
      );
      return groupData;
    });
    return accordionData;
  };

  return (
    <StyledAccordionContainer data-testid={ "good-leader-categories" }>
      <Accordion
        type={ BORDER_ACCORDION_TYPE.rounded }
        data={ getAccordionData() }
        isDefaultIconStyle
      />
    </StyledAccordionContainer>
  );
};

CategoriesResults.propTypes = {
  values: PropTypes.object.isRequired,
  handleModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default CategoriesResults;
