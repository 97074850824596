import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import Accordion from "components/Accordion";
import {
  ALIGN_ITEMS,
  BORDER_ACCORDION_TYPE,
  MIN_VALUE,
  OBJECT_KEYS,
  SKELETONS_NUMBER,
  VARIANT,
} from "common/constants";
import { ENGAGEMENT_SEARCH } from "common/constants/engagement";
import { isEmpty, isEqual, isNull } from "common/helpers";
import {
  getList as getEngagementProcess,
} from "redux/actions/engagementActions";
import { getOne as getSurveyResult, resetStateOne as resetStateSurveyResult } from "redux/actions/performance/surveyResultsActions";
import { getOptionsByQuestions } from "views/Engagement/functions";
import { StyledAccordionContainer } from "views/Engagement/styledComponents";
import { getOpenQuestions } from "views/CollaboratorsFollowUp/functions";
import {
  StyledProfileImage,
  StyledContent,
  StyledSelect,
  StyledGrid,
  StyledSection,
  StyledTitle,
  StyledName,
  StyledPosition,
} from "./styles";

const SatisfactionByCollaborator = (props) => {
  const { employee } = props;
  const { t, i18n } = useTranslation("engagement");
  const [processSelected, setProcessSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { language } = i18n;

  const dispatch = useDispatch();
  const processes = employee?.follow_up_processes_results?.results?.map((e) => ({
    ...e.engagement,
    value: e.engagement.id,
    label: e.engagement.name,
  }));

  const {
    one: surveyResult,
    isLoadingOne: isLoadingSurveyResult,
  } = useSelector(({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer);

  useEffect(() => {
    dispatch(getEngagementProcess());
  }, [dispatch]);

  useEffect(() => {
    if (processes && !isEmpty(processes) && isLoading) {
      let selected = null;
      if (isNull(processSelected)) {
        selected = processes[MIN_VALUE];
        setProcessSelected(selected.id);
      } else {
        selected = processes.find((e) => isEqual(e.id, processSelected));
      }
      if (selected.survey_result_id) {
        dispatch(getSurveyResult(selected.survey_result_id));
      } else {
        dispatch(resetStateSurveyResult());
      }
      setIsLoading(false);
    }
  }, [dispatch, processes, processSelected, isLoading]);

  const handleChange = (prop, event) => {
    setIsLoading(true);
    setProcessSelected(event.target.value);
  };

  const getComments = (openQuestions) => {
    const comments = getOpenQuestions(openQuestions);
    return !isEmpty(comments)
      && (
        <>
          <StyledTitle>{ t("comments") }</StyledTitle>
          <StyledAccordionContainer>
            <Accordion
              isDefaultIconStyle
              type={ BORDER_ACCORDION_TYPE.square }
              data={ comments }
              iconLeft
            />
          </StyledAccordionContainer>
        </>
      );
  };

  const getContent = () => {
    if (isLoadingSurveyResult) {
      return <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />;
    }
    const openQuestions = surveyResult?.complete_results_by_section.filter((e) => e.open);
    const engagementTopics = surveyResult?.complete_results_by_section.filter((e) => !e.open
      && !isEqual(e.weighing, MIN_VALUE)
      && !isEqual(ENGAGEMENT_SEARCH.nps, e.name.toLowerCase()));

    return surveyResult ? (
      <>
        <SwitchSelectorContent
          options={ getOptionsByQuestions(
            t,
            engagementTopics,
            language,
            OBJECT_KEYS.score,
          ) }
        />
        { !isEmpty(openQuestions) && getComments(openQuestions) }
      </>
    ) : (
      <NoDataMessage />
    );
  };

  return (
    <Grid container justifyContent={ ALIGN_ITEMS.flexEnd } data-testid={ "satisfaction-collaborator-view-component" }>
      <Grid item xs={ 12 }>
        <StyledSection>
          <StyledProfileImage src={ employee?.profile_img_url } />
          <StyledContent>
            <StyledName variant={ VARIANT.h3 }>{ employee?.full_name }</StyledName>
            <StyledPosition>{ employee?.position_name }</StyledPosition>
          </StyledContent>
        </StyledSection>
      </Grid>
      <StyledGrid item xs={ 12 } md={ 5 } >
        {!isLoading && (
          <StyledSelect
            id={ OBJECT_KEYS.evaluation }
            label={ t("selectEvaluation") }
            menuItems={ processes }
            value={ processSelected }
            disabled={ isEmpty(processes) }
            onChange={ handleChange }
          />
        )}
      </StyledGrid>
      <Grid item xs={ 12 }>
        { getContent() }
      </Grid>
    </Grid>
  );
};

SatisfactionByCollaborator.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default SatisfactionByCollaborator;
