import styled from "styled-components";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import palette from "theme/palette";

export const StyledList = styled(List)`
  max-height: 300px;
  overflow: scroll;
  .MuiListItem-root {
    align-items: start;
    padding: 0;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  padding: 20px;
  text-align: justify;
  background: ${palette.background.lightGrey};
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
`;
