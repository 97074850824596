import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledGrid = styled(Grid)`
  margin-top: 16px;
`;

export const StyledGridContent = styled(Grid)`
  display: grid;
`;

export const StyledLeaderComment = styled.div`
  padding: 16px;
  border-radius: 5px;
  background-color: ${palette.background.lightGrey};
`;
