import EditFormModal from "./components/EditFormModal";
import PerformanceModal from "./components/PerformanceModal";
import SkillsModal from "./components/SkillsModal";
import DocumentsModal from "./components/DocumentsModal";
import GoodLeaderModal from "./components/GoodLeaderModal";
import NineBoxModal from "./components/NineBoxModal";

const Modals = (props) => (
  <>
    <EditFormModal { ...props } />
    <PerformanceModal { ...props } />
    <SkillsModal { ...props } />
    <DocumentsModal { ...props } />
    <GoodLeaderModal { ...props } />
    <NineBoxModal { ...props } />
  </>
);

export default Modals;
