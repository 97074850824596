import { ACTIONS, INDEX } from "common/constants";
import { isEqual, isNotValid } from "common/helpers";

export const getDataBreadcrumbs = ( t, module) => [
  {
    label: t("Onboarding:title"),
    path: "/onboarding-candidates",
  },
  {
    label: t("candidates:candidate"),
    path: "/onboarding",
  },
  {
    label: module === ACTIONS.new ? t("actions.create") : t("actions.edit"),
    path: "",
  },
];

export const resetFormData = (data) => {
  return {
    "onboarding_template.title": data?.title || "",
  };
};

export const getActionByAttributes = (previous, update, name) => {
  const actions = [];
  previous.forEach((oldItem) => {
    const itemSearch = update.find((lastItem) => isEqual(lastItem.idParent, oldItem.id));
    if (itemSearch) {
      const oldData = {
        id: oldItem.id,
      };
      if (!isEqual(Boolean(itemSearch.required), Boolean(oldItem.required))) {
        oldData.required = itemSearch.required;
      }
      if (itemSearch?.order >= INDEX.zero) {
        oldData.order = itemSearch.order;
      }
      actions.push(oldData);
    } else {
      actions.push({
        id: oldItem.id,
        _destroy: true,
      });
    }
  });

  update.filter((lastItem) => isNotValid(lastItem.idParent)).forEach((item) => {
    const itemData = {
      [name]: item.id,
      required: item.required,
    };
    if (item?.order >= INDEX.zero) {
      itemData.order = item.order;
    }
    actions.push(itemData);
  });
  return actions;
};
