import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import CardResult from "components/CardResult";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleAlert from "components/SimpleAlert";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import Filter from "components/Filter";
import Select from "components/Select";
import SatisfactionIcon from "assets/images/engagement/satisfaction.svg";
import ParticipationIcon from "assets/images/engagement/participa.svg";
import NPSIcon from "assets/images/engagement/nps-total.svg";
import {
  MIN_VALUE,
  OBJECT_KEYS,
  SKELETONS_NUMBER,
  VARIANT,
  INFO,
  COMPANY_SLUGS,
  ROLES,
  SKELETON_VARIANT,
} from "common/constants";
import { MIN_FORMS_FILLED } from "common/constants/engagement";
import { CARD_CLASS } from "common/constants/charts";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import {
  getPercent,
  isAdminOrManager,
  isCurrentCompanySlug,
  isCurrentRole,
  isAdmin,
  getIdParam,
} from "common/utils";
import { isNull, isEmpty, isEqual } from "common/helpers";
import useComplexState from "hooks/utils/useComplexState";
import { SessionContext } from "modules/session/context";
import {
  getList as getEngagementProcess,
  getCommentsByDepartment,
  getSatisfactionByDepartment,
  getOverallStatistics,
  getParticipationByProcess,
  getSatisfactionByTopic,
  getEngagementEvolution,
} from "redux/actions/engagementActions";
import {
  getDataBarNps,
  getDataSatisfactionBar,
  getParticipation,
  getOptionsByQuestions,
  isInvalidFormsFilled,
  getNpsAndSatisfactionChart,
  getNpsAndSatisfactionResult,
  orderEngagementProcesses,
} from "../../functions";
import CommentsByDivision from "../CommentsByDivision";
import SatisfactionByTopic from "../SatisfactionByTopic";
import ComparativePeriods from "../ComparativePeriods";
import { StyledSubtitle } from "../../styledComponents";
import {
  StyledHeader,
  StyledTitle,
  StyledGridContainer,
  StyledParticipationResult,
  StyledParticipationTitle,
  StyledCardContent,
  StyledCard,
  StyledFilterGrid,
  StyledDashboardContainer,
} from "./styles";

const GeneralContent = () => {
  const { t, i18n } = useTranslation("engagement");
  const { language } = i18n;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const {
    state: { user },
  } = useContext(SessionContext);
  const dispatch = useDispatch();

  // Engagement process list
  const {
    list: engagementProcesses,
    isLoadingList: isLoadingEngagementProcesses,
    engagementSatisfaction,
    isLoadingEngagementSatisfaction,
    engagementOverall,
    isLoadingEngagementOverall,
    engagementParticipation,
    isLoadingEngagementParticipation,
    engagementTopic,
    isLoadingEngagementTopic,
  } = useSelector(({ engagementProcessReducer }) => engagementProcessReducer);

  // If is haulmer check for manager role if not then nothing!
  const isHaulmerCompany = isCurrentCompanySlug(user, COMPANY_SLUGS.haulmer);
  const isNotHaulmerLeader = !isHaulmerCompany || (isCurrentCompanySlug(user, COMPANY_SLUGS.haulmer)
    && (isCurrentRole(user, ROLES.ADMIN) || isCurrentRole(user, ROLES.ADMIN_NALA)));
  const isValidToSeeNpsAndSatisfaction = !isEqual(user?.company?.slug, COMPANY_SLUGS.comunidad_feliz)
    || (isAdminOrManager(user?.userCookies) && isNotHaulmerLeader);

  const [values, setValues] = useComplexState({
    evaluation: null,
    evaluations: null,
    hasInvalidResult: false,
    minFilled: null,
    satisfactionByTopic: null,
    npsAndSatisfaction: null,
    satisfactionByDepartment: null,
  });
  const [filterQuery, setFilterQuery] = useState();
  const location = useLocation();

  // Search all engagement processes
  useEffect(() => {
    if (isNull(engagementProcesses) && !isLoadingEngagementProcesses) {
      dispatch(getEngagementProcess());
    }
  }, [engagementProcesses, isLoadingEngagementProcesses, dispatch]);

  useEffect(() => {
    if (engagementProcesses && !isLoadingEngagementProcesses) {
      if (!isEmpty(engagementProcesses)) {
        const evaluations = orderEngagementProcesses(engagementProcesses, language);
        const processId = getIdParam(location.search);
        /* If processId don't include in list then get the first element of the list */
        const evaluation = evaluations.find((item) => item.value === processId)?.value
          || evaluations[0]?.value;
        setValues({
          evaluations,
          evaluation,
        });
      } else {
        setValues({
          satisfactionByTopic: getOptionsByQuestions(t, [], language),
          npsAndSatisfaction: getNpsAndSatisfactionChart(t, [], [], getNpsAndSatisfactionResult([], t)),
        });
      }
    }
    // eslint-disable-next-line
  }, [engagementProcesses, isLoadingEngagementProcesses]);

  const handleChange = (prop, event) => {
    const evaluation = event.target.value;
    setValues({ [prop]: evaluation });
    dispatch(getOverallStatistics(evaluation, filterQuery));
    dispatch(getParticipationByProcess(evaluation, filterQuery));
    dispatch(getSatisfactionByTopic(evaluation, filterQuery));
    dispatch(getCommentsByDepartment(evaluation, filterQuery));
    dispatch(getEngagementEvolution(filterQuery));
    if (isValidToSeeNpsAndSatisfaction) {
      dispatch(getSatisfactionByDepartment(evaluation, filterQuery));
    } else {
      setValues({
        satisfactionByTopic: null,
        npsAndSatisfaction: null,
        satisfactionByDepartment: null,
      });
    }
  };

  useEffect(() => {
    if (values.evaluation && engagementSatisfaction && !isLoadingEngagementSatisfaction) {
      const isFollowUp = engagementProcesses && engagementProcesses.find((e) => isEqual(e.id, values.evaluation))?.follow_up_process_id;
      const MIN_FILLED = isNull(isFollowUp) ? MIN_FORMS_FILLED.withoutFollow : MIN_FORMS_FILLED.withFollow;
      const invalidResult = engagementSatisfaction?.filter((item) => isInvalidFormsFilled(item.forms_filled, MIN_FILLED));
      setValues({
        hasInvalidResult: invalidResult?.length > MIN_VALUE,
        minFilled: MIN_FILLED,
      });

      const validResults = engagementSatisfaction?.filter((item) => item.forms_filled >= MIN_FILLED);
      let npsAndSatisfactionOptions = [];
      if (validResults) {
        const nps = getDataBarNps(validResults);
        const satisfaction = getDataSatisfactionBar(validResults, t);
        const npsAndSatisfactionResult = getNpsAndSatisfactionResult(validResults, t);
        npsAndSatisfactionOptions = getNpsAndSatisfactionChart(t, nps, satisfaction, npsAndSatisfactionResult);
      }
      setValues({
        satisfactionByDepartment: validResults,
        npsAndSatisfaction: npsAndSatisfactionOptions,
      });
    }
    // eslint-disable-next-line
  }, [values.evaluation, engagementSatisfaction, isLoadingEngagementSatisfaction]);

  useEffect(() => {
    if (values.evaluation && values.minFilled && engagementTopic && !isLoadingEngagementTopic) {
      const satisfactionData = getOptionsByQuestions(
        t,
        engagementTopic.forms_filled >= values.minFilled ? engagementTopic?.evaluation_sections : [],
        language,
      );
      setValues({
        satisfactionByTopic: satisfactionData,
      });
    }
    // eslint-disable-next-line
  }, [values.evaluation, values.minFilled, engagementTopic, isLoadingEngagementTopic]);

  const handleExternalQuery = (query) => {
    setFilterQuery(query);
  };

  const cardsContent = !isLoadingEngagementOverall && !isLoadingEngagementParticipation ? (
    <StyledGridContainer
      container
      spacing={ 2 }
    >
      <Grid item xs={ 12 } md={ 4 }>
        <CardResult
          title={ t("generalSatisfaction") }
          icon={ SatisfactionIcon }
          result={ getPercent(
            engagementOverall?.overall_satisfaction
              || MIN_VALUE,
          ) }
          isEmptyResult={ isNull(engagementOverall?.overall_satisfaction) }
          hasSymbol
        />
      </Grid>
      <Grid item xs={ 12 } md={ 4 }>
        <CardResult
          title={ t("participation") }
          icon={ ParticipationIcon }
          color={ CARD_CLASS.blue }
        >
          <StyledParticipationResult>
            <Typography variant={ VARIANT.h2 }>
              {`${engagementParticipation?.meta?.participation_by_evaluation_type?.overall_evaluation?.surveys_answered
                || MIN_VALUE}/`}
              <span>
                {engagementParticipation?.meta?.participation_by_evaluation_type?.overall_evaluation?.surveys_sent}
              </span>
            </Typography>
            <StyledParticipationTitle variant={ VARIANT.h3 }>
              {getParticipation(
                engagementParticipation?.meta?.participation_by_evaluation_type?.overall_evaluation?.surveys_answered,
                engagementParticipation?.meta?.participation_by_evaluation_type?.overall_evaluation?.surveys_sent,
              )}
            </StyledParticipationTitle>
          </StyledParticipationResult>
        </CardResult>
      </Grid>
      <Grid item xs={ 12 } md={ 4 }>
        <CardResult
          title={ t("nps") }
          icon={ NPSIcon }
          color={ CARD_CLASS.green }
          result={ getPercent(
            engagementOverall?.nps || MIN_VALUE,
          ) }
          text={ t("npsDescription") }
          isEmptyResult={ isNull(engagementOverall?.nps) }
        />
      </Grid>
    </StyledGridContainer>
  ) : (
    <SkeletonLoader
      numberOfSkeletons={ SKELETONS_NUMBER.THREE }
      variant={ SKELETON_VARIANT.rectangular }
      height={ 150 }
      columnWidth={ isMobile ? 12 : 4 }
    />
  );

  return (
    <StyledDashboardContainer container data-testid={ "general-content-view" }>
      <StyledHeader item xs={ 12 } sm={ 6 } md={ 8 } lg={ 9 }>
        <StyledTitle>{t("title")}</StyledTitle>
        <Typography variant={ VARIANT.body1 }>{t("subtitle")}</Typography>
      </StyledHeader>
      <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
        {values.evaluations && (
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("selectEvaluation") }
            menuItems={ values.evaluations }
            value={ isLoadingEngagementProcesses ? "" : values.evaluation }
            disabled={ isEmpty(engagementProcesses) || isLoadingEngagementProcesses }
            onChange={ handleChange }
            className={ "custom-select" }
          />
        )}
      </Grid>
      {(values.evaluation) && (
        <StyledFilterGrid container>
          <Grid item xs={ 12 } >
            <Filter
              isLabel
              type={ FILTER_ACTIONS_TYPES.engagement }
              externalParams={ [values.evaluation] }
              isLoading={ isLoadingEngagementProcesses }
              handleExternalQuery={ handleExternalQuery }
            />
          </Grid>
        </StyledFilterGrid>
      )}
      <Grid item xs={ 12 }>
        { cardsContent }
        {values.hasInvalidResult && (
          <SimpleAlert type={ INFO } message={ t("message") } />
        )}
        {!isLoadingEngagementTopic && values.satisfactionByTopic ? (
          <Card elevation={ MIN_VALUE }>
            <StyledCardContent>
              <SwitchSelectorContent
                options={ values.satisfactionByTopic }
                customStyles={{ switch:{ height: "36px" }}}
              />
            </StyledCardContent>
          </Card>
        ) : (
          <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
        )}
        {isValidToSeeNpsAndSatisfaction && isNotHaulmerLeader && (
          <>
            <StyledCard elevation={ MIN_VALUE }>
              {/* nps and satisfaction by division - chart and table */}
              {!isLoadingEngagementSatisfaction && values.npsAndSatisfaction ? (
                <StyledCardContent>
                  <SwitchSelectorContent
                    options={ values.npsAndSatisfaction }
                    customStyles={{ switch:{ height: "36px", margin: "0 0 10px 0" }}}
                  />
                </StyledCardContent>
              ) : <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />}
            </StyledCard>
            <StyledCard elevation={ MIN_VALUE }>
              {/* satisfaction by department */}
              {!isLoadingEngagementSatisfaction
                && !isLoadingEngagementTopic
                && values.satisfactionByDepartment ? (
                  <StyledCardContent>
                    <StyledSubtitle variant={ VARIANT.h4 }>
                      {t("satisfactionByTopic")}
                    </StyledSubtitle>
                    {!isEmpty(values?.satisfactionByDepartment) ? (
                      <SatisfactionByTopic
                        topics={ engagementTopic?.evaluation_sections }
                        formsFilled={ engagementTopic?.forms_filled }
                        divisions={ values?.satisfactionByDepartment }
                      />
                    ) : (
                      <NoDataMessage />
                    )}
                  </StyledCardContent>
                ) : (
                  <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
                )}
            </StyledCard>
          </>
        )}
        {/* comparative */}
        <StyledCard elevation={ MIN_VALUE }>
          <StyledCardContent>
            <ComparativePeriods />
          </StyledCardContent>
        </StyledCard>
        {isAdmin(user?.userCookies) && (
          <StyledCard elevation={ MIN_VALUE }>
            {/* comments by department */}
            <StyledCardContent>
              <CommentsByDivision processId={ values.evaluation } />
            </StyledCardContent>
          </StyledCard>
        )}
      </Grid>
    </StyledDashboardContainer>
  );
};

export default GeneralContent;
