import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Button from "components/Button";
import { getAvatarPromise } from "common/utils";
import { StyledContainer, StyledOptionContainer } from "./styles";

const EmployeeAutocomplete = ({
  t, initialEmployees, onSave, onCancel, allEmployees,
}) => {
  const [selectedEmployees, setSelectedEmployees] = useState(initialEmployees);
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    let isMounted = true;

    const loadImageUrls = async () => {
      const promises = allEmployees.map(
        (employee) => getAvatarPromise(employee).then((url) => ({ id: employee.id, url })),
      );

      const results = await Promise.all(promises);

      if (isMounted) {
        const urls = results.reduce((acc, { id, url }) => {
          acc[id] = url;
          return acc;
        }, {});

        setImageUrls(urls);
      }
    };

    loadImageUrls();

    return () => {
      isMounted = false;
    };
  }, [allEmployees]);

  useEffect(() => {
    setSelectedEmployees(initialEmployees);
  }, [initialEmployees]);

  // uniqueEmployees array to avoid unnecessary recalculations when allEmployees changes
  const uniqueEmployees = useMemo(() => {
    const seen = new Set();
    return allEmployees.filter((employee) => {
      const duplicate = seen.has(employee.full_name);
      seen.add(employee.full_name);
      return !duplicate;
    });
  }, [allEmployees]);

  return (
    <StyledContainer>
      <Autocomplete
        size={ "small" }
        multiple
        options={ uniqueEmployees }
        getOptionLabel={ (option) => option.full_name }
        value={ selectedEmployees }
        onChange={ (event, newValue) => setSelectedEmployees(newValue) }
        isOptionEqualToValue={ (option, value) => option.full_name === value.full_name }
        disableCloseOnSelect
        renderOption={ (props, option, { selected }) => (
          <StyledOptionContainer { ...props } key={ option.id }>
            <Checkbox checked={ selected } />
            <Avatar src={ imageUrls[option.id] || "" } alt={ option.full_name } />
            <span>{option.full_name}</span>
          </StyledOptionContainer>
        ) }
        renderInput={ (params) => (
          <TextField { ...params } label={ t("common:common.collaborators") } variant={ "outlined" } />
        ) }
      />
      <Box display={ "flex" } justifyContent={ "flex-end" }>
        <Box mt={ 1 }>
          <Button
            onClick={ onCancel }
            variant={ "outlined" }
            typeStyle={ "cancel" }
            size={ "small" }
          >
            {t("common:common.cancel")}
          </Button>
        </Box>
        <Box mt={ 1 }>
          <Button
            onClick={ () => onSave(selectedEmployees) }
            variant={ "contained" }
            color={ "primary" }
            typeStyle={ "submit" }
            size={ "small" }
          >
            {t("common:common.save")}
          </Button>
        </Box>
      </Box>
    </StyledContainer>
  );
};

EmployeeAutocomplete.propTypes = {
  t: PropTypes.func.isRequired,
  initialEmployees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string.isRequired,
  })).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  allEmployees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string.isRequired,
  })).isRequired,
};

export default EmployeeAutocomplete;
