/**
 * BuildComponent - A dynamic component builder based on the specified typeElement.
 * @param {Object} props - The component props.
 * @param {Object} props.element - The element data used to build the component.
 * @param {string} props.typeElement - The type of component to build.
 * @param {Array} props.formattedFields - The formatted fields used in the component.
 * @param {number|string} props.activeItem - The active item in the component.
 * @param {Function} props.handleActiveItem - The event handler for the active item in component.
 * @returns {JSX.Element} - The built component based on the specified typeElement.
 */
import PropTypes from "prop-types";
import CardProfile from "components/CardProfile";

const BuildComponent = ({
  element,
  typeElement,
  formattedFields,
  activeItem,
  handleActiveItem,
}) => {
  // Array of functions to build the component based on the specified typeElement.
  const typeElements = {
    cardProfile: (item) => (
      <CardProfile
        key={ item.id }
        data={ item }
        header={ formattedFields }
        activeItem={ activeItem }
        handleAction={ handleActiveItem }
      />
    ),
  };

  return typeElements[typeElement](element);
};

BuildComponent.propTypes = {
  element: PropTypes.object.isRequired,
  typeElement: PropTypes.string.isRequired,
  formattedFields: PropTypes.array,
  activeItem: PropTypes.number || PropTypes.string,
  handleActiveItem: PropTypes.func,
};

BuildComponent.defaultProps = {
  formattedFields: [],
  activeItem: null,
  handleActiveItem: () => {},
};

export default BuildComponent;
