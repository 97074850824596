import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import palette from "theme/palette";

export const StyledCard = styled(Card)`
  cursor: pointer;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 16px 20px;
  &.MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
`;

export const StyledCollapseArrow = styled.div`
  .MuiSvgIcon-root {
    color: ${palette.text.link};
    font-size: 40px;
  }
`;

export const StyledCollapseContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPercent = styled.div`
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 1070px) {
    font-size: 30px;
  }
`;

export const StyledRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 25px;
    margin-right: 5px;
  }
  .MuiTypography-body1 {
    color: ${palette.text.grayDark};
    font-size: 15px;
    margin-right: 10px;
  }
`;

export const StyledRisk = styled.div`
  margin-left: 15px;
  .MuiTypography-h4 {
    color: ${palette.text.grayDark};
    font-weight: 700;
    margin-bottom: 4px;
  }
  .MuiLinearProgress-root {
    height: 15px;
    width: auto;
    @media (max-width: 450px) {
      height: 10px;
    }
  }
  .MuiLinearProgress-colorPrimary {
    background-color: transparent;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.color};
    border-radius: 0 8px 8px 0;
  }
`;
