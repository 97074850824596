import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import InputTag from "components/InputTag";
import { isEmpty, isNotValid } from "common/helpers";
import { getFormattedTags } from "common/utils";
import {
  ALIGN_ITEMS,
  BUTTON_STYLE_TYPES, OBJECT_KEYS, ROLES, SIZE, VARIANT,
} from "common/constants";
import { getList as getCountriesList } from "redux/actions/common/countryActions";
import { getList as getOrgUnitsList } from "redux/actions/common/orgUnitActions";
import {
  dispatchUpdate, generateRoles, getActionRolesWithScope, getRolesWithScope,
} from "views_refactor/Profile/functions/profile";
import {
  StyledDialogActions,
  StyledTitleBox,
  StyledTypography,
  StyledDialogContent,
  StyledLabel,
  StyledAvatar,
  StyledPosition,
} from "./styles";

/* TODO: Refactor */
const RolesModal = (props) => {
  const {
    isOpen,
    onClose,
    collaborator,
    allRolesList,
    hasSelected,
  } = props;
  const { t } = useTranslation("account");
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [inputCountryTagsSelected, setInputCountryTagsSelected] = useState([]);
  const [inputOrganizationUnitTagsSelected, setInputOrganizationUnitTagsSelected] = useState([]);
  const [roleSelected, setRoleSelected] = useState({});
  const [userRoles, setUserRoles] = useState({});
  const {
    list: countriesList,
  } = useSelector(({ countryReducer }) => countryReducer);

  const {
    list: orgUnitsList,
  } = useSelector(({ orgUnitReducer }) => orgUnitReducer);
  const { list: roleList } = useSelector(({ rolesReducer }) => rolesReducer);
  const {
    isLoadingProcess,
  } = useSelector((state) => state.collaboratorReducer);

  const rolesCollaborator = isEmpty(hasSelected) && !isNotValid(collaborator?.roles_with_scope) ? collaborator.roles_with_scope || [] : hasSelected;

  const dispatch = useDispatch();

  const {
    handleSubmit, register,
  } = useForm();

  const onCancel = () => {
    setUserRoles(generateRoles(getRolesWithScope(rolesCollaborator, t), null, true));
    setRoleSelected({});
    setInputCountryTagsSelected([]);
    setInputOrganizationUnitTagsSelected([]);
    onClose();
  };

  const onSubmit = async () => {
    const data = {
      collaborator: { user_roles: getActionRolesWithScope(collaborator, userRoles) },
    };
    dispatchUpdate(dispatch, OBJECT_KEYS.collaborator, data, collaborator?.id);
  };

  const handleTags = (tags) => {
    setUserRoles(generateRoles(tags, userRoles));
    setRoleSelected({});
    setInputCountryTagsSelected([]);
    setInputOrganizationUnitTagsSelected([]);
  };

  const handleCountryTags = (tags) => {
    setUserRoles({
      ...userRoles,
      [roleSelected.value]: {
        ...userRoles[roleSelected.value],
        countries: tags,
      },
    });
  };

  const handleUnitTags = (tags) => {
    setUserRoles({
      ...userRoles,
      [roleSelected.value]: {
        ...userRoles[roleSelected.value],
        units: tags,
      },
    });
  };
  useEffect(() => {
    if (collaborator) {
      dispatch(getCountriesList());
      dispatch(getOrgUnitsList());
      if (!isEmpty(rolesCollaborator)) {
        const rolesWithScope = getRolesWithScope(rolesCollaborator, t);
        setInputTagsSelected(rolesWithScope);
        setUserRoles(generateRoles(rolesWithScope, null, true));
      }
    }
  }, [t, dispatch, roleList, rolesCollaborator, collaborator]);

  const handleRole = (role) => {
    setRoleSelected(role);
    setInputCountryTagsSelected(userRoles[role.value]?.countries || []);
    setInputOrganizationUnitTagsSelected(userRoles[role.value]?.units || []);
  };

  return (
    <Dialog
      open={ isOpen }
      onClose={ onClose }
      fullWidth
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <DialogTitle disableTypography>
          <Box display={ ALIGN_ITEMS.flex }>
            <StyledTitleBox>
              {t("addRoles.title")}
            </StyledTitleBox>
            <Box>
              <IconButton onClick={ onClose }>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <StyledDialogContent>
          <StyledTypography>{t("addRoles.subtitle")}</StyledTypography>
          <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
            <Box marginBottom={ 0 } marginRight={ 1 }>
              <StyledAvatar
                src={ collaborator?.profile_img_url }
                alt={ collaborator?.full_name }
              />
            </Box>
            <Box>
              <Typography variant={ VARIANT.h4 }>
                {collaborator?.full_name}
              </Typography>
              <StyledPosition
                variant={ VARIANT.h5 }
              >
                {[
                  collaborator?.job_position?.position_name,
                  collaborator?.legal_entity?.name,
                  collaborator?.job_position?.country?.name,
                ].filter(Boolean).join(" / ")}
              </StyledPosition>
            </Box>
          </Box>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <StyledLabel>
                {t("addRoles.rolesAssigned")}
              </StyledLabel>
              <InputTag
                id={ "user_roles" }
                label={ t("common:common.roles.main") }
                size={ SIZE.small }
                itemsSelected={ inputTagsSelected }
                name={ "user_roles" }
                register={ register }
                onChange={ handleTags }
                data={ allRolesList }
                onClickTag={ handleRole }
                isRequired
              />
            </Grid>
            <Grid item xs={ 12 }>
              <StyledLabel>
                {t("addRoles.rolLevel")}
              </StyledLabel>
              <InputTag
                id={ "country_ids" }
                label={ t("common:common.country") }
                size={ SIZE.small }
                itemsSelected={ inputCountryTagsSelected }
                name={ "country_ids" }
                register={ register }
                onChange={ handleCountryTags }
                data={ getFormattedTags(countriesList, OBJECT_KEYS.name) }
                isDisabled={ !roleSelected?.value
                  || (roleSelected.value === ROLES.COLLABORATOR
                  || roleSelected.value === ROLES.MANAGER) }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <InputTag
                id={ "organization_unit_ids" }
                label={ t("common:common.unit") }
                size={ SIZE.small }
                itemsSelected={ inputOrganizationUnitTagsSelected }
                name={ "organization_unit_ids" }
                register={ register }
                onChange={ handleUnitTags }
                data={ getFormattedTags(orgUnitsList, OBJECT_KEYS.completePath) }
                isDisabled={ !roleSelected?.value
                  || (roleSelected.value === ROLES.COLLABORATOR
                  || roleSelected.value === ROLES.MANAGER) }
              />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ onCancel }>
            {t("Onboarding:cancelButton")}
          </Button>
          <Button
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            type={ BUTTON_STYLE_TYPES.SUBMIT }
            isLoading={ isLoadingProcess }
          >
            {t("common:common.save")}
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

RolesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasSelected: PropTypes.array.isRequired,
  collaborator: PropTypes.object.isRequired,
  allRolesList: PropTypes.array.isRequired,
};

export default RolesModal;
