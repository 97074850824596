import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import { COLORS_NAME, STATE } from "common/constants";
import { isEqual } from "common/helpers";
import { StyledIconButton, StyledItem, StyledSwitch } from "views/Onboarding/components/DocumentsList/styles";
import { StyledDragItem, StyledItemContainer, StyledDragButton } from "./styles";

const SortableItem = (props) => {
  const { t } = useTranslation("Onboarding");
  const {
    id,
    data,
    setShowItem,
    showItemId,
    removeItem,
    handleChange,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id });

  return (
    <StyledItemContainer
      data-testid={ "sortable-item-view-component" }
      ref={ setNodeRef }
      transform={ CSS.Transform.toString(transform) }
      transition={ transition }
      isDragging={ isDragging }
      isSelectedItem={ isEqual(showItemId, id) }
    >
      <StyledItem spacing={ 1 }>
        <StyledDragItem item xs={ 10 } onClick={ () => setShowItem(id) }>
          <StyledDragButton
            ref={ setActivatorNodeRef }
            { ...listeners }
            { ...attributes }
          >
            <DragIndicatorIcon />
          </StyledDragButton>
          {data?.name}
        </StyledDragItem>
        <Grid item xs={ 4 }>
          <FormControlLabel
            labelPlacement={ STATE.start }
            label={ t("required") }
            control={ (
              <StyledSwitch
                color={ COLORS_NAME.primary }
                checked={ data.required }
                onChange={ () => handleChange(data) }
              />
            ) }
          />
        </Grid>
        <Grid item xs={ 2 }>
          <StyledIconButton
            onClick={ () => removeItem(id) }
          >
            <DeleteIcon />
          </StyledIconButton>
        </Grid>
      </StyledItem>
    </StyledItemContainer>
  );
};

SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  setShowItem: PropTypes.func.isRequired,
  showItemId: PropTypes.string,
  removeItem: PropTypes.func,
  handleChange: PropTypes.func,
};

SortableItem.defaultProps = {
  showItemId: null,
  removeItem: () => {},
  handleChange: () => {},
};

export default SortableItem;
