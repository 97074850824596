import _ from "lodash";
import { MARITAL_STATUS, OBJECT_KEYS } from "common/constants";
import { isEqual } from "common/helpers";

export const getAdditionalInfo = (additionalInformation) => {
  const info = [];
  let answer = "";
  additionalInformation
    && additionalInformation.map((item) => {
      answer = _.isNull(item.answer) ? "" : item.answer;
      return info.push(`${item.name}:${answer}\n`);
    });
  return info.join("");
};

export const resetDefaultValues = (employee) => ({
  [`${employee}.person_attributes.personal_id`]: "",
  [`${employee}.person_attributes.personal_email`]: "",
  [`${employee}.person_attributes.nationality`]: "",
  [`${employee}.person_attributes.marital_status`]: "",
  [`${employee}.person_attributes.gender`]: "",
  [`${employee}.person_attributes.address`]: "",
  [`${employee}.person_attributes.phone_number`]: "",
  [`${employee}.person_attributes.linkedin_profile`]: "",
  [`${employee}.person_attributes.additional_information`]: "",
});

export const getMaritalList = (t) => [
  { value: "single", label: t(MARITAL_STATUS.SINGLE) },
  { value: "married", label: t(MARITAL_STATUS.MARRIED) },
  { value: "freeUnion", label: t(MARITAL_STATUS.FREE_UNION) },
  { value: "separated", label: t(MARITAL_STATUS.SEPARATED) },
  { value: "divorced", label: t(MARITAL_STATUS.DIVORCED) },
  { value: "widower", label: t(MARITAL_STATUS.WIDOWER) },
];

export const getItemSelected = (list, selected, prop = OBJECT_KEYS.value) => list?.find(
  (e) => isEqual(e[prop], selected)
    || isEqual(e.es?.toLowerCase(), selected?.toLowerCase())
    || isEqual(e.en?.toLowerCase(), selected?.toLowerCase())
    || isEqual(e.pt?.toLowerCase(), selected?.toLowerCase()),
);
