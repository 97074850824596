import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import { StyledTableRow, StyledTableCell, StyledSkeleton } from "../../styles";

const TableLoader = (props) => {
  const { cols, rows } = props;

  return (
    <TableBody>
      <StyledTableRow>
        <StyledTableCell colSpan={ cols }>
          {[...Array(rows).keys()].map((row) => (
            <StyledSkeleton
              data-testid={ "tablegrid-loader" }
              key={ `sk-${row}` }
              height={ 48 }
              width={ "100%" }
            />
          ))}
        </StyledTableCell>
      </StyledTableRow>
    </TableBody>
  );
};

TableLoader.propTypes = {
  cols: PropTypes.number.isRequired,
  rows: PropTypes.number,
};

TableLoader.defaultProps = {
  rows: 10,
};

export default TableLoader;
