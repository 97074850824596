import PropTypes from "prop-types";
import { MIN_VALUE, IFRAME } from "common/constants";
import { useStyles } from "./styles";

const SimpleIframe = (props) => {
  const {
    title, src, height,
  } = props;
  const classes = useStyles();

  return (
    <div className={ classes.root } data-testid={ "simple-iframe-component" }>
      <iframe title={ title } frameBorder={ MIN_VALUE } src={ src } height={ height } />
    </div>
  );
};

SimpleIframe.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
};

SimpleIframe.defaultProps = {
  height: IFRAME.min,
};

export default SimpleIframe;
