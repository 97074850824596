import styled from "styled-components";
import Typography from "@mui/material/Typography";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import palette, { PHONE_NUMBER_COLORS, SIGN_IN_COLORS } from "theme/palette";

export const StyledContentSwitch = styled.div`
  .react-tel-input {
    margin: 5px 0 10px 0;
  }
  .react-tel-input .form-control {
    width: 100%;
    background: transparent;
    padding: 17.5px 13px 17.5px 58px; // It's necessary
  }
  .react-tel-input .special-label {
    display: none;
  }
  .react-tel-input .form-control:hover {
    border-color: ${PHONE_NUMBER_COLORS.hoverBorder};
  }
  .react-tel-input .form-control:focus {
    border-color: ${palette.primary.main};
  }
  .react-tel-input .country-list .search-box:hover {
    border-color: ${PHONE_NUMBER_COLORS.grayBorder};
  }
  .react-tel-input .country-list .search-box {
    margin-left: 2px;
    padding: 8px 8px 6px;
    width: 270px;
  }
`;

export const StyledInfoQuestion = styled.div`
  font-size: 12px;
  .MuiAlert-icon {
    color: ${PHONE_NUMBER_COLORS.alertColor};
  }
  .MuiAlert-icon svg {
    width: 48px;
    height: 48px;
  }
  .MuiAlert-standardInfo {
    background-color: ${PHONE_NUMBER_COLORS.alertBackground};
    color: ${PHONE_NUMBER_COLORS.alertColor};
    align-items: center;
  }
`;

export const StyledOrBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 15px 0;
`;

export const StyledEmailInput = styled(InputTextController)`
  &.MuiFormControl-root {
    margin-bottom: 20px;
  }
`;

export const StyledLinkText = styled(Typography)`
  &.MuiTypography-root {
    color: ${SIGN_IN_COLORS.blue};
    margin-top: ${(props) => (props?.marginTop ? props?.marginTop : "15px")};
    margin-bottom: 10px;
    font-weight: 500;
    text-decoration: ${(props) => (props?.underline ? "underline" : "none")};
    text-align: ${(props) => (props?.align ? props?.align : "left")};
    cursor: pointer;
  }
  a {
    color: ${SIGN_IN_COLORS.blue};
  }
`;

export const StyledTextBold = styled.span`
  font-weight: bold;
`;

export const StyledTitle = styled(Typography)`
  &.MuiTypography-h2 {
    color: ${palette.text.secondaryTitle};
    font-size: 2.25rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 40px;
  }
`;

export const StyledSubtitle = styled(Typography)`
  &.MuiTypography-h6 {
    color: ${palette.text.secondaryTitle};
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 40px;
  }
`;

export const StyledButtonContainer = styled.div`
  .MuiButton-root {
    width: 100%;
    margin-top: 15px;
    border-color: ${SIGN_IN_COLORS.blue};
    color: ${palette.text.link};
    margin-bottom: 30px;
    padding: 10px 15px;
    font-weight: 400;
  }
`;

export const StyledGrid = styled.div`
  display: grid;
  max-width: 100%;
  margin: 16px 0 !important;
  grid-gap: 16px;
  grid-template-columns: repeat(2, minmax(45%, 1fr));

  .MuiButtonBase-root {
    margin: 0;
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(1, minmax(100%, 1fr));
  }
`;

export const StyledProvidersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

export const StyledButtonSignIn = styled(Button)`
  margin-top: 10px;
`;
