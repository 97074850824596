import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Clear from "@mui/icons-material/Clear";
import theme from "theme/palette";

export const StyledText = styled(Typography)`
  font-size: 16px;
`;

export const StyledNumber = styled.span`
  font-weight: bold;
`;
export const StyledGrid = styled(Grid)`
  border: ${theme.border.grayLight};
  border-radius: 4px;
  height: 360px;
  overflow-y: auto;
`;

export const StyledBox = styled(Box)`
  padding: 6px 8px;
  align-items: center;
  display: flex;
  :hover {
    background-color: ${theme.table.header};
    color: ${theme.white};
  }
`;

export const StyledStatus = styled.div`
  border-radius: 100%;
  display: inline-flex;
  margin-right: 5px;
  align-items: center;
  svg {
    align-items:center;
    font-size: 14px;
    color: ${(props) => (props.isActive ? theme.table.green : theme.table.red)};
  }
`;

export const StyledClearIcon = styled(Clear)`
  cursor: pointer;
`;

export const StyledEmail = styled.span`
  text-decoration: ${(props) => (props.noExist ? "line-through" : "none")};
`;
