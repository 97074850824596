import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import NoDataMessage from "components/NoDataMessage";
import Loader from "components/Loader";
import Button from "components/Button";
import FeedbackIcon from "assets/images/feedback/feedback.svg";
import AcknowledgementIcon from "assets/images/feedback/acknowledgement.svg";
import { BUTTON_STYLE_TYPES, TYPES } from "common/constants";
import { getAcknowledgementIcon, formatDate } from "common/utils";
import CardMessage from "../CardMessage";
import {
  EMPLOYEE_TYPE,
  FEEDBACK_TYPE,
  FEEDBACKABLES,
  isWithData, LIMIT_PAGINATE_INDEX,
} from "../../functions";
import { useStyles } from "./styles";

const FeedbackList = (props) => {
  const {
    data,
    isLoading,
    viewType,
  } = props;
  const { t } = useTranslation("feedback");
  const classes = useStyles();
  const [isShowAllData, setIsShowAllData] = useState(false);

  const { list } = useSelector(({ acknowledgementTypesReducer }) => acknowledgementTypesReducer);
  const acknowledgementTypeList = list;

  const getIconFeedback = (type) => {
    switch (type) {
    case FEEDBACKABLES.feedbackSimple:
      return FeedbackIcon;
    case FEEDBACKABLES.feedbackAcknowledgement:
      return AcknowledgementIcon;
    default:
      return FeedbackIcon;
    }
  };

  const TypeImage = (type) => (<img src={ getIconFeedback(type) } alt={ type } className={ classes.iconType } />);

  const ListCards = ({ dataList }) => dataList.map((feedback, index) => {
    const type = feedback.feedbackable_type;
    const employeeToShow = viewType === FEEDBACK_TYPE.sent ? EMPLOYEE_TYPE.sender : EMPLOYEE_TYPE.receiver;
    const createdAtFormatted = formatDate(feedback.feedbackable.created_at);
    return (
      <CardMessage
        name={ feedback[`${employeeToShow}_full_name`] }
        imageUrl={ feedback[`${employeeToShow}_profile_img_url`] }
        imageThumbnailUrl={ feedback[`${employeeToShow}_profile_img_url_thumb`] }
        message={ feedback.feedbackable.message ? parse(feedback.feedbackable.message) : "" }
        date={ createdAtFormatted }
        type={ type }
        sscData={ feedback.feedbackable }
        acknowledgementElement={ getAcknowledgementIcon(feedback.feedbackable.acknowledgement_type_id, acknowledgementTypeList) }
        TypeImage={ () => TypeImage(type) }
        key={ `${feedback.employee_from_full_name}-${index}-${type}-${feedback.feedbackable.created_at}` }
      />
    );
  });

  const handleOnClickViewLessOrMore = () => {
    setIsShowAllData(!isShowAllData);
  };

  const ListCardsWithButton = () => {
    const dataList = isShowAllData ? data : data.slice(LIMIT_PAGINATE_INDEX.start, LIMIT_PAGINATE_INDEX.end);
    const labelButtonViewMoreOrLess = isShowAllData ? "view_less" : "view_more";
    return (
      <>
        <ListCards dataList={ dataList } />
        {/* if the data has more than 5 elements then we show the button */}
        {data.length > 5
        && (
          <Button
            variant={ BUTTON_STYLE_TYPES.OUTLINED }
            type={ TYPES.text }
            onClick={ handleOnClickViewLessOrMore }
            customStyle={ classes.btnViewMore }
          >
            {t(labelButtonViewMoreOrLess)}
          </Button>
        )}
      </>
    );
  };

  return (
    <div className={ classes.root } data-testid={ "feedback-list-view" }>
      { isLoading
        ? <Loader />
        : (isWithData(data) && !isLoading)
          ? <ListCardsWithButton />
          : <NoDataMessage />}
    </div>
  );
};

FeedbackList.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  viewType: PropTypes.string,
};

export default FeedbackList;
