import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, isNull } from "common/helpers";
import { formatDateMovementToSubmit, replacePath } from "common/utils";
import { FORM_MODE, INDEX, OBJECT_KEYS } from "common/constants";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  getList as getMovementsHistory,
  create as createMovementHistory,
  resetStateProcess,
  deleteMovementById,
} from "redux/actions/historicalMovementActions";
import { getOne as getCollaborator } from "redux/actions/collaboratorActions";
import useHandleProcess from "hooks/utils/useHandleProcess";
import useComplexState from "hooks/utils/useComplexState";
import {
  getNewValues, getSelectedModal, resetDefaultMovementValues, setUpdatedData,
} from "../functions/profile";
import localRoutes from "../functions/routes";
import { getSortedMovements, getMappedMovementList, setMovementData } from "../functions/movements";

export const useMovementsHistoryHook = (externalHooks, internalHooks) => {
  const {
    t, dispatch, location, history,
  } = externalHooks;
  const { collaborator } = internalHooks;

  const [movementStates, setMovementStates] = useComplexState({
    isModalOpen: {
      addMovement: false,
    },
    value: INDEX.zero,
    movementTypes: [
      OBJECT_KEYS.lateralMovement,
      OBJECT_KEYS.promotion,
      OBJECT_KEYS.salaryRaise,
    ],
    date: null,
    typeSelected: null,
    updatedData: [],
    lastData: null,
  });

  const employee = "collaborator"; // TODO: change this to dynamic when candidate view is ready

  const {
    control, handleSubmit, reset, watch, errors, setValue, getValues,
  } = useForm({
    defaultValues: resetDefaultMovementValues(employee, null, null, location.state?.type),
    mode: FORM_MODE.onChange,
  });
  const watchType = watch(`${employee}.type`);
  const watchDate = watch(`${employee}.date`);

  const {
    list: movementsHistoryList,
    isLoadingList: isLoadingMovementsList,
    successProcess,
    isLoadingProcess,
    errorProcess,
  } = useSelector(({ historicalMovementsReducer }) => historicalMovementsReducer);

  // use effect need it to handle the routes (for modals)
  useEffect(() => {
    if (!isEqual(location.pathname, localRoutes.root)) {
      getSelectedModal(location.pathname).then((resolve) => {
        const { path, key } = resolve;
        replacePath(path);
        setMovementStates({
          isModalOpen: {
            ...movementStates.isModalOpen,
            [key]: true,
          },
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (collaborator) {
      reset(resetDefaultMovementValues(employee, collaborator, null, location.state?.type));
    }
    // eslint-disable-next-line
  }, [collaborator]);

  useEffect(() => {
    if (isNull(movementsHistoryList) && collaborator?.id) {
      dispatch(getMovementsHistory(collaborator?.id));
    }
  // eslint-disable-next-line
  }, [collaborator, movementsHistoryList]);

  useEffect(() => {
    if (!isLoadingMovementsList && movementsHistoryList) {
      setMovementStates({
        value: getMappedMovementList(movementsHistoryList, t, collaborator).length - INDEX.one,
      });
    }
    // eslint-disable-next-line
  }, [movementsHistoryList, isLoadingMovementsList]);

  useEffect(() => {
    setMovementStates({ typeSelected: watchType, date: watchDate });
    reset(resetDefaultMovementValues(employee, collaborator, watchDate, watchType));
    // eslint-disable-next-line
  }, [watchType, watchDate]);

  const processCallback = () => {
    replacePath(history, localRoutes.root);
    dispatch(getCollaborator(collaborator?.id));
    dispatch(getMovementsHistory(collaborator?.id));
  };

  useHandleProcess(
    { t, dispatch },
    { successProcess, isLoadingProcess, errorProcess },
    t("common:common.api_responses.success.update"),
    resetStateProcess,
    processCallback,
  );

  const handleTimelineValue = (index) => {
    setMovementStates({ value: index });
  };

  const sendPreview = () => {
    const newValues = watch().collaborator;
    const data = getNewValues(collaborator, newValues, movementStates.typeSelected);
    setMovementStates({ updatedData: data });
    reset(setUpdatedData(employee, newValues));
  };

  const onSubmit = () => {
    const data = watch();
    const movementData = {
      date: formatDateMovementToSubmit(data[employee].date),
      type: data[employee].type,
    };
    const lastData = {
      ...movementData,
      ...setMovementData(data.collaborator, collaborator, movementStates.typeSelected),
    };
    dispatch(createMovementHistory(lastData, collaborator.id));
  };

  const handleDeleteMovementById = async (movementId) => {
    if (collaborator?.id) {
      const deleteMovement = await dispatch(deleteMovementById(movementId));
      if (!deleteMovement.error) {
        // TODO: create custom general message - delete
        toast(
          MESSAGE_TYPES.success,
          handleMessages(
            MESSAGE_TYPES.success,
            HTTP_STATUS_RESPONSE.ok,
            t,
            t("common:common.api_responses.success.delete"),
          ),
        );
        dispatch(resetStateProcess());
        dispatch(getMovementsHistory(collaborator.id));
        dispatch(getCollaborator(collaborator.id));
      }
    }
  };

  return {
    movementsHistoryListState: {
      list: getSortedMovements(
        getMappedMovementList(movementsHistoryList, t, collaborator),
      ) || null,
      isLoading: isLoadingMovementsList,
    },
    movementStates,
    hookMovementForm: {
      control,
      handleSubmit,
      reset,
      setValue,
    },
    movementErrors: errors,
    isLoadingMovement: isLoadingProcess,
    onMovementSubmit: onSubmit,
    handleTimelineValue,
    sendPreview,
    getMovementValue: getValues,
    handleDeleteMovementById,
  };
};
