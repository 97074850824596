import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ButtonProgress from "components/ButtonProgress";
import { BUTTON_STYLE_TYPES } from "common/constants";
import { BUTTON_PROGRESS_COLORS } from "theme/palette";
import {
  StyledGridContainer, StyledErrorIcon, StyledGrid, StyledTypography, StyledButton,
} from "./styles";

const DeleteEmployee = (props) => {
  const {
    id, action, modalHandler,
  } = props;
  const { t } = useTranslation("collaborators");

  const handleClick = () => {
    action(id);
    if (modalHandler) {
      modalHandler(false);
    }
  };

  return (
    <StyledGridContainer container>
      <StyledGrid item xs={ 12 }>
        <StyledErrorIcon />
        {t("forms.delete_description")}
      </StyledGrid>
      <Grid item xs={ 12 }>
        <StyledTypography>
          {t("forms.delete_question")}
        </StyledTypography>
      </Grid>
      <Grid item xs={ 6 }>
        <ButtonProgress
          label={ t("buttons.confirm_and_delete") }
          color={ BUTTON_PROGRESS_COLORS.error }
          onClick={ () => handleClick() }
        />
      </Grid>
      <Grid item xs={ 6 }>
        <StyledButton
          onClick={ () => modalHandler(false) }
          typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
        >
          {t("buttons.cancel")}
        </StyledButton>
      </Grid>
    </StyledGridContainer>
  );
};

DeleteEmployee.propTypes = {
  id: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  modalHandler: PropTypes.func.isRequired,
};

export default DeleteEmployee;
