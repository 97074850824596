import PropTypes from "prop-types";
import Button from "components/Button";
import { BUTTON_STYLE_TYPES, COLORS_NAME, VARIANT } from "common/constants";
import { StyledActions } from "./styles";

const Actions = (props) => {
  const {
    t,
    index,
    totalSteps,
    handleNext,
    handleBack,
    isLoadingData,
  } = props;

  return (
    <StyledActions>
      { index !== 0 && (
        <Button
          variant={ VARIANT.contained }
          color={ COLORS_NAME.primary }
          type={ BUTTON_STYLE_TYPES.BUTTON }
          typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
          disabled={ index === 0 }
          onClick={ handleBack }
          sx={ { mt: 1, mr: 1 } }
        >
          { t("common:common.back") }
        </Button>
      )}
      {index !== (totalSteps - 1) && (
        <Button
          variant={ VARIANT.contained }
          color={ COLORS_NAME.primary }
          type={ BUTTON_STYLE_TYPES.BUTTON }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          onClick={ handleNext }
          sx={ { mt: 1, mr: 1 } }
          isDisabled={ isLoadingData }
        >
          {t("common:common.next")}
        </Button>
      )}
    </StyledActions>
  );
};

Actions.propTypes = {
  t: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  isLoadingData: PropTypes.bool,
};

Actions.defaultProps = {
  isLoadingData: false,
};

export default Actions;
