import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { isNull } from "common/helpers";
import useComplexState from "hooks/utils/useComplexState";
import RiskCardCollapse from "views/TalentDrain/components/RiskCardCollapse";
import { RISK_CATEGORY } from "views/TalentDrain/functions";

const AttritionRiskTable = (props) => {
  const { handleOpenModal, query } = props;
  const [states, setStates] = useComplexState({
    highRiskIsOpen: false,
    mediumRiskIsOpen: false,
    lowRiskIsOpen: false,
  });

  const {
    highRiskList,
    mediumRiskList,
    lowRiskList,
    listTotal,
    highRiskListTotal,
    mediumRiskListTotal,
    lowRiskListTotal,
    highRiskIsLoadingList,
    mediumRiskIsLoadingList,
    lowRiskIsLoadingList,
  } = useSelector(({ attritionReducer }) => attritionReducer);

  const handleCollapseState = (level, value = null) => {
    setStates({
      [`${level}RiskIsOpen`]: isNull(value) ? !states[`${level}RiskIsOpen`] : value,
    });
  };

  return (
    <div>
      <RiskCardCollapse
        type={ RISK_CATEGORY.high.key }
        data={ highRiskList?.responseList }
        total={ listTotal }
        totalByCategory={ highRiskListTotal }
        isLoading={ highRiskIsLoadingList }
        isOpenCollapse={ states.highRiskIsOpen }
        handleCollapseState={ handleCollapseState }
        query={ query }
        handleOpenModal={ handleOpenModal }
        isShortVersion
      />
      <RiskCardCollapse
        type={ RISK_CATEGORY.medium.key }
        data={ mediumRiskList?.responseList }
        total={ listTotal }
        totalByCategory={ mediumRiskListTotal }
        isLoading={ mediumRiskIsLoadingList }
        isOpenCollapse={ states.mediumRiskIsOpen }
        handleCollapseState={ handleCollapseState }
        query={ query }
        handleOpenModal={ handleOpenModal }
        isShortVersion
      />
      <RiskCardCollapse
        type={ RISK_CATEGORY.low.key }
        data={ lowRiskList?.responseList }
        total={ listTotal }
        totalByCategory={ lowRiskListTotal }
        isLoading={ lowRiskIsLoadingList }
        isOpenCollapse={ states.lowRiskIsOpen }
        handleCollapseState={ handleCollapseState }
        query={ query }
        handleOpenModal={ handleOpenModal }
        isShortVersion
      />
    </div>
  );
};

AttritionRiskTable.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
};

export default AttritionRiskTable;
