import { Line } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
import ChartDataLabels from "chartjs-plugin-datalabels";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "100%",
    height: "auto",
  },
  cardHeader: {
    paddingBottom: 0,
    paddingLeft: 20,
  },
}));

export const HCEvolution = function (props) {
  const HCEvolutionData = props.data !== null ? props.data[0] : null;

  const labels = props.data !== null && HCEvolutionData.labels
    ? HCEvolutionData.labels
    : null;

  const { t } = useTranslation("common", "HRAnalytics");

  const classes = useStyles();

  const chartData = HCEvolutionData !== null
    ? {
      labels,
      datasets: [
        {
          label: HCEvolutionData.title,
          backgroundColor: "#DAB9FE",
          borderColor: "#8D29FF",
          borderWidth: 3,
          data: HCEvolutionData.data,
          showLine: true,
          pointBackgroundColor: "#8D29FF",
        },
      ],
    }
    : "";

  const maxNumber = () => {
    if (chartData !== null && chartData !== "") {
      // Result is the max number of array ==>  example: [275, 278, 296], [296] is the max number
      const result = Math.max(...chartData.datasets[0].data);
      // resultFormat is formating result to [2,9,6]
      const resultFormat = (`${ result}`).split("");
      // This loop is to transform resultFormat to number integer max to result ===> example: [3,0,0]
      resultFormat.map((number, index) => (index !== 0
        ? (resultFormat[index] = 0)
        : (resultFormat[index] = parseInt(number) + 1)));
      // return the max number multiply by 2 to adding chart more space between xAxes to top
      return parseInt(resultFormat.join("")) * 2;
    }
    return 0;
  };

  // Chart Options
  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: "#050620",
        // return Degrees of alignment in datalabels Charts
        align(context) {
          if (context.datasetIndex === 0) {
            if (context.dataIndex === 0) {
              return "-55";
            } if (context.dataIndex === context.dataset.data.length - 1) {
              return "-120";
            }
            return "-90";
          }
          if (context.dataIndex === 0) {
            return "55";
          } if (context.dataIndex === context.dataset.data.length - 1) {
            return "120";
          }
          return "90";
        },
        fontSize: 12,
        fontFamily: "Roboto",
        padding: 8,
      },
    },
    legend: { display: false },
    tooltips: { enabled: false },
    hover: { mode: null },
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 10,
            fontFamily: "Roboto",
            color: "#A3A3A3",
            beginAtZero: true,
            max: maxNumber(),
            min: 0,
            maxRotation: 0.00001,
            padding: 15,
            precision: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 10,
            fontFamily: "Roboto",
            padding: 2,
            color: "#A3A3A3",
          },
        },
      ],
    },
    responsive: true,
  };

  return (
	<Card>
	<CardHeader
	className={ classes.cardHeader }
	title={ t("HRAnalytics:charts.HCEvolution") }
      />
	<CardContent>
	{HCEvolutionData !== null ? (
	<Grid container direction={ "row" } justify={ "center" } alignItems={ "center" }>
	<div className={ classes.chartContainer }>
	<Line data={ chartData } options={ options } height={ 200 } />
            </div>
          </Grid>
        ) : (
	<Loader />
        )}
      </CardContent>
    </Card>
  );
};

export default HCEvolution;
