import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useNodesState,
  useEdgesState,
} from "reactflow";
import { ZOOM } from "common/constants";
import {
  adjustZoom,
  getNewCenter,
  getFormattedData,
  convertToFlowNodesAndEdges,
  getNewNodePosition,
  recursiveSearch,
} from "../functions";

// Custom hook for managing nodes in the organization chart
export const useNodes = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [unassignedList, setUnassignedList] = useState([]);
  const [inactiveLeaderList, setInactiveLeaderList] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState();
  const [isOpenMain, setIsOpenMain] = useState(false);
  const [center, setCenter] = useState({ x: 0, y: 0, zoom: ZOOM.defaultOrgChart });
  const [selectedNode, setSelectedNode] = useState({
    nodeId: "",
    children: [],
  });

  const { t } = useTranslation(["common", "feedback"]);

  const {
    orgChartList: collaboratorList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  // Function to recursively search for a collaborator in the list
  const findCollaborator = (
    collaboratorId,
    ancestryArray,
  ) => recursiveSearch(collaboratorId, ancestryArray, collaboratorList);

  // Function to recursively get all children ids of a node to remove them from the chart
  const getChildrenToRemove = (children, allNodes) => {
    // Function to recursively find all children ids of a node
    const findAllChildrenIds = (localIds, localNodes) => {
      let childrenIds = [...localIds];

      localIds.forEach((parentId) => {
        // Get children ids of the current node
        const actualChildren = localNodes.filter((node) => node.data.parentId === parentId);
        const childrenIdsToAdd = actualChildren.map((child) => child.id);

        // Add children ids to the array and recursively search for their children
        childrenIds = childrenIds.concat(childrenIdsToAdd);
        childrenIds = childrenIds.concat(findAllChildrenIds(childrenIdsToAdd, localNodes));
      });

      return childrenIds;
    };

    return findAllChildrenIds(children, allNodes);
  };

  // Function to handle opening the modal for a selected employee
  const handleOpenModal = (employee) => {
    setEmployeeSelected(employee.person);
    setIsOpenMain(true);
  };

  // Function to handle clicking on a node in the organization chart
  const handleNodeClick = (nodeId, ancestry = null) => {
    const ancestryArray = ancestry !== null
      ? ancestry.split("/")
      : [];
    const selectedCollaborator = findCollaborator(nodeId, ancestryArray);

    if (selectedCollaborator) {
      // Get only active children of the selected collaborator to show in the chart
      const children = selectedCollaborator.children.filter((item) => item.is_active);
      setSelectedNode({ nodeId, children });
    }
  };

  // Effect to handle updating the chart when a node is selected
  useEffect(() => {
    if (selectedNode.children.length === 0) {
      return;
    }

    const { nodeId, children } = selectedNode;
    let allNodes = [...nodes];
    let updatedEdges = [...edges];
    const levelSpacing = 380;
    const siblingSpacing = 240;

    // Get parent node (selected node) and check if its children are hidden
    const parentNode = allNodes.find((node) => node.id === nodeId);
    const hidden = parentNode?.data?.childrenHidden;
    // Get the formatted children of the selected node to add them to the chart
    const formattedChildren = getFormattedData(children, t, true);

    // Hide and remove children of the selected node from the chart if they are shown
    if (!hidden) {
      const childrenIds = children.map((child) => child.id.toString());
      const childrenToRemove = getChildrenToRemove(childrenIds, allNodes);

      // Get the new position of the nodes after removing the children
      allNodes = getNewNodePosition(nodeId, allNodes, false);
      allNodes = allNodes.filter((singleNode) => !childrenToRemove.includes(singleNode.id));
      // Remove edges that are connected to the children nodes removed from the chart
      updatedEdges = updatedEdges.filter((edge) => !childrenToRemove.includes(edge.target));

      // Update the nodes and edges without the children
      setNodes((prevNodes) => allNodes.map((node) => {
        if (node.id === nodeId) {
          node.data.childrenHidden = !hidden;
          node.selected = false; // Force deselect the node to change its color
        }
        const index = prevNodes.findIndex((n) => n.id === node.id);
        return prevNodes[index];
      }));
      setEdges(updatedEdges);
      return;
    }

    // Show children of the selected node if they are hidden
    // Adjust the center of the chart to the selected node
    setCenter({
      x: parentNode.position.x + 120,
      y: parentNode.position.y + 420,
      zoom: adjustZoom(parentNode.data.childrenWidth),
    });

    // Calculate the x position of the first child node
    let currentX = (
      parentNode.position.x - (children.length * siblingSpacing) / 2
    ) + (siblingSpacing / 2);
    const y = parentNode.position.y + levelSpacing;

    // Add new nodes and edges to the chart
    formattedChildren.mainTree.children.forEach((child) => {
      const childId = child.id.toString();
      const x = currentX;
      const childrenWidth = child.children.length * siblingSpacing;

      allNodes.push({
        id: childId,
        type: "customNode",
        position: { x, y },
        data: {
          item: {
            avatar: child?.person.avatar || "",
            name: child?.person.name || "",
            title: child?.person.title || "",
            department: child?.person.department || "",
            country: child?.person.country || "",
            hasCriticalPosition: child?.person.hasCriticalPosition,
            isASuccessor: child?.person.isASuccessor,
          },
          parentId: nodeId,
          childrenCount: child.children.length || 0,
          childrenHidden: true,
          childrenWidth,
          onToggleChildren: () => handleNodeClick(childId, child.ancestry),
          onOpenModal: () => handleOpenModal(child),
        },
      });

      if (hidden) {
        updatedEdges.push({
          id: `e${nodeId}-${childId}`,
          source: nodeId,
          target: childId,
          type: "step",
        });
      }

      currentX += siblingSpacing;
    });

    // Get new nodes positions to avoid overlapping nodes
    allNodes = getNewNodePosition(nodeId, allNodes, hidden);

    // Update the nodes and edges with the new children of the selected node
    setNodes((prevNodes) => allNodes.map((node) => {
      if (node.id === nodeId) {
        node.data.childrenHidden = !hidden;
        node.selected = true; // Force select the node to change its color
      }

      // If the node already exists, update it
      const index = prevNodes.findIndex((n) => n.id === node.id);
      if (index !== -1) {
        return prevNodes[index];
      }

      // If the node doesn't exist, add it
      return node;
    }));
    setEdges(updatedEdges);
    // eslint-disable-next-line
  }, [selectedNode]);

  // Effect to handle updating the chart when the collaborator list changes
  useEffect(() => {
    if (collaboratorList.length > 0) {
      const { mainTree, unassigned, inactiveLeader} = getFormattedData(collaboratorList, t);
      setUnassignedList(unassigned);
      setInactiveLeaderList(inactiveLeader);

      const { newNodes, newEdges } = convertToFlowNodesAndEdges(
        mainTree.children,
        handleNodeClick,
        handleOpenModal,
      );
      const newCenter = getNewCenter(newNodes);
      setCenter(newCenter);
      setNodes(newNodes);
      setEdges(newEdges);
    }
    // eslint-disable-next-line
  }, [collaboratorList]);

  return {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    center,
    unassignedList,
    employeeSelected,
    isOpenMain,
    inactiveLeaderList,
    setIsOpenMain,
    handleOpenModal,
  };
};
