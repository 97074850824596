import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useComplexState from "hooks/utils/useComplexState";
import {
  getListByEmployee as getSurveyProcessesByEmployee,
} from "redux/actions/surveyProcessesActions";
import { getCalendarData, getEventsFromDate } from "views/Planning/functions";

export const useHomeCalendarHook = (externalHooks) => {
  const { dispatch, t } = externalHooks;

  const currentDate = new Date();
  const [calendarStates, setCalendarStates] = useComplexState({
    data: null,
    currentYear: currentDate.getFullYear(),
    currentMonth: currentDate.getMonth(),
    values: "",
    isOpenEventModal: false,
    selectedEvent: null,
  });

  const {
    listByEmployee,
    isLoadingListByEmployee,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  useEffect(() => {
    // Query only the ongoing processes
    // TODO: Check if we also want to show the future processes.
    // This adds complexity because, since they haven't started yet,
    // we can't ensure that the logged-in employee is actually eligible for the process.
    const query = { q: { aasm_state_eq: "running" } };
    dispatch(getSurveyProcessesByEmployee(query));
  }, [dispatch]);

  const handleEvent = (events) => {
    setCalendarStates({
      values: events,
    });
  };

  // events of the day
  const setEventsByCurrentDate = useCallback((calendarData) => {
    const generalData = getEventsFromDate(calendarData, currentDate);
    const allData = {
      events: generalData,
      date: currentDate,
      endDate: null,
    };

    setCalendarStates({
      values: allData,
    });

    setCalendarStates({
      currentYear: currentDate.getFullYear(),
      currentMonth: currentDate.getMonth(),
    });
  }, [currentDate, setCalendarStates]);

  // reset events on calendar if there is any change
  const refreshData = useCallback(() => {
    const calendarData = getCalendarData(listByEmployee, t);
    setEventsByCurrentDate(calendarData);
    setCalendarStates({
      data: calendarData,
    });
  }, [listByEmployee, t, setEventsByCurrentDate, setCalendarStates]);

  // set current year or month
  const handleControls = (index, value) => {
    setCalendarStates({
      [index]: value,
    });
  };

  useEffect(() => {
    if (!isLoadingListByEmployee) {
      if (listByEmployee) {
        refreshData();
      } else {
        setCalendarStates({
          values: { events: [] },
        });
      }
    }
    // eslint-disable-next-line
  }, [isLoadingListByEmployee, listByEmployee]);

  const handleEventModal = (data) => {
    setCalendarStates({
      isOpenEventModal: !calendarStates.isOpenEventModal,
      selectedEvent: data,
    });
  };

  return {
    calendarStates,
    setCalendarStates,
    surveysReducerStates: {
      listByEmployee,
      isLoadingListByEmployee,
    },
    handleControls,
    setEventsByCurrentDate,
    handleEvent,
    handleEventModal,
  };
};
