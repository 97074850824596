import { useContext } from "react";
import { SessionContext } from "modules/session/context";
import ViewModalComponent from "components/ViewModalComponent";
import CollaboratorsView from "./Collaborators.view";

const CollaboratorsController = (props) => {
  const {
    state: { user },
  } = useContext(SessionContext);

  return (
    <ViewModalComponent viewComponent={ (
      <CollaboratorsView
        user={ user }
        { ...props }
      />
    ) }
    />
  );
};

export default CollaboratorsController;
