import styled from "styled-components";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import palette, { HEAT_MAP_COLORS_RANGE, TABLE } from "theme/palette";

export const StyledGridHeatMap = styled(Grid)`
  direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledContainer = styled.div`
  .tCellWhite {
    background-color: ${palette.white};
  }
  .tCellOdd {
    background-color: ${TABLE.ODD};
  }
  .tCellRed {
    background-color: ${HEAT_MAP_COLORS_RANGE.critic};
  }
  .tCellRedLight {
    background-color: ${HEAT_MAP_COLORS_RANGE.low};
  }
  .tCellOrange {
    background-color: ${HEAT_MAP_COLORS_RANGE.medium};
  }
  .tCellYellow {
    background-color: ${HEAT_MAP_COLORS_RANGE.normal};
  }
  .tCellGreenDarker {
    background-color: ${HEAT_MAP_COLORS_RANGE.excellent};
  }
  .tCellGreen {
    background-color: ${HEAT_MAP_COLORS_RANGE.great};
  }
`;

export const StyledContent = styled(TableContainer)`
  padding: 0;
  width: 100%;
  max-height: 520px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const StyledSearch = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledTHead = styled(TableRow)`
  background: ${TABLE.BG_THEAD};
`;

export const StyledTHeadCell = styled(TableCell)`
  color: ${TABLE.TEXT_THEAD};
  border-left: ${`1px solid ${TABLE.TCELL_BORDER}`};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 14px;
  &:first-child {
    border-left-color: transparent;
    text-align: left;
    border-bottom-color: transparent;
  }
`;

export const StyledTBodyCell = styled(TableCell)`
  text-align: center;
  color: ${palette.black};
  border: ${`1px solid${palette.white}`};
  font-size: 14px;
  line-height: 7px;
  &:first-child {
    text-align: left;
    border-bottom: ${`1px solid ${TABLE.TCELL_BORDER}`};
  }
`;
