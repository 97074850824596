import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import {
  BUTTON_STYLE_TYPES, BUTTON_TYPE, INPUT_TYPE, VARIANT,
} from "common/constants";
import { isNotValid, isEmpty } from "common/helpers";
import RadioButtons from "./components/RadioButtons";
import {
  Content, Title, Actions, StyledContainer,
} from "./styles";

const AcknowledgmentForm = (props) => {
  const {
    t,
    feedbackStates,
    hookFeedbackForm,
    acknowledgementTypesList,
    acknowledgementTypesProcess,
    handleAcknowledgementSelected,
  } = props;

  const { control, isValid } = hookFeedbackForm;
  const { selectedAcknowledgmentItem } = feedbackStates;
  const { isLoadingProcessAcknowledgementTypes } = acknowledgementTypesProcess;

  const globalAcknowledgementTypes = acknowledgementTypesList?.filter((item) => item?.is_global);
  const localAcknowledgementTypes = acknowledgementTypesList?.filter((item) => !item?.is_global);

  const radioButtons = (data) => (
    <RadioButtons
      control={ control }
      controlName={ "feedback_acknowledgement.acknowledgement_type_id" }
      radioName={ "acknowledgment" }
      options={ data }
      selected={ selectedAcknowledgmentItem }
      onClick={ handleAcknowledgementSelected }
    />
  );
  const renderAccordion = () => {
    const globalAccordion = (isExpanded) => (
      <Accordion
        defaultExpanded={ isExpanded }
      >
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          aria-controls={ "panel1a-content" }
          id={ "panel1a-header" }
          isExpanded
        >
          {t("feedback:FeedbackTypesOfNala")}
        </AccordionSummary>
        <AccordionDetails>
          {radioButtons(globalAcknowledgementTypes)}
        </AccordionDetails>
      </Accordion>
    );
    const localAccordion = (isExpanded) => (
      <Accordion
        defaultExpanded={ isExpanded }
      >
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          aria-controls={ "panel2a-content" }
          id={ "panel2a-header" }
          isExpanded
        >
          {t("feedback:FeedbackTypesOfCompany")}
        </AccordionSummary>
        <AccordionDetails>
          {radioButtons(localAcknowledgementTypes)}
        </AccordionDetails>
      </Accordion>
    );
    return (
      isEmpty(localAcknowledgementTypes) ? globalAccordion(true) : (
        <>
          {localAccordion(true)}
          {globalAccordion(false)}
        </>
      )
    );
  };

  return (
    <StyledContainer data-testid={ "acknowledgment-form-view" } container >
      <Content item xs={ 12 }>
        <InputTextController
          type={ INPUT_TYPE.text }
          label={ t("feedback:acknowledgment") }
          placeholder={ t("feedback:write_message") }
          control={ control }
          rules={ { required: true } }
          multiline={ 6 }
          showHelperText={ false }
          required
          name={ "feedback_acknowledgement.message" }
        />
        <Title variant={ VARIANT.bodyOne } >
          {`${t("feedback:select_an_acknowledgment")} *`}
        </Title>
        {renderAccordion()}

      </Content>
      <Actions item xs={ 12 }>
        <Button
          type={ BUTTON_TYPE.submit }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          isDisabled={ !isValid || isNotValid(selectedAcknowledgmentItem) }
          isLoading={ isLoadingProcessAcknowledgementTypes }
        >
          {t("feedback:buttons.send_acknowledgment")}
        </Button>
      </Actions>
    </StyledContainer>
  );
};

AcknowledgmentForm.propTypes = {
  t: PropTypes.func.isRequired,
  feedbackStates: PropTypes.object.isRequired,
  hookFeedbackForm: PropTypes.object.isRequired,
  acknowledgementTypesList: PropTypes.array.isRequired,
  acknowledgementTypesProcess: PropTypes.object.isRequired,
  handleAcknowledgementSelected: PropTypes.func.isRequired,
};

export default AcknowledgmentForm;
