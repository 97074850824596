import styled from "styled-components"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const ProgressBar = styled.div`
  background: ${palette.linearProgress.complete};
  height: 20px;
  width: ${ (props) => props.value }%;
  position: relative;
`;

export const Title = styled(Typography)`
  padding-bottom: 20px;
  font-style: italic;
`;

export const Percentage = styled(Typography)`
  position: absolute;
  z-index: 999;
  right: 5px;
`;

export const ContainerChart = styled(Grid)`
  padding-bottom: 20px;
`;

export const ChartTitle = styled(Grid)`
  justify-content: flex-end;
`;
