import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import LoaderImageProfile from "views/OnboardingFollowUp/components/LoaderImageProfile";
import { formatDate, getAvatarPromise } from "common/utils";
import Results from "./Results";
import {
  StyledBoxContainer,
  StyledImageContainer,
  StyledTitleWithSubtitle,
  StyledTitle,
  StyledSubtitle,
  StyledProfileInfo,
} from "./styles";

const CollaboratorProfileSummary = (props) => {
  const {
    collaborator,
  } = props;

  const { t } = useTranslation(["common", "talentDrain"]);

  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const loadImage = async () => {
      setLoading(true);
      try {
        const url = await getAvatarPromise(collaborator);
        if (isMounted) setImageUrl(url);
      } catch (error) {
        if (isMounted) setImageUrl(null);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    loadImage();

    return () => {
      isMounted = false;
    };
  }, [collaborator]);

  const getProfileImage = () => {
    if (loading) return <LoaderImageProfile collaborator={ collaborator } component={ Avatar } />;
    return <Avatar src={ imageUrl } />;
  };

  const positionName = collaborator?.job_position?.position_name || collaborator?.position_name;
  const countryName = collaborator?.job_position?.country?.name || collaborator?.country_name;
  const startingDate = collaborator?.job_position?.starting_date || collaborator?.starting_date;

  return (
    <>
      <StyledBoxContainer>
        <StyledProfileInfo>
          <StyledImageContainer>
            {getProfileImage()}
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <StyledTitle>
              {collaborator.full_name}
            </StyledTitle>
            {positionName
            && (
              <StyledSubtitle>
                {positionName}
                <span>{`${t("common:common.from")} ${formatDate(startingDate)}`}</span>
              </StyledSubtitle>
            ) }
            {countryName
            && (
              <p>
                <span>
                  {`${t("common:common.location")}: ${countryName}`}
                </span>
              </p>
            ) }
          </StyledTitleWithSubtitle>
        </StyledProfileInfo>
      </StyledBoxContainer>
      <Results t={ t } collaborator={ collaborator } />
    </>
  );
};

CollaboratorProfileSummary.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorProfileSummary;
