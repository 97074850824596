import { orderByAsc } from "common/helpers";
import { OBJECT_KEYS } from "common/constants";
import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,

} from "../../actionTypes/performance";

const URL = {
  main: "/performance_processes",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getList = () => async (dispatch, getState) => {
  const { list } = getState().performanceReducer;
  if (!list) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main);
      const data = response.data.performance_processes;
      const orderedData = orderByAsc(data, OBJECT_KEYS.followUpProcessId);
      dispatch({
        type: GET_LIST,
        payload: orderedData,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};
