import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "@mui/material/Chip";
import SendIcon from "@mui/icons-material/Send";
import DoneIcon from "@mui/icons-material/Done";
import FeedbackIcon from "@mui/icons-material/Feedback";
import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import palette from "theme/palette";

const DEFAULT_COLOR_ICON = "white";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: (props) => (props.isMobile ? "0px" : theme.spacing(4)),
    backgroundColor: (props) => (props.isMobile ? "transparent" : palette.white),
    display: (props) => (props.isMobile ? "flex" : "block"),
    flexDirection: (props) => (props.isMobile ? "column" : "row"),
    rowGap: (props) => (props.isMobile ? "16px" : "0px"),
  },
}));

const iconPropsDefault = {
  color: DEFAULT_COLOR_ICON,
  "font-size": "small !important",
};

export const SendIconStyled = styled(SendIcon)`
  ${iconPropsDefault}
`;
export const DoneIconStyled = styled(DoneIcon)`
  ${iconPropsDefault}
`;
export const FeedbackIconStyled = styled(FeedbackIcon)`
  ${iconPropsDefault}
`;
export const InfoIconStyled = styled(InfoIcon)`
  ${iconPropsDefault}
`;
export const ClearIconStyled = styled(ClearIcon)`
  ${iconPropsDefault}
`;
export const ContactSupportIconStyled = styled(ContactSupportIcon)`
  ${iconPropsDefault}
`;
export const ChipStyled = styled(Chip)`
  background-color: ${(props) => props.bgcolor} !important;
  color: ${(props) => props.color};
  padding: 5px;

  .MuiChip-label {
    padding-right: 5px;
  }
`;
