import PropTypes from "prop-types";
import moment from "moment";
import forEach from "lodash/forEach";
import isNull from "lodash/isNull";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import SelectController from "components/SelectController";
import CurrencyTextFieldController from "components/CurrencyInputTextController";
import DateInputController from "components/DateInputController";
import AutoCompleteCreable from "components/AutocompleteCreable";
import { getItemFromLocalStorage, getAutocompleteAttr } from "common/utils";
import { LOCAL_STORAGE_NAMES } from "common/constants";

const Salary = (props) => {
  const { control, register, setValue } = props;
  const { t } = useTranslation(["common", "account"]);
  const typesOfContract = getItemFromLocalStorage(
    LOCAL_STORAGE_NAMES.typeOfContract
  );
  const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);

  const onAutocompleteChange = (nameOfAttr) => (prop, event, newValue) => {
    const autocompleteAttr = getAutocompleteAttr(newValue, nameOfAttr);
    setValue(
      "collaborator.salary_attributes.currency",
      !isNull(newValue) ? autocompleteAttr : ""
    );
  };

  const getTypeOfContract = () => {
    let typeOfContract = [];
    typesOfContract &&
      forEach(typesOfContract, (contract) => {
        typeOfContract.push({
          value: contract.id,
          label: contract.name,
        });
      });
    return typeOfContract;
  };

  return (
    <Card data-testid="salary-collaborator">
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DateInputController
              id="startingDate"
              label={t("common.startingDate")}
              control={control}
              name="collaborator.starting_date"
              openTo={"year"}
              value={moment()}
              views={["year", "month", "day"]}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectController
              id="typeOfContract"
              label={t("common.typeOfContract")}
              control={control}
              menuItems={getTypeOfContract()}
              name="collaborator.job_position_attributes.type_of_contract_id"
              defaultValue={""}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CurrencyTextFieldController
              id="salary"
              label={t("common.salary")}
              control={control}
              name="collaborator.salary_attributes.gross_salary"
              isRequired
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoCompleteCreable
              id={"currency"}
              options={currencies}
              label={t("common:common.currency.main")}
              register={register}
              onChange={onAutocompleteChange("name_with_code")}
              name={"collaborator.salary_attributes.currency"}
              nameOfAttr={"name_with_code"}
              isRequired
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Salary.propTypes = {
  control: PropTypes.object,
  register: PropTypes.object,
  setValue: PropTypes.object,
};

export default Salary;
