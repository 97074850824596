import styled from "styled-components";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import palette, { STATE_COLORS } from "theme/palette";

export const StyledContainer = styled.div`
  .MuiStepper-root {
    padding: 0;
    padding-bottom: 16px;
  }
  button {
    margin-right: 8px;
  }
  @media (max-width: 450px) {
    .MuiPaper-root {
      background-color: transparent;
      overflow-y: scroll;
    }
  }
`;

export const StyledActions = styled.div`
  text-align: right;
`;

export const StyledStepper = styled(Stepper)`
  .MuiSvgIcon-root {
    font-size: 38px;
    border: 1px solid ${STATE_COLORS.GREY};
    border-radius: 30px;
    color: ${palette.white};

    .MuiStepIcon-text {
    fill: ${STATE_COLORS.GREY};
    }

    &.Mui-completed, &.Mui-active {
      border: none;
      color: ${palette.text.link};
    }
  }
  .MuiStepIcon-root.MuiStepIcon-completed, .MuiStepIcon-root.MuiStepIcon-active {
    color: ${palette.text.link};
    border: none;
  }
  .Mui-completed .MuiStepIcon-text, .Mui-active .MuiStepIcon-text {
    fill: ${palette.white};
  }
  .MuiStepLabel-root {
    align-items: flex-start;
    cursor: pointer;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    text-align: left;
  }
  .MuiStepConnector-alternativeLabel{
    top: 18px;
    left: calc(-100% + 55px);
    right: calc(100% + 0px);
  }
`;

export const StepState = styled.span`
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  border: 1px solid ${(props) => props.color};
  border-radius: 100px;
  font-size: 12px;
  padding: 2px 12px;
`;

export const StyledStepLabel = styled(StepLabel)`
  .MuiTypography-body1 {
    font-weight: 700;
    margin-bottom: 8px;
  }
  @media (max-width: 450px) {
    &.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
      width: 180px;
    }
  }
`;
