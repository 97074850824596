import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {
  REL,
  TARGET,
  VARIANT,
} from "common/constants";
import { getTranslatedURL } from "common/utils";
import { getDescriptionBySection } from "views/Account/functions";
import {
  StyledContent, StyledDescription, StyledList, StyledSubtitle,
} from "./styles";

const Recommendations = (props) => {
  const { t, language, data } = props;

  return (
    <StyledContent>
      <StyledDescription variant={ VARIANT.bodyOne }>
        { getDescriptionBySection(t)[data?.key] }
      </StyledDescription>
      <StyledSubtitle>{ `${t("goodLeader:supportingMaterial")}:` }</StyledSubtitle>
      {data?.urls?.length > 0 && (
        <StyledList>
          {data.urls.map((item, index) => (
            <li key={ `recommendation-${item.id || index}` }>
              <Typography variant={ VARIANT.bodyOne }>
                <a
                  href={ getTranslatedURL(item.url, language) }
                  target={ TARGET.blank }
                  rel={ REL }
                >
                  {item.title}
                </a>
              </Typography>
            </li>
          ))}
        </StyledList>
      )}
    </StyledContent>
  );
};

Recommendations.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  data: PropTypes.object,
};

Recommendations.defaultProps = {
  data: {},
};

export default Recommendations;
