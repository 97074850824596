import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import palette from "theme/palette";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-scrollPaper {
    justify-content: flex-end;
  }
  .MuiDialog-paperFullScreen {
    width: ${(props) => props.width || "40%"};
  }
  .MuiDialogContent-root {
    margin-bottom: 30px;
  }
  @media (max-width: 1279px) {
    .MuiDialog-paperFullScreen {
      width: 50%;
    }
  }
  @media (max-width: 960px) {
    .MuiDialog-paperFullScreen {
      width: 70%;
    }
  }
  @media (max-width: 600px) {
    .MuiDialog-paperFullScreen {
      width: 100%;
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 10px 24px 16px;
    color: ${ palette.text.link };
    font-weight: 700;
    font-size: 28px;
`;
