import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Accordion from "components/Accordion";
import { BORDER_ACCORDION_TYPE } from "common/constants";
import { getSatisfactionByTopic } from "../../functions";
import { StyledAccordionContainer } from "../../styledComponents";

const SatisfactionByTopic = (props) => {
  const { topics, formsFilled, divisions } = props;
  const { t, i18n } = useTranslation("engagement");
  const { language } = i18n;

  return (
    <StyledAccordionContainer data-testid={ "satisfaction-by-topic-view-component" }>
      <Accordion
        isDefaultIconStyle
        type={ BORDER_ACCORDION_TYPE.square }
        data={ topics ? getSatisfactionByTopic(topics, formsFilled, divisions, t, language) : [] }
        iconLeft
      />
    </StyledAccordionContainer>
  );
};

SatisfactionByTopic.propTypes = {
  topics: PropTypes.array.isRequired,
  formsFilled: PropTypes.number.isRequired,
  divisions: PropTypes.array.isRequired,
};

export default SatisfactionByTopic;
