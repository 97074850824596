import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useReactFlow } from "reactflow";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { StyledTabsContainer } from "styledComponents/Tabs";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import { COLORS_NAME, TRANSITIONS_DURATION } from "common/constants";
import OrgChart from "../OrgChart";
import UnassignedGrid from "../UnassignedGrid";
import InactiveLeaderGrid from "../InactiveLeaderGrid";
import Modals from "../Modals";
import { getTabs } from "../../functions";
import { useNodes } from "../../hooks/useNodes";

const OrganizationChartView = () => {
  const [activeTab, setActiveTab] = useState(0);

  const { t } = useTranslation(["common", "feedback"]);

  // Hook to get and update viewport of reactflow instance
  const { setCenter, zoomIn, zoomOut } = useReactFlow();

  const {
    nodes,
    edges,
    onNodesChange,
    center,
    employeeSelected,
    unassignedList,
    isOpenMain,
    inactiveLeaderList,
    setIsOpenMain,
    handleOpenModal,
  } = useNodes();

  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  const tabs = getTabs(t, unassignedList, inactiveLeaderList);

  // Update reactflow viewport center when center state changes
  // by a node has been clicked to show your children
  useEffect(() => {
    setCenter(
      center.x,
      center.y,
      { duration: TRANSITIONS_DURATION.zoom, zoom: center.zoom },
    );
    // eslint-disable-next-line
  }, [center, nodes]);

  return (
    <>
      <Paper>
        <Box>
          <StyledTabsContainer
            spacing={ 4 }
            fontsize={ 14 }
            mt={ 0 }
            textcolor={ COLORS_NAME.black }
            normaltext={ "true" }
            uppercase={ "true" }
          >
            <Tab
              tabs={ tabs }
              onChange={ handleChangeTab }
              tabValue={ activeTab }
            />
          </StyledTabsContainer>
        </Box>
      </Paper>
      <TabPanel value={ activeTab } index={ 0 } />
      <TabPanel value={ activeTab } index={ 1 } />
      <TabPanel value={ activeTab } index={ 2 } />


      {activeTab === 0 && (
        <OrgChart
          nodes={ nodes }
          edges={ edges }
          onNodesChange={ onNodesChange }
          zoomIn={ zoomIn }
          zoomOut={ zoomOut }
          center={ center }
        />
      )}

      {activeTab === 1 && (
        <UnassignedGrid
          unassignedList={ unassignedList }
          t={ t }
          onOpenModal={ handleOpenModal }
        />
      )}

      {activeTab === 2 && (
        <InactiveLeaderGrid
          inactiveLeaderList={ inactiveLeaderList }
          t={ t }
          onOpenModal={ handleOpenModal }
        />
      )}

      <Modals
        t={ t }
        employeeSelected={ employeeSelected }
        isOpenMain={ isOpenMain }
        setIsOpenMain={ setIsOpenMain }
      />
    </>
  );
};

export default OrganizationChartView;
