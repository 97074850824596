import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";

import { UserCompanyListToolbar } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(3),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const UserCompanyList = function () {
  const classes = useStyles();
  return (
    <div className={ classes.root }>
      <UserCompanyListToolbar />
      <div className={ classes.content }>
        <Grid
          container
          spacing={ 3 }
	/>
 </div>
 </div>
  );
};

export default UserCompanyList;
