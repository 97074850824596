import { useTranslation } from "react-i18next";
import clsx from "clsx";
import SatisfactionIcon from "assets/images/engagement/satisfied_legend.svg";
import NeutralIcon from "assets/images/engagement/neutral_legend.svg";
import UnsatisfiedIcon from "assets/images/engagement/unsatisfied_legend.svg";
import useStyles from "./styles";

const LegendBySatisfaction = () => {
  const { t } = useTranslation("engagement");
  const classes = useStyles();
  return (
    <div
      data-testid={ "legend-by-satisfaction-view" }
      className={ classes.legend }
    >
      <div className={ clsx(classes.color, classes.satisfied) } />
      <img src={ SatisfactionIcon } alt={ t("satisfied") } className={ classes.icon } />
      <div className={ clsx(classes.color, classes.neutral) } />
      <img src={ NeutralIcon } alt={ t("neutral") } className={ classes.icon } />
      <div className={ clsx(classes.color, classes.unsatisfied) } />
      <img src={ UnsatisfiedIcon } alt={ t("unsatisfied") } className={ classes.icon } />
    </div>
  );
};

export default LegendBySatisfaction;
