import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SectionContainer from "components_refactor/SectionContainer";
import SimpleNineBox from "components/SimpleNineBox";
import NoData from "components/NoData";
import SkeletonLoader from "components/SkeletonLoader";
import constants from "styledComponents/constants";
import { replacePath } from "common/utils";
import { NINEBOX, SKELETONS_NUMBER } from "common/constants";
import { getDataNineBoxQuadrant } from "views/MatrixNineBox/functions";
import localRoutes from "../../functions/routes";
import {
  StyledCardTitle,
  StyledFixedContainer,
  StyledLink,
} from "../../styles";

const NineboxInfo = (props) => {
  const {
    t,
    history,
    currentTalentScore,
    resultScales,
    isLoadingCurrentTalentScore,
    handleQuadrantSelected,
  } = props;

  const [quadrantSelected, setQuadrantSelected] = useState(null);

  const { resultScaleList } = resultScales;

  const handleHistoricalNinebox = useCallback(async () => {
    if (currentTalentScore?.id) {
      const results = getDataNineBoxQuadrant(currentTalentScore, resultScaleList, t);
      if (results.length > 0) {
        const quadrant = results[0].quadrantData[NINEBOX.quadrantData.quadrant];
        setQuadrantSelected(quadrant);
        handleQuadrantSelected(quadrant);
      }
    }
  }, [currentTalentScore, resultScaleList, t]);

  useEffect(() => {
    if (currentTalentScore) {
      handleHistoricalNinebox();
    }
    // eslint-disable-next-line
  }, [currentTalentScore, resultScales]);

  // Render content based on the component's state
  const renderContent = () => {
    if (isLoadingCurrentTalentScore) {
      return <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } />;
    }

    if (currentTalentScore && quadrantSelected !== null) {
      return (
        <>
          <Grid container justifyContent={ "center" } mt={ 2 }>
            <Grid item xs={ 12 } sm={ 10 } md={ 6 }>
              <SimpleNineBox
                quadrantSelected={ quadrantSelected }
              />
            </Grid>
          </Grid>
          <StyledFixedContainer>
            <StyledLink onClick={ () => replacePath(history, localRoutes.ninebox) }>
              { t("common:common.viewMore") }
            </StyledLink>
          </StyledFixedContainer>
        </>
      );
    }

    return <NoData />;
  };

  return (
    <SectionContainer>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <StyledCardTitle weight={ constants.fontConstants.bold }>
            {t("profile:surveys.ninebox.title")}
          </StyledCardTitle>
          {renderContent()}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

NineboxInfo.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentTalentScore: PropTypes.object.isRequired,
  resultScales: PropTypes.any.isRequired,
  isLoadingCurrentTalentScore: PropTypes.bool.isRequired,
  handleQuadrantSelected: PropTypes.func.isRequired,
};

export default NineboxInfo;
