import makeStyles from "@mui/styles/makeStyles";
import styled from "styled-components";
import palette from "theme/palette";

// TODO: refactor - apply styled components
export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3),
  },
  staticProgressBorder: {
    borderBottom: theme.palette.border.simple,
    borderTop: theme.palette.border.simple,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  typography: {
    padding: 0,
    fontWeight: 500,
    fontSize: theme.spacing(1.4),
    color: theme.palette.text.disabled,
  },
  classification: {
    display: "flex",
    alignItems: "center",
  },
  goodLeaderText: {
    marginBottom: theme.spacing(3),
  },
  goodLeaderTextResults: {
    padding: theme.spacing(2),
  },
  progressLine: {
    border: theme.palette.border.light,
  },
}));

export const StyledComment = styled.p`
  padding: 10px;
  margin-bottom: 10px;
  background: ${palette.background.default};
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
`;
