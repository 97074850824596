import styled from "styled-components";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-height: 110px;
  padding: 8px 20px;
  border-radius: 4px;
  background: ${palette.white};
  box-shadow: ${palette.boxShadow.card2};
`;

export const StyledTitle = styled(Typography)`
  min-height: 42px;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  min-width: 100px;
  border-radius: 5px;
  margin: 10px 0;
  &.MuiLinearProgress-root {
    height: 12px;
    background-color: ${palette.linearProgress.incomplete};
  }
  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${(props) => props.customcolor || palette.text.link};
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.customcolor || palette.text.link};
  }
`;

export const StyledLink = styled.button`
  background: transparent;
  border: none;
  color: ${palette.text.link};
  cursor: pointer;
  align-self: flex-end;
  text-decoration-line: underline;
`;

export const StyledSpace = styled.div`
  height: 18px;
`;

export const StyledPoint = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${(props) => props.customcolor || palette.text.link};
`;

export const StyledName = styled(Grid)`
  ${({ point }) => point && `
  display: flex;
  align-items: center;
  column-gap: 8px;
  span {
    min-width: 25px;
  }
    p {
      min-height: unset;
    }
  }`}
`;
