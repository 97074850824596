import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import QueryString from "query-string";
import useComplexState from "hooks/utils/useComplexState";
import { SessionContext } from "modules/session/context";
import {
  INDEX,
  ROLES,
  MAX_FILE_WEIGHT,
} from "common/constants";
import { isEqual, isNull } from "common/helpers";
import {
  getParamEmployeeId,
  isAdmin,
} from "common/utils";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  getList as getDocumentList,
  resetState as resetStateDocumentList,
  resetStateProcess,
  create as createDocument,
  deleteItem as deleteDocument,
  update as updateDocument,
} from "redux/actions/collaborators/documentActions";
import {
  getList as getDocumentTypes,
  create as createDocumentType,
  resetState as resetStateDocumentTypes,
} from "redux/actions/documentTypeActions";
import localRoutes from "../functions/routes";

export const useDocumentHook = (externalHooks, internalHooks) => {
  const {
    t, dispatch, location,
  } = externalHooks;
  const {
    dispatchResetCollaborator,
  } = internalHooks;
  const {
    state: { user },
  } = useContext(SessionContext);

  const [documentsStates, setDocumentsStates] = useComplexState({
    currentCollaboratorId: null,
    selectDocument: null,
    dialog: false,
    newCreation: null,
    file: null,
  });

  const [defaultValue, setDefaultValue] = useState({
    name: null,
  });

  const {
    list: listCollaboratorDocument,
    loadingList: isLoadingListCollaboratorDocument,
    successProcess: successCollaboratorDocument,
    isLoadingProcess: isLoadingProcessCollaboratorDocument,
  } = useSelector(
    ({ collaboratorDocumentReducer }) => collaboratorDocumentReducer,
  );

  const { one: candidate } = useSelector(
    ({ candidateReducer }) => candidateReducer,
  );

  const {
    list: listDocumentTypes,
    isLoadingList: isLoadingListDocumentTypes,
    newDocument: newDocumentType,
  } = useSelector(({ documentTypesReducer }) => documentTypesReducer);

  const params = QueryString.parse(location?.search);

  const candidateValidation = isAdmin(user?.userCookies) && ROLES.CANDIDATE in params;
  const isDocumentsModalOpen = isEqual(location.pathname, localRoutes.documents);

  useEffect(() => {
    if (isDocumentsModalOpen) {
      const collaboratorId = getParamEmployeeId(user, params);
      setDocumentsStates({
        currentCollaboratorId: collaboratorId,
      });
      if (collaboratorId && !isLoadingListCollaboratorDocument && isNull(listCollaboratorDocument)) {
        dispatch(getDocumentList(collaboratorId));
      }
      if (!isLoadingListDocumentTypes && isNull(listDocumentTypes)) {
        dispatch(getDocumentTypes());
      }
    }
  // eslint-disable-next-line
  }, [
    isDocumentsModalOpen,
    listCollaboratorDocument,
    isLoadingListCollaboratorDocument,
    listDocumentTypes,
    isLoadingListDocumentTypes,
    user,
  ]);

  useEffect(() => {
    if (newDocumentType) {
      setDocumentsStates({
        selectDocument: newDocumentType.document_type,
      });
    }
    // eslint-disable-next-line
  }, [newDocumentType]);

  useEffect(() => {
    if (isDocumentsModalOpen && successCollaboratorDocument) {
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      dispatch(resetStateProcess());
      dispatch(resetStateDocumentList());
      dispatchResetCollaborator();
      setDocumentsStates({
        selectDocument: null,
      });
    }
    // eslint-disable-next-line
	}, [successCollaboratorDocument]);

  const handleChange = (e) => {
    dispatch(
      createDocument(
        e.target.files[INDEX.zero],
        documentsStates.selectDocument.id,
        documentsStates.currentCollaboratorId,
      ),
    );
  };

  const handleSelect = (prop, event, newValue) => {
    setDocumentsStates({
      selectDocument: newValue,
    });
    setDefaultValue({
      ...defaultValue,
      name: newValue?.name,
    });
  };

  const handleDialog = (option) => {
    setDocumentsStates({
      dialog: option,
    });
  };

  const handleSubmitDocumentType = (document) => {
    setDocumentsStates({
      newCreation: { name: document },
    });
    handleDialog(true);
  };

  const handleNew = async () => {
    await dispatch(
      createDocumentType(JSON.stringify(documentsStates.newCreation)),
    );
    setDefaultValue({
      ...defaultValue,
      name: documentsStates.newCreation?.name,
    });
    handleDialog(false);
    dispatch(resetStateDocumentTypes());
  };

  const handleDeleteDocument = (itemSelectedId) => {
    dispatch(deleteDocument(itemSelectedId));
  };

  const handleUpdateDocument = (formData, itemSelectedId) => {
    dispatch(updateDocument(formData, itemSelectedId));
  };

  const {
    control, watch, reset,
  } = useForm();

  const handleUpload = (event) => {
    const fileData = event.target?.files[INDEX.zero];
    const itemData = control.getValues();
    fileData.url = URL.createObjectURL(fileData);
    itemData.url = "";
    if (fileData.size > MAX_FILE_WEIGHT) {
      setDocumentsStates({ file: null });
      toast(MESSAGE_TYPES.error, {
        title: t("common:common.files.exceedSizeLimit"),
      });
    } else {
      setDocumentsStates({ file: fileData });
    }
  };

  const handleRemoveFile = () => {
    setDocumentsStates({ file: null });
  };

  const onSubmit = async () => {
    const itemData = control.getValues();
    if (itemData.url || documentsStates.file) {
      dispatch(
        createDocument(
          documentsStates.file,
          documentsStates.selectDocument.id,
          documentsStates.currentCollaboratorId,
          itemData.url,
        ),
      );
      setDocumentsStates({ file: null });
      setDefaultValue({
        ...defaultValue,
        name: null,
      });
      reset({ url: "" });
    } else {
      toast(MESSAGE_TYPES.error, {
        title: t("common:common.files.emptyFile"),
      });
    }
  };

  return {
    documentsHookInfo: {
      documentsStates,
      defaultValue,
      collaboratorDocument: {
        listCollaboratorDocument,
        isLoadingListCollaboratorDocument,
        successCollaboratorDocument,
        isLoadingProcessCollaboratorDocument,
      },
      documentsType: {
        listDocumentTypes,
        isLoadingListDocumentTypes,
        newDocumentType,
      },
      candidate,
      candidateValidation,
      documentsFunctions: {
        handleChange,
        handleSelect,
        handleDialog,
        handleSubmitDocumentType,
        handleNew,
        handleDeleteDocument,
        handleUpdateDocument,
        handleUpload,
        handleRemoveFile,
        onSubmit,
      },
      user,
      hookForm: {
        control,
        watch,
      },
    },
  };
};
