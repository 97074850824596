import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import SimpleAccordion from "components/SimpleAccordion";
import NoDataMessage from "components/NoDataMessage";
import commentIcon from "assets/images/potential/icon-recomendacion.svg";
import { renderComments } from "../../../../functions/performance";
import { useStyles } from "./styles";

const Comments = (props) => {
  const { values } = props;
  const { t } = useTranslation("performance", "engagement");
  const classes = useStyles();

  return (
    <Grid
      item
      xs={ 12 }
      className={ classes.comments }
      data-testid={ "good-leader-comments" }
    >
      <SimpleAccordion
        title={ t("engagement:comments") }
        subtitle={ t("performance:leaderComments") }
        src={ commentIcon }
      >
        {isEmpty(values) ? (
          <NoDataMessage />
        ) : (
          <Grid container spacing={ 2 }>
            {renderComments(values, classes)}
          </Grid>
        )}
      </SimpleAccordion>
    </Grid>
  );
};

Comments.propTypes = {
  values: PropTypes.array,
};

Comments.defaultProps = {
  values: [],
};

export default Comments;
