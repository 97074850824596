import { useContext } from "react";
import PropTypes from "prop-types";
import ViewModalComponent from "components/ViewModalComponent";
import { SessionContext } from "modules/session/context";
import { usePlanningFormHook } from "./hooks/planningForm.hook";
import { usePlanningDataHook } from "./hooks/planningData.hook";
import { usePlanningCalendarHook } from "./hooks/planningCalendar.hook";
import PlanningView from "./Planning.view";

const PlanningController = (props) => {
  const { t, dispatch, handleOpenModal } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  const planningFormStates = usePlanningFormHook({ dispatch, t });
  const planningCalendarStates = usePlanningCalendarHook(
    { dispatch, t, handleOpenModal },
    {
      setFormStates: planningFormStates.setFormStates,
    },
  );
  const planningDataStates = usePlanningDataHook(
    { dispatch, t, handleOpenModal },
    {
      formStates: planningFormStates.formStates,
      setFormStates: planningFormStates.setFormStates,
    },
  );

  return (
    <ViewModalComponent viewComponent={ (
      <PlanningView
        user={ user }
        { ...planningFormStates }
        { ...planningDataStates }
        { ...planningCalendarStates }
        // this props have the translation, dispatch, location, history and match props
        // from router LAYOUT, so we dont need to use the locaiton or history hook is we need it
        { ...props }
      />
    ) }
    />
  );
};

PlanningController.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default PlanningController;
