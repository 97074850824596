import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getList as getAttritionChart } from "redux/actions/attritionActions";
import { getPercent, getSortingOrder } from "common/utils";
import { PAGINATION } from "common/constants";
import usePagination from "hooks/utils/usePagination";

/* Custom hook to handle the data of RiskCardCollapse component */
export const useDataCollapse = ({
  type,
  data,
  total,
  totalByCategory,
  isLoading,
  query,
  searchFilter,
  handleCollapseState,
}) => {
  const [allData, setAllData] = useState([]);
  const [tableSort, setTableSort] = useState("");
  const { pagination, setPagination, handlePagination } = usePagination(1);

  const dispatch = useDispatch();

  const percent = (totalByCategory === 0) || (total === 0)
    ? 0 : getPercent(totalByCategory / total);

  const handleSort = (columnName, direction) => {
    setTableSort(getSortingOrder(columnName, direction));
  };

  useEffect(() => {
    // Reset data and pagination when query or tableSort changes
    setAllData([]);
    setPagination(PAGINATION.next);
    handleCollapseState(type, false);
    // eslint-disable-next-line
  }, [query, tableSort]);

  useEffect(() => {
    if (((searchFilter !== null && searchFilter !== undefined) || tableSort) && query) {
      if (tableSort) {
        query.q.s = tableSort;
      }
      const lastQuery = query;
      lastQuery.q.employee_person_full_name_cont = searchFilter;
      setAllData([]);
      handleCollapseState(type, tableSort !== "");
      dispatch(getAttritionChart(PAGINATION.next, type, lastQuery));
    }
    // eslint-disable-next-line
  }, [searchFilter, tableSort]);

  useEffect(() => {
    if (!isLoading && data && data.length > 0) {
      if (pagination === 1) {
        setAllData(data);
      } else {
        dispatch(getAttritionChart(pagination, type, query));
      }
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    if (!isLoading && data && data.length > 0) {
      setAllData(pagination === 1 ? data : allData.concat(data));
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [data]);

  return {
    allData,
    pagination,
    percent,
    handleSort,
    handlePagination,
  };
};
