import qs from "qs";
import {
  LOCAL_STORAGE_NAMES, OBJECT_KEYS, PAGINATION, PARAMS_SERIALIZER_OPTIONS,
} from "common/constants";
import { getEmployeeId, getItemFromLocalStorage } from "common/utils";
import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";
import {
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE,
} from "../../actionTypes/common/manager";

// NOTE: we need manteing the URL's close to the actions
const URL = {
  main: "/collaborators",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getListSearchPaginated = (query) => async (dispatch) => {
  const employeeId = getEmployeeId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  dispatch({
    type: GET_LIST_LOADING,
  });
  const pagination = { size: PAGINATION.maxPerPage };

  try {
    const response = await configAxios.get(URL.main, {
      params: { ...query, [OBJECT_KEYS.employeeId]: employeeId, page: pagination },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST,
      payload: response.data.collaborators,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};
