import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { ALIGN_ITEMS, JUSTIFY, VARIANT } from "common/constants";
import { STATE_TYPES } from "views/Planning/components/ProcessDetail/functions";
import { StyledCard, StyledState, StyledButton } from "./styles";

const EventCard = (props) => {
  const { t, data, onClick } = props;

  return (
    <StyledCard>
      <CardContent>
        <Box mb={ 1 } display={ ALIGN_ITEMS.flex } >
          <img
            src={ data.processType?.icon }
            alt={ "icon" }
          />
          <Box ml={ 2 }>
            <Typography variant={ VARIANT.h5 }>
              { data.name }
            </Typography>
            <Typography variant={ VARIANT.bodyTwo }>
              { data.processType?.label }
            </Typography>
          </Box>
        </Box>
        <Box display={ ALIGN_ITEMS.flex } justifyContent={ JUSTIFY.spaceBetween }>
          <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
            <Typography>
              { `${t("planning:processDetail.title")}:` }
            </Typography>
            <StyledState>
              { STATE_TYPES[data.processDetail?.status]?.icon }
              <Typography variant={ VARIANT.h6 }>
                { t(`planning:processDetail.status.${data.processDetail?.status}`) }
              </Typography>
            </StyledState>
          </Box>
          <StyledButton
            onClick={ () => onClick(data) }
            variant={ VARIANT.text }
          >
            { t("common:common.viewMore") }
          </StyledButton>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

EventCard.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EventCard;
