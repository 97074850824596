import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { VARIANT, TYPES } from "common/constants";
import { getList as getDynamicAttribute } from "redux/actions/common/dynamicAttributeActions";

const DynamicAttributesData = (props) => {
  const {
    isEdit,
    attributesList,
    dynamicFieldsList,
    setDynamicFieldsList,
  } = props;

  const {
    list: dynamicAttributesList,
  } = useSelector(({ dynamicAttributeReducer }) => dynamicAttributeReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDynamicAttribute());
  }, [dispatch]);

  useEffect(() => {
    const formatElements = dynamicAttributesList?.map((item) => {
      const customAttributesData = attributesList?.find((e) => e.name === item.name);
      return {
        id: item.id,
        custom_attribute_id: customAttributesData?.id || null,
        label: item.name,
        value: customAttributesData?.value || "",
      };
    });
    setDynamicFieldsList(formatElements);
  }, [dynamicAttributesList, attributesList, setDynamicFieldsList]);

  const handleChange = (prop) => (event) => {
    const objIndex = dynamicFieldsList?.findIndex((obj) => obj.label === prop.label);
    setDynamicFieldsList([
      ...dynamicFieldsList.slice(0, objIndex),
      { ...dynamicFieldsList[objIndex], value: event.target.value },
      ...dynamicFieldsList.slice(objIndex + 1),
    ]);
  };

  const allFields = dynamicFieldsList?.map((item) => (
    <Grid item xs={ 12 } sm={ 6 }>
      <TextField
        type={ TYPES.text }
        label={ item.label }
        value={ item.value }
        variant={ VARIANT.outlined }
        margin={ VARIANT.dense }
        onChange={ handleChange(item) }
        disabled={ !isEdit }
        fullWidth
      />
    </Grid>
  ));

  return allFields || null;
};

DynamicAttributesData.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  attributesList: PropTypes.array.isRequired,
  dynamicFieldsList: PropTypes.array.isRequired,
  setDynamicFieldsList: PropTypes.func.isRequired,
};

export default DynamicAttributesData;
