import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import MultiSelect from "./MultiSelect";

const MultiSelectController = (props) => {
  const {
    name,
    data,
    control,
    defaultValue,
  } = props;

  // This function is called when the MultiSelect component's value changes
  const handleOnChange = (selectedValue) => {
    // Set the selected value in the form using the react-hook-form control
    control.setValue(name, selectedValue);
  };

  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({ field }) => (
        <MultiSelect
          data={ data }
          { ...field }
          onChange={ handleOnChange }
          defaultValue={ defaultValue }
        />
      ) }
    />
  );
};

MultiSelectController.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.shape({
    parent: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
    child: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  }),
};

MultiSelectController.defaultProps = {
  defaultValue: null,
};

export default MultiSelectController;
