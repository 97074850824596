import {
  RESET_STATE_SURVEY_RESULTS,
  GET_LIST_SURVEY_RESULTS,
  GET_LIST_LOADING_SURVEY_RESULTS,
  GET_LIST_ERROR_SURVEY_RESULTS,

  RESET_STATE_SURVEY_RESULTS_TO_DOWNLOAD,
  GET_LIST_SURVEY_RESULTS_TO_DOWNLOAD,
  GET_LIST_LOADING_SURVEY_RESULTS_TO_DOWNLOAD,
  GET_LIST_ERROR_SURVEY_RESULTS_TO_DOWNLOAD,
} from "../actionTypes/surveyProcesses";

const INITIAL_STATE = {
  list: null,
  listTotal: 0,
  isLoadingList: false,
  errorList: "",

  downloadList: null,
  isLoadingDownloadList: false,
  errorDownloadList: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE_SURVEY_RESULTS:
    return {
      ...state,
      isLoadingList: false,
      errorList: "",
      listTotal: 0,
      list: null,
    };
  case GET_LIST_SURVEY_RESULTS:
    return {
      ...state,
      list: action.payload,
      listTotal: action.total,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING_SURVEY_RESULTS:
    return {
      ...state,
      list: null,
      isLoadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR_SURVEY_RESULTS:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case RESET_STATE_SURVEY_RESULTS_TO_DOWNLOAD:
    return {
      ...state,
      downloadList: null,
      isLoadingDownloadList: false,
      errorDownloadList: "",
    };
  case GET_LIST_LOADING_SURVEY_RESULTS_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: true,
    };
  case GET_LIST_SURVEY_RESULTS_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: false,
      downloadList: action.payload,
    };
  case GET_LIST_ERROR_SURVEY_RESULTS_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: false,
      errorDownloadList: action.payload,
    };
  default:
    return state;
  }
};
