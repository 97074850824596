import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

const getLabelColor = ({ isDanger, customcolor }) => {
  if (isDanger) return palette.linearProgress.negative;
  return customcolor;
};

export const StyledContent = styled.div`
  h6 {
    font-size: 11px;
    font-weight: 600;
  }
  img {
    width: 24px;
    margin-bottom: 5px;
  }
`;

export const StyledText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSubText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: ${palette.linearProgress.text}
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  min-width: 100px;
  border-radius: 5px;
  &.MuiLinearProgress-root {
    height: ${(props) => (props.isSublevel ? "8px" : "10px")};
    background-color: ${palette.linearProgress.incomplete};
  }
  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${(props) => (props.isPrimaryColor ? palette.text.link : props.customcolor)};
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${getLabelColor};
`;
