import Grid from "@mui/material/Grid";
import palette from "theme/palette";
import styled from "styled-components";

export const StyledRadio = styled.div`
  padding: 6px 16px;
  width: 100%;
  height: 106px;
  border: ${ palette.border.radio };
  cursor: pointer;
  position: relative;
  :hover {
    border: ${ palette.border.radioSelected };
  }
  &.active {
    .MuiTypography-root div {
      color: ${ palette.text.link };
    }
  }
  .MuiFormControlLabel-root{
    margin: 0px;
    align-items: flex-start;
  }
  .MuiTypography-root{
    margin-top: 12px;
  }
`;

export const StyledGrid = styled(Grid)`
  padding: 30px 0px;
`;

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
`;

export const StyledFooter = styled.div`
  text-align: center;
  a{
    padding: 10px 45px;
  }
`;
