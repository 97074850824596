import { STATES, STATE_TRANSITION } from "common/constants/agreements";
import errorHandler from "../../errorHandler";
import {
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE,
} from "../../actionTypes/agreementStates";

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getList = () => (dispatch) => {
  dispatch({
    type: GET_LIST_LOADING,
  });
  try {
    const response = {
      data: [
        {
          id: STATES.pending,
          value: STATES.pending,
          transition: STATE_TRANSITION.pending,
        },
        {
          id: STATES.finished,
          value: STATES.finished,
          transition: STATE_TRANSITION.finish,
        },
      ],
    };
    dispatch({
      type: GET_LIST,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};
