import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components/Filter";
import TalentManager from "views/TalentManager";
import {
  ORG_UNITS, DEFAULT_SORTS, DOWNLOAD_TYPE,
} from "common/constants";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import usePagination from "hooks/utils/usePagination";
import useHandleDispatch from "hooks/utils/useHandleDispatch";
import { getSurveyProcessParticipation } from "redux/actions/surveyProcessesActions";
import {
  StyledDashboardContainer,
} from "styledComponents/View";
import { StyledTabsContainer } from "styledComponents/Tabs";
import Matrix from "./components/Matrix";

const LeadershipView = (props) => {
  const { t, handleExternalQuery } = props;

  const [tabHandler, setTabHandler] = useState(0);

  const allTabs = [{ label: t("common:common.report") }, { label: t("common:common.survey") }];

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  // TODO: remove
  const dispatch = useDispatch();
  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [dispatched, setDispatched] = useState(false);
  const [filterQuery, setFilterQuery] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [selectHeatMap, setSelectHeatMap] = useState(ORG_UNITS.division);
  const [tableSort, setTableSort] = useState(DEFAULT_SORTS.score);

  const [values, setValues] = useState({
    evaluation: "",
    downloadHeatMap: DOWNLOAD_TYPE.none,
  });

  const resetDispatched = (isReset) => {
    setDispatched(isReset);
  };

  const handleExternalLeadershipQuery = (query) => {
    const tempFilterQuery = query;
    dispatch(getSurveyProcessParticipation(values.evaluation, query));
    tempFilterQuery.q.s = tableSort;
    tempFilterQuery.q.employee_person_full_name_cont = searchFilter;
    setFilterQuery(tempFilterQuery);
  };

  const prevSelectedHeatMapRef = useRef();
  useEffect(() => {
    prevSelectedHeatMapRef.current = selectHeatMap;
  });
  const prevSelectedHeatMap = prevSelectedHeatMapRef.current;

  useHandleDispatch(pagination, setDispatched);

  const handleExternalLeadership = (query) => {
    setFilterQuery(query);
    handleExternalQuery(query);
  };

  // end remove

  return (
    <StyledDashboardContainer>
      <ViewHeaderTitle title={ t("leadership:title") } />
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } >
          {tabHandler === 0 || !values.evaluation
            ? (
              <Filter
                isLabel
                type={ FILTER_ACTIONS_TYPES.noActions }
                isLoading={ false }
                externalParams={ [] }
                handleExternalQuery={ handleExternalLeadership }
              />
            ) : (
              <Filter
                type={ FILTER_ACTIONS_TYPES.goodLeader }
                isLabel
                externalParams={ [
                  values.evaluation,
                  selectHeatMap,
                  false,
                  pagination,
                ] }
                pagination={ pagination }
                dispatched={ { value: dispatched, reset: resetDispatched } }
                handleExternalQuery={ handleExternalLeadershipQuery }
                isMultiple
              />
            )}
        </Grid>
      </Grid>
      <StyledTabsContainer item xs={ 12 } spacing={ 1 }>
        <Tab
          tabs={ allTabs }
          onChange={ handleValueChanged }
          tabValue={ tabHandler }
        />
        <TabPanel value={ tabHandler } index={ 0 }>
          <Matrix { ...props } />
        </TabPanel>
        <TabPanel value={ tabHandler } index={ 1 }>
          <TalentManager
            values={ values }
            filterQuery={ filterQuery }
            selectHeatMap={ selectHeatMap }
            setValues={ setValues }
            prevSelectedHeatMap={ prevSelectedHeatMap }
            setSelectHeatMap={ setSelectHeatMap }
            setSearchFilter={ setSearchFilter }
            searchFilter={ searchFilter }
            tableSort={ tableSort }
            setPagination={ setPagination }
            pagination={ pagination }
            handlePagination={ handlePagination }
            setTableSort={ setTableSort }
          />
        </TabPanel>
      </StyledTabsContainer>
    </StyledDashboardContainer>
  );
};

LeadershipView.propTypes = {
  t: PropTypes.func.isRequired,
  handleExternalQuery: PropTypes.func.isRequired,
};

export default LeadershipView;
