import { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { INDEX } from "common/constants";
import ModuleView from "./ModuleView";

const ContainerManager = (props) => {
  const {
    modules,
    // innerModules, // modules'internal validations
    moduleToShow,
    extraAttributes,
    containerName,
    staticModules, // for modules who doesnt have any api call
    dispatchs, // array object
    isLoading,
  } = props;

  const executeDispatchs = useCallback(async () => {
    for (let i = INDEX.zero; i < dispatchs.length; i++) {
      const { action } = dispatchs[i];
      const { params } = dispatchs[i];
      extraAttributes.dispatch(action(...params));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!staticModules && dispatchs && !isLoading) {
      // needed in case of the module is static
      executeDispatchs();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ModuleView
      moduleList={ staticModules || modules }
      moduleToShow={ moduleToShow || modules }
      extraAttributes={ extraAttributes }
      containerName={ containerName }
    />
  );
};

ContainerManager.propTypes = {
  modules: PropTypes.array.isRequired,
  containerName: PropTypes.string.isRequired,
  extraAttributes: PropTypes.object.isRequired,
  staticModules: PropTypes.object,
  moduleToShow: PropTypes.array,
  dispatchs: PropTypes.array,
  isLoading: PropTypes.bool,
};

ContainerManager.defaultProps = {
  staticModules: null,
  moduleToShow: null,
  dispatchs: null,
  isLoading: false,
};

export default ContainerManager;
