import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DownloadExcelButton from "components/DownloadExcelButton";
import AlertDialog from "components/AlertDialog";
import TableGrid from "components/TableGrid";
import TooltipIcon from "components/TooltipIcon";
import {
  toast,
  handleMessages,
  MESSAGE_TYPES,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  MAX_PER_PAGE, ROWS_PER_PAGE, TYPES, BUTTON_STYLE_TYPES /* , PAGINATION */ } from "common/constants";
import { formatDate } from "common/utils";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import usePagination from "hooks/utils/usePagination";
import {
  getPositionsPaginated, download, submitKeyPosition,
} from "redux/actions/position/positionActions";
import { ReactComponent as CriticalPosition } from "assets/images/icons/critic.svg";
import { getRows } from "../../functions";
import {
  StyledText, StyledButton, CriticalInfoContainer,
  StyledModalButton, StyledCancelButton,
} from "./styles";

// TODO: Add pagination
const PositionsUnitTable = (props) => {
  const { t, filterQuery, handleOpenModal } = props;
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const history = useHistory();

  const { pagination/* , setPagination, handlePagination */ } = usePagination(1);
  // const [searchFilter, setSearchFilter] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [criticalPosition, setCriticalPosition] = useState(null);

  // const handleSearch = (name = "") => {
  //   setSearchFilter(name);
  // };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getText = (text) => <StyledText>{text}</StyledText>;

  const getModalButton = (position) => {
    if (position?.active_collaborators_count === 0) {
      return <Typography>{position.active_collaborators_count}</Typography>;
    }

    const positionUnit = {
      positionId: position?.position_id,
      positionName: position?.position_name,
      orgUnitId: position?.organization_unit_id,
      orgUnitName: position?.organization_unit_name,
      query: filterQuery,
      successorsCount: null,
      collaboratorsCount: position.active_collaborators_count,
      isCriticalPosition: position?.key_position?.critical,
    };

    return (
      <StyledModalButton onClick={ () => handleOpenModal(
        history.location.pathname,
        null,
        positionUnit,
      ) }
      >
        {position.active_collaborators_count}
      </StyledModalButton>
    );
  };

  const handleDialog = (state, data = null) => {
    setCriticalPosition(data);
    setDialog(state);
  };

  const getKeyPosition = (rowData) => {
    if (rowData?.key_position?.id) {
      return {
        key_position: {
          id: rowData.key_position.id,
          critical: !rowData.key_position.critical,
        },
      };
    } if (rowData?.position_id
      && rowData?.organization_unit_id) {
      return {
        key_position: {
          position_id: rowData.position_id,
          organization_unit_id: rowData.organization_unit_id,
          critical: true,
        },
      };
    }
    return null;
  };

  const markCriticalPosition = (position) => {
    const positionData = getKeyPosition(position);

    return (
      <StyledButton
        type={ TYPES.text }
        onClick={ () => handleDialog(true, { id: position.id, data: positionData }) }
      >
        {t("collaborators:table.table-body.mark_critical")}
      </StyledButton>
    );
  };

  const unMarkCriticalPosition = (position) => {
    const positionData = getKeyPosition(position);

    return (
      <StyledCancelButton
        typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
        onClick={ () => handleDialog(true, { id: position.id, data: positionData }) }
        size={ "small" }
      >
        <TooltipIcon title={ t("collaborators:table.table-body.unmark_critical") } element={ <CloseIcon /> } />
      </StyledCancelButton>
    );
  };

  const criticalPositionInfo = (position) => (
    <CriticalInfoContainer>
      <TooltipIcon
        title={ t("collaborators:key_position.title") }
        element={ <CriticalPosition fontSize={ "small" } /> }
      />
      <div className={ "critical-details" }>
        <p>{position?.key_position?.last_updated_by}</p>
        <p>{formatDate(position?.key_position?.last_updated_at)}</p>
      </div>
      {unMarkCriticalPosition(position)}
    </CriticalInfoContainer>
  );

  const getCriticalInfo = (position) => {
    if (!position?.position_name || !position.organization_unit_name) {
      return "";
    }
    return position?.key_position?.critical
      ? criticalPositionInfo(position)
      : markCriticalPosition(position);
  };

  const getSuccessionButton = (position) => {
    if (!position?.position_name || !position.organization_unit_name) {
      return position.active_successors_count;
    }

    const positionUnit = {
      positionId: position?.position_id,
      positionName: position?.position_name,
      orgUnitId: position?.organization_unit_id,
      orgUnitName: position?.organization_unit_name,
      query: filterQuery,
      successorsCount: position.active_successors_count,
      collaboratorsCount: position.active_collaborators_count,
      isCriticalPosition: position?.key_position?.critical,
    };

    return (
      <StyledModalButton onClick={ () => handleOpenModal(
        history.location.pathname,
        null,
        positionUnit,
      ) }
      >
        {position.active_successors_count}
      </StyledModalButton>
    );
  };

  const header = [
    {
      id: "position_name",
      label: t("administrator:modules.positions.header.name"),
      customRender: (rowData) => getText(rowData.position_name),
    },
    {
      id: "organization_unit_name",
      label: t("administrator:modules.positions.header.unit"),
      customRender: (rowData) => getText(rowData.organization_unit_name),
    },
    {
      id: "active_collaborators_count",
      label: t("administrator:modules.positions.header.collaborators_count"),
      customRender: (rowData) => getModalButton(rowData),
    },
    {
      id: "is_key_position",
      label: t("administrator:modules.positions.header.critical_position"),
      customRender: (rowData) => getCriticalInfo(rowData),
    },
    {
      id: "active_successors_count",
      label: t("administrator:modules.positions.header.successors"),
      customRender: (rowData) => getSuccessionButton(rowData),
    },
  ];

  // const {
  //   data: { data: positionList = null, total: positionListTotal = 0 } = {},
  //   isLoading: isLoadingPositionList,
  //   error: positionListError,
  //   refetch,
  // } = useQuery(
  //   ["positionList", pagination, query],
  //   () => getPositionsPaginated(pagination, query),
  //   {
  //     staleTime: Infinity,
  //     cacheTime: Infinity,
  //     enabled: false,
  //   },
  // );

  const {
    data: { data: positionList = [] /* total: positionListTotal = 0 */ } = {},
    isLoading: isLoadingPositionList,
    // refetch,
  } = useQuery(
    ["positionList", pagination, filterQuery/* , searchFilter */],
    () => getPositionsPaginated(pagination.number, filterQuery),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: /* Boolean(searchFilter) || */ Boolean(filterQuery),
    },
  );

  // useEffect(() => {
  //   if (!isLoadingPositionList && positionList !== null) {
  //     const newData = getRows(positionList, t);
  //     setRows(newData);
  //   }
  //   // eslint-disable-next-line
  // }, [positionList]);

  useEffect(() => {
    if (!isLoadingPositionList) {
      const newData = getRows(positionList, t);
      setRows(newData);
    }
    // eslint-disable-next-line
  }, [isLoadingPositionList, positionList, t]);

  // useEffect(() => {
  //   const paginationSearch = searchFilter ? PAGINATION.next : pagination;
  //   setPagination(paginationSearch);
  //   refetch();
  //   // eslint-disable-next-line
  // }, [searchFilter, pagination]);

  // TODO: add modal
  const handleSendExcel = async () => {
    const asyncTask = await dispatch(download());
    if (asyncTask?.aasm_state === "pending") {
      toast(MESSAGE_TYPES.info, {
        title: t("administrator:modules.positions.download.title"),
        message: t("administrator:modules.positions.download.message"),
      });
    }
  };

  const handleMarkCriticalPosition = async () => {
    const positionId = criticalPosition.id;
    const keyPosition = await dispatch(submitKeyPosition(criticalPosition.data));
    if (keyPosition?.id) {
      const message = t(`collaborators:success.${keyPosition.critical ? "mark_critical" : "unmarked_critical"}`);
      toast(
        MESSAGE_TYPES.success,
        handleMessages(
          MESSAGE_TYPES.success,
          HTTP_STATUS_RESPONSE.ok,
          t,
          message,
        ),
      );
      const positionIndex = rows.findIndex((row) => row.id === positionId);
      if (positionIndex !== -1) {
        const newRows = [...rows];
        newRows[positionIndex] = {
          ...newRows[positionIndex],
          is_key_position: keyPosition.critical,
          key_position: keyPosition,
        };
        setRows(newRows);
      }
    }
    setCriticalPosition(null);
    setDialog(false);
  };

  return (
    <>
      <TableGrid
        rows={ rows }
        id={ "position-table-grid" }
        header={ header }
        paginationOptions={ {
          maxPerPage: MAX_PER_PAGE,
          rowsRange: ROWS_PER_PAGE,
        } }
        topContent={ <DownloadExcelButton onClick={ () => handleSendExcel() } /> }
        isMobile={ isMobile }
        isLoading={ isLoadingPositionList }
        searchBy={ "position_name" }
      />
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => handleDialog(false) }
        title={ t("collaborators:key_position.title") }
        message={ t(`collaborators:key_position.${criticalPosition?.key_position?.critical ? "" : "un"}mark_critical`) }
        onSubmit={ handleMarkCriticalPosition }
        buttons={ {
          isLoading: false,
        } }
      />
    </>
  );
};

PositionsUnitTable.propTypes = {
  t: PropTypes.func.isRequired,
  filterQuery: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default PositionsUnitTable;
