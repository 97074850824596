import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styled from "styled-components";

export const StyleTypography = styled(Typography)`
  margin: 12px 0 25px;
`;

export const StyledDetails = styled.div`
  .MuiAccordion-root{
    margin-bottom: 8px;
  }
`;

export const StyleList = styled.ul`
  padding-left: 20px;
  font-size: 14px;
`;

export const StyledResult = styled.div`
  margin: 20px 0;
`;

export const StyledGrid = styled(Grid)`
  text-align: right;
`;
