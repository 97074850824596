import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Table from "components/Table";
import Button from "components/Button";
import AlertDialog from "components/AlertDialog";
import ModalCardInfo from "components/ModalCardInfo";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  ROWS_RANGE_PER_PAGE,
} from "common/constants";
import { formatDate } from "common/utils";
import { deleteItem, getAgreementsByEmployeeId } from "redux/actions/agreements/agreementActions";
import { setChildrensList } from "redux/actions/collaboratorActions";
import { getAgreementsHeader } from "views/DevelopmentPlan/functions";
import AddModal from "../AddModal";
import ModalDetails from "../ModalDetails";
import { StyledAddCircleIcon, StyledCollapse } from "./styles";

const CollapseAgreementsTable = (props) => {
  const {
    data, onClickNode, employeeId,
    isValidToPrivateAgreements, isValidToSuccessionPlan,
  } = props;
  const { t } = useTranslation(["developmentPlan", "tables"]);
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [agreementsList, setAgreementsList] = useState();
  const [selectAgreement, setSelectAgreement] = useState();
  const [deleteValue, setDeleteValue] = useState(0);
  const {
    control, handleSubmit, register, reset,
  } = useForm({
    defaultValues: {
      "agreement.title": "",
      "agreement.description": "",
      "agreement.ending_date": moment(),
    },
  });

  const dispatch = useDispatch();

  const {
    childrenList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    successProcessAgreement,
    isLoadingProcessAgreement,
  } = useSelector(({ agreementsReducer }) => agreementsReducer);

  const {
    list: statesList,
  } = useSelector(({ agreementsStatesReducer }) => agreementsStatesReducer);

  const setAgreements = useCallback(async () => {
    const agreementList = await dispatch(getAgreementsByEmployeeId(data.id));
    setAgreementsList(agreementList);
  }, [data, dispatch]);

  useEffect(() => {
    setAgreements();
  }, [setAgreements]);

  useEffect(() => {
    if (selectAgreement === null) {
      setModal(true);
    }
  }, [selectAgreement]);

  const updateAgreements = useCallback(async () => {
    const agreementList = await dispatch(getAgreementsByEmployeeId(data.id));
    if (agreementList && childrenList) {
      childrenList.forEach((item) => {
        if (item.id === data.id) {
          item.agreements = agreementList;
        }
      });
    }
    dispatch(setChildrensList(childrenList));
    setAgreementsList(agreementList);
  }, [dispatch, data.id, childrenList]);

  useEffect(() => {
    if (successProcessAgreement) {
      setModal(false);
      updateAgreements();
    }
  }, [successProcessAgreement, updateAgreements, t]);

  const handleAddModal = () => {
    setModal(!modal);
  };

  const handleModalDetails = () => {
    setModalDetails(!modalDetails);
  };

  const editAgreement = (row) => {
    setModal(true);
    setSelectAgreement(row);
  };

  const viewAgreement = (row) => {
    setModalDetails(true);
    setSelectAgreement(row);
  };

  const handleOpenClose = (option) => {
    setIsOpen(option);
  };

  const handleDeleteAgreement = (id) => {
    setDeleteValue(id);
    handleOpenClose(true);
  };

  const deleteAgreement = () => {
    handleOpenClose(false);
    dispatch(deleteItem(deleteValue));
  };

  const showAgreement = () => {
    setModal(true);
    setSelectAgreement(null);
  };

  return (
    <StyledCollapse>
      <Table
        header={ getAgreementsHeader(
          t,
          statesList,
          editAgreement,
          handleDeleteAgreement,
          onClickNode,
          viewAgreement,
          employeeId,
        ) }
        list={ agreementsList }
        isLoading={ isLoadingProcessAgreement }
        searchBy={ "title" }
        paginationOptions={ {
          maxPerPage: ROWS_RANGE_PER_PAGE[0],
          rowsRange: ROWS_RANGE_PER_PAGE,
        } }
      />
      <Button
        variant={ VARIANT.outlined }
        color={ COLORS_NAME.primary }
        typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
        onClick={ showAgreement }
      >
        <StyledAddCircleIcon />
        {t("tables:actions.create_agreement")}
      </Button>
      <AddModal
        isOpen={ modal }
        onClose={ handleAddModal }
        handleSubmit={ handleSubmit }
        reset={ reset }
        register={ register }
        control={ control }
        employeeId={ data.id }
        selectAgreement={ selectAgreement }
        isValidToPrivateAgreements={ isValidToPrivateAgreements }
        isValidToSuccessionPlan={ isValidToSuccessionPlan }
      />

      <ModalCardInfo
        isOpen={ modalDetails }
        onClose={ handleModalDetails }
        title={ selectAgreement?.title }
        subtitle={ `${t("tables:headers.review-date")}: ${formatDate(selectAgreement?.ending_date)}` }
      >
        <ModalDetails data={ selectAgreement } />
      </ModalCardInfo>
      <AlertDialog
        isOpen={ isOpen }
        onClose={ () => handleOpenClose(false) }
        title={ t("common:common.modal_messages.sure_question") }
        message={ `${t("common:common.modal_messages.delete_text")} ${t("theAgreement")}?` }
        onSubmit={ deleteAgreement }
        buttons={ {
          isLoading: isLoadingProcessAgreement,
        } }
      />
    </StyledCollapse>
  );
};

CollapseAgreementsTable.propTypes = {
  data: PropTypes.object.isRequired,
  onClickNode: PropTypes.func.isRequired,
  employeeId: PropTypes.string,
  isValidToPrivateAgreements: PropTypes.bool,
  isValidToSuccessionPlan: PropTypes.bool,
};

CollapseAgreementsTable.defaultProps = {
  employeeId: null,
  isValidToPrivateAgreements: false,
  isValidToSuccessionPlan: false,
};

export default CollapseAgreementsTable;
