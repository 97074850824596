import styled from "styled-components";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import palette from "theme/palette";
import { getColorByStatus } from "./functions";

export const StyledPaperProcess = styled(Paper)`
  padding: 0;
  margin: 0 0 10px 0;
  border-left: ${(props) => `10px solid ${ getColorByStatus(props.status)}` };
`;

export const StyledProcessContent = styled.div`
  display: flex;
  padding: 15px;
  svg {
    margin-right: 10px;
    font-size: 32px;
  }
  h2 {
    font-size: 16px;
    padding-bottom: 2px;
    text-decoration: ${(props) => (props.isComplete ? "line-through" : "") };
    color: ${(props) => (props.isComplete ? palette.text.iconDisabled : "") };
  }
  p {
    font-size: 12px;
    color: ${palette.text.theadSubtitleText};
    color: ${(props) => (props.isComplete ? palette.text.iconDisabled : "") };
  }
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${palette.text.link};
`;

export const StyledCircleIcon = styled(Brightness1Icon)`
  color: ${palette.text.inactiveLight};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLinkEvaluation = styled.span`
  color: ${palette.text.link};
  font-size: 12px;
  text-decoration: none;
  svg{
    font-size: 12px;
    vertical-align: middle;
    margin-left: 2px;
  }
`;

export const StyledProcessDetail = styled(Box)`
  width: 100%;
`;

export const StyledProcessDescription = styled(Box)`
  display: flex;
  align-items: center;
`;
