import PropTypes from "prop-types";
import AceEditor from "react-ace";
import { CODE_EDITOR } from "common/constants";
import { StyledEditor, StyledTypography } from "./styles";

const InputCodeEditor = (props) => {
  const {
    onChange, label, placeholder,
  } = props;

  return (
    <StyledEditor>
      <StyledTypography>{label}</StyledTypography>
      <AceEditor
        placeholder={ placeholder }
        onChange={ onChange }
        fontSize={ 16 }
        showPrintMargin={ false }
        showGutter
        highlightActiveLine={ false }
        setOptions={ {
          showLineNumbers: true,
        } }
        width={ CODE_EDITOR.totalPercentage }
        height={ CODE_EDITOR.height }
      />
    </StyledEditor>
  );
};

InputCodeEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

InputCodeEditor.defaultProps = {
  label: null,
  placeholder: null,
};

export default InputCodeEditor;
