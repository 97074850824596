import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StaticProgressBar from "components/StaticProgressBar";
import {
  MIN_VALUE,
  VARIANT,
} from "common/constants";
import { getPercent, getScaleByScore } from "common/utils";
import { StyledGrid } from "./styles";

const GeneralResult = (props) => {
  const { data, scale } = props;
  const { t } = useTranslation("performance");

  return (
    <StyledGrid container spacing={ 2 } data-testid={ "good-leader-general-result" }>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography variant={ VARIANT.h5 }>{ t("leadershipAssessment") }</Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 5 }>
        { data ? (
          <StaticProgressBar
            title={ data?.result || " " }
            percentage={ getPercent(data?.score || MIN_VALUE) }
            color={ getScaleByScore(data?.score, scale)?.color }
          />
        ) : (
          <StaticProgressBar
            title={ " " }
            percentage={ MIN_VALUE }
          />
        )}
      </Grid>
    </StyledGrid>
  );
};

GeneralResult.propTypes = {
  data: PropTypes.object.isRequired,
  scale: PropTypes.array,
};

GeneralResult.defaultProps = {
  scale: [],
};

export default GeneralResult;
