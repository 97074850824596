import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";

import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_PROCESS_AGREEMENT_CATEGORIES,
  PROCESS_AGREEMENT_CATEGORIES,
  PROCESS_AGREEMENT_CATEGORIES_LOADING,
  PROCESS_AGREEMENT_CATEGORIES_ERROR,
} from "../../actionTypes/agreementCategories";

const URL = {
  main: "/agreement_categories",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_PROCESS_AGREEMENT_CATEGORIES,
  });
};

export const getList = () => async (dispatch) => {
  dispatch({ type: GET_LIST_LOADING });
  try {
    const response = await configAxios.get(URL.main);
    dispatch({
      type: GET_LIST,
      payload: response.data.agreement_categories,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const create = (data) => async (dispatch) => {
  dispatch({
    type: PROCESS_AGREEMENT_CATEGORIES_LOADING,
  });
  try {
    const response = await configAxios.post(URL.main, data);
    dispatch({
      type: PROCESS_AGREEMENT_CATEGORIES,
      payload: response.data.agreement_category,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_AGREEMENT_CATEGORIES_ERROR);
  }
};
