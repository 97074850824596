import i18n from "i18next";
import get from "lodash/get";

export const getErrorMessage = (errors, field, id = field) => {
  const errorsField = get(errors, field);
  if (errorsField?.message) {
    return errorsField.message;
  }

  if (errorsField?.type) {
    return `${i18n.t(`formValidations:fields.${id}`)} ${i18n.t(
			`formValidations:validations.${errorsField.type}`,
    )}`;
  }
  return null;
};
