import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import dataLoading from "assets/images/general/data_loading.svg";
import { ALIGN_ITEMS } from "common/constants";
import { StyledContainer, StyledMessage, StyledContent } from "./styles";

const NoDataResult = () => {
  const { t } = useTranslation("common");

  return (
    <StyledContainer>
      <StyledContent>
        <Box
          display={ ALIGN_ITEMS.flex }
          justifyContent={ ALIGN_ITEMS.center }
        >
          <img
            src={ dataLoading }
            alt={ "No info to load" }
          />
        </Box>
        <StyledMessage>
          <h4>{t("surveys:not_published.title")}</h4>
          {t("surveys:not_published.message")}
        </StyledMessage>
      </StyledContent>
    </StyledContainer>
  );
};

export default NoDataResult;
