import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EmailIcon from "@mui/icons-material/Email";
import palette from "theme/palette";

export const StyledContainer = styled(Grid)`
  margin-top: 20px;
  .MuiPaper-root{
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 16px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${palette.background.mediumGrey};
      border-radius: 10px;
      border: 4px ${palette.white} solid;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${palette.background.mediumGrey};
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
`;

export const StyledProfileImage = styled(Avatar)`
  margin: auto;
  &.MuiAvatar-root {
    width: 170px;
    height: 170px;
  }
  border: 7px solid ${palette.text.purpleLight};
  top: 15px;
`;

export const StyledProfileImageContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  background: ${palette.white};
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 1px solid ${palette.text.darkDisabled};
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 20px;
  .MuiCircularProgress-root {
    position: absolute;
    width: 215px !important;
    height: 215px !important;
    margin-top: -8px;
    margin-left: -8px;
  }
  & .MuiCircularProgress-circle {
    stroke-width: 1.6;
  }
  & .MuiCircularProgress-svg {
    color: ${palette.text.success};
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
  }
`;

export const StyledContent = styled.div`
  text-align: center;
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
`;

export const StyledTeamList = styled.div`
  display: flex;
  margin: 0;
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  h4 {
    font-size: 14px;
  }
  a {
    font-size: 12px;
  }
`;

export const StyledEmailIcon = styled(EmailIcon)`
  font-size: 14px;
  color: ${palette.text.link};
  vertical-align: middle;
  margin-right: 3px;
`;

export const StyledDays = styled.p`
  position:absolute;
  right: -15px;
  top: -15px;
  font-weight: 500;
  font-size: 14px;
`;

export const StyledCard = styled(Card)`
  margin-top: 5px;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 24px;
`;

export const StyledProcessContainer = styled.div`
  min-height: 320px;
  max-height: 320px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${palette.background.mediumGrey};
    border-radius: 10px;
    border: 4px ${palette.background.light} solid;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${palette.background.mediumGrey};
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;
export const StyledTitle = styled.div`
  font-weight: 500;
  margin: 15px 0 30px;
`;

export const StyledResult = styled.div`
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  h4 {
    font-weight: 700;
    font-size: 16px;
    color: ${palette.text.grayDark};
    padding-bottom: 10px;
  }
`;
