import styled from "styled-components";
import Chip from "@mui/material/Chip";

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${(props) => props.background};
    color: ${(props) => props.textcolor};
    margin-bottom: 5px;
  }
  .MuiChip-deleteIcon {
    color: ${(props) => props.textcolor};
  }
`;
