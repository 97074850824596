import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledSectionContainer = styled.div`
  margin-top: 20px;
  .MuiMobileStepper-root {
    background: ${ palette.white };
  }
  h4 {
    margin-bottom: 30px;
  }
`;

export const StyledContainer = styled.div`
  margin-top: 10px;
  background: ${ palette.white };
  border-radius: 5px;
  .MuiPaper-elevation1 {
    box-shadow: none;
    min-height: 40vh;
  }
  .MuiCardContent-root {
    padding: 0px;
  }
  @media (max-width: 450px) {
    .MuiButton-root {
      font-size: 12px;
    }
  }
  .MuiMobileStepper-root {
    padding: 10px 0;
  }
`;

export const StyledContent = styled.div`
  padding: 10px 30px;
  @media (max-width: 960px) {
    padding: 10px;
  }
`;

export const StyledMobileContainer = styled.div`
  background: ${ palette.white };
  padding: 10px;
  border-radius: 5px;
  .MuiCardContent-root {
    padding: 0px;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiMobileStepper-root {
    background: transparent;
  }
  @media (max-width: 960px) {
    padding: 0px;
    font-family: "Lato";
    .MuiTypography-root {
      font-family: "Lato";
    }
    .MuiSlider-markLabel {
      font-family: "Lato";
    }
    .MuiButton-root {
      font-family: "Lato";
    }
  }
`;

export const StyledAnswersContent = styled.div`
  .MuiLinearProgress-root {
    height: 10px;
    margin: 15px 0;
    border-radius: 5px;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: ${ palette.background.mediumGrey };
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${ palette.linearProgress.complete };
  }
`;

export const StyledProgressContainer = styled.div`
  .MuiLinearProgress-root {
    width: 400px;
    border-radius: 5px;
    height: 10px;
    margin: auto;
    background-color: ${palette.linearProgress.incomplete};
  }
  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${palette.linearProgress.complete};
  }
  .MuiTypography-body1 {
    font-size: 10px;
    text-align: center;
  }
`;

export const StyledBox = styled.div`
  width: 100%;
  border: 0.5px solid ${palette.black};
  border-radius: 8px;
  padding: 12px 20px 25px;
  @media (max-width: 600px) {
    margin: 12px 0;
  }
  .MuiButton-outlinedPrimary {
    border: 1px solid ${palette.text.link};
    color: ${palette.text.link};
  }
`;

export const StyledBoxTitle = styled.div`
  position: absolute;
  margin-top: -24px;
  margin-left: -2px;
  background-color: white;
  padding: 0 15px 0 10px;
  font-weight: 700;
`;

export const StyledBoxDescription = styled(Typography)`
  font-size: 16px;
  text-align: left;
  margin-top: 20px;
`;

export const StickyDetail = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  background-color: ${palette.white};
  padding: 24px 0;
  z-index: 1;
  border-bottom: ${palette.border.simpleThin};
`;

export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledHelpIcon = styled(HelpIcon)`
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: 0;
  padding-bottom: 2px;
`;
