import makeStyles from "@mui/styles/makeStyles";

// TODO: refactor all file - add styled components
export const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    position: "relative",
    paddingBottom: 0,
  },
  container: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  typography: {
    padding: 0,
    fontWeight: 500,
    fontSize: theme.spacing(1.5),
  },
  closeButton: {
    padding: 0,
  },
  closeIcon: {
    padding: "3px",
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  itemAvatar: {
    minWidth: "auto",
    paddingRight: theme.spacing(1),
    margin: 0,
  },
  itemText: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    backgroundColor: theme.palette.background.lightGrey,
    borderRadius: 5,
    margin: 0,
    marginBottom: theme.spacing(0.5),
    "& p": {
      lineHeight: "1rem",
      wordWrap: "break-word",
    },
  },
  title: {
    fontWeight: 700,
  },
  listItem: {
    padding: theme.spacing(0.5),
    paddingTop: 0,
  },
  list: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    overflow: "auto",
    maxHeight: 200,
    "&::-webkit-scrollbar": {
      width: "7px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.darkGrey,
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: theme.palette.scroll.thumbActive,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.scroll.thumbHover,
      boxShadow: theme.palette.scroll.boxShadow,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-track:hover,::-webkit-scrollbar-track:active": {},
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: theme.palette.scroll.firefoxColor,
  },
}));
