import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import isUndefined from "lodash/isUndefined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Table from "components/Table";
import SimpleAlert from "components/SimpleAlert";
import SkeletonLoader from "components/SkeletonLoader";
import AlertDialog from "components/AlertDialog";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import {
  WARNING,
  ERROR,
  BUTTON_STYLE_TYPES,
  PERCENTAGE,
  VARIANT,
  COLORS_NAME,
  SKELETONS_NUMBER,
  GOAL_RULES,
} from "common/constants";
import { preventEnter } from "common/utils";
import {
  create as createGoal,
  deleteItem as deleteGoalItem,
  update as updateGoal,
  resetStateProcess as resetStateGoalProcess,
} from "redux/actions/goalsActions";
import {
  totalCell,
  getDataEmpty,
  getTotalGoals,
  formatData,
  getUpdatedGoals,
  isUpdatedGoals,
  getGoalsHeader,
} from "../../functions";
import PeriodCollapse from "../PeriodCollapse";
import useCollapse from "./useCollapse";
import { useStyles, StyledContainer, StyledButton } from "./styles";

// TODO: refactor this component
const CollapseGoalsTable = (props) => {
  const {
    data, rules, isGeneral, isUpdateAllowed,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation(["goals", "tables", "common", "collaborator", "periods"]);
  const dispatch = useDispatch();
  const {
    isLoadingProcess: isLoadingGoalProcess,
    errorProcess: errorGoalProcess,
    successProcess: successGoalProccess,
  } = useSelector(({ goalsReducer }) => goalsReducer);

  // TODO: update with useComplexState
  const [modal, setModal] = useState(false);
  const [modalRuleValidation, setModalRuleValidation] = useState(false);
  const [totalSum, setTotalSum] = useState();
  const [goalsEmployeeId, setGoalsEmployeeId] = useState();
  const [editData, setEditData] = useState(false);
  const [deleteValue, setDeleteValue] = useState(0);
  const [alert, setAlert] = useState({
    open: true,
    message: t("goals:alert.warning"),
    type: WARNING,
  });

  const [goalToSaveState, setGoalToSaveState] = useState();

  const {
    control, handleSubmit, reset, watch,
  } = useForm();

  const {
    values,
    setValues,
    newData,
    setNewData,
    setNewValues,
    getGoalsGroupedByPeriods,
    periodListTransformed,
    handleDuplicate,
    editGoal,
    closeForm,
  } = useCollapse(data, t, reset);

  const handleAlert = useCallback(
    (totalSum) => {
      const itIsEqual = totalSum === PERCENTAGE.max;
      const itIsGreaterThan = totalSum > PERCENTAGE.max;
      const itIsLowerThan = totalSum < PERCENTAGE.max;
      const typeValidation = itIsGreaterThan && !itIsEqual ? ERROR : itIsLowerThan && WARNING;

      setAlert({
        open: !itIsEqual,
        message: itIsEqual ? "" : t(`goals:alert.${typeValidation}`),
        type: isUndefined(typeValidation) ? "" : typeValidation,
      });
    },
    [t],
  );

  useEffect(() => {
    totalSum && handleAlert(totalSum);
  }, [totalSum, handleAlert]);

  const sumTotal = useCallback((goalsData) => {
    const totalGoal = getTotalGoals(
      watch("goals.id"),
      watch("goals.weight"),
      goalsData || values,
    );
    setTotalSum(totalGoal);
    return totalGoal;
  }, [values, watch]);

  const updateValues = () => {
    values.pop();
    values.push(getDataEmpty(periodListTransformed), totalCell);
    setValues(values);
    editGoal(0);
  };

  useEffect(() => {
    if (newData) {
      updateValues();
    }
    // eslint-disable-next-line
  }, [newData]);

  const onSubmitDispatch = (goalToSave, isRuleValidationTriggered) => {
    const goalData = {
      goal: {
        description: goalToSave.description,
        employee_id: data.id,
        metric: goalToSave.metric,
        weight: goalToSave.weight,
        completed_value: goalToSave.completed_value,
        starting_date: goalToSave.starting_date,
        end_date: goalToSave.end_date,
        goal_rule_id: goalToSave.goal_rule_id,
        period_id: goalToSave.period_id,
        subperiod_id: goalToSave.subperiod_id,
      },
    };

    if (goalToSave.id !== 0) {
      dispatch(updateGoal(goalData, goalToSave.id));
    } else {
      dispatch(createGoal(goalData));
    }
    setEditData(true);
    setGoalsEmployeeId(data.id);
    if (isRuleValidationTriggered) {
      setModalRuleValidation(false);
    }
  };

  const validateDates = (startingDate, endDate) => {
    if (!startingDate || !endDate) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.required_dates") });
      return false;
    }

    if (new Date(endDate) <= new Date(startingDate)) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.validation_dates") });
      return false;
    }

    return true;
  };

  const onSubmit = (goalsData) => {
    if (goalsData) {
      const { starting_date: startingDate, end_date: endDate } = goalsData.goals;

      if (!validateDates(startingDate, endDate)) {
        return;
      }

      dispatch(resetStateGoalProcess());
      const goalToSave = formatData(goalsData);

      // more is better: completed_value / metric
      // less is better: (metric - completed_value) / metric + 1

      const ruleSelected = rules.filter((item) => item.id === goalToSave.goal_rule_id);

      let ruleValidation = 0;
      // more is better
      if (ruleSelected[0].name === GOAL_RULES.moreIsBetter) {
        ruleValidation = goalToSave.completed_value / goalToSave.metric;
      } else if (ruleSelected.name === GOAL_RULES.lessIsBetter) {
        ruleValidation = (goalToSave.metric - goalToSave.completed_value) / goalToSave.metric + 1;
      }

      ruleValidation *= 100;

      if (ruleValidation > 1000) {
        setGoalToSaveState(goalToSave);
        setModalRuleValidation(true);
      } else {
        onSubmitDispatch(goalToSave);
      }
    }
  };

  const deleteGoal = () => {
    dispatch(resetStateGoalProcess());
    setModal(false);
    setDeleteValue(deleteValue);
    dispatch(deleteGoalItem(deleteValue));
    setEditData(true);
    setGoalsEmployeeId(data.id);
  };

  const handleDeleteGoal = (id) => {
    setDeleteValue(id);
    setModal(true);
  };

  const resetData = useCallback(
    (goalSaved) => {
      dispatch(resetStateGoalProcess());
      setValues(null);
      setNewData(false);
      const updatedGoals = getUpdatedGoals(data.goals, goalSaved, deleteValue, sumTotal);
      setNewValues(updatedGoals);
      setGoalsEmployeeId(0);
      setEditData(false);
    },
    [data, deleteValue, sumTotal, dispatch],
  );

  useEffect(() => {
    editData
      && isUpdatedGoals(
        goalsEmployeeId,
        data.id,
        isLoadingGoalProcess,
        successGoalProccess,
        errorGoalProcess,
      )
      && resetData(successGoalProccess);
  }, [
    successGoalProccess,
    data,
    goalsEmployeeId,
    resetData,
    editData,
    isLoadingGoalProcess,
    errorGoalProcess,
  ]);

  const showTableContent = (groupedValues) => (
    <Table
      header={ getGoalsHeader(control, rules, classes, totalSum, {
        sumTotal, editGoal, handleDeleteGoal, closeForm, handleDuplicate,
      }, t, isGeneral, periodListTransformed, isUpdateAllowed) }
      list={ groupedValues }
      isSimpleTable
      customStyles={ classes.customTable }
      isNotPagination
      paginationOptions={ {
        maxPerPage: 100,
      } }
    />
  );

  return (
    <StyledContainer data-testid={ "collapse-goals-table" }>
      {(isLoadingGoalProcess && (goalsEmployeeId === data.id && values === null)) || values === null ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FIVE } />
      ) : (
        <>
          <form
            id={ "goals-form" }
            onSubmit={ handleSubmit(onSubmit) }
            className={ classes.goalsForm }
            onKeyPress={ preventEnter }
            autoComplete={ "off" }
          >
            {isGeneral && data.goals.length > 0
              ? (
                <PeriodCollapse
                  dataByYear={ getGoalsGroupedByPeriods(data.goals) }
                  showTableContent={ showTableContent }
                />
              ) : showTableContent(values)}
          </form>
          {!isGeneral
          && (
            <StyledButton
              variant={ VARIANT.outlined }
              color={ COLORS_NAME.primary }
              typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
              isDisabled={ (totalSum === PERCENTAGE.max) || newData }
              onClick={ () => {
                setNewData(true);
              } }
            >
              <AddCircleOutlineIcon className={ classes.addGoalIcon } />
              {t("tables:actions.add_goal")}
            </StyledButton>
          )}
          {alert.open && !isGeneral && (
            <SimpleAlert type={ alert.type } message={ alert.message } />
          )}

        </>
      )}
      {/* TODO: handle this two alert dialog as one Modal */}
      <AlertDialog
        isOpen={ modal }
        onClose={ () => setModal(false) }
        title={ t("common:common.modal_messages.sure_question") }
        message={ t("tables:confirm_actions.text_confirm") }
        onSubmit={ deleteGoal }
        buttons={ {
          isLoading: false,
        } }
      />
      <AlertDialog
        isOpen={ modalRuleValidation }
        onClose={ () => setModalRuleValidation(false) }
        title={ t("common:common.modal_messages.sure_question") }
        message={ t("goals:alert.ruleValidation") }
        onSubmit={ () => onSubmitDispatch(goalToSaveState, true) }
        buttons={ {
          isLoading: false,
        } }
      />
    </StyledContainer>
  );
};

CollapseGoalsTable.propTypes = {
  data: PropTypes.object,
  rules: PropTypes.array,
  isGeneral: PropTypes.bool,
  isUpdateAllowed: PropTypes.bool,
};

CollapseGoalsTable.defaultProps = {
  data: {},
  rules: [],
  isGeneral: false,
  isUpdateAllowed: true,
};

export default CollapseGoalsTable;
