import styled from "styled-components";
import theme from "theme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "components/Modal";
import palette from "theme/palette";

export const StyledGridContainer = styled(Grid)`
  margin-bottom: ${theme.spacing(2)}px;
  align-items: center;
`;

export const StyledClassification = styled(Box)`
  display: flex;
  align-Items: center;
  min-width: 600px;
`;

export const StyledGridContentContainer = styled(Grid)`
  margin-top: 12px;
`;

export const StyledGridFilter = styled(Grid)`
  margin-top: 12px;
`;

export const StyledResult = styled.div`
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  h4 {
    font-weight: 700;
    font-size: 16px;
    color: ${palette.text.grayDark};
    padding-bottom: 10px;
  }
`;

export const StyledModal = styled(Modal)`
  padding: 0px !important;
`;
