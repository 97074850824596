import { hot } from "react-hot-loader/root";
import ReactGA from "react-ga";
import { Router } from "react-router-dom";
// TODO: remove hotjar
// import { hotjar } from "react-hotjar";
import { clarity } from "react-microsoft-clarity";
import posthog from "posthog-js";
import { Chart } from "react-chartjs-2";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@mui/material";
import Routes from "Routes";
import { Provider } from "Context";
import { SessionProvider } from "modules/session/context";
import { chartjs } from "helpers";
import { setUserAttributes } from "helpers/user_attributes";
import theme from "theme";
import Toast from "components/Toast";
import ErrorBoundary from "components/ErrorBoundary";
import {
  GA_TRACKING_ID,
  CLARITY_ID,
  POSTHOG_KEY,
  POSTHOG_HOST,
} from "common/constants";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/scss/index.scss";

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

ReactGA.initialize(GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

browserHistory.listen((location) => {
  window.ga("set", "page", location.pathname + location.search);
  window.ga("send", "pageview");
});

// // initialize Hotjar
// if (process.env.REACT_APP_ENV === "production") {
//   hotjar.initialize(HJID, 6);
//   setUserAttributes("hotjar");
// }

// initialize clarity
if (process.env.REACT_APP_ENV === "development"
|| process.env.REACT_APP_ENV === "production") {
  clarity.init(CLARITY_ID);
  setUserAttributes("clarity");
  // initialize posthog
  posthog.init(POSTHOG_KEY, { api_host: POSTHOG_HOST });
  setUserAttributes("posthog");
}

const App = () => (
  <ErrorBoundary>
    <Provider>
      <SessionProvider>
        <ThemeProvider theme={ theme }>
          <Router history={ browserHistory }>
            <Routes />
            <Toast />
          </Router>
        </ThemeProvider>
      </SessionProvider>
    </Provider>
  </ErrorBoundary>
);

export default hot(App);
