import styled from "styled-components";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import { ReactComponent as CriticalPosition } from "assets/images/icons/critic.svg";
import { ReactComponent as SuccessionIcon } from "assets/images/icons/succession.svg";
import palette from "theme/palette";

const getBorderColor = (props) => {
  if (props?.hasCriticalPosition) {
    return palette.text.purpleLink;
  } if (props.active === 1) {
    return palette.primaryApp;
  }
  return "none";
};

export const StyledCard = styled(Card)`
  width: 200px;
  height: 260px;
  display: flex;
  justify-content: center;
  border: ${(props) => `1px solid ${getBorderColor(props)}`};
  border-radius: 4px;
  box-shadow: ${(props) => (props.active === 1 ? palette.boxShadow.card2 : "none")};
  cursor: default;
`;

export const StyledContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  height: 100%;
  text-align: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin: 0 auto;
  width: 70px;
  height: 70px;
`;
export const StyledFullName = styled(Button)`
  color: ${palette.text.secondaryTitle};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;

  &:hover {
    background-color: transparent;
    color: ${palette.text.linkHover};
  }
`;

export const StyledTitle = styled(Typography)`
  font-size: 11px;
  line-height: 11.5px;
  font-weight: ${(props) => (props.bold === 1 ? 700 : 400)};
  text-transform: uppercase;
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => (props.active === 1 ? palette.background.button : palette.background.light)};
  color: ${(props) => (props.active === 1 ? palette.white : palette.text.purple)};
  margin: 0 auto;
  padding: 5px 16px;
  border-radius: 49px;
  font-weight: ${(props) => (props.active === 1 ? 700 : 400)};
  box-shadow: none;

  &:hover {
    background-color: ${(props) => (props.active === 1 ? palette.background.button : palette.background.light)};
    color: ${(props) => (props.active === 1 ? palette.white : palette.text.linkHover)};
  }
`;

export const AvatarIconContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 auto;
`;

export const StyledCriticalPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCriticalPositionIcon = styled(CriticalPosition)`
  width: 18px;
  height: 18px;
  padding-left: 3px;
  padding-bottom: 5px;
`;

export const StyledSuccessionIcon = styled(SuccessionIcon)`
  width: 18px;
  height: 18px;
  padding-right: 3px;
  cursor: pointer;
`;
