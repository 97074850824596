import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Button from "components/Button";
import {
  PROFILE_INDEX_TABS, BUTTON_STYLE_TYPES, ALIGN_ITEMS, INPUT_TYPE, VARIANT, SIZE, COMPONENT,
} from "common/constants";
import { historyPush } from "common/utils";
import { isEmpty } from "common/helpers";
import downloadImg from "assets/images/forms/descargar.svg";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import { getFile } from "views/Preboarding/functions";
import { useStyles, ListItemStyled, StyledDivider } from "./styles";

const DocumentForm = (props) => {
  const {
    data, handleUpload, state, isCandidate, isPreboarding,
  } = props;
  const { t } = useTranslation("candidates");
  const classes = useStyles();
  const history = useHistory();

  // const handleClick = () => {
  //   //TODO: Download save file
  //   alert("Download File");
  // };

  const getTab = () => {
    historyPush(history, "/onboarding_profile", "", {
      tabId: PROFILE_INDEX_TABS.personalData,
    });
  };

  return (
    <div data-testid={ "document-form" }>
      {isCandidate && !isPreboarding && (
        <Box pb={ 3 }>
          <Typography variant={ VARIANT.bodyOne } className={ classes.btnLink }>
            {`${t("induction.complete_data")} `}
            <Link onClick={ getTab }>{t("induction.personal_data")}</Link>
          </Typography>
        </Box>
      )}
      { !isPreboarding && <StyledDivider /> }
      {!isEmpty(data) && (
        <Box pb={ 3 }>
          { !isPreboarding && <Typography variant={ VARIANT.h5 }>{t("induction.requested_docs")}</Typography> }
          <Typography variant={ VARIANT.bodyOne }>
            {t("induction.description_doc")}
          </Typography>
        </Box>
      )}

      {data?.map((item) => (
        <Box key={ `document-type-${item.document_type.name}` } pb={ 0.5 } className={ classes.upload }>
          <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
            <Box pr={ 1 }>
              <Avatar alt={ t("induction.upload") } src={ uploadImg } />
            </Box>
            <Box flexGrow={ 1 }>
              <Typography variant={ VARIANT.h6 }>
                {`${t("induction.upload")} ${item.document_type.name} `}
                {item.required && "*"}
              </Typography>
              <Typography variant={ VARIANT.bodyTwo }>
                {t("induction.formats")}
              </Typography>
            </Box>
          </Box>
          <Button
            variant={ VARIANT.outlined }
            typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
            component={ COMPONENT.label }
            customStyle={ classes.button }
          >
            <input
              color={ "primary" }
              accept={ "image/jpg, image/jpeg, image/png, .pdf" }
              type={ INPUT_TYPE.file }
              onChange={ (e) => handleUpload(e, item.id) }
            />
            {state && !isEmpty(getFile(state.files, item.id))
              ? t("induction.select_another_file")
              : t("induction.select_file")}
          </Button>
          <List>
            <ListItemStyled>
              {state && getFile(state.files, item.id).map((file) => (
                <ListItemText key={ `list-item-text-${item.id}` }>
                  <Button
                    component={ COMPONENT.label }
                    size={ SIZE.small }
                    className={ classes.downloadButton }
                    isDisabled
                    // onClick={handleClick}
                  >
                    <Avatar
                      alt={ t("introduction.download") }
                      src={ downloadImg }
                    />
                    {`${item.document_type.name}`}
                    <Typography
                      variant={ VARIANT.bodyTwo }
                      component={ COMPONENT.span }
                      className={ classes.docName }
                    >
                      {`(${file.name})`}
                    </Typography>
                  </Button>
                </ListItemText>
              ))}
            </ListItemStyled>
          </List>
        </Box>
      ))}
    </div>
  );
};

DocumentForm.propTypes = {
  data: PropTypes.array,
  getFile: PropTypes.func,
  handleUpload: PropTypes.func,
  state: PropTypes.object,
  isCandidate: PropTypes.bool,
  isPreboarding: PropTypes.bool,
};

export default DocumentForm;
