import styled from "styled-components";
import Box from "@mui/material/Box";
import palette from "theme/palette";

export const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  background: ${palette.background.lightGray};
  .MuiButton-containedPrimary {
    background-color: ${palette.text.link};
  }
`;
