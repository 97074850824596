import { Link } from "react-router-dom";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledTitle = styled.div`
  font-size: 28px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyledSubTitle = styled.div`
  font-size: 16px;
  color: ${palette.text.secondaryTitle};
  font-weight: 700;
  margin-bottom: 16px;
`;

export const StyledGoalContainer = styled.div`
  p {
    font-size: 12px;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const StyledDivider = styled.div`
    background: ${palette.background.divider};
    height: 1px;
    margin-top: 8px;
    margin-bottom: 10px;
`;

export const StyledFixedContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

export const StyledLink = styled(Link)`
  color: ${palette.text.link};
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledContentText = styled.div`
  p {
    margin: 10px 0 5px;
  }
`;

export const StyledSectionContainer = styled.div`
  padding: 16px;
`;
