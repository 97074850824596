import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import ChangeLanguageSelect from "components/ChangeLanguageSelect";
import FAQs from "components/FAQs";
import { LOCAL_STORAGE_NAMES, VARIANT } from "common/constants";
import { getItemFromLocalStorage, getMainRoute, isCandidate } from "common/utils";
import { isEmpty } from "common/helpers";
import { SessionContext } from "modules/session/context";
import useToastShared from "hooks/utils/useToastShared";
import nalaLogoImage from "assets/images/sign-in/nala-logo.svg";
import people from "assets/images/sign-in/people.svg";
import {
  LanguageGrid,
  LeftGrid,
  StyledTitle,
  StyledSubtitle,
  TextContainer,
  useStyles,
} from "./styles";

const AuthenticationLayout = (props) => {
  const { children } = props;
  const [balancedTitle, setBalancedTitle] = useState([]);
  const classes = useStyles();
  const { t, i18n } = useTranslation(["authentication", "common"]);
  const { language } = i18n;
  // Activate the toast shared between views
  useToastShared();

  const {
    state: { user },
  } = useContext(SessionContext);

  useEffect(() => {
    const title = t("authentication:title");
    const firstLine = title?.split(" ").slice(0, 2).join(" ");
    const secondLine = title?.split(" ").slice(2).join(" ");
    setBalancedTitle([firstLine, secondLine]);
    // eslint-disable-next-line
  }, [language]);

  const isCustomStyleView = window.location.pathname === "/recover-password"
    && !window.location.search;

  if (user) {
    const referrer = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.referrer);
    const mainRoute = isEmpty(referrer) || isCandidate(user?.userCookies) ? getMainRoute() : referrer;
    return <Redirect to={ mainRoute } />;
  }

  return (
    <Grid
      container
      className={ classes.mainContainer }
      data-testid={ "authentication-layout" }
    >
      <LanguageGrid item xs={ 12 }>
        <FAQs />
        <ChangeLanguageSelect isCustomIcon />
      </LanguageGrid>
      <LeftGrid item sm={ 6 } md={ 5 } >
        <TextContainer>
          <StyledTitle variant={ VARIANT.h1 }>
            {balancedTitle.map((title) => (
              <span key={ title }>{title}</span>
            ))}
          </StyledTitle>
          <StyledSubtitle>
            {t("authentication:subtitle")}
          </StyledSubtitle>
        </TextContainer>
        <img src={ people } alt={ "Nala Logo" } />
      </LeftGrid>
      <Grid item xs={ 12 } sm={ 6 } md={ 7 } className={ classes.rightContent }>
        <Grid
          item
          xs={ 12 }
          sm={ 9 }
          md={ 6 }
          lg={ 5 }
          className={ clsx(isCustomStyleView && classes.rightContentCustomStyle) }
        >
          <Link to={ "/sign-in" }>
            <img src={ nalaLogoImage } alt={ "Nala Logo" } />
          </Link>
          {children}
        </Grid>
      </Grid>
    </Grid>
  // )
  );
};

AuthenticationLayout.propTypes = {
  children: PropTypes.any,
};

export default AuthenticationLayout;
