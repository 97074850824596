import styled from "styled-components";
import palette from "theme/palette";
import Grid from "@mui/material/Grid";

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
  }
`;

export const StyledHeaderIcon = styled.div`
  margin-right: 10px;
  padding: 5px;
  border-radius: 100px;
  border: 2px solid ${palette.background.purple};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  font-size: 18px;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    width: 16px;
  }
`;

export const StyledActiveTitle = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
`;

export const StyledGridContainer = styled(Grid)`
  &.MuiGrid-item {
    padding-top: 0;
  }
`;

export const StyledEmptyContainer = styled.div`
  div {
    padding: 0;
    width: 100%;
  }
  button {
    display: flex;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    &:hover {
      background: none;
      color: ${palette.primaryApp};
    }
  }
`;

export const StyledContainerInfo = styled.div`
  margin: 10px;
`;

export const StyledContainerAccordion = styled.div`
  .Mui-expanded:last-of-type {
    margin-bottom: 10px;
  }
  .MuiTableCell-root {
    padding: 0;
    border: none;
  }
  thead {
    display: none;
  }
  .MuiPaper-elevation {
    box-shadow: none;
  }
`;
