import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CalendarControl from "components/CalendarControl";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import Calendar from "components/Calendar";
import ModalCardInfo from "components/ModalCardInfo";
import {
  SKELETONS_NUMBER,
  VARIANT,
  ALIGN_ITEMS,
  JUSTIFY,
  SKELETON_VARIANT,
} from "common/constants";
import { isEmpty } from "common/helpers";
import { formatDate, getUtilsFromDate } from "common/utils";
import ProcessDetail from "views/Planning/components/ProcessDetail";
import EventCard from "./components/EventCard";
import {
  StyledActions,
  StyledButton,
  StyledHeaderCalendar,
  StyledBox,
  StyledEventsTitle,
  StyledTitleEvents,
  StyledCalendarTodayIcon,
  StyledIcon,
  StyledSubtitleEvent,
  StyledDetail,
  StyledEvents,
  StyledHeader,
  StyledCardContainer,
  StyledTitle,
  StyledSubtitle,
  StyledBold,
} from "./styles";

const PersonalCalendar = (props) => {
  const {
    t,
    calendarStates,
    surveysReducerStates,
    handleControls,
    setEventsByCurrentDate,
    handleEvent,
    handleEventModal,
  } = props.extraAttributes;

  const { loadingList } = surveysReducerStates;
  const {
    data,
    values,
    currentYear,
    currentMonth,
    isOpenEventModal,
    selectedEvent,
  } = calendarStates;

  const getEvents = isEmpty(values?.events) ? (
    <NoDataMessage
      message={t("planning:calendar.empty_events")}
      icon={<StyledCalendarTodayIcon />}
    />
  ) : (
    values.events?.map((event) => (
      <EventCard key={event.id} t={t} data={event} onClick={handleEventModal} />
    ))
  );

  return (
    <StyledCardContainer data-testid={"personal-calendar-view-component"}>
      {!loadingList && data && values ? (
        <>
          <StyledTitle>
            <CalendarTodayIcon />
            <Typography>{t("planning:calendar.title")}</Typography>
          </StyledTitle>
          <StyledSubtitle>
            {t("home:calendar.firstSubtitle")}
            <StyledBold>{` ${values?.events.length} `}</StyledBold>
            {t("home:calendar.secondSubtitle")}
          </StyledSubtitle>
          <StyledBox>
            <StyledHeaderCalendar container spacing={3}>
              <StyledActions item xs={12}>
                <Box
                  display={ALIGN_ITEMS.flex}
                  justifyContent={JUSTIFY.spaceBetween}
                  alignItems={ALIGN_ITEMS.center}
                >
                  <Box>
                    <StyledButton
                      variant={VARIANT.outlined}
                      onClick={() => setEventsByCurrentDate(data)}
                    >
                      {t("planning:calendar.today")}
                    </StyledButton>
                  </Box>
                  <Box
                    display={ALIGN_ITEMS.flex}
                    alignItems={ALIGN_ITEMS.center}
                  >
                    <CalendarControl
                      onClick={handleControls}
                      currentValue={currentMonth}
                      index={"currentMonth"}
                      byMonth
                    />
                    <CalendarControl
                      onClick={handleControls}
                      currentValue={currentYear}
                      index={"currentYear"}
                    />
                  </Box>
                </Box>
              </StyledActions>
            </StyledHeaderCalendar>
            <Calendar
              currentYear={currentYear}
              data={data}
              onSelectedEvent={handleEvent}
              currentMonth={currentMonth}
              isMonthMode
            />
          </StyledBox>
          <StyledTitleEvents variant={VARIANT.h5}>
            {t("planning:calendar.events")}
          </StyledTitleEvents>
          {values?.date && (
            <StyledEventsTitle variant={VARIANT.h6}>
              {`${getUtilsFromDate(values.date, t)} ${
                values.endDate
                  ? ` - ${getUtilsFromDate(values.endDate, t)}`
                  : ""
              }`}
            </StyledEventsTitle>
          )}
          <StyledEvents>{getEvents}</StyledEvents>
          <ModalCardInfo
            isOpen={isOpenEventModal}
            onClose={handleEventModal}
            title={
              <Box mb={1} display={ALIGN_ITEMS.flex}>
                <StyledIcon>
                  <img src={selectedEvent?.processType?.icon} alt={"icon"} />
                </StyledIcon>
                <StyledHeader>
                  {selectedEvent?.name}
                  <StyledSubtitleEvent>
                    {selectedEvent?.processType?.label}
                  </StyledSubtitleEvent>
                </StyledHeader>
              </Box>
            }
            subtitle={`${t("surveys:form.fields.end_date")}: ${formatDate(
              selectedEvent?.endDate
            )}`}
            noPicture
          >
            <StyledDetail>
              <ProcessDetail
                t={t}
                data={selectedEvent?.processDetail}
                hasNalaForm={selectedEvent?.hasNalaForm}
              />
            </StyledDetail>
          </ModalCardInfo>
        </>
      ) : (
        <>
          <SkeletonLoader numberOfSkeletons={SKELETONS_NUMBER.ONE} />
          <SkeletonLoader
            numberOfSkeletons={SKELETONS_NUMBER.ONE}
            isInline={false}
          />
          <SkeletonLoader
            numberOfSkeletons={SKELETONS_NUMBER.ONE}
            variant={SKELETON_VARIANT.rectangular}
            height={300}
            columnWidth={12}
          />
          <SkeletonLoader
            numberOfSkeletons={SKELETONS_NUMBER.TWO}
            variant={SKELETON_VARIANT.rectangular}
            height={100}
            columnWidth={12}
          />
        </>
      )}
    </StyledCardContainer>
  );
};

PersonalCalendar.propTypes = {
  t: PropTypes.func.isRequired,
  calendarStates: PropTypes.object.isRequired,
  surveysReducerStates: PropTypes.object.isRequired,
  handleControls: PropTypes.func.isRequired,
  setEventsByCurrentDate: PropTypes.func.isRequired,
  handleEvent: PropTypes.func.isRequired,
  handleEventModal: PropTypes.func.isRequired,
};

export default PersonalCalendar;
