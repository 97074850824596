import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 20px;
  table {
    border-radius: 4px;
  }
  td {
    padding: 0;
  }
  .MuiTableCell-root {
    border: ${palette.border.grayLight} !important;
  }
  .MuiTableBody-root {
    .MuiTableCell-body:first-of-type {
      text-align: ${(hasIcon) => (hasIcon ? "center" : "left") };
      padding: 5px;
    }
    .MuiTableCell-body:last-of-type {
      text-align: center;
      padding: 5px;
    }
  }
  input {
    margin: 0;
    font-size: 12px;
    &:focus-within {
      box-shadow: ${palette.boxShadow.card};
    }
  }
  fieldset {
    border: 0;
  }
  .MuiTextField-root {
    margin: 0;
  }
`;

export const StyledTableHead = styled(TableHead)`
  background: ${palette.primaryApp};
  th {
    padding: 5px;
    color: ${palette.white};
    text-align:center;
  }
  svg {
    color: ${palette.white};
    font-size: 12px;
    margin-left: 10px;
  }
`;
