import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import palette from "theme/palette";
import { Typography } from "@mui/material";

export const StyledCard = styled(Card)`
  cursor: pointer;
  margin-top: 10px;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 16px 20px;
  &.MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
`;

export const StyledCollapseArrow = styled.div`
  .MuiSvgIcon-root {
    color: ${palette.text.link};
    font-size: 40px;
  }
`;

export const StyledCollapseContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 30px;
  }
`;

export const StyledTitle = styled(Typography)`
  margin-left: 10px;
  span {
    font-weight: 700;
  }
`;

export const StyledRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 25px;
    margin-right: 5px;
  }
  .MuiTypography-body1 {
    color: ${palette.text.grayDark};
    font-size: 15px;
    margin-right: 10px;
  }
`;
