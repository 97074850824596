import { useContext } from "react";
import { SessionContext } from "modules/session/context";
import ViewModalComponent from "components/ViewModalComponent";
import PositionsView from "./Positions.view";

const PositionsController = (props) => {
  const {
    state: { user },
  } = useContext(SessionContext);

  return (
    <ViewModalComponent viewComponent={ (
      <PositionsView
        user={ user }
        { ...props }
      />
    ) }
    />
  );
};

export default PositionsController;
