import PropTypes from "prop-types";
import DownloadExcelButton from "components/DownloadExcelButton";
import { VARIANT } from "common/constants";
import { isEmpty } from "common/helpers";
import DataNineBoxIcon from "assets/images/ninebox/bill.svg";
import { downloadExcel } from "../../functions";
import {
  StyledTitle,
  StyledImage,
  StyledNineboxTitle,
  StyledHeader,
} from "./styles";

const HistoricalHeader = (props) => {
  const { t, nineBoxResult } = props;

  return (
    <StyledHeader>
      <StyledNineboxTitle>
        <StyledImage src={ DataNineBoxIcon } alt={ "Ninebox" } />
        <StyledTitle variant={ VARIANT.h4 }>{t("ninebox:title")}</StyledTitle>
      </StyledNineboxTitle>
      <DownloadExcelButton
        onClick={ downloadExcel(
          t("ninebox:title"),
          nineBoxResult,
          t,
        ) }
        isDisabled={ isEmpty(nineBoxResult) }
      />
    </StyledHeader>
  );
};

HistoricalHeader.propTypes = {
  t: PropTypes.func.isRequired,
  nineBoxResult: PropTypes.array.isRequired,
};

export default HistoricalHeader;
