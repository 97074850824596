import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import theme from "theme";
import { SIGN_IN_COLORS } from "theme/palette";
import signInImage from "assets/images/sign-in/background.svg";

export const useStyles = makeStyles((actualTheme) => ({
  mainContainer: {
    height: "100vh",
  },
  rightContent: {
    backgroundColor: SIGN_IN_COLORS.grayBackground,
    display: "flex",
    overflowY: "auto",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "20px",
    [actualTheme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  rightContentCustomStyle: {
    width: "70%",
  },
}));

export const LanguageGrid = styled(Grid)`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 25px 15px;

  .MuiButtonBase-root {
    padding: 3px 0;
  }
`;

export const LeftGrid = styled(Grid)`
  background-image: url(${signInImage});
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  padding-bottom: 30px;

  img {
    min-width: 100%;
    max-width: 100%;
  }

  ${theme.breakpoints.up("sm")} {
    display: block;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 45px;
  padding: 6rem 60px 1rem 70px;
`;

export const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    color: ${SIGN_IN_COLORS.titles};
    font-size: 3rem;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;

    span {
      display: block;
    }

    @media (min-width: 600px) and (max-width: 629px) {
      font-size: 2rem;
    }

    @media (min-width: 630px) and (max-width: 960px) {
      font-size: 2.5rem;
    }
  }
`;

export const StyledSubtitle = styled(Typography)`
  &.MuiTypography-root {
    color: ${SIGN_IN_COLORS.titles};
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;

    @media (min-width: 600px) and (max-width: 629px) {
      font-size: 1rem;
    }

    @media (min-width: 630px) and (max-width: 960px) {
      font-size: 1.25rem;
    }
  }
`;
