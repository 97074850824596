import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import SkeletonLoader from "components/SkeletonLoader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { SKELETONS_NUMBER } from "common/constants";
import { isEqual } from "common/helpers";

const TabPanel = (props) => {
  const {
    children, value, index, isLoading,
  } = props;

  return (
    <div
      data-testid={ "tabPanelContainer" }
      role={ "tabpanel" }
      hidden={ !isEqual(value, index) }
      id={ `simple-tabpanel-${index}` }
      aria-labelledby={ `simple-tab-${index}` }
    >
      {isEqual(value, index) && (
        <Box>
          {isLoading ? (
            <Card data-testid={ "account-profile-tab" }>
              <Divider />
              <CardContent>
                <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } />
              </CardContent>
            </Card>
          ) : (
            children
          )}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default TabPanel;
