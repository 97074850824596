import styled from "styled-components";
import Stack from "@mui/material/Stack";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 1.5rem 0 -8px;
`;

export const StyledStack = styled(Stack)`
  background: ${palette.background.default};
  border-radius: 4px;
  button {
    svg {
      color: ${palette.table.placeholder};
    }
    &:hover {
      background: none;
      svg {
        color: ${palette.background.purple};
      }
    }
  }
`;
