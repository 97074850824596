import styled from "styled-components";
import { Typography } from "@mui/material";

export const StyledContent = styled.div`
  padding: 30px;
`;

export const StyledDescription = styled(Typography)`
  text-align: justify;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin-top: 20px;
`;

export const StyledList = styled.ul`
  width: 100%;
  padding: 20px;
  li {
    margin-bottom: 10px;
  }
`;
