import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { SIZE, ACTIONS, INFO } from "common/constants";
import infoImage from "assets/images/general/hand.svg";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import {
  StyledModalContent,
  StyledCloseButton,
  StyledHeader,
  StyledTitle,
  StyledImage,
  StyledSubtitleHeader,
  StyledBanner,
} from "./styles";

const ModalCardInfo = (props) => {
  const {
    children,
    isOpen,
    onClose,
    title,
    subtitle,
    noPicture,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={ isOpen }>
      <StyledModalContent>
        <div>
          <StyledHeader noPicture={ noPicture }>
            <StyledBanner noPicture={ noPicture }>
              <StyledTitle>
                { title }
              </StyledTitle>
              { subtitle && (
                <StyledSubtitleHeader>
                  { subtitle }
                </StyledSubtitleHeader>
              )}
            </StyledBanner>
            {!noPicture && <StyledImage alt={ INFO } src={ infoImage } />}
          </StyledHeader>
          <StyledCloseButton
            aria-label={ ACTIONS.close }
            onClick={ handleClose }
          >
            <CloseIcon fontSize={ SIZE.small } />
          </StyledCloseButton>
        </div>
        {/* We should use it in the cases that we need to scroll since this way we keep the same everywhere */}
        <StyledScrollBar maxHeight={ "400px" }>
          { children }
        </StyledScrollBar>
      </StyledModalContent>
    </Modal>
  );
};

ModalCardInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.element,
  noPicture: PropTypes.bool,
};

ModalCardInfo.defaultProps = {
  title: "",
  subtitle: "",
  children: null,
  noPicture: false,
};

export default ModalCardInfo;
