import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import { INDEX, SKELETON_VARIANT } from "common/constants";
import { StyledGrid } from "./styles";

const SkeletonLoader = (props) => {
  const {
    isInline, numberOfSkeletons, hasPadding, variant, columnWidth, padding,
  } = props;

  return (
    <Grid container data-testid={ "skeletonLoader" }>
      {Array(numberOfSkeletons)
        .fill()
        .map((e, index) => (
          <StyledGrid
            item
            key={ `skeleton-${index}` }
            xs={ columnWidth || (isInline ? 6 : 12) }
            hasPadding={ hasPadding }
            padding={ padding }
          >
            <Skeleton { ...props } variant={ variant } />
          </StyledGrid>
        ))}
    </Grid>
  );
};

SkeletonLoader.propTypes = {
  isInline: PropTypes.bool,
  numberOfSkeletons: PropTypes.number,
  hasPadding: PropTypes.bool,
  variant: PropTypes.string,
  columnWidth: PropTypes.number,
  padding: PropTypes.string,
  height: PropTypes.number,
};

SkeletonLoader.defaultProps = {
  isInline: true,
  numberOfSkeletons: INDEX.one,
  hasPadding: true,
  variant: SKELETON_VARIANT.text,
  columnWidth: null,
  padding: "20px",
  height: null,
};

export default SkeletonLoader;
