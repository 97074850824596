import makeStyles from "@mui/styles/makeStyles";
import { LINEAL_GRAPHICS } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: theme.spacing(2),
  },
  subTitleText: {
    fontWeight: 500,
    lineHeight: "21px",
    color: theme.palette.subtitle,
    marginBottom: theme.spacing(1),
  },
  cardIconTitleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 20px",
  },
  cardTitle: {
    fontWeight: "bold",
    margin: "10px",
  },
  cardTitleBordered: {
    borderLeft: `2px solid ${ LINEAL_GRAPHICS.LINE_COLOR}`,
    margin: "10px 20px",
  },
  cardText: {
    margin: "10px 20px",
  },
  cardImage: {
    marginRight: theme.spacing(0.7),
  },
  typographyPotentialTab: {
    padding: "20px",
    paddingBottom: 0,
  },
  badgesAndRecommendationsText: {
    marginBottom: "5px",
    color: theme.palette.text.secondaryTitle,
  },
  badgesAndRecommendationsTitle: {
    marginLeft: "10px",
  },
  badgesAndRecommendationsTitleBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "15px",
    paddingTop: "10px",
  },
  badgesContainer: {
    padding: "20px",
    textAlign: "center",
  },
  individualBadgeGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  potentialDescription: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  cardBadges: {
    padding: "20px",
  },
  cardBadgeTitleContainer: {
    display: "flex",
    paddingBottom: "15px",
    alignItems: "center",
  },
  badgesSubtitle: {
    padding: "15px",
    paddingLeft: "0px",
  },
  recommendationTitle: {
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  mainContainerBadges: {
    padding: "15px",
    paddingTop: "0px",
  },
  badgeGrid: {
    "&.MuiGrid-item": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  badgeImage: {
    width: "25px",
    marginRight: "15px",
  },
  badgeImageLarge: {
    width: "35px",
    marginRight: theme.spacing(3),
  },
  noDataCustom: {
    padding: "0px !important",
    "& div": {
      padding: theme.spacing(0, 0.5, 0, 0),
    },
    "& img": {
      marginBottom: theme.spacing(1),
    },
  },
}));
