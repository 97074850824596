import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import NoDataMessage from "components/NoDataMessage";
import { ALIGN_ITEMS } from "common/constants";
import { getValueToRender } from "../../functions/index";
import { StyledTableRow, StyledTableCell } from "../../styles";

const TableBodyComponent = ({
  dataToShow, header, emptyRows, hiddenCell,
}) => (
  <TableBody>
    {dataToShow.length === 0 ? (
      <StyledTableRow>
        <StyledTableCell colSpan={ 6 }>
          <NoDataMessage />
        </StyledTableCell>
      </StyledTableRow>
    )
      : dataToShow.map((row) => (
        <StyledTableRow hover tabIndex={ -1 } key={ row.id }>
          {header.map((headerItem) => {
            const headerItemParent = headerItem?.parent;
            return (
              <StyledTableCell
                key={ `${headerItem.id}-${row.id}` }
                scope={ "row" }
                collapsible={ headerItemParent }
                show={ !!(headerItemParent && hiddenCell[headerItemParent]) }
                align={ headerItem?.align || ALIGN_ITEMS.center }
              >
                {getValueToRender(headerItem, row)}
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      ))}
    {emptyRows > 0 && (
      <StyledTableRow>
        <StyledTableCell colSpan={ 6 } />
      </StyledTableRow>
    )}
  </TableBody>
);

TableBodyComponent.propTypes = {
  dataToShow: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.arrayOf(PropTypes.object),
  emptyRows: PropTypes.number,
  hiddenCell: PropTypes.object,
};

TableBodyComponent.defaultProps = {
  dataToShow: [],
  header: [],
  emptyRows: 0,
  hiddenCell: {},
};

export default TableBodyComponent;
