import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PERCENTAGE, VARIANT, MIN_VALUE } from "common/constants";
import { isNull } from "common/helpers";
import palette from "theme/palette";
import { getPercentage } from "./functions";
import {
  StyledTypography, StyledContent, StyledText, StyledSubText, StyledLinearProgress,
} from "./styles";

const StaticProgressBar = (props) => {
  const {
    title,
    percentage,
    icon,
    isSublevel,
    value,
    maxValue,
    isNotShowLabel,
    color,
    isPrimaryColor,
    labelCustomColor,
  } = props;

  const getLabel = () => {
    if (isNotShowLabel) {
      return "";
    }
    return isNull(value) ? `${percentage}%` : `${value}/${maxValue}`;
  };

  return (
    <StyledContent data-testid={ "static-progress-bar-component" } >
      {title || icon || isSublevel ? (
        <>
          <StyledText>
            {icon ? (
              <img src={ icon } alt={ title } />
            ) : (
              <Typography variant={ VARIANT.h6 }>{title}</Typography>
            )}
            <Typography variant={ VARIANT.h6 }>{`${percentage}%`}</Typography>
          </StyledText>
          <StyledLinearProgress
            variant={ VARIANT.determinate }
            value={ getPercentage(percentage) }
            isSublevel={ isSublevel }
            customcolor={ isSublevel ? palette.background.purple : color }
          />
          <StyledSubText>
            <Typography variant={ VARIANT.h6 }>
              {`${MIN_VALUE}%`}
            </Typography>
            <Typography variant={ VARIANT.h6 }>
              {`${PERCENTAGE.max}%`}
            </Typography>
          </StyledSubText>
        </>
      ) : (
        <>
          <StyledText>
            <StyledTypography
              variant={ VARIANT.h6 }
              isDanger={ percentage < PERCENTAGE.min }
              customcolor={ labelCustomColor }
            >
              { getLabel() }
            </StyledTypography>
          </StyledText>
          <Box pb={ 1 }>
            <StyledLinearProgress
              variant={ VARIANT.determinate }
              value={ getPercentage(percentage) }
              isSublevel={ isSublevel }
              isPrimaryColor={ isPrimaryColor }
              customcolor={ !isPrimaryColor ? color : palette.linearProgress.primaryColor }
              isNotShowLabel
            />
          </Box>
        </>
      )}
    </StyledContent>
  );
};

StaticProgressBar.propTypes = {
  title: PropTypes.string,
  percentage: PropTypes.number,
  isSublevel: PropTypes.bool,
  icon: PropTypes.string,
  value: PropTypes.number,
  maxValue: PropTypes.number,
  isNotShowLabel: PropTypes.bool,
  color: PropTypes.string,
  isPrimaryColor: PropTypes.bool,
  labelCustomColor: PropTypes.string,
};

StaticProgressBar.defaultProps = {
  title: null,
  percentage: MIN_VALUE,
  isSublevel: false,
  icon: null,
  value: null,
  maxValue: null,
  isNotShowLabel: false,
  color: palette.linearProgress.complete,
  isPrimaryColor: true,
  labelCustomColor: null,
};

export default StaticProgressBar;
