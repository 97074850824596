import styled from "styled-components";
import palette, { SIGN_IN_COLORS } from "theme/palette";

export const StyledButton = styled.div`
  .MuiButton-root{
    width: 100%;
    margin-top: 15px;
    padding-top: 3px;
    border-color: ${ SIGN_IN_COLORS.blue };
    color: ${ palette.text.primary };
    margin-bottom: 30px;
    padding-bottom: 3px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
`;

export const StyledTitle = styled.div`
  margin-left: 6px;
`;
