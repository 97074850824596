import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const StyledContent = styled.div`
  padding: 24px;
  iframe {
    height: 500px;
  }
  @media (max-width: 600px) {
    iframe {
      height: 280px;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  display: flex;
  padding: 16px 0 16px 16px;
  font-weight: bold;
  line-height: 28px;
`;
