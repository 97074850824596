import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledToggleButton = styled(ToggleButton)`
  font-size: 12px;
  width: 170px;
  height: 30px;
  background-color: ${palette.background.lightDark};
  border: 1px solid ${palette.table.blueLight};
  border-radius:0;
  text-transform: capitalize;
  justify-content: start;
  &.Mui-selected, &.Mui-selected:hover, &:hover {
    background-color: ${palette.background.lightBlue};
    color: ${palette.primaryApp};
  }

  .MuiCheckbox-root.Mui-checked {
    color: ${palette.primaryApp};
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border: 0px;
  .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-left: 1px solid ${palette.table.blueLight};
  }
`;
