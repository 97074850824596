import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell } from "../../styles";

const TableRowDesktop = (props) => {
  const { item } = props;

  return (
    <TableRow>
      { item?.map((cell) => (
        <StyledTableCell key={ `cell${item}` }>
          {cell.content}
        </StyledTableCell>
      ))}
    </TableRow>
  );
};

TableRowDesktop.propTypes = {
  item: PropTypes.array.isRequired,
};

export default TableRowDesktop;
