import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { StyledLink } from "../../styles";

const CardLink = ({ onClick }) => {
  const { t } = useTranslation("common");

  return (
    <StyledLink onClick={ onClick }>
      <SearchIcon />
      {t("participation.link")}
    </StyledLink>
  );
};

CardLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CardLink;
