import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_BY_COLLABORATORS,
  GET_LIST_BY_COLLABORATORS,
  GET_LIST_BY_COLLABORATORS_LOADING,
  GET_LIST_BY_COLLABORATORS_ERROR,
  RESET_STATE_BY_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_BY_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_LOADING_BY_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_ERROR_BY_COLLABORATORS_TO_DOWNLOAD,
} from "../actionTypes/followUp";

const INITIAL_STATE = {
  list: null,
  isLoadingList: false,
  errorlist: "",

  collaboratorListTotal: 0,
  collaboratorList: null,
  collaboratorMeta: null,
  isLoadingCollaboratorList: false,
  errorCollaboratorList: "",

  downloadList: null,
  isLoadingDownloadList: false,
  errorDownloadList: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      errorList: null,
      list: null,
    };
  case RESET_STATE_BY_COLLABORATORS:
    return {
      ...state,
      collaboratorList: null,
      collaboratorListTotal: 0,
      collaboratorMeta: null,
      isLoadingCollaboratorList: false,
      errorCollaboratorList: "",
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: null,
      isLoadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case GET_LIST_BY_COLLABORATORS_LOADING:
    return {
      ...state,
      isLoadingCollaboratorList: true,
    };
  case GET_LIST_BY_COLLABORATORS:
    return {
      ...state,
      isLoadingCollaboratorList: false,
      collaboratorList: action.payload,
      collaboratorListTotal: action.total,
      collaboratorMeta: action.meta,
    };
  case GET_LIST_BY_COLLABORATORS_ERROR:
    return {
      ...state,
      isLoadingCollaboratorList: false,
      errorCollaboratorList: action.payload,
    };
  case RESET_STATE_BY_COLLABORATORS_TO_DOWNLOAD:
    return {
      ...state,
      downloadList: null,
      isLoadingDownloadList: false,
      errorDownloadList: "",
    };
  case GET_LIST_BY_COLLABORATORS_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: true,
    };
  case GET_LIST_LOADING_BY_COLLABORATORS_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: false,
      downloadList: action.payload,
    };
  case GET_LIST_ERROR_BY_COLLABORATORS_TO_DOWNLOAD:
    return {
      ...state,
      isLoadingDownloadList: false,
      errorDownloadList: action.payload,
    };
  default:
    return state;
  }
};
