import PropTypes from "prop-types";
import { replacePath } from "common/utils";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import { StyledButton } from "views_refactor/Profile/styles";
import SalaryModal from "../SalaryModal";
import localRoutes from "../../../../functions/routes";
import { StyledSalaryInfoContainer } from "../../styles";

const InfoEditButton = (props) => {
  const { history } = props;
  return (
    <>
      <StyledSalaryInfoContainer>
        <StyledButton onClick={ () => replacePath(history, localRoutes.salaryInfo) }>
          <EditIcon />
        </StyledButton>
      </StyledSalaryInfoContainer>
      <SalaryModal { ...props } />
    </>
  );
};

InfoEditButton.propTypes = {
  history: PropTypes.func.isRequired,
};

export default InfoEditButton;
