import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledContainer = styled(Grid)`
  border: ${palette.border.card};
  border-radius: 2px;
  margin-bottom: 10px;
`;

export const StyledBoxContainer = styled(Box)`
  padding: 20px 15px 10px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledImageContainer = styled.div`
  position: relative;
  .MuiAvatar-root {
    width: 80px;
    height: 80px;
  }
`;

export const StyledTitleWithSubtitle = styled.div`
  display: inline;
  margin-left: 5px;
  text-align: left;
  padding-left: 5px;
  span {
    color: ${palette.text.secondaryParagraph};
    font-size: 11px;
    font-weight: 400;
  }
`;

export const StyledTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: ${palette.primaryApp};
  padding-bottom: 5px;
`;

export const StyledSubtitle = styled.h3`
  font-weight: 700;
  font-size: 14px;
  color: ${palette.text.secondaryParagraph};
  display: flex;
  align-items: center;
  span {
    padding-left: 10px;
  }
`;

export const StyledProfileInfo = styled.div`
  padding-bottom: 20px;
  border-bottom: ${palette.border.card};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const StyledResultContainer = styled(Grid)`
  padding: 0 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledRiskCategory = styled.div`
  background-color: ${(props) => props.color};
  width: 8px;
  height: 8px;
  border-radius: 8px;
`;

export const StyledRiskCategoryContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${palette.text.grayDark};
  span {
    font-weight: 400;
  }
`;
