import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import TooltipIcon from "components/TooltipIcon";
import StarRating from "components/StarRating";
import ProfileImageDetail from "components/ProfileImageDetail";
import SeeMoreLink from "components/SeeMoreLink";
import {
  SIZE,
  ALIGN_ITEMS,
} from "common/constants";
import { getSeniority } from "../functions";
import { StyledTimeText, StyledHeaderCard } from "../styles";

/* Custom hook to get the data table for RiskCardCollapse component */
export const useDataTable = () => {
  const HEADER = ["name", "position", "performance", "seniority", "actions"];
  const ORDER = {
    performance: "performance_score",
    seniority: "employee_starting_date",
  };
  const SHORT_HEADER = ["name", "manager", "position", "actions"];

  const getRowsDesktop = (data, t, history, handleOpenModal) => {
    const rows = data?.map((rowData) => {
      const timeline = getSeniority(rowData.employee.starting_date, t);
      const performanceProcess = rowData.employee?.performance_results;
      return [
        {
          content: (
            <ProfileImageDetail collaborator={ rowData.employee } hasStatus />
          ),
        },
        {
          content: rowData.employee.position_name,
        },
        {
          content: performanceProcess?.scale_index_count ? (
            <StarRating
              name={ rowData?.id }
              value={ performanceProcess?.position }
              isReadOnly
              maxRating={ performanceProcess?.scale_index_count }
              max={ performanceProcess?.scale_index_count }
              precision={ 1 }
              label={ performanceProcess?.result }
              hasNoFormattedValue
            />
          ) : "",
        },
        {
          content: (
            <TooltipIcon
              title={ timeline.complete }
              element={ (
                <StyledTimeText>
                  <TimelapseIcon />
                  {timeline.basic}
                </StyledTimeText>
              ) }
            />
          ),
        },
        {
          content: (
            <SeeMoreLink
              t={ t }
              onClick={ () => {
                handleOpenModal(history.location.pathname, rowData.employee.id);
              } }
              align={ ALIGN_ITEMS.left }
            />
          ),
        },
      ];
    });
    return rows;
  };

  const getShortRowsDesktop = (data, t, history, handleOpenModal) => {
    const rows = data?.map((rowData) => [
      {
        content: (
          <ProfileImageDetail collaborator={ rowData.employee } isShortVersion />
        ),
      },
      {
        content: rowData.employee?.manager_name,
      },
      {
        content: rowData.employee?.position_name,
      },
      {
        content: (
          <SeeMoreLink
            t={ t }
            onClick={ () => {
              handleOpenModal(history.location.pathname, rowData.employee.id);
            } }
            align={ ALIGN_ITEMS.left }
          />
        ),
      },
    ]);
    return rows;
  };

  const getShortRowsMobile = (data, t, history, handleOpenModal) => {
    const rows = data?.map((rowData) => [
      {
        content: (
          <StyledHeaderCard>
            <ProfileImageDetail
              collaborator={ rowData.employee }
              customLink={ (
                <IconButton
                  onClick={ () => {
                    handleOpenModal(
                      history.location.pathname,
                      rowData.employee.id,
                    );
                  } }
                >
                  <VisibilityIcon fontSize={ SIZE.small } />
                </IconButton>
              ) }
              isShortVersion
            />
          </StyledHeaderCard>
        ),
      },
    ]);
    return rows;
  };

  return {
    HEADER,
    ORDER,
    SHORT_HEADER,
    getRowsDesktop,
    getShortRowsDesktop,
    getShortRowsMobile,
  };
};
