import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_PROCESS_SECTION_ITEM,
  PROCESS_SECTION_ITEM_ERROR,
  PROCESS_SECTION_ITEM_LOADING,
} from "../../actionTypes/sectionItems";

const URL = {
  main: "/section_items",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetProcess = () => (dispatch) => {
  dispatch({
    type: RESET_PROCESS_SECTION_ITEM,
  });
};

export const getList = () => async (dispatch, getState) => {
  const { list } = getState().sectionItemReducer;

  if (!list) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main);
      dispatch({
        type: GET_LIST,
        payload: response.data.section_items,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

export const create = (data) => async (dispatch) => {
  dispatch({
    type: PROCESS_SECTION_ITEM_LOADING,
  });
  try {
    const response = await configAxios.post(URL.main, data);
    return response.data.section_item;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SECTION_ITEM_ERROR);
  }
};

export const update = (id, data) => async (dispatch) => {
  dispatch({
    type: PROCESS_SECTION_ITEM_LOADING,
  });
  try {
    const response = await configAxios.put(`${URL.main}/${id}`, data);
    return response.data.section_item;
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_SECTION_ITEM_ERROR);
  }
};
