import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import split from "lodash/split";
import filter from "lodash/filter";
import isNumber from "lodash/isNumber";
import {
  ROLES,
  ROLES_NAMES,
  FILTER_SEACH,
  OBJECT_KEYS,
  DATE,
} from "common/constants";
import { formatDate, formatDateToSubmit, getDivisionAndAreaType } from "common/utils";
import {
  update as updateCollaborator,
} from "redux/actions/collaboratorActions";
import {
  update as updateCandidate,
} from "redux/actions/candidateActions";
import { create as createMovementHistory } from "redux/actions/historicalMovementActions";

import { getActiveCollaborators } from "../../Collaborators/functions";

export const resetDefaultValues = (employee) => ({
  [`${employee}.person_attributes.full_name`]: "",
  [`${employee}.manager_id`]: {},
  [`${employee}.job_position_attributes.position_id`]: "",
  [`${employee}.job_position_attributes.division_name`]: "",
  [`${employee}.job_position_attributes.area_name`]: "",
  [`${employee}.email`]: "",
  [`${employee}.roles_name`]: [],
});

export const dispatchUpdate = (
  dispatch,
  employee,
  data,
  reducerToUse,
  imgProfile,
) => (isEqual(employee, ROLES.CANDIDATE)
  ? dispatch(updateCandidate(
    data,
    reducerToUse.one.id,
    imgProfile,
  ))
  : dispatch(
    updateCollaborator(
      data,
      reducerToUse.one.id,
      imgProfile,
    ),
  ));

export const getOrganizationUnitName = (userUnits) => {
  const organizationUnitsTypes = getDivisionAndAreaType();
  const orgUnitTypeDivisionId = organizationUnitsTypes?.division?.id;
  const orgUnitTypeAreaId = organizationUnitsTypes?.area?.id;
  return {
    division: userUnits?.find((orgUnit) => orgUnit.organization_unit_type_id === orgUnitTypeDivisionId)?.name,
    area: userUnits?.find((orgUnit) => orgUnit.organization_unit_type_id === orgUnitTypeAreaId)?.name,
  };
};

export const getDivisionId = (userUnits) => {
  const orgUnitTypeDivisionId = getDivisionAndAreaType()?.division?.id;
  return userUnits?.find((orgUnit) => orgUnit.organization_unit_type_id === orgUnitTypeDivisionId)?.id;
};

export const getAreaId = (userUnits) => {
  const orgUnitTypeAreaId = getDivisionAndAreaType()?.area?.id;
  return userUnits?.find((orgUnit) => orgUnit.organization_unit_type_id === orgUnitTypeAreaId)?.id;
};

const getRolesName = (otherAdmin, role) => (otherAdmin
  ? !isEqual(role.name, ROLES.ADMIN_COLOMBIA)
        && !isEqual(role.name, ROLES.ADMIN)
        && !isEqual(role.name, ROLES.ADMIN_NALA)
        && !isEqual(role.name, ROLES.CANDIDATE)
  : !isEqual(role.name, ROLES.ADMIN_NALA)
        && !isEqual(role.name, ROLES.CANDIDATE));

export const getRolesApp = (roles, t, otherAdmin) => {
  let appRoles = [];
  if (!isEmpty(roles)) {
    appRoles = roles
      .filter((role) => getRolesName(otherAdmin, role))
      .map((item) => ({
        value: item.name,
        label: t(ROLES_NAMES[item.name]),
      }));
  }
  return appRoles;
};

export const getCollaboratorRoles = (roles_list, user_roles) => {
  let userRoles = [];
  if (!isNull(user_roles)) {
    userRoles = roles_list.filter((role) => user_roles.find((user) => isEqual(role.id, user.id)));
  }
  return userRoles;
};

export const isValidToDispatch = (reducerToUse) => isEmpty(reducerToUse.one) && isEmpty(reducerToUse.list);

export const getRolesListId = (roles) => {
  let rolesname = [];
  if (!isNull(roles)) {
    rolesname = roles?.map((role) => role.id);
  }

  return rolesname;
};

export const getRolesListName = (roles) => roles && roles?.map((role) => role.name);

export const removeCurrentCollaborator = (reducer, from, user) => {
  const activeCollaborator = getActiveCollaborators(reducer, true);
  const collaboratorId = parseInt(
    isEmpty(from) ? user.employee?.id : split(from, FILTER_SEACH.equal)[1],
  );

  return filter(activeCollaborator, (item) => !isEqual(item.id, collaboratorId));
};

export const validateAllFields = (data) => {
  const { collaborator, candidate } = data;
  const employeeData = collaborator || candidate;
  const jobAttributes = employeeData?.job_position_attributes;
  const salaryAttributes = employeeData?.salary_attributes;

  const dataToValidate = [
    { id: OBJECT_KEYS.date, value: employeeData?.date },
    { id: OBJECT_KEYS.cityName, value: jobAttributes?.city_name },
    { id: OBJECT_KEYS.countryName, value: jobAttributes?.country_name },
    { id: OBJECT_KEYS.managerId, value: employeeData?.manager_id?.value },
    { id: OBJECT_KEYS.positionId, value: jobAttributes?.position_name },
    {
      id: OBJECT_KEYS.typeOfContractId,
      value: jobAttributes?.type_of_contract_id,
    },
    { id: OBJECT_KEYS.grossSalary, value: salaryAttributes?.gross_salary },
    { id: OBJECT_KEYS.currency, value: salaryAttributes?.currency },
  ];

  const fieldsToValidate = dataToValidate.filter((item) => {
    if (item.id === DATE) {
      return isNull(item.value)
        ? isEmpty(item.value)
        : isEmpty(formatDate(item.value));
    }
    if (isEqual(item.id, "type_of_contract_id")) {
      return !isNumber(item.value);
    }
    return isEmpty(item.value);
  });

  return fieldsToValidate.map((item) => item.id);
};

export const handleMovement = (constToHandle) => {
  const {
    data,
    dispatch,
    employeeId,
    movement,
  } = constToHandle;
  data.collaborator.date = formatDateToSubmit(data.collaborator.date);
  const movementData = {
    date: data.collaborator.date,
    job_position_attributes: data.collaborator.job_position_attributes,
    manager_id: data.collaborator.manager_id,
    type: isEqual(movement, OBJECT_KEYS.lateralMovement)
      ? OBJECT_KEYS.lateralMovement
      : OBJECT_KEYS.promotion,
  };

  if (isEqual(movement, OBJECT_KEYS.promotion)) {
    const salary = data.collaborator.salary_attributes.gross_salary;
    data.collaborator.salary_attributes.gross_salary = salary.replaceAll(
      ",",
      "",
    );
    movementData.salary_attributes = data.collaborator.salary_attributes;
  }

  return dispatch(createMovementHistory(movementData, employeeId));
};
export const getPromotionMessage = (t) => `${t("account:accountDetails.sure_about")}${t(
  "account:accountDetails.promote",
)}`;

export const getLateralMovementMessage = (t) => `${t("account:accountDetails.sure_about")}${t(
  "account:accountDetails.lateral_movement",
)}`;

export const getRolesWithScope = (roles, t) => roles.map((item) => ({
  value: item.name,
  label: t(ROLES_NAMES[item.name]),
  countries: item.managed_countries?.map((country) => ({
    id: country.id,
    value: country?.name,
    label: country?.name,
  })),
}));

export const generateRoles = (roles, rolesEdited, firstTime = false) => roles.reduce((o, item) => (
  { ...o, [item.value]: (firstTime ? item.countries : rolesEdited[item.value]) || [] }), {});

export const getActionRoles = (rolesCollaborator, userRoles) => {
  const actions = [];
  const beforeRoles = rolesCollaborator.map((rol) => {
    if (!userRoles.find((r) => isEqual(r.value, rol.name))) {
      actions.push({ name: rol.name, _revoke: true });
    }
    return rol;
  });
  userRoles.forEach((userRol) => {
    if (!beforeRoles.find((rol) => isEqual(rol.name, userRol.value))) {
      actions.push({ name: userRol.value });
    }
  });
  return actions;
};

export const getActionsOfCountries = (rolName, beforeCountries, afterCountries) => {
  const actions = [];
  const removeItems = beforeCountries.filter((id) => !afterCountries.includes(id));

  if (!isEmpty(afterCountries)) {
    actions.push({ name: rolName, scope: { country_ids: afterCountries } });
  }

  if (!isEmpty(removeItems)) {
    actions.push({ name: rolName, _revoke: true, scope: { country_ids: removeItems } });
  }

  return actions;
};

export const getActionRolesWithScope = (user, userRoles) => {
  let actions = [];
  const oldRolesCollaborator = user?.roles_with_scope;
  const beforeRoles = oldRolesCollaborator.map((rol) => {
    // When a previous role was deleted
    if (!(rol.name in userRoles) || (isEmpty(rol.managed_countries) && !isEmpty(userRoles[rol.name]))) {
      actions.push({ name: rol.name, _revoke: true });
    }
    return rol;
  });

  Object.keys(userRoles).forEach((key) => {
    // When a new role was added
    if (!beforeRoles.find((beforeRol) => isEqual(beforeRol.name, key)) && isEmpty(userRoles[key])) {
      actions.push({ name: key });
    }
    const countriesActions = getActionsOfCountries(
      key,
      oldRolesCollaborator.find((rol) => isEqual(rol.name, key))?.managed_countries?.map((a) => a.id) || [],
      userRoles[key].map((userRol) => userRol.id),
    );
    actions = [...actions, ...countriesActions];
  });
  return actions;
};
