import {
  RESET_STATE_SCALE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
} from "../actionTypes/evaluationResults";

const INITIAL_STATE = {
  one: null,
  isLoadingOne: false,
  errorOne: "",
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE_SCALE:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: null,
      one: null,
    };
  case GET_ONE:
    return {
      ...state,
      one: action.payload,
      isLoadingOne: false,
      errorOne: "",
    };
  case GET_ONE_LOADING:
    return {
      ...state,
      one: null,
      isLoadingOne: true,
      errorOne: "",
    };
  case GET_ONE_ERROR:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: action.payload,
    };
  default:
    return state;
  }
};
