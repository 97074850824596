import { useContext, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomFilterToolbar from "components/CustomFilterToolbar";
import { useTranslation } from "react-i18next";
import { getValidUser } from "common/validators";
import { HCEvolution } from "./components/index";
import EvolutionPayroll from "./components/EvolutionPayroll";
import { useAnalyticsData } from "../../hooks/useAnalyticsData";
// i18N
import { SessionContext } from "../../modules/session/context";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  titleText: {
    padding: "1rem 0 1rem 1rem",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    color: "#000000",
  },
}));

const Dashboard = function () {
  const { t } = useTranslation("HRAnalytics");
  const [filter, setFilter] = useState(null);
  const {
    state: { user },
  } = useContext(SessionContext);
  const validUser = getValidUser(user);
  const [data] = useAnalyticsData(filter, validUser ? validUser.email : null);
  const [HCEvolutionData, setHCEvolutionData] = useState(null);
  const [PayrollData, setPayrollData] = useState([]);
  const classes = useStyles();

  const onChangeFilter = (filters) => {
    setFilter(filters);
    setPayrollData(null);
    setHCEvolutionData(null);
  };

  useEffect(() => {
    if (data.chartsData.length === 3) {
      setHCEvolutionData(
        data.chartsData.filter((filter) => filter.title === "HC EVOLUTION"),
      );
      setPayrollData([
        ...data.chartsData.filter(
          (filter) => filter.title === "P&A PAYROLL EVOLUTION",
        ),
        ...data.chartsData.filter(
          (filter) => filter.title === "FLAT PAYROLL EVOLUTION",
        ),
      ]);
    }
  }, [data]);

  return (
	<div className={ classes.root }>
	<Typography className={ classes.titleText }>{t("title")}</Typography>
	<Grid item lg={ 12 } sm={ 12 } xl={ 12 } xs={ 12 }>
	<CustomFilterToolbar onChange={ onChangeFilter } />
      </Grid>
	<Grid
	container
	direction={ "row" }
	justify={ "center" }
	alignItems={ "center" }
	spacing={ 4 }
      >
	<Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 } xl={ 6 }>
	<HCEvolution data={ HCEvolutionData } />
        </Grid>
	<Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 } xl={ 6 }>
	<EvolutionPayroll data={ PayrollData } />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
