import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { StyledIcon, StyledText } from "../../styles";

const CardHeader = ({ icon, title, isModal }) => (
  <Box display={ "flex" } alignItems={ "center" }>
    <StyledIcon src={ icon } alt={ title } size={ isModal ? 34 : 28 } />
    <StyledText fontSize={ isModal ? 28 : 14 } isBold isTitle>
      {title}
    </StyledText>
  </Box>
);

CardHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isModal: PropTypes.bool.isRequired,
};

export default CardHeader;
