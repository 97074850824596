import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputTextController from "components/InputTextController";
import InputCheckbox from "components/InputCheckbox";
import Button from "components/Button";
import {
  FORM_MODE,
  INPUT_TYPE,
  BUTTON_TYPE,
  BUTTON_STYLE_TYPES,
  INPUT_RULES,
  VARIANT,
  OBJECT_KEYS,
} from "common/constants";
import { StyledButtonActionsContainer } from "components/TablePagination/styles";

const EditDocumentFormModal = (props) => {
  const {
    itemSelected,
    modal,
    handleUpdate,
    currentCollaboratorId,
  } = props;
  const { t } = useTranslation(["account", "templates"]);
  const {
    control,
    handleSubmit,
    register,
  } = useForm({
    reValidateMode: FORM_MODE.onChange,
    mode: FORM_MODE.onChange,
  });

  const onSubmit = (formData) => {
    formData.requested_document.employee_id = currentCollaboratorId;
    handleUpdate(formData, itemSelected?.id);
  };

  return (
    <div data-testid={ "edit-document-modal-component" }>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <Grid item xs={ 12 }>
          <InputTextController
            type={ INPUT_TYPE.text }
            label={ itemSelected?.filename ? t("account:accountDocuments.filename") : t("templates:sections.form.url") }
            control={ control }
            rules={ INPUT_RULES.required }
            required
            name={ `requested_document.${itemSelected?.filename ? OBJECT_KEYS.fileName : OBJECT_KEYS.url}` }
            defaultValue={ itemSelected?.filename || itemSelected?.url }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <InputCheckbox
            name={ "requested_document.hidden" }
            control={ control }
            inputRef={ register }
            label={ t("accountDocuments.hidden") }
            isDefaultChecked={ itemSelected?.hidden }
          />
        </Grid>
        <Grid item xs={ 12 }>
          <StyledButtonActionsContainer>
            <Button
              onClick={ () => modal.set(false) }
              variant={ VARIANT.contained }
              typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
            >
              {t("collaborators:buttons.cancel")}
            </Button>
            <Button
              type={ BUTTON_TYPE.submit }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            >
              {t("accountDocuments.update")}
            </Button>
          </StyledButtonActionsContainer>
        </Grid>
      </form>
    </div>
  );
};

EditDocumentFormModal.propTypes = {
  currentCollaboratorId: PropTypes.number.isRequired,
  itemSelected: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default EditDocumentFormModal;
