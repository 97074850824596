import styled from "styled-components";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  padding: 0;
  margin-top: 8px;
`;

export const StyledPaper = styled(Paper)`
  border-radius: 8px 8px 0 0;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-radius: 8px 8px 0 0;
  table-layout: fixed;
`;

export const StyledTable = styled(Table)`
  table-layout: auto;
  width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  background-color: ${palette.table.header} !important;
  color: ${palette.white};
  border-radius: 8px 8px 0 0;
  .MuiTableCell-head {
    padding: 10px 16px;

    .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
      .MuiTableSortLabel-icon {
      color: ${palette.white};
    }
  }
`;

export const StyledHeaderItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.align};
  column-gap: 4px;
  min-height: 40px;

  .MuiButtonBase-root {
      color: ${palette.white};
      padding: 0;
      column-gap: ${(props) => (props.onlyLabel ? "0" : "4px")};

      &:hover {
        color: rgba(${palette.white}, 0.5);

        .MuiTableSortLabel-icon {
          opacity: 1;
        }
      }

      &.MuiTableSortLabel-root {
        margin-left: -10px;
      }

      .MuiTableSortLabel-icon {
        font-size: 1.25rem;
        color: ${palette.white};
        opacity: 0.35;
        margin: 0;
      }
    }
`;

export const StyledHeaderTitle = styled.span`
  color: ${palette.white};
  text-align:center;
`;

export const TopTableContainer = styled.div`
  display: ${(props) => (props?.isMobile ? "inline-block" : "flex")};
  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  gap: 16px;

  .searchInput {
    input {
      width: 250px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  justify-content: space-evenly;
  .MuiTableCell-root {
    padding: 15px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-size: 14px;
    line-height: 16px;
    border-bottom: ${palette.border.simpleThin};
    width: ${(props) => (props.collapsible && !props.show ? "0px!important" : "auto")};
    display: ${(props) => (props.collapsible && !props.show ? "none" : "table-cell")};
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 4px auto;
`;
