import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  INFO,
  OBJECT_KEYS,
} from "common/constants";
import {
  isEmpty, isEqual, isNull, isEmptyObject, isNullOrUndefined,
} from "common/helpers";
import { getEvaluationType } from "common/validators";
import starImg from "assets/images/performance/star.svg";
import Select from "components/Select";
import NoDataMessage from "components/NoDataMessage";
import SimpleAlert from "components/SimpleAlert";
import CircleLoader from "components_refactor/CircleLoader";
import {
  getAlert,
  getTotalEvaluationTable,
  getStarRating,
} from "views_refactor/Profile/functions/performance";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import AccordionRate from "./components/AccordionRate";
import {
  StyledGridContainer,
  StyledClassification,
  StyledGridContentContainer,
  StyledGridFilter,
  StyledResult,
  StyledModal,
} from "./styles";

const PerformanceModal = (props) => {
  const {
    t,
    profileStates,
    performanceStates,
    surveyStates,
    isValidShowPercentage,
    handleChangeFilterPeriod,
    onCloseModals,
  } = props;

  const { isModalOpen } = profileStates;
  const { performanceData } = performanceStates;

  const scaleIndices = performanceData?.result_scale_indices;
  const scaleMaxIndex = scaleIndices?.length;

  const emptyEvaluations = performanceStates.evaluationList?.length === 0;

  const evaluationCards = isEqual(
    performanceStates?.evaluationSelected,
    performanceData?.survey_process?.id,
  ) && !isEmpty(performanceData?.complete_results_by_section)
    && performanceData?.complete_results_by_section?.map(
      (evaluation) => (
        <Box key={ evaluation.name } pb={ 3 }>
          <AccordionRate
            title={ evaluation.name }
            text={ evaluation.description }
            label={ evaluation.result }
            children={ getTotalEvaluationTable(
              evaluation,
              evaluation.evaluation_results,
              performanceData,
              t,
              evaluation?.open,
              scaleMaxIndex,
              scaleIndices,
            ) }
            isOpen={ evaluation?.open }
            scaleMaxIndex={ scaleMaxIndex }
            scaleIndices={ scaleIndices }
          />
        </Box>
      ),
    );

  const initialRender = isNull(surveyStates.surveyResults)
    || surveyStates.isLoadingSurveyResults
    || isNullOrUndefined(performanceData)
    ? (
      <CircleLoader />
    ) : (
      <StyledGridContainer container>
        <StyledGridFilter item xs={ 12 } sm={ 6 } md={ 7 }>
          <StyledClassification>
            <Box pr={ 1 }>
              <img
                src={ starImg }
                alt={ t("profile:modals.performance.evaluation") }
              />
            </Box>
            <Box>
              <Typography variant={ "h4" }>
                {t("profile:modals.performance.title")}
              </Typography>
            </Box>
          </StyledClassification>
        </StyledGridFilter>
        <StyledGridFilter item xs={ 12 } sm={ 6 } md={ 5 }>
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("profile:modals.performance.filterPeriod") }
            menuItems={ performanceStates.evaluationList }
            value={ performanceStates.evaluationSelected || "" }
            onChange={ handleChangeFilterPeriod }
            disabled={ isEmpty(performanceStates.evaluationList) }
          />
        </StyledGridFilter>
        <StyledGridContentContainer item xs={ 12 }>
          {isEmptyObject(performanceData)
            || emptyEvaluations ? (
              <>
                <AccordionRate
                  title={ t(
                    "profile:modals.performance.performanceEvaluationNote",
                  ) }
                />
                <NoDataMessage customStyles={ "noDataCustom" } />
              </>
            ) : (
              <>
                <AccordionRate
                  title={ t(
                    "profile:modals.performance.performanceEvaluationNote",
                  ) }
                  text={ !isNull(performanceData?.comment)
                    ? performanceData?.comment
                    : "" }
                  label={ performanceData?.result }
                  scaleMaxIndex={ scaleMaxIndex }
                  scaleIndices={ scaleIndices }
                />
                <Box pt={ 2 }>
                  {isValidShowPercentage && performanceData?.results_by_evaluation_type
                && (
                  <SimpleAlert
                    type={ INFO }
                    message={ getAlert(
                      performanceData?.results_by_evaluation_type,
                      t,
                    ) }
                  />
                )}
                  <Box pb={ 2 } display={ "flex" }>
                    {performanceData?.results_by_evaluation_type.map((result) => (
                      <StyledResult key={ result.id }>
                        <h4>{getEvaluationType(result.evaluation_type, t)}</h4>
                        {getStarRating(result, scaleMaxIndex, scaleIndices)}
                      </StyledResult>
                    ))}
                  </Box>
                </Box>
                <Grid container>
                  <Grid item xs={ 12 }>
                    { evaluationCards }
                  </Grid>
                </Grid>
              </>
            )}
        </StyledGridContentContainer>
      </StyledGridContainer>
    );

  return (
    <StyledModal
      isOpen={ isModalOpen.performance }
      onClose={ () => onCloseModals() }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "800px" }>
        {initialRender}
      </StyledScrollBar>
    </StyledModal>
  );
};

PerformanceModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModals: PropTypes.func.isRequired,
  profileStates: PropTypes.object.isRequired,
  performanceStates: PropTypes.object.isRequired,
  surveyStates: PropTypes.object.isRequired,
  isValidShowPercentage: PropTypes.bool.isRequired,
  handleChangeFilterPeriod: PropTypes.func.isRequired,
};

export default PerformanceModal;
