import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ALIGN_ITEMS } from "common/constants";
import { StyledSpiderChartTooltip, StyledTooltipText, StyledTooltipIcon } from "styledComponents/Charts";
import constants from "styledComponents/constants";
import palette from "theme/palette";
import { getTooltipIcon, getTooltipTitle, getTooltipRecommendation } from "../../functions/dotTooltip";

const DotTooltipDetail = (props) => {
  const { payload, label } = props;
  const { t } = useTranslation("talentDrain");

  const getTooltipMessage = () => {
    const { value } = payload[0];
    const message = {
      icon: getTooltipIcon(value),
      text: getTooltipTitle(value, t),
      recommendation: getTooltipRecommendation(value, label, t),
    };
    return (message);
  };

  const tooltipRecommendation = isEmpty(payload) ? "" : getTooltipMessage();

  return (
    <div data-testid={ "dot-tooltip-component-view" }>
      {!isEmpty(payload) && (
        <StyledSpiderChartTooltip>
          <Grid container>
            <Grid item xs={ 12 }>
              <StyledTooltipText fontWeight={ constants.fontConstants.bold }>{`${payload[0].value}%`}</StyledTooltipText>
            </Grid>
            <Grid item xs={ 12 }>
              <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
                <StyledTooltipIcon hasBackground>{tooltipRecommendation.icon}</StyledTooltipIcon>
                <StyledTooltipText
                  fontWeight={ constants.fontConstants.medium }
                  color={ palette.white }
                >
                  {tooltipRecommendation.text}
                </StyledTooltipText>
              </Box>
            </Grid>
            <Grid item xs={ 12 }>
              <StyledTooltipText>{tooltipRecommendation.recommendation}</StyledTooltipText>
            </Grid>
          </Grid>
        </StyledSpiderChartTooltip>
      )}
    </div>
  );
};

DotTooltipDetail.propTypes = {
  payload: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default DotTooltipDetail;
