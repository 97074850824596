import styled from "styled-components";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import { isEqual } from "common/helpers";
import palette from "theme/palette";

export const StyledCircleImage = styled.circle`
  stroke: ${(props) => (isEqual(props.order, 0) ? palette.background.purple : palette.white)};
  stroke-width: 0.3px;
  cursor: pointer;
  :hover {
    stroke: ${(props) => (isEqual(props.order, 0) ? palette.white : palette.background.purple)}
  }
`;

export const StyledGroupNumber = styled.text`
  font-size: 3px;
`;

export const StyledFocusRect = styled.rect`
  opacity: 0.5;
`;

export const StyledTooltipRect = styled.rect`
  fill: ${palette.background.purple};
`;

export const StyledTooltipText = styled.text`
  font-size: 1.6px;
  fill: ${palette.white};
`;

export const StyledContainerList = styled(List)`
  width: 250px;
`;

export const StyledContentFlex = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0px !important;
`;

export const StyledListItemAvatar = styled(ListItemAvatar)`
  min-width: 32px !important;
`;

export const StyledListItem = styled(ListItem)`
  align-items: center;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  padding: 3px;
`;

export const StyledSmallAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;
