import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ALIGN_ITEMS, VARIANT } from "common/constants";
import { getRange } from "../../../../functions/performance";
import { StyledScale, StyledTitle, StyledGrid } from "./styles";

const ResultScale = (props) => {
  const { scale } = props;
  const { t } = useTranslation("performance");

  return (
    <StyledGrid item xs={ 12 } md={ 3 } data-testid={ "good-leader-result-scale" } >
      <Card>
        <CardContent>
          <Typography variant={ VARIANT.bodyOne }>
            {t("goodLeaderDescriptionScale")}
          </Typography>
          {scale.map((scale, index) => (
            <Box key={ `scale-${index}` } mt={ 1 } display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
              <StyledScale color={ scale?.color }>
                {getRange(scale)}
              </StyledScale>
              <StyledTitle>
                {scale.result}
              </StyledTitle>
            </Box>
          ))}
        </CardContent>
      </Card>
    </StyledGrid>
  );
};

ResultScale.propTypes = {
  scale: PropTypes.array.isRequired,
};

export default ResultScale;
