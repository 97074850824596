import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {INPUT_RULES, INPUT_TYPE, VARIANT} from "common/constants";
import InputForm from "components/InputForm";
import { getErrorMessage } from "common/formValidators";

const EmailRequestForm = (props) => {
  const { control, errors } = props;
  const { t } = useTranslation(["common", "authentication"]);
  return (
    <div data-testid={ "email-request" }>
      <InputForm
        label={ t("authentication:recover_password.your_email") }
        control={ control }
        name={ "email" }
        margin={ VARIANT.normal }
        type={ INPUT_TYPE.text }
        rules={ INPUT_RULES.isValidEmail }
        error={ getErrorMessage(errors, "email") }
      />
    </div>
  );
};

EmailRequestForm.propTypes = {
  control: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default EmailRequestForm;
