import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import InputTextController from "components/InputTextController";
import DateInputController from "components/DateInputController";
import RadioButtonController from "components/RadioButtonController";
import Button from "components/Button";
import SelectController from "components/SelectController";
import {
  AUTOCOMPLETE,
  BUTTON_STYLE_TYPES,
  BUTTON_TYPE,
  COMPONENT,
  DATE_PARTS,
  OBJECT_KEYS,
  RADIO_OPTIONS,
  TYPES, VARIANT,
} from "common/constants";
import { getFormattedItems, isDateGreaterThanToday } from "common/utils";
import { isEqual } from "common/helpers";
import { getList as getTournoverReasons } from "redux/actions/common/turnoverReasonActions";
import { StyledContainer, StyledActions } from "./styles";

const TerminateEmploymentForm = (props) => {
  const {
    id, action, relocatedData, modalHandler,
  } = props;
  const { t } = useTranslation("collaborators");
  const [isLoading, setIsLoading] = useState(false);
  const [voluntary, setVoluntary] = useState(RADIO_OPTIONS.voluntary);
  const dispatch = useDispatch();

  const {
    list: turnoverReasonsList,
    isLoadingList: isLoadingTurnoverReasons,
  } = useSelector(({ turnoverReasonReducer }) => turnoverReasonReducer);

  const reasonsList = turnoverReasonsList && turnoverReasonsList.filter((e) => isEqual(
    e.voluntary, isEqual(voluntary, RADIO_OPTIONS.voluntary),
  ));

  useEffect(() => {
    dispatch(getTournoverReasons());
  }, [dispatch]);

  const handleChange = (event) => {
    setVoluntary(event.target.value);
  };

  const { handleSubmit, control } = useForm({
    defaultValues: {
      turnover_reason_id: "",
      comments: "",
    },
  });

  const onSubmit = (data) => {
    if (!isDateGreaterThanToday(data.termination_date)) {
      setIsLoading(true);
    }
    const turnover = {};
    if (relocatedData) {
      data.managers = relocatedData;
    }
    turnover.turnover = data;
    action(id, turnover);
    if (modalHandler) {
      modalHandler(false);
    }
  };

  return (
    <StyledContainer>
      <form
        autoComplete={ AUTOCOMPLETE.off }
        onSubmit={ handleSubmit(onSubmit) }
      >
        <DateInputController
          id={ OBJECT_KEYS.terminationDate }
          label={ t("collaborators:forms.termination_date") }
          control={ control }
          name={ OBJECT_KEYS.terminationDate }
          openTo={ DATE_PARTS.day }
          value={ new Date() }
          required
          views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
        />
        <FormControl component={ COMPONENT.fieldset }>
          <FormLabel component={ COMPONENT.legend }>
            {t("collaborators:forms.termination_type")}
          </FormLabel>
          <RadioGroup
            aria-label={ RADIO_OPTIONS.voluntary }
            name={ RADIO_OPTIONS.voluntary }
            value={ voluntary }
            onChange={ handleChange }
            control={ control }
          >
            <RadioButtonController
              label={ t("collaborators:forms.termination_type_voluntary") }
              value={ RADIO_OPTIONS.voluntary }
            />
            <RadioButtonController
              label={ t("collaborators:forms.termination_type_involuntary") }
              value={ RADIO_OPTIONS.involuntary }
            />
          </RadioGroup>
        </FormControl>
        <SelectController
          id={ OBJECT_KEYS.turnoverReasonId }
          label={ t("collaborators:forms.termination_reason") }
          control={ control }
          name={ OBJECT_KEYS.turnoverReasonId }
          menuItems={ getFormattedItems(null, reasonsList) }
          disabled={ isLoadingTurnoverReasons }
          required
        />
        <InputTextController
          type={ TYPES.text }
          label={ t("collaborators:forms.termination_comments") }
          control={ control }
          multiline={ 4 }
          name={ OBJECT_KEYS.comments }
        />
        <StyledActions>
          {/* <Button
            onClick={() => setEditable(false)}
            variant="contained"
            typeStyle={BUTTON_STYLE_TYPES.CANCEL}
          >
            {t("collaborators:buttons.cancel")}
          </Button> */}
          <Button
            variant={ VARIANT.contained }
            autoFocus
            type={ BUTTON_TYPE.submit }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            isLoading={ isLoading }
          >
            {t("collaborators:table.table-body.finish_contract")}
          </Button>
        </StyledActions>
      </form>
    </StyledContainer>
  );
};

TerminateEmploymentForm.propTypes = {
  id: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  relocatedData: PropTypes.array,
  modalHandler: PropTypes.func,
};

TerminateEmploymentForm.defaultValues = {
  relocatedData: [],
  modalHandler: () => {},
};

export default TerminateEmploymentForm;
