import styled from "styled-components";
import Grid from "@mui/material/Grid";
import palette from "theme/palette";

export const StyledContainer = styled(Grid)`
  margin-top: -50px;
  row-gap: 16px;

  @media (max-width: 900px) {
    margin-top: 0px;
  }
`;

export const StyledGrid = styled(Grid)`
  background-color: ${palette.background.lightPurple};
  padding: 15px 16px;
  margin: 0;
  border-radius: 4px;
`;
