import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { FORMAT_TYPE, OBJECT_KEYS, URL_FORMAT } from "common/constants";
import { STATES } from "common/constants/preboarding";
import { orderByAsc } from "common/helpers";

export const HEADER = ["content", "format", "state"];

const getStatus = (t, hasConfirmed, required) => {
  const pendingState = required ? STATES.pendingRequired : STATES.pendingNotRequired;
  return t(`preboarding:states.${hasConfirmed ? STATES.completed : pendingState}`);
};

export const getRowsDesktop = (data, t) => data?.map(({
  name, format, has_confirmed, required,
}) => [
  { content: name },
  { content: FORMAT_TYPE[format].value },
  { content: getStatus(t, has_confirmed, required) },
]);

export const getFormatByURL = (url) => {
  if (URL_FORMAT.mp4.some((format) => url.match(format))) return FORMAT_TYPE.mp4.key;
  if (URL_FORMAT.video.some((format) => url.match(format))) return FORMAT_TYPE.video.key;
  if (URL_FORMAT.pdf.some((format) => url.match(format))) return FORMAT_TYPE.pdf.key;
  return FORMAT_TYPE.website.key;
};

export const getCompanyResourcesFormat = (data) => orderByAsc(data, OBJECT_KEYS.order)?.map(
  (item) => ({
    ...item,
    format: getFormatByURL(item.url || item.file_url),
    url: item.url || item.file_url,
  }),
);

export const addInfo = (id, answer) => ({ id, answer });

export const addFile = (id, name, file) => ({ id, name, file });

export const isInvalidAnswersRequired = (infos = [], answers) => infos.some((item) => item?.required && (!(answers && item.id in answers) || answers[item.id] === ""));

export const isInvalidDocumentsRequired = (
  files = [], uploaded,
) => files.some(
  (item) => item?.required && !uploaded.some((e) => e.id === item.id),
);

export const getFile = (files, id) => Object.values(files)?.filter((e) => e.id === id);

export const handleUpload = (file, id, t) => {
  const extensions = /(.jpg|.jpeg|.png|.pdf)$/i;
  if (!extensions.exec(file.name)) {
    toast(MESSAGE_TYPES.warning, {
      title: t("common:common.api_responses.warning.title"),
      message: t("candidates:induction.format_file"),
    });
    return null;
  }
  return addFile(id, file.name, file);
};
