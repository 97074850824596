import PropTypes from "prop-types";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledButton, StyledTooltip } from "./styles";

const Button = (props) => {
  const {
    id,
    variant,
    component,
    onClick,
    isDisabled,
    type,
    size,
    isFullWidth,
    children,
    isLoading,
    icon,
    iconSide,
    typeStyle,
    customStyle,
    className,
    href,
    onBlur,
    tooltip,
  } = props;
  const handleClick = (event) => onClick(event);

  let render = children;

  if (icon) {
    render = (
      <div className={ "buttonIconContainer" }>
        <div className={ iconSide }>{icon}</div>
        {children}
      </div>
    );
  }
  let finalRender = render;

  if (isLoading) {
    finalRender = <CircularProgress size={ 30 } />;
  }

  const buttonComponent = (
    <StyledButton
      { ...props }
      id={ id }
      variant={ variant }
      className={ clsx(typeStyle, "general", customStyle, className) }
      component={ component }
      onClick={ handleClick }
      disabled={ isDisabled || isLoading }
      type={ type }
      size={ size }
      fullWidth={ isFullWidth }
      to={ isDisabled ? "" : href }
      onBlur={ onBlur }
    >
      {finalRender}
    </StyledButton>
  );

  return isDisabled && tooltip
    ? (
      <StyledTooltip title={ tooltip?.title } placement={ tooltip?.placement }>
        <span>
          {buttonComponent}
        </span>
      </StyledTooltip>
    )
    : buttonComponent;
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  customStyle: PropTypes.string,
  component: PropTypes.any,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.object || PropTypes.string,
  iconSide: PropTypes.string,
  typeStyle: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  tooltip: PropTypes.object || PropTypes.boolean,
};

Button.defaultProps = {
  id: "",
  variant: "text",
  type: "button",
  customStyle: "",
  component: "button",
  isDisabled: false,
  size: "",
  isFullWidth: false,
  isLoading: false,
  icon: null,
  iconSide: "",
  typeStyle: "",
  href: "",
  className: "",
  onBlur: null,
  onClick: () => {},
  tooltip: null,
};

export default Button;
