import forOwn from "lodash/forOwn";
import isDate from "lodash/isDate";
import isString from "lodash/isString";
import XLSX from "xlsx";
import {
  BULK_UPLOAD,
  ERROR,
  FORMAT_EMAIL,
  OBJECT_KEYS,
  TYPES,
} from "common/constants";
import {
  isEmpty,
  isEqual,
  isNull,
  isNullOrUndefined,
  isNumber,
} from "common/helpers";
import TooltipIconTable from "../components/TooltipIconTable";

export const hasPreviewData = (stateFile, preview, isLoading) => !isNull(stateFile) && !isEmpty(preview.data) && !isLoading;

export const headerByDefault = (excelHeader) => {
  const headerData = [
    {
      id: BULK_UPLOAD.row_number,
      label: "",
      customRender: (rowData) => <TooltipIconTable data={ rowData } />,
    },
  ];
  excelHeader.forEach((item) => {
    headerData.push({ id: item.key, label: item.name });
  });
  return headerData;
};

export const downloadExcelCustom = (filename, excelHeader) => {
  const data = excelHeader;
  return [data, filename, true];
};

const isValidTemplate = (excelHeader, data) => {
  let isValidData = false;
  data.forEach((item) => {
    forOwn(item, (value, key) => {
      if (BULK_UPLOAD.row_number !== key) {
        const dataType = excelHeader.find((header) => header.key === key);
        if (!isNullOrUndefined(dataType)) {
          isValidData = true;
        }
      }
    });
  });

  if (!isValidData) {
    isValidData = Object.keys(data[BULK_UPLOAD.xlsx.firstData]).length
        - BULK_UPLOAD.xlsx.col
      === excelHeader.length;
  }
  return isValidData;
};

export const validationByDataType = (type, value) => {
  if (isEqual(type, TYPES.number)) {
    return isNumber(value);
  }
  if (isEqual(type, TYPES.email)) {
    return FORMAT_EMAIL.test(value);
  }
  if (isEqual(type, TYPES.date)) {
    return isDate(value);
  }
  return isNumber(value) || isString(value);
};

const validateValueByList = (value, data) => data.list.find((item) => item[data.keyList] === value);

const getFormat = (format) => (format === TYPES.string ? TYPES.text : format);

const getValidationData = (isValid, message) => ({ isValid, message });

const validateDataByKey = (value, key, t, excelHeader) => {
  const dataType = excelHeader.find((header) => header.key === key);
  let validationData = {};

  // Validate that it is a value from the list
  if (!isNull(value) && !isEmpty(dataType.list)) {
    const isValidValue = validateValueByList(value, dataType);
    validationData = getValidationData(
      isValidValue,
      `${dataType.name} - ${t("common:common.excel.selectError")}`,
    );
  // Validate required data
  } else if (isNull(value) && dataType.isRequired) {
    const requiredFileMessage = `${dataType.name} - ${t("required")}`;
    validationData = getValidationData(false, requiredFileMessage);
  } else {
  // Validate format
    const isValid = isNull(value) || validationByDataType(dataType.format, value);
    const invalidFileMessage = !validationData.isValid
        && `${dataType.name} ${t("isInvalid")} "${getFormat(dataType.format)}"`;
    validationData = getValidationData(isValid, invalidFileMessage);
  }

  return validationData;
};

const orderDataByRowAndError = (data) => data
  .sort((a, b) => a.row_number - b.row_number)
  .sort((a, b) => +b.errors.hasError - a.errors.hasError);

export const hasAlert = (preview, isLoading) => preview && preview.alert && !isLoading;

export const getAlert = (type, message) => ({ type, message });

export const validationData = (data, excelHeader, t) => {
  let dataPreview = [];
  let hasError = false;
  let alertInfo = {};

  if (isValidTemplate(excelHeader, data)) {
    data.forEach((item) => {
      let error = false;
      const errorList = [];
      forOwn(item, (value, key) => {
        if (BULK_UPLOAD.row_number !== key) {
          const validationDataResult = validateDataByKey(
            value,
            key,
            t,
            excelHeader,
          );
          if (!validationDataResult?.isValid) {
            error = true;
            errorList.push(validationDataResult?.message);
          }
        }
      });
      if (error) {
        hasError = true;
      }
      item[OBJECT_KEYS.errors] = {
        hasError: error,
        errorList,
      };
    });
    const orderedData = orderDataByRowAndError(data);
    dataPreview = orderedData.slice(
      BULK_UPLOAD.xlsx.firstData,
      BULK_UPLOAD.xlsx.dataLimit,
    );
  } else {
    alertInfo = getAlert(ERROR, t("alertInfo.templateError"));
    hasError = true;
  }
  return { data: dataPreview, hasError, alert: alertInfo };
};

const getWorksheet = (workbook, sheetNames, stateFile) => {
  const worksheet = workbook.Sheets[sheetNames];
  const range = XLSX.utils.decode_range(worksheet[BULK_UPLOAD.xlsx.ref]);

  for (let column = range.s.r; column <= range.e.c; ++column) {
    const address = XLSX.utils.encode_col(column) + BULK_UPLOAD.xlsx.col.toString();
    if (!worksheet[address]) continue;
    const stateFileHeader = stateFile.header[column + BULK_UPLOAD.xlsx.col];
    worksheet[address].w = stateFileHeader && stateFileHeader.id;
  }

  return worksheet;
};

const getRange = (worksheet, typeBulkUpload) => {
  const range = XLSX.utils.decode_range(worksheet[BULK_UPLOAD.xlsx.ref]);
  range.s.c = BULK_UPLOAD.xlsx.firstData;
  range.e.c = BULK_UPLOAD.xlsx.maxCol[typeBulkUpload];
  return XLSX.utils.encode_range(range);
};

export const readData = (result, readAsBinaryString, stateFile, typeBulkUpload) => {
  const workbook = XLSX.read(result, {
    type: readAsBinaryString
      ? BULK_UPLOAD.xlsx.type.binary
      : BULK_UPLOAD.xlsx.type.array,
    bookVBA: true,
    cellDates: true,
  });
  const sheetNames = workbook.SheetNames[BULK_UPLOAD.xlsx.firstData];
  const worksheet = getWorksheet(workbook, sheetNames, stateFile);
  const newRange = getRange(worksheet, typeBulkUpload);
  const data = XLSX.utils.sheet_to_json(worksheet, {
    blankRows: true,
    defval: null,
    range: newRange,
  });
  data.forEach((item, index) => {
    item[BULK_UPLOAD.row_number] = index + BULK_UPLOAD.xlsx.initialCounter;
  });
  return data;
};
