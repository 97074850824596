import styled from "styled-components";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import theme from "theme";
import palette from "theme/palette";

export const StyledToolbar = styled(Toolbar)`
  min-height: 48px;
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(1)};
  background-color: ${(props) => props.highlight && palette.secondary.dark};
  color: ${palette.white};
`;

export const StyledTitle = styled(Typography)`
  flex: 1 1 100%;
  text-align: left;
  color: ${ palette.white };
`;
