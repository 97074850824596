import { combineReducers } from "redux";
import acknowledgementTypesReducer from "../redux/reducers/acknowledgementTypesReducer";
import nineboxProcessReducer from "../redux/reducers/nineboxReducer";
import managerReducer from "../redux/reducers/common/managerReducer";
import signInReducer from "../redux/reducers/userAuthReducer";
import createPasswordReducer from "../redux/reducers/userRecoveryReducer";
import countryReducer from "../redux/reducers/common/countryReducer";
import orgUnitReducer from "../redux/reducers/common/orgUnitReducer";
import collaboratorReducer from "../redux/reducers/collaboratorReducer";
import surveysReducer from "../redux/reducers/surveyProcessesReducer";
import cityReducer from "../redux/reducers/common/cityReducer";
import surveyResultReducer from "../redux/reducers/surveyResultReducer";
import documentTypesReducer from "../redux/reducers/documentTypesReducer";
import infoTypesReducer from "../redux/reducers/infoTypesReducer";
import collaboratorDocumentReducer from "../redux/reducers/collaborator/documentReducer";
import performanceReducer from "../redux/reducers/performance/performanceReducer";
import performanceSurveyResultsReducer from "../redux/reducers/performance/surveyResultsReducer";
import performanceRankingReducer from "../redux/reducers/performance/rankingReducer";
import performanceHeatMapReducer from "../redux/reducers/performance/heatMapReducer";
import performanceGoodLeaderReducer from "../redux/reducers/performance/goodLeaderReducer";
import collaboratorAttritionReducer from "../redux/reducers/collaborator/attritionReducer";
import attritionReducer from "../redux/reducers/attritionReducer";
import personalityReducer from "../redux/reducers/personalityReducer";
import engagementProcessReducer from "../redux/reducers/engagementReducer";
import typeOfContractReducer from "../redux/reducers/typeOfContractReducer";
import hrAnalyticsReducer from "../redux/reducers/hrAnalyticsReducer";
import goalsReducer from "../redux/reducers/goalsReducer";
import stateReducer from "../redux/reducers/common/stateReducer";
import timeOffReducer from "../redux/reducers/timeOffReducer";
import candidateReducer from "../redux/reducers/candidateReducer";
import positionReducer from "../redux/reducers/position/positionReducer";
import orgUnitsTypesReducer from "../redux/reducers/organizationUnits/orgUnitsTypesReducer";
import orgUnitsReducer from "../redux/reducers/organizationUnits/orgUnitsReducer";
import feedbackReducer from "../redux/reducers/feedbackReducer";
import bulkUploadReducer from "../redux/reducers/bulkUploadReducer";
import potentialReducer from "../redux/reducers/potential/potentialReducer";
import templateReducer from "../redux/reducers/template/templateReducer";
import followUpReducer from "../redux/reducers/followUpReducer";
import benefitsReducer from "../redux/reducers/benefitsReducer";
import timeOffCategoryReducer from "../redux/reducers/timeOffCategoryReducer";
import timeOffTypeReducer from "../redux/reducers/timeOffTypeReducer";
import historicalMovementsReducer from "../redux/reducers/historicalMovementsReducer";
import employmentRelationshipReducer from "../redux/reducers/common/employmentRelationshipReducer";
import rolesReducer from "../redux/reducers/common/rolesReducer";
import currencyReducer from "../redux/reducers/common/currencyReducer";
import notificationReducer from "../redux/reducers/notification/notificationReducer";
import agreementsReducer from "../redux/reducers/agreement/agreementsReducer";
import agreementsCategoryReducer from "../redux/reducers/agreement/agreementsCategoryReducer";
import evaluationScaleReducer from "../redux/reducers/evaluationScaleReducer";
import agreementsStatesReducer from "../redux/reducers/agreement/agreementsStatesReducer";
import legalEntityReducer from "../redux/reducers/common/legalEntityReducer";
import hierarchyLevelReducer from "../redux/reducers/common/hierarchyLevelReducer";
import genderReducer from "../redux/reducers/common/genderReducer";
import audienceReducer from "../redux/reducers/audience/audienceReducer";
import dynamicAttributeReducer from "../redux/reducers/common/dynamicAttributeReducer";
import turnoverReasonReducer from "../redux/reducers/common/turnoverReasonReducer";
import sectionReducer from "../redux/reducers/section/sectionReducer";
import sectionItemReducer from "../redux/reducers/section/sectionItemReducer";
import resultScalesReducer from "../redux/reducers/resultScalesReducer";
import evaluationResultReducer from "../redux/reducers/evaluationResultReducer";
import nalaAnalyticsReducer from "../redux/reducers/nalaAnalytics/nalaAnalyticsReducer";
import slackReducer from "../redux/reducers/integrations/slackReducer";
import periodsReducer from "../redux/reducers/periods/periodsReducer";

const rootReducer = combineReducers({
  hrAnalyticsReducer,
  acknowledgementTypesReducer,
  nineboxProcessReducer,
  managerReducer,
  signInReducer,
  createPasswordReducer,
  countryReducer,
  orgUnitReducer,
  collaboratorReducer,
  surveysReducer,
  cityReducer,
  surveyResultReducer,
  documentTypesReducer,
  infoTypesReducer,
  collaboratorDocumentReducer,
  performanceReducer,
  performanceSurveyResultsReducer,
  performanceRankingReducer,
  performanceHeatMapReducer,
  performanceGoodLeaderReducer,
  collaboratorAttritionReducer,
  attritionReducer,
  personalityReducer,
  engagementProcessReducer,
  stateReducer,
  timeOffReducer,
  candidateReducer,
  orgUnitsTypesReducer,
  orgUnitsReducer,
  typeOfContractReducer,
  positionReducer,
  feedbackReducer,
  bulkUploadReducer,
  potentialReducer,
  goalsReducer,
  templateReducer,
  followUpReducer,
  benefitsReducer,
  timeOffCategoryReducer,
  timeOffTypeReducer,
  historicalMovementsReducer,
  employmentRelationshipReducer,
  rolesReducer,
  currencyReducer,
  notificationReducer,
  agreementsReducer,
  agreementsCategoryReducer,
  evaluationScaleReducer,
  agreementsStatesReducer,
  legalEntityReducer,
  hierarchyLevelReducer,
  genderReducer,
  audienceReducer,
  dynamicAttributeReducer,
  turnoverReasonReducer,
  sectionReducer,
  sectionItemReducer,
  resultScalesReducer,
  evaluationResultReducer,
  nalaAnalyticsReducer,
  slackReducer,
  periodsReducer,
});

export default rootReducer;
