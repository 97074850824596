import {
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import CardContent from "@mui/material/CardContent";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleAlert from "components/SimpleAlert";
import { DEVELOPMENT_PLAN_INDEX_TABS, PAGINATION } from "common/constants";
import {
  getEmployeeId, isManager, isAdmin, isValidAdmin, isAdminOrManager as isAdminOrManagerFunction,
  isAdminOrSuccessionAdmin, isValidToKeyPositionAction,
} from "common/utils";
import {
  getTotalAgreementsByEmployee,
  getAgreementsPaginated,
} from "redux/actions/agreements/agreementActions";
import useComplexState from "hooks/utils/useComplexState";
import { SessionContext } from "modules/session/context";
import usePagination from "hooks/utils/usePagination";
import Overview from "./components/Overview";
import InProgress from "./components/InProgress";
import { StyledContainer, StyledTabContainer, StyledCard } from "./styles";

// TODO: refactor and use new architecture
const DevelopmentPlan = (props) => {
  const { t } = useTranslation("developmentPlan");
  const {
    state: { user },
  } = useContext(SessionContext);
  const employeeId = user && getEmployeeId(user);
  const [selectIdFromTable, setSelectIdFromTable] = useState();
  const [developmentPlanStates, setDevelopmentPlanStates] = useComplexState({
    tabHandler: DEVELOPMENT_PLAN_INDEX_TABS.inProgress,
    isLoading: false,
    agreementsListTotalGeneral: null,
    defaultSelectedEmployeeId: null,
  });
  const [active, setActive] = useState(false);

  const filter = props?.location?.search;
  let filterName = null;
  if (filter) {
    const params = new URLSearchParams(filter);
    filterName = params.get("employee-name");
  }

  const handleChange = () => {
    setActive(!active);
  };

  const handleValueChanged = (value) => {
    setDevelopmentPlanStates({ tabHandler: value });
  };

  useEffect(() => {
    if (selectIdFromTable) {
      setDevelopmentPlanStates({
        isLoading: true,
        tabHandler: DEVELOPMENT_PLAN_INDEX_TABS.overview,
      });
    }
    // eslint-disable-next-line
  }, [selectIdFromTable]);

  useEffect(() => {
    if (developmentPlanStates.tabHandler === DEVELOPMENT_PLAN_INDEX_TABS.overview) {
      setDevelopmentPlanStates({ isLoading: false });
    }
    // eslint-disable-next-line
  }, [developmentPlanStates.tabHandler]);

  // default query
  const isAdminOrManager = isAdminOrManagerFunction(user?.userCookies);

  const isValidToPrivateAgreements = isAdminOrSuccessionAdmin(user);
  const isValidToSuccessionPlan = isValidToKeyPositionAction(user);

  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [searchFilter, setSearchFilter] = useState(filterName);

  const handleSearch = (name = "") => {
    setSearchFilter(name);
  };

  const getDefaultQuery = () => {
    const query = {
      q: {
        active_in: [true],
      },
    };
    if ((isManager(user?.userCookies) && !isValidAdmin(user?.userCookies)) || active) {
      query.q.manager_id_eq = employeeId;
    }
    if (!isAdminOrManager) {
      query.q.id_eq = employeeId;
    }
    return query;
  };

  const query = getDefaultQuery();

  // get manager data
  // FIXME: Ideally, I would like to make only one GET request and be able
  // to indicate that the current employee should appear first on page 1
  const managerDataQuery = useQuery(
    ["managerData", employeeId],
    () => getTotalAgreementsByEmployee(employeeId),
    { staleTime: Infinity },
    {
      enabled: isManager(user?.userCookies),
    },
  );

  const { data: managerData, error: managerDataError } = managerDataQuery;

  const {
    data: { data: agreementsList = null, total: agreementsListTotal = 0 } = {},
    isLoading: isLoadingAgreementsList,
    error: agreementsListError,
    refetch, // This function is used to manually trigger the execution of a query.
  } = useQuery(
    ["agreementsList", pagination, query],
    () => getAgreementsPaginated(pagination, query),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: false,
    },
  );

  useEffect(() => {
    if (agreementsList !== null && developmentPlanStates.agreementsListTotalGeneral === null) {
      setDevelopmentPlanStates(
        {
          agreementsListTotalGeneral: agreementsListTotal,
          defaultSelectedEmployeeId: agreementsListTotal > 0 ? agreementsList[0].id : null,
        },
      );
    }
    // eslint-disable-next-line
  }, [agreementsListTotal]);

  const isValidOverview = (isManager(user?.userCookies) || isAdmin(user?.userCookies))
  && developmentPlanStates.defaultSelectedEmployeeId;

  const allTabs = () => {
    const tabs = [{ label: t("tabs.in-progress") }];
    if (isValidOverview) {
      tabs.push({ label: t("tabs.overview") });
    }
    return tabs;
  };

  useEffect(() => {
    if (isAdminOrManager) {
      if (searchFilter) {
        query.q.person_full_name_cont = searchFilter;
      } else {
        delete query.q.person_full_name_cont;
      }
      const paginationSearch = searchFilter ? PAGINATION.next : pagination;
      setPagination(paginationSearch);
      refetch();
    }
    // eslint-disable-next-line
  }, [searchFilter, pagination, active]);

  return (
    <StyledContainer data-testid={ "development-plan-view-component" }>
      <ViewHeaderTitle title={ t("title") } subtitle={ t("subtitle") } />
      <StyledTabContainer
        spacing={ 4 }
        fontsize={ 14 }
        normaltext={ "true" }
        uppercase={ "true" }
        withbackground={ "true" }
      >
        {developmentPlanStates.isLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            <Tab
              tabs={ allTabs() }
              onChange={ handleValueChanged }
              tabValue={ developmentPlanStates.tabHandler }
            />
            <TabPanel
              value={ developmentPlanStates.tabHandler }
              index={ DEVELOPMENT_PLAN_INDEX_TABS.inProgress }
            >
              <StyledCard>
                <CardContent>
                  {(agreementsListError || managerDataError)
                    ? (
                      <SimpleAlert
                        type={ "error" }
                        title={ t("common:common.api_responses.error.title") }
                        message={ t(`common:common.api_responses.error.${agreementsListError?.response?.status || managerDataError?.response?.status}`) }
                      />
                    )
                    : (
                      <InProgress
                        setSelectId={ setSelectIdFromTable }
                        employeeId={ employeeId }
                        agreementsList={ agreementsList }
                        isLoadingAgreementsList={ isLoadingAgreementsList }
                        agreementsListTotal={ agreementsListTotal }
                        handlePagination={ handlePagination }
                        pagination={ pagination }
                        handleSearch={ handleSearch }
                        managerData={ managerData }
                        agreementsTotal={ developmentPlanStates.agreementsListTotalGeneral }
                        showTeam={ { active, handleChange } }
                        isValidToPrivateAgreements={ isValidToPrivateAgreements }
                        isValidToSuccessionPlan={ isValidToSuccessionPlan }
                        filterName={ filterName }
                      />
                    )}
                </CardContent>
              </StyledCard>
            </TabPanel>
            {isValidOverview && (
              <TabPanel
                value={ developmentPlanStates.tabHandler }
                index={ DEVELOPMENT_PLAN_INDEX_TABS.overview }
              >
                <StyledCard>
                  <CardContent>
                    <Overview
                      selectIdFromTable={ selectIdFromTable || developmentPlanStates.defaultSelectedEmployeeId }
                    />
                  </CardContent>
                </StyledCard>
              </TabPanel>
            )}
          </>
        )}
      </StyledTabContainer>
    </StyledContainer>
  );
};

export default DevelopmentPlan;
