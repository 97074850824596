import moment from "moment";
import {
  DATE_PARTS,
  GRAMMATICAL_NUMBER,
  OBJECT_KEYS,
} from "common/constants";
import { RISK_COLORS } from "theme/palette";

export const RISK_CATEGORY = {
  high: {
    key: OBJECT_KEYS.high,
    color: RISK_COLORS.RED,
  },
  medium: {
    key: OBJECT_KEYS.medium,
    color: RISK_COLORS.YELLOW,
  },
  low: {
    key: OBJECT_KEYS.low,
    color: RISK_COLORS.GREEN,
  },
};

// Get the translated name of a date part based on the total and type
const getNameByTotal = (t, total, type) => t(`common:common.date_parts.${type}.${total === 1 ? GRAMMATICAL_NUMBER.singular : GRAMMATICAL_NUMBER.plural}`);

// Calculate the seniority based on the starting date
export const getSeniority = (startingDate, t) => {
  const today = moment(new Date());
  const start = moment(startingDate);
  const years = today.diff(start, DATE_PARTS.year);
  start.add(years, DATE_PARTS.years);

  const allMonths = today.diff(start, DATE_PARTS.months);
  const months = allMonths;
  start.add(months, DATE_PARTS.months);

  const days = today.diff(start, DATE_PARTS.days);

  return {
    basic: years < 1 ? `${allMonths} ${getNameByTotal(t, allMonths, DATE_PARTS.month)}` : `${years} ${getNameByTotal(t, years, DATE_PARTS.year)}`,
    complete: [
      years > 0 && `${years} ${getNameByTotal(t, years, DATE_PARTS.year)}`,
      months > 0 && `${months} ${getNameByTotal(t, months, DATE_PARTS.month)}`,
      days > 0 && `${days} ${getNameByTotal(t, days, DATE_PARTS.day)}`,
    ].filter(Boolean).join(", "),
  };
};
