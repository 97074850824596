import _ from "lodash";
import { isNullOrUndefined } from "common/validators";
import { DEFAULT_MIN_DECIMAL, KEYS_WITH_DECIMALS } from "common/constants";

export const getValueToRender = (valueToRender, key) => (!isNullOrUndefined(valueToRender) && _.isDate(valueToRender)
  ? valueToRender.toLocaleDateString()
  : isFloatToRender(valueToRender, key)
);

export const isFloatToRender = (value, key) => (
  value && key in KEYS_WITH_DECIMALS ? parseFloat(value).toFixed(DEFAULT_MIN_DECIMAL) : value
);

export const getCustomStyle = (cell) => (cell.customStyleByColumn
  ? cell.customStyleByColumn
  : "");

export const getCustomColumn = (cell) => (cell.customColum ? cell.customColum : "");

export const getStyleByCell = (element, item) => (element.customStyleByColumn
  ? element.customStyleByColumn
  : element.cellClass
    ? item.cellClass
    : "");
