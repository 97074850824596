import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  grid: {
    padding: "20px",
  },
  typeMessage: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "24px",
      height: "24px",
    },
  },
}));
