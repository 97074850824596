import styled from "styled-components";

export const StyledContent = styled.div`
  width: 100%;
  height: 120px;
  font-size: 14px;
  margin-top: 25px;
  position: relative;
  .events {
    top: 60px !important;
  }
  .events-bar li {
    padding-bottom: 20px !important;
  }
  .buttons li {
    border: 0px !important;
    top: 60px !important;
  }
`;
