import PropTypes from "prop-types";
import { TYPES, MIN_VALUE } from "common/constants";
import charts from "theme/charts";
import { StyledItem } from "./styles";

const CheckboxButton = (props) => {
  const {
    id,
    name,
    onChange,
    color,
    isChecked,
  } = props;

  const handleCheckbox = (e) => {
    onChange(id, e.target.checked);
  };

  return (
    <StyledItem color={ color }>
      <input
        data-testid={ "checkbox-button" }
        type={ TYPES.checkbox }
        name={ id }
        id={ id }
        onChange={ handleCheckbox }
        checked={ isChecked }
      />
      <label htmlFor={ id }>{ name }</label>
    </StyledItem>
  );
};

CheckboxButton.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  isChecked: PropTypes.bool,
};

CheckboxButton.defaultProps = {
  color: charts.colors.engagementBar[MIN_VALUE],
  isChecked: false,
};

export default CheckboxButton;
