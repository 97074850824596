import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SectionContainer from "components_refactor/SectionContainer";
import { isNull } from "common/helpers";
import { replacePath } from "common/utils";
import constants from "styledComponents/constants";
import AttritionProbabilityCard from "./components/AttritionProbabilityCard";
import AttritionModal from "./components/AttritionModal";
import localRoutes from "../../functions/routes";
import { StyledCardTitle, StyledFixedContainer, StyledLink } from "./styles";

const LossRiskInfo = (props) => {
  const {
    t, history, attritionStates,
  } = props;

  return (
    <SectionContainer>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <StyledCardTitle weight={ constants.fontConstants.bold }>
            {t("common:common.lossRisk")}
          </StyledCardTitle>
          <AttritionProbabilityCard t={ t } attritionStates={ attritionStates } />
          {!(isNull(attritionStates.data) || attritionStates.isLoading) && (
            <>
              <StyledFixedContainer>
                <StyledLink onClick={ () => replacePath(history, localRoutes.lossRisk) }>
                  { t("common:common.viewMore") }
                </StyledLink>
              </StyledFixedContainer>
              <AttritionModal { ...props } />
            </>
          )}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

LossRiskInfo.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  attritionStates: PropTypes.object.isRequired,
};

export default LossRiskInfo;
