export const RESET_STATE = "RESET_STATE_CANDIDATES";
export const GET_LIST = "GET_LIST_CANDIDATES";
export const GET_LIST_LOADING = "GET_LIST_LOADING_CANDIDATES";
export const GET_LIST_ERROR = "GET_LIST_ERROR_CANDIDATES";

// NOTE: for candidate
export const RESET_STATE_PROCESS = "RESET_STATE_PROCESS_CANDIDATE";
export const PROCESS_CANDIDATE = "PROCESS_CANDIDATE";
export const PROCESS_CANDIDATE_LOADING = "PROCESS_CANDIDATE_LOADING";
export const PROCESS_CANDIDATE_ERROR = "PROCESS_CANDIDATE_ERROR";
// end

export const RESET_STATE_CANDIDATE = "RESET_STATE_CANDIDATE";
export const GET_ONE = "GET_ONE_CANDIDATE";
export const GET_ONE_LOADING = "GET_ONE_LOADING_CANDIDATE";
export const GET_ONE_ERROR = "GET_ONE_ERROR_CANDIDATE";

export const RESET_STATE_CANDIDATES_TO_DOWNLOAD = "RESET_STATE_CANDIDATES_TO_DOWNLOAD";
export const GET_LIST_CANDIDATES_TO_DOWNLOAD = "GET_LIST_CANDIDATES_TO_DOWNLOAD";
export const GET_LIST_CANDIDATES_TO_DOWNLOAD_LOADING = "GET_LIST_CANDIDATES_TO_DOWNLOAD_LOADING";
export const GET_LIST_CANDIDATES_TO_DOWNLOAD_ERROR = "GET_LIST_CANDIDATES_TO_DOWNLOAD_ERROR";
