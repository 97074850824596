import {
  useCallback, useLayoutEffect, useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableRow from "@mui/material/TableRow";
import Modal from "components/Modal";
import { isEmpty } from "common/helpers";
import {
  LISTENER, PERCENTAGE, TIMEOUT_REDIRECT,
} from "common/constants";
import useComplexState from "hooks/utils/useComplexState";
import charts from "theme/charts";
import TableDesktop from "./components/TableDesktop";
import TableMobile from "./components/TableMobile";
import TableRowDesktop from "./components/TableRowDesktop";
import { errorTable, noDataTable } from "./functions";
import { StyledTable, StyledTableCard, StyledTableCell } from "./styles";

const TablePaginationInfinityScroll = (props) => {
  const {
    getRows,
    list,
    isLoading,
    error,
    extraParam,
    handlePagination,
    total,
    modalOptions,
    sortAction,
  } = props;
  const { t } = useTranslation(["common", "collaborators"]);

  const tableScroller = useRef();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const [states, setStates] = useComplexState({
    isOpen: false,
    modalContent: null,
    distanceBottom: 0,
  });

  const loadMore = useCallback(() => {
    const loadItems = async () => {
      await new Promise((resolve) => setTimeout(() => {
        handlePagination(1);
        resolve();
      }, TIMEOUT_REDIRECT));
    };
    loadItems();
  }, [handlePagination]);

  const scrollListener = useCallback(() => {
    const bottom = tableScroller.current.scrollHeight - tableScroller.current.clientHeight;

    if (!states.distanceBottom) {
      setStates({ distanceBottom: Math.round((bottom / PERCENTAGE.max) * 20) });
    }
    if (tableScroller.current.scrollTop > bottom - states.distanceBottom
      && !isLoading && list.length < total) {
      loadMore();
    }
  }, [setStates, loadMore, isLoading, states.distanceBottom, list.length, total]);

  useLayoutEffect(() => {
    const tableRef = tableScroller.current;
    if (tableRef) {
      tableRef.addEventListener(LISTENER.scroll, scrollListener);
      return () => {
        tableRef.removeEventListener(LISTENER.scroll, scrollListener);
      };
    }
  }, [scrollListener]);

  const handleCloseModal = (value) => {
    setStates({ isOpen: value });
  };

  const handleModalContent = (content) => {
    setStates({ modalContent: content });
  };

  const handleSort = (columnName, direction) => {
    if (sortAction) sortAction(columnName, direction);
  };

  const rowsOptions = [
    list,
    t,
    history,
    extraParam,
    isMobile,
    {
      value: states.isOpen,
      set: handleCloseModal,
      handleModalContent,
      modalOptions,
    },
  ];

  const showContent = () => {
    if (error) {
      return (
        <TableRow>
          <StyledTableCell colSpan={ rowsOptions.length }>
            {errorTable(t)}
          </StyledTableCell>
        </TableRow>
      );
    }
    if (isEmpty(list) && !isLoading) {
      return (
        <TableRow>
          <StyledTableCell colSpan={ rowsOptions.length }>
            {noDataTable()}
          </StyledTableCell>
        </TableRow>
      );
    }
    if (isMobile && getRows.mobile) {
      return getRows.mobile(...rowsOptions)?.map((item) => (
        <StyledTableCard key={ item }>
          {item?.map((cell) => (
            <div key={ item }>{cell.content}</div>
          ))}
        </StyledTableCard>
      ));
    }
    return getRows.desktop(...rowsOptions)?.map((item, index) => (
      <TableRowDesktop
        key={ `item-${index}` }
        item={ item }
      />
    ));
  };

  const modal = modalOptions && (
    <Modal
      isOpen={ states.isOpen }
      onClose={ handleCloseModal(false) }
      title={ states.modalContent?.title }
    >
      <div>
        {states.modalContent?.children}
      </div>
    </Modal>
  );

  return (
    <StyledTable>
      {/* TODO: Mobile version */}
      {isMobile && getRows.mobile
        ? (
          <TableMobile
            contentTable={ showContent }
            tableRef={ tableScroller }
            { ...props }
          />
        )
        : (
          <TableDesktop
            contentTable={ showContent }
            isMobile={ isMobile }
            tableRef={ tableScroller }
            handleSort={ handleSort }
            { ...props }
          />
        )}
      { modal }
    </StyledTable>
  );
};

TablePaginationInfinityScroll.propTypes = {
  getRows: PropTypes.object.isRequired,
  list: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  extraParam: PropTypes.object,
  handlePagination: PropTypes.func,
  total: PropTypes.number,
  modalOptions: PropTypes.array,
  sortAction: PropTypes.func,
};

TablePaginationInfinityScroll.defaultProps = {
  list: null,
  isLoading: false,
  error: "",
  extraParam: null,
  handlePagination: null,
  total: null,
  modalOptions: null,
  sortAction: null,
};

export default TablePaginationInfinityScroll;
