import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import theme from "theme/palette";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 8px;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.background.iconBtn};
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;

  .MuiTypography-root {
    text-align: center;
    font-size: 10px;
    line-height: 15px;
    white-space: break-spaces;
  }
`;
