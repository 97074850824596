import styled from "styled-components";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";

export const StyledTypography = styled(Typography)`
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledTitleBox = styled(Box)`
  text-align: center;
  margin-left: 48px;
  margin-top: 25px;
  font-weight: bold;
  font-size: 32px;
  flex-grow: 1;
`;

export const StyledDialogActions = styled(DialogActions)`
  margin-top: 20px;
  padding: 0px 24px 25px;
`;

export const StyledDialogContent = styled(DialogContent)`
  overflow-y: hidden;
`;

export const StyledLabel = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const StyledAvatar = styled(Avatar)`
  width: 85px;
  height: 85px;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    width: 45px;
    height: 45px;
  };
`;

export const StyledPosition = styled(Typography)`
  font-weight: normal;
  margin-top: 5px;
`;
