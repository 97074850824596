import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import styled from "styled-components";
import palette from "theme/palette";

export const StyledCard = styled(Card)`
  padding: 24px;
  & input, .MuiOutlinedInput-root, select: {
    background: ${palette.white};
  };
`;

export const StyledContent = styled.div`
  margin-top: 16px;
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const StyledHiddenInput = styled.div`
  display: none;
`;

export const StyledGridSubmit = styled(Grid)`
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
`;

export const StyledGrid = styled(Grid)`
  background: ${palette.background.simpleGrey};
  margin-bottom: 20px;
`;
