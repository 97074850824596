import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { isEqual } from "common/helpers";
import { LINEAR_PROGRESS, VARIANT } from "common/constants";
import { BUTTON_PROGRESS_COLORS } from "theme/palette";
import { StyledContent, StyledLabel, StyledButtonProgress } from "./styles";

const ButtonProgress = (props) => {
  const {
    label,
    onClick,
    color,
  } = props;
  const [isComplete, setIsComplete] = useState(false);
  const [progress, setProgress] = useState(LINEAR_PROGRESS.min);

  const handleClick = () => {
    if (isEqual(progress, LINEAR_PROGRESS.max)) {
      onClick();
    }
  };

  useEffect(() => {
    if (!isComplete) {
      setInterval(() => {
        setProgress((oldProgress) => {
          if (isEqual(oldProgress, LINEAR_PROGRESS.max)) {
            setIsComplete(true);
            return LINEAR_PROGRESS.max;
          }
          const diff = Math.random() * LINEAR_PROGRESS.diff;
          return Math.min(oldProgress + diff, LINEAR_PROGRESS.max);
        });
      }, LINEAR_PROGRESS.longInterval);
    }
  }, [isComplete]);

  return (
    <StyledContent>
      <StyledLabel className={ "button-progress" } onClick={ handleClick } isComplete={ isComplete }>
        <Typography>{ label }</Typography>
      </StyledLabel>
      <StyledButtonProgress
        variant={ VARIANT.determinate }
        value={ progress }
        isComplete={ isComplete }
        mainColor={ color?.main }
        lightMainColor={ color?.light }
        disableMainColor={ color?.disable }
      />
    </StyledContent>
  );
};

ButtonProgress.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.object,
};

ButtonProgress.defaultProps = {
  color: BUTTON_PROGRESS_COLORS.primary,
};

export default ButtonProgress;
