import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { bgColor } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  linkDisabled: {
    "&:hover": {
      cursor: "default",
    },
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardContainer: {
    display: "flex",
    alignItems: "center",
  },
  circleIcon: {
    background: bgColor.noDataHexa,
    width: "48px",
    height: "48px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  disabledCircle: {
    background: theme.palette.text.disabled,
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  icon: {
    color: theme.palette.text.link,
  },
}));

export const StyledCardTitle = styled(Typography)`
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-color: transparent;
  border: none;
`;
