import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ALIGN_ITEMS } from "common/constants";
import { isEqual } from "common/helpers";
import { getCurrentLanguage } from "common/utils";
import { RadioContainer, RadioIcon } from "./styles";

const RadioButtons = (props) => {
  const {
    control, controlName, radioName, options, selected, onClick,
  } = props;

  return (
    <Controller
      data-testid={ "radio-button-view-component" }
      control={ control }
      name={ controlName }
      as={ (
        <RadioGroup row aria-label={ radioName } name={ radioName } >
          {options?.map((item) => (
            <RadioContainer key={ item.title }>
              <FormControlLabel
                value={ item.id }
                control={ (
                  <Radio
                    icon={ (
                      <RadioIcon
                        src={ item.external_img }
                        alt={ item.title }
                        selected={ isEqual(item.id, selected) }
                      />
                    ) }
                    checkedIcon={ (
                      <RadioIcon
                        src={ item.external_img }
                        alt={ item.title }
                        selected={ item.id === selected }
                      />
                    ) }
                    onClick={ () => onClick(item.id) }
                  />
                ) }
                label={ item[`title_${getCurrentLanguage()}`] }
                labelPlacement={ ALIGN_ITEMS.bottom }
              />
            </RadioContainer>
          ))}
        </RadioGroup>
      ) }
    />

  );
};

RadioButtons.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  radioName: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RadioButtons;
