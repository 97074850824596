import { useState, useEffect } from "react";
import useComplexState from "hooks/utils/useComplexState";
import {
  ASC,
  DESC,
  MAX_PER_PAGE,
} from "common/constants";
import { searchDataByName } from "common/utils";
import { sortObjects } from "../functions";

export const useTable = ({
  rows, paginationOptions, searchBy,
}) => {
  const [dataToShow, setDataToShow] = useState(rows || []);
  const [searchResults, setSearchResults] = useState([]);
  const [tableConfig, setTableConfig] = useComplexState({
    order: ASC,
    orderBy: "",
    page: 0,
    rowsPerPage: paginationOptions?.maxPerPage || MAX_PER_PAGE,
    totalFilteredRows: -1,
    emptyRows: 0,
  });

  const updateDataToShow = () => searchResults
    .map((dataRow) => {
      const updatedRow = rows.find((row) => row.id === dataRow.id);
      return updatedRow || null;
    })
    .filter((row) => row !== null);

  // Get the rows to show based on the pagination and sorting configuration
  const getRowsPerPage = () => {
    const actualData = tableConfig.totalFilteredRows >= 0 ? updateDataToShow() : rows;
    let orderedData = actualData;

    if (tableConfig.orderBy && tableConfig.orderBy !== "actions") {
      orderedData = tableConfig.order === ASC
        ? sortObjects(actualData, tableConfig.orderBy)
        : sortObjects(actualData, tableConfig.orderBy, true);
    }

    return (
      orderedData?.slice(
        tableConfig.page * tableConfig.rowsPerPage,
        tableConfig.page * tableConfig.rowsPerPage + tableConfig.rowsPerPage,
      ) || []
    );
  };

  // Handle table sorting when clicking on a column header
  const handleRequestSort = (event, property) => {
    const isAsc = tableConfig.orderBy === property && tableConfig.order === ASC;
    setTableConfig({ order: isAsc ? DESC : ASC, orderBy: property });
  };

  // Handle page change in table pagination
  const handleChangePage = (event, newPage) => {
    setTableConfig({ page: newPage });
  };

  // Perform table search based on the provided text
  const handleSearch = (searchText) => {
    if (!searchText) {
      setTableConfig({ totalFilteredRows: -1 });
      setSearchResults(rows);
      return;
    }

    const newData = searchDataByName(rows, searchText, searchBy);
    setTableConfig({ totalFilteredRows: newData?.length, page: 0 });
    setSearchResults(newData);
  };

  // Handle change in rows per page in table pagination
  const handleChangeRowsPerPage = (event) => {
    setTableConfig({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  // Calculate the number of empty rows in the table
  const getEmptyRows = () => {
    const dataLength = tableConfig.totalFilteredRows >= 0
      ? tableConfig.totalFilteredRows
      : rows?.length;

    return Math.max(
      0,
      tableConfig.rowsPerPage
        - Math.min(
          tableConfig.rowsPerPage,
          dataLength - tableConfig.page * tableConfig.rowsPerPage,
        ),
    );
  };

  useEffect(() => {
    setTableConfig({ emptyRows: getEmptyRows() });
    // eslint-disable-next-line
  }, [
    tableConfig.rowsPerPage,
    tableConfig.page,
    tableConfig.totalFilteredRows,
    rows,
  ]);

  // Update the data to show when the table configuration or rows change
  useEffect(() => {
    setDataToShow(getRowsPerPage());
    // eslint-disable-next-line
  }, [
    tableConfig.order,
    tableConfig.orderBy,
    tableConfig.page,
    tableConfig.rowsPerPage,
    rows,
    tableConfig.totalFilteredRows,
    searchResults,
  ]);

  return {
    dataToShow,
    tableConfig,
    handleRequestSort,
    handleChangePage,
    handleSearch,
    handleChangeRowsPerPage,
  };
};
