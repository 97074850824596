import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import SectionContainer from "components_refactor/SectionContainer";
import { ReactComponent as EnvelopIcon } from "assets/images/icons/envelop.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/icons/linkedin.svg";
import { isEmpty, isNotValid } from "common/helpers";
import { TARGET } from "common/constants";
import { replacePath } from "common/utils";
import constants from "styledComponents/constants";
import { StyledNoData } from "views_refactor/Profile/styles";
import InfoEditButton from "./components/InfoEditButton";
import RolesWithTooltips from "./components/RolesWithTooltips";
import localRoutes from "../../functions/routes";
import { getOrganizationUnitName } from "../../functions/profile";
import {
  StyledInfoContainer,
  StyledTitle,
  StyleRolesContainer,
  StyledLink,
} from "./styles";

const EmployeeInfo = (props) => {
  const {
    t,
    collaborator,
    history,
    isAdmin,
    hasAuthorization,
  } = props;

  const orgUnits = getOrganizationUnitName(collaborator?.job_position?.organization_units);
  const orgUnitsName = [
    orgUnits.division?.name,
    orgUnits.area?.name,
    orgUnits.subarea?.name,
  ];

  const formattedOrgNames = orgUnitsName.filter((name) => name).join(" | ");

  const completedDocumentsLength = collaborator?.requested_documents
    ? collaborator.requested_documents.filter((doc) => doc.file_url || doc.url).length
    : 0;

  return (
    <SectionContainer isFlex>
      <Grid container spacing={ 2 }>
        {isAdmin && hasAuthorization && (
          <Grid item xs={ 12 }>
            <InfoEditButton { ...props } />
          </Grid>
        )}
        <Grid item xs={ 12 } md={ 6 }>
          <StyledInfoContainer>
            <StyledTitle weight={ constants.fontConstants.bold }>
              {t("profile:unit")}
            </StyledTitle>
            <p>
              {orgUnitsName[0] || (<StyledNoData>{t("profile:noData")}</StyledNoData>)}
            </p>
          </StyledInfoContainer>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <StyledInfoContainer>
            <StyledTitle weight={ constants.fontConstants.bold }>
              {t(`profile:${
                  collaborator?.job_position?.city ? "city" : "country"
                }`)}
            </StyledTitle>
            <p>
              {collaborator?.job_position?.city?.name
                || collaborator?.job_position?.country?.name
                || (<StyledNoData>{t("profile:noData")}</StyledNoData>)}
            </p>
          </StyledInfoContainer>
        </Grid>
        <Grid item xs={ 12 }>
          <StyledInfoContainer>
            <StyledTitle weight={ constants.fontConstants.bold }>
              {t("profile:orgUnit")}
            </StyledTitle>
            <StyleRolesContainer>
              {isNotValid(collaborator?.job_position) || isEmpty(collaborator?.job_position?.organization_units)
                ? (
                  <StyledNoData>{t("profile:noData")}</StyledNoData>
                ) : formattedOrgNames}
            </StyleRolesContainer>
          </StyledInfoContainer>
        </Grid>
        <Grid item xs={ 6 }>
          <StyledInfoContainer>
            <StyledTitle weight={ constants.fontConstants.bold }>
              {t("profile:role")}
            </StyledTitle>
            <RolesWithTooltips collaborator={ collaborator } t={ t } />
          </StyledInfoContainer>
        </Grid>
        <Grid item xs={ 6 }>
          <StyledInfoContainer>
            <StyledTitle weight={ constants.fontConstants.bold }>
              {t("profile:documents")}
            </StyledTitle>
            <StyledLink onClick={ () => replacePath(history, localRoutes.documents) } >
              {`(${completedDocumentsLength}) ${t("profile:filesUploaded")}`}
            </StyledLink>
          </StyledInfoContainer>
        </Grid>
        <Grid item xs={ 12 }>
          <StyledInfoContainer color={ "link" }>
            {collaborator?.email
            && (
              <div className={ "imageTextContainer" }>
                <EnvelopIcon />
                <a href={ `mailto:${collaborator.email}` }>
                  {collaborator.email}
                </a>
              </div>
            ) }
            {collaborator?.person?.linkedin_profile && (
              <div className={ "imageTextContainer" }>
                <LinkedinIcon />
                <Link
                  href={ collaborator?.person?.linkedin_profile }
                  target={ TARGET.blank }
                >
                  {t("profile:seeLinkedinProfile")}
                </Link>
              </div>
            )}
          </StyledInfoContainer>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

EmployeeInfo.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  hasAuthorization: PropTypes.bool.isRequired,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
};

EmployeeInfo.defaultProps = {
  history: null,
  isAdmin: false,
};

export default EmployeeInfo;
