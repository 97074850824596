import styled from "styled-components";
import theme from "theme/palette";

export const StyledItem = styled.div`
  label {
    border: 1px solid ${theme.text.inactive};
    color: ${theme.text.theadSubtitleText};
    display: inline-block;
    padding: 4px 8px 4px 25px;
    position: relative;
    cursor: pointer;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: "";
      width: 7px;
      height: 7px;
      display: inline-block;
      background: ${theme.text.theadSubtitleText};
      border-radius: 50%;
      position: absolute;
      left: 5px;
      top: 9px;
    }
    &:hover {
      color: ${theme.text.theadSubtitleText};
      border: 1px solid ${theme.text.inactive};
      &:before {
        background: ${theme.text.theadSubtitleText};
      }
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked {
      ~ label {
        color: ${(props) => props.color};
        border-color: ${(props) => props.color};
        &:before {
          background: ${(props) => props.color};
        }
      }
    } 
  }
`;
