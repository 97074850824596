import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import {
  ALIGN_ITEMS, COMPONENT, PAGINATION, SIZE, SKELETONS_NUMBER,
} from "common/constants";
import { getSortingOrder } from "common/utils";
import {
  StyledTableRow,
  StyledTableCell,
  StyledAddCircleIcon,
  StyledRemoveCircleIcon,
  StyledOrder,
  StyledArrowUpwardIcon,
} from "./styles";

const FollowUpTable = (props) => {
  const {
    header,
    data,
    pagination,
    isLoading,
    setTableSort,
    total,
  } = props;
  const [isHidden, setIsHidden] = useState({});
  const [order, setOrder] = useState({});
  const start = data?.length - 1 <= PAGINATION.maxPerPage ? 0 : pagination.page * pagination.pageSize;
  const rowsByPage = data && data.slice(start, start + pagination.pageSize);

  const activeOrInactiveData = (parentId, isDisabled) => {
    setIsHidden((prevValues) => (
      { ...prevValues, [parentId]: isDisabled }
    ));
  };

  const orderByColumn = (columnName, direction) => {
    setOrder({ [columnName]: direction });
    setTableSort(getSortingOrder(columnName, direction));
  };

  const setParents = useCallback(() => {
    const parentsData = {};
    const parents = header?.filter((item) => item.isParent);
    if (parents) {
      parents.forEach((element) => { parentsData[element.id] = true; });
    }
    setIsHidden(parentsData);
  }, [header]);

  useEffect(() => {
    setParents();
  }, [header, setParents]);

  const getOrderIcon = (headCell) => {
    let orderIcon = <StyledArrowUpwardIcon isDisabled />;
    if (order[headCell.sortingColumn] || order[headCell.sortingColumn] === false) {
      orderIcon = order[headCell.sortingColumn] ? <StyledArrowUpwardIcon /> : <ArrowDownwardIcon />;
    }
    return orderIcon;
  };

  const headerContent = header?.map((headCell) => (
    <StyledTableCell
      key={ headCell.id }
      disabled={ headCell.key && isHidden[headCell.key] && "none" }
    >
      <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
        {headCell.hasSorting && (
          <StyledOrder>
            <IconButton
              onClick={ (e) => (
                orderByColumn(headCell.sortingColumn, !order[headCell.sortingColumn], e)
              ) }
            >
              { getOrderIcon(headCell) }
            </IconButton>
          </StyledOrder>
        )}
        {headCell.label}
        {headCell.tooltip}
        {headCell.isParent && (
          <Box>
            <IconButton
              onClick={ () => (
                activeOrInactiveData(headCell.id, !isHidden[headCell.id])
              ) }
            >
              {isHidden[headCell.id]
                ? <StyledAddCircleIcon />
                : <StyledRemoveCircleIcon /> }
            </IconButton>
          </Box>
        )}
      </Box>
    </StyledTableCell>
  ));

  const rowsContent = rowsByPage && rowsByPage?.map((item) => (
    <StyledTableRow key={ item.id }>
      {header?.map((element, index) => (
        <StyledTableCell
          key={ `result${element.id}` }
          color={ element.color }
          disabled={ element.key && isHidden[element.key] && "none" }
        >
          { item[index].content }
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));

  const paginationContent = !isLoading && (data?.length === 0 ? <NoDataMessage />
    : (
      <TablePagination
        component={ COMPONENT.div }
        count={ Number(total) }
        rowsPerPage={ pagination.pageSize }
        page={ pagination.page }
        onPageChange={ pagination.handlePageChange }
        rowsPerPageOptions={ [] }
      />
    ));

  return (
    <Box mt={ 2 } >
      <TableContainer component={ Paper }>
        <Table size={ SIZE.small }>
          <TableHead>
            <StyledTableRow>
              { headerContent }
            </StyledTableRow>
          </TableHead>
          { !isLoading
            && (
              <TableBody>
                { rowsContent }
              </TableBody>
            )}
        </Table>
        {isLoading && <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } />}
        { paginationContent}
      </TableContainer>
    </Box>
  );
};

FollowUpTable.propTypes = {
  data: PropTypes.array,
  header: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setTableSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
};

FollowUpTable.defaultProps = {
  isLoading: false,
  data: [],
  total: null,
};

export default FollowUpTable;
