import { useQuery, useQueryClient } from "react-query";
import { getHierarchyLevels } from "redux/actions/common/hierarchyLevelActions";

const fetchHierarchyLevels = async () => {
  const response = await getHierarchyLevels();
  return response;
};

export const useHierarchyLevel = () => {
  const queryClient = useQueryClient();

  const { data: hierarchyLevels, isLoading, isError } = useQuery("hierarchyLevels", fetchHierarchyLevels, {
    staleTime: Infinity,
  });

  return {
    hierarchyLevels,
    isLoading,
    isError,
    queryClient,
  };
};
