import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { VARIANT, SIZE } from "common/constants";
import { StyledContainer, StyledTextField } from "./styles";

const SelectComponent = (props) => {
  const {
    id,
    label,
    menuItems,
    value = "",
    onChange,
    disabled,
    size,
  } = props;
  const handleChange = (prop) => (event) => {
    onChange(prop, event);
  };

  return (
    <StyledContainer data-testid={ "select-container" }>
      <StyledTextField
        role={ "textbox" }
        variant={ VARIANT.outlined }
        value={ value }
        onChange={ handleChange(id) }
        select
        label={ label }
        disabled={ disabled }
        size={ size }
      >
        {menuItems
          && menuItems.map((menuItem, index) => (
            <MenuItem key={ index } value={ menuItem.value }>
              <Typography variant={ "body1" }>{menuItem.label}</Typography>
            </MenuItem>
          ))}
      </StyledTextField>
    </StyledContainer>
  );
};

SelectComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  menuItems: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

SelectComponent.defaultProps = {
  id: "",
  label: "",
  menuItems: [],
  value: "",
  onChange: () => {},
  disabled: false,
  size: SIZE.small,
};

export default SelectComponent;
