import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledIcon = styled.div`
  svg {
    color: ${props => props.color};
    width:  20px;
    height:  20px;
  }
`;

export const StyledSmileIcon = styled.div`
  text-align: center;
  margin-bottom: 16px;
  img {
    width:  40px;
    height:  40px;
  }
`;

export const StyledTypography = styled(Typography)`
  margin-right: 16px;
`;


export const StyledStateIcon = styled.div`
  margin-right: 8px;
`;
