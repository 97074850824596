import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import {
  replacePath,
  getCitiesByCountry,
  getItemFromLocalStorage,
  isColombianAdmin,
} from "common/utils";
import {
  ENTITY_OPTION, FORM_MODE, INDEX, LOCAL_STORAGE_NAMES,
} from "common/constants";
import useComplexState from "hooks/utils/useComplexState";
import { create as createPosition } from "redux/actions/position/positionActions";
import { getList as getHierarchyLevelList, create as createLevel } from "redux/actions/common/hierarchyLevelActions";
import { getList as getLegalEntityList, create as createEntity } from "redux/actions/common/legalEntityActions";
import { create as createCity } from "redux/actions/common/cityActions";
import { getList as getDynamicAttribute } from "redux/actions/common/dynamicAttributeActions";
import { useCities } from "hooks/useCities";
import localRoutes from "../functions/routes";
import {
  dispatchUpdate,
  getSelectedModal,
  getRolesApp,
  resetDefaultEmployeeValues,
  getOrganizationUnitName,
  getRolesWithScope,
  generateSubmitData,
} from "../functions/profile";
import { getDivisions, getAreas } from "../functions";

export const useEmployeeInfoHook = (externalHooks, internalHooks) => {
  const {
    t, user, dispatch, location,
  } = externalHooks;
  const {
    collaborator,
    updateMovementFormValue,
  } = internalHooks;

  const {
    list: hierarchyLevelsList,
    isLoadingList: isLoadingHierarchyLevels,
  } = useSelector(({ hierarchyLevelReducer }) => hierarchyLevelReducer);
  const {
    list: legalEntitiesList,
    isLoadingList: isLoadingLegalEntities,
  } = useSelector(({ legalEntityReducer }) => legalEntityReducer);
  const {
    list: dynamicAttributesList,
    isLoadingList: isLoadingDynamicAttributes,
  } = useSelector(({ dynamicAttributeReducer }) => dynamicAttributeReducer);

  const isValidColombianAdmin = isColombianAdmin(user?.userCookies);
  const allRolesList = getRolesApp(t, user, isValidColombianAdmin);

  const organizationUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);
  const [dynamicFieldsList, setDynamicFieldsList] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [levels, setLevels] = useState([]);
  const [entities, setEntities] = useState([]);
  const [employeeInfoStates, setEmployeeInfoStates] = useComplexState({
    isModalOpen: {
      employeeInfo: false,
      rolesInfo: false,
    },
    inputTextValue: "",
    newCreation: null,
    isOpenDialog: false,
    inputTagsSelected: [],
    employeeRoles: [],
    optionsList: {
      countries: getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries),
      positions: getItemFromLocalStorage(LOCAL_STORAGE_NAMES.positions),
      divisions: getDivisions(organizationUnits),
    },
  });

  // Cities
  const {
    cities: allCities, isLoading, queryClient,
  } = useCities();

  const employee = "collaborator"; // TODO: change this to dynamic when candidate view is ready

  const {
    control, handleSubmit, reset, register, errors, setValue, getValues,
  } = useForm({
    defaultValues: resetDefaultEmployeeValues(employee),
    mode: FORM_MODE.onChange,
  });

  // use effect need it to handle the routes (for modals)
  useEffect(() => {
    if (location.pathname !== localRoutes.root) {
      getSelectedModal(location.pathname).then((resolve) => {
        const { path, key } = resolve;
        replacePath(path);
        setEmployeeInfoStates({
          isModalOpen: {
            ...employeeInfoStates.isModalOpen,
            [key]: true,
          },
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getHierarchyLevelList());
    dispatch(getLegalEntityList());
    dispatch(getDynamicAttribute());
  }, [dispatch]);

  useEffect(() => {
    if (hierarchyLevelsList && hierarchyLevelsList?.length > 0) {
      setLevels(hierarchyLevelsList);
    }
    if (legalEntitiesList && legalEntitiesList?.length > 0) {
      setEntities(legalEntitiesList);
    }
  }, [hierarchyLevelsList, legalEntitiesList]);

  useEffect(() => {
    if (collaborator && !isLoading) {
      if (collaborator.job_position) {
        const orgUnits = getOrganizationUnitName(collaborator?.job_position?.organization_units);
        collaborator.job_position.division_id = orgUnits.division?.id;
        collaborator.job_position.area_id = orgUnits.area?.id;
        reset(resetDefaultEmployeeValues(employee, collaborator));
        if (collaborator.job_position.country?.id && allCities) {
          setCities(getCitiesByCountry(collaborator.job_position.country.id, allCities));
        }
        if (collaborator.job_position.division_id) {
          setAreas(getAreas(organizationUnits, collaborator.job_position.division_id));
        }
      }
      if (collaborator?.roles_with_scope?.length > 0) {
        setEmployeeInfoStates({
          inputTagsSelected: getRolesWithScope(collaborator?.roles_with_scope, t),
        });
      }
    }
    // eslint-disable-next-line
	}, [collaborator, isLoading]);

  useEffect(() => {
    if (!isLoadingDynamicAttributes && dynamicAttributesList) {
      const formatElements = dynamicAttributesList?.map((item) => {
        const customAttributesData = collaborator?.custom_attributes?.find((e) => e.name === item.name);
        return {
          id: item.id,
          custom_attribute_id: customAttributesData?.id || null,
          label: item.name,
          value: customAttributesData?.value || null,
        };
      });
      setDynamicFieldsList(formatElements);
    }
  }, [dynamicAttributesList, isLoadingDynamicAttributes, collaborator, setDynamicFieldsList]);

  const onSubmit = (data) => {
    const submitData = generateSubmitData(
      data,
      employee,
      collaborator.roles_with_scope,
      employeeInfoStates,
      dynamicFieldsList,
      allRolesList,
    );

    // Dispatch the update action
    dispatchUpdate(dispatch, employee, submitData, collaborator.id);
  };

  // OnAutoComplete fields
  const onAutocompleteChange = () => (prop, newValue) => {
    if (prop === ENTITY_OPTION.country) {
      setValue(`${employee}.job_position_attributes.city_id`, null);
      if (allCities) {
        setCities(getCitiesByCountry(newValue?.id, allCities));
      }
    }
  };

  const onChangeDivision = (divisionId, setValueForm) => {
    setValueForm(`${employee}.job_position_attributes.area_id`, null);
    setAreas(getAreas(organizationUnits, divisionId));
  };

  // Handles
  const handleDialog = (option) => {
    setEmployeeInfoStates({ isOpenDialog: option });
  };

  const handleDialogSubmit = (text, prop) => {
    setEmployeeInfoStates({
      inputTextValue: text,
      newCreation: prop,
    });
    handleDialog(true);
  };

  const handleRolesModal = () => {
    setEmployeeInfoStates({
      isModalOpen: {
        ...employeeInfoStates.isModalOpen,
        rolesInfo: !employeeInfoStates.isModalOpen.rolesInfo,
      },
    });
  };

  const handleTags = (tags) => {
    setEmployeeInfoStates({ employeeRoles: tags });
  };
  // End Handles

  const creations = {
    city: {
      list: cities,
      data: {
        country_id: getValues(`${employee}.job_position_attributes.country_id`)?.id,
        name: employeeInfoStates.inputTextValue,
      },
      create: createCity,
      name: `${employee}.job_position_attributes.city_id`,
      queryName: "cities",
    },
    position: {
      list: employeeInfoStates.optionsList.positions,
      localName: LOCAL_STORAGE_NAMES.positions,
      data: {
        name: employeeInfoStates.inputTextValue,
      },
      create: createPosition,
      name: `${employee}.job_position_attributes.position_id`,
    },
    level: {
      list: levels,
      data: {
        name: employeeInfoStates.inputTextValue,
      },
      create: createLevel,
      name: `${employee}.job_position_attributes.hierarchy_level_id`,
    },
    legalEntity: {
      list: entities,
      data: {
        name: employeeInfoStates.inputTextValue,
        code: employeeInfoStates.inputTextValue,
      },
      create: createEntity,
      name: `${employee}.legal_entity_id`,
    },
  };

  const handleNew = async (isMovementForm = false) => {
    const newItem = creations[employeeInfoStates.newCreation];
    const createdElement = await dispatch(newItem.create(newItem.data));

    if (createdElement) {
      //  error handle
      if (createdElement.error) {
        toast(MESSAGE_TYPES.error, createdElement, t);
      } else {
        newItem.list.push(createdElement);
        if (isMovementForm) {
          updateMovementFormValue(newItem.name, createdElement);
        } else {
          setValue(newItem.name, createdElement);
        }
        if (newItem?.localName) {
          const tempLocalstorage = getItemFromLocalStorage(newItem.localName);
          tempLocalstorage.push(createdElement);
          localStorage.setItem(newItem.localName, JSON.stringify(tempLocalstorage));
        }
        if (newItem?.queryName) {
          queryClient.setQueryData(newItem.queryName, (old) => [...old, createdElement]);
        }
      }

      handleDialog(false);
    }
  };

  const handleChangeDynamicFields = (prop) => (event) => {
    const objIndex = dynamicFieldsList?.findIndex((obj) => obj.label === prop.label);
    setDynamicFieldsList([
      ...dynamicFieldsList.slice(INDEX.zero, objIndex),
      { ...dynamicFieldsList[objIndex], value: event.target.value },
      ...dynamicFieldsList.slice(objIndex + INDEX.one),
    ]);
  };

  return {
    employeeInfoStates,
    allRolesList,
    hookEmployeeForm: {
      control,
      handleSubmit,
      reset,
      register,
      setValue,
    },
    modalButtons: {
      disagree: t("buttons:cancel"),
      agree: t("buttons:saveChanges"),
    },
    handleFunctions: {
      handleNew,
      handleDialog,
      handleDialogSubmit,
      handleRolesModal,
      handleTags,
      handleChangeDynamicFields,
    },
    loadings: {
      isLoadingHierarchyLevels,
      isLoadingLegalEntities,
      isLoadingDynamicAttributes,
    },
    otherFields: {
      levels,
      entities,
      cities,
      areas,
      dynamicFieldsList,
    },
    onAutocomplete: {
      onAutocompleteChange,
      onChangeDivision,
    },
    employeeErrors: errors,
    onEmployeeSubmit: onSubmit,
  };
};
