import PropTypes from "prop-types";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "components/Breadcrumbs";
import { BUTTON_STYLE_TYPES, VARIANT } from "common/constants";
import MenuPopup from "../MenuPopup";
import { useStyles, StyledTitle, StyledSubTitle } from "./styles";

const ViewHeaderTitle = (props) => {
  const classes = useStyles();
  const {
    title,
    subtitle,
    breadcrumb,
    isWithMenuButton,
    menuItems,
    menuButtonLabel,
    customTitleStyle,
    icon,
  } = props;
  const gridTitle = isWithMenuButton ? 6 : 12;
  return (
    <div data-testid={ "view-header-title" }>
      <Grid container>
        {breadcrumb && (
          <Grid item xs={ 12 }>
            <Breadcrumbs data={ breadcrumb } />
          </Grid>
        )}
        <Grid item xs={ gridTitle }>
          <StyledTitle className={ clsx(customTitleStyle) }>
            {title}
            {icon}
          </StyledTitle>
          {subtitle && (
            <StyledSubTitle variant={ VARIANT.bodyOne }>
              {subtitle}
            </StyledSubTitle>
          )}
        </Grid>
        {isWithMenuButton && (
          <Grid item xs={ 6 } sm={ 6 } className={ classes.btnRight }>
            <MenuPopup
              button={ menuButtonLabel }
              items={ menuItems }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
ViewHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  breadcrumb: PropTypes.array,
  isWithMenuButton: PropTypes.bool,
  menuItems: PropTypes.array,
  menuButtonLabel: PropTypes.string,
  customTitleStyle: PropTypes.object,
  icon: PropTypes.node,
};

ViewHeaderTitle.defaultProps = {
  subtitle: null,
  breadcrumb: null,
  isWithMenuButton: false,
  menuItems: [],
  menuButtonLabel: null,
  customTitleStyle: null,
  icon: "",
};

export default ViewHeaderTitle;
