import styled from "styled-components";
import Button from "components/Button";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const ButtonStyled = styled(Button)`
  color: ${palette.text.link};
`;

export const Container = styled.div`
  button {
    width: 100% !important;
    border: 1px solid ${palette.text.link};
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
`;

export const TextButton = styled.div`
  color: ${palette.text.link};
`;
