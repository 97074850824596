import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledDescription = styled.div`
  button {
    width: 100% !important;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  h4 {
    font-weight: 700;
  }
`;

export const StyledQuadrantDescription = styled(Typography)`
  padding-top: 8px;
`;

export const StyledHistorical = styled.div`
  max-height: 400px;
  @media (max-width: 2200px) {
    max-height: 500px;
  }
  @media (max-width: 1800px) {
    max-height: 350px;
  }
  @media (max-width: 1300px) {
    max-height: 280px;
  }
  @media (max-width: 1024px) {
    max-height: 240px;
  }
`;
